import CommonComponent from "../../components/CommonComponent";

// module
import * as authActions from "../../../redux/modules/Auth";
import * as hotelActions from "../../../redux/modules/Hotel";



class HotelDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_HOTEL_ONE") {
      this.onGetHotelOneResults(props);
    }
    if (this.state.requestType == "GET_PAYMENT_SETTING") {
      this.onGetPaymentSettingResults(props);
    }
  }

  // redux에 get hotel Detail 요청
  requestGetHotelOne = (hotelId) => {
    this.setState({
      requestType: "GET_HOTEL_ONE",
    });
    this.props.onGetOne(hotelId);
  };

  // redux에 get payment setting  요청
  requestGetPaymentSetting = (hotelId) => {
    this.setState({
      requestType: "GET_PAYMENT_SETTING",
    });
    this.props.onGetPaymentSetting(hotelId);
  };

  // get hotel One results
  onGetHotelOneResults = (props) => {
    if (props.hotelDetail == null && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    this.requestGetPaymentSetting(this.state.hotelId);

    if (props.hotelDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_hotel_detail"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // get Region results
  onGetPaymentSettingResults = (props) => {
    if (props.paymentSetting == null && props.failData == null) {
      // 응답이 오기 전에 실행하지 못하도록 return함
      return;
    }
    if (props.paymentSetting != null || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props.paymentSetting?.success == true) {
      } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
        const reason = props.paymentSetting?.reason;
        this.props.showSimpleTopPopupDialog(reason ?? this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      hotelDetail: state.hotel.hotelDetail,
      paymentSetting: state.hotel.paymentSetting,
      failData: state.hotel.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetOne: (hotelId) => dispatch(hotelActions.onGetOne(hotelId)),
      onGetPaymentSetting: (hotelId) => dispatch(hotelActions.onGetPaymentSetting(hotelId)),
    };
  };
}

export default HotelDetailData;
