var languageCode = "";
var lang = "undefined";
let MultiLanguage = null;

// Webpack의 require.context를 사용하여 특정 디렉토리에서 동적으로 모듈을 로드 
const requireLanguage = require.context("./multiLanguage", false, /\.js$/);

if (navigator.language != null) {
  lang = navigator.language; // 언어 값이 있을 경우 lang에 저장
} else {
  lang = "undefined";
}

if (lang.includes("ko")) {
  languageCode = "ko-KR";
} else if (lang.includes("ja")) {
  languageCode = "ja-JP";
} else if (lang.includes("zh")) {
  languageCode = "zh-CN";
} else if (lang.includes("ms")) {
  languageCode = "ms-MY";
} else {
  languageCode = "en-EN";
}

var multiLanguageCode = languageCode.substr(0, 2);


const loadLanguage = async () => {
  try {
    if( MultiLanguage == null || MultiLanguage == undefined) {
      // multiLanguageCode에 따라 동적 import하여 MultiLanguage에 할당함.
      const modulePath = `./${multiLanguageCode}.js`
      const module = await requireLanguage(modulePath);
      MultiLanguage = module.default || module;

      useMultiLanguage(MultiLanguage);

      return { MultiLanguage, multiLanguageCode };
    }

  } catch (error) {
    console.error("Error loading language", error)
  }
}

const useMultiLanguage = (MultiLanguage) => {
  if(MultiLanguage) {
    // 화면에 표시되는 text를 multiLanguageCode에 맞게 번역 업데이트함.
    updateTextLanguage(MultiLanguage);
  } else {
    console.log("MultiLanguage is not loaded")
  }
}

const LANGUAGE_CODE_LIST = ["ko", "en", "cn", "jp", "ms"];

function updateLanguage() {
  loadLanguage();
}

function updateTextLanguage(MultiLanguage) {
    if(MultiLanguage == null || MultiLanguage == undefined) {
      return;
    }
   // 각 dataset중 detect인 요소들을 찾아 변경하는 곳.
    const changeNodeList = Array.prototype.slice.call(document.querySelectorAll("[data-detect]"));
    changeNodeList.forEach((v,i) => {
      if(i == changeNodeList.length -1) {
      }
      v.innerHTML = updateStringToHtml(MultiLanguage.language[multiLanguageCode][v.dataset.detect]??v.dataset.detect);
    })
  
    // 각 dataset중 detect-placeholder인 요소들을 찾아 변경하는 곳.
    const changePlaceholderList = Array.prototype.slice.call(document.querySelectorAll("[data-detect-placeholder]"));
    changePlaceholderList.forEach((v) => {
      v.placeholder = updateStringToHtml(MultiLanguage.language[multiLanguageCode][v.dataset.detectPlaceholder]);
    })
  
    // 각 dataset중 detect-value인 요소들을 찾아 변경하는 곳.
    const changeValueList = Array.prototype.slice.call(document.querySelectorAll("[data-detect-value]"));
    changeValueList.forEach((v) => {
      v.value = updateStringToHtml(MultiLanguage.language[multiLanguageCode][v.dataset.detectValue]);
    })

}

function updateStringToHtml(value) {
  if (typeof value === 'undefined') {
    return '';
  } 
  var replaceString = value.replace(/\n/gi, "<br>");
  replaceString = replaceString.replace(/<</gi, "<b>");
  replaceString = replaceString.replace(/>>/gi, "</b>");
  return replaceString;
}

export { languageCode, multiLanguageCode, MultiLanguage, updateLanguage, loadLanguage, updateTextLanguage, LANGUAGE_CODE_LIST };