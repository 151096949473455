import CommonComponent from "../../components/CommonComponent";

// modules
import * as statisticsActions from "../../../redux/modules/Statistics";

class StatisticsListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      statisticsList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hotelList != this.props.hotelList) {
      if (this.state.requestType == "GET_HOTEL_LIST") {
        this.onGetHotelListResults(nextProps);
      }
    }
    if (nextProps.statisticsList != this.props.statisticsList) {
      if (this.state.requestType == "GET_STATISTICS_LIST") {
        this.onGetStatisticsListResults(nextProps);
      }
    }
  }

  /* ----------------------------------------------------- request ---------------------------------------------------- */
  // redux에 get hotel list 요청
  requestGetHotelList = () => {
    this.setState({
      requestType: "GET_HOTEL_LIST",
    });
    this.props.onGetAllHotel();
  };

  // redux에 get statistics list 요청
  requestGetStatisticsList = (pageNo, startDate, endDate, hotelId) => {
    this.setState({
      requestType: "GET_STATISTICS_LIST",
    });
    this.props.onGetAll(pageNo, startDate, endDate, hotelId);
  };

  /* ----------------------------------------------------- results ---------------------------------------------------- */
  // get hotel list results
  onGetHotelListResults = (props) => {
    if (props.hotelList?.length == 0 && props.failData == null) {
      return;
    }

    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.hotelList?.success == true) {
      // 성공
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // get statistics list results
  onGetStatisticsListResults = (props) => {
    if (props.statisticsList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.statisticsList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      hotelList: state.statistics.hotelList,
      statisticsList: state.statistics.statisticsList,
      failData: state.statistics.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAllHotel: () => dispatch(statisticsActions.onGetAllHotel()),
      onGetAll: (pageNo, startDate, endDate, hotelId) => dispatch(statisticsActions.onGetAll(pageNo, startDate, endDate, hotelId)),
    };

    return returnProps;
  };
}

export default StatisticsListData;
