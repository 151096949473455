import axiosInstance from "../api/axios";

/* ---------------------------------------------------- get List ---------------------------------------------------- */
export function onGetAll(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/hotel/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

/* ----------------------------------------------------- get One ---------------------------------------------------- */
export function onGetOne(hotelId) {
  return axiosInstance.get("/admin/api/hotel/" + hotelId);
}

/* ---------------------------------------------------- add Hotel --------------------------------------------------- */
export function onAdd(formData) {
  return axiosInstance.post("/admin/api/hotel/", formData);
}
/* -------------------------------------------------- delete Hotel -------------------------------------------------- */
export function onDelete(hotelId) {
  return axiosInstance.delete("/admin/api/hotel/" + hotelId);
}

//------------------------------- edit Hotel ------------------------------------------------------//
export function onEdit(hotelId, region, country, city) {
  const data = {
    region: region,
    country: country,
    city: city,
  };

  return axiosInstance.put("/admin/api/hotel/area/" + hotelId, data);
}
/* ------------------------------------------------ edit Hotel isShow ----------------------------------------------- */
export function onEditIsShow(hotelId, isShow) {
  const data = {
    isShow: isShow,
  };

  return axiosInstance.put("/admin/api/hotel/isShow/" + hotelId, data);
}

/* --------------------------------------------------- copy Hotel --------------------------------------------------- */
export function onCopy(formData) {
  return axiosInstance.post("/admin/api/v2/hotel/copyHotel", formData);
}

/* --------------------------------------------------- get Region --------------------------------------------------- */
export function onGetRegion() {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/region/?content=&offset=0&limit=" + maxNumber);
}

/* --------------------------------------------------- get Country -------------------------------------------------- */
export function onGetCountry(region) {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/country/?content=&offset=0&limit=" + maxNumber + "&hotelRegionId=" + region);
}

/* ---------------------------------------------------- get City ---------------------------------------------------- */
export function onGetCity(regionId, countryId) {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/city/?content=&offset=0&limit=" + maxNumber + "&hotelCountryId=" + countryId + "&hotelRegionId=" + regionId);
}

/* --------------------------------------------------- get Country Name -------------------------------------------------- */
export function onGetCountryName() {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/v2/IDRecognitionInfo?countryName=&offset=0&idType=&limit=" + maxNumber);
}

/* ------------------------------------------------ edit country name ----------------------------------------------- */
export function onEditCountryName(hotelId, countryName) {
  const data = {
    countryName: countryName
  }

  return axiosInstance.put("/admin/api/hotel/countryName/" + hotelId, data);
}

/* ----------------------------------------------- get payment setting ---------------------------------------------- */
export function onGetPaymentSetting(hotelId) {

  return axiosInstance.get("/admin/api/hotel/payment/setting?hotelId=" + hotelId);
}

/* ---------------------------------------------- edit payment setting ---------------------------------------------- */
export async function onEditPaymentSetting(hotelId, paymentServerURL, isDirectPayable, isCardTokenizable) {
  const data = {
    paymentServerURL: paymentServerURL,
    isDirectPayable: isDirectPayable,
    isCardTokenizable: isCardTokenizable,
  };

  return axiosInstance.put("/admin/api/hotel/payment/setting/" + hotelId, data);
}
