// HotelListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import HotelListData from "../List/HotelListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/hotel/hotelList.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class HotelListView extends HotelListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],
      isMoved: false,

      // 임시값
      tempSearch: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetHotelList(
        this.state.pageNo,
        this.state.search,
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = e.target.value;

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;

    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
      },
      () => {
        this.requestGetHotelList(this.state.pageNo, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
        },
        () => {
          this.requestGetHotelList(this.state.pageNo, this.state.search);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search }, () => {
      this.requestGetHotelList(pageNo, this.state.search); // 호텔 목록 요청
    });
  };

  //pageNo 변경시 history.location의 state에 저장
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  //파람값 url로 전달
  linkHotelDetail = (hotelId) => {
    this.props.history.push(`/hotelDetailPage?hotelId=${hotelId}`);
  };

  //insert
  linkHotelAdd = () => {
    this.props.history.push("/hotelAddPage");
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  handleMouseUp = (hotelId) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkHotelDetail(hotelId);
    }
  }

  render() {
    let count;
    let hotelList = [];

    if (this.props.hotelList != null && this.props.hotelList?.success == true) {
      count = this.props.hotelList.results.count;
      hotelList = this.props.hotelList.results.rows;
    }
    return (
      <div className="hotelList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="hotel_list_container">
          <div className="hotel_list_container_inner">
            <div className="title" data-detect="admin_hotel_list_title">
              HOTEL LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_hotel_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- hotel list table  --> */}
            <div className="hotel_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(hotelList, this.state.pageNo)}
                        checked={hotelList && this.state.checkedList.length == hotelList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_hotel_list_label_hotel_no">
                      Hotel No.
                    </div>
                    <div className="cell" data-detect="admin_hotel_list_label_name">
                      Name
                    </div>
                    <div className="cell" data-detect="admin_hotel_list_label_address">
                      Address
                    </div>
                    <div className="cell" data-detect="admin_hotel_list_label_is_show">
                      Is Show
                    </div>
                    <div className="cell" data-detect="admin_hotel_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {hotelList?.length > 0 ? (
                    hotelList.map((hotel, index) => (
                      <div className="row tbodyRow" key={hotel.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={hotel.id}
                            id={hotel.id}
                            onClick={() => this.onClickInput(hotel.id, hotelList)}
                            checked={this.state.checkedList.includes(hotel.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={hotel.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(hotel.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {hotel.id}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(hotel.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {hotel.name}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(hotel.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {hotel.address}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(hotel.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {hotel.isShow}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(hotel.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(hotel.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_hotel_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedHotels();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_hotel_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkHotelAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_hotel_list_button_add">Add</span>
                </button>
                <button
                  className="copy_btn"
                  id="copy_btn"
                  type="button"
                  onClick={() => {
                    this.copyHotel();
                  }}
                >
                  <span data-detect="admin_hotel_list_button_copy">Copy</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = HotelListData.mapStateToProps;
let mapDispatchToProps = HotelListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HotelListView)
);
