import React, { Component } from 'react';
import MyConectColors from '../../../components/colors/MyConectColors';

class CircleButton extends Component {
    render() {
        const { disabled } = this.props;

        const textColor = {
            color: !disabled ? MyConectColors.BG_PRIMARY.TEXT_DEFAULT : MyConectColors.BUTTON_TEXT_DISABLE_GREY,
        };

        return (
            <button className={`myConect_circle_button ${disabled ? 'disabled' : ''}`} disabled={disabled} style={textColor}>
                <div className='myConect_buttton_title'>
                    관리자 로그인 페이지
                </div>
            </button>
        );
    }
}

export default CircleButton;
