import React, { Component } from "react";
import "../../components/loading/Loading.css"
import logoLoading from "../../img/icon_question_register_loading.svg";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="loading_container">
        <div className="loading">
          <img src={logoLoading} alt={"loading"} />
        </div>
      </div>
    );
  }
}

export default Loading;
