import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as amenityApis from "../lib/amenity_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const AMENITY_GET_ALL = "amenity/AMENITY_GET_ALL";
const AMENITY_GET_ONE = "amenity/AMENITY_GET_ONE";
const ADD_AMENITY = "amenity/ADD_AMENITY";
const DELETE_AMENITY = "amenity/DELETE_AMENITY";
const EDIT_AMENITY = "amenity/EDIT_AMENITY";

// 액션 생성자
export const onGetAllAmenity = createAction(AMENITY_GET_ALL, amenityApis.onGetAllAmenity);
export const onGetOneAmenity = createAction(AMENITY_GET_ONE, amenityApis.onGetOneAmenity);
export const onAddAmenity = createAction(ADD_AMENITY, amenityApis.onAddAmenity);
export const onDeleteAmenity = createAction(DELETE_AMENITY, amenityApis.onDeleteAmenity);
export const onEditAmenity = createAction(EDIT_AMENITY, amenityApis.onEditAmenity);

// 초기 상태
const initialState = {
    amenityList: [],
    amenityDetail: null,
    amenityAdd: null,
    amenityEdit: null,
    successDelete: null,
    failData: null,
};

export default handleActions(
    {
        ...pender({
            type: AMENITY_GET_ALL,
            onPending: (state, action) => {
                return { ...state, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, amenityList: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, amenityList: [], failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: AMENITY_GET_ONE,
            onPending: (state, action) => {
                return { ...state, amenityDetail: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, amenityDetail: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: ADD_AMENITY,
            onPending: (state, action) => {
                return { ...state, amenityAdd: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, amenityAdd: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: DELETE_AMENITY,
            onPending: (state, action) => {
                return { ...state, successDelete: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, successDelete: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: EDIT_AMENITY,
            onPending: (state, action) => {
                return { ...state, amenityEdit: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, amenityEdit: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
