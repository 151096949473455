import CommonComponent from "../../components/CommonComponent";

// module
import * as authActions from "../../../redux/modules/Auth";
import * as userInfoActions from "../../../redux/modules/UserInfo";

class UserInfoEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_USER_INFO") {
      this.onEditUserInfoResults(props);
    }
    if (this.state.requestType == "EDIT_MY_INFO") {
      this.onEditMyInfoResults(props);
    }
  }

  // redux에 user edit info 요청
  requestEditUser = (userInfoId, duty) => {
    this.setState({
      requestType: "EDIT_USER_INFO",
    });
    this.props.onEdit(userInfoId, duty);
  };
  // redux에 my edit info 요청
  requestEditMyInfo = (userInfoId, name, department) => {
    this.setState({
      requestType: "EDIT_MY_INFO",
    });
    this.props.onEditMyInfo(userInfoId, name, department);
  };

  // edit user info results
  onEditUserInfoResults = (props) => {
    if (props.userInfoEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.userInfoEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditUserInfoSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_change_duty"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditUserInfoSuccess = () => {
    // 페이지 이동
    this.props.history.push("/userInfoListPage");
  };

  // edit my info results
  onEditMyInfoResults = (props) => {
    if (props.myInfoEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.myInfoEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_user"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditMyInfoSuccess = () => {
    const { userInfoId } = this.state;
    // 페이지 이동
    this.props.history.push(`/userInfoDetailPage?userInfoId=${userInfoId}`);
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.sessionData,
      userToken: state.auth.sessionData,
      userInfoDetail: state.userInfo.userInfoDetail,
      userInfoEdit: state.userInfo.userInfoEdit,
      myInfoEdit: state.userInfo.myInfoEdit,
      failData: state.userInfo.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onEdit: (userInfoId, duty) => dispatch(userInfoActions.onEditUserInfo(userInfoId, duty)),
      onEditMyInfo: (userInfoId, name, department) => dispatch(userInfoActions.onEditMyInfo(userInfoId, name, department)),
    };
  };
}

export default UserInfoEditData;
