// PaymentListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import PaymentListData from "../List/PaymentListData";

//datePicker
import DatePicker from "react-datepicker";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/payment/paymentList.css";

// icon
import CalendarIcon from "../../img/data_icon.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class PaymentListView extends PaymentListData {
  constructor(props) {
    super(props);

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      hotelId: "",
      userName: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempSearch: "",
      tempHotelId: "",
      tempUserName: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
          // Date 설정
      const startDate = new Date();
      const yesterday = startDate.getDate() - 1;
      startDate.setHours(0, 0, 0, 0);
      startDate.setDate(yesterday);

      const endDate = new Date();
      endDate.setHours(23, 59, 59, 0);
      this.setState(
        {
          startDate: startDate, // startDate를 어제로 설정
          endDate: endDate,
          tempStartDate: startDate,
          tempEndDate: endDate,
        },
        () => {
          this.requestGetPaymentList(this.state.pageNo, this.formatDateISO(startDate), this.formatDateISO(endDate), this.state.hotelId, this.state.userName, this.state.search);
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const { history, location } = this.props;
    let value = "";

    if (stateName == "tempStartDate" || stateName == "tempEndDate") {
      value = e;
      history.replace(location.pathname, this.state);
    } else {
      value = e.target.value;
    }

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        hotelId: this.state.tempHotelId,
        userName: this.state.tempUserName,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetPaymentList(this.state.pageNo, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate), this.state.hotelId, this.state.userName, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          hotelId: this.state.tempHotelId,
          userName: this.state.tempUserName,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetPaymentList(this.state.pageNo, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate), this.state.hotelId, this.state.userName, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState(
      {
        isShowAllLoading: true,
        tempSearch: this.state.search,
        tempHotelId: this.state.hotelId,
        tempUserName: this.state.userName,
        tempStartDate: this.state.startDate,
        tempEndDate: this.state.endDate,
      },
      () => {
        this.requestGetPaymentList(pageNo, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate), this.state.hotelId, this.state.userName, this.state.search);
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkPaymentDetail(id);
    }
  }

  //파람값 url로 전달
  linkPaymentDetail = (paymentId) => {
    this.props.history.push(`/paymentDetailPage?paymentId=${paymentId}`);
  };

  //insert
  linkPaymentAdd = () => {
    this.props.history.push("/paymentAddPage");
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if(!date || date == "") return;
    return date.toISOString();
  };

  render() {
    let count;
    let paymentList = [];
    if (this.props.paymentList && this.props.paymentList?.success == true) {
      paymentList = this.props.paymentList.results.rows;
      count = this.props.paymentList.results.count;
    }
    
    return (
      <div className="paymentList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="payment_list_container">
          <div className="payment_list_container_inner">
            <div className="title" data-detect="admin_payment_list_title">
              PAYMENT LIST
            </div>
            <div className="period_container">
              <div className="period_start">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  shouldCloseOnSelect
                  selected={this.state.tempStartDate}
                  onChange={(e) => this.onChangeValue(e, "tempStartDate")}
                  id="termStartPicker"
                  name="startDate"
                />
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  onClick={() => {
                    document.getElementById("termStartPicker").click();
                  }}
                />
              </div>
              <div className="period_line"></div>
              <div className="period_end">
                <DatePicker dateFormat="yyyy-MM-dd" shouldCloseOnSelect selected={this.state.tempEndDate} onChange={(e) => this.onChangeValue(e, "tempEndDate")} id="termEndPicker" />
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  onClick={() => {
                    document.getElementById("termEndPicker").click();
                  }}
                />
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search search_by_id">
                    <input
                      type="search"
                      id="search_input_hotel_id"
                      data-detect-placeholder="admin_payment_list_placeholder_hotel_id"
                      placeholder="Hotel ID"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempHotelId")}
                      value={this.state.tempHotelId}
                    />
                  </div>
                  <div className="search search_by_id">
                    <input
                      type="search"
                      id="search_input_user_name"
                      data-detect-placeholder="admin_payment_list_placeholder_user_name"
                      placeholder="User Name"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempUserName")}
                      value={this.state.tempUserName}
                    />
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_payment_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_payment_list_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_reservation_id">
                      Reservation ID
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_user_name">
                      User Name
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_long_info">
                      Long info
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_amount">
                      Amount
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_unit">
                      Unit
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_pg_status_code">
                      PG status code
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_pg_status_message">
                      PG status message
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_hotel_id">
                      Hotel ID
                    </div>
                    <div className="cell" data-detect="admin_payment_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {paymentList.length > 0 ? (
                    paymentList.map((payment, index) => (
                      <div className="row tbodyRow" key={payment.id}>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.id}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.reservationId}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.user.gender + payment.user.firstName + payment.user.lastName}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.longInfo}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.pgAmount || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.unit}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.pgStatusCode || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.pgStatusMessage || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment.hotel.id}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(payment.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {payment && formatDateTimeSlash(payment && payment.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="hotel_payment_history_log_list_guide_empty"></div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = PaymentListData.mapStateToProps;
let mapDispatchToProps = PaymentListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentListView));
