import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import AppDetailData from "../Detail/AppDetailData";



//css
import "../../css/app/appDetail.css";
import "../../css/common.css";
import { formatDateTimeDot } from '../../common/Utils';

class AppDetailView extends AppDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      appId: "",
      region: "",
      country: "",
      city: "",
    };
  }

  // 첫 실행시 URL에서 appId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const appId = params.get("appId");
    this.setState({
      appId: appId,
    });
    this.requestGetAppOne(appId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkAppEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const appId = params.get("appId");
    this.props.history.push(`/appEditPage?appId=${appId}`);
  };

  render() {
    let appDetail = [];

    if (this.props.appDetail != null && this.props.appDetail?.success == true) {
      appDetail = this.props.appDetail.results;
    }

    return (
      <div className="appDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="application_user_detail_container">
          <div className="application_user_detail_inner">
            <div
              className="title_container"
              data-detect="admin_application_user_detail_title"
            >
              User Email
            </div>
            <div className="applciation_user">
              <div className="info_email">
                <span>{appDetail?.email??"-"}</span>
              </div>
              <div className="info_date">
                <span>{appDetail && formatDateTimeDot(appDetail.created_at)}</span>
              </div>
            </div>
            <div className="application_user_info_container">
              <div className="application_user_info_title_container">
                <div className="title_line">
                  <div className="application_user_info_title">
                    <span data-detect="admin_application_user_detail_label_user_information">
                      Application User Information
                    </span>
                  </div>
                </div>
              </div>
              <div className="user_info_container">
                <div className="user_info_table">
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_email"
                      >
                        E-mail
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.email??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_last_name"
                      >
                        Last Name
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.lastName??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_first_name"
                      >
                        First-Name
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.firstName??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_gender"
                      >
                        Gender
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.gender??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_alternative_first_name"
                      >
                        Alternative First Name
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.alternativeFirstName??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_alternative_last_name"
                      >
                        Alternative Last Name
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.alternativeLastName??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_member_type"
                      >
                        Member Type
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.memberType??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_backup_email"
                      >
                        Backup Email
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.backupEmail??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_push_token"
                      >
                        Push Token
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.pushToken??"-"}
                        </div>
                      </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_os_type"
                      >
                        OS Type
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.osType??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_language_code"
                      >
                        Language Code
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.languageCode??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_mail_confirmed_at"
                      >
                        Email Confirmed At
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.mailConfimedAt??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_updated_at"
                      >
                        Updated At
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.updated_at??"-"}
                        </div>
                    </div>
                  </div>
                  <div className="application_user_info_row">
                    <div className="user_info_cell index">
                      <div
                        className="applictin_user_sub_title_container"
                        data-detect="admin_application_user_detail_user_info_date"
                      >
                        Date
                      </div>
                    </div>
                    <div className="user_info_cell content">
                        <div className="user_info_content">
                          {appDetail?.created_at??"-"}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_hotel_detail_button_go_to_list"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AppDetailData.mapStateToProps;
let mapDispatchToProps = AppDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppDetailView)
);
