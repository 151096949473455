import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as userInfoApis from "../lib/userInfo_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const USER_INFO_GET_ALL = "userInfo/USER_INFO_GET_ALL";
const USER_INFO_GET_ONE = "userInfo/USER_INFO_GET_ONE";
const DELETE_USER_INFO = "userInfo/DELETE_USER_INFO";
const ADD_USER_INFO = "userInfo/ADD_USER_INFO";
const EDIT_USER_INFO = "userInfo/EDIT_USER_INFO";
const EDIT_MY_INFO = "userInfo/EDIT_MY_INFO";

// 액션 생성자
export const onGetAllUserInfo = createAction(USER_INFO_GET_ALL, userInfoApis.onGetAllUserInfo);
export const onGetUserInfoOne = createAction(USER_INFO_GET_ONE, userInfoApis.onGetUserInfoOne);
export const onAdduserInfo = createAction(ADD_USER_INFO, userInfoApis.onAdduserInfo);
export const onEditUserInfo = createAction(EDIT_USER_INFO, userInfoApis.onEditUserInfo);
export const onEditMyInfo = createAction(EDIT_MY_INFO, userInfoApis.onEditMyInfo);
export const onDeleteUserInfo = createAction(DELETE_USER_INFO, userInfoApis.onDeleteUserInfo);

// 초기 상태
const initialState = {
    userInfoList: [],
    userInfoAdd: null,
    userInfoDetail: null,
    userInfoEdit: null,
    myInfoEdit: null,
    successDelete: null,
    failData: null,
};

export default handleActions(
    {
        ...pender({
            type: USER_INFO_GET_ALL,
            onPending: (state, action) => {
                return { ...state, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, userInfoList: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, userInfoList: [], failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: USER_INFO_GET_ONE,
            onPending: (state, action) => {
                return { ...state, userInfoDetail: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, userInfoDetail: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: ADD_USER_INFO,
            onPending: (state, action) => {
                return { ...state, userInfoAdd: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, userInfoAdd: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: DELETE_USER_INFO,
            onPending: (state, action) => {
                return { ...state, successDelete: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, successDelete: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: EDIT_USER_INFO,
            onPending: (state, action) => {
                return { ...state, userInfoEdit: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, userInfoEdit: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: EDIT_MY_INFO,
            onPending: (state, action) => {
                return { ...state, myInfoEdit: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, myInfoEdit: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
