import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import TermsGroupEditData from "../Edit/TermsGroupEditData";

//css
import "../../../css/common.css";
import "../../../css/termsAndCondition/TermsGroup/TermsGroupEdit.css";

// utils 
import { formatDateTimeDot } from "../../../common/Utils";

class TermsGroupEditView extends TermsGroupEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      termsGroupId: "",
      title: "",
      countryCode: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const termsGroupId = params.get("termsGroupId");
    if (this.props.termsGroupDetail != null && this.props.termsGroupDetail.success) {
      this.setState({
        title: this.props.termsGroupDetail.results.title, //default값 설정
        countryCode: this.props.termsGroupDetail.results.countryCode,
        termsGroupId: termsGroupId,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change title
  onChangeTitle = (e) => {
    this.setState({ title: e.target.value }, () => {
      this.onCheckValidation();
    });
  };

  // change countryCode
  onChangeCountryCode = (e) => {
    this.setState({ countryCode: e.target.value }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("title", "admin_terms_group_edit_validation_title") && this.confirmField("countryCode", "admin_terms_group_edit_validation_country_code")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };



  render() {
    let termsGroupDetail = null;

    if (this.props.termsGroupDetail != null && this.props.termsGroupDetail?.success == true) {
      termsGroupDetail = this.props.termsGroupDetail.results;
    }

    return (
      <div className="termsGroupEdit">
        {this.state.isShowAllLoading && <Loading />}
        {termsGroupDetail !== null && (
          <div className="termsGroupinfo_detail_container">
            <div className="termsGroupinfo_detail_container_inner">
              <div className="title_container">
                <div className="main_text">
                  <p data-detect="admin_terms_group_detail_title">TERMS GROUP INFORMATIONP</p>
                </div>
                <div className="sub_text">
                  <p className="message" data-detect={this.state.errorMessage}></p>
                </div>
              </div>
              <div className="termsGroupinfo_container">
                <div className="detail_table">
                  <div className="detail_tbody">
                    <div className="termsGroup_info">
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_terms_group_edit_label_title">
                          Title
                        </div>
                        <div className="detail_cell content termsGroup_name">
                          <input type="text" value={this.state.title} onChange={this.onChangeTitle} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_terms_group_edit_label_country_code">
                          Country Code
                        </div>
                        <div className="detail_cell content termsGroup_name">
                          <input type="text" value={this.state.countryCode} onChange={this.onChangeCountryCode} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_terms_group_edit_label_created_at">
                          Created At
                        </div>
                        <div className="detail_cell content termsGroup_name">{termsGroupDetail && formatDateTimeDot(termsGroupDetail.created_at)}</div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_terms_group_edit_label_updated_at">
                          Updated At
                        </div>
                        <div className="detail_cell content termsGroup_name">{termsGroupDetail && formatDateTimeDot(termsGroupDetail.updated_at)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button_form_container">
                <div className="cancel_btn_container">
                  <button className="cancel_btn" onClick={this.cancelBtn} data-detect="admin_terms_group_edit_button_cancel"></button>
                </div>
                <div className="ok_btn_container">
                  <button className="ok_btn" onClick={this.onSubmit} data-detect="admin_terms_group_edit_button_ok" disabled={!this.state.isCheckValidation}></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let mapStateToProps = TermsGroupEditData.mapStateToProps;
let mapDispatchToProps = TermsGroupEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsGroupEditView));
