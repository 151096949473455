import CommonComponent from "../../components/CommonComponent";

// module
import * as amenityActions from "../../../redux/modules/Amenity";

class AmenityDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_AMENITY_ONE") {
      this.onGetAmenityOneResults(props);
    }
  }

  // redux에 get amenity Detail 요청
  requestGetAmenityOne = (amenityId) => {
    this.setState({
      requestType: "GET_AMENITY_ONE",
    });
    this.props.onGetOne(amenityId);
  };

  // get amenity One results
  onGetAmenityOneResults = (props) => {
    if (props.amenityDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.amenityDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      amenityDetail: state.amenity.amenityDetail,
      failData: state.amenity.failData,
      successDelete: state.amenity.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (amenityId) =>
        dispatch(amenityActions.onGetOneAmenity(amenityId)),
    };
  };
}

export default AmenityDetailData;
