import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import ReservationListData from "./ReservationListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/statistics/reservationList.css";

// date utils
import { formatDateTimeSlash } from "../../common/Utils";

class ReservationListView extends ReservationListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      hotelId: "",
      date: "",
      isMoved: false,
    };
  }

  /* ------------------------------------------------------ 생명주기 ------------------------------------------------------ */
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const hotelId = params.get("hotelId");
    const date = params.get("date");
    const type = params.get("type");
    this.setState({
      hotelId: hotelId,
      date: date,
      type: type,
    });

    const { location } = this.props;
    if (location.state && this.props.history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetReservationList(this.state.pageNo, hotelId, date, type);
      this.props.history.replace(location.pathname, this.state);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- Event Handler ------------------------------------------------- */
  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const value = e.target.value;

    this.setState({
      [stateName]: value,
    });
  };

  // page 이동
  handlePageChange = (pageNo) => {
    const { history, location } = this.props;
    this.setState(
      {
        pageNo: pageNo,
        isShowAllLoading: true,
      },
      () => {
        history.replace(location.pathname, {
          pageNo: this.state.pageNo,
        });
        this.requestGetReservationList(pageNo, this.state.hotelId, this.state.date, this.state.type);
      }
    );
  };

  handleClickSearchButton = (e) => {
    const { history, location } = this.props;
    this.setState({
      isShowAllLoading: true,
    });
    history.replace(location.pathname, {
      selectedOption: this.state.selectedOption,
      reservationStatus: this.state.reservationStatus,
    });
    this.requestGetReservationList(1, this.state.hotelId, this.state.date, this.state.type);
  };

  // search 엔터키
  handleClickKeyUpButton = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState({
        isShowAllLoading: true,
      });
      history.replace(location.pathname, {
        selectedOption: this.state.selectedOption,
        reservationStatus: this.state.reservationStatus,
      });
      this.requestGetReservationList(1, this.state.hotelId, this.state.date, this.state.type);
    }
  };

  // reservation 선택항목 삭제하기
  handleClickCancelReservation = (reservationId, confirmationNo) => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_booking_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.setState(
          {
            isShowAllLoading: true,
            confirmationNo: confirmationNo,
          },
          () => this.requestDeleteReservation(reservationId, confirmationNo)
        );
      }, // ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      } // cancel 버튼시
    );
  };

  handleClickSyncButton = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetSync();
  };

  // 파람값 url로 전달
  linkReservationDetail = (hotelId, reservationId) => {
    const reservationDetailUrl = `/reservationDetailpage?hotelId=${hotelId}&reservationId=${reservationId}`;
    this.props.history.push(reservationDetailUrl, this.state);
  };

  handleMouseMove = () => {
    const { isMoved } = this.state;
    if (isMoved) return;

    this.setState({
      isMoved: true,
    });
  };

  handleMouseDown = () => {
    this.setState({
      isMoved: false,
    });
  };

  handleMouseUp = (hotelId, reservationId) => {
    const { isMoved } = this.state;
    if (isMoved) {
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkReservationDetail(hotelId, reservationId);
    }
  };

  // statistics list page로 이동
  linkStatisticsList = () => {
    this.props.history.goBack();
  };
  /* ------------------------------------------------------- etc ------------------------------------------------------ */
  // 예약상태에 따라 취소버튼에 클래스명 추가
  activateCancelButton = (status) => {
    if (status == "DUEIN" || status == "RESERVED" || status == "CHANGED") {
      return "";
    } else {
      return "deactivate";
    }
  };

  render() {
    let count;
    let reservationList = [];
    let timeZone = null;

    if (this.props.reservationList != null && this.props.reservationList?.success == true) {
      count = this.props.reservationList?.results?.count;
      reservationList = this.props.reservationList?.results?.rows;
    }
    // if (this.props.hotelSetting != null && this.props.hotelSetting?.success == true) {
    //   timeZone = this.props.hotelSetting?.results?.timeZone;
    // }

    return (
      <div className="reservationList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="reservation_list_container">
          <div className="reservation_list_container_inner">
            <div className="title" data-detect="hotel_reservation_reservation_list_title">
              RESERVATION LIST
            </div>
            {/* <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select
                      className="search_select"
                      id="reservationlist_select"
                      name="reservationlist_select"
                      value={this.state.reservationStatus}
                      onChange={(e) => this.onChangeValue(e, "reservationStatus")}
                      style={{
                        color: this.state.isStatusSelected ? "#fff" : "#888",
                      }}
                    >
                      <option data-detect="hotel_reservation_reservation_list_placeholder_select_box_reservation_status" value="" disabled>
                        Reservation Status
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_inhouse" value="INHOUSE">
                        INHOUSE
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_reserved" value="RESERVED">
                        RESERVED
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_changed" value="CHANGED">
                        CHANGED
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_canceled" value="CANCELED">
                        CANCELED
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_no_show" value="NOSHOW">
                        NOSHOW
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_checked_out" value="CHECKEDOUT">
                        CHECKEDOUT
                      </option>
                    </select>
                  </div>
                  <div className="find">
                    <select
                      className="search_select"
                      id="reservationlist_select"
                      name="reservationlist_select"
                      value={this.state.selectedOption}
                      onChange={(e) => this.onChangeValue(e, "selectedOption")}
                      style={{
                        color: this.state.isOptionSelected ? "#fff" : "#888",
                      }}
                    >
                      <option data-detect="hotel_reservation_reservation_list_placeholder_select_box_reservation" value="" disabled>
                        Reservation
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_confirmation_no" value="confirmationNo">
                        Confirmation No.
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_room_no" value="roomNo">
                        Room No.
                      </option>
                      <option data-detect="hotel_reservation_reservation_list_select_name" value="content">
                        Name
                      </option>
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="hotel_reservation_reservation_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleClickKeyUpButton}
                      value={this.state.search}
                      onChange={(e) => this.onChangeValue(e, "search")}
                    />
                    <button type="button" className="search_btn" onClick={this.handleClickSearchButton}></button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="reservation_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="hotel_reservation_reservation_list_label_no">
                      No.
                    </div>
                    <div className="cell" data-detect="hotel_reservation_reservation_list_label_confirmation_no">
                      Confirmation No.
                    </div>
                    <div className="cell" data-detect="hotel_reservation_future_booking_list_label_booking_owner">
                      Booking Owner
                    </div>
                    <div className="cell" data-detect="hotel_reservation_reservation_list_label_main_guest">
                      Main Guest
                    </div>
                    <div className="cell" data-detect="hotel_reservation_reservation_list_label_status">
                      Status
                    </div>
                    <div className="cell" data-detect="hotel_reservation_reservation_list_label_checkin_date">
                      Checkin Date
                    </div>
                    <div className="cell" data-detect="hotel_reservation_reservation_list_label_checkout_date">
                      Checkout Date
                    </div>
                    <div className="cell" data-detect="hotel_reservation_future_booking_list_label_occupy">
                      Occupy
                    </div>
                    <div className="cell" data-detect="hotel_reservation_reservation_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {reservationList && reservationList.length > 0 ? (
                    reservationList.map((reservation, index) => (
                      <div className="row tbodyRow" key={reservation.id}>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          <div className="eachIndexNo">
                            <span>{index + 1 + (this.state.pageNo - 1) * 10}</span>
                          </div>
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {reservation.confirmationNo}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>{`${
                          reservation.bookingUser?.gender ?? ""
                        } ${reservation.bookingUser?.firstName ?? "(Imported)"} ${reservation.bookingUser?.lastName ?? ""}`}</div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {reservation.hotelReservationOwner[0] != null
                            ? `${reservation.hotelReservationOwner[0]?.title ?? ""} ${reservation.hotelReservationOwner[0]?.firstName ?? ""} ${reservation.hotelReservationOwner[0]?.lastName ?? ""}`
                            : "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {reservation.reservationStatus}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(reservation.checkInDate)}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(reservation.checkOutDate)}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {reservation.isOccupy}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(reservation.hotelId, reservation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(reservation.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="hotel_reservation_reservation_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
              {/* 버튼 */}
              <div className="button_container">
                <div className="gotolist_btn_container">
                  <button className="gotolist_btn" onClick={this.linkStatisticsList} data-detect="admin_statistics_list_button_go_to_statistics">
                    Go to Statistics
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
let mapStateToProps = ReservationListData.mapStateToProps;
let mapDispatchToProps = ReservationListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReservationListView));
