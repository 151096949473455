import React from "react";
import { connect } from "react-redux";
import StaffTransferLogDetailInfoData from "../LogDetail/staffTransferLogHistoryDetailInfoData";


//css
import "../../../css/common.css";
import "../../../css/transfer/staffTransferLogHistory/staffTransferLogHistoryDetailInfo.css";

// utils
import { changeEmptyValueToDash, formatDateTimeDot, prettyPrint } from "../../../common/Utils";

class StaffTransferLogInfoDetailView extends StaffTransferLogDetailInfoData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      staffTransferId: "",
    };
  }

  // 첫 실행시 URL에서 staffTransferId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const staffTransferId = params.get("staffTransferId");
    this.setState({
      staffTransferId: staffTransferId,
      isShowAllLoading: false,
    });
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }


  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let staffTransferLogHistoryDetail = null;

    if (this.props.staffTransferLogHistoryDetail != null && this.props.staffTransferLogHistoryDetail?.success == true) {
      const staffTransferId = this.state.staffTransferId;
      const rows = this.props.staffTransferLogHistoryDetail.results.rows;

      const filteredItems = rows.filter((item) => item.id == staffTransferId);

      if (filteredItems.length > 0) {
        staffTransferLogHistoryDetail = changeEmptyValueToDash(filteredItems[0]);
      }
    }
    return (
      <div className="staffTransferLogInfoDetail">
        <div className="staffTransfer_detail_container">
          <div className="staffTransfer_detail_innder">
            <div className="title" data-detect="admin_transfer_log_detail_title">
              Transfer Detail
            </div>
            <div className="staffTransfer_container">
              <div className="staffTransfer_title_container"></div>
              <div className="staffTransfer_info_container">
                <div className="staffTransfer_info_table">
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_transfer_id"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span>{staffTransferLogHistoryDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_type"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {staffTransferLogHistoryDetail?.type??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_user"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {staffTransferLogHistoryDetail?.user??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_hotel_id"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {staffTransferLogHistoryDetail?.hotelId??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_user_email"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {staffTransferLogHistoryDetail?.userEmail??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_list_label_path"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {staffTransferLogHistoryDetail?.path??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_status"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {staffTransferLogHistoryDetail?.status??"-"}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_header"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span className="format">{prettyPrint(staffTransferLogHistoryDetail?.header)}</span>

                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_param"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span className="format">{prettyPrint(staffTransferLogHistoryDetail?.param)}</span>

                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_body"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span className="format">{prettyPrint(staffTransferLogHistoryDetail?.body)}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_soap"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span className="format">{prettyPrint(staffTransferLogHistoryDetail?.soap)}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_session"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span className="format">{prettyPrint(staffTransferLogHistoryDetail?.session)}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_token"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span className="format">{prettyPrint(staffTransferLogHistoryDetail?.token)}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_created_at"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {(staffTransferLogHistoryDetail ? formatDateTimeDot(staffTransferLogHistoryDetail?.created_at) : "-")}</span>
                    </div>
                  </div>
                  <div className="staffTransfer_info_row">
                    <div className="staffTransfer_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_updated_at"></span>
                    </div>
                    <div className="staffTransfer_info_cell content">
                      <span> {(staffTransferLogHistoryDetail ? formatDateTimeDot(staffTransferLogHistoryDetail?.updated_at) : "-")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_transaction_log_detail_button_go_to_list"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = StaffTransferLogDetailInfoData.mapStateToProps;
let mapDispatchToProps = StaffTransferLogDetailInfoData.mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StaffTransferLogInfoDetailView);
