import CommonComponent from "../../../components/CommonComponent";

// module
import * as termsAndConditionActions from "../../../../redux/modules/TermsAndCondition";

class TermsDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_TERMS_ONE") {

      this.onGetTermsOneResults(props);
    }
  }

  // redux에 get terms Detail 요청
  requestGetTermsOne = (termsId) => {
    this.setState({
      requestType: "GET_TERMS_ONE",
    });
    this.props.onGetOne(termsId);
  };

  // get terms One results
  onGetTermsOneResults = (props) => {
    if (props.termsDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.termsDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_hotel_detail"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      termsDetail: state.termsAndCondition.termsDetail,
      failData: state.termsAndCondition.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (termsId) => dispatch(termsAndConditionActions.onGetOneTerms(termsId)),
    };
  };
}

export default TermsDetailData;
