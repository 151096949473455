import CommonComponent from "../../components/CommonComponent";

// module
import * as facilityImageActions from "../../../redux/modules/FacilityImage";



class FacilityImageEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_FACILITY_IMAGE") {
      this.onEditFacilityImageOneResults(props);
    }
  }

  // redux에 edit facilityImage 요청
  requestEditFacilityImage = (description, iconFile, iconText, facilityImageId) => {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("hotelFacilityImageFile", iconFile);
    formData.append("imageText", iconText);

    this.setState({
      requestType: "EDIT_FACILITY_IMAGE",
    });
    this.props.onEdit(formData, facilityImageId);
  };

  onEditFacilityImageOneResults(props) {
    if (props.facilityImageEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.facilityImageEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_facility_img"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  }

  onEditSuccess = () => {
    const facilityImageId = this.state.facilityImageId;
    // 페이지 이동
    this.props.history.push(`/facilityImageDetailPage?facilityImageId=${facilityImageId}`);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      // "Are you sure you want to delete it?",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editFacilityImage();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editFacilityImage = () => {
    this.requestEditFacilityImage(this.state.description, this.state.iconFile, this.state.iconText, this.state.facilityImageId);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/facilityImageListPage");
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      facilityImageDetail: state.facilityImage.facilityImageDetail,
      facilityImageEdit: state.facilityImage.facilityImageEdit,
      failData: state.facilityImage.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (formData, facilityImageId) => dispatch(facilityImageActions.onEditFacilityImage(formData, facilityImageId)),
    };
  };
}

export default FacilityImageEditData;
