import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import TransferLogDetailData from "../Detail/TransferLogDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/transferLog/transferLogDetail.css";

// utils
import { formatDateTimeDot, prettyPrint } from "../../../common/Utils";

class TransferLogDetailView extends TransferLogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      transferLogId: "",
    };
  }

  // 첫 실행시 URL에서 transferLogId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const transferLogId = params.get("transferLogId");
    this.setState({
      transferLogId: transferLogId,
    });
    this.requestGetTransferLogDetail(transferLogId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let transferLogDetail = null;

    if (this.props.transferLogDetail != null && this.props.transferLogDetail?.success == true) {
      transferLogDetail = this.props.transferLogDetail.results;
    }

    return (
      <div className="transferLogDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="transferLog_detail_container">
          <div className="transferLog_detail_innder">
            <div className="title" data-detect="admin_transfer_log_detail_title">
              Transfer Detail
            </div>
            <div className="transferLog_container">
              <div className="transferLog_title_container"></div>
              <div className="transferLog_info_container">
                <div className="transferLog_info_table">
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_transfer_id">Transfer Id</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span>{transferLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_type">Type</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span> {transferLogDetail?.type??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_user">User</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span> {transferLogDetail?.user??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_hotel_id">Hotel Id</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span> {transferLogDetail?.hotelId??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_user_email">User Email</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span> {transferLogDetail?.userEmail??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_path">Path</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span>{transferLogDetail?.path??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_status">Status</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span>{transferLogDetail?.status??"-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_header">Header</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span className="format">{prettyPrint(transferLogDetail?.header)}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_param">Param</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span className="format">{prettyPrint(transferLogDetail?.param)}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_body">Body</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span className="format">{prettyPrint(transferLogDetail?.body)}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_session">Session</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span className="format">{prettyPrint(transferLogDetail?.session)}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_token">Token</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span className="format">{prettyPrint(transferLogDetail?.token)}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_created_at">Created At</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span>{transferLogDetail ? formatDateTimeDot(transferLogDetail.created_at) : "-"}</span>
                    </div>
                  </div>
                  <div className="transferLog_info_row">
                    <div className="transferLog_info_cell index">
                      <span data-detect="admin_transfer_log_detail_label_updated_at">Updated At</span>
                    </div>
                    <div className="transferLog_info_cell content">
                      <span>{transferLogDetail ? formatDateTimeDot(transferLogDetail.updated_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TransferLogDetailData.mapStateToProps;
let mapDispatchToProps = TransferLogDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferLogDetailView));
