import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import CountryEditData from "../Edit/CountryEditData";

//css
import "../../../css/common.css";
import "../../../css/location/country/countryEdit.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class CountryEditView extends CountryEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      country: "",
      countryId: "",
      region: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    if (this.props.countryDetail != null && this.props.countryDetail.success) {
      this.setState({
        country: this.props.countryDetail.results.name, //default값 설정
        countryId: this.props.countryDetail.results.id,
        region: this.props.countryDetail.results.hotelRegion.name,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change country
  onCountryChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ country: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("country", "admin_country_edit_validation_country")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };



  render() {
    let countryDetail = [];

    if (this.props.countryDetail != null && this.props.countryDetail?.success == true) {
      countryDetail = this.props.countryDetail.results;
    }

    return (
      <div className="countryEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="countryinfo_detail_container">
          <div className="countryinfo_detail_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_country_edit_title">COUNTRY</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="countryinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="country_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_country_edit_label_region">
                        Region
                      </div>
                      <div className="detail_cell content region_name">
                        <input style={{ borderBottom: "none" }} type="text" value={this.state.region} placeholder="Region Name" readOnly />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_country_edit_label_country">
                        Country
                      </div>
                      <div className="detail_cell content country_name" style={{ color: "white" }}>
                        <input
                          type="text"
                          value={this.state.country}
                          onChange={this.onCountryChange}
                          placeholder="Country Name"
                          data-detect-placeholder="admin_country_edit_placeholder_country_name"
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_list_label_created_at">
                        Created At
                      </div>
                      <div className="detail_cell content country_name" style={{ color: "white" }}>
                        {countryDetail && formatDateTimeDot(countryDetail.created_at)}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content country_name" style={{ color: "white" }}>
                        {countryDetail && formatDateTimeDot(countryDetail.updated_at)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.cancelBtn} data-detect="common_cancled"></button>
              </div>
              <button className="edit_btn" onClick={this.onSubmit} data-detect="common_confirmed" disabled={!this.state.isCheckValidation}></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CountryEditData.mapStateToProps;
let mapDispatchToProps = CountryEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryEditView));
