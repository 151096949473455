import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../typography/typography.css";

//image
import NotoSansBold from "../../img/NotoSans_text_Bold.svg";
import NotoSansMedium from "../../img/NotoSans_text_Medium.svg";
import NotoSansRegular from "../../img/NotoSans_text_Regular.svg";

class Typography extends Component {
    render() {
        return (
            <div className='typographyViewPage'>
                <div className='inner'>
                    <Container>
                        <Row>
                            <div className='title'>
                                <div className='myConect_menu_title'>Typography </div>
                            </div>
                        </Row>
                        <div className='line'></div>

                        <Row>
                            <div className='title_padding'></div>
                            <div className='sub_title'>
                                <b>Font</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row ><div><b>Noto Sans</b></div></Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <Row><div  >
                                    <img className='font_image'
                                        src={NotoSansRegular}
                                        alt="NotoSans Text Regular"
                                    />
                                </div></Row>
                                <Row>
                                    <div className='font_whiteSpace'>
                                        <div className='myConect_font_regular'>Regular</div>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row><div>
                                    <img className='font_image'
                                        src={NotoSansMedium}
                                        alt="NotoSans Text Medium"
                                    />
                                </div>
                                </Row>
                                <Row>
                                    <div className='font_whiteSpace'>
                                        <div className='myConect_font_medium'>Medium</div>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row><div>
                                    <img className='font_image'
                                        src={NotoSansBold}
                                        alt="NotoSans Text Bold"
                                    />
                                </div>
                                </Row>
                                <Row>
                                    <div className='font_whiteSpace'>
                                        <div className='myConect_font_bold'>Bold</div>
                                    </div>
                                </Row>
                            </Col>

                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>Font Info</b></div>
                        </Row>
                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={2}><b>Type Style</b></Col>
                            <Col sm={2}><b>Category</b></Col>
                            <Col sm={2}><b>Weight</b></Col>
                            <Col sm={2}><b>Size</b></Col>
                            <Col sm={2}><b>Sample Text</b></Col>
                        </Row>

                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Title</div></Col>
                            <Col sm={2}><div className='myConect_title'>제목</div></Col>
                            <Col sm={2}><div className='myConect_title'>Medium</div></Col>
                            <Col sm={2}><div className='myConect_title'>20</div></Col>
                            <Col sm={2}><div className='myConect_title'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Menu</div></Col>
                            <Col sm={2}><div className='myConect_menu'>메뉴</div></Col>
                            <Col sm={2}><div className='myConect_menu'>Medium</div></Col>
                            <Col sm={2}><div className='myConect_menu'>20</div></Col>
                            <Col sm={2}><div className='myConect_menu'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Menu Title</div></Col>
                            <Col sm={2}><div className='myConect_menu_title'>메뉴 제목</div></Col>
                            <Col sm={2}><div className='myConect_menu_title'>Bold</div></Col>
                            <Col sm={2}><div className='myConect_menu_title'>17</div></Col>
                            <Col sm={2}><div className='myConect_menu_title'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Sub Menu</div></Col>
                            <Col sm={2}><div className='myConect_subMenu'>소메뉴</div></Col>
                            <Col sm={2}><div className='myConect_subMenu'>Medium</div></Col>
                            <Col sm={2}><div className='myConect_subMenu'>14</div></Col>
                            <Col sm={2}><div className='myConect_subMenu'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>SubMenu Title</div></Col>
                            <Col sm={2}><div className='myConect_subMenu_title'>소메뉴 제목</div></Col>
                            <Col sm={2}><div className='myConect_subMenu_title'>Medium</div></Col>
                            <Col sm={2}><div className='myConect_subMenu_title'>14</div></Col>
                            <Col sm={2}><div className='myConect_subMenu_title'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Content</div></Col>
                            <Col sm={2}><div className='myConect_content'>본문</div></Col>
                            <Col sm={2}><div className='myConect_content'>Regular</div></Col>
                            <Col sm={2}><div className='myConect_content'>14</div></Col>
                            <Col sm={2}><div className='myConect_content'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Emphasizing Content</div></Col>
                            <Col sm={2}><div className='myConect_emphasi_content'>강조 내용</div></Col>
                            <Col sm={2}><div className='myConect_emphasi_content'>Bold</div></Col>
                            <Col sm={2}><div className='myConect_emphasi_content'>14</div></Col>
                            <Col sm={2}><div className='myConect_emphasi_content'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Sub Content</div></Col>
                            <Col sm={2}><div className='myConect_subConent'>보조 내용</div></Col>
                            <Col sm={2}><div className='myConect_subConent'>Regular</div></Col>
                            <Col sm={2}><div className='myConect_subConent'>12</div></Col>
                            <Col sm={2}><div className='myConect_subConent'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Number in List</div></Col>
                            <Col sm={2}><div className='myConect_number_in_list'>목록 숫자</div></Col>
                            <Col sm={2}><div className='myConect_number_in_list'>Medium</div></Col>
                            <Col sm={2}><div className='myConect_number_in_list'>10</div></Col>
                            <Col sm={2}><div className='myConect_number_in_list'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Button Title</div></Col>
                            <Col sm={2}><div className='myConect_buttton_title'>대제목</div></Col>
                            <Col sm={2}><div className='myConect_buttton_title'>Bold</div></Col>
                            <Col sm={2}><div className='myConect_buttton_title'>17</div></Col>
                            <Col sm={2}><div className='myConect_buttton_title'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Button SubTitle</div></Col>
                            <Col sm={2}><div className='myConect_buttton_subTitle'>소제목</div></Col>
                            <Col sm={2}><div className='myConect_buttton_subTitle'>Medium</div></Col>
                            <Col sm={2}><div className='myConect_buttton_subTitle'>14</div></Col>
                            <Col sm={2}><div className='myConect_buttton_subTitle'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Radio Button</div></Col>
                            <Col sm={2}><div className='myConect_radio_button'>라디오</div></Col>
                            <Col sm={2}><div className='myConect_radio_button'>Regular</div></Col>
                            <Col sm={2}><div className='myConect_radio_button'>14</div></Col>
                            <Col sm={2}><div className='myConect_radio_button'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Pagination Button</div></Col>
                            <Col sm={2}><div className='myConect_pagination_button'>페이지네이션</div></Col>
                            <Col sm={2}><div className='myConect_pagination_button'>Regular</div></Col>
                            <Col sm={2}><div className='myConect_pagination_button'>17</div></Col>
                            <Col sm={2}><div className='myConect_pagination_button'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>
                                <div className='disabled_color'>
                                    Disabled Button
                                </div>
                            </div>
                            </Col>
                            <Col sm={2}><div className='myConect_disabled_button'>비활성화</div></Col>
                            <Col sm={2}><div className='myConect_disabled_button'>Bold</div></Col>
                            <Col sm={2}><div className='myConect_disabled_button'>14</div></Col>
                            <Col sm={2}><div className='myConect_disabled_button'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <div className='myConect_button_primary'>
                                <Col sm={2}><div className='type_font_size'>Primary Yellow Button</div></Col>
                                <Col sm={2}><div className='myConect_primary_yellow_button'>강조 색상(노랑)</div></Col>
                                <Col sm={2}><div className='myConect_primary_yellow_button'>Regular</div></Col>
                                <Col sm={2}><div className='myConect_primary_yellow_button'>14</div></Col>
                                <Col sm={2}><div className='myConect_primary_yellow_button'>MyCONECT</div></Col>
                            </div>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}><div className='type_font_size'>Primary Black Button</div></Col>
                            <Col sm={2}><div className='myConect_primary_black_button'>강조 색상(검정)</div></Col>
                            <Col sm={2}><div className='myConect_primary_black_button'>Regular</div></Col>
                            <Col sm={2}><div className='myConect_primary_black_button'>14</div></Col>
                            <Col sm={2}><div className='myConect_primary_black_button'>MyCONECT</div></Col>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <div className='myConect_button_dropDown_box'>
                                <div className='dropDown_box_background'>
                                    <Col sm={2}><div className='type_font_size'>DropDown Box Button</div></Col>
                                    <Col sm={2}><div className='myConect_dropDownBox_button'>드롭박스</div></Col>
                                    <Col sm={2}><div className='myConect_dropDownBox_button'>Regular</div></Col>
                                    <Col sm={2}><div className='myConect_dropDownBox_button'>12</div></Col>
                                    <Col sm={2}><div className='myConect_dropDownBox_button'>MyCONECT</div></Col>
                                </div>
                            </div>
                        </Row>
                        <Row >
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <div className='myConect_text_response'>
                                <Col sm={2}><div className='type_font_size'>Text Response</div></Col>
                                <Col sm={2}><div className='myConect_body_status_response'>
                                    Response Text
                                </div></Col>
                                <Col sm={2}><div className='myConect_body_status_response'>Regular</div></Col>
                                <Col sm={2}><div className='myConect_body_status_response'>14</div></Col>
                                <Col sm={2}><div className='myConect_body_status_response'>MyCONECT</div></Col>
                            </div>
                        </Row>
                        <Row>
                            <div className='title_padding'></div>
                            <Col sm={2}></Col>
                            <div className='myConect_text_request'>
                                <Col sm={2}><div className='type_font_size'>Text Request</div></Col>
                                <Col sm={2}><div className='myConect_body_status_request'>Request Text</div></Col>
                                <Col sm={2}><div className='myConect_body_status_request'>Regular</div></Col>
                                <Col sm={2}><div className='myConect_body_status_request'>14</div></Col>
                                <Col sm={2}><div className='myConect_body_status_request'>MyCONECT</div></Col>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Typography;
