import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllUseKeyLogList(pageNo, keyLogStatus, search, startDate, endDate) {
  var hotelId = "";
  var email = "";

  if (keyLogStatus == "hotelId") {
    hotelId = search;
  }
  if (keyLogStatus == "email") {
    email = search;
  }

  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/useKeyLog/search?fromDate=" + startDate + "&endDate=" + endDate + "&email=" + email + "&hotelId=" + hotelId + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetUseKeyLogDetail(usekeyLogId) {
  return axiosInstance.get("/admin/api/usekeyLog/" + usekeyLogId);
}
