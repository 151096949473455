import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as paymentApis from "../lib/payment_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const PAYMENT_GET_ALL = "payment/PAYMENT_GET_ALL";
const PAYMENT_GET_ONE = "payment/PAYMENT_GET_ONE";
const GET_RESERVATION = "payment/GET_RESERVATION";
const GET_LOG_LIST = "payment/GET_LOG_LIST";
const GET_LOG_DETAIL = "payment/GET_LOG_DETAIL"
const ADD_PAYMENT = "payment/ADD_PAYMENT";
const DELETE_PAYMENT = "payment/DELETE_PAYMENT";
const EDIT_PAYMENT = "payment/EDIT_PAYMENT";
const COPY_PAYMENT = "payment/COPY_PAYMENT";

// 액션 생성자
export const onGetAll = createAction(PAYMENT_GET_ALL, paymentApis.onGetAll);
export const onGetOne = createAction(PAYMENT_GET_ONE, paymentApis.onGetOne);
export const onGetReservation = createAction(GET_RESERVATION, paymentApis.onGetReservation);
export const onGetLogList = createAction(GET_LOG_LIST, paymentApis.onGetLogList);
export const onGetLogDetail = createAction(GET_LOG_DETAIL, paymentApis.onGetLogDetail);

export const onAdd = createAction(ADD_PAYMENT, paymentApis.onAdd);
export const onDelete = createAction(DELETE_PAYMENT, paymentApis.onDelete);
export const onEdit = createAction(EDIT_PAYMENT, paymentApis.onEdit);
export const onCopy = createAction(COPY_PAYMENT, paymentApis.onCopy);

// 초기 상태
const initialState = {
  paymentList: [],
  paymentDetail: null,
  reservationDetail: null,
  logList: [],
  logDetail: null,
  paymentAdd: null,
  paymentEdit: null,
  paymentCopy: null,
  successDelete: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: PAYMENT_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, paymentList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: PAYMENT_GET_ONE,
      onPending: (state, action) => {
        return { ...state, paymentDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_RESERVATION,
      onPending: (state, action) => {
        return { ...state, reservationDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, reservationDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_LOG_LIST,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, logList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, logList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_LOG_DETAIL,
      onPending: (state, action) => {
        return { ...state, logDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, logDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_PAYMENT,
      onPending: (state, action) => {
        return { ...state, paymentAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_PAYMENT,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_PAYMENT,
      onPending: (state, action) => {
        return { ...state, paymentEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: COPY_PAYMENT,
      onPending: (state, action) => {
        return { ...state, paymentCopy: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentCopy: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
