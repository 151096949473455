import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import TransactionLogDetailData from "../Detail/transactionLogDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/transactionLog/transactionLogDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class TransactionLogDetailView extends TransactionLogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      transactionLogId: "",
    };
  }

  // 첫 실행시 URL에서 transactionLogId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const transactionLogId = params.get("transactionLogId");
    this.setState({
      transactionLogId: transactionLogId,
    });
    this.requestGetTransactionLogDetail(transactionLogId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let transactionLogDetail = null;

    if (
      this.props.transactionLogDetail != null &&
      this.props.transactionLogDetail?.success == true
    ) {
      transactionLogDetail = this.props.transactionLogDetail.results;
    }

    return (
      <div className="transactionLogDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="transactionLog_detail_container">
          <div className="transactionLog_detail_innder">
            <div
              className="title"
              data-detect="admin_transaction_log_detail_title"
            >
              TRANSACTION LOG DETAIL
            </div>

            <div className="transactionLog_container">
              <div className="transactionLog_title_container"></div>
              <div className="transactionLog_info_container">
                <div className="transactionLog_info_table">
                  <div className="transactionLog_info_row">
                    <div className="transactionLog_info_cell index">
                      <span data-detect="admin_transaction_log_detail_label_transaction_id">
                        Transaction ID
                      </span>
                    </div>
                    <div className="transactionLog_info_cell content">
                      <span>{transactionLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="transactionLog_info_row">
                    <div className="transactionLog_info_cell index">
                      <span data-detect="admin_transaction_log_detail_label_act_on_function">
                        Act on function
                      </span>
                    </div>
                    <div className="transactionLog_info_cell content">
                      <span>
                        {transactionLogDetail?.actOnFunction??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="transactionLog_info_row">
                    <div className="transactionLog_info_cell index">
                      <span data-detect="admin_transaction_log_detail_label_complete_action">
                        Complete action
                      </span>
                    </div>
                    <div className="transactionLog_info_cell content">
                      <span>
                        {transactionLogDetail?.completeAction??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="transactionLog_info_row">
                    <div className="transactionLog_info_cell index">
                      <span data-detect="admin_transaction_log_detail_label_rollback_reason">
                        Rollback reason
                      </span>
                    </div>
                    <div className="transactionLog_info_cell content">
                      <span>
                        {transactionLogDetail?.rollbackReason??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="transactionLog_info_row">
                    <div className="transactionLog_info_cell index">
                      <span data-detect="admin_transaction_log_detail_label_completed_at">
                        Completed at
                      </span>
                    </div>
                    <div className="transactionLog_info_cell content">
                      <span>
                        {(transactionLogDetail ?
                          formatDateTimeDot(
                            transactionLogDetail.completedAt
                          ) : "-")}
                      </span>
                    </div>
                  </div>
                  <div className="transactionLog_info_row">
                    <div className="transactionLog_info_cell index">
                      <span data-detect="admin_transaction_log_detail_label_created_at">
                        Created At
                      </span>
                    </div>
                    <div className="transactionLog_info_cell content">
                      <span>
                        {(transactionLogDetail ?
                          formatDateTimeDot(transactionLogDetail.created_at) : "-")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_transaction_log_detail_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TransactionLogDetailData.mapStateToProps;
let mapDispatchToProps = TransactionLogDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionLogDetailView)
);
