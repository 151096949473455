import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAll(pageNo, selectedOption, search) {
  let email = "";
  let name = "";

  if (selectedOption == 'email') {
    email = search
  } else if (selectedOption == 'name') {
    name = search
  }

  const offset = (pageNo - 1) * 10;

  return axiosInstance.get(
        "/admin/api/applicationUser/?email=" + email + '&name=' + name + '&offset=' + offset + '&limit=' + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetOne(appId) {

  return axiosInstance.get("/admin/api/applicationUser/" + appId);
}

//------------------------------- delete App ------------------------------------------------------//
export function onDelete(appId) {

  return axiosInstance.delete("/admin/api/applicationUser/" + appId);
}
