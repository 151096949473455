import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import serverUrl from "../../../react/common/ServerUrl";
import { Loading } from "../../components/Paths";
import AmenityDetailData from "../Detail/AmenityDetailData";



//css
import "../../css/amenity/amenityDetail.css";
import "../../css/common.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class AmenityDetailView extends AmenityDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      amenityId: "",
    };
  }

  // 첫 실행시 URL에서 amenityId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const amenityId = params.get("amenityId");
    this.setState({
      amenityId: amenityId,
    });
    this.requestGetAmenityOne(amenityId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkAmenityEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const amenityId = params.get("amenityId");
    this.props.history.push(`/amenityEditPage?amenityId=${amenityId}`);
  };


  render() {
    let amenityDetail = null;
    let imagePath = "";

    if (
      this.props.amenityDetail != null &&
      this.props.amenityDetail?.success == true
    ) {
      amenityDetail = this.props.amenityDetail.results;
      imagePath = this.props.amenityDetail.results.iconFileId;
    }

    return (
      <div className="amenityDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="amenityinfo_detail_container">
          <div className="amenityinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_amenity_detail_title"
            >
              AMENITY INFORMATION
            </div>
            <div className="amenityinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="amenity_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_amenity_detail_label_type"
                      >
                        Type
                      </div>
                      <div
                        className="detail_cell content amenity_type"
                        style={{ color: "white" }}
                      >
                        <span id="amenity_id">{amenityDetail?.type??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_amenity_detail_label_group"
                      >
                        Group
                      </div>
                      <div
                        className="detail_cell content amenity_group"
                        style={{ color: "white" }}
                      >
                        <span id="amenity_id">{amenityDetail?.group??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_amenity_detail_label_code"
                      >
                        Code
                      </div>
                      <div
                        className="detail_cell content amenity_code"
                        style={{ color: "white" }}
                      >
                        <span id="amenity_id">{amenityDetail?.code??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_amenity_detail_label_name"
                      >
                        Name
                      </div>
                      <div
                        className="detail_cell content amenity_name"
                        style={{ color: "white" }}
                      >
                        <span id="amenity_id">{amenityDetail?.name??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_amenity_detail_label_icon"
                      >
                        Icon
                      </div>
                      <div
                        className="detail_cell content amenity_icon"
                        style={{ margin: "0" }}
                      >
                        <span className="imageDiv">
                          <img
                            className="image_form"
                            src={serverUrl + "/image/amenity/" + imagePath}
                            alt="icon"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_amenity_detail_label_update_date"
                      >
                        Code
                      </div>
                      <div
                        className="detail_cell content amenity_updated_at"
                        style={{ color: "white" }}
                      >
                        <span id="amenity_id">
                          {amenityDetail &&
                            formatDateTimeDot(amenityDetail.updated_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_amenity_detail_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkAmenityEdit();
                  }}
                  data-detect="admin_amenity_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AmenityDetailData.mapStateToProps;
let mapDispatchToProps = AmenityDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AmenityDetailView)
);
