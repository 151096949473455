import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import CityEditData from "../Edit/CityEditData";

//css
import "../../../css/common.css";
import "../../../css/location/city/cityEdit.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class CityEditView extends CityEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      city: "",
      cityId: "",
      region: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  /* --------------------------------------------------- Life cycle --------------------------------------------------- */
  componentDidMount() {
    if (this.props.cityDetail != null && this.props.cityDetail.success) {
      this.setState({
        city: this.props.cityDetail.results.name, //default값 설정
        cityId: this.props.cityDetail.results.id,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- Event handler ------------------------------------------------- */
  // change 국가
  onCityChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ city: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editCity();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editCity = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestEditCity(this.state.cityId, this.state.city);
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/cityListPage");
  };

  /* ------------------------------------------------- Other functions ------------------------------------------------ */
  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("city", "admin_city_edit_validation_city")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };



  render() {
    let cityDetail = null;

    if (this.props.cityDetail != null && this.props.cityDetail?.success == true) {
      cityDetail = this.props.cityDetail.results;
    }

    return (
      <div className="cityEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="cityinfo_detail_container">
          <div className="cityinfo_detail_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_city_edit_title">City Information</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="cityinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="city_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_city_edit_label_region">
                        Region
                      </div>
                      <div className="detail_cell content region_name">
                        <input style={{ borderBottom: "none" }} type="text" value={cityDetail?.hotelCountry?.hotelRegion.name??"-"} placeholder="Region Name" readOnly />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_city_edit_label_country">
                        Country
                      </div>
                      <div className="detail_cell content country_name">
                        <input style={{ borderBottom: "none" }} type="text" value={cityDetail?.hotelCountry?.name??"-"} placeholder="Country Name" readOnly />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_city_list_label_city">
                        City
                      </div>
                      <div className="detail_cell content city_name">
                        <input type="text" value={this.state.city} onChange={this.onCityChange} placeholder="City Name" data-detect-placeholder="admin_city_edit_placeholder_city_name" />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_city_edit_label_created_at">
                        Created At
                      </div>
                      <div className="detail_cell content city_name">{cityDetail && formatDateTimeDot(cityDetail.created_at)}</div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_city_edit_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content city_name">{cityDetail && formatDateTimeDot(cityDetail.updated_at)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="cancel_btn_container">
                <button className="cancel_btn" onClick={this.cancelBtn} data-detect="admin_city_edit_button_cancel"></button>
              </div>
              <div className="ok_btn_container">
                <button className="ok_btn" onClick={this.onSubmit} data-detect="admin_city_edit_button_ok" disabled={!this.state.isCheckValidation}></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CityEditData.mapStateToProps;
let mapDispatchToProps = CityEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CityEditView));
