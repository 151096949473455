import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import serverUrl from "../../../react/common/ServerUrl";
import { Loading } from "../../components/Paths";
import FacilityImageDetailData from "../Detail/FacilityImageDetailData";

//css
import "../../css/common.css";
import "../../css/facilityImage/facilityImageDetail.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class FacilityImageDetailView extends FacilityImageDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      facilityImageId: "",
    };
  }

  // 첫 실행시 URL에서 facilityImageId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const facilityImageId = params.get("facilityImageId");
    this.setState({
      facilityImageId: facilityImageId,
    });
    this.requestGetFacilityImageOne(facilityImageId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkFacilityImageEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const facilityImageId = params.get("facilityImageId");
    this.props.history.push(
      `/facilityImageEditPage?facilityImageId=${facilityImageId}`
    );
  };



  render() {
    let facilityImageDetail = null;
    let imagePath = "";

    if (
      this.props.facilityImageDetail != null &&
      this.props.facilityImageDetail?.success == true
    ) {
      facilityImageDetail = this.props.facilityImageDetail.results;
    }

    return (
      <div className="facilityImageDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="facilityImageinfo_detail_container">
          <div className="facilityImageinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_facility_img_detail_title"
            >
              HOTEL FACILITY IMAGE INFORMATION
            </div>
            <div className="facilityImageinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="facilityImage_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_facility_img_detail_label_img"
                      >
                        Image
                      </div>
                      <div
                        className="detail_cell content facilityImage_type"
                        style={{ color: "white" }}
                      >
                        <span className="imageDiv">
                          <img
                            className="image_form"
                            src={
                              serverUrl +
                              "/image/facility/" +
                              facilityImageDetail?.fileId
                            }
                            alt="icon"
                          />
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_facility_img_detail_label_description"
                      >
                        Description
                      </div>
                      <div
                        className="detail_cell content facilityImage_group"
                        style={{ color: "white" }}
                      >
                        <span id="facilityImage_id">
                          {facilityImageDetail?.description??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_facility_img_detail_label_updated_at"
                      >
                        Updated At
                      </div>
                      <div
                        className="detail_cell content updated_at"
                        style={{ color: "white" }}
                      >
                        <span id="facilityImage_id">
                          {facilityImageDetail ?
                            formatDateTimeDot(facilityImageDetail.updated_at) : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_facility_img_detail_button_go_to_list"
                ></button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkFacilityImageEdit();
                  }}
                  data-detect="admin_facility_img_detail_button_edit"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = FacilityImageDetailData.mapStateToProps;
let mapDispatchToProps = FacilityImageDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FacilityImageDetailView)
);
