import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import UserInfoAddData from "../Add/UserInfoAddData";

//css
import "../../css/common.css";
import "../../css/userInfo/userInfoAdd.css";

class UserInfoAddView extends UserInfoAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      email: "",
      name: "",
      password: "",
      confirmPassword: "",
      department: "",
      duty: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.onCheckValidation();
    // 번역이 되지 않는 문제 예방
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // 정규식 패턴 정의
  passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)[\w!@#$%^&*()+={}]{8,}$/;
  mailRegExp = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  nameRegExp = /^[a-zA-Z.-_ㄱ-ㅎ가-힣\s]+$/;
  departmentRegExp = /^[a-zA-Z.-_ㄱ-ㅎ가-힣\s]+$/;
  spaceRegExp = /^[\s]+$/;

  // change email
  onChangeEmail = (e) => {
    const inputValue = e.target.value;
    this.setState({ email: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change password
  onChangePassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ password: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change confirmPassword
  onChangeConfirmPassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ confirmPassword: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change department
  onChangeDepartment = (e) => {
    const inputValue = e.target.value;
    this.setState({ department: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change duty
  onChangeDuty = (e) => {
    const inputValue = e.target.value;
    this.setState({ duty: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // check validation
  onCheckValidation = () => {
    const { email, name, password, confirmPassword, department, duty } = this.state;
    let errorMessage = "";

    const emailIsValid = this.mailRegExp.test(email);
    const passwordIsValid = this.passwordRegExp.test(password);

    if (email !== "" && !emailIsValid) {
      errorMessage = "이메일 형식에 맞게 입력하세요.";
    } else if (password !== "" && !passwordIsValid) {
      errorMessage = "영문과 숫자를 조합하여 8자 이상 입력하세요.";
    } else if (password !== "" && password !== confirmPassword) {
      errorMessage = "비밀번호가 일치하지 않습니다.";
    }

    this.setState({ errorMessage });

    if (email !== "" && name !== "" && password !== "" && confirmPassword !== "" && department !== null && department !== "" && duty !== ""&& errorMessage == "") {
      document.querySelector(".add_btn").removeAttribute("disabled");
    } else {
      document.querySelector(".add_btn").setAttribute("disabled", "disabled");
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = async () => {
    this.setState({
      isShowAllLoading: true,
    });
    await this.requestAddUserInfo(this.state.email, this.state.name, this.state.password, this.state.confirmPassword, this.state.department, this.state.duty);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/userInfoListPage");
  };

  render() {
    let myDuty = "";

    if (this.props.data != null && this.props.data?.success == true) {
      myDuty = this.props.data.results.duty;
    }
    
    const dutyOptions = {
      SUPER_ADMIN: [
        {
          value: "SUPER_ADMIN",
          label: "SUPER ADMIN",
          detect: "admin_admin_user_add_select_super_admin",
        },
        {
          value: "ADMIN",
          label: "ADMIN",
          detect: "admin_admin_user_add_select_admin",
        },
        {
          value: "USER",
          label: "USER",
          detect: "admin_admin_user_add_select_user",
        },
      ],
      ADMIN: [
        {
          value: "ADMIN",
          label: "ADMIN",
          detect: "admin_admin_user_add_select_admin",
        },
        {
          value: "USER",
          label: "USER",
          detect: "admin_admin_user_add_select_user",
        },
      ],
      USER: [
        {
          value: "USER",
          label: "USER",
          detect: "admin_admin_user_add_select_user",
        },
      ],
    };

    const selectOptions = dutyOptions[myDuty] || [];

    return (
      <div className="addUserInfo">
        {this.state.isShowAllLoading && <Loading />}
        <div className="userInfo_add_container">
          <div className="adduserInfo_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_admin_user_add_title">NEW ADMIN</p>
              </div>
              <div className="error-container">{this.state.errorMessage && <p className="error-message">{this.state.errorMessage}</p>}</div>
            </div>
            <div className="adduserInfo_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="adduserInfo_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_admin_user_add_label_email">Email</p>
                      </div>
                      <div className="detail_cell content userInfo_email">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          data-detect-placeholder="admin_admin_user_add_label_email"
                          placeholder="admin_admin_user_add_label_email"
                          autoComplete="off"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_admin_user_add_placeholder_name">ConfirmPassword</p>
                      </div>
                      <div className="admin_application_translation_add_label_name">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          data-detect-placeholder="admin_admin_user_add_placeholder_name"
                          placeholder="admin_admin_user_add_placeholder_name"
                          autoComplete="off"
                          value={this.state.name}
                          onChange={this.onChangeName}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_admin_user_add_placeholder_password">Password</p>
                      </div>
                      <div className="admin_application_translation_add_label_password">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          data-detect-placeholder="admin_admin_user_add_placeholder_password"
                          placeholder="admin_admin_user_add_placeholder_password"
                          autoComplete="off"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_admin_user_add_placeholder_confirm_password">ConfirmPassword</p>
                      </div>
                      <div className="admin_application_translation_add_label_confirm_password">
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          data-detect-placeholder="admin_admin_user_add_placeholder_confirm_password"
                          placeholder="admin_admin_user_add_placeholder_confirm_password"
                          autoComplete="off"
                          value={this.state.confirmPassword}
                          onChange={this.onChangeConfirmPassword}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_admin_user_add_placeholder_department">Department</p>
                      </div>
                      <div className="admin_application_translation_add_label_department">
                        <input
                          type="text"
                          id="department"
                          name="department"
                          data-detect-placeholder="admin_admin_user_add_placeholder_department"
                          placeholder="admin_admin_user_add_placeholder_department"
                          autoComplete="off"
                          value={this.state.department}
                          onChange={this.onChangeDepartment}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_admin_user_add_label_duty">Duty</p>
                      </div>
                      <div className="detail_cell content applicationEndPoint_duty">
                        <select id="duty" className="duty_name_select" name="duty" style={{ color: "white" }} value={this.state.duty} onChange={this.onChangeDuty}>
                        <option disabled value="" data-detect="admin_admin_user_add_select_choose">
                            Choose
                          </option>
                          {selectOptions.map((option) => (
                            <option key={option.value} value={option.value} data-detect={option.detect}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_admin_user_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_admin_user_add_button_add" onClick={this.onSubmit}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserInfoAddData.mapStateToProps;
let mapDispatchToProps = UserInfoAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserInfoAddView));
