import React from "react";
import { connect } from "react-redux";
import { Loading } from "../../components/Paths";
import UseKeyLogDetailData from "../Detail/useKeyLogDetailData";

//css
import "../../css/common.css";
import "../../css/useKey/useKeyLogDetail.css";

// utils
import { formatDateTimeDot, prettyPrint } from "../../common/Utils";

class UseKeyLogDetailView extends UseKeyLogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      useKeyLogId: "",
    };
  }

  // 첫 실행시 URL에서 useKeyLogId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const useKeyLogId = params.get("useKeyLogId");
    this.setState({
      useKeyLogId: useKeyLogId,
    });
    this.requestGetUseKeyLogDetail(useKeyLogId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let useKeyLogDetail = null;

    if (this.props.useKeyLogDetail != null && this.props.useKeyLogDetail?.success == true) {
      useKeyLogDetail = this.props.useKeyLogDetail.results;
    }

    return (
      <div className="useKeyLogDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="useKeyLog_detail_container">
          <div className="useKeyLog_detail_innder">
            <div className="title" data-detect="admin_use_key_log_detail_title">
              USE KEY LOG DETAIL
            </div>
            <div className="useKeyLog_container">
              <div className="useKeyLog_title_container"></div>
              <div className="useKeyLog_info_container">
                <div className="useKeyLog_info_table">
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_use_key_log_id"> Use key log ID </span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span>{useKeyLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_user_id"> User ID</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail?.user.id??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_user_email">User Email</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail?.user.email??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_hotel_key_id"> Hotel key ID</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail?.hotelKeyId??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_hotel_id"> Hotel ID</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_device">Device</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail?.device??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_app_version"> Application version</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail?.appVersion??"-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_log">Log</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span className="format">{prettyPrint(useKeyLogDetail?.log)}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_created_at">Created At</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail ? formatDateTimeDot(useKeyLogDetail.created_at) : "-"}</span>
                    </div>
                  </div>
                  <div className="useKeyLog_info_row">
                    <div className="useKeyLog_info_cell index">
                      <span data-detect="admin_use_key_log_detail_label_updated_at">Updated At</span>
                    </div>
                    <div className="useKeyLog_info_cell content">
                      <span> {useKeyLogDetail ? formatDateTimeDot(useKeyLogDetail.created_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_use_key_log_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UseKeyLogDetailData.mapStateToProps;
let mapDispatchToProps = UseKeyLogDetailData.mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UseKeyLogDetailView);
