import CommonComponent from "../../components/CommonComponent";

// module
import * as applicationTranslationActions from "../../../redux/modules/ApplicationTranslation";
import * as authActions from "../../../redux/modules/Auth";




class ApplicationTranslationDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_APPLICATION_TRANSLATION_ONE") {

      this.onGetApplicationTranslationOneResults(props);
    }
  }

  // redux에 get applicationTranslation Detail 요청
  requestGetApplicationTranslationOne = (applicationTranslationId) => {
    this.setState({
      requestType: "GET_APPLICATION_TRANSLATION_ONE",
    });
    this.props.onGetOne(applicationTranslationId);
  };

  // get applicationTranslation One results
  onGetApplicationTranslationOneResults = (props) => {
    if (props.applicationTranslationDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationTranslationDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationTranslationDetail: state.applicationTranslation.applicationTranslationDetail,
      failData: state.applicationTranslation.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetOne: (applicationTranslationId) => dispatch(applicationTranslationActions.onGetApplicationTranslationOne(applicationTranslationId)),
    };
  };
}

export default ApplicationTranslationDetailData;
