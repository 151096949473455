// VersionListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import VersionListData from "../List/VersionListData";


//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/version/version/versionList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { getSessionStorageItem, setSessionStorageItem } from "../../../common/Utils";

class VersionListView extends VersionListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetVersionList(
        this.state.pageNo,
        this.state.search,
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
      },
      () => {
        this.requestGetVersionList(this.state.pageNo, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
        },
        () => {
          this.requestGetVersionList(this.state.pageNo, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search }, () => {
      this.requestGetVersionList(pageNo, this.state.search); // 호텔 목록 요청
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkVersionDetail(id);
    }
  }

  //파람값 url로 전달
  linkVersionDetail = (versionId) => {
    this.props.history.push(`/versionDetailPage?versionId=${versionId}`);
  };

  //insert
  linkVersionAdd = () => {
    this.props.history.push("/versionAddPage");
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  render() {
    let count;
    let versionList = [];
    if (
      this.props.versionList.length !== 0 &&
      this.props.versionList?.success == true
    ) {
      count = this.props.versionList.results.count;
      versionList = this.props.versionList.results.rows;
    }

    return (
      <div className="versionList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="version_list_container">
          <div className="version_list_container_inner">
            <div className="title" data-detect="admin_version_list_title">
              VERSION LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_version_list_placeholiser_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- version list table  --> */}
            <div className="version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(versionList, this.state.pageNo)}
                        checked={versionList && this.state.checkedList.length == versionList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_version_list_label_version_name">
                      Version Name
                    </div>
                    <div className="cell" data-detect="admin_version_list_label_ios">
                      IOS
                    </div>
                    <div className="cell" data-detect="admin_version_list_label_ios_debug">
                      IOS Debug
                    </div>
                    <div className="cell" data-detect="admin_version_list_label_android">
                      Android
                    </div>
                    <div className="cell" data-detect="admin_version_list_label_android_debug">
                      Android Debug
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {versionList?.length > 0 ? (
                    versionList.map((version, index) => (
                      <div className="row tbodyRow" key={version.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={version.id}
                            id={version.id}
                            onClick={() => this.onClickInput(version.id, versionList)}
                            checked={this.state.checkedList.includes(version.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={version.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(version.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {version.id}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(version.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {version.ios}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(version.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {version.iosDebug}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(version.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {version.android}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(version.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {version.androidDebug}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_version_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedVersions();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_version_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkVersionAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_version_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = VersionListData.mapStateToProps;
let mapDispatchToProps = VersionListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VersionListView)
);
