// TermsGroupAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as termsAndConditionActions from "../../../../redux/modules/TermsAndCondition";

class TermsGroupAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_TERMS_GROUP") {
      this.onAddTermsGroupResults(props);
    }
  }

  // redux에 add termsGroup 요청
  requestAddTermsGroup = (title, countryCode) => {
    this.setState({
      requestType: "ADD_TERMS_GROUP",
    });
    this.props.onAdd(title, countryCode);
  };

  // get add termsGroup results
  onAddTermsGroupResults = (props) => {
    if (props.termsGroupAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.termsGroupAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(props.termsGroupAdd.reason, () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/termsGroupListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      termsGroupAdd: state.termsAndCondition.termsGroupAdd,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (title, countryCode) => dispatch(termsAndConditionActions.onAddTermsGroup(title, countryCode)),
    };
  };
}

export default TermsGroupAddData;
