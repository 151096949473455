// CityListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import CityListData from "../List/CityListData";


//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/location/city/cityList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class CityListView extends CityListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      regionId: "",
      regionName: "",
      regionList: this.props.regionList,
      cityList: [],
      checkedList: [],
      countryId: "",
      countryName: "", //region값에 맞게 select된 countryData

      // 임시값
      tempSearch: "",
      tempRegionId: "",
      tempRegionName: "",
      tempCountryId: "",
      tempCountryName: "",
    };
  }

  //초기값 pageNo=1 , search=null;
  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetRegionList(this.state.pageNo, this.state.search);
    }

  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // region 변경
  onChangeRegion = (regionId, regionName) => {
    const inputValue = regionId;
    this.setState({
      isShowAllLoading: true,
      tempRegionId: inputValue,
      tempRegionName: regionName,
    });
    this.requestGetCountry(regionId); //country 함수 호출
  };

  // country 변경
  onChangeCountry = (countryId, countryName) => {
    const inputValue = countryId;
    this.setState({
      tempCountryId: inputValue,
      tempCountryName: countryName,
    });
  };

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  //검색 버튼을 누르면
  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        regionId: this.state.tempRegionId,
        countryId: this.state.tempCountryId,
      },
      () => {
        this.requestGetCityList(this.state.pageNo, this.state.search, this.state.regionId, this.state.countryId);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          regionId: this.state.tempRegionId,
          countryId: this.state.tempCountryId,
        },
        () => {
          this.requestGetCityList(this.state.pageNo, this.state.search, this.state.regionId, this.state.countryId);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState(
      {
        isShowAllLoading: true,
        tempSearch: this.state.search,
        tempRegionId: this.state.regionId,
        tempRegionName: this.state.regionName,
        tempCountryId: this.state.tempCountryId,
        tempCountryName: this.state.tempCountryName,
      },
      () => {
        this.requestGetCityList(pageNo, this.state.search, this.state.regionId, this.state.countryId);
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkCityDetail(id);
    }
  }

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // //파람값 url로 전달
  linkCityDetail = (cityId) => {
    this.props.history.push(`/cityDetailPage?cityId=${cityId}`);
  };

  //insert
  linkCityAdd = () => {
    this.props.history.push("/cityAddPage");
  };

  findSelectedName = (value, list) => {
    let selectedName = "";
    let selectedId = "";
    if (value != "" && list.length > 0) {
      const selectedRow = list.find((row) => row.name == value);
      selectedName = selectedRow.name;
      selectedId = selectedRow.id;
    }
    return { name: selectedName, id: selectedId };
  }

  render() {
    let count;
    let regionList,
      cityList,
      countryList = [];

    if (this.props.regionList.length !== 0 && this.props.regionList?.success == true && this.props.cityList.length !== 0 && this.props.cityList?.success == true) {
      regionList = this.props.regionList.results.rows;
      cityList = this.props.cityList.results.rows;
      count = this.props.cityList.results.count;
    }
    if(this.props.cityData.length !== 0 && this.props.cityData?.success == true) {
      countryList = this.props.cityData.results.rows;
    }
    return (
      <div className="cityList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="city_list_container">
          <div className="city_list_container_inner">
            <div className="title" data-detect="admin_city_list_title">
              REGION LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select
                      // className="search"
                      style={{ marginRight: "6px" }}
                      id="regionSelect"
                      onChange={(e) => {
                        const value = e.target.value;
                        const { id, name } = this.findSelectedName(value, regionList);
                        this.onChangeRegion(id, name);
                      }}
                      value={this.state.tempRegionName}
                    >
                      <option value="" data-detect="admin_city_list_select_region_all">
                        Region ( ALL )
                      </option>
                      {regionList &&
                        regionList.map((region) => (
                          <option key={region.id} value={region.name}>
                            {region.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="find">
                    <select
                      style={{ marginRight: "6px" }}
                      id="countrySelect"
                      onChange={(e) => {
                        const value = e.target.value;
                        const { id, name } = this.findSelectedName(value, countryList);
                        this.onChangeCountry(id, name);
                      }}
                      value={this.state.tempCountryName}
                    >
                      <option className="all" value="" data-detect="admin_city_list_select_country_all">
                        Country ( ALL )
                      </option>
                      {countryList.length > 0 &&
                        countryList.map((city) => (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_city_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- city list table  --> */}
            <div className="city_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input type="checkbox" id="allCheck" onClick={() => this.allCheck(cityList, this.state.pageNo)} checked={this.state.checkedList.length == cityList?.length} readOnly />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_city_add_label_region">
                      Region.
                    </div>
                    <div className="cell" data-detect="admin_city_add_label_country">
                      Country.
                    </div>
                    <div className="cell" data-detect="admin_city_add_label_city">
                      City No.
                    </div>
                    <div className="cell" data-detect="admin_city_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {cityList?.length > 0 ? (
                    cityList.map((city, index) => (
                      <div className="row tbodyRow" key={city.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={city.id}
                            id={city.id}
                            onClick={() => this.onClickInput(city.id, cityList)}
                            checked={this.state.checkedList.includes(city.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={city.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(city.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {city.hotelCountry.hotelRegion.name}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(city.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {city.hotelCountry.name}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(city.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {city.name}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(city.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(city.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_city_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedCitys();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_city_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkCityAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_city_list_button_add">Add</span>
                </button>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CityListData.mapStateToProps;
let mapDispatchToProps = CityListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CityListView));
