import axios from "axios";
import serverUrl from "../../react/common/ServerUrl";
import { languageCode } from "../../react/components/common/Language";
import { reissueAdminToken } from "../lib/auth_apis";

const axiosConfig = {
  baseURL: serverUrl,
  headers: {
    "Content-Type": "application/json",
    os: "WEB",
    "Content-Language": languageCode,
  },
  timeout: 1000 * 100, // 응답 시간이 60초를 초과할 경우 에러 처리함
};

const axiosInstance = axios.create(axiosConfig);

// 요청이 전달되기 전에 토큰 저장하기
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");

    config.headers["x-access-token"] = accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 401 에러 응답시 refreshtoken으로 access토큰 재요청
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 404) {
      console.log("error status: 404");
    }
    return response;
  },
  async (error) => {
    await handleErrors(error);
  }
);

export async function handleErrors(error) {
  const err = error;
  if (err.response?.status === 401) {
    const data = err.response.data;

    if (data?.code == "INVALID_TOKEN") {
      // refresh 토큰으로 재발급 요청하기
      await reissueAdminToken()
        .then((tokenData) => {
          if (tokenData?.data?.success == true) {
            // 새로 발급받은 토큰을 저장
            const { accessToken, refreshToken } = tokenData.data;

            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            document.cookie = `accessToken=${accessToken}; path=/;`;
            document.cookie = `refreshToken=${refreshToken}; path=/;`;

            err.config.headers = {
              "x-access-token": accessToken,
            };
            // 중단된 요청 (에러난 요청)을 새로운 토큰으로 재전송
            const originalResponse = axiosInstance.request(err.config);
            return originalResponse;
          } else {
            // refresh 토큰이 없거나 만료되었을 떄(success가 false일 때)
            const err = new Error();
            err.response = {
              data: tokenData.data,
            };
            return Promise.reject(err);
          }
        })
        .catch((err) => {
          // refresh 토큰이 만료되었을 때
          return Promise.reject(err);
        });
    }
  } else if (err.code == "ECONNABORTED") {
    // timeout 되었을 때
    // 에러 객체에 response data 할당
    err.response = {
      data: {
        code: "TIMEOUT",
        reason: error.message ?? "timeout error",
      },
    };
    return Promise.reject(err);
  } else if (err.response?.status >= 500) {
    // 서버 에러(5xx) 처리
    // 에러 객체에 response data 할당
    err.response = {
      data: {
        code: "SERVER_ERROR",
        reason: error.message ?? "server error",
      },
    };
    return Promise.reject(err);
  } else if(err.code == undefined && err.message == "Network Error"){
    // 네트워크 오류 
      err.response = {
        data: {
          code: error.code ? "ERROR:" + error.code : "NETWORK_ERROR",
          reason: error.message ?? "network error",
        },
      };
    return Promise.reject(err);
  }
  else {
    // 401, 504 에러를 제외한 나머지 에러
    console.log("error code:", err.code);
    console.log("error message:", err.message);
    console.log("error stack:", err.stack);
    console.log("error status:", err.response?.status);
    return Promise.reject(error);
  }
}

export default axiosInstance;
