// TermsAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as termsAndConditionActions from "../../../../redux/modules/TermsAndCondition";

// language
import { multiLanguageCode, MultiLanguage } from "../../../components/common/Language";


class TermsAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }
  // request redux
  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_TERMS_GROUP_LIST") {
      this.onGetTermsGroupListResults(props);
    }
    if (this.state.requestType == "ADD_TERMS") {
      this.onAddTermsResults(props);
    }
  }

  // redux에 get terms list 요청
  requestTermsGroupList = () => {
    this.setState({
      requestType: "GET_TERMS_GROUP_LIST",
    });
    this.props.onGetTermsGroupList();
  };
  
  // redux에 add terms 요청
  requestAddTerms = (title, requiredId, languageCode, requiredStatus, description, linkText, fileContent) => {
    const changedDescription = description
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ');

    const formData = new FormData();
    formData.append('termsFile', fileContent);
    formData.append('termsGroupId', requiredId);
    formData.append('languageCode', languageCode);
    formData.append('required', requiredStatus);
    formData.append('description', changedDescription);
    formData.append("title", title);
    formData.append("linkText", linkText);

    this.setState({
      requestType: "ADD_TERMS",
    });
    this.props.onAdd(formData);
  };



  // get  terms  group list results
  onGetTermsGroupListResults = (props) => {
    if (props.termsGroupList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.termsGroupList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // get add terms results
  onAddTermsResults = (props) => {
    if (props.termsAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.termsAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(props.termsAdd.reason, () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/termsListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      termsAdd: state.termsAndCondition.termsAdd,
      termsGroupList: state.termsAndCondition.termsGroupList,
      failData: state.termsAndCondition.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (formData) => dispatch(termsAndConditionActions.onAddTerms(formData)),
      onGetTermsGroupList: () => dispatch(termsAndConditionActions.onGetTermsGroupList()),
    };
  };
}

export default TermsAddData;
