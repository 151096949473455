import CommonComponent from "../../../components/CommonComponent";

// module
import * as authActions from "../../../../redux/modules/Auth";

class StaffTransferLogInfoDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }


  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      staffTransferLogHistoryDetail: state.transfer.staffTransferLogHistoryDetail,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
    };
  };
}

export default StaffTransferLogInfoDetailData;
