// TransferLogListView.js

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTimePicker from "../../../components/DateTimePicker/DataTimePicker";
import { Loading, Pagination } from "../../../components/Paths";
import UserTransferLogHistoryListData from "./userTransferLogHistoryListData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/transfer/userTransferLogHistory/userTransferLogHistoryList.css";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class UserTransferLogHistoryListView extends UserTransferLogHistoryListData {
  constructor(props) {
    super(props);

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      email: "",
      search: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempSearch: "",
      tempEmail: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // Date 설정
      const startDate = new Date();
      const thirtyMinutesAgo = startDate.getMinutes() - 30;
      startDate.setMinutes(thirtyMinutesAgo);
      startDate.setSeconds(0);

      const endDate = new Date();
      endDate.setSeconds(0);

      this.setState(
        {
          startDate: startDate, // startDate를 어제로 설정
          endDate: endDate,
          tempStartDate: startDate,
          tempEndDate: endDate,
        },
        () => {
          this.requestGetUserTransferLogHistoryList(this.state.pageNo, this.state.email, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        }
      );
    }
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = "";

    if (stateName == "tempStartDate" || stateName == "tempEndDate") {
      value = e;
    } else {
      value = e.target.value;
    }

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        email: this.state.tempEmail,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetUserTransferLogHistoryList(this.state.pageNo, this.state.email, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          email: this.state.tempEmail,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetUserTransferLogHistoryList(this.state.pageNo, this.state.email, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempEmail: this.state.email, tempStartDate: this.state.startDate, tempEndDate: this.state.endDate }, () => {
      this.requestGetUserTransferLogHistoryList(pageNo, this.state.email, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  handleMouseUp = (transferCode) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkuserTransferLogHistory(transferCode);
    }
  }

  //파람값 url로 전달
  linkuserTransferLogHistory = (transferCode) => {
    this.props.history.push(`/userTransferLogHistoryDetailPage?transferCode=${transferCode}`);
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if(!date || date == "") return;
    return date.toISOString();
  };

  isSuccess = (body) => {
    if (body == null || body == "") return;
    // success 정리
    let success = "-";

    let includeTrue = body.indexOf("true");
    let includeFalse = body.indexOf("false");

    if (includeTrue == -1 && includeFalse == -1) {
      success = "-";
    } else if ((includeTrue == -1 ? Number.MAX_SAFE_INTEGER : includeTrue) < (includeFalse == -1 ? Number.MAX_SAFE_INTEGER : includeFalse)) {
      success = "true";
    } else {
      success = "false";
    }
    return success;
  };

  render() {
    let count;
    let userTransferLogHistoryList = [];

    if (this.props.userTransferLogHistoryList && this.props.userTransferLogHistoryList?.success == true) {
      userTransferLogHistoryList = this.props.userTransferLogHistoryList.results.rows;
      count = this.props.userTransferLogHistoryList.results.count;
    }

    return (
      <div className="userTransferLogHistoryList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="user_transfer_list_container">
          <div className="user_transfer_list_container_inner">
            <div className="title" data-detect="admin_user_transfer_log_history_title">
              USER TRANSFER LOG HISTORY
            </div>
            <div className="btn_container_left">
              <div className="period_container">
                <div className="period_start">
                  <DataTimePicker value={this.state.tempStartDate} onDateTimeChange={(e) => this.onChangeValue(e, "tempStartDate")} />
                </div>
                <div className="period_line"></div>
                <div className="period_end">
                  <DataTimePicker value={this.state.tempEndDate} onDateTimeChange={(e) => this.onChangeValue(e, "tempEndDate")} />
                </div>
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search search_email">
                    <input
                      type="search"
                      id="search_input_email"
                      data-detect-placeholder="admin_user_transfer_log_history_placeholder_email"
                      placeholder="Please enter your E-mail."
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempEmail")}
                      value={this.state.tempEmail}
                    />
                  </div>
                  <div className="search search_content">
                    <input
                      type="search"
                      id="search_input_content"
                      data-detect-placeholder="admin_user_transfer_log_history_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                      value={this.state.tempSearch}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="user_transfer_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_user_transfer_log_history_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_method">
                      Method
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_path">
                      Path
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_status">
                      Status
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_success">
                      Success
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_user_email">
                      User Email
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_created_at">
                      Created At
                    </div>
                    <div className="cell" data-detect="admin_user_transfer_log_history_label_process_time">
                      Process Time(ms)
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {userTransferLogHistoryList.length > 0 ? (
                    userTransferLogHistoryList.map((transferLogHistory, index) => (
                      <div
                        key={transferLogHistory.id}
                        className={`row ${transferLogHistory.status !== "200" || this.isSuccess(transferLogHistory.body) == "false" ? "ROWRED" : ""}`}
                        id={transferLogHistory.id}
                        onMouseUp={() => this.handleMouseUp(transferLogHistory.transferCode)}
                        onMouseMove={this.handleMouseMove}
                        onMouseDown={this.handleMouseDown}
                      >
                        <div className="cell">
                          <div className="eachIndexNo">
                            <span>{transferLogHistory.id}</span>
                          </div>
                        </div>
                        <div className={`cell ${transferLogHistory.method}`}>{transferLogHistory.method}</div>
                        <div className="cell" title={transferLogHistory.path}>
                          {transferLogHistory.path}
                        </div>
                        <div className="cell">{transferLogHistory.status}</div>
                        <div className="cell">{this.isSuccess(transferLogHistory.body)}</div>
                        <div className="cell">{transferLogHistory.userEmail}</div>
                        <div className="cell">{transferLogHistory && formatDateTimeSlash(transferLogHistory.created_at)}</div>
                        <div className="cell">{transferLogHistory.termMillisec}</div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_user_transfer_log_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserTransferLogHistoryListData.mapStateToProps;
let mapDispatchToProps = UserTransferLogHistoryListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTransferLogHistoryListView));
