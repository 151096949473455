import axiosInstance from "../api/axios";

//------------------------------- Post User ------------------------------------------------------//
export function onPost(email, password, name, company, duty) {
  const data = {
    type: "LOCAL",
    email: email,
    password: password,
    name: name,
    company: company,
    duty: duty,
  };

  return axiosInstance.post("/api/user/", data);
}

//------------------------------- Post User ------------------------------------------------------//
export function onPostWithGoogle(idToken, name, company, duty) {
  const data = {
    type: "GOOGLE",
    token: idToken,
    name: name,
    company: company,
    duty: duty,
  };

  return axiosInstance.post("/api/user/", data);
}

//------------------------------- Post User ------------------------------------------------------//
export function onPostProfile(formData) {
  return axiosInstance.post("/api/user/profileFile", formData);
}

//------------------------------- Put User ------------------------------------------------------//
export function onPut(name, company, duty) {
  const data = {
    name: name,
    company: company,
    duty: duty,
  };

  return axiosInstance.put("/api/user/", data);
}

//------------------------------- withdraw User ------------------------------------------------------//
export function onWithdraw(password) {
  const data = {
    password: password,
  };
  return axiosInstance.post("/api/user/withdraw", data);
}

export function onWithdrawWithGoogle(idToken) {
  const data = {
    type: "GOOGLE",
    token: idToken,
  };

  return axiosInstance.post("/api/user/withdraw", data);
}

//------------------------------- Get User ------------------------------------------------------//
export function onGet() {
  return axiosInstance.get("/api/user/");
}

//------------------------------- Select User ------------------------------------------------------//
export function onGetByEmail(email) {
  return axiosInstance.get("/api/user/" + email);
}

//------------------------------- Get User UsedBytes ------------------------------------------------------//
export function onGetBytes() {
  return axiosInstance.get("/api/user/usedBytes");
}

//------------------------------- Get User profile ------------------------------------------------------//
export function onGetMyProfile() {
  const config = {
    responseType: "blob",
  };
  return axiosInstance.get("/api/user/profileFile", config);
}

//------------------------------- Get other User profile ------------------------------------------------------//
export function onGetOtherProfile(userId) {
  const config = {
    responseType: "blob",
  };

  return axiosInstance.get("/api/user/profileFile/" + userId, config);
}
