// RegionAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class RegionDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_REGION_ONE") {

      this.onGetRegionOneResults(props);
    }
  }

  // redux에 get region Detail 요청
  requestGetRegionOne = (regionId) => {
    this.setState({
      requestType: "GET_REGION_ONE",
    });
    this.props.onGetOne(regionId);
  };

  // get region One results
  onGetRegionOneResults = (props) => {
    if (props.regionDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.regionDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_region_detail"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      regionDetail: state.location.regionDetail,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (regionId) => dispatch(locationActions.onGetOne(regionId)),
    };
  };
}

export default RegionDetailData;
