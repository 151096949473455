// LoginView.js

import React from 'react';
import { connect } from 'react-redux';
import LoginData from './LoginData';
import { withRouter } from "react-router-dom";
import { Loading } from "../components/Paths";

// language
import { updateTextLanguage } from "../../react/components/common/Language";

// Import the CSS module
import "../login/Login.css";

// img
import loginLogo from '../img/login_Logo.svg';

class Login extends LoginData {
  constructor(props) {
    super(props);
    this.state = {
      isShowLoading: false,
      isMsg: '관리자'
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
    updateTextLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // 로그인 버튼 클릭
  onSignIn = () => {
    let email = document.getElementById('email').value;
    let password = document.getElementById('pw').value;
    this.requestLogin(email, password);
  };

  // input 엔터키 : 로그인
  onKeyUp = () => {
    if (window.event.keyCode == 13) {
      let email = document.getElementById('email').value;
      let password = document.getElementById('pw').value;
      this.requestLogin(email, password);
    }
  };

  render() {
    // HTML rendering using CSS module classes
    return (
      <div className="login">
        {this.state.isShowLoading && <Loading />}
        <div className="login_logo_title">
          <div className="login_logo">
            <a href="./">
              <div className="login_img">
                <img src={loginLogo} alt={'myConnect logo'} className="login_img" loading="lazy"/>
              </div>
            </a>
          </div>
          <div className="login_admin">
            <h3 data-detect="admin_login_admin_label_admin">ADMIN</h3>
          </div>
        </div>
        <div className="login_form">
          <div className="input_area" style={{ marginBottom: '10px' }}>
            <input
              id="email"
              type="email"
              onKeyUp={() => this.onKeyUp()}
              data-detect-placeholder="admin_login_admin_placeholder_email"
              autoComplete="username"
            />
          </div>
          <div className="input_area" style={{ marginBottom: '30px' }}>
            <input
              id="pw"
              type="password"
              autoComplete="current-password"
              onKeyUp={() => this.onKeyUp()}
              data-detect-placeholder="admin_login_admin_placeholder_password"
            />
          </div>

          <button
            type="button"
            data-detect="admin_login_admin_button_login"
            onClick={() => {
              this.onSignIn();
            }}
          >
            Login
          </button>
        </div>
        <div className="login_title">
          <p data-detect="admin_login_admin_guide_login">Login as an admin user.</p>
        </div>
      </div>
    );
  }
}

let mapStateToProps = LoginData.mapStateToProps;
let mapDispatchToProps = LoginData.mapDispatchToProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));
