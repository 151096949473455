import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import serverUrl from "../../../react/common/ServerUrl";
import { Loading } from "../../components/Paths";
import FacilityImageEditData from "../Edit/FacilityImageEditData";

//css
import "../../css/common.css";
import "../../css/facilityImage/facilityImageEdit.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class FacilityImageEditView extends FacilityImageEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      facilityImageId: "",
      description: "",
      iconFile: null,
      iconText: "",
      updatedAt: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  // 첫 실행시 URL에서 facilityImageId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const facilityImageId = params.get("facilityImageId");
    this.setState({
      facilityImageId: facilityImageId,
    });

    if (this.props.facilityImageDetail != null && this.props.facilityImageDetail.success) {
      this.setState({
        description: this.props.facilityImageDetail.results.description,
        iconFile: this.props.facilityImageDetail.results.fileId,
        iconText: this.props.facilityImageDetail.results.fileName,
        updatedAt: this.props.facilityImageDetail.results.created_at,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change description
  onChangeDescription = (e) => {
    const inputValue = e.target.value;
    this.setState({ description: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("iconFile", "admin_facility_img_edit_validation_image") && //
      this.confirmField("description", "admin_facility_img_edit_validation_description")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file image 미리보기
  preview = () => {
    if (!this.state.iconFile || !this.state.iconFile instanceof Blob) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }
    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        logoUrl: reader.result,
        isShowAllLoading: false,
      });
    };
    reader.readAsDataURL(this.state.iconFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(
      { iconFile: file, iconText: file ? file.name : "" },
      () => {
        this.onCheckValidation();
        this.preview();
      } // 파일이 있는 경우 파일 이름을 설정
    );
  };



  render() {
    return (
      <div className="facilityImageEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="facilityImageinfo_detail_container">
          <div className="facilityImageinfo_detail_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_facility_img_edit_title">HOTEL FACILITY IMAGE INFORMATION</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="facilityImageinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="facilityImage_info">
                    <div className="icon_index">
                      <div className="detail_cell index" data-detect="admin_facility_img_edit_label_img">
                        Image
                      </div>
                      <div className="logo__box">
                        <div className="img__box">
                          {this.state.logoUrl ? (
                            // 이미지 URL 변경시 변경된 이미지 노출
                            <img className="preImg" src={this.state.logoUrl} alt="FacilityImage Logo" />
                          ) : (
                            // 새로운 파일을 선택하기 전 기존 이미지 받아오기
                            <img className="preImg" src={serverUrl + "/image/facility/" + this.state.iconFile} alt="FacilityImage Logo" />
                          )}
                        </div>
                        <div className="file_upload_button_container">
                          <div>
                            <label htmlFor="logo_image_file" className="file_upload_button" data-detect="admin_facility_img_edit_button_upload">
                              Upload
                            </label>
                            <input id="logo_image_file" type="file" name="iconFile" className="image_files blind" onChange={this.onFileChange} />
                          </div>

                          {this.state.iconText ? <p className="file_textName">{this.state.iconText}</p> : <p className="file_textName">{"ex) My conect.png"}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_facility_img_edit_label_description">
                        Description
                      </div>
                      <div className="detail_cell content facilityImage_description">
                        <div className="detail_cell content facilityImage_description">
                          <input
                            type="text"
                            id="description"
                            name="description"
                            data-detect-placeholder="admin_facility_img_detail_label_description"
                            placeholder="FacilityImage Description"
                            autoComplete="off"
                            value={this.state.description}
                            onChange={this.onChangeDescription}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_facility_img_edit_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content facilityImage_updated_at">
                        <span id="facilityImage_id">{formatDateTimeDot(this.state.updatedAt)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="cancel_btn_container">
                <button
                  className="cancel_btn"
                  data-detect="admin_facility_img_edit_button_cancel"
                  onClick={() => {
                    this.cancelBtn();
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="ok_btn_container">
                <button className="ok_btn" data-detect="admin_facility_img_edit_button_ok" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = FacilityImageEditData.mapStateToProps;
let mapDispatchToProps = FacilityImageEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilityImageEditView));
