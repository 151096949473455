// ApplicationVersionListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import ApplicationVersionListData from "../List/ApplicationVersionListData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/version/applicationVersion/applicationVersionList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class ApplicationVersionListView extends ApplicationVersionListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],
    };
  }

  componentDidMount() {
    this.requestGetAOSVersionLIst("aos"); //AOS LIST 호출
    this.requestGetIOSVersionList("iOS"); //IOS LIST 호출
    this.requestGetApplicationVersionList(this.state.pageNo, this.state.search); //Application List 호출
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      search: inputValue,
    });
  };

  onClickSearch = (e) => {
    this.setState({
      isShowAllLoading: true,
    });

    this.requestGetApplicationVersionList(1, this.state.search);
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, versionList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const inputValue = e.target.value;
    if (window.event.keyCode == 13) {
      this.setState({
        isShowAllLoading: true,
      });

      this.requestGetApplicationVersionList(1, this.state.search);
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo }, () => {
      this.onPageChange(pageNo); // 페이지 변경 처리
      this.requestGetApplicationVersionList(pageNo, this.state.search); // 호텔 목록 요청
    });
  };

  //insert
  linkApplicationVersionAdd = () => {
    this.props.history.push("/applicationVersionAddPage");
  };

  //download Applicaiton
  downloadApplication = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true);
    link.click();
  };

  render() {
    let count;
    let aosList = [];
    let iosList = [];
    let allList = [];
    let applicationVersionList = [];

    if (this.props.applicationVersionList.length !== 0 && this.props.applicationVersionList?.success == true) {
      count = this.props.applicationVersionList.results.count;
      applicationVersionList = this.props.applicationVersionList.results.rows;
    }
    if (this.props.aosList.length !== 0 && this.props.aosList?.success == true) {
      aosList = this.props.aosList.results;
    }
    if (this.props.iosList.length !== 0 && this.props.iosList?.success == true) {
      iosList = this.props.iosList.results;
      allList = [...aosList, ...iosList];
    }
    return (
      <div className="applicationVerisonList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="version_list_container">
          <div className="applicationVersion_list_container_inner">
            <div className="title" data-detect="admin_version_app_down_version_list_title">
              APPLICATION DOWNLOAD VERSION LIST
            </div>
            {/* active_version_list_table */}
            <div className="active_version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input type="checkbox" id="allCheck" onClick={() => this.allCheck(allList, this.state.pageNo)} checked={allList && this.state.checkedList.length == allList.length} readOnly />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_os">
                      OS
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_type">
                      Type
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_version">
                      Version
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_link">
                      Link
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {allList?.length > 0 ? (allList.map((versionList, index) => (
                    <div className="row tbodyRow" key={versionList.id}>
                      <div className="cell">
                        <div className="eachIndexNo blind">
                          <span>{index + 1}</span>
                        </div>
                        <input
                          type="checkbox"
                          name="eachCheck"
                          key={versionList.id}
                          id={versionList.id}
                          onClick={() => this.onClickInput(versionList.id, versionList)}
                          checked={this.state.checkedList.includes(versionList.id)}
                          readOnly
                        />
                        <label className="tbodyLabel" htmlFor={versionList.id}></label>
                      </div>
                      <div className="cell ellipsis">{versionList.os}</div>
                      <div className="cell">{versionList.type}</div>
                      <div className="cell">{versionList.version}</div>
                      <div className="cell ellipsis">
                        <a href={versionList.link} target="_blank" onClick={() => this.downloadApplication(versionList.link)}>
                          {versionList.link}
                        </a>
                      </div>
                      <div className="cell"> {formatDateTimeSlash(versionList.created_at)} </div>
                    </div>
                  ))) : (
                    <div className="no-data-in-list" data-detect="admin_version_app_down_version_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
            </div>
            <div className="btn_container_left">
              <button
                className="delete_btn"
                id="delete_btn"
                type="button"
                onClick={() => {
                  this.deleteSelectedApplicationVersions();
                }}
              >
                <img src={deleteIcon} alt="delete button" />
                <span data-detect="admin_version_app_down_version_list_button_delete">Delete</span>
              </button>
              <button
                className="add_btn"
                id="add_btn"
                type="button"
                onClick={() => {
                  this.linkApplicationVersionAdd();
                }}
              >
                <img src={addIcon} alt="add button" />
                <span data-detect="admin_version_app_down_version_list_button_add">Add</span>
              </button>
            </div>

            {/* inactive_version_list_table */}
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_version_app_down_version_list_placeholiser_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.search}
                      onChange={this.onChangeSearchText}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="inactive_version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_version_app_down_version_list_label_no">
                      No.
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_os">
                      OS
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_type">
                      Type
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_version">
                      Version
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_link">
                      Link
                    </div>
                    <div className="cell" data-detect="admin_version_app_down_version_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {applicationVersionList?.length > 0 ? (applicationVersionList.map((appVersionList, index) => (
                    <div className={`row tbodyRow ${appVersionList.id}`} key={index}>
                      <div className="cell">
                        <div className="eachIndexNo">
                          <span>{index}</span>
                        </div>
                      </div>
                      <div className="cell ellipsis">{appVersionList.os}</div>
                      <div className="cell">{appVersionList.type}</div>
                      <div className="cell">{appVersionList.version}</div>
                      <div className="cell ellipsis">
                        <a href={appVersionList.link} target="_blank">
                          {appVersionList.link}
                        </a>
                      </div>
                      <div className="cell">{formatDateTimeSlash(appVersionList.created_at)} </div>
                    </div>
                  ))) : (
                    <div className="no-data-in-list" data-detect="admin_version_app_down_version_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationVersionListData.mapStateToProps;
let mapDispatchToProps = ApplicationVersionListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationVersionListView));
