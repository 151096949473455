// HotelCopyData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as hotelActions from "../../../redux/modules/Hotel";


class HotelCopyData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "COPY_HOTEL") {
      this.onCopyHotelResults(props);
    }
  }

  //cancelBtn
  cancelBtn = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  onBackBtn = () => {
    this.props.history.push("/hotelListPage");
  };

  // redux에 copy hotel 요청
  requestCopyHotel = (name, address, email, password, confirmPwd, logoFile, logoText, copyId) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("con_password", confirmPwd);
    formData.append("logoFile", logoFile);
    formData.append("logoText", logoText);
    formData.append("copyHotelId", copyId);

    this.setState({
      requestType: "COPY_HOTEL",
    });
    this.props.onCopy(formData);
  };

  //copy hotel
  onCopyHotelResults = (props) => {
    if (props.hotelCopy == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.hotelCopy?.success == true) {
      this.props.history.push("/hotelListPage");
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(props.hotelCopy.reason, () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };
  
  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };
  
  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      hotelCopy: state.hotel.hotelCopy,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onCopy: (formData) => dispatch(hotelActions.onCopy(formData)),
    };
  };
}

export default HotelCopyData;
