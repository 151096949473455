import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import RegionDetailData from "../Detail/RegionDetailData";

//css
import "../../../css/common.css";
import "../../../css/location/region/regionDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class RegionDetailView extends RegionDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      regionId: "",
    };
  }

  // 첫 실행시 URL에서 regionId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const regionId = params.get("regionId");
    this.setState({
      regionId: regionId,
    });
    this.requestGetRegionOne(regionId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkRegionEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const regionId = params.get("regionId");
    this.props.history.push(`/regionEditPage?regionId=${regionId}`);
  };



  render() {
    let regionDetail = null;

    if (this.props.regionDetail != null && this.props.regionDetail?.success == true) {
      regionDetail = this.props.regionDetail.results;
    }

    return (
      <div className="regionDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="regioninfo_detail_container">
          <div className="regioninfo_detail_container_inner">
            <div className="title_container" data-detect="admin_header_link_region_list">
              region Information
            </div>
            <div className="regioninfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="region_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_region_list_label_region">
                        Region
                      </div>
                      <div className="detail_cell content region_name" style={{ color: "white" }}>
                        <span id="region_id">{regionDetail?.name ?? "-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_list_label_created_at">
                        Created At
                      </div>
                      <div className="detail_cell content region_name" style={{ color: "white" }}>
                        {regionDetail ? formatDateTimeDot(regionDetail.created_at) : "-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content region_name" style={{ color: "white" }}>
                        {regionDetail ? formatDateTimeDot(regionDetail.updated_at) : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_region_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkRegionEdit();
                  }}
                  data-detect="admin_region_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RegionDetailData.mapStateToProps;
let mapDispatchToProps = RegionDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegionDetailView)
);
