import CommonComponent from "../../../components/CommonComponent";

// module
import * as authActions from "../../../../redux/modules/Auth";
import * as transferActions from "../../../../redux/modules/Transfer";



class TransferLogDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_TRANSFER_LOG_DETAIL") {

      this.onGetPaymentTransferLogResults(props);
    }
  }

  // redux에 get payment Detail 요청
  requestGetTransferLogDetail = (transferLogId) => {
    this.setState({
      requestType: "GET_TRANSFER_LOG_DETAIL",
    });
    this.props.onGetTransferLogDetail(transferLogId);
  };

  // get payment One results
  onGetPaymentTransferLogResults = (props) => {
    if (props.transferLogDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.transferLogDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      transferLogDetail: state.transfer.transferLogDetail,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetTransferLogDetail: (transferLogId) =>
        dispatch(transferActions.onGetTransferLogDetail(transferLogId)),
    };
  };
}

export default TransferLogDetailData;
