import CommonComponent from "../../components/CommonComponent";

// modules
import * as authActions from "../../../redux/modules/Auth";
import { persistor } from "../../../redux/Store";


const utils = require("../../common/Utils");

class MenuData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
      requestChildType: "",
      userProfileBytesList: new Map(),
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_SESSION") {
      this.getSessionResult(props);
    } else if (this.state.requestType == "LOG_OUT") {
      this.logoutResult(props);
    } 
  }

  // Get Session 호출
  requestGetSession = () => {
    this.setState({
      requestType: "GET_SESSION",
    });

    this.props.onGetSession();
  };

  // Logout 호출
  requestLogout = () => {
    this.setState({
      requestType: "LOG_OUT",
    });

    this.props.onSignOut();
  };


  // get session 결과값
  getSessionResult = (props) => {
    if(props.sessionData == null) return;

    if (props.sessionData != null && props.sessionData?.success == true) {
    } else {
      if (this.props.location) {
        let currentPath = location.pathname;
        if(currentPath.includes("login")) return; 
      }
      // if (props.sessionData.code != "INVALID_TOKEN") {
      //   // 세션 만료시 팝업
      //   this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
      //     this.props.hideTopPopupDialog();
      //     this.props.history.push("/login");
      //   });
      // } else {
      //   this.onFailed(props);
      // }
    }
  };

  // logout 결과값
  logoutResult = (props) => {
      if (props.userOutedData != null) {
        this.setState({
          requestType: "",
        })
        if (props.userOutedData?.success == true) {
          // 로그아웃 시 storage 및 redux store 초기화
          persistor.purge();
          localStorage.clear();
          sessionStorage.clear();
          this.props.history.push("/login");
        } else if(props.userOutedData.code != "INVALID_TOKEN") {
            // 로그 아웃 실패시 팝업
          this.props.showSimpleTopPopupDialog(
            this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_failed_logout'], 
            () => {
            this.props.hideTopPopupDialog();
            });
        } else {
          this.onFailed(props);
        }
      }
  };


  onFailed = (props) => {
    if (props.error != null) {
      console.log("fail to get session", props.error);
      let code = props.error.code;
      let reason = props.error.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };


  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      sessionData: state.auth.sessionData,
      userOutedData: state.auth.userOutedData,
      error: state.auth.error,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetSession: () => dispatch(authActions.onGet()),
      onSignOut: () => dispatch(authActions.onSignOut()),
    };
  };
}

export default MenuData;
