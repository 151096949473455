import axiosInstance from "../api/axios";

/* ----------------------------------------------------- Get IOS ---------------------------------------------------- */
export function onGetIOS() {
  return axiosInstance.get("/hotel/api/applicationVersion/byOSForType/?os=iOS");
}

/* ----------------------------------------------------- Get AOS ---------------------------------------------------- */
export function onGetAOS() {
  return axiosInstance.get("/hotel/api/applicationVersion/byOSForType/?os=AOS");
}
