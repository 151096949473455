import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import BookingEmailDetailData from "../Detail/BookingEmailDetailData";



//css
import "../../css/common.css";
import "../../css/bookingEmail/bookingEmailDetail.css";

class BookingEmailDetailView extends BookingEmailDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      bookingEmailText: "",
      bookingEmailFile: "",
      logoUrl: "",
    };
  }
  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // check validation
  onCheckValidation = () => {
    const { bookingEmailFile, bookingEmailText } = this.state;
    if (bookingEmailFile !== "" && bookingEmailText !== "") {
      document.querySelector(".edit_btn").removeAttribute("disabled");
    } else {
      document.querySelector(".edit_btn").setAttribute("disabled", "disabled");
    }
  };

  //file image 미리보기
  preview = () => {
    if (
      !this.state.bookingEmailFile ||
      !this.state.bookingEmailFile instanceof Blob
    ) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }
    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const imgEL = document.querySelector(".img__box");
    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        logoUrl: reader.result,
        isShowAllLoading: false,
      });
    };
    reader.readAsDataURL(this.state.bookingEmailFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(
      { bookingEmailFile: file, bookingEmailText: file ? file.name : "" }, // 파일이 있는 경우 파일 이름을 설정
      this.preview
    );
    //  this.onCheckValidation();
  };

  render() {
    const imgBoxStyle = {
      marginLeft:
        this.state.bookingEmailFile &&
        this.state.bookingEmailFile instanceof Blob
          ? "300px"
          : "0",
    };
    return (
      <div className="bookingEmailDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="bookingEmailinfo_container">
          <div className="bookingEmailinfo_container_inner">
            <div className="title_box">
              <div className="title">
                <p data-detect="admin_booking_email_detail_label_format">
                  Booking E-mail Format
                </p>
              </div>
            </div>
            <div className="bookingEmailinfo_container">
              <div className="img__box">
                {this.state.logoUrl && (
                  <img
                    className="preImg"
                    src={this.state.logoUrl}
                    alt="Hotel Logo"
                  />
                )}
              </div>
              <div>
                <label
                  htmlFor="logo_image_file"
                  className="file_upload_button"
                  data-detect="admin_booking_email_detail_button_upload"
                >
                  Upload
                </label>
                <input
                  id="logo_image_file"
                  type="file"
                  name="bookingEmailFile"
                  className="image_files blind"
                  onChange={this.onFileChange}
                />
              </div>
              <div className="text_name">
                {this.state.bookingEmailText ? (
                  <p className="file_textName">{this.state.bookingEmailText}</p>
                ) : (
                  <p className="file_textName">{"ex) My conect.png"}</p>
                )}
              </div>
            </div>
            <div className="button_form_container">
              <button
                className="edit_btn"
                data-detect="admin_booking_email_detail_button_edit"
                onClick={this.onSubmit}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = BookingEmailDetailData.mapStateToProps;
let mapDispatchToProps = BookingEmailDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BookingEmailDetailView)
);
