import CommonComponent from "../../components/CommonComponent";

// modules
import * as statisticsActions from "../../../redux/modules/Statistics";

class ReservationListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_RESERVATION_ALL") {
      this.onGetReservationListResults(props);
    }
    if (this.state.requestType == "GET_SYNC_ALL") {
      this.onGetSyncResults(props);
    }
    if (this.state.requestType == "GET_SYNC_CANCEL") {
      this.onGetSyncCancelResults(props);
    }
    if (this.state.requestType == "DELETE_RESERVATION") {
      this.onDeleteReservationResults(props);
    }
  }

  /* -------------------------------------------- request get reservation list -------------------------------------------- */
  requestGetReservationList = (pageNo, hotelId, date, type) => {
    this.setState({
      requestType: "GET_RESERVATION_ALL",
    });

    this.props.onGetAllReservation(pageNo, hotelId, date, type);
  };

  /* ---------------------------------------- request get sync reservation all ---------------------------------------- */
  requestGetSync = () => {
    this.setState({
      requestType: "GET_SYNC_ALL",
    });

    this.props.onGetSync();
  };

  /* --------------------------------------- request get sync reservation cancel -------------------------------------- */
  requestGetSyncCancel = () => {
    const { confirmationNo } = this.state;
    this.setState({
      isShowAllLoading: true,
      requestType: "GET_SYNC_CANCEL",
    });

    this.props.onGetSyncCancel(confirmationNo);
  };

  /* ------------------------------------------- request delete reservation one ------------------------------------------- */
  requestDeleteReservation = (reservationId, confirmationNo) => {
    this.setState({
      requestType: "DELETE_RESERVATION",
    });
    this.props.onDelete(reservationId, confirmationNo);
  };

  /* -------------------------------------------- get reservation list results -------------------------------------------- */
  onGetReservationListResults = (props) => {
    if (props.reservationList?.length === 0 && props.failData == null) {
      return;
    }

    if (props.reservationList != null || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props.reservationList?.success == true) {
      } else if (props.failData?.code != "INVALID_TOKEN") {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  onFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      // else
      return;
    }
  };

  /* -------------------------------------------- get sync reservation all -------------------------------------------- */
  onGetSyncResults = (props) => {
    if (props.reservationSync?.length === 0 && props.failData == null) {
      return;
    }

    if (props.reservationSync != null || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props.reservationSync?.success == true) {
        this.requestGetReservationList(this.state.pageNo, this.state.selectedOption, this.state.search, this.state.reservationStatus);
      } else if (props.failData?.code != "INVALID_TOKEN") {
        if (props.reservationSync.code == "EXIST_REQUEST") {
          const reason = props.reservationSync.reason;
          this.props.showSimpleTopPopupDialog(reason, () => {
            this.props.hideTopPopupDialog();
          });
        } else {
          this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_sync"], () => {
            this.props.hideTopPopupDialog();
          });
        }
      } else {
        this.onFailed(props);
      }
    }
  };

  /* ------------------------------------------- get sync cancel reservation ------------------------------------------ */
  onGetSyncCancelResults = (props) => {
    if (props.reservationSyncCancel?.length === 0 && props.failData == null) {
      return;
    }

    if (props.reservationSyncCancel != null || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props.reservationSyncCancel?.success == true) {
        this.requestGetReservationList(this.state.pageNo, this.state.selectedOption, this.state.search, this.state.reservationStatus);
      } else if (props.failData?.code != "INVALID_TOKEN") {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_sync"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  /* ------------------------------------------- delete reservation list results ------------------------------------------ */
  onDeleteReservationResults(props) {
    if (props?.successDelete?.length === 0 && props.failData == null) {
      return;
    }

    if (props?.successDelete != null || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props?.successDelete?.success == true) {
        // 수정이 완료된 후에 sync cancel 요청
        this.requestGetSyncCancel();
      } else if (props.failData?.code != "INVALID_TOKEN") {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_cancel"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  }

  onDeleteFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      reservationList: state.statistics.reservationList,
      failData: state.statistics.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAllReservation: (pageNo, hotelId, date, type) => dispatch(statisticsActions.onGetAllReservation(pageNo, hotelId, date, type)),
    };

    return returnProps;
  };
}

export default ReservationListData;
