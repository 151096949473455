import CommonComponent from "../../components/CommonComponent";

// modules
import * as idRecognitionActions from "../../../redux/modules/IDRecognition";

class IDRecognitionListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      idRecognitionList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.idRecognitionList != this.props.idRecognitionList) {
      if (this.state.requestType == 'GET_ID_RECOGNITION_LIST') {
        this.onGetIDRecognitionListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_ID_RECOGNITION_ONE") {
      this.onDeleteIDRecognitionListResults(nextProps);
    }
  }

  

  // redux에 get idRecognition list 요청
  requestGetIDRecognitionList = (pageNo, search, idType) => {
    this.setState({
      requestType: "GET_ID_RECOGNITION_LIST",
    });
    this.props.onGetAll(pageNo, search, idType);
  };
  // redux에 순차적으로 delete IDRecognition 요청
  requestDeleteIDRecognition = (idRecognitionId) => {
    this.setState({
      requestType: "DELETE_ID_RECOGNITION_ONE",
    });
    this.props.onDeleteOne(idRecognitionId);
  };

  // get idRecognition list results
  onGetIDRecognitionListResults = (props) => {
    if (props.idRecognitionList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.idRecognitionList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete idRecognition results
  onDeleteIDRecognitionListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteIDRecognition();
        } else {
          // 삭제 완료
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetIDRecognitionList(this.state.pageNo, this.state.search, this.state.idType);
  };


  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      idRecognitionList: state.idRecognition.idRecognitionList,
      failData: state.idRecognition.failData,
      successDelete: state.idRecognition.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, search, idType) => dispatch(idRecognitionActions.onGetAll(pageNo, search, idType)),
      onDeleteOne: (idRecognitionId) => dispatch(idRecognitionActions.onDelete(idRecognitionId)),
    };

    return returnProps;
  };
}

export default IDRecognitionListData;
