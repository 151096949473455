import CommonComponent from "../../components/CommonComponent";

// modules
import * as applicationEndPointActions from "../../../redux/modules/ApplicationEndPoint";
import * as authActions from "../../../redux/modules/Auth";

class ApplicationEndPointListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      applicationEndPointList: [],
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.applicationEndPointList != this.props.applicationEndPointList) {
      if (this.state.requestType == 'GET_APPLICATION_ENDPOINT_LIST') {
        this.onGetApplicationEndPointListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_APPLICATION_ENDPOINT_ONE") {
      this.onDeleteApplicationEndPointListResults(nextProps);
    }
  }

  // redux에 ApplicationEndPoint list 요청
  requestGetApplicationEndPointList = (pageNo, search) => {
    this.setState({
      requestType: "GET_APPLICATION_ENDPOINT_LIST",
    });
    this.props.onGetAll(pageNo, search);
  };

  // redux에 순차적으로 delete ApplicationEndPoint 요청
  requestDeleteApplicationEndPoint = (applicationEndPointId) => {
    this.setState({
      requestType: "DELETE_APPLICATION_ENDPOINT_ONE",
    });
    this.props.onDeleteOne(applicationEndPointId);
  };

  // get applicationEndPoint list results
  onGetApplicationEndPointListResults = (props) => {
    if (props.applicationEndPointList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationEndPointList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete applicationEndPoint results
  onDeleteApplicationEndPointListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteApplicationEndPoint();
        } else {
          // 수정이 완료된 후에 페이지 이동
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetApplicationEndPointList(this.state.pageNo, this.state.search);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (applicationEndPointList) => {
    const { checkedList } = this.state;
    const allApplicationEndPointCnt = applicationEndPointList.length;
    const isChecked = allApplicationEndPointCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : applicationEndPointList.map((applicationEndPoint) => applicationEndPoint.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      applicationEndPointList: state.applicationEndPoint.applicationEndPointList,
      failData: state.applicationEndPoint.failData,
      successDelete: state.applicationEndPoint.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAll: (pageNo, search) => dispatch(applicationEndPointActions.onGetAllApplicationEndPoint(pageNo, search)),
      onDeleteOne: (applicationEndPointId) => dispatch(applicationEndPointActions.onDeleteApplicationEndPoint(applicationEndPointId)),
    };

    return returnProps;
  };
}

export default ApplicationEndPointListData;
