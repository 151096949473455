import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import VersionAddData from "../Add/VersionAddData";


//css
import "../../../css/common.css";
import "../../../css/version/version/versionAdd.css";

class VersionAddView extends VersionAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      name: "",
      ios: "",
      iosDebug: "",
      andriod: "",
      andriodDebug: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change ios
  onChangeIos = (e) => {
    const inputValue = e.target.value;
    this.setState({ ios: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change iosDebug
  onChangeIosDebug = (e) => {
    const inputValue = e.target.value;
    this.setState({ iosDebug: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change andriod
  onChangeAndriod = (e) => {
    const inputValue = e.target.value;
    this.setState({ andriod: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change andriodDebug
  onChangeAndriodDebug = (e) => {
    const inputValue = e.target.value;
    this.setState({ andriodDebug: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("name", "admin_version_add_validation_name") &&
      this.confirmField("ios", "admin_version_add_validation_ios") &&
      this.confirmField("iosDebug", "admin_version_add_validation_ios_debug") &&
      this.confirmField("andriod", "admin_version_add_validation_andriod") &&
      this.confirmField("andriodDebug", "admin_version_add_validation_andriod_debug")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  // 데이터 저장을 로딩으로 감싸기
  add_btn = () => {
    this.onSubmit();
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = async () => {
    this.setState({
      isShowAllLoading: true,
    });
    await this.requestAddVersion(this.state.name, this.state.ios, this.state.iosDebug, this.state.andriod, this.state.andriodDebug);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/versionListPage");
  };

  render() {
    return (
      <div className="addVersion">
        {this.state.isShowAllLoading && <Loading />}
        <div className="version_add_container">
          <div className="addversion_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_version_add_title">ADD VERSION</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addversion_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addversion_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_add_label_version_name">Version Name</p>
                      </div>
                      <div className="detail_cell content version_address">
                        <input type="text" id="name" name="name" autoComplete="off" value={this.state.name} onChange={this.onChangeName} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_add_label_ios">IOS</p>
                      </div>
                      <div className="detail_cell content version_address">
                        <input type="text" id="ios" name="ios" autoComplete="off" value={this.state.ios} onChange={this.onChangeIos} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_add_label_ios_debug">IOS Debug</p>
                      </div>
                      <div className="detail_cell content version_address">
                        <select
                          id="ios_debug"
                          className="ios_debug_select"
                          name="ios_debug"
                          // value={this.state.iosDebug}
                          onChange={this.onChangeIosDebug}
                        >
                          <option disabled selected value="0" data-detect="admin_version_add_placeholder_ios_debug">
                            IOS Debug
                          </option>
                          <option value="true" data-detect="admin_version_add_select_ios_true">
                            true
                          </option>
                          <option value="false" data-detect="admin_version_add_select_ios_false">
                            false
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_add_label_android">Android</p>
                      </div>
                      <div className="detail_cell content version_name">
                        <input type="text" id="andriod" name="andriod" autoComplete="off" value={this.state.andriod} onChange={this.onChangeAndriod} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_add_label_android_debug">Android Debug</p>
                      </div>
                      <div className="detail_cell content version_address">
                        <select
                          id="andriod_debug"
                          className="andriod_debug_select"
                          name="andriod_debug"
                          // value={this.state.iosDebug}
                          onChange={this.onChangeAndriodDebug}
                        >
                          <option disabled selected value="0" data-detect="admin_version_add_placeholder_android_debug">
                            Android Debug
                          </option>
                          <option value="true" data-detect="admin_version_add_select_ios_true">
                            true
                          </option>
                          <option value="false" data-detect="admin_version_add_select_ios_false">
                            false
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_version_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_version_add_button_add" onClick={this.add_btn} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = VersionAddData.mapStateToProps;
let mapDispatchToProps = VersionAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VersionAddView));
