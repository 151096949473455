import $ from "jquery";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MenuData from "./MenuData";

// css
import "../../css/common/myConectErrorMessage.css";
import "./Menu.css";

// img
import closeIcon from "../../img/double_arrow_close.svg";
import openIcon from "../../img/double_arrow_open.svg";
import arrowDownIcon from "../../img/icon_arrowDown.svg";
import arrowUpIcon from "../../img/icon_arrowUp.svg";
import mailIcon from "../../img/icon_mail_white.svg";
import amenityIcon from "../../img/icon_menu_amenity.svg";
import authIcon from "../../img/icon_menu_auth_data.svg";
import emialIcon from "../../img/icon_menu_email_form.svg";
import endPointIcon from "../../img/icon_menu_endpoint.svg";
import FacilityIcon from "../../img/icon_menu_facility.svg";
import hotelIcon from "../../img/icon_menu_hotel.svg";
import keyLogIcon from "../../img/icon_menu_keylog.svg";
import locationIcon from "../../img/icon_menu_location.svg";
import userIcon from "../../img/icon_menu_member.svg";
import paymentIcon from "../../img/icon_menu_payment.svg";
import recommendAppIcon from "../../img/icon_menu_recommend.svg";
import settingIcon from "../../img/icon_menu_setting.svg";
import termsIcon from "../../img/icon_menu_terms.svg";
import transeferIcon from "../../img/icon_menu_transfer.svg";
import translationIcon from "../../img/icon_menu_translation.svg";
import versionIcon from "../../img/icon_menu_version.svg";
import idRecognitionIcon from "../../img/icon_menu_id_recognition.svg";
import statisticsIcon from "../../img/icon_statistics.svg";
import mainLogo from "../../img/login_Logo.svg";
import MenuLink from './MenuLink';

// menu
const utils = require("../../common/Utils");

class Menu extends MenuData {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      department: null,
      duty: null,
      isSharedToggleOn: true,
      isMenuArrowToggleOn: false,
    };
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidMount() {
    this.loadUserInfo();

    const { location } = this.props;
    if ($(window).width() >= 1400) {
      $(".hotel_menu").css({ transform: "translateX(0%)", transition: "0.5s" });
      $(".menu_arrow").css({ display: "none" });
      $("body").css({ paddingLeft: "300px" });
    } else {
      $(".hotel_menu").css({ transform: "translateX(-100%)", transition: "0.5s" });
      $(".menu_arrow").css({ display: "block" });
      $("body").css({ paddingLeft: "0px" });
      $(".contents_container").css({ paddingLeft: "0px" });
    }

    // 리사이즈 이벤트
    window.addEventListener("resize", this.handleWindowResize);

    let currentPath = location.pathname;
    this.focusMenu(currentPath);

    // login 페이지, download 페이지에서 메뉴바가 보이지 않도록 함.
    currentPath = currentPath.toLowerCase();
    if (currentPath.includes("login") || currentPath.includes("download") || currentPath.includes("otareservation")) {
      $(".hotel_menu").css({ display: "none" });
      $("body").css({ paddingLeft: "0px" });
    } else {
      $(".hotel_menu").css({ display: "block" });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location && prevProps.location.pathname !== this.props.location.pathname) {
      this.loadUserInfo();
      let currentPath = this.props.location.pathname;

      if ($(window).width() >= 1400) {
        $("body").css({ paddingLeft: "300px" });
        $(".hotel_menu").css({ transform: "translateX(0%)", transition: "0.5s" });
        $(".menu_arrow").css({ display: "none" });
      } else {
        $("body").css({ paddingLeft: "0px" });
        $(".hotel_menu").css({ transform: "translateX(-100%)", transition: "0.5s" });
        $(".menu_arrow").css({ display: "block" });
        this.setState({
          isMenuArrowToggleOn: false,
        });
      }

      this.focusMenu(currentPath);

      currentPath = currentPath.toLowerCase();
      // login 페이지, download 페이지에서 메뉴바가 보이지 않도록 함.
      if (currentPath.includes("login") || currentPath.includes("download") || currentPath.includes("otareservation")) {
        $(".hotel_menu").css({ display: "none" });
        $("body").css({ paddingLeft: "0px" });
      } else {
        $(".hotel_menu").css({ display: "block" });
      }
    }
  }

  // 하위 메뉴 화살표 버튼
  onshowSubMenuBtn = (target) => {
    if (target == "menuLocation") {
      this.setState((state) => ({
        isLocationToggleOn: !state.isLocationToggleOn,
      }));
    }
    if (target == "menuVersion") {
      this.setState((state) => ({
        isVersionToggleOn: !state.isVersionToggleOn,
      }));
    }
    if (target == "menuEmail") {
      this.setState((state) => ({
        isEmailToggleOn: !state.isEmailToggleOn,
      }));
    }
    if (target == "menuTransfer") {
      this.setState((state) => ({
        isTransferToggleOn: !state.isTransferToggleOn,
      }));
    }
    if (target == "menuTerms") {
      this.setState((state) => ({
        isTermsToggleOn: !state.isTermsToggleOn,
      }));
    }
    if (target == "menuAuth") {
      this.setState((state) => ({
        isAuthToggleOn: !state.isAuthToggleOn,
      }));
    }

    $(`#${target} > div`).slideToggle();
  };

  onshowMenuOpenCloseBtn = () => {
    const { isMenuArrowToggleOn } = this.state;
    this.setState((state) => ({
      isMenuArrowToggleOn: !state.isMenuArrowToggleOn,
    }));
    if (isMenuArrowToggleOn) {
      $(".hotel_menu").css({
        transform: "translateX(-100%)",
        transition: "0.5s",
      });
      $(".menu_arrow").removeClass("close_menu");
    } else {
      $(".hotel_menu").css({ transform: "translateX(0%)", transition: "0.5s" });
      $(".menu_arrow").addClass("close_menu");
    }
  };

  handleWindowResize = () => {
    this.setState((state) => ({
      isMenuArrowToggleOn: false,
    }));
    $(".menu_arrow").removeClass("close_menu");
    if ($(window).width() >= 1400) {
      $(".hotel_menu").css({ transform: "translateX(0%)", transition: "0.5s" });
      $(".menu_arrow").css({ display: "none" });
      $("body").css({ paddingLeft: "300px" });
      $(".contents_container").css({ paddingLeft: "300px" });
    } else {
      $(".hotel_menu").css({
        transform: "translateX(-100%)",
        transition: "0.5s",
      });
      $(".menu_arrow").css({ display: "block" });
      $("body").css({ paddingLeft: "0px" });
      $(".contents_container").css({ paddingLeft: "0px" });
    }
    let currentPath = this.props.location.pathname.toLowerCase();

    if (currentPath.includes("login") || currentPath.includes("download") || currentPath.includes("otareservation")) {
      // login 페이지에서 메뉴바가 보이지 않도록 함.
      $("body").css({ paddingLeft: "0px" });
      $(".hotel_menu").css({ display: "none" });
    } else {
      $(".hotel_menu").css({ display: "block" });
    }
  };

  focusMenu = (path) => {
    let currentPath = path;
    // current path에 매칭되는 메뉴 버튼을 포커스함.
    if (
      currentPath.includes("changePassword") ||
      currentPath.includes("forgotId") ||
      currentPath.includes("forgotPassword") ||
      currentPath.includes("createEmailConfirm") ||
      currentPath.includes("emailConfirmFailure") ||
      currentPath.includes("emailConfirmSuccess") ||
      currentPath.includes("emailConfirmSuccess") ||
      currentPath.includes("profile") ||
      currentPath.includes("withdraw") ||
      currentPath.includes("bookingEmail")
    ) {
      currentPath = currentPath.replace(/EditPage/g, "EditPage");
    } else if (currentPath.includes("bookingEmail")) {
      currentPath = currentPath.replace(/DetailPage/g, "DetailPage");
    } else if (currentPath.includes("paymentLog")) {
      currentPath = currentPath.replace(/paymentLogListPage|paymentLogDetailPage/g, "paymentListPage");
    } else {
      currentPath = currentPath.replace(/AddPage|DetailPage|EditPage|copyPage|UpdatePage|DetailInfoPage/g, "ListPage");
    }

    // currentPath에 해당되는 메뉴에 focus 클래스 추가
    this.setState({ currentPath }, () => {
      $(".pageLink").removeClass("focus");
      $(`.pageLink[href="${this.state.currentPath}"]`).addClass("focus");
    });
  };

  onClickLogOutButton = () => {
    this.requestLogout();
  };

  loadUserInfo = () => {
    const loginData = localStorage.getItem("accessToken");

    if (loginData && this.props.sessionData == null) {
      // 토큰이 있고, sessionData가 없을 때만 session 요청함.
      this.requestGetSession();
    }
  };

  render() {
    const { sessionData } = this.props;

    // 현재 pathname
    let currentPathname = this.props.history.location.pathname;

    // get auth Data
    let name;
    let email;
    let department;
    let duty;
    if (sessionData != null && sessionData?.success == true) {
      name = sessionData.results.name;
      department = sessionData.results.department;
      duty = sessionData.results.duty;
      email = sessionData.results.email;
    }
    // html 렌더링
    return (
      <div className="hotel_menu">
        <nav>
          <div className="menu_container">
            <div className="menu_inner">
              <div className="hotel_logo">
                <img src={mainLogo} alt="MyConnect Logo" />
              </div>
              <div className="info">
                <div>
                  {/* user information */}
                  <div className="userName">{name}</div>
                  <div className="userCompany">{department}</div>
                  <ul className="loginInfo">
                    <li className="password" style={{ fontWeight: "bolder" }}>
                      <MenuLink to="/passwordEditPage" data-detect="admin_header_link_password">
                        Password
                      </MenuLink>
                    </li>
                    <li>
                      <button className="logoutBtn" type="button" onClick={this.onClickLogOutButton} data-detect="admin_header_link_logout">
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="menulist">
                <ul>
                  <li className="on menu_hotel">
                    <div className="depth1">
                      <MenuLink to="/hotelListPage" className="pageLink">
                        <img src={hotelIcon} alt="hotelIcon" className="menu_img" />
                        <span data-detect="admin_header_link_hotel">Hotel</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_setting">
                    <div className="depth1">
                      <MenuLink to="/settingListPage" className="pageLink">
                        <img src={settingIcon} alt="settingIcon" className="menu_img" />
                        <span data-detect="admin_header_link_setting">Setting</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_location slideActionMenu" id="menuLocation">
                    <span className="location depth1" onClick={() => this.onshowSubMenuBtn("menuLocation")}>
                      <div>
                        <img src={locationIcon} alt="locationIcon" className="menu_img" />
                        <span data-detect="admin_header_accordion_location">Location</span>
                      </div>
                      <div className="menuLocationArrowIcon">
                        <img src={this.state.isLocationToggleOn ? arrowUpIcon : arrowDownIcon} alt="toggle arrow" />
                      </div>
                    </span>
                    <div
                      className="slide_menu"
                      style={{
                        display: "none",
                      }}
                    >
                      <MenuLink to="/regionListPage" className="pageLink" data-detect="admin_header_link_region_list">
                        Region List
                      </MenuLink>
                      <MenuLink to="/countryListPage" className="pageLink" data-detect="admin_header_link_country_list">
                        Country List
                      </MenuLink>
                      <MenuLink to="/cityListPage" className="pageLink" data-detect="admin_header_link_city_list">
                        City List
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_application_user">
                    <div className="depth1">
                      <MenuLink to="/appListPage" className="pageLink">
                        <img src={userIcon} alt="userIcon" className="menu_img" />
                        <span data-detect="admin_header_link_application_user">Application User</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_payment">
                    <div className="depth1">
                      <MenuLink to="/paymentListPage" className="pageLink">
                        <img src={paymentIcon} alt="paymentIcon" className="menu_img" />
                        <span data-detect="admin_header_link_payment">Payment</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_version slideActionMenu" id="menuVersion">
                    <span className="menuVersion depth1" onClick={() => this.onshowSubMenuBtn("menuVersion")}>
                      <div>
                        <img src={versionIcon} alt="versionIcon" className="menu_img" />
                        <span data-detect="admin_header_accordion_version">Version</span>
                      </div>
                      <div className="menuVersionArrowIcon">
                        <img src={this.state.isVersionToggleOn ? arrowUpIcon : arrowDownIcon} alt="toggle arrow" />
                      </div>
                    </span>
                    <div
                      className="slide_menu"
                      style={{
                        display: "none",
                      }}
                    >
                      <MenuLink to="/versionListPage" className="pageLink" data-detect="admin_header_link_version">
                        Version
                      </MenuLink>
                      <MenuLink to="/applicationVersionListPage" className="pageLink" data-detect="admin_header_link_application_down_version_list">
                        Application Download Version
                      </MenuLink>
                      <MenuLink to="/guestApplicationVersionListPage" className="pageLink" data-detect="admin_header_link_guest_application_down_list">
                        Guest Application Download
                      </MenuLink>
                      <MenuLink to="/staffApplicationVersionListPage" className="pageLink" data-detect="admin_header_link_staff_application_down_list">
                        Staff Application Download
                      </MenuLink>
                      <MenuLink to="/parkingStaffApplicationVersionListPage" className="pageLink" data-detect="admin_header_link_paking_staff_application_down_list">
                        Paking Staff Application Download
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_application_translation">
                    <div className="depth1">
                      <MenuLink to="/applicationTranslationListPage" className="pageLink">
                        <img src={translationIcon} alt="translationIcon" className="menu_img" />
                        <span data-detect="admin_header_link_application_translation">Application Translation</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_booking_email_image">
                    <div className="depth1">
                      <MenuLink to="/bookingEmailDetailPage" className="pageLink">
                        <img src={mailIcon} alt="mailIcon" className="menu_img" />
                        <span data-detect="admin_header_link_booking_email">Booking E-mail</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_email_form" id="menuEmail">
                    <span className="email_form depth1" onClick={() => this.onshowSubMenuBtn("menuEmail")}>
                      <div>
                        <img src={emialIcon} alt="emialIcon" className="menu_img" />
                        <span data-detect="admin_header_accordion_email_form">E-mail Form</span>
                      </div>
                      <div className="menuEmailArrowIcon">
                        <img src={this.state.isEmailToggleOn ? arrowUpIcon : arrowDownIcon} alt="toggle arrow" />
                      </div>
                    </span>
                    <div
                      className="slide_menu"
                      style={{
                        display: "none",
                      }}
                    >
                      <MenuLink to="/changePasswordEditPage" className="pageLink" data-detect="admin_header_link_change_password">
                        Change Password
                      </MenuLink>
                      <MenuLink to="/forgotIdEditPage" className="pageLink" data-detect="admin_header_link_forgot_id">
                        Forgot Id
                      </MenuLink>
                      <MenuLink to="/forgotPasswordEditPage" className="pageLink" data-detect="admin_header_link_forgot_password">
                        Forgot Password
                      </MenuLink>
                      <MenuLink to="/createEmailConfirmEditPage" className="pageLink" data-detect="admin_header_link_create_confirm_email">
                        Create E-mail Confirm
                      </MenuLink>
                      <MenuLink to="/emailConfirmFailureEditPage" className="pageLink" data-detect="admin_header_link_create_confirm_email_fail">
                        Create E-mail Confirm Failure
                      </MenuLink>
                      <MenuLink to="/emailConfirmSuccessEditPage" className="pageLink" data-detect="admin_header_link_create_confirm_email_success">
                        Create E-mail Confirm Success
                      </MenuLink>
                      <MenuLink to="/profileEditPage" className="pageLink" data-detect="admin_header_link_update_profile">
                        Update Profile
                      </MenuLink>
                      <MenuLink to="/withdrawEditPage" className="pageLink" data-detect="admin_header_link_withdraw">
                        Withdraw
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_transfer" id="menuTransfer">
                    <span className="transfer_log depth1" onClick={() => this.onshowSubMenuBtn("menuTransfer")}>
                      <div>
                        <img src={transeferIcon} alt="transeferIcon" className="menu_img" />
                        <span data-detect="admin_header_accordion_transfer">Transfer</span>
                      </div>
                      <div className="menuTransferArrowIcon">
                        <img src={this.state.isTransferToggleOn ? arrowUpIcon : arrowDownIcon} alt="toggle arrow" />
                      </div>
                    </span>
                    <div
                      className="slide_menu"
                      style={{
                        display: "none",
                      }}
                    >
                      <MenuLink to="/transferLogListPage" className="pageLink" data-detect="admin_header_link_transfer_log_list">
                        Transfer Log List
                      </MenuLink>
                      <MenuLink to="/userTransferLogListPage" className="pageLink" data-detect="admin_header_link_user_transfer_log_list">
                        User Transfer Log List
                      </MenuLink>
                      <MenuLink to="/transactionLogListPage" className="pageLink" data-detect="admin_header_link_transaction_log_list">
                        Transaction Log List
                      </MenuLink>
                      <MenuLink to="/userTransferLogHistoryListPage" className="pageLink" data-detect="admin_header_link_user_transfer_log_history">
                        User Transfer Log History
                      </MenuLink>
                      <MenuLink to="/staffTransferLogHistoryListPage" className="pageLink" data-detect="admin_header_link_staff_transfer_log_history">
                        Staff Transfer Log History
                      </MenuLink>
                      <MenuLink to="/userNotificationLogListPage" className="pageLink" data-detect="admin_header_link_user_notification_log_list">
                        User Notification Log List
                      </MenuLink>
                      <MenuLink to="/staffNotificationLogListPage" className="pageLink" data-detect="admin_header_link_staff_notification_log_list">
                        Staff Notification Log List
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_endpoint">
                    <div className="depth1">
                      <MenuLink to="/applicationEndPointListPage" className="pageLink">
                        <img src={endPointIcon} alt="endPointIcon" className="menu_img" />
                        <span data-detect="admin_header_link_app_endpoint">Application Endpoint</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_terms" id="menuTerms">
                    <span className="terms_and_conditions depth1" onClick={() => this.onshowSubMenuBtn("menuTerms")}>
                      <div>
                        <img src={termsIcon} alt="termsIcon" className="menu_img" />
                        <span data-detect="admin_header_accordion_terms_and_conditions">Terms and Conditions</span>
                      </div>
                      <div className="menuTermsArrowIcon">
                        <img src={this.state.isTermsToggleOn ? arrowUpIcon : arrowDownIcon} alt="toggle arrow" />
                      </div>
                    </span>
                    <div
                      className="slide_menu"
                      style={{
                        display: "none",
                      }}
                    >
                      <MenuLink to="/termsListPage" className="pageLink" data-detect="admin_header_link_terms">
                        Terms
                      </MenuLink>
                      <MenuLink to="/termsGroupListPage" className="pageLink" data-detect="admin_header_link_terms_group">
                        Terms Group
                      </MenuLink>
                      <MenuLink to="/agreementHistoryListPage" className="pageLink" data-detect="admin_header_link_agreement_history">
                        Agreement History
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_auth_data" id="menuAuth">
                    <span className="auth_data depth1" onClick={() => this.onshowSubMenuBtn("menuAuth")}>
                      <div>
                        <img src={authIcon} alt="authIcon" className="menu_img" />
                        <span data-detect="admin_header_accordion_auth_data">Auth Data</span>
                      </div>
                      <div className="menuAuthArrowIcon">
                        <img src={this.state.isAuthToggleOn ? arrowUpIcon : arrowDownIcon} alt="toggle arrow" />
                      </div>
                    </span>
                    <div
                      className="slide_menu"
                      style={{
                        display: "none",
                      }}
                    >
                      <MenuLink to="/authDataListPage" className="pageLink" data-detect="admin_header_link_auth_data_log_list">
                        Auth Data List
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_use_key_log">
                    <div className="depth1">
                      <MenuLink to="/useKeyLogListPage" className="pageLink">
                        <img src={keyLogIcon} alt="keyLogIcon" className="menu_img" />
                        <span data-detect="admin_header_link_use_key_log">Use Key Log</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_amenity">
                    <div className="depth1">
                      <MenuLink to="/amenityListPage" className="pageLink">
                        <img src={amenityIcon} alt="amenityIcon" className="menu_img" />
                        <span data-detect="admin_header_link_amenity">Amenity</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_facility">
                    <div className="depth1">
                      <MenuLink to="/facilityImageListPage" className="pageLink">
                        <img src={FacilityIcon} alt="FacilityIcon" className="menu_img" />
                        <span data-detect="admin_header_link_facility_image">Facility Image</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_recommend">
                    <div className="depth1">
                      <MenuLink to="/recommendListPage" className="pageLink">
                        <img src={recommendAppIcon} alt="recommendAppIcon" className="menu_img" />
                        <span data-detect="admin_header_link_recommend_app">Recommend App</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_id_recognition_information">
                    <div className="depth1">
                      <MenuLink to="/idRecognitionListPage" className="pageLink">
                        <img src={idRecognitionIcon} alt="idRecognitionIcon" className="menu_img" />
                        <span data-detect="admin_header_link_id_recognition_information">ID Recognition Information</span>
                      </MenuLink>
                    </div>
                  </li>
                  <li className="menu_statistics">
                    <div className="depth1">
                      <MenuLink to="/statisticsListPage" className="pageLink">
                        <img src={statisticsIcon} alt="statistics" className="menu_img" />
                        <span data-detect="admin_header_link_statistics">Statistics</span>
                      </MenuLink>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom_container">
              <div className="version">
                <div>
                  <p data-detect="admin_header_current_version">Current Version</p>
                  <span>V1.0.1</span>
                </div>
                <div>
                  <p data-detect="admin_header_translation_version">Translation Version</p>
                  <span>V0.0.4</span>
                </div>
              </div>
              <div className="adminUser">
                <MenuLink to="/userInfoListPage" className="pageLink admin_user" data-detect="admin_header_link_admin_user" style={{ backgroundColor: "transparent" }}>
                  Admin
                </MenuLink>
              </div>
            </div>
          </div>
          <div className="menu_arrow" onClick={() => this.onshowMenuOpenCloseBtn()}>
            <img src={this.state.isMenuArrowToggleOn ? closeIcon : openIcon} alt="arrow open" />
          </div>
        </nav>
      </div>
    );
  }
}

const { mapStateToProps } = MenuData;
const { mapDispatchToProps } = MenuData;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
