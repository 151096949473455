import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import UserTransferLogHistoryDetailData from "../Detail/userTransferLogHistoryDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/userTransferLogHistory/userTransferLogHistoryDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class UserTransferLogHistoryDetailView extends UserTransferLogHistoryDetailData {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      isShowAllLoading: true,
      transferCode: "",
    };
  }

  getBeautifyJson = (json) => {
    if (json == null || json == "" || json == undefined) return "-";
    
    return JSON.stringify(json, null, 6).replace(/\n( *)/g, function(
      match,
      p1
    ) {
      return "<br>" + "&nbsp;".repeat(p1.length);
    });
  };
  // 첫 실행시 URL에서 paymentId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const transferCode = params.get("transferCode");
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.setState(
        {
          transferCode: transferCode,
        },
        () => {
          this.requestGetUserTransferLogDetailHistory(this.state.transferCode);
          this.props.history.replace(location.pathname, this.state);
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkuserTransferLogDetail(id);
    }
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //파람값 url로 전달
  linkuserTransferLogDetail = (userTransferId) => {
    this.props.history.push(
      `../userTransferLogHistoryDetailInfoPage?userTransferId=${userTransferId}`
    );
  };

  // success 여부를 반환
  isSuccess = (body) => {
    // success 정리
    if(body == null || body== "") return;

    let success = "-";

    let includeTrue = body.indexOf('true')
    let includeFalse = body.indexOf('false')

    if (includeTrue == -1 && includeFalse == -1) {
      success = '-'
    } else if ((includeTrue == -1 ? Number.MAX_SAFE_INTEGER : includeTrue) < (includeFalse == -1 ? Number.MAX_SAFE_INTEGER : includeFalse)) {
      success = 'true'
    } else {
      success = 'false'
    }
    return success;
  }

  render() {
    let userTransferLogHistoryDetail = [];
    if (
      this.props.userTransferLogHistoryDetail != null &&
      this.props.userTransferLogHistoryDetail?.success == true
    ) {
      userTransferLogHistoryDetail = this.props.userTransferLogHistoryDetail.results.rows;
    }

    return (
      <div className="userTransferLogHistoryDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="user_transfer_list_container">
          <div className="user_transfer_list_container_inner">
            <div
              className="title"
              data-detect="admin_user_transfer_log_history_detail_title"
            >
              User Transfer Detail History
            </div>
            <div className="btn_container_left"></div>
            <div className="btn_container"></div>
            <div className="user_transfer_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div
                      id="theadFistCell"
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_no"
                    >
                      NO.
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_type"
                    >
                      Type
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_method"
                    >
                      Method
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_path"
                    >
                      Path
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_status"
                    >
                      Status
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_success"
                    >
                      Success
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_user_email"
                    >
                      User Email
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_user_transfer_log_history_detail_label_created_at"
                    >
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {userTransferLogHistoryDetail.length > 0 &&
                    userTransferLogHistoryDetail.map(
                      (userTransferLogDetailHistory, index) => (
                        <div
                          className={`row ${
                            (userTransferLogDetailHistory.type == "RESPONSE" &&
                              userTransferLogDetailHistory.status !== "-" &&
                              userTransferLogDetailHistory.status !== "200") ||
                              this.isSuccess(userTransferLogDetailHistory.body) == "false"
                              ? "ROWRED"
                              : ""
                          }`}
                          key={userTransferLogDetailHistory.id}
                          onMouseUp={() => this.handleMouseUp(userTransferLogDetailHistory.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          <div className="cell">
                            {userTransferLogDetailHistory.id || "-"}
                          </div>
                          <div className={`cell ${userTransferLogDetailHistory.type}`}>
                            {userTransferLogDetailHistory.type || "-"}
                          </div>
                          <div className="cell">
                            {userTransferLogDetailHistory.method || "-"}
                          </div>
                          <div className="cell">
                            {userTransferLogDetailHistory.path || "-"}
                          </div>
                          <div className="cell">
                            {userTransferLogDetailHistory.status || "-"}
                          </div>
                          <div className="cell">
                            {(() => {
                              if(userTransferLogDetailHistory.body == null) return;

                              var success = "-";
                              var includeTrue = userTransferLogDetailHistory.body.indexOf(
                                "true"
                              );
                              var includeFalse = userTransferLogDetailHistory.body.indexOf(
                                "false"
                              );

                              if (includeTrue == -1 && includeFalse == -1) {
                                success = "-";
                              } else if (
                                (includeTrue == -1
                                  ? Number.MAX_SAFE_INTEGER
                                  : includeTrue) <
                                (includeFalse == -1
                                  ? Number.MAX_SAFE_INTEGER
                                  : includeFalse)
                              ) {
                                success = "true";
                              } else {
                                success = "false";
                              }

                              return success;
                            })()}
                          </div>
                          <div className="cell">
                            {userTransferLogDetailHistory.userEmail || "-"}
                          </div>
                          <div className="cell">
                            {userTransferLogDetailHistory &&
                              formatDateTimeDot(
                                userTransferLogDetailHistory.created_at
                              )}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="button_container">
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_hotel_detail_button_go_to_list"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserTransferLogHistoryDetailData.mapStateToProps;
let mapDispatchToProps = UserTransferLogHistoryDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserTransferLogHistoryDetailView)
);
