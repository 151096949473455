import CommonComponent from "../../components/CommonComponent";

// modules
import * as applicationTranslationActions from "../../../redux/modules/ApplicationTranslation";

class ApplicationTranslationListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      applicationTranslationList: [],
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.applicationTranslationList != this.props.applicationTranslationList) {
      if (this.state.requestType == 'GET_APPLICATION_TRANSLATION_LIST') {
        this.onGetApplicationTranslationListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_APPLICATION_TRANSLATION_ONE") {
      this.onDeleteApplicationTranslationListResults(nextProps);
    }
  }

  // 검색시 redux에 조건에 맞는 list 요청
  requestGetApplicationTranslationList = (pageNo, appType, osType, appVersion, search) => {
    this.setState({
      requestType: "GET_APPLICATION_TRANSLATION_LIST",
    });
    this.props.onGetAllApplicationTranslation(pageNo, appType, osType, appVersion, search);
  };

  // redux에 순차적으로 delete ApplicationTranslation 요청
  requestDeleteApplicationTranslation = (applicationTranslationId) => {
    this.setState({
      requestType: "DELETE_APPLICATION_TRANSLATION_ONE",
    });
    this.props.onDeleteOne(applicationTranslationId);
  };

  // get applicationTranslation list results
  onGetApplicationTranslationListResults = (props) => {
    if (props.applicationTranslationList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationTranslationList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete applicationTranslation results
  onDeleteApplicationTranslationListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteApplicationTranslation();
        } else {
          // 수정이 완료된 후에 페이지 이동
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetApplicationTranslationList(this.state.pageNo, this.state.appType, this.state.osType, this.state.appVersion, this.state.search);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationTranslationList: state.applicationTranslation.applicationTranslationList,
      failData: state.applicationTranslation.failData,
      successDelete: state.applicationTranslation.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAllApplicationTranslation: (pageNo, appType, osType, appVersion, search) =>
        dispatch(applicationTranslationActions.onGetAllApplicationTranslation(pageNo, appType, osType, appVersion, search)),
      onDeleteOne: (applicationTranslationId) => dispatch(applicationTranslationActions.onDeleteApplicationTranslation(applicationTranslationId)),
    };

    return returnProps;
  };
}

export default ApplicationTranslationListData;
