import CommonComponent from "../../../components/CommonComponent";

// module
import * as authActions from "../../../../redux/modules/Auth";
import * as transferActions from "../../../../redux/modules/Transfer";



class StaffNotificationLogDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_STAFF_TRANSFER_LOG_DETAIL") {

      this.onGetStaffNotificationLogResults(props);
    }
  }

  // redux에 get payment Detail 요청
  requestGetStaffNotificationLogDetail = (staffNotificationLogId) => {
    this.setState({
      requestType: "GET_STAFF_TRANSFER_LOG_DETAIL",
    });
    this.props.onGetStaffNotificationLogDetail(staffNotificationLogId);
  };

  // get payment One results
  onGetStaffNotificationLogResults = (props) => {
    if (props.staffNotificationLogDetail == null && props.failData == null) {
      return;
    }

    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.staffNotificationLogDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(props.staffNotificationLogDetail.reason ?? this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      staffNotificationLogDetail: state.transfer.staffNotificationLogDetail,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetStaffNotificationLogDetail: (staffNotificationLogId) =>
        dispatch(
          transferActions.onGetStaffNotificationLogDetail(
            staffNotificationLogId
          )
        ),
    };
  };
}

export default StaffNotificationLogDetailData;
