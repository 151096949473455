// IDRecognitionAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as idRecognitionActions from "../../../redux/modules/IDRecognition";

class IDRecognitionAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_ID_RECOGNITION") {
      this.onAddIDRecognitionResults(props);
    }
  }

  // redux에 add idRecognition 요청
  requestAddIDRecognition = (idRecognitionInfo) => {
    this.setState({
      requestType: "ADD_ID_RECOGNITION",
    });
    this.props.onAdd(idRecognitionInfo);
  };

  // get add idRecognition results
  onAddIDRecognitionResults = (props) => {
    if (props.idRecognitionAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.idRecognitionAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_id_recognition_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/idRecognitionListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      idRecognitionAdd: state.idRecognition.idRecognitionAdd,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (idRecognitionInfo) => dispatch(idRecognitionActions.onAdd(idRecognitionInfo)),
    };
  };
}

export default IDRecognitionAddData;
