import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import CountryAddData from "../Add/CountryAddData";


//css
import "../../../css/common.css";
import "../../../css/location/country/countryAdd.css";

class CountryAddView extends CountryAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      country: "",
      regionId: "",
      regionList: [],
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change country
  onChangeCountry = (e) => {
    const inputValue = e.target.value;
    this.setState({ country: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change regionId
  onChangeRegion = (regionId) => {
    const inputValue = regionId;
    this.setState({ regionId: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("regionId", "admin_country_add_validation_region") && //
      this.confirmField("country", "admin_country_add_validation_country")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddCountry(this.state.regionId, this.state.country);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/countryListPage");
  };

  render() {
    let regionList = [];
    if (this.props.regionList.length !== 0 && this.props.regionList?.success == true) {
      regionList = this.props.regionList.results;
    }

    return (
      <div className="addCountry">
        {this.state.isShowAllLoading && <Loading />}
        <div className="country_add_container">
          <div className="addcountry_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_country_add_title">ADD REGION</p>
              </div>
              <div className="sub_text">
                <p data-detect="admin_country_add_guide_click_add">Enter information to add REGION. And Click the "Add" button.</p>
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addcountry_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addcountry_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_country_add_label_region">Region</p>
                      </div>
                      <div className="detail_cell content country_name">
                        <div className="detail_selectBox">
                          <div>
                            <select
                              className="region_select"
                              id="regionSelect"
                              onChange={(e) => {
                                const selectedRow = regionList.rows.find((row) => row.name == e.target.value);
                                this.onChangeRegion(selectedRow.id);
                              }}
                              value={this.state.regionId}
                            >
                              <option disabled value="" data-detect="admin_country_add_label_choose">
                                Choose
                              </option>
                              {regionList &&
                                regionList.rows.map((region) => (
                                  <option key={region.id} value={region.name}>
                                    {region.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_country_add_label_country">Country</p>
                        </div>
                      </div>
                      <div className="detail_cell content country_name">
                        <input
                          type="text"
                          id="country"
                          name="country"
                          data-detect-placeholder="admin_country_add_placeholder_country_name"
                          placeholder="Country Name"
                          autoComplete="off"
                          value={this.state.country}
                          onChange={this.onChangeCountry}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_country_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_country_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CountryAddData.mapStateToProps;
let mapDispatchToProps = CountryAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryAddView));
