import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import CityDetailData from "../Detail/CityDetailData";

//css
import "../../../css/common.css";
import "../../../css/location/city/cityDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class CityDetailView extends CityDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      cityId: "",
      cityDetail: null,
    };
  }

  // 첫 실행시 URL에서 cityId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const cityId = params.get("cityId");
    this.setState({
      cityId: cityId,
    });
    this.requestGetCityOne(cityId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkCityEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const cityId = params.get("cityId");
    this.props.history.push(`/cityEditPage?cityId=${cityId}`);
  };



  render() {
    let cityDetail = null;

    if (
      this.props.cityDetail != null &&
      this.props.cityDetail?.success == true
    ) {
      cityDetail = this.props.cityDetail.results;
    }

    return (
      <div className="cityDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="cityinfo_detail_container">
          <div className="cityinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_header_link_city_list"
            >
              city Information
            </div>
            <div className="cityinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="city_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_city_list_label_region"
                      >
                        Region
                      </div>
                      <div
                        className="detail_cell content region_name"
                        style={{ color: "white" }}
                      >
                        <span id="region_id">
                          {cityDetail?.hotelCountry.hotelRegion.name??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_city_list_label_country"
                      >
                        Country
                      </div>
                      <div
                        className="detail_cell content country_name"
                        style={{ color: "white" }}
                      >
                        <span id="country_id">
                          {cityDetail?.hotelCountry.name??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_city_list_label_city"
                      >
                        City
                      </div>
                      <div
                        className="detail_cell content city_name"
                        style={{ color: "white" }}
                      >
                        <span id="country_id">
                          {cityDetail?.name??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_endpoint_list_label_created_at"
                      >
                        Created At
                      </div>
                      <div
                        className="detail_cell content created_at"
                        style={{ color: "white" }}
                      >
                        {cityDetail ?
                          formatDateTimeDot(
                            cityDetail && cityDetail.created_at
                          ) : "-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_terms_detail_label_updated_at"
                      >
                        Updated At
                      </div>
                      <div
                        className="detail_cell content updated_at"
                        style={{ color: "white" }}
                      >
                        {cityDetail ?
                          formatDateTimeDot(
                            cityDetail && cityDetail.updated_at
                          ) : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_city_detail_button_go_to_list"
                >
                  {" "}
                  Go to List{" "}
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkCityEdit();
                  }}
                  data-detect="admin_city_detail_button_edit"
                >
                  {" "}
                  Edit{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CityDetailData.mapStateToProps;
let mapDispatchToProps = CityDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CityDetailView)
);
