// TransferLogListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import AgreementHistoryDetailListData from "../List/AgreementHistoryData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/termsAndCondition/AgreementHistory/agreementHistoryList.css";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class AgreementHistoryDetailListView extends AgreementHistoryDetailListData {
  constructor(props) {
    super(props);

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      email: "",
      search: "",

      // 임시값
      tempSearch: "",
      tempEmail: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetAgreementHistoryList(
        this.state.pageNo,
        this.state.email,
        this.state.search
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = e.target.value;

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        search: this.state.tempSearch,
        email: this.state.tempEmail,
      },
      () => {
        this.requestGetAgreementHistoryList(1, this.state.email, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          email: this.state.tempEmail,
        },
        () => {
          this.requestGetAgreementHistoryList(this.state.pageNo, this.state.email, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempEmail: this.state.email }, () => {
      this.requestGetAgreementHistoryList(this.state.pageNo, this.state.email, this.state.search); // 호텔 목록 요청
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkagreementHistoryDetailDetail(id);
    }
  }

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  //파람값 url로 전달
  linkagreementHistoryDetailDetail = (agreementHistoryId) => {
    this.props.history.push(
      `/agreementHistoryDetailPage?agreementHistoryId=${agreementHistoryId}`
    );
  };

  render() {
    let count;
    let agreementHistoryList = [];
    if (
      this.props.agreementHistoryList &&
      this.props.agreementHistoryList?.success == true
    ) {
      agreementHistoryList = this.props.agreementHistoryList.results.rows;
      count = this.props.agreementHistoryList.results.count;
    }
    
    return (
      <div className="agreementHistoryList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="agreementHistory_list_container">
          <div className="agreementHistory_list_container_inner">
            <div className="title" data-detect="admin_agreement_list_title">
              AGREEMENT HISTORY LIST
            </div>

            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search search_by_id">
                    <input
                      type="search"
                      id="search_input_email"
                      data-detect-placeholder="admin_agreement_list_placeholder_email"
                      placeholder="Email"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempEmail")}
                      value={this.state.tempEmail}
                    />
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_agreement_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="agreementHistory_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_agreement_list_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_agreement_list_label_user_id">
                      User Id
                    </div>

                    <div className="cell" data-detect="admin_agreement_list_label_terms_id">
                      Terms ID
                    </div>
                    <div className="cell" data-detect="admin_agreement_list_label_agreed">
                      Agreed
                    </div>
                    <div className="cell" data-detect="admin_agreement_list_label_email">
                      Email
                    </div>
                    <div className="cell" data-detect="admin_agreement_list_label_name">
                      Name
                    </div>
                    <div className="cell" data-detect="admin_agreement_list_label_file_name">
                      File Name
                    </div>
                    <div className="cell" data-detect="admin_agreement_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {agreementHistoryList.length > 0 ? (
                    agreementHistoryList.map((agreementHistoryDetail, index) => (
                      <div className="row tbodyRow" key={agreementHistoryDetail.id}>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {agreementHistoryDetail.id || "-"}
                        </div>
                        <div
                          className={`cell ${agreementHistoryDetail.type}`}
                          onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {agreementHistoryDetail.user.id || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {agreementHistoryDetail.termsId || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {agreementHistoryDetail.agreed || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {agreementHistoryDetail.user.email || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {agreementHistoryDetail.user.gender + agreementHistoryDetail.user.lastName + agreementHistoryDetail.user.firstName || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {agreementHistoryDetail.terms.fileName || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(agreementHistoryDetail.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {(agreementHistoryDetail && formatDateTimeSlash(agreementHistoryDetail.created_at)) || "-"}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_terms_list_guide_empty"></div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AgreementHistoryDetailListData.mapStateToProps;
let mapDispatchToProps = AgreementHistoryDetailListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgreementHistoryDetailListView)
);
