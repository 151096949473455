import axiosInstance from "../api/axios";

/* ------------------------------------------------- Get Hotel List ------------------------------------------------- */
export function onGetHotelAll() {
  return axiosInstance.get("/api/v2/hotel/hotelDetail/findHotel?latitude=37.47149658203125&longitude=127.03932759263459&name=&offset=0&limit=21000000&distance=&valetable=");
}

/* ---------------------------------------------------- Get List ---------------------------------------------------- */
export function onGetAll(setHotelId, checkIn, checkOut, adultNumber, chlidrenNumber) {
  return axiosInstance.get(
    "/hotel/api/hotelReservation/roomDetail/availableRooms?hotelId=" +
      setHotelId +
      "&startDate=" +
      checkIn +
      "&endDate=" +
      checkOut +
      "&numberOfRooms=1&totalNumberOfGuests=" +
      adultNumber +
      "&numberOfChildren=" +
      chlidrenNumber +
      "&requestPageType=ota"
  );
}

/* ----------------------------------------------------- Get One ---------------------------------------------------- */
export function onGetOne(setHotelId) {
  return axiosInstance.get("/api/v2/hotel/hotelDetail?hotelId=" + setHotelId);
}

/* ----------------------------------------------- Get Membership Type ---------------------------------------------- */
export function onGetMembershipType(setHotelId) {
  return axiosInstance.get("/api/hotel/hotelMemberType?hotelId=" + setHotelId);
}

/* ------------------------------------------------- Get Title Type ------------------------------------------------- */
export function onGetTitleType(setHotelId) {
  return axiosInstance.get("/api/v2/hotel/title?hotelId=" + setHotelId);
}

/* -------------------------------------------------- Get Card Type ------------------------------------------------- */
export function onGetCardType(setHotelId) {
  return axiosInstance.get("/api/hotel/reservation/cardType/?hotelId=" + setHotelId);
}

/* ---------------------------------------------- Get Profile By Email ---------------------------------------------- */
export function onGetProfileByEmail(setHotelId, searchEmailVal) {
  return axiosInstance.get("/api/hotel/user/profileByEmail?hotelId=" + setHotelId + "&email=" + searchEmailVal);
}

/* ------------------------------------------- Get Profile By Memebership ------------------------------------------- */
export function onGetProfileByMembership(setHotelId, membershipLastName, membershipType, searchEmailVal) {
  return axiosInstance.get(
    "/api/v2/hotel/user/profileByMembership?hotelId=" + setHotelId + "&lastName=" + membershipLastName + "&membershipType=" + membershipType + "&membershipNumber=" + searchEmailVal
  );
}

/* -------------------------------------------- Get Terms And Conditions -------------------------------------------- */
export function onGetTermsConditions(setHotelId) {
  return axiosInstance.get("/api/v2/hotel/terms?hotelId=" + setHotelId + "&type=TERMS&confirmationNo=");
}

/* ----------------------------------------------- Get Privacy Policy ----------------------------------------------- */
export function onGetPrivacyPolicy(setHotelId) {
  return axiosInstance.get("/api/v2/hotel/terms?hotelId=" + setHotelId + "&type=PRIVACY_POLICIES&confirmationNo=");
}

/* -------------------------------------------- Add Booking With Profile -------------------------------------------- */
export function onAddBookingWithProfile(
  hotelId,
  startDate,
  endDate,
  ratePlanCode,
  roomTypeCode,
  adults,
  children,
  numberOfUnits,
  guaranteeType,
  cardType,
  cardHolderName,
  cardNumber,
  expirationDate,
  nameTitle,
  firstName,
  lastName,
  email,
  phone,
  alternativeFirstName,
  alternativeLastName,
  requestedPageType
) {
  const data = {
    hotelId: hotelId,
    startDate: startDate,
    endDate: endDate,
    ratePlanCode: ratePlanCode,
    roomTypeCode: roomTypeCode,
    adults: adults,
    children: children,
    numberOfUnits: numberOfUnits,
    guaranteeType: guaranteeType,
    cardType: cardType,
    cardHolderName: cardHolderName,
    cardNumber: cardNumber,
    expirationDate: expirationDate,
    nameTitle: nameTitle,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    alternativeFirstName: alternativeFirstName,
    alternativeLastName: alternativeLastName,
    requestedPageType: "OTA",
  };

  return axiosInstance.post("/api/v2/hotel/reservation/createBooking/createProfile", data);
}

/* --------------------------------------------------- Add Booking -------------------------------------------------- */
export function onAddBooking(
  hotelId,
  startDate,
  endDate,
  ratePlanCode,
  roomTypeCode,
  adults,
  children,
  numberOfUnits,
  guaranteeType,
  cardType,
  cardHolderName,
  cardNumber,
  expirationDate,
  profileId,
  requestedPageType
) {
  const data = {
    hotelId: hotelId,
    startDate: startDate,
    endDate: endDate,
    ratePlanCode: ratePlanCode,
    roomTypeCode: roomTypeCode,
    adults: adults,
    children: children,
    numberOfUnits: numberOfUnits,
    guaranteeType: guaranteeType,
    cardType: cardType,
    cardHolderName: cardHolderName,
    cardNumber: cardNumber,
    expirationDate: expirationDate,
    profileId: profileId,
    requestedPageType: "OTA",
  };

  return axiosInstance.post("/api/v2/hotel/reservation/createBooking", data);
}
