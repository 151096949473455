import CommonComponent from "../../../components/CommonComponent";

// module
import * as authActions from "../../../../redux/modules/Auth";
import * as transferActions from "../../../../redux/modules/Transfer";



class UserNotificationLogDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_USER_TRANSFER_LOG_DETAIL") {

      this.onGetUserNotificationLogResults(props);
    }
  }

  // redux에 get payment Detail 요청
  requestGetUserNotificationLogDetail = (userNotificationLogId) => {
    this.setState({
      requestType: "GET_USER_TRANSFER_LOG_DETAIL",
    });
    this.props.onGetUserNotificationLogDetail(userNotificationLogId);
  };

  // get payment One results
  onGetUserNotificationLogResults = (props) => {
    if (props.userNotificationLogDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.userNotificationLogDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };
  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      userNotificationLogDetail: state.transfer.userNotificationLogDetail,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetUserNotificationLogDetail: (userNotificationLogId) =>
        dispatch(
          transferActions.onGetUserNotificationLogDetail(userNotificationLogId)
        ),
    };
  };
}

export default UserNotificationLogDetailData;
