import React from "react";
import { connect } from "react-redux";
import { Loading } from "../../../components/Paths";
import UserNotificationLogDetailData from "../Detail/userNotificationLogDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/userNotificationLogList/userNotificationLogDetail.css";

// utils
import { formatDateTimeDot, prettyPrint } from "../../../common/Utils";

class UserNotificationLogDetailView extends UserNotificationLogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      userNotificationLogId: "",
    };
  }

  // 첫 실행시 URL에서 userNotificationLogId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const userNotificationLogId = params.get("userNotificationLogId");
    this.setState({
      userNotificationLogId: userNotificationLogId,
    });
    this.requestGetUserNotificationLogDetail(userNotificationLogId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };



  render() {
    let userNotificationLogDetail = null;

    if (this.props.userNotificationLogDetail != null && this.props.userNotificationLogDetail?.success == true) {
      userNotificationLogDetail = this.props.userNotificationLogDetail.results;
    }

    return (
      <div className="userNotificationLogDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="userNotificationLog_detail_container">
          <div className="userNotificationLog_detail_innder">
            <div className="title" data-detect="admin_user_notification_log_detail_title">
              USER NOTIFICATION LOG DETAIL
            </div>
            <div className="userNotificationLog_container">
              <div className="userNotificationLog_title_container"></div>
              <div className="userNotificationLog_info_container">
                <div className="userNotificationLog_info_table">
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_notification_id">Notification ID</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span>{userNotificationLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_user_id"> User ID</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span> {userNotificationLogDetail?.userId??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_success">Success</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span> {userNotificationLogDetail?.success??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_user"> User</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span> {userNotificationLogDetail ? (userNotificationLogDetail.user.gender + " " + userNotificationLogDetail.user.lastName + " " + userNotificationLogDetail.user.firstName) : "-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_user_email">User Email</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span> {userNotificationLogDetail?.user.email??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_language_code"> Language Code</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span> {userNotificationLogDetail?.user.languageCode??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_os_type">OS Type</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span>{userNotificationLogDetail?.osType??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_type">Type</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span>{userNotificationLogDetail?.user.osType??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_content">Content</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span>{userNotificationLogDetail?.content??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_data">Data</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span className="format">{prettyPrint(userNotificationLogDetail?.data)}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_token">Token</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span>{userNotificationLogDetail?.content??"-"}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_created_at">Created At</span>
                    </div>{" "}
                    <div className="userNotificationLog_info_cell content">
                      <span>{(userNotificationLogDetail ? formatDateTimeDot(userNotificationLogDetail.created_at) : "-")}</span>
                    </div>
                  </div>
                  <div className="userNotificationLog_info_row">
                    <div className="userNotificationLog_info_cell index">
                      <span data-detect="admin_user_notification_log_detail_label_updated_at">Updated At</span>
                    </div>
                    <div className="userNotificationLog_info_cell content">
                      <span>{userNotificationLogDetail ? formatDateTimeDot(userNotificationLogDetail.updated_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserNotificationLogDetailData.mapStateToProps;
let mapDispatchToProps = UserNotificationLogDetailData.mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserNotificationLogDetailView);
