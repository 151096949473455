import React from "react";
import CommonComponent from "../../../components/CommonComponent";

// CSS
import "../../../css/common.css";
import "../../../css/emailForm/changePassword/changePasswordCharacter.css";

class ChangePasswordCharacterView extends CommonComponent {
  constructor(props) {
    super(props);
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    return (
      <div className="changePasswordCharacter">
        <div className="change_password_character_container">
          <div className="change_password_character_container_inner">
            <div className="title">CHANGE PASSWORD CHARACTER</div>
            <div className="change_password_text_box table">
              <div className="row">
                <div className="change_password_text confirmation_number cell">
                  <div className="change_password_title row">Sample</div>
                  <div className="change_password_character row">{`{{ Sample }}`}</div>
                </div>
                <div className="change_password_text change_password_id cell">
                  <div className="change_password_title row">Sample</div>
                  <div className="change_password_character row">{`{{ Sample }}`}</div>
                </div>
                <div className="change_password_text arrival_date cell">
                  <div className="change_password_title row">Sample</div>
                  <div className="change_password_character row">{`{{ Sample }}`}</div>
                </div>
                <div className="change_password_text departure_date cell">
                  <div className="change_password_title row">Sample</div>
                  <div className="change_password_character row">{`{{ Sample }}`}</div>
                </div>
              </div>
              <div className="row">
                <div className="change_password_text room_type cell">
                  <div className="change_password_title row">Sample</div>
                  <div className="change_password_character row">{`{{ Sample }}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePasswordCharacterView;
