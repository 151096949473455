import axiosInstance from "../api/axios";

//------------------------------- get ApplicationEndPoint List ------------------------------------------------------//
export function onGetAllApplicationEndPoint(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/appEndpoint/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get ApplicationEndPoint One ------------------------------------------------------//
export function onGetOneApplicationEndPoint(applicationEndPointId) {
  return axiosInstance.get("/admin/api/appEndpoint/" + applicationEndPointId);
}

//------------------------------- add ApplicationEndPoint location ------------------------------------------------------//
export function onAddApplicationEndPoint(packageName, os, version, url) {
  const requestData = {
    packageName: packageName,
    os: os,
    version: version,
    url: url,
  };

  return axiosInstance.post("/admin/api/appEndpoint", requestData);
}
//------------------------------- delete ApplicationEndPoint location ------------------------------------------------------//
export function onDeleteApplicationEndPoint(applicationEndPointId) {
  return axiosInstance.delete("/admin/api/appEndpoint/" + applicationEndPointId);
}

//------------------------------- edit applicationEndPoint ------------------------------------------------------//
export function onEditApplicationEndPoint(applicationEndPointId, packageName, os, version, url) {
  const requestData = {
    packageName: packageName,
    os: os,
    version: version,
    url: url,
  };

  return axiosInstance.put("/admin/api/appEndpoint/" + applicationEndPointId, requestData);
}
