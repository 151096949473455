import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as useKeyApis from "../lib/useKey_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const USEKEY_GET_ALL = "useKey/USEKEY_GET_ALL";
const USEKEY_GET_ONE = "useKey/USEKEY_GET_ONE";

// 액션 생성자
export const onGetAllUseKeyLogList = createAction(USEKEY_GET_ALL, useKeyApis.onGetAllUseKeyLogList);
export const onGetUseKeyLogDetail = createAction(USEKEY_GET_ONE, useKeyApis.onGetUseKeyLogDetail);


// 초기 상태
const initialState = {
    useKeyLogList: [],
    useKeyLogDetail: null,
    failData: null,
};

export default handleActions(
    {
        ...pender({
            type: USEKEY_GET_ALL,
            onPending: (state, action) => {
                return { ...state, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, useKeyLogList: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, useKeyLogList: [], failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: USEKEY_GET_ONE,
            onPending: (state, action) => {
                return { ...state, useKeyLogDetail: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, useKeyLogDetail: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
