import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import HotelAddData from "../Add/HotelAddData";



//css
import "../../css/common.css";
import "../../css/hotel/hotelAdd.css";

class HotelAddView extends HotelAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      name: "",
      address: "",
      email: "",
      password: "",
      confirmPwd: "",
      logoFile: null,
      logoText: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const copyId = params.get("copyId");
    this.setState({
      copyId: copyId,
    });
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // 정규식 패턴 정의
  mailRegExp = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)[\w!@#$%^&*()+={}]{8,}$/; // 알파벳, 숫자, 특수 문자 중 각 한 개 이상을 포함하여 8자 이상으로 이어져야 함.

  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change address
  onChangeAddress = (e) => {
    const inputValue = e.target.value;
    this.setState({ address: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change email
  onChangeEmail = (e) => {
    const inputValue = e.target.value;
    this.setState({ email: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change password
  onChangePassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ password: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change password confirm
  onChangePwdConfirm = (e) => {
    const inputValue = e.target.value;
    this.setState({ confirmPwd: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // email 형식을 확인한 후 유효성 검사 메시지를 수정함.
  confirmEmailFormat = (messageKey) => {
    const { email } = this.state;

    const emailIsValid = this.mailRegExp.test(email);

    if (email !== "" && !emailIsValid) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // password 형식을 확인한 후 유효성 검사 메시지를 수정함.
  confirmPasswordFormat = (messageKey) => {
    const { password } = this.state;
    const passwordIsValid = this.passwordRegExp.test(password);

    if (password !== "" && !passwordIsValid) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // password와 password confirm이 일치하는 지 확인한 후 유효성 검사 메시지를 수정함.
  confirmPasswordMatch = (messageKey) => {
    const { password, confirmPwd } = this.state;

    if (password !== "" && password !== confirmPwd) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("name", "admin_hotel_add_validation_name") &&
      this.confirmField("address", "admin_hotel_add_validation_address") &&
      this.confirmField("email", "admin_hotel_add_validation_email") &&
      this.confirmEmailFormat("admin_hotel_add_validation_email_format") &&
      this.confirmField("password", "admin_hotel_add_validation_password") &&
      this.confirmPasswordFormat("admin_hotel_add_validation_character_number_combining") &&
      this.confirmPasswordMatch("admin_hotel_add_validation_not_match") &&
      this.confirmField("logoFile", "admin_hotel_add_validation_image_file")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file image 미리보기
  preview = () => {
    if (!this.state.logoFile || !this.state.logoFile instanceof Blob) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }

    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const reader = new FileReader();

    // 이미지 미리보기가 완료되면 로딩 종료
    reader.onloadend = () => {
      this.setState({
        imageUrl: reader.result,
        isShowAllLoading: false,
      });
    };

    reader.readAsDataURL(this.state.logoFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ logoFile: file, logoText: file ? file.name : "ex) MyCONECT.png" }, () => {
      this.preview();
      this.onCheckValidation();
    });
  };

  // 데이터 저장을 로딩으로 감싸기
  add_btn = () => {
    const { copyId } = this.state;
    this.onSubmit();
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddHotel(this.state.name, this.state.address, this.state.email, this.state.password, this.state.confirmPwd, this.state.logoFile, this.state.logoText);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/hotelListPage");
  };

  render() {
    const imgBoxStyle = {
      marginLeft: this.state.logoFile && this.state.logoFile instanceof Blob ? "300px" : "0",
    };

    return (
      <div className="addHotel">
        {this.state.isShowAllLoading && <Loading />}
        <div className="hotel_add_container">
          <div className="addhotel_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_hotel_add_title">ADD HOTEL</p>
              </div>
              <div className="sub_text" style={{ color: "white" }}>
                <p data-detect="admin_hotel_add_guide_click_add">Enter information to add HOTEL. And Click the "Add" button.</p>
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addhotel_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addhotel_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_name">Name</p>
                      </div>
                      <div className="detail_cell content hotel_name">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          data-detect-placeholder="admin_hotel_add_placeholder_hotel_name"
                          placeholder="Hotel Name"
                          autoComplete="off"
                          value={this.state.name}
                          onChange={this.onChangeName}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_address">Address</p>
                      </div>
                      <div className="detail_cell content hotel_address">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          data-detect-placeholder="admin_hotel_add_placeholder_hotel_address"
                          placeholder="Hotel Address"
                          autoComplete="off"
                          value={this.state.address}
                          onChange={this.onChangeAddress}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_email">Email</p>
                      </div>
                      <div className="detail_cell content hotel_email">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          data-detect-placeholder="admin_hotel_add_placeholder_staff_email"
                          placeholder="Staff E-mail"
                          autoComplete="off"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_password">Password</p>
                      </div>
                      <div className="admin_hotel_add_label_password">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          data-detect-placeholder="admin_hotel_add_placeholder_staff_password"
                          placeholder="Staff Password"
                          autoComplete="off"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_confirm_password">Password Confirm</p>
                      </div>
                      <div className="detail_cell content hotel_con_password">
                        <input
                          type="password"
                          id="confirmPwd"
                          name="confirmPwd"
                          data-detect-placeholder="admin_hotel_add_placeholder_staff_confirm_password"
                          placeholder="Staff Confirm Password"
                          autoComplete="off"
                          value={this.state.confirmPwd}
                          onChange={this.onChangePwdConfirm}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_add_label_logo">
                        Logo
                      </div>
                      <div
                        style={{
                          display: this.state.logoFile && this.state.logoFile instanceof Blob ? "block" : "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <div style={imgBoxStyle}>
                          {this.state.logoFile && this.state.logoFile instanceof Blob && <img className="img__box" src={this.state.imageUrl} alt="hotel_upload_image" />}
                          <div className="file_upload_button_container" style={{ display: "flex" }}>
                            <form>
                              <label htmlFor="logoFile" className="file_upload_button" data-detect="admin_hotel_add_button_upload">
                                Upload
                              </label>
                              <input
                                type="file"
                                id="logoFile"
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={this.onFileChange}
                                title="Upload"
                                style={{ display: "none" }} // input 요소를 화면에서 감추기
                              />
                            </form>
                            {this.state.logoText ? <p className="file_textName">{this.state.logoText}</p> : <p className="file_textName">{"ex) My conect.png"}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_hotel_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_hotel_add_button_add" onClick={this.add_btn} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = HotelAddData.mapStateToProps;
let mapDispatchToProps = HotelAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HotelAddView));
