import React from "react";
import CommonComponent from "../../../components/CommonComponent";



// CSS
import "../../../css/common.css";
import "../../../css/emailForm/createEmailConfirmSuccess/createEmailConfirmSuccessCharacter.css";

class CreateEmailConfirmSuccessCharacterView extends CommonComponent {
  constructor(props) {
    super(props);
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    return (
      <div className="createEmailContirmSuccessCharacter">
        <div className="create_confirm_success_character_container">
          <div className="create_confirm_success_character_container_inner">
            <div className="title">CREATE CONFIRM SUCCESS CHARACTER</div>
            <div className="create_confirm_success_text_box table">
              <div className="row">
                <div className="create_confirm_success_text confirmation_number cell">
                  <div className="create_confirm_success_title row">Sample</div>
                  <div className="create_confirm_success_character row">{`{{ sample }}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEmailConfirmSuccessCharacterView;
