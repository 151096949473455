import React from "react";
import CommonComponent from "../../../components/CommonComponent";



// CSS
import "../../../css/common.css";
import "../../../css/emailForm/updateProfile/updateProfileCharacter.css";

class UpdateProfileCharacterView extends CommonComponent {
  constructor(props) {
    super(props);
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    return (
      <div className="updateProfileCharacter">
        <div className="update_profile_character_container">
          <div className="update_profile_character_container_inner">
            <div className="title" data-detect="admin_email_form_forgot_id_detail_title">
              UPDATE PROFILE CHARACTER
            </div>
            <div className="update_profile_text_box table">
              <div className="row">
                <div className="update_profile_text confirmation_number cell">
                  <div className="update_profile_title row">Sample</div>
                  <div className="update_profile_character row">{{ Sample }}</div>
                </div>
                <div className="update_profile_text update_profile_id cell">
                  <div className="update_profile_title row">Sample</div>
                  <div className="update_profile_character row">{{ Sample }}</div>
                </div>
                <div className="update_profile_text arrival_date cell">
                  <div className="update_profile_title row">Sample</div>
                  <div className="update_profile_character row">{{ Sample }}</div>
                </div>
                <div className="update_profile_text departure_date cell">
                  <div className="update_profile_title row">Sample</div>
                  <div className="update_profile_character row">{{ Sample }}</div>
                </div>
              </div>
              <div className="row">
                <div className="update_profile_text room_type cell">
                  <div className="update_profile_title row">Sample</div>
                  <div className="update_profile_character row">{{ Sample }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpdateProfileCharacterView;
