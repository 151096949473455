import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as applicationEndPointApis from "../lib/applicationEndPoint_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const APPLICATION_ENDPOINT_GET_ALL = "applicationEndPoint/APPLICATION_ENDPOINT_GET_ALL";
const APPLICATION_ENDPOINT_GET_ONE = "applicationEndPoint/APPLICATION_ENDPOINT_GET_ONE";
const ADD_APPLICATION_ENDPOINT = "applicationEndPoint/ADD_APPLICATION_ENDPOINT";
const DELETE_APPLICATION_ENDPOINT = "applicationEndPoint/DELETE_APPLICATION_ENDPOINT";
const EDIT_APPLICATION_ENDPOINT = "applicationEndPoint/EDIT_APPLICATION_ENDPOINT";

// 액션 생성자
export const onGetAllApplicationEndPoint = createAction(APPLICATION_ENDPOINT_GET_ALL, applicationEndPointApis.onGetAllApplicationEndPoint);
export const onGetOneApplicationEndPoint = createAction(APPLICATION_ENDPOINT_GET_ONE, applicationEndPointApis.onGetOneApplicationEndPoint);
export const onAddApplicationEndPoint = createAction(ADD_APPLICATION_ENDPOINT, applicationEndPointApis.onAddApplicationEndPoint);
export const onDeleteApplicationEndPoint = createAction(DELETE_APPLICATION_ENDPOINT, applicationEndPointApis.onDeleteApplicationEndPoint);
export const onEditApplicationEndPoint = createAction(EDIT_APPLICATION_ENDPOINT, applicationEndPointApis.onEditApplicationEndPoint);

// 초기 상태
const initialState = {
  applicationEndPointList: [],
  applicationEndPointDetail: null,
  applicationEndPointAdd: null,
  applicationEndPointEdit: null,
  failData: null,
  successDelete: null,
};

export default handleActions(
  {
    ...pender({
      type: APPLICATION_ENDPOINT_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationEndPointList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, applicationEndPointList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APPLICATION_ENDPOINT_GET_ONE,
      onPending: (state, action) => {
        return { ...state, applicationEndPointDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationEndPointDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_APPLICATION_ENDPOINT,
      onPending: (state, action) => {
        return { ...state, applicationEndPointAdd: null, failData: null };
      },
      onSuccess: (state, action) => {

        return { ...state, applicationEndPointAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_APPLICATION_ENDPOINT,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_APPLICATION_ENDPOINT,
      onPending: (state, action) => {
        return { ...state, applicationEndPointEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationEndPointEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
