import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as passwordApis from "../lib/password_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const MATCH_PASSWORD = "password/MATCH_PASSWORD";
const EDIT_PASSWORD = "password/EDIT_PASSWORD";

// 액션 생성자
export const onMatchPassword = createAction(MATCH_PASSWORD, passwordApis.onMatchPassword);
export const onEditPassword = createAction(EDIT_PASSWORD, passwordApis.onEditPassword);

// 초기 상태
const initialState = {
    matchPassword: null,
    passwordData: null,
    failData: null,
};

export default handleActions({
    ...pender({
        type: MATCH_PASSWORD,
        onPending: (state, action) => {
            return { ...state, matchPassword: null, failData: null };
        },
        onSuccess: (state, action) => {
            return { ...state, matchPassword: action.payload.data };
        },
        onFailure: (state, action) => {
            return { ...state, failData: action.payload.response?.data };
        },
    }),
    ...pender({
        type: EDIT_PASSWORD,
        onPending: (state, action) => {
            return { ...state, passwordData: null, failData: null };
        },
        onSuccess: (state, action) => {
            return { ...state, passwordData: action.payload.data };
        },
        onFailure: (state, action) => {
            return { ...state, failData: action.payload.response?.data };
        },
    }),
    [PURGE]: (state, action) => {
        return initialState;
    },
},
    initialState
);

