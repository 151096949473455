// UserInfoListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import UserInfoListData from "../List/UserInfoListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/userInfo/userInfoList.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class UserInfoListView extends UserInfoListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      type: "",
      search: "",
      checkedList: [],
      isAllChecked: false,

      // 임시값
      tempSearch: "",
      tempType: "",
    };
  }

  /* ---------------------------------------------------- lifecycle --------------------------------------------------- */
  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetUserInfoList(
        this.state.pageNo,
        this.state.type,
        this.state.search,
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- event handler ------------------------------------------------- */
  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = e.target.value;

    this.setState({ [stateName]: value });
  };

  //검색 버튼을 누르면
  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        type: this.state.tempType,
      },
      () => {
        this.requestGetUserInfoList(this.state.pageNo, this.state.type, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          type: this.state.tempType,
        },
        () => {
          this.requestGetUserInfoList(this.state.pageNo, this.state.type, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempType: this.state.type, tempSearch: this.state.search }, () => {
      this.requestGetUserInfoList(pageNo, this.state.type, this.state.search);
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkUserInfoDetail(id);
    }
  }

  // //파람값 url로 전달
  linkUserInfoDetail = (userInfoId) => {
    this.props.history.push(`/userInfoDetailPage?userInfoId=${userInfoId}`);
  };

  //insert
  linkUserInfoAdd = () => {
    this.props.history.push("/userInfoAddPage");
  };


  /* ------------------------------------------------------- etc ------------------------------------------------------ */
  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  render() {
    let count;
    let userInfoList = [];
    let myDuty = "";

    if (
      this.props.userInfoList.length !== 0 &&
      this.props.userInfoList?.success == true
    ) {
      userInfoList = this.props.userInfoList.results.rows;
      count = this.props.userInfoList.results.count;
    }

    if (this.props.data.length !== 0 && this.props.data?.success == true) {
      myDuty = this.props.data.results.duty;
    }

    return (
      <div className="userInfoList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="user_info_list_container">
          <div className="user_info_list_container_inner">
            <div className="title" data-detect="admin_admin_user_list_title">
              USER LIST
            </div>

            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <input type="search" id="pageNo" value="" style={{ display: "none" }} readOnly />
                  <div className="find">
                    <select id="application_type_select" className="search_select" name="application_type_select" onChange={(e) => this.onChangeValue(e, "tempType")} value={this.state.tempType}>
                      <option disabled value="" data-detect="admin_admin_user_list_placeholder_select_box_user">
                        USER
                      </option>
                      <option data-detect="admin_admin_user_list_select_name" value="name">
                        Name
                      </option>
                      <option data-detect="admin_admin_user_list_select_email" value="email">
                        E-mail
                      </option>
                      <option data-detect="admin_admin_user_list_select_department" value="department">
                        Department
                      </option>
                      <option data-detect="admin_admin_user_list_select_duty" value="duty">
                        Duty
                      </option>
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_admin_user_list_placeholder_search"
                      placeholder="Search"
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                      value={this.state.tempSearch}
                      onKeyUp={this.handleKeyUp}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            <div className="user_info_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input type="checkbox" id="allCheck" onChange={() => this.allCheck(myDuty, userInfoList)} checked={this.state.isAllChecked} />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_admin_user_list_label_name">
                      Name
                    </div>
                    <div className="cell" data-detect="admin_admin_user_list_label_email">
                      E-mail
                    </div>
                    <div className="cell" data-detect="admin_admin_user_list_label_department">
                      Department
                    </div>
                    <div className="cell" data-detect="admin_admin_user_list_label_duty">
                      Duty
                    </div>
                    <div className="cell" data-detect="admin_admin_user_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {userInfoList?.length > 0 ? (
                    userInfoList.map((userInfo, index) => (
                      <div className={`row tbodyRow ${userInfo.duty !== "USER" && myDuty == "USER" ? "non-deletable" : ""}`} key={userInfo.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={userInfo.id}
                            id={userInfo.id}
                            onChange={() => this.onClickInput(userInfo.id, userInfoList)}
                            disabled={myDuty == "USER" || (myDuty == "ADMIN" && userInfo.duty !== "USER") || (myDuty == "SUPER_ADMIN" && userInfo.duty == "SUPER_ADMIN")}
                            checked={this.state.checkedList.includes(userInfo.id)}
                          />
                          <label className="tbodyLabel" htmlFor={userInfo.id}></label>
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(userInfo.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {" "}
                          {userInfo.name}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(userInfo.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {" "}
                          {userInfo.email}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(userInfo.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {" "}
                          {userInfo.department}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(userInfo.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {" "}
                          {userInfo.duty}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(userInfo.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {" "}
                          {userInfo && formatDateTimeSlash(userInfo.loginAt)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_admin_user_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedUserInfo();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_admin_user_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkUserInfoAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_admin_user_list_button_add">Add</span>
                </button>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserInfoListData.mapStateToProps;
let mapDispatchToProps = UserInfoListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserInfoListView)
);
