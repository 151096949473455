//CityAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class CityDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_CITY_ONE") {

      this.onGetCityOneResults(props);
    }
  }

  // redux에 get city Detail 요청
  requestGetCityOne = (cityId) => {
    this.setState({
      requestType: "GET_CITY_ONE",
    });
    this.props.onGetOne(cityId);
  };

  // get city One results
  onGetCityOneResults = (props) => {
    if (props.cityDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.cityDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_country_detail"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };


  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      cityDetail: state.location.cityDetail,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (cityId) => dispatch(locationActions.onGetOneCity(cityId)),
    };
  };
}

export default CityDetailData;
