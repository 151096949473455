import CommonComponent from "../../components/CommonComponent";

// module
import * as useKeyActions from "../../../redux/modules/UseKey";



class UseKeyLogDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_USEKEY_LOG_DETAIL") {

      this.onGetUseKeyLogResults(props);
    }
  }

  // redux에 get payment Detail 요청
  requestGetUseKeyLogDetail = (useKeyLogId) => {
    this.setState({
      requestType: "GET_USEKEY_LOG_DETAIL",
    });
    this.props.onGetUseKeyLogDetail(useKeyLogId);
  };

  // get payment One results
  onGetUseKeyLogResults = (props) => {
    if (props.useKeyLogDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.useKeyLogDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      useKeyLogDetail: state.useKeyLog.useKeyLogDetail,
      failData: state.useKeyLog.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetUseKeyLogDetail: (useKeyLogId) =>
        dispatch(useKeyActions.onGetUseKeyLogDetail(useKeyLogId)),
    };
  };
}

export default UseKeyLogDetailData;
