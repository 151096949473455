import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import PaymentDetailData from "../Detail/PaymentDetailData";

//css
import "../../css/common.css";
import "../../css/payment/paymentDetail.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class PaymentDetailView extends PaymentDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      paymentId: "",
    };
  }

  // 첫 실행시 URL에서 paymentId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const paymentId = params.get("paymentId");
    this.setState({
      paymentId: paymentId,
    });
    this.requestGetPaymentOne(paymentId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //로그 목록 list로 이동
  onLogBtn = () => {
    this.props.history.push(`/paymentLogListPage?paymentId=${this.state.paymentId}`);
  };



  render() {
    let paymentDetail = null;
    let reservationDetail = null;

    if (this.props.paymentDetail != null && this.props.paymentDetail?.success == true) {
      paymentDetail = this.props.paymentDetail.results;
    }

    if (this.props.reservationDetail != null && this.props.reservationDetail?.success == true) {
      reservationDetail = this.props.reservationDetail.results;
    }

    return (
      <div className="paymentDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="payment_detail_container">
          <div className="payment_detail_innder">
            <div className="order_no_title_container" data-detect="admin_payment_detail_label_order_no">
              Order No.
            </div>
            <div className="order_no_info">
              <div className="info_order_no">
                <span>{paymentDetail?.orderNo}</span>
              </div>
              <div className="info_date">
                <span>{paymentDetail ? formatDateTimeDot(paymentDetail.created_at) : "-"}</span>
              </div>
            </div>

            {/* 사용자 */}
            <div>
              <div className="user_title_container" data-detect="admin_payment_detail_label_user">
                User
              </div>
              <div className="user_info">
                <div className="user_info_table">
                  <div className="user_info_thead">
                    <div className="user_info_row">
                      <div className="user_info_cell" data-detect="admin_payment_detail_label_user_profile_id">
                        Profile ID
                      </div>
                      <div className="user_info_cell" data-detect="admin_payment_detail_label_user_title">
                        Title
                      </div>
                      <div className="user_info_cell" data-detect="admin_payment_detail_label_user_first_name">
                        First Name
                      </div>
                      <div className="user_info_cell" data-detect="admin_payment_detail_label_user_last_name">
                        Last Name
                      </div>
                      <div className="user_info_cell" data-detect="admin_payment_detail_label_user_email">
                        E-mail
                      </div>
                      <div className="user_info_cell" data-detect="admin_payment_detail_label_user_date">
                        Date
                      </div>
                    </div>
                  </div>

                  <div className="user_info_tbody">
                    <div className="user_info_row">
                      <div className="user_info_cell user_profile_id">
                        <span>{paymentDetail?.user.id??"-"}</span>
                      </div>
                      <div className="user_info_cell user_title">
                        <span>{paymentDetail?.user.gender??"-"}</span>
                      </div>
                      <div className="user_info_cell user_first_name">
                        <span>{paymentDetail?.user.firstName??"-"}</span>
                      </div>
                      <div className="user_info_cell user_last_name">
                        <span>{paymentDetail?.user.lastName??"-"}</span>
                      </div>
                      <div className="user_info_cell user_email">
                        <span>{paymentDetail?.user.email??"-"}</span>
                      </div>
                      <div className="user_info_cell user_date">{paymentDetail ? formatDateTimeDot(paymentDetail.user.created_at) : "-"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 예약 */}
            <div>
              <div className="reservation_title_container" data-detect="admin_payment_detail_label_reservation">
                Reservation
              </div>
              <div className="reservation_info">
                <div className="reservation_info_table">
                  <div className="reservation_info_thead">
                    <div className="reservation_info_row">
                      <div className="reservation_info_cell" data-detect="admin_payment_detail_label_reservation_confirmation_no">
                        Confirmation No.
                      </div>
                      <div className="reservation_info_cell" data-detect="admin_payment_detail_label_reservation_reservation_id">
                        Reservation ID
                      </div>
                      <div className="reservation_info_cell" data-detect="admin_payment_detail_label_reservation_reservation_status">
                        Reservation Status
                      </div>
                      <div className="reservation_info_cell" data-detect="admin_payment_detail_label_reservation_check_in_date">
                        Check In Date
                      </div>
                      <div className="reservation_info_cell" data-detect="admin_payment_detail_label_reservation_check_out_date">
                        Check Out Date
                      </div>
                    </div>
                  </div>

                  <div className="reservation_info_tbody">
                    <div className="reservation_info_row">
                      <div className="reservation_info_cell reservation_confirmation_id">
                        <span>{reservationDetail?.confirmationNo??"-"}</span>
                      </div>
                      <div className="reservation_info_cell reservation_reservation_id">
                        <span>{reservationDetail?.reservationId??"-"}</span>
                      </div>
                      <div className="reservation_info_cell reservation_reservation_status">
                        <span>{reservationDetail?.computedReservationStatus??"-"}</span>
                      </div>
                      <div className="reservation_info_cell reservation_check_in_date">{reservationDetail ? formatDateTimeDot(reservationDetail.checkInDate) : "-"}</div>
                      <div className="reservation_info_cell reservation_check_out_date">{reservationDetail ? formatDateTimeDot(reservationDetail.checkOutDate) : "-"}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 호텔 */}
            <div>
              <div className="hotel_title_container" data-detect="admin_payment_detail_label_hotel">
                Reservation
              </div>
              <div className="hotel_info">
                <div className="hotel_info_table">
                  <div className="hotel_info_thead">
                    <div className="hotel_info_row">
                      <div className="hotel_info_cell" data-detect="admin_payment_detail_label_hotel_hotel_id">
                        Hotel ID
                      </div>
                      <div className="hotel_info_cell" data-detect="admin_payment_detail_label_hotel_hotel_name">
                        Hotel Name
                      </div>
                      <div className="hotel_info_cell" data-detect="admin_payment_detail_label_hotel_region">
                        Region
                      </div>
                      <div className="hotel_info_cell" data-detect="admin_payment_detail_label_hotel_country">
                        Country
                      </div>
                      <div className="hotel_info_cell" data-detect="admin_payment_detail_label_hotel_city">
                        City
                      </div>
                    </div>
                  </div>

                  <div className="hotel_info_tbody">
                    <div className="hotel_info_row">
                      <div className="hotel_info_cell hotel_hotel_id">
                        <span>{paymentDetail?.hotel.id??"-"}</span>
                      </div>
                      <div className="hotel_info_cell hotel_hotel_name">
                        <span>{paymentDetail?.hotel.name??"-"}</span>
                      </div>
                      <div className="hotel_info_cell hotel_region">
                        <span>{paymentDetail?.hotel.region??"-"}</span>
                      </div>
                      <div className="hotel_info_cell hotel_country">
                        <span>{paymentDetail?.hotel.country??"-"}</span>
                      </div>
                      <div className="hotel_info_cell hotel_city">
                        <span>{paymentDetail?.hotel.city??"-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 결제 */}
            <div className="payment_container">
              <div className="payment_title_container">
                <div className="title_line">
                  <div className="payment_title">
                    <span data-detect="admin_payment_detail_label_payment">Payment</span>
                  </div>
                </div>
              </div>
              <div className="payment_info_container">
                <div className="payment_info_table">
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_order_no">Order No</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {paymentDetail?.orderNo??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_reservation_id">Reservation ID</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {paymentDetail?.reservationId??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_long_info">Long Info</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {paymentDetail?.longInfo??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_folio_view_no">Folio View No</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {paymentDetail?.folioViewNo??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_amount">Amount</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.amount??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_unit">Unit</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.unit??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_order_no">PG Order No</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgOrderNo??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_status">PG Status</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgStatus??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_status_code">PG Status Code</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgStatusCode??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_status_message">PG Status Message</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgStatusMessage??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_amount">PG Amount</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgAmount??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_unit">PG Unit</span>
                    </div>{" "}
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgUnit??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_pg_billing_no">PG Billing No</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail?.pgBillingNo??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_confirmed_at">Confirmed At</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{paymentDetail ? formatDateTimeDot(paymentDetail.confirmedAt) : "-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_created_at">Created At</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {paymentDetail ? formatDateTimeDot(paymentDetail.created_at) : "-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_detail_label_payment_updated_at">Updated At</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {paymentDetail ? formatDateTimeDot(paymentDetail.updated_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list"></button>
                <button className="gotolog_btn" onClick={this.onLogBtn} data-detect="admin_payment_detail_button_go_to_log"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = PaymentDetailData.mapStateToProps;
let mapDispatchToProps = PaymentDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentDetailView));
