import React, { Component } from 'react';
import MyConectColors from '../../../components/colors/MyConectColors';

//img
import AddIcon from "../../../img/icon_plus.png"
import DisabledAddIcon from "../../../img/icon_plus_grey.png";

class MiniSquareAddButton extends Component {
    render() {
        const { disabled } = this.props;

        const textColor = {
            color: !disabled ? MyConectColors.BG_PRIMARY.TEXT_DEFAULT : MyConectColors.BUTTON_TEXT_DISABLE_GREY,
        };

        return (
            <button className={`myConect_miniSquare_add_button ${disabled ? 'disabled' : ''}`} disabled={disabled} style={textColor}>
                <div className='myConect_buttton_subTitle'>
                    {disabled ? (
                        <img src={DisabledAddIcon} alt="Add Icon" />
                    ) : (
                        <img src={AddIcon} alt="Add Icon" />
                    )}
                    &nbsp;추가
                </div>
            </button>
        );
    }
}

export default MiniSquareAddButton;
