import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ru-RU"; // you can import any other locale
import { Loading } from "../../../components/Paths";
import TermsAddData from "../Add/TermsAddData";


// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/dropdown";
import "bootstrap/js/modal";
import "bootstrap/js/tooltip";

//css
import "../../../css/common.css";
import "../../../css/summernote/summernote-lite.min.css";
import "../../../css/termsAndCondition/Terms/termsAdd.css";

class TermsAddView extends TermsAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      title: "",
      requiredId: "",
      languageCode: "",
      requiredStatus: "",
      description: "",
      linkText: "",
      fileText: "",
      fileContent: "",
      termsGroupList: [],
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.requestTermsGroupList(); //조건 그룹 아이디 selectBox값 list 받아오기
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change title
  onChangeTitle = (e) => {
    const inputValue = e.target.value;
    this.setState({ title: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change requiredId
  onChangeRequiredId = (requiredId) => {
    this.setState({ requiredId: requiredId }, () => {
      this.onCheckValidation();
    });
  };
  // change langauageCode
  onChangeLanguageCode = (e) => {
    const inputValue = e.target.value;
    this.setState({ languageCode: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change requiredStatus
  onChangeRequiredStatus = (e) => {
    const inputValue = e.target.value;
    this.setState({ requiredStatus: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change  description
  onChangeDescription = (content) => {
    this.setState({ description: content }, () => {
      this.onCheckValidation(); // 내용이 변경될 때마다 유효성을 확인
    });
  };

  // summernote image 업로드할 때
  onUploadImage = (images, insertImage) => {
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();

      reader.onloadend = () => {
        insertImage(reader.result);
      };

      reader.readAsDataURL(images[i]);
    }
  };

  // change linkText
  onChangeLinkText = (e) => {
    const inputValue = e.target.value;
    this.setState({ linkText: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ fileContent: file, fileText: file ? file.name : "ex) MyCONECT.png" }, () => {
      this.onCheckValidation();
    });
  };

  // change value of state  "N" <=> "Y"
  onChangeRadio = (e) => {
    const name = e.target.name;
    if (e.target.value == "Y") {
      this.setState(
        {
          [name]: "Y",
        },
        () => this.onCheckValidation()
      );
    } else {
      this.setState(
        {
          [name]: "N",
        },
        () => this.onCheckValidation()
      );
    }
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (field == "description") {
      // summernote가 빈칸으로 표시될 때
      if (value == "" || value == "<p><br></p>" || value == "<p></p>" || value == null) {
        this.setState({
          errorMessage: messageKey,
        });
        return false;
      } else {
        return true;
      }
    } else {
      if (value == "" || value == null) {
        this.setState({
          errorMessage: messageKey,
        });
        return false;
      } else {
        return true;
      }
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("title", "admin_terms_add_validation_title") &&
      this.confirmField("requiredId", "admin_terms_add_validation_terms_group_id") &&
      this.confirmField("languageCode", "admin_terms_add_validation_language_code") &&
      this.confirmField("requiredStatus", "admin_terms_add_validation_required") &&
      this.confirmField("description", "admin_terms_add_validation_description") &&
      this.confirmField("linkText", "admin_terms_add_validation_link_text") &&
      this.confirmField("fileContent", "admin_terms_add_validation_file")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = async () => {
    this.setState({
      isShowAllLoading: true,
    });
    await this.requestAddTerms(this.state.title, this.state.requiredId, this.state.languageCode, this.state.requiredStatus, this.state.description, this.state.linkText, this.state.fileContent);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/termsListPage");
  };

  render() {
    const { termsGroupList } = this.props;

    const termsListAvailable = termsGroupList && termsGroupList.results && termsGroupList.results.rows;
    return (
      <div className="addTerms">
        {this.state.isShowAllLoading && <Loading />}
        <div className="terms_add_container">
          <div className="addterms_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_terms_add_title">ADD TERMS</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addterms_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addterms_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_title">Title</p>
                      </div>
                      <div className="detail_cell content terms_title">
                        <input type="text" id="title" name="title" autoComplete="off" value={this.state.title} onChange={this.onChangeTitle} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_terms_group_id">Terms Group ID</p>
                      </div>
                      <div className="detail_cell content terms_group_id">
                        <div className="detail_selectBox">
                          <div>
                            {termsListAvailable && (
                              <select
                                className="terms_group_id_select"
                                id="termsGroupList"
                                onChange={(e) => {
                                  const selectedIndex = e.target.selectedIndex;
                                  const selectedTitle = e.target.options[selectedIndex].text;
                                  const selectedGroup = this.props.termsGroupList.results.rows.find((group) => group.title == selectedTitle);
                                  //선택된 title값과 같은 값을 termsGroupList에서 찾아서 termsGroupList id값을 전달함
                                  if (selectedGroup) {
                                    this.onChangeRequiredId(selectedGroup.id);
                                  }
                                }}
                                value={this.state.requiredId}
                              >
                                <option disabled value="" data-detect="admin_terms_add_label_choose">
                                  Choose
                                </option>
                                {termsGroupList.results.rows.map((termsGroup) => (
                                  <option key={termsGroup.id} value={termsGroup.id}>
                                    {termsGroup.title}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_language_code">Language Code</p>
                      </div>
                      <div className="detail_cell content terms_languageCode">
                        <input type="text" id="languageCode" name="languageCode" placeholder="ex) ko" autoComplete="off" value={this.state.languageCode} onChange={this.onChangeLanguageCode} />
                      </div>
                    </div>
                    {/* requiredStatus */}
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_required">Required</p>
                      </div>
                      <div className="detail_cell content info_requiredStatus radio_container">
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="requiredStatus" value="Y" checked={this.state.requiredStatus == "Y"} onChange={this.onChangeRadio} data-detect="admin_terms_add_button_yes" />
                            Yes
                          </label>
                        </div>
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="requiredStatus" value="N" checked={this.state.requiredStatus == "N"} onChange={this.onChangeRadio} data-detect="admin_terms_add_button_no" />
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* 설명 */}
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_description">Description</p>
                      </div>
                      <div className="detail_cell content terms_description">
                        <ReactSummernote
                          value=""
                          className="customReactSummernote"
                          options={{
                            lang: "en-EN",
                            height: 300,
                            dialogsInBody: true,
                            toolbar: [
                              ["style", ["style"]],
                              ["font", ["bold", "underline", "clear"]],
                              ["fontname", ["fontname"]],
                              ["para", ["ul", "ol", "paragraph"]],
                              ["table", ["table"]],
                              ["insert", ["link", "picture", "video"]],
                              ["view", ["fullscreen", "codeview"]],
                            ],
                          }}
                          onChange={this.onChangeDescription}
                          onImageUpload={this.onUploadImage}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_link_text">Link Text</p>
                      </div>
                      <div className="detail_cell content terms_link_text">
                        <input type="text" id="email" name="email" placeholder="Link Text" autoComplete="off" value={this.state.linkText} onChange={this.onChangeLinkText} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_add_label_file_url">
                        File URL
                      </div>
                      <div>
                        <div className="file_upload_button_container" style={{ display: "flex" }}>
                          <form>
                            <label htmlFor="fileContent" className="file_upload_button" data-detect="hotel_terms_add_button_upload">
                              Upload
                            </label>
                            <input
                              type="file"
                              id="fileContent"
                              accept=".html"
                              onChange={this.onFileChange}
                              title="Upload"
                              style={{ display: "none" }} // input 요소를 화면에서 감추기
                            />
                          </form>
                          {this.state.fileText ? <p className="file_textName">{this.state.fileText}</p> : <p className="file_textName">{"ex) MyCONECT.html"}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_terms_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_terms_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsAddData.mapStateToProps;
let mapDispatchToProps = TermsAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsAddView));
