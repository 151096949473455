// SettingListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import SettingListData from "./SettingListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/setting/settingList.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class SettingListView extends SettingListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
    };
  }

  componentDidMount() {
    // 컴포넌트가 처음 마운트될 때 getList 호출
    this.requestGetSettingList();
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  updateBtn = () => {
    this.props.history.push("/settingUpdatePage");
  };



  render() {
    let settingList = null;

    if (
      this.props.settingList != null &&
      this.props.settingList?.success == true
    ) {
      settingList = this.props.settingList.results;
    }

    return (
      <div className="settingList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="settinglist_container">
          <div className="settinglist_container_inner">
            <div className="title_container">
              <div data-detect="admin_setting_detail_title">
                MyCONECT SETTING
              </div>
              <div className="update_date">
                <p data-detect="admin_setting_detail_label_date">Update Date</p>
                <span>
                  {settingList && formatDateTimeDot(settingList.updated_at)}
                </span>
              </div>
            </div>
            <div className="setting_information_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="setting_info">
                    <div className="detail_row title_row">
                      <div
                        className="detail_cell title_index"
                        data-detect="admin_setting_detail_label_setting"
                      >
                        Setting
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_setting_detail_label_coordinate_distance"
                      >
                        Coordinate Distance
                      </div>
                      <div className="detail_cell content info_coordinateDistance">
                        {settingList && settingList.coordinateDistance}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_container">
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={this.updateBtn}
                  data-detect="admin_setting_detail_button_edit"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = SettingListData.mapStateToProps;
let mapDispatchToProps = SettingListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SettingListView)
);
