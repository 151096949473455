import React, { Component } from 'react';
import MyConectColors from '../../../components/colors/MyConectColors';

//img
import DeleteIcon from "../../../img/icon_cross_error.png"
import DisabledDeleteIcon from "../../../img/icon_cross_error_grey.png";

class DeleteButton extends Component {
    render() {
        const { disabled } = this.props;

        const textColor = {
            color: !disabled ? MyConectColors.BG_PRIMARY.TEXT_DEFAULT : MyConectColors.BUTTON_TEXT_DISABLE_GREY,
        };

        return (
            <button className={`myConect_delete_button ${disabled ? 'disabled' : ''}`} disabled={disabled} style={textColor}>
                <div className='myConect_buttton_subTitle'>
                    {disabled ? (
                        <img src={DisabledDeleteIcon} alt="Add Icon" />
                    ) : (
                        <img src={DeleteIcon} alt="Add Icon" />
                    )}
                    &nbsp; 삭제
                </div>
            </button>
        );
    }
}

export default DeleteButton;
