const KoreanLanguage = {
  version: "2.0.18",
  language: {
    ko: {
      login_button_admin: "관리자 로그인 페이지",
      login_button_hotel: "호텔 로그인 페이지",
      login_guide_login: "로그인 페이지를 클릭하세요",
      admin_login_admin_label_admin: "관리자",
      admin_login_admin_placeholder_email: "이메일",
      admin_login_admin_placeholder_password: "비밀번호",
      admin_login_admin_button_login: "로그인",
      admin_login_admin_guide_login: "관리자로 로그인하세요.",
      hotel_login_hotel_label_hotel: "호텔",
      hotel_login_hotel_placeholder_email: "이메일",
      hotel_login_hotel_placeholder_password: "비밀번호",
      hotel_login_hotel_button_login: "로그인",
      hotel_login_hotel_guide_login: "호텔 사용자로 로그인하세요.",
      common_taxi_call: "택시",
      common_parcel_service: "택배 서비스",
      common_luggage_service: "수하물 서비스",
      common_taxi_call_ex: "익스프레스 택시",
      common_parcel_service_ex: "익스프레스 택배 서비스",
      common_luggage_service_ex: "익스프레스 수하물 서비스",
      common_green_campaign: "그린 캠페인",
      common_make_up_room: "객실 청소",
      common_turn_down_service: "객실 정돈",
      common_emergency: "응급 서비스",
      common_dnd: "DND",
      common_in_room_dining: "인 룸 다이닝 서비스",
      common_ironing_service: "다림질 서비스",
      common_laundry_service: "세탁 서비스",
      common_amenity_service: "어메니티 서비스",
      common_in_room_dining_ex: "익스프레스 인 룸 다이닝 서비스",
      common_ironing_service_ex: "익스프레스 다림질 서비스",
      common_laundry_service_ex: "익스프레스 세탁 서비스",
      common_waiting_request: "출차 요청 서비스",
      common_valet_return_additional_charge: "발렛 재입차 추가 요금",
      common_requested: "요청",
      common_confirmed: "확인",
      common_change: "변경",
      common_cancled: "취소",
      common_standby: "대기중",
      common_completed: "완료",
      common_stopped: "중지",
      common_picked_up: "수거됨",
      common_started: "시작",
      common_delivered: "배달 완료",
      common_not_available: "서비스 불가",
      common_delay: "지연",
      common_room_posting: "객실 청구",
      common_room_posting_refund: "객실 청구 환불",
      common_self_settlement: "직접 결제",
      common_self_settlement_refund: "직접 결제 환불",
      common_staff_room_posting: "객실 청구(직원처리)",
      common_staff_settlement: "직접 결제(직원처리)",
      common_put_off: "연기",
      common_vehicle_change: "차량 번호 변경",
      common_valet: "발렛 접수",
      common_location: "주차 위치",
      common_waiting: "출차대기 요청",
      common_waiting_confirm: "출차대기 확인",
      common_date_change: "출차대기 시간 변경",
      common_vehicle_standby: "차량 준비 완료",
      common_preparing: "준비중",
      common_return: "회차",
      common_handover: "전달 완료",
      common_deleted: "삭제",
      commom_delay_15: "15분 지연",
      commom_delay_30: "30분 지연",
      commom_delay_45: "45분 지연",
      commom_delay_60: "60분 지연",
      commom_delay_asaa: "빠른 시간 내",
      common_normal: "일반",
      common_express: "익스프레스",
      common_boundary_line: "제한선",
      common_accepted: "수락함",
      common_rejected: "거절함",
      common_key_expired: "키 만료됨",
      common_disposable_key: "일회용",
      common_today_only_key: "당일 사용",
      common_entire_stay_key: "전체 투숙 기간 사용",
      common_validation_name: "이름을 입력하세요.",
      common_validation_content: "내용을 입력하세요.",
      common_validation_select_img: "이미지를 선택하세요.",
      common_summernote_lang: "ko-KR",
      common_clean: "청소 완료",
      common_inspected: "검사 완료",
      common_dirty: "청소 필요",
      common_out_of_order: "객실 불량",
      common_out_of_service: "서비스 불가",
      common_pick_up: "픽업",
      common_cleaning: "청소중",
      common_done: "완료",
      common_not_reserved: "예약 전",
      common_departed: "퇴실",
      common_arrived: "입실",
      common_week_mon: "월",
      common_week_tue: "화",
      common_week_wed: "수",
      common_week_thu: "목",
      common_week_fri: "금",
      common_week_sat: "토",
      common_week_sun: "일",
      common_percent: "퍼센트",
      common_nights: "숙박일",
      common_flat: "고정 요금",
      common_all_nights: "전체 숙박일",
      common_deposit_only: "보증금",
      common_first_night: "숙박 첫날",
      common_night: "일",
      common_inactive: "비활성화",
      common_active: "활성화",
      common_success: "성공",
      common_failure: "실패",
      admin_header_link_password: "비밀번호",
      admin_header_link_logout: "로그아웃",
      admin_header_link_hotel: "호텔",
      admin_header_link_setting: "설정",
      admin_header_accordion_location: "위치",
      admin_header_link_region_list: "지역 목록",
      admin_header_link_country_list: "국가 목록",
      admin_header_link_city_list: "도시 목록",
      admin_header_link_application_user: "앱 사용자",
      admin_header_link_payment: "결제",
      admin_header_accordion_transfer: "통신",
      admin_header_link_transfer_log_list: "통신 로그 목록",
      admin_header_link_user_transfer_log_list: "사용자 통신 로그 목록",
      admin_header_link_transaction_log_list: "상태 처리 과정 목록",
      admin_header_link_user_transfer_log_history: "사용자 통신 로그 히스토리",
      admin_header_link_staff_transfer_log_history: "Staff 통신 로그 히스토리",
      admin_header_link_user_notification_log_list: "사용자 알림 로그 목록",
      admin_header_link_staff_notification_log_list: "스태프 알림 로그 목록",
      admin_header_accordion_version: "버전",
      admin_header_link_version: "버전",
      admin_header_link_application_down_version_list: "앱 다운로드 화면 설정",
      admin_header_link_guest_application_down_list: "게스트 앱 최소버전 설정",
      admin_header_link_staff_application_down_list: "스태프 앱 최소버전 설정",
      admin_header_link_paking_staff_application_down_list: "주차 스태프 앱 최소버전 설정",
      admin_header_link_application_translation: "앱 번역",
      admin_header_link_booking_email: "이메일 이미지",
      admin_header_accordion_email_form: "이메일 양식",
      admin_header_link_change_password: "비밀번호 변경",
      admin_header_link_forgot_id: "ID 찾기",
      admin_header_link_forgot_password: "비밀번호 찾기",
      admin_header_link_create_confirm_email: "이메일 확인",
      admin_header_link_create_confirm_email_fail: "이메일 확인 실패",
      admin_header_link_create_confirm_email_success: "이메일 확인 성공",
      admin_header_link_update_profile: "프로필 업데이트",
      admin_header_link_withdraw: "회원탈퇴",
      admin_header_link_app_endpoint: "앱 엔드포인트",
      admin_header_accordion_terms_and_conditions: "이용 약관",
      admin_header_link_terms: "조건",
      admin_header_link_terms_group: "조건 그룹",
      admin_header_link_agreement_history: "동의 내역",
      admin_header_current_version: "현재 버전",
      admin_header_translation_version: "번역 버전",
      admin_header_link_admin_user: "관리자",
      admin_header_accordion_auth_data: "비콘/GPS 연결 기록",
      admin_header_link_auth_data_log_list: "비콘/GPS 연결 목록",
      admin_header_link_use_key_log: "키 사용 로그",
      admin_header_link_amenity: "편의시설",
      admin_header_link_facility_image: "호텔 시설 이미지",
      admin_header_link_recommend_app: "추천 앱",
      admin_header_link_id_recognition_information: "신분증 인증 정보",
      admin_header_link_statistics: "통계",
      admin_password_title: "비밀번호 변경",
      admin_password_guide_password: "최소 8자 이상의 새 비밀번호를 입력하세요.",
      admin_password_validation_current_password: "* 현재 비밀번호를 입력하세요.",
      admin_password_validation_character_number_combining: "* 영문과 숫자를 조합하여 8자 이상 입력하세요.",
      admin_password_validation_new_password: "* 새 비밀번호를 입력하세요.",
      admin_password_validation_new_password_again: "* 새 비밀번호를 다시 입력하세요.",
      admin_password_validation_not_match: "* 비밀번호가 일치하지 않습니다.",
      admin_password_placeholder_password: "비밀번호",
      admin_password_placeholder_new_password: "새 비밀번호",
      admin_password_placeholder_new_confirm_password: "새 비밀번호 확인",
      admin_password_button_save: "저장",
      admin_hotel_list_title: "호텔 목록",
      admin_hotel_list_placeholder_search: "검색",
      admin_hotel_list_label_hotel_no: "호텔 번호",
      admin_hotel_list_label_name: "이름",
      admin_hotel_list_label_address: "주소",
      admin_hotel_list_label_is_show: "전시 여부",
      admin_hotel_list_label_date: "날짜",
      admin_hotel_list_guide_empty: "목록이 비어 있습니다",
      admin_hotel_list_button_delete: "삭제",
      admin_hotel_list_button_add: "추가",
      admin_hotel_list_button_copy: "복사",
      admin_hotel_add_title: "호텔 추가",
      admin_hotel_add_guide_click_add: "정보 입력 후 <<추가>> 버튼을 눌러 호텔을 추가하세요.",
      admin_hotel_add_validation_name: "호텔명을 입력하세요.",
      admin_hotel_add_validation_address: "호텔 주소를 입력하세요.",
      admin_hotel_add_validation_korea_english: "한국어, 영어 또는 ._ - 를 사용하여 입력하세요.",
      admin_hotel_add_validation_email: "사용자 이메일을 입력하세요.",
      admin_hotel_add_validation_email_format: "이메일 형식에 맞게 입력하세요.",
      admin_hotel_add_validation_password: "사용자 비밀번호를 입력하세요.",
      admin_hotel_add_validation_character_number_combining: "영문과 숫자를 조합하여 8자 이상 입력하세요.",
      admin_hotel_add_validation_not_match: "비밀번호가 일치하지 않습니다.",
      admin_hotel_add_validation_image_file: "로고 이미지를 업로드하세요.",
      admin_hotel_add_label_name: "이름",
      admin_hotel_add_placeholder_hotel_name: "호텔명",
      admin_hotel_add_label_address: "주소",
      admin_hotel_add_placeholder_hotel_address: "호텔 주소",
      admin_hotel_add_label_email: "이메일",
      admin_hotel_add_placeholder_staff_email: "직원 이메일",
      admin_hotel_add_label_password: "비밀번호",
      admin_hotel_add_placeholder_staff_password: "직원 비밀번호",
      admin_hotel_add_label_confirm_password: "비밀번호 확인",
      admin_hotel_add_placeholder_staff_confirm_password: "직원 비밀번호 확인",
      admin_hotel_add_label_logo: "로고",
      admin_hotel_add_button_upload: "업로드",
      admin_hotel_add_placeholder_upload: "ex) MyCONECT.png",
      admin_hotel_add_button_go_to_list: "목록으로 가기",
      admin_hotel_add_button_add: "추가",
      admin_hotel_detail_title: "호텔 정보",
      admin_hotel_detail_label_hotel_no: "호텔 번호",
      admin_hotel_detail_label_name: "호텔명",
      admin_hotel_detail_label_address: "주소",
      admin_hotel_detail_label_region: "지역",
      admin_hotel_detail_label_country: "국가",
      admin_hotel_detail_label_city: "도시",
      admin_hotel_detail_label_country_name: "국가명",
      admin_hotel_detail_label_payment_server_url: "결제 서버 URL",
      admin_hotel_detail_label_is_direct_payable: "Direct pay 가능여부",
      admin_hotel_detail_label_is_card_tokenizable: "카드정보 토큰화 가능여부",
      admin_hotel_detail_label_is_show: "전시 여부",
      admin_hotel_detail_button_yes: "예",
      admin_hotel_detail_button_no: "아니오",
      admin_hotel_detail_label_logo: "로고",
      admin_hotel_detail_label_hotel_photo: "호텔 사진",
      admin_hotel_detail_label_hotel_map: "지도",
      admin_hotel_detail_button_go_to_list: "목록으로 가기",
      admin_hotel_detail_button_edit: "수정",
      admin_hotel_edit_title: "호텔 정보",
      admin_hotel_edit_label_hotel_no: "호텔 번호",
      admin_hotel_edit_label_name: "호텔명",
      admin_hotel_edit_label_address: "주소",
      admin_hotel_edit_label_region: "지역",
      admin_hotel_edit_label_country: "국가",
      admin_hotel_edit_label_choose: "선택",
      admin_hotel_edit_label_city: "도시",
      admin_hotel_edit_label_country_name: "국가명",
      admin_hotel_edit_label_logo: "로고",
      admin_hotel_edit_label_payment_server_url: "결제 서버 URL",
      admin_hotel_edit_label_is_direct_payable: "Direct pay 가능여부",
      admin_hotel_edit_label_is_card_tokenizable: "카드정보 토큰화 가능여부",
      admin_hotel_edit_label_is_show: "전시 여부",
      admin_hotel_edit_button_yes: "예",
      admin_hotel_edit_button_no: "아니오",
      admin_hotel_edit_button_upload: "업로드",
      admin_hotel_edit_placeholder_upload: "ex) MyCONECT.png",
      admin_hotel_edit_label_hotel_photo: "호텔 사진",
      admin_hotel_edit_label_hotel_map: "지도",
      admin_hotel_edit_button_search: "검색",
      admin_hotel_edit_button_cancel: "취소",
      admin_hotel_edit_button_ok: "확인",
      admin_hotel_edit_validation_region: "지역을 선택해주세요.",
      admin_hotel_edit_validation_country: "국가를 선택해주세요.",
      admin_hotel_edit_validation_city: "도시를 선택해주세요.",
      admin_hotel_edit_validation_country_name: "국가명을 선택하세요.",
      admin_hotel_edit_validation_is_show: "전시여부를 선택해주세요.",
      admin_hotel_copy_title: "호텔 복사",
      admin_hotel_copy_guide_click_copy: 'HOTEL을 복사하려면 정보를 입력하세요. 그리고 "복사" 버튼을 클릭하세요.',
      admin_hotel_copy_button_copy: "복사",
      admin_setting_detail_title: "MyCONECT 설정",
      admin_setting_detail_label_date: "업데이트 날짜",
      admin_setting_detail_label_setting: "설정",
      admin_setting_detail_label_coordinate_distance: "좌표 거리",
      admin_setting_detail_button_edit: "수정",
      admin_setting_edit_title: "MyCONECT 설정",
      admin_setting_edit_label_date: "업데이트 날짜",
      admin_setting_edit_label_setting: "설정",
      admin_setting_edit_label_coordinate_distance: "좌표 거리",
      admin_setting_edit_button_ok: "확인",
      admin_setting_edit_button_cancel: "취소",
      admin_setting_edit_validation_coordinate_distance: "좌표 거리를 입력해주세요.",
      admin_region_list_title: "지역 목록",
      admin_region_list_placeholder_search: "검색",
      admin_region_list_label_region: "지역",
      admin_region_list_label_date: "날짜",
      admin_region_list_guide_empty: "목록이 비어 있습니다.",
      admin_region_list_button_delete: "삭제",
      admin_region_list_button_add: "추가",
      admin_region_add_title: "지역 추가",
      admin_region_add_guide_click_add: "정보 입력 후 <<추가>> 버튼을 눌러 지역을 추가하세요.",
      admin_region_add_validation_name: "* 지역 이름을 입력하십시오.",
      admin_region_add_label_region: "지역",
      admin_region_add_placeholder_region_name: "지역 이름",
      admin_region_add_button_go_to_list: "목록으로 가기",
      admin_region_add_button_add: "추가",
      admin_region_detail_title: "지역",
      admin_region_detail_label_region: "지역",
      admin_region_detail_label_created_at: "생성 시간",
      admin_region_detail_label_updated_at: "업데이트 시간",
      admin_region_detail_button_go_to_list: "목록으로 가기",
      admin_region_detail_button_edit: "수정",
      admin_region_edit_title: "지역",
      admin_region_edit_validation_name: "* 지역 이름을 입력하십시오.",
      admin_region_edit_label_region: "지역",
      admin_region_edit_placeholder_region_name: "지역 이름",
      admin_region_edit_label_created_at: "생성 시간",
      admin_region_edit_label_updated_at: "업데이트 시간",
      admin_region_edit_button_cancel: "취소",
      admin_region_edit_button_ok: "확인",
      admin_country_list_title: "국가 목록",
      admin_country_list_select_region_all: "지역 ( 모두 )",
      admin_country_list_placeholder_search: "검색",
      admin_country_list_label_region: "지역",
      admin_country_list_label_country: "국가",
      admin_country_list_label_date: "날짜",
      admin_country_list_guide_empty: "목록이 비어 있습니다.",
      admin_country_list_button_delete: "삭제",
      admin_country_list_button_add: "추가",
      admin_country_add_title: "국가 추가",
      admin_country_add_guide_click_add: "정보 입력 후 <<추가>> 버튼을 눌러 국가를 추가하세요.",
      admin_country_add_validation_region: "* 지역을 선택하세요.",
      admin_country_add_validation_country: "* 국가명을 입력하십시오.",
      admin_country_add_label_region: "지역",
      admin_country_add_label_choose: "선택",
      admin_country_add_label_country: "국가",
      admin_country_add_placeholder_country_name: "국가명",
      admin_country_add_button_go_to_list: "목록으로 가기",
      admin_country_add_button_add: "추가",
      admin_country_detail_title: "국가",
      admin_country_detail_label_region: "지역",
      admin_country_detail_label_country: "국가",
      admin_country_detail_placeholder_country_name: "국가명",
      admin_country_detail_label_created_at: "생성 시간",
      admin_country_detail_label_updated_at: "업데이트 시간",
      admin_country_detail_button_go_to_list: "목록으로 가기",
      admin_country_detail_button_edit: "수정",
      admin_country_edit_title: "국가",
      admin_country_edit_validation_country: "* 국가명을 입력하십시오.",
      admin_country_edit_label_region: "지역",
      admin_country_edit_label_country: "국가",
      admin_country_edit_placeholder_country_name: "국가명",
      admin_country_edit_label_created_at: "생성 시간",
      admin_country_edit_label_updated_at: "업데이트 시간",
      admin_country_edit_button_cancel: "취소",
      admin_country_edit_button_ok: "확인",
      admin_city_list_title: "도시 목록",
      admin_city_list_select_region_all: "지역 ( 모두 )",
      admin_city_list_select_country_all: "국가 ( 모두 )",
      admin_city_list_placeholder_search: "검색",
      admin_city_list_label_region: "지역",
      admin_city_list_label_country: "국가",
      admin_city_list_label_city: "도시",
      admin_city_list_label_date: "날짜",
      admin_city_list_guide_empty: "목록이 비어 있습니다.",
      admin_city_list_button_delete: "삭제",
      admin_city_list_button_add: "추가",
      admin_city_add_title: "도시 추가",
      admin_city_add_guide_click_add: "정보 입력 후 <<추가>> 버튼을 눌러 도시를 추가하세요.",
      admin_city_add_validation_region: "* 지역을 선택하세요.",
      admin_city_add_validation_country: "* 국가를 선택하세요.",
      admin_city_add_validation_city: "* 도시 이름을 입력하십시오.",
      admin_city_add_label_region: "지역",
      admin_city_add_label_country: "국가",
      admin_city_add_label_choose: "선택",
      admin_city_add_label_city: "도시",
      admin_city_add_placeholder_city_name: "도시 이름",
      admin_city_add_button_go_to_list: "목록으로 가기",
      admin_city_add_button_add: "추가",
      admin_city_detail_label_region: "지역",
      admin_city_detail_title: "도시",
      admin_city_detail_label_country: "국가",
      admin_city_detail_label_city: "도시",
      admin_city_detail_placeholder_city_name: "도시 이름",
      admin_city_detail_label_created_at: "생성 시간",
      admin_city_detail_label_updated_at: "업데이트 시간",
      admin_city_detail_button_go_to_list: "목록으로 가기",
      admin_city_detail_button_edit: "수정",
      admin_city_edit_title: "도시",
      admin_city_edit_validation_city: "* 도시 이름을 입력하십시오.",
      admin_city_edit_label_region: "지역",
      admin_city_edit_label_country: "국가",
      admin_city_edit_label_city: "도시",
      admin_city_edit_placeholder_city_name: "도시 이름",
      admin_city_edit_label_created_at: "생성 시간",
      admin_city_edit_label_updated_at: "업데이트 시간",
      admin_city_edit_button_cancel: "취소",
      admin_city_edit_button_ok: "확인",
      admin_application_user_list_title: "앱 사용자 목록",
      admin_application_user_list_placeholder_select_box_user: "사용자",
      admin_application_user_list_select_email: "이메일",
      admin_application_user_list_select_name: "이름",
      admin_application_user_list_placeholder_search: "검색",
      admin_application_user_list_label_email: "이메일",
      admin_application_user_list_label_backup_email: "보조 이메일",
      admin_application_user_list_label_name: "이름",
      admin_application_user_list_label_alternative_name: "한글 이름",
      admin_application_user_list_label_mail_confirmed_at: "이메일 확인 시간",
      admin_application_user_list_label_date: "날짜",
      admin_application_user_list_button_delete: "삭제",
      admin_application_user_list_guide_empty: "목록이 비어 있습니다",
      admin_application_user_detail_title: "사용자 이메일",
      admin_application_user_detail_label_user_information: "앱 사용자 정보",
      admin_application_user_detail_user_info_email: "이메일",
      admin_application_user_detail_user_info_gender: "성별",
      admin_application_user_detail_user_info_first_name: "이름",
      admin_application_user_detail_user_info_last_name: "성",
      admin_application_user_detail_user_info_alternative_first_name: "한글 이름",
      admin_application_user_detail_user_info_alternative_last_name: "한글 성",
      admin_application_user_detail_user_info_member_type: "회원 타입",
      admin_application_user_detail_user_info_backup_email: "보조 이메일",
      admin_application_user_detail_user_info_mail_confirmed_at: "이메일 확인 시간",
      admin_application_user_detail_user_info_push_token: "푸쉬 토큰",
      admin_application_user_detail_user_info_os_type: "OS 유형",
      admin_application_user_detail_user_info_language_code: "언어 코드",
      admin_application_user_detail_user_info_updated_at: "수정 시간",
      admin_application_user_detail_user_info_date: "날짜",
      admin_application_user_detail_button_go_to_list: "목록으로 가기",
      admin_payment_list_title: "결제 목록",
      admin_payment_list_placeholder_hotel_id: "호텔 ID",
      admin_payment_list_placeholder_user_name: "사용자 이름",
      admin_payment_list_placeholder_search: "검색",
      admin_payment_list_label_no: "번호",
      admin_payment_list_label_reservation_id: "예약 ID",
      admin_payment_list_label_user_name: "사용자 이름",
      admin_payment_list_label_long_info: "롱 정보",
      admin_payment_list_label_amount: "금액",
      admin_payment_list_label_unit: "단위",
      admin_payment_list_label_pg_status_code: "PG 상태 코드",
      admin_payment_list_label_pg_status_message: "PG 상태 메시지",
      admin_payment_list_label_hotel_id: "호텔 ID",
      admin_payment_list_label_date: "날짜",
      admin_payment_detail_label_order_no: "주문 번호",
      admin_payment_detail_label_user: "사용자",
      admin_payment_detail_label_user_profile_id: "프로필 ID",
      admin_payment_detail_label_user_title: "경칭",
      admin_payment_detail_label_user_first_name: "이름",
      admin_payment_detail_label_user_last_name: "성별",
      admin_payment_detail_label_user_email: "이메일",
      admin_payment_detail_label_user_date: "날짜",
      admin_payment_detail_label_reservation: "예약",
      admin_payment_detail_label_reservation_confirmation_no: "예약 번호",
      admin_payment_detail_label_reservation_reservation_id: "예약 ID",
      admin_payment_detail_label_reservation_reservation_status: "예약 상태",
      admin_payment_detail_label_reservation_check_in_date: "체크인 날짜",
      admin_payment_detail_label_reservation_check_out_date: "체크아웃 날짜",
      admin_payment_detail_label_hotel: "호텔",
      admin_payment_detail_label_hotel_hotel_id: "호텔 ID",
      admin_payment_detail_label_hotel_hotel_name: "호텔명",
      admin_payment_detail_label_hotel_region: "지역",
      admin_payment_detail_label_hotel_country: "국가",
      admin_payment_detail_label_hotel_city: "도시",
      admin_payment_detail_label_payment: "결제",
      admin_payment_detail_button_go_to_list: "목록으로 가기",
      admin_payment_detail_button_go_to_log: "로그 목록으로 가기",
      admin_payment_detail_label_payment_order_no: "주문 번호",
      admin_payment_detail_label_payment_reservation_id: "예약 ID",
      admin_payment_detail_label_payment_long_info: "롱 정보",
      admin_payment_detail_label_payment_folio_view_no: "폴리오 조회 번호",
      admin_payment_detail_label_payment_amount: "금액",
      admin_payment_detail_label_payment_unit: "단위",
      admin_payment_detail_label_payment_pg_order_no: "PG 주문 번호",
      admin_payment_detail_label_payment_pg_status: "PG 상태",
      admin_payment_detail_label_payment_pg_status_code: "PG 상태 코드",
      admin_payment_detail_label_payment_pg_status_message: "PG 상태 메시지",
      admin_payment_detail_label_payment_pg_amount: "PG 금액",
      admin_payment_detail_label_payment_pg_unit: "PG 단위",
      admin_payment_detail_label_payment_pg_billing_no: "PG 청구 번호",
      admin_payment_detail_label_payment_confirmed_at: "확인 시간",
      admin_payment_detail_label_payment_created_at: "생성 시간",
      admin_payment_detail_label_payment_updated_at: "업데이트 시간",
      admin_payment_log_list_title: "결제 로그 리스트",
      admin_payment_log_list_label_no: "번호",
      admin_payment_log_list_label_type: "타입",
      admin_payment_log_list_label_method: "방법",
      admin_payment_log_list_label_path: "경로",
      admin_payment_log_list_label_status: "상태",
      admin_payment_log_list_label_success: "성공",
      admin_payment_log_list_label_created_at: "생성 시간",
      admin_payment_log_list_guide_empty: "목록이 비어 있습니다",
      admin_payment_log_list_button_go_to_detail: "상세로 가기",
      admin_payment_log_detail_title: "결제 로그 상세정보",
      admin_payment_log_detail_label_id: "ID",
      admin_payment_log_detail_label_hotel_id: "호텔 ID",
      admin_payment_log_detail_label_payment_id: "결제 ID",
      admin_payment_log_detail_label_transfer_code: "통신 코드",
      admin_payment_log_detail_label_type: "타입",
      admin_payment_log_detail_label_method: "방법",
      admin_payment_log_detail_label_path: "경로",
      admin_payment_log_detail_label_header: "머리말",
      admin_payment_log_detail_label_param: "변수",
      admin_payment_log_detail_label_body: "본문",
      admin_payment_log_detail_label_status: "상태",
      admin_payment_log_detail_label_session: "세션",
      admin_payment_log_detail_label_created_at: "생성 시간",
      admin_payment_log_detail_button_go_to_list: "목록으로 가기",
      admin_transfer_log_list_title: "통신 정보",
      admin_transfer_log_list_placeholder_user_id: "사용자 ID",
      admin_transfer_log_list_placeholder_hotel_id: "호텔 ID",
      admin_transfer_log_list_placeholder_search: "검색",
      admin_transfer_log_list_label_no: "번호",
      admin_transfer_log_list_label_type: "타입",
      admin_transfer_log_list_label_user: "사용자",
      admin_transfer_log_list_label_hotel_id: "호텔 ID",
      admin_transfer_log_list_label_path: "경로",
      admin_transfer_log_list_label_status: "상태",
      admin_transfer_log_list_label_success: "성공",
      admin_transfer_log_list_label_created_at: "생성 시간",
      admin_transfer_log_list_guide_empty: "목록이 비어 있습니다.",
      admin_transfer_log_detail_title: "통신 상세정보",
      admin_transfer_log_detail_label_transfer_id: "통신 ID",
      admin_transfer_log_detail_label_type: "타입",
      admin_transfer_log_detail_label_user: "사용자",
      admin_transfer_log_detail_label_hotel_id: "호텔 ID",
      admin_transfer_log_detail_label_user_email: "사용자 이메일",
      admin_transfer_log_detail_label_session: "세션",
      admin_transfer_log_detail_label_token: "토큰",
      admin_transfer_log_detail_label_path: "경로",
      admin_transfer_log_detail_label_status: "상태",
      admin_transfer_log_detail_label_header: "머리말",
      admin_transfer_log_detail_label_param: "변수",
      admin_transfer_log_detail_label_body: "본문",
      admin_transfer_log_detail_label_created_at: "생성 시간",
      admin_transfer_log_detail_label_updated_at: "업데이트 시간",
      admin_transfer_log_detail_button_go_to_list: "목록으로 가기",
      admin_user_transfer_log_list_title: "사용자 통신 목록",
      admin_user_transfer_log_list_placeholder_email: "이메일을 입력하세요",
      admin_user_transfer_log_list_placeholder_search: "검색",
      admin_user_transfer_log_list_label_no: "번호",
      admin_user_transfer_log_list_label_type: "타입",
      admin_user_transfer_log_list_label_method: "방법",
      admin_user_transfer_log_list_label_path: "경로",
      admin_user_transfer_log_list_label_status: "상태",
      admin_user_transfer_log_list_label_success: "성공",
      admin_user_transfer_log_list_label_user_email: "사용자 이메일",
      admin_user_transfer_log_list_label_created_at: "생성 시간",
      admin_user_transfer_log_list_guide_empty: "목록이 비어 있습니다",
      admin_user_transfer_log_detail_title: "사용자 통신 상세정보",
      admin_user_transfer_log_detail_label_id: "ID",
      admin_user_transfer_log_detail_label_type: "타입",
      admin_user_transfer_log_detail_label_method: "방법",
      admin_user_transfer_log_detail_label_path: "경로",
      admin_user_transfer_log_detail_label_header: "머리말",
      admin_user_transfer_log_detail_label_param: "변수",
      admin_user_transfer_log_detail_label_body: "본문",
      admin_user_transfer_log_detail_label_soap: "SOAP",
      admin_user_transfer_log_detail_label_status: "상태",
      admin_user_transfer_log_detail_label_session: "세션",
      admin_user_transfer_log_detail_label_token: "토큰",
      admin_user_transfer_log_detail_label_user_email: "사용자 이메일",
      admin_user_transfer_log_detail_label_created_at: "생성 시간",
      admin_user_transfer_log_detail_button_go_to_list: "목록으로 가기",
      admin_transaction_log_list_title: "상태 처리 과정 로그 목록",
      admin_transaction_log_list_select_complete_action: "동작 완료 ( 모두 )",
      admin_transaction_log_list_select_complete_action_commit: "실행",
      admin_transaction_log_list_select_complete_action_rollback: "되돌리기",
      admin_transaction_log_list_select_complete_action_none: "없음",
      admin_transaction_log_list_placeholder_search: "검색",
      admin_transaction_log_list_label_no: "번호",
      admin_transaction_log_list_label_act_on_function: "기능에 따라 동작",
      admin_transaction_log_list_label_complete_action: "동작 완료",
      admin_transaction_log_list_label_completed_at: "완료 시간",
      admin_transaction_log_list_label_date: "날짜",
      admin_transaction_log_detail_title: "상태 처리 과정 로그 상세",
      admin_transaction_log_detail_label_transaction_id: "거래 ID",
      admin_transaction_log_detail_label_act_on_function: "기능에 따라 동작",
      admin_transaction_log_detail_label_complete_action: "동작 완료",
      admin_transaction_log_detail_label_rollback_reason: "사유 되돌리기",
      admin_transaction_log_detail_label_completed_at: "완료 시간",
      admin_transaction_log_detail_label_created_at: "생성 시간",
      admin_transaction_log_detail_label_updated_at: "업데이트 시간",
      admin_transaction_log_detail_button_go_to_list: "목록으로 가기",
      admin_transaction_log_detail_rollback_reason_stack: "스택",
      admin_transaction_log_detail_rollback_reason_error_message: "오류 메시지",
      admin_user_transfer_log_history_title: "사용자 통신 로그 히스토리",
      admin_user_transfer_log_history_placeholder_email: "이메일을 입력하세요.",
      admin_user_transfer_log_history_placeholder_search: "검색",
      admin_user_transfer_log_history_label_no: "번호",
      admin_user_transfer_log_history_label_method: "방법",
      admin_user_transfer_log_history_label_path: "경로",
      admin_user_transfer_log_history_label_status: "상태",
      admin_user_transfer_log_history_label_success: "성공",
      admin_user_transfer_log_history_label_user_email: "사용자 이메일",
      admin_user_transfer_log_history_label_created_at: "생성 시간",
      admin_user_transfer_log_history_label_process_time: "처리 시간(ms)",
      admin_user_transfer_log_history_detail_title: "사용자 통신 로그 상세 히스토리 ( ↓ )",
      admin_user_transfer_log_history_detail_label_no: "번호",
      admin_user_transfer_log_history_detail_label_type: "타입",
      admin_user_transfer_log_history_detail_label_method: "방법",
      admin_user_transfer_log_history_detail_label_path: "경로",
      admin_user_transfer_log_history_detail_label_status: "상태",
      admin_user_transfer_log_history_detail_label_success: "성공",
      admin_user_transfer_log_history_detail_label_user_email: "사용자 이메일",
      admin_user_transfer_log_history_detail_label_created_at: "생성 시간",
      admin_user_transfer_log_history_detail_button_go_to_list: "목록으로 가기",
      admin_staff_transfer_log_history_title: "STAFF 통신 로그 히스토리",
      admin_staff_transfer_log_history_list_placeholder_user_id: "사용자 ID",
      admin_staff_transfer_log_history_list_placeholder_hotel_id: "호텔 ID",
      admin_staff_transfer_log_history_list_placeholder_search: "검색",
      admin_staff_transfer_log_history_list_label_no: "번호",
      admin_staff_transfer_log_history_list_label_method: "Method",
      admin_staff_transfer_log_history_list_label_user: "사용자",
      admin_staff_transfer_log_history_list_label_hotel_id: "호텔 ID",
      admin_staff_transfer_log_history_list_label_path: "경로",
      admin_staff_transfer_log_history_list_label_status: "상태",
      admin_staff_transfer_log_history_list_label_success: "성공",
      admin_staff_transfer_log_history_list_label_created_at: "생성 시간",
      admin_staff_transfer_log_history_list_label_process_time: "처리 시간(ms)",
      admin_staff_transfer_log_history_list_guide_empty: "목록이 비어 있습니다.",
      admin_staff_transfer_log_history_detail_title: "Staff 통신 로그 상세 히스토리 ( ↓ )",
      admin_staff_transfer_log_history_detail_label_no: "번호",
      admin_staff_transfer_log_history_detail_label_type: "타입",
      admin_staff_transfer_log_history_detail_label_method: "방법",
      admin_staff_transfer_log_history_detail_label_user: "사용자",
      admin_staff_transfer_log_history_detail_label_hotel_id: "호텔 ID",
      admin_staff_transfer_log_history_detail_label_path: "경로",
      admin_staff_transfer_log_history_detail_label_status: "상태",
      admin_staff_transfer_log_history_detail_label_success: "성공",
      admin_staff_transfer_log_history_detail_label_created_at: "생성 시간",
      admin_staff_transfer_log_history_detail_button_go_to_list: "목록으로 가기",
      admin_user_notification_log_list_title: "사용자 알림 로그 목록",
      admin_user_notification_log_list_placeholder_email: "이메일",
      admin_user_notification_log_list_placeholder_search: "검색",
      admin_user_notification_log_list_label_no: "번호",
      admin_user_notification_log_list_label_type: "타입",
      admin_user_notification_log_list_label_title: "제목",
      admin_user_notification_log_list_label_email: "이메일",
      admin_user_notification_log_list_label_user: "사용자",
      admin_user_notification_log_list_label_success: "성공",
      admin_user_notification_log_list_label_os: "OS",
      admin_user_notification_log_list_label_language_code: "언어 코드",
      admin_user_notification_log_list_label_created_at: "생성 시간",
      admin_user_notification_log_detail_title: "사용자 알림 로그 상세",
      admin_user_notification_log_detail_label_notification_id: "알림 ID",
      admin_user_notification_log_detail_label_user_id: "사용자 ID",
      admin_user_notification_log_detail_label_success: "성공",
      admin_user_notification_log_detail_label_user: "사용자",
      admin_user_notification_log_detail_label_user_email: "사용자 이메일",
      admin_user_notification_log_detail_label_language_code: "언어 코드",
      admin_user_notification_log_detail_label_os_type: "OS 타입",
      admin_user_notification_log_detail_label_type: "타입",
      admin_user_notification_log_detail_label_title: "제목",
      admin_user_notification_log_detail_label_content: "내용",
      admin_user_notification_log_detail_label_data: "데이터",
      admin_user_notification_log_detail_label_token: "토큰",
      admin_user_notification_log_detail_label_created_at: "생성 시간",
      admin_user_notification_log_detail_label_updated_at: "업데이트 시간",
      admin_user_notification_log_detail_button_go_to_list: "목록으로 가기",
      admin_staff_notification_log_list_title: "스태프 알림 로그 목록",
      admin_staff_notification_log_list_placeholder_email: "이메일",
      admin_staff_notification_log_list_placeholder_search: "검색",
      admin_staff_notification_log_list_label_no: "번호",
      admin_staff_notification_log_list_label_type: "타입",
      admin_staff_notification_log_list_label_title: "제목",
      admin_staff_notification_log_list_label_email: "이메일",
      admin_staff_notification_log_list_label_hotel: "호텔",
      admin_staff_notification_log_list_label_staff: "스태프",
      admin_staff_notification_log_list_label_task: "태스크",
      admin_staff_notification_log_list_label_department: "부서",
      admin_staff_notification_log_list_label_success: "성공",
      admin_staff_notification_log_list_label_os: "OS",
      admin_staff_notification_log_list_label_language_code: "언어 코드",
      admin_staff_notification_log_list_label_created_at: "생성 시간",
      admin_staff_notification_log_detail_title: "스태프 알림 로그 상세",
      admin_staff_notification_log_detail_label_notification_id: "알림 ID",
      admin_staff_notification_log_detail_label_hotel_id: "호텔 ID",
      admin_staff_notification_log_detail_label_staff_id: "스태프 ID",
      admin_staff_notification_log_detail_label_success: "성공",
      admin_staff_notification_log_detail_label_staff_email: "이메일",
      admin_staff_notification_log_detail_label_staff_no: "스태프 번호",
      admin_staff_notification_log_detail_label_department: "부서",
      admin_staff_notification_log_detail_label_team: "팀",
      admin_staff_notification_log_detail_label_level: "직책",
      admin_staff_notification_log_detail_label_task: "태스크",
      admin_staff_notification_log_detail_label_location: "위치",
      admin_staff_notification_log_detail_label_zone: "구역",
      admin_staff_notification_log_detail_label_room: "객실",
      admin_staff_notification_log_detail_label_guest_inquiry_level: "고객 조회 레벨",
      admin_staff_notification_log_detail_label_language_code: "언어 코드",
      admin_staff_notification_log_detail_label_os_type: "OS 타입",
      admin_staff_notification_log_detail_label_type: "타입",
      admin_staff_notification_log_detail_label_title: "제목",
      admin_staff_notification_log_detail_label_content: "내용",
      admin_staff_notification_log_detail_label_data: "데이터",
      admin_staff_notification_log_detail_label_token: "토큰",
      admin_staff_notification_log_detail_label_created_at: "생성 시간",
      admin_staff_notification_log_detail_label_updated_at: "업데이트 시간",
      admin_staff_notification_log_detail_button_go_to_list: "목록으로 가기",
      admin_version_list_title: "버전 목록",
      admin_version_list_placeholiser_search: "검색",
      admin_version_list_label_version_name: "버전 이름",
      admin_version_list_label_ios: "iOS",
      admin_version_list_label_ios_debug: "iOS 디버그",
      admin_version_list_label_android: "Android",
      admin_version_list_label_android_debug: "Android 디버그",
      admin_version_list_guide_empty: "목록이 비어 있습니다",
      admin_version_list_button_delete: "삭제",
      admin_version_list_button_add: "추가",
      admin_version_add_title: "버전 추가",
      admin_version_add_label_version_name: "버전명",
      admin_version_add_label_ios: "iOS",
      admin_version_add_label_ios_debug: "iOS 디버그",
      admin_version_add_placeholder_ios_debug: "iOS 디버그",
      admin_version_add_select_ios_true: "TRUE",
      admin_version_add_select_ios_false: "FALSE",
      admin_version_add_label_android: "Android",
      admin_version_add_label_android_debug: "Android 디버그",
      admin_version_add_placeholder_android_debug: "Android 디버그",
      admin_version_add_validation_name: "버전명을 입력해주세요.",
      admin_version_add_validation_ios: "iOS를 입력해주세요.",
      admin_version_add_validation_ios_debug: "iOS 디버그를 선택해주세요.",
      admin_version_add_validation_andriod: "Android를 입력해주세요.",
      admin_version_add_validation_andriod_debug: "Android 디버그를 선택해주세요.",
      admin_version_add_select_android_true: "TRUE",
      admin_version_add_select_android_false: "FALSE",
      admin_version_add_button_go_to_list: "목록으로 가기",
      admin_version_add_button_add: "추가",
      admin_version_detail_title: "버전 정보",
      admin_version_detail_label_version_name: "버전명",
      admin_version_detail_label_ios: "iOS",
      admin_version_detail_label_ios_debug: "iOS 디버그",
      admin_version_detail_label_android: "Android",
      admin_version_detail_label_android_debug: "Android 디버그",
      admin_version_detail_button_go_to_list: "목록으로 가기",
      admin_version_detail_button_delete: "삭제",
      admin_version_detail_button_edit: "수정",
      admin_version_edit_title: "버전 정보",
      admin_version_edit_label_version_name: "버전명",
      admin_version_edit_label_ios: "iOS",
      admin_version_edit_label_ios_debug: "iOS 디버그",
      admin_version_edit_select_ios_true: "TRUE",
      admin_version_edit_select_ios_false: "FALSE",
      admin_version_edit_label_android: "Android",
      admin_version_edit_label_android_debug: "Android 디버그",
      admin_version_edit_select_android_true: "TRUE",
      admin_version_edit_select_android_false: "FALSE",
      admin_version_edit_button_cancel: "취소",
      admin_version_edit_button_delete: "삭제",
      admin_version_edit_button_ok: "확인",
      admin_version_app_down_version_list_title: "앱 버전 목록",
      admin_version_app_down_version_list_label_os: "OS",
      admin_version_app_down_version_list_label_type: "타입",
      admin_version_app_down_version_list_label_version: "버전",
      admin_version_app_down_version_list_label_link: "링크",
      admin_version_app_down_version_list_label_created_at: "생성 시간",
      admin_version_app_down_version_list_button_delete: "삭제",
      admin_version_app_down_version_list_button_add: "추가",
      admin_version_app_down_version_list_placeholiser_search: "검색",
      admin_version_app_down_version_list_label_no: "번호",
      admin_version_app_down_version_list_guide_empty: "목록이 비어 있습니다.",
      admin_version_app_down_version_add_title: "앱 다운로드 버전 추가",
      admin_version_app_down_version_add_validation_os: "OS를 선택해주세요.",
      admin_version_app_down_version_add_validation_type: "타입을 선택해주세요.",
      admin_version_app_down_version_add_validation_version: "버전을 선택해주세요.",
      admin_version_app_down_version_add_validation_link: "링크를 선택해주세요.",
      admin_version_app_down_version_add_label_os: "OS",
      admin_version_app_down_version_add_placeholder_os: "OS",
      admin_version_app_down_version_add_select_ios: "iOS",
      admin_version_app_down_version_add_select_aos: "AOS",
      admin_version_app_down_version_add_label_type: "타입",
      admin_version_app_down_version_add_placeholder_type: "타입",
      admin_version_app_down_version_add_select_guest: "게스트",
      admin_version_app_down_version_add_select_staff: "스태프",
      admin_version_app_down_version_add_select_valet: "발렛",
      admin_version_app_down_version_add_select_parking_staff: "주차 스태프",
      admin_version_app_down_version_add_label_version: "버전",
      admin_version_app_down_version_add_label_link: "링크",
      admin_version_app_down_version_add_button_go_to_list: "목록으로 가기",
      admin_version_app_down_version_add_button_add: "추가",
      admin_version_guest_app_version_list_title: "게스트 앱 버전 목록",
      admin_version_guest_app_version_list_placeholiser_search: "검색",
      admin_version_guest_app_version_list_label_no: "번호",
      admin_version_guest_app_version_list_label_os: "OS",
      admin_version_guest_app_version_list_label_version: "버전명",
      admin_version_guest_app_version_list_label_link: "링크",
      admin_version_guest_app_version_list_label_created_at: "생성 시간",
      admin_version_guest_app_version_list_button_delete: "삭제",
      admin_version_guest_app_version_list_button_add: "추가",
      admin_version_guest_app_version_list_guide_empty: "목록이 비어 있습니다.",
      admin_version_guest_app_version_add_title: "게스트 버전 추가",
      admin_version_guest_app_version_add_validation_os: "OS를 선택해주세요.",
      admin_version_guest_app_version_add_validation_version: "버전을 선택해주세요.",
      admin_version_guest_app_version_add_validation_link: "링크를 선택해주세요.",
      admin_version_guest_app_version_add_label_os: "OS",
      admin_version_guest_app_version_add_placeholder_os: "OS",
      admin_version_guest_app_version_add_select_ios: "iOS",
      admin_version_guest_app_version_add_select_aos: "AOS",
      admin_version_guest_app_version_add_label_version: "버전",
      admin_version_guest_app_version_add_label_link: "링크",
      admin_version_guest_app_version_add_button_go_to_list: "목록으로 가기",
      admin_version_guest_app_version_add_button_add: "추가",
      admin_version_staff_app_version_list_title: "스태프 앱 버전 목록",
      admin_version_staff_app_version_add_validation_os: "OS를 선택해주세요.",
      admin_version_staff_app_version_add_validation_version: "버전을 선택해주세요.",
      admin_version_staff_app_version_add_validation_link: "링크를 선택해주세요.",
      admin_version_staff_app_version_list_placeholiser_search: "검색",
      admin_version_staff_app_version_list_label_no: "번호",
      admin_version_staff_app_version_list_label_os: "OS",
      admin_version_staff_app_version_list_label_version: "버전",
      admin_version_staff_app_version_list_label_link: "링크",
      admin_version_staff_app_version_list_label_created_at: "생성 시간",
      admin_version_staff_app_version_list_button_delete: "삭제",
      admin_version_staff_app_version_list_button_add: "추가",
      admin_version_staff_app_version_list_guide_empty: "목록이 비어 있습니다.",
      admin_version_staff_app_version_add_title: "스태프 버전 추가",
      admin_version_staff_app_version_add_label_os: "OS",
      admin_version_staff_app_version_add_placeholder_os: "OS",
      admin_version_staff_app_version_add_select_ios: "iOS",
      admin_version_staff_app_version_add_select_aos: "AOS",
      admin_version_staff_app_version_add_label_version: "버전",
      admin_version_staff_app_version_add_label_link: "링크",
      admin_version_staff_app_version_add_button_go_to_list: "목록으로 가기",
      admin_version_staff_app_version_add_button_add: "추가",
      admin_version_parking_staff_app_version_list_title: "주차 스태프 앱 다운로드 목록",
      admin_version_parking_staff_app_version_add_validation_os: "OS를 선택해주세요.",
      admin_version_parking_staff_app_version_add_validation_version: "버전을 입력해주세요.",
      admin_version_parking_staff_app_version_add_validation_link: "링크를 입력해주세요.",
      admin_version_parking_staff_app_version_list_placeholiser_search: "검색",
      admin_version_parking_staff_app_version_list_label_no: "번호",
      admin_version_parking_staff_app_version_list_label_os: "OS",
      admin_version_parking_staff_app_version_list_label_version: "버전",
      admin_version_parking_staff_app_version_list_label_link: "링크",
      admin_version_parking_staff_app_version_list_label_created_at: "생성 시간",
      admin_version_parking_staff_app_version_list_button_delete: "삭제",
      admin_version_parking_staff_app_version_list_button_add: "추가",
      admin_version_parking_staff_app_version_add_title: "주차 스태프 다운로드 추가",
      admin_version_parking_staff_app_version_add_label_os: "OS",
      admin_version_parking_staff_app_version_add_placeholder_os: "OS",
      admin_version_parking_staff_app_version_add_select_ios: "iOS",
      admin_version_parking_staff_app_version_add_select_aos: "AOS",
      admin_version_parking_staff_app_version_add_label_version: "버전",
      admin_version_parking_staff_app_version_add_label_link: "링크",
      admin_version_parking_staff_app_version_add_button_go_to_list: "목록으로 가기",
      admin_version_parking_staff_app_version_add_button_add: "추가",
      admin_application_translation_list_title: "앱 번역 목록",
      admin_application_translation_list_select_application_type: "앱 타입 ( 모두 )",
      admin_application_translation_list_select_application_type_guest: "게스트",
      admin_application_translation_list_select_application_type_staff: "스태프",
      admin_application_translation_list_select_application_type_parking_staff: "주차 스태프",
      admin_application_translation_list_select_os_type: "OS 타입 ( 모두 )",
      admin_application_translation_list_select_os_type_aos: "AOS",
      admin_application_translation_list_select_os_type_ios: "iOS",
      admin_application_translation_list_placehoder_application_version: "앱 버전",
      admin_application_translation_list_placehoder_file_name: "파일명",
      admin_application_translation_list_label_application_type: "앱 타입",
      admin_application_translation_list_label_os: "OS",
      admin_application_translation_list_label_application_version: "앱 버전",
      admin_application_translation_list_label_version: "버전",
      admin_application_translation_list_label_file_name: "파일명",
      admin_application_translation_list_label_date: "날짜",
      admin_application_translation_list_button_delete: "삭제",
      admin_application_translation_list_button_add: "추가",
      admin_application_translation_list_guide_empty: "목록이 비어 있습니다.",
      admin_application_translation_add_title: "앱 번역 추가",
      admin_application_translation_add_validation_application_type: "앱 타입을 선택해주세요.",
      admin_application_translation_add_validation_os_type: "OS Type을 선택해주세요.",
      admin_application_translation_add_validation_application_version: "앱 버전을 입력해주세요.",
      admin_application_translation_add_validation_version: "버전을 입력해주세요.",
      admin_application_translation_add_validation_translation_file: "번역 파일을 입력해주세요.",
      admin_application_translation_add_label_admin_application_type: "앱 타입",
      admin_application_translation_add_placeholder_admin_application_type: "앱 타입",
      admin_application_translation_add_select_admin_application_type_guest: "게스트",
      admin_application_translation_add_select_admin_application_type_staff: "스태프",
      admin_application_translation_add_select_admin_application_type_parking_staff: "주차 스태프",
      admin_application_translation_add_label_os_type: "OS 타입",
      admin_application_translation_add_placeholder_os_type: "OS 타입",
      admin_application_translation_add_select_os_type_aos: "AOS",
      admin_application_translation_add_select_os_type_ios: "iOS",
      admin_application_translation_add_label_admin_application_version: "앱 버전",
      admin_application_translation_add_label_version: "버전",
      admin_application_translation_add_label_translation_file: "번역 파일",
      admin_application_translation_add_button_upload: "업로드",
      admin_application_translation_add_placeholder_upload: "ex) Translation.json",
      admin_application_translation_add_button_go_to_list: "목록으로 가기",
      admin_application_translation_add_button_add: "추가",
      admin_application_translation_detail_title: "앱 번역 상세",
      admin_application_translation_detail_label_admin_application_type: "앱 타입",
      admin_application_translation_detail_placeholder_select_box_choose: "선택",
      admin_application_translation_detail_select_admin_application_type_guest: "게스트",
      admin_application_translation_detail_select_admin_application_type_staff: "스태프",
      admin_application_translation_detail_label_os_type: "OS 타입",
      admin_application_translation_detail_select_os_type_aos: "AOS",
      admin_application_translation_detail_select_os_type_ios: "iOS",
      admin_application_translation_detail_label_admin_application_version: "앱 버전",
      admin_application_translation_detail_label_version: "버전",
      admin_application_translation_detail_label_file_name: "파일명",
      admin_application_translation_detail_label_date: "날짜",
      admin_application_translation_detail_button_edit: "수정",
      admin_application_translation_detail_button_go_to_list: "목록으로 가기",
      admin_application_translation_detail_button_download: "다운로드",
      admin_application_translation_edit_title: "앱 번역 상세",
      admin_application_translation_edit_button_download: "다운로드",
      admin_application_translation_edit_validation_application_type: "앱 타입을 선택해주세요.",
      admin_application_translation_edit_validation_os_type: "OS Type을 선택해주세요.",
      admin_application_translation_edit_validation_application_version: "앱 버전을 입력해주세요.",
      admin_application_translation_edit_validation_version: "버전을 입력해주세요.",
      admin_application_translation_edit_validation_translation_file: "번역 파일을 입력해주세요.",
      admin_application_translation_edit_label_admin_application_type: "앱 타입",
      admin_application_translation_edit_placeholder_select_box_choose: "선택",
      admin_application_translation_edit_select_admin_application_type_guest: "게스트",
      admin_application_translation_edit_select_admin_application_type_staff: "스태프",
      admin_application_translation_edit_label_os_type: "OS 타입",
      admin_application_translation_edit_select_os_type_aos: "AOS",
      admin_application_translation_edit_select_os_type_ios: "iOS",
      admin_application_translation_edit_label_admin_application_version: "앱 버전",
      admin_application_translation_edit_label_version: "버전",
      admin_application_translation_edit_label_file_name: "파일명",
      admin_application_translation_edit_label_date: "날짜",
      admin_application_translation_edit_button_upload: "업로드",
      admin_application_translation_edit_button_ok: "확인",
      admin_application_translation_edit_button_cancel: "취소",
      admin_booking_email_detail_label_format: "이메일 이미지 형식",
      admin_booking_email_detail_label_image: "이메일 이미지",
      admin_booking_email_detail_button_upload: "업로드",
      admin_booking_email_detail_placeholder_upload: "ex) MyCONECT.png",
      admin_booking_email_detail_button_edit: "수정",
      admin_email_form_change_password_edit_title: "비밀번호 변경",
      admin_email_form_change_password_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_change_password_edit_select_kr: "한국어",
      admin_email_form_change_password_edit_select_en: "영어",
      admin_email_form_change_password_edit_select_cn: "중국어",
      admin_email_form_change_password_edit_select_jp: "일본어",
      admin_email_form_change_password_edit_select_ms: "말레이어",
      admin_email_form_change_password_edit_button_remove: "제거",
      admin_email_form_change_password_edit_button_append: "추가",
      admin_email_form_change_password_edit_button_save: "저장",
      admin_email_form_change_password_edit_toolbar_bold: "굵게",
      admin_email_form_change_password_edit_toolbar_italic: "이탤릭체",
      admin_email_form_change_password_edit_toolbar_underline: "밑줄",
      admin_email_form_change_password_edit_toolbar_strikethrough: "취소선",
      admin_email_form_change_password_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_change_password_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_change_password_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_change_password_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_change_password_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_change_password_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_change_password_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_change_password_edit_toolbar_table: "테이블",
      admin_email_form_change_password_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_change_password_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_change_password_edit_toolbar_paragraph: "단락",
      admin_email_form_change_password_edit_toolbar_line_height: "줄 높이",
      admin_email_form_change_password_edit_toolbar_picture: "사진",
      admin_email_form_change_password_edit_toolbar_code_view: "코드 보기",
      admin_email_form_change_password_edit_toolbar_full_screen: "전체화면",
      admin_email_form_change_password_edit_toolbar_help: "도움말",
      admin_email_form_change_password_edit_toolbar_tip: "팁",
      admin_email_form_change_password_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_forgot_id_edit_title: "ID 찾기",
      admin_email_form_forgot_id_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_forgot_id_edit_select_kr: "한국어",
      admin_email_form_forgot_id_edit_select_en: "영어",
      admin_email_form_forgot_id_edit_select_cn: "중국어",
      admin_email_form_forgot_id_edit_select_jp: "일본어",
      admin_email_form_forgot_id_edit_select_ms: "말레이어",
      admin_email_form_forgot_id_edit_button_remove: "제거",
      admin_email_form_forgot_id_edit_button_append: "추가",
      admin_email_form_forgot_id_edit_button_save: "저장",
      admin_email_form_forgot_id_edit_toolbar_bold: "굵게",
      admin_email_form_forgot_id_edit_toolbar_italic: "이탤릭체",
      admin_email_form_forgot_id_edit_toolbar_underline: "밑줄",
      admin_email_form_forgot_id_edit_toolbar_strikethrough: "취소선",
      admin_email_form_forgot_id_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_forgot_id_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_forgot_id_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_forgot_id_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_forgot_id_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_forgot_id_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_forgot_id_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_forgot_id_edit_toolbar_table: "테이블",
      admin_email_form_forgot_id_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_forgot_id_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_forgot_id_edit_toolbar_paragraph: "단락",
      admin_email_form_forgot_id_edit_toolbar_line_height: "줄 높이",
      admin_email_form_forgot_id_edit_toolbar_picture: "사진",
      admin_email_form_forgot_id_edit_toolbar_code_view: "코드 보기",
      admin_email_form_forgot_id_edit_toolbar_full_screen: "전체화면",
      admin_email_form_forgot_id_edit_toolbar_help: "도움말",
      admin_email_form_forgot_id_edit_toolbar_tip: "팁",
      admin_email_form_forgot_id_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_forgot_id_detail_title: "ID 찾기 문자",
      admin_email_form_forgot_id_detail_label_user_info: "고객 정보",
      admin_email_form_forgot_id_detail_label_example_user_info: "{{userInfo}}",
      admin_email_form_forgot_password_edit_title: "비밀번호 찾기",
      admin_email_form_forgot_password_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_forgot_password_edit_select_kr: "한국어",
      admin_email_form_forgot_password_edit_select_en: "영어",
      admin_email_form_forgot_password_edit_select_cn: "중국어",
      admin_email_form_forgot_password_edit_select_jp: "일본어",
      admin_email_form_forgot_password_edit_select_ms: "말레이어",
      admin_email_form_forgot_password_edit_button_remove: "제거",
      admin_email_form_forgot_password_edit_button_append: "추가",
      admin_email_form_forgot_password_edit_button_save: "저장",
      admin_email_form_forgot_password_edit_toolbar_bold: "굵게",
      admin_email_form_forgot_password_edit_toolbar_italic: "이탤릭체",
      admin_email_form_forgot_password_edit_toolbar_underline: "밑줄",
      admin_email_form_forgot_password_edit_toolbar_strikethrough: "취소선",
      admin_email_form_forgot_password_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_forgot_password_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_forgot_password_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_forgot_password_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_forgot_password_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_forgot_password_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_forgot_password_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_forgot_password_edit_toolbar_table: "테이블",
      admin_email_form_forgot_password_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_forgot_password_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_forgot_password_edit_toolbar_paragraph: "단락",
      admin_email_form_forgot_password_edit_toolbar_line_height: "줄 높이",
      admin_email_form_forgot_password_edit_toolbar_picture: "사진",
      admin_email_form_forgot_password_edit_toolbar_code_view: "코드 보기",
      admin_email_form_forgot_password_edit_toolbar_full_screen: "전체화면",
      admin_email_form_forgot_password_edit_toolbar_help: "도움말",
      admin_email_form_forgot_password_edit_toolbar_tip: "팁",
      admin_email_form_forgot_password_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_forgot_password_detail_title: "비밀번호 찾기 문자",
      admin_email_form_forgot_password_detail_label_email: "이메일",
      admin_email_form_forgot_password_detail_label_example_email: "{{email}}",
      admin_email_form_forgot_password_detail_label_password: "비밀번호",
      admin_email_form_forgot_password_detail_label_example_password: "{{password}}",
      admin_email_form_create_confirm_edit_title: "이메일 생성 확인",
      admin_email_form_create_confirm_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_create_confirm_edit_select_kr: "한국어",
      admin_email_form_create_confirm_edit_select_en: "영어",
      admin_email_form_create_confirm_edit_select_cn: "중국어",
      admin_email_form_create_confirm_edit_select_jp: "일본어",
      admin_email_form_create_confirm_edit_select_ms: "말레이어",
      admin_email_form_create_confirm_edit_button_remove: "제거",
      admin_email_form_create_confirm_edit_button_append: "추가",
      admin_email_form_create_confirm_edit_button_save: "저장",
      admin_email_form_create_confirm_edit_toolbar_bold: "굵게",
      admin_email_form_create_confirm_edit_toolbar_italic: "이탤릭체",
      admin_email_form_create_confirm_edit_toolbar_underline: "밑줄",
      admin_email_form_create_confirm_edit_toolbar_strikethrough: "취소선",
      admin_email_form_create_confirm_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_create_confirm_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_create_confirm_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_create_confirm_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_create_confirm_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_create_confirm_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_create_confirm_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_create_confirm_edit_toolbar_table: "테이블",
      admin_email_form_create_confirm_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_create_confirm_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_create_confirm_edit_toolbar_paragraph: "단락",
      admin_email_form_create_confirm_edit_toolbar_line_height: "줄 높이",
      admin_email_form_create_confirm_edit_toolbar_picture: "사진",
      admin_email_form_create_confirm_edit_toolbar_code_view: "코드 보기",
      admin_email_form_create_confirm_edit_toolbar_full_screen: "전체화면",
      admin_email_form_create_confirm_edit_toolbar_help: "도움말",
      admin_email_form_create_confirm_edit_toolbar_tip: "팁",
      admin_email_form_create_confirm_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_create_confirm_detail_title: "이메일 생성 확인 문자",
      admin_email_form_create_confirm_detail_label_url: "URL",
      admin_email_form_create_confirm_detail_example_url: "{{url}}",
      admin_email_form_create_confirm_failure_edit_title: "이메일 확인 실패",
      admin_email_form_create_confirm_failure_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_create_confirm_failure_edit_select_kr: "한국어",
      admin_email_form_create_confirm_failure_edit_select_en: "영어",
      admin_email_form_create_confirm_failure_edit_select_cn: "중국어",
      admin_email_form_create_confirm_failure_edit_select_jp: "일본어",
      admin_email_form_create_confirm_failure_edit_select_ms: "말레이어",
      admin_email_form_create_confirm_failure_edit_button_remove: "제거",
      admin_email_form_create_confirm_failure_edit_button_append: "추가",
      admin_email_form_create_confirm_failure_edit_button_save: "저장",
      admin_email_form_create_confirm_failure_edit_toolbar_bold: "굵게",
      admin_email_form_create_confirm_failure_edit_toolbar_italic: "이탤릭체",
      admin_email_form_create_confirm_failure_edit_toolbar_underline: "밑줄",
      admin_email_form_create_confirm_failure_edit_toolbar_strikethrough: "취소선",
      admin_email_form_create_confirm_failure_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_create_confirm_failure_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_create_confirm_failure_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_create_confirm_failure_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_create_confirm_failure_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_create_confirm_failure_edit_toolbar_table: "테이블",
      admin_email_form_create_confirm_failure_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_create_confirm_failure_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_create_confirm_failure_edit_toolbar_paragraph: "단락",
      admin_email_form_create_confirm_failure_edit_toolbar_line_height: "줄 높이",
      admin_email_form_create_confirm_failure_edit_toolbar_picture: "사진",
      admin_email_form_create_confirm_failure_edit_toolbar_code_view: "코드 보기",
      admin_email_form_create_confirm_failure_edit_toolbar_full_screen: "전체화면",
      admin_email_form_create_confirm_failure_edit_toolbar_help: "도움말",
      admin_email_form_create_confirm_failure_edit_toolbar_tip: "팁",
      admin_email_form_create_confirm_failure_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_create_confirm_failure_detail_title: "이메일 생성 실패 문자",
      admin_email_form_create_confirm_failure_detail_label_reason: "사유",
      admin_email_form_create_confirm_failure_detail_label_example_reason: "{{reason}}",
      admin_email_form_create_confirm_success_edit_title: "이메일 생성 성공",
      admin_email_form_create_confirm_success_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_create_confirm_success_edit_select_kr: "한국어",
      admin_email_form_create_confirm_success_edit_select_en: "영어",
      admin_email_form_create_confirm_success_edit_select_cn: "중국어",
      admin_email_form_create_confirm_success_edit_select_jp: "일본어",
      admin_email_form_create_confirm_success_edit_select_ms: "말레이어",
      admin_email_form_create_confirm_success_edit_button_remove: "제거",
      admin_email_form_create_confirm_success_edit_button_append: "추가",
      admin_email_form_create_confirm_success_edit_button_save: "저장",
      admin_email_form_create_confirm_success_edit_toolbar_bold: "굵게",
      admin_email_form_create_confirm_success_edit_toolbar_italic: "이탤릭체",
      admin_email_form_create_confirm_success_edit_toolbar_underline: "밑줄",
      admin_email_form_create_confirm_success_edit_toolbar_strikethrough: "취소선",
      admin_email_form_create_confirm_success_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_create_confirm_success_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_create_confirm_success_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_create_confirm_success_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_create_confirm_success_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_create_confirm_success_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_create_confirm_success_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_create_confirm_success_edit_toolbar_table: "테이블",
      admin_email_form_create_confirm_success_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_create_confirm_success_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_create_confirm_success_edit_toolbar_paragraph: "단락",
      admin_email_form_create_confirm_success_edit_toolbar_line_height: "줄 높이",
      admin_email_form_create_confirm_success_edit_toolbar_picture: "사진",
      admin_email_form_create_confirm_success_edit_toolbar_code_view: "코드 보기",
      admin_email_form_create_confirm_success_edit_toolbar_full_screen: "전체화면",
      admin_email_form_create_confirm_success_edit_toolbar_help: "도움말",
      admin_email_form_create_confirm_success_edit_toolbar_tip: "팁",
      admin_email_form_create_confirm_success_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_update_profile_edit_title: "프로필 업데이트",
      admin_email_form_update_profile_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_update_profile_edit_select_kr: "한국어",
      admin_email_form_update_profile_edit_select_en: "영어",
      admin_email_form_update_profile_edit_select_cn: "중국어",
      admin_email_form_update_profile_edit_select_jp: "일본어",
      admin_email_form_update_profile_edit_select_ms: "말레이어",
      admin_email_form_update_profile_edit_button_remove: "제거",
      admin_email_form_update_profile_edit_button_append: "추가",
      admin_email_form_update_profile_edit_button_save: "저장",
      admin_email_form_update_profile_edit_toolbar_bold: "굵게",
      admin_email_form_update_profile_edit_toolbar_italic: "이탤릭체",
      admin_email_form_update_profile_edit_toolbar_underline: "밑줄",
      admin_email_form_update_profile_edit_toolbar_strikethrough: "취소선",
      admin_email_form_update_profile_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_update_profile_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_update_profile_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_update_profile_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_update_profile_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_update_profile_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_update_profile_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_update_profile_edit_toolbar_table: "테이블",
      admin_email_form_update_profile_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_update_profile_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_update_profile_edit_toolbar_paragraph: "단락",
      admin_email_form_update_profile_edit_toolbar_line_height: "줄 높이",
      admin_email_form_update_profile_edit_toolbar_picture: "사진",
      admin_email_form_update_profile_edit_toolbar_code_view: "코드 보기",
      admin_email_form_update_profile_edit_toolbar_full_screen: "전체화면",
      admin_email_form_update_profile_edit_toolbar_help: "도움말",
      admin_email_form_update_profile_edit_toolbar_tip: "팁",
      admin_email_form_update_profile_edit_placeholder_content: "내용을 입력하세요",
      admin_email_form_withdraw_edit_title: "회원탈퇴",
      admin_email_form_withdraw_edit_validation_content: "내용을 입력해주세요.",
      admin_email_form_withdraw_edit_select_kr: "한국어",
      admin_email_form_withdraw_edit_select_en: "영어",
      admin_email_form_withdraw_edit_select_cn: "중국어",
      admin_email_form_withdraw_edit_select_jp: "일본어",
      admin_email_form_withdraw_edit_select_ms: "말레이어",
      admin_email_form_withdraw_edit_button_remove: "제거",
      admin_email_form_withdraw_edit_button_append: "추가",
      admin_email_form_withdraw_edit_button_save: "저장",
      admin_email_form_withdraw_edit_toolbar_bold: "굵게",
      admin_email_form_withdraw_edit_toolbar_italic: "이탤릭체",
      admin_email_form_withdraw_edit_toolbar_underline: "밑줄",
      admin_email_form_withdraw_edit_toolbar_strikethrough: "취소선",
      admin_email_form_withdraw_edit_toolbar_remove_font_style: "글씨체 제거",
      admin_email_form_withdraw_edit_toolbar_font_family: "글꼴 모음",
      admin_email_form_withdraw_edit_toolbar_font_size: "글꼴 크기",
      admin_email_form_withdraw_edit_toolbar_text_color: "텍스트 색상",
      admin_email_form_withdraw_edit_toolbar_text_more_color: "색상 더 보기",
      admin_email_form_withdraw_edit_toolbar_recent_color: "최근 사용 색상",
      admin_email_form_withdraw_edit_toolbar_recent_more_color: "색상 더 보기",
      admin_email_form_withdraw_edit_toolbar_table: "테이블",
      admin_email_form_withdraw_edit_toolbar_unordered_list: "미 주문 목록",
      admin_email_form_withdraw_edit_toolbar_ordered_list: "주문 목록",
      admin_email_form_withdraw_edit_toolbar_paragraph: "단락",
      admin_email_form_withdraw_edit_toolbar_line_height: "줄 높이",
      admin_email_form_withdraw_edit_toolbar_picture: "사진",
      admin_email_form_withdraw_edit_toolbar_code_view: "코드 보기",
      admin_email_form_withdraw_edit_toolbar_full_screen: "전체화면",
      admin_email_form_withdraw_edit_toolbar_help: "도움말",
      admin_email_form_withdraw_edit_toolbar_tip: "팁",
      admin_email_form_withdraw_edit_placeholder_content: "내용을 입력하세요",
      admin_endpoint_list_title: "앱 엔드포인트",
      admin_endpoint_list_placeholiser_search: "검색",
      admin_endpoint_list_label_package_name: "패키지명",
      admin_endpoint_list_label_os: "OS",
      admin_endpoint_list_label_version: "버전",
      admin_endpoint_list_label_url: "URL",
      admin_endpoint_list_label_created_at: "생성시간",
      admin_endpoint_list_guide_empty: "목록이 비어 있습니다.",
      admin_endpoint_list_button_delete: "삭제",
      admin_endpoint_list_button_add: "추가",
      admin_endpoint_add_title: "앱 엔드포인트 추가",
      admin_endpoint_add_validation_package_name: "패키지명을 선택해주세요.",
      admin_endpoint_add_validation_os: "OS을 선택해주세요.",
      admin_endpoint_add_validation_version: "버전을 입력해주세요.",
      admin_endpoint_add_validation_url: "url을 선택해주세요.",
      admin_endpoint_add_label_package_name: "패키지명",
      admin_endpoint_add_placeholder_choose: "선택",
      admin_endpoint_add_select_os_staff: "스태프",
      admin_endpoint_add_select_os_guest: "게스트",
      admin_endpoint_add_select_os_staff_parking: "주차 스태프",
      admin_endpoint_add_label_os: "OS",
      admin_endpoint_add_select_os_ios: "iOS",
      admin_endpoint_add_select_os_aos: "AOS",
      admin_endpoint_add_label_version: "버전",
      admin_endpoint_add_label_url: "URL",
      admin_endpoint_add_button_go_to_list: "목록으로 가기",
      admin_endpoint_add_button_add: "추가",
      admin_endpoint_detail_title: "앱 엔드포인트 정보",
      admin_endpoint_detail_label_package_name: "패키지명",
      admin_endpoint_detail_placeholder_choose: "선택",
      admin_endpoint_detail_select_os_staff: "스태프",
      admin_endpoint_detail_select_os_guest: "게스트",
      admin_endpoint_detail_select_os_staff_parking: "주차 스태프",
      admin_endpoint_detail_label_os: "OS",
      admin_endpoint_detail_select_os_ios: "iOS",
      admin_endpoint_detail_select_os_aos: "AOS",
      admin_endpoint_detail_label_version: "버전",
      admin_endpoint_detail_label_url: "URL",
      admin_endpoint_detail_label_create_at: "생성 시간",
      admin_endpoint_detail_button_go_to_list: "목록으로 가기",
      admin_endpoint_detail_button_delete: "삭제",
      admin_endpoint_detail_button_edit: "수정",
      admin_endpoint_edit_title: "앱 엔드포인트 정보",
      admin_endpoint_edit_validation_package_name: "패키지명을 선택해주세요.",
      admin_endpoint_edit_validation_os: "OS을 선택해주세요.",
      admin_endpoint_edit_validation_version: "버전을 입력해주세요.",
      admin_endpoint_edit_validation_url: "url을 선택해주세요.",
      admin_endpoint_edit_label_package_name: "패키지명",
      admin_endpoint_edit_placeholder_choose: "선택",
      admin_endpoint_edit_select_os_staff: "스태프",
      admin_endpoint_edit_select_os_guest: "게스트",
      admin_endpoint_edit_select_os_staff_parking: "주차 스태프",
      admin_endpoint_edit_label_os: "OS",
      admin_endpoint_edit_select_os_ios: "iOS",
      admin_endpoint_edit_select_os_aos: "AOS",
      admin_endpoint_edit_label_version: "버전",
      admin_endpoint_edit_label_url: "URL",
      admin_endpoint_edit_label_create_at: "생성 시간",
      admin_endpoint_edit_button_cancel: "취소",
      admin_endpoint_edit_button_delete: "삭제",
      admin_endpoint_edit_button_ok: "확인",
      admin_terms_list_title: "조건 목록",
      admin_terms_list_placeholder_search: "검색",
      admin_terms_list_label_language_code: "언어 코드",
      admin_terms_list_label_file_name: "파일명",
      admin_terms_list_label_required: "필수 여부",
      admin_terms_list_label_title: "호칭",
      admin_terms_list_label_terms_group_id: "조건 그룹 아이디",
      admin_terms_list_label_created_at: "생성 시간",
      admin_terms_list_guide_empty: "목록이 비어 있습니다.",
      admin_terms_list_button_delete: "삭제",
      admin_terms_list_button_add: "추가",
      admin_terms_add_title: "조건 추가",
      admin_terms_add_validation_title: "호칭을 입력해주세요.",
      admin_terms_add_validation_terms_group_id: "조건 그룹 아이디를 선택해주세요.",
      admin_terms_add_validation_language_code: "언어 코드를 입력해주세요.",
      admin_terms_add_validation_required: "필수 여부를 선택해주세요.",
      admin_terms_add_validation_description: "설명을 입력해주세요.",
      admin_terms_add_validation_link_text: "링크 텍스트를 입력해주세요.",
      admin_terms_add_validation_file: "파일을 업로드해주세요.",
      admin_terms_add_label_title: "호청",
      admin_terms_add_label_terms_group_id: "조건 그룹 아이디",
      admin_terms_add_label_choose: "선택",
      admin_terms_add_label_language_code: "언어 코드",
      admin_terms_add_label_required: "필수 여부",
      admin_terms_add_button_yes: "예",
      admin_terms_add_button_no: "아니오",
      admin_terms_add_label_description: "설명",
      admin_terms_add_label_link_text: "링크 텍스트",
      admin_terms_add_label_file_name: "파일명",
      admin_terms_add_label_file_url: "파일 URL",
      admin_terms_add_button_upload: "업로드",
      admin_terms_add_button_go_to_list: "목록으로 가기",
      admin_terms_add_button_add: "추가",
      admin_terms_detail_title: "조건 상세",
      admin_terms_detail_label_title: "호칭",
      admin_terms_detail_label_language_code: "언어 코드",
      admin_terms_detail_label_description: "설명",
      admin_terms_detail_label_link_text: "링크 텍스트",
      admin_terms_detail_label_required: "필수 여부",
      admin_terms_detail_button_yes: "예",
      admin_terms_detail_button_no: "아니오",
      admin_terms_detail_label_terms_group_id: "조건 그룹 아이디",
      admin_terms_detail_label_choose: "선택",
      admin_terms_detail_label_file_name: "파일명",
      admin_terms_detail_label_file_url: "파일 URL",
      admin_terms_detail_button_upload: "업로드",
      admin_terms_detail_label_created_at: "생성 시간",
      admin_terms_detail_label_updated_at: "업데이트 시간",
      admin_terms_detail_button_go_to_list: "목록으로 가기",
      admin_terms_detail_button_edit: "수정",
      admin_terms_edit_title: "조건 상세",
      admin_terms_edit_validation_title: "호칭을 입력해주세요.",
      admin_terms_edit_validation_terms_group_id: "조건 그룹 아이디를 선택해주세요.",
      admin_terms_edit_validation_language_code: "언어 코드를 입력해주세요.",
      admin_terms_edit_validation_required: "필수 여부를 선택해주세요.",
      admin_terms_edit_validation_description: "설명을 입력해주세요.",
      admin_terms_edit_validation_link_text: "링크 텍스트를 입력해주세요.",
      admin_terms_edit_validation_file: "파일을 업로드해주세요.",
      admin_terms_edit_label_title: "호칭",
      admin_terms_edit_label_language_code: "언어 코드",
      admin_terms_edit_label_description: "설명",
      admin_terms_edit_label_link_text: "링크 텍스트",
      admin_terms_edit_label_required: "필수 여부",
      admin_terms_edit_button_yes: "예",
      admin_terms_edit_button_no: "아니오",
      admin_terms_edit_label_terms_group_id: "조건 그룹 아이디",
      admin_terms_edit_label_choose: "선택",
      admin_terms_edit_label_file_name: "파일명",
      admin_terms_edit_label_file_url: "파일 URL",
      admin_terms_edit_button_upload: "업로드",
      admin_terms_edit_label_created_at: "생성 시간",
      admin_terms_edit_label_updated_at: "업데이트 시간",
      admin_terms_edit_button_cancel: "취소",
      admin_terms_edit_button_ok: "확인",
      admin_terms_group_list_title: "조건 그룹 목록",
      admin_terms_group_list_placeholder_search: "검색",
      admin_terms_group_list_label_title: "호칭",
      admin_terms_group_list_label_country_code: "국가 코드",
      admin_terms_group_list_label_created_at: "생성 시간",
      admin_terms_group_list_guide_empty: "목록이 비어 있습니다.",
      admin_terms_group_list_button_delete: "삭제",
      admin_terms_group_list_button_add: "추가",
      admin_terms_group_add_title: "조건 그룹 추가",
      admin_terms_group_add_validation_title: "호칭을 입력해주세요.",
      admin_terms_group_add_validation_country_code: "국가 코드를 입력해주세요.",
      admin_terms_group_add_label_title: "호칭",
      admin_terms_group_add_label_country_code: "국가 코드",
      admin_terms_group_add_button_go_to_list: "목록으로 가기",
      admin_terms_group_add_button_add: "추가",
      admin_terms_group_detail_title: "조건 그룹 상세",
      admin_terms_group_detail_label_title: "호칭",
      admin_terms_group_detail_label_country_code: "국가 코드",
      admin_terms_group_detail_label_created_at: "생성 시간",
      admin_terms_group_detail_label_updated_at: "업데이트 시간",
      admin_terms_group_detail_button_go_to_list: "목록으로 가기",
      admin_terms_group_detail_button_edit: "수정",
      admin_terms_group_edit_title: "조건 그룹 상세",
      admin_terms_group_edit_validation_title: "호칭을 입력해주세요.",
      admin_terms_group_edit_validation_country_code: "국가 코드를 입력해주세요.",
      admin_terms_group_edit_label_title: "호칭",
      admin_terms_group_edit_label_country_code: "국가 코드",
      admin_terms_group_edit_label_created_at: "생성 시간",
      admin_terms_group_edit_label_updated_at: "업데이트 시간",
      admin_terms_group_edit_button_cancel: "취소",
      admin_terms_group_edit_button_ok: "확인",
      admin_agreement_list_title: "동의 내역 목록",
      admin_agreement_list_placeholder_email: "이메일을 입력하세요.",
      admin_agreement_list_placeholder_search: "검색",
      admin_agreement_list_label_no: "번호",
      admin_agreement_list_label_user_id: "사용자 아이디",
      admin_agreement_list_label_terms_id: "조건",
      admin_agreement_list_label_agreed: "동의 여부",
      admin_agreement_list_label_email: "이메일",
      admin_agreement_list_label_name: "이름",
      admin_agreement_list_label_file_name: "파일명",
      admin_agreement_list_label_created_at: "생성 시간",
      admin_agreement_detail_title: "동의 내역 상세",
      admin_agreement_detail_label_agreement: "동의",
      admin_agreement_detail_label_agreed: "동의 여부",
      admin_agreement_detail_label_agreement_created_at: "동의 시간",
      admin_agreement_detail_label_user: "사용자",
      admin_agreement_detail_label_user_id: "사용자 아이디",
      admin_agreement_detail_label_email: "이메일",
      admin_agreement_detail_label_name: "이름",
      admin_agreement_detail_label_email_confimed_at: "이메일 확인 시간",
      admin_agreement_detail_label_user_language_code: "사용자 언어 코드",
      admin_agreement_detail_label_user_created_at: "사용자 생성 시간",
      admin_agreement_detail_label_terms: "조건",
      admin_agreement_detail_label_terms_id: "조건 아이디",
      admin_agreement_detail_label_terms_language_code: "조건 언어 코드",
      admin_agreement_detail_label_file_name: "파일명",
      admin_agreement_detail_label_required: "필수 여부",
      admin_agreement_detail_label_title: "호칭",
      admin_agreement_detail_label_description: "설명",
      admin_agreement_detail_label_link_text: "링크 텍스트",
      admin_agreement_detail_label_terms_group_id: "조건 그룹 아이디",
      admin_agreement_detail_label_terms_created_at: "조건 생성 시간",
      admin_agreement_detail_button_go_to_list: "목록으로 가기",
      admin_auth_data_list_title: "위치정보 이용ㆍ제공사실 확인자료",
      admin_auth_data_list_placeholder_select_box_authentication_data: "확인자료",
      admin_auth_data_list_select_hotel_id: "호텔 아이디",
      admin_auth_data_list_select_email: "이메일",
      admin_auth_data_list_label_no: "번호",
      admin_auth_data_list_placeholder_search: "검색",
      admin_auth_data_list_label_user_email: "대상",
      admin_auth_data_list_label_acquisition_path: "취득경로",
      admin_auth_data_list_label_service: "제공 서비스",
      admin_auth_data_list_label_recipient: "제공받는 자",
      admin_auth_data_list_label_request_date: "수집요청일시",
      admin_auth_data_list_label_end_date: "수집종료일시",
      admin_auth_data_list_guide_empty: "목록이 비어 있습니다",
      admin_use_key_log_list_title: "키 사용 로그 목록",
      admin_use_key_log_list_placeholder_select_box_use_key_log: "키 사용 로그",
      admin_use_key_log_list_select_hotel_id: "호텔 ID",
      admin_use_key_log_list_select_email: "이메일",
      admin_use_key_log_list_placeholder_search: "검색",
      admin_use_key_log_list_label_no: "번호",
      admin_use_key_log_list_label_user_email: "사용자 이메일",
      admin_use_key_log_list_label_device: "디바이스",
      admin_use_key_log_list_label_app_version: "앱 버전",
      admin_use_key_log_list_label_hotel_id: "호텔 ID",
      admin_use_key_log_list_label_key_id: "키 ID",
      admin_use_key_log_list_label_date: "날짜",
      admin_use_key_log_list_guide_empty: "목록이 비어 있습니다.",
      admin_use_key_log_detail_title: "키 사용 로그 상세정보",
      admin_use_key_log_detail_label_use_key_log_id: "키 사용 로그 ID",
      admin_use_key_log_detail_label_user_id: "사용자 ID",
      admin_use_key_log_detail_label_user_email: "사용자 이메일",
      admin_use_key_log_detail_label_hotel_key_id: "호텔 키 ID",
      admin_use_key_log_detail_label_hotel_id: "호텔 ID",
      admin_use_key_log_detail_label_device: "디바이스",
      admin_use_key_log_detail_label_app_version: "앱 버전",
      admin_use_key_log_detail_label_log: "로그",
      admin_use_key_log_detail_label_created_at: "생성 시간",
      admin_use_key_log_detail_label_updated_at: "업데이트 시간",
      admin_use_key_log_detail_button_go_to_list: "목록으로 가기",
      admin_amenity_list_title: "편의시설 및 서비스 목록",
      admin_amenity_list_placeholder_search: "검색...",
      admin_amenity_list_label_type: "타입",
      admin_amenity_list_label_group: "그룹",
      admin_amenity_list_label_code: "코드",
      admin_amenity_list_label_name: "이름",
      admin_amenity_list_label_date: "날짜",
      admin_amenity_list_button_delete: "삭제",
      admin_amenity_list_button_add: "추가",
      admin_amenity_list_guide_empty: "목록이 비어 있습니다",
      admin_amenity_add_title: "편의시설 추가",
      admin_amenity_add_validation_type: "* 타입을 선택해주세요.",
      admin_amenity_add_validation_group: "* 그룹을 입력해주세요.",
      admin_amenity_add_validation_code: "* 코드를 입력해주세요.",
      admin_amenity_add_validation_name: "* 이름을 입력해주세요.",
      admin_amenity_add_validation_icon_image: "* 아이콘 이미지를 업로드해주세요.",
      admin_amenity_add_label_type: "타입",
      admin_amenity_add_placeholder_select_box_choose: "선택",
      admin_amenity_add_select_hotel: "HOTEL",
      admin_amenity_add_select_room: "ROOM",
      admin_amenity_add_label_group: "그룹",
      admin_amenity_add_label_code: "코드",
      admin_amenity_add_label_name: "이름",
      admin_amenity_add_label_icon: "아이콘",
      admin_amenity_add_button_upload: "업로드",
      admin_amenity_add_placeholder_upload: "ex) MyCONECT.png",
      admin_amenity_add_button_go_to_list: "목록으로 가기",
      admin_amenity_add_button_add: "추가",
      admin_amenity_detail_title: "편의시설 정보",
      admin_amenity_detail_label_type: "타입",
      admin_amenity_detail_select_hotel: "HOTEL",
      admin_amenity_detail_select_room: "ROOM",
      admin_amenity_detail_label_group: "그룹",
      admin_amenity_detail_label_code: "코드",
      admin_amenity_detail_label_name: "이름",
      admin_amenity_detail_label_icon: "아이콘",
      admin_amenity_detail_button_upload: "업로드",
      admin_amenity_detail_placeholder_upload: "ex) MyCONECT.png",
      admin_amenity_detail_label_update_date: "수정 날짜",
      admin_amenity_detail_button_go_to_list: "목록으로 가기",
      admin_amenity_detail_button_cancel: "취소",
      admin_amenity_detail_button_delete: "삭제",
      admin_amenity_detail_button_edit: "수정",
      admin_amenity_detail_button_ok: "확인",
      admin_amenity_edit_title: "편의시설 정보",
      admin_amenity_edit_validation_type: "타입을 선택해주세요.",
      admin_amenity_edit_validation_group: "그룹을 입력해주세요.",
      admin_amenity_edit_validation_code: "코드를 입력해주세요.",
      admin_amenity_edit_validation_name: "이름을 입력해주세요.",
      admin_amenity_edit_validation_icon_image: "아이콘 이미지를 업로드해주세요.",
      admin_amenity_edit_label_type: "타입",
      admin_amenity_edit_placeholder_select_box_choose: "선택",
      admin_amenity_edit_select_hotel: "HOTEL",
      admin_amenity_edit_select_room: "ROOM",
      admin_amenity_edit_label_group: "그룹",
      admin_amenity_edit_label_code: "코드",
      admin_amenity_edit_label_name: "이름",
      admin_amenity_edit_label_icon: "아이콘",
      admin_amenity_edit_button_upload: "업로드",
      admin_amenity_edit_placeholder_upload: "ex) MyCONECT.png",
      admin_amenity_edit_label_update_date: "수정 날짜",
      admin_amenity_edit_button_cancel: "취소",
      admin_amenity_edit_button_delete: "삭제",
      admin_amenity_edit_button_ok: "확인",
      admin_facility_img_list_title: "호텔 시설 이미지 목록",
      admin_facility_img_list_label_facility_img: "호텔 시설 이미지",
      admin_facility_img_list_label_description: "설명",
      admin_facility_img_list_label_date: "날짜",
      admin_facility_img_list_button_delete: "삭제",
      admin_facility_img_list_button_add: "추가",
      admin_facility_img_list_guide_empty: "목록이 비어 있습니다",
      admin_facility_img_add_title: "호텔 시설 이미지 추가",
      admin_facility_img_add_validation_image: "이미지를 업로드해주세요.",
      admin_facility_img_add_validation_description: "설명을 입력해주세요.",
      admin_facility_img_add_label_img: "이미지",
      admin_facility_img_add_button_upload: "업로드",
      admin_facility_img_add_label_description: "설명",
      admin_facility_img_add_button_go_to_list: "목록으로 가기",
      admin_facility_img_add_button_add: "추가",
      admin_facility_img_detail_title: "호텔 시설 이미지 정보",
      admin_facility_img_detail_label_img: "이미지",
      admin_facility_img_detail_button_upload: "업로드",
      admin_facility_img_detail_label_description: "설명",
      admin_facility_img_detail_label_updated_at: "업데이트 시간",
      admin_facility_img_detail_button_go_to_list: "목록으로 가기",
      admin_facility_img_detail_button_cancel: "취소",
      admin_facility_img_detail_button_delete: "삭제",
      admin_facility_img_detail_button_edit: "수정",
      admin_facility_img_detail_button_ok: "확인",
      admin_facility_img_edit_title: "호텔 시설 이미지 정보",
      admin_facility_img_edit_validation_image: "이미지를 업로드해주세요.",
      admin_facility_img_edit_validation_description: "설명을 입력해주세요.",
      admin_facility_img_edit_label_img: "이미지",
      admin_facility_img_edit_button_upload: "업로드",
      admin_facility_img_edit_label_description: "설명",
      admin_facility_img_edit_label_updated_at: "업데이트 시간",
      admin_facility_img_edit_button_cancel: "취소",
      admin_facility_img_edit_button_delete: "삭제",
      admin_facility_img_edit_button_ok: "확인",
      admin_recommend_app_list_title: "앱 추천 목록",
      admin_recommend_app_list_select_name: "이름",
      admin_recommend_app_list_placeholder_search: "검색",
      admin_recommend_app_list_label_name: "이름",
      admin_recommend_app_list_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_list_label_aos_package_id: "AOS 패키지 ID",
      admin_recommend_app_list_label_date: "날짜",
      admin_recommend_app_list_button_delete: "삭제",
      admin_recommend_app_list_button_add: "추가",
      admin_recommend_app_list_guide_empty: "목록이 비어 있습니다.",
      admin_recommend_app_add_title: "앱 추천 추가",
      admin_recommend_app_add_validation_name: "이름을 입력해주세요.",
      admin_recommend_app_add_validation_ios_scheme: "iOS Scheme을 입력해주세요.",
      admin_recommend_app_add_validation_ios_itunes_url: "iOS 아이튠즈 URL을 입력해주세요.",
      admin_recommend_app_add_validation_aos_package_id: "AOS 패키지 ID을 입력해주세요.",
      admin_recommend_app_add_validation_aos_play_store_url: "AOS 플레이 스토어 URL을 입력해주세요.",
      admin_recommend_app_add_label_name: "이름",
      admin_recommend_app_add_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_add_label_ios_itunes_url: "iOS 아이튠즈 URL",
      admin_recommend_app_add_label_aos_package_id: "AOS 패키지 ID",
      admin_recommend_app_add_label_aos_play_store_url: "AOS 플레이 스토어 URL",
      admin_recommend_app_add_button_go_to_list: "목록으로 가기",
      admin_recommend_app_add_button_add: "추가",
      admin_recommend_app_detail_title: "앱 추천 정보",
      admin_recommend_app_detail_label_name: "이름",
      admin_recommend_app_detail_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_detail_label_ios_itunes_url: "iOS 아이튠즈 URL",
      admin_recommend_app_detail_label_aos_package_id: "AOS 패키지 ID",
      admin_recommend_app_detail_label_aos_play_store_url: "AOS 플레이 스토어 URL",
      admin_recommend_app_detail_label_updated_at: "업데이트 시간",
      admin_recommend_app_detail_button_go_to_list: "목록으로 가기",
      admin_recommend_app_detail_button_delete: "삭제",
      admin_recommend_app_detail_button_edit: "수정",
      admin_recommend_app_edit_title: "앱 추천 정보",
      admin_recommend_app_edit_validation_name: "이름을 입력해주세요.",
      admin_recommend_app_edit_validation_ios_scheme: "iOS Scheme을 입력해주세요.",
      admin_recommend_app_edit_validation_ios_itunes_url: "iOS 아이튠즈 URL을 입력해주세요.",
      admin_recommend_app_edit_validation_aos_package_id: "AOS 패키지 ID을 입력해주세요.",
      admin_recommend_app_edit_validation_aos_play_store_url: "AOS 플레이 스토어 URL을 입력해주세요.",
      admin_recommend_app_edit_label_name: "이름",
      admin_recommend_app_edit_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_edit_label_ios_itunes_url: "iOS 아이튠즈 URL",
      admin_recommend_app_edit_label_aos_package_id: "AOS 패키지 ID",
      admin_recommend_app_edit_label_aos_play_store_url: "AOS 플레이 스토어 URL",
      admin_recommend_app_editl_label_updated_at: "업데이트 시간",
      admin_recommend_app_edit_button_cancel: "취소",
      admin_recommend_app_edit_button_delete: "삭제",
      admin_recommend_app_edit_button_ok: "확인",
      admin_admin_user_list_title: "사용자 목록",
      admin_admin_user_list_placeholder_select_box_user: "사용자",
      admin_admin_user_list_select_name: "이름",
      admin_admin_user_list_select_email: "이메일",
      admin_admin_user_list_select_department: "부서",
      admin_admin_user_list_select_duty: "직무",
      admin_admin_user_list_placeholder_search: "검색",
      admin_admin_user_list_label_name: "이름",
      admin_admin_user_list_label_email: "이메일",
      admin_admin_user_list_label_department: "부서",
      admin_admin_user_list_label_duty: "직무",
      admin_admin_user_list_label_date: "날짜",
      admin_admin_user_list_guide_empty: "목록이 비어 있습니다.",
      admin_admin_user_list_button_delete: "삭제",
      admin_admin_user_list_button_add: "추가",
      admin_admin_user_add_title: "새 관리자",
      admin_admin_user_add_guide_click_add: "정보 입력 후 <<추가>> 버튼을 눌러 사용자를 추가하세요.",
      admin_admin_user_add_validation_email: "사용자 이메일을 입력하세요.",
      admin_admin_user_add_validation_email_format: "이메일 형식에 맞게 입력하세요.",
      admin_admin_user_add_validation_password: "사용자 비밀번호를 입력하세요.",
      admin_admin_user_add_validation_character_number_combining: "영문과 숫자를 조합하여 8자 이상 입력하세요.",
      admin_admin_user_add_validation_not_match: "비밀번호가 일치하지 않습니다.",
      admin_admin_user_add_validation_name: "이름을 입력하세요.",
      admin_admin_user_add_validation_korea_english: "한국어, 영어 또는 ._ - 를 사용하여 입력하세요.",
      admin_admin_user_add_validation_department: "부서를 입력하세요.",
      admin_admin_user_add_validation_select_duty: "직원의 담당업무를 선택하세요.",
      admin_admin_user_add_label_email: "이메일",
      admin_admin_user_add_placeholder_email: "이메일",
      admin_admin_user_add_label_name: "이름",
      admin_admin_user_add_placeholder_name: "이름",
      admin_admin_user_add_label_passoword: "비밀번호",
      admin_admin_user_add_placeholder_password: "비밀번호",
      admin_admin_user_add_label_confirm_passoword: "비밀번호 확인",
      admin_admin_user_add_placeholder_confirm_password: "비밀번호 확인",
      admin_admin_user_add_label_department: "부서",
      admin_admin_user_add_placeholder_department: "부서",
      admin_admin_user_add_label_duty: "담당업무",
      admin_admin_user_add_select_choose: "선택",
      admin_admin_user_add_select_super_admin: "최고 관리자",
      admin_admin_user_add_select_admin: "관리자",
      admin_admin_user_add_select_user: "사용자",
      admin_admin_user_add_button_add: "추가",
      admin_admin_user_add_button_go_to_list: "목록으로 가기",
      admin_admin_user_detail_title: "사용자 정보",
      admin_admin_user_detail_select_user: "사용자",
      admin_admin_user_detail_select_admin: "관리자",
      admin_admin_user_detail_select_super_admin: "최고 관리자",
      admin_admin_user_detail_label_admin: "관리자",
      admin_admin_user_detail_label_name: "이름",
      admin_admin_user_detail_label_department: "부서",
      admin_admin_user_detail_label_duty: "직무",
      admin_admin_user_detail_label_date: "날짜",
      admin_admin_user_detail_button_delete: "삭제",
      admin_admin_user_detail_button_go_to_list: "목록으로 가기",
      admin_admin_user_detail_button_ok: "확인",
      admin_admin_user_edit_title: "관리자 수정",
      admin_admin_user_edit_guide_edit_or_leave: "이메일을 제외한 모든 정보를 수정할 수 있습니다.",
      admin_admin_user_edit_validation_name: "이름을 입력하세요.",
      admin_admin_user_edit_validation_department: "부서를 입력하세요.",
      admin_admin_user_edit_validation_korea_english: "한국어, 영어 또는 ._ - 를 사용하여 입력하세요.",
      admin_admin_user_edit_button_withdraw: "철회",
      admin_admin_user_edit_button_save: "저장",
      admin_id_recognition_information_list_title: "신분증 인식 정보 목록",
      admin_id_recognition_information_list_select_id_type_all: "신분증 유형 (모두)",
      admin_id_recognition_information_list_select_id_type_registration: "등록증",
      admin_id_recognition_information_list_select_id_type_passport: "여권",
      admin_id_recognition_information_list_select_id_type_driver_license: "운전 면허증",
      admin_id_recognition_information_list_placeholder_search: "국가명",
      admin_id_recognition_information_list_label_country_name: "국가명",
      admin_id_recognition_information_list_label_id_type: "신분증 유형",
      admin_id_recognition_information_list_label_date: "날짜",
      admin_id_recognition_information_list_guide_empty: "목록이 비어 있습니다.",
      admin_id_recognition_information_list_button_delete: "삭제",
      admin_id_recognition_information_list_button_add: "추가",
      admin_id_recognition_information_add_title: "신분증 인식 정보 추가",
      admin_id_recognition_information_add_guide_click_add: "정보 입력 후 <<추가>> 버튼을 눌러 신분증 인식 정보를 추가하세요.",
      admin_id_recognition_information_add_label_country_name: "국가명",
      admin_id_recognition_information_add_placeholder_country_name: "국가명",
      admin_id_recognition_information_add_label_id_type: "신분증 유형",
      admin_id_recognition_information_add_placeholder_select_box_choose: "선택",
      admin_id_recognition_information_add_select_id_type_registration: "등록증",
      admin_id_recognition_information_add_select_id_type_passport: "여권",
      admin_id_recognition_information_add_select_id_type_driver_license: "운전 면허증",
      admin_id_recognition_information_add_label_recognized_texts: "인식 문자",
      admin_id_recognition_information_add_placeholder_recognized_texts: "ex) REPUBLIC OF KOREA, Passport No., Surname, Given names, Date of birth, ...",
      admin_id_recognition_information_add_validation_country_name: "국가명을 입력하세요.",
      admin_id_recognition_information_add_validation_id_type: "신분증 유형을 입력하세요.",
      admin_id_recognition_information_add_validation_recognized_texts: "인식 문자들을 입력하세요.",
      admin_id_recognition_information_add_button_go_to_list: "목록으로 가기",
      admin_id_recognition_information_add_button_add: "추가",
      admin_id_recognition_information_detail_title: "신분증 인식 정보",
      admin_id_recognition_information_detail_label_country_name: "국가명",
      admin_id_recognition_information_detail_label_id_type: "신분증 유형",
      admin_id_recognition_information_detail_label_recognized_texts: "인식 문자",
      admin_id_recognition_information_detail_label_created_at: "생성 시간",
      admin_id_recognition_information_detail_label_updated_at: "업데이트 시간",
      admin_id_recognition_information_detail_button_go_to_list: "목록으로 가기",
      admin_id_recognition_information_detail_button_edit: "수정",
      admin_id_recognition_information_edit_title: "신분증 인식 정보",
      admin_id_recognition_information_edit_label_country_name: "국가명",
      admin_id_recognition_information_edit_placeholder_country_name: "국가명",
      admin_id_recognition_information_edit_label_id_type: "신분증 유형",
      admin_id_recognition_information_edit_placeholder_select_box_choose: "선택",
      admin_id_recognition_information_edit_select_id_type_registration: "등록증",
      admin_id_recognition_information_edit_select_id_type_passport: "여권",
      admin_id_recognition_information_edit_select_id_type_driver_license: "운전 면허증",
      admin_id_recognition_information_edit_label_recognized_texts: "인식 문자",
      admin_id_recognition_information_edit_placeholder_recognized_texts: "ex) REPUBLIC OF KOREA, Passport No., Surname, Given names, Date of birth, ...",
      admin_id_recognition_information_edit_validation_country_name: "국가명을 입력하세요.",
      admin_id_recognition_information_edit_validation_id_type: "신분증 유형을 입력하세요.",
      admin_id_recognition_information_edit_validation_recognized_texts: "인식 문자들을 입력하세요.",
      admin_id_recognition_information_edit_label_updated_at: "업데이트 시간",
      admin_id_recognition_information_edit_button_cancel: "취소",
      admin_id_recognition_information_edit_button_ok: "확인",
      admin_statistics_list_title: "통계 목록",
      admin_statistics_list_placeholder_select_box_choose: "선택 (전체)",
      admin_statistics_list_placeholder_search: "검색",
      admin_statistics_list_label_date: "날짜",
      admin_statistics_list_label_reservation: "예약",
      admin_statistics_list_label_walk_in: "워크인",
      admin_statistics_list_label_check_in: "체크인",
      admin_statistics_list_label_checked_out: "체크아웃",
      admin_statistics_list_button_go_to_statistics: "통계로 돌아가기",
      hotel_header_link_password: "비밀번호",
      hotel_header_link_logout: "로그아웃",
      hotel_header_accordion_hotel: "호텔",
      hotel_header_link_hotel_information: "정보",
      hotel_header_link_hotel_beacon: "비콘",
      hotel_header_link_hotel_currency: "통화",
      hotel_header_link_hotel_setting: "설정",
      hotel_header_link_hotel_detail: "호텔 상세정보",
      hotel_header_link_hotel_image: "호텔 이미지",
      hotel_header_accordion_room: "객실",
      hotel_header_link_room: "객실",
      hotel_header_link_room_detail: "객실 상세정보",
      hotel_header_link_room_image: "객실 이미지",
      hotel_header_accordion_room_management: "객실 관리",
      hotel_header_link_room_list: "객실 목록",
      hotel_header_link_room_management_setting: "설정",
      hotel_header_accordion_package: "패키지",
      hotel_header_link_package_package: "패키지",
      hotel_header_link_package_package_detail: "패키지 상세정보",
      hotel_header_accordion_member: "회원",
      hotel_header_link_member_member: "회원",
      hotel_header_link_member_non_member: "비회원",
      hotel_header_accordion_reservation: "예약",
      hotel_header_link_reservation_reservation: "예약",
      hotel_header_link_reservation_future_booking: "미래 예약",
      hotel_header_link_reservation_waiting_room: "객실배정 대기",
      hotel_header_accordion_reservation_policy: "예약 정책",
      hotel_header_link_reservation_rule_setting_deposit_rule: "보증금 규칙",
      hotel_header_link_reservation_rule_setting_reservation_rule: "예약 규칙",
      hotel_header_link_reservation_rule_setting_cancellation_rule: "취소 규칙",
      hotel_header_link_reservation_rule_setting_no_show_rule: "노쇼 규칙",
      hotel_header_link_reservation_rule_setting_rule_schedule: "규칙 스케줄",
      hotel_header_accordion_payment: "결제",
      hotel_header_link_payment_history: "내역",
      hotel_header_link_payment_setting: "설정",
      hotel_header_accordion_service: "서비스",
      hotel_header_link_service_hotel_info: "호텔 정보",
      hotel_header_accordion_concierge: "컨시어지",
      hotel_header_link_service_concierge_request: "요청 (확인 전)",
      hotel_header_link_service_concierge_process: "요청 (확인 후)",
      hotel_header_link_service_concierge_schedule_process: "스케줄 처리 결과",
      hotel_header_link_service_concierge_schedule: "스케줄 설정",
      hotel_header_link_service_concierge_business_rule: "업무 규칙 설정",
      hotel_header_link_service_concierge_boundary_line: "제한 설정",
      hotel_header_accordion_housekeeping: "하우스키핑",
      hotel_header_link_service_housekeeping_request: "요청 (확인 전)",
      hotel_header_link_service_housekeeping_process: "요청 (확인 후)",
      hotel_header_link_service_housekeeping_schedule_process: "스케줄 처리 결과",
      hotel_header_link_service_housekeeping_schedule: "스케줄 설정",
      hotel_header_link_service_housekeeping_business_rule: "업무 규칙 설정",
      hotel_header_link_service_housekeeping_boundary_line: "제한 설정",
      hotel_header_link_service_setting_amenity: "어메니티 설정",
      hotel_header_link_service_housekeeping_setting: "설정",
      hotel_header_accordion_in_room_dining: "인 룸 다이닝",
      hotel_header_link_service_in_room_dining_request: "요청 (확인 전)",
      hotel_header_link_service_in_room_dining_process: "요청 (확인 후)",
      hotel_header_link_service_in_room_dining_schedule_process: "스케줄 처리 결과",
      hotel_header_link_service_in_room_dining_schedule: "스케줄 설정",
      hotel_header_link_service_in_room_dining_business_rule: "업무 규칙 설정",
      hotel_header_link_service_in_room_dining_boundary_line: "제한 설정",
      hotel_header_link_service_in_room_dining_cart: "장바구니",
      hotel_header_link_service_in_room_dining_category: "카테고리",
      hotel_header_link_service_in_room_dining_menu_setting: "메뉴 설정",
      hotel_header_link_service_in_room_dining_setting: "설정",
      hotel_header_accordion_valet: "발렛파킹",
      hotel_header_link_service_valet_process: "요청",
      hotel_header_link_service_valet_schedule: "스케줄 설정",
      hotel_header_link_service_valet_business_rule: "업무 규칙 설정",
      hotel_header_link_service_valet_parking_setting: "설정",
      hotel_header_accordion_self: "셀프파킹",
      hotel_header_link_service_self_parking_setting: "설정",
      hotel_header_link_service_hotel_inhouse_menu_setting: "인하우스 메뉴 설정",
      hotel_header_link_service_hotel_inhouse_menu_description: "서비스 설명",
      hotel_header_accordion_key: "키",
      hotel_header_link_key_room_to_room: "룸투룸",
      hotel_header_link_key_guest_key: "게스트 키",
      hotel_header_link_key_staff_key: "스태프 키",
      hotel_header_link_key_share_key: "공유 키",
      hotel_header_link_key_help_for_mobile_key: "모바일 키 도움말",
      hotel_header_link_key_staff_key_link: "링크",
      hotel_header_link_key_access: "접근권한 설정",
      hotel_header_link_key_access_detail: "접근권한 상세정보 설정",
      hotel_header_link_key_key_setting: "설정",
      hotel_header_link_key_reservation_access: "접근권한 링크",
      hotel_header_accordion_pms: "PMS",
      hotel_header_link_pms_title: "호칭",
      hotel_header_link_pms_card_type: "카드 타입",
      hotel_header_link_pms_membership_type: "회원권 타입",
      hotel_header_link_pms_fias_manager: "FIAS 매니저",
      hotel_header_link_pms_notification_callback: "알림 콜백",
      hotel_header_link_pms_setting: "설정",
      hotel_header_accordion_room_controller: "객실제어",
      hotel_header_link_room_controller_info: "정보",
      hotel_header_link_room_controller_setting: "설정",
      hotel_header_accordion_document: "문서",
      hotel_header_accordion_document_email: "이메일",
      hotel_header_accordion_event: "이벤트",
      hotel_header_link_event_event: "이벤트",
      hotel_header_link_event_event_category: "이벤트 카테고리",
      hotel_header_link_email_reservation: "예약",
      hotel_header_link_email_reservation_change: "예약 변경",
      hotel_header_link_email_reservation_cancel: "예약 취소",
      hotel_header_link_email_no_show: "노쇼",
      hotel_header_link_email_reservation_pack_add: "예약 패키지 추가",
      hotel_header_link_email_reservation_pack_delete: "예약 패키지 삭제",
      hotel_header_link_email_reservation_accom_add: "예약 동반자 추가",
      hotel_header_link_email_reservation_accom_delete: "예약 동반자 삭제",
      hotel_header_link_email_reservation_accom_email: "동반자 예약 이메일",
      hotel_header_link_email_reservation_accom_change: "동반자 예약 변경",
      hotel_header_link_email_reservation_accom_cancel: "동반자 예약 취소",
      hotel_header_link_email_reservation_folio_email: "폴리오 이메일",
      hotel_header_link_email_reservation_enroll_profile: "등록 프로필",
      hotel_header_accordion_document_pdf: "PDF",
      hotel_header_link_pdf_reservation: "예약 PDF",
      hotel_header_link_pdf_reservation_accom_pdf: "동반자 예약 PDF",
      hotel_header_link_pdf_folio_pdf: "폴리오 PDF",
      hotel_header_accordion_terms_and_conditions: "이용 약관",
      hotel_header_link_terms_and_conditions_terms: "약관",
      hotel_header_link_terms_and_conditions_agreement: "동의 내역",
      hotel_header_current_version: "현재 버전",
      hotel_header_translation_version: "번역 버전",
      hotel_header_link_staff_user: "스태프 사용자",
      hotel_header_link_app_download: "앱 설치",
      hotel_password_title: "비밀번호 변경",
      hotel_password_guide_password: "8자리 이상의 새 비밀번호를 입력하세요.",
      hotel_password_validation_current_password: "* 현재 비밀번호를 입력하세요.",
      hotel_password_validation_character_number_combining: "* 영문과 숫자를 조합하여 8자 이상 입력하세요.",
      hotel_password_validation_new_password: "* 새 비밀번호를 입력하세요.",
      hotel_password_validation_new_password_again: "* 새 비밀번호를 다시 입력하세요.",
      hotel_password_validation_not_match: "* 비밀번호가 일치하지 않습니다.",
      hotel_password_placeholder_password: "비밀번호",
      hotel_password_placeholder_new_password: "새 비밀번호",
      hotel_password_placeholder_new_confirm_password: "새 비밀번호 확인",
      hotel_password_button_save: "저장",
      hotel_hotel_info_detail_title: "호텔 정보",
      hotel_hotel_info_detail_label_hotel_no: "호텔 번호",
      hotel_hotel_info_detail_label_name: "호텔명",
      hotel_hotel_info_detail_label_address: "주소",
      hotel_hotel_info_detail_label_region: "지역",
      hotel_hotel_info_detail_label_country: "국가",
      hotel_hotel_info_detail_label_city: "도시",
      hotel_hotel_info_detail_label_logo: "로고",
      hotel_hotel_info_detail_label_hotel_photo: "호텔",
      hotel_hotel_info_detail_label_hotel_map: "지도",
      hotel_hotel_info_detail_button_edit: "수정",
      hotel_hotel_info_edit_title: "호텔 정보",
      hotel_hotel_info_edit_label_hotel_no: "호텔 번호",
      hotel_hotel_info_edit_label_name: "호텔명",
      hotel_hotel_info_edit_label_address: "주소",
      hotel_hotel_info_edit_label_region: "지역",
      hotel_hotel_info_edit_label_country: "국가",
      hotel_hotel_info_edit_label_city: "도시",
      hotel_hotel_info_edit_label_logo: "로고",
      hotel_hotel_info_edit_button_upload: "업로드",
      hotel_hotel_info_edit_placeholder_upload: "ex) MyCONECT.png",
      hotel_hotel_info_edit_label_hotel_photo: "호텔 사진",
      hotel_hotel_info_edit_label_hotel_map: "지도",
      hotel_hotel_info_edit_button_search: "검색",
      hotel_hotel_info_edit_button_ok: "확인",
      hotel_hotel_info_edit_validation_hotel_name: "* 호텔명을 입력하세요.",
      hotel_hotel_info_edit_validation_hotel_main_tel: "* 호텔 대표 전화번호를 입력하세요.",
      hotel_hotel_info_edit_validation_hotel_service_tel: "* 호텔 서비스 전화번호를 입력하세요.",
      hotel_hotel_info_edit_validation_hotel_address: "* 주소를 입력하세요.",
      hotel_hotel_pms_detail_title: "설정",
      hotel_hotel_pms_detail_label_date: "업데이트 날짜",
      hotel_hotel_pms_detail_label_type: "타입",
      hotel_hotel_pms_detail_label_cloud_flag: "클라우드 여부",
      hotel_hotel_pms_detail_label_server_url: "서버 URL",
      hotel_hotel_pms_detail_label_origin_entity_id: "Origin Entity ID",
      hotel_hotel_pms_detail_label_origin_system_type: "Origin System Type",
      hotel_hotel_pms_detail_label_destination_entity_id: "Destination Entity ID",
      hotel_hotel_pms_detail_label_destination_system_type: "Destination System Type",
      hotel_hotel_pms_detail_label_user_name: "사용자 이름",
      hotel_hotel_pms_detail_label_user_password: "사용자 비밀번호",
      hotel_hotel_pms_detail_label_domain: "도메인",
      hotel_hotel_pms_detail_label_hotel_code: "Hotel Code",
      hotel_hotel_pms_detail_label_chain_code: "Chain Code",
      hotel_hotel_pms_detail_label_reservation_transaction_code_is_credit: "Reservation Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_cancellation_transaction_code_is_credit: "Cancellation Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_deposit_transaction_code_is_credit: "Deposit Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_noshow_transaction_code_is_credit: "No-Show Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_service_fee_transaction_code_is_credit: "Service Fee Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_default_reservation_transaction_code: "Default Reservation Transaction Code",
      hotel_hotel_pms_detail_label_default_cancellation_transaction_code: "Default Cancellation Transaction Code",
      hotel_hotel_pms_detail_label_default_deposit_transaction_code: "Default Deposit Transaction Code",
      hotel_hotel_pms_detail_label_default_noshow_transaction_code: "Default No-Show Transaction Code",
      hotel_hotel_pms_detail_label_default_service_fee_transaction_code: "Default Service Fee Transaction Code",
      hotel_hotel_pms_detail_label_cancellation_debit_transaction_code: "Cancellation Debit Transaction Code",
      hotel_hotel_pms_detail_label_noshow_debit_transaction_code: "No-Show Debit Transaction Code",
      hotel_hotel_pms_detail_label_market_code: "Market Code",
      hotel_hotel_pms_detail_label_future_booking: "예약",
      hotel_hotel_pms_detail_label_walk_in: "워크인",
      hotel_hotel_pms_detail_label_web_booking: "홈페이지 예약",
      hotel_hotel_pms_detail_label_ota_booking: "OTA 예약",
      hotel_hotel_pms_detail_label_origin_code: "Origin Code",
      hotel_hotel_pms_detail_label_rate_code: "Rate Code",
      hotel_hotel_pms_detail_label_yes: "예",
      hotel_hotel_pms_detail_label_no: "아니오",
      hotel_hotel_pms_detail_label_earliest_checkin_time: "가장 빠른 체크인 시간",
      hotel_hotel_pms_detail_label_dummy_pms_profile_id: "더미 PMS 프로필 ID",
      hotel_hotel_pms_detail_button_go_to_info: "정보로 가기",
      hotel_hotel_pms_detail_button_edit: "수정",
      hotel_hotel_pms_edit_opera: "오페라",
      hotel_hotel_pms_edit_planyo: "플랜요",
      hotel_hotel_pms_edit_title: "설정",
      hotel_hotel_pms_edit_label_date: "업데이트 날짜",
      hotel_hotel_pms_edit_label_type: "타입",
      hotel_hotel_pms_edit_label_cloud_flag: "클라우드 여부",
      hotel_hotel_pms_edit_label_server_url: "서버 URL",
      hotel_hotel_pms_edit_label_origin_entity_id: "Origin Entity ID",
      hotel_hotel_pms_edit_label_origin_system_type: "기존 시스템 타입",
      hotel_hotel_pms_edit_label_destination_entity_id: "Destination Entity ID",
      hotel_hotel_pms_edit_label_destination_system_type: "Destination System Type",
      hotel_hotel_pms_edit_label_user_name: "사용자 이름",
      hotel_hotel_pms_edit_label_user_password: "사용자 비밀번호",
      hotel_hotel_pms_edit_label_domain: "도메인",
      hotel_hotel_pms_edit_label_hotel_code: "Hotel Code",
      hotel_hotel_pms_edit_label_chain_code: "Chain Code",
      hotel_hotel_pms_edit_label_reservation_transaction_code_is_credit: "Reservation Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_cancellation_transaction_code_is_credit: "Cancellation Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_deposit_transaction_code_is_credit: "Deposit Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_noshow_transaction_code_is_credit: "No-Show Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_service_fee_transaction_code_is_credit: "Service Fee Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_default_reservation_transaction_code: "Default Reservation Transaction Code",
      hotel_hotel_pms_edit_label_default_cancellation_transaction_code: "Default Cancellation Transaction Code",
      hotel_hotel_pms_edit_label_default_deposit_transaction_code: "Default Deposit Transaction Code",
      hotel_hotel_pms_edit_label_default_noshow_transaction_code: "Default No-Show Transaction Code",
      hotel_hotel_pms_edit_label_default_service_fee_transaction_code: "Default Service Fee Transaction Code",
      hotel_hotel_pms_edit_label_cancellation_debit_transaction_code: "Cancellation Debit Transaction Code",
      hotel_hotel_pms_edit_label_noshow_debit_transaction_code: "No-Show Debit Transaction Code",
      hotel_hotel_pms_edit_label_market_code: "Market Code",
      hotel_hotel_pms_edit_label_future_booking: "Future Booking",
      hotel_hotel_pms_edit_label_walk_in: "Walk-In",
      hotel_hotel_pms_edit_label_web_booking: "Web Booking",
      hotel_hotel_pms_edit_label_ota_booking: "OTA 예약",
      hotel_hotel_pms_edit_label_origin_code: "Origin Code",
      hotel_hotel_pms_edit_label_rate_code: "Rate Code",
      hotel_hotel_pms_edit_label_yes: "예",
      hotel_hotel_pms_edit_label_no: "아니오",
      hotel_hotel_pms_edit_button_cancel: "취소",
      hotel_hotel_pms_edit_button_ok: "확인",
      hotel_hotel_pms_edit_validation_server_url: "* 서버 URL을 입력해주세요.",
      hotel_hotel_pms_edit_validation_origin_entity_id: "* Origin Entity ID를 입력해주세요.",
      hotel_hotel_pms_edit_validation_origin_system_type: "* Origin System Type을 입력해주세요.",
      hotel_hotel_pms_edit_validation_destination_entity_id: "* Destination Entity ID를 입력해주세요.",
      hotel_hotel_pms_edit_validation_destination_system_type: "* Destination System Type을 입력해주세요.",
      hotel_hotel_pms_edit_validation_user_name: "* 사용자 이름을 입력해주세요.",
      hotel_hotel_pms_edit_validation_user_password: "* 사용자 비밀번호를 입력해주세요.",
      hotel_hotel_pms_edit_validation_domain: "* 도메인을 입력해주세요.",
      hotel_hotel_setting_detail_title: "설정",
      hotel_hotel_setting_detail_label_date: "업데이트 날짜",
      hotel_hotel_setting_detail_label_room_control: "객실제어",
      hotel_hotel_setting_detail_label_room_control_ip: "객실제어 IP",
      hotel_hotel_setting_detail_label_room_control_port: "객실제어 포트",
      hotel_hotel_setting_detail_label_fias: "FIAS",
      hotel_hotel_setting_detail_label_notification_callback: "알림 콜백",
      hotel_hotel_setting_detail_label_fias_hotel_id: "호텔 ID",
      hotel_hotel_setting_detail_label_fias_auth_code: "인증 코드",
      hotel_hotel_setting_detail_label_payment: "결제",
      hotel_hotel_setting_detail_label_payment_auth_code: "인증 코드",
      hotel_hotel_setting_detail_label_store_id: "스토어 ID",
      hotel_hotel_setting_detail_label_store_key: "스토어 키",
      hotel_hotel_setting_detail_label_store_api_id: "스토어 API ID",
      hotel_hotel_setting_detail_label_payment_salt: "결제 보안 임의 데이터",
      hotel_hotel_setting_detail_label_payment_token_password: "결제 토큰 비밀번호",
      hotel_hotel_setting_detail_label_secure_hash_secret: "보안 해시 비밀키",
      hotel_hotel_setting_detail_label_payment_url: "결제 URL",
      hotel_hotel_setting_detail_label_refund_url: "환불 URL",
      hotel_hotel_setting_detail_label_payment_type: "결제 타입",
      hotel_hotel_setting_detail_placeholder_select_box_choose: "선택",
      hotel_hotel_setting_detail_select_nicepay: "Nicepay",
      hotel_hotel_setting_detail_select_asiapay: "Asiapay",
      hotel_hotel_setting_detail_label_booking_payment_using_myconect_flag: "예약시 MyCONECT 결제 사용여부",
      hotel_hotel_setting_detail_label_checkin_payment_using_myconect_flag: "체크인시 MyCONECT 결제 사용여부",
      hotel_hotel_setting_detail_label_walkin_payment_using_myconect_flag: "워크인시 MyCONECT 결제 사용여부",
      hotel_hotel_setting_detail_label_noshow_payment_using_myconect_flag: "노쇼시 MyCONECT 결제 사용여부",
      hotel_hotel_setting_detail_label_checkout_payment_using_myconect_flag: "체크아웃시 MyCONECT 결제 사용여부",
      hotel_hotel_setting_detail_label_cancel_booking_payment_using_myconect_flag: "예약취소시 MyCONECT 결제 사용여부",
      hotel_hotel_setting_detail_label_need_card_information_for_booking: "예약시 카드정보 필요여부",
      hotel_hotel_setting_detail_label_need_card_information_for_checkin: "체크인시 카드정보 필요여부",
      hotel_hotel_setting_detail_label_need_card_information_for_walkin: "워크인시 카드정보 필요여부",
      hotel_hotel_setting_detail_label_is_direct_payable: "토큰정보로 direct pay 사용여부",
      hotel_hotel_setting_detail_label_is_card_tokenizable: "카드정보 토큰화 사용여부",
      hotel_hotel_setting_detail_label_min_card_expiry_days_post_checkout: "체크아웃 후 최소 카드 만료일",
      hotel_hotel_setting_detail_label_time: "시간",
      hotel_hotel_setting_detail_label_early_check_in: "얼리 체크인",
      hotel_hotel_setting_detail_label_assign_room_time: "객실배정 시간 (시간:분)",
      hotel_hotel_setting_detail_label_do_not_disturb_time: "방해 금지 요청 시간 (시간:분)",
      hotel_hotel_setting_detail_label_time_zone: "시간대",
      hotel_hotel_setting_detail_label_tell: "전화번호",
      hotel_hotel_setting_detail_label_hotel_main_tel: "호텔 대표 전화번호",
      hotel_hotel_setting_detail_label_hotel_service_tel: "호텔 서비스 전화번호",
      hotel_hotel_setting_detail_label_setting: "설정",
      hotel_hotel_setting_detail_label_dnd: "DND",
      hotel_hotel_setting_detail_label_dnd_status: "DND 상태 사용자 확인 알림 기간 (시간:분)",
      hotel_hotel_setting_detail_label_dnd_relese: "서비스 알림 시간에 대한 DND 릴리스 사전 요청 (분)",
      hotel_hotel_setting_detail_button_yes: "예",
      hotel_hotel_setting_detail_button_no: "아니오",
      hotel_hotel_setting_detail_button_refresh: "새로고침",
      hotel_hotel_setting_detail_label_member_number_check: "고객 인원 확인",
      hotel_hotel_setting_detail_label_passport: "여권 확인 모듈",
      hotel_hotel_setting_detail_select_detion: "Detion",
      hotel_hotel_setting_detail_select_inzi_soft: "Inzi Soft",
      hotel_hotel_setting_detail_label_accompany_local_check: "동반 고객(내국인) 신분증 스캔",
      hotel_hotel_setting_detail_label_accompany_foreigner_check: "동반 고객(외국인) 신분증 스캔",
      hotel_hotel_setting_detail_label_room_number: "객실 번호 표시",
      hotel_hotel_setting_detail_label_hotel_logo: "호텔 로고 표시",
      hotel_hotel_setting_detail_label_member_lock_count: "회원 잠김 횟수",
      hotel_hotel_setting_detail_label_payment_lock_count: "결제 잠김 횟수",
      hotel_hotel_setting_detail_label_staff_login_lock_count: "스태프 로그인 잠김 횟수",
      hotel_hotel_setting_detail_label_default_currency: "기본 통화",
      hotel_hotel_setting_detail_label_percent_for_hotel_charge: "호텔 요금 비율 (%)",
      hotel_hotel_setting_detail_label_language_code: "언어 코드",
      hotel_hotel_setting_detail_label_alternative_name_code: "한글성명 코드",
      hotel_hotel_setting_detail_label_floor: "층",
      hotel_hotel_setting_detail_label_out_of_order_check: "객실 불량 확인",
      hotel_hotel_setting_detail_label_password_change_day: "비밀번호 변경일 (일)",
      hotel_hotel_setting_detail_label_password_change_notice: "비밀번호 변경 알림 기간 (일)",
      hotel_hotel_setting_detail_label_occupy_minutes: "점거 시간 (분)",
      hotel_hotel_setting_detail_label_reservation_charge_waiting_minutes: "예약 요금 대기 시간 (분)",
      hotel_hotel_setting_detail_label_beacon_check_hours: "비콘 확인 시간 (시)",
      hotel_hotel_setting_detail_label_compare_faces_flag: "외국인 얼굴 비교 사용",
      hotel_hotel_setting_detail_label_compare_face_for_local_flag: "내국인 얼굴 비교 사용",
      hotel_hotel_setting_detail_label_singapore_government_eva_flag: "싱가폴 EVA 사용",
      hotel_hotel_setting_detail_label_singapore_government_eva_hotel_code: "싱가폴 EVA 호텔 코드",
      hotel_hotel_setting_detail_label_date_reservation_flag: "예약시 날짜 선택 유무",
      hotel_hotel_setting_detail_label_time_reservation_flag: "예약시 시간 선택 유무",
      hotel_hotel_setting_detail_label_transit_hotel_flag: "경유 호텔 여부",
      hotel_hotel_setting_detail_label_request_make_up_room_at_checkout: "체크아웃시 객실 청소 요청 여부",
      hotel_hotel_setting_detail_label_show_rate_for_ota_flag: "OTA 요금 표시",
      hotel_hotel_setting_detail_button_edit: "수정",
      hotel_hotel_setting_detail_button_cancel: "취소",
      hotel_hotel_setting_edit_title: "설정",
      hotel_hotel_setting_edit_label_date: "날짜 업데이트",
      hotel_hotel_setting_edit_label_room_control: "객실제어",
      hotel_hotel_setting_edit_label_room_control_ip: "객실제어 IP",
      hotel_hotel_setting_edit_placeholder_room_control_ip: "ex) 127.0.0.1",
      hotel_hotel_setting_edit_label_room_control_port: "객실 제어 포트",
      hotel_hotel_setting_edit_placeholder_room_control_port: "ex) 80",
      hotel_hotel_setting_edit_label_payment: "결제",
      hotel_hotel_setting_edit_label_store_id: "스토어 ID",
      hotel_hotel_setting_edit_placeholder_store_id: "스토어 ID",
      hotel_hotel_setting_edit_label_payment_url: "결제 URL",
      hotel_hotel_setting_edit_placeholder_payment_url: "결제 URL",
      hotel_hotel_setting_edit_label_refund_url: "환불 URL",
      hotel_hotel_setting_edit_placeholder_refund_url: "환불 URL",
      hotel_hotel_setting_edit_label_time: "시간",
      hotel_hotel_setting_edit_label_assign_room_time: "객실배정 시간",
      hotel_hotel_setting_edit_placeholder_assign_room_time: "객실배정 시간",
      hotel_hotel_setting_edit_label_do_not_disturb_time: "DND 요청 시간",
      hotel_hotel_setting_edit_placeholder_do_not_disturb_time: "DND 요청 시간",
      hotel_hotel_setting_edit_label_time_zone: "시간대",
      hotel_hotel_setting_edit_placeholder_time_zone: "시간대",
      hotel_hotel_setting_edit_label_tell: "전화번호",
      hotel_hotel_setting_edit_label_hotel_main_tel: "호텔 대표 전화번호",
      hotel_hotel_setting_edit_placeholder_hotel_main_tel: "호텔 대표 전화번호",
      hotel_hotel_setting_edit_label_hotel_service_tel: "호텔 서비스 전화번호",
      hotel_hotel_setting_edit_placeholder_hotel_service_tel: "호텔 서비스 전화번호",
      hotel_hotel_setting_edit_label_setting: "설정",
      hotel_hotel_setting_edit_button_yes: "예",
      hotel_hotel_setting_edit_button_no: "아니오",
      hotel_hotel_setting_edit_label_member_number_check: "고객 인원 확인",
      hotel_hotel_setting_edit_label_accompany_local_check: "동반 고객(내국인) 신분증 스캔",
      hotel_hotel_setting_edit_label_accompany_foreigner_check: "동반 고객(외국인) 신분증 스캔",
      hotel_hotel_setting_edit_label_room_number: "객실 번호 표시",
      hotel_hotel_setting_edit_label_hotel_logo: "호텔 로고 표시",
      hotel_hotel_setting_edit_label_member_lock_count: "회원 잠김 횟수",
      hotel_hotel_setting_edit_placeholder_member_lock_count: "회원 잠김 횟수",
      hotel_hotel_setting_edit_label_payment_lock_count: "결제 잠김 횟수",
      hotel_hotel_setting_edit_placeholder_payment_lock_count: "결제 잠김 횟수",
      hotel_hotel_setting_edit_label_default_currency: "기본 통화",
      hotel_hotel_setting_edit_placeholder_default_currency: "기본 통화",
      hotel_hotel_setting_edit_label_percent_for_hotel_charge: "호텔 요금 비율",
      hotel_hotel_setting_edit_placeholder_percent_for_hotel_charge: "호텔 요금 비율",
      hotel_hotel_setting_edit_label_language_code: "언어 코드",
      hotel_hotel_setting_edit_placeholder_language_code: "언어 코드",
      hotel_hotel_setting_edit_label_alternative_name_code: "한글성명 코드",
      hotel_hotel_setting_edit_placeholder_alternative_name_code: "한글성명 코드",
      hotel_hotel_setting_edit_label_floor: "층",
      hotel_hotel_setting_edit_placeholder_floor: "층",
      hotel_hotel_setting_edit_button_go_to_info: "정보로 가기",
      hotel_hotel_setting_edit_button_cancel: "취소",
      hotel_hotel_setting_edit_button_ok: "확인",
      hotel_hotel_setting_edit_button_refresh: "새로고침",
      hotel_hotel_setting_edit_validation_assign_room_time: "* 객실배정 시간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_time_zone: "* 시간대를 선택해주세요.",
      hotel_hotel_setting_edit_validation_member_lock_count: "* 회원 잠김 횟수를 입력해주세요.",
      hotel_hotel_setting_edit_validation_payment_lock_count: "* 결제 잠김 횟수를 입력해주세요.",
      hotel_hotel_setting_edit_validation_staff_lock_count: "* 스태프 로그인 잠김 횟수를 입력해주세요.",
      hotel_hotel_setting_edit_validation_default_currency: "* 기본 통화를 입력해주세요.",
      hotel_hotel_setting_edit_validation_language_code: "* 언어 코드를 입력해주세요.",
      hotel_hotel_setting_edit_validation_alternative_name_code: "* 한글성명 코드를 입력해주세요.",
      hotel_hotel_setting_edit_validation_floors: "* 층을 입력해주세요.",
      hotel_hotel_setting_edit_validation_password_change_day: "* 비밀번호 변경일을 입력해주세요.",
      hotel_hotel_setting_edit_validation_password_change_notice_period: "* 비밀번호 변경 알림 기간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_occupy_minutes: "* 점거 시간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_reservation_charge_waiting_minutes: "* 예약 요금 대기 시간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_beacon_check_hours: "* 비콘 확인 시간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_gov_sg_eva_hotel_code: "* 싱가폴 EVA 호텔 코드를 입력해주세요.",
      hotel_hotel_setting_edit_validation_store_id: "* 스토어 ID를 입력해주세요.",
      hotel_hotel_setting_edit_validation_store_key: "* 스토어 키를 입력해주세요.",
      hotel_hotel_setting_edit_validation_payment_url: "* 결제 URL을 입력해주세요.",
      hotel_hotel_setting_edit_validation_refund_url: "* 환불 URL을 입력해주세요.",
      hotel_hotel_setting_edit_validation_payment_type: "* 결제 타입을 선택해주세요.",
      hotel_hotel_setting_edit_validation_payment_salt: "* 결제 salt 정보를 입력해주세요.",
      hotel_hotel_setting_edit_validation_payment_token_password: "* 결제 토큰 비밀번호를 입력해주세요.",
      hotel_hotel_setting_edit_validation_secure_hash_secret: "* 보안 해시 비밀키를 입력해주세요.",
      hotel_hotel_setting_edit_validation_booking_payment_using_myconect_flag: "* 예약시 MyCONECT 결제 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_checkin_payment_using_myconect_flag: "* 체크인시 MyCONECT 결제 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_walkin_payment_using_myconect_flag: "* 워크인시 MyCONECT 결제 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_noshow_payment_using_myconect_flag: "* 노쇼시 MyCONECT 결제 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_checkout_payment_using_myconect_flag: "* 체크아웃시 MyCONECT 결제 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_cancel_booking_payment_using_myconect_flag: "* 예약취소시 MyCONECT 결제 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_need_card_information_for_booking: "* 예약시 카드정보 필요여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_need_card_information_for_checkin: "* 체크인시 카드정보 필요여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_need_card_information_for_walkin: "* 워크인시 카드정보 필요여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_is_direct_payable: "* Direct pay 가능여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_is_card_tokenizable: "* 카드정보 토큰화 가능여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_cancel_booking_post_charge_using_flag: "* 예약취소시 후불청구 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_no_show_post_charge_using_flag: "* 노쇼시 후불청구 사용여부를 선택해주세요.",
      hotel_hotel_setting_edit_validation_min_card_expiry_days_checkout: "* 체크아웃 후 최소 카드 만료일을 입력해주세요.",
      hotel_hotel_setting_detail_label_cancel_booking_post_charge_using_flag: "예약취소시 후불청구 사용여부",
      hotel_hotel_setting_detail_label_noshow_post_charge_using_flag: "노쇼시 후불청구 사용여부",
      hotel_hotel_setting_edit_validation_dnd_status: "* DND 상태 사용자 확인 알림 기간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_dnd_relese: "* 서비스 시작전 DND 해제 요청 알림 시간을 입력해주세요.",
      hotel_hotel_setting_edit_validation_room_control_property_id: "* 호텔 ID를 입력해주세요.",
      hotel_hotel_setting_edit_validation_room_control_ip: "* 객실제어 IP를 입력해주세요.",
      hotel_hotel_setting_edit_validation_room_control_port: "* 객실제어 포트를 입력해주세요.",
      hotel_hotel_detail_list_title: "호텔 상세정보 목록",
      hotel_hotel_detail_list_label_name: "이름",
      hotel_hotel_detail_list_label_language_code: "언어 코드",
      hotel_hotel_detail_list_label_rating: "등급",
      hotel_hotel_detail_list_label_address: "주소",
      hotel_hotel_detail_list_label_date: "날짜",
      hotel_hotel_detail_list_button_delete: "삭제",
      hotel_hotel_detail_list_button_add: "추가",
      hotel_hotel_detail_add_title: "호텔 상세정보 추가",
      hotel_hotel_detail_add_label_name: "이름",
      hotel_hotel_detail_add_label_language_code: "언어 코드",
      hotel_hotel_detail_add_placeholder_select_box_choose: "선택",
      hotel_hotel_detail_add_select_ko: "한국어",
      hotel_hotel_detail_add_select_en: "영어",
      hotel_hotel_detail_add_select_cn: "중국어",
      hotel_hotel_detail_add_select_jp: "일본어",
      hotel_hotel_detail_add_label_rating: "등급",
      hotel_hotel_detail_add_label_address: "주소",
      hotel_hotel_detail_add_label_phone: "전화번호",
      hotel_hotel_detail_add_label_email: "이메일주소",
      hotel_hotel_detail_add_button_go_to_list: "목록으로 가기",
      hotel_hotel_detail_add_button_add: "추가",
      hotel_hotel_detail_add_validation_name: "* 호텔명을 입력해주세요.",
      hotel_hotel_detail_add_validation_languageCode: "* 언어 코드를 선택해주세요.",
      hotel_hotel_detail_add_validation_rating: "* 호텔 등급을 입력해주세요.",
      hotel_hotel_detail_add_validation_address: "* 주소를 입력해주세요.",
      hotel_hotel_detail_add_validation_phone: "* 전화번호를 입력해주세요.",
      hotel_hotel_detail_add_validation_email: "* 호텔 이메일 주소를 입력해주세요.",
      hotel_hotel_detail_add_validation_about: "* 객실 설명을 입력해주세요.",
      hotel_hotel_detail_detail_title: "호텔 상세정보",
      hotel_hotel_detail_detail_label_details: "상세정보",
      hotel_hotel_detail_detail_label_name: "이름",
      hotel_hotel_detail_detail_label_language_code: "언어 코드",
      hotel_hotel_detail_detail_placeholder_select_box_choose: "선택",
      hotel_hotel_detail_detail_select_ko: "한국어",
      hotel_hotel_detail_detail_select_en: "영어",
      hotel_hotel_detail_detail_select_cn: "중국어",
      hotel_hotel_detail_detail_select_jp: "일본어",
      hotel_hotel_detail_detail_label_rating: "등급",
      hotel_hotel_detail_detail_label_address: "주소",
      hotel_hotel_detail_detail_label_phone: "전화번호",
      hotel_hotel_detail_detail_label_email: "이메일주소",
      hotel_hotel_detail_detail_label_about: "호텔 설명",
      hotel_hotel_detail_detail_label_date: "날짜",
      hotel_hotel_detail_detail_label_facility: "시설",
      hotel_hotel_detail_detail_label_add_facility: "시설 추가",
      hotel_hotel_detail_detail_label_edit_facility: "시설 수정",
      hotel_hotel_detail_detail_add_placeholder_name: "이름을 입력하세요.",
      hotel_hotel_detail_detail_label_amenity: "편의시설",
      hotel_hotel_detail_detail_label_add_amenity: "편의시설 추가",
      hotel_hotel_detail_detail_type: "타입",
      hotel_hotel_detail_detail_group: "그룹",
      hotel_hotel_detail_detail_code: "코드",
      hotel_hotel_detail_detail_name: "이름",
      hotel_hotel_detail_detail_icon: "아이콘",
      hotel_hotel_detail_detail_date: "날짜",
      hotel_hotel_detail_detail_label_recommend_application: "추천 앱",
      hotel_hotel_detail_detail_application: "앱",
      hotel_hotel_detail_detail_label_add_recommend_app: "추천 앱 추가",
      hotel_hotel_detail_detail_empty_guide: "내용을 추가해주세요.",
      hotel_hotel_detail_detail_button_go_to_list: "목록으로 가기",
      hotel_hotel_detail_detail_button_delete: "삭제",
      hotel_hotel_detail_detail_button_add: "추가",
      hotel_hotel_detail_detail_button_cancel: "취소",
      hotel_hotel_detail_detail_button_edit: "수정",
      hotel_hotel_detail_detail_button_ok: "확인",
      hotel_hotel_detail_edit_validation_name: "* 호텔명을 입력해주세요.",
      hotel_hotel_detail_edit_validation_languageCode: "* 언어 코드를 선택해주세요.",
      hotel_hotel_detail_edit_validation_rating: "* 호텔 등급을 입력해주세요.",
      hotel_hotel_detail_edit_validation_address: "* 주소를 입력해주세요.",
      hotel_hotel_detail_edit_validation_phone: "* 전화번호를 입력해주세요.",
      hotel_hotel_detail_edit_validation_email: "* 호텔 이메일 주소를 입력해주세요.",
      hotel_hotel_detail_edit_validation_about: "* 객실 설명을 입력해주세요.",
      hotel_hotel_img_list_title: "호텔 이미지 목록",
      hotel_hotel_img_list_label_category: "카테고리",
      hotel_hotel_img_list_label_type: "타입",
      hotel_hotel_img_list_label_hotel_img: "호텔 이미지",
      hotel_hotel_img_list_label_date: "날짜",
      hotel_hotel_img_list_button_delete: "삭제",
      hotel_hotel_img_list_button_add: "추가",
      hotel_hotel_img_add_title: "호텔 이미지 추가",
      hotel_hotel_img_add_label_category: "카테고리",
      hotel_hotel_img_add_label_type: "타입",
      hotel_hotel_img_add_placeholder_select_box_choose: "선택",
      hotel_hotel_img_add_select_gallery: "갤러리",
      hotel_hotel_img_add_select_hotel_side: "호텔 사이드",
      hotel_hotel_img_add_select_hotel_detail: "호텔 상세",
      hotel_hotel_img_add_select_hotel_center: "호텔 메인",
      hotel_hotel_img_add_select_hotel_front: "호텔 프론트",
      hotel_hotel_img_add_select_hotel_background: "호텔 배경",
      hotel_hotel_img_add_select_logo_dark: "다크 로고",
      hotel_hotel_img_add_select_logo_light: "라이트 로고",
      hotel_hotel_img_add_select_logo_chat: "채팅 로고",
      hotel_hotel_img_add_select_card_key: "키 카드",
      hotel_hotel_img_add_select_direct: "직접 입력",
      hotel_hotel_img_add_label_hotel_img: "호텔 이미지",
      hotel_hotel_img_add_button_upload: "업로드",
      hotel_hotel_img_add_button_go_to_list: "목록으로 가기",
      hotel_hotel_img_add_button_add: "추가",
      hotel_hotel_img_add_validation_category: "* 카테고리를 선택해주세요.",
      hotel_hotel_img_add_validation_hotel_img: "* 호텔 이미지를 업로드해주세요.",
      hotel_hotel_img_detail_title: "호텔 이미지 정보",
      hotel_hotel_img_detail_label_category: "카테고리",
      hotel_hotel_img_detail_label_type: "타입",
      hotel_hotel_img_detail_label_type_gallery: "갤러리",
      hotel_hotel_img_detail_placeholder_select_box_choose: "선택",
      hotel_hotel_img_detail_select_direct: "직접 입력",
      hotel_hotel_img_detail_select_gallery: "갤러리",
      hotel_hotel_img_detail_select_hotel_side: "호텔 사이드",
      hotel_hotel_img_detail_select_hotel_detail: "호텔 상세",
      hotel_hotel_img_detail_select_hotel_center: "호텔 메인",
      hotel_hotel_img_detail_select_hotel_front: "호텔 프론트",
      hotel_hotel_img_detail_select_hotel_background: "호텔 배경",
      hotel_hotel_img_detail_select_logo_dark: "다크 로고",
      hotel_hotel_img_detail_select_logo_light: "라이트 로고",
      hotel_hotel_img_detail_select_logo_chat: "채팅 로고",
      hotel_hotel_img_detail_select_card_key: "키 카드",
      hotel_hotel_img_detail_label_hotel_img: "호텔 이미지",
      hotel_hotel_img_detail_button_upload: "업로드",
      hotel_hotel_img_detail_label_updated_at: "업데이트 시간",
      hotel_hotel_img_detail_button_go_to_list: "목록으로 가기",
      hotel_hotel_img_detail_button_delete: "삭제",
      hotel_hotel_img_detail_button_edit: "수정",
      hotel_hotel_img_detail_button_ok: "확인",
      hotel_hotel_img_detail_button_cancel: "취소",
      hotel_room_list_title: "객실 목록",
      hotel_room_list_placeholder_select_box_choose: "선택",
      hotel_room_list_select_content: "내용",
      hotel_room_list_placeholder_search: "검색",
      hotel_room_list_label_rate_plan: "요금제",
      hotel_room_list_label_room_type: "객실 타입",
      hotel_room_list_label_date: "날짜",
      hotel_room_list_button_delete: "삭제",
      hotel_room_list_button_add: "추가",
      hotel_room_list_guide_empty: "목록이 비어 있습니다.",
      hotel_room_add_title: "객실 추가",
      hotel_room_add_label_rate_plan: "요금제",
      hotel_room_add_label_room_type: "객실 타입",
      hotel_room_add_button_go_to_list: "목록으로 가기",
      hotel_room_add_button_add: "추가",
      hotel_room_add_validation_rate_plan: "* 요금제를 입력해주세요.",
      hotel_room_add_validation_room_type: "* 객실 타입을 입력해주세요.",
      hotel_room_detail_title: "객실 정보",
      hotel_room_detail_label_rate_plan: "요금제",
      hotel_room_detail_label_room_type: "객실 타입",
      hotel_room_detail_label_room_date: "날짜",
      hotel_room_detail_label_resource_information: "리소스 정보",
      hotel_room_detail_label_resource_information_resource_id: "리소스 ID",
      hotel_room_detail_label_resource_information_name: "리소스 명",
      hotel_room_detail_label_resource_information_unit_price: "단위 가격",
      hotel_room_detail_label_resource_information_currency: "통화",
      hotel_room_detail_label_resource_information_quantity: "수량",
      hotel_room_detail_label_resource_information_time_unit: "리소스의 기본 시간단위",
      hotel_room_detail_label_resource_information_min_rental_time: "최소 대여 시간",
      hotel_room_detail_label_resource_information_max_rental_time: "최대 대여 시간",
      hotel_room_detail_label_resource_information_min_time_between_rentals: "예약과 예약 사이의 최소 구분시간",
      hotel_room_detail_label_resource_information_min_hours_to_rental: "최소 예약과 대여 사이의 시간",
      hotel_room_detail_label_resource_information_start_quarters: "허용시작분기",
      hotel_room_detail_label_resource_information_start_hour: "대여 시작 시간",
      hotel_room_detail_label_resource_information_end_hour: "대여 종료 시간",
      hotel_room_detail_label_resource_information_unit_names: "리소스의 단위 이름들",
      hotel_room_detail_button_go_to_list: "목록으로 가기",
      hotel_room_detail_button_cancel: "취소",
      hotel_room_detail_button_delete: "삭제",
      hotel_room_detail_button_edit: "수정",
      hotel_room_detail_button_ok: "확인",
      hotel_room_edit_validation_rate_plan: "* 요금제를 입력해주세요.",
      hotel_room_edit_validation_room_type: "* 객실 타입을 입력해주세요.",
      hotel_room_detail_list_title: "객실 상세정보 목록",
      hotel_room_detail_list_placeholder_select_box_choose: "선택",
      hotel_room_detail_list_select_room_type: "객실 타입",
      hotel_room_detail_list_select_rate_plan: "요금제",
      hotel_room_detail_list_placeholder_search: "검색",
      hotel_room_detail_list_label_language_code: "언어 코드",
      hotel_room_detail_list_label_name: "이름",
      hotel_room_detail_list_label_view: "전망",
      hotel_room_detail_list_label_type: "타입",
      hotel_room_detail_list_label_room_type: "객실 타입",
      hotel_room_detail_list_label_rate_plan: "요금제",
      hotel_room_detail_list_label_date: "날짜",
      hotel_room_detail_list_button_delete: "삭제",
      hotel_room_detail_list_button_add: "추가",
      hotel_room_detail_list_guide_empty: "목록이 비어 있습니다.",
      hotel_room_detail_add_title: "객실 상세정보 추가",
      hotel_room_detail_add_label_language_code: "언어 코드",
      hotel_room_detail_add_placeholder_select_box_choose: "선택",
      hotel_room_detail_add_select_ko: "한국어",
      hotel_room_detail_add_select_en: "영어",
      hotel_room_detail_add_select_cn: "중국어",
      hotel_room_detail_add_select_jp: "일본어",
      hotel_room_detail_add_label_name: "이름",
      hotel_room_detail_add_label_view: "전망",
      hotel_room_detail_add_label_type: "타입",
      hotel_room_detail_add_label_bed_type: "침대 타입",
      hotel_room_detail_add_label_max_occupancy: "최대인원",
      hotel_room_detail_add_label_standard_occupancy: "최소인원",
      hotel_room_detail_add_label_size: "크기 (m²)",
      hotel_room_detail_add_label_checkin_time: "체크인 시간 (시간:분)",
      hotel_room_detail_add_label_checkout_time: "체크아웃 시간 (시간:분)",
      hotel_room_detail_add_label_about: "객실 설명",
      hotel_room_detail_add_label_more: "객실 추가 설명",
      hotel_room_detail_add_label_room_type: "객실 타입",
      hotel_room_detail_add_button_go_to_list: "목록으로 가기",
      hotel_room_detail_add_button_add: "추가",
      hotel_room_detail_add_validation_room_type: "* 객실 타입을 선택해주세요.",
      hotel_room_detail_add_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_room_detail_add_validation_name: "* 객실명을 입력해주세요.",
      hotel_room_detail_add_validation_view: "* 객실 전망을 입력해주세요.",
      hotel_room_detail_add_validation_type: "* 객실 타입을 입력해주세요.",
      hotel_room_detail_add_validation_bed_type: "* 침대 타입을 입력해주세요.",
      hotel_room_detail_add_validation_max_occupancy: "* 투숙객 최대인원을 입력해주세요.",
      hotel_room_detail_add_validation_standard_occupancy: "* 투숙객 최소인원을 입력해주세요.",
      hotel_room_detail_add_validation_checkin_time: "* 체크인 시간을 입력해주세요.",
      hotel_room_detail_add_validation_checkout_time: "* 체크아웃 시간을 입력해주세요.",
      hotel_room_detail_add_validation_about: "* 객실 설명을 입력해주세요.",
      hotel_room_detail_add_validation_more: "* 객실 추가 설명을 입력해주세요.",
      hotel_room_detail_detail_title: "객실 상세정보",
      hotel_room_detail_detail_label_details: "상세정보",
      hotel_room_detail_detail_label_name: "이름",
      hotel_room_detail_detail_label_language_code: "언어 코드",
      hotel_room_detail_detail_placeholder_select_box_choose: "선택",
      hotel_room_detail_detail_select_ko: "한국어",
      hotel_room_detail_detail_select_en: "영어",
      hotel_room_detail_detail_select_cn: "중국어",
      hotel_room_detail_detail_select_jp: "일본어",
      hotel_room_detail_detail_label_view: "전망",
      hotel_room_detail_detail_label_type: "타입",
      hotel_room_detail_detail_label_bed_type: "침대 타입",
      hotel_room_detail_detail_label_max_occupancy: "최대 인원",
      hotel_room_detail_detail_label_standard_occupancy: "최소 인원",
      hotel_room_detail_detail_label_size: "크기 (m²)",
      hotel_room_detail_detail_label_checkin_time: "체크인 시간 (시간:분)",
      hotel_room_detail_detail_label_checkout_time: "체크아웃 시간 (시간:분)",
      hotel_room_detail_detail_label_about: "객실 설명",
      hotel_room_detail_detail_label_more: "객실 추가 설명",
      hotel_room_detail_detail_label_date: "날짜",
      hotel_room_detail_detail_title_amenity: "편의시설",
      hotel_room_detail_detail_type: "타입",
      hotel_room_detail_detail_group: "그룹",
      hotel_room_detail_detail_code: "코드",
      hotel_room_detail_detail_name: "이름",
      hotel_room_detail_detail_icon: "아이콘",
      hotel_room_detail_detail_date: "날짜",
      hotel_room_detail_detail_title_add_amenity: "편의시설 추가",
      hotel_room_detail_detail_button_go_to_list: "목록으로 가기",
      hotel_room_detail_detail_button_add: "추가",
      hotel_room_detail_detail_button_delete: "삭제",
      hotel_room_detail_detail_button_cancel: "취소",
      hotel_room_detail_detail_button_edit: "수정",
      hotel_room_detail_detail_button_ok: "확인",
      hotel_room_detail_edit_validation_room_type: "* 객실 타입을 선택해주세요.",
      hotel_room_detail_edit_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_room_detail_edit_validation_name: "* 객실명을 입력해주세요.",
      hotel_room_detail_edit_validation_view: "* 객실 전망을 입력해주세요.",
      hotel_room_detail_edit_validation_type: "* 객실 타입을 입력해주세요.",
      hotel_room_detail_edit_validation_bed_type: "* 침대 타입을 입력해주세요.",
      hotel_room_detail_edit_validation_max_occupancy: "* 투숙객 최대인원을 입력해주세요.",
      hotel_room_detail_edit_validation_standard_occupancy: "* 투숙객 최소인원을 입력해주세요.",
      hotel_room_detail_edit_validation_size: "* 객실 크기를 입력하세요.",
      hotel_room_detail_edit_validation_checkin_time: "* 체크인 시간을 입력해주세요.",
      hotel_room_detail_edit_validation_checkout_time: "* 체크아웃 시간을 입력해주세요.",
      hotel_room_detail_edit_validation_about: "* 객실 설명을 입력해주세요.",
      hotel_room_detail_edit_validation_more: "* 객실 추가 설명을 입력해주세요.",
      hotel_room_img_list_title: "객실 이미지 목록",
      hotel_room_img_list_label_rate_plan: "요금제",
      hotel_room_img_list_label_room_type: "객실 타입",
      hotel_room_img_list_label_type: "타입",
      hotel_room_img_list_label_room_img: "객실 이미지",
      hotel_room_img_list_label_date: "날짜",
      hotel_room_img_list_button_delete: "삭제",
      hotel_room_img_list_button_add: "추가",
      hotel_room_img_add_title: "객실 이미지 추가",
      hotel_room_img_add_label_room_type: "객실 타입",
      hotel_room_img_add_label_type: "타입",
      hotel_room_img_add_placeholder_select_box_choose: "선택",
      hotel_room_img_add_select_gallery: "갤러리",
      hotel_room_img_add_select_room_detail: "객실 상세",
      hotel_room_img_add_select_room_center: "객실 메인",
      hotel_room_img_add_label_hotel_img: "객실 이미지",
      hotel_room_img_add_button_upload: "업로드",
      hotel_room_img_add_button_go_to_list: "목록으로 가기",
      hotel_room_img_add_button_add: "추가",
      hotel_room_img_add_validation_room_type: "* 객실 타입을 선택해주세요.",
      hotel_room_img_add_validation_image_file: "* 호텔 이미지를 업로드해주세요.",
      hotel_room_img_detail_title: "객실 이미지 정보",
      hotel_room_img_detail_label_room_type: "객실 타입",
      hotel_room_img_detail_placeholder_select_box_choose: "선택",
      hotel_room_img_detail_label_type: "타입",
      hotel_room_img_detail_select_gallery: "갤러리",
      hotel_room_img_detail_select_room_detail: "객실 상세",
      hotel_room_img_detail_select_room_center: "객실 메인",
      hotel_room_img_detail_label_room_img: "객실 이미지",
      hotel_room_img_detail_button_upload: "업로드",
      hotel_room_img_detail_label_updated_at: "업데이트 날짜",
      hotel_room_img_detail_button_go_to_list: "목록으로 가기",
      hotel_room_img_detail_button_delete: "삭제",
      hotel_room_img_detail_button_edit: "수정",
      hotel_room_img_detail_button_ok: "확인",
      hotel_room_img_detail_button_cancel: "취소",
      hotel_room_management_room_list_list_title: "객실 목록",
      hotel_room_management_room_list_list_select_floor: "층",
      hotel_room_management_room_list_list_select_cleaning_status: "청소 상태",
      hotel_room_management_room_list_list_select_room_type: "객실 타입",
      hotel_room_management_room_list_list_select_rooms_status: "객실 상태",
      hotel_room_management_room_list_list_select_fo_status: "FO 상태",
      hotel_room_management_room_list_list_select_reservation_status: "예약 상태",
      hotel_room_management_room_list_list_placeholder_room_no: "객실 번호",
      hotel_room_management_room_list_list_button_all: "전체",
      hotel_room_management_room_list_list_button_change: "변경",
      hotel_room_management_room_list_list_guide_empty: "목록이 비어 있습니다.",
      hotel_room_management_room_list_detail_title: "객실 상세",
      hotel_room_management_room_list_detail_label_floor: "층",
      hotel_room_management_room_list_detail_label_room_no: "객실 번호",
      hotel_room_management_room_list_detail_label_room_type: "객실 타입",
      hotel_room_management_room_list_detail_label_room_status: "객실 상태",
      hotel_room_management_room_list_detail_label_fo_status: "FO 상태",
      hotel_room_management_room_list_detail_label_reservation_status: "예약 상태",
      hotel_room_management_room_list_detail_label_created_at: "생성 시간",
      hotel_room_management_room_list_detail_button_status: "상태",
      hotel_room_management_room_list_detail_button_cleaning: "청소중",
      hotel_room_management_room_list_detail_label_history: "히스토리",
      hotel_room_management_room_list_detail_placeholder_select_box_choose: "선택",
      hotel_room_management_room_list_detail_select_housekeeping: "하우스키핑",
      hotel_room_management_room_list_detail_placeholder_search: "검색",
      hotel_room_management_room_list_detail_label_number: "번호",
      hotel_room_management_room_list_detail_label_housekeeping: "하우스키핑",
      hotel_room_management_room_list_detail_label_staff: "스태프",
      hotel_room_management_room_list_detail_label_date: "날짜",
      hotel_room_management_room_list_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_room_management_room_list_modal_title_status_message: "객실 상태",
      hotel_room_management_room_list_modal_status_clean: "청소 완료",
      hotel_room_management_room_list_modal_status_inspected: "검사 완료",
      hotel_room_management_room_list_modal_status_dirty: "청소 필요",
      hotel_room_management_room_list_modal_status_pick_up: "픽업",
      hotel_room_management_setting_detail_title: "설정",
      hotel_room_management_setting_detail_label_total_floors: "전체 층",
      hotel_room_management_setting_detail_label_total_number_of_rooms: "총 객실 수",
      hotel_room_management_setting_detail_label_floor_detail: "층 상세",
      hotel_room_management_setting_detail_label_number: "번호",
      hotel_room_management_setting_detail_label_number_of_rooms_on_the_floor: "층별 객실 수",
      hotel_room_management_setting_detail_label_number_of_rooms: "객실 번호",
      hotel_room_management_setting_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_room_management_setting_detail_button_edit: "수정",
      hotel_room_management_setting_edit_validation_total_floors: "* 전체 층을 입력해주세요.",
      hotel_room_management_setting_edit_validation_number_of_rooms: "* 객실 번호를 입력해주세요.",
      hotel_room_management_setting_edit_label_total_floors: "전체 층",
      hotel_room_management_setting_edit_label_total_number_of_rooms: "총 객실 수",
      hotel_room_management_setting_edit_label_floor_detail: "층 상세",
      hotel_room_management_setting_edit_label_number: "번호",
      hotel_room_management_setting_edit_label_number_of_rooms_on_the_floor: "층별 객실 수",
      hotel_room_management_setting_edit_label_number_of_rooms: "객실 번호",
      hotel_room_management_setting_edit_guide_empty: "목록이 비어 있습니다.",
      hotel_room_management_setting_edit_button_cancel: "취소",
      hotel_room_management_setting_edit_button_ok: "확인",
      hotel_package_list_title: "패키지 목록",
      hotel_package_list_label_type: "타입",
      hotel_package_list_label_code: "코드",
      hotel_package_list_label_name: "이름",
      hotel_package_list_label_description: "설명",
      hotel_package_list_label_date: "날짜",
      hotel_package_list_button_delete: "삭제",
      hotel_package_list_button_add: "추가",
      hotel_package_list_guide_empty: "목록이 비어 있습니다",
      hotel_package_add_title: "패키지 추가",
      hotel_package_add_label_type: "타입",
      hotel_package_add_placeholder_select_box_choose: "선택",
      hotel_package_add_placeholder_select_box_package: "패키지",
      hotel_package_add_placeholder_select_box_transaction: "결제",
      hotel_package_add_label_code: "코드",
      hotel_package_add_label_name: "이름",
      hotel_package_add_label_description: "설명",
      hotel_package_add_button_go_to_list: "목록으로 가기",
      hotel_package_add_button_add: "추가",
      hotel_package_add_validation_type: "* 패키지 타입을 선택해주세요.",
      hotel_package_add_validation_code: "* 패키지 코드를 입력해주세요.",
      hotel_package_add_validation_name: "* 패키지명을 입력해주세요.",
      hotel_package_add_validation_description: "* 패키지 설명을 입력해주세요.",
      hotel_package_detail_title: "패키지 정보",
      hotel_package_detail_label_type: "타입",
      hotel_package_detail_label_code: "코드",
      hotel_package_detail_label_name: "이름",
      hotel_package_detail_label_description: "설명",
      hotel_package_detail_label_updated_at: "날짜",
      hotel_package_detail_button_go_to_list: "목록으로 가기",
      hotel_package_detail_button_cancel: "취소",
      hotel_package_detail_button_delete: "삭제",
      hotel_package_detail_button_edit: "수정",
      hotel_package_detail_button_ok: "확인",
      hotel_package_edit_validation_type: "* 패키지 타입을 선택해주세요.",
      hotel_package_edit_validation_code: "* 패키지 코드를 입력해주세요.",
      hotel_package_edit_validation_name: "* 패키지명을 입력해주세요.",
      hotel_package_edit_validation_description: "* 패키지 설명을 입력해주세요.",
      hotel_package_detail_list_title: "패키지 상세정보 목록",
      hotel_package_detail_list_label_code: "코드",
      hotel_package_detail_list_label_name: "이름",
      hotel_package_detail_list_label_language_code: "언어 코드",
      hotel_package_detail_list_label_date: "날짜",
      hotel_package_detail_list_button_delete: "삭제",
      hotel_package_detail_list_button_add: "추가",
      hotel_package_detail_list_guide_empty: "목록이 비어 있습니다",
      hotel_package_detail_add_title: "패키지 상세정보 추가",
      hotel_package_detail_add_label_name: "이름",
      hotel_package_detail_add_label_package_code: "패키지 코드",
      hotel_package_detail_add_placeholder_select_box_choose: "선택",
      hotel_package_detail_add_label_language_code: "언어 코드",
      hotel_package_detail_add_select_ko: "한국어",
      hotel_package_detail_add_select_en: "영어",
      hotel_package_detail_add_select_cn: "중국어",
      hotel_package_detail_add_select_jp: "일본어",
      hotel_package_detail_add_label_description: "설명",
      hotel_package_detail_add_label_content: "내용",
      hotel_package_detail_add_label_package_img: "패키지 이미지",
      hotel_package_detail_add_button_upload: "업로드",
      hotel_package_detail_add_button_go_to_list: "목록으로 가기",
      hotel_package_detail_add_button_add: "추가",
      hotel_package_detail_add_validation_package_code: "* 패키지 코드를 선택해주세요.",
      hotel_package_detail_add_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_package_detail_add_validation_description: "* 패키지 설명을 입력해주세요.",
      hotel_package_detail_add_validation_image_file: "* 패키지 이미지를 업로드해주세요.",
      hotel_package_detail_add_validation_content: "* 패키지 내용을 입력해주세요.",
      hotel_package_detail_detail_title: "패키지 상세정보",
      hotel_package_detail_detail_label_date: "업데이트 날짜",
      hotel_package_detail_detail_label_name: "이름",
      hotel_package_detail_detail_label_package_code: "패키지 코드",
      hotel_package_detail_detail_placeholder_select_box_choose: "선택",
      hotel_package_detail_detail_label_language_code: "언어 코드",
      hotel_package_detail_detail_select_ko: "한국어",
      hotel_package_detail_detail_select_en: "영어",
      hotel_package_detail_detail_select_cn: "중국어",
      hotel_package_detail_detail_select_jp: "일본어",
      hotel_package_detail_detail_label_description: "설명",
      hotel_package_detail_detail_label_content: "내용",
      hotel_package_detail_detail_label_package_img: "패키지 이미지",
      hotel_package_detail_detail_button_upload: "업로드",
      hotel_package_detail_detail_button_go_to_list: "목록으로 가기",
      hotel_package_detail_detail_button_cancel: "취소",
      hotel_package_detail_detail_button_delete: "삭제",
      hotel_package_detail_detail_button_edit: "수정",
      hotel_package_detail_detail_button_ok: "확인",
      hotel_package_detail_edit_validation_package_code: "* 패키지 코드를 선택해주세요.",
      hotel_package_detail_edit_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_package_detail_edit_validation_description: "* 패키지 설명을 입력해주세요.",
      hotel_package_detail_edit_validation_image_file: "* 패키지 이미지를 업로드해주세요.",
      hotel_package_detail_edit_validation_content: "* 패키지 내용을 입력해주세요.",
      hotel_hotel_valet_setting_detail_title: "발렛파킹 설정",
      hotel_hotel_valet_setting_detail_label_date: "업데이트 날짜",
      hotel_hotel_valet_setting_detail_label_parking: "주차",
      hotel_hotel_valet_setting_detail_label_parking_availability: "주차 가능",
      hotel_hotel_valet_setting_detail_button_yes: "예",
      hotel_hotel_valet_setting_detail_button_no: "아니오",
      hotel_hotel_valet_setting_detail_label_time: "시간",
      hotel_hotel_valet_setting_detail_label_delay_time: "지연 시간",
      hotel_hotel_valet_setting_detail_button_15: "15분",
      hotel_hotel_valet_setting_detail_button_30: "30분",
      hotel_hotel_valet_setting_detail_button_45: "45분",
      hotel_hotel_valet_setting_detail_button_60: "60분",
      hotel_hotel_valet_setting_detail_button_as_soon_as: "빠른 시간 내",
      hotel_hotel_valet_setting_detail_label_calculation_time: "대기 요청 시간 (시간:분)",
      hotel_hotel_valet_setting_detail_label_vehicle_time: "차량 반환 시간 (시간:분)",
      hotel_hotel_valet_setting_detail_label_request_caution_time: "요청 주의 시간 (분)",
      hotel_hotel_valet_setting_detail_label_delay_available_time: "지연 적용 시간 (분)",
      hotel_hotel_valet_setting_detail_label_requirement_time: "편집 가능한 최소 요구사항 시간 (분)",
      hotel_hotel_valet_setting_detail_label_log_active_hours: "활동 시간 기록 (시)",
      hotel_hotel_valet_setting_detail_label_return_additional_charge: "회차 추가 요금",
      hotel_hotel_valet_setting_detail_label_return_push_message: "반환 메시지 알림",
      hotel_hotel_valet_setting_detail_label_return_charge_count: "회차 추가 요금 적용 횟수",
      hotel_hotel_valet_setting_detail_label_return_repeat_message: "회차 요청 시 반복 패널티",
      hotel_hotel_valet_setting_detail_button_edit: "수정",
      hotel_hotel_valet_setting_edit_title: "발렛파킹 설정",
      hotel_hotel_valet_setting_edit_label_date: "업데이트 날짜",
      hotel_hotel_valet_setting_edit_label_parking: "주차",
      hotel_hotel_valet_setting_edit_label_parking_availability: "주차 가능",
      hotel_hotel_valet_setting_edit_button_yes: "예",
      hotel_hotel_valet_setting_edit_button_no: "아니오",
      hotel_hotel_valet_setting_edit_label_time: "시간",
      hotel_hotel_valet_setting_edit_label_delay_time: "지연 시간",
      hotel_hotel_valet_setting_edit_placeholder_delay_time: "ex) 80",
      hotel_hotel_valet_setting_edit_label_calculation_time: "대기 요청 시간",
      hotel_hotel_valet_setting_edit_placeholder_calculation_time: "ex) 24:00",
      hotel_hotel_valet_setting_edit_label_vehicle_time: "차량 회차 시간",
      hotel_hotel_valet_setting_edit_placeholder_vehicle_time: "ex) 80",
      hotel_hotel_valet_setting_edit_button_ok: "확인",
      hotel_hotel_valet_setting_edit_validation_parking_availability: "* 주차 가능 상태를 선택해주세요.",
      hotel_hotel_valet_setting_edit_validation_waiting_calculation_time: "* 출차대기 요청 시간을 입력해주세요.",
      hotel_hotel_valet_setting_edit_validation_vehicle_time: "* 차량 반환 시간을 입력해주세요.",
      hotel_hotel_valet_setting_edit_validation_request_caution_time: "* 요청 주의 시간을 입력해주세요.",
      hotel_hotel_valet_setting_edit_validation_requirement_time: "* 편집 가능한 최소 요구사항 시간을 입력해주세요.",
      hotel_hotel_valet_setting_edit_validation_charge_log_active_hours: "* 활동 시간 기록을 입력해주세요.",
      hotel_hotel_valet_setting_edit_validation_return_push_message: "* 반환 메시지 알림 상태를 선택해주세요.",
      hotel_hotel_self_setting_detail_title: "셀프주차 설정",
      hotel_hotel_self_setting_detail_label_date: "업데이트 날짜",
      hotel_hotel_self_setting_detail_label_parking: "주차",
      hotel_hotel_self_setting_detail_label_parking_availability: "주차 가능",
      hotel_hotel_self_setting_detail_button_yes: "예",
      hotel_hotel_self_setting_detail_button_no: "아니오",
      hotel_hotel_self_setting_detail_button_edit: "수정",
      hotel_hotel_self_setting_edit_title: "셀프주차 설정",
      hotel_hotel_self_setting_edit_label_date: "업데이트 날짜",
      hotel_hotel_self_setting_edit_label_parking: "주차",
      hotel_hotel_self_setting_edit_label_parking_availability: "주차 가능",
      hotel_hotel_self_setting_edit_button_yes: "예",
      hotel_hotel_self_setting_edit_button_no: "아니오",
      hotel_hotel_self_setting_edit_placeholder_vehicle_time: "ex) 80",
      hotel_hotel_self_setting_edit_button_ok: "확인",
      hotel_hotel_service_setting_detail_title: "인하우스 메뉴 설정",
      hotel_hotel_service_setting_detail_label_date: "업데이트 날짜",
      hotel_hotel_service_setting_detail_button_yes: "예",
      hotel_hotel_service_setting_detail_button_no: "아니오",
      hotel_hotel_service_setting_detail_label_housekeeping: "하우스키핑",
      hotel_hotel_service_setting_detail_label_housekeeping_dnd: "방해 금지",
      hotel_hotel_service_setting_detail_label_housekeeping_green_campaign: "그린 캠페인",
      hotel_hotel_service_setting_detail_label_housekeeping_emergency_service: "응급",
      hotel_hotel_service_setting_detail_label_housekeeping_make_up_room: "객실 청소",
      hotel_hotel_service_setting_detail_label_housekeeping_turndown_service: "객실 정돈",
      hotel_hotel_service_setting_detail_label_concierge: "컨시어지",
      hotel_hotel_service_setting_detail_label_concierge_luggage_service: "수하물",
      hotel_hotel_service_setting_detail_label_concierge_parcel_service: "택배",
      hotel_hotel_service_setting_detail_label_concierge_taxi_call_service: "택시",
      hotel_hotel_service_setting_detail_label_guest_service: "고객 서비스",
      hotel_hotel_service_setting_detail_label_guest_service_amenity_service: "어메니티",
      hotel_hotel_service_setting_detail_label_guest_service_laundry_service: "세탁",
      hotel_hotel_service_setting_detail_label_guest_service_in_room_dining: "인 룸 다이닝",
      hotel_hotel_service_setting_detail_label_guest_service_ironing_service: "다림질",
      hotel_hotel_service_setting_detail_label_etc: "그 외",
      hotel_hotel_service_setting_detail_label_room_to_room: "룸투룸",
      hotel_hotel_service_setting_detail_label_share_key: "공유 키",
      hotel_hotel_service_setting_detail_label_in_room_control: "객실 제어",
      hotel_hotel_service_setting_detail_button_edit: "수정",
      hotel_hotel_service_setting_detail_button_cancel: "취소",
      hotel_hotel_service_setting_detail_button_ok: "확인",
      hotel_service_description_list_title: "서비스 설명",
      hotel_service_description_list_button_add: "추가",
      hotel_service_description_list_button_delete: "삭제",
      hotel_service_description_list_button_edit: "수정",
      hotel_service_description_list_guide_add: "서비스 설명을 추가하세요",
      hotel_service_description_add_title: "서비스 설명 추가",
      hotel_service_description_add_select_choose: "타입을 선택하세요",
      hotel_service_description_add_select_in_room_dining: "인 룸 다이닝",
      hotel_service_description_add_select_luggage_service: "수하물 서비스",
      hotel_service_description_add_select_parcel_service: "택배 서비스",
      hotel_service_description_add_select_taxi_call: "택시",
      hotel_service_description_add_select_amenity_service: "어메니티 서비스",
      hotel_service_description_add_select_ironing_service: "다림질 서비스",
      hotel_service_description_add_select_laundry_service: "세탁 서비스",
      hotel_service_description_add_select_dnd: "방해 금지",
      hotel_service_description_add_select_green_campaign: "그린 캠페인",
      hotel_service_description_add_select_emergency_service: "응급 서비스",
      hotel_service_description_add_toolbar_bold: "굵게",
      hotel_service_description_add_toolbar_italic: "이탤릭체",
      hotel_service_description_add_toolbar_underline: "밑줄",
      hotel_service_description_add_toolbar_remove_font_style: "글씨체 제거",
      hotel_service_description_add_toolbar_font_family: "글꼴 모음",
      hotel_service_description_add_toolbar_font_size: "글꼴 크기",
      hotel_service_description_add_toolbar_recent_color: "최근 사용 색상",
      hotel_service_description_add_toolbar_recent_more_color: "색상 더 보기",
      hotel_service_description_add_toolbar_unordered_list: "미주문 목록",
      hotel_service_description_add_toolbar_ordered_list: "주문 목록",
      hotel_service_description_add_toolbar_paragraph: "단락",
      hotel_service_description_add_toolbar_line_height: "줄 높이",
      hotel_service_description_add_placeholder_content: "내용을 입력하세요",
      hotel_service_description_add_button_cancel: "취소",
      hotel_service_description_add_button_ok: "확인",
      hotel_service_description_add_validation_type: "* 타입을 선택해주세요.",
      hotel_service_description_add_validation_content: "* 내용을 입력해주세요.",
      hotel_service_description_edit_title: "서비스 설명 수정",
      hotel_service_description_edit_select_kr: "한국어",
      hotel_service_description_edit_select_en: "영어",
      hotel_service_description_edit_select_cn: "중국어",
      hotel_service_description_edit_select_jp: "일본어",
      hotel_service_description_edit_toolbar_bold: "굵게",
      hotel_service_description_edit_toolbar_italic: "이탤릭체",
      hotel_service_description_edit_toolbar_underline: "밑줄",
      hotel_service_description_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_service_description_edit_toolbar_font_family: "글꼴 모음",
      hotel_service_description_edit_toolbar_font_size: "글꼴 크기",
      hotel_service_description_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_service_description_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_service_description_edit_toolbar_unordered_list: "미주문 목록",
      hotel_service_description_edit_toolbar_ordered_list: "주문 목록",
      hotel_service_description_edit_toolbar_paragraph: "단락",
      hotel_service_description_edit_toolbar_code_view: "코드 보기",
      hotel_service_description_edit_toolbar_line_height: "줄 높이",
      hotel_service_description_edit_button_append: "추가",
      hotel_service_description_edit_button_remove: "제거",
      hotel_service_description_edit_button_cancel: "취소",
      hotel_service_description_edit_button_ok: "확인",
      hotel_service_description_edit_validation_language_code: "* 중복되지 않는 언어 코드를 선택해주세요.",
      hotel_service_description_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_hotel_beacon_list_title: "비콘 목록",
      hotel_hotel_beacon_list_placeholder_search: "검색",
      hotel_hotel_beacon_list_label_uuid: "UUID",
      hotel_hotel_beacon_list_label_code: "코드",
      hotel_hotel_beacon_list_label_type: "타입",
      hotel_hotel_beacon_list_label_content: "내용",
      hotel_hotel_beacon_list_label_date: "날짜",
      hotel_hotel_beacon_list_guide_empty: "목록이 비어 있습니다",
      hotel_hotel_beacon_list_button_delete: "삭제",
      hotel_hotel_beacon_list_button_add: "추가",
      hotel_hotel_beacon_add_title: "비콘 추가",
      hotel_hotel_beacon_add_label_uuid: "UUID",
      hotel_hotel_beacon_add_label_code: "코드",
      hotel_hotel_beacon_add_label_type: "타입",
      hotel_hotel_beacon_add_label_content: "내용",
      hotel_hotel_beacon_add_button_go_to_list: "목록으로 가기",
      hotel_hotel_beacon_add_button_add: "추가",
      hotel_hotel_beacon_add_validation_uuid: "* UUID를 입력해주세요.",
      hotel_hotel_beacon_add_validation_code: "* 코드를 입력해주세요.",
      hotel_hotel_beacon_add_validation_type: "* 타입을 입력해주세요.",
      hotel_hotel_beacon_add_validation_content: "* 내용을 입력해주세요.",
      hotel_hotel_beacon_detail_title: "비콘 정보",
      hotel_hotel_beacon_detail_label_uuid: "UUID",
      hotel_hotel_beacon_detail_label_code: "코드",
      hotel_hotel_beacon_detail_label_type: "타입",
      hotel_hotel_beacon_detail_label_content: "내용",
      hotel_hotel_beacon_detail_label_date: "날짜",
      hotel_hotel_beacon_detail_button_cancel: "취소",
      hotel_hotel_beacon_detail_button_go_to_list: "목록으로 가기",
      hotel_hotel_beacon_detail_button_delete: "삭제",
      hotel_hotel_beacon_detail_button_edit: "수정",
      hotel_hotel_beacon_edit_title: "비콘 정보",
      hotel_hotel_beacon_edit_label_uuid: "UUID",
      hotel_hotel_beacon_edit_label_code: "코드",
      hotel_hotel_beacon_edit_label_type: "타입",
      hotel_hotel_beacon_edit_label_content: "내용",
      hotel_hotel_beacon_edit_label_date: "날짜",
      hotel_hotel_beacon_edit_button_cancel: "취소",
      hotel_hotel_beacon_edit_button_delete: "삭제",
      hotel_hotel_beacon_edit_button_ok: "확인",
      hotel_hotel_beacon_edit_validation_uuid: "* UUID를 입력해주세요.",
      hotel_hotel_beacon_edit_validation_code: "* 코드를 입력해주세요.",
      hotel_hotel_beacon_edit_validation_type: "* 타입을 입력해주세요.",
      hotel_hotel_beacon_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_hotel_currency_list_title: "통화 목록",
      hotel_hotel_currency_list_guide_exchange_rate: "환율(KRW):",
      hotel_hotel_currency_list_guide_won: "{{data}} 원 / {{data}}",
      hotel_hotel_currency_list_placeholder_currency: "통화 ( 모두 )",
      hotel_hotel_currency_list_select_display: "통화 표시",
      hotel_hotel_currency_list_select_name: "통화명",
      hotel_hotel_currency_list_placeholder_search: "검색",
      hotel_hotel_currency_list_label_display: "통화 표시",
      hotel_hotel_currency_list_label_name: "통화명",
      hotel_hotel_currency_list_label_exchange_rate: "환율",
      hotel_hotel_currency_list_label_date: "날짜",
      hotel_hotel_currency_list_label_syne: "동기화",
      hotel_hotel_currency_list_guide_empty: "목록이 비어 있습니다.",
      hotel_hotel_currency_list_button_syne: "동기화",
      hotel_hotel_currency_list_button_delete: "삭제",
      hotel_hotel_currency_list_button_add: "추가",
      hotel_hotel_currency_add_title: "통화 추가",
      hotel_hotel_currency_add_guide_currency: "환율(KRW):",
      hotel_hotel_currency_add_guide_won: "{{data}} 원 / {{data}}",
      hotel_hotel_currency_add_select_display: "통화 표시",
      hotel_hotel_currency_add_select_name: "통화명",
      hotel_hotel_currency_add_label_exchange_rate: "환율",
      hotel_hotel_currency_add_button_go_to_list: "목록으로 가기",
      hotel_hotel_currency_add_button_add: "추가",
      hotel_hotel_currency_add_validation_currency_display: "* 통화 표시를 입력해주세요.",
      hotel_hotel_currency_add_validation_currency_name: "* 통화명을 입력해주세요.",
      hotel_hotel_currency_add_validation_exchange_rate: "* 환율을 입력해주세요.",
      hotel_hotel_membership_list_title: "회원권 타입 목록",
      hotel_hotel_membership_list_placeholder_search: "검색",
      hotel_hotel_membership_list_label_membership_type: "회원권 타입",
      hotel_hotel_membership_list_label_description: "내용",
      hotel_hotel_membership_list_label_date: "날짜",
      hotel_hotel_membership_list_guide_empty: "목록이 비어 있습니다.",
      hotel_hotel_membership_list_button_delete: "삭제",
      hotel_hotel_membership_list_button_sync: "동기화",
      hotel_hotel_membership_list_button_add: "추가",
      hotel_hotel_membership_add_title: "회원 타입 추가",
      hotel_hotel_membership_add_label_membership_type: "회원권 타입",
      hotel_hotel_membership_add_label_description: "내용",
      hotel_hotel_membership_add_button_go_to_list: "목록으로 가기",
      hotel_hotel_membership_add_button_add: "추가",
      hotel_hotel_membership_add_validation_membership_type: "* 회원권 타입을 입력해주세요.",
      hotel_hotel_membership_add_validation_description: "* 내용을 입력해주세요.",
      hotel_hotel_membership_detail_title: "회원권 타입 정보",
      hotel_hotel_membership_detail_label_membership_type: "회원권 타입",
      hotel_hotel_membership_detail_label_description: "내용",
      hotel_hotel_membership_detail_label_date: "날짜",
      hotel_hotel_membership_detail_button_go_to_list: "목록으로 가기",
      hotel_hotel_membership_detail_button_delete: "삭제",
      hotel_hotel_membership_detail_button_edit: "수정",
      hotel_hotel_membership_edit_title: "회원권 타입 정보",
      hotel_hotel_membership_edit_label_membership_type: "회원권 타입",
      hotel_hotel_membership_edit_label_description: "내용",
      hotel_hotel_membership_edit_label_date: "날짜",
      hotel_hotel_membership_edit_button_cancel: "취소",
      hotel_hotel_membership_edit_button_delete: "삭제",
      hotel_hotel_membership_edit_button_ok: "확인",
      hotel_hotel_membership_edit_validation_membership_type: "* 회원권 타입을 입력해주세요.",
      hotel_hotel_membership_edit_validation_description: "* 내용을 입력해주세요.",
      hotel_Title_list_title: "호칭 목록",
      hotel_Title_list_placeholder_search: "검색",
      hotel_Title_list_label_title: "호칭",
      hotel_Title_list_label_description: "내용",
      hotel_Title_list_label_date: "날짜",
      hotel_Title_list_guide_empty: "목록이 비어 있습니다.",
      hotel_Title_list_button_delete: "삭제",
      hotel_Title_list_button_sync: "동기화",
      hotel_Title_list_button_add: "추가",
      hotel_Title_add_title: "추가",
      hotel_Title_add_label_title: "호칭",
      hotel_Title_add_label_description: "내용",
      hotel_Title_add_button_go_to_list: "목록으로 가기",
      hotel_Title_add_button_add: "추가",
      hotel_Title_detail_title: "호칭 정보",
      hotel_Title_detail_label_title: "호칭",
      hotel_Title_detail_label_description: "내용",
      hotel_Title_detail_label_date: "날짜",
      hotel_Title_detail_button_cancel: "취소",
      hotel_Title_detail_button_go_to_list: "목록으로 가기",
      hotel_Title_detail_button_delete: "삭제",
      hotel_hotel_card_type_list_title: "카드 타입",
      hotel_hotel_card_type_list_label_ok: "번호",
      hotel_hotel_card_type_list_label_value: "값",
      hotel_hotel_card_type_list_label_description: "내용",
      hotel_hotel_payment_list_title: "결제 목록",
      hotel_hotel_payment_list_placeholder_user_name: "사용자 이름",
      hotel_hotel_payment_list_placeholder_search: "검색",
      hotel_hotel_payment_list_label_no: "번호",
      hotel_hotel_payment_list_label_reservation_id: "예약 ID",
      hotel_hotel_payment_list_label_user_name: "사용자 이름",
      hotel_hotel_payment_list_label_long_info: "롱 정보",
      hotel_hotel_payment_list_label_amount: "금액",
      hotel_hotel_payment_list_label_unit: "단위",
      hotel_hotel_payment_list_label_pg_status_code: "PG 상태 코드",
      hotel_hotel_payment_list_label_pg_status_message: "PG 상태 메시지",
      hotel_hotel_payment_list_label_date: "날짜",
      hotel_hotel_payment_list_guide_empty: "목록이 비어 있습니다",
      hotel_hotel_payment_detail_label_order_no: "주문 번호",
      hotel_hotel_payment_detail_label_user: "사용자",
      hotel_hotel_payment_detail_label_user_profile_id: "프로필 ID",
      hotel_hotel_payment_detail_label_user_title: "호칭",
      hotel_hotel_payment_detail_label_user_first_name: "이름",
      hotel_hotel_payment_detail_label_user_last_name: "성",
      hotel_hotel_payment_detail_label_user_email: "이메일",
      hotel_hotel_payment_detail_label_user_date: "날짜",
      hotel_hotel_payment_detail_label_reservation: "예약",
      hotel_hotel_payment_detail_label_reservation_confirmation_no: "예약 번호",
      hotel_hotel_payment_detail_label_reservation_reservation_id: "예약 ID",
      hotel_hotel_payment_detail_label_reservation_reservation_status: "예약 상태",
      hotel_hotel_payment_detail_label_reservation_check_in_date: "체크인 날짜",
      hotel_hotel_payment_detail_label_reservation_check_out_date: "체크아웃 날짜",
      hotel_hotel_payment_detail_label_payment: "결제",
      hotel_hotel_payment_detail_button_go_to_list: "목록으로 가기",
      hotel_hotel_payment_detail_button_go_to_log: "로그 목록으로 가기",
      hotel_hotel_payment_detail_label_payment_order_no: "주문 번호",
      hotel_hotel_payment_detail_label_payment_reservation_id: "예약 ID",
      hotel_hotel_payment_detail_label_payment_long_info: "롱 정보",
      hotel_hotel_payment_detail_label_payment_folio_view_no: "폴리오 조회 번호",
      hotel_hotel_payment_detail_label_payment_amount: "금액",
      hotel_hotel_payment_detail_label_payment_unit: "유닛",
      hotel_hotel_payment_detail_label_payment_pg_order_no: "PG 주문 번호",
      hotel_hotel_payment_detail_label_payment_pg_status: "PG 상태",
      hotel_hotel_payment_detail_label_payment_pg_status_code: "PG 상태 코드",
      hotel_hotel_payment_detail_label_payment_pg_status_message: "PG 상태 메시지",
      hotel_hotel_payment_detail_label_payment_pg_amount: "PG 금액",
      hotel_hotel_payment_detail_label_payment_pg_unit: "PG 단위",
      hotel_hotel_payment_detail_label_payment_pg_billing_no: "PG 결제 번호",
      hotel_hotel_payment_detail_label_payment_confirmed_at: "확인 시간",
      hotel_hotel_payment_detail_label_payment_created_at: "생성 시간",
      hotel_hotel_payment_detail_label_payment_updated_at: "업데이트 시간",
      hotel_hotel_payment_detail_label_schedule: "스케줄",
      hotel_hotel_payment_detail_label_schedule_rule_type: "규칙 타입",
      hotel_hotel_payment_detail_label_schedule_name: "이름",
      hotel_hotel_payment_detail_label_schedule_description: "설명",
      hotel_hotel_payment_detail_label_schedule_rate_code: "요금 코드",
      hotel_hotel_payment_detail_label_schedule_begin_date: "시작 날짜",
      hotel_hotel_payment_detail_label_schedule_end_date: "최종 날짜",
      hotel_hotel_payment_detail_label_schedule_day_of_the_week: "요일",
      hotel_hotel_payment_detail_label_schedule_created_at: "생성 시간",
      hotel_hotel_payment_detail_label_schedule_updated_at: "업데이트 시간",
      hotel_hotel_payment_detail_label_reservation_rule: "예약 규칙",
      hotel_hotel_payment_detail_label_rule_name: "규칙명",
      hotel_hotel_payment_detail_label_description: "설명",
      hotel_hotel_payment_detail_label_reservation_amount: "예약 금액",
      hotel_hotel_payment_detail_label_day_before_arrival: "체크인 ~일 전",
      hotel_hotel_payment_detail_label_before_time: "체크인 ~시간 전",
      hotel_hotel_payment_detail_label_deposit_rule: "보증금 규칙",
      hotel_hotel_payment_detail_label_type: "타입",
      hotel_hotel_payment_detail_label_deposit_amount: "보증금 금액",
      hotel_hotel_payment_detail_label_cancellation_rule: "취소 규칙",
      hotel_hotel_payment_detail_label_no_show_rule: "노쇼 규칙",
      hotel_hotel_payment_detail_label_source_code: "소스 코드",
      hotel_hotel_payment_detail_label_penalty_amount: "패널티 금액",
      hotel_hotel_payment_detail_label_guarantee_type: "게런테 타입",
      hotel_hotel_payment_detail_label_posting_rule: "청구 규칙",
      hotel_payment_history_log_list_title: "결제 로그 리스트",
      hotel_payment_history_log_list_label_no: "번호",
      hotel_payment_history_log_list_label_type: "타입",
      hotel_payment_history_log_list_label_method: "방법",
      hotel_payment_history_log_list_label_path: "경로",
      hotel_payment_history_log_list_label_status: "상태",
      hotel_payment_history_log_list_label_success: "성공",
      hotel_payment_history_log_list_label_created_at: "생성 시간",
      hotel_payment_history_log_list_guide_empty: "목록이 비어 있습니다",
      hotel_payment_history_log_list_button_go_to_detail: "상세로 가기",
      hotel_payment_history_log_detail_title: "결제 로그 상세정보",
      hotel_payment_history_log_detail_label_id: "ID",
      hotel_payment_history_log_detail_label_hotel_id: "호텔 ID",
      hotel_payment_history_log_detail_label_payment_id: "결제 ID",
      hotel_payment_history_log_detail_label_transfer_code: "통신 코드",
      hotel_payment_history_log_detail_label_type: "타입",
      hotel_payment_history_log_detail_label_method: "방법",
      hotel_payment_history_log_detail_label_path: "경로",
      hotel_payment_history_log_detail_label_header: "머리말",
      hotel_payment_history_log_detail_label_param: "변수",
      hotel_payment_history_log_detail_label_body: "본문",
      hotel_payment_history_log_detail_label_status: "상태",
      hotel_payment_history_log_detail_label_session: "세션",
      hotel_payment_history_log_detail_label_created_at: "생성 시간",
      hotel_payment_history_log_detail_button_go_to_list: "목록으로 가기",
      hotel_hotel_reservation_email_edit_title: "예약 이메일 서식",
      hotel_hotel_reservation_email_edit_select_kr: "한국어",
      hotel_hotel_reservation_email_edit_select_en: "영어",
      hotel_hotel_reservation_email_edit_select_cn: "중국어",
      hotel_hotel_reservation_email_edit_select_jp: "일본어",
      hotel_hotel_reservation_email_edit_select_ms: "말레이어",
      hotel_hotel_reservation_email_edit_button_remove: "제거",
      hotel_hotel_reservation_email_edit_button_append: "추가",
      hotel_hotel_reservation_email_edit_button_save: "저장",
      hotel_hotel_reservation_email_edit_toolbar_bold: "굵게",
      hotel_hotel_reservation_email_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_reservation_email_edit_toolbar_underline: "밑줄",
      hotel_hotel_reservation_email_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_reservation_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_reservation_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_reservation_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_reservation_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_reservation_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_reservation_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_reservation_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_reservation_email_edit_toolbar_table: "테이블",
      hotel_hotel_reservation_email_edit_toolbar_unordered_list: "미주문 목록",
      hotel_hotel_reservation_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_reservation_email_edit_toolbar_paragraph: "단락",
      hotel_hotel_reservation_email_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_reservation_email_edit_toolbar_picture: "이미지",
      hotel_hotel_reservation_email_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_reservation_email_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_reservation_email_edit_toolbar_help: "도움말",
      hotel_hotel_reservation_email_edit_toolbar_tip: "팁",
      hotel_hotel_reservation_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_reservation_email_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_hotel_reservation_email_edit_validation_language_code: "* 중복되지 않는 언어 코드를 선택해주세요.",
      hotel_hotel_reservation_email_detail_title: "예약 이메일 문자",
      hotel_hotel_reservation_email_detail_label_confurmation_number: "예약 번호",
      hotel_hotel_reservation_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_email_detail_label_reservation_id: "예약 ID",
      hotel_hotel_reservation_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_email_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_reservation_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_email_detail_label_departure_date: "출발 날짜",
      hotel_hotel_reservation_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_email_detail_label_room_type: "객실 타입",
      hotel_hotel_reservation_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_email_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_reservation_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_email_detail_label_room_rate: "객실 요금",
      hotel_hotel_reservation_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_email_detail_label_total_rate: "총 요금",
      hotel_hotel_reservation_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_email_detail_label_current_balance: "이용 금액",
      hotel_hotel_reservation_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_email_detail_label_room_number: "객실 번호",
      hotel_hotel_reservation_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_email_detail_label_adults: "성인",
      hotel_hotel_reservation_email_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_email_detail_label_children: "유아/어린이",
      hotel_hotel_reservation_email_detail_example_children: "{{children}}",
      hotel_hotel_reservation_email_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_reservation_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_email_detail_label_name_title: "이름 호칭",
      hotel_hotel_reservation_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_email_detail_label_first_name: "영문 이름",
      hotel_hotel_reservation_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_email_detail_label_last_name: "영문 성",
      hotel_hotel_reservation_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_email_detail_label_country_code: "국가 코드",
      hotel_hotel_reservation_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_email_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_reservation_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_email_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_reservation_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_email_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_reservation_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_email_detail_label_phone: "휴대폰 번호",
      hotel_hotel_reservation_email_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_email_detail_label_email: "이메일",
      hotel_hotel_reservation_email_detail_example_email: "{{email}}",
      hotel_hotel_reservation_email_detail_label_accompany: "동반 고객",
      hotel_hotel_reservation_email_detail_example_accompany: "{{accompany}}",
      hotel_hotel_reservation_pdf_edit_title: "예약 PDF 서식",
      hotel_hotel_reservation_pdf_edit_select_kr: "한국어",
      hotel_hotel_reservation_pdf_edit_select_en: "영어",
      hotel_hotel_reservation_pdf_edit_select_cn: "중국어",
      hotel_hotel_reservation_pdf_edit_select_jp: "일본어",
      hotel_hotel_reservation_pdf_edit_select_ms: "말레이어",
      hotel_hotel_reservation_pdf_edit_button_remove: "제거",
      hotel_hotel_reservation_pdf_edit_button_append: "추가",
      hotel_hotel_reservation_pdf_edit_button_save: "저장",
      hotel_hotel_reservation_pdf_edit_toolbar_bold: "굵게",
      hotel_hotel_reservation_pdf_edit_toolbar_italic: "이탤릭",
      hotel_hotel_reservation_pdf_edit_toolbar_underline: "밑줄",
      hotel_hotel_reservation_pdf_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_reservation_pdf_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_reservation_pdf_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_reservation_pdf_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_reservation_pdf_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_reservation_pdf_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_reservation_pdf_edit_toolbar_table: "테이블",
      hotel_hotel_reservation_pdf_edit_toolbar_unordered_list: "미주문 목록",
      hotel_hotel_reservation_pdf_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_reservation_pdf_edit_toolbar_paragraph: "단락",
      hotel_hotel_reservation_pdf_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_reservation_pdf_edit_toolbar_picture: "사진",
      hotel_hotel_reservation_pdf_edit_toolbar_code_view: "코드 조회",
      hotel_hotel_reservation_pdf_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_reservation_pdf_edit_toolbar_help: "도움말",
      hotel_hotel_reservation_pdf_edit_toolbar_tip: "팁",
      hotel_hotel_reservation_pdf_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_hotel_reservation_pdf_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_reservation_pdf_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_hotel_reservation_pdf_edit_validation_language_code: "* 중복되지 않는 언어 코드를 선택해주세요.",
      hotel_hotel_reservation_pdf_detail_title: "예약 PDF 문자",
      hotel_hotel_reservation_pdf_detail_label_confurmation_number: "예약 번호",
      hotel_hotel_reservation_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_pdf_detail_label_reservation_id: "예약 ID",
      hotel_hotel_reservation_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_pdf_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_reservation_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_pdf_detail_label_departure_date: "출발 날짜",
      hotel_hotel_reservation_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_pdf_detail_label_room_type: "객실 타입",
      hotel_hotel_reservation_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_reservation_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate: "객실 요금",
      hotel_hotel_reservation_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_pdf_detail_label_total_rate: "총 요금",
      hotel_hotel_reservation_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_pdf_detail_label_current_balance: "이용 금액",
      hotel_hotel_reservation_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_pdf_detail_label_room_number: "객실 번호",
      hotel_hotel_reservation_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_pdf_detail_label_adults: "성인",
      hotel_hotel_reservation_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_pdf_detail_label_children: "유아/어린이",
      hotel_hotel_reservation_pdf_detail_example_children: "{{children}}",
      hotel_hotel_reservation_pdf_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_reservation_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_pdf_detail_label_name_title: "이름 호칭",
      hotel_hotel_reservation_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_pdf_detail_label_first_name: "영문 이름",
      hotel_hotel_reservation_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_pdf_detail_label_last_name: "영문 성",
      hotel_hotel_reservation_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_pdf_detail_label_country_code: "국가 코드",
      hotel_hotel_reservation_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_reservation_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_reservation_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_reservation_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_pdf_detail_label_phone: "휴대폰 번호",
      hotel_hotel_reservation_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_pdf_detail_label_email: "이메일",
      hotel_hotel_reservation_pdf_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_email_edit_title: "동반 고객 예약 이메일 서식",
      hotel_hotel_reservation_accompany_email_edit_select_kr: "한국어",
      hotel_hotel_reservation_accompany_email_edit_select_en: "영어",
      hotel_hotel_reservation_accompany_email_edit_select_cn: "중국어",
      hotel_hotel_reservation_accompany_email_edit_select_jp: "일본어",
      hotel_hotel_reservation_accompany_email_edit_select_ms: "말레이어",
      hotel_hotel_reservation_accompany_email_edit_button_remove: "제거",
      hotel_hotel_reservation_accompany_email_edit_button_append: "추가",
      hotel_hotel_reservation_accompany_email_edit_button_save: "저장",
      hotel_hotel_reservation_accompany_email_edit_toolbar_bold: "굵게",
      hotel_hotel_reservation_accompany_email_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_reservation_accompany_email_edit_toolbar_underline: "밑줄",
      hotel_hotel_reservation_accompany_email_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_reservation_accompany_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_reservation_accompany_email_edit_toolbar_table: "테이블",
      hotel_hotel_reservation_accompany_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_hotel_reservation_accompany_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_reservation_accompany_email_edit_toolbar_paragraph: "단락",
      hotel_hotel_reservation_accompany_email_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_reservation_accompany_email_edit_toolbar_picture: "사진",
      hotel_hotel_reservation_accompany_email_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_reservation_accompany_email_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_reservation_accompany_email_edit_toolbar_help: "도움말",
      hotel_hotel_reservation_accompany_email_edit_toolbar_tip: "팁",
      hotel_hotel_reservation_accompany_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_reservation_accompany_email_detail_title: "동반 고객 예약 이메일 문자",
      hotel_hotel_reservation_accompany_email_detail_label_confurmation_number: "예약 번호",
      hotel_hotel_reservation_accompany_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_accompany_email_detail_label_reservation_id: "예약 ID",
      hotel_hotel_reservation_accompany_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_accompany_email_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_reservation_accompany_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_accompany_email_detail_label_departure_date: "출발 날짜",
      hotel_hotel_reservation_accompany_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_type: "객실 타입",
      hotel_hotel_reservation_accompany_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate: "객실 요금",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_accompany_email_detail_label_total_rate: "총 요금",
      hotel_hotel_reservation_accompany_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_accompany_email_detail_label_current_balance: "이용 금액",
      hotel_hotel_reservation_accompany_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_number: "객실 번호",
      hotel_hotel_reservation_accompany_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_accompany_email_detail_label_adults: "성인",
      hotel_hotel_reservation_accompany_email_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_accompany_email_detail_label_children: "유아/어린이",
      hotel_hotel_reservation_accompany_email_detail_example_children: "{{children}}",
      hotel_hotel_reservation_accompany_email_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_reservation_accompany_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_accompany_email_detail_label_name_title: "이름 호칭",
      hotel_hotel_reservation_accompany_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_first_name: "영문 이름",
      hotel_hotel_reservation_accompany_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_last_name: "영문 성",
      hotel_hotel_reservation_accompany_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_accompany_email_detail_label_country_code: "국가 코드",
      hotel_hotel_reservation_accompany_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_accompany_email_detail_label_phone: "휴대폰 번호",
      hotel_hotel_reservation_accompany_email_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_accompany_email_detail_label_email: "이메일",
      hotel_hotel_reservation_accompany_email_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_title: "동반 고객 호칭",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_first_name: "동반 고객 영문 이름",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_last_name: "동반 고객 영문 성",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_edit_title: "동반자 예약 변경 이메일",
      hotel_document_accom_reservation_change_edit_select_kr: "한국어",
      hotel_document_accom_reservation_change_edit_select_en: "영어",
      hotel_document_accom_reservation_change_edit_select_cn: "중국어",
      hotel_document_accom_reservation_change_edit_select_jp: "일본어",
      hotel_document_accom_reservation_change_edit_select_ms: "말레이어",
      hotel_document_accom_reservation_change_edit_button_remove: "제거",
      hotel_document_accom_reservation_change_edit_button_append: "추가",
      hotel_document_accom_reservation_change_edit_button_save: "저장",
      hotel_document_accom_reservation_change_edit_toolbar_bold: "굵게",
      hotel_document_accom_reservation_change_edit_toolbar_italic: "이탤릭체",
      hotel_document_accom_reservation_change_edit_toolbar_underline: "밑줄",
      hotel_document_accom_reservation_change_edit_toolbar_strikethrough: "취소선",
      hotel_document_accom_reservation_change_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_document_accom_reservation_change_edit_toolbar_font_family: "글꼴 모음",
      hotel_document_accom_reservation_change_edit_toolbar_font_size: "글꼴 크기",
      hotel_document_accom_reservation_change_edit_toolbar_text_color: "텍스트 색상",
      hotel_document_accom_reservation_change_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_document_accom_reservation_change_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_document_accom_reservation_change_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_document_accom_reservation_change_edit_toolbar_table: "테이블",
      hotel_document_accom_reservation_change_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_document_accom_reservation_change_edit_toolbar_ordered_list: "주문 목록",
      hotel_document_accom_reservation_change_edit_toolbar_paragraph: "단락",
      hotel_document_accom_reservation_change_edit_toolbar_line_height: "줄 높이",
      hotel_document_accom_reservation_change_edit_toolbar_picture: "사진",
      hotel_document_accom_reservation_change_edit_toolbar_code_view: "코드 보기",
      hotel_document_accom_reservation_change_edit_toolbar_full_screen: "전체화면",
      hotel_document_accom_reservation_change_edit_toolbar_help: "도움말",
      hotel_document_accom_reservation_change_edit_toolbar_tip: "팁",
      hotel_document_accom_reservation_change_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_document_accom_reservation_change_edit_placeholder_content: "내용을 입력하세요",
      hotel_document_accom_reservation_change_detail_title: "동반자 예약 변경 문자",
      hotel_document_accom_reservation_change_detail_label_confirmation_number: "예약 번호",
      hotel_document_accom_reservation_change_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_document_accom_reservation_change_detail_label_accom_first_name: "동반자 영문 이름",
      hotel_document_accom_reservation_change_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_change_detail_label_accom_last_name: "동반자 영문 성",
      hotel_document_accom_reservation_change_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_detail_label_first_name: "영문 이름",
      hotel_document_accom_reservation_change_detail_example_first_name: "{{firstName}}",
      hotel_document_accom_reservation_change_detail_label_last_name: "영문 성",
      hotel_document_accom_reservation_change_detail_example_last_name: "{{lastName}}",
      hotel_document_accom_reservation_change_detail_label_room_type: "객실 타입",
      hotel_document_accom_reservation_change_detail_example_room_type: "{{roomType}}",
      hotel_document_accom_reservation_change_detail_label_arrival_date: "도착 날짜",
      hotel_document_accom_reservation_change_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_document_accom_reservation_change_detail_label_departure_date: "출발 날짜",
      hotel_document_accom_reservation_change_detail_example_departure_date: "{{departureDate}}",
      hotel_document_accom_reservation_change_detail_label_accom_title: "동반자 호칭",
      hotel_document_accom_reservation_change_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_change_detail_label_adults: "성인",
      hotel_document_accom_reservation_change_detail_example_adults: "{{adults}}",
      hotel_document_accom_reservation_change_detail_label_night: "일수",
      hotel_document_accom_reservation_change_detail_example_night: "{{night}}",
      hotel_document_accom_reservation_change_detail_label_room_rate: "객실 요금",
      hotel_document_accom_reservation_change_detail_example_room_rate: "{{roomRate}}",
      hotel_document_accom_reservation_change_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_document_accom_reservation_change_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_document_accom_reservation_change_detail_label_total_rate: "총 요금",
      hotel_document_accom_reservation_change_detail_example_total_rate: "{{totalRate}}",
      hotel_document_accom_reservation_change_detail_label_cancellation: "취소 정책",
      hotel_document_accom_reservation_change_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_document_accom_reservation_cancel_edit_title: "동반자 예약 취소 이메일",
      hotel_document_accom_reservation_cancel_edit_select_kr: "한국어",
      hotel_document_accom_reservation_cancel_edit_select_en: "영어",
      hotel_document_accom_reservation_cancel_edit_select_cn: "중국어",
      hotel_document_accom_reservation_cancel_edit_select_jp: "일본어",
      hotel_document_accom_reservation_cancel_edit_select_ms: "말레이어",
      hotel_document_accom_reservation_cancel_edit_button_remove: "제거",
      hotel_document_accom_reservation_cancel_edit_button_append: "추가",
      hotel_document_accom_reservation_cancel_edit_button_save: "저장",
      hotel_document_accom_reservation_cancel_edit_toolbar_bold: "굵게",
      hotel_document_accom_reservation_cancel_edit_toolbar_italic: "이탤릭체",
      hotel_document_accom_reservation_cancel_edit_toolbar_underline: "밑줄",
      hotel_document_accom_reservation_cancel_edit_toolbar_strikethrough: "취소선",
      hotel_document_accom_reservation_cancel_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_family: "글꼴 모음",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_size: "글꼴 크기",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_color: "텍스트 색상",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_document_accom_reservation_cancel_edit_toolbar_table: "테이블",
      hotel_document_accom_reservation_cancel_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_document_accom_reservation_cancel_edit_toolbar_ordered_list: "주문 목록",
      hotel_document_accom_reservation_cancel_edit_toolbar_paragraph: "단락",
      hotel_document_accom_reservation_cancel_edit_toolbar_line_height: "줄 높이",
      hotel_document_accom_reservation_cancel_edit_toolbar_picture: "사진",
      hotel_document_accom_reservation_cancel_edit_toolbar_code_view: "코드 보기",
      hotel_document_accom_reservation_cancel_edit_toolbar_full_screen: "전체화면",
      hotel_document_accom_reservation_cancel_edit_toolbar_help: "도움말",
      hotel_document_accom_reservation_cancel_edit_toolbar_tip: "팁",
      hotel_document_accom_reservation_cancel_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_document_accom_reservation_cancel_edit_placeholder_content: "내용을 입력하세요",
      hotel_document_accom_reservation_cancel_detail_title: "동반자 예약 취소 문자",
      hotel_document_accom_reservation_cancel_detail_label_confirmation_number: "예약 번호",
      hotel_document_accom_reservation_cancel_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_first_name: "동반자 영문 이름",
      hotel_document_accom_reservation_cancel_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_last_name: "동반자 영문 성",
      hotel_document_accom_reservation_cancel_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_cancel_detail_label_first_name: "영문 이름",
      hotel_document_accom_reservation_cancel_detail_example_first_name: "{{firstName}}",
      hotel_document_accom_reservation_cancel_detail_label_last_name: "영문 성",
      hotel_document_accom_reservation_cancel_detail_example_last_name: "{{lastName}}",
      hotel_document_accom_reservation_cancel_detail_label_room_type: "객실 타입",
      hotel_document_accom_reservation_cancel_detail_example_room_type: "{{roomType}}",
      hotel_document_accom_reservation_cancel_detail_label_arrival_date: "도착 날짜",
      hotel_document_accom_reservation_cancel_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_document_accom_reservation_cancel_detail_label_departure_date: "출발 날짜",
      hotel_document_accom_reservation_cancel_detail_example_departure_date: "{{departureDate}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_title: "동반자 호칭",
      hotel_document_accom_reservation_cancel_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_cancel_detail_label_adults: "성인",
      hotel_document_accom_reservation_cancel_detail_example_adults: "{{adults}}",
      hotel_document_accom_reservation_cancel_detail_label_night: "일수",
      hotel_document_accom_reservation_cancel_detail_example_night: "{{night}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate: "객실 요금",
      hotel_document_accom_reservation_cancel_detail_example_room_rate: "{{roomRate}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_document_accom_reservation_cancel_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_document_accom_reservation_cancel_detail_label_total_rate: "총 요금",
      hotel_document_accom_reservation_cancel_detail_example_total_rate: "{{totalRate}}",
      hotel_document_accom_reservation_cancel_detail_label_cancellation: "취소 정책",
      hotel_document_accom_reservation_cancel_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_hotel_reservation_accompany_pdf_edit_title: "동반자 예약 PDF 서식",
      hotel_hotel_reservation_accompany_pdf_edit_select_kr: "한국어",
      hotel_hotel_reservation_accompany_pdf_edit_select_en: "영어",
      hotel_hotel_reservation_accompany_pdf_edit_select_cn: "중국어",
      hotel_hotel_reservation_accompany_pdf_edit_select_jp: "일본어",
      hotel_hotel_reservation_accompany_pdf_edit_select_ms: "말레이어",
      hotel_hotel_reservation_accompany_pdf_edit_button_remove: "제거",
      hotel_hotel_reservation_accompany_pdf_edit_button_append: "추가",
      hotel_hotel_reservation_accompany_pdf_edit_button_save: "저장",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_bold: "굵게",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_underline: "밑줄",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_table: "테이블",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_paragraph: "단락",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_picture: "사진",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_help: "도움말",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_tip: "팁",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_hotel_reservation_accompany_pdf_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_reservation_accompany_pdf_detail_title: "동반 고객 예약 PDF 문자",
      hotel_hotel_reservation_accompany_pdf_detail_label_confurmation_number: "예약 번호",
      hotel_hotel_reservation_accompany_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_reservation_id: "예약 ID",
      hotel_hotel_reservation_accompany_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_reservation_accompany_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_departure_date: "출발 날짜",
      hotel_hotel_reservation_accompany_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_type: "객실 타입",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate: "객실 요금",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_total_rate: "총 요금",
      hotel_hotel_reservation_accompany_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_current_balance: "이용 금액",
      hotel_hotel_reservation_accompany_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_number: "객실 번호",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_adults: "성인",
      hotel_hotel_reservation_accompany_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_children: "유아/어린이",
      hotel_hotel_reservation_accompany_pdf_detail_example_children: "{{children}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_reservation_accompany_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_name_title: "이름 호칭",
      hotel_hotel_reservation_accompany_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_first_name: "영문 이름",
      hotel_hotel_reservation_accompany_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_last_name: "영문 성",
      hotel_hotel_reservation_accompany_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_country_code: "국가 코드",
      hotel_hotel_reservation_accompany_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_phone: "휴대폰 번호",
      hotel_hotel_reservation_accompany_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_email: "이메일",
      hotel_hotel_reservation_accompany_pdf_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_title: "동반 고객 호칭",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_first_name: "동반 고객 영문 이름",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_last_name: "동반 고객 영문 성",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_hotel_folio_email_edit_title: "폴리오 이메일 서식",
      hotel_hotel_folio_email_edit_select_kr: "한국어",
      hotel_hotel_folio_email_edit_select_en: "영어",
      hotel_hotel_folio_email_edit_select_cn: "중국어",
      hotel_hotel_folio_email_edit_select_jp: "일본어",
      hotel_hotel_folio_email_edit_select_ms: "말레이어",
      hotel_hotel_folio_email_edit_button_remove: "제거",
      hotel_hotel_folio_email_edit_button_append: "추가",
      hotel_hotel_folio_email_edit_button_save: "저장",
      hotel_hotel_folio_email_edit_toolbar_bold: "굵게",
      hotel_hotel_folio_email_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_folio_email_edit_toolbar_underline: "밑줄",
      hotel_hotel_folio_email_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_folio_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_folio_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_folio_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_folio_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_folio_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_folio_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_folio_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_folio_email_edit_toolbar_table: "테이블",
      hotel_hotel_folio_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_hotel_folio_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_folio_email_edit_toolbar_paragraph: "단락",
      hotel_hotel_folio_email_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_folio_email_edit_toolbar_picture: "사진",
      hotel_hotel_folio_email_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_folio_email_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_folio_email_edit_toolbar_help: "도움말",
      hotel_hotel_folio_email_edit_toolbar_tip: "팁",
      hotel_hotel_folio_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_folio_email_detail_title: "폴리오 이메일 문자",
      hotel_hotel_folio_email_detail_label_confurmation_number: "예약 번호",
      hotel_hotel_folio_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_folio_email_detail_label_reservation_id: "예약 ID",
      hotel_hotel_folio_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_folio_email_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_folio_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_folio_email_detail_label_departure_date: "출발 날짜",
      hotel_hotel_folio_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_folio_email_detail_label_room_type: "객실 타입",
      hotel_hotel_folio_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_folio_email_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_folio_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_folio_email_detail_label_room_rate: "객실 요금",
      hotel_hotel_folio_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_folio_email_detail_label_total_rate: "총 요금",
      hotel_hotel_folio_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_folio_email_detail_label_current_balance: "잔고",
      hotel_hotel_folio_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_folio_email_detail_label_room_number: "객실 번호",
      hotel_hotel_folio_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_folio_email_detail_label_adults: "성인",
      hotel_hotel_folio_email_detail_example_adults: "{{adults}}",
      hotel_hotel_folio_email_detail_label_children: "아동",
      hotel_hotel_folio_email_detail_example_children: "{{children}}",
      hotel_hotel_folio_email_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_folio_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_folio_email_detail_label_name_title: "이름 호칭",
      hotel_hotel_folio_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_folio_email_detail_label_first_name: "영문 이름",
      hotel_hotel_folio_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_folio_email_detail_label_last_name: "영문 성",
      hotel_hotel_folio_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_folio_email_detail_label_country_code: "국가 코드",
      hotel_hotel_folio_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_folio_email_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_folio_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_folio_email_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_folio_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_email_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_folio_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_folio_email_detail_label_phone: "휴대폰 번호",
      hotel_hotel_folio_email_detail_example_phone: "{{phone}}",
      hotel_hotel_folio_email_detail_label_email: "이메일",
      hotel_hotel_folio_email_detail_example_email: "{{email}}",
      hotel_hotel_folio_email_detail_label_text_area: "폴리오 텍스트 영역",
      hotel_hotel_folio_email_detail_example_text_area: "{{folio_text_area}}",
      hotel_hotel_enroll_profile_edit_title: "등록 프로필 서식",
      hotel_hotel_enroll_profile_edit_select_kr: "한국어",
      hotel_hotel_enroll_profile_edit_select_en: "영어",
      hotel_hotel_enroll_profile_edit_select_cn: "중국어",
      hotel_hotel_enroll_profile_edit_select_jp: "일본어",
      hotel_hotel_enroll_profile_edit_select_ms: "말레이어",
      hotel_hotel_enroll_profile_edit_button_remove: "제거",
      hotel_hotel_enroll_profile_edit_button_append: "추가",
      hotel_hotel_enroll_profile_edit_button_save: "저장",
      hotel_hotel_enroll_profile_edit_toolbar_bold: "굵게",
      hotel_hotel_enroll_profile_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_enroll_profile_edit_toolbar_underline: "밑줄",
      hotel_hotel_enroll_profile_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_enroll_profile_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_enroll_profile_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_enroll_profile_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_enroll_profile_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_enroll_profile_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_enroll_profile_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_enroll_profile_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_enroll_profile_edit_toolbar_table: "테이블",
      hotel_hotel_enroll_profile_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_hotel_enroll_profile_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_enroll_profile_edit_toolbar_paragraph: "단락",
      hotel_hotel_enroll_profile_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_enroll_profile_edit_toolbar_picture: "사진",
      hotel_hotel_enroll_profile_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_enroll_profile_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_enroll_profile_edit_toolbar_help: "도움말",
      hotel_hotel_enroll_profile_edit_toolbar_tip: "팁",
      hotel_hotel_enroll_profile_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_enroll_profile_detail_title: "등록 프로필 문자",
      hotel_hotel_enroll_profile_detail_label_email: "이메일",
      hotel_hotel_enroll_profile_detail_example_email: "{{email}}",
      hotel_hotel_enroll_profile_detail_label_hotel_name: "호텔 이름",
      hotel_hotel_enroll_profile_detail_example_hotel_name: "{{hotelName}}",
      hotel_hotel_enroll_profile_detail_label_title: "호칭",
      hotel_hotel_enroll_profile_detail_example_title: "{{title}}",
      hotel_hotel_enroll_profile_detail_label_first_name: "영문 이름",
      hotel_hotel_enroll_profile_detail_example_first_name: "{{firstName}}",
      hotel_hotel_enroll_profile_detail_label_last_name: "영문 성",
      hotel_hotel_enroll_profile_detail_example_last_name: "{{lastName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_enroll_profile_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_enroll_profile_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_enroll_profile_detail_label_mobile_number: "휴대폰 번호",
      hotel_hotel_enroll_profile_detail_example_mobile_number: "{{mobileNumber}}",
      hotel_hotel_folio_pdf_edit_title: "폴리오 PDF 서식",
      hotel_hotel_folio_pdf_edit_select_kr: "한국어",
      hotel_hotel_folio_pdf_edit_select_en: "영어",
      hotel_hotel_folio_pdf_edit_select_cn: "중국어",
      hotel_hotel_folio_pdf_edit_select_jp: "일본어",
      hotel_hotel_folio_pdf_edit_select_ms: "말레이어",
      hotel_hotel_folio_pdf_edit_button_remove: "제거",
      hotel_hotel_folio_pdf_edit_button_append: "추가",
      hotel_hotel_folio_pdf_edit_button_save: "저장",
      hotel_hotel_folio_pdf_edit_toolbar_bold: "굵게",
      hotel_hotel_folio_pdf_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_folio_pdf_edit_toolbar_underline: "밑줄",
      hotel_hotel_folio_pdf_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_folio_pdf_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_folio_pdf_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_folio_pdf_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_folio_pdf_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_folio_pdf_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_folio_pdf_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_folio_pdf_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_folio_pdf_edit_toolbar_table: "테이블",
      hotel_hotel_folio_pdf_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_hotel_folio_pdf_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_folio_pdf_edit_toolbar_paragraph: "단락",
      hotel_hotel_folio_pdf_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_folio_pdf_edit_toolbar_picture: "사진",
      hotel_hotel_folio_pdf_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_folio_pdf_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_folio_pdf_edit_toolbar_help: "도움말",
      hotel_hotel_folio_pdf_edit_toolbar_tip: "팁",
      hotel_hotel_folio_pdf_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_hotel_folio_pdf_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_folio_pdf_detail_title: "폴리오 PDF 문자",
      hotel_hotel_folio_pdf_detail_label_confurmation_number: "예약 번호",
      hotel_hotel_folio_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_folio_pdf_detail_label_reservation_id: "예약 ID",
      hotel_hotel_folio_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_folio_pdf_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_folio_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_folio_pdf_detail_label_departure_date: "출발 날짜",
      hotel_hotel_folio_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_folio_pdf_detail_label_room_type: "객실 타입",
      hotel_hotel_folio_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_folio_pdf_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_folio_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_folio_pdf_detail_label_room_rate: "객실 요금",
      hotel_hotel_folio_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_folio_pdf_detail_label_total_rate: "총 요금",
      hotel_hotel_folio_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_folio_pdf_detail_label_current_balance: "잔고",
      hotel_hotel_folio_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_folio_pdf_detail_label_room_number: "객실 번호",
      hotel_hotel_folio_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_folio_pdf_detail_label_adults: "성인",
      hotel_hotel_folio_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_folio_pdf_detail_label_children: "아동",
      hotel_hotel_folio_pdf_detail_example_children: "{{children}}",
      hotel_hotel_folio_pdf_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_folio_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_folio_pdf_detail_label_name_title: "이름 호칭",
      hotel_hotel_folio_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_folio_pdf_detail_label_first_name: "영문 이름",
      hotel_hotel_folio_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_folio_pdf_detail_label_last_name: "영문 성",
      hotel_hotel_folio_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_folio_pdf_detail_label_country_code: "국가 코드",
      hotel_hotel_folio_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_folio_pdf_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_folio_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_folio_pdf_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_folio_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_pdf_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_folio_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_folio_pdf_detail_label_phone: "휴대폰 번호",
      hotel_hotel_folio_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_folio_pdf_detail_label_email: "이메일",
      hotel_hotel_folio_pdf_detail_example_email: "{{email}}",
      hotel_hotel_folio_pdf_detail_label_text_area: "폴리오 텍스트 영역",
      hotel_hotel_folio_pdf_detail_example_text_area: "{{folio_text_area}}",
      hotel_future_booking_change_email_edit_title: "미래 예약 변경 이메일",
      hotel_future_booking_change_email_edit_select_kr: "한국어",
      hotel_future_booking_change_email_edit_select_en: "영어",
      hotel_future_booking_change_email_edit_select_cn: "중국어",
      hotel_future_booking_change_email_edit_select_jp: "일본어",
      hotel_future_booking_change_email_edit_select_ms: "말레이어",
      hotel_future_booking_change_email_edit_button_remove: "제거",
      hotel_future_booking_change_email_edit_button_append: "추가",
      hotel_future_booking_change_email_edit_button_save: "저장",
      hotel_future_booking_change_email_edit_toolbar_bold: "굵게",
      hotel_future_booking_change_email_edit_toolbar_italic: "이탤릭체",
      hotel_future_booking_change_email_edit_toolbar_underline: "밑줄",
      hotel_future_booking_change_email_edit_toolbar_strikethrough: "취소선",
      hotel_future_booking_change_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_future_booking_change_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_future_booking_change_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_future_booking_change_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_future_booking_change_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_future_booking_change_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_future_booking_change_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_future_booking_change_email_edit_toolbar_table: "테이블",
      hotel_future_booking_change_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_future_booking_change_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_future_booking_change_email_edit_toolbar_paragraph: "단락",
      hotel_future_booking_change_email_edit_toolbar_line_height: "줄 높이",
      hotel_future_booking_change_email_edit_toolbar_picture: "사진",
      hotel_future_booking_change_email_edit_toolbar_code_view: "코드 보기",
      hotel_future_booking_change_email_edit_toolbar_full_screen: "전체화면",
      hotel_future_booking_change_email_edit_toolbar_help: "도움말",
      hotel_future_booking_change_email_edit_toolbar_tip: "팁",
      hotel_future_booking_change_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_future_booking_change_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_future_booking_change_email_detail_title: "미래 예약 변경 문자",
      hotel_future_booking_change_email_detail_label_confirmation_number: "예약 번호",
      hotel_future_booking_change_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_change_email_detail_label_first_name: "영문 이름",
      hotel_future_booking_change_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_change_email_detail_label_last_name: "영문 성",
      hotel_future_booking_change_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_change_email_detail_label_room_type: "객실 타입",
      hotel_future_booking_change_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_change_email_detail_label_arrival_date: "도착 날짜",
      hotel_future_booking_change_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_change_email_detail_label_departure_date: "출발 날짜",
      hotel_future_booking_change_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_change_email_detail_label_name_title: "이름 호칭",
      hotel_future_booking_change_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_change_email_detail_label_adults: "성인",
      hotel_future_booking_change_email_detail_example_adults: "{{adults}}",
      hotel_future_booking_change_email_detail_label_night: "일수",
      hotel_future_booking_change_email_detail_example_night: "{{night}}",
      hotel_future_booking_change_email_detail_label_room_rate: "객실 요금",
      hotel_future_booking_change_email_detail_example_room_rate: "{{roomRate}}",
      hotel_future_booking_change_email_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_future_booking_change_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_future_booking_change_email_detail_label_total_rate: "총 요금",
      hotel_future_booking_change_email_detail_example_total_rate: "{{totalRate}}",
      hotel_future_booking_change_email_detail_label_cancellation: "취소 정책",
      hotel_future_booking_change_email_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_future_booking_cancel_email_edit_title: "미래 예약 취소 이메일",
      hotel_future_booking_cancel_email_edit_select_kr: "한국어",
      hotel_future_booking_cancel_email_edit_select_en: "영어",
      hotel_future_booking_cancel_email_edit_select_cn: "중국어",
      hotel_future_booking_cancel_email_edit_select_jp: "일본어",
      hotel_future_booking_cancel_email_edit_select_ms: "말레이어",
      hotel_future_booking_cancel_email_edit_button_remove: "제거",
      hotel_future_booking_cancel_email_edit_button_append: "추가",
      hotel_future_booking_cancel_email_edit_button_save: "저장",
      hotel_future_booking_cancel_email_edit_toolbar_bold: "굵게",
      hotel_future_booking_cancel_email_edit_toolbar_italic: "이탤릭체",
      hotel_future_booking_cancel_email_edit_toolbar_underline: "밑줄",
      hotel_future_booking_cancel_email_edit_toolbar_strikethrough: "취소선",
      hotel_future_booking_cancel_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_future_booking_cancel_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_future_booking_cancel_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_future_booking_cancel_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_future_booking_cancel_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_future_booking_cancel_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_future_booking_cancel_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_future_booking_cancel_email_edit_toolbar_table: "테이블",
      hotel_future_booking_cancel_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_future_booking_cancel_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_future_booking_cancel_email_edit_toolbar_paragraph: "단락",
      hotel_future_booking_cancel_email_edit_toolbar_line_height: "줄 높이",
      hotel_future_booking_cancel_email_edit_toolbar_picture: "사진",
      hotel_future_booking_cancel_email_edit_toolbar_code_view: "코드 보기",
      hotel_future_booking_cancel_email_edit_toolbar_full_screen: "전체화면",
      hotel_future_booking_cancel_email_edit_toolbar_help: "도움말",
      hotel_future_booking_cancel_email_edit_toolbar_tip: "팁",
      hotel_future_booking_cancel_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_future_booking_cancel_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_future_booking_cancel_email_detail_title: "미래 예약 취소 문자",
      hotel_future_booking_cancel_email_detail_label_confirmation_number: "예약 번호",
      hotel_future_booking_cancel_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_cancel_email_detail_label_first_name: "영문 이름",
      hotel_future_booking_cancel_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_cancel_email_detail_label_last_name: "영문 성",
      hotel_future_booking_cancel_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_cancel_email_detail_label_room_type: "객실 타입",
      hotel_future_booking_cancel_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_cancel_email_detail_label_arrival_date: "도착 날짜",
      hotel_future_booking_cancel_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_cancel_email_detail_label_departure_date: "출발 날짜",
      hotel_future_booking_cancel_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_cancel_email_detail_label_name_title: "이름 호칭",
      hotel_future_booking_cancel_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_no_show_email_edit_title: "미래 예약 노쇼 이메일",
      hotel_hotel_no_show_email_edit_select_kr: "한국어",
      hotel_hotel_no_show_email_edit_select_en: "영어",
      hotel_hotel_no_show_email_edit_select_cn: "중국어",
      hotel_hotel_no_show_email_edit_select_jp: "일본어",
      hotel_hotel_no_show_email_edit_select_ms: "말레이어",
      hotel_hotel_no_show_email_edit_button_remove: "제거",
      hotel_hotel_no_show_email_edit_button_append: "추가",
      hotel_hotel_no_show_email_edit_button_save: "저장",
      hotel_hotel_no_show_email_edit_toolbar_bold: "굵게",
      hotel_hotel_no_show_email_edit_toolbar_italic: "이탤릭체",
      hotel_hotel_no_show_email_edit_toolbar_underline: "밑줄",
      hotel_hotel_no_show_email_edit_toolbar_strikethrough: "취소선",
      hotel_hotel_no_show_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_hotel_no_show_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_hotel_no_show_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_hotel_no_show_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_hotel_no_show_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_hotel_no_show_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_hotel_no_show_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_hotel_no_show_email_edit_toolbar_table: "테이블",
      hotel_hotel_no_show_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_hotel_no_show_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_hotel_no_show_email_edit_toolbar_paragraph: "단락",
      hotel_hotel_no_show_email_edit_toolbar_line_height: "줄 높이",
      hotel_hotel_no_show_email_edit_toolbar_picture: "사진",
      hotel_hotel_no_show_email_edit_toolbar_code_view: "코드 보기",
      hotel_hotel_no_show_email_edit_toolbar_full_screen: "전체화면",
      hotel_hotel_no_show_email_edit_toolbar_help: "도움말",
      hotel_hotel_no_show_email_edit_toolbar_tip: "팁",
      hotel_hotel_no_show_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_hotel_no_show_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_hotel_no_show_email_detail_title: "미래 예약 취소 문자",
      hotel_hotel_no_show_email_detail_label_confirmation_number: "예약 번호",
      hotel_hotel_no_show_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_hotel_no_show_email_detail_label_reservation_id: "예약 ID",
      hotel_hotel_no_show_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_no_show_email_detail_label_first_name: "영문 이름",
      hotel_hotel_no_show_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_no_show_email_detail_label_last_name: "영문 성",
      hotel_hotel_no_show_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_no_show_email_detail_label_room_type: "객실 타입",
      hotel_hotel_no_show_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_no_show_email_detail_label_arrival_date: "도착 날짜",
      hotel_hotel_no_show_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_no_show_email_detail_label_departure_date: "출발 날짜",
      hotel_hotel_no_show_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_no_show_email_detail_label_name_title: "이름 호칭",
      hotel_hotel_no_show_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_no_show_email_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_hotel_no_show_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_no_show_email_detail_label_room_rate: "객실 요금",
      hotel_hotel_no_show_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_no_show_email_detail_label_total_rate: "총 요금",
      hotel_hotel_no_show_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_no_show_email_detail_label_current_balance: "이용 금액",
      hotel_hotel_no_show_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_no_show_email_detail_label_room_number: "객실 번호",
      hotel_hotel_no_show_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_no_show_email_detail_label_adults: "성인",
      hotel_hotel_no_show_email_detail_example_adults: "{{adults}}",
      hotel_hotel_no_show_email_detail_label_children: "유아/어린이",
      hotel_hotel_no_show_email_detail_example_children: "{{children}}",
      hotel_hotel_no_show_email_detail_label_check_out_time: "체크아웃 시간",
      hotel_hotel_no_show_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_no_show_email_detail_label_country_code: "국가 코드",
      hotel_hotel_no_show_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_no_show_email_detail_label_alternative_language_code: "대체 언어 코드",
      hotel_hotel_no_show_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_no_show_email_detail_label_alternative_first_name: "한글 이름",
      hotel_hotel_no_show_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_no_show_email_detail_label_alternative_last_name: "한글 성",
      hotel_hotel_no_show_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_no_show_email_detail_label_phone: "전화번호",
      hotel_hotel_no_show_email_detail_example_phone: "{{phone}}",
      hotel_hotel_no_show_email_detail_label_email: "이메일",
      hotel_hotel_no_show_email_detail_example_email: "{{email}}",
      hotel_hotel_no_show_email_detail_label_accompany: "동반고객",
      hotel_hotel_no_show_email_detail_example_accompany: "{{accompany}}",
      hotel_future_booking_pack_add_email_edit_title: "미래 예약 패키지 추가 이메일",
      hotel_future_booking_pack_add_email_edit_select_kr: "한국어",
      hotel_future_booking_pack_add_email_edit_select_en: "영어",
      hotel_future_booking_pack_add_email_edit_select_cn: "중국어",
      hotel_future_booking_pack_add_email_edit_select_jp: "일본어",
      hotel_future_booking_pack_add_email_edit_select_ms: "말레이어",
      hotel_future_booking_pack_add_email_edit_button_remove: "제거",
      hotel_future_booking_pack_add_email_edit_button_append: "추가",
      hotel_future_booking_pack_add_email_edit_button_save: "저장",
      hotel_future_booking_pack_add_email_edit_toolbar_bold: "굵게",
      hotel_future_booking_pack_add_email_edit_toolbar_italic: "이탤릭체",
      hotel_future_booking_pack_add_email_edit_toolbar_underline: "밑줄",
      hotel_future_booking_pack_add_email_edit_toolbar_strikethrough: "취소선",
      hotel_future_booking_pack_add_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_future_booking_pack_add_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_future_booking_pack_add_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_future_booking_pack_add_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_future_booking_pack_add_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_future_booking_pack_add_email_edit_toolbar_table: "테이블",
      hotel_future_booking_pack_add_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_future_booking_pack_add_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_future_booking_pack_add_email_edit_toolbar_paragraph: "단락",
      hotel_future_booking_pack_add_email_edit_toolbar_line_height: "줄 높이",
      hotel_future_booking_pack_add_email_edit_toolbar_picture: "사진",
      hotel_future_booking_pack_add_email_edit_toolbar_code_view: "코드 보기",
      hotel_future_booking_pack_add_email_edit_toolbar_full_screen: "전체화면",
      hotel_future_booking_pack_add_email_edit_toolbar_help: "도움말",
      hotel_future_booking_pack_add_email_edit_toolbar_tip: "팁",
      hotel_future_booking_pack_add_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_future_booking_pack_add_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_future_booking_pack_add_email_detail_title: "미래 예약 패키지 추가 문자",
      hotel_future_booking_pack_add_email_detail_label_confirmation_number: "예약 번호",
      hotel_future_booking_pack_add_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_pack_add_email_detail_label_first_name: "영문 이름",
      hotel_future_booking_pack_add_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_pack_add_email_detail_label_last_name: "영문 성",
      hotel_future_booking_pack_add_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_pack_add_email_detail_label_room_type: "객실 타입",
      hotel_future_booking_pack_add_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_pack_add_email_detail_label_arrival_date: "도착 날짜",
      hotel_future_booking_pack_add_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_pack_add_email_detail_label_departure_date: "출발 날짜",
      hotel_future_booking_pack_add_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_pack_add_email_detail_label_name_title: "이름 호칭",
      hotel_future_booking_pack_add_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_pack_add_email_detail_label_package_code: "패키지 코드",
      hotel_future_booking_pack_add_email_detail_example_package_code: "{{packageCode}}",
      hotel_future_booking_pack_add_email_detail_label_package_description: "패키지 설명",
      hotel_future_booking_pack_add_email_detail_example_package_description: "{{packageDescription}}",
      hotel_future_booking_pack_add_email_detail_label_amount: "금액",
      hotel_future_booking_pack_add_email_detail_example_amount: "{{amount}}",
      hotel_future_booking_pack_add_email_detail_label_package_text_area: "패키지 텍스트 영역",
      hotel_future_booking_pack_add_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_pack_delete_email_edit_title: "미래 예약 패키지 삭제 이메일",
      hotel_future_booking_pack_delete_email_edit_select_kr: "한국어",
      hotel_future_booking_pack_delete_email_edit_select_en: "영어",
      hotel_future_booking_pack_delete_email_edit_select_cn: "중국어",
      hotel_future_booking_pack_delete_email_edit_select_jp: "일본어",
      hotel_future_booking_pack_delete_email_edit_select_ms: "말레이어",
      hotel_future_booking_pack_delete_email_edit_button_remove: "제거",
      hotel_future_booking_pack_delete_email_edit_button_append: "추가",
      hotel_future_booking_pack_delete_email_edit_button_save: "저장",
      hotel_future_booking_pack_delete_email_edit_toolbar_bold: "굵게",
      hotel_future_booking_pack_delete_email_edit_toolbar_italic: "이탤릭체",
      hotel_future_booking_pack_delete_email_edit_toolbar_underline: "밑줄",
      hotel_future_booking_pack_delete_email_edit_toolbar_strikethrough: "취소선",
      hotel_future_booking_pack_delete_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_future_booking_pack_delete_email_edit_toolbar_table: "테이블",
      hotel_future_booking_pack_delete_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_future_booking_pack_delete_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_future_booking_pack_delete_email_edit_toolbar_paragraph: "단락",
      hotel_future_booking_pack_delete_email_edit_toolbar_line_height: "줄 높이",
      hotel_future_booking_pack_delete_email_edit_toolbar_picture: "사진",
      hotel_future_booking_pack_delete_email_edit_toolbar_code_view: "코드 보기",
      hotel_future_booking_pack_delete_email_edit_toolbar_full_screen: "전체화면",
      hotel_future_booking_pack_delete_email_edit_toolbar_help: "도움말",
      hotel_future_booking_pack_delete_email_edit_toolbar_tip: "팁",
      hotel_future_booking_pack_delete_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_future_booking_pack_delete_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_future_booking_pack_delete_email_detail_title: "미래 예약 패키지 삭제 문자",
      hotel_future_booking_pack_delete_email_detail_label_confirmation_number: "예약 번호",
      hotel_future_booking_pack_delete_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_pack_delete_email_detail_label_first_name: "영문 이름",
      hotel_future_booking_pack_delete_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_pack_delete_email_detail_label_last_name: "영문 성",
      hotel_future_booking_pack_delete_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_pack_delete_email_detail_label_room_type: "객실 타입",
      hotel_future_booking_pack_delete_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_pack_delete_email_detail_label_arrival_date: "도착 날짜",
      hotel_future_booking_pack_delete_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_pack_delete_email_detail_label_departure_date: "출발 날짜",
      hotel_future_booking_pack_delete_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_pack_delete_email_detail_label_name_title: "이름 호칭",
      hotel_future_booking_pack_delete_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_pack_delete_email_detail_label_package_code: "패키지 코드",
      hotel_future_booking_pack_delete_email_detail_example_package_code: "{{packageCode}}",
      hotel_future_booking_pack_delete_email_detail_label_package_description: "패키지 설명",
      hotel_future_booking_pack_delete_email_detail_example_package_description: "{{packageDescription}}",
      hotel_future_booking_pack_delete_email_detail_label_amount: "금액",
      hotel_future_booking_pack_delete_email_detail_example_amount: "{{amount}}",
      hotel_future_booking_pack_delete_email_detail_label_package_text_area: "패키지 텍스트 영역",
      hotel_future_booking_pack_delete_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_accom_add_email_edit_title: "미래 예약 동반자 추가 이메일",
      hotel_future_booking_accom_add_email_edit_select_kr: "한국어",
      hotel_future_booking_accom_add_email_edit_select_en: "영어",
      hotel_future_booking_accom_add_email_edit_select_cn: "중국어",
      hotel_future_booking_accom_add_email_edit_select_jp: "일본어",
      hotel_future_booking_accom_add_email_edit_select_ms: "말레이어",
      hotel_future_booking_accom_add_email_edit_button_remove: "제거",
      hotel_future_booking_accom_add_email_edit_button_append: "추가",
      hotel_future_booking_accom_add_email_edit_button_save: "저장",
      hotel_future_booking_accom_add_email_edit_toolbar_bold: "굵게",
      hotel_future_booking_accom_add_email_edit_toolbar_italic: "이탤릭체",
      hotel_future_booking_accom_add_email_edit_toolbar_underline: "밑줄",
      hotel_future_booking_accom_add_email_edit_toolbar_strikethrough: "취소선",
      hotel_future_booking_accom_add_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_future_booking_accom_add_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_future_booking_accom_add_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_future_booking_accom_add_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_future_booking_accom_add_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_future_booking_accom_add_email_edit_toolbar_table: "테이블",
      hotel_future_booking_accom_add_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_future_booking_accom_add_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_future_booking_accom_add_email_edit_toolbar_paragraph: "단락",
      hotel_future_booking_accom_add_email_edit_toolbar_line_height: "줄 높이",
      hotel_future_booking_accom_add_email_edit_toolbar_picture: "사진",
      hotel_future_booking_accom_add_email_edit_toolbar_code_view: "코드 보기",
      hotel_future_booking_accom_add_email_edit_toolbar_full_screen: "전체화면",
      hotel_future_booking_accom_add_email_edit_toolbar_help: "도움말",
      hotel_future_booking_accom_add_email_edit_toolbar_tip: "팁",
      hotel_future_booking_accom_add_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_future_booking_accom_add_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_future_booking_accom_add_email_detail_title: "미래 예약 동반자 추가 문자",
      hotel_future_booking_accom_add_email_detail_label_confirmation_number: "예약 번호",
      hotel_future_booking_accom_add_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_accom_add_email_detail_label_accom_first_name: "동반자 영문 이름",
      hotel_future_booking_accom_add_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_add_email_detail_label_accom_last_name: "동반자 영문 성",
      hotel_future_booking_accom_add_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_add_email_detail_label_first_name: "영문 이름",
      hotel_future_booking_accom_add_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_accom_add_email_detail_label_last_name: "영문 성",
      hotel_future_booking_accom_add_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_accom_add_email_detail_label_room_type: "객실 타입",
      hotel_future_booking_accom_add_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_accom_add_email_detail_label_arrival_date: "도착 날짜",
      hotel_future_booking_accom_add_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_accom_add_email_detail_label_departure_date: "출발 날짜",
      hotel_future_booking_accom_add_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_accom_add_email_detail_label_accom_title: "동반자 호칭",
      hotel_future_booking_accom_add_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_add_email_detail_label_adults: "성인",
      hotel_future_booking_accom_add_email_detail_example_adults: "{{adults}}",
      hotel_future_booking_accom_add_email_detail_label_night: "일수",
      hotel_future_booking_accom_add_email_detail_example_night: "{{night}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate: "객실 요금",
      hotel_future_booking_accom_add_email_detail_example_room_rate: "{{roomRate}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate_currency: "객실 요금 통화",
      hotel_future_booking_accom_add_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_future_booking_accom_add_email_detail_label_total_rate: "총 요금",
      hotel_future_booking_accom_add_email_detail_example_total_rate: "{{totalRate}}",
      hotel_future_booking_accom_add_email_detail_label_cancellation: "취소 정책",
      hotel_future_booking_accom_add_email_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_future_booking_accom_delete_email_edit_title: "미래 예약 동반자 삭제 이메일",
      hotel_future_booking_accom_delete_email_edit_select_kr: "한국어",
      hotel_future_booking_accom_delete_email_edit_select_en: "영어",
      hotel_future_booking_accom_delete_email_edit_select_cn: "중국어",
      hotel_future_booking_accom_delete_email_edit_select_jp: "일본어",
      hotel_future_booking_accom_delete_email_edit_select_ms: "말레이어",
      hotel_future_booking_accom_delete_email_edit_button_remove: "제거",
      hotel_future_booking_accom_delete_email_edit_button_append: "추가",
      hotel_future_booking_accom_delete_email_edit_button_save: "저장",
      hotel_future_booking_accom_delete_email_edit_toolbar_bold: "굵게",
      hotel_future_booking_accom_delete_email_edit_toolbar_italic: "이탤릭체",
      hotel_future_booking_accom_delete_email_edit_toolbar_underline: "밑줄",
      hotel_future_booking_accom_delete_email_edit_toolbar_strikethrough: "취소선",
      hotel_future_booking_accom_delete_email_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_family: "글꼴 모음",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_size: "글꼴 크기",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_color: "텍스트 색상",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_more_color: "색상 더 보기",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_future_booking_accom_delete_email_edit_toolbar_table: "테이블",
      hotel_future_booking_accom_delete_email_edit_toolbar_unordered_list: "미 주문 목록",
      hotel_future_booking_accom_delete_email_edit_toolbar_ordered_list: "주문 목록",
      hotel_future_booking_accom_delete_email_edit_toolbar_paragraph: "단락",
      hotel_future_booking_accom_delete_email_edit_toolbar_line_height: "줄 높이",
      hotel_future_booking_accom_delete_email_edit_toolbar_picture: "사진",
      hotel_future_booking_accom_delete_email_edit_toolbar_code_view: "코드 보기",
      hotel_future_booking_accom_delete_email_edit_toolbar_full_screen: "전체화면",
      hotel_future_booking_accom_delete_email_edit_toolbar_help: "도움말",
      hotel_future_booking_accom_delete_email_edit_toolbar_tip: "팁",
      hotel_future_booking_accom_delete_email_edit_toolbar_download_pdf: "PDF 다운로드",
      hotel_future_booking_accom_delete_email_edit_placeholder_content: "내용을 입력하세요",
      hotel_future_booking_accom_delete_email_detail_title: "미래 예약 동반자 삭제 문자",
      hotel_future_booking_accom_delete_email_detail_label_confirmation_number: "예약 번호",
      hotel_future_booking_accom_delete_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_first_name: "동반자 영문 이름",
      hotel_future_booking_accom_delete_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_last_name: "동반자 영문 성",
      hotel_future_booking_accom_delete_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_delete_email_detail_label_first_name: "영문 이름",
      hotel_future_booking_accom_delete_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_accom_delete_email_detail_label_last_name: "영문 성",
      hotel_future_booking_accom_delete_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_accom_delete_email_detail_label_room_type: "객실 타입",
      hotel_future_booking_accom_delete_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_accom_delete_email_detail_label_arrival_date: "도착 날짜",
      hotel_future_booking_accom_delete_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_accom_delete_email_detail_label_departure_date: "출발 날짜",
      hotel_future_booking_accom_delete_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_title: "동반자 호칭",
      hotel_future_booking_accom_delete_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_delete_email_detail_label_main_guest_name: "메인 고객명",
      hotel_future_booking_accom_delete_email_detail_example_main_guest_name: "{{mainGuestName}}",
      hotel_member_list_title: "회원 목록",
      hotel_member_list_placeholder_search: "검색",
      hotel_member_list_label_no: "번호",
      hotel_member_list_label_first_name: "영문 이름",
      hotel_member_list_label_last_name: "영문 성",
      hotel_member_list_label_email: "이메일",
      hotel_member_list_label_gender: "성별",
      hotel_member_list_label_member_type: "회원 타입",
      hotel_member_list_label_profile_id: "프로필 ID",
      hotel_member_list_label_fail_count: "실패 횟수",
      hotel_member_list_label_date: "날짜",
      hotel_member_list_guide_empty: "목록이 비어 있습니다",
      hotel_member_detail_title: "프로필 ID",
      hotel_member_detail_label_user: "사용자",
      hotel_member_detail_button_user_transfer: "동기화",
      hotel_member_detail_label_user_first_name: "영문 이름",
      hotel_member_detail_label_user_last_name: "영문 성",
      hotel_member_detail_label_user_email: "이메일",
      hotel_member_detail_label_user_gender: "성별",
      hotel_member_detail_label_user_member_type: "회원 타입",
      hotel_member_detail_label_user_date: "날짜",
      hotel_member_detail_label_fail_log_list: "실패 로그 목록",
      hotel_member_detail_label_fail_count: "실패 횟수",
      hotel_member_detail_button_unlock: "잠금 해제",
      hotel_member_detail_label_fail_log_no: "번호",
      hotel_member_detail_label_fail_log_lock: "잠금",
      hotel_member_detail_label_fail_log_date: "날짜",
      hotel_member_detail_label_pms_member_profile: "PMS 회원 프로필",
      hotel_member_detail_label_pms_member_profile_name_title: "이름 호칭",
      hotel_member_detail_label_pms_member_profile_first_name: "영문 이름",
      hotel_member_detail_label_pms_member_profile_last_name: "영문 성",
      hotel_member_detail_label_pms_member_profile_alternative_language_code: "대체 언어 코드",
      hotel_member_detail_label_pms_member_profile_alternative_first_name: "한글 이름",
      hotel_member_detail_label_pms_member_profile_alternative_last_name: "한글 성",
      hotel_member_detail_label_pms_member_profile_email: "이메일",
      hotel_member_detail_label_pms_member_profile_email_opera_id: "이메일 오페라 ID",
      hotel_member_detail_label_pms_member_profile_phone: "휴대폰 번호",
      hotel_member_detail_label_pms_member_profile_phone_opera_id: "휴대폰 번호 오페라 ID",
      hotel_member_detail_button_go_to_list: "목록으로 가기",
      hotel_non_member_list_title: "비회원 목록",
      hotel_non_member_list_placeholder_search: "검색",
      hotel_non_member_list_label_no: "번호",
      hotel_non_member_list_label_first_name: "영문 이름",
      hotel_non_member_list_label_last_name: "영문 성",
      hotel_non_member_list_label_email: "이메일",
      hotel_non_member_list_label_gender: "성별",
      hotel_non_member_list_label_fail_count: "회원 타입",
      hotel_non_member_list_label_date: "날짜",
      hotel_non_member_list_guide_empty: "목록이 비어 있습니다",
      hotel_non_member_detail_label_user: "사용자",
      hotel_non_member_detail_label_user_first_name: "영문 이름",
      hotel_non_member_detail_label_user_last_name: "영문 성",
      hotel_non_member_detail_label_user_email: "이메일",
      hotel_non_member_detail_label_user_gender: "성별",
      hotel_non_member_detail_label_user_date: "날짜",
      hotel_non_member_detail_label_fail_log_list: "실패 로그 목록",
      hotel_non_member_detail_label_fail_count: "실패 횟수",
      hotel_non_member_detail_button_unlock: "잠금 해제",
      hotel_non_member_detail_label_fail_log_no: "번호",
      hotel_non_member_detail_label_fail_log_lock: "잠금",
      hotel_non_member_detail_label_fail_log_date: "날짜",
      hotel_non_member_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_reservation_list_title: "예약 목록",
      hotel_reservation_reservation_list_placeholder_select_box_reservation_status: "예약 상태",
      hotel_reservation_reservation_list_select_inhouse: "인하우스",
      hotel_reservation_reservation_list_select_reserved: "예약",
      hotel_reservation_reservation_list_select_changed: "변경",
      hotel_reservation_reservation_list_select_canceled: "취소",
      hotel_reservation_reservation_list_select_no_show: "노쇼",
      hotel_reservation_reservation_list_select_checked_out: "체크아웃",
      hotel_reservation_reservation_list_placeholder_select_box_reservation: "예약",
      hotel_reservation_reservation_list_select_confirmation_no: "예약번호",
      hotel_reservation_reservation_list_select_room_no: "방번호",
      hotel_reservation_reservation_list_select_name: "이름",
      hotel_reservation_reservation_list_placeholder_search: "검색",
      hotel_reservation_reservation_list_label_no: "번호",
      hotel_reservation_reservation_list_label_confirmation_no: "예약번호",
      hotel_reservation_reservation_list_label_owner: "예약자",
      hotel_reservation_reservation_list_label_main_guest: "주 고객",
      hotel_reservation_reservation_list_label_status: "상태",
      hotel_reservation_reservation_list_label_checkin_date: "체크인 날짜",
      hotel_reservation_reservation_list_label_checkout_date: "체크아웃 날짜",
      hotel_reservation_reservation_list_label_date: "날짜",
      hotel_reservation_reservation_list_label_cancel: "취소",
      hotel_reservation_reservation_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_reservation_list_button_cancel: "취소",
      hotel_reservation_reservation_list_button_sync: "동기화",
      hotel_reservation_reservation_detail_label_confirmation_no: "예약번호",
      hotel_reservation_reservation_detail_label_owner_main_guest: "주 고객",
      hotel_reservation_reservation_detail_label_owner_delete_btn: "삭제",
      hotel_reservation_reservation_detail_label_owner_profile_id: "프로필 ID",
      hotel_reservation_reservation_detail_label_owner_title: "호칭",
      hotel_reservation_reservation_detail_label_owner_first_name: "영문 이름",
      hotel_reservation_reservation_detail_label_owner_last_name: "영문 성",
      hotel_reservation_reservation_detail_label_owner_confirmed_at: "확인 시간",
      hotel_reservation_reservation_detail_label_owner_confirmed_type: "확인 타입",
      hotel_reservation_reservation_detail_label_owner_use_key: "키 사용",
      hotel_reservation_reservation_detail_label_owner_waiting_room: "객실 대기 요청 시간",
      hotel_reservation_reservation_detail_label_owner_date: "날짜",
      hotel_reservation_reservation_detail_owner_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_reservation_detail_label_booking_user_owner: "예약자",
      hotel_reservation_reservation_detail_label_booking_user_title: "호칭",
      hotel_reservation_reservation_detail_label_booking_user_first_name: "영문 이름",
      hotel_reservation_reservation_detail_label_booking_user_last_name: "영문 성",
      hotel_reservation_reservation_detail_label_booking_user_confirmed_at: "확인",
      hotel_reservation_reservation_detail_label_booking_user_date: "날짜",
      hotel_reservation_reservation_detail_label_accompany: "동반 고객",
      hotel_reservation_reservation_detail_label_accompany_no: "번호",
      hotel_reservation_reservation_detail_label_accompany_profile_id: "프로필 ID",
      hotel_reservation_reservation_detail_label_accompany_title: "호칭",
      hotel_reservation_reservation_detail_label_accompany_first_name: "영문 이름",
      hotel_reservation_reservation_detail_label_accompany_last_name: "영문 성",
      hotel_reservation_reservation_detail_label_accompany_use_key: "키 사용",
      hotel_reservation_reservation_detail_label_accompany_confirmed_at: "확인 시간",
      hotel_reservation_reservation_detail_label_accompany_confirmed_type: "확인 타입",
      hotel_reservation_reservation_detail_label_accompany_hotel_member_id: "호텔 회원 ID",
      hotel_reservation_reservation_detail_label_accompany_hotel_date: "날짜",
      hotel_reservation_reservation_detail_accompany_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_reservation_detail_label_agreed: "약관 동의",
      hotel_reservation_reservation_detail_label_agreed_title: "호칭",
      hotel_reservation_reservation_detail_label_agreed_agreement: "동의 내용",
      hotel_reservation_reservation_detail_label_agreed_agreed_at: "동의 시간",
      hotel_reservation_reservation_detail_label_agreed_view_details: "상세",
      hotel_reservation_reservation_detail_label_agreed_go_to_detail: "상세 보기",
      hotel_reservation_reservation_detail_label_payment: "결제",
      hotel_reservation_reservation_detail_label_payment_long_info: "롱 정보",
      hotel_reservation_reservation_detail_label_payment_amount: "금액",
      hotel_reservation_reservation_detail_label_payment_unit: "단위",
      hotel_reservation_reservation_detail_label_payment_pg_status_code: "PG 상태 코드",
      hotel_reservation_reservation_detail_label_payment_pg_status_message: "PG 상태 메시지",
      hotel_reservation_reservation_detail_label_payment_date: "날짜",
      hotel_reservation_reservation_detail_label_pms: "PMS",
      hotel_reservation_reservation_detail_label_pms_confirmation_no: "예약번호",
      hotel_reservation_reservation_detail_label_pms_reservation_id: "예약 ID",
      hotel_reservation_reservation_detail_label_pms_profile_id: "프로필 ID",
      hotel_reservation_reservation_detail_label_pms_reservation_status: "예약 상태",
      hotel_reservation_reservation_detail_label_pms_computed_reservation_status: "계산된 예약 상태",
      hotel_reservation_reservation_detail_label_pms_message_exists: "메시지가 존재합니다",
      hotel_reservation_reservation_detail_label_pms_arrival_date: "도착 날짜",
      hotel_reservation_reservation_detail_label_pms_departure_date: "출발 날짜",
      hotel_reservation_reservation_detail_label_pms_room_type: "객실 타입",
      hotel_reservation_reservation_detail_label_pms_room_type_code: "객실 타입 코드",
      hotel_reservation_reservation_detail_label_pms_room_rate_currency: "객실 요금 통화",
      hotel_reservation_reservation_detail_label_pms_room_rate: "객실 요금",
      hotel_reservation_reservation_detail_label_pms_total_rate: "총 요금",
      hotel_reservation_reservation_detail_label_pms_current_balance: "이용 금액",
      hotel_reservation_reservation_detail_label_pms_room_number: "객실 번호",
      hotel_reservation_reservation_detail_label_pms_adults: "성인",
      hotel_reservation_reservation_detail_label_pms_children: "유아/어린이",
      hotel_reservation_reservation_detail_label_pms_accompany: "동반 고객",
      hotel_reservation_reservation_detail_label_pms_accompany_no: "번호",
      hotel_reservation_reservation_detail_label_pms_accompany_profile_id: "프로필 ID",
      hotel_reservation_reservation_detail_label_pms_accompany_first_name: "영문 이름",
      hotel_reservation_reservation_detail_label_pms_accompany_last_name: "영문 성",
      hotel_reservation_reservation_detail_label_pms_accompany_nationality: "국가",
      hotel_reservation_reservation_detail_label_pms_accompany_passport_no: "여권번호",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_birth: "생년월일",
      hotel_reservation_reservation_detail_label_pms_accompany_gender: "성별",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_expiry: "여권 만료일",
      hotel_reservation_reservation_detail_label_pms_main_guest: "메인 고객",
      hotel_reservation_reservation_detail_label_pms_main_guest_profile_id: "프로필 ID",
      hotel_reservation_reservation_detail_label_pms_main_guest_first_name: "영문 이름",
      hotel_reservation_reservation_detail_label_pms_main_guest_last_name: "영문 성",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_nationality: "국가",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_passport_no: "여권번호",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_birth: "생년월일",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_gender: "성별",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_expiry: "여권 만료일",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_date: "도착 항공편 날짜",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_no: "도착 항공편 번호",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_time: "도착 항공편 비행시간",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_date: "출발 항공편 날짜",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_no: "출발 항공편 번호",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_time: "출발 항공편 비행시간",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_wake_up_call: "모닝콜",
      hotel_reservation_reservation_detail_label_pms_package: "패키지",
      hotel_reservation_reservation_detail_label_pms_package_no: "번호",
      hotel_reservation_reservation_detail_label_pms_package_package_code: "패키지 코드",
      hotel_reservation_reservation_detail_label_pms_package_description: "내용",
      hotel_reservation_reservation_detail_label_pms_package_amount: "금액",
      hotel_reservation_reservation_detail_label_pms_charges_by_date: "날짜 별 요금",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_no: "번호",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_date: "날짜",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_description: "내역",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_charges: "요금",
      hotel_reservation_reservation_detail_label_pms_invoice: "청구서",
      hotel_reservation_reservation_detail_label_pms_invoice_no: "번호",
      hotel_reservation_reservation_detail_label_pms_invoice_date: "날짜",
      hotel_reservation_reservation_detail_label_pms_invoice_description: "내역",
      hotel_reservation_reservation_detail_label_pms_invoice_currency_code: "통화 코드",
      hotel_reservation_reservation_detail_label_pms_invoice_amount: "금액",
      hotel_reservation_reservation_detail_label_pms_invoice_supplement: "추가 설명",
      hotel_reservation_reservation_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_reservation_detail_button_cancel: "취소",
      hotel_reservation_reservation_compare_face_detail_title: "얼굴 비교 정보",
      hotel_reservation_reservation_detail_label_user_name: "사용자명",
      hotel_reservation_reservation_detail_label_identity_document_type: "신분증 유형",
      hotel_reservation_reservation_detail_label_auth_status: "인증 상태",
      hotel_reservation_reservation_detail_label_similarity: "유사도",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response: "싱가폴 EVA 응답",
      hotel_reservation_reservation_detail_label_government_singapore_eva_status: "싱가폴 EVA 상태",
      hotel_reservation_reservation_detail_label_government_singapore_eva_request_at: "싱가폴 EVA 요청 시간",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response_at: "싱가폴 EVA 응답 시간",
      hotel_reservation_reservation_detail_label_created_at: "생성 시간",
      hotel_reservation_reservation_compare_face_detail_button_go_to_detail: "상세로 가기",
      hotel_reservation_reservation_exist_label_room_no: "객실 번호",
      hotel_reservation_reservation_exist_label_period_of_stay: "투숙 기간",
      hotel_reservation_reservation_exist_label_user: "사용자",
      hotel_reservation_reservation_exist_label_user_confirmation_no: "예약번호",
      hotel_reservation_reservation_exist_label_user_first_name: "영문 이름",
      hotel_reservation_reservation_exist_label_user_last_name: "영문 성",
      hotel_reservation_reservation_exist_label_user_email: "이메일",
      hotel_reservation_reservation_exist_label_user_gender: "성별",
      hotel_reservation_reservation_exist_label_user_member_type: "회원 타입",
      hotel_reservation_reservation_exist_label_user_date: "날짜",
      hotel_reservation_reservation_exist_label_exist_request_list: "요청 목록",
      hotel_reservation_reservation_exist_label_exist_request_list_: "요청 날짜",
      hotel_reservation_reservation_exist_label_exist_request_list_request_type: "요청 타입",
      hotel_reservation_reservation_exist_label_exist_request_list_express: "태스크",
      hotel_reservation_reservation_exist_label_exist_request_list_number_of_item: "항목 수",
      hotel_reservation_reservation_exist_label_exist_request_list_action_status: "현재 동작 상태",
      hotel_reservation_reservation_exist_label_exist_request_list_date: "날짜",
      hotel_reservation_reservation_exist_label_exist_request_list_hover: "해당 요청은 중단처리가 불가합니다",
      hotel_reservation_reservation_exist_button_go_to_list: "목록으로 가기",
      hotel_reservation_reservation_exist_button_stop: "중단",
      hotel_reservation_waiting_list_title: "객실 대기 목록",
      hotel_reservation_waiting_list_placeholder_select_box_waiting_room: "객실 대기",
      hotel_reservation_waiting_list_select_profile_id: "프로필 ID",
      hotel_reservation_waiting_list_select_owner_name: "예약자명",
      hotel_reservation_waiting_list_placeholder_search: "검색",
      hotel_reservation_waiting_list_label_no: "번호",
      hotel_reservation_waiting_list_label_profile_id: "프로필 ID",
      hotel_reservation_waiting_list_label_title: "호칭",
      hotel_reservation_waiting_list_label_main_guest: "주 고객",
      hotel_reservation_waiting_list_label_main_guest_email: "주 고객 이메일",
      hotel_reservation_waiting_list_label_owner_name: "예약자명",
      hotel_reservation_waiting_list_label_owner_email: "예약자 이메일",
      hotel_reservation_waiting_list_label_confirmation_no: "예약번호",
      hotel_reservation_waiting_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_waiting_detail_label_confirmation_no: "예약 확인번호",
      hotel_reservation_waiting_detail_label_owner: "예약자",
      hotel_reservation_waiting_detail_label_owner_profile_id: "프로필 ID",
      hotel_reservation_waiting_detail_label_owner_title: "호칭",
      hotel_reservation_waiting_detail_label_owner_first_name: "영문 이름",
      hotel_reservation_waiting_detail_label_owner_last_name: "영문 성",
      hotel_reservation_waiting_detail_label_owner_confirmed_at: "확인 시간",
      hotel_reservation_waiting_detail_label_owner_confirmed_type: "확인 타입",
      hotel_reservation_waiting_detail_label_owner_waiting_room: "객실 대기",
      hotel_reservation_waiting_detail_label_owner_date: "날짜",
      hotel_reservation_waiting_detail_label_accompany: "동반 고객",
      hotel_reservation_waiting_detail_label_accompany_no: "번호",
      hotel_reservation_waiting_detail_label_accompany_profile_id: "프로필 ID",
      hotel_reservation_waiting_detail_label_accompany_tile: "호칭",
      hotel_reservation_waiting_detail_label_accompany_first_name: "영문 이름",
      hotel_reservation_waiting_detail_label_accompany_last_name: "영문 성",
      hotel_reservation_waiting_detail_label_accompany_use_key: "키 사용",
      hotel_reservation_waiting_detail_label_accompany_confirmed_at: "확인 시간",
      hotel_reservation_waiting_detail_label_accompany_confirmed_type: "확인 타입",
      hotel_reservation_waiting_detail_label_accompany_hotel_member_id: "호텔 회원 ID",
      hotel_reservation_waiting_detail_label_accompany_date: "날짜",
      hotel_reservation_waiting_detail_label_pms: "PMS",
      hotel_reservation_waiting_detail_label_pms_confirmation_no: "예약 확인번호",
      hotel_reservation_waiting_detail_label_pms_reservation_id: "예약 ID",
      hotel_reservation_waiting_detail_label_pms_profile_id: "프로필 ID",
      hotel_reservation_waiting_detail_label_pms_reservation_status: "예약 상태",
      hotel_reservation_waiting_detail_label_pms_message_exists: "메시지가 존재합니다.",
      hotel_reservation_waiting_detail_label_pms_arrival_date: "도착 날짜",
      hotel_reservation_waiting_detail_label_pms_departure_date: "출발 날짜",
      hotel_reservation_waiting_detail_label_pms_room_type: "객실 타입",
      hotel_reservation_waiting_detail_label_pms_room_type_code: "객실 타입 코드",
      hotel_reservation_waiting_detail_label_pms_room_rate_currency: "객실 요금 통화",
      hotel_reservation_waiting_detail_label_pms_room_rate: "객실 요금",
      hotel_reservation_waiting_detail_label_pms_total_rate: "총 요금",
      hotel_reservation_waiting_detail_label_pms_current_balance: "이용 금액",
      hotel_reservation_waiting_detail_label_pms_room_number: "객실 번호",
      hotel_reservation_waiting_detail_label_pms_adults: "성인",
      hotel_reservation_waiting_detail_label_pms_children: "유아/어린이",
      hotel_reservation_waiting_detail_label_pms_accompany: "동반 고객",
      hotel_reservation_waiting_detail_label_pms_accompany_no: "번호",
      hotel_reservation_waiting_detail_label_pms_accompany_profile_id: "프로필 ID",
      hotel_reservation_waiting_detail_label_pms_accompany_first_name: "영문 이름",
      hotel_reservation_waiting_detail_label_pms_accompany_last_name: "영문 성",
      hotel_reservation_waiting_detail_label_pms_package: "패키지",
      hotel_reservation_waiting_detail_label_pms_package_no: "번호",
      hotel_reservation_waiting_detail_label_pms_package_package_code: "패키지 코드",
      hotel_reservation_waiting_detail_label_pms_package_description: "내용",
      hotel_reservation_waiting_detail_label_pms_package_amount: "금액",
      hotel_reservation_waiting_detail_label_pms_charges_by_date: "날짜별 요금",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_no: "번호",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_date: "날짜",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_description: "내역",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_charges: "요금",
      hotel_reservation_waiting_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_waiting_detail_button_cancel: "취소",
      hotel_reservation_future_booking_list_title: "미래 예약 목록",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation_status: "예약 상태",
      hotel_reservation_future_booking_list_select_inhouse: "인하우스",
      hotel_reservation_future_booking_list_select_reserved: "예약",
      hotel_reservation_future_booking_list_select_changed: "변경",
      hotel_reservation_future_booking_list_select_canceled: "취소",
      hotel_reservation_future_booking_list_select_no_show: "노쇼",
      hotel_reservation_future_booking_list_select_checked_out: "체크아웃",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation: "미래 예약",
      hotel_reservation_future_booking_list_select_confirmation_no: "예약번호",
      hotel_reservation_future_booking_list_select_name: "이름",
      hotel_reservation_future_booking_list_placeholder_search: "검색...",
      hotel_reservation_future_booking_list_label_no: "번호",
      hotel_reservation_future_booking_list_label_confirmation_no: "예약번호",
      hotel_reservation_future_booking_list_label_booking_owner: "예약자 본인",
      hotel_reservation_future_booking_list_label_status: "상태",
      hotel_reservation_future_booking_list_label_occupy: "가예약",
      hotel_reservation_future_booking_list_label_date: "날짜",
      hotel_reservation_future_booking_list_label_cancel: "취소",
      hotel_reservation_future_booking_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_list_button_cancel: "취소",
      hotel_reservation_future_booking_list_button_sync: "동기화",
      hotel_reservation_future_booking_detail_label_confirmation_no: "예약번호",
      hotel_reservation_future_booking_detail_label_owner: "예약자 본인",
      hotel_reservation_future_booking_detail_label_owner_title: "호칭",
      hotel_reservation_future_booking_detail_label_owner_first_name: "영문 이름",
      hotel_reservation_future_booking_detail_label_owner_last_name: "영문 성",
      hotel_reservation_future_booking_detail_label_owner_use_key: "키 사용",
      hotel_reservation_future_booking_detail_label_owner_confirmed_at: "확인",
      hotel_reservation_future_booking_detail_label_owner_date: "날짜",
      hotel_reservation_future_booking_detail_label_pms: "PMS",
      hotel_reservation_future_booking_detail_label_pms_confirmation_no: "예약",
      hotel_reservation_future_booking_detail_label_pms_reservation_id: "예약 ID",
      hotel_reservation_future_booking_detail_label_pms_profile_id: "프로필 ID",
      hotel_reservation_future_booking_detail_label_pms_reservation_status: "예약 상태",
      hotel_reservation_future_booking_detail_label_pms_message_exists: "메시지가 존재합니다",
      hotel_reservation_future_booking_detail_label_pms_arrival_date: "도착 날짜",
      hotel_reservation_future_booking_detail_label_pms_departure_date: "출발 날짜",
      hotel_reservation_future_booking_detail_label_pms_room_type: "객실 타입",
      hotel_reservation_future_booking_detail_label_pms_room_type_code: "객실 타입 코드",
      hotel_reservation_future_booking_detail_label_pms_room_rate_currency: "객실 요금 통화",
      hotel_reservation_future_booking_detail_label_pms_room_rate: "객실 요금",
      hotel_reservation_future_booking_detail_label_pms_total_rate: "총 요금",
      hotel_reservation_future_booking_detail_label_pms_current_balance: "이용 금액",
      hotel_reservation_future_booking_detail_label_pms_room_number: "객실 번호",
      hotel_reservation_future_booking_detail_label_pms_adults: "성인",
      hotel_reservation_future_booking_detail_label_pms_children: "유아/어린이",
      hotel_reservation_future_booking_detail_label_pms_main_guest: "메인 고객",
      hotel_reservation_future_booking_detail_label_pms_main_guest_profile_id: "프로필 ID",
      hotel_reservation_future_booking_detail_label_pms_main_guest_first_name: "영문 이름",
      hotel_reservation_future_booking_detail_label_pms_main_guest_last_name: "영문 성",
      hotel_reservation_future_booking_detail_label_pms_main_guest_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_nationality: "국가",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_passport_no: "여권번호",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_birth: "생년월일",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_gender: "성별",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_expiry: "여권 만료일",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_date: "도착 항공편 날짜",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_no: "도착 항공편 번호",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_time: "도착 항공편 비행시간",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_date: "출발 항공편 날짜",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_no: "출발 항공편 번호",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_time: "출발 항공편 비행시간",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_wake_up_call: "모닝콜",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_detail_label_pms_accompany: "동반 고객",
      hotel_reservation_future_booking_detail_label_pms_accompany_no: "번호",
      hotel_reservation_future_booking_detail_label_pms_accompany_profile_id: "프로필 ID",
      hotel_reservation_future_booking_detail_label_pms_accompany_first_name: "영문 이름",
      hotel_reservation_future_booking_detail_label_pms_accompany_last_name: "영문 성",
      hotel_reservation_future_booking_detail_label_pms_accompany_info_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_detail_label_pms_package: "패키지",
      hotel_reservation_future_booking_detail_label_pms_package_no: "번호",
      hotel_reservation_future_booking_detail_label_pms_package_package_code: "패키지 코드",
      hotel_reservation_future_booking_detail_label_pms_package_description: "내용",
      hotel_reservation_future_booking_detail_label_pms_package_amount: "금액",
      hotel_reservation_future_booking_detail_label_pms_package_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date: "날짜별 요금",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_no: "번호",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_date: "날짜",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_description: "내역",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_charges: "요금",
      hotel_reservation_future_booking_detail_label_pms_charges_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_future_booking_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_future_booking_detail_button_cancel: "취소",
      hotel_reservation_rule_setting_reservation_rule_list_title: "예약 규칙 목록",
      hotel_reservation_rule_setting_reservation_rule_list_select_active_type: "활성화 타입 ( 모두 )",
      hotel_reservation_rule_setting_reservation_rule_list_select_active: "활성화",
      hotel_reservation_rule_setting_reservation_rule_list_select_inactive: "비활성화",
      hotel_reservation_rule_setting_reservation_rule_list_placeholder_search: "검색",
      hotel_reservation_rule_setting_reservation_rule_list_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_reservation_rule_list_label_reservation_amount: "예약 금액",
      hotel_reservation_rule_setting_reservation_rule_list_label_type: "타입",
      hotel_reservation_rule_setting_reservation_rule_list_label_day_before_arrival: "체크인 ~일 전",
      hotel_reservation_rule_setting_reservation_rule_list_label_before_time: "체크인 ~시간 전",
      hotel_reservation_rule_setting_reservation_rule_list_label_inactive: "비활성화",
      hotel_reservation_rule_setting_reservation_rule_list_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_reservation_rule_list_button_delete: "삭제",
      hotel_reservation_rule_setting_reservation_rule_list_button_add: "추가",
      hotel_reservation_rule_setting_reservation_rule_list_guide_empty: "목록이 비어 있습니다.",
      hotel_reservation_rule_setting_reservation_rule_add_title: "예약 규칙 추가",
      hotel_reservation_rule_setting_reservation_rule_add_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_reservation_rule_add_label_description: "설명",
      hotel_reservation_rule_setting_reservation_rule_add_label_type: "타입",
      hotel_reservation_rule_setting_reservation_rule_add_placeholder_select_box_select: "선택",
      hotel_reservation_rule_setting_reservation_rule_add_select_percent: "퍼센트",
      hotel_reservation_rule_setting_reservation_rule_add_select_nights: "숙박 일",
      hotel_reservation_rule_setting_reservation_rule_add_select_flat: "고정 요금",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_amount: "금액",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_percent: "퍼센트",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_nights: "숙박 일",
      hotel_reservation_rule_setting_reservation_rule_add_label_transaction_code: "결제 코드",
      hotel_reservation_rule_setting_reservation_rule_add_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_reservation_rule_add_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_reservation_rule_add_label_inactive: "비활성화",
      hotel_reservation_rule_setting_reservation_rule_add_label_yes: "네",
      hotel_reservation_rule_setting_reservation_rule_add_label_no: "아니오",
      hotel_reservation_rule_setting_reservation_rule_add_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_reservation_rule_add_button_add: "추가",
      hotel_reservation_rule_setting_reservation_rule_add_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_type: "* 타입을 선택해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_reservation_amount: "* 퍼센트를 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_dayBefore_arrival: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_transaction_code: "* 체크인 ~일 전을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_inactive: "*  비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_before_time: "* 체크인 ~시간 전을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_detail_title: "예약 규칙 정보",
      hotel_reservation_rule_setting_reservation_rule_detail_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_reservation_rule_detail_label_description: "설명",
      hotel_reservation_rule_setting_reservation_rule_detail_label_type: "타입",
      hotel_reservation_rule_setting_reservation_rule_detail_select_percent: "퍼센트",
      hotel_reservation_rule_setting_reservation_rule_detail_select_nights: "숙박 일",
      hotel_reservation_rule_setting_reservation_rule_detail_select_flat: "고정 요금",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_amount: "금액",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_percent: "퍼센트",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_nights: "숙박 일",
      hotel_reservation_rule_setting_reservation_rule_detail_label_transactio_code: "결제 코드",
      hotel_reservation_rule_setting_reservation_rule_detail_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_reservation_rule_detail_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_reservation_rule_detail_label_inactive: "비활성화",
      hotel_reservation_rule_setting_reservation_rule_detail_label_yes: "네",
      hotel_reservation_rule_setting_reservation_rule_detail_label_no: "아니오",
      hotel_reservation_rule_setting_reservation_rule_detail_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_reservation_rule_detail_label_updated_at: "업데이트 시간",
      hotel_reservation_rule_setting_reservation_rule_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_reservation_rule_detail_button_cancel: "취소",
      hotel_reservation_rule_setting_reservation_rule_detail_button_delete: "삭제",
      hotel_reservation_rule_setting_reservation_rule_detail_button_edit: "수정",
      hotel_reservation_rule_setting_reservation_rule_detail_button_ok: "확인",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_type: "* 타입을 선택해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_reservation_amount: "* 퍼센트를 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_dayBefore_arrival: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_transaction_code: "* 체크인 ~일 전을 입력해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_inactive: "*  비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_before_time: "* 체크인 ~시간 전을 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_list_title: "보증금 규칙 목록",
      hotel_reservation_rule_setting_deposit_rule_list_select_active_type: "활성화 타입 ( 모두 )",
      hotel_reservation_rule_setting_deposit_rule_list_select_active: "활성화",
      hotel_reservation_rule_setting_deposit_rule_list_select_inactive: "비활성화",
      hotel_reservation_rule_setting_deposit_rule_list_placeholder_search: "검색",
      hotel_reservation_rule_setting_deposit_rule_list_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_deposit_rule_list_label_deposit_amount: "보증금 금액",
      hotel_reservation_rule_setting_deposit_rule_list_label_type: "타입",
      hotel_reservation_rule_setting_deposit_rule_list_label_day_before_arrival: "체크인 ~일 전",
      hotel_reservation_rule_setting_deposit_rule_list_label_before_time: "체크인 ~시간 전",
      hotel_reservation_rule_setting_deposit_rule_list_label_inactive: "비활성화",
      hotel_reservation_rule_setting_deposit_rule_list_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_deposit_rule_list_button_delete: "삭제",
      hotel_reservation_rule_setting_deposit_rule_list_button_add: "추가",
      hotel_reservation_rule_setting_deposit_rule_add_title: "보증금 규칙 추가",
      hotel_reservation_rule_setting_deposit_rule_add_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_deposit_rule_add_label_description: "설명",
      hotel_reservation_rule_setting_deposit_rule_add_label_type: "타입",
      hotel_reservation_rule_setting_deposit_rule_add_placeholder_select_box_select: "선택",
      hotel_reservation_rule_setting_deposit_rule_add_select_percent: "퍼센트",
      hotel_reservation_rule_setting_deposit_rule_add_select_nights: "숙박 일",
      hotel_reservation_rule_setting_deposit_rule_add_select_flat: "고정 요금",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_amount: "금액",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_percent: "퍼센트",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_nights: "숙박 일",
      hotel_reservation_rule_setting_deposit_rule_add_label_transaction_code: "결제 코드",
      hotel_reservation_rule_setting_deposit_rule_add_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_deposit_rule_add_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_deposit_rule_add_label_inactive: "비활성화",
      hotel_reservation_rule_setting_deposit_rule_add_label_yes: "네",
      hotel_reservation_rule_setting_deposit_rule_add_label_no: "아니오",
      hotel_reservation_rule_setting_deposit_rule_add_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_deposit_rule_add_button_add: "추가",
      hotel_reservation_rule_setting_deposit_rule_add_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_type: "* 타입을 선택해주세요.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_deposit_amount: "* 퍼센트를 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_transaction_code: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_inactive: "* 비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_deposit_rule_detail_title: "보증금 규칙 정보",
      hotel_reservation_rule_setting_deposit_rule_detail_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_deposit_rule_detail_label_description: "설명",
      hotel_reservation_rule_setting_deposit_rule_detail_label_type: "타입",
      hotel_reservation_rule_setting_deposit_rule_detail_select_percent: "퍼센트",
      hotel_reservation_rule_setting_deposit_rule_detail_select_nights: "숙박 일",
      hotel_reservation_rule_setting_deposit_rule_detail_select_flat: "고정 요금",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_amount: "금액",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_percent: "퍼센트",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_nights: "숙박 일",
      hotel_reservation_rule_setting_deposit_rule_detail_label_transactio_code: "결제 코드",
      hotel_reservation_rule_setting_deposit_rule_detail_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_deposit_rule_detail_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_deposit_rule_detail_label_inactive: "비활성화",
      hotel_reservation_rule_setting_deposit_rule_detail_label_yes: "네",
      hotel_reservation_rule_setting_deposit_rule_detail_label_no: "아니오",
      hotel_reservation_rule_setting_deposit_rule_detail_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_deposit_rule_detail_label_updated_at: "업데이트 시간",
      hotel_reservation_rule_setting_deposit_rule_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_deposit_rule_detail_button_cancel: "취소",
      hotel_reservation_rule_setting_deposit_rule_detail_button_delete: "삭제",
      hotel_reservation_rule_setting_deposit_rule_detail_button_edit: "수정",
      hotel_reservation_rule_setting_deposit_rule_detail_button_ok: "확인",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_type: "* 타입을 선택해주세요.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_deposit_amount: "* 퍼센트를 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_transaction_code: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_inactive: "* 비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_list_title: "취소 규칙 목록",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active_type: "활성화 타입 ( 모두 )",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active: "활성화",
      hotel_reservation_rule_setting_cancellation_rule_list_select_inactive: "비활성화",
      hotel_reservation_rule_setting_cancellation_rule_list_placeholder_search: "검색",
      hotel_reservation_rule_setting_cancellation_rule_list_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_cancellation_rule_list_label_penalty_amount: "패널티 금액",
      hotel_reservation_rule_setting_cancellation_rule_list_label_type: "타입",
      hotel_reservation_rule_setting_cancellation_rule_list_label_day_before_arrival: "체크인 ~일 전",
      hotel_reservation_rule_setting_cancellation_rule_list_label_before_time: "체크인 ~시간 전",
      hotel_reservation_rule_setting_cancellation_rule_list_label_inactive: "비활성화",
      hotel_reservation_rule_setting_cancellation_rule_list_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_cancellation_rule_list_button_delete: "삭제",
      hotel_reservation_rule_setting_cancellation_rule_list_button_add: "추가",
      hotel_reservation_rule_setting_cancellation_rule_list_guide_empty: "목록이 비어 있습니다",
      hotel_reservation_rule_setting_cancellation_rule_add_title: "취소 규칙 추가",
      hotel_reservation_rule_setting_cancellation_rule_add_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_cancellation_rule_add_label_description: "설명",
      hotel_reservation_rule_setting_cancellation_rule_add_label_type: "타입",
      hotel_reservation_rule_setting_cancellation_rule_add_placeholder_select_box_select: "선택",
      hotel_reservation_rule_setting_cancellation_rule_add_select_percent: "퍼센트",
      hotel_reservation_rule_setting_cancellation_rule_add_select_nights: "숙박 일",
      hotel_reservation_rule_setting_cancellation_rule_add_select_flat: "고정 요금",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_amount: "금액",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_percent: "퍼센트",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_nights: "숙박 일",
      hotel_reservation_rule_setting_cancellation_rule_add_label_transaction_code: "결제 코드",
      hotel_reservation_rule_setting_cancellation_rule_add_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_cancellation_rule_add_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_cancellation_rule_add_label_inactive: "비활성화",
      hotel_reservation_rule_setting_cancellation_rule_add_label_yes: "네",
      hotel_reservation_rule_setting_cancellation_rule_add_label_no: "아니오",
      hotel_reservation_rule_setting_cancellation_rule_add_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_cancellation_rule_add_button_add: "추가",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_type: "* 타입을 선택해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_penalty_amount: "* 퍼센트를 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_dayBefore_arrival: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_transaction_code: "* 체크인 ~일 전을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_inactive: "*  비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_before_time: "* 체크인 ~시간 전을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_detail_title: "취소 규칙 정보",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_description: "설명",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_type: "타입",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_percent: "퍼센트",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_nights: "숙박 일",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_flat: "고정 요금",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_amount: "금액",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_percent: "퍼센트",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_nights: "숙박 일",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_transactio_code: "결제 코드",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_inactive: "비활성화",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_yes: "네",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_no: "아니오",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_updated_at: "업데이트 시간",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_cancel: "취소",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_delete: "삭제",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_edit: "수정",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_ok: "확인",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_type: "* 타입을 선택해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_penalty_amount: "* 퍼센트를 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_dayBefore_arrival: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_transaction_code: "* 체크인 ~일 전을 입력해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_inactive: "*  비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_before_time: "* 체크인 ~시간 전을 입력해주세요.",
      hotel_reservation_rule_setting_no_show_rule_list_title: "노쇼 규칙 목록",
      hotel_reservation_rule_setting_no_show_rule_list_select_posting_rule: "청구 규칙 ( 모두 )",
      hotel_reservation_rule_setting_no_show_rule_list_select_all_nights: "전체 숙박 일",
      hotel_reservation_rule_setting_no_show_rule_list_select_deposit_only: "보증금",
      hotel_reservation_rule_setting_no_show_rule_list_select_first_night: "숙박 첫날",
      hotel_reservation_rule_setting_no_show_rule_list_select_percent: "퍼센트",
      hotel_reservation_rule_setting_no_show_rule_list_placeholder_search: "검색",
      hotel_reservation_rule_setting_no_show_rule_list_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_no_show_rule_list_label_penalty_amount: "패널티 금액",
      hotel_reservation_rule_setting_no_show_rule_list_label_source_code: "소스 코드",
      hotel_reservation_rule_setting_no_show_rule_list_label_guarantee_type: "게런티 타입",
      hotel_reservation_rule_setting_no_show_rule_list_label_posting_rule: "청구 규칙",
      hotel_reservation_rule_setting_no_show_rule_list_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_no_show_rule_list_button_delete: "삭제",
      hotel_reservation_rule_setting_no_show_rule_list_button_add: "추가",
      hotel_reservation_rule_setting_no_show_rule_list_guide_empty: "목록이 비어 있습니다",
      hotel_reservation_rule_setting_no_show_rule_add_title: "노쇼 규칙 추가",
      hotel_reservation_rule_setting_no_show_rule_add_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_no_show_rule_add_label_description: "설명",
      hotel_reservation_rule_setting_no_show_rule_add_label_source_code: "소스 코드",
      hotel_reservation_rule_setting_no_show_rule_add_label_guarantee_type: "게런티 타입",
      hotel_reservation_rule_setting_no_show_rule_add_label_posting_rule: "청구 규칙",
      hotel_reservation_rule_setting_no_show_rule_add_select_all_nights: "전체 숙박 일",
      hotel_reservation_rule_setting_no_show_rule_add_select_deposit_only: "보증금",
      hotel_reservation_rule_setting_no_show_rule_add_select_first_night: "숙박 첫날",
      hotel_reservation_rule_setting_no_show_rule_add_select_percent: "퍼센트",
      hotel_reservation_rule_setting_no_show_rule_add_select_flat: "고정 요금",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_amount: "패널티 금액",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_percent: "패널티 퍼센트",
      hotel_reservation_rule_setting_no_show_rule_add_label_transaction_code: "결제 코드",
      hotel_reservation_rule_setting_no_show_rule_add_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_no_show_rule_add_button_add: "추가",
      hotel_reservation_rule_setting_no_show_rule_add_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_no_show_rule_add_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_no_show_rule_add_validation_posting_rule: "* 청구 규칙을 선택해주세요.",
      hotel_reservation_rule_setting_no_show_rule_add_validation_penalty_amount: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_no_show_rule_detail_title: "노쇼 규칙 정보",
      hotel_reservation_rule_setting_no_show_rule_detail_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_no_show_rule_detail_label_description: "설명",
      hotel_reservation_rule_setting_no_show_rule_detail_label_source_code: "소스 코드",
      hotel_reservation_rule_setting_no_show_rule_detail_label_guarantee_type: "게런티 타입",
      hotel_reservation_rule_setting_no_show_rule_detail_label_posting_rule: "청구 규칙",
      hotel_reservation_rule_setting_no_show_rule_detail_select_all_nights: "전체 숙박 일",
      hotel_reservation_rule_setting_no_show_rule_detail_select_deposit_only: "보증금",
      hotel_reservation_rule_setting_no_show_rule_detail_select_first_night: "숙박 첫날",
      hotel_reservation_rule_setting_no_show_rule_detail_select_percent: "퍼센트",
      hotel_reservation_rule_setting_no_show_rule_detaill_select_flat: "고정 요금",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_amount: "금액",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_percent: "퍼센트",
      hotel_reservation_rule_setting_no_show_rule_detail_label_transactio_code: "결제 코드",
      hotel_reservation_rule_setting_no_show_rule_detail_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_no_show_rule_detail_label_updated_at: "업데이트 시간",
      hotel_reservation_rule_setting_no_show_rule_detail_button_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_no_show_rule_detail_button_cancel: "취소",
      hotel_reservation_rule_setting_no_show_rule_detail_button_delete: "삭제",
      hotel_reservation_rule_setting_no_show_rule_detail_button_edit: "수정",
      hotel_reservation_rule_setting_no_show_rule_detail_button_ok: "확인",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_rule_name: "* 규칙명을 입력해주세요.",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_posting_rule: "* 청구 규칙을 선택해주세요.",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_penalty_amount: "* 결제 코드를 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_list_title: "규칙 스케줄 목록",
      hotel_reservation_rule_setting_rule_schedule_list_label_active: "활성화",
      hotel_reservation_rule_setting_rule_schedule_list_label_reservation_rule: "예약 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_label_no: "번호",
      hotel_reservation_rule_setting_rule_schedule_list_label_schedule_name: "스케줄명",
      hotel_reservation_rule_setting_rule_schedule_list_label_rate_code: "요금 코드",
      hotel_reservation_rule_setting_rule_schedule_list_label_begin_date: "시작 날짜",
      hotel_reservation_rule_setting_rule_schedule_list_label_end_date: "최종 날짜",
      hotel_reservation_rule_setting_rule_schedule_list_label_week: "요일",
      hotel_reservation_rule_setting_rule_schedule_list_label_week_all: "모두",
      hotel_reservation_rule_setting_rule_schedule_list_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_rule_schedule_list_label_sorting: "정렬",
      hotel_reservation_rule_setting_rule_schedule_list_label_deposit_rule: "보증금 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_label_cancellation_rule: "취소 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_label_no_show_rule: "노쇼 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_label_inactive: "비활성화",
      hotel_reservation_rule_setting_rule_schedule_list_select_rule_type: "규칙 타입 ( 모두 )",
      hotel_reservation_rule_setting_rule_schedule_list_select_reservation_rule: "예약 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_select_deposit_rule: "보증금 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_select_cancellation_rule: "취소 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_select_no_show_rule: "노쇼 규칙",
      hotel_reservation_rule_setting_rule_schedule_list_placeholder_search: "검색",
      hotel_reservation_rule_setting_rule_schedule_list_label_rule_type: "규칙 타입",
      hotel_reservation_rule_setting_rule_schedule_list_label_delete: "삭제",
      hotel_reservation_rule_setting_rule_schedule_list_label_add: "추가",
      hotel_reservation_rule_setting_rule_schedule_list_guide_empty: "목록이 비어 있습니다",
      hotel_reservation_rule_setting_rule_schedule_add_title: "규칙 스케줄 추가",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_type: "규칙 타입",
      hotel_reservation_rule_setting_rule_schedule_add_placeholder_select_box_select: "선택",
      hotel_reservation_rule_setting_rule_schedule_add_select_reservation_rule: "예약 규칙",
      hotel_reservation_rule_setting_rule_schedule_add_select_deposit_rule: "보증금 규칙",
      hotel_reservation_rule_setting_rule_schedule_add_select_cancellation_rule: "취소 규칙",
      hotel_reservation_rule_setting_rule_schedule_add_select_no_show_rule: "노쇼 규칙",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_rule_schedule_add_label_schedule_name: "스케줄명",
      hotel_reservation_rule_setting_rule_schedule_add_label_description: "설명",
      hotel_reservation_rule_setting_rule_schedule_add_label_rate_code: "요금 코드",
      hotel_reservation_rule_setting_rule_schedule_add_label_begin_date: "시작 날짜",
      hotel_reservation_rule_setting_rule_schedule_add_label_end_date: "최종 날짜",
      hotel_reservation_rule_setting_rule_schedule_add_label_week: "요일",
      hotel_reservation_rule_setting_rule_schedule_add_label_inactive: "비활성화",
      hotel_reservation_rule_setting_rule_schedule_add_label_yes: "네",
      hotel_reservation_rule_setting_rule_schedule_add_label_no: "아니오",
      hotel_reservation_rule_setting_rule_schedule_add_label_sequence: "순서",
      hotel_reservation_rule_setting_rule_schedule_add_label_type: "타입",
      hotel_reservation_rule_setting_rule_schedule_add_label_amount: "금액",
      hotel_reservation_rule_setting_rule_schedule_add_label_percent: "퍼센트",
      hotel_reservation_rule_setting_rule_schedule_add_label_nights: "숙박 일",
      hotel_reservation_rule_setting_rule_schedule_add_label_transaction_code: "결제 코드",
      hotel_reservation_rule_setting_rule_schedule_add_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_rule_schedule_add_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_rule_schedule_add_label_source_code: "소스 코드",
      hotel_reservation_rule_setting_rule_schedule_add_label_guarantee_type: "게런티 타입",
      hotel_reservation_rule_setting_rule_schedule_add_label_posting_rule: "청구 규칙",
      hotel_reservation_rule_setting_rule_schedule_add_label_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_rule_schedule_add_label_add: "추가",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_type: "* 규칙 타입을 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_name: "* 규칙명을 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_schedule_name: "* 스케줄명을 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rate_code: "* 요금 코드를 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_begin_date: "* 시작 날짜를 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_end_date: "* 최종 날짜를 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_week: "* 요일을 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_inactive: "* 비활성화 상태를 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_detail_title: "규칙 스케줄 정보",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_type: "규칙 타입",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_name: "규칙명",
      hotel_reservation_rule_setting_rule_schedule_detail_label_schedule_name: "스케줄명",
      hotel_reservation_rule_setting_rule_schedule_detail_label_description: "설명",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rate_code: "요금 코드",
      hotel_reservation_rule_setting_rule_schedule_detail_label_begin_date: "시작 날짜",
      hotel_reservation_rule_setting_rule_schedule_detail_label_end_date: "최종 날짜",
      hotel_reservation_rule_setting_rule_schedule_detail_label_week: "요일",
      hotel_reservation_rule_setting_rule_schedule_detail_label_inactive: "비활성화",
      hotel_reservation_rule_setting_rule_schedule_detail_label_yes: "네",
      hotel_reservation_rule_setting_rule_schedule_detail_label_no: "아니오",
      hotel_reservation_rule_setting_rule_schedule_detail_label_sequence: "순서",
      hotel_reservation_rule_setting_rule_schedule_detail_label_created_at: "생성 시간",
      hotel_reservation_rule_setting_rule_schedule_detail_label_updated_at: "업데이트 시간",
      hotel_reservation_rule_setting_rule_schedule_detail_label_type: "타입",
      hotel_reservation_rule_setting_rule_schedule_detail_label_amount: "금액",
      hotel_reservation_rule_setting_rule_schedule_detail_label_percent: "퍼센트",
      hotel_reservation_rule_setting_rule_schedule_detail_label_nights: "숙박 일",
      hotel_reservation_rule_setting_rule_schedule_detail_label_transaction_code: "결제 코드",
      hotel_reservation_rule_setting_rule_schedule_detail_label_day_before_arrival: "체크인 ~일 전 (일)",
      hotel_reservation_rule_setting_rule_schedule_detail_label_before_time: "체크인 ~시간 전 (시간:분)",
      hotel_reservation_rule_setting_rule_schedule_detail_label_source_code: "소스 코드",
      hotel_reservation_rule_setting_rule_schedule_detail_label_guarantee_type: "게런티 타입",
      hotel_reservation_rule_setting_rule_schedule_detail_label_posting_rule: "청구 규칙",
      hotel_reservation_rule_setting_rule_schedule_detail_label_go_to_list: "목록으로 가기",
      hotel_reservation_rule_setting_rule_schedule_detail_label_cancel: "취소",
      hotel_reservation_rule_setting_rule_schedule_detail_label_delete: "삭제",
      hotel_reservation_rule_setting_rule_schedule_detail_label_edit: "수정",
      hotel_reservation_rule_setting_rule_schedule_detail_label_ok: "확인",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_type: "* 규칙 타입을 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_name: "* 규칙명을 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_schedule_name: "* 스케줄명을 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rate_code: "* 요금 코드를 입력해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_begin_date: "* 시작 날짜를 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_end_date: "* 최종 날짜를 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_week: "* 요일을 선택해주세요.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_inactive: "* 비활성화 상태를 선택해주세요.",
      hotel_inhouse_hotel_info_list_title: "호텔 정보 메뉴",
      hotel_inhouse_hotel_info_list_button_add: "추가",
      hotel_inhouse_hotel_info_list_button_delete: "삭제",
      hotel_inhouse_hotel_info_list_button_edit: "수정",
      hotel_inhouse_hotel_info_list_guide_add: "호텔 정보를 추가하세요",
      hotel_inhouse_hotel_info_add_title: "호텔 정보 추가",
      hotel_inhouse_hotel_info_add_placeholder_title: "호칭을 입력하세요",
      hotel_inhouse_hotel_info_add_placeholder_index: "번호를 입력하세요",
      hotel_inhouse_hotel_info_add_select_choose: "타입을 선택하세요",
      hotel_inhouse_hotel_info_add_select_address: "호텔 주소",
      hotel_inhouse_hotel_info_add_select_wifi: "WiFi",
      hotel_inhouse_hotel_info_add_select_smoking: "금연",
      hotel_inhouse_hotel_info_add_select_transportation: "이동수단",
      hotel_inhouse_hotel_info_add_select_parking: "주차",
      hotel_inhouse_hotel_info_add_select_fitness_center: "피트니스 센터",
      hotel_inhouse_hotel_info_add_select_business_center: "비즈니스 센터",
      hotel_inhouse_hotel_info_add_select_guest_service: "고객 서비스",
      hotel_inhouse_hotel_info_add_select_room_service: "룸 서비스",
      hotel_inhouse_hotel_info_add_select_breakfast: "조식",
      hotel_inhouse_hotel_info_add_select_dining: "다이닝",
      hotel_inhouse_hotel_info_add_select_executive_lounge: "이그제큐티브 라운지",
      hotel_inhouse_hotel_info_add_toolbar_bold: "굵게",
      hotel_inhouse_hotel_info_add_toolbar_italic: "이탤릭",
      hotel_inhouse_hotel_info_add_toolbar_underline: "밑줄",
      hotel_inhouse_hotel_info_add_toolbar_remove_font_style: "글씨체 제거",
      hotel_inhouse_hotel_info_add_toolbar_font_family: "글꼴 모음",
      hotel_inhouse_hotel_info_add_toolbar_font_size: "글꼴 크기",
      hotel_inhouse_hotel_info_add_toolbar_recent_color: "텍스트 색상",
      hotel_inhouse_hotel_info_add_toolbar_recent_more_color: "색상 더 보기",
      hotel_inhouse_hotel_info_add_toolbar_unordered_list: "미주문 목록",
      hotel_inhouse_hotel_info_add_toolbar_ordered_list: "주문 목록",
      hotel_inhouse_hotel_info_add_toolbar_paragraph: "단락",
      hotel_inhouse_hotel_info_add_toolbar_line_height: "줄 높이",
      hotel_inhouse_hotel_info_add_placeholder_content: "내용을 입력하세요",
      hotel_inhouse_hotel_info_add_button_cancel: "취소",
      hotel_inhouse_hotel_info_add_button_ok: "확인",
      hotel_inhouse_hotel_info_edit_title: "호텔 정보 수정",
      hotel_inhouse_hotel_info_edit_placeholder_title: "호칭",
      hotel_inhouse_hotel_info_edit_select_kr: "한국어",
      hotel_inhouse_hotel_info_edit_select_en: "영어",
      hotel_inhouse_hotel_info_edit_select_cn: "중국어",
      hotel_inhouse_hotel_info_edit_select_jp: "일본어",
      hotel_inhouse_hotel_info_edit_placeholder_index: "색인",
      hotel_inhouse_hotel_info_edit_toolbar_bold: "굵게",
      hotel_inhouse_hotel_info_edit_toolbar_italic: "이탤릭",
      hotel_inhouse_hotel_info_edit_toolbar_underline: "밑줄",
      hotel_inhouse_hotel_info_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_inhouse_hotel_info_edit_toolbar_font_family: "글꼴 모음",
      hotel_inhouse_hotel_info_edit_toolbar_font_size: "글꼴 크기",
      hotel_inhouse_hotel_info_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_inhouse_hotel_info_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_inhouse_hotel_info_edit_toolbar_unordered_list: "미주문 목록",
      hotel_inhouse_hotel_info_edit_toolbar_ordered_list: "주문 목록",
      hotel_inhouse_hotel_info_edit_toolbar_paragraph: "단락",
      hotel_inhouse_hotel_info_edit_toolbar_code_view: "코드 보기",
      hotel_inhouse_hotel_info_edit_toolbar_line_height: "줄 높이",
      hotel_inhouse_hotel_info_edit_button_append: "추가",
      hotel_inhouse_hotel_info_edit_button_remove: "제거",
      hotel_inhouse_hotel_info_edit_button_cancel: "취소",
      hotel_inhouse_hotel_info_edit_button_ok: "확인",
      hotel_inhouse_room_to_room_list_title: "룸투룸 목록",
      hotel_inhouse_room_to_room_list_button_alive: "사용 중",
      hotel_inhouse_room_to_room_list_placeholder_select_box_room_to_room: "룸투룸",
      hotel_inhouse_room_to_room_list_select_room_no: "객실 번호",
      hotel_inhouse_room_to_room_list_select_email: "이메일",
      hotel_inhouse_room_to_room_list_select_user_name: "사용자명",
      hotel_inhouse_room_to_room_list_placeholder_search: "검색",
      hotel_inhouse_room_to_room_list_label_no: "번호",
      hotel_inhouse_room_to_room_list_label_sender_room_no: "발신자 객실 번호",
      hotel_inhouse_room_to_room_list_label_sender_name: "발신자명",
      hotel_inhouse_room_to_room_list_label_sender_email: "발신자 이메일",
      hotel_inhouse_room_to_room_list_label_key_type: "키 타입",
      hotel_inhouse_room_to_room_list_label_expired_at: "만료 날짜",
      hotel_inhouse_room_to_room_list_label_receiver_room_no: "수신자 객실 번호",
      hotel_inhouse_room_to_room_list_label_receiver_email: "수신자 이메일",
      hotel_inhouse_room_to_room_list_label_receiver_status: "수신자 현황",
      hotel_inhouse_room_to_room_list_label_date: "날짜",
      hotel_inhouse_room_to_room_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_room_to_room_detail_title: "룸투룸 상세정보",
      hotel_inhouse_room_to_room_detail_label_sender_room_no: "발신자 객실 번호",
      hotel_inhouse_room_to_room_detail_label_sender_name: "발신자명",
      hotel_inhouse_room_to_room_detail_label_sender_email: "발신자 이메일",
      hotel_inhouse_room_to_room_detail_label_key_type: "룸투룸 키 타입",
      hotel_inhouse_room_to_room_detail_label_expired_at: "룸투룸 만료날짜",
      hotel_inhouse_room_to_room_detail_label_receiver_room_no: "수신자 객실 번호",
      hotel_inhouse_room_to_room_detail_label_receiver_email: "수신자 이메일",
      hotel_inhouse_room_to_room_detail_label_receiver_status: "수신자 현황",
      hotel_inhouse_room_to_room_detail_label_created_at: "생성 시간",
      hotel_inhouse_room_to_room_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_concierge_request_list_title: "컨시어지 요청 목록 (확인 전)",
      hotel_inhouse_concierge_request_list_label_that_date_request: "고객 요청 수",
      hotel_inhouse_concierge_request_list_label_that_date_not_confirmed: "고객 요청 (확인 전)",
      hotel_inhouse_concierge_request_list_label_taxi_ex: "택시 익스프레스",
      hotel_inhouse_concierge_request_list_label_taxi: "택시",
      hotel_inhouse_concierge_request_list_label_parcel_ex: "택배 익스프레스",
      hotel_inhouse_concierge_request_list_label_parcel: "택배 서비스",
      hotel_inhouse_concierge_request_list_label_luggage_ex: "수하물 서비스 익스프레스",
      hotel_inhouse_concierge_request_list_label_luggage: "수하물 서비스",
      hotel_inhouse_concierge_request_list_label_total: "총 요금",
      hotel_inhouse_concierge_request_list_select_request_type: "요청 타입 ( 모두 )",
      hotel_inhouse_concierge_request_list_select_request_type_all: "모두",
      hotel_inhouse_concierge_request_list_select_taxi: "택시 서비스",
      hotel_inhouse_concierge_request_list_select_parcel: "택배 서비스",
      hotel_inhouse_concierge_request_list_select_luggage: "수하물 서비스",
      hotel_inhouse_concierge_request_list_select_express: "태스크 ( 모두 )",
      hotel_inhouse_concierge_request_list_select_express_all: "모두",
      hotel_inhouse_concierge_request_list_select_yes: "익스프레스",
      hotel_inhouse_concierge_request_list_select_no: "일반",
      hotel_inhouse_concierge_request_list_select_request: "요청 ( 모두 )",
      hotel_inhouse_concierge_request_list_select_request_all: "모두",
      hotel_inhouse_concierge_request_list_select_room_no: "객실 번호",
      hotel_inhouse_concierge_request_list_select_user_name: "사용자명",
      hotel_inhouse_concierge_request_list_placeholder_search: "검색",
      hotel_inhouse_concierge_request_list_label_room_no: "객실 번호",
      hotel_inhouse_concierge_request_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_concierge_request_list_label_user_name: "사용자명",
      hotel_inhouse_concierge_request_list_label_request_date: "요청 날짜",
      hotel_inhouse_concierge_request_list_label_request_type: "요청 타입",
      hotel_inhouse_concierge_request_list_label_express: "태스크",
      hotel_inhouse_concierge_request_list_label_number_of_item: "항목 수",
      hotel_inhouse_concierge_request_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_concierge_request_list_label_date: "날짜",
      hotel_inhouse_concierge_request_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_concierge_request_detail_title: "요청 상세",
      hotel_inhouse_concierge_request_detail_label_room_no: "객실 번호",
      hotel_inhouse_concierge_request_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_concierge_request_detail_label_user_name: "사용자명",
      hotel_inhouse_concierge_request_detail_label_email: "이메일",
      hotel_inhouse_concierge_request_detail_label_stay: "투숙",
      hotel_inhouse_concierge_request_detail_label_request_type: "요청 타입",
      hotel_inhouse_concierge_request_detail_label_status: "현황",
      hotel_inhouse_concierge_request_detail_label_number_of_item: "항목 수",
      hotel_inhouse_concierge_request_detail_label_request_at: "요청 시간",
      hotel_inhouse_concierge_request_detail_label_created_at: "생성 시간",
      hotel_inhouse_concierge_request_detail_label_total: "총 금액",
      hotel_inhouse_concierge_request_detail_button_not_available: "서비스 불가",
      hotel_inhouse_concierge_request_detail_button_myconect: "MyCONECT",
      hotel_inhouse_concierge_request_detail_button_confirm: "확인",
      hotel_inhouse_concierge_request_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_concierge_request_modal_title: "MyCONECT",
      hotel_inhouse_concierge_request_modal_button_one_time: "한번",
      hotel_inhouse_concierge_request_modal_button_one_hour: "1시간 후",
      hotel_inhouse_concierge_request_modal_button_two_hour: "2시간 후",
      hotel_inhouse_concierge_request_modal_button_three_hour: "3시간 후",
      hotel_inhouse_concierge_request_modal_button_unlimited: "알 수 없음",
      hotel_inhouse_concierge_request_modal_button_cancel: "취소",
      hotel_inhouse_concierge_process_list_title: "컨시어지 진행중 목록\n (확인 후)",
      hotel_inhouse_concierge_process_list_label_request: "고객 요청 수",
      hotel_inhouse_concierge_process_list_label_unassigned: "날짜 미지정",
      hotel_inhouse_concierge_process_list_label_taxi_ex: "택시 익스프레스",
      hotel_inhouse_concierge_process_list_label_taxi: "택시",
      hotel_inhouse_concierge_process_list_label_parcel_ex: "택배 익스프레스",
      hotel_inhouse_concierge_process_list_label_parcel: "택배 서비스",
      hotel_inhouse_concierge_process_list_label_luggage_ex: "수하물 서비스 익스프레스",
      hotel_inhouse_concierge_process_list_label_luggage: "수하물 서비스",
      hotel_inhouse_concierge_process_list_label_total: "총 요금",
      hotel_inhouse_concierge_process_list_select_request_type: "요청 타입 ( 모두 )",
      hotel_inhouse_concierge_process_list_select_taxi: "택시",
      hotel_inhouse_concierge_process_list_select_parcel: "택배 서비스",
      hotel_inhouse_concierge_process_list_select_luggage: "수하물 서비스",
      hotel_inhouse_concierge_process_list_select_express: "태스크 ( 모두 )",
      hotel_inhouse_concierge_process_list_select_yes: "익스프레스",
      hotel_inhouse_concierge_process_list_select_no: "일반",
      hotel_inhouse_concierge_process_list_select_request: "요청 ( 모두 )",
      hotel_inhouse_concierge_process_list_select_room_no: "객실 번호",
      hotel_inhouse_concierge_process_list_select_user_name: "사용자명",
      hotel_inhouse_concierge_process_list_placeholder_search: "검색",
      hotel_inhouse_concierge_process_list_label_room_no: "객실 번호",
      hotel_inhouse_concierge_process_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_concierge_process_list_label_user_name: "사용자명",
      hotel_inhouse_concierge_process_list_label_request_date: "요청 날짜",
      hotel_inhouse_concierge_process_list_label_request_type: "요청 타입",
      hotel_inhouse_concierge_process_list_label_express: "태스크",
      hotel_inhouse_concierge_process_list_label_number_of_item: "항목 수",
      hotel_inhouse_concierge_process_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_concierge_process_list_label_date: "날짜",
      hotel_inhouse_concierge_process_list_guide_empty: "목록이 비어 있습니다",
      hotel_inhouse_concierge_process_detail_title: "요청 (요청 후) 상세정보",
      hotel_inhouse_concierge_process_detail_label_room_no: "객실 번호",
      hotel_inhouse_concierge_process_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_concierge_process_detail_label_user_name: "사용자명",
      hotel_inhouse_concierge_process_detail_label_email: "이메일",
      hotel_inhouse_concierge_process_detail_label_stay: "투숙",
      hotel_inhouse_concierge_process_detail_label_request_type: "요청 타입",
      hotel_inhouse_concierge_process_detail_label_status: "현황",
      hotel_inhouse_concierge_process_detail_label_number_of_item: "항목 개수",
      hotel_inhouse_concierge_process_detail_label_request_at: "요청 시간",
      hotel_inhouse_concierge_process_detail_label_created_at: "생성 시간",
      hotel_inhouse_concierge_process_detail_button_stand_by: "대기",
      hotel_inhouse_concierge_process_detail_button_vehicle_no: "차량 번호",
      hotel_inhouse_concierge_process_detail_button_complete: "완료",
      hotel_inhouse_concierge_process_detail_button_stop: "중단",
      hotel_inhouse_concierge_process_detail_button_pickup: "픽업",
      hotel_inhouse_concierge_process_detail_button_start: "시작",
      hotel_inhouse_concierge_process_detail_button_deliver: "배달",
      hotel_inhouse_concierge_process_detail_button_change: "변경",
      hotel_inhouse_concierge_process_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_concierge_process_modal_title_vehicle_no: "차량 번호",
      hotel_inhouse_concierge_process_modal_placeholder_vehicle_no: "차량 번호를 입력하세요",
      hotel_inhouse_concierge_process_modal_title_luggage: "수하물 개수",
      hotel_inhouse_concierge_process_modal_placeholder_luggage: "수하물 개수를 입력하세요.",
      hotel_inhouse_concierge_process_modal_title_parcel: "택배 정보",
      hotel_inhouse_concierge_process_modal_button_cancel: "취소",
      hotel_inhouse_concierge_process_modal_button_ok: "확인",
      hotel_inhouse_concierge_process_modal_title_stop_message: "중단 메시지",
      hotel_inhouse_concierge_process_modal_button_unknown_situation: "현황 파악 불가",
      hotel_inhouse_concierge_process_modal_button_no_taxi: "택시 노쇼",
      hotel_inhouse_concierge_process_modal_button_no_guest: "고객 노쇼",
      hotel_inhouse_concierge_process_modal_button_waiting_30: "대기 시간 30분 초과",
      hotel_inhouse_concierge_process_modal_button_waiting_60: "대기 시간 60분 초과",
      hotel_inhouse_concierge_process_modal_button_no_motorcycle: "배달 오토바이 노쇼",
      hotel_inhouse_concierge_process_modal_button_not_luggage: "수하물 준비 안됨",
      hotel_inhouse_concierge_myconect_list_title: "컨시어지 스케줄 처리 결과 목록",
      hotel_inhouse_concierge_myconect_list_label_user_count: "사용자 취소 개수",
      hotel_inhouse_concierge_myconect_list_label_hotel_count: "호텔 취소 개수",
      hotel_inhouse_concierge_myconect_list_label_taxi_ex: "택시 익스프레스",
      hotel_inhouse_concierge_myconect_list_label_taxi: "택시",
      hotel_inhouse_concierge_myconect_list_label_parcel_ex: "택배 익스프레스",
      hotel_inhouse_concierge_myconect_list_label_parcel: "택배 서비스",
      hotel_inhouse_concierge_myconect_list_label_luggage_ex: "수하물 서비스 익스프레스",
      hotel_inhouse_concierge_myconect_list_label_luggage: "수하물 서비스",
      hotel_inhouse_concierge_myconect_list_label_total: "총 금액",
      hotel_inhouse_concierge_myconect_list_label_room_no: "객실 번호",
      hotel_inhouse_concierge_myconect_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_concierge_myconect_list_label_user_name: "사용자명",
      hotel_inhouse_concierge_myconect_list_label_request_date: "요청 날짜",
      hotel_inhouse_concierge_myconect_list_label_request_type: "요청 타입",
      hotel_inhouse_concierge_myconect_list_label_express: "태스크",
      hotel_inhouse_concierge_myconect_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_concierge_myconect_list_label_myconect_at: "MyCONECT 동작 시간",
      hotel_inhouse_concierge_myconect_list_label_completed_at: "완료 시간",
      hotel_inhouse_concierge_myconect_list_label_date: "날짜",
      hotel_inhouse_concierge_myconect_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_concierge_myconect_detail_title: "스케줄 처리 결과 상세",
      hotel_inhouse_concierge_myconect_detail_label_room_no: "객실 번호",
      hotel_inhouse_concierge_myconect_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_concierge_myconect_detail_label_user_name: "사용자명",
      hotel_inhouse_concierge_myconect_detail_label_email: "이메일",
      hotel_inhouse_concierge_myconect_detail_label_stay: "투숙",
      hotel_inhouse_concierge_myconect_detail_label_request_type: "요청 타입",
      hotel_inhouse_concierge_myconect_detail_label_status: "현황",
      hotel_inhouse_concierge_myconect_detail_label_number_of_item: "항목 수",
      hotel_inhouse_concierge_myconect_detail_label_request_at: "요청 시간",
      hotel_inhouse_concierge_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_concierge_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_concierge_multi_myconect_list_title: "컨시어지 스케줄 설정 목록",
      hotel_inhouse_concierge_multi_myconect_list_button_alive: "사용 중",
      hotel_inhouse_concierge_multi_myconect_list_label_staff_number: "스태프 번호",
      hotel_inhouse_concierge_multi_myconect_list_label_request: "요청",
      hotel_inhouse_concierge_multi_myconect_list_label_department: "부서",
      hotel_inhouse_concierge_multi_myconect_list_label_email: "이메일",
      hotel_inhouse_concierge_multi_myconect_list_label_respond: "MyCONECT 응답",
      hotel_inhouse_concierge_multi_myconect_list_label_from: "~부터",
      hotel_inhouse_concierge_multi_myconect_list_label_to: "~까지",
      hotel_inhouse_concierge_multi_myconect_list_label_date: "날짜",
      hotel_inhouse_concierge_multi_myconect_list_button_add: "추가",
      hotel_inhouse_concierge_multi_myconect_add_title: "컨시어지 스케줄 설정 추가",
      hotel_inhouse_concierge_multi_myconect_add_label_request_type: "요청 타입",
      hotel_inhouse_concierge_multi_myconect_add_placeholder_select_box_choose: "선택",
      hotel_inhouse_concierge_multi_myconect_add_select_box_luggage: "수하물 서비스",
      hotel_inhouse_concierge_multi_myconect_add_select_box_parcel: "택배 서비스",
      hotel_inhouse_concierge_multi_myconect_add_select_box_taxi: "택시",
      hotel_inhouse_concierge_multi_myconect_add_label_date: "날짜",
      hotel_inhouse_concierge_multi_myconect_add_label_respond: "MyCONECT 응답",
      hotel_inhouse_concierge_multi_myconect_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_concierge_multi_myconect_add_button_add: "추가",
      hotel_inhouse_concierge_multi_myconect_add_validation_type_request: "* 요청 타입을 선택해주세요.",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_start: "* 날짜를 선택해주세요.",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_end: "* 날짜를 선택해주세요.",
      hotel_inhouse_concierge_multi_myconect_add_validation_respond: "* MyCONECT 응답을 선택해주세요.",
      hotel_inhouse_concierge_multi_myconect_detail_title: "컨시어지 스케줄 설정 정보",
      hotel_inhouse_concierge_multi_myconect_detail_label_update: "업데이트 날짜",
      hotel_inhouse_concierge_multi_myconect_detail_label_date: "날짜",
      hotel_inhouse_concierge_multi_myconect_detail_label_respond: "MyCONECT 응답",
      hotel_inhouse_concierge_multi_myconect_detail_label_staff_number: "스태프 번호",
      hotel_inhouse_concierge_multi_myconect_detail_label_request: "요청",
      hotel_inhouse_concierge_multi_myconect_detail_label_department: "부서",
      hotel_inhouse_concierge_multi_myconect_detail_label_email: "이메일",
      hotel_inhouse_concierge_multi_myconect_detail_label_released_at: "해제 시간",
      hotel_inhouse_concierge_multi_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_concierge_multi_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_concierge_multi_myconect_detail_button_cancel: "취소",
      hotel_inhouse_concierge_multi_myconect_detail_button_release: "해제",
      hotel_inhouse_concierge_multi_myconect_detail_button_edit: "수정",
      hotel_inhouse_concierge_multi_myconect_detail_button_ok: "확인",
      hotel_inhouse_housekeeping_request_list_title: "하우스키핑 요청 목록 (확인 전)",
      hotel_inhouse_housekeeping_request_list_label_request: "고객 요청 수",
      hotel_inhouse_housekeeping_request_list_label_not_confirmed: "날짜 미확인",
      hotel_inhouse_housekeeping_request_list_label_mur: "객실 청소",
      hotel_inhouse_housekeeping_request_list_label_tds: "객실 정돈",
      hotel_inhouse_housekeeping_request_list_label_green: "그린 캠페인",
      hotel_inhouse_housekeeping_request_list_label_ironing_ex: "다림질 익스프레스",
      hotel_inhouse_housekeeping_request_list_label_ironing: "다림질 서비스",
      hotel_inhouse_housekeeping_request_list_label_laundry_ex: "세탁 익스프레스",
      hotel_inhouse_housekeeping_request_list_label_laundry: "세탁 서비스",
      hotel_inhouse_housekeeping_request_list_label_amenity: "어메니티 서비스",
      hotel_inhouse_housekeeping_request_list_label_total: "총 요금",
      hotel_inhouse_housekeeping_request_list_select_request_type: "요청 타입 ( 모두 )",
      hotel_inhouse_housekeeping_request_list_select_mur: "객실 청소",
      hotel_inhouse_housekeeping_request_list_select_tds: "객실 정돈",
      hotel_inhouse_housekeeping_request_list_select_green: "그린 캠페인",
      hotel_inhouse_housekeeping_request_list_select_ironing: "다림질 서비스",
      hotel_inhouse_housekeeping_request_list_select_laundry: "세탁 서비스",
      hotel_inhouse_housekeeping_request_list_select_amenity: "어메니티 서비스",
      hotel_inhouse_housekeeping_request_list_select_request_express: "태스크 ( 모두 )",
      hotel_inhouse_housekeeping_request_list_select_yes: "익스프레스",
      hotel_inhouse_housekeeping_request_list_select_no: "일반",
      hotel_inhouse_housekeeping_request_list_select_request: "요청 ( 모두 )",
      hotel_inhouse_housekeeping_request_list_select_room_no: "객실 번호",
      hotel_inhouse_housekeeping_request_list_select_user_name: "사용자명",
      hotel_inhouse_housekeeping_request_list_placeholder_search: "검색",
      hotel_inhouse_housekeeping_request_list_label_roon_no: "객실 번호",
      hotel_inhouse_housekeeping_request_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_housekeeping_request_list_label_user_name: "사용자명",
      hotel_inhouse_housekeeping_request_list_label_request_date: "요청 날짜",
      hotel_inhouse_housekeeping_request_list_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_request_list_label_express: "태스크",
      hotel_inhouse_housekeeping_request_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_housekeeping_request_list_label_date: "날짜",
      hotel_inhouse_housekeeping_request_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_housekeeping_request_detail_title: "요청 상세",
      hotel_inhouse_housekeeping_request_detail_label_roon_no: "객실 번호",
      hotel_inhouse_housekeeping_request_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_housekeeping_request_detail_label_user_name: "사용자명",
      hotel_inhouse_housekeeping_request_detail_label_email: "이메일",
      hotel_inhouse_housekeeping_request_detail_label_stay: "투숙",
      hotel_inhouse_housekeeping_request_detail_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_request_detail_label_status: "현황",
      hotel_inhouse_housekeeping_request_detail_label_request_at: "요청 시간",
      hotel_inhouse_housekeeping_request_detail_label_created_at: "생성 시간",
      hotel_inhouse_housekeeping_request_detail_label_total: "총 요금",
      hotel_inhouse_housekeeping_request_detail_button_not_availble: "서비스 불가",
      hotel_inhouse_housekeeping_request_detail_button_myconect: "MyCONECT",
      hotel_inhouse_housekeeping_request_detail_button_auto: "자동",
      hotel_inhouse_housekeeping_request_detail_button_delay: "지연",
      hotel_inhouse_housekeeping_request_detail_button_confirm: "확인",
      hotel_inhouse_housekeeping_request_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_housekeeping_request_modal_title_delay: "지연 메시지",
      hotel_inhouse_housekeeping_request_modal_button_delay_30: "30분 지연",
      hotel_inhouse_housekeeping_request_modal_button_delay_60: "60분 지연",
      hotel_inhouse_housekeeping_request_modal_button_as_soon_as: "빠른 시간 내",
      hotel_inhouse_housekeeping_request_modal_title_myconect: "MyCONECT",
      hotel_inhouse_housekeeping_request_modal_button_one_time: "한번",
      hotel_inhouse_housekeeping_request_modal_button_one_hour: "1시간 후",
      hotel_inhouse_housekeeping_request_modal_button_two_hour: "2시간 후",
      hotel_inhouse_housekeeping_request_modal_button_three_hour: "3시간 후",
      hotel_inhouse_housekeeping_request_modal_button_unlimited: "알 수 없음",
      hotel_inhouse_housekeeping_request_modal_button_cancel: "취소",
      hotel_inhouse_housekeeping_process_list_title: "하우스키핑 진행중 목록 (확인 완료)",
      hotel_inhouse_housekeeping_process_list_label_request: "고객 요청 수",
      hotel_inhouse_housekeeping_process_list_label_unassigned: "날짜 미지정",
      hotel_inhouse_housekeeping_process_list_label_mur: "객실 청소",
      hotel_inhouse_housekeeping_process_list_label_tds: "객실 정돈",
      hotel_inhouse_housekeeping_process_list_label_housekeeping_total: "총",
      hotel_inhouse_housekeeping_process_list_label_ironing_ex: "다림질 익스프레스",
      hotel_inhouse_housekeeping_process_list_label_ironing: "다림질 서비스",
      hotel_inhouse_housekeeping_process_list_label_laundry_ex: "세탁 익스프레스",
      hotel_inhouse_housekeeping_process_list_label_laundry: "세탁 서비스",
      hotel_inhouse_housekeeping_process_list_label_guest_service_total: "총 요금",
      hotel_inhouse_housekeeping_process_list_label_green: "그린 캠페인",
      hotel_inhouse_housekeeping_process_list_label_dnd: "방해 금지",
      hotel_inhouse_housekeeping_process_list_label_amenity_number: "어메니티 항목 수",
      hotel_inhouse_housekeeping_process_list_label_bath_towel: "배스 타올",
      hotel_inhouse_housekeeping_process_list_label_face_towel: "페이스 타올",
      hotel_inhouse_housekeeping_process_list_label_hand_towel: "핸드 타올",
      hotel_inhouse_housekeeping_process_list_label_bath_gown: "목욕 가운",
      hotel_inhouse_housekeeping_process_list_label_razor: "면도기",
      hotel_inhouse_housekeeping_process_list_label_shampoo: "샴푸",
      hotel_inhouse_housekeeping_process_list_label_conditioner: "컨디셔너",
      hotel_inhouse_housekeeping_process_list_label_soap: "비누",
      hotel_inhouse_housekeeping_process_list_label_amenity_total: "총",
      hotel_inhouse_housekeeping_process_list_select_request_type: "요청 타입 ( 모두 )",
      hotel_inhouse_housekeeping_process_list_select_mur: "객실 청소",
      hotel_inhouse_housekeeping_process_list_select_tds: "객실 정돈",
      hotel_inhouse_housekeeping_process_list_select_green: "그린 캠페인",
      hotel_inhouse_housekeeping_process_list_select_dnd: "방해 금지",
      hotel_inhouse_housekeeping_process_list_select_ironing: "다림질 서비스",
      hotel_inhouse_housekeeping_process_list_select_laundry: "세탁 서비스",
      hotel_inhouse_housekeeping_process_list_select_amenity: "어메니티 서비스",
      hotel_inhouse_housekeeping_process_list_select_express: "태스크 ( 모두 )",
      hotel_inhouse_housekeeping_process_list_select_express_all: "모두",
      hotel_inhouse_housekeeping_process_list_select_yes: "익스프레스",
      hotel_inhouse_housekeeping_process_list_select_no: "일반",
      hotel_inhouse_housekeeping_process_list_select_floor: "층 ( 모두 )",
      hotel_inhouse_housekeeping_process_list_select_floor_1: "1층",
      hotel_inhouse_housekeeping_process_list_select_floor_2: "2층",
      hotel_inhouse_housekeeping_process_list_select_floor_3: "3층",
      hotel_inhouse_housekeeping_process_list_select_floor_4: "4층",
      hotel_inhouse_housekeeping_process_list_select_floor_5: "5층",
      hotel_inhouse_housekeeping_process_list_select_floor_6: "6층",
      hotel_inhouse_housekeeping_process_list_select_floor_7: "7층",
      hotel_inhouse_housekeeping_process_list_select_floor_8: "8층",
      hotel_inhouse_housekeeping_process_list_select_request: "요청 ( 모두 )",
      hotel_inhouse_housekeeping_process_list_select_room_no: "객실 번호",
      hotel_inhouse_housekeeping_process_list_placeholder_search: "검색",
      hotel_inhouse_housekeeping_process_list_label_roon_no: "객실 번호",
      hotel_inhouse_housekeeping_process_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_housekeeping_process_list_label_user_name: "사용자명",
      hotel_inhouse_housekeeping_process_list_label_request_date: "요청 날짜",
      hotel_inhouse_housekeeping_process_list_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_process_list_label_express: "태스크",
      hotel_inhouse_housekeeping_process_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_housekeeping_process_list_label_date: "날짜",
      hotel_inhouse_housekeeping_process_list_guide_empty: "목록이 비어 있습니다",
      hotel_inhouse_housekeeping_process_detail_title: "요청 상세",
      hotel_inhouse_housekeeping_process_detail_label_roon_no: "객실 번호",
      hotel_inhouse_housekeeping_process_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_housekeeping_process_detail_label_user_name: "사용자명",
      hotel_inhouse_housekeeping_process_detail_label_email: "이메일",
      hotel_inhouse_housekeeping_process_detail_label_stay: "투숙",
      hotel_inhouse_housekeeping_process_detail_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_process_detail_label_status: "현황",
      hotel_inhouse_housekeeping_process_detail_label_request_at: "요청 시간",
      hotel_inhouse_housekeeping_process_detail_label_created_at: "생성 시간",
      hotel_inhouse_housekeeping_process_detail_button_put_off: "연기",
      hotel_inhouse_housekeeping_process_detail_button_complete: "완료",
      hotel_inhouse_housekeeping_process_detail_button_start: "시작",
      hotel_inhouse_housekeeping_process_detail_button_stop: "중단",
      hotel_inhouse_housekeeping_process_detail_button_deliver: "배달",
      hotel_inhouse_housekeeping_process_detail_button_pickup: "픽업",
      hotel_inhouse_housekeeping_process_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_housekeeping_process_modal_title_put_off: "연기 메시지",
      hotel_inhouse_housekeeping_process_modal_button_cancel: "취소",
      hotel_inhouse_housekeeping_process_modal_button_delay_30: "30분 지연",
      hotel_inhouse_housekeeping_process_modal_button_delay_60: "60분 지연",
      hotel_inhouse_housekeeping_process_modal_button_as_soon_as: "빠른 시간 내",
      hotel_inhouse_housekeeping_process_modal_button_out_of_order: "객실 불량",
      hotel_inhouse_housekeeping_process_modal_title_stop_message: "중단 메시지",
      hotel_inhouse_housekeeping_process_modal_button_unknown_situation: "현황 파악 불가",
      hotel_inhouse_housekeeping_process_modal_button_cancel_service: "서비스 취소",
      hotel_inhouse_housekeeping_process_modal_button_not_found_it: "찾을 수 없음",
      hotel_inhouse_housekeeping_process_modal_button_no_guest_room: "고객 부재",
      hotel_inhouse_housekeeping_process_modal_button_change_items: "항목 변경",
      hotel_inhouse_housekeeping_myconect_list_title: "하우스키핑 스케줄 처리 결과 목록",
      hotel_inhouse_housekeeping_myconect_list_label_user_count: "사용자 취소 카운트",
      hotel_inhouse_housekeeping_myconect_list_label_hotel_count: "호텔 취소 카운트",
      hotel_inhouse_housekeeping_myconect_list_label_mur: "객실 청소",
      hotel_inhouse_housekeeping_myconect_list_label_tds: "객실 정돈",
      hotel_inhouse_housekeeping_myconect_list_label_ironing_ex: "다림질 익스프레스",
      hotel_inhouse_housekeeping_myconect_list_label_ironing: "다림질 서비스",
      hotel_inhouse_housekeeping_myconect_list_label_laundry_ex: "세탁 익스프레스",
      hotel_inhouse_housekeeping_myconect_list_label_laundry: "세탁 서비스",
      hotel_inhouse_housekeeping_myconect_list_label_total: "총 요금",
      hotel_inhouse_housekeeping_myconect_list_label_roon_no: "객실 번호",
      hotel_inhouse_housekeeping_myconect_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_housekeeping_myconect_list_label_user_name: "사용자명",
      hotel_inhouse_housekeeping_myconect_list_label_request_date: "요청 날짜",
      hotel_inhouse_housekeeping_myconect_list_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_myconect_list_label_express: "태스크",
      hotel_inhouse_housekeeping_myconect_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_housekeeping_myconect_list_label_myconect_at: "MyCONECT 동작 시간",
      hotel_inhouse_housekeeping_myconect_list_label_completed_at: "완료 시간",
      hotel_inhouse_housekeeping_myconect_list_label_date: "날짜",
      hotel_inhouse_housekeeping_myconect_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_housekeeping_myconect_detail_title: "스케줄 처리 결과 상세",
      hotel_inhouse_housekeeping_myconect_detail_label_roon_no: "객실 번호",
      hotel_inhouse_housekeeping_myconect_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_housekeeping_myconect_detail_label_user_name: "사용자명",
      hotel_inhouse_housekeeping_myconect_detail_label_email: "이메일",
      hotel_inhouse_housekeeping_myconect_detail_label_stay: "투숙",
      hotel_inhouse_housekeeping_myconect_detail_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_myconect_detail_label_status: "현황",
      hotel_inhouse_housekeeping_myconect_detail_label_request_at: "요청 시간",
      hotel_inhouse_housekeeping_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_housekeeping_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_housekeeping_multi_myconect_list_title: "하우스키핑 스케줄 목록",
      hotel_inhouse_housekeeping_multi_myconect_list_button_alive: "사용 중",
      hotel_inhouse_housekeeping_multi_myconect_list_label_staff_number: "스태프 번호",
      hotel_inhouse_housekeeping_multi_myconect_list_label_request: "요청",
      hotel_inhouse_housekeeping_multi_myconect_list_label_department: "부서",
      hotel_inhouse_housekeeping_multi_myconect_list_label_email: "이메일",
      hotel_inhouse_housekeeping_multi_myconect_list_label_respond: "MyCONECT 응답",
      hotel_inhouse_housekeeping_multi_myconect_list_label_from: "~부터",
      hotel_inhouse_housekeeping_multi_myconect_list_label_to: "~까지",
      hotel_inhouse_housekeeping_multi_myconect_list_label_date: "날짜",
      hotel_inhouse_housekeeping_multi_myconect_list_button_add: "추가",
      hotel_inhouse_housekeeping_multi_myconect_add_title: "하우스키핑 스케줄 추가",
      hotel_inhouse_housekeeping_multi_myconect_add_label_request_type: "요청 타입",
      hotel_inhouse_housekeeping_multi_myconect_add_placeholder_select_box_choose: "선택",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_ironing: "다림질 서비스",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_laundry: "세탁 서비스",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_amenity: "어메니티 서비스",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_make_up_room: "객실 청소",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_turn_down_service: "객실 정돈",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_green_campaign: "그린 캠페인",
      hotel_inhouse_housekeeping_multi_myconect_add_label_date: "날짜",
      hotel_inhouse_housekeeping_multi_myconect_add_label_respond: "MyCONECT 응답",
      hotel_inhouse_housekeeping_multi_myconect_add_select_not_availble: "사용 불가",
      hotel_inhouse_housekeeping_multi_myconect_add_select_confirm: "확인",
      hotel_inhouse_housekeeping_multi_myconect_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_housekeeping_multi_myconect_add_button_add: "추가",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_type_request: "* 요청 타입을 선택해주세요.",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_start: "* 날짜를 선택해주세요.",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_end: "* 날짜를 선택해주세요.",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_respond: "* MyCONECT 응답을 선택해주세요.",
      hotel_inhouse_housekeeping_multi_myconect_add_button_set: "설정",
      hotel_inhouse_housekeeping_multi_myconect_detail_title: "하우스키핑 스케줄 정보",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_update: "업데이트 날짜",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_date: "날짜",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_respond: "MyCONECT 응답",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_staff_number: "스태프 번호",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_request: "요청",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_department: "부서",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_email: "이메일",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_released_at: "해제 시간",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_cancel: "취소",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_release: "해제",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_edit: "수정",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_ok: "확인",
      hotel_inhouse_valet_list_title: "발렛파킹 요청 목록",
      hotel_inhouse_valet_list_label_valet_requst: "발렛 요청",
      hotel_inhouse_valet_list_label_requesting: "요청 중",
      hotel_inhouse_valet_list_label_parking: "주차",
      hotel_inhouse_valet_list_label_waiting: "대기 중",
      hotel_inhouse_valet_list_label_preparing: "준비 중",
      hotel_inhouse_valet_list_label_standby: "대기",
      hotel_inhouse_valet_list_label_return: "반환",
      hotel_inhouse_valet_list_label_total: "총",
      hotel_inhouse_valet_list_placeholder_select_box_request: "요청 ( 모두 )",
      hotel_inhouse_valet_list_select_request_all: "모두",
      hotel_inhouse_valet_list_select_vehicle_no: "차량 번호",
      hotel_inhouse_valet_list_select_room_no: "객실 번호",
      hotel_inhouse_valet_list_select_user_name: "사용자명",
      hotel_inhouse_valet_list_placeholder_search: "검색",
      hotel_inhouse_valet_list_label_confirmation_no: "예약 번호",
      hotel_inhouse_valet_list_label_room_no: "객실 번호",
      hotel_inhouse_valet_list_label_user_name: "사용자 이름",
      hotel_inhouse_valet_list_label_request_date: "요청 날짜",
      hotel_inhouse_valet_list_label_location_no: "위치 번호",
      hotel_inhouse_valet_list_label_vehicle_no: "차량 번호",
      hotel_inhouse_valet_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_valet_list_label_date: "날짜",
      hotel_inhouse_valet_detail_title: "발렛파킹 요청 상세",
      hotel_inhouse_valet_detail_label_confirmation_no: "예약 번호",
      hotel_inhouse_valet_detail_label_room_no: "객실 번호",
      hotel_inhouse_valet_detail_label_user_name: "사용자 이름",
      hotel_inhouse_valet_detail_label_email: "이메일",
      hotel_inhouse_valet_detail_label_request_type: "요청 타입",
      hotel_inhouse_valet_detail_label_location_no: "위치 번호",
      hotel_inhouse_valet_detail_label_vehicle_no: "차량 번호",
      hotel_inhouse_valet_detail_label_status: "상태",
      hotel_inhouse_valet_detail_label_request_at: "요청 시간",
      hotel_inhouse_valet_detail_label_created_at: "생성 시간",
      hotel_inhouse_valet_detail_button_valet_no: "발렛 접수",
      hotel_inhouse_valet_detail_button_change: "변경",
      hotel_inhouse_valet_detail_button_myconect: "MyCONECT",
      hotel_inhouse_valet_detail_button_stop: "중단",
      hotel_inhouse_valet_detail_button_location: "위치",
      hotel_inhouse_valet_detail_button_standby: "대기",
      hotel_inhouse_valet_detail_button_handover: "인도",
      hotel_inhouse_valet_detail_button_return: "반환",
      hotel_inhouse_valet_detail_button_settlement: "직접 결제",
      hotel_inhouse_valet_detail_button_room_posting: "객실 청구",
      hotel_inhouse_valet_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_valet_detail_button_go_to_detail: "상세로 가기",
      hotel_inhouse_valet_detail_button_payment: "결제",
      hotel_inhouse_valet_detail_business_list_label_no: "번호",
      hotel_inhouse_valet_detail_business_list_label_type: "타입",
      hotel_inhouse_valet_detail_business_list_label_task: "태스크",
      hotel_inhouse_valet_detail_business_list_label_time_from: "사용 가능 시간 ~부터",
      hotel_inhouse_valet_detail_business_list_label_time_to: "사용 가능 시간 ~까지",
      hotel_inhouse_valet_detail_business_list_label_charge_amount: "청구 금액",
      hotel_inhouse_valet_detail_business_list_label_currency: "통화",
      hotel_inhouse_valet_detail_placeholder_select_box_choose: "선택",
      hotel_inhouse_valet_detail_modal_valet_title: "발렛 접수 번호",
      hotel_inhouse_valet_detail_modal_valet_request_received: "요청이 접수 되었습니다.",
      hotel_inhouse_valet_detail_modal_change_title: "차량 번호 변경",
      hotel_inhouse_valet_detail_modal_change_placeholder_vehicle_no: "차량 번호",
      hotel_inhouse_valet_detail_modal_myconect_title: "위치 번호",
      hotel_inhouse_valet_detail_modal_myconect_placeholder_parking_area_no: "주차 구역 번호",
      hotel_inhouse_valet_detail_modal_stop_title: "중단 메시지",
      hotel_inhouse_valet_detail_modal_stop_message_unparked: "주차되지 않은 상태로 취소",
      hotel_inhouse_valet_detail_modal_stop_message_parked: "주차된 상태로 취소",
      hotel_inhouse_valet_detail_modal_stop_message_unknown: "현황 파악 불가",
      hotel_inhouse_valet_detail_modal_valet_location_title: "주차 위치 번호",
      hotel_inhouse_valet_detail_modal_valet_location_message: "차량 픽업을 시작합니다.",
      hotel_inhouse_valet_detail_modal_valet_standby_title: "대기",
      hotel_inhouse_valet_detail_modal_valet_standby_message: "차량 대기를 진행합니다.",
      hotel_inhouse_valet_detail_modal_valet_handover_title: "인도",
      hotel_inhouse_valet_detail_modal_valet_handover_message: "운전자에게 차량 인도를 진행합니다.",
      hotel_inhouse_valet_detail_modal_valet_return_title: "반환",
      hotel_inhouse_valet_detail_modal_valet_return_message: "차량 반환을 시작합니다.",
      hotel_inhouse_valet_detail_modal_return_placeholder_parking_area_no: "주차 구역 번호",
      hotel_inhouse_valet_detail_modal_room_number: "객실 번호",
      hotel_inhouse_valet_detail_modal_button_cancel: "취소",
      hotel_inhouse_valet_detail_modal_button_ok: "확인",
      hotel_inhouse_valet_multi_myconect_list_title: "발렛 스케줄 설정 목록",
      hotel_inhouse_valet_multi_myconect_list_button_alive: "사용 중",
      hotel_inhouse_valet_multi_myconect_list_label_staff_number: "스태프 번호",
      hotel_inhouse_valet_multi_myconect_list_label_request: "요청",
      hotel_inhouse_valet_multi_myconect_list_label_department: "부서",
      hotel_inhouse_valet_multi_myconect_list_label_email: "이메일",
      hotel_inhouse_valet_multi_myconect_list_label_respond: "MyCONECT 응답",
      hotel_inhouse_valet_multi_myconect_list_label_from: "~부터",
      hotel_inhouse_valet_multi_myconect_list_label_to: "~까지",
      hotel_inhouse_valet_multi_myconect_list_label_date: "날짜",
      hotel_inhouse_valet_multi_myconect_list_button_add: "추가",
      hotel_inhouse_valet_myconect_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_valet_multi_myconect_add_title: "발렛 스케줄 설정 추가",
      hotel_inhouse_valet_multi_myconect_add_label_request_type: "요청 타입",
      hotel_inhouse_valet_multi_myconect_add_label_repond: "응답",
      hotel_inhouse_valet_multi_myconect_add_label_delay_time: "지연 시간",
      hotel_inhouse_valet_multi_myconect_add_placeholder_select_box_choose: "선택",
      hotel_inhouse_valet_multi_myconect_add_select_box_not_available: "서비스 불가",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay15: "15분 지연",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay30: "30분 지연",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay45: "45분 지연",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay60: "60분 지연",
      hotel_inhouse_valet_multi_myconect_add_select_box_asaa: "빠른 시간 내",
      hotel_inhouse_valet_multi_myconect_add_select_box_valet: "발렛",
      hotel_inhouse_valet_multi_myconect_add_select_box_waiting_request: "출차대기 요청",
      hotel_inhouse_valet_multi_myconect_add_label_date: "날짜",
      hotel_inhouse_valet_multi_myconect_add_validation_type_request: "* 요청 타입을 선택해주세요.",
      hotel_inhouse_valet_multi_myconect_add_validation_repond: "* 응답을 선택해주세요.",
      hotel_inhouse_valet_multi_myconect_add_validation_delay: "* 지연 시간을 선택해주세요.",
      hotel_inhouse_valet_multi_myconect_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_valet_multi_myconect_add_button_add: "추가",
      hotel_inhouse_valet_multi_myconect_detail_title: "발렛 스케줄 설정 정보",
      hotel_inhouse_valet_multi_myconect_detail_label_update: "업데이트 날짜",
      hotel_inhouse_valet_multi_myconect_detail_label_date: "날짜",
      hotel_inhouse_valet_multi_myconect_detail_label_delay_time: "지연",
      hotel_inhouse_valet_multi_myconect_detail_placeholder_select_box_choose: "선택",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay15: "15분 지연",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay30: "30분 지연",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay45: "45분 지연",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay60: "60분 지연",
      hotel_inhouse_valet_multi_myconect_detail_select_box_asaa: "빠른 시간 내",
      hotel_inhouse_valet_multi_myconect_detail_label_respond: "MyCONECT 응답",
      hotel_inhouse_valet_multi_myconect_detail_label_staff_number: "스태프 번호",
      hotel_inhouse_valet_multi_myconect_detail_label_request: "요쳥",
      hotel_inhouse_valet_multi_myconect_detail_label_department: "부서",
      hotel_inhouse_valet_multi_myconect_detail_label_email: "이메일",
      hotel_inhouse_valet_multi_myconect_detail_label_released_at: "해제 시간",
      hotel_inhouse_valet_multi_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_valet_multi_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_valet_multi_myconect_detail_button_cancel: "취소",
      hotel_inhouse_valet_multi_myconect_detail_button_release: "해제",
      hotel_inhouse_valet_multi_myconect_detail_button_edit: "수정",
      hotel_inhouse_valet_multi_myconect_detail_button_ok: "확인",
      hotel_inhouse_in_room_dining_cart_list_title: "인 룸 다이닝 장바구니 목록",
      hotel_inhouse_in_room_dining_cart_list_placeholder_select_box_select: "선택",
      hotel_inhouse_in_room_dining_cart_list_select_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_cart_list_placeholder_search: "검색",
      hotel_inhouse_in_room_dining_cart_list_label_no: "번호",
      hotel_inhouse_in_room_dining_cart_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_cart_list_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_cart_list_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_cart_list_label_update_date: "날짜 업데이트",
      hotel_inhouse_in_room_dining_cart_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_cart_detail_title: "인 룸 다이닝 장바구니 정보",
      hotel_inhouse_in_room_dining_cart_detail_label_info: "정보",
      hotel_inhouse_in_room_dining_cart_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_cart_detail_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_cart_detail_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_cart_detail_label_updated_at: "날짜 업데이트",
      hotel_inhouse_in_room_dining_cart_detail_label_payment: "결제",
      hotel_inhouse_in_room_dining_cart_detail_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_cart_detail_label_discount_rate: "할인율",
      hotel_inhouse_in_room_dining_cart_detail_label_price: "가격",
      hotel_inhouse_in_room_dining_cart_detail_label_order: "주문",
      hotel_inhouse_in_room_dining_cart_detail_label_no: "번호",
      hotel_inhouse_in_room_dining_cart_detail_label_category: "카테고리",
      hotel_inhouse_in_room_dining_cart_detail_label_menu: "메뉴",
      hotel_inhouse_in_room_dining_cart_detail_label_option: "옵션",
      hotel_inhouse_in_room_dining_cart_detail_label_items: "품목",
      hotel_inhouse_in_room_dining_cart_detail_label_quantity: "수량",
      hotel_inhouse_in_room_dining_cart_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_cart_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_category_list_title: "인 룸 다이닝 카테고리 목록",
      hotel_inhouse_in_room_dining_category_list_label_all: "메뉴 전시여부 (모두)",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_yes: "메뉴 전시여부 (예)",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_no: "메뉴 전시여부 (아니오)",
      hotel_inhouse_in_room_dining_category_list_placeholder_search: "검색",
      hotel_inhouse_in_room_dining_category_list_label_no: "번호",
      hotel_inhouse_in_room_dining_category_list_label_name: "이름",
      hotel_inhouse_in_room_dining_category_list_label_date: "날짜",
      hotel_inhouse_in_room_dining_category_list_label_sorting: "정렬",
      hotel_inhouse_in_room_dining_category_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_category_list_button_delete: "삭제",
      hotel_inhouse_in_room_dining_category_list_button_add: "추가",
      hotel_inhouse_in_room_dining_category_add_title: "인 룸 다이닝 카테고리",
      hotel_inhouse_in_room_dining_category_add_label_name: "이름",
      hotel_inhouse_in_room_dining_category_add_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_category_add_select_yes: "예",
      hotel_inhouse_in_room_dining_category_add_select_no: "아니오",
      hotel_inhouse_in_room_dining_category_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_category_add_button_add: "추가",
      hotel_inhouse_in_room_dining_category_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_category_detail_title: "인 룸 다이닝 카테고리 정보",
      hotel_inhouse_in_room_dining_category_detail_label_name: "이름",
      hotel_inhouse_in_room_dining_category_detail_label_date: "날짜",
      hotel_inhouse_in_room_dining_category_detail_label_category_details: "카테고리 상세 정보",
      hotel_inhouse_in_room_dining_category_detail_label_language: "언어",
      hotel_inhouse_in_room_dining_category_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_category_detail_button_add: "추가",
      hotel_inhouse_in_room_dining_category_detail_button_delete: "삭제",
      hotel_inhouse_in_room_dining_category_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_category_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_category_edit_title: "인 룸 다이닝 카테고리",
      hotel_inhouse_in_room_dining_category_edit_label_category_details: "카테고리 상세 정보",
      hotel_inhouse_in_room_dining_category_edit_label_language: "언어",
      hotel_inhouse_in_room_dining_category_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_category_edit_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_category_edit_select_yes: "예",
      hotel_inhouse_in_room_dining_category_edit_select_no: "아니오",
      hotel_inhouse_in_room_dining_category_edit_label_date: "날짜",
      hotel_inhouse_in_room_dining_category_edit_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_category_edit_button_add: "추가",
      hotel_inhouse_in_room_dining_category_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_category_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_category_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_category_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_category_edit_guide_empty: "목록이 비어 있습니다.",
      hotel_hotel_inhouse_in_room_dining_category_translation_add_title: "인 룸 다이닝 카테고리 번역",
      hotel_inhouse_in_room_dining_category_translation_add_label_language: "언어",
      hotel_inhouse_in_room_dining_category_translation_add_select_ko: "한국어",
      hotel_inhouse_in_room_dining_category_translation_add_select_en: "영어",
      hotel_inhouse_in_room_dining_category_translation_add_select_ja: "일본어",
      hotel_inhouse_in_room_dining_category_translation_add_select_zh: "중국어",
      hotel_inhouse_in_room_dining_category_translation_add_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_category_translation_add_label_name: "이름",
      hotel_inhouse_in_room_dining_category_translation_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_category_translation_add_button_add: "추가",
      hotel_inhouse_in_room_dining_category_translation_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_category_translation_edit_title: "인 룸 다이닝 카테고리 번역",
      hotel_inhouse_in_room_dining_category_translation_edit_label_language: "언어",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ko: "한국어",
      hotel_inhouse_in_room_dining_category_translation_edit_select_en: "영어",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ja: "일본어",
      hotel_inhouse_in_room_dining_category_translation_edit_select_zh: "중국어",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_category_translation_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_category_translation_edit_label_date: "날짜",
      hotel_inhouse_in_room_dining_category_translation_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_category_translation_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_category_translation_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_category_translation_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_list_title: "인 룸 다이닝 메뉴 설정 목록",
      hotel_inhouse_in_room_dining_menu_setting_list_label_no: "번호",
      hotel_inhouse_in_room_dining_menu_setting_list_label_type: "종류",
      hotel_inhouse_in_room_dining_menu_setting_list_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_list_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_setting_list_discount_rate: "할인율",
      hotel_inhouse_in_room_dining_menu_setting_list_price: "가격",
      hotel_inhouse_in_room_dining_menu_setting_list_status: "상태",
      hotel_inhouse_in_room_dining_menu_setting_list_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_setting_list_created_at: "날짜",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sold_out: "매진",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sale: "판매",
      hotel_inhouse_in_room_dining_menu_setting_list_select_preparing: "준비중",
      hotel_inhouse_in_room_dining_menu_setting_list_select_not_sale: "비매품",
      hotel_inhouse_in_room_dining_menu_setting_list_select_yes: "예",
      hotel_inhouse_in_room_dining_menu_setting_list_select_no: "아니오",
      hotel_inhouse_in_room_dining_menu_setting_list_sorting: "정렬",
      hotel_inhouse_in_room_dining_menu_setting_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_setting_list_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_setting_list_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_setting_add_title: "인 룸 다이닝 메뉴 설정 추가",
      hotel_inhouse_in_room_dining_menu_setting_add_label_category: "카테고리",
      hotel_inhouse_in_room_dining_menu_setting_add_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_add_label_about: "설명",
      hotel_inhouse_in_room_dining_menu_setting_add_label_main_image: "메인 이미지",
      hotel_inhouse_in_room_dining_menu_setting_add_label_detail_images: "상세 이미지",
      hotel_inhouse_in_room_dining_menu_setting_add_label_upload: "업로드",
      hotel_inhouse_in_room_dining_menu_setting_add_label_more: "추가 설명",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_start_time: "주문 가능 시간 (시간:분)",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_cutoff_time: "주문 종료 시간 (시간:분)",
      hotel_inhouse_in_room_dining_menu_setting_add_label_day_of_week: "요일",
      hotel_inhouse_in_room_dining_menu_setting_add_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_type: "할인율",
      hotel_inhouse_in_room_dining_menu_setting_add_select_flat: "고정 요금",
      hotel_inhouse_in_room_dining_menu_setting_add_select_percent: "퍼센트",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_setting_add_label_status: "상태",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sold_out: "매진",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sale: "판매",
      hotel_inhouse_in_room_dining_menu_setting_add_select_preparing: "준비중",
      hotel_inhouse_in_room_dining_menu_setting_add_select_not_sale: "비매품",
      hotel_inhouse_in_room_dining_menu_setting_add_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_setting_add_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_setting_add_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_setting_add_label_recommended: "추천 여부",
      hotel_inhouse_in_room_dining_menu_setting_add_label_packaging_availability: "포장 가능 여부",
      hotel_inhouse_in_room_dining_menu_setting_add_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_setting_add_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_menu_category_ids: "* 카테고리를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_about: "* 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_main_image: "* 메인 이미지를 업로드해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_detail_images: "* 상세 이미지를 업로드해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_more: "* 추가 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_from_at: "* 주문 가능 시간을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_to_at: "* 주문 종료 시간을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_day_of_week: "* 요일을 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_supply_price: "* 공급 가격을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_show: "* 메뉴 전시여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_recommended: "* 추천 여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_packaging_availability: "* 포장 가능 여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_detail_title: "인 룸 다이닝 메뉴 설정 정보",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_category: "카테고리",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_about: "설명",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_main_image: "메인 이미지",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_detail_images: "상세 이미지",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_more: "추가 설명",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_start_time: "주문 가능 시간 (시간:분)",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_cutoff_time: "주문 종료 시간 (시간:분)",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_day_of_week: "요일",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_type: "할인율",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_flat: "고정 요금",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_percent: "퍼센트",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_price: "할인 가격",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_sorting: "정렬",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_status: "상태",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sold_out: "매진",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sale: "판매",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_preparing: "준비중",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_not_sale: "비매품",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_recommended: "추천 여부",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_packaging_availability: "포장 가능 여부",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_language: "언어",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_date: "날짜",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_option: "옵션",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_no: "번호",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_items: "품목",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_required: "필수 여부",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_setting_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_setting_edit_title: "인 룸 다이닝 메뉴 설정",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_category: "카테고리",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_about: "설명",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_main_image: "메인 이미지",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_detail_images: "상세 이미지",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_upload: "업로드",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_more: "추가 설명",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_from_at: "주문 가능 시간 (시간:분)",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_to_at: "주문 종료 시간 (시간:분)",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_week: "요일",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_type: "할인율",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_flat: "고정 요금",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_percent: "퍼센트",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_status: "상태",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sold_out: "매진",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sale: "판매",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_preparing: "준비중",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_not_sale: "비매품",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_recommended: "추천 여부",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_packaging_availability: "포장 가능 여부",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_language: "언어",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_delete: "취소",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_add: "확인",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_date: "날짜",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_option: "옵션",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_no: "번호",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_items: "품목",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_required: "필수 여부",
      hotel_inhouse_in_room_dining_menu_setting_edit_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_price: "가격",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_menu_category_ids: "* 카테고리를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_about: "* 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_more: "* 추가 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_from_at: "* 주문 가능 시간을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_to_at: "* 주문 종료 시간을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_day_of_week: "* 요일을 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_supply_price: "* 공급 가격을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_title: "인 룸 다이닝 메뉴 번역 추가",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ko: "한국어",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_en: "영어",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ja: "일본어",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_zh: "중국어",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_about: "설명",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_more: "추가 설명",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_about: "* 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_more: "* 추가 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_title: "인 룸 다이닝 메뉴 번역 정보",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_about: "설명",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_more: "추가 설명",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_title: "인 룸 다이닝 메뉴 번역 수정",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ko: "한국어",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_en: "영어",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ja: "일본어",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_zh: "중국어",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_about: "설명",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_more: "추가 설명",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_about: "* 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_more: "* 추가 설명을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_add_title: "인 룸 다이닝 메뉴 옵션 추가",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_max_select: "최대 선택 수",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_required: "필수 여부",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_required: "* 필수 여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_max_select: "* 최대 선택 수를 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_show: "*메뉴 전시여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_detail_title: "인 룸 다이닝 메뉴 옵션 정보",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_max_select: "최대 선택 수",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_required: "필수 여부",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_language: "언어",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_date: "날짜",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_sorting: "정렬",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_option: "옵션",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_no: "번호",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_items: "품목",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_option_group_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_price: "가격",
      hotel_inhouse_in_room_dining_menu_option_group_edit_title: "인 룸 다이닝 메뉴 옵션 수정",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_max_select: "최대 선택 수",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_required: "필수 여부",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_language: "언어",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_date: "날짜",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_option: "옵션",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_no: "번호",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_items: "품목",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_option_group_edit_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_price: "가격",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_required: "* 필수 여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_max_select: "* 최대 선택 수를 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_title: "인 룸 다이닝 메뉴 옵션 번역 추가",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ko: "한국어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_en: "영어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ja: "일본어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_zh: "중국어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_title: "인 룸 다이닝 메뉴 옵션 번역 수정",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ko: "한국어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_en: "영어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ja: "일본어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_zh: "중국어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_add_title: "인 룸 다이닝 메뉴 옵션 품목 추가",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_type: "할인 종류",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_flat: "고정 요금",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_percent: "퍼센트",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_price: "가격",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_supply_price: "* 공급 가격을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_show: "* 메뉴 전시여부를 선택해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_detail_title: "인 룸 다이닝 메뉴 옵션 품목 정보",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_type: "할인 종류",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_price: "가격",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_language: "언어",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_date: "날짜",
      hotel_inhouse_in_room_dining_menu_option_value_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_value_edit_title: "인 룸 다이닝 메뉴 옵션 품목 수정",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_yes: "예",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_no: "아니오",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_show: "메뉴 전시여부",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_language: "언어",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_date: "날짜",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_option: "옵션",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_no: "번호",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_items: "픔목",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_required: "필수 여부",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_amount: "할인 가격",
      hotel_inhouse_in_room_dining_menu_option_value_edit_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_type: "할인 종류",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_flat: "고정 요금",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_percent: "퍼센트",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_price: "가격",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_supply_price: "* 공급 가격을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_title: "인 룸 다이닝 메뉴 옵션 품목 번역 추가",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ko: "한국어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_en: "영어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ja: "일본어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_zh: "중국어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_add: "추가",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_title: "인 룸 다이닝 메뉴 옵션 품목 번역 수정",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_language_code: "언어 코드",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ko: "한국어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_en: "영어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ja: "일본어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_zh: "중국어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ms: "말레이시아어",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_name: "이름",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_delete: "삭제",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_ok: "확인",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_validation_name: "* 이름을 입력해주세요.",
      hotel_inhouse_in_room_dining_process_list_title: "인 룸 다이닝 요청 목록 (확인 완료)",
      hotel_inhouse_in_room_dining_process_list_label_number_of_guest_requests: "고객 요청 수",
      hotel_inhouse_in_room_dining_process_list_label_unconfirmed_guest_requests: "확인되지 않은 고객 요청",
      hotel_inhouse_in_room_dining_process_list_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_process_list_label_all: "요청 ( 모두 )",
      hotel_inhouse_in_room_dining_process_list_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_process_list_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_process_list_placeholder_search: "검색",
      hotel_inhouse_in_room_dining_process_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_process_list_label_request_date: "요청 날짜",
      hotel_inhouse_in_room_dining_process_list_label_request_type: "요청 타입",
      hotel_inhouse_in_room_dining_process_list_label_task: "태스크",
      hotel_inhouse_in_room_dining_process_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_in_room_dining_process_list_label_date: "날짜",
      hotel_inhouse_in_room_dining_process_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_process_detail_title: "인 룸 다이닝 요청 정보",
      hotel_inhouse_in_room_dining_process_detail_label_order_no: "주문 번호",
      hotel_inhouse_in_room_dining_process_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_process_detail_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_process_detail_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_process_detail_label_status: "상태",
      hotel_inhouse_in_room_dining_process_detail_label_requests: "요청",
      hotel_inhouse_in_room_dining_process_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_process_detail_label_request_at: "요청 시간",
      hotel_inhouse_in_room_dining_process_detail_button_stop: "취소",
      hotel_inhouse_in_room_dining_process_detail_button_preparing: "준비중",
      hotel_inhouse_in_room_dining_process_detail_button_deliver: "배달 완료",
      hotel_inhouse_in_room_dining_process_detail_label_payment: "결제 수단",
      hotel_inhouse_in_room_dining_process_detail_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_process_detail_label_discount_rate: "할인율",
      hotel_inhouse_in_room_dining_process_detail_label_price: "가격",
      hotel_inhouse_in_room_dining_process_detail_label_to_go: "포장",
      hotel_inhouse_in_room_dining_process_detail_label_order: "주문",
      hotel_inhouse_in_room_dining_process_detail_label_no: "번호",
      hotel_inhouse_in_room_dining_process_detail_label_category: "카테고리",
      hotel_inhouse_in_room_dining_process_detail_label_menu: "메뉴",
      hotel_inhouse_in_room_dining_process_detail_label_option: "옵션",
      hotel_inhouse_in_room_dining_process_detail_label_items: "품목",
      hotel_inhouse_in_room_dining_process_detail_label_quantity: "수량",
      hotel_inhouse_in_room_dining_process_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_process_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_modal_stop_customer_reason: "고객사유",
      hotel_inhouse_in_room_dining_modal_stop_product_issue: "제품문제",
      hotel_inhouse_in_room_dining_modal_stop_delivery_problem: "배송(배달)문제",
      hotel_inhouse_in_room_dining_modal_stop_other: "기타",
      hotel_inhouse_in_room_dining_receipt_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_receipt_label_order_created_date: "주문 생성 날짜",
      hotel_inhouse_in_room_dining_receipt_label_guest_requested_date: "고객 요청 날짜",
      hotel_inhouse_in_room_dining_receipt_label_confirmation_no: "예약 번호",
      hotel_inhouse_in_room_dining_receipt_label_guest_name: "고객명",
      hotel_inhouse_in_room_dining_receipt_label_check_in: "체크인",
      hotel_inhouse_in_room_dining_receipt_label_check_out: "체크아웃",
      hotel_inhouse_in_room_dining_receipt_label_order_no: "주문 번호",
      hotel_inhouse_in_room_dining_receipt_label_to_go: "포장",
      hotel_inhouse_in_room_dining_receipt_label_description: "메뉴명",
      hotel_inhouse_in_room_dining_receipt_label_unit_price: "단위당 가격",
      hotel_inhouse_in_room_dining_receipt_label_quantity: "수량",
      hotel_inhouse_in_room_dining_receipt_label_amount: "가격",
      hotel_inhouse_in_room_dining_receipt_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_receipt_label_subtotal: "합계",
      hotel_inhouse_in_room_dining_receipt_label_discount: "할인",
      hotel_inhouse_in_room_dining_receipt_label_total: "총액",
      hotel_inhouse_in_room_dining_receipt_label_payment: "결제",
      hotel_inhouse_in_room_dining_request_receipt_label_room_posting: "겍실로 결제",
      hotel_inhouse_in_room_dining_request_receipt_label_self_settlement: "셀프 정산",
      hotel_inhouse_in_room_dining_request_receipt_label_balance: "잔액",
      hotel_inhouse_in_room_dining_request_receipt_contents_agreement:
        "본인은 이 계산서에 대한 책임이 면제되지 않으며, 지정된 개인, 회사 또는 단체가 요금 일부 또는 전부를 지불하지 않을 경우에는 개인적인 책임을 지는 데 동의합니다.",
      hotel_inhouse_in_room_dining_request_list_title: "인 룸 다이닝 요청 목록 (확인 전)",
      hotel_inhouse_in_room_dining_request_list_label_number_of_guest_requests: "고객 요청 수",
      hotel_inhouse_in_room_dining_request_list_label_unconfirmed_guest_requests: "확인되지 않은 고객 요청",
      hotel_inhouse_in_room_dining_request_list_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_request_list_label_all: "요청 ( 모두 )",
      hotel_inhouse_in_room_dining_request_list_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_request_list_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_request_list_placeholder_search: "검색",
      hotel_inhouse_in_room_dining_request_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_request_list_label_request_date: "요청 날짜",
      hotel_inhouse_in_room_dining_request_list_label_request_type: "요청 타입",
      hotel_inhouse_in_room_dining_request_list_label_task: "태스크",
      hotel_inhouse_in_room_dining_request_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_in_room_dining_request_list_label_date: "날짜",
      hotel_inhouse_in_room_dining_request_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_request_detail_title: "인 룸 다이닝 요청 정보",
      hotel_inhouse_in_room_dining_request_detail_label_update_at: "업데이트 시간",
      hotel_inhouse_in_room_dining_request_detail_label_order_no: "주문 번호",
      hotel_inhouse_in_room_dining_request_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_request_detail_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_request_detail_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_request_detail_label_status: "상태",
      hotel_inhouse_in_room_dining_request_detail_label_requests: "요청",
      hotel_inhouse_in_room_dining_request_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_request_detail_label_request_at: "요청 시간",
      hotel_inhouse_in_room_dining_request_detail_button_pdf: "PDF",
      hotel_inhouse_in_room_dining_request_detail_button_not_available: "취소",
      hotel_inhouse_in_room_dining_request_detail_button_confirm: "확인",
      hotel_inhouse_in_room_dining_request_detail_label_payment: "결제 수단",
      hotel_inhouse_in_room_dining_request_detail_label_supply_price: "공급 가격",
      hotel_inhouse_in_room_dining_request_detail_label_discount_rate: "할인율",
      hotel_inhouse_in_room_dining_request_detail_label_price: "가격",
      hotel_inhouse_in_room_dining_request_detail_label_to_go: "포장",
      hotel_inhouse_in_room_dining_request_detail_label_order: "주문",
      hotel_inhouse_in_room_dining_request_detail_label_no: "번호",
      hotel_inhouse_in_room_dining_request_detail_label_category: "카테고리",
      hotel_inhouse_in_room_dining_request_detail_label_menu: "메뉴",
      hotel_inhouse_in_room_dining_request_detail_label_option: "옵션",
      hotel_inhouse_in_room_dining_request_detail_label_items: "품목",
      hotel_inhouse_in_room_dining_request_detail_label_quantity: "수량",
      hotel_inhouse_in_room_dining_request_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_request_detail_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_request_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_myconect_list_title: "인 룸 다이닝 스케줄 처리 결과 목록",
      hotel_inhouse_in_room_dining_myconect_list_label_user_count: "사용자 취소 개수",
      hotel_inhouse_in_room_dining_myconect_list_label_hotel_count: "호텔 취소 개수",
      hotel_inhouse_in_room_dining_myconect_list_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_myconect_list_label_room_no: "객실 번호",
      hotel_inhouse_in_room_dining_myconect_list_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_myconect_list_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_myconect_list_label_request_date: "요청 날짜",
      hotel_inhouse_in_room_dining_myconect_list_label_request_type: "요청 타입",
      hotel_inhouse_in_room_dining_myconect_list_label_express: "태스크",
      hotel_inhouse_in_room_dining_myconect_list_label_action_status: "현재 동작 상태",
      hotel_inhouse_in_room_dining_myconect_list_label_myconect_at: "MyCONECT 동작 시간",
      hotel_inhouse_in_room_dining_myconect_list_label_completed_at: "완료 시간",
      hotel_inhouse_in_room_dining_myconect_list_label_date: "날짜",
      hotel_inhouse_in_room_dining_myconect_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_in_room_dining_myconect_detail_title: "스케줄 처리 결과 상세",
      hotel_inhouse_in_room_dining_myconect_detail_label_roon_no: "객실 번호",
      hotel_inhouse_in_room_dining_myconect_detail_label_confirmation_no: "예약 확인번호",
      hotel_inhouse_in_room_dining_myconect_detail_label_user_name: "사용자명",
      hotel_inhouse_in_room_dining_myconect_detail_label_email: "이메일",
      hotel_inhouse_in_room_dining_myconect_detail_label_stay: "투숙",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_type: "요청 타입",
      hotel_inhouse_in_room_dining_myconect_detail_label_status: "현황",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_at: "요청 시간",
      hotel_inhouse_in_room_dining_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_multi_myconect_list_title: "인 룸 다이닝 스케줄 목록",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_alive: "사용 중",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_staff_number: "스태프 번호",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_request: "요청",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_department: "부서",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_email: "이메일",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_respond: "MyCONECT 응답",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_from: "~부터",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_to: "~까지",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_date: "날짜",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_add: "추가",
      hotel_inhouse_in_room_dining_multi_myconect_add_title: "인 룸 다이닝 스케줄 추가",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_request_type: "요청 타입",
      hotel_inhouse_in_room_dining_multi_myconect_add_placeholder_select_box_choose: "선택",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_respond: "MyCONECT 응답",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_not_availble: "사용 불가",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_confirm: "확인",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_date: "날짜",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_add: "추가",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_set: "설정",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_type_request: "* 요청 타입을 선택해주세요.",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_start: "* 날짜를 선택해주세요.",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_end: "* 날짜를 선택해주세요.",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_respond: "* MyCONECT 응답을 선택해주세요.",
      hotel_inhouse_in_room_dining_multi_myconect_detail_title: "인 룸 다이닝 스케줄 정보",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_update: "업데이트 날짜",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_date: "날짜",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_respond: "MyCONECT 응답",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_staff_number: "스태프 번호",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_request: "요청",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_department: "부서",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_email: "이메일",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_released_at: "해제 시간",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_created_at: "생성 시간",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_cancel: "취소",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_release: "해제",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_ok: "확인",
      hotel_inhouse_in_room_dining_setting_detail_title: "인 룸 다이닝 설정",
      hotel_inhouse_in_room_dining_setting_detail_label_update_at: "업데이트 시간",
      hotel_inhouse_in_room_dining_setting_detail_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_setting_detail_label_room_posting: "객실 청구",
      hotel_inhouse_in_room_dining_setting_detail_button_yes: "예",
      hotel_inhouse_in_room_dining_setting_detail_button_no: "아니오",
      hotel_inhouse_in_room_dining_setting_detail_label_payment: "결제 수단",
      hotel_inhouse_in_room_dining_setting_detail_button_edit: "수정",
      hotel_inhouse_in_room_dining_setting_edit_title: "인 룸 다이닝 설정",
      hotel_inhouse_in_room_dining_setting_edit_label_update_at: "업데이트 시간",
      hotel_inhouse_in_room_dining_setting_edit_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_in_room_dining_setting_edit_label_room_posting: "객실 청구",
      hotel_inhouse_in_room_dining_setting_edit_button_yes: "예",
      hotel_inhouse_in_room_dining_setting_edit_button_no: "아니오",
      hotel_inhouse_in_room_dining_setting_edit_label_payment: "결제 수단",
      hotel_inhouse_in_room_dining_setting_edit_button_cancel: "취소",
      hotel_inhouse_in_room_dining_setting_edit_button_ok: "확인",
      hotel_inhouse_setting_business_list_title: "업무 규칙 설정",
      hotel_inhouse_setting_business_list_label_no: "번호",
      hotel_inhouse_setting_business_list_label_type: "타입",
      hotel_inhouse_setting_business_list_label_task: "태스크",
      hotel_inhouse_setting_business_list_label_time_from: "사용 가능 시간 ~부터",
      hotel_inhouse_setting_business_list_label_time_to: "사용 가능 시간 ~까지",
      hotel_inhouse_setting_business_list_label_charge_amount: "청구 금액",
      hotel_inhouse_setting_business_list_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_setting_business_list_label_update_at: "업데이트 시간",
      hotel_inhouse_setting_business_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_setting_business_detail_title: "업무 규칙 설정 정보",
      hotel_inhouse_setting_business_detail_label_date: "날짜 업데이트",
      hotel_inhouse_setting_business_detail_label_type: "타입",
      hotel_inhouse_setting_business_detail_label_task: "태스크",
      hotel_inhouse_setting_business_detail_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_setting_business_detail_label_time_from: "사용 가능 시간 ~부터",
      hotel_inhouse_setting_business_detail_label_time_to: "사용 가능 시간 ~까지",
      hotel_inhouse_setting_business_detail_label_min_requirement_time: "최소 요구 시간 (분)",
      hotel_inhouse_setting_business_detail_label_waiting_time: "MyCONECT 대기 시간 (분)",
      hotel_inhouse_setting_business_detail_label_respond: "MyCONECT 응답",
      hotel_inhouse_setting_business_detail_label_amount: "청구 금액",
      hotel_inhouse_setting_business_detail_label_transaction_code: "결제 코드",
      hotel_inhouse_setting_business_detail_label_description: "내역",
      hotel_inhouse_setting_business_detail_label_supplement: "요금 상세내역",
      hotel_inhouse_setting_business_detail_label_myconect_from: "MyCONECT 사용 시작 시간",
      hotel_inhouse_setting_business_detail_label_myconect_to: "MyCONECT 사용 종료 시간",
      hotel_inhouse_setting_business_detail_label_additional_charge_count: "추가 결제 조건 횟수",
      hotel_inhouse_setting_business_detail_label_additional_charge_repeat_flag: "추가 결제 반복 여부",
      hotel_inhouse_setting_business_detail_button_yes: "예",
      hotel_inhouse_setting_business_detail_button_no: "아니오",
      hotel_inhouse_setting_business_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_setting_business_detail_button_edit: "수정",
      hotel_inhouse_setting_business_edit_title: "업무 규칙 설정 정보",
      hotel_inhouse_setting_business_edit_label_date: "업데이트 날짜",
      hotel_inhouse_setting_business_edit_label_type: "타입",
      hotel_inhouse_setting_business_edit_label_task: "태스크",
      hotel_inhouse_setting_business_edit_label_time_from: "사용 가능 시간 ~부터",
      hotel_inhouse_setting_business_edit_label_time_to: "사용 가능 시간 ~까지",
      hotel_inhouse_setting_business_edit_label_min_requirement_time: "최소 요구 시간",
      hotel_inhouse_setting_business_edit_label_waiting_time: "MyCONECT 대기 시간",
      hotel_inhouse_setting_business_edit_label_respond: "MyCONECT 응답",
      hotel_inhouse_setting_business_edit_select_not_availble: "사용 불가",
      hotel_inhouse_setting_business_edit_select_confirm: "확인",
      hotel_inhouse_setting_business_edit_select_delay15: "15분 지연",
      hotel_inhouse_setting_business_edit_select_delay30: "30분 지연",
      hotel_inhouse_setting_business_edit_select_delay45: "45분 지연",
      hotel_inhouse_setting_business_edit_select_delay60: "60분 지연",
      hotel_inhouse_setting_business_edit_select_asaa: "빠른 시간 내",
      hotel_inhouse_setting_business_edit_label_amount: "청구 금액",
      hotel_inhouse_setting_business_edit_label_transaction_code: "결제 코드",
      hotel_inhouse_setting_business_edit_label_description: "내역",
      hotel_inhouse_setting_business_edit_label_supplement: "요금 상세내역",
      hotel_inhouse_setting_business_edit_label_myconect_from: "MyCONECT 사용 시작 시간",
      hotel_inhouse_setting_business_edit_label_myconect_to: "MyCONECT 사용 종료 시간",
      hotel_inhouse_setting_business_edit_validation_available_time_from: "* 사용 가능 시간 ~부터를 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_available_time_to: "* 사용 가능 시간 ~까지를 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_min_requirement_time: "* 최소 요구 시간을 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_myconect_waiting_time: "* MyCONECT 대기 시간을 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_myconect_respond: "* MyCONECT 응답을 선택해주세요.",
      hotel_inhouse_setting_business_edit_validation_charge_amount: "* 청구 금액을 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_charge_transaction_code: "* 결제 코드를 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_charge_description: "* 내역을 입력해주세요.",
      hotel_inhouse_setting_business_edit_validation_charge_supplement: "* 요금 상세내역을 입력해주세요.",
      hotel_inhouse_setting_business_edit_label_in_room_dining: "인 룸 다이닝",
      hotel_inhouse_setting_business_edit_button_cancel: "취소",
      hotel_inhouse_setting_business_edit_button_ok: "확인",
      hotel_inhouse_setting_boundary_list_title: "제한 설정",
      hotel_inhouse_setting_boundary_list_label_no: "번호",
      hotel_inhouse_setting_boundary_list_label_type: "타입",
      hotel_inhouse_setting_boundary_list_label_task: "태스크",
      hotel_inhouse_setting_boundary_list_label_ten_mins: "10분",
      hotel_inhouse_setting_boundary_list_label_hour: "시",
      hotel_inhouse_setting_boundary_list_label_three_hours: "3시간",
      hotel_inhouse_setting_boundary_list_label_update_at: "업데이트 시간",
      hotel_inhouse_setting_boundary_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_setting_boundary_detail_title: "업무 정보",
      hotel_inhouse_setting_boundary_detail_label_date: "업데이트 시간",
      hotel_inhouse_setting_boundary_detail_label_type: "타입",
      hotel_inhouse_setting_boundary_detail_label_task: "태스크",
      hotel_inhouse_setting_boundary_detail_label_ten_mins: "10분",
      hotel_inhouse_setting_boundary_detail_label_hour: "시간",
      hotel_inhouse_setting_boundary_detail_label_three_hours: "3시간",
      hotel_inhouse_setting_boundary_detail_button_none: "없음",
      hotel_inhouse_setting_boundary_detail_button_unlimited: "시간 제한 없음",
      hotel_inhouse_setting_boundary_detail_button_value: "값",
      hotel_inhouse_setting_boundary_detail_label_option_one: "첫번째 옵션",
      hotel_inhouse_setting_boundary_detail_label_option_two: "두번째 옵션",
      hotel_inhouse_setting_boundary_detail_button_go_to_list: "목록으로 가기",
      hotel_inhouse_setting_boundary_detail_button_edit: "수정",
      hotel_inhouse_setting_boundary_edit_title: "업무 정보",
      hotel_inhouse_setting_boundary_edit_label_date: "날짜 업데이트",
      hotel_inhouse_setting_boundary_edit_label_type: "타입",
      hotel_inhouse_setting_boundary_edit_label_task: "태스크",
      hotel_inhouse_setting_boundary_edit_label_ten_mins: "10분",
      hotel_inhouse_setting_boundary_edit_label_hour: "시간",
      hotel_inhouse_setting_boundary_edit_label_three_hours: "3시간",
      hotel_inhouse_setting_boundary_edit_button_none: "없음",
      hotel_inhouse_setting_boundary_edit_button_unlimited: "시간 제한 없음",
      hotel_inhouse_setting_boundary_edit_button_value: "값",
      hotel_inhouse_setting_boundary_edit_label_option_one: "첫번째 옵션",
      hotel_inhouse_setting_boundary_edit_label_option_two: "두번째 옵션",
      hotel_inhouse_setting_boundary_edit_validation_boundary_line_hour: "* 시간을 입력해주세요.",
      hotel_inhouse_setting_boundary_edit_button_cancel: "취소",
      hotel_inhouse_setting_boundary_edit_button_ok: "확인",
      hotel_inhouse_setting_amenity_list_title: "어메니티 설정",
      hotel_inhouse_setting_amenity_list_label_no: "번호",
      hotel_inhouse_setting_amenity_list_label_item: "항목",
      hotel_inhouse_setting_amenity_list_label_order_by: "주문 순서",
      hotel_inhouse_setting_amenity_list_label_update_at: "업데이트 시간",
      hotel_inhouse_setting_amenity_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_room_controller_detail_title: "객실제어",
      hotel_inhouse_room_controller_detail_button_choose_file: "파일 선택",
      hotel_inhouse_room_controller_detail_button_insert: "입력",
      hotel_inhouse_room_controller_detail_button_export: "내보내기",
      hotel_inhouse_help_list_title: "모바일 키 메뉴 도움말",
      hotel_inhouse_help_list_button_add: "추가",
      hotel_inhouse_help_list_button_delete: "삭제",
      hotel_inhouse_help_list_button_edit: "수정",
      hotel_inhouse_help_list_guide_add: "호텔 정보를 입력하세요",
      hotel_inhouse_help_add_title: "모바일 키 도움말 추가",
      hotel_inhouse_help_add_placeholder_title: "제목을 입력하세요.",
      hotel_inhouse_help_add_placeholder_index: "번호를 입력하세요.",
      hotel_inhouse_help_add_select_choose: "타입을 선택하세요",
      hotel_inhouse_help_add_select_use: "사용",
      hotel_inhouse_help_add_select_access: "접근",
      hotel_inhouse_help_add_select_range: "범위",
      hotel_inhouse_help_add_select_not_working: "실행불가",
      hotel_inhouse_help_add_select_in_room_tech: "인 룸 테크",
      hotel_inhouse_help_add_select_trouble: "문제",
      hotel_inhouse_help_add_toolbar_bold: "굵게",
      hotel_inhouse_help_add_toolbar_italic: "이탤릭체",
      hotel_inhouse_help_add_toolbar_underline: "밑줄",
      hotel_inhouse_help_add_toolbar_remove_font_style: "글씨체 제거",
      hotel_inhouse_help_add_toolbar_font_family: "글꼴 모음",
      hotel_inhouse_help_add_toolbar_font_size: "글꼴 크기",
      hotel_inhouse_help_add_toolbar_recent_color: "최근 사용 색상",
      hotel_inhouse_help_add_toolbar_recent_more_color: "색상 더 보기",
      hotel_inhouse_help_add_toolbar_unordered_list: "미주문 목록",
      hotel_inhouse_help_add_toolbar_ordered_list: "주문 목록",
      hotel_inhouse_help_add_toolbar_paragraph: "단락",
      hotel_inhouse_help_add_toolbar_line_height: "줄 높이",
      hotel_inhouse_help_add_placeholder_content: "내용을 입력하세요",
      hotel_inhouse_help_add_button_cancel: "취소",
      hotel_inhouse_help_add_button_ok: "확인",
      hotel_inhouse_help_edit_title: "호텔 정보 수정",
      hotel_inhouse_help_edit_placeholder_title: "제목",
      hotel_inhouse_help_edit_select_kr: "한국어",
      hotel_inhouse_help_edit_select_en: "영어",
      hotel_inhouse_help_edit_select_cn: "중국어",
      hotel_inhouse_help_edit_select_jp: "일본어",
      hotel_inhouse_help_edit_select_ms: "말레이어",
      hotel_inhouse_help_edit_placeholder_index: "색인",
      hotel_inhouse_help_edit_toolbar_bold: "굵게",
      hotel_inhouse_help_edit_toolbar_italic: "이탤릭체",
      hotel_inhouse_help_edit_toolbar_underline: "밑줄",
      hotel_inhouse_help_edit_toolbar_remove_font_style: "글씨체 제거",
      hotel_inhouse_help_edit_toolbar_font_family: "글꼴 모음",
      hotel_inhouse_help_edit_toolbar_font_size: "글꼴 크기",
      hotel_inhouse_help_edit_toolbar_recent_color: "최근 사용 색상",
      hotel_inhouse_help_edit_toolbar_recent_more_color: "색상 더 보기",
      hotel_inhouse_help_edit_toolbar_unordered_list: "미주문 목록",
      hotel_inhouse_help_edit_toolbar_ordered_list: "주문 목록",
      hotel_inhouse_help_edit_toolbar_paragraph: "단락",
      hotel_inhouse_help_edit_toolbar_code_view: "코드 보기",
      hotel_inhouse_help_edit_toolbar_line_height: "줄 높이",
      hotel_inhouse_help_edit_button_append: "추가",
      hotel_inhouse_help_edit_button_remove: "제거",
      hotel_inhouse_help_edit_button_cancel: "취소",
      hotel_inhouse_help_edit_button_ok: "확인",
      hotel_key_guest_list_title: "고객 키 목록",
      hotel_key_guest_list_button_alive: "사용 중",
      hotel_key_guest_list_placeholder_select_box_key: "키",
      hotel_key_guest_list_select_room_no: "객실 번호",
      hotel_key_guest_list_placeholder_search: "검색",
      hotel_key_guest_list_label_id: "ID",
      hotel_key_guest_list_label_availble_at: "사용 가능 시간",
      hotel_key_guest_list_label_expired_at: "만료 시간",
      hotel_key_guest_list_label_is_main: "대표",
      hotel_key_guest_list_label_room_no: "객실 번호",
      hotel_key_guest_list_label_user: "사용자",
      hotel_key_guest_list_button_cancel: "취소",
      hotel_key_guest_list_guide_empty: "목록이 비어 있습니다.",
      hotel_key_guest_detail_title: "키 정보",
      hotel_key_guest_detail_label_id: "ID",
      hotel_key_guest_detail_label_availble_at: "사용 가능 시간",
      hotel_key_guest_detail_label_expired_at: "만료 시간",
      hotel_key_guest_detail_label_is_main: "대표",
      hotel_key_guest_detail_label_room_no: "객실 번호",
      hotel_key_guest_detail_label_confirmation_no: "예약 확인번호",
      hotel_key_guest_detail_label_user: "사용자",
      hotel_key_guest_detail_label_user_email: "사용자 이메일",
      hotel_key_guest_detail_button_go_to_list: "목록으로 가기",
      hotel_key_guest_detail_button_cancel: "취소",
      hotel_key_staff_list_title: "스태프 키 목록",
      hotel_key_staff_list_button_alive: "사용 중",
      hotel_key_staff_list_placeholder_select_box_key: "키",
      hotel_key_staff_list_select_task: "태스크",
      hotel_key_staff_list_select_emp_id: "직원 ID",
      hotel_key_staff_list_placeholder_search: "검색",
      hotel_key_staff_list_label_type: "타입",
      hotel_key_staff_list_label_link_type: "링크 타입",
      hotel_key_staff_list_label_link_detail: "링크 상세정보",
      hotel_key_staff_list_label_link_code: "링크 코드",
      hotel_key_staff_list_label_availble_at: "사용가능 시간",
      hotel_key_staff_list_label_expired_at: "만료 시간",
      hotel_key_staff_list_label_staff_number: "스태프 번호",
      hotel_key_staff_list_button_cancel: "취소",
      hotel_key_staff_list_button_add: "추가",
      hotel_key_staff_list_guide_empty: "목록이 비어 있습니다.",
      hotel_key_staff_add_title: "스태프 키 추가",
      hotel_key_staff_add_button_append: "추가",
      hotel_key_staff_add_label_staff_number: "스태프 번호",
      hotel_key_staff_add_label_type: "타입",
      hotel_key_staff_add_label_detail: "상세",
      hotel_key_staff_add_label_from_date: "시작 날짜",
      hotel_key_staff_add_label_end_date: "종료 날짜",
      hotel_key_staff_add_placeholder_select_box_choose: "선택",
      hotel_key_staff_add_button_remove: "제거",
      hotel_key_staff_add_button_go_to_list: "목록으로 가기",
      hotel_key_staff_add_button_add: "추가",
      hotel_key_staff_add_validation_number: "* 스태프 번호를 입력해주세요.",
      hotel_key_staff_add_validation_type: "* 타입을 선택해주세요.",
      hotel_key_staff_add_validation_detail: "* 상세를 선택해주세요.",
      hotel_key_staff_add_validation_from_date: "* 시작 날짜를 선택해주세요.",
      hotel_key_staff_add_validation_end_date: "* 종료 날짜를 선택해주세요.",
      hotel_key_staff_detail_title: "스태프 키 정보",
      hotel_key_staff_detail_label_type: "타입",
      hotel_key_staff_detail_label_link_type: "링크 타입",
      hotel_key_staff_detail_label_link_detail: "링크 상세정보",
      hotel_key_staff_detail_label_link_code: "링크 코드",
      hotel_key_staff_detail_label_availble_at: "사용가능 시간",
      hotel_key_staff_detail_label_expired_at: "만료 시간",
      hotel_key_staff_detail_label_employee_id: "직원 번호",
      hotel_key_staff_detail_label_canceled_at: "취소 시간",
      hotel_key_staff_detail_button_go_to_list: "목록으로 가기",
      hotel_key_staff_detail_button_cancel: "취소",
      hotel_inhouse_share_key_list_title: "공유 키 목록",
      hotel_inhouse_share_key_list_button_alive: "사용 중",
      hotel_inhouse_share_key_list_placeholder_select_box_share_key: "공유 키",
      hotel_inhouse_share_key_list_select_room_no: "객실 번호",
      hotel_inhouse_share_key_list_select_email: "이메일",
      hotel_inhouse_share_key_list_select_user_name: "사용자명",
      hotel_inhouse_share_key_list_placeholder_search: "검색",
      hotel_inhouse_share_key_list_label_no: "번호",
      hotel_inhouse_share_key_list_label_sender_room_no: "발신자 객실 번호",
      hotel_inhouse_share_key_list_label_sender_name: "발신자명",
      hotel_inhouse_share_key_list_label_sender_email: "발신자 이메일",
      hotel_inhouse_share_key_list_label_key_type: "키 타입",
      hotel_inhouse_share_key_list_label_expired_at: "만료 날짜",
      hotel_inhouse_share_key_list_label_receiver_room_no: "수신자 객실 번호",
      hotel_inhouse_share_key_list_label_receiver_email: "수신자 이메일",
      hotel_inhouse_share_key_list_label_receiver_status: "수신자 현황",
      hotel_inhouse_share_key_list_label_date: "날짜",
      hotel_inhouse_share_key_list_guide_empty: "목록이 비어 있습니다.",
      hotel_inhouse_share_key_detail_title: "공유 키 상세정보",
      hotel_inhouse_share_key_detail_label_sender_room_no: "발신자 객실 번호",
      hotel_inhouse_share_key_detail_label_sender_name: "발신자명",
      hotel_inhouse_share_key_detail_label_sender_email: "발신자 이메일",
      hotel_inhouse_share_key_detail_label_key_type: "공유 키 타입",
      hotel_inhouse_share_key_detail_label_expired_at: "공유 키 만료날짜",
      hotel_inhouse_share_key_detail_label_receiver_room_no: "수신자 객실 번호",
      hotel_inhouse_share_key_detail_label_receiver_email: "수신자 이메일",
      hotel_inhouse_share_key_detail_label_receiver_status: "수신자 현황",
      hotel_inhouse_share_key_detail_label_created_at: "생성 시간",
      hotel_inhouse_share_key_detail_button_go_to_list: "목록으로 가기",
      help_for_mobile_key_add_validation_title: "* 제목을 입력해주세요.",
      help_for_mobile_key_add_validation_index: "* 번호를 입력해주세요.",
      help_for_mobile_key_add_validation_type: "* 타입을 선택해주세요.",
      help_for_mobile_key_add_validation_content: "* 내용을 입력해주세요.",
      help_for_mobile_key_edit_validation_title: "* 제목을 입력해주세요.",
      help_for_mobile_key_edit_validation_index: "* 번호를 입력해주세요.",
      help_for_mobile_key_edit_validation_content: "* 내용을 입력해주세요.",
      help_for_mobile_key_edit_validation_language_code: "* 중복되지 않는 언어 코드를 선택해주세요.",
      hotel_key_link_list_title: "스태프 키 링크 목록",
      hotel_key_link_list_placeholder_search: "검색",
      hotel_key_link_list_label_type_code: "타입 코드",
      hotel_key_link_list_label_type_description: "타입 설명",
      hotel_key_link_list_label_detail_code: "상세 코드",
      hotel_key_link_list_label_detail_description: "상세 설명",
      hotel_key_link_list_label_staff_type: "스태프 타입",
      hotel_key_link_list_label_code: "코드",
      hotel_key_link_list_label_date: "날짜",
      hotel_key_link_list_button_delete: "삭제",
      hotel_key_link_list_button_add: "추가",
      hotel_key_link_list_guide_empty: "목록이 비어 있습니다.",
      hotel_key_link_add_title: "스태프 키 링크 추가",
      hotel_key_link_add_label_type_code: "타입 코드",
      hotel_key_link_add_placeholder_select_box_choose: "선택",
      hotel_key_link_add_select_floor: "층",
      hotel_key_link_add_select_task: "태스크",
      hotel_key_link_add_select_zone: "구역",
      hotel_key_link_add_label_type_description: "타입 설명",
      hotel_key_link_add_label_detail_code: "상세 코드",
      hotel_key_link_add_label_detail_description: "상세 설명",
      hotel_key_link_add_label_staff_type: "스태프 타입",
      hotel_key_link_add_select_concoerge: "컨시어지",
      hotel_key_link_add_select_housekeeping: "하우스키핑",
      hotel_key_link_add_select_front_desk: "프런트 데스크",
      hotel_key_link_add_label_code: "코드",
      hotel_key_link_add_button_go_to_list: "목록으로 가기",
      hotel_key_link_add_button_add: "추가",
      hotel_key_link_add_validation_type_code: "* 타입 코드를 선택해주세요.",
      hotel_key_link_add_validation_type_description: "* 타입 설명을 입력해주세요.",
      hotel_key_link_add_validation_detail_code: "* 상세 코드를 선택해주세요.",
      hotel_key_link_add_validation_detail_description: "* 상세 설명을 입력해주세요.",
      hotel_key_link_add_validation_staff_type: "* 스태프 타입을 선택해주세요.",
      hotel_key_link_add_validation_code: "* 코드를 선택해주세요.",
      hotel_key_link_detail_title: "스태프 키 링크 정보",
      hotel_key_link_detail_label_type_code: "타입 코드",
      hotel_key_link_detail_label_type_description: "타입 설명",
      hotel_key_link_detail_label_detail_code: "상세 코드",
      hotel_key_link_detail_label_detail_description: "상세 설명",
      hotel_key_link_detail_label_staff_type: "스태프 타입",
      hotel_key_link_detail_label_code: "코드",
      hotel_key_link_detail_label_date: "날짜",
      hotel_key_link_detail_button_go_to_list: "목록으로 가기",
      hotel_key_link_detail_button_delete: "삭제",
      hotel_key_link_detail_button_edit: "수정",
      hotel_key_link_edit_title: "스태프 키 링크 정보",
      hotel_key_link_edit_label_type_code: "타입 코드",
      hotel_key_link_edit_placeholder_select_box_choose: "선택",
      hotel_key_link_edit_select_floor: "층",
      hotel_key_link_edit_select_task: "태스크",
      hotel_key_link_edit_select_zone: "구역",
      hotel_key_link_edit_select_room: "객실",
      hotel_key_link_edit_label_type_description: "타입 설명",
      hotel_key_link_edit_label_detail_code: "상세 코드",
      hotel_key_link_edit_label_detail_description: "상세 설명",
      hotel_key_link_edit_label_staff_type: "스태프 타입",
      hotel_key_link_edit_select_concoerge: "컨시어지",
      hotel_key_link_edit_select_housekeeping: "하우스키핑",
      hotel_key_link_edit_select_front_desk: "프런트 데스크",
      hotel_key_link_edit_label_code: "코드",
      hotel_key_link_edit_label_date: "날짜",
      hotel_key_link_edit_button_cancel: "취소",
      hotel_key_link_edit_button_delete: "삭제",
      hotel_key_link_edit_button_ok: "확인",
      hotel_key_link_edit_validation_type_code: "* 타입 코드를 선택해주세요.",
      hotel_key_link_edit_validation_type_description: "* 타입 설명을 입력해주세요.",
      hotel_key_link_edit_validation_detail_code: "* 상세 코드를 선택해주세요.",
      hotel_key_link_edit_validation_detail_description: "* 상세 설명을 입력해주세요.",
      hotel_key_link_edit_validation_staff_type: "* 스태프 타입을 선택해주세요.",
      hotel_key_link_edit_validation_code: "* 코드를 선택해주세요.",
      hotel_key_access_list_title: "접근 목록",
      hotel_key_access_list_label_title: "호칭",
      hotel_key_access_list_label_type: "타입",
      hotel_key_access_list_label_content: "설명",
      hotel_key_access_list_label_position_code: "직책 코드",
      hotel_key_access_list_label_position_name: "직책명",
      hotel_key_access_list_label_remark: "비고",
      hotel_key_access_list_label_lcon: "아이콘",
      hotel_key_access_list_label_update_at: "업데이트 시간",
      hotel_key_access_list_button_delete: "삭제",
      hotel_key_access_list_button_add: "추가",
      hotel_key_access_list_guide_empty: "목록이 비어 있습니다.",
      hotel_key_access_add_title: "접근 추가",
      hotel_key_access_add_label_title: "호칭",
      hotel_key_access_add_label_type: "타입",
      hotel_key_access_add_label_content: "내용",
      hotel_key_access_add_label_position_code: "직책 코드",
      hotel_key_access_add_label_position_name: "직책명",
      hotel_key_access_add_label_remark: "비고",
      hotel_key_access_add_placeholder_select_box_choose: "선택",
      hotel_key_access_add_label_icon: "아이콘",
      hotel_key_access_add_button_upload: "업로드",
      hotel_key_access_add_placeholder_upload: "ex) MyCONECT.png",
      hotel_key_access_add_button_go_to_list: "목록으로 가기",
      hotel_key_access_add_button_add: "추가",
      hotel_key_access_add_validation_title: "* 호칭을 입력해주세요.",
      hotel_key_access_add_validation_type: "* 타입을 입력해주세요.",
      hotel_key_access_add_validation_content: "* 내용을 입력해주세요.",
      hotel_key_access_add_validation_position_name: "* 직책명을 선택해주세요.",
      hotel_key_access_add_validation_file: "* 아이콘을 업로드해주세요.",
      hotel_key_access_detail_title: "접근 정보",
      hotel_key_access_detail_label_title: "호칭",
      hotel_key_access_detail_label_type: "타입",
      hotel_key_access_detail_label_content: "내용",
      hotel_key_access_detail_label_position_code: "직책 코드",
      hotel_key_access_detail_label_position_name: "직책명",
      hotel_key_access_detail_label_remark: "비고",
      hotel_key_access_detail_label_lcon: "아이콘",
      hotel_key_access_detail_label_update_date: "날짜 업데이트",
      hotel_key_access_detail_button_upload: "업로드",
      hotel_key_access_detail_button_go_to_list: "목록으로 가기",
      hotel_key_access_detail_button_cancel: "취소",
      hotel_key_access_detail_button_delete: "삭제",
      hotel_key_access_detail_button_edit: "수정",
      hotel_key_access_detail_button_ok: "확인",
      hotel_key_access_edit_validation_title: "* 호칭을 입력해주세요.",
      hotel_key_access_edit_validation_type: "* 타입을 입력해주세요.",
      hotel_key_access_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_key_access_edit_validation_position_name: "* 직책명을 선택해주세요.",
      hotel_key_access_edit_validation_file: "* 아이콘을 업로드해주세요.",
      hotel_key_access_detail_list_title: "접근 상세정보 목록",
      hotel_key_access_detail_list_label_access_title: "접근 호칭",
      hotel_key_access_detail_list_label_language_code: "언어 코드",
      hotel_key_access_detail_list_label_access_detail_title: "접근 상세정보 호칭",
      hotel_key_access_detail_list_label_date: "날짜",
      hotel_key_access_detail_list_button_delete: "삭제",
      hotel_key_access_detail_list_button_add: "추가",
      hotel_key_access_detail_list_guide_empty: "목록이 비어 있습니다.",
      hotel_key_access_detail_add_title: "접근 상세정보 추가",
      hotel_key_access_detail_add_label_access_title: "접근 호칭",
      hotel_key_access_detail_add_placeholder_select_box_choose: "선택",
      hotel_key_access_detail_add_label_language_code: "언어 코드",
      hotel_key_access_detail_add_select_kr: "한국어",
      hotel_key_access_detail_add_select_en: "영어",
      hotel_key_access_detail_add_select_cn: "중국어",
      hotel_key_access_detail_add_select_jp: "일본어",
      hotel_key_access_detail_add_label_access_detail_title: "접근 상세정보 호칭",
      hotel_key_access_detail_add_label_content: "내용",
      hotel_key_access_detail_add_button_go_to_list: "목록으로 가기",
      hotel_key_access_detail_add_button_add: "추가",
      hotel_key_access_detail_add_validation_hotel_access_area_id: "* 접근 호칭을 선택해주세요.",
      hotel_key_access_detail_add_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_key_access_detail_add_validation_title: "* 접근 상세정보 호칭을 입력해주세요.",
      hotel_key_access_detail_add_validation_content: "* 내용을 입력해주세요.",
      hotel_key_access_detail_detail_title: "접근 상세정보",
      hotel_key_access_detail_detail_label_date: "업데이트 시간",
      hotel_key_access_detail_detail_label_access_title: "접근 호칭",
      hotel_key_access_detail_detail_placeholder_select_box_choose: "선택",
      hotel_key_access_detail_detail_label_language_code: "언어 코드",
      hotel_key_access_detail_detail_select_kr: "한국어",
      hotel_key_access_detail_detail_select_en: "영어",
      hotel_key_access_detail_detail_select_cn: "중국어",
      hotel_key_access_detail_detail_select_jp: "일본어",
      hotel_key_access_detail_detail_label_access_detail_title: "접근 상세정보 호칭",
      hotel_key_access_detail_detail_label_content: "내용",
      hotel_key_access_detail_detail_button_go_to_list: "목록으로 가기",
      hotel_key_access_detail_detail_button_cancel: "취소",
      hotel_key_access_detail_detail_button_delete: "삭제",
      hotel_key_access_detail_detail_button_edit: "수정",
      hotel_key_access_detail_detail_button_ok: "확인",
      hotel_key_access_detail_edit_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_key_access_detail_edit_validation_title: "* 접근 상세정보 호칭을 입력해주세요.",
      hotel_key_access_detail_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_key_reservation_access_add_validation_type: "* 타입을 선택해주세요.",
      hotel_key_reservation_access_add_validation_code: "* 코드를 선택해주세요.",
      hotel_key_reservation_access_add_validation_name: "* 이름을 입력해주세요.",
      hotel_key_reservation_access_add_validation_description: "* 내용을 입력해주세요.",
      hotel_key_reservation_access_add_validation_checked_list: "* 예약 접근 권한 목록을 체크해주세요.",
      hotel_key_reservation_access_edit_validation_checked_list: "* 예약 접근 권한 목록을 체크해주세요.",
      hotel_key_setting_detail_title: "키 설정",
      hotel_key_setting_detail_label_update_date: "업데이트된 날짜",
      hotel_key_setting_detail_label_card_key: "카드 키",
      hotel_key_setting_detail_label_card_key_type: "카드 키 타입",
      hotel_key_setting_detail_label_card_key_ip: "카드 키 IP",
      hotel_key_setting_detail_label_card_key_port: "카드 키 포트",
      hotel_key_setting_detail_label_card_key_path: "카드 키 경로",
      hotel_key_setting_detail_label_card_key_id: "카드 키 ID",
      hotel_key_setting_detail_label_card_key_password: "카드 키 비밀번호",
      hotel_key_setting_detail_label_special_guest: "고객 특별 구역",
      hotel_key_setting_detail_label_special_staff: "스태프 특별 구역",
      hotel_key_setting_detail_label_rssi_guest: "고객 RSSI",
      hotel_key_setting_detail_label_rrssi_staff: "스태프 RSSI",
      hotel_key_setting_detail_label_room_show_update_flag: "룸쇼 업데이트 표시",
      hotel_key_setting_detail_label_room_open_update_flag: "객실 열림 업데이트 표시",
      hotel_key_setting_detail_label_room_show_key: "프런트 데스크 룸쇼 키 발행",
      hotel_key_setting_detail_button_yes: "예",
      hotel_key_setting_detail_button_no: "아니오",
      hotel_key_setting_detail_label_key_expired_time: "키 만료 시간 (시간:분)",
      hotel_key_setting_detail_label_accompany_room_to_room_issue: "동반자 룸투룸 발행",
      hotel_key_setting_detail_label_room_to_room_not_available_time: "룸투룸 수락 대기시간(분)",
      hotel_key_setting_detail_label_share_key_issue: "공유 키 발행",
      hotel_key_setting_detail_label_accompany_share_key_issue: "동반자 공유 키 발행",
      hotel_key_setting_detail_label_share_key_not_available_time: "공유키 수락 대기시간(분)",
      hotel_key_setting_detail_label_share_key_room_key_issued_max_count: "룸 키 최대 발급 수",
      hotel_key_setting_detail_label_test_room_number: "테스트 객실 번호",
      hotel_key_setting_detail_button_cancel: "취소",
      hotel_key_setting_detail_button_edit: "수정",
      hotel_key_setting_edit_title: "키 설정",
      hotel_key_setting_edit_label_update_date: "업데이트된 날짜",
      hotel_key_setting_edit_label_card_key: "카드 키",
      hotel_key_setting_edit_label_card_key_type: "카드 키 타입",
      hotel_key_setting_edit_placeholder_select_box_choose: "선택",
      hotel_key_setting_edit_select_messerschmitt: "Messerschmitt",
      hotel_key_setting_edit_select_racos: "RACOS",
      hotel_key_setting_edit_select_racos_cms: "RACOS CMS",
      hotel_key_setting_edit_select_assa_abloy: "ASSA ABLOY",
      hotel_key_setting_edit_select_none: "사용하지 않음",
      hotel_key_setting_edit_label_card_key_ip: "카드 키 IP",
      hotel_key_setting_edit_placeholder_card_key_ip: "ex) 127.0.0.1",
      hotel_key_setting_edit_label_card_key_port: "카드 키 포트",
      hotel_key_setting_edit_placeholder_card_key_port: "ex) 8080",
      hotel_key_setting_edit_label_card_key_path: "카드 키 경로",
      hotel_key_setting_edit_label_card_key_id: "카드 키 ID",
      hotel_key_setting_edit_label_card_key_password: "카드 키 비밀번호",
      hotel_key_setting_edit_label_special_guest: "고객 특별 구역",
      hotel_key_setting_edit_label_special_staff: "스태프 특별 구역",
      hotel_key_setting_edit_label_rssi_guest: "고객 RSSI",
      hotel_key_setting_edit_label_rrssi_staff: "스태프 RSSI",
      hotel_key_setting_edit_label_room_show_update_flag: "룸쇼 업데이트 표시",
      hotel_key_setting_edit_label_room_show_key: "프런트 데스크 룸쇼 키 발행",
      hotel_key_setting_edit_button_yes: "예",
      hotel_key_setting_edit_button_no: "아니오",
      hotel_key_setting_edit_label_test_room_number: "테스트 객실 번호",
      hotel_key_setting_edit_button_cancel: "취소",
      hotel_key_setting_edit_button_ok: "확인",
      hotel_key_setting_edit_validation_card_key_type: "* 카드 키 타입을 선택해주세요.",
      hotel_key_setting_edit_validation_card_key_IP: "* 카드 키 IP를 입력해주세요.",
      hotel_key_setting_edit_validation_card_key_port: "* 카드 키 포트를 입력해주세요.",
      hotel_key_setting_edit_validation_card_key_path: "* 카드 키 경로를 입력해주세요.",
      hotel_key_setting_edit_validation_card_key_client_id: "* 카드 키 ID를 입력해주세요.",
      hotel_key_setting_edit_validation_card_key_client_password: "* 카드 키 비밀번호를 입력해주세요.",
      hotel_key_setting_edit_validation_special_area_for_guest: "* 고객 특별 구역을 입력해주세요.",
      hotel_key_setting_edit_validation_special_area_for_staff: "* 스태프 특별 구역을 입력해주세요.",
      hotel_key_setting_edit_validation_RSSI_for_guest: "* 고객 RSSI를 입력해주세요.",
      hotel_key_setting_edit_validation_RSSI_for_staff: "* 스태프 RSSI를 입력해주세요.",
      hotel_key_setting_edit_validation_room_show_flag: "* 룸쇼 업데이트 표시를 선택해주세요.",
      hotel_key_setting_edit_validation_room_show_issued_front_desk: "* 프런트 데스크 룸쇼 키 발행을 선택해주세요.",
      hotel_key_setting_edit_validation_key_expired_time: "* 키 만료 시간을 입력해주세요.",
      hotel_key_setting_edit_validation_room_to_room_not_available_time: "* 룸투룸 수락 대기시간을 입력해주세요.",
      hotel_key_setting_edit_validation_is_share_key_issue: "* 공유 키 발행을 선택해주세요.",
      hotel_key_setting_edit_validation_share_key_not_available_time: "* 공유키 수락 대기시간을 입력해주세요.",
      hotel_key_setting_edit_validation_room_key_issued_max_count: "* 룸 키 최대 발급 수를 입력해주세요.",
      hotel_key_setting_edit_validation_test_room_no: "* 테스트 객실 번호를 입력해주세요.",
      hotel_key_reservation_access_list_title: "접근권한 링크",
      hotel_key_reservation_access_list_label_type: "타입",
      hotel_key_reservation_access_list_label_name: "이름",
      hotel_key_reservation_access_list_label_code: "코드",
      hotel_key_reservation_access_list_label_access_area: "액세스 영역",
      hotel_key_reservation_access_list_label_date: "날짜",
      hotel_key_reservation_access_list_label_description: "내용",
      hotel_key_reservation_access_list_button_cancel: "삭제",
      hotel_key_reservation_access_list_button_add: "추가",
      hotel_key_reservation_access_list_guide_empty: "목록이 비어 있습니다.",
      hotel_key_reservation_access_add_title: "접근권한 링크 추가",
      hotel_key_reservation_access_add_label_type: "타입",
      hotel_key_reservation_access_add_placeholder_select_box_choose: "선택",
      hotel_key_reservation_access_add_select_transaction: "결제",
      hotel_key_reservation_access_add_select_package: "패키지",
      hotel_key_reservation_access_add_label_code: "코드",
      hotel_key_reservation_access_add_label_name: "이름",
      hotel_key_reservation_access_add_label_description: "내용",
      hotel_key_reservation_access_add_label_list: "예약 접근 권한 목록",
      hotel_key_reservation_access_add_label_list_title: "호칭",
      hotel_key_reservation_access_add_label_list_type: "타입",
      hotel_key_reservation_access_add_label_list_content: "내용",
      hotel_key_reservation_access_add_label_list_position_code: "직책 코드",
      hotel_key_reservation_access_add_label_list_position_name: "직책명",
      hotel_key_reservation_access_add_label_list_remark: "비고",
      hotel_key_reservation_access_add_button_go_to_list: "목록으로 가기",
      hotel_key_reservation_access_add_button_add: "추가",
      hotel_key_reservation_access_detail_title: "접근권한 링크 정보",
      hotel_key_reservation_access_detail_label_type: "타입",
      hotel_key_reservation_access_detail_label_code: "코드",
      hotel_key_reservation_access_detail_label_name: "이름",
      hotel_key_reservation_access_detail_label_description: "내용",
      hotel_key_reservation_access_detail_label_list: "예약 접근 권한 목록",
      hotel_key_reservation_access_detail_label_list_title: "호칭",
      hotel_key_reservation_access_detail_label_list_type: "타입",
      hotel_key_reservation_access_detail_label_list_content: "내용",
      hotel_key_reservation_access_detail_label_list_position_code: "직책 코드",
      hotel_key_reservation_access_detail_label_list_position_name: "직책명",
      hotel_key_reservation_access_detail_label_list_remark: "비고",
      hotel_key_reservation_access_detail_button_go_to_list: "목록으로 가기",
      hotel_key_reservation_access_detail_button_delete: "삭제",
      hotel_key_reservation_access_detail_button_edit: "수정",
      hotel_key_reservation_access_edit_title: "접근권한 링크 정보",
      hotel_key_reservation_access_edit_label_type: "타입",
      hotel_key_reservation_access_edit_placeholder_select_box_choose: "선택",
      hotel_key_reservation_access_edit_select_transaction: "결제",
      hotel_key_reservation_access_edit_select_package: "패키지",
      hotel_key_reservation_access_edit_label_code: "코드",
      hotel_key_reservation_access_edit_label_description: "내용",
      hotel_key_reservation_access_edit_label_list: "예약 접근 권한 목록",
      hotel_key_reservation_access_edit_label_list_title: "호칭",
      hotel_key_reservation_access_edit_label_list_type: "타입",
      hotel_key_reservation_access_edit_label_list_content: "내용",
      hotel_key_reservation_access_edit_label_list_position_code: "직책 코드",
      hotel_key_reservation_access_edit_label_list_position_name: "직책명",
      hotel_key_reservation_access_edit_label_list_remark: "비고",
      hotel_key_reservation_access_edit_button_cancel: "취소",
      hotel_key_reservation_access_edit_button_delete: "삭제",
      hotel_key_reservation_access_edit_button_ok: "확인",
      hotel_terms_list_title: "약관 목록",
      hotel_terms_list_select_type: "타입 ( 모두 )",
      hotel_terms_list_select_hotel_terms: "호텔 이용 약관",
      hotel_terms_list_select_hotel_privacy_policies: "호텔 개인 정보 보호 정책",
      hotel_terms_list_select_reservation_terms: "예약 이용 약관",
      hotel_terms_list_select_reservation_privacy_policies: "예약 개인 정보 보호 정책",
      hotel_terms_list_select_language_code: "언어 코드 ( 모두 )",
      hotel_terms_list_select_ko: "한국어",
      hotel_terms_list_select_en: "영어",
      hotel_terms_list_select_cn: "중국어",
      hotel_terms_list_select_jp: "일본어",
      hotel_terms_list_label_id: "아이디",
      hotel_terms_list_label_language_code: "언어 코드",
      hotel_terms_list_label_type: "타입",
      hotel_terms_list_label_title: "호칭",
      hotel_terms_list_label_version: "버전",
      hotel_terms_list_label_link_text: "링크 텍스트",
      hotel_terms_list_label_created_at: "생성 시간",
      hotel_terms_list_select_posted: "게시 여부 ( 모두 )",
      hotel_terms_list_select_yes: "예",
      hotel_terms_list_select_no: "아니오",
      hotel_terms_list_placeholder_search: "검색",
      hotel_terms_list_guide_empty: "목록이 비어 있습니다.",
      hotel_terms_list_button_delete: "삭제",
      hotel_terms_list_button_add: "추가",
      hotel_terms_add_title: "약관 추가",
      hotel_terms_add_label_language_code: "언어 코드",
      hotel_terms_add_placeholder_select_box_select: "선택",
      hotel_terms_add_select_ko: "한국어",
      hotel_terms_add_select_en: "영어",
      hotel_terms_add_select_cn: "중국어",
      hotel_terms_add_select_jp: "일본어",
      hotel_terms_add_label_type: "타입",
      hotel_terms_add_select_hotel_terms: "호텔 이용 약관",
      hotel_terms_add_select_hotel_privacy_policies: "호텔 개인 정보 보호 정책",
      hotel_terms_add_select_reservation_terms: "예약 이용 약관",
      hotel_terms_add_select_reservation_privacy_policies: "예약 개인 정보 보호 정책",
      hotel_terms_add_label_title: "호칭",
      hotel_terms_add_label_required: "필수 여부",
      hotel_terms_add_button_yes: "예",
      hotel_terms_add_button_no: "아니오",
      hotel_terms_add_label_description: "설명",
      hotel_terms_add_label_link_text: "링크 텍스트",
      hotel_terms_add_label_posted: "게시 여부",
      hotel_terms_add_label_version: "버전",
      hotel_terms_add_label_content_type: "내용 타입",
      hotel_terms_add_select_file: "파일",
      hotel_terms_add_select_text: "텍스트",
      hotel_terms_add_label_content: "내용",
      hotel_terms_add_label_file_url: "파일 URL",
      hotel_terms_add_button_upload: "업로드",
      hotel_terms_add_button_go_to_list: "목록으로 가기",
      hotel_terms_add_button_add: "추가",
      hotel_terms_add_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_terms_add_validation_type: "* 타입을 선택해주세요.",
      hotel_terms_add_validation_title: "* 호칭을 입력해주세요.",
      hotel_terms_add_validation_required: "* 필수 여부를 선택해주세요.",
      hotel_terms_add_validation_description: "* 설명을 입력해주세요.",
      hotel_terms_add_validation_link_text: "* 링크 텍스트를 입력해주세요.",
      hotel_terms_add_validation_posted: "* 게시 여부를 선택해주세요.",
      hotel_terms_add_validation_version: "* 버전을 입력해주세요.",
      hotel_terms_add_validation_content_type: "* 내용 타입을 선택해주세요.",
      hotel_terms_add_validation_content: "* 내용을 입력해주세요.",
      hotel_terms_add_validation_hotel_terms_file_url: "* 파일을 업로드해주세요.",
      hotel_terms_detail_title: "약관 상세",
      hotel_terms_detail_label_terms_id: "약관 아이디",
      hotel_terms_detail_label_language_code: "언어 코드",
      hotel_terms_detail_select_ko: "한국어",
      hotel_terms_detail_select_en: "영어",
      hotel_terms_detail_select_cn: "중국어",
      hotel_terms_detail_select_jp: "일본어",
      hotel_terms_detail_label_type: "타입",
      hotel_terms_detail_select_hotel_terms: "호텔 이용 약관",
      hotel_terms_detail_select_hotel_privacy_policies: "호텔 개인 정보 보호 정책",
      hotel_terms_detail_select_reservation_terms: "예약 이용 약관",
      hotel_terms_detail_select_reservation_privacy_policies: "예약 개인 정보 보호 정책",
      hotel_terms_detail_label_version: "버전",
      hotel_terms_detail_label_title: "호칭",
      hotel_terms_detail_label_posted: "게시 여부",
      hotel_terms_detail_button_yes: "예",
      hotel_terms_detail_button_no: "아니오",
      hotel_terms_detail_label_required: "필수 여부",
      hotel_terms_detail_label_description: "설명",
      hotel_terms_detail_label_link_text: "링크 텍스트",
      hotel_terms_detail_label_content_type: "내용 타입",
      hotel_terms_detail_select_file: "파일",
      hotel_terms_detail_select_text: "텍스트",
      hotel_terms_detail_label_file_url: "파일 URL",
      hotel_terms_detail_button_upload: "업로드",
      hotel_terms_detail_label_content: "내용",
      hotel_terms_detail_label_created_at: "생성 시간",
      hotel_terms_detail_label_updated_at: "업데이트 시간",
      hotel_terms_detail_button_go_to_list: "목록으로 가기",
      hotel_terms_edit_button_cancel: "취소",
      hotel_terms_detail_button_delete: "삭제",
      hotel_terms_edit_button_download: "다운로드",
      hotel_terms_detail_button_edit: "수정",
      hotel_terms_edit_button_ok: "확인",
      hotel_terms_edit_validation_language_code: "* 언어 코드를 선택해주세요.",
      hotel_terms_edit_validation_type: "* 타입을 선택해주세요.",
      hotel_terms_edit_validation_title: "* 호칭을 입력해주세요.",
      hotel_terms_edit_validation_required: "* 필수 여부를 선택해주세요.",
      hotel_terms_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_terms_edit_validation_link_text: "* 링크 텍스트를 입력해주세요.",
      hotel_terms_edit_validation_posted: "* 게시 여부를 선택해주세요.",
      hotel_terms_edit_validation_version: "* 버전을 입력해주세요.",
      hotel_terms_edit_validation_content_type: "* 내용 타입을 선택해주세요.",
      hotel_terms_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_terms_edit_validation_hotel_terms_file_url: "* 파일을 업로드해주세요.",
      hotel_agreement_list_title: "동의 내역 목록",
      hotel_agreement_list_placeholder_select_box_choose: "산텍",
      hotel_agreement_list_select_email: "이메일",
      hotel_agreement_list_select_content: "내용",
      hotel_agreement_list_select_confirmation_no: "예약 번호",
      hotel_agreement_list_placeholder_search: "검색",
      hotel_agreement_list_label_no: "번호",
      hotel_agreement_list_label_terms_type: "약관 타입",
      hotel_agreement_list_label_terms_id: "약관 아이디",
      hotel_agreement_list_label_terms_title: "약관 호칭",
      hotel_agreement_list_label_confirmation_no: "예약 번호",
      hotel_agreement_list_label_email: "이메일",
      hotel_agreement_list_label_name: "이름",
      hotel_agreement_list_label_created_at: "생성 시간",
      hotel_agreement_detail_title: "동의 내역 상세",
      hotel_agreement_detail_label_agreement: "동의",
      hotel_agreement_detail_label_agreement_at: "동의 시간",
      hotel_agreement_detail_label_user: "사용자",
      hotel_agreement_detail_label_email: "이메일",
      hotel_agreement_detail_label_name: "이름",
      hotel_agreement_detail_label_language_code: "언어 코드",
      hotel_agreement_detail_label_reservation: "예약",
      hotel_agreement_detail_label_confirmation_no: "예약 번호",
      hotel_agreement_detail_label_terms: "약관",
      hotel_agreement_detail_label_terms_id: "약관 아이디",
      hotel_agreement_detail_label_type: "타입",
      hotel_agreement_detail_label_version: "버전",
      hotel_agreement_detail_label_title: "호칭",
      hotel_agreement_detail_label_required: "필수 여부",
      hotel_agreement_detail_button_go_to_list: "목록으로 가기",
      hotel_event_list_title: "이벤트",
      hotel_event_list_label_category_all: "카테고리 ( 모두 )",
      hotel_event_list_label_search_type_all: "검색 종류 ( 모두 )",
      hotel_event_list_label_title: "제목",
      hotel_event_list_label_description: "설명",
      hotel_event_list_placeholder_search: "검색",
      hotel_event_list_label_no: "번호",
      hotel_event_list_label_category: "카테고리",
      hotel_event_list_label_date: "날짜",
      hotel_event_list_guide_empty: "목록이 비어 있습니다.",
      hotel_event_list_button_delete: "삭제",
      hotel_event_list_button_add: "추가",
      hotel_event_add_validation_category: "* 카테고리를 선택하세요.",
      hotel_event_add_validation_templates: "* 템플릿을 선택하세요.",
      hotel_event_add_validation_users: "* 사용자를 선택하세요.",
      hotel_event_add_title: "이벤트 추가",
      hotel_input_detail_label_details: "상세",
      hotel_event_add_label_category: "카테고리",
      hotel_event_add_label_templates: "템플릿",
      hotel_event_add_select_all: "모두",
      hotel_event_add_label_notification_time: "알림 시각",
      hotel_event_add_label_title: "제목",
      hotel_event_add_label_content: "내용",
      hotel_event_add_label_description: "설명",
      hotel_event_add_label_template_language: "템플릿 언어",
      hotel_event_add_template_add_empty_guide: "내용을 추가해주세요.",
      hotel_event_add_label_user: "사용자",
      hotel_event_add_label_search_time: "검색 시각",
      hotel_event_add_label_myconect_id: "MyCONECT ID",
      hotel_event_add_label_check_in: "체크인",
      hotel_event_add_placeholder_search: "검색",
      hotel_event_add_label_no: "번호",
      hotel_event_add_label_first_name: "이름",
      hotel_event_add_label_last_name: "성",
      hotel_event_add_label_email: "이메일",
      hotel_event_add_label_tile: "호칭",
      hotel_event_add_label_check_out: "체크아웃",
      hotel_event_add_guide_empty: "목록이 비어 있습니다.",
      hotel_event_add_button_go_to_list: "목록으로 가기",
      hotel_event_add_button_delete: "삭제",
      hotel_event_add_button_ok: "확인",
      hotel_event_add_user_validation_users: "* 사용자를 선택하세요.",
      hotel_event_add_user_title: "이벤트 추가",
      hotel_event_add_user_label_user: "사용자",
      hotel_event_add_user_label_current_time: "현재 시각",
      hotel_event_add_user_label_search_time: "검색 시간",
      hotel_event_add_user_label_all: "모두",
      hotel_event_add_user_label_myconect_id: "MyCONECT ID",
      hotel_event_add_user_label_profile_id: "프로필 ID",
      hotel_event_add_user_label_check_in: "체크인",
      hotel_event_add_user_placeholder_search: "검색",
      hotel_event_add_user_label_floor: "층",
      hotel_event_add_user_label_reservation_status: "예약 상태",
      hotel_event_add_user_label_room_no: "객실 번호",
      hotel_event_add_user_label_reserved: "예약",
      hotel_event_add_user_label_due_in: "체크인 예정",
      hotel_event_add_user_label_due_out: "체크아웃 예정",
      hotel_event_add_user_label_in_house: "인하우스",
      hotel_event_add_user_label_checked_out: "체크아웃",
      hotel_event_add_user_label_title: "호칭",
      hotel_event_add_user_label_no: "번호",
      hotel_event_add_user_label_first_name: "이름",
      hotel_event_add_user_label_last_name: "성",
      hotel_event_add_user_label_email: "이메일",
      hotel_event_add_user_label_check_out: "체크아웃",
      hotel_event_add_user_guide_empty: "목록이 비어 있습니다.",
      hotel_event_add_user_button_cancel: "취소",
      hotel_event_add_user_button_ok: "확인",
      hotel_event_detail_title: "이벤트 상세",
      hotel_event_detail_label_details: "상세",
      hotel_event_detail_label_category: "카테고리",
      hotel_event_detail_label_templates: "템플릿",
      hotel_event_detail_label_notification_time: "알림 시각",
      hotel_event_detail_label_updated_at: "업데이트 시간",
      hotel_event_detail_label_title: "제목",
      hotel_event_detail_label_content: "내용",
      hotel_event_detail_label_description: "설명",
      hotel_event_detail_label_templates_language: "템플릿 언어",
      hotel_event_detail_template_add_empty_guide: "내용을 추가해주세요.",
      hotel_event_detail_label_all: "모두",
      hotel_event_detail_label_myconect_id: "MyCONECT ID",
      hotel_event_detail_label_profile_id: "프로필 ID",
      hotel_event_detail_placeholder_search: "검색",
      hotel_event_detail_label_no: "번호",
      hotel_event_detail_label_first_name: "이름",
      hotel_event_detail_label_last_name: "성",
      hotel_event_detail_label_email: "이메일",
      hotel_event_detail_label_gender: "호칭",
      hotel_event_detail_label_send: "전송",
      hotel_event_detail_label_check_up: "확인 시각",
      hotel_event_detail_label_check_in: "체크인",
      hotel_event_detail_label_check_out: "체크아웃",
      hotel_event_detail_label_success: "성공",
      hotel_event_detail_label_failed: "실패",
      hotel_event_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_event_detail_button_go_to_list: "목록으로 가기",
      hotel_event_detail_button_delete: "삭제",
      hotel_event_detail_button_edit: "수정",
      hotel_event_edit_validation_category: "* 카테고리를 선택하세요.",
      hotel_event_edit_validation_templates: "* 템플릿을 선택하세요.",
      hotel_event_edit_validation_users: "* 사용자를 선택하세요.",
      hotel_event_edit_title: "이벤트 수정",
      hotel_event_edit_label_details: "상세",
      hotel_event_edit_label_category: "카테고리",
      hotel_event_edit_label_templates: "템플릿",
      hotel_event_edit_label_all: "모두",
      hotel_event_edit_label_notification_time: "알림 시각",
      hotel_event_edit_label_title: "제목",
      hotel_event_edit_label_content: "내용",
      hotel_event_edit_label_description: "설명",
      hotel_input_edit_label_templates_language: "템플릿 언어",
      hotel_event_edit_template_add_empty_guide: "내용을 추가해주세요.",
      hotel_event_edit_label_user: "사용자",
      hotel_event_edit_label_myconect_id: "MyCONECT ID",
      hotel_event_edit_label_check_in: "체크인",
      hotel_event_edit_label_check_out: "체크아웃",
      hotel_event_edit_placeholder_search: "검색",
      hotel_event_edit_button_delete: "삭제",
      hotel_event_edit_label_no: "번호",
      hotel_event_edit_label_first_name: "이름",
      hotel_event_edit_label_last_name: "성",
      hotel_event_edit_label_email: "이메일",
      hotel_event_edit_label_gender: "호칭",
      hotel_event_edit_guide_empty: "목록이 비어 있습니다.",
      hotel_event_edit_button_cancel: "취소",
      hotel_event_edit_button_ok: "확인",
      hotel_event_category_list_title: "이벤트 카테고리",
      hotel_event_category_list_label_all: "모두",
      hotel_event_category_list_label_type: "타입",
      hotel_event_category_list_label_show: "전시 여부",
      hotel_event_category_list_placeholder_search: "검색...",
      hotel_event_category_list_label_no: "번호",
      hotel_event_category_list_label_date: "날짜",
      hotel_event_category_list_guide_empty: "목록이 비어 있습니다.",
      hotel_event_category_list_button_delete: "삭제",
      hotel_event_category_list_button_add: "추가",
      hotel_event_category_add_title: "이벤트 카테고리 추가",
      hotel_event_category_add_label_type: "타입",
      hotel_event_category_add_label_show: "전시 여부",
      hotel_event_category_add_label_yes: "예",
      hotel_event_category_add_label_no: "아니오",
      hotel_event_category_add_button_go_to_list: "목록으로 가기",
      hotel_event_category_add_button_add: "추가",
      hotel_event_category_add_validation_type: "* 타입을 입력해주세요.",
      hotel_event_category_add_validation_show: "* 전시 여부를 선택해주세요.",
      hotel_event_category_detail_title: "이벤트 카테고리 상세",
      hotel_event_category_detail_label_detail: "상세",
      hotel_event_category_detail_button_edit: "수정",
      hotel_event_category_detail_label_type: "타입",
      hotel_event_category_detail_label_show: "전시 여부",
      hotel_event_category_detail_label_date: "날짜",
      hotel_event_category_detail_button_go_to_list: "목록으로 가기",
      hotel_event_category_detail_button_delete: "삭제",
      hotel_event_category_detail_label_yes: "예",
      hotel_event_category_detail_label_no: "아니오",
      hotel_event_category_detail_label_template: "템플릿",
      hotel_event_category_detail_button_add: "추가",
      hotel_event_category_detail_label_number: "번호",
      hotel_event_category_detail_label_title: "제목",
      hotel_event_category_detail_guide_empty: "목록이 비어 있습니다.",
      hotel_event_category_edit_title: "이벤트 카테고리 수정",
      hotel_event_category_edit_label_type: "타입",
      hotel_event_category_edit_label_show: "전시 여부",
      hotel_event_category_edit_label_yes: "예",
      hotel_event_category_edit_label_no: "아니오",
      hotel_event_category_edit_label_date: "날짜",
      hotel_event_category_edit_button_cancel: "취소",
      hotel_event_category_edit_button_delete: "삭제",
      hotel_event_category_edit_button_ok: "확인",
      hotel_event_category_edit_validation_type: "* 타입을 입력해주세요.",
      hotel_event_category_template_add_title: "이벤트 카테고리 템플릿 추가",
      hotel_event_category_template_add_label_title: "제목",
      hotel_event_category_template_add_label_content: "내용",
      hotel_event_category_template_add_label_description: "설명",
      hotel_event_category_template_add_label_language: "언어",
      hotel_event_category_template_add_button_add: "추가",
      hotel_event_category_template_add_button_delete: "삭제",
      hotel_event_category_template_add_empty_guide: "내용을 추가해주세요.",
      hotel_event_category_template_add_button_go_to_list: "목록으로 가기",
      hotel_event_category_template_add_button_ok: "확인",
      hotel_event_category_template_add_validation_title: "* 제목을 입력해주세요.",
      hotel_event_category_template_add_validation_content: "* 내용을 입력해주세요.",
      hotel_event_category_template_add_validation_description: "* 설명을 입력해주세요.",
      hotel_event_category_template_detail_title: "이벤트 카테고리 템플릿 상세",
      hotel_event_category_template_detail_label_templates: "템플릿",
      hotel_event_category_template_detail_label_category: "카테고리",
      hotel_event_category_template_detail_label_title: "제목",
      hotel_event_category_template_detail_label_content: "내용",
      hotel_event_category_template_detail_label_description: "설명",
      hotel_event_category_template_detail_label_date: "날짜",
      hotel_event_category_template_detail_label_language: "언어",
      hotel_event_category_template_detail_empty_guide: "내용을 추가해주세요.",
      hotel_event_category_template_detail_button_go_to_list: "목록으로 가기",
      hotel_event_category_template_detail_button_delete: "삭제",
      hotel_event_category_template_detail_button_edit: "수정",
      hotel_event_category_template_edit_title: "이벤트 카테고리 템플릿 수정",
      hotel_event_category_template_edit_label_title: "제목",
      hotel_event_category_template_edit_label_content: "내용",
      hotel_event_category_template_edit_label_description: "설명",
      hotel_event_category_template_edit_label_date: "날짜",
      hotel_event_category_template_edit_label_language: "언어",
      hotel_event_category_template_edit_button_add: "추가",
      hotel_event_category_template_edit_button_delete: "삭제",
      hotel_event_category_template_edit_empty_guide: "내용을 추가해주세요.",
      hotel_event_category_template_edit_button_go_to_list: "목록으로 가기",
      hotel_event_category_template_edit_button_ok: "확인",
      hotel_event_category_template_edit_validation_title: "* 제목을 입력해주세요.",
      hotel_event_category_template_edit_validation_content: "* 내용을 입력해주세요.",
      hotel_event_category_template_edit_validation_description: "* 설명을 입력해주세요.",
      hotel_staff_user_list_title: "스태프 목록",
      hotel_staff_user_list_select_staff: "스태프 ( 모두 )",
      hotel_staff_user_list_select_all: "모두",
      hotel_staff_user_list_select_department: "부서",
      hotel_staff_user_list_select_team: "팀",
      hotel_staff_user_list_select_level: "직급",
      hotel_staff_user_list_select_task: "태스크",
      hotel_staff_user_list_select_staff_number: "스태프 번호",
      hotel_staff_user_list_select_email: "이메일",
      hotel_staff_user_list_placeholder_search: "검색",
      hotel_staff_user_list_label_department: "부서",
      hotel_staff_user_list_label_team: "팀",
      hotel_staff_user_list_label_level: "직책",
      hotel_staff_user_list_label_task: "태스크",
      hotel_staff_user_list_label_number: "번호",
      hotel_staff_user_list_label_employee_id: "직원 ID",
      hotel_staff_user_list_label_available_at: "사용 가능 시간",
      hotel_staff_user_list_label_expired_at: "만료 시간",
      hotel_staff_user_list_label_login_at: "로그인 시간",
      hotel_staff_user_list_label_date: "날짜",
      hotel_staff_user_list_button_delete: "삭제",
      hotel_staff_user_list_button_add: "추가",
      hotel_staff_user_add_title: "신규 스태프",
      hotel_staff_user_add_guide_click_add: "신규 스태프 등록을 위해 아래 정보를 입력한 후 '추가'를 누르세요.",
      hotel_staff_user_add_validation_department: "* 스태프의 부서를 선택하세요.",
      hotel_staff_user_add_validation_team: "* 스태프의 팀을 선택하세요.",
      hotel_staff_user_add_validation_level: "* 스태프의 직책을 선택하세요.",
      hotel_staff_user_add_validation_guest_inquiry_level: "* 스태프 고객 조회 레벨을 선택하세요.",
      hotel_staff_user_add_validation_number: "* 스태프 번호를 입력하세요.",
      hotel_staff_user_add_validation_task: "* 스태프 태스크를 입력하세요.",
      hotel_staff_user_add_validation_number_only: "* 숫자만 입력하세요.",
      hotel_staff_user_add_validation_email: "* 스태프 이메일을 입력하세요.",
      hotel_staff_user_add_validation_email_format: "* 이메일 형식에 맞게 입력하세요.",
      hotel_staff_user_add_validation_password: "* 스태프 비밀번호를 입력하세요.",
      hotel_staff_user_add_validation_character_number_combining: "* 영문과 숫자를 조합하여 8자 이상 입력하세요.",
      hotel_staff_user_add_validation_not_mach: "* 비밀번호가 일치하지 않습니다.",
      hotel_staff_user_add_label_type: "타입",
      hotel_staff_user_add_select_full_time: "정규직",
      hotel_staff_user_add_select_temporary: "비정규직",
      hotel_staff_user_add_placeholder_select_box_choose: "선택",
      hotel_staff_user_add_label_department: "부서",
      hotel_staff_user_add_select_manage: "관리",
      hotel_staff_user_add_select_concierge: "컨시어지",
      hotel_staff_user_add_select_housekeeping: "하우스키핑",
      hotel_staff_user_add_select_front_desk: "프런트 데스크",
      hotel_staff_user_add_select_valet: "발렛",
      hotel_staff_user_add_label_team: "팀",
      hotel_staff_user_add_select_none: "없음",
      hotel_staff_user_add_select_guest_service: "고객 서비스",
      hotel_staff_user_add_label_level: "직급",
      hotel_staff_user_add_select_manager: "매니저",
      hotel_staff_user_add_select_supervisor: "슈퍼바이저",
      hotel_staff_user_add_select_staff: "스태프",
      hotel_staff_user_add_select_duty_manager: "당직지배인",
      hotel_staff_user_add_label_guest_inquiry_level: "고객 조회 레벨",
      hotel_staff_user_add_label_number: "번호",
      hotel_staff_user_add_placeholder_staff_number: "스태프 번호",
      hotel_staff_user_add_label_location: "위치",
      hotel_staff_user_add_placeholder_staff_location: "스태프 위치",
      hotel_staff_user_add_label_task: "태스크",
      hotel_staff_user_add_placeholder_staff_task: "스태프 태스크",
      hotel_staff_user_add_label_email: "이메일",
      hotel_staff_user_add_placeholder_staff_email: "스태프 이메일",
      hotel_staff_user_add_label_password: "비밀번호",
      hotel_staff_user_add_placeholder_staff_password: "스태프 비밀번호",
      hotel_staff_user_add_label_confirm_password: "확인 비밀번호",
      hotel_staff_user_add_placeholder_staff_confirm_password: "확인 비밀번호",
      hotel_staff_user_add_label_available_at: "가능 시간",
      hotel_staff_user_add_label_expired_at: "만료 시간",
      hotel_staff_user_add_button_add: "추가",
      hotel_staff_user_add_button_go_to_list: "목록으로 가기",
      hotel_staff_user_detail_title: "스태프 정보",
      hotel_staff_user_detail_label_update_date: "업데이트된 날짜",
      hotel_staff_user_detail_label_department: "부서",
      hotel_staff_user_detail_label_team: "팀",
      hotel_staff_user_detail_label_level: "직책",
      hotel_staff_user_detail_label_guest_inquiry_level: "고객 조회 레벨",
      hotel_staff_user_detail_label_task: "태스크",
      hotel_staff_user_detail_label_staff_number: "스태프 번호",
      hotel_staff_user_detail_label_email: "이메일",
      hotel_staff_user_detail_label_location: "위치",
      hotel_staff_user_detail_label_room: "객실",
      hotel_staff_user_detail_label_login_at: "로그인 시간",
      hotel_staff_user_detail_label_date: "날짜",
      hotel_staff_user_detail_label_available_at: "계정 사용 시작 시간",
      hotel_staff_user_detail_label_expired_at: "계정 사용 만료 시간",
      hotel_staff_user_detail_label_locked_information: "잠김 정보",
      hotel_staff_user_detail_label_locked_reason: "잠김 사유",
      hotel_staff_user_detail_label_locked_at: "잠김 시간",
      hotel_staff_user_detail_label_password: "비밀번호",
      hotel_staff_user_detail_label_password_change: "비밀번호 변경",
      hotel_staff_user_detail_button_go_to_list: "목록으로 가기",
      hotel_staff_user_detail_button_delete: "삭제",
      hotel_staff_user_detail_button_edit: "수정",
      hotel_staff_user_edit_title: "스태프 정보",
      hotel_staff_user_edit_label_update_date: "업데이트된 날짜",
      hotel_staff_user_edit_label_department: "부서",
      hotel_staff_user_edit_placeholder_select_box_choose: "선택",
      hotel_staff_user_edit_validation_department: "* 스태프 부서를 선택하세요.",
      hotel_staff_user_edit_select_manage: "관리부",
      hotel_staff_user_edit_select_concierge: "컨시어지",
      hotel_staff_user_edit_select_housekeeping: "하우스키핑",
      hotel_staff_user_edit_select_front_desk: "프런트 데스크",
      hotel_staff_user_edit_select_valet: "발렛",
      hotel_staff_user_edit_label_team: "팀",
      hotel_staff_user_edit_select_none: "없음",
      hotel_staff_user_edit_select_guest_service: "고객 서비스",
      hotel_staff_user_edit_label_level: "직급",
      hotel_staff_user_edit_select_manager: "매니저",
      hotel_staff_user_edit_select_supervisor: "슈퍼바이저",
      hotel_staff_user_edit_select_staff: "스태프",
      hotel_staff_user_edit_select_duty_manager: "당직지배인",
      hotel_staff_user_edit_label_task: "태스크",
      hotel_staff_user_edit_placeholder_task: "태스크",
      hotel_staff_user_edit_label_staff_number: "스태프 번호",
      hotel_staff_user_edit_placeholder_staff_number: "스태프 번호",
      hotel_staff_user_edit_label_email: "이메일",
      hotel_staff_user_edit_placeholder_email: "이메일",
      hotel_staff_user_edit_label_location: "위치",
      hotel_staff_user_edit_placeholder_location: "위치",
      hotel_staff_user_edit_label_room: "객실",
      hotel_staff_user_edit_label_login_at: "로그인 시간",
      hotel_staff_user_edit_label_date: "날짜",
      hotel_staff_user_edit_label_available_at: "계정 사용 시작 시간",
      hotel_staff_user_edit_label_expired_at: "계정 사용 만료 시간",
      hotel_staff_user_edit_label_locked_information: "잠김 정보",
      hotel_staff_user_edit_label_locked_reason: "잠김 사유",
      hotel_staff_user_edit_label_locked_at: "잠김 시간",
      hotel_staff_user_edit_button_cancel: "취소",
      hotel_staff_user_edit_button_delete: "삭제",
      hotel_staff_user_edit_button_ok: "확인",
      contents_failed_unable_connect_server: "서버에 연결할 수 없습니다. \n 인터넷 연결을 확인하세요.",
      button_ok: "확인",
      button_cancel: "취소",
      button_failed_login_message: "이메일과 비밀번호가 일치하지 않습니다.",
      button_delete_message: "선택한 정보를 정말 삭제하겠습니까?",
      button_no_select_delete_message: "선택 없음. \n 삭제할 목록을 선택하세요.",
      button_no_select_hotel_copy_message: "선택 없음.\n복사할 호텔을 선택하세요.",
      button_only_one_hotel_copy_message: "호텔을 하나만 선택하십시오.",
      button_cancel_message: "선택한 정보를 정말 취소하겠습니까?",
      button_no_select_cancel_message: "선택 없음. \n 취소할 목록을 선택하세요.",
      button_admin_cant_delete_message: "관리자는 삭제할 수 없습니다. 선택을 해제해 주세요.",
      button_manager_cant_delete_message: "매니저는 삭제할 수 없습니다.\n 선택을 해제해 주세요.",
      button_cant_release_message: "해당 회원을 해제할 수 없습니다.\n 선택을 해제해 주세요.",
      button_withdraw_message: "철회하시겠습니까?",
      button_release_message: "선택한 정보를 정말 해제하겠습니까?",
      button_no_select_release_message: "선택 없음. \n 해제할 목록을 선택하세요.",
      button_delete_qna_message: "QnA를 정말 삭제하겠습니까?",
      button_update_information_message: "일치하는 정보가 존재합니다. \n 정보를 업데이트하겠습니까?",
      button_delete_information_message: "기존 정보를 삭제하고 \n 새 정보로 업데이트합니다.",
      button_insert_information_message: "새 정보 입력",
      button_qna_loading_message: "로딩 중…",
      button_cancel_not_save_message: "취소하면 내용이 저장되지 않습니다. \n 정말 취소하시겠습니까?",
      button_cancel_booking_message: "선택한 예약을 취소하시겠습니까?",
      button_delete_owner_message: "선택한 메인 고객을 삭제하시겠습니까?",
      button_success_message: "수정이 완료되었습니다.",
      button_stop_message: "선택한 요청을 중단하시겠습니까?\n (현황 파악 불가)",
      button_no_select_stop_message: "선택 없음. \n 정지할 목록을 선택하세요.",
      button_upload_success_message: "업로드 완료",
      button_reservation_message: "미완료 요청이 있습니다. 요청을 확인하시겠습니까?",
      button_amenity_message_title: "품목번호",
      button_amenity_message: "해당 어메니티의 품목번호를 다음과 같이 수정하시겠습니까?",
      button_transfer_user_id_message: "사용자 정보를 아래 이메일 정보로 전송하시겠습니까?",
      contents_failed_session_password: "최소 8자 이상의 새 비밀번호를 입력하세요.",
      contents_session_out_message: "세션 종료. \n 로그인 페이지로 이동하세요.",
      contents_failed_session_message: "세션 불러오기 실패",
      contents_failed_password_incorrect_message: "현재 비밀번호가 잘못되었습니다.",
      contents_failed_get_hotel_detail: "호텔 상세정보를 불러오지 못했습니다.",
      contents_failed_get_region_detail: "지역 정보를 불러오지 못했습니다.",
      contents_failed_get_region_list: "지역 목록을 불러오지 못했습니다.",
      contents_failed_get_country_detail: "국가 정보를 불러오지 못했습니다.",
      contents_failed_get_country_list: "국가 목록을 불러오지 못했습니다.",
      contents_failed_get_city_detail: "도시 정보를 불러오지 못했습니다.",
      contents_failed_get_city_list: "도시 목록을 불러오지 못했습니다.",
      contents_failed_edit_hotel: "호텔정보를 수정하지 못했습니다.",
      contents_failed_edit_region: "지역정보를 수정하지 못했습니다.",
      contents_failed_edit_country: "국가정보를 수정하지 못했습니다.",
      contents_failed_edit_city: "도시정보를 수정하지 못했습니다.",
      contents_failed_edit_amenity: "편의시설을 수정하지 못했습니다.",
      contents_failed_add_amenity: "편의시설 추가할 수 없음.",
      contents_failed_edit_recommend_app: "앱 추천을 수정하지 못했습니다.",
      contents_failed_add_recommend_app: "앱 추천 추가할 수 없음.",
      contents_failed_edit_id_recognition_information: "신분증 인식 정보를 수정하지 못했습니다.",
      contents_failed_add_id_recognition_information: "신분증 인식 정보를 추가할 수 없음.",
      contents_failed_get_list: "해당 페이지 목록을 불러오지 못했습니다.",
      contents_failed_logout: "로그아웃 실패",
      contents_failed_get_informarion: "사용자 정보를 불러오지 못했습니다.",
      contents_failed_edit_information: "정보를 수정하지 못했습니다.",
      contents_failed_modify: "암호 변경 실패.",
      contents_failed_add_user: "사용자를 추가할 수 없습니다. 이미 가입된 이메일인지 확인해 주세요.",
      contents_failed_transfer: "링크 전송에 실패했습니다.",
      contents_failed_change_duty: "직무 변경에 실패했습니다.",
      contents_failed_edit_user: "사용자 수정에 실패했습니다.",
      contents_failed_get_withdraw: "철회에 실패했습니다.",
      contents_failed_add_version: "버전을 추가할 수 없습니다.",
      contents_failed_edit_version: "버전을 수정하지 못했습니다.",
      contents_failed_delete: "삭제에 실패했습니다.",
      contents_failed_image: "이미지 정보를 불러오지 못했습니다.",
      contents_failed_get_area_list: "특별 구역 목록을 불러오지 못했습니다.",
      contents_failed_get_hotel_detail_list: "호텔 상세정보 목록을 불러오지 못했습니다.",
      contents_failed_get_hotel_detail_detail: "호텔 상세정보 불러오기 실패",
      contents_failed_get_facility_icon_list: "호텔 시설 아이콘 목록을 불러오지 못했습니다.",
      contents_failed_get_hotel_amenity_list: "호텔 편의시설 목록을 불러오지 못했습니다.",
      contents_failed_add_hotel_detail_content: "호텔 상세내용 추가할 수 없음.",
      contents_failed_add_hotel_detail_amenity: "호텔 편의시설 추가할 수 없음.",
      contents_failed_get_hotel_recommend_application_list: "호텔 추천 앱 목록을 불러오지 못했습니다.",
      contents_failed_add_hotel_detail_recommend_application: "호텔 추천 앱 추가할 수 없음.",
      contents_failed_get_hotel_img_detail: "호텔 이미지 상세 불러오기 실패",
      contents_failed_get_facility_img_detail: "호텔 시설 이미지 상세 불러오기 실패",
      contents_failed_add_hotel_img: "호텔 이미지 추가할 수 없음.",
      contents_failed_add_facility_img: "호텔 시설 이미지 추가할 수 없음.",
      contents_failed_add_room_img: "객실 이미지 추가할 수 없음.",
      contents_failed_get_room_amenity_list: "객실 편의시설 목록을 불러오지 못했습니다.",
      contents_failed_add_room_detail_amenity: "객실 편의시설 추가할 수 없음.",
      contents_failed_get_room_img_detail: "객실 이미지 상세 불러오기 실패",
      contents_failed_edit_hotel_detail_content: "호텔 상세내용 수정 실패",
      contents_failed_edit_room: "객실 수정 실패",
      contents_failed_edit_room_detail_content: "객실 상세내용 수정 실패",
      contents_failed_add_package: "패키지 추가할 수 없음.",
      contents_failed_add_package_detail: "패키지 상세정보 추가할 수 없음.",
      contents_failed_get_package_detail: "패키지 불러오기 실패",
      contents_failed_get_package_detail_detail: "패키지 상세정보 불러오기 실패",
      contents_failed_get_access_detail_detail: "접근 상세정보 불러오기 실패",
      contents_failed_add_access: "접근 추가할 수 없음.",
      contents_failed_edit_access: "접근 수정 실패",
      contents_failed_add_access_detail: "접근 상세정보 추가할 수 없음.",
      contents_failed_add_beacon: "비콘 추가할 수 없음.",
      contents_failed_add_region: "지역을 추가할 수 없음.",
      contents_failed_add_country: "국가를 추가할 수 없음.",
      contents_failed_add_city: "도시를 추가할 수 없음.",
      contents_failed_edit_beacon: "비콘 수정 실패",
      contents_failed_add_currency: "통화 추가할 수 없음.",
      contents_failed_get_currency_detail: "호텔 통화 정보 불러오기 실패",
      contents_not_supported_exchange_rate: "환율정보 지원되지 않음",
      contents_failed_get_currency_pms: "PMS 호텔 통화 불러오기 실패",
      contents_failed_get_currency_update: "호텔 통화 업데이트 실패",
      contents_failed_request_stop: "정지 요청 실패",
      contents_failed_upload_file: "파일 업로드 실패",
      contents_failed_get_count_canceled: "취소 카운트 보드 불러오기 실패",
      contents_failed_get_business_rule_list: "업무 규칙 목록 불러오기 실패",
      contents_failed_uptade_myconect: "MyCONECT 동작시간 설정 업데이트 실패",
      contents_failed_get_concierge_myconect: "MyCONECT 컨시어지 목록 불러오기 실패",
      contents_request_type_not_exist: "요청 유형이 존재하지 않습니다.",
      contents_failed_get_request_detail: "요청 상세정보를 불러오지 못했습니다.",
      contents_failed_request_stand_by: "대기 요청을 하지 못했습니다.",
      contents_failed_request_pickup: "픽업 요청을 하지 못했습니다.",
      contents_failed_request_start: "시작 요청을 하지 못했습니다.",
      contents_failed_request_deliver: "배달 요청을 하지 못했습니다.",
      contents_failed_request_valet_no: "발렛 요청을 하지 못했습니다.",
      contents_failed_get_hotel_setting: "호텔 설정을 불러오지 못했습니다.",
      contents_failed_get_count_request_board: "카운트 보드를 불러오지 못했습니다.",
      contents_failed_get_concierge_process_list: "컨시어지 진행 목록을 불러오지 못했습니다.",
      contents_failed_get_valet_parking_list: "발렛파킹 목록을 불러오지 못했습니다.",
      contents_failed_add_parking_area_no: "주차 구역 번호를 추가할 수 없습니다.",
      contents_failed_change_veicle_no: "차량 번호를 변경할 수 없습니다.",
      contents_failed_request_location: "차량 준비에 실패하였습니다.",
      contents_failed_request_return: "차량 회차에 실패하였습니다.",
      contents_failed_request_handover: "차량 인도에 실패하였습니다.",
      contents_failed_get_business_rule: "업무 규칙을 불러오지 못했습니다.",
      contents_failed_get_boundary_line_setting: "제한 설정을 불러오지 못했습니다.",
      contents_failed_request_confirm: "확인을 요청하지 못했습니다.",
      contents_failed_request_not_available: "사용불가'를 요청하지 못했습니다",
      contents_failed_request_not_available_myconect: "MyCONECT 서비스 불가'를 요청하지 못했습니다.",
      contents_failed_get_concierge_request_list: "컨시어지 요청 목록을 불러오지 못했습니다.",
      contents_already_exists: "해당 유형은 이미 존재합니다.",
      contents_failed_add_help_for_mobile_key: "모바일 키 도움말을 추가하지 못했습니다.",
      contents_failed_get_help_for_mobile_key: "모바일 키 도움말을 불러오지 못했습니다.",
      contents_failed_edit_help_for_mobile_key: "모바일 키 도움말을 수정하지 못했습니다.",
      contents_failed_delete_help_for_mobile_key: "모바일 키 도움말 정보를 삭제하지 못했습니다.",
      contents_failed_add_service_description: "서비스 설명을 추가하지 못했습니다.",
      contents_failed_get_service_description: "서비스 설명을 불러오지 못했습니다.",
      contents_failed_edit_service_description: "서비스 설명을 수정하지 못했습니다.",
      contents_failed_delete_service_description: "서비스 설명을 삭제하지 못했습니다.",
      contents_failed_add_hotel_information: "호텔 정보를 추가하지 못했습니다.",
      contents_failed_get_hotel_information: "호텔 정보를 불러오지 못했습니다.",
      contents_failed_edit_hotel_information: "호텔 정보를 수정하지 못했습니다.",
      contents_failed_edit_language_code_translation: "이미 선택한 언어입니다.",
      contents_failed_all_language_code_exist: "모든 번역이 추가되었습니다.",
      contents_failed_delete_hotel_information: "호텔 정보를 삭제하지 못했습니다.",
      contents_failed_get_canceled_user_count: "사용자 삭제 횟수 불러오기 실패",
      contents_failed_get_canceled_staff_count: "MyCONECT 또는 스태프 취소 횟수 불러오기 실패",
      contents_failed_get_housekeeping_myconect: "하우스키핑 MyCONECT 목록 불러오기 실패",
      contents_failed_add_multi_myconect: "선택한 날짜가 잘못되었습니다.",
      contents_failed_request_complete: "'완료' 요청 실패",
      contents_failed_request_put_off: "'연기' 요청 실패",
      contents_failed_get_amenity_count: "어메니티 개수 불러오기 실패",
      contents_failed_get_valet_count: "발렛 개수 불러오기 실패",
      contents_failed_get_housekeeping_process_list: "하우스키핑 진행 목록 불러오기 실패",
      contents_failed_get_boundary_line_request_count: "제한설정 요청 카운트 불러오기 실패",
      contents_failed_update_auto: "그린캠페인 자동모드 시간 설정 업데이트 실패",
      contents_failed_get_housekeeping_request_list: "하우스키핑 요청 목록 불러오기 실패",
      contents_failed_get_room_controller_detail: "객실제어 상세정보 불러오기 실패",
      contents_failed_insert_files: "파일 추가 실패",
      contents_failed_get_room_to_room_detail: "룸투룸 상세정보 불러오기 실패",
      contents_failed_get_share_key_detail: "공유 키 상세정보 불러오기 실패",
      contents_failed_modify_amenity: "어메니티 품목번호 수정 실패",
      contents_failed_get_boundary_line_detail: "제한 설정 상세 불러오기 실패",
      contents_failed_modify_boundary_line_detail: "제한 설정 상세 수정 실패",
      contents_failed_modify_boundary_line_unasigned_count: "미지정 제한 설정 카운트 불러오기 실패",
      contents_failed_get_business_rule_detail: "업무규칙 상세 불러오기 실패",
      contents_failed_modify_business_rule_detail: "업무규칙 상세 수정 실패",
      contents_failed_modify_waiting_time: "객실 대기시간 수정 실패",
      contents_failed_cancel: "취소 실패",
      contents_failed_add_member_type: "회원 타입 추가 불가",
      contents_failed_edit_member_type: "회원 타입 수정 실패",
      contents_failed_edit_pms: "PMS 수정 실패",
      contents_failed_sync: "동기화 실패",
      contents_failed_send_reservation_email: "예약 확인 메일 전송 실패",
      contents_failed_get_staff_key_link_list: "스태프 키 링크 목록 불러오기 실패",
      contents_failed_get_staff_key_link_type_list: "스태프 키 링크 타입 목록 불러오기 실패",
      contents_failed_get_staff_key_link_floor_list: "스태프 키 링크 층 목록 불러오기 실패",
      contents_failed_get_staff_key_link_zone_list: "스태프 키 링크 구역 목록 불러오기 실패",
      contents_failed_get_staff_key_link_code_list: "스태프 키 링크 코드 목록 불러오기 실패",
      contents_failed_get_staff_detail: "스태프 상세정보 불러오기 실패",
      contents_failed_edit_staff_user: "스태프 사용자 수정 실패",
      contents_failed_add_title: "호칭 추가할 수 없음",
      contents_failed_edit_title: "호칭 수정 실패",
      contents_failed_get_list_search_page: "검색 페이지 목록 불러오기 실패",
      contents_failed_edit_setting: "설정 수정 실패",
      contents_failed_add_staff_key_link: "스태프 키 링크 추가할 수 없음",
      contents_failed_edit_staff_key_link: "스태프 키 링크 수정 실패",
      contents_failed_edit_hotel_img: "호텔 이미지 수정 실패",
      contents_failed_edit_facility_img: "호텔 시설 내용 이미지 수정 실패",
      contents_failed_edit_room_img: "객실 이미지 수정 실패",
      contents_failed_edit_package_detail: "패키지 상세정보 수정 실패",
      contents_failed_edit_access_detail: "접근 상세정보 수정 실패",
      contents_failed_add_guest_app_version: "게스트 앱 버전 추가 실패",
      contents_failed_add_staff_app_version: "스태프 앱 버전 추가 실패",
      contents_failed_get_rule_information: "규칙 정보를 불러오지 못했습니다.",
      contents_failed_edit_rule_information: "규칙 정보를 수정하지 못했습니다.",
      contents_failed_edit_sequence_information: "순서를 수정하지 못했습니다.",
      contents_failed_get_rule_schedule_information: "규칙 스케줄 정보를 불러오지 못했습니다.",
      contents_failed_edit_rule_schedule_information: "규칙 스케줄 정보를 수정하지 못했습니다.",
      None: "얼굴 비교 정보를 불러오지 못했습니다.",
      contents_modal_stop_message_unknown: "중단 메시지를 '현황 파익 불가'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_no_taxi: "중단 메시지를 '택시 노쇼'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_no_guest: "중단 메시지를 '고객 노쇼'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_time_out_30: "중단 메시지를 '대기시간 30분 초과'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_time_out_60: "중단 메시지를 '대기시간 60분 초과'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_no_motorcycle: "중단 메시지를 '배달 오토바이 노쇼'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_no_luggage: "중단 메시지를 '수하물 준비 안됨'으로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_delay_30: "연기 메시지를 '30분 지연'으로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_delay_60: "연기 메시지를 '60분 지연'으로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_as_soon_as: "연기 메시지를 '빠른 시간 내'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_out_of_order: "중단 메시지를 '객실 불량'으로 저장하겠습니까?",
      contents_modal_stop_message_cancel_service: "중단 메시지를 '서비스 취소'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_not_found_it: "중단 메시지를 '찾을 수 없음'으로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_no_guest_room: "중단 메시지를 '고객 부재'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_change_items: "중단 메시지를 '품목 변경'으로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_unparked: "중단 메시지를 '주차되지 않은 상태로 취소'으로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_stop_message_parked: "중단 메시지를 '주차되지 않은 상태로 취소'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_myconect_release_proceed: "중단 메시지를 '주차된 상태로 취소'로 저장합니다.\n 계속하시겠습니까?",
      contents_modal_pickup_request: "요청을 픽업하겠습니까?",
      contents_modal_deliver_request: "요청을 배달하겠습니까?",
      contents_modal_start_request: "요청을 시작하겠습니까?",
      contents_modal_confirm_request: "요청을 진행하겠습니까?",
      contents_modal_not_available_request: "요청을 '서비스 불가'로 설정하겠습니까?",
      contents_modal_complete_request: "요청을 완료하겠습니까?",
      contents_modal_greencampaign_auto_process: "그린 캠페인 자동모드를 실행하겠습니까?",
      contents_modal_select_staff_payment: "선택한 정보로 진행하시겠습니까?",
      contents_modal_select_business_rule: "선택한 정보로 진행하시겠습니까?",
      contents_modal_delay_request: "요청을 지연하시겠습니까?",
      contents_modal_put_off_request: "요청은 연기하겠습니까?",
      contents_modal_change_request: "객실 상태를 변경하시겠습니까?",
      contents_modal_save_these_modifications: "수정사항을 저장하겠습니까?",
      contents_modal_select_myconect_not_available: "MyCONECT 서비스 불가' 설정 시간 선택",
      contents_modal_select_delay: "지연' 설정 시간 선택",
      contents_modal_select_status: "상태를 선택하세요",
      contents_modal_make_sure_reason: "사유를 선택하세요",
      contents_modal_myconect_not_available_proceed: "MyCONECT에 의해 해당 요청을 '서비스 불가'로 설정합니다. \n \n\n 계속하시겠습니까?",
      contents_modal_myconect_time_proceed: "MyCONECT에 의해 적용시간이 다음과 같이 처리됩니다:\n 시작: {{fromTime}}  종료: {{endTime}}\n \n 계속하시겠습니까?",
      contents_modal_greencampaign_time_proceed: "그린 캠페인이 다음과 같이 처리됩니다:\n 시작: {{fromTime}}  종료: {{endTime}}\n \n 계속하시겠습니까?",
      contents_modal_cannot_empty_value: "값이 비어있습니다.\n 값을 입력해 주세요.",
      contents_modal_information_already_exists: "정보가 이미 존재합니다.\n 다시 입력해 주세요.",
      contents_modal_placeholder_user_email: "사용자의 이메일을 입력하십시오.",
      contents_modal_check_reset: "이전에 선택했던 사용자가 초기화됩니다. 계속 진행하겠습니까?",
    },
  },
  date_format: {
    ko: {
      date_format_slash_time_date: {
        date_format: "yy/MM/dd a hh:mm",
        language_code: "ko",
      },
      date_format_slash_date: {
        date_format: "yy/MM/dd",
        language_code: "ko",
      },
      date_format_hyphen_date: {
        date_format: "yyyy-MM-dd",
        language_code: "ko",
      },
      date_format_dot_month_year: {
        date_format: "yyyy.MMM",
        language_code: "ko",
      },
      date_format_dot_year_month: {
        date_format: "yyyy.MM",
        language_code: "ko",
      },
      date_format_slash_year_month: {
        date_format: "yy/MM",
        language_code: "ko",
      },
      date_format_ampm_time: {
        date_format: "a hh:mm",
        language_code: "ko",
      },
      date_format_time_ampm: {
        date_format: "a hh:mm",
        language_code: "ko",
      },
      date_format_24_hours_time: {
        date_format: "HH:mm",
        language_code: "ko",
      },
      date_format_hours_ampm: {
        date_format: "ha",
        language_code: "ko",
      },
      date_format_full_year: {
        date_format: "yyyy",
        language_code: "ko",
      },
      date_format_word_month: {
        date_format: "MMM",
        language_code: "ko",
      },
      date_format_full_day: {
        date_format: "dd",
        language_code: "ko",
      },
      date_format_short_day: {
        date_format: "d",
        language_code: "ko",
      },
      date_format_day_of_week: {
        date_format: "E",
        language_code: "ko",
      },
      date_format_ampm: {
        date_format: "a",
        language_code: "ko",
      },
      date_format_day_of_week_full: {
        date_format: "EEEE",
        language_code: "ko",
      },
      date_format_dot_month_day: {
        date_format: "MM.dd",
        language_code: "ko",
      },
      date_format_day_of_week_date: {
        date_format: "yyyy'년' MM'월' dd'일' E",
        language_code: "ko",
      },
      date_format_slash_date_time: {
        date_format: "yy/MM/dd a hh:mm",
        language_code: "ko",
      },
      date_format_slash_month_day_time: {
        date_format: "MM/dd a hh:mm",
        language_code: "ko",
      },
      date_format_dot_date_time_full: {
        date_format: "yyyy.MM.dd a hh:mm",
        language_code: "ko",
      },
      date_format_dot_year_month_day: {
        date_format: "yy.MM.dd",
        language_code: "ko",
      },
      date_format_slash_full_year_date: {
        date_format: "yyyy/MM/dd",
        language_code: "ko",
      },
      date_format_space_date: {
        date_format: "yyyy'년' MM'월' dd'일'",
        language_code: "ko",
      },
      date_format_create_at_time_full: {
        date_format: "yyyy.MM.dd\na hh:mm",
        language_code: "ko",
      },
    },
  },
};
export default KoreanLanguage;
