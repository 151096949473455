import CommonComponent from "../../../components/CommonComponent.js";

// modules 
import * as authActions from "../../../../redux/modules/Auth.js";
import * as transferActions from "../../../../redux/modules/Transfer.js";

// language
import { multiLanguageCode, MultiLanguage } from "../../../components/common/Language";

class StaffTransferLogHistoryListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      staffTransferLogHistoryList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.staffTransferLogHistoryList != this.props.staffTransferLogHistoryList) {
      if (this.state.requestType == 'GET_STAFF_TRANSFER_LOG_HISTORY_LIST') {
        this.onGetStaffTransferLogHistoryListResults(nextProps);
      }
    }
  }

  // redux에 get transfer list 요청
  requestGetStaffTransferLogHistoryList = (pageNo, userId, hotelId, search, startDate, endDate) => {

    this.setState({
      requestType: "GET_STAFF_TRANSFER_LOG_HISTORY_LIST",
    });
    this.props.onGetAll(pageNo, userId, hotelId, search, startDate, endDate);
  };

  // get transfer list results
  onGetStaffTransferLogHistoryListResults = (props) => {
    if (props.staffTransferLogHistoryList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({
      isShowAllLoading: false,
      requestType: "",
    });
    if (props.staffTransferLogHistoryList?.success == true) {
      
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      staffTransferLogHistoryList: state.transfer.staffTransferLogHistoryList,
      failData: state.transfer.failData,
      successDelete: state.transfer.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAll: (pageNo, userId, hotelId, search, startDate, endDate) => dispatch(transferActions.onGetAllStaffTransferLogHistoryList(pageNo, userId, hotelId, search, startDate, endDate)),
    };

    return returnProps;
  };
}

export default StaffTransferLogHistoryListData;
