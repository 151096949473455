import CommonComponent from "../../../components/CommonComponent.js";

// modules
import * as transferActions from "../../../../redux/modules/Transfer.js";

class TransferLogListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: '',
      transferLogList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.transferLogList != this.props.transferLogList) {
      if (this.state.requestType == 'GET_TRANSFER_LOG_LIST') {
        this.onGetTransferLogListResults(nextProps);
      }
    }
  }

  // redux에 get transfer list 요청
  requestGetTransferLogList = (pageNo, userId, hotelId, search, startDate, endDate) => {
    this.setState({
      requestType: 'GET_TRANSFER_LOG_LIST',
    });
    this.props.onGetAll(pageNo, userId, hotelId, search, startDate, endDate);
  };

  // get transfer list results
  onGetTransferLogListResults = (props) => {
    if (props.transferLogList?.length === 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: '' });

    if (props.transferLogList?.success == true) {
    } else if (props.failData?.code != 'INVALID_TOKEN') {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage['language'][this.props.multiLanguageCode]['contents_failed_get_list'], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === '') {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog('unknown error.', () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      transferLogList: state.transfer.transferLogList,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, userId, hotelId, search, startDate, endDate) => dispatch(transferActions.onGetAllTransferLogList(pageNo, userId, hotelId, search, startDate, endDate)),
    };

    return returnProps;
  };
}

export default TransferLogListData;
