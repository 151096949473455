import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as facilityImageApis from "../lib/facilityImage_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const FACILITY_IMAGE_GET_ALL = "facilityImage/FACILITY_IMAGE_GET_ALL";
const FACILITY_IMAGE_GET_ONE = "facilityImage/FACILITY_IMAGE_GET_ONE";
const ADD_FACILITY_IMAGE = "facilityImage/ADD_FACILITY_IMAGE";
const DELETE_FACILITY_IMAGE = "facilityImage/DELETE_FACILITY_IMAGE";
const EDIT_FACILITY_IMAGE = "facilityImage/EDIT_FACILITY_IMAGE";

// 액션 생성자
export const onGetAllFacilityImage = createAction(FACILITY_IMAGE_GET_ALL, facilityImageApis.onGetAllFacilityImage);
export const onGetOneFacilityImage = createAction(FACILITY_IMAGE_GET_ONE, facilityImageApis.onGetOneFacilityImage);
export const onAddFacilityImage = createAction(ADD_FACILITY_IMAGE, facilityImageApis.onAddFacilityImage);
export const onDeleteFacilityImage = createAction(DELETE_FACILITY_IMAGE, facilityImageApis.onDeleteFacilityImage);
export const onEditFacilityImage = createAction(EDIT_FACILITY_IMAGE, facilityImageApis.onEditFacilityImage);

// 초기 상태
const initialState = {
    facilityImageList: [],
    facilityImageDetail: null,
    facilityImageAdd: null,
    facilityImageEdit: null,
    successDelete: null,
    failData: null,
};

export default handleActions(
    {
        ...pender({
            type: FACILITY_IMAGE_GET_ALL,
            onPending: (state, action) => {
                return { ...state, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, facilityImageList: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, facilityImageList: [], failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: FACILITY_IMAGE_GET_ONE,
            onPending: (state, action) => {
                return { ...state, facilityImageDetail: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, facilityImageDetail: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: ADD_FACILITY_IMAGE,
            onPending: (state, action) => {
                return { ...state, facilityImageAdd: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, facilityImageAdd: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: DELETE_FACILITY_IMAGE,
            onPending: (state, action) => {
                return { ...state, successDelete: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, successDelete: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: EDIT_FACILITY_IMAGE,
            onPending: (state, action) => {
                return { ...state, facilityImageEdit: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, facilityImageEdit: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
