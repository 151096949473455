import axios from "axios";
import serverUrl from "../../react/common/ServerUrl";
import axiosInstance from "../api/axios";
import { languageCode } from "../../react/components/common/Language";

/* ------------------------------------------------------ login ----------------------------------------------------- */
export function onPost(email, password) {
  const data = {
    email: email,
    password: password,
  }
  return axiosInstance.post("/admin/api/auth/", data);
}

/* ----------------------------------------------------- log out ---------------------------------------------------- */
export function onDelete() {
  return axiosInstance.delete("/admin/api/auth/", {});
}

/* --------------------------------------------------- get session -------------------------------------------------- */
export function onGet() {
  return axiosInstance.get("/admin/api/auth/");
}

/* ----------------------------------------------- reissue admin token ----------------------------------------------- */
export function reissueAdminToken() {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  
  const data = {
    refreshToken: refreshToken,
  };

  const reissueApi = axios.create({
      withCredentials: true,
      // timeout: 3000,
      headers: {
          "Content-Type": "application/json",
          os: "WEB",
          "x-access-token": accessToken,
          "Content-Language": languageCode,
      },
  });
  return reissueApi.post(serverUrl + '/admin/api/auth/byjwt/reissueToken', data);
}