import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import serverUrl from "../../../react/common/ServerUrl";
import { Loading } from "../../components/Paths";
import googleMapsApiKey from "../../components/common/GoogleMapsApiKey";
import HotelDetailData from "../Detail/HotelDetailData";

//css
import "../../css/common.css";
import "../../css/hotel/hotelDetail.css";

class HotelDetailView extends HotelDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      hotelId: "",
      region: "",
      country: "",
      city: "",
    };
  }

  /* ---------------------------------------------------- lifecycle --------------------------------------------------- */
  // 첫 실행시 URL에서 hotelId 파라미터 가져오기
  componentDidMount() {
    // this.loadGoogleMaps();

    const params = new URLSearchParams(window.location.search);
    const hotelId = params.get("hotelId");
    this.setState({
      hotelId: hotelId,
    });

    this.requestGetHotelOne(hotelId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkHotelEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const hotelId = params.get("hotelId");
    this.props.history.push(`/hotelEditPage?hotelId=${hotelId}`);
  };

  /* ----------------------------------------------- Google Maps 초기화 함수 ----------------------------------------------- */
  loadGoogleMaps() {
    if (!window.google || !window.google.maps) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&region=kr`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        this.initializeMap(this.props.hotelDetail.results.latitude, this.props.hotelDetail.results.longitude);
      };
      document.head.appendChild(script);
    } else {
      this.initializeMap(this.props.hotelDetail.results.latitude, this.props.hotelDetail.results.longitude);
    }
  }

  initializeMap(hotelLatitude, hotelLongitude) {
    const latitude = parseFloat(hotelLatitude);
    const longitude = parseFloat(hotelLongitude);

    const latlng = { lat: latitude, lng: longitude };

    let map = null;
    // geocoder = new google.maps.Geocoder();

    // 위도와 경도가 유효한 숫자인지 확인합니다.
    if (!isNaN(latitude) && !isNaN(longitude)) {
      const mapOptions = {
        center: latlng,
        zoom: 17,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      };

      map = new window.google.maps.Map(document.getElementById("map_canvas"), mapOptions);

      // 마커 추가
      const marker = new window.google.maps.Marker({
        position: latlng,
        map: map,
        title: "호텔 위치",
      });
    } else {
      // 유효하지 않은 위도 또는 경도
    }
  }

  render() {
    let hotelDetail = null;
    let hotelPaymentSetting = null;
    let imagePath = "";

    if (this.props.hotelDetail != null && this.props.hotelDetail?.success == true) {
      hotelDetail = this.props.hotelDetail.results;
      imagePath = this.props.hotelDetail.results.logoFileId;
    }
    if (this.props.paymentSetting != null && this.props.paymentSetting?.success == true) {
      hotelPaymentSetting = this.props.paymentSetting.results;
    }

    return (
      <div className="hotelDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="hotelinfo_detail_container">
          <div className="hotelinfo_detail_container_inner">
            <div className="title_container" data-detect="admin_hotel_detail_title">
              Hotel Information
            </div>
            <div className="hotelinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="hotel_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_hotel_no">
                        Hotel No.
                      </div>
                      <div className="detail_cell content hotel_no" style={{ color: "white" }}>
                        <span id="hotel_id">{hotelDetail?.id??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_name">
                        Name
                      </div>
                      <div className="detail_cell content hotel_name" style={{ color: "white" }}>
                        <span id="hotel_id">{hotelDetail?.name??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_address">
                        Address
                      </div>
                      <div className="detail_cell content hotel_address" style={{ color: "white" }}>
                        <span id="hotel_id">{hotelDetail?.address??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_region">
                        Region
                      </div>
                      <div className="detail_cell content hotel_region">
                        <span id="hotel_region">{hotelDetail?.region??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_country">
                        Country
                      </div>
                      <div className="detail_cell content hotel_country">
                        <span id="hotel_country">{hotelDetail?.country??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_city">
                        City
                      </div>
                      <div className="detail_cell content hotel_city">
                        <span id="hotel_city">{hotelDetail?.city??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_country_name">
                        Country Name
                      </div>
                      <div className="detail_cell content hotel_country_name">
                        <span id="hotel_country_name">{hotelDetail?.countryName??"-"}</span>
                      </div>
                    </div>
                    {/* payment setting */}
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_payment_server_url">
                        Payment Server URL
                      </div>
                      <div className="detail_cell content hotel_paymentURL">
                        <span id="paymentServerURL">{hotelPaymentSetting?.paymentServerURL??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_is_direct_payable">
                        Is Direct Payable
                      </div>
                      <div className="detail_cell content hotel_isDirectPayable radio_container">
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="isDirectPayable" value="Y" checked={hotelPaymentSetting?.isDirectPayable == "Y"} data-detect="admin_hotel_edit_button_yes" disabled />
                            Yes
                          </label>
                        </div>
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="isDirectPayable" value="N" checked={hotelPaymentSetting?.isDirectPayable == "N"} data-detect="admin_hotel_edit_button_no" disabled />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_is_card_tokenizable">
                        Is Card Tokenizable
                      </div>
                      <div className="detail_cell content hotel_isCardTokenizable radio_container">
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="isCardTokenizable" value="Y" checked={hotelPaymentSetting?.isCardTokenizable == "Y"} data-detect="admin_hotel_edit_button_yes" disabled />
                            Yes
                          </label>
                        </div>
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="isCardTokenizable" value="N" checked={hotelPaymentSetting?.isCardTokenizable == "N"} data-detect="admin_hotel_edit_button_no" disabled />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_is_show">
                        Is Show
                      </div>
                      <div className="detail_cell content hotel_is_show radio_container">
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="isShow" value="Y" checked={hotelDetail?.isShow == "Y"} data-detect="admin_hotel_edit_button_yes" disabled />
                            Yes
                          </label>
                        </div>
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="isShow" value="N" checked={hotelDetail?.isShow == "N"} data-detect="admin_hotel_edit_button_no" disabled />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_logo">
                        Logo
                      </div>
                      <div className="detail_cell content hotel_logo logo_image_form">
                        <div className="imageDiv">
                          <img className="image_form" src={serverUrl + "/image/hotel/logo/" + imagePath} alt="logo" />
                        </div>
                      </div>
                    </div>
                    {/* 구글 맵 */}
                    {/* <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_hotel_detail_label_hotel_map">
                        Map
                      </div>
                      <div className="detail_cell content hotel_map hotel_map">
                        <div id="map_canvas" className="mapCanvas"></div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkHotelEdit();
                  }}
                  data-detect="admin_hotel_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = HotelDetailData.mapStateToProps;
let mapDispatchToProps = HotelDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HotelDetailView));
