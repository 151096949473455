import { routerReducer } from "react-router-redux";
import { combineReducers, compose } from "redux";
import penderMiddleware from "redux-pender";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import ReduxThunk from "redux-thunk";
import storageSession from "redux-persist/lib/storage/session"; 
import { configureStore } from "@reduxjs/toolkit"

// reducers
const reducers = combineReducers({
  constant: require("./modules/Constant").default,
  user: require("./modules/User").default,
  auth: require("./modules/Auth").default,
  hotel: require("./modules/Hotel").default,
  setting: require("./modules/Setting").default,
  location: require("./modules/Location").default,
  app: require("./modules/App").default,
  payment: require("./modules/Payment").default,
  version: require("./modules/Version").default,
  applicationTranslation: require("./modules/ApplicationTranslation").default,
  bookingEmail: require("./modules/bookingEmail").default,
  emailForm: require("./modules/EmailForm").default,
  transfer: require("./modules/Transfer").default,
  applicationEndPoint: require("./modules/ApplicationEndPoint").default,
  termsAndCondition: require("./modules/TermsAndCondition").default,
  authData: require("./modules/AuthData").default,
  useKeyLog: require("./modules/UseKey").default,
  amenity: require("./modules/Amenity").default,
  facilityImage: require("./modules/FacilityImage").default,
  recommend: require("./modules/Recommend").default,
  userInfo: require("./modules/UserInfo").default,
  password: require("./modules/Password").default,
  hotelAppDownload: require("./modules/HotelAppDownload").default,
  otaReservation: require("./modules/OTAReservation").default,
  idRecognition: require("./modules/IDRecognition").default,
  statistics: require("./modules/Statistics").default,
  routing: routerReducer,
});

// Redux-persist 
const persistConfig = {
  key: "root", // 저장소 키 
  // storage, // localStorage 사용
  storage: storageSession, // sessionStorage 사용
};

const persistedReducer = persistReducer(persistConfig, reducers); //Redux Persist에서 제공하는 persistReducer 함수를 사용하여 지속 가능하도록 함.

// store
const isDevelopment = process.env.NODE_ENV == "development"; // 환경이 개발모드인지 확인합니다

const composeEnhancers = isDevelopment
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.config.adapter', 'payload'],
        ignoredPaths: ['constant.dialog.positiveFunction', 'constant.dialog.negativeFunction', 'constant.dialog.closeFunction', 'auth.error'],
      },
      immutableCheck: false,
    }).concat(ReduxThunk, penderMiddleware()), // Adding thunk and penderMiddleware
  devTools: isDevelopment,
});
const persistor = persistStore(store);

export { persistor, store };

