import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import TermsDetailData from "../Detail/TermsDetailData";

//css
import "../../../css/common.css";
import "../../../css/termsAndCondition/Terms/termsDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class TermsDetailView extends TermsDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      termsId: "",
    };
  }

  // 첫 실행시 URL에서 termsId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const termsId = params.get("termsId");
    this.setState({
      termsId: termsId,
    });
    this.requestGetTermsOne(termsId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkTermsEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const termsId = params.get("termsId");
    this.props.history.push(`/termsEditPage?termsId=${termsId}`);
  };



  render() {
    let termsDetail = null;

    if (
      this.props.termsDetail != null &&
      this.props.termsDetail?.success == true
    ) {
      termsDetail = this.props.termsDetail.results;
    }

    return (
      <div className="termsDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="termsinfo_detail_container">
          <div className="termsinfo_detail_container_inner">
            <div className="title_container" data-detect="admin_terms_detail_title">
              TERMS INFORMATION
            </div>
            <div className="termsinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="terms_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_title">
                        Title
                      </div>
                      <div className="detail_cell content terms_title">
                        <span id="terms_id">{termsDetail?.title ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_language_code">
                        Language Code
                      </div>
                      <div className="detail_cell content language_code">
                        <span id="terms_id">{termsDetail?.languageCode ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_description">
                        Description
                      </div>
                      <div
                        className="detail_cell content terms_description"
                        dangerouslySetInnerHTML={{
                          __html: termsDetail?.description ?? '-',
                        }}
                      ></div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_link_text">
                        Link Text
                      </div>
                      <div className="detail_cell content terms_link_text">
                        <span id="terms_id">{termsDetail?.linkText ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_required">
                        Required
                      </div>
                      <div className="detail_cell content terms_link_required">
                        <span id="terms_id">{termsDetail?.required ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_terms_group_id">
                        Terms Group ID
                      </div>
                      <div className="detail_cell content terms_group_id">
                        <span id="terms_id">{termsDetail?.termsGroupId ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_file_name">
                        File Name
                      </div>
                      <div className="detail_cell content terms_file_name">
                        <span id="terms_id">{termsDetail?.fileName ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_file_url">
                        File URL
                      </div>
                      <div className="detail_cell content terms_file_url">
                        <span id="terms_id">{termsDetail?.fileId ?? '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_created_at">
                        Created At
                      </div>
                      <div className="detail_cell content terms_created_at">
                        <span id="terms_id">{termsDetail ? formatDateTimeDot(termsDetail.created_at) : '-'}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content terms_updated_at">
                        <span id="terms_id">{termsDetail ? formatDateTimeDot(termsDetail.updated_at) : '-'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_terms_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkTermsEdit();
                  }}
                  data-detect="admin_terms_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsDetailData.mapStateToProps;
let mapDispatchToProps = TermsDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TermsDetailView)
);
