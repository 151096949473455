import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetPasswordForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=UPDATE_PASSWORD");
}

//------------------------------- delete Summernote ------------------------------------------------------//
export function onDeleteSummernote(id) {
  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add Summernote ------------------------------------------------------//
export function onAddSummernote(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit Summernote ------------------------------------------------------//
export function onEditSummernote(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get Forgot Password Form ------------------------------------------------------//
export function onGetForgotPasswordForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=FORGOT_PASSWORD");
}

//------------------------------- delete Forgot Password Form ------------------------------------------------------//
export function onDeleteForgotPasswordForm(id) {

  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add Forgot Password Form------------------------------------------------------//
export function onAddForgotPasswordForm(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit Forgot Password Form ------------------------------------------------------//
export function onEditForgotPasswordForm(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get create Email Form ------------------------------------------------------//
export function onGetCreateEmailForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=CONFIRM");
}

//------------------------------- delete Create Email Form ------------------------------------------------------//
export function onDeleteCreateEmailForm(id) {
  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add Create Email Form------------------------------------------------------//
export function onAddCreateEmailForm(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit Create Email Form ------------------------------------------------------//
export function onEditCreateEmailForm(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get Email fail Form ------------------------------------------------------//
export function onGetCreateEmailFailureForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=CONFIRM_FAIL");
}

//------------------------------- delete Fail Email Form ------------------------------------------------------//
export function onDeleteCreateEmailFailureForm(id) {
  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add Email Fail Form------------------------------------------------------//
export function onAddCreateEmailFailureForm(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit Email fail Form ------------------------------------------------------//
export function onEditCreateEmailFailureForm(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get Email success Form ------------------------------------------------------//
export function onGetCreateEmailSuccessForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=CONFIRM_SUCCESS");
}

//------------------------------- delete email success Form ------------------------------------------------------//
export function onDeleteCreateEmailSuccessForm(id) {
  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add Email success Form------------------------------------------------------//
export function onAddCreateEmailSuccessForm(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit Email success Form ------------------------------------------------------//
export function onEditCreateEmailSuccessForm(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get profile Form ------------------------------------------------------//
export function onGetProfileForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=UPDATE_PROFILE");
}

//------------------------------- delete profile Form ------------------------------------------------------//
export function onDeleteProfileForm(id) {
  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add profile Form------------------------------------------------------//
export function onAddProfileForm(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit profile Form ------------------------------------------------------//
export function onEditProfileForm(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get withdraw Form ------------------------------------------------------//
export function onGetWithdrawForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=WITHDRAW");
}

//------------------------------- delete withdraw Form ------------------------------------------------------//
export function onDeleteWithdrawForm(id) {
  return axiosInstance.delete("/admin/api/emailFormTranslation/" + id);
}

//------------------------------- add withdraw Form------------------------------------------------------//
export function onAddWithdrawForm(emailFormId, content, languageCode) {
  const requestData = {
    emailFormId: emailFormId,
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.post("/admin/api/emailFormTranslation", requestData);
}

//------------------------------- edit withdraw Form ------------------------------------------------------//
export function onEditWithdrawForm(id, emailFormId, content, languageCode) {
  const requestData = {
    languageCode: languageCode,
    content: content,
  };

  return axiosInstance.put("/admin/api/emailFormTranslation/" + id, requestData);
}

//------------------------------- get Forgot Id Form ------------------------------------------------------//
export function onGetForgotIdForm() {
  return axiosInstance.get("/admin/api/emailForm/type?type=FORGOT_ID");
}

//------------------------------- edit default language Form ------------------------------------------------------//
export function onEditDefaultLanguageForm(id, content) {
  const requestData = {
    content: content,
  };

  return axiosInstance.put("/admin/api/emailForm/" + id, requestData);
}
