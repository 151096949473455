import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import StaffTransferLogHistoryDetailData from "../Detail/staffTransferLogHistoryDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/staffTransferLogHistory/staffTransferLogHistoryDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class StaffTransferLogHistoryDetailView extends StaffTransferLogHistoryDetailData {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      isShowAllLoading: true,
      transferCode: "",
    };
  }

  // 첫 실행시 URL에서 paymentId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const transferCode = params.get("transferCode");
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.setState(
        {
          transferCode: transferCode,
        },
        () => {
          this.requestGetStaffTransferLogDetailHistory(this.state.transferCode);
          this.props.history.replace(location.pathname, this.state);
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkstaffTransferLogDetail(id);
    }
  }

  //파람값 url로 전달
  linkstaffTransferLogDetail = (staffTransferId) => {
    this.props.history.push(
      `/staffTransferLogHistoryDetailInfoPage?staffTransferId=${staffTransferId}`
    );
  };

  getBeautifyJson = (json) => {
    if (json == null || json == "" || json == undefined) return "-";
    return JSON.stringify(json, null, 6).replace(/\n( *)/g, function(
      match,
      p1
    ) {
      return "<br>" + "&nbsp;".repeat(p1.length);
    });
  };
  
  // 성공여부 판단
  isSuccess = (body) => {
    if(body == null || body== "") return;
    // success 정리
    let success = "-";

    let includeTrue = body.indexOf('true')
    let includeFalse = body.indexOf('false')

    if (includeTrue == -1 && includeFalse == -1) {
      success = '-'
    } else if ((includeTrue == -1 ? Number.MAX_SAFE_INTEGER : includeTrue) < (includeFalse == -1 ? Number.MAX_SAFE_INTEGER : includeFalse)) {
      success = 'true'
    } else {
      success = 'false'
    }
    return success;
  }

  render() {
    let staffTransferLogHistoryDetail = [];
    if (
      this.props.staffTransferLogHistoryDetail != null &&
      this.props.staffTransferLogHistoryDetail?.success == true
    ) {
      staffTransferLogHistoryDetail = this.props.staffTransferLogHistoryDetail.results.rows;
    }

    return (
      <div className="staffTransferLogHistoryDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="user_transfer_list_container">
          <div className="user_transfer_list_container_inner">
            <div
              className="title"
              data-detect="admin_staff_transfer_log_history_detail_title"
            >
              Staff Transfer Detail History (↓)
            </div>
            <div className="btn_container_left"></div>
            <div className="btn_container"></div>
            <div className="user_transfer_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div
                      id="theadFistCell"
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_no"
                    >
                      NO.
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_type"
                    >
                      Type
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_method"
                    >
                      method
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_user"
                    >
                      user
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_hotel_id"
                    >
                      Hotel ID
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_path"
                    >
                      Path
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_status"
                    >
                      Status
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_success"
                    >
                      Success
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_staff_transfer_log_history_detail_label_created_at"
                    >
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {staffTransferLogHistoryDetail.length > 0 &&
                    staffTransferLogHistoryDetail.map(
                      (staffTransferLogDetailHistory, index) => (
                        <div
                          className={`row ${
                            ( staffTransferLogDetailHistory.type == "RESPONSE" &&
                              staffTransferLogDetailHistory.status !== "-" &&
                              staffTransferLogDetailHistory.status !== "200") ||
                              this.isSuccess(staffTransferLogDetailHistory.body) == "false"
                              ? "ROWRED"
                              : ""
                          }`}
                          key={staffTransferLogDetailHistory.id}
                          onMouseUp={() => this.handleMouseUp(staffTransferLogDetailHistory.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          <div className="cell">
                            {staffTransferLogDetailHistory.id || "-"}
                          </div>
                          <div className={`cell ${staffTransferLogDetailHistory.type}`}>
                            {staffTransferLogDetailHistory.type || "-"}
                          </div>
                          <div className="cell">
                            {staffTransferLogDetailHistory.method || "-"}
                          </div>
                          <div className="cell">
                            {staffTransferLogDetailHistory.user || "-"}
                          </div>
                          <div className="cell">
                            {staffTransferLogDetailHistory.hotelId || "-"}
                          </div>
                          <div className="cell">
                            {staffTransferLogDetailHistory.path || "-"}
                          </div>
                          <div className="cell">
                            {staffTransferLogDetailHistory.status || "-"}
                          </div>
                          <div className="cell">
                            {this.isSuccess(staffTransferLogDetailHistory.body)}
                          </div>
                          <div className="cell">
                            {staffTransferLogDetailHistory &&
                              formatDateTimeDot(
                                staffTransferLogDetailHistory.created_at
                              )}
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="button_container">
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_hotel_detail_button_go_to_list"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = StaffTransferLogHistoryDetailData.mapStateToProps;
let mapDispatchToProps = StaffTransferLogHistoryDetailData.mapDispatchToProps;

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StaffTransferLogHistoryDetailView)
);
