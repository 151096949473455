// ApplicationEndPointAddData.js
import CommonComponent from "../../components/CommonComponent";

// module
import * as applicationEndPointActions from "../../../redux/modules/ApplicationEndPoint";


class ApplicationEndPointEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      applicationEndPoint: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_APPLICATION_ENDPOINT") {
      this.onEditApplicationEndPointOneResults(props);
    }
    if (this.state.requestType == "DELETE_APPLICATION_ENDPOINT_ONE") {
      this.onDeleteApplicationEndPointListResults(props);
    }
  }

  // redux에 edit applicationEndPoint 요청
  requestEditApplicationEndPoint = (applicationEndPointId, packageName, os, version, url) => {
    this.setState({
      requestType: "EDIT_APPLICATION_ENDPOINT",
    });
    this.props.onEdit(applicationEndPointId, packageName, os, version, url);
  };

  // redux에 delete ApplicationEndPoint 요청
  requestDeleteApplicationEndPoint = (applicationEndPointId) => {
    this.setState({
      requestType: "DELETE_APPLICATION_ENDPOINT_ONE",
    });
    this.props.onDeleteOne(applicationEndPointId);
  };

  // get edit applicationEndPoint results
  onEditApplicationEndPointOneResults = (props) => {
    if (props.applicationEndPointEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationEndPointEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_amenity"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    const applicationEndPointId = this.state.applicationEndPointId;
    // 페이지 이동
    this.props.history.push(`/applicationEndPointDetailPage?applicationEndPointId=${applicationEndPointId}`);
  };

  // delete applicationEndPoint results
  onDeleteApplicationEndPointListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      // 삭제 완료된 후에 페이지 이동
      this.onDeleteSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.props.history.push("/ApplicationEndPointListPage");
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationEndPointDetail: state.applicationEndPoint.applicationEndPointDetail,
      applicationEndPointEdit: state.applicationEndPoint.applicationEndPointEdit,
      failData: state.applicationEndPoint.failData,
      successDelete: state.applicationEndPoint.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (applicationEndPointId, packageName, os, version, url) => dispatch(applicationEndPointActions.onEditApplicationEndPoint(applicationEndPointId, packageName, os, version, url)),
      onDeleteOne: (applicationEndPointId) => dispatch(applicationEndPointActions.onDeleteApplicationEndPoint(applicationEndPointId)),
    };
  };
}

export default ApplicationEndPointEditData;
