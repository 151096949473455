import CommonComponent from "../../../components/CommonComponent.js";

// modules
import * as transferActions from "../../../../redux/modules/Transfer.js";


class UserNotificationLogListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      userNotificationLogList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userNotificationLogList != this.props.userNotificationLogList) {
      if (this.state.requestType == 'GET_USER_NOTIFICATION_LOG_LIST') {
        this.onGetUserNotificationLogListResults(nextProps);
      }
    }
  }

  // redux에 get notification list 요청
  requestGetUserNotificationLogList = (
    pageNo,
    email,
    search,
    startDate,
    endDate
  ) => {
    this.setState({
      requestType: "GET_USER_NOTIFICATION_LOG_LIST",
    });
    this.props.onGetAll(pageNo, email, search, startDate, endDate);
  };

  // get notification list results
  onGetUserNotificationLogListResults = (props) => {
    if (props.userNotificationLogList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (
      props.userNotificationLogList?.success == true
    ) {
      
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      userNotificationLogList: state.transfer.userNotificationLogList,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, email, search, startDate, endDate) =>
        dispatch(
          transferActions.onGetAllUserNotificationLogList(
            pageNo,
            email,
            search,
            startDate,
            endDate
          )
        ),
      onDeleteOne: (notificationId) =>
        dispatch(notificationActions.onDelete(notificationId)),
    };

    return returnProps;
  };
}

export default UserNotificationLogListData;
