// AmenityAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as amenityActions from "../../../redux/modules/Amenity";

class AmenityAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_AMENITY") {
      this.onAddAmenityResults(props);
    }
  }

  // redux에 add amenity 요청
  requestAddAmenity = (type, group, code, name, iconFile, iconText) => {
    const formData = new FormData();
    formData.append("type", type);
    formData.append("group", group);
    formData.append("code", code);
    formData.append("name", name);
    formData.append("amenityFile", iconFile);
    formData.append("iconText", iconText);

    this.setState({
      requestType: "ADD_AMENITY",
    });
    this.props.onAdd(formData);
  };

  // get add amenity results
  onAddAmenityResults = (props) => {
    if (props.amenityAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.amenityAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_amenity"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/amenityListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      amenityAdd: state.amenity.amenityAdd,
      failData: state.amenity.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (formData) => dispatch(amenityActions.onAddAmenity(formData)),
    };
  };
}

export default AmenityAddData;
