import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import MyConectColors from '../../components/color/MyConectColors';
import Calendar from '../../components/Calendar/dateCalendar/DateCalendar';
import DateRangeCalendar from "../../components/Calendar/dateRangeCalendar/DateRangeCalendar";
import CircleButton from "../../components/buttons/Button/CircleButton";
import DeleteButton from '../../components/buttons/Button/DeleteButton';
import LightSquareAddButton from '../../components/buttons/Button/LightSquareAddButton';
import MiniSquareAddButton from "../../components/buttons/Button/MiniSquareAddButton";
import SearchButton from '../../components/buttons/Button/SearchButton';
import SquareAddButton from '../../components/buttons/Button/SquareAddButton';
import CheckBoxButton from '../../components/buttons/CheckBoxButton/CheckBoxButton';
import RadioButton from "../../components/buttons/RadioButton/RadioButton";
import SelectBoxButton from '../../components/buttons/SelectBoxButton/SelectBoxButton';
import SelectBoxContainTypeButton from '../../components/buttons/SelectBoxButton/SelectBoxContainTypeButton';
import SelectCheckBoxButton from '../../components/buttons/SelectBoxButton/SelectCheckBoxButton';
import SelectLanguageCodeButton from '../../components/buttons/SelectBoxButton/SelectLanguageCodeButton';
import Pagination from '../../components/common/Pagination';
import InputTextAreaBox from '../../components/input/Text/InputTextAreaBox';
import InputTextBox from '../../components/input/Text/InputTextBox';
import InputTextLabelBox from '../../components/input/Text/InputTextLabelBox';
import "../buttons/buttons.css";



class Buttons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNo: 1,
        };
    }
    // page 이동
    handlePageChange = (pageNo) => {
        this.setState(
            { pageNo: pageNo },
            () => {
                this.onPageChange(pageNo); // 페이지 변경 처리
            }
        );
    };

    //pageNo 변경시 checkedList값 초기화
    onPageChange = (newPageNo) => {
        this.setState({
            pageNo: newPageNo,
        });
    };

    render() {
        let count;
        count = 110; //pagination test value;

        return (
            <div className='buttonsViewPage'>
                <div className='inner'>
                    <Container>
                        <Row>
                            <div className='title'>
                                <div className='myConect_menu_title'>Button Definition</div>
                            </div>
                        </Row>
                        <div className='line'></div>

                        <Row>
                            <div className='title_padding'></div>
                            <div className='sub_title'>
                                <b>Button</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Dynamic Status
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Disabled Status
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}> <CircleButton /></Col>
                            <Col sm={3}> <CircleButton disabled /></Col>
                        </Row>
                        <Row>
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}> <SquareAddButton /></Col>
                            <Col sm={3}> <SquareAddButton disabled /></Col>
                        </Row>
                        <Row>
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}> <MiniSquareAddButton /></Col>
                            <Col sm={3}> <MiniSquareAddButton disabled /></Col>
                        </Row>
                        <Row>
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}> <LightSquareAddButton /></Col>
                            <Col sm={3}> <LightSquareAddButton disabled /></Col>
                        </Row>
                        <Row>
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}> <DeleteButton /></Col>
                            <Col sm={3}> <DeleteButton disabled /></Col>
                        </Row>
                        <Row>
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}> <SearchButton /></Col>
                            <Col sm={3}> <SearchButton disabled /></Col>
                        </Row>


                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>Radio Button</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Default
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Disabled Status
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Checked and disabled Status
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <RadioButton />
                            </Col>
                            <Col sm={3}>
                                <RadioButton disabled />
                            </Col>
                            <Col sm={3}>
                                <RadioButton disableSelected />
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>CheckBox Button</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Default
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Disabled Status
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Checked and disabled Status
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <CheckBoxButton />
                            </Col>
                            <Col sm={3}>
                                <CheckBoxButton disabled />
                            </Col>
                            <Col sm={3}>
                                <CheckBoxButton disableSelected />
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>Pagination</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Pagination
                                    total={1}
                                    dataSize={"10"}
                                    pageSize={"10"}
                                    // handlePageChange={this.handlePageChange}
                                    pageNo={1}
                                />
                            </Col>
                            <Col sm={3}>
                                <Pagination
                                    total={30}
                                    dataSize={"10"}
                                    pageSize={"10"}
                                    handlePageChange={this.handlePageChange}
                                    pageNo={this.state.pageNo}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={8}>
                                <Pagination
                                    total={count}
                                    dataSize={"10"}
                                    pageSize={"10"}
                                    handlePageChange={this.handlePageChange}
                                    pageNo={this.state.pageNo}
                                />
                            </Col>
                        </Row>

                        <div className='row_padding'></div>
                        <div className='line'></div>

                        <Row>
                            <div className='title_padding'></div>
                            <div className='myConect_menu_title'>
                                <b>Input</b></div>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>Text</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Dynamic Status
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className='subTitle_align'>
                                    Disabled Status
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><InputTextBox /> </Col>
                            <Col sm={3}> <InputTextBox disabled /></Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><InputTextAreaBox /> </Col>
                            <Col sm={3}> <InputTextAreaBox disabled /></Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><InputTextLabelBox /> </Col>
                            <Col sm={3}> <InputTextLabelBox disabled /></Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>Select Box</b></div>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><SelectBoxButton /> </Col>
                            <Col sm={3}> <SelectBoxButton disabled /></Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><SelectBoxContainTypeButton /> </Col>
                            <Col sm={3}> <SelectBoxContainTypeButton disabled /></Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><SelectCheckBoxButton /> </Col>
                            <Col sm={3}> <SelectCheckBoxButton disabled /></Col>
                        </Row>

                        <Row>
                            <div className='content_align'></div>
                            <Col sm={2}></Col>
                            <Col sm={3}><SelectLanguageCodeButton /> </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>
                                <b>Date / Calendar</b></div>
                        </Row>

                        <Row>
                            <Col sm={2}>
                                <div className='row_padding'></div>
                                <div className='sub_title'>
                                    <span>Select Date</span>
                                </div>
                            </Col>
                            <Col sm={3}><Calendar /> </Col>
                            <Col sm={3}><Calendar disabled /> </Col>
                        </Row>

                        <Row>
                            <Col sm={2}>
                                <div className='row_padding'></div>
                                <div className='sub_title'>
                                    <span>Date Range</span>
                                </div>
                            </Col>
                            <Col sm={3}><DateRangeCalendar /> </Col>
                            <Col sm={3}><DateRangeCalendar disabled /> </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        );
    }
}

export default Buttons;
