import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import RecommendAddData from "../Add/RecommendAddData";



//css
import "../../css/common.css";
import "../../css/recommend/recommendAdd.css";

class RecommendAddView extends RecommendAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      name: "",
      iosScheme: "",
      iosItunesUrl: "",
      aosPackageId: "",
      aosPlayStoreUrl: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change iosScheme
  onChangeIosScheme = (e) => {
    const inputValue = e.target.value;
    this.setState({ iosScheme: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change iosItunesUrl
  onChangeIosItunesUrl = (e) => {
    const inputValue = e.target.value;
    this.setState({ iosItunesUrl: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change aosPackageId
  onChangeAosPackageId = (e) => {
    const inputValue = e.target.value;
    this.setState({ aosPackageId: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change aosPlayStoreUrl
  onChangeAosPlayStoreUrl = (e) => {
    const inputValue = e.target.value;
    this.setState({ aosPlayStoreUrl: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("name", "admin_recommend_app_add_validation_name") &&
      this.confirmField("iosScheme", "admin_recommend_app_add_validation_ios_scheme") &&
      this.confirmField("iosItunesUrl", "admin_recommend_app_add_validation_ios_itunes_url") &&
      this.confirmField("aosPackageId", "admin_recommend_app_add_validation_aos_package_id") &&
      this.confirmField("aosPlayStoreUrl", "admin_recommend_app_add_validation_aos_play_store_url")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddRecommend(this.state.name, this.state.iosScheme, this.state.iosItunesUrl, this.state.aosPackageId, this.state.aosPlayStoreUrl);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/recommendListPage");
  };

  render() {
    return (
      <div className="addRecommend">
        {this.state.isShowAllLoading && <Loading />}
        <div className="recommend_add_container">
          <div className="addrecommend_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_recommend_app_add_title">ADD RECOMMEND APP</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addrecommend_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addrecommend_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_recommend_app_add_label_name">Name</p>
                        </div>
                      </div>
                      <div className="detail_cell content recommend_name">
                        <input type="text" id="name" name="name" autoComplete="off" value={this.state.name} onChange={this.onChangeName} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_recommend_app_add_label_ios_scheme">iOS Scheme</p>
                        </div>
                      </div>
                      <div className="detail_cell content recommend_iosScheme">
                        <input type="text" id="iosScheme" name="iosScheme" autoComplete="off" value={this.state.iosScheme} onChange={this.onChangeIosScheme} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_recommend_app_add_label_ios_itunes_url">iOS iTunes Url</p>
                        </div>
                      </div>
                      <div className="detail_cell content recommend_iosItunesUrl">
                        <input type="text" id="iosItunesUrl" name="iosItunesUrl" autoComplete="off" value={this.state.iosItunesUrl} onChange={this.onChangeIosItunesUrl} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_recommend_app_add_label_aos_package_id">AOS Package ID</p>
                        </div>
                      </div>
                      <div className="detail_cell content recommend_aosPackageId">
                        <input type="text" id="aosPackageId" name="aosPackageId" autoComplete="off" value={this.state.aosPackageId} onChange={this.onChangeAosPackageId} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_recommend_app_add_label_aos_play_store_url">AOS Play Store Url</p>
                        </div>
                      </div>
                      <div className="detail_cell content recommend_name">
                        <input type="text" id="aosPlayStoreUrl" name="aosPlayStoreUrl" autoComplete="off" value={this.state.aosPlayStoreUrl} onChange={this.onChangeAosPlayStoreUrl} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_recommend_app_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_recommend_app_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RecommendAddData.mapStateToProps;
let mapDispatchToProps = RecommendAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecommendAddView));
