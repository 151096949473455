import axiosInstance from "../api/axios";

//region
//------------------------------- get Region List ------------------------------------------------------//
export function onGetAll(pageNo, search) {
  const offset = (pageNo - 1) * 10;
  return axiosInstance.get("/admin/api/region/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get Region One ------------------------------------------------------//
export function onGetOne(regionId) {
  return axiosInstance.get("/admin/api/region/" + regionId);
}

//------------------------------- add Region location ------------------------------------------------------//
export function onAdd(region) {
  const requestData = {
    name: region,
  };

  return axiosInstance.post("/admin/api/region/", requestData);
}
//------------------------------- delete Region location ------------------------------------------------------//
export function onDelete(regionId) {
  return axiosInstance.delete("/admin/api/region/" + regionId);
}

//------------------------------- edit region ------------------------------------------------------//
export function onEdit(regionId, region) {
  const requestData = {
    name: region,
  };

  return axiosInstance.put("/admin/api/region/" + regionId, requestData);
}

//country
//------------------------------- get country List ------------------------------------------------------//
export function onGetAllCountry(pageNo, search, regionId) {
  const offset = (pageNo - 1) * 10;
  return axiosInstance.get("/admin/api/country/?content=" + search + "&offset=" + offset + "&limit=" + 10 + "&hotelRegionId=" + regionId);
}

//------------------------------- get country One ------------------------------------------------------//
export function onGetOneCountry(countryId) {
  return axiosInstance.get("/admin/api/country/" + countryId);
}

//------------------------------- add country location ------------------------------------------------------//
export function onAddCountry(regionId, country) {
  const requestData = {
    hotelRegionId: regionId,
    name: country,
  };

  return axiosInstance.post("/admin/api/country/", requestData);
}
//------------------------------- delete country location ------------------------------------------------------//
export function onDeleteCountry(countryId) {
  return axiosInstance.delete("/admin/api/country/" + countryId);
}

//------------------------------- edit country ------------------------------------------------------//
export function onEditCountry(countryId, country) {
  const requestData = {
    name: country,
  };

  return axiosInstance.put("/admin/api/country/" + countryId, requestData);
}

//city
//------------------------------- get city List ------------------------------------------------------//
export function onGetAllCity(pageNo, search, regionId, countryId) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/city/?content=" + search + "&offset=" + offset + "&limit=" + 10 + "&hotelCountryId=" + countryId + "&hotelRegionId=" + regionId);
}

//------------------------------- get city One ------------------------------------------------------//
export function onGetOneCity(cityId) {
  return axiosInstance.get("/admin/api/city/" + cityId);
}

//------------------------------- add city location ------------------------------------------------------//
export function onAddCity(regionId, countryId, city) {
  const requestData = {
    hotelRegionId: regionId,
    hotelCountryId: countryId,
    name: city,
  };

  return axiosInstance.post("/admin/api/city/", requestData);
}
//------------------------------- delete city location ------------------------------------------------------//
export function onDeleteCity(cityId) {
  return axiosInstance.delete("/admin/api/city/" + cityId);
}

//------------------------------- edit city ------------------------------------------------------//
export function onEditCity(cityId, city) {
  const requestData = {
    name: city,
  };

  return axiosInstance.put("/admin/api/city/" + cityId, requestData);
}

//------------------------------- region에 따라 select된 cityData ------------------------------------------------------//
export function onGetCountry(regionId) {
  const maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/country/?content=&offset=0&limit=" + maxNumber + "&hotelRegionId=" + regionId);
}
