import React, { Component } from 'react';

class SelectLanguageCodeButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenSelectBox: false,
            selectedValue: null,
        };

        this.onChangeToggleValue = this.onChangeToggleValue.bind(this);
        this.onChangeSelectValue = this.onChangeSelectValue.bind(this);
    }

    onChangeToggleValue() {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState((prevState) => ({
                isOpenSelectBox: !prevState.isOpenSelectBox,
            }));
        }
    }

    onChangeSelectValue(value) {
        this.setState({
            selectedValue: value,
            isOpenSelectBox: false,
        });
    }

    render() {
        const { isOpenSelectBox, selectedValue } = this.state;
        const isError = selectedValue == 'errorTest';

        const options = ['En', 'Kr'];
        return (
            <div className="myConectLanguageCodeBox">
                <div className={`myConect_input_language_code_box`}
                    onClick={this.onChangeToggleValue} >
                    {'Language Code'}
                </div>
                {isOpenSelectBox && (
                    <div className="select_options">
                        {options.map((option, index) => (
                            <div className={option == selectedValue ? 'selected' : ''} key={index} onClick={() => this.onChangeSelectValue(option)}>
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default SelectLanguageCodeButton;
