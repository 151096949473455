import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as versionApis from "../lib/version_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const VERSION_GET_ALL = "version/VERSION_GET_ALL";
const VERSION_GET_ONE = "version/VERSION_GET_ONE";
const ADD_VERSION = "version/ADD_VERSION";
const DELETE_VERSION = "version/DELETE_VERSION";

//application
const APPLICATION_VERSION_GET_ALL = " version/APPLICATION_VERSION_GET_ALL";
const APPLICATION_VERSION_GET_AOS = " version/APPLICATION_VERSION_GET_AOS";
const APPLICATION_VERSION_GET_IOS = " version/APPLICATION_VERSION_GET_IOS";
const APPLICATION_DELETE_VERSION = " version/APPLICATION_DELETE_VERSION";
const ADD_APPLICATION_VERSION = "version/ADD_APPLICATION_VERSION";

//guest
const GUEST_APPLICATION_VERSION_GET_ALL = " version/GUEST_APPLICATION_VERSION_GET_ALL";
const ADD_GUEST_APPLICATION_VERSION = "version/ADD_GUEST_APPLICATION_VERSION";
const GUEST_APPLICATION_VERSION_GET_ACTIVE = " version/GUEST_APPLICATION_VERSION_GET_ACTIVE";
const DELETE_GUEST_APPLICATION_VERSION = " version/DELETE_GUEST_APPLICATION_VERSION";
//staff
const STAFF_APPLICATION_VERSION_GET_ALL = " version/STAFF_APPLICATION_VERSION_GET_ALL";
const ADD_STAFF_APPLICATION_VERSION = "version/ADD_STAFF_APPLICATION_VERSION";
const STAFF_APPLICATION_VERSION_GET_ACTIVE = " version/STAFF_APPLICATION_VERSION_GET_ACTIVE";
const DELETE_STAFF_APPLICATION_VERSION = " version/DELETE_STAFF_APPLICATION_VERSION";
//parking
const PARKING_STAFF_APPLICATION_VERSION_GET_ALL = " version/PARKING_STAFF_APPLICATION_VERSION_GET_ALL";
const ADD_PARKING_STAFF_APPLICATION_VERSION = "version/ADD_PARKING_STAFF_APPLICATION_VERSION";
const PARKING_STAFF_APPLICATION_VERSION_GET_ACTIVE = " version/PARKING_STAFF_APPLICATION_VERSION_GET_ACTIVE";
const DELETE_PARKING_STAFF_APPLICATION_VERSION = " version/DELETE_PARKING_STAFF_APPLICATION_VERSION";
// 액션 생성자

//version
export const onGetAll = createAction(VERSION_GET_ALL, versionApis.onGetAll);
export const onGetOne = createAction(VERSION_GET_ONE, versionApis.onGetOne);
export const onAdd = createAction(ADD_VERSION, versionApis.onAdd);
export const onDelete = createAction(DELETE_VERSION, versionApis.onDelete);
//applicationVersion
export const onGetAllApplicationVersion = createAction(APPLICATION_VERSION_GET_ALL, versionApis.onGetAllApplicationVersion);
export const onGetaosAll = createAction(APPLICATION_VERSION_GET_AOS, versionApis.onGetaosAll);
export const onGetiosAll = createAction(APPLICATION_VERSION_GET_IOS, versionApis.onGetiosAll);
export const onAddApplicationVersion = createAction(ADD_APPLICATION_VERSION, versionApis.onAddApplicationVersion);
export const onDeleteApplicationVersion = createAction(APPLICATION_DELETE_VERSION, versionApis.onDeleteApplicationVersion);
//guest
export const onGetAllGuestApplicationVersion = createAction(GUEST_APPLICATION_VERSION_GET_ALL, versionApis.onGetAllGuestApplicationVersion);
export const onAddGuestApplicationVersion = createAction(ADD_GUEST_APPLICATION_VERSION, versionApis.onAddGuestApplicationVersion);
export const onGetactiveAll = createAction(GUEST_APPLICATION_VERSION_GET_ACTIVE, versionApis.onGetactiveAll);
export const onDeleteGuestApplicationVersion = createAction(DELETE_GUEST_APPLICATION_VERSION, versionApis.onDeleteGuestApplicationVersion);
//staff
export const onGetAllStaffApplicationVersion = createAction(STAFF_APPLICATION_VERSION_GET_ALL, versionApis.onGetAllStaffApplicationVersion);
export const onAddStaffApplicationVersion = createAction(ADD_STAFF_APPLICATION_VERSION, versionApis.onAddStaffApplicationVersion);
export const onGetStaffactiveAll = createAction(STAFF_APPLICATION_VERSION_GET_ACTIVE, versionApis.onGetStaffactiveAll);
export const onDeleteStaffApplicationVersion = createAction(DELETE_STAFF_APPLICATION_VERSION, versionApis.onDeleteStaffApplicationVersion);
//parking staff
export const onGetAllParkingStaffApplicationVersion = createAction(PARKING_STAFF_APPLICATION_VERSION_GET_ALL, versionApis.onGetAllParkingStaffApplicationVersion);
export const onAddParkingStaffApplicationVersion = createAction(ADD_PARKING_STAFF_APPLICATION_VERSION, versionApis.onAddParkingStaffApplicationVersion);
export const onGetParkingStaffactiveAll = createAction(PARKING_STAFF_APPLICATION_VERSION_GET_ACTIVE, versionApis.onGetParkingStaffactiveAll);
export const onDeleteParkingStaffApplicationVersion = createAction(DELETE_PARKING_STAFF_APPLICATION_VERSION, versionApis.onDeleteParkingStaffApplicationVersion);

// 초기 상태
const initialState = {
  versionList: [],
  versionDetail: null,
  versionAdd: null,
  successDelete: null,
  applicationVersionList: [],
  aosList: [],
  iosList: [],
  applicationVersionAdd: null,
  applicationVersionDelete: null,
  guestApplicationVersionAdd: null,
  guestApplicationVersionDelete: null,
  guestApplicationVersionList: [],
  activeList: [],
  staffApplicationVersionAdd: null,
  staffApplicationVersionDelete: null,
  staffApplicationVersionList: [],
  staffActiveList: [],
  parkingStaffApplicationVersionAdd: null,
  parkingStaffApplicationVersionDelete: null,
  parkingStaffApplicationVersionList: [],
  parkingStaffActiveList: [],
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: VERSION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, versionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, versionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: VERSION_GET_ONE,
      onPending: (state, action) => {
        return { ...state, versionDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, versionDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_VERSION,
      onPending: (state, action) => {
        return { ...state, versionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, versionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_VERSION,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APPLICATION_VERSION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationVersionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, applicationVersionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APPLICATION_VERSION_GET_AOS,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, aosList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, aosList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APPLICATION_VERSION_GET_IOS,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, iosList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, iosList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, applicationVersionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationVersionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APPLICATION_DELETE_VERSION,
      onPending: (state, action) => {
        return { ...state, applicationVersionDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationVersionDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_GUEST_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, guestApplicationVersionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, guestApplicationVersionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_GUEST_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, guestApplicationVersionDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, guestApplicationVersionDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GUEST_APPLICATION_VERSION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, guestApplicationVersionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, guestApplicationVersionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GUEST_APPLICATION_VERSION_GET_ACTIVE,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, activeList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, activeList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_STAFF_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, staffApplicationVersionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffApplicationVersionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_STAFF_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, staffApplicationVersionDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffApplicationVersionDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: STAFF_APPLICATION_VERSION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffApplicationVersionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, staffApplicationVersionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: STAFF_APPLICATION_VERSION_GET_ACTIVE,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffActiveList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, staffActiveList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_PARKING_STAFF_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, parkingStaffApplicationVersionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, parkingStaffApplicationVersionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_PARKING_STAFF_APPLICATION_VERSION,
      onPending: (state, action) => {
        return { ...state, parkingStaffApplicationVersionDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, parkingStaffApplicationVersionDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: PARKING_STAFF_APPLICATION_VERSION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, parkingStaffApplicationVersionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, parkingStaffApplicationVersionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: PARKING_STAFF_APPLICATION_VERSION_GET_ACTIVE,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, parkingStaffActiveList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, parkingStaffActiveList: [], failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
