import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ru-RU"; // you can import any other locale
import { Loading } from "../../../components/Paths";
import TermsEditData from "../Edit/TermsEditData";


// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/dropdown";
import "bootstrap/js/modal";
import "bootstrap/js/tooltip";

//css
import "../../../css/common.css";
import "../../../css/summernote/summernote-lite.min.css";
import "../../../css/termsAndCondition/Terms/termsEdit.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class TermsEditView extends TermsEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      termsId: "",
      title: "",
      languageCode: "",
      description: "",
      linkText: "",
      required: "",
      termsGroupId: "",
      fileName: "",
      fileId: "",
      createdAt: "",
      updatedAt: "",
      termsGroupList: [],
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const termsId = params.get("termsId");
    this.setState({
      termsId: termsId,
    });

    if (this.props.termsDetail != null && this.props.termsDetail.success) {
      this.setState({
        title: this.props.termsDetail.results.title, //default값 설정
        languageCode: this.props.termsDetail.results.languageCode,
        description: this.props.termsDetail.results.description,
        linkText: this.props.termsDetail.results.linkText,
        required: this.props.termsDetail.results.required,
        termsGroupId: this.props.termsDetail.results.termsGroup.id,
        fileName: this.props.termsDetail.results.fileName,
        fileId: this.props.termsDetail.results.fileId,
        createdAt: this.props.termsDetail.results.created_at,
        updatedAt: this.props.termsDetail.results.updated_at,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change title
  onChangeTitle = (e) => {
    const inputValue = e.target.value;
    this.setState({ title: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change termsGroupId
  onChangeTermsGroupId = (e) => {
    const inputValue = e.target.value;
    this.setState({ termsGroupId: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change required
  onChangeRequiredId = (required) => {
    this.setState({ required: required }, () => {
      this.onCheckValidation();
    });
  };
  // change langauageCode
  onChangeLanguageCode = (e) => {
    const inputValue = e.target.value;
    this.setState({ languageCode: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change required
  onChangeRequired = (e) => {
    const inputValue = e.target.value;
    this.setState({ required: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change  description
  onChangeDescription = (content) => {
    this.setState({ description: content }, () => {
      this.onCheckValidation(); // 내용이 변경될 때마다 유효성을 확인
    });
  };

  // summernote image 업로드할 때
  onUploadImage = (images, insertImage) => {
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();

      reader.onloadend = () => {
        insertImage(reader.result);
      };

      reader.readAsDataURL(images[i]);
    }
  };

  // change linkText
  onChangeLinkText = (e) => {
    const inputValue = e.target.value;
    this.setState({ linkText: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change value of state  "N" <=> "Y"
  onChangeRadio = (e) => {
    const name = e.target.name;
    if (e.target.value == "Y") {
      this.setState(
        {
          [name]: "Y",
        },
        () => this.onCheckValidation()
      );
    } else {
      this.setState(
        {
          [name]: "N",
        },
        () => this.onCheckValidation()
      );
    }
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (field == "description") {
      // summernote가 빈칸으로 표시될 때
      if (value == "" || value == "<p><br></p>" || value == "<p></p>" || value == null) {
        this.setState({
          errorMessage: messageKey,
        });
        return false;
      } else {
        return true;
      }
    } else {
      if (value == "" || value == null) {
        this.setState({
          errorMessage: messageKey,
        });
        return false;
      } else {
        return true;
      }
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("title", "admin_terms_edit_validation_title") &&
      this.confirmField("languageCode", "admin_terms_edit_validation_language_code") &&
      this.confirmField("description", "admin_terms_edit_validation_description") &&
      this.confirmField("linkText", "admin_terms_edit_validation_link_text") &&
      this.confirmField("required", "admin_terms_edit_validation_required") &&
      this.confirmField("termsGroupId", "admin_terms_edit_validation_terms_group_id") &&
      this.confirmField("fileId", "admin_terms_edit_validation_file")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ fileId: file, fileName: file ? file.name : "ex) MyCONECT.html" }, () => {
      this.onCheckValidation();
    });
  };

  //edit 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editTerm();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editTerm = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestEditTerms(
      this.state.title,
      this.state.languageCode,
      this.state.description,
      this.state.linkText,
      this.state.required,
      this.state.termsGroupId,
      this.state.fileName,
      this.state.fileId,
      this.state.termsId
    );
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/termsListPage");
  };


  render() {
    let termsGroupList = [];

    const termsListAvailable = termsGroupList && termsGroupList.results && termsGroupList.results.rows;

    if (this.props.termsGroupList != [] && this.props.termsGroupList?.success == true) {
      termsGroupList = this.props.termsGroupList.results.rows;
    }

    return (
      <div className="editTerms">
        {this.state.isShowAllLoading && <Loading />}
        <div className="terms_edit_container">
          <div className="editterms_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_terms_edit_title">EDIT TERMS</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="editterms_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="editterms_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_edit_label_title">Title</p>
                      </div>
                      <div className="detail_cell content terms_title">
                        <input type="text" id="title" name="title" placeholder="Terms Name" autoComplete="off" value={this.state.title} onChange={this.onChangeTitle} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_edit_label_language_code">Language Code</p>
                      </div>
                      <div className="detail_cell content terms_languageCode">
                        <input type="text" id="languageCode" name="languageCode" placeholder="ex) ko" autoComplete="off" value={this.state.languageCode} onChange={this.onChangeLanguageCode} />
                      </div>
                    </div>
                    {/* 설명 */}
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_edit_label_description">Description</p>
                      </div>
                      <div className="detail_cell content terms_description">
                        <ReactSummernote
                          value={this.state.description}
                          className="customReactSummernote"
                          options={{
                            lang: 'en-EN',
                            height: 300,
                            dialogsInBody: true,
                            toolbar: [
                              ['style', ['style']],
                              ['font', ['bold', 'underline', 'clear']],
                              ['fontname', ['fontname']],
                              ['para', ['ul', 'ol', 'paragraph']],
                              ['table', ['table']],
                              ['insert', ['link', 'picture', 'video']],
                              ['view', ['fullscreen', 'codeview']],
                            ],
                          }}
                          onChange={this.onChangeDescription}
                          onImageUpload={this.onUploadImage}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_edit_label_link_text">Link Text</p>
                      </div>
                      <div className="detail_cell content terms_link_text">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          data-detect-placeholder="admin_terms_edit_label_link_text"
                          placeholder="Link Text"
                          autoComplete="off"
                          value={this.state.linkText}
                          onChange={this.onChangeLinkText}
                        />
                      </div>
                    </div>
                    {/* required */}
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_edit_label_required">Required</p>
                      </div>
                      <div className="detail_cell content info_required radio_container">
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="required" value="Y" checked={this.state.required == 'Y'} onChange={this.onChangeRadio} data-detect="admin_terms_edit_button_yes" />
                            Yes
                          </label>
                        </div>
                        <div className="radio_inner">
                          <label>
                            <input type="radio" name="required" value="N" checked={this.state.required == 'N'} onChange={this.onChangeRadio} data-detect="admin_terms_edit_button_no" />
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_edit_label_terms_group_id">Terms Group ID</p>
                      </div>
                      <div className="detail_cell content terms_group_id">
                        <div className="detail_selectBox">
                          <div>
                            {termsListAvailable && (
                              <select
                                className="termsGroupId_select"
                                id="termsGroupList"
                                value={this.state.termsGroupId}
                                onChange={(e) => {
                                  const selectedIndex = e.target.selectedIndex;
                                  const selectedTitle = e.target.options[selectedIndex].text;
                                  const selectedGroup = termsGroupList.find((group) => group.title == selectedTitle);
                                  if (selectedGroup) {
                                    this.onChangeTermsGroupId(selectedGroup.id);
                                  }
                                }}
                              >
                                <option disabled value="" data-detect="admin_terms_edit_label_choose"></option>
                                {termsGroupList.map((termsGroup) => (
                                  <option key={termsGroup.id} value={termsGroup.id}>
                                    {termsGroup.title}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_add_label_file_name">File Name</p>
                      </div>
                      <div className="detail_cell content terms_file_name">
                        <input type="text" id="email" name="email" placeholder="File Name" autoComplete="off" disabled value={this.state.fileName} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_edit_label_file_url">
                        File URL
                      </div>
                      <div>
                        <div className="file_upload_button_container" style={{ display: 'flex' }}>
                          <form>
                            <label htmlFor="fileId" className="file_upload_button" data-detect="admin_terms_edit_button_upload">
                              Upload
                            </label>
                            <input
                              type="file"
                              id="fileId"
                              accept=".html"
                              onChange={this.onFileChange}
                              title="Upload"
                              style={{ display: 'none' }} // input 요소를 화면에서 감추기
                            />
                          </form>
                          {this.state.fileId ? <p className="file_textName">{this.state.fileName}</p> : <p className="file_textName">{'ex) MyCONECT.html'}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_detail_label_created_at">Created At</p>
                      </div>
                      <div className="detail_cell content">
                        <span id="createAt">{formatDateTimeDot(this.state.createdAt)}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_terms_detail_label_updated_at">Updated At</p>
                      </div>
                      <div className="detail_cell content">
                        <span id="updateAt">{formatDateTimeDot(this.state.updatedAt)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_terms_detail_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="edit_btn" data-detect="admin_terms_detail_button_edit" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsEditData.mapStateToProps;
let mapDispatchToProps = TermsEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsEditView));
