import axiosInstance from "../api/axios";

/* ----------------------------------------------- get ID recongnition ---------------------------------------------- */
export function onGetAll(pageNo, search, idType) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/v2/IDRecognitionInfo?countryName=" + search + "&offset=" + offset + "&limit=" + 10 + "&idType=" + idType);
}

/* ----------------------------------------------- get ID recongnition ---------------------------------------------- */
export function onGetOne(IDRecognitionId) {
  return axiosInstance.get("/admin/api/v2/IDRecognitionInfo/" + IDRecognitionId);
}

/* ----------------------------------------------- add ID recongnition ---------------------------------------------- */
export function onAdd(idRecognitionInfo) {
  const data = idRecognitionInfo;

  return axiosInstance.post("/admin/api/v2/IDRecognitionInfo", data);
}

/* --------------------------------------------- delete ID recongnition --------------------------------------------- */
export function onDelete(IDRecognitionId) {
  return axiosInstance.delete("/admin/api/v2/IDRecognitionInfo/" + IDRecognitionId);
}

/* ---------------------------------------------- edit ID recongnition ---------------------------------------------- */
export function onEdit(IDRecognitionId, idRecognitionInfo) {
  const data = idRecognitionInfo;

  return axiosInstance.put("/admin/api/v2/IDRecognitionInfo/" + IDRecognitionId, data);
}

