import React, { Component } from 'react';

class CheckBoxButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: ['default']
        };
    }

    onChange = (e) => {
        const selectedValue = e.target.value;
        const { selectedOptions } = this.state;

        if (this.props.disableSelected) {
            return;
        }

        if (this.props.disabled) {
            return;
        }

        let updatedOptions;

        if (selectedOptions.includes(selectedValue)) {
            updatedOptions = selectedOptions.filter(option => option !== selectedValue);
        } else {
            updatedOptions = [...selectedOptions, selectedValue];
        }

        this.setState({
            selectedOptions: updatedOptions
        });
    };

    render() {
        const disabledStatus = this.props.disabled;
        const disableSelectedStatus = this.props.disableSelected;

        return (
            <div className="myConectCheckBox">
                <div className={`myConect_checkBox_button ${disabledStatus ? 'disabled' : ''} ${disableSelectedStatus ? 'disableSelected' : ''}`}>
                    <label>
                        <input
                            type="checkbox"
                            value="default"
                            checked={this.state.selectedOptions.includes('default')}
                            onChange={this.onChange}
                            disabled={disabledStatus || disableSelectedStatus}
                        ></input>
                        Default
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            value="selected"
                            checked={this.state.selectedOptions.includes('selected')}
                            onChange={this.onChange}
                            disabled={disabledStatus || disableSelectedStatus}
                        />
                        Selected
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            value="disabled"
                            checked={this.state.selectedOptions.includes('disabled')}
                            onChange={this.onChange}
                            disabled={disabledStatus || disableSelectedStatus}
                        />
                        Disabled
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            value="disableSelected"
                            checked={this.state.selectedOptions.includes('disableSelected')}
                            onChange={this.onChange}
                            disabled={disabledStatus || disableSelectedStatus}
                        />
                        Disable Selected
                    </label>
                </div>
            </div>
        );
    }
}

export default CheckBoxButton;
