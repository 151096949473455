// CityAddData.js
import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class CityEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_CITY") {
      this.onEditCityOneResults(props);
    }
  }

  // redux에 edit city 요청
  requestEditCity = (cityId, city) => {
    if (city == "") {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_city"],
        () => {},
        () => {},
        () => {}
      );
    } else {
      this.setState({
        requestType: "EDIT_CITY",
      });
      this.props.onEdit(cityId, city);
    }
  };

  // get edit city results
  onEditCityOneResults = (props) => {
    if (props.cityEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.cityEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_city"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    const cityId = this.state.cityId;
    this.props.history.push(`/cityDetailPage?cityId=${cityId}`);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      cityDetail: state.location.cityDetail,
      cityEdit: state.location.cityEdit,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (cityId, city) => dispatch(locationActions.onEditCity(cityId, city)),
    };
  };
}

export default CityEditData;
