// TermsListView.js

import React, { lazy } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import TermsListData from "../List/TermsListData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/termsAndCondition/Terms/termsList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class TermsListView extends TermsListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetTermsList(
        this.state.pageNo,
        this.state.search,
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;

    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
      },
      () => {
        this.requestGetTermsList(this.state.pageNo, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;

    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
        },
        () => {
          this.requestGetTermsList(this.state.pageNo, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search }, () => {
      this.requestGetTermsList(pageNo, this.state.search);
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkTermsDetail(id);
    }
  }

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, termsList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  deleteSelectedTermss = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteTerms();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteTerms = () => {
    const { checkedList } = this.state;
    this.setState({ isShowAllLoading: true });
    if (checkedList.length > 0) {
      // this.props.onClearTermsDeleteState()
      this.requestDeleteTerms(checkedList[0]);
    }
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (termsList) => {
    const { checkedList } = this.state;
    const allTermsCnt = termsList.length;
    const isChecked = allTermsCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : termsList.map((terms) => terms.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  //copy terms
  copyTerms = () => {
    const { checkedList } = this.state;

    if (checkedList.length == 1) {
      const copyId = checkedList[0];
      this.props.history.push(`/termscopyPage?copyId=${copyId}`);
    } else if (checkedList.length > 1) {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_only_one_terms_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
      //초기화
      this.setState({
        checkedList: [],
      });
    } else {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_terms_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //파람값 url로 전달
  linkTermsDetail = (termsId) => {
    this.props.history.push(`/termsDetailPage?termsId=${termsId}`);
  };

  //insert
  linkTermsAdd = () => {
    this.props.history.push("/termsAddPage");
  };


  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  render() {
    let count;
    let termsList = [];
    if (this.props.termsList.length !== 0 && this.props.termsList?.success == true) {
      count = this.props.termsList.results.count;
      termsList = this.props.termsList.results.rows;
    }

    return (
      <div className="termsList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="terms_list_container">
          <div className="terms_list_container_inner">
            <div className="title" data-detect="admin_terms_list_title">
              TERMS LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_terms_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- terms list table  --> */}
            <div className="terms_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(termsList, this.state.pageNo)}
                        checked={termsList && this.state.checkedList.length == termsList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_terms_list_label_language_code">
                      Language Code
                    </div>
                    <div className="cell" data-detect="admin_terms_list_label_file_name">
                      File Name
                    </div>
                    <div className="cell" data-detect="admin_terms_list_label_required">
                      Required
                    </div>
                    <div className="cell" data-detect="admin_terms_list_label_title">
                      Title
                    </div>
                    <div className="cell" data-detect="admin_terms_list_label_terms_group_id">
                      Terms Group ID
                    </div>
                    <div className="cell" data-detect="admin_terms_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {termsList?.length > 0 ? (
                    termsList.map((terms, index) => (
                      <div className="row tbodyRow" key={terms.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={terms.id}
                            id={terms.id}
                            onClick={() => this.onClickInput(terms.id, termsList)}
                            checked={this.state.checkedList.includes(terms.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={terms.id}></label>
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(terms.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {terms.languageCode}
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(terms.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {terms.fileName}
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(terms.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {terms.required}
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(terms.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {terms.termsGroup.title}
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(terms.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {terms.termsGroupId}
                        </div>
                        <div
                          className="cell"
                          onMouseUp={() => this.handleMouseUp(terms.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {terms && formatDateTimeSlash(terms.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_terms_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedTermss();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_terms_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkTermsAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_terms_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsListData.mapStateToProps;
let mapDispatchToProps = TermsListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsListView));
