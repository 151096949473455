import React from "react";
import CommonComponent from "../../../components/CommonComponent";

// CSS
import "../../../css/common.css";
import "../../../css/emailForm/createEmailConfirm/createEmailConfirmCharacter.css";

class CreateEmailConfirmCharacterView extends CommonComponent {
  constructor(props) {
    super(props);
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    return (
      <div className="createEmailContirmCharacter">
        <div className="create_confirm_character_container">
          <div className="create_confirm_character_container_inner">
            <div className="title" data-detect="admin_email_form_create_confirm_detail_title">
              CREATE CONFIRM CHARACTER
            </div>
            <div className="create_confirm_text_box table">
              <div className="row">
                <div className="create_confirm_text confirmation_number cell">
                  <div className="create_confirm_title row" data-detect="admin_email_form_create_confirm_detail_label_url">
                    URL
                  </div>
                  <div className="create_confirm_character row">{`{{ url }}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEmailConfirmCharacterView;
