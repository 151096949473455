import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import UserInfoDetailData from "../Detail/UserInfoDetailData";

//css
import "../../css/common.css";
import "../../css/userInfo/userInfoDetail.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class UserInfoDetailView extends UserInfoDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      userInfoId: "",
      myDuty: "",
    };
  }

  // 첫 실행시 URL에서 userInfoId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const userInfoId = params.get("userInfoId");
    this.setState({
      userInfoId: userInfoId,
    });
    this.requestGetUserInfoOne(userInfoId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //수정 페이지로 이동
  linkUserInfoEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const userInfoId = params.get("userInfoId");
    this.props.history.push(`/userInfoEditPage?userInfoId=${userInfoId}`);
  };

  //내 정보 수정 페이지로 이동
  linkMyInfoEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const userInfoId = params.get("userInfoId");
    this.props.history.push(`/myInfoEditPage?userInfoId=${userInfoId}`);
  };

  // 삭제 버튼 클릭시 
  onClickDeleteButton = () => {
    this.props.showTopPopupDialog(
      "N",

      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.deleteUserinfo();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      } //cancel 버튼시
    );
  };

  // userInfo 삭제 요청
  deleteUserinfo = () => {
    const { userInfoId } = this.state;
    this.setState({ isShowAllLoading: true });
    this.requestDeleteUserInfo(userInfoId);
  };



  render() {
    let userInfoDetail = null;
    let getDuty = "";
    let myDuty = "";
    let myEmail = "";
    let getEmail = "";
    let modifyMyInfo = false;
    if (
      this.props.userInfoDetail != null &&
      this.props.userInfoDetail?.success == true
    ) {
      userInfoDetail = this.props.userInfoDetail.results;
      getDuty = this.props.userInfoDetail.results.duty;
      getEmail = this.props.userInfoDetail.results.email;
    }

    if (this.props.data != null && this.props.data?.success == true) {
      myDuty = this.props.data.results.duty;
      myEmail = this.props.data.results.email;
    }

    if(this.props.userInfoDetail?.success == true && this.props.data?.success == true) {
        myEmail = this.props.data.results.email;
        getEmail = this.props.userInfoDetail.results.email;
        modifyMyInfo = myEmail == getEmail;
      }

    // 사용자의 직책에 따라 삭제 버튼 표시 여부 결정
    const cantDelete = (
      (myDuty == "ADMIN" &&  getDuty != "USER") ||
      (myDuty == "USER"));

    // 사용자의 직책에 따라 수정 버튼 표시 여부 결정
    const canEdit =
      (myDuty &&
        myDuty == "SUPER_ADMIN" &&
        (getDuty == "ADMIN" || getDuty == "USER")) ||
      (myDuty && myDuty == "ADMIN" && getDuty == "USER");

    return (
      <div className="userInfoDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="userdetail_container">
          <div className="userdetail_container_inner">
            <div className="title" data-detect="admin_admin_user_detail_title">
              USER INFORMATION
            </div>

            <div className="user_information_container">
              <div className="detail_table">
                <div className="detail_thead">
                  <div className="detail_cell info_email">
                    <span>{userInfoDetail?.email??"-"}</span>
                  </div>
                  <div className="detail_cell user_duty">
                    <span>{userInfoDetail?.duty??"-"}</span>
                  </div>
                </div>

                <div className="detail_tbody">
                  <div className="detail_row info_name">
                    <div className="detail_cell index" data-detect="admin_admin_user_detail_label_name">
                      Name
                    </div>
                    <div className="detail_cell content">
                      <span>{userInfoDetail?.name??"-"}</span>
                    </div>
                  </div>
                  <div className="detail_row info_name">
                    <div className="detail_cell index" data-detect="admin_admin_user_detail_label_department">
                      Department
                    </div>
                    <div className="detail_cell content">
                      <span>{userInfoDetail?.department??"-"}</span>
                    </div>
                  </div>
                  <div className="detail_row info_name">
                    <div className="detail_cell index" data-detect="admin_admin_user_detail_label_duty">
                      Duty
                    </div>
                    <div className="detail_cell content">
                      <span>{userInfoDetail?.duty??"-"}</span>
                    </div>
                  </div>
                  <div className="detail_row info_name">
                    <div className="detail_cell index" data-detect="admin_admin_user_detail_label_date">
                      Date
                    </div>
                    <div className="detail_cell content">
                      <span>{userInfoDetail ? formatDateTimeDot(userInfoDetail.loginAt) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="button_form_container">
              {!cantDelete && (
                <div className="delete_btn_container">
                  <button className="delete_btn" onClick={this.onClickDeleteButton} data-detect="admin_admin_user_detail_button_delete">
                    Delete
                  </button>
                </div>
              )}
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_admin_user_detail_button_go_to_list"></button>
              </div>
              {/* 수정 버튼 표시 여부에 따라 조건부 렌더링 */}
              {canEdit && (
                <div className="edit_btn_container">
                  <button
                    className="edit_btn on"
                    onClick={() => {
                      this.linkUserInfoEdit();
                    }}
                    data-detect="admin_admin_user_detail_button_ok"
                  >
                    Edit
                  </button>
                </div>
              )}
              {/* 수정 버튼 표시 여부에 따라 조건부 렌더링 */}
              {modifyMyInfo && (
                <div className="edit_btn_container">
                  <button
                    className="edit_btn on"
                    onClick={() => {
                      this.linkMyInfoEdit();
                    }}
                    data-detect="admin_admin_user_detail_button_ok"
                  >
                    Edit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserInfoDetailData.mapStateToProps;
let mapDispatchToProps = UserInfoDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserInfoDetailView)
);
