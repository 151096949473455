import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllApplicationTranslation(pageNo, appType, osType, appVersion, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get(
    '/admin/api/applicationTranslation/?fileName=' + search + '&applicationType=' + appType + '&osType=' + osType + '&applicationVersion=' + appVersion + '&version=&offset=' + offset + '&limit=' + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetApplicationTranslationOne(applicationTranslationId) {
  return axiosInstance.get("/admin/api/applicationTranslation/" + applicationTranslationId);
}

//------------------------------- add applicaiton translation ------------------------------------------------------//
export function onAddapplicationTranslation(formData) {
  return axiosInstance.post("/admin/api/applicationTranslation/", formData);
}
//------------------------------- delete application translation ------------------------------------------------------//
export function onDeleteApplicationTranslation(id) {
  return axiosInstance.delete("/admin/api/applicationTranslation/" + id);
}

//------------------------------- edit ApplicationTranslation ------------------------------------------------------//
export function onEditApplicationTranslation(applicationTranslationId, formData) {
  return axiosInstance.put("/admin/api/applicationTranslation/" + applicationTranslationId, formData);
}
