class MyConectColors {
    /* Background colors */
    static BG_PRIMARY = '#FFC700';
    static BG_DARK = '#383838';
    static BG_LIGHT = '#FFFFFF';
    static BG_MENU = '#FFF9E5';
    static BG_GREY = '#F2F2F2';
    static BG_MY_PAGE = '#3E4A59';
    static BG_LIST = '#F8D2D2';

    /* Text colors */
    static TEXT_DEFAULT = '#3E4A59';
    static TEXT_PRIMARY = '#FFC700';
    static TEXT_DARK = '#383838';
    static TEXT_LIGHT = '#FFFFFF';
    static TEXT_DISABLE = '#E9EDF1';
    static TEXT_RESPONSE = '#FF4249';
    static TEXT_REQUEST = '#0000f5';

    /* Line colors */
    static LINE_DEFAULT = '#9A9FA8';
    static LINE_LIST = '#E5E5E5';

    /* Button colors */
    static BUTTON_BG_PRIMARY = '#FFC700';
    static BUTTON_BG_HOVER = '#FFD84C';
    static BUTTON_BG_FOCUS = '#e5b302';
    static BUTTON_BG_DISABLE = '#E9EdF1';
    static BUTTON_BG_MYPAGE_DEFAULT = '#FFFFFF';
    static BUTTON_BG_MYPAGE_SELECT = '#535B68';
    static BUTTON_LIGHT_DEFAULT = '#FFFFFF';
    static BUTTON_LIGHT_HOVER = '#FFF9E5';
    static BUTTON_LIGHT_ACTIVE = '#FFF4CC';

    /* Button Border colors */
    static BUTTON_BORDER_DEFAULT = '#DFE3E9';
    static BUTTON_BORDER_DISABLE = '#D1D5D8';
    static BUTTON_BORDER_HOVER = '#667486';
    static BUTTON_BORDER_FOCUS = '#3E4A59';

    // button text colors
    static BUTTON_TEXT_DEFAULT = '#7F8FA4';
    static BUTTON_TEXT_DISABLE_GREY = '#9A9FA8';
    static BUTTON_TEXT_ERROR = '#FF4249';
    static BUTTON_TEXT_ACTIVE = '#3E4A59';
    static BUTTON_TEXT_PRIMARY = '#FFC700';
    static BUTTON_TEXT_DARK = '#383838';
    static BUTTON_TEXT_LIGHT = '#FFFFFF';

    /* Input colors */
    static INPUT_BORDER_DEFAULT = '#DFE3E9';
    static INPUT_BORDER_DISABLE = '#D1D5D8';
    static INPUT_BORDER_ERROR = '#FF4249';
    static INPUT_BORDER_ACTIVE = '#667486';
    static INPUT_TEXT_DEFAULT = "#3E4A59";
    static INPUT_TEXT_DISABLE = "#9A9FA8";
    static INPUT_TEXT_PLACEHOLDER = "#7F8FA4";
    static ICON_DEFAULT = '#9A9FA8';
    static ICON_PRIMARY = '#FFC700';
    static ICON_DARK = '#383838';
    static ICON_LIGHT = '#FFFFFF';
}

export default MyConectColors;
