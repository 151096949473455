let ServerUrl = "";

if (window.location.href.includes("cms.beta.myconect.net")) {
  ServerUrl = "https://cms.api.beta.myconect.net";
} else if (window.location.href.includes("cms.myconect.net")) {
  ServerUrl = "https://cms.api.rel.myconect.net";
} else {
  ServerUrl = "http://holich.iptime.org:8300";
}

export default ServerUrl;
