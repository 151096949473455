const EnglishLanguage = {
  version: "2.0.18",
  language: {
    en: {
      login_button_admin: "ADMIN LOGIN PAGE",
      login_button_hotel: "HOTEL LOGIN PAGE",
      login_guide_login: "Click your Login Page",
      admin_login_admin_label_admin: "ADMIN",
      admin_login_admin_placeholder_email: "Email",
      admin_login_admin_placeholder_password: "Password",
      admin_login_admin_button_login: "Login",
      admin_login_admin_guide_login: "Log in as admin.",
      hotel_login_hotel_label_hotel: "HOTEL",
      hotel_login_hotel_placeholder_email: "Email",
      hotel_login_hotel_placeholder_password: "Password",
      hotel_login_hotel_button_login: "Log in",
      hotel_login_hotel_guide_login: "Log in as hotel.",
      common_taxi_call: "Taxi",
      common_parcel_service: "Parcel Service",
      common_luggage_service: "Luggage Service",
      common_taxi_call_ex: "Taxi Express",
      common_parcel_service_ex: "Parcel Service Express",
      common_luggage_service_ex: "Luggage Service Express",
      common_green_campaign: "Green Campaign",
      common_make_up_room: "Make Up Room",
      common_turn_down_service: "Turndown Service",
      common_emergency: "Emergency Service",
      common_dnd: "DND",
      common_in_room_dining: "In-Room Dining Service",
      common_ironing_service: "Ironing Service",
      common_laundry_service: "Laundry Service",
      common_amenity_service: "Amenity Service",
      common_in_room_dining_ex: "In-Room Dining Service Express",
      common_ironing_service_ex: "Ironing Service Express",
      common_laundry_service_ex: "Laundry Service Express",
      common_waiting_request: "Standby Request Service",
      common_valet_return_additional_charge: "Valet Return Additional Charge",
      common_requested: "Requested",
      common_confirmed: "Confirmed",
      common_change: "Changed",
      common_cancled: "Canceled",
      common_standby: "Standby",
      common_completed: "Completed",
      common_stopped: "Stopped",
      common_picked_up: "Picked Up",
      common_started: "Started",
      common_delivered: "Delivered",
      common_not_available: "Not Available",
      common_delay: "Delay",
      common_room_posting: "Room Posting",
      common_room_posting_refund: "Room Posting Refund",
      common_self_settlement: "Self Settlement",
      common_self_settlement_refund: "Self-Settlement Refund",
      common_staff_room_posting: "Staff Room Posting",
      common_staff_settlement: "Staff Settlement",
      common_put_off: "Put Off",
      common_vehicle_change: "Vehicle no. change",
      common_valet: "Valet",
      common_location: "Parked location",
      common_waiting: "Standby request",
      common_waiting_confirm: "Confirmed",
      common_date_change: "Standby time change",
      common_vehicle_standby: "Vehicle ready",
      common_preparing: "Preparing",
      common_return: "Returned",
      common_handover: "Handed over",
      common_deleted: "Deleted",
      commom_delay_15: "Delay 15 Minutes",
      commom_delay_30: "Delay 30 Minutes",
      commom_delay_45: "Delay 45 Minutes",
      commom_delay_60: "Delay 60 Minutes",
      commom_delay_asaa: "As soon as available",
      common_normal: "Normal",
      common_express: "Express",
      common_boundary_line: "Boundary Line",
      common_accepted: "Accepted",
      common_rejected: "Rejected",
      common_key_expired: "Key Expired",
      common_disposable_key: "Disposable Key",
      common_today_only_key: "Today Only Key",
      common_entire_stay_key: "Entire Stay Key",
      common_validation_name: "Please enter name.",
      common_validation_content: "Please enter content.",
      common_validation_select_img: "Please select image.",
      common_summernote_lang: "en-EN",
      common_clean: "Cleaning complete",
      common_inspected: "Inspected",
      common_dirty: "Dirty",
      common_out_of_order: "Out of order",
      common_out_of_service: "Out of service",
      common_pick_up: "Pick Up",
      common_cleaning: "Cleaning",
      common_done: "Completed",
      common_not_reserved: "Not reserved",
      common_departed: "Departed",
      common_arrived: "Arrived",
      common_week_mon: "Mon",
      common_week_tue: "Tue",
      common_week_wed: "Wed",
      common_week_thu: "Thu",
      common_week_fri: "Fri",
      common_week_sat: "Sat",
      common_week_sun: "Sun",
      common_percent: "Percentage",
      common_nights: "Nights",
      common_flat: "Flat Rate",
      common_all_nights: "All Nights (entire stay)",
      common_deposit_only: "Deposit Only",
      common_first_night: "First Night",
      common_night: "Night",
      common_inactive: "Inactive",
      common_active: "Active",
      common_success: "success",
      common_failure: "failure",
      admin_header_link_password: "Password",
      admin_header_link_logout: "Log Out",
      admin_header_link_hotel: "Hotel",
      admin_header_link_setting: "Setting",
      admin_header_accordion_location: "Location",
      admin_header_link_region_list: "Region List",
      admin_header_link_country_list: "Country List",
      admin_header_link_city_list: "City List",
      admin_header_link_application_user: "Application User",
      admin_header_link_payment: "Payment",
      admin_header_accordion_transfer: "Transfer",
      admin_header_link_transfer_log_list: "Transfer Log List",
      admin_header_link_user_transfer_log_list: "User Transfer Log List",
      admin_header_link_transaction_log_list: "Transaction Log List",
      admin_header_link_user_transfer_log_history: "User Transfer Log History",
      admin_header_link_staff_transfer_log_history: "Staff Transfer Log History",
      admin_header_link_user_notification_log_list: "User Notification Log List",
      admin_header_link_staff_notification_log_list: "Staff Notification Log List",
      admin_header_accordion_version: "Version",
      admin_header_link_version: "Version",
      admin_header_link_application_down_version_list: "App Download Screen Settings",
      admin_header_link_guest_application_down_list: "Guest App Minimum Version Settings",
      admin_header_link_staff_application_down_list: "Staff App Minimum Version Settings",
      admin_header_link_paking_staff_application_down_list: "Parking Staff App Minimum Version Settings",
      admin_header_link_application_translation: "Application Translation",
      admin_header_link_booking_email: "Email Image",
      admin_header_accordion_email_form: "Email Form",
      admin_header_link_change_password: "Change Password",
      admin_header_link_forgot_id: "Forgot ID",
      admin_header_link_forgot_password: "Forgot Password",
      admin_header_link_create_confirm_email: "Confirm Email",
      admin_header_link_create_confirm_email_fail: "Confirm Email Fail",
      admin_header_link_create_confirm_email_success: "Confirm Email Success",
      admin_header_link_update_profile: "Update Profile",
      admin_header_link_withdraw: "Delete Account",
      admin_header_link_app_endpoint: "Application Endpoint",
      admin_header_accordion_terms_and_conditions: "Terms and Conditions",
      admin_header_link_terms: "Terms",
      admin_header_link_terms_group: "Terms Group",
      admin_header_link_agreement_history: "Agreement History",
      admin_header_current_version: "Current Version",
      admin_header_translation_version: "Translation Version",
      admin_header_link_admin_user: "Admin User",
      admin_header_accordion_auth_data: "Beacon/GPS Connection History",
      admin_header_link_auth_data_log_list: "Beacon/GPS Connection List",
      admin_header_link_use_key_log: "Use Key Log",
      admin_header_link_amenity: "Amenity",
      admin_header_link_facility_image: "Hotel Facility Image",
      admin_header_link_recommend_app: "Recommended App",
      admin_header_link_id_recognition_information: "ID Recognition Information",
      admin_header_link_statistics: "Statistics",
      admin_password_title: "CHANGE PASSWORD",
      admin_password_guide_password: "Please enter a new password \n with at least 8 characters.",
      admin_password_validation_current_password: "* Please enter your current password.",
      admin_password_validation_character_number_combining: "* Please enter at least 8 characters combining letters and numbers.",
      admin_password_validation_new_password: "* Please enter a new password.",
      admin_password_validation_new_password_again: "* Please enter your new password again.",
      admin_password_validation_not_match: "* Passwords do not match.",
      admin_password_placeholder_password: "Password",
      admin_password_placeholder_new_password: "New password",
      admin_password_placeholder_new_confirm_password: "Confirm new password",
      admin_password_button_save: "Save",
      admin_hotel_list_title: "HOTEL LIST",
      admin_hotel_list_placeholder_search: "Search...",
      admin_hotel_list_label_hotel_no: "Hotel No.",
      admin_hotel_list_label_name: "Name",
      admin_hotel_list_label_address: "Address",
      admin_hotel_list_label_is_show: "Is Show",
      admin_hotel_list_label_date: "Date",
      admin_hotel_list_guide_empty: "List is empty",
      admin_hotel_list_button_delete: "Delete",
      admin_hotel_list_button_add: "Add",
      admin_hotel_list_button_copy: "Copy",
      admin_hotel_add_title: "ADD HOTEL",
      admin_hotel_add_guide_click_add: "Enter information to add HOTEL \n and click the <<'Add'>> button.",
      admin_hotel_add_validation_name: "* Please enter hotel name.",
      admin_hotel_add_validation_address: "* Please enter hotel address.",
      admin_hotel_add_validation_korea_english: "* Korean and English or 'Please enter using ._ -'.",
      admin_hotel_add_validation_email: "* Please enter user email.",
      admin_hotel_add_validation_email_format: "* Please enter email in the format someone@example.com",
      admin_hotel_add_validation_password: "* Please enter user password.",
      admin_hotel_add_validation_character_number_combining: "* Please enter at least 8 characters combining letters and numbers.",
      admin_hotel_add_validation_not_match: "* Passwords do not match.",
      admin_hotel_add_validation_image_file: "* Please upload a logo image.",
      admin_hotel_add_label_name: "Name",
      admin_hotel_add_placeholder_hotel_name: "Hotel name",
      admin_hotel_add_label_address: "Address",
      admin_hotel_add_placeholder_hotel_address: "Hotel address",
      admin_hotel_add_label_email: "Email",
      admin_hotel_add_placeholder_staff_email: "Staff email",
      admin_hotel_add_label_password: "Password",
      admin_hotel_add_placeholder_staff_password: "Staff password",
      admin_hotel_add_label_confirm_password: "Password Confirm",
      admin_hotel_add_placeholder_staff_confirm_password: "Confirm staff password",
      admin_hotel_add_label_logo: "Logo",
      admin_hotel_add_button_upload: "Upload",
      admin_hotel_add_placeholder_upload: "ex) MyCONECT.png",
      admin_hotel_add_button_go_to_list: "Go to list",
      admin_hotel_add_button_add: "Add",
      admin_hotel_detail_title: "HOTEL INFORMATION",
      admin_hotel_detail_label_hotel_no: "Hotel No.",
      admin_hotel_detail_label_name: "Name",
      admin_hotel_detail_label_address: "Address",
      admin_hotel_detail_label_region: "Region",
      admin_hotel_detail_label_country: "Country",
      admin_hotel_detail_label_city: "City",
      admin_hotel_detail_label_country_name: "Country Name",
      admin_hotel_detail_label_payment_server_url: "Payment Server URL",
      admin_hotel_detail_label_is_direct_payable: "Is Direct Payable",
      admin_hotel_detail_label_is_card_tokenizable: "Is Card Tokenizable",
      admin_hotel_detail_label_is_show: "Is Show",
      admin_hotel_detail_button_yes: "Yse",
      admin_hotel_detail_button_no: "No",
      admin_hotel_detail_label_logo: "Logo",
      admin_hotel_detail_label_hotel_photo: "Hotel photo",
      admin_hotel_detail_label_hotel_map: "Map",
      admin_hotel_detail_button_go_to_list: "Go to list",
      admin_hotel_detail_button_edit: "Edit",
      admin_hotel_edit_title: "HOTEL INFORMATION",
      admin_hotel_edit_label_hotel_no: "Hotel No.",
      admin_hotel_edit_label_name: "Name",
      admin_hotel_edit_label_address: "Address",
      admin_hotel_edit_label_region: "Region",
      admin_hotel_edit_label_country: "Country",
      admin_hotel_edit_label_choose: "Select",
      admin_hotel_edit_label_city: "City",
      admin_hotel_edit_label_country_name: "Country Name",
      admin_hotel_edit_label_logo: "Logo",
      admin_hotel_edit_label_payment_server_url: "Payment Server URL",
      admin_hotel_edit_label_is_direct_payable: "Is Direct Payable",
      admin_hotel_edit_label_is_card_tokenizable: "Is Card Tokenizable",
      admin_hotel_edit_label_is_show: "Is Show",
      admin_hotel_edit_button_yes: "Yse",
      admin_hotel_edit_button_no: "No",
      admin_hotel_edit_button_upload: "Upload",
      admin_hotel_edit_placeholder_upload: "ex) MyCONECT.png",
      admin_hotel_edit_label_hotel_photo: "Hotel photo",
      admin_hotel_edit_label_hotel_map: "Map",
      admin_hotel_edit_button_search: "Search",
      admin_hotel_edit_button_cancel: "Cancel",
      admin_hotel_edit_button_ok: "OK",
      admin_hotel_edit_validation_region: "* Please select a region.",
      admin_hotel_edit_validation_country: "* Please select a country.",
      admin_hotel_edit_validation_city: "* Please select a city.",
      admin_hotel_edit_validation_country_name: "* Please select a country name.",
      admin_hotel_edit_validation_is_show: "* Please select Is Show.",
      admin_hotel_copy_title: "COPY HOTEL",
      admin_hotel_copy_guide_click_copy: "Enter the hotel details and click 'Copy' to copy the hotel information.",
      admin_hotel_copy_button_copy: "Copy",
      admin_setting_detail_title: "MyCONECT SETTINGS",
      admin_setting_detail_label_date: "Date Updated",
      admin_setting_detail_label_setting: "Settings",
      admin_setting_detail_label_coordinate_distance: "Coordinate Distance",
      admin_setting_detail_button_edit: "Edit",
      admin_setting_edit_title: "MyCONECT SETTINGS",
      admin_setting_edit_label_date: "Date Updated",
      admin_setting_edit_label_setting: "Settings",
      admin_setting_edit_label_coordinate_distance: "Coordinate Distance",
      admin_setting_edit_button_ok: "OK",
      admin_setting_edit_button_cancel: "Cancel",
      admin_setting_edit_validation_coordinate_distance: "* Please enter the coordinate distance.",
      admin_region_list_title: "REGION LIST",
      admin_region_list_placeholder_search: "Search...",
      admin_region_list_label_region: "Region",
      admin_region_list_label_date: "Date",
      admin_region_list_guide_empty: "List is empty",
      admin_region_list_button_delete: "Delete",
      admin_region_list_button_add: "Add",
      admin_region_add_title: "ADD REGION",
      admin_region_add_guide_click_add: "Enter the region. \n  and click the <<'Add'>> button.",
      admin_region_add_validation_name: "* Please enter region name.",
      admin_region_add_label_region: "Region",
      admin_region_add_placeholder_region_name: "Region name",
      admin_region_add_button_go_to_list: "Go to list",
      admin_region_add_button_add: "Add",
      admin_region_detail_title: "REGION",
      admin_region_detail_label_region: "Region",
      admin_region_detail_label_created_at: "Created At",
      admin_region_detail_label_updated_at: "Updated At",
      admin_region_detail_button_go_to_list: "Go to list",
      admin_region_detail_button_edit: "Edit",
      admin_region_edit_title: "REGION",
      admin_region_edit_validation_name: "* Please enter region name.",
      admin_region_edit_label_region: "Region",
      admin_region_edit_placeholder_region_name: "Region name",
      admin_region_edit_label_created_at: "Create At",
      admin_region_edit_label_updated_at: "Updated At",
      admin_region_edit_button_cancel: "Cancel",
      admin_region_edit_button_ok: "OK",
      admin_country_list_title: "COUNTRY LIST",
      admin_country_list_select_region_all: "Region ( ALL )",
      admin_country_list_placeholder_search: "Search...",
      admin_country_list_label_region: "Region",
      admin_country_list_label_country: "Country",
      admin_country_list_label_date: "Date",
      admin_country_list_guide_empty: "List is empty",
      admin_country_list_button_delete: "Delete",
      admin_country_list_button_add: "Add",
      admin_country_add_title: "ADD COUNTRY",
      admin_country_add_guide_click_add: "Enter the country and click the <<'Add'>> button.",
      admin_country_add_validation_region: "* Please select region.",
      admin_country_add_validation_country: "* Please enter country name.",
      admin_country_add_label_region: "Region",
      admin_country_add_label_choose: "Select",
      admin_country_add_label_country: "Country",
      admin_country_add_placeholder_country_name: "Country name",
      admin_country_add_button_go_to_list: "Go to list",
      admin_country_add_button_add: "Add",
      admin_country_detail_title: "COUNTRY",
      admin_country_detail_label_region: "Region",
      admin_country_detail_label_country: "Country",
      admin_country_detail_placeholder_country_name: "Country name",
      admin_country_detail_label_created_at: "Created At",
      admin_country_detail_label_updated_at: "Updated At",
      admin_country_detail_button_go_to_list: "Go to list",
      admin_country_detail_button_edit: "Edit",
      admin_country_edit_title: "COUNTRY",
      admin_country_edit_validation_country: "* Please enter the country name.",
      admin_country_edit_label_region: "Region",
      admin_country_edit_label_country: "Country",
      admin_country_edit_placeholder_country_name: "Country name",
      admin_country_edit_label_created_at: "Created At",
      admin_country_edit_label_updated_at: "Updated At",
      admin_country_edit_button_cancel: "Cancel",
      admin_country_edit_button_ok: "OK",
      admin_city_list_title: "CITY LIST",
      admin_city_list_select_region_all: "Region ( ALL )",
      admin_city_list_select_country_all: "Country ( ALL )",
      admin_city_list_placeholder_search: "Search...",
      admin_city_list_label_region: "Region",
      admin_city_list_label_country: "Country",
      admin_city_list_label_city: "City",
      admin_city_list_label_date: "Date",
      admin_city_list_guide_empty: "List is empty",
      admin_city_list_button_delete: "Delete",
      admin_city_list_button_add: "Add",
      admin_city_add_title: "ADD CITY",
      admin_city_add_guide_click_add: "Enter the city. \n  and click the <<'Add'>> button.",
      admin_city_add_validation_region: "* Please select region.",
      admin_city_add_validation_country: "* Please select country.",
      admin_city_add_validation_city: "* Please enter city name.",
      admin_city_add_label_region: "Region",
      admin_city_add_label_country: "Country",
      admin_city_add_label_choose: "Select",
      admin_city_add_label_city: "City",
      admin_city_add_placeholder_city_name: "City name",
      admin_city_add_button_go_to_list: "Go to list",
      admin_city_add_button_add: "CITY",
      admin_city_detail_label_region: "Add",
      admin_city_detail_title: "Region",
      admin_city_detail_label_country: "Country",
      admin_city_detail_label_city: "City",
      admin_city_detail_placeholder_city_name: "City name",
      admin_city_detail_label_created_at: "Created At",
      admin_city_detail_label_updated_at: "Updated At",
      admin_city_detail_button_go_to_list: "Go to list",
      admin_city_detail_button_edit: "Edit",
      admin_city_edit_title: "CITY",
      admin_city_edit_validation_city: "* Please enter city name.",
      admin_city_edit_label_region: "Region",
      admin_city_edit_label_country: "Country",
      admin_city_edit_label_city: "City",
      admin_city_edit_placeholder_city_name: "City name",
      admin_city_edit_label_created_at: "Created At",
      admin_city_edit_label_updated_at: "Updated At",
      admin_city_edit_button_cancel: "Cancel",
      admin_city_edit_button_ok: "OK",
      admin_application_user_list_title: "APPLICATION USER LIST",
      admin_application_user_list_placeholder_select_box_user: "User",
      admin_application_user_list_select_email: "Email",
      admin_application_user_list_select_name: "Name",
      admin_application_user_list_placeholder_search: "Search...",
      admin_application_user_list_label_email: "Email",
      admin_application_user_list_label_backup_email: "Backup Email",
      admin_application_user_list_label_name: "Name",
      admin_application_user_list_label_alternative_name: "Alternative Name",
      admin_application_user_list_label_mail_confirmed_at: "Email Confirmed At",
      admin_application_user_list_label_date: "Date",
      admin_application_user_list_button_delete: "Delete",
      admin_application_user_list_guide_empty: "List is empty",
      admin_application_user_detail_title: "User Email",
      admin_application_user_detail_label_user_information: "Application User Information",
      admin_application_user_detail_user_info_email: "Email",
      admin_application_user_detail_user_info_gender: "Gender",
      admin_application_user_detail_user_info_first_name: "First Name",
      admin_application_user_detail_user_info_last_name: "Last Name",
      admin_application_user_detail_user_info_alternative_first_name: "Alternative First Name",
      admin_application_user_detail_user_info_alternative_last_name: "Alternative Last Name",
      admin_application_user_detail_user_info_member_type: "Member Type",
      admin_application_user_detail_user_info_backup_email: "Backup Email",
      admin_application_user_detail_user_info_mail_confirmed_at: "Email Confirmed At",
      admin_application_user_detail_user_info_push_token: "Push Token",
      admin_application_user_detail_user_info_os_type: "OS Type",
      admin_application_user_detail_user_info_language_code: "Language Code",
      admin_application_user_detail_user_info_updated_at: "Updated At",
      admin_application_user_detail_user_info_date: "Date",
      admin_application_user_detail_button_go_to_list: "Go to list",
      admin_payment_list_title: "PAYMENT LIST",
      admin_payment_list_placeholder_hotel_id: "Hotel ID",
      admin_payment_list_placeholder_user_name: "User Name",
      admin_payment_list_placeholder_search: "Search...",
      admin_payment_list_label_no: "No.",
      admin_payment_list_label_reservation_id: "Reservation ID",
      admin_payment_list_label_user_name: "User Name",
      admin_payment_list_label_long_info: "Long Info",
      admin_payment_list_label_amount: "Amount",
      admin_payment_list_label_unit: "Unit",
      admin_payment_list_label_pg_status_code: "PG status code",
      admin_payment_list_label_pg_status_message: "PG status message",
      admin_payment_list_label_hotel_id: "Hotel ID",
      admin_payment_list_label_date: "Date",
      admin_payment_detail_label_order_no: "Order No.",
      admin_payment_detail_label_user: "User",
      admin_payment_detail_label_user_profile_id: "Profile ID",
      admin_payment_detail_label_user_title: "Title",
      admin_payment_detail_label_user_first_name: "First Name",
      admin_payment_detail_label_user_last_name: "Last Name",
      admin_payment_detail_label_user_email: "Email",
      admin_payment_detail_label_user_date: "Date",
      admin_payment_detail_label_reservation: "Reservation",
      admin_payment_detail_label_reservation_confirmation_no: "Confirmation No.",
      admin_payment_detail_label_reservation_reservation_id: "Reservation ID",
      admin_payment_detail_label_reservation_reservation_status: "Reservation Status",
      admin_payment_detail_label_reservation_check_in_date: "Check In Date",
      admin_payment_detail_label_reservation_check_out_date: "Check Out Date",
      admin_payment_detail_label_hotel: "Hotel",
      admin_payment_detail_label_hotel_hotel_id: "Hotel ID",
      admin_payment_detail_label_hotel_hotel_name: "Hotel Name",
      admin_payment_detail_label_hotel_region: "Region",
      admin_payment_detail_label_hotel_country: "Country",
      admin_payment_detail_label_hotel_city: "City",
      admin_payment_detail_label_payment: "Payment",
      admin_payment_detail_button_go_to_list: "Go to list",
      admin_payment_detail_button_go_to_log: "Go to log list",
      admin_payment_detail_label_payment_order_no: "Order No.",
      admin_payment_detail_label_payment_reservation_id: "Reservation ID",
      admin_payment_detail_label_payment_long_info: "Long Info",
      admin_payment_detail_label_payment_folio_view_no: "Folio View No.",
      admin_payment_detail_label_payment_amount: "Amount",
      admin_payment_detail_label_payment_unit: "Unit",
      admin_payment_detail_label_payment_pg_order_no: "PG Order No.",
      admin_payment_detail_label_payment_pg_status: "PG Status",
      admin_payment_detail_label_payment_pg_status_code: "PG Status Code",
      admin_payment_detail_label_payment_pg_status_message: "PG Status Message",
      admin_payment_detail_label_payment_pg_amount: "PG Amount",
      admin_payment_detail_label_payment_pg_unit: "PG Unit",
      admin_payment_detail_label_payment_pg_billing_no: "PG Billing No.",
      admin_payment_detail_label_payment_confirmed_at: "Confirmed At",
      admin_payment_detail_label_payment_created_at: "Created At",
      admin_payment_detail_label_payment_updated_at: "Updated At",
      admin_payment_log_list_title: "PAYMENT LOG LIST",
      admin_payment_log_list_label_no: "NO.",
      admin_payment_log_list_label_type: "Type",
      admin_payment_log_list_label_method: "Method",
      admin_payment_log_list_label_path: "Path",
      admin_payment_log_list_label_status: "Status",
      admin_payment_log_list_label_success: "Success",
      admin_payment_log_list_label_created_at: "Created At",
      admin_payment_log_list_guide_empty: "List is empty",
      admin_payment_log_list_button_go_to_detail: "Go to Detail",
      admin_payment_log_detail_title: "PAYMENT LOG DETAIL",
      admin_payment_log_detail_label_id: "ID",
      admin_payment_log_detail_label_hotel_id: "Hotel ID",
      admin_payment_log_detail_label_payment_id: "Payment ID",
      admin_payment_log_detail_label_transfer_code: "Transfer Code",
      admin_payment_log_detail_label_type: "Type",
      admin_payment_log_detail_label_method: "Method",
      admin_payment_log_detail_label_path: "Path",
      admin_payment_log_detail_label_header: "Header",
      admin_payment_log_detail_label_param: "Param",
      admin_payment_log_detail_label_body: "Body",
      admin_payment_log_detail_label_status: "Status",
      admin_payment_log_detail_label_session: "Session",
      admin_payment_log_detail_label_created_at: "Created At",
      admin_payment_log_detail_button_go_to_list: "Go to list",
      admin_transfer_log_list_title: "TRANSFER LIST",
      admin_transfer_log_list_placeholder_user_id: "User ID",
      admin_transfer_log_list_placeholder_hotel_id: "Hotel ID",
      admin_transfer_log_list_placeholder_search: "Search...",
      admin_transfer_log_list_label_no: "No.",
      admin_transfer_log_list_label_type: "Type",
      admin_transfer_log_list_label_user: "User",
      admin_transfer_log_list_label_hotel_id: "Hotel ID",
      admin_transfer_log_list_label_path: "Path",
      admin_transfer_log_list_label_status: "Status",
      admin_transfer_log_list_label_success: "Success",
      admin_transfer_log_list_label_created_at: "Created At",
      admin_transfer_log_list_guide_empty: "List is empty",
      admin_transfer_log_detail_title: "Transfer Detail",
      admin_transfer_log_detail_label_transfer_id: "Transfer ID",
      admin_transfer_log_detail_label_type: "Type",
      admin_transfer_log_detail_label_user: "User",
      admin_transfer_log_detail_label_hotel_id: "Hotel ID",
      admin_transfer_log_detail_label_user_email: "User Email",
      admin_transfer_log_detail_label_session: "Session",
      admin_transfer_log_detail_label_token: "Token",
      admin_transfer_log_detail_label_path: "Path",
      admin_transfer_log_detail_label_status: "Status",
      admin_transfer_log_detail_label_header: "Header",
      admin_transfer_log_detail_label_param: "Param",
      admin_transfer_log_detail_label_body: "Body",
      admin_transfer_log_detail_label_created_at: "Created at",
      admin_transfer_log_detail_label_updated_at: "Updated at",
      admin_transfer_log_detail_button_go_to_list: "Go to list",
      admin_user_transfer_log_list_title: "User Transfer List",
      admin_user_transfer_log_list_placeholder_email: "Please enter email",
      admin_user_transfer_log_list_placeholder_search: "Search...",
      admin_user_transfer_log_list_label_no: "No.",
      admin_user_transfer_log_list_label_type: "Type",
      admin_user_transfer_log_list_label_method: "Method",
      admin_user_transfer_log_list_label_path: "Path",
      admin_user_transfer_log_list_label_status: "Status",
      admin_user_transfer_log_list_label_success: "Success",
      admin_user_transfer_log_list_label_user_email: "User Email",
      admin_user_transfer_log_list_label_created_at: "Created At",
      admin_user_transfer_log_list_guide_empty: "List is empty",
      admin_user_transfer_log_detail_title: "User Transfer Detail",
      admin_user_transfer_log_detail_label_id: "ID",
      admin_user_transfer_log_detail_label_type: "Type",
      admin_user_transfer_log_detail_label_method: "Method",
      admin_user_transfer_log_detail_label_path: "Path",
      admin_user_transfer_log_detail_label_header: "Header",
      admin_user_transfer_log_detail_label_param: "Param",
      admin_user_transfer_log_detail_label_body: "Body",
      admin_user_transfer_log_detail_label_soap: "Soap",
      admin_user_transfer_log_detail_label_status: "Status",
      admin_user_transfer_log_detail_label_session: "Session",
      admin_user_transfer_log_detail_label_token: "Token",
      admin_user_transfer_log_detail_label_user_email: "User Email",
      admin_user_transfer_log_detail_label_created_at: "Created At",
      admin_user_transfer_log_detail_button_go_to_list: "Go to list",
      admin_transaction_log_list_title: "TRANSACTION LOG LIST",
      admin_transaction_log_list_select_complete_action: "Complete Action ( ALL )",
      admin_transaction_log_list_select_complete_action_commit: "Commit",
      admin_transaction_log_list_select_complete_action_rollback: "Rollback",
      admin_transaction_log_list_select_complete_action_none: "",
      admin_transaction_log_list_placeholder_search: "Search...",
      admin_transaction_log_list_label_no: "No.",
      admin_transaction_log_list_label_act_on_function: "Act on function",
      admin_transaction_log_list_label_complete_action: "Complete action",
      admin_transaction_log_list_label_completed_at: "Completed At",
      admin_transaction_log_list_label_date: "Date",
      admin_transaction_log_detail_title: "TRANSACTION LOG DETAIL",
      admin_transaction_log_detail_label_transaction_id: "Transaction ID",
      admin_transaction_log_detail_label_act_on_function: "Act on function",
      admin_transaction_log_detail_label_complete_action: "Complete action",
      admin_transaction_log_detail_label_rollback_reason: "Rollback reason",
      admin_transaction_log_detail_label_completed_at: "Completed at",
      admin_transaction_log_detail_label_created_at: "Created At",
      admin_transaction_log_detail_label_updated_at: "Updated At",
      admin_transaction_log_detail_button_go_to_list: "Go to list",
      admin_transaction_log_detail_rollback_reason_stack: "Stack",
      admin_transaction_log_detail_rollback_reason_error_message: "Error message",
      admin_user_transfer_log_history_title: "USER TRANSFER LOG HISTORY",
      admin_user_transfer_log_history_placeholder_email: "Please enter your email.",
      admin_user_transfer_log_history_placeholder_search: "Search...",
      admin_user_transfer_log_history_label_no: "NO.",
      admin_user_transfer_log_history_label_method: "Method",
      admin_user_transfer_log_history_label_path: "Path",
      admin_user_transfer_log_history_label_status: "Status",
      admin_user_transfer_log_history_label_success: "Success",
      admin_user_transfer_log_history_label_user_email: "User Email",
      admin_user_transfer_log_history_label_created_at: "Created At",
      admin_user_transfer_log_history_label_process_time: "Progress Time(ms)",
      admin_user_transfer_log_history_detail_title: "User Transfer Detail History (↓)",
      admin_user_transfer_log_history_detail_label_no: "NO.",
      admin_user_transfer_log_history_detail_label_type: "Type",
      admin_user_transfer_log_history_detail_label_method: "Method",
      admin_user_transfer_log_history_detail_label_path: "Path",
      admin_user_transfer_log_history_detail_label_status: "Status",
      admin_user_transfer_log_history_detail_label_success: "Success",
      admin_user_transfer_log_history_detail_label_user_email: "User Email",
      admin_user_transfer_log_history_detail_label_created_at: "Created At",
      admin_user_transfer_log_history_detail_button_go_to_list: "Go to list",
      admin_staff_transfer_log_history_title: "STAFF TRANSFER LOG HISTORY",
      admin_staff_transfer_log_history_list_placeholder_user_id: "User ID",
      admin_staff_transfer_log_history_list_placeholder_hotel_id: "Hotel ID",
      admin_staff_transfer_log_history_list_placeholder_search: "Search...",
      admin_staff_transfer_log_history_list_label_no: "No.",
      admin_staff_transfer_log_history_list_label_method: "방법",
      admin_staff_transfer_log_history_list_label_user: "User",
      admin_staff_transfer_log_history_list_label_hotel_id: "Hotel ID",
      admin_staff_transfer_log_history_list_label_path: "Path",
      admin_staff_transfer_log_history_list_label_status: "Status",
      admin_staff_transfer_log_history_list_label_success: "Success",
      admin_staff_transfer_log_history_list_label_created_at: "Created At",
      admin_staff_transfer_log_history_list_label_process_time: "Progress Time(ms)",
      admin_staff_transfer_log_history_list_guide_empty: "List is empty",
      admin_staff_transfer_log_history_detail_title: "Staff Transfer Detail History (↓)",
      admin_staff_transfer_log_history_detail_label_no: "No.",
      admin_staff_transfer_log_history_detail_label_type: "Type",
      admin_staff_transfer_log_history_detail_label_method: "Method",
      admin_staff_transfer_log_history_detail_label_user: "User",
      admin_staff_transfer_log_history_detail_label_hotel_id: "Hotel ID",
      admin_staff_transfer_log_history_detail_label_path: "Path",
      admin_staff_transfer_log_history_detail_label_status: "Status",
      admin_staff_transfer_log_history_detail_label_success: "Success",
      admin_staff_transfer_log_history_detail_label_created_at: "Created At",
      admin_staff_transfer_log_history_detail_button_go_to_list: "Go to list",
      admin_user_notification_log_list_title: "USER NOTIFICATION LOG LIST",
      admin_user_notification_log_list_placeholder_email: "Email",
      admin_user_notification_log_list_placeholder_search: "Search...",
      admin_user_notification_log_list_label_no: "NO.",
      admin_user_notification_log_list_label_type: "Type",
      admin_user_notification_log_list_label_title: "Title",
      admin_user_notification_log_list_label_email: "Email",
      admin_user_notification_log_list_label_user: "User",
      admin_user_notification_log_list_label_success: "Success",
      admin_user_notification_log_list_label_os: "OS",
      admin_user_notification_log_list_label_language_code: "Language Code",
      admin_user_notification_log_list_label_created_at: "Created At",
      admin_user_notification_log_detail_title: "USER NOTIFICATION LOG DETAIL",
      admin_user_notification_log_detail_label_notification_id: "Notification ID",
      admin_user_notification_log_detail_label_user_id: "User Id",
      admin_user_notification_log_detail_label_success: "Success",
      admin_user_notification_log_detail_label_user: "User",
      admin_user_notification_log_detail_label_user_email: "User Email",
      admin_user_notification_log_detail_label_language_code: "Language Code",
      admin_user_notification_log_detail_label_os_type: "OS Type",
      admin_user_notification_log_detail_label_type: "Type",
      admin_user_notification_log_detail_label_title: "Title",
      admin_user_notification_log_detail_label_content: "Content",
      admin_user_notification_log_detail_label_data: "Data",
      admin_user_notification_log_detail_label_token: "Token",
      admin_user_notification_log_detail_label_created_at: "Created At",
      admin_user_notification_log_detail_label_updated_at: "Updated At",
      admin_user_notification_log_detail_button_go_to_list: "Go to list",
      admin_staff_notification_log_list_title: "STAFF NOTIFICATION LOG LIST",
      admin_staff_notification_log_list_placeholder_email: "Email",
      admin_staff_notification_log_list_placeholder_search: "Search...",
      admin_staff_notification_log_list_label_no: "NO.",
      admin_staff_notification_log_list_label_type: "Type",
      admin_staff_notification_log_list_label_title: "Title",
      admin_staff_notification_log_list_label_email: "Email",
      admin_staff_notification_log_list_label_hotel: "Hotel",
      admin_staff_notification_log_list_label_staff: "Staff",
      admin_staff_notification_log_list_label_task: "Task",
      admin_staff_notification_log_list_label_department: "Department",
      admin_staff_notification_log_list_label_success: "Success",
      admin_staff_notification_log_list_label_os: "OS",
      admin_staff_notification_log_list_label_language_code: "Language Code",
      admin_staff_notification_log_list_label_created_at: "Created At",
      admin_staff_notification_log_detail_title: "STAFF NOTIFICATION LOG DETAIL",
      admin_staff_notification_log_detail_label_notification_id: "Notification ID",
      admin_staff_notification_log_detail_label_hotel_id: "Hotel ID",
      admin_staff_notification_log_detail_label_staff_id: "Staff ID",
      admin_staff_notification_log_detail_label_success: "Success",
      admin_staff_notification_log_detail_label_staff_email: "Email",
      admin_staff_notification_log_detail_label_staff_no: "Staff NO.",
      admin_staff_notification_log_detail_label_department: "Department",
      admin_staff_notification_log_detail_label_team: "Team",
      admin_staff_notification_log_detail_label_level: "Level",
      admin_staff_notification_log_detail_label_task: "Task",
      admin_staff_notification_log_detail_label_location: "Location",
      admin_staff_notification_log_detail_label_zone: "Zone",
      admin_staff_notification_log_detail_label_room: "Room",
      admin_staff_notification_log_detail_label_guest_inquiry_level: "Guest Inquiry Level",
      admin_staff_notification_log_detail_label_language_code: "Language Code",
      admin_staff_notification_log_detail_label_os_type: "OS Type",
      admin_staff_notification_log_detail_label_type: "Type",
      admin_staff_notification_log_detail_label_title: "Title",
      admin_staff_notification_log_detail_label_content: "Content",
      admin_staff_notification_log_detail_label_data: "Data",
      admin_staff_notification_log_detail_label_token: "Token",
      admin_staff_notification_log_detail_label_created_at: "Created At",
      admin_staff_notification_log_detail_label_updated_at: "Updated At",
      admin_staff_notification_log_detail_button_go_to_list: "Go to list",
      admin_version_list_title: "VERSION LIST",
      admin_version_list_placeholiser_search: "Search...",
      admin_version_list_label_version_name: "Version Name",
      admin_version_list_label_ios: "iOS",
      admin_version_list_label_ios_debug: "iOS Debug",
      admin_version_list_label_android: "Android",
      admin_version_list_label_android_debug: "Android Debug",
      admin_version_list_guide_empty: "List is empty",
      admin_version_list_button_delete: "Delete",
      admin_version_list_button_add: "Add",
      admin_version_add_title: "ADD VERSION",
      admin_version_add_label_version_name: "Version Name",
      admin_version_add_label_ios: "iOS",
      admin_version_add_label_ios_debug: "iOS Debug",
      admin_version_add_placeholder_ios_debug: "iOS Debug",
      admin_version_add_select_ios_true: "TRUE",
      admin_version_add_select_ios_false: "FALSE",
      admin_version_add_label_android: "Android",
      admin_version_add_label_android_debug: "Android Debug",
      admin_version_add_placeholder_android_debug: "Android Debug",
      admin_version_add_validation_name: "* Please enter the version name.",
      admin_version_add_validation_ios: "* Please enter iOS.",
      admin_version_add_validation_ios_debug: "* Please select iOS Debug.",
      admin_version_add_validation_andriod: "* Please enter Android.",
      admin_version_add_validation_andriod_debug: "* Please select Android Debug.",
      admin_version_add_select_android_true: "TRUE",
      admin_version_add_select_android_false: "FALSE",
      admin_version_add_button_go_to_list: "Go to list",
      admin_version_add_button_add: "Add",
      admin_version_detail_title: "VERSION INFORMATION",
      admin_version_detail_label_version_name: "Version Name",
      admin_version_detail_label_ios: "iOS",
      admin_version_detail_label_ios_debug: "iOS Debug",
      admin_version_detail_label_android: "Android",
      admin_version_detail_label_android_debug: "Android Debug",
      admin_version_detail_button_go_to_list: "Go to list",
      admin_version_detail_button_delete: "Delete",
      admin_version_detail_button_edit: "Edit",
      admin_version_edit_title: "VERSION INFORMATION",
      admin_version_edit_label_version_name: "Version Name",
      admin_version_edit_label_ios: "iOS",
      admin_version_edit_label_ios_debug: "iOS Debug",
      admin_version_edit_select_ios_true: "TRUE",
      admin_version_edit_select_ios_false: "FALSE",
      admin_version_edit_label_android: "Android",
      admin_version_edit_label_android_debug: "Android Debug",
      admin_version_edit_select_android_true: "TRUE",
      admin_version_edit_select_android_false: "FALSE",
      admin_version_edit_button_cancel: "Cancel",
      admin_version_edit_button_delete: "Delete",
      admin_version_edit_button_ok: "OK",
      admin_version_app_down_version_list_title: "APPLICATION VERSION LIST",
      admin_version_app_down_version_list_label_os: "OS",
      admin_version_app_down_version_list_label_type: "Type",
      admin_version_app_down_version_list_label_version: "Version",
      admin_version_app_down_version_list_label_link: "Link",
      admin_version_app_down_version_list_label_created_at: "Created At",
      admin_version_app_down_version_list_button_delete: "Delete",
      admin_version_app_down_version_list_button_add: "Add",
      admin_version_app_down_version_list_placeholiser_search: "Search...",
      admin_version_app_down_version_list_label_no: "No.",
      admin_version_app_down_version_list_guide_empty: "List is empty",
      admin_version_app_down_version_add_title: "ADD APPLICATION DOWNLOAD VERSION",
      admin_version_app_down_version_add_validation_os: "* Please select OS.",
      admin_version_app_down_version_add_validation_type: "* Please select a type.",
      admin_version_app_down_version_add_validation_version: "* Please enter the version.",
      admin_version_app_down_version_add_validation_link: "* Please enter the link.",
      admin_version_app_down_version_add_label_os: "OS",
      admin_version_app_down_version_add_placeholder_os: "OS",
      admin_version_app_down_version_add_select_ios: "iOS",
      admin_version_app_down_version_add_select_aos: "AOS",
      admin_version_app_down_version_add_label_type: "Type",
      admin_version_app_down_version_add_placeholder_type: "Type",
      admin_version_app_down_version_add_select_guest: "GUEST",
      admin_version_app_down_version_add_select_staff: "STAFF",
      admin_version_app_down_version_add_select_valet: "VALET",
      admin_version_app_down_version_add_select_parking_staff: "PARKING STAFF",
      admin_version_app_down_version_add_label_version: "Version",
      admin_version_app_down_version_add_label_link: "Link",
      admin_version_app_down_version_add_button_go_to_list: "Go to list",
      admin_version_app_down_version_add_button_add: "Add",
      admin_version_guest_app_version_list_title: "GUEST APPLICATION VERSION LIST",
      admin_version_guest_app_version_list_placeholiser_search: "Search...",
      admin_version_guest_app_version_list_label_no: "No.",
      admin_version_guest_app_version_list_label_os: "OS",
      admin_version_guest_app_version_list_label_version: "Version",
      admin_version_guest_app_version_list_label_link: "Link",
      admin_version_guest_app_version_list_label_created_at: "Created At",
      admin_version_guest_app_version_list_button_delete: "Delete",
      admin_version_guest_app_version_list_button_add: "Add",
      admin_version_guest_app_version_list_guide_empty: "List is empty",
      admin_version_guest_app_version_add_title: "ADD GUEST VERSION",
      admin_version_guest_app_version_add_validation_os: "* Please select OS.",
      admin_version_guest_app_version_add_validation_version: "* Please enter the version.",
      admin_version_guest_app_version_add_validation_link: "* Please enter the link.",
      admin_version_guest_app_version_add_label_os: "OS",
      admin_version_guest_app_version_add_placeholder_os: "OS",
      admin_version_guest_app_version_add_select_ios: "iOS",
      admin_version_guest_app_version_add_select_aos: "AOS",
      admin_version_guest_app_version_add_label_version: "Version",
      admin_version_guest_app_version_add_label_link: "Link",
      admin_version_guest_app_version_add_button_go_to_list: "Go to list",
      admin_version_guest_app_version_add_button_add: "Add",
      admin_version_staff_app_version_list_title: "STAFF APPLICATION VERSION LIST",
      admin_version_staff_app_version_add_validation_os: "* Please select OS.",
      admin_version_staff_app_version_add_validation_version: "* Please enter the version.",
      admin_version_staff_app_version_add_validation_link: "* Please enter the link.",
      admin_version_staff_app_version_list_placeholiser_search: "Search...",
      admin_version_staff_app_version_list_label_no: "No.",
      admin_version_staff_app_version_list_label_os: "OS",
      admin_version_staff_app_version_list_label_version: "Version",
      admin_version_staff_app_version_list_label_link: "Link",
      admin_version_staff_app_version_list_label_created_at: "Created At",
      admin_version_staff_app_version_list_button_delete: "Delete",
      admin_version_staff_app_version_list_button_add: "Add",
      admin_version_staff_app_version_list_guide_empty: "List is empty",
      admin_version_staff_app_version_add_title: "ADD STAFF VERSION",
      admin_version_staff_app_version_add_label_os: "OS",
      admin_version_staff_app_version_add_placeholder_os: "OS",
      admin_version_staff_app_version_add_select_ios: "iOS",
      admin_version_staff_app_version_add_select_aos: "AOS",
      admin_version_staff_app_version_add_label_version: "Version",
      admin_version_staff_app_version_add_label_link: "Link",
      admin_version_staff_app_version_add_button_go_to_list: "Go to list",
      admin_version_staff_app_version_add_button_add: "Add",
      admin_version_parking_staff_app_version_list_title: "PARKING STAFF APPLICATION DOWNLOAD LIST",
      admin_version_parking_staff_app_version_add_validation_os: "* Please select OS.",
      admin_version_parking_staff_app_version_add_validation_version: "* Please enter the version.",
      admin_version_parking_staff_app_version_add_validation_link: "* Please enter the link.",
      admin_version_parking_staff_app_version_list_placeholiser_search: "Search...",
      admin_version_parking_staff_app_version_list_label_no: "No.",
      admin_version_parking_staff_app_version_list_label_os: "OS",
      admin_version_parking_staff_app_version_list_label_version: "Version",
      admin_version_parking_staff_app_version_list_label_link: "Link",
      admin_version_parking_staff_app_version_list_label_created_at: "Created At",
      admin_version_parking_staff_app_version_list_button_delete: "Delete",
      admin_version_parking_staff_app_version_list_button_add: "Add",
      admin_version_parking_staff_app_version_add_title: "ADD PARKING STAFF DOWNLOAD",
      admin_version_parking_staff_app_version_add_label_os: "OS",
      admin_version_parking_staff_app_version_add_placeholder_os: "OS",
      admin_version_parking_staff_app_version_add_select_ios: "iOS",
      admin_version_parking_staff_app_version_add_select_aos: "AOS",
      admin_version_parking_staff_app_version_add_label_version: "Version",
      admin_version_parking_staff_app_version_add_label_link: "Link",
      admin_version_parking_staff_app_version_add_button_go_to_list: "Go to list",
      admin_version_parking_staff_app_version_add_button_add: "Add",
      admin_application_translation_list_title: "APPLICATION TRANSLATION LIST",
      admin_application_translation_list_select_application_type: "Application Type ( ALL )",
      admin_application_translation_list_select_application_type_guest: "Guest",
      admin_application_translation_list_select_application_type_staff: "Staff",
      admin_application_translation_list_select_application_type_parking_staff: "Parking Staff",
      admin_application_translation_list_select_os_type: "OS Type ( ALL )",
      admin_application_translation_list_select_os_type_aos: "AOS",
      admin_application_translation_list_select_os_type_ios: "iOS",
      admin_application_translation_list_placehoder_application_version: "Application Version",
      admin_application_translation_list_placehoder_file_name: "File Name",
      admin_application_translation_list_label_application_type: "Application Type",
      admin_application_translation_list_label_os: "OS",
      admin_application_translation_list_label_application_version: "Application Version",
      admin_application_translation_list_label_version: "Version",
      admin_application_translation_list_label_file_name: "File Name",
      admin_application_translation_list_label_date: "Date",
      admin_application_translation_list_button_delete: "Delete",
      admin_application_translation_list_button_add: "Add",
      admin_application_translation_list_guide_empty: "List is empty",
      admin_application_translation_add_title: "ADD APPLICATION TRANSLATION",
      admin_application_translation_add_validation_application_type: "* Please select an application type.",
      admin_application_translation_add_validation_os_type: "* Please select OS type.",
      admin_application_translation_add_validation_application_version: "* Please enter Application Version.",
      admin_application_translation_add_validation_version: "* Please enter the version.",
      admin_application_translation_add_validation_translation_file: "* Please enter the translation file.",
      admin_application_translation_add_label_admin_application_type: "Application Type",
      admin_application_translation_add_placeholder_admin_application_type: "Application Type",
      admin_application_translation_add_select_admin_application_type_guest: "Guest",
      admin_application_translation_add_select_admin_application_type_staff: "Staff",
      admin_application_translation_add_select_admin_application_type_parking_staff: "Parking Staff",
      admin_application_translation_add_label_os_type: "OS Type",
      admin_application_translation_add_placeholder_os_type: "OS Type",
      admin_application_translation_add_select_os_type_aos: "AOS",
      admin_application_translation_add_select_os_type_ios: "iOS",
      admin_application_translation_add_label_admin_application_version: "Application Version",
      admin_application_translation_add_label_version: "Version",
      admin_application_translation_add_label_translation_file: "Translation File",
      admin_application_translation_add_button_upload: "Upload",
      admin_application_translation_add_placeholder_upload: "ex) Translation.json",
      admin_application_translation_add_button_go_to_list: "Go to list",
      admin_application_translation_add_button_add: "Add",
      admin_application_translation_detail_title: "APPLICATION TRANSLATION DETAILS",
      admin_application_translation_detail_label_admin_application_type: "Application Type",
      admin_application_translation_detail_placeholder_select_box_choose: "Select",
      admin_application_translation_detail_select_admin_application_type_guest: "Guest",
      admin_application_translation_detail_select_admin_application_type_staff: "Staff",
      admin_application_translation_detail_label_os_type: "OS Type",
      admin_application_translation_detail_select_os_type_aos: "AOS",
      admin_application_translation_detail_select_os_type_ios: "iOS",
      admin_application_translation_detail_label_admin_application_version: "Application Version",
      admin_application_translation_detail_label_version: "Version",
      admin_application_translation_detail_label_file_name: "File Name",
      admin_application_translation_detail_label_date: "Date",
      admin_application_translation_detail_button_edit: "Edit",
      admin_application_translation_detail_button_go_to_list: "Go to list",
      admin_application_translation_detail_button_download: "Download",
      admin_application_translation_edit_title: "APPLICATION TRANSLATION DETAIL",
      admin_application_translation_edit_button_download: "Download",
      admin_application_translation_edit_validation_application_type: "* Please select an application type.",
      admin_application_translation_edit_validation_os_type: "* Please select OS type.",
      admin_application_translation_edit_validation_application_version: "* Please enter Application Version.",
      admin_application_translation_edit_validation_version: "* Please enter the version.",
      admin_application_translation_edit_validation_translation_file: "* Please enter the translation file.",
      admin_application_translation_edit_label_admin_application_type: "Application Type",
      admin_application_translation_edit_placeholder_select_box_choose: "Select",
      admin_application_translation_edit_select_admin_application_type_guest: "Guest",
      admin_application_translation_edit_select_admin_application_type_staff: "Staff",
      admin_application_translation_edit_label_os_type: "OS Type",
      admin_application_translation_edit_select_os_type_aos: "AOS",
      admin_application_translation_edit_select_os_type_ios: "iOS",
      admin_application_translation_edit_label_admin_application_version: "Application Version",
      admin_application_translation_edit_label_version: "Version",
      admin_application_translation_edit_label_file_name: "File Name",
      admin_application_translation_edit_label_date: "Date",
      admin_application_translation_edit_button_upload: "Upload",
      admin_application_translation_edit_button_ok: "OK",
      admin_application_translation_edit_button_cancel: "Cancel",
      admin_booking_email_detail_label_format: "Email Image Format",
      admin_booking_email_detail_label_image: "Email Image",
      admin_booking_email_detail_button_upload: "Upload",
      admin_booking_email_detail_placeholder_upload: "ex) MyCONECT.png",
      admin_booking_email_detail_button_edit: "Edit",
      admin_email_form_change_password_edit_title: "CHANGE PASSWORD",
      admin_email_form_change_password_edit_validation_content: "* Please enter your details.",
      admin_email_form_change_password_edit_select_kr: "KR",
      admin_email_form_change_password_edit_select_en: "EN",
      admin_email_form_change_password_edit_select_cn: "CN",
      admin_email_form_change_password_edit_select_jp: "JP",
      admin_email_form_change_password_edit_select_ms: "MS",
      admin_email_form_change_password_edit_button_remove: "Remove",
      admin_email_form_change_password_edit_button_append: "Append",
      admin_email_form_change_password_edit_button_save: "Save",
      admin_email_form_change_password_edit_toolbar_bold: "Bold",
      admin_email_form_change_password_edit_toolbar_italic: "Italic",
      admin_email_form_change_password_edit_toolbar_underline: "Underline",
      admin_email_form_change_password_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_change_password_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_change_password_edit_toolbar_font_family: "Font Family",
      admin_email_form_change_password_edit_toolbar_font_size: "Font Size",
      admin_email_form_change_password_edit_toolbar_text_color: "Text Color",
      admin_email_form_change_password_edit_toolbar_text_more_color: "More Color",
      admin_email_form_change_password_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_change_password_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_change_password_edit_toolbar_table: "Table",
      admin_email_form_change_password_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_change_password_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_change_password_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_change_password_edit_toolbar_line_height: "Line Height",
      admin_email_form_change_password_edit_toolbar_picture: "Picture",
      admin_email_form_change_password_edit_toolbar_code_view: "Code View",
      admin_email_form_change_password_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_change_password_edit_toolbar_help: "Help",
      admin_email_form_change_password_edit_toolbar_tip: "Tip",
      admin_email_form_change_password_edit_placeholder_content: "Please enter the content.",
      admin_email_form_forgot_id_edit_title: "FORGOT ID",
      admin_email_form_forgot_id_edit_validation_content: "* Please enter your details.",
      admin_email_form_forgot_id_edit_select_kr: "KR",
      admin_email_form_forgot_id_edit_select_en: "EN",
      admin_email_form_forgot_id_edit_select_cn: "CN",
      admin_email_form_forgot_id_edit_select_jp: "JP",
      admin_email_form_forgot_id_edit_select_ms: "MS",
      admin_email_form_forgot_id_edit_button_remove: "Remove",
      admin_email_form_forgot_id_edit_button_append: "Append",
      admin_email_form_forgot_id_edit_button_save: "Save",
      admin_email_form_forgot_id_edit_toolbar_bold: "Bold",
      admin_email_form_forgot_id_edit_toolbar_italic: "Italic",
      admin_email_form_forgot_id_edit_toolbar_underline: "Underline",
      admin_email_form_forgot_id_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_forgot_id_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_forgot_id_edit_toolbar_font_family: "Font Family",
      admin_email_form_forgot_id_edit_toolbar_font_size: "Font Size",
      admin_email_form_forgot_id_edit_toolbar_text_color: "Text Color",
      admin_email_form_forgot_id_edit_toolbar_text_more_color: "More Color",
      admin_email_form_forgot_id_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_forgot_id_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_forgot_id_edit_toolbar_table: "Table",
      admin_email_form_forgot_id_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_forgot_id_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_forgot_id_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_forgot_id_edit_toolbar_line_height: "Line Height",
      admin_email_form_forgot_id_edit_toolbar_picture: "Picture",
      admin_email_form_forgot_id_edit_toolbar_code_view: "Code View",
      admin_email_form_forgot_id_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_forgot_id_edit_toolbar_help: "Help",
      admin_email_form_forgot_id_edit_toolbar_tip: "Tip",
      admin_email_form_forgot_id_edit_placeholder_content: "Please enter the content.",
      admin_email_form_forgot_id_detail_title: "FORGOT ID CHARACTER",
      admin_email_form_forgot_id_detail_label_user_info: "User Information",
      admin_email_form_forgot_id_detail_label_example_user_info: "{{userInfo}}",
      admin_email_form_forgot_password_edit_title: "FORGOT PASSWORD",
      admin_email_form_forgot_password_edit_validation_content: "* Please enter your details.",
      admin_email_form_forgot_password_edit_select_kr: "KR",
      admin_email_form_forgot_password_edit_select_en: "EN",
      admin_email_form_forgot_password_edit_select_cn: "CN",
      admin_email_form_forgot_password_edit_select_jp: "JP",
      admin_email_form_forgot_password_edit_select_ms: "MS",
      admin_email_form_forgot_password_edit_button_remove: "Remove",
      admin_email_form_forgot_password_edit_button_append: "Append",
      admin_email_form_forgot_password_edit_button_save: "Save",
      admin_email_form_forgot_password_edit_toolbar_bold: "Bold",
      admin_email_form_forgot_password_edit_toolbar_italic: "Italic",
      admin_email_form_forgot_password_edit_toolbar_underline: "Underline",
      admin_email_form_forgot_password_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_forgot_password_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_forgot_password_edit_toolbar_font_family: "Font Family",
      admin_email_form_forgot_password_edit_toolbar_font_size: "Font Size",
      admin_email_form_forgot_password_edit_toolbar_text_color: "Text Color",
      admin_email_form_forgot_password_edit_toolbar_text_more_color: "More Color",
      admin_email_form_forgot_password_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_forgot_password_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_forgot_password_edit_toolbar_table: "Table",
      admin_email_form_forgot_password_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_forgot_password_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_forgot_password_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_forgot_password_edit_toolbar_line_height: "Line Height",
      admin_email_form_forgot_password_edit_toolbar_picture: "Picture",
      admin_email_form_forgot_password_edit_toolbar_code_view: "Code View",
      admin_email_form_forgot_password_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_forgot_password_edit_toolbar_help: "Help",
      admin_email_form_forgot_password_edit_toolbar_tip: "Tip",
      admin_email_form_forgot_password_edit_placeholder_content: "Please enter the content.",
      admin_email_form_forgot_password_detail_title: "FORGOT PASSWORD CHARACTER",
      admin_email_form_forgot_password_detail_label_email: "Email",
      admin_email_form_forgot_password_detail_label_example_email: "{{email}}",
      admin_email_form_forgot_password_detail_label_password: "Password",
      admin_email_form_forgot_password_detail_label_example_password: "{{password}}",
      admin_email_form_create_confirm_edit_title: "CREATE E-mail CONFIRM",
      admin_email_form_create_confirm_edit_validation_content: "* Please enter your details.",
      admin_email_form_create_confirm_edit_select_kr: "KR",
      admin_email_form_create_confirm_edit_select_en: "EN",
      admin_email_form_create_confirm_edit_select_cn: "CN",
      admin_email_form_create_confirm_edit_select_jp: "JP",
      admin_email_form_create_confirm_edit_select_ms: "MS",
      admin_email_form_create_confirm_edit_button_remove: "Remove",
      admin_email_form_create_confirm_edit_button_append: "Append",
      admin_email_form_create_confirm_edit_button_save: "Save",
      admin_email_form_create_confirm_edit_toolbar_bold: "Bold",
      admin_email_form_create_confirm_edit_toolbar_italic: "Italic",
      admin_email_form_create_confirm_edit_toolbar_underline: "Underline",
      admin_email_form_create_confirm_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_create_confirm_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_create_confirm_edit_toolbar_font_family: "Font Family",
      admin_email_form_create_confirm_edit_toolbar_font_size: "Font Size",
      admin_email_form_create_confirm_edit_toolbar_text_color: "Text Color",
      admin_email_form_create_confirm_edit_toolbar_text_more_color: "More Color",
      admin_email_form_create_confirm_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_create_confirm_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_create_confirm_edit_toolbar_table: "Table",
      admin_email_form_create_confirm_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_create_confirm_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_create_confirm_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_create_confirm_edit_toolbar_line_height: "Line Height",
      admin_email_form_create_confirm_edit_toolbar_picture: "Picture",
      admin_email_form_create_confirm_edit_toolbar_code_view: "Code View",
      admin_email_form_create_confirm_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_create_confirm_edit_toolbar_help: "Help",
      admin_email_form_create_confirm_edit_toolbar_tip: "Tip",
      admin_email_form_create_confirm_edit_placeholder_content: "Please enter the content.",
      admin_email_form_create_confirm_detail_title: "CREATE CONFIRM CHARACTER",
      admin_email_form_create_confirm_detail_label_url: "URL",
      admin_email_form_create_confirm_detail_example_url: "{{url}}",
      admin_email_form_create_confirm_failure_edit_title: "CONFIRM EMAIL FAILED",
      admin_email_form_create_confirm_failure_edit_validation_content: "* Please enter your details.",
      admin_email_form_create_confirm_failure_edit_select_kr: "KR",
      admin_email_form_create_confirm_failure_edit_select_en: "EN",
      admin_email_form_create_confirm_failure_edit_select_cn: "CN",
      admin_email_form_create_confirm_failure_edit_select_jp: "JP",
      admin_email_form_create_confirm_failure_edit_select_ms: "MS",
      admin_email_form_create_confirm_failure_edit_button_remove: "Remove",
      admin_email_form_create_confirm_failure_edit_button_append: "Append",
      admin_email_form_create_confirm_failure_edit_button_save: "Save",
      admin_email_form_create_confirm_failure_edit_toolbar_bold: "Bold",
      admin_email_form_create_confirm_failure_edit_toolbar_italic: "Italic",
      admin_email_form_create_confirm_failure_edit_toolbar_underline: "Underline",
      admin_email_form_create_confirm_failure_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_create_confirm_failure_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_create_confirm_failure_edit_toolbar_font_family: "Font Family",
      admin_email_form_create_confirm_failure_edit_toolbar_font_size: "Font Size",
      admin_email_form_create_confirm_failure_edit_toolbar_text_color: "Text Color",
      admin_email_form_create_confirm_failure_edit_toolbar_text_more_color: "More Color",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_create_confirm_failure_edit_toolbar_table: "Table",
      admin_email_form_create_confirm_failure_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_create_confirm_failure_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_create_confirm_failure_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_create_confirm_failure_edit_toolbar_line_height: "Line Height",
      admin_email_form_create_confirm_failure_edit_toolbar_picture: "Picture",
      admin_email_form_create_confirm_failure_edit_toolbar_code_view: "Code View",
      admin_email_form_create_confirm_failure_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_create_confirm_failure_edit_toolbar_help: "Help",
      admin_email_form_create_confirm_failure_edit_toolbar_tip: "Tip",
      admin_email_form_create_confirm_failure_edit_placeholder_content: "Please enter the content.",
      admin_email_form_create_confirm_failure_detail_title: "CREATE EMAIL FAILURE CONTENTS",
      admin_email_form_create_confirm_failure_detail_label_reason: "Reason",
      admin_email_form_create_confirm_failure_detail_label_example_reason: "{{reason}}",
      admin_email_form_create_confirm_success_edit_title: "CREATE CONFIRM EMAIL SUCCESS",
      admin_email_form_create_confirm_success_edit_validation_content: "* Please enter your details.",
      admin_email_form_create_confirm_success_edit_select_kr: "KR",
      admin_email_form_create_confirm_success_edit_select_en: "EN",
      admin_email_form_create_confirm_success_edit_select_cn: "CN",
      admin_email_form_create_confirm_success_edit_select_jp: "JP",
      admin_email_form_create_confirm_success_edit_select_ms: "MS",
      admin_email_form_create_confirm_success_edit_button_remove: "Remove",
      admin_email_form_create_confirm_success_edit_button_append: "Append",
      admin_email_form_create_confirm_success_edit_button_save: "Save",
      admin_email_form_create_confirm_success_edit_toolbar_bold: "Bold",
      admin_email_form_create_confirm_success_edit_toolbar_italic: "Italic",
      admin_email_form_create_confirm_success_edit_toolbar_underline: "Underline",
      admin_email_form_create_confirm_success_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_create_confirm_success_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_create_confirm_success_edit_toolbar_font_family: "Font Family",
      admin_email_form_create_confirm_success_edit_toolbar_font_size: "Font Size",
      admin_email_form_create_confirm_success_edit_toolbar_text_color: "Text Color",
      admin_email_form_create_confirm_success_edit_toolbar_text_more_color: "More Color",
      admin_email_form_create_confirm_success_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_create_confirm_success_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_create_confirm_success_edit_toolbar_table: "Table",
      admin_email_form_create_confirm_success_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_create_confirm_success_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_create_confirm_success_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_create_confirm_success_edit_toolbar_line_height: "Line Height",
      admin_email_form_create_confirm_success_edit_toolbar_picture: "Picture",
      admin_email_form_create_confirm_success_edit_toolbar_code_view: "Code View",
      admin_email_form_create_confirm_success_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_create_confirm_success_edit_toolbar_help: "Help",
      admin_email_form_create_confirm_success_edit_toolbar_tip: "Tip",
      admin_email_form_create_confirm_success_edit_placeholder_content: "Please enter the content.",
      admin_email_form_update_profile_edit_title: "UPDATE PROFILE",
      admin_email_form_update_profile_edit_validation_content: "* Please enter your details.",
      admin_email_form_update_profile_edit_select_kr: "KR",
      admin_email_form_update_profile_edit_select_en: "EN",
      admin_email_form_update_profile_edit_select_cn: "CN",
      admin_email_form_update_profile_edit_select_jp: "JP",
      admin_email_form_update_profile_edit_select_ms: "MS",
      admin_email_form_update_profile_edit_button_remove: "Remove",
      admin_email_form_update_profile_edit_button_append: "Append",
      admin_email_form_update_profile_edit_button_save: "Save",
      admin_email_form_update_profile_edit_toolbar_bold: "Bold",
      admin_email_form_update_profile_edit_toolbar_italic: "Italic",
      admin_email_form_update_profile_edit_toolbar_underline: "Underline",
      admin_email_form_update_profile_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_update_profile_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_update_profile_edit_toolbar_font_family: "Font Family",
      admin_email_form_update_profile_edit_toolbar_font_size: "Font Size",
      admin_email_form_update_profile_edit_toolbar_text_color: "Text Color",
      admin_email_form_update_profile_edit_toolbar_text_more_color: "More Color",
      admin_email_form_update_profile_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_update_profile_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_update_profile_edit_toolbar_table: "Table",
      admin_email_form_update_profile_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_update_profile_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_update_profile_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_update_profile_edit_toolbar_line_height: "Line Height",
      admin_email_form_update_profile_edit_toolbar_picture: "Picture",
      admin_email_form_update_profile_edit_toolbar_code_view: "Code View",
      admin_email_form_update_profile_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_update_profile_edit_toolbar_help: "Help",
      admin_email_form_update_profile_edit_toolbar_tip: "Tip",
      admin_email_form_update_profile_edit_placeholder_content: "Please enter the content.",
      admin_email_form_withdraw_edit_title: "WITHDRAW",
      admin_email_form_withdraw_edit_validation_content: "* Please enter your details.",
      admin_email_form_withdraw_edit_select_kr: "KR",
      admin_email_form_withdraw_edit_select_en: "EN",
      admin_email_form_withdraw_edit_select_cn: "CN",
      admin_email_form_withdraw_edit_select_jp: "JP",
      admin_email_form_withdraw_edit_select_ms: "MS",
      admin_email_form_withdraw_edit_button_remove: "Remove",
      admin_email_form_withdraw_edit_button_append: "Append",
      admin_email_form_withdraw_edit_button_save: "Save",
      admin_email_form_withdraw_edit_toolbar_bold: "Bold",
      admin_email_form_withdraw_edit_toolbar_italic: "Italic",
      admin_email_form_withdraw_edit_toolbar_underline: "Underline",
      admin_email_form_withdraw_edit_toolbar_strikethrough: "Strikethrough",
      admin_email_form_withdraw_edit_toolbar_remove_font_style: "Remove Font Style",
      admin_email_form_withdraw_edit_toolbar_font_family: "Font Family",
      admin_email_form_withdraw_edit_toolbar_font_size: "Font Size",
      admin_email_form_withdraw_edit_toolbar_text_color: "Text Color",
      admin_email_form_withdraw_edit_toolbar_text_more_color: "More Color",
      admin_email_form_withdraw_edit_toolbar_recent_color: "Recent Color",
      admin_email_form_withdraw_edit_toolbar_recent_more_color: "More Color",
      admin_email_form_withdraw_edit_toolbar_table: "Table",
      admin_email_form_withdraw_edit_toolbar_unordered_list: "Unordered list",
      admin_email_form_withdraw_edit_toolbar_ordered_list: "Ordered list",
      admin_email_form_withdraw_edit_toolbar_paragraph: "Paragraph",
      admin_email_form_withdraw_edit_toolbar_line_height: "Line Height",
      admin_email_form_withdraw_edit_toolbar_picture: "Picture",
      admin_email_form_withdraw_edit_toolbar_code_view: "Code View",
      admin_email_form_withdraw_edit_toolbar_full_screen: "Full Screen",
      admin_email_form_withdraw_edit_toolbar_help: "Help",
      admin_email_form_withdraw_edit_toolbar_tip: "Tip",
      admin_email_form_withdraw_edit_placeholder_content: "Please enter the content.",
      admin_endpoint_list_title: "APPLICATION ENDPOINT",
      admin_endpoint_list_placeholiser_search: "Search...",
      admin_endpoint_list_label_package_name: "Package Name",
      admin_endpoint_list_label_os: "OS",
      admin_endpoint_list_label_version: "Version",
      admin_endpoint_list_label_url: "URL",
      admin_endpoint_list_label_created_at: "Created At",
      admin_endpoint_list_guide_empty: "List is empty",
      admin_endpoint_list_button_delete: "Delete",
      admin_endpoint_list_button_add: "Add",
      admin_endpoint_add_title: "ADD APPLICATION ENDPOINT",
      admin_endpoint_add_validation_package_name: "* Please select a package name.",
      admin_endpoint_add_validation_os: "* Please select an OS.",
      admin_endpoint_add_validation_version: "* Please enter the version.",
      admin_endpoint_add_validation_url: "* Please select a url.",
      admin_endpoint_add_label_package_name: "Package Name",
      admin_endpoint_add_placeholder_choose: "Choose",
      admin_endpoint_add_select_os_staff: "Staff",
      admin_endpoint_add_select_os_guest: "Guest",
      admin_endpoint_add_select_os_staff_parking: "Staff Parking",
      admin_endpoint_add_label_os: "OS",
      admin_endpoint_add_select_os_ios: "iOS",
      admin_endpoint_add_select_os_aos: "AOS",
      admin_endpoint_add_label_version: "Version",
      admin_endpoint_add_label_url: "URL",
      admin_endpoint_add_button_go_to_list: "Go to list",
      admin_endpoint_add_button_add: "Add",
      admin_endpoint_detail_title: "APPLICATION ENDPOINT INFORMATION",
      admin_endpoint_detail_label_package_name: "Package Name",
      admin_endpoint_detail_placeholder_choose: "Choose",
      admin_endpoint_detail_select_os_staff: "Staff",
      admin_endpoint_detail_select_os_guest: "Guest",
      admin_endpoint_detail_select_os_staff_parking: "Staff Parking",
      admin_endpoint_detail_label_os: "OS",
      admin_endpoint_detail_select_os_ios: "iOS",
      admin_endpoint_detail_select_os_aos: "AOS",
      admin_endpoint_detail_label_version: "Version",
      admin_endpoint_detail_label_url: "URL",
      admin_endpoint_detail_label_create_at: "Created At",
      admin_endpoint_detail_button_go_to_list: "Go to list",
      admin_endpoint_detail_button_delete: "Delete",
      admin_endpoint_detail_button_edit: "Edit",
      admin_endpoint_edit_title: "APPLICATION ENDPOINT INFORMATION",
      admin_endpoint_edit_validation_package_name: "* Please select a package name.",
      admin_endpoint_edit_validation_os: "* Please select an OS.",
      admin_endpoint_edit_validation_version: "* Please enter the version.",
      admin_endpoint_edit_validation_url: "* Please select a url.",
      admin_endpoint_edit_label_package_name: "Package Name",
      admin_endpoint_edit_placeholder_choose: "Choose",
      admin_endpoint_edit_select_os_staff: "Staff",
      admin_endpoint_edit_select_os_guest: "Guest",
      admin_endpoint_edit_select_os_staff_parking: "Staff Parking",
      admin_endpoint_edit_label_os: "OS",
      admin_endpoint_edit_select_os_ios: "iOS",
      admin_endpoint_edit_select_os_aos: "AOS",
      admin_endpoint_edit_label_version: "Version",
      admin_endpoint_edit_label_url: "URL",
      admin_endpoint_edit_label_create_at: "Created At",
      admin_endpoint_edit_button_cancel: "Cancel",
      admin_endpoint_edit_button_delete: "Delete",
      admin_endpoint_edit_button_ok: "OK",
      admin_terms_list_title: "TERMS LIST",
      admin_terms_list_placeholder_search: "Search...",
      admin_terms_list_label_language_code: "Language Code",
      admin_terms_list_label_file_name: "File Name",
      admin_terms_list_label_required: "Required",
      admin_terms_list_label_title: "Title",
      admin_terms_list_label_terms_group_id: "Terms Group ID",
      admin_terms_list_label_created_at: "Created At",
      admin_terms_list_guide_empty: "List is empty",
      admin_terms_list_button_delete: "Delete",
      admin_terms_list_button_add: "Add",
      admin_terms_add_title: "ADD TERMS",
      admin_terms_add_validation_title: "* Please enter the title.",
      admin_terms_add_validation_terms_group_id: "* Please select_x0008_a terms group ID.",
      admin_terms_add_validation_language_code: "* Please enter the language code.",
      admin_terms_add_validation_required: "* Please select whether required or not.",
      admin_terms_add_validation_description: "* Please enter a description.",
      admin_terms_add_validation_link_text: "* Please enter link text.",
      admin_terms_add_validation_file: "* Please upload the file.",
      admin_terms_add_label_title: "Title",
      admin_terms_add_label_terms_group_id: "Terms Group ID",
      admin_terms_add_label_choose: "Choose",
      admin_terms_add_label_language_code: "Language Code",
      admin_terms_add_label_required: "Required",
      admin_terms_add_button_yes: "Yse",
      admin_terms_add_button_no: "No",
      admin_terms_add_label_description: "Description",
      admin_terms_add_label_link_text: "Link Text",
      admin_terms_add_label_file_name: "File Name",
      admin_terms_add_label_file_url: "File URL",
      admin_terms_add_button_upload: "Upload",
      admin_terms_add_button_go_to_list: "Go to list",
      admin_terms_add_button_add: "Add",
      admin_terms_detail_title: "TERMS INFORMATION",
      admin_terms_detail_label_title: "Title",
      admin_terms_detail_label_language_code: "Language Code",
      admin_terms_detail_label_description: "Description",
      admin_terms_detail_label_link_text: "Link Text",
      admin_terms_detail_label_required: "Required",
      admin_terms_detail_button_yes: "Yes",
      admin_terms_detail_button_no: "No",
      admin_terms_detail_label_terms_group_id: "Terms Group ID",
      admin_terms_detail_label_choose: "Choose",
      admin_terms_detail_label_file_name: "File Name",
      admin_terms_detail_label_file_url: "File URL",
      admin_terms_detail_button_upload: "Upload",
      admin_terms_detail_label_created_at: "Created At",
      admin_terms_detail_label_updated_at: "Updated At",
      admin_terms_detail_button_go_to_list: "Go to list",
      admin_terms_detail_button_edit: "Edit",
      admin_terms_edit_title: "TERMS INFORMATION",
      admin_terms_edit_validation_title: "* Please enter the title.",
      admin_terms_edit_validation_terms_group_id: "* Please select a terms group ID.",
      admin_terms_edit_validation_language_code: "* Please enter the language code.",
      admin_terms_edit_validation_required: "* Please select whether required or not.",
      admin_terms_edit_validation_description: "* Please enter a description.",
      admin_terms_edit_validation_link_text: "* Please enter link text.",
      admin_terms_edit_validation_file: "* Please upload the file.",
      admin_terms_edit_label_title: "Title",
      admin_terms_edit_label_language_code: "Language Code",
      admin_terms_edit_label_description: "Description",
      admin_terms_edit_label_link_text: "Link Text",
      admin_terms_edit_label_required: "Required",
      admin_terms_edit_button_yes: "Yes",
      admin_terms_edit_button_no: "No",
      admin_terms_edit_label_terms_group_id: "Terms Group ID",
      admin_terms_edit_label_choose: "Choose",
      admin_terms_edit_label_file_name: "File Name",
      admin_terms_edit_label_file_url: "File URL",
      admin_terms_edit_button_upload: "Upload",
      admin_terms_edit_label_created_at: "Created At",
      admin_terms_edit_label_updated_at: "Updated At",
      admin_terms_edit_button_cancel: "Cancel",
      admin_terms_edit_button_ok: "OK",
      admin_terms_group_list_title: "TERMS GROUP LIST",
      admin_terms_group_list_placeholder_search: "Search...",
      admin_terms_group_list_label_title: "Title",
      admin_terms_group_list_label_country_code: "Country Code",
      admin_terms_group_list_label_created_at: "Created At",
      admin_terms_group_list_guide_empty: "List is empty",
      admin_terms_group_list_button_delete: "Delete",
      admin_terms_group_list_button_add: "Add",
      admin_terms_group_add_title: "ADD TERMS GROUP",
      admin_terms_group_add_validation_title: "* Please enter the title.",
      admin_terms_group_add_validation_country_code: "* Please enter the country code.",
      admin_terms_group_add_label_title: "Title",
      admin_terms_group_add_label_country_code: "Country Code",
      admin_terms_group_add_button_go_to_list: "Go to list",
      admin_terms_group_add_button_add: "Add",
      admin_terms_group_detail_title: "TERMS GROUP INFORMATION",
      admin_terms_group_detail_label_title: "Title",
      admin_terms_group_detail_label_country_code: "Country Code",
      admin_terms_group_detail_label_created_at: "Created At",
      admin_terms_group_detail_label_updated_at: "Updated At",
      admin_terms_group_detail_button_go_to_list: "Go to list",
      admin_terms_group_detail_button_edit: "Edit",
      admin_terms_group_edit_title: "TERMS INFORMATION",
      admin_terms_group_edit_validation_title: "* Please enter the title.",
      admin_terms_group_edit_validation_country_code: "* Please enter the country code.",
      admin_terms_group_edit_label_title: "Title",
      admin_terms_group_edit_label_country_code: "Country Code",
      admin_terms_group_edit_label_created_at: "Created At",
      admin_terms_group_edit_label_updated_at: "Updated At",
      admin_terms_group_edit_button_cancel: "Cancel",
      admin_terms_group_edit_button_ok: "OK",
      admin_agreement_list_title: "AGREEMENT HISTORY LIST",
      admin_agreement_list_placeholder_email: "Please enter your Email.",
      admin_agreement_list_placeholder_search: "Search...",
      admin_agreement_list_label_no: "NO.",
      admin_agreement_list_label_user_id: "User ID",
      admin_agreement_list_label_terms_id: "Terms ID",
      admin_agreement_list_label_agreed: "Agreed",
      admin_agreement_list_label_email: "Email",
      admin_agreement_list_label_name: "Name",
      admin_agreement_list_label_file_name: "File Name",
      admin_agreement_list_label_created_at: "Created At",
      admin_agreement_detail_title: "AGREEMENT HISTORY INFORMATION",
      admin_agreement_detail_label_agreement: "Agreement",
      admin_agreement_detail_label_agreed: "Agreed",
      admin_agreement_detail_label_agreement_created_at: "Agreement Confirmed At",
      admin_agreement_detail_label_user: "User",
      admin_agreement_detail_label_user_id: "User ID",
      admin_agreement_detail_label_email: "Email",
      admin_agreement_detail_label_name: "Name",
      admin_agreement_detail_label_email_confimed_at: "Email Confimed At",
      admin_agreement_detail_label_user_language_code: "User Language Code",
      admin_agreement_detail_label_user_created_at: "User Created At",
      admin_agreement_detail_label_terms: "Terms",
      admin_agreement_detail_label_terms_id: "Terms ID",
      admin_agreement_detail_label_terms_language_code: "Terms Language Code",
      admin_agreement_detail_label_file_name: "File Name",
      admin_agreement_detail_label_required: "Required",
      admin_agreement_detail_label_title: "Title",
      admin_agreement_detail_label_description: "Description",
      admin_agreement_detail_label_link_text: "Link Text",
      admin_agreement_detail_label_terms_group_id: "Terms Group ID",
      admin_agreement_detail_label_terms_created_at: "Terms Created At",
      admin_agreement_detail_button_go_to_list: "Go to list",
      admin_auth_data_list_title: "Data verifying the collection,\n use and provision of personal location information",
      admin_auth_data_list_placeholder_select_box_authentication_data: "Authentication data",
      admin_auth_data_list_select_hotel_id: "Hotel ID",
      admin_auth_data_list_select_email: "Email",
      admin_auth_data_list_label_no: "No.",
      admin_auth_data_list_placeholder_search: "Search...",
      admin_auth_data_list_label_user_email: "Email",
      admin_auth_data_list_label_acquisition_path: "Acquisition Path",
      admin_auth_data_list_label_service: "Service",
      admin_auth_data_list_label_recipient: "Recipient",
      admin_auth_data_list_label_request_date: "Request Date",
      admin_auth_data_list_label_end_date: "End Date",
      admin_auth_data_list_guide_empty: "List is empty",
      admin_use_key_log_list_title: "USE KEY LOG LIST",
      admin_use_key_log_list_placeholder_select_box_use_key_log: "Use key log",
      admin_use_key_log_list_select_hotel_id: "Hotel ID",
      admin_use_key_log_list_select_email: "Email",
      admin_use_key_log_list_placeholder_search: "Search...",
      admin_use_key_log_list_label_no: "No.",
      admin_use_key_log_list_label_user_email: "User Email",
      admin_use_key_log_list_label_device: "Device",
      admin_use_key_log_list_label_app_version: "Application version",
      admin_use_key_log_list_label_hotel_id: "Hotel ID",
      admin_use_key_log_list_label_key_id: "Key ID",
      admin_use_key_log_list_label_date: "Date",
      admin_use_key_log_list_guide_empty: "List is empty",
      admin_use_key_log_detail_title: "USE KEY LOG DETAIL",
      admin_use_key_log_detail_label_use_key_log_id: "Use key log ID",
      admin_use_key_log_detail_label_user_id: "User ID",
      admin_use_key_log_detail_label_user_email: "User Email",
      admin_use_key_log_detail_label_hotel_key_id: "Hotel key ID",
      admin_use_key_log_detail_label_hotel_id: "Hotel ID",
      admin_use_key_log_detail_label_device: "Device",
      admin_use_key_log_detail_label_app_version: "Application version",
      admin_use_key_log_detail_label_log: "Log",
      admin_use_key_log_detail_label_created_at: "Created At",
      admin_use_key_log_detail_label_updated_at: "Updated At",
      admin_use_key_log_detail_button_go_to_list: "Go to list",
      admin_amenity_list_title: "AMENITY LIST",
      admin_amenity_list_placeholder_search: "Search...",
      admin_amenity_list_label_type: "Type",
      admin_amenity_list_label_group: "Group",
      admin_amenity_list_label_code: "Code",
      admin_amenity_list_label_name: "Name",
      admin_amenity_list_label_date: "Date",
      admin_amenity_list_button_delete: "Delete",
      admin_amenity_list_button_add: "Add",
      admin_amenity_list_guide_empty: "List is empty",
      admin_amenity_add_title: "ADD AMENITY",
      admin_amenity_add_validation_type: "* Please select a type.",
      admin_amenity_add_validation_group: "* Please enter a group.",
      admin_amenity_add_validation_code: "* Please enter the code.",
      admin_amenity_add_validation_name: "* Please enter the name.",
      admin_amenity_add_validation_icon_image: "* Please upload an icon image.",
      admin_amenity_add_label_type: "Type",
      admin_amenity_add_placeholder_select_box_choose: "Choose",
      admin_amenity_add_select_hotel: "HOTEL",
      admin_amenity_add_select_room: "ROOM",
      admin_amenity_add_label_group: "Group",
      admin_amenity_add_label_code: "Code",
      admin_amenity_add_label_name: "Name",
      admin_amenity_add_label_icon: "Icon",
      admin_amenity_add_button_upload: "Upload",
      admin_amenity_add_placeholder_upload: "ex) MyCONECT.png",
      admin_amenity_add_button_go_to_list: "Go to list",
      admin_amenity_add_button_add: "Add",
      admin_amenity_detail_title: "AMENITY INFORMATION",
      admin_amenity_detail_label_type: "Type",
      admin_amenity_detail_select_hotel: "HOTEL",
      admin_amenity_detail_select_room: "ROOM",
      admin_amenity_detail_label_group: "Group",
      admin_amenity_detail_label_code: "Code",
      admin_amenity_detail_label_name: "Name",
      admin_amenity_detail_label_icon: "Icon",
      admin_amenity_detail_button_upload: "Upload",
      admin_amenity_detail_placeholder_upload: "ex) MyCONECT.png",
      admin_amenity_detail_label_update_date: "Date Updated",
      admin_amenity_detail_button_go_to_list: "Go to list",
      admin_amenity_detail_button_cancel: "Cancel",
      admin_amenity_detail_button_delete: "Delete",
      admin_amenity_detail_button_edit: "Edit",
      admin_amenity_detail_button_ok: "OK",
      admin_amenity_edit_title: "AMENITY INFORMATION",
      admin_amenity_edit_validation_type: "* Please select a type.",
      admin_amenity_edit_validation_group: "* Please enter a group.",
      admin_amenity_edit_validation_code: "* Please enter the code.",
      admin_amenity_edit_validation_name: "* Please enter the name.",
      admin_amenity_edit_validation_icon_image: "* Please upload an icon image.",
      admin_amenity_edit_label_type: "Type",
      admin_amenity_edit_placeholder_select_box_choose: "Choose",
      admin_amenity_edit_select_hotel: "HOTEL",
      admin_amenity_edit_select_room: "ROOM",
      admin_amenity_edit_label_group: "Group",
      admin_amenity_edit_label_code: "Code",
      admin_amenity_edit_label_name: "Name",
      admin_amenity_edit_label_icon: "Icon",
      admin_amenity_edit_button_upload: "Upload",
      admin_amenity_edit_placeholder_upload: "ex) MyCONECT.png",
      admin_amenity_edit_label_update_date: "Date Updated",
      admin_amenity_edit_button_cancel: "Cancel",
      admin_amenity_edit_button_delete: "Delete",
      admin_amenity_edit_button_ok: "OK",
      admin_facility_img_list_title: "HOTEL FACILITY IMAGE LIST",
      admin_facility_img_list_label_facility_img: "Hotel facility Image",
      admin_facility_img_list_label_description: "Description",
      admin_facility_img_list_label_date: "Date",
      admin_facility_img_list_button_delete: "Delete",
      admin_facility_img_list_button_add: "Add",
      admin_facility_img_list_guide_empty: "List is empty",
      admin_facility_img_add_title: "ADD HOTEL FACILITY IMAGE",
      admin_facility_img_add_validation_image: "* Please upload an image.",
      admin_facility_img_add_validation_description: "* Please enter a description.",
      admin_facility_img_add_label_img: "Image",
      admin_facility_img_add_button_upload: "Upload",
      admin_facility_img_add_label_description: "Description",
      admin_facility_img_add_button_go_to_list: "Go to list",
      admin_facility_img_add_button_add: "Add",
      admin_facility_img_detail_title: "HOTEL FACILITY IMAGE INFORMATION",
      admin_facility_img_detail_label_img: "Image",
      admin_facility_img_detail_button_upload: "Upload",
      admin_facility_img_detail_label_description: "Description",
      admin_facility_img_detail_label_updated_at: "Updated At",
      admin_facility_img_detail_button_go_to_list: "Go to list",
      admin_facility_img_detail_button_cancel: "Cancel",
      admin_facility_img_detail_button_delete: "Delete",
      admin_facility_img_detail_button_edit: "Edit",
      admin_facility_img_detail_button_ok: "OK",
      admin_facility_img_edit_title: "HOTEL FACILITY IMAGE INFORMATION",
      admin_facility_img_edit_validation_image: "* Please upload an image.",
      admin_facility_img_edit_validation_description: "* Please enter a description.",
      admin_facility_img_edit_label_img: "Image",
      admin_facility_img_edit_button_upload: "Upload",
      admin_facility_img_edit_label_description: "Description",
      admin_facility_img_edit_label_updated_at: "Updated At",
      admin_facility_img_edit_button_cancel: "Cancel",
      admin_facility_img_edit_button_delete: "Delete",
      admin_facility_img_edit_button_ok: "OK",
      admin_recommend_app_list_title: "RECOMMEND APP LIST",
      admin_recommend_app_list_select_name: "Name",
      admin_recommend_app_list_placeholder_search: "Search...",
      admin_recommend_app_list_label_name: "Name",
      admin_recommend_app_list_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_list_label_aos_package_id: "AOS Package ID",
      admin_recommend_app_list_label_date: "Date",
      admin_recommend_app_list_button_delete: "Delete",
      admin_recommend_app_list_button_add: "Add",
      admin_recommend_app_list_guide_empty: "List is empty",
      admin_recommend_app_add_title: "ADD RECOMMEND APP",
      admin_recommend_app_add_validation_name: "* Please enter the name.",
      admin_recommend_app_add_validation_ios_scheme: "* Please enter iOS Scheme.",
      admin_recommend_app_add_validation_ios_itunes_url: "* Please enter the iOS iTunes URL.",
      admin_recommend_app_add_validation_aos_package_id: "* Please enter the AOS package ID.",
      admin_recommend_app_add_validation_aos_play_store_url: "* Please enter the AOS Play Store URL.",
      admin_recommend_app_add_label_name: "Name",
      admin_recommend_app_add_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_add_label_ios_itunes_url: "iOS iTunes URL",
      admin_recommend_app_add_label_aos_package_id: "AOS Package ID",
      admin_recommend_app_add_label_aos_play_store_url: "AOS Play Store URL",
      admin_recommend_app_add_button_go_to_list: "Go to list",
      admin_recommend_app_add_button_add: "Add",
      admin_recommend_app_detail_title: "RECOMMEND APP INFORMATION",
      admin_recommend_app_detail_label_name: "Name",
      admin_recommend_app_detail_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_detail_label_ios_itunes_url: "iOS iTunes URL",
      admin_recommend_app_detail_label_aos_package_id: "AOS Package ID",
      admin_recommend_app_detail_label_aos_play_store_url: "AOS Play Store URL",
      admin_recommend_app_detail_label_updated_at: "Updated At",
      admin_recommend_app_detail_button_go_to_list: "Go to list",
      admin_recommend_app_detail_button_delete: "Delete",
      admin_recommend_app_detail_button_edit: "Edit",
      admin_recommend_app_edit_title: "RECOMMEND APP INFORMATION",
      admin_recommend_app_edit_validation_name: "* Please enter the name.",
      admin_recommend_app_edit_validation_ios_scheme: "* Please enter iOS Scheme.",
      admin_recommend_app_edit_validation_ios_itunes_url: "* Please enter the iOS iTunes URL.",
      admin_recommend_app_edit_validation_aos_package_id: "* Please enter the AOS package ID.",
      admin_recommend_app_edit_validation_aos_play_store_url: "* Please enter the AOS Play Store URL.",
      admin_recommend_app_edit_label_name: "Name",
      admin_recommend_app_edit_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_edit_label_ios_itunes_url: "iOS iTunes URL",
      admin_recommend_app_edit_label_aos_package_id: "AOS Package ID",
      admin_recommend_app_edit_label_aos_play_store_url: "AOS Play Store URL",
      admin_recommend_app_editl_label_updated_at: "Updated At",
      admin_recommend_app_edit_button_cancel: "Cancel",
      admin_recommend_app_edit_button_delete: "Delete",
      admin_recommend_app_edit_button_ok: "OK",
      admin_admin_user_list_title: "USER LIST",
      admin_admin_user_list_placeholder_select_box_user: "User",
      admin_admin_user_list_select_name: "Name",
      admin_admin_user_list_select_email: "Email",
      admin_admin_user_list_select_department: "Department",
      admin_admin_user_list_select_duty: "Duty",
      admin_admin_user_list_placeholder_search: "Search...",
      admin_admin_user_list_label_name: "Name",
      admin_admin_user_list_label_email: "Email",
      admin_admin_user_list_label_department: "Department",
      admin_admin_user_list_label_duty: "Duty",
      admin_admin_user_list_label_date: "Date",
      admin_admin_user_list_guide_empty: "List is empty",
      admin_admin_user_list_button_delete: "Delete",
      admin_admin_user_list_button_add: "Add",
      admin_admin_user_add_title: "NEW ADMIN",
      admin_admin_user_add_guide_click_add: 'Enter required information and click the <<"Add">> button to add new user.',
      admin_admin_user_add_validation_email: "* Please enter user email.",
      admin_admin_user_add_validation_email_format: "* Please enter email in the format someone@example.com",
      admin_admin_user_add_validation_password: "* Please enter user password.",
      admin_admin_user_add_validation_character_number_combining: "* Please enter at least 8 characters combining letters and numbers.",
      admin_admin_user_add_validation_not_match: "* Passwords do not match.",
      admin_admin_user_add_validation_name: "* Please enter name.",
      admin_admin_user_add_validation_korea_english: "* Korean and English or 'Please enter using ._ -'.",
      admin_admin_user_add_validation_department: "* Please enter department.",
      admin_admin_user_add_validation_select_duty: "* Please select employee duty.",
      admin_admin_user_add_label_email: "Email",
      admin_admin_user_add_placeholder_email: "Email",
      admin_admin_user_add_label_name: "Name",
      admin_admin_user_add_placeholder_name: "Name",
      admin_admin_user_add_label_passoword: "Password",
      admin_admin_user_add_placeholder_password: "Password",
      admin_admin_user_add_label_confirm_passoword: "Confirm password",
      admin_admin_user_add_placeholder_confirm_password: "Confirm password",
      admin_admin_user_add_label_department: "Department",
      admin_admin_user_add_placeholder_department: "Department",
      admin_admin_user_add_label_duty: "Duty",
      admin_admin_user_add_select_choose: "Select",
      admin_admin_user_add_select_super_admin: "Super Admin",
      admin_admin_user_add_select_admin: "Admin",
      admin_admin_user_add_select_user: "User",
      admin_admin_user_add_button_add: "Add",
      admin_admin_user_add_button_go_to_list: "Go to list",
      admin_admin_user_detail_title: "USER INFORMATION",
      admin_admin_user_detail_select_user: "User",
      admin_admin_user_detail_select_admin: "Admin",
      admin_admin_user_detail_select_super_admin: "Super Admin",
      admin_admin_user_detail_label_admin: "Admin",
      admin_admin_user_detail_label_name: "Name",
      admin_admin_user_detail_label_department: "Department",
      admin_admin_user_detail_label_duty: "Duty",
      admin_admin_user_detail_label_date: "Date",
      admin_admin_user_detail_button_delete: "Delete",
      admin_admin_user_detail_button_go_to_list: "Go to list",
      admin_admin_user_detail_button_ok: "OK",
      admin_admin_user_edit_title: "EDIT ADMIN",
      admin_admin_user_edit_guide_edit_or_leave: "Information can be modified excluding email address.",
      admin_admin_user_edit_validation_name: "* Please enter name.",
      admin_admin_user_edit_validation_department: "* Please enter department.",
      admin_admin_user_edit_validation_korea_english: "* Korean and English or 'Please enter using ._ -'.",
      admin_admin_user_edit_button_withdraw: "Withdraw",
      admin_admin_user_edit_button_save: "Save",
      admin_id_recognition_information_list_title: "ID RECOGNITION INFORMATION LIST",
      admin_id_recognition_information_list_select_id_type_all: "ID Type ( ALL )",
      admin_id_recognition_information_list_select_id_type_registration: "Registration",
      admin_id_recognition_information_list_select_id_type_passport: "Passport",
      admin_id_recognition_information_list_select_id_type_driver_license: "Driver License",
      admin_id_recognition_information_list_placeholder_search: "Country Name",
      admin_id_recognition_information_list_label_country_name: "Country Name",
      admin_id_recognition_information_list_label_id_type: "ID Type",
      admin_id_recognition_information_list_label_date: "Date",
      admin_id_recognition_information_list_guide_empty: "List is empty",
      admin_id_recognition_information_list_button_delete: "Delete",
      admin_id_recognition_information_list_button_add: "Add",
      admin_id_recognition_information_add_title: "ADD ID RECOGNITION INFORMATION",
      admin_id_recognition_information_add_guide_click_add: "Enter ID Recognition Information and click the <<'Add'>> button.",
      admin_id_recognition_information_add_label_country_name: "Country Name",
      admin_id_recognition_information_add_placeholder_country_name: "Country Name",
      admin_id_recognition_information_add_label_id_type: "ID Type",
      admin_id_recognition_information_add_placeholder_select_box_choose: "Choose",
      admin_id_recognition_information_add_select_id_type_registration: "Registration",
      admin_id_recognition_information_add_select_id_type_passport: "Passport",
      admin_id_recognition_information_add_select_id_type_driver_license: "Driver License",
      admin_id_recognition_information_add_label_recognized_texts: "Recongnized Texts",
      admin_id_recognition_information_add_placeholder_recognized_texts: "ex) REPUBLIC OF KOREA, Passport No., Surname, Given names, Date of birth, ...",
      admin_id_recognition_information_add_validation_country_name: "* Please enter the country name.",
      admin_id_recognition_information_add_validation_id_type: "* Please enter _x0008_a ID type.",
      admin_id_recognition_information_add_validation_recognized_texts: "* Enter recognition texts using ,.",
      admin_id_recognition_information_add_button_go_to_list: "Go to list",
      admin_id_recognition_information_add_button_add: "Add",
      admin_id_recognition_information_detail_title: "ID RECOGNITION INFORMATION",
      admin_id_recognition_information_detail_label_country_name: "Country Name",
      admin_id_recognition_information_detail_label_id_type: "ID Type",
      admin_id_recognition_information_detail_label_recognized_texts: "Recongnized Texts",
      admin_id_recognition_information_detail_label_created_at: "Created At",
      admin_id_recognition_information_detail_label_updated_at: "Updated At",
      admin_id_recognition_information_detail_button_go_to_list: "Go to List",
      admin_id_recognition_information_detail_button_edit: "Edit",
      admin_id_recognition_information_edit_title: "ID RECOGNITION INFORMATION",
      admin_id_recognition_information_edit_label_country_name: "Country Name",
      admin_id_recognition_information_edit_placeholder_country_name: "Country Name",
      admin_id_recognition_information_edit_label_id_type: "ID Type",
      admin_id_recognition_information_edit_placeholder_select_box_choose: "Choose",
      admin_id_recognition_information_edit_select_id_type_registration: "Registration",
      admin_id_recognition_information_edit_select_id_type_passport: "Passport",
      admin_id_recognition_information_edit_select_id_type_driver_license: "Driver License",
      admin_id_recognition_information_edit_label_recognized_texts: "Recongnized Texts",
      admin_id_recognition_information_edit_placeholder_recognized_texts: "ex) REPUBLIC OF KOREA, Passport No., Surname, Given names, Date of birth, ...",
      admin_id_recognition_information_edit_validation_country_name: "* Please enter the country name.",
      admin_id_recognition_information_edit_validation_id_type: "* Please enter _x0008_a ID type.",
      admin_id_recognition_information_edit_validation_recognized_texts: "* Enter recognition texts using ,.",
      admin_id_recognition_information_edit_label_updated_at: "Updated At",
      admin_id_recognition_information_edit_button_cancel: "Cancel",
      admin_id_recognition_information_edit_button_ok: "OK",
      admin_statistics_list_title: "STATISTICS LIST",
      admin_statistics_list_placeholder_select_box_choose: "Choose (All)",
      admin_statistics_list_placeholder_search: "Search...",
      admin_statistics_list_label_date: "Date",
      admin_statistics_list_label_reservation: "Reservation",
      admin_statistics_list_label_walk_in: "Walk-In",
      admin_statistics_list_label_check_in: "Check In",
      admin_statistics_list_label_checked_out: "Checked Out",
      admin_statistics_list_button_go_to_statistics: "Go to Statistics",
      hotel_header_link_password: "Password",
      hotel_header_link_logout: "Log Out",
      hotel_header_accordion_hotel: "Hotel",
      hotel_header_link_hotel_information: "Information",
      hotel_header_link_hotel_beacon: "Beacon",
      hotel_header_link_hotel_currency: "Currency",
      hotel_header_link_hotel_setting: "Settings",
      hotel_header_link_hotel_detail: "Hotel Details",
      hotel_header_link_hotel_image: "Hotel Image",
      hotel_header_accordion_room: "Room",
      hotel_header_link_room: "Room",
      hotel_header_link_room_detail: "Room Details",
      hotel_header_link_room_image: "Room Image",
      hotel_header_accordion_room_management: "Room Management",
      hotel_header_link_room_list: "Room List",
      hotel_header_link_room_management_setting: "Settings",
      hotel_header_accordion_package: "Package",
      hotel_header_link_package_package: "Package",
      hotel_header_link_package_package_detail: "Package Details",
      hotel_header_accordion_member: "Member",
      hotel_header_link_member_member: "Member",
      hotel_header_link_member_non_member: "Non-Member",
      hotel_header_accordion_reservation: "Reservation",
      hotel_header_link_reservation_reservation: "Reservation",
      hotel_header_link_reservation_future_booking: "Future Reservation",
      hotel_header_link_reservation_waiting_room: "Waiting for Room Assignment",
      hotel_header_accordion_reservation_policy: "Reservation Policy",
      hotel_header_link_reservation_rule_setting_deposit_rule: "Deposit Rule",
      hotel_header_link_reservation_rule_setting_reservation_rule: "Reservation Rule",
      hotel_header_link_reservation_rule_setting_cancellation_rule: "Cancellation Rule",
      hotel_header_link_reservation_rule_setting_no_show_rule: "No Show Rule",
      hotel_header_link_reservation_rule_setting_rule_schedule: "Rule Schedule",
      hotel_header_accordion_payment: "Payment",
      hotel_header_link_payment_history: "History",
      hotel_header_link_payment_setting: "Settings",
      hotel_header_accordion_service: "Service",
      hotel_header_link_service_hotel_info: "Hotel Information",
      hotel_header_accordion_concierge: "Concierge",
      hotel_header_link_service_concierge_request: "Request (Before Confirmation)",
      hotel_header_link_service_concierge_process: "Request (After Confirmation)",
      hotel_header_link_service_concierge_schedule_process: "Schedule Progress",
      hotel_header_link_service_concierge_schedule: "Schedule Setting",
      hotel_header_link_service_concierge_business_rule: "Business Rule Setting",
      hotel_header_link_service_concierge_boundary_line: "Boundary Line Setting",
      hotel_header_accordion_housekeeping: "Housekeeping",
      hotel_header_link_service_housekeeping_request: "Request (Before Confirmation)",
      hotel_header_link_service_housekeeping_process: "Request (after Confirmation)",
      hotel_header_link_service_housekeeping_schedule_process: "Schedule Progress",
      hotel_header_link_service_housekeeping_schedule: "Schedule Setting",
      hotel_header_link_service_housekeeping_business_rule: "Business Rule Setting",
      hotel_header_link_service_housekeeping_boundary_line: "Boundary Line Setting",
      hotel_header_link_service_setting_amenity: "Amenity Setting",
      hotel_header_link_service_housekeeping_setting: "Settings",
      hotel_header_accordion_in_room_dining: "In-Room Dining",
      hotel_header_link_service_in_room_dining_request: "Request (Before Confirmation)",
      hotel_header_link_service_in_room_dining_process: "Request (After Confirmation)",
      hotel_header_link_service_in_room_dining_schedule_process: "Schedule Progress",
      hotel_header_link_service_in_room_dining_schedule: "Schedule Setting",
      hotel_header_link_service_in_room_dining_business_rule: "Business Rule Setting",
      hotel_header_link_service_in_room_dining_boundary_line: "Boundary Line Setting",
      hotel_header_link_service_in_room_dining_cart: "Cart",
      hotel_header_link_service_in_room_dining_category: "Category",
      hotel_header_link_service_in_room_dining_menu_setting: "Menu Settings",
      hotel_header_link_service_in_room_dining_setting: "Settings",
      hotel_header_accordion_valet: "Valet Parking",
      hotel_header_link_service_valet_process: "Request",
      hotel_header_link_service_valet_schedule: "Schedule Setting",
      hotel_header_link_service_valet_business_rule: "Business Rule Setting",
      hotel_header_link_service_valet_parking_setting: "Settings",
      hotel_header_accordion_self: "Self Parking",
      hotel_header_link_service_self_parking_setting: "Settings",
      hotel_header_link_service_hotel_inhouse_menu_setting: "In-House Menu Settings",
      hotel_header_link_service_hotel_inhouse_menu_description: "Service Description",
      hotel_header_accordion_key: "Key",
      hotel_header_link_key_room_to_room: "Room to Room",
      hotel_header_link_key_guest_key: "Guest Key",
      hotel_header_link_key_staff_key: "Staff Key",
      hotel_header_link_key_share_key: "Share Key",
      hotel_header_link_key_help_for_mobile_key: "Help for Mobile Key",
      hotel_header_link_key_staff_key_link: "Link",
      hotel_header_link_key_access: "Access",
      hotel_header_link_key_access_detail: "Access Details",
      hotel_header_link_key_key_setting: "Settings",
      hotel_header_link_key_reservation_access: "Access Link",
      hotel_header_accordion_pms: "PMS",
      hotel_header_link_pms_title: "Title",
      hotel_header_link_pms_card_type: "Card Type",
      hotel_header_link_pms_membership_type: "Membership Type",
      hotel_header_link_pms_fias_manager: "FIAS Manager",
      hotel_header_link_pms_notification_callback: "Notification Callback",
      hotel_header_link_pms_setting: "Settings",
      hotel_header_accordion_room_controller: "Room Controller",
      hotel_header_link_room_controller_info: "Information",
      hotel_header_link_room_controller_setting: "Settings",
      hotel_header_accordion_document: "Stationery Editor",
      hotel_header_accordion_document_email: "Email",
      hotel_header_accordion_event: "Event",
      hotel_header_link_event_event: "Event",
      hotel_header_link_event_event_category: "Event Category",
      hotel_header_link_email_reservation: "Reservation",
      hotel_header_link_email_reservation_change: "Reservation Change",
      hotel_header_link_email_reservation_cancel: "Reservation Cancel",
      hotel_header_link_email_no_show: "No-Show",
      hotel_header_link_email_reservation_pack_add: "Reservation Package Add",
      hotel_header_link_email_reservation_pack_delete: "Reservation Package Delete",
      hotel_header_link_email_reservation_accom_add: "Reservation Accompany Add",
      hotel_header_link_email_reservation_accom_delete: "Reservation Accompany Delete",
      hotel_header_link_email_reservation_accom_email: "Accompany Reservation Email",
      hotel_header_link_email_reservation_accom_change: "Accompany Reservation Change",
      hotel_header_link_email_reservation_accom_cancel: "Accompany Reservation Cancel",
      hotel_header_link_email_reservation_folio_email: "Folio Email",
      hotel_header_link_email_reservation_enroll_profile: "Enroll Profile",
      hotel_header_accordion_document_pdf: "PDF",
      hotel_header_link_pdf_reservation: "Reservation PDF",
      hotel_header_link_pdf_reservation_accom_pdf: "Accompany Reservation PDF",
      hotel_header_link_pdf_folio_pdf: "Folio PDF",
      hotel_header_accordion_terms_and_conditions: "Terms and Conditions",
      hotel_header_link_terms_and_conditions_terms: "Terms",
      hotel_header_link_terms_and_conditions_agreement: "Agreement",
      hotel_header_current_version: "Current Version",
      hotel_header_translation_version: "Translation Version",
      hotel_header_link_staff_user: "Staff User",
      hotel_header_link_app_download: "App Download",
      hotel_password_title: "CHANGE PASSWORD",
      hotel_password_guide_password: "Please enter a new password \n with at least 8 characters.",
      hotel_password_validation_current_password: "* Please enter your current password.",
      hotel_password_validation_character_number_combining: "* Please enter at least 8 characters combining letters and numbers.",
      hotel_password_validation_new_password: "* Please enter a new password.",
      hotel_password_validation_new_password_again: "* Please enter your new password again.",
      hotel_password_validation_not_match: "* Passwords do not match.",
      hotel_password_placeholder_password: "Password",
      hotel_password_placeholder_new_password: "New password",
      hotel_password_placeholder_new_confirm_password: "Confirm new password",
      hotel_password_button_save: "Save",
      hotel_hotel_info_detail_title: "Hotel Information",
      hotel_hotel_info_detail_label_hotel_no: "Hotel No.",
      hotel_hotel_info_detail_label_name: "Name",
      hotel_hotel_info_detail_label_address: "Address",
      hotel_hotel_info_detail_label_region: "Region",
      hotel_hotel_info_detail_label_country: "Country",
      hotel_hotel_info_detail_label_city: "City",
      hotel_hotel_info_detail_label_logo: "Logo",
      hotel_hotel_info_detail_label_hotel_photo: "Hotel Photo",
      hotel_hotel_info_detail_label_hotel_map: "Map",
      hotel_hotel_info_detail_button_edit: "Edit",
      hotel_hotel_info_edit_title: "Hotel Information",
      hotel_hotel_info_edit_label_hotel_no: "Hotel No.",
      hotel_hotel_info_edit_label_name: "Name",
      hotel_hotel_info_edit_label_address: "Address",
      hotel_hotel_info_edit_label_region: "Region",
      hotel_hotel_info_edit_label_country: "Country",
      hotel_hotel_info_edit_label_city: "City",
      hotel_hotel_info_edit_label_logo: "Logo",
      hotel_hotel_info_edit_button_upload: "Upload",
      hotel_hotel_info_edit_placeholder_upload: "ex) MyCONECT.png",
      hotel_hotel_info_edit_label_hotel_photo: "Hotel photo",
      hotel_hotel_info_edit_label_hotel_map: "Map",
      hotel_hotel_info_edit_button_search: "Search",
      hotel_hotel_info_edit_button_ok: "OK",
      hotel_hotel_info_edit_validation_hotel_name: "* Enter the hotel name.",
      hotel_hotel_info_edit_validation_hotel_main_tel: "* Enter the hotel's main phone number.",
      hotel_hotel_info_edit_validation_hotel_service_tel: "* Enter the hotel service phone number.",
      hotel_hotel_info_edit_validation_hotel_address: "* Please enter address.",
      hotel_hotel_pms_detail_title: "SETTINGS",
      hotel_hotel_pms_detail_label_date: "Date Updated",
      hotel_hotel_pms_detail_label_type: "Type",
      hotel_hotel_pms_detail_label_cloud_flag: "Cloud Flag",
      hotel_hotel_pms_detail_label_server_url: "Server Url",
      hotel_hotel_pms_detail_label_origin_entity_id: "Origin Entity ID",
      hotel_hotel_pms_detail_label_origin_system_type: "Origin System Type",
      hotel_hotel_pms_detail_label_destination_entity_id: "Destination Entity ID",
      hotel_hotel_pms_detail_label_destination_system_type: "Destination System Type",
      hotel_hotel_pms_detail_label_user_name: "User Name",
      hotel_hotel_pms_detail_label_user_password: "User Password",
      hotel_hotel_pms_detail_label_domain: "Domain",
      hotel_hotel_pms_detail_label_hotel_code: "Hotel Code",
      hotel_hotel_pms_detail_label_chain_code: "Chain Code",
      hotel_hotel_pms_detail_label_reservation_transaction_code_is_credit: "Reservation Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_cancellation_transaction_code_is_credit: "Cancellation Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_deposit_transaction_code_is_credit: "Deposit Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_noshow_transaction_code_is_credit: "No-Show Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_service_fee_transaction_code_is_credit: "Service Fee Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_default_reservation_transaction_code: "Default Reservation Transaction Code",
      hotel_hotel_pms_detail_label_default_cancellation_transaction_code: "Default Cancellation Transaction Code",
      hotel_hotel_pms_detail_label_default_deposit_transaction_code: "Default Deposit Transaction Code",
      hotel_hotel_pms_detail_label_default_noshow_transaction_code: "Default No-Show Transaction Code",
      hotel_hotel_pms_detail_label_default_service_fee_transaction_code: "Default Service Fee Transaction Code",
      hotel_hotel_pms_detail_label_cancellation_debit_transaction_code: "Cancellation Debit Transaction Code",
      hotel_hotel_pms_detail_label_noshow_debit_transaction_code: "No-Show Debit Transaction Code",
      hotel_hotel_pms_detail_label_market_code: "Market Code",
      hotel_hotel_pms_detail_label_future_booking: "Future Booking",
      hotel_hotel_pms_detail_label_walk_in: "Walk-In",
      hotel_hotel_pms_detail_label_web_booking: "Web Booking",
      hotel_hotel_pms_detail_label_ota_booking: "OTA Booking",
      hotel_hotel_pms_detail_label_origin_code: "Origin Code",
      hotel_hotel_pms_detail_label_rate_code: "Rate Code",
      hotel_hotel_pms_detail_label_yes: "Yes",
      hotel_hotel_pms_detail_label_no: "No",
      hotel_hotel_pms_detail_label_earliest_checkin_time: "Earliest Check In Time",
      hotel_hotel_pms_detail_label_dummy_pms_profile_id: "Dummy PMS Profile ID",
      hotel_hotel_pms_detail_button_go_to_info: "Go to info",
      hotel_hotel_pms_detail_button_edit: "Edit",
      hotel_hotel_pms_edit_opera: "Opera",
      hotel_hotel_pms_edit_planyo: "Planyo",
      hotel_hotel_pms_edit_title: "SETTINGS",
      hotel_hotel_pms_edit_label_date: "Date Updated",
      hotel_hotel_pms_edit_label_type: "Type",
      hotel_hotel_pms_edit_label_cloud_flag: "Cloud Flag",
      hotel_hotel_pms_edit_label_server_url: "Server Url",
      hotel_hotel_pms_edit_label_origin_entity_id: "Origin Entity ID",
      hotel_hotel_pms_edit_label_origin_system_type: "Origin System Type",
      hotel_hotel_pms_edit_label_destination_entity_id: "Destination Entity ID",
      hotel_hotel_pms_edit_label_destination_system_type: "Destination System Type",
      hotel_hotel_pms_edit_label_user_name: "User Name",
      hotel_hotel_pms_edit_label_user_password: "User Password",
      hotel_hotel_pms_edit_label_domain: "Domain",
      hotel_hotel_pms_edit_label_hotel_code: "Hotel Code",
      hotel_hotel_pms_edit_label_chain_code: "Chain Code",
      hotel_hotel_pms_edit_label_reservation_transaction_code_is_credit: "Reservation Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_cancellation_transaction_code_is_credit: "Cancellation Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_deposit_transaction_code_is_credit: "Deposit Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_noshow_transaction_code_is_credit: "No-Show Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_service_fee_transaction_code_is_credit: "Service Fee Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_default_reservation_transaction_code: "Default Reservation Transaction Code",
      hotel_hotel_pms_edit_label_default_cancellation_transaction_code: "Default Cancellation Transaction Code",
      hotel_hotel_pms_edit_label_default_deposit_transaction_code: "Default Deposit Transaction Code",
      hotel_hotel_pms_edit_label_default_noshow_transaction_code: "Default No-Show Transaction Code",
      hotel_hotel_pms_edit_label_default_service_fee_transaction_code: "Default Service Fee Transaction Code",
      hotel_hotel_pms_edit_label_cancellation_debit_transaction_code: "Cancellation Debit Transaction Code",
      hotel_hotel_pms_edit_label_noshow_debit_transaction_code: "No-Show Debit Transaction Code",
      hotel_hotel_pms_edit_label_market_code: "Market Code",
      hotel_hotel_pms_edit_label_future_booking: "Future Booking",
      hotel_hotel_pms_edit_label_walk_in: "Walk-In",
      hotel_hotel_pms_edit_label_web_booking: "Web Booking",
      hotel_hotel_pms_edit_label_ota_booking: "OTA Booking",
      hotel_hotel_pms_edit_label_origin_code: "Origin Code",
      hotel_hotel_pms_edit_label_rate_code: "Rate Code",
      hotel_hotel_pms_edit_label_yes: "Yes",
      hotel_hotel_pms_edit_label_no: "No",
      hotel_hotel_pms_edit_button_cancel: "Cancel",
      hotel_hotel_pms_edit_button_ok: "OK",
      hotel_hotel_pms_edit_validation_server_url: "* Please enter the server URL.",
      hotel_hotel_pms_edit_validation_origin_entity_id: "* Please enter the Origin Entity ID.",
      hotel_hotel_pms_edit_validation_origin_system_type: "* Please enter the Origin System Type.",
      hotel_hotel_pms_edit_validation_destination_entity_id: "* Please enter the Destination Entity ID.",
      hotel_hotel_pms_edit_validation_destination_system_type: "* Please enter the Destination System Type.",
      hotel_hotel_pms_edit_validation_user_name: "* Please enter your user name.",
      hotel_hotel_pms_edit_validation_user_password: "* Please enter your user password.",
      hotel_hotel_pms_edit_validation_domain: "* Please enter the domain.",
      hotel_hotel_setting_detail_title: "SETTINGS",
      hotel_hotel_setting_detail_label_date: "Date Updated",
      hotel_hotel_setting_detail_label_room_control: "Room Control",
      hotel_hotel_setting_detail_label_room_control_ip: "Room Control IP",
      hotel_hotel_setting_detail_label_room_control_port: "Room Control Port",
      hotel_hotel_setting_detail_label_fias: "FIAS",
      hotel_hotel_setting_detail_label_notification_callback: "Notification Callback",
      hotel_hotel_setting_detail_label_fias_hotel_id: "Hotel ID",
      hotel_hotel_setting_detail_label_fias_auth_code: "Authentication Code",
      hotel_hotel_setting_detail_label_payment: "Payment",
      hotel_hotel_setting_detail_label_payment_auth_code: "Authentication Code",
      hotel_hotel_setting_detail_label_store_id: "Store ID",
      hotel_hotel_setting_detail_label_store_key: "Store Key",
      hotel_hotel_setting_detail_label_store_api_id: "Store API ID",
      hotel_hotel_setting_detail_label_payment_salt: "Salt",
      hotel_hotel_setting_detail_label_payment_token_password: "Token Password",
      hotel_hotel_setting_detail_label_secure_hash_secret: "Secure Hash Secret",
      hotel_hotel_setting_detail_label_payment_url: "Payment URL",
      hotel_hotel_setting_detail_label_refund_url: "Refund URL",
      hotel_hotel_setting_detail_label_payment_type: "Payment Type",
      hotel_hotel_setting_detail_placeholder_select_box_choose: "Choose",
      hotel_hotel_setting_detail_select_nicepay: "Nicepay",
      hotel_hotel_setting_detail_select_asiapay: "Asiapay",
      hotel_hotel_setting_detail_label_booking_payment_using_myconect_flag: "Use MyCONECT payment at booking",
      hotel_hotel_setting_detail_label_checkin_payment_using_myconect_flag: "Use MyCONECT payment at check-in",
      hotel_hotel_setting_detail_label_walkin_payment_using_myconect_flag: "Use MyCONECT payment at walk-in",
      hotel_hotel_setting_detail_label_noshow_payment_using_myconect_flag: "Use MyCONECT payment at no-show",
      hotel_hotel_setting_detail_label_checkout_payment_using_myconect_flag: "Use MyCONECT payment at check-out",
      hotel_hotel_setting_detail_label_cancel_booking_payment_using_myconect_flag: "Use MyCONECT payment at booking cancellation",
      hotel_hotel_setting_detail_label_need_card_information_for_booking: "Card information required at booking",
      hotel_hotel_setting_detail_label_need_card_information_for_checkin: "Card information required at check-in",
      hotel_hotel_setting_detail_label_need_card_information_for_walkin: "Card information required at walk-in",
      hotel_hotel_setting_detail_label_is_direct_payable: "Enable direct pay using token information",
      hotel_hotel_setting_detail_label_is_card_tokenizable: "Enable card tokenization",
      hotel_hotel_setting_detail_label_min_card_expiry_days_post_checkout: "Minimum number of days until card expiration after checkout.",
      hotel_hotel_setting_detail_label_time: "Time",
      hotel_hotel_setting_detail_label_early_check_in: "Early Check In",
      hotel_hotel_setting_detail_label_assign_room_time: "Assign Room Time (Hour:Minutes)",
      hotel_hotel_setting_detail_label_do_not_disturb_time: "Do Not Disturb Time (Hour:Minutes)",
      hotel_hotel_setting_detail_label_time_zone: "Time Zone",
      hotel_hotel_setting_detail_label_tell: "Tel.",
      hotel_hotel_setting_detail_label_hotel_main_tel: "Hotel Main Tel",
      hotel_hotel_setting_detail_label_hotel_service_tel: "Hotel Service Tel.",
      hotel_hotel_setting_detail_label_setting: "Settings",
      hotel_hotel_setting_detail_label_dnd: "DND",
      hotel_hotel_setting_detail_label_dnd_status: "DND Status Reminder Notice Period (Hour:Minutes)",
      hotel_hotel_setting_detail_label_dnd_relese: "Release DND Before Service Notification (minutes)",
      hotel_hotel_setting_detail_button_yes: "Yes",
      hotel_hotel_setting_detail_button_no: "No",
      hotel_hotel_setting_detail_button_refresh: "Refresh",
      hotel_hotel_setting_detail_label_member_number_check: "Confirm No. of Check In Guests",
      hotel_hotel_setting_detail_label_passport: "Passport Verify Module",
      hotel_hotel_setting_detail_select_detion: "Detion",
      hotel_hotel_setting_detail_select_inzi_soft: "Inzi Soft",
      hotel_hotel_setting_detail_label_accompany_local_check: "Domestic Accompany ID Scan",
      hotel_hotel_setting_detail_label_accompany_foreigner_check: "Foreigner Accompany ID Scan",
      hotel_hotel_setting_detail_label_room_number: "Display Room Number",
      hotel_hotel_setting_detail_label_hotel_logo: "Display Hotel Logo",
      hotel_hotel_setting_detail_label_member_lock_count: "Member Lock Count",
      hotel_hotel_setting_detail_label_payment_lock_count: "Payment Lock Count",
      hotel_hotel_setting_detail_label_staff_login_lock_count: "Staff Login Lock Count",
      hotel_hotel_setting_detail_label_default_currency: "Default Currency",
      hotel_hotel_setting_detail_label_percent_for_hotel_charge: "Hotel Charge Percentage (%)",
      hotel_hotel_setting_detail_label_language_code: "Language Code",
      hotel_hotel_setting_detail_label_alternative_name_code: "Alternative Name Code",
      hotel_hotel_setting_detail_label_floor: "Floor",
      hotel_hotel_setting_detail_label_out_of_order_check: "Out Of Order Check",
      hotel_hotel_setting_detail_label_password_change_day: "Password Change Day (Day)",
      hotel_hotel_setting_detail_label_password_change_notice: "Password Change Notice Period (Day)",
      hotel_hotel_setting_detail_label_occupy_minutes: "Occupy Minutes (Minute)",
      hotel_hotel_setting_detail_label_reservation_charge_waiting_minutes: "Reservation Charge Waiting Minutes (Minute)",
      hotel_hotel_setting_detail_label_beacon_check_hours: "Beacon Check Hours (Hour)",
      hotel_hotel_setting_detail_label_compare_faces_flag: "Compare Faces for Foreigner Flag",
      hotel_hotel_setting_detail_label_compare_face_for_local_flag: "Compare Face for Local Flag",
      hotel_hotel_setting_detail_label_singapore_government_eva_flag: "Singapore Government EVA Flag",
      hotel_hotel_setting_detail_label_singapore_government_eva_hotel_code: "Singapore Government EVA Hotel Code",
      hotel_hotel_setting_detail_label_date_reservation_flag: "Date Reservation Flag",
      hotel_hotel_setting_detail_label_time_reservation_flag: "Time Reservation Flag",
      hotel_hotel_setting_detail_label_transit_hotel_flag: "Transit Hotel Flag",
      hotel_hotel_setting_detail_label_request_make_up_room_at_checkout: "Request Make Up Room At Checkout",
      hotel_hotel_setting_detail_label_show_rate_for_ota_flag: "Show Rate for OTA Flag",
      hotel_hotel_setting_detail_button_edit: "Edit",
      hotel_hotel_setting_detail_button_cancel: "Cancel",
      hotel_hotel_setting_edit_title: "Settings",
      hotel_hotel_setting_edit_label_date: "Date Updated",
      hotel_hotel_setting_edit_label_room_control: "Room Control",
      hotel_hotel_setting_edit_label_room_control_ip: "Room Control IP",
      hotel_hotel_setting_edit_placeholder_room_control_ip: "ex) 127.0.0.1",
      hotel_hotel_setting_edit_label_room_control_port: "Room Control Port",
      hotel_hotel_setting_edit_placeholder_room_control_port: "ex) 80",
      hotel_hotel_setting_edit_label_payment: "Payment",
      hotel_hotel_setting_edit_label_store_id: "Store ID",
      hotel_hotel_setting_edit_placeholder_store_id: "Store ID",
      hotel_hotel_setting_edit_label_payment_url: "Payment URL",
      hotel_hotel_setting_edit_placeholder_payment_url: "Payment URL",
      hotel_hotel_setting_edit_label_refund_url: "Refund URL",
      hotel_hotel_setting_edit_placeholder_refund_url: "Refund URL",
      hotel_hotel_setting_edit_label_time: "Time",
      hotel_hotel_setting_edit_label_assign_room_time: "Assign Room Time",
      hotel_hotel_setting_edit_placeholder_assign_room_time: "Assign Room Time",
      hotel_hotel_setting_edit_label_do_not_disturb_time: "Do Not Disturb Time",
      hotel_hotel_setting_edit_placeholder_do_not_disturb_time: "Do Not Disturb Time",
      hotel_hotel_setting_edit_label_time_zone: "Time Zone",
      hotel_hotel_setting_edit_placeholder_time_zone: "Time Zone",
      hotel_hotel_setting_edit_label_tell: "Tel.",
      hotel_hotel_setting_edit_label_hotel_main_tel: "Hotel Main Tel.",
      hotel_hotel_setting_edit_placeholder_hotel_main_tel: "Hotel Main Tel.",
      hotel_hotel_setting_edit_label_hotel_service_tel: "Hotel Service Tel.",
      hotel_hotel_setting_edit_placeholder_hotel_service_tel: "Hotel Service Tel.",
      hotel_hotel_setting_edit_label_setting: "Settings",
      hotel_hotel_setting_edit_button_yes: "Yes",
      hotel_hotel_setting_edit_button_no: "No",
      hotel_hotel_setting_edit_label_member_number_check: "Confirm No. of Check In Guests",
      hotel_hotel_setting_edit_label_accompany_local_check: "Domestic Accompany ID Scan",
      hotel_hotel_setting_edit_label_accompany_foreigner_check: "Foreigner Accompany ID Scan",
      hotel_hotel_setting_edit_label_room_number: "Display Room Number",
      hotel_hotel_setting_edit_label_hotel_logo: "Display Hotel Logo",
      hotel_hotel_setting_edit_label_member_lock_count: "Member Lock Count",
      hotel_hotel_setting_edit_placeholder_member_lock_count: "Member Lock Count",
      hotel_hotel_setting_edit_label_payment_lock_count: "Payment Lock Count",
      hotel_hotel_setting_edit_placeholder_payment_lock_count: "Payment Lock Count",
      hotel_hotel_setting_edit_label_default_currency: "Default Currency",
      hotel_hotel_setting_edit_placeholder_default_currency: "Default Currency",
      hotel_hotel_setting_edit_label_percent_for_hotel_charge: "Hotel Charge Percentage",
      hotel_hotel_setting_edit_placeholder_percent_for_hotel_charge: "Hotel Charge Percentage",
      hotel_hotel_setting_edit_label_language_code: "Language Code",
      hotel_hotel_setting_edit_placeholder_language_code: "Language Code",
      hotel_hotel_setting_edit_label_alternative_name_code: "Alternative Name Code",
      hotel_hotel_setting_edit_placeholder_alternative_name_code: "Alternative Name Code",
      hotel_hotel_setting_edit_label_floor: "Floor",
      hotel_hotel_setting_edit_placeholder_floor: "Floor",
      hotel_hotel_setting_edit_button_go_to_info: "Go to info",
      hotel_hotel_setting_edit_button_cancel: "Cancel",
      hotel_hotel_setting_edit_button_ok: "OK",
      hotel_hotel_setting_edit_button_refresh: "Refresh",
      hotel_hotel_setting_edit_validation_assign_room_time: "* Please enter the room assignment time.",
      hotel_hotel_setting_edit_validation_time_zone: "* Please select a time zone.",
      hotel_hotel_setting_edit_validation_member_lock_count: "* Please enter the number of member lockouts.",
      hotel_hotel_setting_edit_validation_payment_lock_count: "* Please enter the number of payment lockouts.",
      hotel_hotel_setting_edit_validation_staff_lock_count: "* Please enter the number of staff login lockouts.",
      hotel_hotel_setting_edit_validation_default_currency: "* Please enter your default currency.",
      hotel_hotel_setting_edit_validation_language_code: "* Please enter the language code.",
      hotel_hotel_setting_edit_validation_alternative_name_code: "* Please enter your Korean name code.",
      hotel_hotel_setting_edit_validation_floors: "* Please enter the floor.",
      hotel_hotel_setting_edit_validation_password_change_day: "* Please enter the password change date.",
      hotel_hotel_setting_edit_validation_password_change_notice_period: "* Please enter the password change notification period.",
      hotel_hotel_setting_edit_validation_occupy_minutes: "* Please enter the occupation time.",
      hotel_hotel_setting_edit_validation_reservation_charge_waiting_minutes: "* Please enter the waiting time for prepayment.",
      hotel_hotel_setting_edit_validation_beacon_check_hours: "* Please enter the beacon confirmation time.",
      hotel_hotel_setting_edit_validation_gov_sg_eva_hotel_code: "* Please enter the singapore government EVA hotel code.",
      hotel_hotel_setting_edit_validation_store_id: "* Please enter your store ID.",
      hotel_hotel_setting_edit_validation_store_key: "* Please enter your store key.",
      hotel_hotel_setting_edit_validation_payment_url: "* Please enter the payment URL.",
      hotel_hotel_setting_edit_validation_refund_url: "* Please enter the refund URL.",
      hotel_hotel_setting_edit_validation_payment_type: "* Please select a payment type.",
      hotel_hotel_setting_edit_validation_payment_salt: "* Please enter the salt information.",
      hotel_hotel_setting_edit_validation_payment_token_password: "* Please enter the token password.",
      hotel_hotel_setting_edit_validation_secure_hash_secret: "* Please enter the secure hash secret.",
      hotel_hotel_setting_edit_validation_booking_payment_using_myconect_flag: "* Use MyCONECT payment at booking",
      hotel_hotel_setting_edit_validation_checkin_payment_using_myconect_flag: "* Use MyCONECT payment at check-in",
      hotel_hotel_setting_edit_validation_walkin_payment_using_myconect_flag: "* Use MyCONECT payment at walk-in",
      hotel_hotel_setting_edit_validation_noshow_payment_using_myconect_flag: "* Use MyCONECT payment at no-show",
      hotel_hotel_setting_edit_validation_checkout_payment_using_myconect_flag: "* Use MyCONECT payment at check-out",
      hotel_hotel_setting_edit_validation_cancel_booking_payment_using_myconect_flag: "* Use MyCONECT payment at booking cancelation",
      hotel_hotel_setting_edit_validation_need_card_information_for_booking: "* Select whether card information is required at the time of booking.",
      hotel_hotel_setting_edit_validation_need_card_information_for_checkin: "* Select whether card information is required at check-in.",
      hotel_hotel_setting_edit_validation_need_card_information_for_walkin: "* Select whether card information is required at walk-in.",
      hotel_hotel_setting_edit_validation_is_direct_payable: "* Select whether direct pay is available.",
      hotel_hotel_setting_edit_validation_is_card_tokenizable: "* Please select the Is card tokenizable.",
      hotel_hotel_setting_edit_validation_cancel_booking_post_charge_using_flag: "* Use post-payment upon booking cancellation.",
      hotel_hotel_setting_edit_validation_no_show_post_charge_using_flag: "* Select whether to enable post-payment upon no-show.",
      hotel_hotel_setting_edit_validation_min_card_expiry_days_checkout: "* Enter the minimum number of days until card expiration after checkout.",
      hotel_hotel_setting_detail_label_cancel_booking_post_charge_using_flag: "Enable post-payment upon booking cancellation ",
      hotel_hotel_setting_detail_label_noshow_post_charge_using_flag: "Enable post-payment billing upon no-show",
      hotel_hotel_setting_edit_validation_dnd_status: "* Please enter the notification period for confirming guest's DND mode.",
      hotel_hotel_setting_edit_validation_dnd_relese: "* Please enter the time to send a DND release reminder notification to guests before service time.",
      hotel_hotel_setting_edit_validation_room_control_property_id: "* Please enter your hotel ID.",
      hotel_hotel_setting_edit_validation_room_control_ip: "* Please enter the room control IP.",
      hotel_hotel_setting_edit_validation_room_control_port: "* Please enter the room control port.",
      hotel_hotel_detail_list_title: "HOTEL DETAIL LIST",
      hotel_hotel_detail_list_label_name: "Name",
      hotel_hotel_detail_list_label_language_code: "Language Code",
      hotel_hotel_detail_list_label_rating: "Rating",
      hotel_hotel_detail_list_label_address: "Address",
      hotel_hotel_detail_list_label_date: "Date",
      hotel_hotel_detail_list_button_delete: "Delete",
      hotel_hotel_detail_list_button_add: "Add",
      hotel_hotel_detail_add_title: "ADD HOTEL DETAIL",
      hotel_hotel_detail_add_label_name: "Name",
      hotel_hotel_detail_add_label_language_code: "Language Code",
      hotel_hotel_detail_add_placeholder_select_box_choose: "Select",
      hotel_hotel_detail_add_select_ko: "KR",
      hotel_hotel_detail_add_select_en: "EN",
      hotel_hotel_detail_add_select_cn: "CN",
      hotel_hotel_detail_add_select_jp: "JP",
      hotel_hotel_detail_add_label_rating: "Rating",
      hotel_hotel_detail_add_label_address: "Address",
      hotel_hotel_detail_add_label_phone: "Phone",
      hotel_hotel_detail_add_label_email: "Email",
      hotel_hotel_detail_add_button_go_to_list: "Go to list",
      hotel_hotel_detail_add_button_add: "Add",
      hotel_hotel_detail_add_validation_name: "* Please enter the hotel name.",
      hotel_hotel_detail_add_validation_languageCode: "* Please select a language code.",
      hotel_hotel_detail_add_validation_rating: "* Please enter the hotel grade.",
      hotel_hotel_detail_add_validation_address: "* Please enter the address.",
      hotel_hotel_detail_add_validation_phone: "* Please enter the phone number.",
      hotel_hotel_detail_add_validation_email: "* Please enter hotel email address.",
      hotel_hotel_detail_add_validation_about: "* Please enter the room description.",
      hotel_hotel_detail_detail_title: "HOTEL DETAIL",
      hotel_hotel_detail_detail_label_details: "Details",
      hotel_hotel_detail_detail_label_name: "Name",
      hotel_hotel_detail_detail_label_language_code: "Language Code",
      hotel_hotel_detail_detail_placeholder_select_box_choose: "Select",
      hotel_hotel_detail_detail_select_ko: "KR",
      hotel_hotel_detail_detail_select_en: "EN",
      hotel_hotel_detail_detail_select_cn: "CN",
      hotel_hotel_detail_detail_select_jp: "JP",
      hotel_hotel_detail_detail_label_rating: "Rating",
      hotel_hotel_detail_detail_label_address: "Address",
      hotel_hotel_detail_detail_label_phone: "Phone",
      hotel_hotel_detail_detail_label_email: "Email",
      hotel_hotel_detail_detail_label_about: "About",
      hotel_hotel_detail_detail_label_date: "Date",
      hotel_hotel_detail_detail_label_facility: "Facility",
      hotel_hotel_detail_detail_label_add_facility: "Add Facility",
      hotel_hotel_detail_detail_label_edit_facility: "Edit Facility",
      hotel_hotel_detail_detail_add_placeholder_name: "Please enter the name.",
      hotel_hotel_detail_detail_label_amenity: "Amenities",
      hotel_hotel_detail_detail_label_add_amenity: "Add Amenity",
      hotel_hotel_detail_detail_type: "Type",
      hotel_hotel_detail_detail_group: "Group",
      hotel_hotel_detail_detail_code: "Code",
      hotel_hotel_detail_detail_name: "Name",
      hotel_hotel_detail_detail_icon: "Icon",
      hotel_hotel_detail_detail_date: "Date",
      hotel_hotel_detail_detail_label_recommend_application: "Suggested Apps",
      hotel_hotel_detail_detail_application: "Application",
      hotel_hotel_detail_detail_label_add_recommend_app: "Add Suggested App",
      hotel_hotel_detail_detail_empty_guide: "Please Add Content",
      hotel_hotel_detail_detail_button_go_to_list: "Go to list",
      hotel_hotel_detail_detail_button_delete: "Delete",
      hotel_hotel_detail_detail_button_add: "Add",
      hotel_hotel_detail_detail_button_cancel: "Cancel",
      hotel_hotel_detail_detail_button_edit: "Edit",
      hotel_hotel_detail_detail_button_ok: "OK",
      hotel_hotel_detail_edit_validation_name: "* Please enter the hotel name.",
      hotel_hotel_detail_edit_validation_languageCode: "* Please select a language code.",
      hotel_hotel_detail_edit_validation_rating: "* Please enter the hotel grade.",
      hotel_hotel_detail_edit_validation_address: "* Please enter the address.",
      hotel_hotel_detail_edit_validation_phone: "* Please enter the phone number.",
      hotel_hotel_detail_edit_validation_email: "* Please enter hotel email address.",
      hotel_hotel_detail_edit_validation_about: "* Please enter the room description.",
      hotel_hotel_img_list_title: "HOTEL IMAGE LIST",
      hotel_hotel_img_list_label_category: "Category",
      hotel_hotel_img_list_label_type: "Type",
      hotel_hotel_img_list_label_hotel_img: "Hotel Images",
      hotel_hotel_img_list_label_date: "Date",
      hotel_hotel_img_list_button_delete: "Delete",
      hotel_hotel_img_list_button_add: "Add",
      hotel_hotel_img_add_title: "ADD HOTEL IMAGE",
      hotel_hotel_img_add_label_category: "Category",
      hotel_hotel_img_add_label_type: "Type",
      hotel_hotel_img_add_placeholder_select_box_choose: "Choose",
      hotel_hotel_img_add_select_gallery: "Gallery",
      hotel_hotel_img_add_select_hotel_side: "Hotel Side",
      hotel_hotel_img_add_select_hotel_detail: "Hotel Detail",
      hotel_hotel_img_add_select_hotel_center: "Hotel Center",
      hotel_hotel_img_add_select_hotel_front: "Hotel Front",
      hotel_hotel_img_add_select_hotel_background: "Hotel Background",
      hotel_hotel_img_add_select_logo_dark: "Logo Dark",
      hotel_hotel_img_add_select_logo_light: "Logo Light",
      hotel_hotel_img_add_select_logo_chat: "Logo Chat",
      hotel_hotel_img_add_select_card_key: "Card Key",
      hotel_hotel_img_add_select_direct: "Direct input",
      hotel_hotel_img_add_label_hotel_img: "Hotel Image",
      hotel_hotel_img_add_button_upload: "Upload",
      hotel_hotel_img_add_button_go_to_list: "Go to list",
      hotel_hotel_img_add_button_add: "Add",
      hotel_hotel_img_add_validation_category: "* Please choose a category.",
      hotel_hotel_img_add_validation_hotel_img: "* Please upload a hotel image.",
      hotel_hotel_img_detail_title: "HOTEL IMAGE INFORMATION",
      hotel_hotel_img_detail_label_category: "Category",
      hotel_hotel_img_detail_label_type: "Type",
      hotel_hotel_img_detail_label_type_gallery: "Gallery",
      hotel_hotel_img_detail_placeholder_select_box_choose: "Choose",
      hotel_hotel_img_detail_select_direct: "Direct input",
      hotel_hotel_img_detail_select_gallery: "Gallery",
      hotel_hotel_img_detail_select_hotel_side: "Hotel Side",
      hotel_hotel_img_detail_select_hotel_detail: "Hotel Detail",
      hotel_hotel_img_detail_select_hotel_center: "Hotel Center",
      hotel_hotel_img_detail_select_hotel_front: "Hotel Front",
      hotel_hotel_img_detail_select_hotel_background: "Hotel Background",
      hotel_hotel_img_detail_select_logo_dark: "Logo Dark",
      hotel_hotel_img_detail_select_logo_light: "Logo Light",
      hotel_hotel_img_detail_select_logo_chat: "Logo Chat",
      hotel_hotel_img_detail_select_card_key: "Card Key",
      hotel_hotel_img_detail_label_hotel_img: "Hotel Image",
      hotel_hotel_img_detail_button_upload: "Upload",
      hotel_hotel_img_detail_label_updated_at: "Updated At",
      hotel_hotel_img_detail_button_go_to_list: "Go to list",
      hotel_hotel_img_detail_button_delete: "Delete",
      hotel_hotel_img_detail_button_edit: "Edit",
      hotel_hotel_img_detail_button_ok: "OK",
      hotel_hotel_img_detail_button_cancel: "Cancel",
      hotel_room_list_title: "ROOM LIST",
      hotel_room_list_placeholder_select_box_choose: "Select",
      hotel_room_list_select_content: "Content",
      hotel_room_list_placeholder_search: "Search...",
      hotel_room_list_label_rate_plan: "Rate Plan",
      hotel_room_list_label_room_type: "Room Type",
      hotel_room_list_label_date: "Date",
      hotel_room_list_button_delete: "Delete",
      hotel_room_list_button_add: "Add",
      hotel_room_list_guide_empty: "List is empty",
      hotel_room_add_title: "ADD ROOM",
      hotel_room_add_label_rate_plan: "Rate Plan",
      hotel_room_add_label_room_type: "Room Type",
      hotel_room_add_button_go_to_list: "Go to list",
      hotel_room_add_button_add: "Add",
      hotel_room_add_validation_rate_plan: "* Please enter your rate plan.",
      hotel_room_add_validation_room_type: "* Please enter the room type.",
      hotel_room_detail_title: "ROOM INFORMATION",
      hotel_room_detail_label_rate_plan: "Rate Plan",
      hotel_room_detail_label_room_type: "Room Type",
      hotel_room_detail_label_room_date: "Date",
      hotel_room_detail_label_resource_information: "Resource Information",
      hotel_room_detail_label_resource_information_resource_id: "Resource ID",
      hotel_room_detail_label_resource_information_name: "Name",
      hotel_room_detail_label_resource_information_unit_price: "Unit Price",
      hotel_room_detail_label_resource_information_currency: "Currency",
      hotel_room_detail_label_resource_information_quantity: "Quantity",
      hotel_room_detail_label_resource_information_time_unit: "Time Unit",
      hotel_room_detail_label_resource_information_min_rental_time: "Min Rental Time",
      hotel_room_detail_label_resource_information_max_rental_time: "Max Rental Time",
      hotel_room_detail_label_resource_information_min_time_between_rentals: "Min Time Between Rentals",
      hotel_room_detail_label_resource_information_min_hours_to_rental: "Min Hours To Rental",
      hotel_room_detail_label_resource_information_start_quarters: "Start Quarters",
      hotel_room_detail_label_resource_information_start_hour: "Start Hour",
      hotel_room_detail_label_resource_information_end_hour: "End Hour",
      hotel_room_detail_label_resource_information_unit_names: "Unit Names",
      hotel_room_detail_button_go_to_list: "Go to list",
      hotel_room_detail_button_cancel: "Cancel",
      hotel_room_detail_button_delete: "Delete",
      hotel_room_detail_button_edit: "Edit",
      hotel_room_detail_button_ok: "OK",
      hotel_room_edit_validation_rate_plan: "* Please enter your rate plan.",
      hotel_room_edit_validation_room_type: "* Please enter the room type.",
      hotel_room_detail_list_title: "ROOM DETAIL LIST",
      hotel_room_detail_list_placeholder_select_box_choose: "Select",
      hotel_room_detail_list_select_room_type: "Room Type",
      hotel_room_detail_list_select_rate_plan: "Rate Plan",
      hotel_room_detail_list_placeholder_search: "Search...",
      hotel_room_detail_list_label_language_code: "Language Code",
      hotel_room_detail_list_label_name: "Name",
      hotel_room_detail_list_label_view: "View",
      hotel_room_detail_list_label_type: "Type",
      hotel_room_detail_list_label_room_type: "Room Type",
      hotel_room_detail_list_label_rate_plan: "Rate Plan",
      hotel_room_detail_list_label_date: "Date",
      hotel_room_detail_list_button_delete: "Delete",
      hotel_room_detail_list_button_add: "Add",
      hotel_room_detail_list_guide_empty: "List is empty",
      hotel_room_detail_add_title: "ADD ROOM DETAIL",
      hotel_room_detail_add_label_language_code: "Language Code",
      hotel_room_detail_add_placeholder_select_box_choose: "Select",
      hotel_room_detail_add_select_ko: "KR",
      hotel_room_detail_add_select_en: "EN",
      hotel_room_detail_add_select_cn: "CN",
      hotel_room_detail_add_select_jp: "JP",
      hotel_room_detail_add_label_name: "Name",
      hotel_room_detail_add_label_view: "View",
      hotel_room_detail_add_label_type: "Type",
      hotel_room_detail_add_label_bed_type: "Bed Type",
      hotel_room_detail_add_label_max_occupancy: "Max Occupancy",
      hotel_room_detail_add_label_standard_occupancy: "Standard Occupancy",
      hotel_room_detail_add_label_size: "Size (m²)",
      hotel_room_detail_add_label_checkin_time: "Checkin Time (Hour:Minutes)",
      hotel_room_detail_add_label_checkout_time: "Checkout Time (Hour:Minutes)",
      hotel_room_detail_add_label_about: "About",
      hotel_room_detail_add_label_more: "More",
      hotel_room_detail_add_label_room_type: "Room Type",
      hotel_room_detail_add_button_go_to_list: "Go to list",
      hotel_room_detail_add_button_add: "Add",
      hotel_room_detail_add_validation_room_type: "* Please select a room type.",
      hotel_room_detail_add_validation_language_code: "* Please select a language code.",
      hotel_room_detail_add_validation_name: "* Please enter the room name.",
      hotel_room_detail_add_validation_view: "* Please enter the view of the room.",
      hotel_room_detail_add_validation_type: "* Please enter room type.",
      hotel_room_detail_add_validation_bed_type: "* Please enter the bed type.",
      hotel_room_detail_add_validation_max_occupancy: "* Please enter the maximum number of guests.",
      hotel_room_detail_add_validation_standard_occupancy: "* Please enter the minimum number of guests.",
      hotel_room_detail_add_validation_checkin_time: "* Please enter the check-in time.",
      hotel_room_detail_add_validation_checkout_time: "* Please enter the check-out time.",
      hotel_room_detail_add_validation_about: "* Please enter the room description.",
      hotel_room_detail_add_validation_more: "* Please enter any additional room description.",
      hotel_room_detail_detail_title: "ROOM DETAIL INFORMATION",
      hotel_room_detail_detail_label_details: "Room Details",
      hotel_room_detail_detail_label_name: "Name",
      hotel_room_detail_detail_label_language_code: "Language Code",
      hotel_room_detail_detail_placeholder_select_box_choose: "Select",
      hotel_room_detail_detail_select_ko: "KR",
      hotel_room_detail_detail_select_en: "EN",
      hotel_room_detail_detail_select_cn: "CN",
      hotel_room_detail_detail_select_jp: "JP",
      hotel_room_detail_detail_label_view: "View",
      hotel_room_detail_detail_label_type: "Type",
      hotel_room_detail_detail_label_bed_type: "Bed Type",
      hotel_room_detail_detail_label_max_occupancy: "Max Occupancy",
      hotel_room_detail_detail_label_standard_occupancy: "Standard Occupancy",
      hotel_room_detail_detail_label_size: "Size (m²)",
      hotel_room_detail_detail_label_checkin_time: "Checkin Time (Hour:Minutes)",
      hotel_room_detail_detail_label_checkout_time: "Checkout Time (Hour:Minutes)",
      hotel_room_detail_detail_label_about: "About",
      hotel_room_detail_detail_label_more: "More",
      hotel_room_detail_detail_label_date: "Date",
      hotel_room_detail_detail_title_amenity: "Amenity",
      hotel_room_detail_detail_type: "Type",
      hotel_room_detail_detail_group: "Group",
      hotel_room_detail_detail_code: "Code",
      hotel_room_detail_detail_name: "Name",
      hotel_room_detail_detail_icon: "Icon",
      hotel_room_detail_detail_date: "Date",
      hotel_room_detail_detail_title_add_amenity: "Add Amenity",
      hotel_room_detail_detail_button_go_to_list: "Go to list",
      hotel_room_detail_detail_button_add: "Add",
      hotel_room_detail_detail_button_delete: "Delete",
      hotel_room_detail_detail_button_cancel: "Cancel",
      hotel_room_detail_detail_button_edit: "Edit",
      hotel_room_detail_detail_button_ok: "OK",
      hotel_room_detail_edit_validation_room_type: "* Please select a room type.",
      hotel_room_detail_edit_validation_language_code: "* Please select a language code.",
      hotel_room_detail_edit_validation_name: "* Please enter the room name.",
      hotel_room_detail_edit_validation_view: "* Please enter the view of the room.",
      hotel_room_detail_edit_validation_type: "* Please enter the room type.",
      hotel_room_detail_edit_validation_bed_type: "* Please enter the bed type.",
      hotel_room_detail_edit_validation_max_occupancy: "* Please enter the maximum number of guests.",
      hotel_room_detail_edit_validation_standard_occupancy: "* Please enter the minimum number of guests.",
      hotel_room_detail_edit_validation_size: "* Please enter the size of the room.",
      hotel_room_detail_edit_validation_checkin_time: "* Please enter the check-in time.",
      hotel_room_detail_edit_validation_checkout_time: "* Please enter the check-out time.",
      hotel_room_detail_edit_validation_about: "* Please enter the room description.",
      hotel_room_detail_edit_validation_more: "* Please enter any additional room description.",
      hotel_room_img_list_title: "ROOM IMAGE LIST",
      hotel_room_img_list_label_rate_plan: "Rate Plan",
      hotel_room_img_list_label_room_type: "Room Type",
      hotel_room_img_list_label_type: "Type",
      hotel_room_img_list_label_room_img: "Room Image",
      hotel_room_img_list_label_date: "Date",
      hotel_room_img_list_button_delete: "Delete",
      hotel_room_img_list_button_add: "Add",
      hotel_room_img_add_title: "ADD ROOM IMAGE",
      hotel_room_img_add_label_room_type: "Room Type",
      hotel_room_img_add_label_type: "Type",
      hotel_room_img_add_placeholder_select_box_choose: "Select",
      hotel_room_img_add_select_gallery: "Gallery",
      hotel_room_img_add_select_room_detail: "Room Detail",
      hotel_room_img_add_select_room_center: "Room Center",
      hotel_room_img_add_label_hotel_img: "Room Image",
      hotel_room_img_add_button_upload: "Upload",
      hotel_room_img_add_button_go_to_list: "Go to list",
      hotel_room_img_add_button_add: "Add",
      hotel_room_img_add_validation_room_type: "* Please select a room type.",
      hotel_room_img_add_validation_image_file: "* Please upload a hotel image.",
      hotel_room_img_detail_title: "ROOM IMAGE INFORMATION",
      hotel_room_img_detail_label_room_type: "Room Type",
      hotel_room_img_detail_placeholder_select_box_choose: "Select",
      hotel_room_img_detail_label_type: "Type",
      hotel_room_img_detail_select_gallery: "Gallery",
      hotel_room_img_detail_select_room_detail: "Room Detail",
      hotel_room_img_detail_select_room_center: "Room Center",
      hotel_room_img_detail_label_room_img: "Room Image",
      hotel_room_img_detail_button_upload: "Upload",
      hotel_room_img_detail_label_updated_at: "Updated At",
      hotel_room_img_detail_button_go_to_list: "Go to list",
      hotel_room_img_detail_button_delete: "Delete",
      hotel_room_img_detail_button_edit: "Edit",
      hotel_room_img_detail_button_ok: "OK",
      hotel_room_img_detail_button_cancel: "Cancel",
      hotel_room_management_room_list_list_title: "ROOM LIST",
      hotel_room_management_room_list_list_select_floor: "Floor",
      hotel_room_management_room_list_list_select_cleaning_status: "Cleaning Status",
      hotel_room_management_room_list_list_select_room_type: "Room Type",
      hotel_room_management_room_list_list_select_rooms_status: "Room Status",
      hotel_room_management_room_list_list_select_fo_status: "FO Status",
      hotel_room_management_room_list_list_select_reservation_status: "Reservation Status",
      hotel_room_management_room_list_list_placeholder_room_no: "Room No.",
      hotel_room_management_room_list_list_button_all: "All",
      hotel_room_management_room_list_list_button_change: "Change",
      hotel_room_management_room_list_list_guide_empty: "List is empty",
      hotel_room_management_room_list_detail_title: "ROOM DETAIL",
      hotel_room_management_room_list_detail_label_floor: "Floor",
      hotel_room_management_room_list_detail_label_room_no: "Room No.",
      hotel_room_management_room_list_detail_label_room_type: "Room Type",
      hotel_room_management_room_list_detail_label_room_status: "Room Status",
      hotel_room_management_room_list_detail_label_fo_status: "FO Status",
      hotel_room_management_room_list_detail_label_reservation_status: "Reservation Status",
      hotel_room_management_room_list_detail_label_created_at: "Created At",
      hotel_room_management_room_list_detail_button_status: "Status",
      hotel_room_management_room_list_detail_button_cleaning: "Cleaning",
      hotel_room_management_room_list_detail_label_history: "History",
      hotel_room_management_room_list_detail_placeholder_select_box_choose: "Select",
      hotel_room_management_room_list_detail_select_housekeeping: "Housekeeping",
      hotel_room_management_room_list_detail_placeholder_search: "Search...",
      hotel_room_management_room_list_detail_label_number: "No.",
      hotel_room_management_room_list_detail_label_housekeeping: "Housekeeping",
      hotel_room_management_room_list_detail_label_staff: "Staff",
      hotel_room_management_room_list_detail_label_date: "Date",
      hotel_room_management_room_list_detail_guide_empty: "List is empty",
      hotel_room_management_room_list_modal_title_status_message: "Room Status",
      hotel_room_management_room_list_modal_status_clean: "Clean",
      hotel_room_management_room_list_modal_status_inspected: "Inspected",
      hotel_room_management_room_list_modal_status_dirty: "Dirty",
      hotel_room_management_room_list_modal_status_pick_up: "Pick Up",
      hotel_room_management_setting_detail_title: "SETTINGS",
      hotel_room_management_setting_detail_label_total_floors: "Total floors",
      hotel_room_management_setting_detail_label_total_number_of_rooms: "Total number of rooms",
      hotel_room_management_setting_detail_label_floor_detail: "Floor Detail",
      hotel_room_management_setting_detail_label_number: "No.",
      hotel_room_management_setting_detail_label_number_of_rooms_on_the_floor: "Number of rooms on the floor",
      hotel_room_management_setting_detail_label_number_of_rooms: "Number of rooms",
      hotel_room_management_setting_detail_guide_empty: "List is empty",
      hotel_room_management_setting_detail_button_edit: "Edit",
      hotel_room_management_setting_edit_validation_total_floors: "* Please enter the total floors.",
      hotel_room_management_setting_edit_validation_number_of_rooms: "* Please enter the number of rooms.",
      hotel_room_management_setting_edit_label_total_floors: "Total floors",
      hotel_room_management_setting_edit_label_total_number_of_rooms: "Total number of rooms",
      hotel_room_management_setting_edit_label_floor_detail: "Floor Detail",
      hotel_room_management_setting_edit_label_number: "No.",
      hotel_room_management_setting_edit_label_number_of_rooms_on_the_floor: "Number of rooms on the floor",
      hotel_room_management_setting_edit_label_number_of_rooms: "Number of rooms",
      hotel_room_management_setting_edit_guide_empty: "List is empty",
      hotel_room_management_setting_edit_button_cancel: "Cancel",
      hotel_room_management_setting_edit_button_ok: "OK",
      hotel_package_list_title: "PACKAGE LIST",
      hotel_package_list_label_type: "Type",
      hotel_package_list_label_code: "Code",
      hotel_package_list_label_name: "Name",
      hotel_package_list_label_description: "Description",
      hotel_package_list_label_date: "Date",
      hotel_package_list_button_delete: "Delete",
      hotel_package_list_button_add: "Add",
      hotel_package_list_guide_empty: "List is empty",
      hotel_package_add_title: "ADD PACKAGE",
      hotel_package_add_label_type: "Type",
      hotel_package_add_placeholder_select_box_choose: "Select",
      hotel_package_add_placeholder_select_box_package: "Package",
      hotel_package_add_placeholder_select_box_transaction: "Transaction",
      hotel_package_add_label_code: "Code",
      hotel_package_add_label_name: "Name",
      hotel_package_add_label_description: "Description",
      hotel_package_add_button_go_to_list: "Go to list",
      hotel_package_add_button_add: "Add",
      hotel_package_add_validation_type: "* Please select package type.",
      hotel_package_add_validation_code: "* Please enter the package code.",
      hotel_package_add_validation_name: "* Please enter the package name.",
      hotel_package_add_validation_description: "* Please enter the package description.",
      hotel_package_detail_title: "PACKAGE INFORMATION",
      hotel_package_detail_label_type: "Type",
      hotel_package_detail_label_code: "Code",
      hotel_package_detail_label_name: "Name",
      hotel_package_detail_label_description: "description",
      hotel_package_detail_label_updated_at: "Updated At",
      hotel_package_detail_button_go_to_list: "Go to list",
      hotel_package_detail_button_cancel: "Cancel",
      hotel_package_detail_button_delete: "Delete",
      hotel_package_detail_button_edit: "Edit",
      hotel_package_detail_button_ok: "OK",
      hotel_package_edit_validation_type: "* Please select package type.",
      hotel_package_edit_validation_code: "* Please enter the package code.",
      hotel_package_edit_validation_name: "* Please enter the package name.",
      hotel_package_edit_validation_description: "* Please enter the package description.",
      hotel_package_detail_list_title: "PACKAGE DETAIL LIST",
      hotel_package_detail_list_label_code: "Code",
      hotel_package_detail_list_label_name: "Name",
      hotel_package_detail_list_label_language_code: "Language Code",
      hotel_package_detail_list_label_date: "Date",
      hotel_package_detail_list_button_delete: "Delete",
      hotel_package_detail_list_button_add: "Add",
      hotel_package_detail_list_guide_empty: "List is empty",
      hotel_package_detail_add_title: "ADD PACKAGE DETAIL",
      hotel_package_detail_add_label_name: "Name",
      hotel_package_detail_add_label_package_code: "Package Code",
      hotel_package_detail_add_placeholder_select_box_choose: "Select",
      hotel_package_detail_add_label_language_code: "Language Code",
      hotel_package_detail_add_select_ko: "KR",
      hotel_package_detail_add_select_en: "EN",
      hotel_package_detail_add_select_cn: "CN",
      hotel_package_detail_add_select_jp: "JP",
      hotel_package_detail_add_label_description: "Description",
      hotel_package_detail_add_label_content: "Content",
      hotel_package_detail_add_label_package_img: "Package Image",
      hotel_package_detail_add_button_upload: "Upload",
      hotel_package_detail_add_button_go_to_list: "Go to list",
      hotel_package_detail_add_button_add: "Add",
      hotel_package_detail_add_validation_package_code: "* Please select a package code.",
      hotel_package_detail_add_validation_language_code: "* Please select a language code.",
      hotel_package_detail_add_validation_description: "* Please enter package description.",
      hotel_package_detail_add_validation_image_file: "* Please upload a package image.",
      hotel_package_detail_add_validation_content: "* Please enter package details.",
      hotel_package_detail_detail_title: "PACKAGE DETAILS",
      hotel_package_detail_detail_label_date: "Date Updated",
      hotel_package_detail_detail_label_name: "Name",
      hotel_package_detail_detail_label_package_code: "Code",
      hotel_package_detail_detail_placeholder_select_box_choose: "Select",
      hotel_package_detail_detail_label_language_code: "Language Code",
      hotel_package_detail_detail_select_ko: "KR",
      hotel_package_detail_detail_select_en: "EN",
      hotel_package_detail_detail_select_cn: "CN",
      hotel_package_detail_detail_select_jp: "JP",
      hotel_package_detail_detail_label_description: "Description",
      hotel_package_detail_detail_label_content: "Content",
      hotel_package_detail_detail_label_package_img: "Package Image",
      hotel_package_detail_detail_button_upload: "Upload",
      hotel_package_detail_detail_button_go_to_list: "Go to list",
      hotel_package_detail_detail_button_cancel: "Cancel",
      hotel_package_detail_detail_button_delete: "Delete",
      hotel_package_detail_detail_button_edit: "Edit",
      hotel_package_detail_detail_button_ok: "OK",
      hotel_package_detail_edit_validation_package_code: "* Please select a package code.",
      hotel_package_detail_edit_validation_language_code: "* Please select a language code.",
      hotel_package_detail_edit_validation_description: "* Please enter package description.",
      hotel_package_detail_edit_validation_image_file: "* Please upload a package image.",
      hotel_package_detail_edit_validation_content: "* Please enter package details.",
      hotel_hotel_valet_setting_detail_title: "VALET PARKING SETTINGS",
      hotel_hotel_valet_setting_detail_label_date: "Date Updated",
      hotel_hotel_valet_setting_detail_label_parking: "Parking",
      hotel_hotel_valet_setting_detail_label_parking_availability: "Valet Parking Availability",
      hotel_hotel_valet_setting_detail_button_yes: "Yes",
      hotel_hotel_valet_setting_detail_button_no: "No",
      hotel_hotel_valet_setting_detail_label_time: "Time",
      hotel_hotel_valet_setting_detail_label_delay_time: "Delay Time",
      hotel_hotel_valet_setting_detail_button_15: "15 Minutes",
      hotel_hotel_valet_setting_detail_button_30: "30 Minutes",
      hotel_hotel_valet_setting_detail_button_45: "45 Minutes",
      hotel_hotel_valet_setting_detail_button_60: "60 Minutes",
      hotel_hotel_valet_setting_detail_button_as_soon_as: "As soon as available",
      hotel_hotel_valet_setting_detail_label_calculation_time: "Waiting Request Calculation Time (Hour:Minutes)",
      hotel_hotel_valet_setting_detail_label_vehicle_time: "Vehicle Return Time (Hour:Minutes)",
      hotel_hotel_valet_setting_detail_label_request_caution_time: "Request Caution Min (Minute)",
      hotel_hotel_valet_setting_detail_label_delay_available_time: "Delay Available Time (Minute)",
      hotel_hotel_valet_setting_detail_label_requirement_time: "Editable Min Requirement Time (Minute)",
      hotel_hotel_valet_setting_detail_label_log_active_hours: "Log Active Hours (Hour)",
      hotel_hotel_valet_setting_detail_label_return_additional_charge: "Return Additional Charge",
      hotel_hotel_valet_setting_detail_label_return_push_message: "Return Push Message Flag",
      hotel_hotel_valet_setting_detail_label_return_charge_count: "Return Charge Count",
      hotel_hotel_valet_setting_detail_label_return_repeat_message: "Return Repeat Flag",
      hotel_hotel_valet_setting_detail_button_edit: "Edit",
      hotel_hotel_valet_setting_edit_title: "VALET PARKING SETTINGS",
      hotel_hotel_valet_setting_edit_label_date: "Date Updated",
      hotel_hotel_valet_setting_edit_label_parking: "Parking",
      hotel_hotel_valet_setting_edit_label_parking_availability: "Valet Parking Availability",
      hotel_hotel_valet_setting_edit_button_yes: "Yes",
      hotel_hotel_valet_setting_edit_button_no: "No",
      hotel_hotel_valet_setting_edit_label_time: "Time",
      hotel_hotel_valet_setting_edit_label_delay_time: "Delay Time",
      hotel_hotel_valet_setting_edit_placeholder_delay_time: "ex) 80",
      hotel_hotel_valet_setting_edit_label_calculation_time: "Waiting Request Calculation Time",
      hotel_hotel_valet_setting_edit_placeholder_calculation_time: "ex) 24:00",
      hotel_hotel_valet_setting_edit_label_vehicle_time: "Vehicle Return Time",
      hotel_hotel_valet_setting_edit_placeholder_vehicle_time: "ex) 80",
      hotel_hotel_valet_setting_edit_button_ok: "OK",
      hotel_hotel_valet_setting_edit_validation_parking_availability: "* Please select parking availability status.",
      hotel_hotel_valet_setting_edit_validation_waiting_calculation_time: "* Please enter the waiting request time.",
      hotel_hotel_valet_setting_edit_validation_vehicle_time: "* Please enter the 'return vehicle to parking lot' time.",
      hotel_hotel_valet_setting_edit_validation_request_caution_time: "* Please enter the requested attention time.",
      hotel_hotel_valet_setting_edit_validation_requirement_time: "* Please enter the minimum required editable time.",
      hotel_hotel_valet_setting_edit_validation_charge_log_active_hours: "* Please enter your activity time records.",
      hotel_hotel_valet_setting_edit_validation_return_push_message: "* Please select the return message notification status.",
      hotel_hotel_self_setting_detail_title: "SELF PARKING SETTINGS",
      hotel_hotel_self_setting_detail_label_date: "Date Updated",
      hotel_hotel_self_setting_detail_label_parking: "Parking",
      hotel_hotel_self_setting_detail_label_parking_availability: "Self Parking Availability",
      hotel_hotel_self_setting_detail_button_yes: "Yes",
      hotel_hotel_self_setting_detail_button_no: "No",
      hotel_hotel_self_setting_detail_button_edit: "Edit",
      hotel_hotel_self_setting_edit_title: "SELF PARKING SETTINGS",
      hotel_hotel_self_setting_edit_label_date: "Date Updated",
      hotel_hotel_self_setting_edit_label_parking: "Parking",
      hotel_hotel_self_setting_edit_label_parking_availability: "Self Parking Availability",
      hotel_hotel_self_setting_edit_button_yes: "Yes",
      hotel_hotel_self_setting_edit_button_no: "No",
      hotel_hotel_self_setting_edit_placeholder_vehicle_time: "ex) 80",
      hotel_hotel_self_setting_edit_button_ok: "OK",
      hotel_hotel_service_setting_detail_title: "IN-HOUSE MENU SETTING",
      hotel_hotel_service_setting_detail_label_date: "Date Updated",
      hotel_hotel_service_setting_detail_button_yes: "Yes",
      hotel_hotel_service_setting_detail_button_no: "No",
      hotel_hotel_service_setting_detail_label_housekeeping: "Housekeeping",
      hotel_hotel_service_setting_detail_label_housekeeping_dnd: "DND",
      hotel_hotel_service_setting_detail_label_housekeeping_green_campaign: "Green Campaign",
      hotel_hotel_service_setting_detail_label_housekeeping_emergency_service: "Emergency Service",
      hotel_hotel_service_setting_detail_label_housekeeping_make_up_room: "Make Up Room",
      hotel_hotel_service_setting_detail_label_housekeeping_turndown_service: "Turndown Service",
      hotel_hotel_service_setting_detail_label_concierge: "Concierge",
      hotel_hotel_service_setting_detail_label_concierge_luggage_service: "Luggage Service",
      hotel_hotel_service_setting_detail_label_concierge_parcel_service: "Parcel Service",
      hotel_hotel_service_setting_detail_label_concierge_taxi_call_service: "Taxi Service",
      hotel_hotel_service_setting_detail_label_guest_service: "Guest Services",
      hotel_hotel_service_setting_detail_label_guest_service_amenity_service: "Amenity Service",
      hotel_hotel_service_setting_detail_label_guest_service_laundry_service: "Laundry Service",
      hotel_hotel_service_setting_detail_label_guest_service_in_room_dining: "In Room Dining",
      hotel_hotel_service_setting_detail_label_guest_service_ironing_service: "Ironing Service",
      hotel_hotel_service_setting_detail_label_etc: "Etc.",
      hotel_hotel_service_setting_detail_label_room_to_room: "Room to Room",
      hotel_hotel_service_setting_detail_label_share_key: "Share Key",
      hotel_hotel_service_setting_detail_label_in_room_control: "In Room Control",
      hotel_hotel_service_setting_detail_button_edit: "Edit",
      hotel_hotel_service_setting_detail_button_cancel: "Cancel",
      hotel_hotel_service_setting_detail_button_ok: "OK",
      hotel_service_description_list_title: "SERVICE DESCRIPTION",
      hotel_service_description_list_button_add: "Add",
      hotel_service_description_list_button_delete: "Delete",
      hotel_service_description_list_button_edit: "Edit",
      hotel_service_description_list_guide_add: "Please add service description.",
      hotel_service_description_add_title: "ADD SERVICE DESCRIPTION",
      hotel_service_description_add_select_choose: "Please choose type",
      hotel_service_description_add_select_in_room_dining: "In-Room Dining",
      hotel_service_description_add_select_luggage_service: "Luggage Service",
      hotel_service_description_add_select_parcel_service: "Parcel Service",
      hotel_service_description_add_select_taxi_call: "Taxi",
      hotel_service_description_add_select_amenity_service: "Amenity Service",
      hotel_service_description_add_select_ironing_service: "Ironing Service",
      hotel_service_description_add_select_laundry_service: "Laundry Service",
      hotel_service_description_add_select_dnd: "DND",
      hotel_service_description_add_select_green_campaign: "Green Campaign",
      hotel_service_description_add_select_emergency_service: "Emergency Service",
      hotel_service_description_add_toolbar_bold: "Bold",
      hotel_service_description_add_toolbar_italic: "Italic",
      hotel_service_description_add_toolbar_underline: "Underline",
      hotel_service_description_add_toolbar_remove_font_style: "Remove font style",
      hotel_service_description_add_toolbar_font_family: "Font family",
      hotel_service_description_add_toolbar_font_size: "Font size",
      hotel_service_description_add_toolbar_recent_color: "Recent color",
      hotel_service_description_add_toolbar_recent_more_color: "More colors",
      hotel_service_description_add_toolbar_unordered_list: "Unordered list",
      hotel_service_description_add_toolbar_ordered_list: "Ordered list",
      hotel_service_description_add_toolbar_paragraph: "Paragraph",
      hotel_service_description_add_toolbar_line_height: "Line Height",
      hotel_service_description_add_placeholder_content: "Please enter the content.",
      hotel_service_description_add_button_cancel: "Cancel",
      hotel_service_description_add_button_ok: "OK",
      hotel_service_description_add_validation_type: "* Please select a type.",
      hotel_service_description_add_validation_content: "* Please enter the details.",
      hotel_service_description_edit_title: "EDIT SERVICE DESCRIPTION",
      hotel_service_description_edit_select_kr: "KR",
      hotel_service_description_edit_select_en: "EN",
      hotel_service_description_edit_select_cn: "CN",
      hotel_service_description_edit_select_jp: "JP",
      hotel_service_description_edit_toolbar_bold: "Bold",
      hotel_service_description_edit_toolbar_italic: "Italic",
      hotel_service_description_edit_toolbar_underline: "Underline",
      hotel_service_description_edit_toolbar_remove_font_style: "Remove font style",
      hotel_service_description_edit_toolbar_font_family: "Font family",
      hotel_service_description_edit_toolbar_font_size: "Font size",
      hotel_service_description_edit_toolbar_recent_color: "Recent color",
      hotel_service_description_edit_toolbar_recent_more_color: "More colors",
      hotel_service_description_edit_toolbar_unordered_list: "Unordered list",
      hotel_service_description_edit_toolbar_ordered_list: "Ordered list",
      hotel_service_description_edit_toolbar_paragraph: "Paragraph",
      hotel_service_description_edit_toolbar_code_view: "Code view",
      hotel_service_description_edit_toolbar_line_height: "Line height",
      hotel_service_description_edit_button_append: "Append",
      hotel_service_description_edit_button_remove: "Remove",
      hotel_service_description_edit_button_cancel: "Cancel",
      hotel_service_description_edit_button_ok: "OK",
      hotel_service_description_edit_validation_language_code: "* Please select a language code that does not overlap.",
      hotel_service_description_edit_validation_content: "* Please enter the details.",
      hotel_hotel_beacon_list_title: "BEACON LIST",
      hotel_hotel_beacon_list_placeholder_search: "Search...",
      hotel_hotel_beacon_list_label_uuid: "UUID",
      hotel_hotel_beacon_list_label_code: "Code",
      hotel_hotel_beacon_list_label_type: "Type",
      hotel_hotel_beacon_list_label_content: "Content",
      hotel_hotel_beacon_list_label_date: "Date",
      hotel_hotel_beacon_list_guide_empty: "List is empty",
      hotel_hotel_beacon_list_button_delete: "Delete",
      hotel_hotel_beacon_list_button_add: "Add",
      hotel_hotel_beacon_add_title: "ADD BEACON",
      hotel_hotel_beacon_add_label_uuid: "UUID",
      hotel_hotel_beacon_add_label_code: "Code",
      hotel_hotel_beacon_add_label_type: "Type",
      hotel_hotel_beacon_add_label_content: "Content",
      hotel_hotel_beacon_add_button_go_to_list: "Go to list",
      hotel_hotel_beacon_add_button_add: "Add",
      hotel_hotel_beacon_add_validation_uuid: "* Please enter the UUID.",
      hotel_hotel_beacon_add_validation_code: "* Please enter the code.",
      hotel_hotel_beacon_add_validation_type: "* Please enter the type.",
      hotel_hotel_beacon_add_validation_content: "* Please enter the contents.",
      hotel_hotel_beacon_detail_title: "BEACON INFORMATION",
      hotel_hotel_beacon_detail_label_uuid: "UUID",
      hotel_hotel_beacon_detail_label_code: "Code",
      hotel_hotel_beacon_detail_label_type: "Type",
      hotel_hotel_beacon_detail_label_content: "Content",
      hotel_hotel_beacon_detail_label_date: "Date",
      hotel_hotel_beacon_detail_button_cancel: "Cancel",
      hotel_hotel_beacon_detail_button_go_to_list: "Go to list",
      hotel_hotel_beacon_detail_button_delete: "Delete",
      hotel_hotel_beacon_detail_button_edit: "Edit",
      hotel_hotel_beacon_edit_title: "BEACON INFORMATION",
      hotel_hotel_beacon_edit_label_uuid: "UUID",
      hotel_hotel_beacon_edit_label_code: "Code",
      hotel_hotel_beacon_edit_label_type: "Type",
      hotel_hotel_beacon_edit_label_content: "Content",
      hotel_hotel_beacon_edit_label_date: "Date",
      hotel_hotel_beacon_edit_button_cancel: "Cancel",
      hotel_hotel_beacon_edit_button_delete: "Delete",
      hotel_hotel_beacon_edit_button_ok: "OK",
      hotel_hotel_beacon_edit_validation_uuid: "* Please enter the UUID.",
      hotel_hotel_beacon_edit_validation_code: "* Please enter the code.",
      hotel_hotel_beacon_edit_validation_type: "* Please enter the type.",
      hotel_hotel_beacon_edit_validation_content: "* Please enter the contents.",
      hotel_hotel_currency_list_title: "CURRENCY LIST",
      hotel_hotel_currency_list_guide_exchange_rate: "Exchange rate(KRW) :",
      hotel_hotel_currency_list_guide_won: "{{data}} won / {{data}}",
      hotel_hotel_currency_list_placeholder_currency: "Currency ( ALL )",
      hotel_hotel_currency_list_select_display: "Currency Display",
      hotel_hotel_currency_list_select_name: "Currency Name",
      hotel_hotel_currency_list_placeholder_search: "Search...",
      hotel_hotel_currency_list_label_display: "Currency Display",
      hotel_hotel_currency_list_label_name: "Currency Name",
      hotel_hotel_currency_list_label_exchange_rate: "Exchange Rate",
      hotel_hotel_currency_list_label_date: "Date",
      hotel_hotel_currency_list_label_syne: "Sync",
      hotel_hotel_currency_list_guide_empty: "List is empty",
      hotel_hotel_currency_list_button_syne: "Sync",
      hotel_hotel_currency_list_button_delete: "Delete",
      hotel_hotel_currency_list_button_add: "Add",
      hotel_hotel_currency_add_title: "ADD CURRENCY",
      hotel_hotel_currency_add_guide_currency: "Exchange rate(KRW)",
      hotel_hotel_currency_add_guide_won: "{{data}} won / {{data}}",
      hotel_hotel_currency_add_select_display: "Currency Display",
      hotel_hotel_currency_add_select_name: "Currency Name",
      hotel_hotel_currency_add_label_exchange_rate: "Exchange Rate",
      hotel_hotel_currency_add_button_go_to_list: "Go to list",
      hotel_hotel_currency_add_button_add: "Add",
      hotel_hotel_currency_add_validation_currency_display: "* Please enter the currency symbol.",
      hotel_hotel_currency_add_validation_currency_name: "* Please enter the currency name.",
      hotel_hotel_currency_add_validation_exchange_rate: "* Please enter the exchange rate.",
      hotel_hotel_membership_list_title: "MEMBERSHIP TYPE LIST",
      hotel_hotel_membership_list_placeholder_search: "Search...",
      hotel_hotel_membership_list_label_membership_type: "Membership Type",
      hotel_hotel_membership_list_label_description: "Description",
      hotel_hotel_membership_list_label_date: "Date",
      hotel_hotel_membership_list_guide_empty: "List is empty",
      hotel_hotel_membership_list_button_delete: "Delete",
      hotel_hotel_membership_list_button_sync: "Sync",
      hotel_hotel_membership_list_button_add: "Add",
      hotel_hotel_membership_add_title: "ADD MEMBERSHIP TYPE",
      hotel_hotel_membership_add_label_membership_type: "Membership Type",
      hotel_hotel_membership_add_label_description: "Description",
      hotel_hotel_membership_add_button_go_to_list: "Go to list",
      hotel_hotel_membership_add_button_add: "Add",
      hotel_hotel_membership_add_validation_membership_type: "* Please enter your membership type.",
      hotel_hotel_membership_add_validation_description: "* Please enter your details.",
      hotel_hotel_membership_detail_title: "MEMBERSHIP TYPE INFORMATION",
      hotel_hotel_membership_detail_label_membership_type: "Membership Type",
      hotel_hotel_membership_detail_label_description: "Description",
      hotel_hotel_membership_detail_label_date: "Date",
      hotel_hotel_membership_detail_button_go_to_list: "Go to list",
      hotel_hotel_membership_detail_button_delete: "Delete",
      hotel_hotel_membership_detail_button_edit: "Edit",
      hotel_hotel_membership_edit_title: "MEMBERSHIP TYPE INFORMATION",
      hotel_hotel_membership_edit_label_membership_type: "Membership Type",
      hotel_hotel_membership_edit_label_description: "Description",
      hotel_hotel_membership_edit_label_date: "Date",
      hotel_hotel_membership_edit_button_cancel: "Cancel",
      hotel_hotel_membership_edit_button_delete: "Delete",
      hotel_hotel_membership_edit_button_ok: "OK",
      hotel_hotel_membership_edit_validation_membership_type: "* Please enter your membership type.",
      hotel_hotel_membership_edit_validation_description: "* Please enter your details.",
      hotel_Title_list_title: "TITLE LIST",
      hotel_Title_list_placeholder_search: "Search...",
      hotel_Title_list_label_title: "Title",
      hotel_Title_list_label_description: "Description",
      hotel_Title_list_label_date: "Date",
      hotel_Title_list_guide_empty: "List is empty",
      hotel_Title_list_button_delete: "Delete",
      hotel_Title_list_button_sync: "Sync",
      hotel_Title_list_button_add: "Add",
      hotel_Title_add_title: "ADD",
      hotel_Title_add_label_title: "Title",
      hotel_Title_add_label_description: "Description",
      hotel_Title_add_button_go_to_list: "Go to list",
      hotel_Title_add_button_add: "Add",
      hotel_Title_detail_title: "TITLE INFORMATION",
      hotel_Title_detail_label_title: "Title",
      hotel_Title_detail_label_description: "Description",
      hotel_Title_detail_label_date: "Date",
      hotel_Title_detail_button_cancel: "Cancel",
      hotel_Title_detail_button_go_to_list: "Go to list",
      hotel_Title_detail_button_delete: "Delete",
      hotel_hotel_card_type_list_title: "CARD TYPE",
      hotel_hotel_card_type_list_label_ok: "NO.",
      hotel_hotel_card_type_list_label_value: "Value",
      hotel_hotel_card_type_list_label_description: "Description",
      hotel_hotel_payment_list_title: "PAYMENT LIST",
      hotel_hotel_payment_list_placeholder_user_name: "User name",
      hotel_hotel_payment_list_placeholder_search: "Search...",
      hotel_hotel_payment_list_label_no: "NO.",
      hotel_hotel_payment_list_label_reservation_id: "Reservation ID",
      hotel_hotel_payment_list_label_user_name: "User name",
      hotel_hotel_payment_list_label_long_info: "Long info",
      hotel_hotel_payment_list_label_amount: "Amount",
      hotel_hotel_payment_list_label_unit: "Unit",
      hotel_hotel_payment_list_label_pg_status_code: "PG status code",
      hotel_hotel_payment_list_label_pg_status_message: "PG status message",
      hotel_hotel_payment_list_label_date: "Date",
      hotel_hotel_payment_list_guide_empty: "List is empty",
      hotel_hotel_payment_detail_label_order_no: "Order No.",
      hotel_hotel_payment_detail_label_user: "User",
      hotel_hotel_payment_detail_label_user_profile_id: "Profile ID",
      hotel_hotel_payment_detail_label_user_title: "Title",
      hotel_hotel_payment_detail_label_user_first_name: "First Name",
      hotel_hotel_payment_detail_label_user_last_name: "Last Name",
      hotel_hotel_payment_detail_label_user_email: "Email",
      hotel_hotel_payment_detail_label_user_date: "Date",
      hotel_hotel_payment_detail_label_reservation: "Reservation",
      hotel_hotel_payment_detail_label_reservation_confirmation_no: "Confirmation No.",
      hotel_hotel_payment_detail_label_reservation_reservation_id: "Reservation ID",
      hotel_hotel_payment_detail_label_reservation_reservation_status: "Reservation Status",
      hotel_hotel_payment_detail_label_reservation_check_in_date: "Check In Date",
      hotel_hotel_payment_detail_label_reservation_check_out_date: "Check Out Date",
      hotel_hotel_payment_detail_label_payment: "Payment",
      hotel_hotel_payment_detail_button_go_to_list: "Go to list",
      hotel_hotel_payment_detail_button_go_to_log: "Go to log list",
      hotel_hotel_payment_detail_label_payment_order_no: "Order No.",
      hotel_hotel_payment_detail_label_payment_reservation_id: "Reservation ID",
      hotel_hotel_payment_detail_label_payment_long_info: "Long Info",
      hotel_hotel_payment_detail_label_payment_folio_view_no: "Folio View No.",
      hotel_hotel_payment_detail_label_payment_amount: "Amount",
      hotel_hotel_payment_detail_label_payment_unit: "Unit",
      hotel_hotel_payment_detail_label_payment_pg_order_no: "PG Order No.",
      hotel_hotel_payment_detail_label_payment_pg_status: "PG Status",
      hotel_hotel_payment_detail_label_payment_pg_status_code: "PG Status Code",
      hotel_hotel_payment_detail_label_payment_pg_status_message: "PG Status Message",
      hotel_hotel_payment_detail_label_payment_pg_amount: "PG Amount",
      hotel_hotel_payment_detail_label_payment_pg_unit: "PG Unit",
      hotel_hotel_payment_detail_label_payment_pg_billing_no: "PG Billing No.",
      hotel_hotel_payment_detail_label_payment_confirmed_at: "Confirmed At",
      hotel_hotel_payment_detail_label_payment_created_at: "Created At",
      hotel_hotel_payment_detail_label_payment_updated_at: "Updated At",
      hotel_hotel_payment_detail_label_schedule: "Schedule",
      hotel_hotel_payment_detail_label_schedule_rule_type: "Rule Type",
      hotel_hotel_payment_detail_label_schedule_name: "Name",
      hotel_hotel_payment_detail_label_schedule_description: "Description",
      hotel_hotel_payment_detail_label_schedule_rate_code: "Rate Code",
      hotel_hotel_payment_detail_label_schedule_begin_date: "Begin Date",
      hotel_hotel_payment_detail_label_schedule_end_date: "End Date",
      hotel_hotel_payment_detail_label_schedule_day_of_the_week: "Day Of The Week",
      hotel_hotel_payment_detail_label_schedule_created_at: "CreatedAt",
      hotel_hotel_payment_detail_label_schedule_updated_at: "UpdatedAt",
      hotel_hotel_payment_detail_label_reservation_rule: "Reservation Rule",
      hotel_hotel_payment_detail_label_rule_name: "Rule Name",
      hotel_hotel_payment_detail_label_description: "Description",
      hotel_hotel_payment_detail_label_reservation_amount: "Reservation Amount",
      hotel_hotel_payment_detail_label_day_before_arrival: "Day Before Arrival",
      hotel_hotel_payment_detail_label_before_time: "Before Time",
      hotel_hotel_payment_detail_label_deposit_rule: "Deposit Rule",
      hotel_hotel_payment_detail_label_type: "Type",
      hotel_hotel_payment_detail_label_deposit_amount: "Deposit Amount",
      hotel_hotel_payment_detail_label_cancellation_rule: "Cancellation Rule",
      hotel_hotel_payment_detail_label_no_show_rule: "No Show Rule",
      hotel_hotel_payment_detail_label_source_code: "Source Code",
      hotel_hotel_payment_detail_label_penalty_amount: "Penalty Amount",
      hotel_hotel_payment_detail_label_guarantee_type: "Guarantee Type",
      hotel_hotel_payment_detail_label_posting_rule: "Posting Rule",
      hotel_payment_history_log_list_title: "Payment Log List",
      hotel_payment_history_log_list_label_no: "NO.",
      hotel_payment_history_log_list_label_type: "Type",
      hotel_payment_history_log_list_label_method: "Method",
      hotel_payment_history_log_list_label_path: "Paty",
      hotel_payment_history_log_list_label_status: "Statue",
      hotel_payment_history_log_list_label_success: "Success",
      hotel_payment_history_log_list_label_created_at: "Created At",
      hotel_payment_history_log_list_guide_empty: "List is empty",
      hotel_payment_history_log_list_button_go_to_detail: "Go to Detail",
      hotel_payment_history_log_detail_title: "Payment Log Detail",
      hotel_payment_history_log_detail_label_id: "ID",
      hotel_payment_history_log_detail_label_hotel_id: "Hotel ID",
      hotel_payment_history_log_detail_label_payment_id: "Payment ID",
      hotel_payment_history_log_detail_label_transfer_code: "Transfer Code",
      hotel_payment_history_log_detail_label_type: "Type",
      hotel_payment_history_log_detail_label_method: "Method",
      hotel_payment_history_log_detail_label_path: "Path",
      hotel_payment_history_log_detail_label_header: "Header",
      hotel_payment_history_log_detail_label_param: "Param",
      hotel_payment_history_log_detail_label_body: "Body",
      hotel_payment_history_log_detail_label_status: "Status",
      hotel_payment_history_log_detail_label_session: "Session",
      hotel_payment_history_log_detail_label_created_at: "Created At",
      hotel_payment_history_log_detail_button_go_to_list: "Go to list",
      hotel_hotel_reservation_email_edit_title: "RESERVATION EMAIL FORMAT",
      hotel_hotel_reservation_email_edit_select_kr: "KR",
      hotel_hotel_reservation_email_edit_select_en: "EN",
      hotel_hotel_reservation_email_edit_select_cn: "CN",
      hotel_hotel_reservation_email_edit_select_jp: "JP",
      hotel_hotel_reservation_email_edit_select_ms: "MS",
      hotel_hotel_reservation_email_edit_button_remove: "Remove",
      hotel_hotel_reservation_email_edit_button_append: "Append",
      hotel_hotel_reservation_email_edit_button_save: "Save",
      hotel_hotel_reservation_email_edit_toolbar_bold: "Bold",
      hotel_hotel_reservation_email_edit_toolbar_italic: "Italic",
      hotel_hotel_reservation_email_edit_toolbar_underline: "Underline",
      hotel_hotel_reservation_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_reservation_email_edit_toolbar_remove_font_style: "Remove font style",
      hotel_hotel_reservation_email_edit_toolbar_font_family: "Font family",
      hotel_hotel_reservation_email_edit_toolbar_font_size: "Font size",
      hotel_hotel_reservation_email_edit_toolbar_text_color: "Text color",
      hotel_hotel_reservation_email_edit_toolbar_text_more_color: "More colors",
      hotel_hotel_reservation_email_edit_toolbar_recent_color: "Recent color",
      hotel_hotel_reservation_email_edit_toolbar_recent_more_color: "More colors",
      hotel_hotel_reservation_email_edit_toolbar_table: "Table",
      hotel_hotel_reservation_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_reservation_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_reservation_email_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_reservation_email_edit_toolbar_line_height: "Line height",
      hotel_hotel_reservation_email_edit_toolbar_picture: "Picture",
      hotel_hotel_reservation_email_edit_toolbar_code_view: "Code view",
      hotel_hotel_reservation_email_edit_toolbar_full_screen: "Full screen",
      hotel_hotel_reservation_email_edit_toolbar_help: "Help",
      hotel_hotel_reservation_email_edit_toolbar_tip: "Tip",
      hotel_hotel_reservation_email_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_reservation_email_edit_validation_content: "* Please enter your details.",
      hotel_hotel_reservation_email_edit_validation_language_code: "* Please select a language code that does not overlap.",
      hotel_hotel_reservation_email_detail_title: "RESERVATION EMAIL CONTENTS",
      hotel_hotel_reservation_email_detail_label_confurmation_number: "Confirmation Number",
      hotel_hotel_reservation_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_email_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_reservation_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_email_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_reservation_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_email_detail_label_departure_date: "Departure Date",
      hotel_hotel_reservation_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_email_detail_label_room_type: "Room Type",
      hotel_hotel_reservation_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_email_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_hotel_reservation_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_email_detail_label_room_rate: "Room Rate",
      hotel_hotel_reservation_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_email_detail_label_total_rate: "Total Rate",
      hotel_hotel_reservation_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_email_detail_label_current_balance: "Current Balance",
      hotel_hotel_reservation_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_email_detail_label_room_number: "Room Number",
      hotel_hotel_reservation_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_email_detail_label_adults: "Adults",
      hotel_hotel_reservation_email_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_email_detail_label_children: "Children",
      hotel_hotel_reservation_email_detail_example_children: "{{children}}",
      hotel_hotel_reservation_email_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_reservation_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_email_detail_label_name_title: "Name Title",
      hotel_hotel_reservation_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_email_detail_label_first_name: "First Name",
      hotel_hotel_reservation_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_email_detail_label_last_name: "Last Name",
      hotel_hotel_reservation_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_email_detail_label_country_code: "Country Code",
      hotel_hotel_reservation_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_email_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_reservation_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_email_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_reservation_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_email_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_reservation_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_email_detail_label_phone: "Mobile Phone No.",
      hotel_hotel_reservation_email_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_email_detail_label_email: "Email",
      hotel_hotel_reservation_email_detail_example_email: "{{email}}",
      hotel_hotel_reservation_email_detail_label_accompany: "Accompany",
      hotel_hotel_reservation_email_detail_example_accompany: "{{accompany}}",
      hotel_hotel_reservation_pdf_edit_title: "RESERVATION PDF FORMAT",
      hotel_hotel_reservation_pdf_edit_select_kr: "KR",
      hotel_hotel_reservation_pdf_edit_select_en: "EN",
      hotel_hotel_reservation_pdf_edit_select_cn: "CN",
      hotel_hotel_reservation_pdf_edit_select_jp: "JP",
      hotel_hotel_reservation_pdf_edit_select_ms: "MS",
      hotel_hotel_reservation_pdf_edit_button_remove: "Remove",
      hotel_hotel_reservation_pdf_edit_button_append: "Append",
      hotel_hotel_reservation_pdf_edit_button_save: "Save",
      hotel_hotel_reservation_pdf_edit_toolbar_bold: "Bold",
      hotel_hotel_reservation_pdf_edit_toolbar_italic: "Italic",
      hotel_hotel_reservation_pdf_edit_toolbar_underline: "Underline",
      hotel_hotel_reservation_pdf_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_reservation_pdf_edit_toolbar_remove_font_style: "Remove font style",
      hotel_hotel_reservation_pdf_edit_toolbar_font_family: "Font family",
      hotel_hotel_reservation_pdf_edit_toolbar_font_size: "Font size",
      hotel_hotel_reservation_pdf_edit_toolbar_text_color: "Text color",
      hotel_hotel_reservation_pdf_edit_toolbar_text_more_color: "More colors",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_color: "Recent color",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_more_color: "More colors",
      hotel_hotel_reservation_pdf_edit_toolbar_table: "Table",
      hotel_hotel_reservation_pdf_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_reservation_pdf_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_reservation_pdf_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_reservation_pdf_edit_toolbar_line_height: "Line height",
      hotel_hotel_reservation_pdf_edit_toolbar_picture: "Picture",
      hotel_hotel_reservation_pdf_edit_toolbar_code_view: "Code view",
      hotel_hotel_reservation_pdf_edit_toolbar_full_screen: "Full screen",
      hotel_hotel_reservation_pdf_edit_toolbar_help: "Help",
      hotel_hotel_reservation_pdf_edit_toolbar_tip: "Tip",
      hotel_hotel_reservation_pdf_edit_toolbar_download_pdf: "Download PDF",
      hotel_hotel_reservation_pdf_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_reservation_pdf_edit_validation_content: "* Please enter your details.",
      hotel_hotel_reservation_pdf_edit_validation_language_code: "* Please select a language code that does not overlap.",
      hotel_hotel_reservation_pdf_detail_title: "RESERVATION PDF CONTENTS",
      hotel_hotel_reservation_pdf_detail_label_confurmation_number: "Confirmation Number",
      hotel_hotel_reservation_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_pdf_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_reservation_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_pdf_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_reservation_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_pdf_detail_label_departure_date: "Departure Date",
      hotel_hotel_reservation_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_pdf_detail_label_room_type: "Room Type",
      hotel_hotel_reservation_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate_currency: "RoomRate Currency",
      hotel_hotel_reservation_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate: "Room Rate",
      hotel_hotel_reservation_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_pdf_detail_label_total_rate: "Total Rate",
      hotel_hotel_reservation_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_pdf_detail_label_current_balance: "Current Balance",
      hotel_hotel_reservation_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_pdf_detail_label_room_number: "Room Number",
      hotel_hotel_reservation_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_pdf_detail_label_adults: "Adults",
      hotel_hotel_reservation_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_pdf_detail_label_children: "Children",
      hotel_hotel_reservation_pdf_detail_example_children: "{{children}}",
      hotel_hotel_reservation_pdf_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_reservation_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_pdf_detail_label_name_title: "Name Title",
      hotel_hotel_reservation_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_pdf_detail_label_first_name: "First Name",
      hotel_hotel_reservation_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_pdf_detail_label_last_name: "Last Name",
      hotel_hotel_reservation_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_pdf_detail_label_country_code: "Country Code",
      hotel_hotel_reservation_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_reservation_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_reservation_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_reservation_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_pdf_detail_label_phone: "Mobile Phone No.",
      hotel_hotel_reservation_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_pdf_detail_label_email: "Email",
      hotel_hotel_reservation_pdf_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_email_edit_title: "ACCOMPANY RESERVATION EMAIL",
      hotel_hotel_reservation_accompany_email_edit_select_kr: "KR",
      hotel_hotel_reservation_accompany_email_edit_select_en: "EN",
      hotel_hotel_reservation_accompany_email_edit_select_cn: "CN",
      hotel_hotel_reservation_accompany_email_edit_select_jp: "JP",
      hotel_hotel_reservation_accompany_email_edit_select_ms: "MS",
      hotel_hotel_reservation_accompany_email_edit_button_remove: "Remove",
      hotel_hotel_reservation_accompany_email_edit_button_append: "Append",
      hotel_hotel_reservation_accompany_email_edit_button_save: "Save",
      hotel_hotel_reservation_accompany_email_edit_toolbar_bold: "Bold",
      hotel_hotel_reservation_accompany_email_edit_toolbar_italic: "Italic",
      hotel_hotel_reservation_accompany_email_edit_toolbar_underline: "Underline",
      hotel_hotel_reservation_accompany_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_reservation_accompany_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_family: "Font Family",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_size: "Font Size",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_color: "Text Color",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_more_color: "More Color",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_color: "Recent Color",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_more_color: "More Color",
      hotel_hotel_reservation_accompany_email_edit_toolbar_table: "Table",
      hotel_hotel_reservation_accompany_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_reservation_accompany_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_reservation_accompany_email_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_reservation_accompany_email_edit_toolbar_line_height: "Line Height",
      hotel_hotel_reservation_accompany_email_edit_toolbar_picture: "Picture",
      hotel_hotel_reservation_accompany_email_edit_toolbar_code_view: "Code View",
      hotel_hotel_reservation_accompany_email_edit_toolbar_full_screen: "Full Screen",
      hotel_hotel_reservation_accompany_email_edit_toolbar_help: "Help",
      hotel_hotel_reservation_accompany_email_edit_toolbar_tip: "Tip",
      hotel_hotel_reservation_accompany_email_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_reservation_accompany_email_detail_title: "ACCOMPANY RESERVATION EMAIL CONTENTS",
      hotel_hotel_reservation_accompany_email_detail_label_confurmation_number: "Confirmation Number",
      hotel_hotel_reservation_accompany_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_accompany_email_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_reservation_accompany_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_accompany_email_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_reservation_accompany_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_accompany_email_detail_label_departure_date: "Departure Date",
      hotel_hotel_reservation_accompany_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_type: "Room Type",
      hotel_hotel_reservation_accompany_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate_currency: "RoomRate Currency",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate: "Room Rate",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_accompany_email_detail_label_total_rate: "Total Rate",
      hotel_hotel_reservation_accompany_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_accompany_email_detail_label_current_balance: "Current Balance",
      hotel_hotel_reservation_accompany_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_number: "Room Number",
      hotel_hotel_reservation_accompany_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_accompany_email_detail_label_adults: "Adults",
      hotel_hotel_reservation_accompany_email_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_accompany_email_detail_label_children: "Children",
      hotel_hotel_reservation_accompany_email_detail_example_children: "{{children}}",
      hotel_hotel_reservation_accompany_email_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_reservation_accompany_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_accompany_email_detail_label_name_title: "Name Title",
      hotel_hotel_reservation_accompany_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_first_name: "First Name",
      hotel_hotel_reservation_accompany_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_last_name: "Last Name",
      hotel_hotel_reservation_accompany_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_accompany_email_detail_label_country_code: "Country Code",
      hotel_hotel_reservation_accompany_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_accompany_email_detail_label_phone: "Mobile Phone No.",
      hotel_hotel_reservation_accompany_email_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_accompany_email_detail_label_email: "Email",
      hotel_hotel_reservation_accompany_email_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_title: "Accompany Title",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_first_name: "Accompany First Name",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_last_name: "Accompany Last Name",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_edit_title: "ACCOMPANY MODIFY RESERVATION EMAIL",
      hotel_document_accom_reservation_change_edit_select_kr: "KR",
      hotel_document_accom_reservation_change_edit_select_en: "EN",
      hotel_document_accom_reservation_change_edit_select_cn: "CN",
      hotel_document_accom_reservation_change_edit_select_jp: "JP",
      hotel_document_accom_reservation_change_edit_select_ms: "MS",
      hotel_document_accom_reservation_change_edit_button_remove: "remove",
      hotel_document_accom_reservation_change_edit_button_append: "Append",
      hotel_document_accom_reservation_change_edit_button_save: "Save",
      hotel_document_accom_reservation_change_edit_toolbar_bold: "Bold",
      hotel_document_accom_reservation_change_edit_toolbar_italic: "Italic",
      hotel_document_accom_reservation_change_edit_toolbar_underline: "Underline",
      hotel_document_accom_reservation_change_edit_toolbar_strikethrough: "Strikethrough",
      hotel_document_accom_reservation_change_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_document_accom_reservation_change_edit_toolbar_font_family: "Font Family",
      hotel_document_accom_reservation_change_edit_toolbar_font_size: "Font Size",
      hotel_document_accom_reservation_change_edit_toolbar_text_color: "Text Color",
      hotel_document_accom_reservation_change_edit_toolbar_text_more_color: "More Color",
      hotel_document_accom_reservation_change_edit_toolbar_recent_color: "Recent Color",
      hotel_document_accom_reservation_change_edit_toolbar_recent_more_color: "More Color",
      hotel_document_accom_reservation_change_edit_toolbar_table: "Table",
      hotel_document_accom_reservation_change_edit_toolbar_unordered_list: "Unordered list",
      hotel_document_accom_reservation_change_edit_toolbar_ordered_list: "Ordered list",
      hotel_document_accom_reservation_change_edit_toolbar_paragraph: "Paragraph",
      hotel_document_accom_reservation_change_edit_toolbar_line_height: "Line Height",
      hotel_document_accom_reservation_change_edit_toolbar_picture: "Picture",
      hotel_document_accom_reservation_change_edit_toolbar_code_view: "Code View",
      hotel_document_accom_reservation_change_edit_toolbar_full_screen: "Full Screen",
      hotel_document_accom_reservation_change_edit_toolbar_help: "Help",
      hotel_document_accom_reservation_change_edit_toolbar_tip: "Tip",
      hotel_document_accom_reservation_change_edit_toolbar_download_pdf: "Download PDF",
      hotel_document_accom_reservation_change_edit_placeholder_content: "Please enter the content.",
      hotel_document_accom_reservation_change_detail_title: "ACCOMPANY RESERVATION CHANGE CONTENTS",
      hotel_document_accom_reservation_change_detail_label_confirmation_number: "Confirmation Number",
      hotel_document_accom_reservation_change_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_document_accom_reservation_change_detail_label_accom_first_name: "Accompany First Name",
      hotel_document_accom_reservation_change_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_change_detail_label_accom_last_name: "Accompany Last Name",
      hotel_document_accom_reservation_change_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_detail_label_first_name: "First Name",
      hotel_document_accom_reservation_change_detail_example_first_name: "{{firstName}}",
      hotel_document_accom_reservation_change_detail_label_last_name: "Last Name",
      hotel_document_accom_reservation_change_detail_example_last_name: "{{lastName}}",
      hotel_document_accom_reservation_change_detail_label_room_type: "Room Type",
      hotel_document_accom_reservation_change_detail_example_room_type: "{{roomType}}",
      hotel_document_accom_reservation_change_detail_label_arrival_date: "Arrival Date",
      hotel_document_accom_reservation_change_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_document_accom_reservation_change_detail_label_departure_date: "Departure Date",
      hotel_document_accom_reservation_change_detail_example_departure_date: "{{departureDate}}",
      hotel_document_accom_reservation_change_detail_label_accom_title: "Accompany Title",
      hotel_document_accom_reservation_change_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_change_detail_label_adults: "Adults",
      hotel_document_accom_reservation_change_detail_example_adults: "{{adults}}",
      hotel_document_accom_reservation_change_detail_label_night: "Night",
      hotel_document_accom_reservation_change_detail_example_night: "{{night}}",
      hotel_document_accom_reservation_change_detail_label_room_rate: "Room Rate",
      hotel_document_accom_reservation_change_detail_example_room_rate: "{{roomRate}}",
      hotel_document_accom_reservation_change_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_document_accom_reservation_change_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_document_accom_reservation_change_detail_label_total_rate: "Total Rate",
      hotel_document_accom_reservation_change_detail_example_total_rate: "{{totalRate}}",
      hotel_document_accom_reservation_change_detail_label_cancellation: "Cancellation Policy",
      hotel_document_accom_reservation_change_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_document_accom_reservation_cancel_edit_title: "ACCOMPANY RESERVATION CANCEL EMAIL",
      hotel_document_accom_reservation_cancel_edit_select_kr: "KR",
      hotel_document_accom_reservation_cancel_edit_select_en: "EN",
      hotel_document_accom_reservation_cancel_edit_select_cn: "CN",
      hotel_document_accom_reservation_cancel_edit_select_jp: "JP",
      hotel_document_accom_reservation_cancel_edit_select_ms: "MS",
      hotel_document_accom_reservation_cancel_edit_button_remove: "remove",
      hotel_document_accom_reservation_cancel_edit_button_append: "Append",
      hotel_document_accom_reservation_cancel_edit_button_save: "Save",
      hotel_document_accom_reservation_cancel_edit_toolbar_bold: "Bold",
      hotel_document_accom_reservation_cancel_edit_toolbar_italic: "Italic",
      hotel_document_accom_reservation_cancel_edit_toolbar_underline: "Underline",
      hotel_document_accom_reservation_cancel_edit_toolbar_strikethrough: "Strikethrough",
      hotel_document_accom_reservation_cancel_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_family: "Font Family",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_size: "Font Size",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_color: "Text Color",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_more_color: "More Color",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_color: "Recent Color",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_more_color: "More Color",
      hotel_document_accom_reservation_cancel_edit_toolbar_table: "Table",
      hotel_document_accom_reservation_cancel_edit_toolbar_unordered_list: "Unordered list",
      hotel_document_accom_reservation_cancel_edit_toolbar_ordered_list: "Ordered list",
      hotel_document_accom_reservation_cancel_edit_toolbar_paragraph: "Paragraph",
      hotel_document_accom_reservation_cancel_edit_toolbar_line_height: "Line Height",
      hotel_document_accom_reservation_cancel_edit_toolbar_picture: "Picture",
      hotel_document_accom_reservation_cancel_edit_toolbar_code_view: "Code View",
      hotel_document_accom_reservation_cancel_edit_toolbar_full_screen: "Full Screen",
      hotel_document_accom_reservation_cancel_edit_toolbar_help: "Help",
      hotel_document_accom_reservation_cancel_edit_toolbar_tip: "Tip",
      hotel_document_accom_reservation_cancel_edit_toolbar_download_pdf: "Download PDF",
      hotel_document_accom_reservation_cancel_edit_placeholder_content: "Please enter the content.",
      hotel_document_accom_reservation_cancel_detail_title: "ACCOMPANY RESERVATION CANCEL CONTENTS",
      hotel_document_accom_reservation_cancel_detail_label_confirmation_number: "Confirmation Number",
      hotel_document_accom_reservation_cancel_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_first_name: "Accompany First Name",
      hotel_document_accom_reservation_cancel_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_last_name: "Accompany Last Name",
      hotel_document_accom_reservation_cancel_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_cancel_detail_label_first_name: "First Name",
      hotel_document_accom_reservation_cancel_detail_example_first_name: "{{firstName}}",
      hotel_document_accom_reservation_cancel_detail_label_last_name: "Last Name",
      hotel_document_accom_reservation_cancel_detail_example_last_name: "{{lastName}}",
      hotel_document_accom_reservation_cancel_detail_label_room_type: "Room Type",
      hotel_document_accom_reservation_cancel_detail_example_room_type: "{{roomType}}",
      hotel_document_accom_reservation_cancel_detail_label_arrival_date: "Arrival Date",
      hotel_document_accom_reservation_cancel_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_document_accom_reservation_cancel_detail_label_departure_date: "Departure Date",
      hotel_document_accom_reservation_cancel_detail_example_departure_date: "{{departureDate}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_title: "Accompany Title",
      hotel_document_accom_reservation_cancel_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_cancel_detail_label_adults: "Adults",
      hotel_document_accom_reservation_cancel_detail_example_adults: "{{adults}}",
      hotel_document_accom_reservation_cancel_detail_label_night: "Night",
      hotel_document_accom_reservation_cancel_detail_example_night: "{{night}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate: "Room Rate",
      hotel_document_accom_reservation_cancel_detail_example_room_rate: "{{roomRate}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_document_accom_reservation_cancel_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_document_accom_reservation_cancel_detail_label_total_rate: "Total Rate",
      hotel_document_accom_reservation_cancel_detail_example_total_rate: "{{totalRate}}",
      hotel_document_accom_reservation_cancel_detail_label_cancellation: "Cancellation Policy",
      hotel_document_accom_reservation_cancel_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_hotel_reservation_accompany_pdf_edit_title: "ACCOMPANY RESERVATION PDF FORMAT",
      hotel_hotel_reservation_accompany_pdf_edit_select_kr: "KR",
      hotel_hotel_reservation_accompany_pdf_edit_select_en: "EN",
      hotel_hotel_reservation_accompany_pdf_edit_select_cn: "CN",
      hotel_hotel_reservation_accompany_pdf_edit_select_jp: "JP",
      hotel_hotel_reservation_accompany_pdf_edit_select_ms: "MS",
      hotel_hotel_reservation_accompany_pdf_edit_button_remove: "Remove",
      hotel_hotel_reservation_accompany_pdf_edit_button_append: "Append",
      hotel_hotel_reservation_accompany_pdf_edit_button_save: "Save",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_bold: "Bold",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_italic: "Italic",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_underline: "Underline",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_family: "Font Family",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_size: "Font Size",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_color: "Text Color",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_more_color: "More Color",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_color: "Recent Color",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_more_color: "More Color",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_table: "Table",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_line_height: "Line Height",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_picture: "Picture",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_code_view: "Code View",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_full_screen: "Full Screen",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_help: "Help",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_tip: "Tip",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_download_pdf: "Download PDF",
      hotel_hotel_reservation_accompany_pdf_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_reservation_accompany_pdf_detail_title: "ACCOMPANY RESERVATION PDF CONTENTS",
      hotel_hotel_reservation_accompany_pdf_detail_label_confurmation_number: "Confirmation Number",
      hotel_hotel_reservation_accompany_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_reservation_accompany_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_reservation_accompany_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_departure_date: "Departure Date",
      hotel_hotel_reservation_accompany_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_type: "Room Type",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate_currency: "RoomRate Currency",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate: "Room Rate",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_total_rate: "Total Rate",
      hotel_hotel_reservation_accompany_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_current_balance: "Current Balance",
      hotel_hotel_reservation_accompany_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_number: "Room Number",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_adults: "Adults",
      hotel_hotel_reservation_accompany_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_children: "Children",
      hotel_hotel_reservation_accompany_pdf_detail_example_children: "{{children}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_reservation_accompany_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_name_title: "Name Title",
      hotel_hotel_reservation_accompany_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_first_name: "First Name",
      hotel_hotel_reservation_accompany_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_last_name: "Last Name",
      hotel_hotel_reservation_accompany_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_country_code: "Country Code",
      hotel_hotel_reservation_accompany_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_phone: "Mobile Phone No.",
      hotel_hotel_reservation_accompany_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_email: "Email",
      hotel_hotel_reservation_accompany_pdf_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_title: "Accompany Title",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_first_name: "Accompany First Name",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_last_name: "Accompany Last Name",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_hotel_folio_email_edit_title: "FOLIO Email FORMAT",
      hotel_hotel_folio_email_edit_select_kr: "KR",
      hotel_hotel_folio_email_edit_select_en: "EN",
      hotel_hotel_folio_email_edit_select_cn: "CN",
      hotel_hotel_folio_email_edit_select_jp: "JP",
      hotel_hotel_folio_email_edit_select_ms: "MS",
      hotel_hotel_folio_email_edit_button_remove: "remove",
      hotel_hotel_folio_email_edit_button_append: "Append",
      hotel_hotel_folio_email_edit_button_save: "Save",
      hotel_hotel_folio_email_edit_toolbar_bold: "Bold",
      hotel_hotel_folio_email_edit_toolbar_italic: "Italic",
      hotel_hotel_folio_email_edit_toolbar_underline: "Underline",
      hotel_hotel_folio_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_folio_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_hotel_folio_email_edit_toolbar_font_family: "Font Family",
      hotel_hotel_folio_email_edit_toolbar_font_size: "Font Size",
      hotel_hotel_folio_email_edit_toolbar_text_color: "Text Color",
      hotel_hotel_folio_email_edit_toolbar_text_more_color: "More Color",
      hotel_hotel_folio_email_edit_toolbar_recent_color: "Recent Color",
      hotel_hotel_folio_email_edit_toolbar_recent_more_color: "More Color",
      hotel_hotel_folio_email_edit_toolbar_table: "Table",
      hotel_hotel_folio_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_folio_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_folio_email_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_folio_email_edit_toolbar_line_height: "Line Height",
      hotel_hotel_folio_email_edit_toolbar_picture: "Picture",
      hotel_hotel_folio_email_edit_toolbar_code_view: "Code View",
      hotel_hotel_folio_email_edit_toolbar_full_screen: "Full Screen",
      hotel_hotel_folio_email_edit_toolbar_help: "Help",
      hotel_hotel_folio_email_edit_toolbar_tip: "Tip",
      hotel_hotel_folio_email_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_folio_email_detail_title: "FOLIO EMAIL CONTENTS",
      hotel_hotel_folio_email_detail_label_confurmation_number: "Confirmation Number",
      hotel_hotel_folio_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_folio_email_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_folio_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_folio_email_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_folio_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_folio_email_detail_label_departure_date: "Departure Date",
      hotel_hotel_folio_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_folio_email_detail_label_room_type: "Room Type",
      hotel_hotel_folio_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_folio_email_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_hotel_folio_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_folio_email_detail_label_room_rate: "Room Rate",
      hotel_hotel_folio_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_folio_email_detail_label_total_rate: "Total Rate",
      hotel_hotel_folio_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_folio_email_detail_label_current_balance: "Current Balance",
      hotel_hotel_folio_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_folio_email_detail_label_room_number: "Room Number",
      hotel_hotel_folio_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_folio_email_detail_label_adults: "Adults",
      hotel_hotel_folio_email_detail_example_adults: "{{adults}}",
      hotel_hotel_folio_email_detail_label_children: "Children",
      hotel_hotel_folio_email_detail_example_children: "{{children}}",
      hotel_hotel_folio_email_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_folio_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_folio_email_detail_label_name_title: "Name Title",
      hotel_hotel_folio_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_folio_email_detail_label_first_name: "First Name",
      hotel_hotel_folio_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_folio_email_detail_label_last_name: "Last Name",
      hotel_hotel_folio_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_folio_email_detail_label_country_code: "Country Code",
      hotel_hotel_folio_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_folio_email_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_folio_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_folio_email_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_folio_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_email_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_folio_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_folio_email_detail_label_phone: "Mobile Phone No.",
      hotel_hotel_folio_email_detail_example_phone: "{{phone}}",
      hotel_hotel_folio_email_detail_label_email: "Email",
      hotel_hotel_folio_email_detail_example_email: "{{email}}",
      hotel_hotel_folio_email_detail_label_text_area: "Folio Text Area",
      hotel_hotel_folio_email_detail_example_text_area: "{{folio_text_area}}",
      hotel_hotel_enroll_profile_edit_title: "ENROLL PROFILE FORMAT",
      hotel_hotel_enroll_profile_edit_select_kr: "KR",
      hotel_hotel_enroll_profile_edit_select_en: "EN",
      hotel_hotel_enroll_profile_edit_select_cn: "CN",
      hotel_hotel_enroll_profile_edit_select_jp: "JP",
      hotel_hotel_enroll_profile_edit_select_ms: "MS",
      hotel_hotel_enroll_profile_edit_button_remove: "remove",
      hotel_hotel_enroll_profile_edit_button_append: "Append",
      hotel_hotel_enroll_profile_edit_button_save: "Save",
      hotel_hotel_enroll_profile_edit_toolbar_bold: "Bold",
      hotel_hotel_enroll_profile_edit_toolbar_italic: "Italic",
      hotel_hotel_enroll_profile_edit_toolbar_underline: "Underline",
      hotel_hotel_enroll_profile_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_enroll_profile_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_hotel_enroll_profile_edit_toolbar_font_family: "Font Family",
      hotel_hotel_enroll_profile_edit_toolbar_font_size: "Font Size",
      hotel_hotel_enroll_profile_edit_toolbar_text_color: "Text Color",
      hotel_hotel_enroll_profile_edit_toolbar_text_more_color: "More Color",
      hotel_hotel_enroll_profile_edit_toolbar_recent_color: "Recent Color",
      hotel_hotel_enroll_profile_edit_toolbar_recent_more_color: "More Color",
      hotel_hotel_enroll_profile_edit_toolbar_table: "Table",
      hotel_hotel_enroll_profile_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_enroll_profile_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_enroll_profile_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_enroll_profile_edit_toolbar_line_height: "Line Height",
      hotel_hotel_enroll_profile_edit_toolbar_picture: "Picture",
      hotel_hotel_enroll_profile_edit_toolbar_code_view: "Code View",
      hotel_hotel_enroll_profile_edit_toolbar_full_screen: "Full Screen",
      hotel_hotel_enroll_profile_edit_toolbar_help: "Help",
      hotel_hotel_enroll_profile_edit_toolbar_tip: "Tip",
      hotel_hotel_enroll_profile_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_enroll_profile_detail_title: "ENROLL PROFILE CONTENTS",
      hotel_hotel_enroll_profile_detail_label_email: "Email",
      hotel_hotel_enroll_profile_detail_example_email: "{{email}}",
      hotel_hotel_enroll_profile_detail_label_hotel_name: "Hotel Name",
      hotel_hotel_enroll_profile_detail_example_hotel_name: "{{hotelName}}",
      hotel_hotel_enroll_profile_detail_label_title: "Title",
      hotel_hotel_enroll_profile_detail_example_title: "{{title}}",
      hotel_hotel_enroll_profile_detail_label_first_name: "First Name",
      hotel_hotel_enroll_profile_detail_example_first_name: "{{firstName}}",
      hotel_hotel_enroll_profile_detail_label_last_name: "Last Name",
      hotel_hotel_enroll_profile_detail_example_last_name: "{{lastName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_enroll_profile_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_enroll_profile_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_enroll_profile_detail_label_mobile_number: "Mobile Number",
      hotel_hotel_enroll_profile_detail_example_mobile_number: "{{mobileNumber}}",
      hotel_hotel_folio_pdf_edit_title: "FOLIO PDF FORMAT",
      hotel_hotel_folio_pdf_edit_select_kr: "KR",
      hotel_hotel_folio_pdf_edit_select_en: "EN",
      hotel_hotel_folio_pdf_edit_select_cn: "CN",
      hotel_hotel_folio_pdf_edit_select_jp: "JP",
      hotel_hotel_folio_pdf_edit_select_ms: "MS",
      hotel_hotel_folio_pdf_edit_button_remove: "remove",
      hotel_hotel_folio_pdf_edit_button_append: "Append",
      hotel_hotel_folio_pdf_edit_button_save: "Save",
      hotel_hotel_folio_pdf_edit_toolbar_bold: "Bold",
      hotel_hotel_folio_pdf_edit_toolbar_italic: "Italic",
      hotel_hotel_folio_pdf_edit_toolbar_underline: "Underline",
      hotel_hotel_folio_pdf_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_folio_pdf_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_hotel_folio_pdf_edit_toolbar_font_family: "Font Family",
      hotel_hotel_folio_pdf_edit_toolbar_font_size: "Font Size",
      hotel_hotel_folio_pdf_edit_toolbar_text_color: "Text Color",
      hotel_hotel_folio_pdf_edit_toolbar_text_more_color: "More Color",
      hotel_hotel_folio_pdf_edit_toolbar_recent_color: "Recent Color",
      hotel_hotel_folio_pdf_edit_toolbar_recent_more_color: "More Color",
      hotel_hotel_folio_pdf_edit_toolbar_table: "Table",
      hotel_hotel_folio_pdf_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_folio_pdf_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_folio_pdf_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_folio_pdf_edit_toolbar_line_height: "Line Height",
      hotel_hotel_folio_pdf_edit_toolbar_picture: "Picture",
      hotel_hotel_folio_pdf_edit_toolbar_code_view: "Code View",
      hotel_hotel_folio_pdf_edit_toolbar_full_screen: "Full Screen",
      hotel_hotel_folio_pdf_edit_toolbar_help: "Help",
      hotel_hotel_folio_pdf_edit_toolbar_tip: "Tip",
      hotel_hotel_folio_pdf_edit_toolbar_download_pdf: "Download PDF",
      hotel_hotel_folio_pdf_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_folio_pdf_detail_title: "FOLIO PDF CONTENTS",
      hotel_hotel_folio_pdf_detail_label_confurmation_number: "Confirmation Number",
      hotel_hotel_folio_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_folio_pdf_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_folio_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_folio_pdf_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_folio_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_folio_pdf_detail_label_departure_date: "Departure Date",
      hotel_hotel_folio_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_folio_pdf_detail_label_room_type: "Room Type",
      hotel_hotel_folio_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_folio_pdf_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_hotel_folio_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_folio_pdf_detail_label_room_rate: "Room Rate",
      hotel_hotel_folio_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_folio_pdf_detail_label_total_rate: "Total Rate",
      hotel_hotel_folio_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_folio_pdf_detail_label_current_balance: "Current Balance",
      hotel_hotel_folio_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_folio_pdf_detail_label_room_number: "Room Number",
      hotel_hotel_folio_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_folio_pdf_detail_label_adults: "Adults",
      hotel_hotel_folio_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_folio_pdf_detail_label_children: "Children",
      hotel_hotel_folio_pdf_detail_example_children: "{{children}}",
      hotel_hotel_folio_pdf_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_folio_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_folio_pdf_detail_label_name_title: "Name Title",
      hotel_hotel_folio_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_folio_pdf_detail_label_first_name: "First Name",
      hotel_hotel_folio_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_folio_pdf_detail_label_last_name: "Last Name",
      hotel_hotel_folio_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_folio_pdf_detail_label_country_code: "Country Code",
      hotel_hotel_folio_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_folio_pdf_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_folio_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_folio_pdf_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_folio_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_pdf_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_folio_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_folio_pdf_detail_label_phone: "Mobile Phone No.",
      hotel_hotel_folio_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_folio_pdf_detail_label_email: "Email",
      hotel_hotel_folio_pdf_detail_example_email: "{{email}}",
      hotel_hotel_folio_pdf_detail_label_text_area: "Folio Text Area",
      hotel_hotel_folio_pdf_detail_example_text_area: "{{folio_text_area}}",
      hotel_future_booking_change_email_edit_title: "FUTURE BOOKING CHANGE Email",
      hotel_future_booking_change_email_edit_select_kr: "KR",
      hotel_future_booking_change_email_edit_select_en: "EN",
      hotel_future_booking_change_email_edit_select_cn: "CN",
      hotel_future_booking_change_email_edit_select_jp: "JP",
      hotel_future_booking_change_email_edit_select_ms: "MS",
      hotel_future_booking_change_email_edit_button_remove: "remove",
      hotel_future_booking_change_email_edit_button_append: "Append",
      hotel_future_booking_change_email_edit_button_save: "Save",
      hotel_future_booking_change_email_edit_toolbar_bold: "Bold",
      hotel_future_booking_change_email_edit_toolbar_italic: "Italic",
      hotel_future_booking_change_email_edit_toolbar_underline: "Underline",
      hotel_future_booking_change_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_future_booking_change_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_future_booking_change_email_edit_toolbar_font_family: "Font Family",
      hotel_future_booking_change_email_edit_toolbar_font_size: "Font Size",
      hotel_future_booking_change_email_edit_toolbar_text_color: "Text Color",
      hotel_future_booking_change_email_edit_toolbar_text_more_color: "More Color",
      hotel_future_booking_change_email_edit_toolbar_recent_color: "Recent Color",
      hotel_future_booking_change_email_edit_toolbar_recent_more_color: "More Color",
      hotel_future_booking_change_email_edit_toolbar_table: "Table",
      hotel_future_booking_change_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_future_booking_change_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_future_booking_change_email_edit_toolbar_paragraph: "Paragraph",
      hotel_future_booking_change_email_edit_toolbar_line_height: "Line Height",
      hotel_future_booking_change_email_edit_toolbar_picture: "Picture",
      hotel_future_booking_change_email_edit_toolbar_code_view: "Code View",
      hotel_future_booking_change_email_edit_toolbar_full_screen: "Full Screen",
      hotel_future_booking_change_email_edit_toolbar_help: "Help",
      hotel_future_booking_change_email_edit_toolbar_tip: "Tip",
      hotel_future_booking_change_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_future_booking_change_email_edit_placeholder_content: "Please enter the content.",
      hotel_future_booking_change_email_detail_title: "CHANGE FUTURE BOOKING CONTENTS",
      hotel_future_booking_change_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_future_booking_change_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_change_email_detail_label_first_name: "First Name",
      hotel_future_booking_change_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_change_email_detail_label_last_name: "Last Name",
      hotel_future_booking_change_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_change_email_detail_label_room_type: "Room Type",
      hotel_future_booking_change_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_change_email_detail_label_arrival_date: "Arrival Date",
      hotel_future_booking_change_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_change_email_detail_label_departure_date: "Departure Date",
      hotel_future_booking_change_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_change_email_detail_label_name_title: "Name Title",
      hotel_future_booking_change_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_change_email_detail_label_adults: "Adults",
      hotel_future_booking_change_email_detail_example_adults: "{{adults}}",
      hotel_future_booking_change_email_detail_label_night: "Night",
      hotel_future_booking_change_email_detail_example_night: "{{night}}",
      hotel_future_booking_change_email_detail_label_room_rate: "Room Rate",
      hotel_future_booking_change_email_detail_example_room_rate: "{{roomRate}}",
      hotel_future_booking_change_email_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_future_booking_change_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_future_booking_change_email_detail_label_total_rate: "Total Rate",
      hotel_future_booking_change_email_detail_example_total_rate: "{{totalRate}}",
      hotel_future_booking_change_email_detail_label_cancellation: "Cancellation Policy",
      hotel_future_booking_change_email_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_future_booking_cancel_email_edit_title: "FUTURE BOOKING CANCEL Email",
      hotel_future_booking_cancel_email_edit_select_kr: "KR",
      hotel_future_booking_cancel_email_edit_select_en: "EN",
      hotel_future_booking_cancel_email_edit_select_cn: "CN",
      hotel_future_booking_cancel_email_edit_select_jp: "JP",
      hotel_future_booking_cancel_email_edit_select_ms: "MS",
      hotel_future_booking_cancel_email_edit_button_remove: "remove",
      hotel_future_booking_cancel_email_edit_button_append: "Append",
      hotel_future_booking_cancel_email_edit_button_save: "Save",
      hotel_future_booking_cancel_email_edit_toolbar_bold: "Bold",
      hotel_future_booking_cancel_email_edit_toolbar_italic: "Italic",
      hotel_future_booking_cancel_email_edit_toolbar_underline: "Underline",
      hotel_future_booking_cancel_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_future_booking_cancel_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_future_booking_cancel_email_edit_toolbar_font_family: "Font Family",
      hotel_future_booking_cancel_email_edit_toolbar_font_size: "Font Size",
      hotel_future_booking_cancel_email_edit_toolbar_text_color: "Text Color",
      hotel_future_booking_cancel_email_edit_toolbar_text_more_color: "More Color",
      hotel_future_booking_cancel_email_edit_toolbar_recent_color: "Recent Color",
      hotel_future_booking_cancel_email_edit_toolbar_recent_more_color: "More Color",
      hotel_future_booking_cancel_email_edit_toolbar_table: "Table",
      hotel_future_booking_cancel_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_future_booking_cancel_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_future_booking_cancel_email_edit_toolbar_paragraph: "Paragraph",
      hotel_future_booking_cancel_email_edit_toolbar_line_height: "Line Height",
      hotel_future_booking_cancel_email_edit_toolbar_picture: "Picture",
      hotel_future_booking_cancel_email_edit_toolbar_code_view: "Code View",
      hotel_future_booking_cancel_email_edit_toolbar_full_screen: "Full Screen",
      hotel_future_booking_cancel_email_edit_toolbar_help: "Help",
      hotel_future_booking_cancel_email_edit_toolbar_tip: "Tip",
      hotel_future_booking_cancel_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_future_booking_cancel_email_edit_placeholder_content: "Please enter the content.",
      hotel_future_booking_cancel_email_detail_title: "CANCEL FUTURE BOOKING CONTENTS",
      hotel_future_booking_cancel_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_future_booking_cancel_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_cancel_email_detail_label_first_name: "First Name",
      hotel_future_booking_cancel_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_cancel_email_detail_label_last_name: "Last Name",
      hotel_future_booking_cancel_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_cancel_email_detail_label_room_type: "Room Type",
      hotel_future_booking_cancel_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_cancel_email_detail_label_arrival_date: "Arrival Date",
      hotel_future_booking_cancel_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_cancel_email_detail_label_departure_date: "Departure Date",
      hotel_future_booking_cancel_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_cancel_email_detail_label_name_title: "Name Title",
      hotel_future_booking_cancel_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_no_show_email_edit_title: "FUTURE BOOKING NO-SHOW Email",
      hotel_hotel_no_show_email_edit_select_kr: "KR",
      hotel_hotel_no_show_email_edit_select_en: "EN",
      hotel_hotel_no_show_email_edit_select_cn: "CN",
      hotel_hotel_no_show_email_edit_select_jp: "JP",
      hotel_hotel_no_show_email_edit_select_ms: "MS",
      hotel_hotel_no_show_email_edit_button_remove: "remove",
      hotel_hotel_no_show_email_edit_button_append: "Append",
      hotel_hotel_no_show_email_edit_button_save: "Save",
      hotel_hotel_no_show_email_edit_toolbar_bold: "Bold",
      hotel_hotel_no_show_email_edit_toolbar_italic: "Italic",
      hotel_hotel_no_show_email_edit_toolbar_underline: "Underline",
      hotel_hotel_no_show_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_hotel_no_show_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_hotel_no_show_email_edit_toolbar_font_family: "Font Family",
      hotel_hotel_no_show_email_edit_toolbar_font_size: "Font Size",
      hotel_hotel_no_show_email_edit_toolbar_text_color: "Text Color",
      hotel_hotel_no_show_email_edit_toolbar_text_more_color: "More Color",
      hotel_hotel_no_show_email_edit_toolbar_recent_color: "Recent Color",
      hotel_hotel_no_show_email_edit_toolbar_recent_more_color: "More Color",
      hotel_hotel_no_show_email_edit_toolbar_table: "Table",
      hotel_hotel_no_show_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_hotel_no_show_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_hotel_no_show_email_edit_toolbar_paragraph: "Paragraph",
      hotel_hotel_no_show_email_edit_toolbar_line_height: "Line Height",
      hotel_hotel_no_show_email_edit_toolbar_picture: "Picture",
      hotel_hotel_no_show_email_edit_toolbar_code_view: "Code View",
      hotel_hotel_no_show_email_edit_toolbar_full_screen: "Full Screen",
      hotel_hotel_no_show_email_edit_toolbar_help: "Help",
      hotel_hotel_no_show_email_edit_toolbar_tip: "Tip",
      hotel_hotel_no_show_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_hotel_no_show_email_edit_placeholder_content: "Please enter the content.",
      hotel_hotel_no_show_email_detail_title: "CANCEL FUTURE BOOKING CONTENTS",
      hotel_hotel_no_show_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_hotel_no_show_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_hotel_no_show_email_detail_label_reservation_id: "Reservation ID",
      hotel_hotel_no_show_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_no_show_email_detail_label_first_name: "First Name",
      hotel_hotel_no_show_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_no_show_email_detail_label_last_name: "Last Name",
      hotel_hotel_no_show_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_no_show_email_detail_label_room_type: "Room Type",
      hotel_hotel_no_show_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_no_show_email_detail_label_arrival_date: "Arrival Date",
      hotel_hotel_no_show_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_no_show_email_detail_label_departure_date: "Departure Date",
      hotel_hotel_no_show_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_no_show_email_detail_label_name_title: "Name Title",
      hotel_hotel_no_show_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_no_show_email_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_hotel_no_show_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_no_show_email_detail_label_room_rate: "Room Rate",
      hotel_hotel_no_show_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_no_show_email_detail_label_total_rate: "Total Rate",
      hotel_hotel_no_show_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_no_show_email_detail_label_current_balance: "Current Balance",
      hotel_hotel_no_show_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_no_show_email_detail_label_room_number: "Room Number",
      hotel_hotel_no_show_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_no_show_email_detail_label_adults: "Adults",
      hotel_hotel_no_show_email_detail_example_adults: "{{adults}}",
      hotel_hotel_no_show_email_detail_label_children: "Children",
      hotel_hotel_no_show_email_detail_example_children: "{{children}}",
      hotel_hotel_no_show_email_detail_label_check_out_time: "Check Out Time",
      hotel_hotel_no_show_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_no_show_email_detail_label_country_code: "Country Code",
      hotel_hotel_no_show_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_no_show_email_detail_label_alternative_language_code: "Alternative Language Code",
      hotel_hotel_no_show_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_no_show_email_detail_label_alternative_first_name: "Alternative First Name",
      hotel_hotel_no_show_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_no_show_email_detail_label_alternative_last_name: "Alternative Last Name",
      hotel_hotel_no_show_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_no_show_email_detail_label_phone: "Phone",
      hotel_hotel_no_show_email_detail_example_phone: "{{phone}}",
      hotel_hotel_no_show_email_detail_label_email: "E-mail",
      hotel_hotel_no_show_email_detail_example_email: "{{email}}",
      hotel_hotel_no_show_email_detail_label_accompany: "Accompany",
      hotel_hotel_no_show_email_detail_example_accompany: "{{accompany}}",
      hotel_future_booking_pack_add_email_edit_title: "FUTURE BOOKING PACKAGE ADD Email",
      hotel_future_booking_pack_add_email_edit_select_kr: "KR",
      hotel_future_booking_pack_add_email_edit_select_en: "EN",
      hotel_future_booking_pack_add_email_edit_select_cn: "CN",
      hotel_future_booking_pack_add_email_edit_select_jp: "JP",
      hotel_future_booking_pack_add_email_edit_select_ms: "MS",
      hotel_future_booking_pack_add_email_edit_button_remove: "remove",
      hotel_future_booking_pack_add_email_edit_button_append: "Append",
      hotel_future_booking_pack_add_email_edit_button_save: "Save",
      hotel_future_booking_pack_add_email_edit_toolbar_bold: "Bold",
      hotel_future_booking_pack_add_email_edit_toolbar_italic: "Italic",
      hotel_future_booking_pack_add_email_edit_toolbar_underline: "Underline",
      hotel_future_booking_pack_add_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_future_booking_pack_add_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_future_booking_pack_add_email_edit_toolbar_font_family: "Font Family",
      hotel_future_booking_pack_add_email_edit_toolbar_font_size: "Font Size",
      hotel_future_booking_pack_add_email_edit_toolbar_text_color: "Text Color",
      hotel_future_booking_pack_add_email_edit_toolbar_text_more_color: "More Color",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_color: "Recent Color",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_more_color: "More Color",
      hotel_future_booking_pack_add_email_edit_toolbar_table: "Table",
      hotel_future_booking_pack_add_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_future_booking_pack_add_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_future_booking_pack_add_email_edit_toolbar_paragraph: "Paragraph",
      hotel_future_booking_pack_add_email_edit_toolbar_line_height: "Line Height",
      hotel_future_booking_pack_add_email_edit_toolbar_picture: "Picture",
      hotel_future_booking_pack_add_email_edit_toolbar_code_view: "Code View",
      hotel_future_booking_pack_add_email_edit_toolbar_full_screen: "Full Screen",
      hotel_future_booking_pack_add_email_edit_toolbar_help: "Help",
      hotel_future_booking_pack_add_email_edit_toolbar_tip: "Tip",
      hotel_future_booking_pack_add_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_future_booking_pack_add_email_edit_placeholder_content: "Please enter the content.",
      hotel_future_booking_pack_add_email_detail_title: "ADD FUTURE BOOKING PACKAGE CONTENTS",
      hotel_future_booking_pack_add_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_future_booking_pack_add_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_pack_add_email_detail_label_first_name: "First Name",
      hotel_future_booking_pack_add_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_pack_add_email_detail_label_last_name: "Last Name",
      hotel_future_booking_pack_add_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_pack_add_email_detail_label_room_type: "Room Type",
      hotel_future_booking_pack_add_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_pack_add_email_detail_label_arrival_date: "Arrival Date",
      hotel_future_booking_pack_add_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_pack_add_email_detail_label_departure_date: "Departure Date",
      hotel_future_booking_pack_add_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_pack_add_email_detail_label_name_title: "Name Title",
      hotel_future_booking_pack_add_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_pack_add_email_detail_label_package_code: "Package Code",
      hotel_future_booking_pack_add_email_detail_example_package_code: "{{packageCode}}",
      hotel_future_booking_pack_add_email_detail_label_package_description: "Package Description",
      hotel_future_booking_pack_add_email_detail_example_package_description: "{{packageDescription}}",
      hotel_future_booking_pack_add_email_detail_label_amount: "Amount",
      hotel_future_booking_pack_add_email_detail_example_amount: "{{amount}}",
      hotel_future_booking_pack_add_email_detail_label_package_text_area: "Package Text Area",
      hotel_future_booking_pack_add_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_pack_delete_email_edit_title: "FUTURE BOOKING PACKAGE DELETE Email",
      hotel_future_booking_pack_delete_email_edit_select_kr: "KR",
      hotel_future_booking_pack_delete_email_edit_select_en: "EN",
      hotel_future_booking_pack_delete_email_edit_select_cn: "CN",
      hotel_future_booking_pack_delete_email_edit_select_jp: "JP",
      hotel_future_booking_pack_delete_email_edit_select_ms: "MS",
      hotel_future_booking_pack_delete_email_edit_button_remove: "remove",
      hotel_future_booking_pack_delete_email_edit_button_append: "Append",
      hotel_future_booking_pack_delete_email_edit_button_save: "Save",
      hotel_future_booking_pack_delete_email_edit_toolbar_bold: "Bold",
      hotel_future_booking_pack_delete_email_edit_toolbar_italic: "Italic",
      hotel_future_booking_pack_delete_email_edit_toolbar_underline: "Underline",
      hotel_future_booking_pack_delete_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_future_booking_pack_delete_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_family: "Font Family",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_size: "Font Size",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_color: "Text Color",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_more_color: "More Color",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_color: "Recent Color",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_more_color: "More Color",
      hotel_future_booking_pack_delete_email_edit_toolbar_table: "Table",
      hotel_future_booking_pack_delete_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_future_booking_pack_delete_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_future_booking_pack_delete_email_edit_toolbar_paragraph: "Paragraph",
      hotel_future_booking_pack_delete_email_edit_toolbar_line_height: "Line Height",
      hotel_future_booking_pack_delete_email_edit_toolbar_picture: "Picture",
      hotel_future_booking_pack_delete_email_edit_toolbar_code_view: "Code View",
      hotel_future_booking_pack_delete_email_edit_toolbar_full_screen: "Full Screen",
      hotel_future_booking_pack_delete_email_edit_toolbar_help: "Help",
      hotel_future_booking_pack_delete_email_edit_toolbar_tip: "Tip",
      hotel_future_booking_pack_delete_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_future_booking_pack_delete_email_edit_placeholder_content: "Please enter the content.",
      hotel_future_booking_pack_delete_email_detail_title: "DELETE FUTURE BOOKING PACKAGE CONTENTS",
      hotel_future_booking_pack_delete_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_future_booking_pack_delete_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_pack_delete_email_detail_label_first_name: "First Name",
      hotel_future_booking_pack_delete_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_pack_delete_email_detail_label_last_name: "Last Name",
      hotel_future_booking_pack_delete_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_pack_delete_email_detail_label_room_type: "Room Type",
      hotel_future_booking_pack_delete_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_pack_delete_email_detail_label_arrival_date: "Arrival Date",
      hotel_future_booking_pack_delete_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_pack_delete_email_detail_label_departure_date: "Departure Date",
      hotel_future_booking_pack_delete_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_pack_delete_email_detail_label_name_title: "Name Title",
      hotel_future_booking_pack_delete_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_pack_delete_email_detail_label_package_code: "Package Code",
      hotel_future_booking_pack_delete_email_detail_example_package_code: "{{packageCode}}",
      hotel_future_booking_pack_delete_email_detail_label_package_description: "Package Description",
      hotel_future_booking_pack_delete_email_detail_example_package_description: "{{packageDescription}}",
      hotel_future_booking_pack_delete_email_detail_label_amount: "Amount",
      hotel_future_booking_pack_delete_email_detail_example_amount: "{{amount}}",
      hotel_future_booking_pack_delete_email_detail_label_package_text_area: "Package Text Area",
      hotel_future_booking_pack_delete_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_accom_add_email_edit_title: "FUTURE BOOKING ACCOMPANY ADD Email",
      hotel_future_booking_accom_add_email_edit_select_kr: "KR",
      hotel_future_booking_accom_add_email_edit_select_en: "EN",
      hotel_future_booking_accom_add_email_edit_select_cn: "CN",
      hotel_future_booking_accom_add_email_edit_select_jp: "JP",
      hotel_future_booking_accom_add_email_edit_select_ms: "MS",
      hotel_future_booking_accom_add_email_edit_button_remove: "remove",
      hotel_future_booking_accom_add_email_edit_button_append: "Append",
      hotel_future_booking_accom_add_email_edit_button_save: "Save",
      hotel_future_booking_accom_add_email_edit_toolbar_bold: "Bold",
      hotel_future_booking_accom_add_email_edit_toolbar_italic: "Italic",
      hotel_future_booking_accom_add_email_edit_toolbar_underline: "Underline",
      hotel_future_booking_accom_add_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_future_booking_accom_add_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_future_booking_accom_add_email_edit_toolbar_font_family: "Font Family",
      hotel_future_booking_accom_add_email_edit_toolbar_font_size: "Font Size",
      hotel_future_booking_accom_add_email_edit_toolbar_text_color: "Text Color",
      hotel_future_booking_accom_add_email_edit_toolbar_text_more_color: "More Color",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_color: "Recent Color",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_more_color: "More Color",
      hotel_future_booking_accom_add_email_edit_toolbar_table: "Table",
      hotel_future_booking_accom_add_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_future_booking_accom_add_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_future_booking_accom_add_email_edit_toolbar_paragraph: "Paragraph",
      hotel_future_booking_accom_add_email_edit_toolbar_line_height: "Line Height",
      hotel_future_booking_accom_add_email_edit_toolbar_picture: "Picture",
      hotel_future_booking_accom_add_email_edit_toolbar_code_view: "Code View",
      hotel_future_booking_accom_add_email_edit_toolbar_full_screen: "Full Screen",
      hotel_future_booking_accom_add_email_edit_toolbar_help: "Help",
      hotel_future_booking_accom_add_email_edit_toolbar_tip: "Tip",
      hotel_future_booking_accom_add_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_future_booking_accom_add_email_edit_placeholder_content: "Please enter the content.",
      hotel_future_booking_accom_add_email_detail_title: "ADD FUTURE BOOKING ACCOMPANY CONTENTS",
      hotel_future_booking_accom_add_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_future_booking_accom_add_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_accom_add_email_detail_label_accom_first_name: "Accompany First Name",
      hotel_future_booking_accom_add_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_add_email_detail_label_accom_last_name: "Accompany Last Name",
      hotel_future_booking_accom_add_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_add_email_detail_label_first_name: "First Name",
      hotel_future_booking_accom_add_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_accom_add_email_detail_label_last_name: "Last Name",
      hotel_future_booking_accom_add_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_accom_add_email_detail_label_room_type: "Room Type",
      hotel_future_booking_accom_add_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_accom_add_email_detail_label_arrival_date: "Arrival Date",
      hotel_future_booking_accom_add_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_accom_add_email_detail_label_departure_date: "Departure Date",
      hotel_future_booking_accom_add_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_accom_add_email_detail_label_accom_title: "Accompany Title",
      hotel_future_booking_accom_add_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_add_email_detail_label_adults: "Adults",
      hotel_future_booking_accom_add_email_detail_example_adults: "{{adults}}",
      hotel_future_booking_accom_add_email_detail_label_night: "Night",
      hotel_future_booking_accom_add_email_detail_example_night: "{{night}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate: "Room Rate",
      hotel_future_booking_accom_add_email_detail_example_room_rate: "{{roomRate}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate_currency: "Room Rate Currency",
      hotel_future_booking_accom_add_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_future_booking_accom_add_email_detail_label_total_rate: "Total Rate",
      hotel_future_booking_accom_add_email_detail_example_total_rate: "{{totalRate}}",
      hotel_future_booking_accom_add_email_detail_label_cancellation: "Cancellation Policy",
      hotel_future_booking_accom_add_email_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_future_booking_accom_delete_email_edit_title: "FUTURE BOOKING ACCOMPANY DELETION EMAIL",
      hotel_future_booking_accom_delete_email_edit_select_kr: "KR",
      hotel_future_booking_accom_delete_email_edit_select_en: "EN",
      hotel_future_booking_accom_delete_email_edit_select_cn: "CN",
      hotel_future_booking_accom_delete_email_edit_select_jp: "JP",
      hotel_future_booking_accom_delete_email_edit_select_ms: "MS",
      hotel_future_booking_accom_delete_email_edit_button_remove: "remove",
      hotel_future_booking_accom_delete_email_edit_button_append: "Append",
      hotel_future_booking_accom_delete_email_edit_button_save: "Save",
      hotel_future_booking_accom_delete_email_edit_toolbar_bold: "Bold",
      hotel_future_booking_accom_delete_email_edit_toolbar_italic: "Italic",
      hotel_future_booking_accom_delete_email_edit_toolbar_underline: "Underline",
      hotel_future_booking_accom_delete_email_edit_toolbar_strikethrough: "Strikethrough",
      hotel_future_booking_accom_delete_email_edit_toolbar_remove_font_style: "Remove Font Style",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_family: "Font Family",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_size: "Font Size",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_color: "Text Color",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_more_color: "More Color",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_color: "Recent Color",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_more_color: "More Color",
      hotel_future_booking_accom_delete_email_edit_toolbar_table: "Table",
      hotel_future_booking_accom_delete_email_edit_toolbar_unordered_list: "Unordered list",
      hotel_future_booking_accom_delete_email_edit_toolbar_ordered_list: "Ordered list",
      hotel_future_booking_accom_delete_email_edit_toolbar_paragraph: "Paragraph",
      hotel_future_booking_accom_delete_email_edit_toolbar_line_height: "Line Height",
      hotel_future_booking_accom_delete_email_edit_toolbar_picture: "Picture",
      hotel_future_booking_accom_delete_email_edit_toolbar_code_view: "Code View",
      hotel_future_booking_accom_delete_email_edit_toolbar_full_screen: "Full Screen",
      hotel_future_booking_accom_delete_email_edit_toolbar_help: "Help",
      hotel_future_booking_accom_delete_email_edit_toolbar_tip: "Tip",
      hotel_future_booking_accom_delete_email_edit_toolbar_download_pdf: "Download PDF",
      hotel_future_booking_accom_delete_email_edit_placeholder_content: "Please enter the content.",
      hotel_future_booking_accom_delete_email_detail_title: "DELETE FUTURE BOOKING ACCOMPANY CONTENTS",
      hotel_future_booking_accom_delete_email_detail_label_confirmation_number: "Confirmation Number",
      hotel_future_booking_accom_delete_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_first_name: "Accompany First Name",
      hotel_future_booking_accom_delete_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_last_name: "Accompany Last Name",
      hotel_future_booking_accom_delete_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_delete_email_detail_label_first_name: "First Name",
      hotel_future_booking_accom_delete_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_accom_delete_email_detail_label_last_name: "Last Name",
      hotel_future_booking_accom_delete_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_accom_delete_email_detail_label_room_type: "Room Type",
      hotel_future_booking_accom_delete_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_accom_delete_email_detail_label_arrival_date: "Arrival Date",
      hotel_future_booking_accom_delete_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_accom_delete_email_detail_label_departure_date: "Departure Date",
      hotel_future_booking_accom_delete_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_title: "Accompany Title",
      hotel_future_booking_accom_delete_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_delete_email_detail_label_main_guest_name: "Main Guest Name",
      hotel_future_booking_accom_delete_email_detail_example_main_guest_name: "{{mainGuestName}}",
      hotel_member_list_title: "MEMBER LIST",
      hotel_member_list_placeholder_search: "Search...",
      hotel_member_list_label_no: "No.",
      hotel_member_list_label_first_name: "First Name",
      hotel_member_list_label_last_name: "Last Name",
      hotel_member_list_label_email: "Email",
      hotel_member_list_label_gender: "Gender",
      hotel_member_list_label_member_type: "Member Type",
      hotel_member_list_label_profile_id: "Profile ID",
      hotel_member_list_label_fail_count: "Fail Count",
      hotel_member_list_label_date: "Date",
      hotel_member_list_guide_empty: "List is empty",
      hotel_member_detail_title: "Profile ID",
      hotel_member_detail_label_user: "User",
      hotel_member_detail_button_user_transfer: "Resync",
      hotel_member_detail_label_user_first_name: "First Name",
      hotel_member_detail_label_user_last_name: "Last Name",
      hotel_member_detail_label_user_email: "Email",
      hotel_member_detail_label_user_gender: "Gender",
      hotel_member_detail_label_user_member_type: "Member Type",
      hotel_member_detail_label_user_date: "Date",
      hotel_member_detail_label_fail_log_list: "Fail Log List",
      hotel_member_detail_label_fail_count: "Fail Count",
      hotel_member_detail_button_unlock: "Unlock",
      hotel_member_detail_label_fail_log_no: "No.",
      hotel_member_detail_label_fail_log_lock: "Lock",
      hotel_member_detail_label_fail_log_date: "Date",
      hotel_member_detail_label_pms_member_profile: "PMS Member Profile",
      hotel_member_detail_label_pms_member_profile_name_title: "Name Title",
      hotel_member_detail_label_pms_member_profile_first_name: "First Name",
      hotel_member_detail_label_pms_member_profile_last_name: "Last Name",
      hotel_member_detail_label_pms_member_profile_alternative_language_code: "Alternative Language Code",
      hotel_member_detail_label_pms_member_profile_alternative_first_name: "Alternative First Name",
      hotel_member_detail_label_pms_member_profile_alternative_last_name: "Alternative Last Name",
      hotel_member_detail_label_pms_member_profile_email: "Email",
      hotel_member_detail_label_pms_member_profile_email_opera_id: "Email Opera ID",
      hotel_member_detail_label_pms_member_profile_phone: "Mobile Phone No.",
      hotel_member_detail_label_pms_member_profile_phone_opera_id: "Mobile Phone No. Opera ID",
      hotel_member_detail_button_go_to_list: "Go to list",
      hotel_non_member_list_title: "NON MEMBER LIST",
      hotel_non_member_list_placeholder_search: "Search...",
      hotel_non_member_list_label_no: "No.",
      hotel_non_member_list_label_first_name: "First Name",
      hotel_non_member_list_label_last_name: "Last Name",
      hotel_non_member_list_label_email: "Email",
      hotel_non_member_list_label_gender: "Gender",
      hotel_non_member_list_label_fail_count: "Member Type",
      hotel_non_member_list_label_date: "Date",
      hotel_non_member_list_guide_empty: "List is empty",
      hotel_non_member_detail_label_user: "User",
      hotel_non_member_detail_label_user_first_name: "First Name",
      hotel_non_member_detail_label_user_last_name: "Last Name",
      hotel_non_member_detail_label_user_email: "Email",
      hotel_non_member_detail_label_user_gender: "Gender",
      hotel_non_member_detail_label_user_date: "Date",
      hotel_non_member_detail_label_fail_log_list: "Fail Log List",
      hotel_non_member_detail_label_fail_count: "Fail Count",
      hotel_non_member_detail_button_unlock: "Unlock",
      hotel_non_member_detail_label_fail_log_no: "No.",
      hotel_non_member_detail_label_fail_log_lock: "Lock",
      hotel_non_member_detail_label_fail_log_date: "Date",
      hotel_non_member_detail_button_go_to_list: "Go to list",
      hotel_reservation_reservation_list_title: "RESERVATION LIST",
      hotel_reservation_reservation_list_placeholder_select_box_reservation_status: "Reservation Status",
      hotel_reservation_reservation_list_select_inhouse: "In-House",
      hotel_reservation_reservation_list_select_reserved: "Reserved",
      hotel_reservation_reservation_list_select_changed: "Changed",
      hotel_reservation_reservation_list_select_canceled: "Canceled",
      hotel_reservation_reservation_list_select_no_show: "No Show",
      hotel_reservation_reservation_list_select_checked_out: "Checked Out",
      hotel_reservation_reservation_list_placeholder_select_box_reservation: "Reservation",
      hotel_reservation_reservation_list_select_confirmation_no: "Confirmation No.",
      hotel_reservation_reservation_list_select_room_no: "Room No.",
      hotel_reservation_reservation_list_select_name: "Name",
      hotel_reservation_reservation_list_placeholder_search: "Search...",
      hotel_reservation_reservation_list_label_no: "No.",
      hotel_reservation_reservation_list_label_confirmation_no: "Confirmation No.",
      hotel_reservation_reservation_list_label_owner: "Owner",
      hotel_reservation_reservation_list_label_main_guest: "Main Guest",
      hotel_reservation_reservation_list_label_status: "Status",
      hotel_reservation_reservation_list_label_checkin_date: "Checkin Date",
      hotel_reservation_reservation_list_label_checkout_date: "Checkout Date",
      hotel_reservation_reservation_list_label_date: "Date",
      hotel_reservation_reservation_list_label_cancel: "Cancel",
      hotel_reservation_reservation_list_guide_empty: "List is empty",
      hotel_reservation_reservation_list_button_cancel: "Cancel",
      hotel_reservation_reservation_list_button_sync: "Sync",
      hotel_reservation_reservation_detail_label_confirmation_no: "Confirmation No.",
      hotel_reservation_reservation_detail_label_owner_main_guest: "Main Guest",
      hotel_reservation_reservation_detail_label_owner_delete_btn: "Delete",
      hotel_reservation_reservation_detail_label_owner_profile_id: "Profile ID",
      hotel_reservation_reservation_detail_label_owner_title: "Title",
      hotel_reservation_reservation_detail_label_owner_first_name: "First Name",
      hotel_reservation_reservation_detail_label_owner_last_name: "Last Name",
      hotel_reservation_reservation_detail_label_owner_confirmed_at: "Confirmed At",
      hotel_reservation_reservation_detail_label_owner_confirmed_type: "Confirmed Type",
      hotel_reservation_reservation_detail_label_owner_use_key: "Use Key",
      hotel_reservation_reservation_detail_label_owner_waiting_room: "Room Wait Request Time",
      hotel_reservation_reservation_detail_label_owner_date: "Date",
      hotel_reservation_reservation_detail_owner_list_guide_empty: "List is empty",
      hotel_reservation_reservation_detail_label_booking_user_owner: "Owner",
      hotel_reservation_reservation_detail_label_booking_user_title: "Title",
      hotel_reservation_reservation_detail_label_booking_user_first_name: "First Name",
      hotel_reservation_reservation_detail_label_booking_user_last_name: "Last Name",
      hotel_reservation_reservation_detail_label_booking_user_confirmed_at: "Confirmed At",
      hotel_reservation_reservation_detail_label_booking_user_date: "Date",
      hotel_reservation_reservation_detail_label_accompany: "Accompany",
      hotel_reservation_reservation_detail_label_accompany_no: "No.",
      hotel_reservation_reservation_detail_label_accompany_profile_id: "Profile ID",
      hotel_reservation_reservation_detail_label_accompany_title: "Title",
      hotel_reservation_reservation_detail_label_accompany_first_name: "First Name",
      hotel_reservation_reservation_detail_label_accompany_last_name: "Last Name",
      hotel_reservation_reservation_detail_label_accompany_use_key: "Use Key",
      hotel_reservation_reservation_detail_label_accompany_confirmed_at: "Confirmed At",
      hotel_reservation_reservation_detail_label_accompany_confirmed_type: "Confirmed Type",
      hotel_reservation_reservation_detail_label_accompany_hotel_member_id: "Hotel Member ID",
      hotel_reservation_reservation_detail_label_accompany_hotel_date: "Date",
      hotel_reservation_reservation_detail_accompany_list_guide_empty: "List is empty",
      hotel_reservation_reservation_detail_label_agreed: "Agreement",
      hotel_reservation_reservation_detail_label_agreed_title: "Title",
      hotel_reservation_reservation_detail_label_agreed_agreement: "Agreement",
      hotel_reservation_reservation_detail_label_agreed_agreed_at: "Agreed At",
      hotel_reservation_reservation_detail_label_agreed_view_details: "View Details",
      hotel_reservation_reservation_detail_label_agreed_go_to_detail: "Go to detail",
      hotel_reservation_reservation_detail_label_payment: "Payment",
      hotel_reservation_reservation_detail_label_payment_long_info: "Long info",
      hotel_reservation_reservation_detail_label_payment_amount: "Amount",
      hotel_reservation_reservation_detail_label_payment_unit: "Unit",
      hotel_reservation_reservation_detail_label_payment_pg_status_code: "PG status code",
      hotel_reservation_reservation_detail_label_payment_pg_status_message: "PG status message",
      hotel_reservation_reservation_detail_label_payment_date: "Date",
      hotel_reservation_reservation_detail_label_pms: "PMS",
      hotel_reservation_reservation_detail_label_pms_confirmation_no: "Confirmation No.",
      hotel_reservation_reservation_detail_label_pms_reservation_id: "Reservation ID",
      hotel_reservation_reservation_detail_label_pms_profile_id: "Profile ID",
      hotel_reservation_reservation_detail_label_pms_reservation_status: "Reservation Status",
      hotel_reservation_reservation_detail_label_pms_computed_reservation_status: "Computed Reservation Status",
      hotel_reservation_reservation_detail_label_pms_message_exists: "Message Exists",
      hotel_reservation_reservation_detail_label_pms_arrival_date: "Arrival Date",
      hotel_reservation_reservation_detail_label_pms_departure_date: "Departure Date",
      hotel_reservation_reservation_detail_label_pms_room_type: "Room Type",
      hotel_reservation_reservation_detail_label_pms_room_type_code: "Room Type Code",
      hotel_reservation_reservation_detail_label_pms_room_rate_currency: "Room Rate Currency",
      hotel_reservation_reservation_detail_label_pms_room_rate: "Room Rate",
      hotel_reservation_reservation_detail_label_pms_total_rate: "Total Rate",
      hotel_reservation_reservation_detail_label_pms_current_balance: "Current Balance",
      hotel_reservation_reservation_detail_label_pms_room_number: "Room Number",
      hotel_reservation_reservation_detail_label_pms_adults: "Adults",
      hotel_reservation_reservation_detail_label_pms_children: "Children",
      hotel_reservation_reservation_detail_label_pms_accompany: "Accompany",
      hotel_reservation_reservation_detail_label_pms_accompany_no: "No.",
      hotel_reservation_reservation_detail_label_pms_accompany_profile_id: "Profile ID",
      hotel_reservation_reservation_detail_label_pms_accompany_first_name: "First Name",
      hotel_reservation_reservation_detail_label_pms_accompany_last_name: "Last Name",
      hotel_reservation_reservation_detail_label_pms_accompany_nationality: "Nationality",
      hotel_reservation_reservation_detail_label_pms_accompany_passport_no: "Passport No.",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_birth: "Date Of Birth",
      hotel_reservation_reservation_detail_label_pms_accompany_gender: "Gender",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_expiry: "Date Of Expiry",
      hotel_reservation_reservation_detail_label_pms_main_guest: "Main Guest",
      hotel_reservation_reservation_detail_label_pms_main_guest_profile_id: "Profile ID",
      hotel_reservation_reservation_detail_label_pms_main_guest_first_name: "First Name",
      hotel_reservation_reservation_detail_label_pms_main_guest_last_name: "Last Name",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_nationality: "Nationality",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_passport_no: "Passport No.",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_birth: "Date Of Birth",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_gender: "Gender",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_expiry: "Date Of Expiry",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_date: "Arrival Date",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_no: "Arrival Flight No.",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_time: "Arrival Flight Time",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_date: "Departure Date",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_no: "Departure Flight No.",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_time: "Departure Time",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_wake_up_call: "Wake Up Call",
      hotel_reservation_reservation_detail_label_pms_package: "Package",
      hotel_reservation_reservation_detail_label_pms_package_no: "No.",
      hotel_reservation_reservation_detail_label_pms_package_package_code: "Package Code",
      hotel_reservation_reservation_detail_label_pms_package_description: "Description",
      hotel_reservation_reservation_detail_label_pms_package_amount: "Amount",
      hotel_reservation_reservation_detail_label_pms_charges_by_date: "Charges By Date",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_no: "No.",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_date: "Date",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_description: "Description",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_charges: "Charges",
      hotel_reservation_reservation_detail_label_pms_invoice: "Invoice",
      hotel_reservation_reservation_detail_label_pms_invoice_no: "No.",
      hotel_reservation_reservation_detail_label_pms_invoice_date: "Date",
      hotel_reservation_reservation_detail_label_pms_invoice_description: "Description",
      hotel_reservation_reservation_detail_label_pms_invoice_currency_code: "Currency Code",
      hotel_reservation_reservation_detail_label_pms_invoice_amount: "Amount",
      hotel_reservation_reservation_detail_label_pms_invoice_supplement: "Additional Details",
      hotel_reservation_reservation_detail_button_go_to_list: "Go to list",
      hotel_reservation_reservation_detail_button_cancel: "Cancel",
      hotel_reservation_reservation_compare_face_detail_title: "COMPARE FACE DETAIL",
      hotel_reservation_reservation_detail_label_user_name: "User Name",
      hotel_reservation_reservation_detail_label_identity_document_type: "Identity Document Type",
      hotel_reservation_reservation_detail_label_auth_status: "Auth Status",
      hotel_reservation_reservation_detail_label_similarity: "Similarity",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response: "Government Singapore EVA Response",
      hotel_reservation_reservation_detail_label_government_singapore_eva_status: "Government Singapore EVA Status",
      hotel_reservation_reservation_detail_label_government_singapore_eva_request_at: "Government Singapore EVA Requested At",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response_at: "Government Singapore EVA Response At",
      hotel_reservation_reservation_detail_label_created_at: "Created At",
      hotel_reservation_reservation_compare_face_detail_button_go_to_detail: "Go to detail",
      hotel_reservation_reservation_exist_label_room_no: "Room No.",
      hotel_reservation_reservation_exist_label_period_of_stay: "Period Of Stay",
      hotel_reservation_reservation_exist_label_user: "User",
      hotel_reservation_reservation_exist_label_user_confirmation_no: "Confirmation No.",
      hotel_reservation_reservation_exist_label_user_first_name: "First Name",
      hotel_reservation_reservation_exist_label_user_last_name: "Last Name",
      hotel_reservation_reservation_exist_label_user_email: "Email",
      hotel_reservation_reservation_exist_label_user_gender: "Gender",
      hotel_reservation_reservation_exist_label_user_member_type: "Member Type",
      hotel_reservation_reservation_exist_label_user_date: "Date",
      hotel_reservation_reservation_exist_label_exist_request_list: "Existing Request List",
      hotel_reservation_reservation_exist_label_exist_request_list_: "Request Date",
      hotel_reservation_reservation_exist_label_exist_request_list_request_type: "Request Type",
      hotel_reservation_reservation_exist_label_exist_request_list_express: "Task",
      hotel_reservation_reservation_exist_label_exist_request_list_number_of_item: "Number of Items",
      hotel_reservation_reservation_exist_label_exist_request_list_action_status: "Action Status",
      hotel_reservation_reservation_exist_label_exist_request_list_date: "Date",
      hotel_reservation_reservation_exist_label_exist_request_list_hover: "Request cannot be stopped.",
      hotel_reservation_reservation_exist_button_go_to_list: "Go to list",
      hotel_reservation_reservation_exist_button_stop: "Stop",
      hotel_reservation_waiting_list_title: "ROOM WAITING LIST",
      hotel_reservation_waiting_list_placeholder_select_box_waiting_room: "Room Wait",
      hotel_reservation_waiting_list_select_profile_id: "Profile ID",
      hotel_reservation_waiting_list_select_owner_name: "Guest Name",
      hotel_reservation_waiting_list_placeholder_search: "Search...",
      hotel_reservation_waiting_list_label_no: "No.",
      hotel_reservation_waiting_list_label_profile_id: "Profile ID",
      hotel_reservation_waiting_list_label_title: "Title",
      hotel_reservation_waiting_list_label_main_guest: "Main Guest",
      hotel_reservation_waiting_list_label_main_guest_email: "Main Guest Email",
      hotel_reservation_waiting_list_label_owner_name: "Guest Name",
      hotel_reservation_waiting_list_label_owner_email: "Guest Email",
      hotel_reservation_waiting_list_label_confirmation_no: "Confirmation No.",
      hotel_reservation_waiting_list_guide_empty: "List is empty",
      hotel_reservation_waiting_detail_label_confirmation_no: "Confirmation No.",
      hotel_reservation_waiting_detail_label_owner: "Reserved Guest",
      hotel_reservation_waiting_detail_label_owner_profile_id: "Profile ID",
      hotel_reservation_waiting_detail_label_owner_title: "Title",
      hotel_reservation_waiting_detail_label_owner_first_name: "First Name",
      hotel_reservation_waiting_detail_label_owner_last_name: "Last Name",
      hotel_reservation_waiting_detail_label_owner_confirmed_at: "Confirmed At",
      hotel_reservation_waiting_detail_label_owner_confirmed_type: "Confirmed Type",
      hotel_reservation_waiting_detail_label_owner_waiting_room: "Waiting Room",
      hotel_reservation_waiting_detail_label_owner_date: "Date",
      hotel_reservation_waiting_detail_label_accompany: "Accompany",
      hotel_reservation_waiting_detail_label_accompany_no: "No.",
      hotel_reservation_waiting_detail_label_accompany_profile_id: "Profile ID",
      hotel_reservation_waiting_detail_label_accompany_tile: "Title",
      hotel_reservation_waiting_detail_label_accompany_first_name: "First Name",
      hotel_reservation_waiting_detail_label_accompany_last_name: "Last Name",
      hotel_reservation_waiting_detail_label_accompany_use_key: "Use Key",
      hotel_reservation_waiting_detail_label_accompany_confirmed_at: "Confirmed At",
      hotel_reservation_waiting_detail_label_accompany_confirmed_type: "Confirmed Type",
      hotel_reservation_waiting_detail_label_accompany_hotel_member_id: "Hotel Member ID",
      hotel_reservation_waiting_detail_label_accompany_date: "Date",
      hotel_reservation_waiting_detail_label_pms: "PMS",
      hotel_reservation_waiting_detail_label_pms_confirmation_no: "Confirmation No.",
      hotel_reservation_waiting_detail_label_pms_reservation_id: "Reservation ID",
      hotel_reservation_waiting_detail_label_pms_profile_id: "Profile ID",
      hotel_reservation_waiting_detail_label_pms_reservation_status: "Reservation Status",
      hotel_reservation_waiting_detail_label_pms_message_exists: "Message Exists",
      hotel_reservation_waiting_detail_label_pms_arrival_date: "Arrival Date",
      hotel_reservation_waiting_detail_label_pms_departure_date: "Departure Date",
      hotel_reservation_waiting_detail_label_pms_room_type: "Room Type",
      hotel_reservation_waiting_detail_label_pms_room_type_code: "Room Type Code",
      hotel_reservation_waiting_detail_label_pms_room_rate_currency: "Room Rate Currency",
      hotel_reservation_waiting_detail_label_pms_room_rate: "Room Rate",
      hotel_reservation_waiting_detail_label_pms_total_rate: "Total Rate",
      hotel_reservation_waiting_detail_label_pms_current_balance: "Current Balance",
      hotel_reservation_waiting_detail_label_pms_room_number: "Room Number",
      hotel_reservation_waiting_detail_label_pms_adults: "Adults",
      hotel_reservation_waiting_detail_label_pms_children: "Children",
      hotel_reservation_waiting_detail_label_pms_accompany: "Accompany",
      hotel_reservation_waiting_detail_label_pms_accompany_no: "No.",
      hotel_reservation_waiting_detail_label_pms_accompany_profile_id: "Profile ID",
      hotel_reservation_waiting_detail_label_pms_accompany_first_name: "First Name",
      hotel_reservation_waiting_detail_label_pms_accompany_last_name: "Last Name",
      hotel_reservation_waiting_detail_label_pms_package: "Package",
      hotel_reservation_waiting_detail_label_pms_package_no: "No.",
      hotel_reservation_waiting_detail_label_pms_package_package_code: "Package Code",
      hotel_reservation_waiting_detail_label_pms_package_description: "Description",
      hotel_reservation_waiting_detail_label_pms_package_amount: "Amount",
      hotel_reservation_waiting_detail_label_pms_charges_by_date: "Charges By Date",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_no: "No.",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_date: "Date",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_description: "Description",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_charges: "Charges",
      hotel_reservation_waiting_detail_button_go_to_list: "Go to list",
      hotel_reservation_waiting_detail_button_cancel: "Cancel",
      hotel_reservation_future_booking_list_title: "FUTURE BOOKING LIST",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation_status: "Reservation Status",
      hotel_reservation_future_booking_list_select_inhouse: "In-House",
      hotel_reservation_future_booking_list_select_reserved: "Reserved",
      hotel_reservation_future_booking_list_select_changed: "Changed",
      hotel_reservation_future_booking_list_select_canceled: "Canceled",
      hotel_reservation_future_booking_list_select_no_show: "No Show",
      hotel_reservation_future_booking_list_select_checked_out: "Checked Out",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation: "Future Booking",
      hotel_reservation_future_booking_list_select_confirmation_no: "Confirmation No.",
      hotel_reservation_future_booking_list_select_name: "Name",
      hotel_reservation_future_booking_list_placeholder_search: "Search...",
      hotel_reservation_future_booking_list_label_no: "No.",
      hotel_reservation_future_booking_list_label_confirmation_no: "Confirmation No.",
      hotel_reservation_future_booking_list_label_booking_owner: "Booking Owner",
      hotel_reservation_future_booking_list_label_status: "Status",
      hotel_reservation_future_booking_list_label_occupy: "Pre-occupy",
      hotel_reservation_future_booking_list_label_date: "Date",
      hotel_reservation_future_booking_list_label_cancel: "Cancel",
      hotel_reservation_future_booking_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_list_button_cancel: "Cancel",
      hotel_reservation_future_booking_list_button_sync: "Sync",
      hotel_reservation_future_booking_detail_label_confirmation_no: "Confirmation No.",
      hotel_reservation_future_booking_detail_label_owner: "Booking Owner",
      hotel_reservation_future_booking_detail_label_owner_title: "Title",
      hotel_reservation_future_booking_detail_label_owner_first_name: "First Name",
      hotel_reservation_future_booking_detail_label_owner_last_name: "Last Name",
      hotel_reservation_future_booking_detail_label_owner_use_key: "Use Key",
      hotel_reservation_future_booking_detail_label_owner_confirmed_at: "Confirmed",
      hotel_reservation_future_booking_detail_label_owner_date: "Date",
      hotel_reservation_future_booking_detail_label_pms: "PMS",
      hotel_reservation_future_booking_detail_label_pms_confirmation_no: "Confirmation No.",
      hotel_reservation_future_booking_detail_label_pms_reservation_id: "Reservation ID",
      hotel_reservation_future_booking_detail_label_pms_profile_id: "Profile ID",
      hotel_reservation_future_booking_detail_label_pms_reservation_status: "Reservation Status",
      hotel_reservation_future_booking_detail_label_pms_message_exists: "Message Exists",
      hotel_reservation_future_booking_detail_label_pms_arrival_date: "Arrival Date",
      hotel_reservation_future_booking_detail_label_pms_departure_date: "Departure Date",
      hotel_reservation_future_booking_detail_label_pms_room_type: "Room Type",
      hotel_reservation_future_booking_detail_label_pms_room_type_code: "Room Type Code",
      hotel_reservation_future_booking_detail_label_pms_room_rate_currency: "Room Rate Currency",
      hotel_reservation_future_booking_detail_label_pms_room_rate: "Room Rate",
      hotel_reservation_future_booking_detail_label_pms_total_rate: "Total Rate",
      hotel_reservation_future_booking_detail_label_pms_current_balance: "Current Balance",
      hotel_reservation_future_booking_detail_label_pms_room_number: "Room Number",
      hotel_reservation_future_booking_detail_label_pms_adults: "Adults",
      hotel_reservation_future_booking_detail_label_pms_children: "Children",
      hotel_reservation_future_booking_detail_label_pms_main_guest: "Main Guest",
      hotel_reservation_future_booking_detail_label_pms_main_guest_profile_id: "Profile ID",
      hotel_reservation_future_booking_detail_label_pms_main_guest_first_name: "First Name",
      hotel_reservation_future_booking_detail_label_pms_main_guest_last_name: "Last Name",
      hotel_reservation_future_booking_detail_label_pms_main_guest_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_nationality: "Nationality",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_passport_no: "Passport No.",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_birth: "Date Of Birth",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_gender: "Gender",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_expiry: "Date Of Expiry",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_date: "Arrival Date",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_no: "Arrival Flight No.",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_time: "Arrival Flight Time",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_date: "Departure Flight No.",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_no: "Departure Flight No.",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_time: "Departure Time",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_wake_up_call: "Wake Up Call",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_detail_label_pms_accompany: "Accompany",
      hotel_reservation_future_booking_detail_label_pms_accompany_no: "No.",
      hotel_reservation_future_booking_detail_label_pms_accompany_profile_id: "Profile ID",
      hotel_reservation_future_booking_detail_label_pms_accompany_first_name: "First Name",
      hotel_reservation_future_booking_detail_label_pms_accompany_last_name: "Last Name",
      hotel_reservation_future_booking_detail_label_pms_accompany_info_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_detail_label_pms_package: "Package",
      hotel_reservation_future_booking_detail_label_pms_package_no: "No.",
      hotel_reservation_future_booking_detail_label_pms_package_package_code: "Package Code",
      hotel_reservation_future_booking_detail_label_pms_package_description: "Description",
      hotel_reservation_future_booking_detail_label_pms_package_amount: "Amount",
      hotel_reservation_future_booking_detail_label_pms_package_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date: "Charges By Date",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_no: "No.",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_date: "Date",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_description: "Description",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_charges: "Charges",
      hotel_reservation_future_booking_detail_label_pms_charges_list_guide_empty: "List is empty",
      hotel_reservation_future_booking_detail_button_go_to_list: "Go to list",
      hotel_reservation_future_booking_detail_button_cancel: "Cancel",
      hotel_reservation_rule_setting_reservation_rule_list_title: "RESERVATION RULE LIST",
      hotel_reservation_rule_setting_reservation_rule_list_select_active_type: "Active Type ( ALL )",
      hotel_reservation_rule_setting_reservation_rule_list_select_active: "Active",
      hotel_reservation_rule_setting_reservation_rule_list_select_inactive: "Inactive",
      hotel_reservation_rule_setting_reservation_rule_list_placeholder_search: "Search...",
      hotel_reservation_rule_setting_reservation_rule_list_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_reservation_rule_list_label_reservation_amount: "Reservation Amount",
      hotel_reservation_rule_setting_reservation_rule_list_label_type: "Type",
      hotel_reservation_rule_setting_reservation_rule_list_label_day_before_arrival: "Day Before Arrival",
      hotel_reservation_rule_setting_reservation_rule_list_label_before_time: "Before Time",
      hotel_reservation_rule_setting_reservation_rule_list_label_inactive: "Inactive",
      hotel_reservation_rule_setting_reservation_rule_list_label_created_at: "Created At",
      hotel_reservation_rule_setting_reservation_rule_list_button_delete: "Delete",
      hotel_reservation_rule_setting_reservation_rule_list_button_add: "Add",
      hotel_reservation_rule_setting_reservation_rule_list_guide_empty: "List is empty",
      hotel_reservation_rule_setting_reservation_rule_add_title: "ADD RESERVATION RULE",
      hotel_reservation_rule_setting_reservation_rule_add_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_reservation_rule_add_label_description: "Description",
      hotel_reservation_rule_setting_reservation_rule_add_label_type: "Type",
      hotel_reservation_rule_setting_reservation_rule_add_placeholder_select_box_select: "Select",
      hotel_reservation_rule_setting_reservation_rule_add_select_percent: "Percentage",
      hotel_reservation_rule_setting_reservation_rule_add_select_nights: "Nights",
      hotel_reservation_rule_setting_reservation_rule_add_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_amount: "Amount",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_percent: "Percentage",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_nights: "Nights",
      hotel_reservation_rule_setting_reservation_rule_add_label_transaction_code: "Transaction Code",
      hotel_reservation_rule_setting_reservation_rule_add_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_reservation_rule_add_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_reservation_rule_add_label_inactive: "Inactive",
      hotel_reservation_rule_setting_reservation_rule_add_label_yes: "Yes",
      hotel_reservation_rule_setting_reservation_rule_add_label_no: "No",
      hotel_reservation_rule_setting_reservation_rule_add_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_reservation_rule_add_button_add: "Add",
      hotel_reservation_rule_setting_reservation_rule_add_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_type: "* Please select a type.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_reservation_amount: "* Please enter the percentage.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_dayBefore_arrival: "* Please enter payment code.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_transaction_code: "* Please enter the number of days before check-in.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_reservation_rule_add_validation_before_time: "* Please enter the time before check-in.",
      hotel_reservation_rule_setting_reservation_rule_detail_title: "RESERVATION RULE DETAILS",
      hotel_reservation_rule_setting_reservation_rule_detail_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_reservation_rule_detail_label_description: "Description",
      hotel_reservation_rule_setting_reservation_rule_detail_label_type: "Type",
      hotel_reservation_rule_setting_reservation_rule_detail_select_percent: "Percentage",
      hotel_reservation_rule_setting_reservation_rule_detail_select_nights: "Nights",
      hotel_reservation_rule_setting_reservation_rule_detail_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_amount: "Amount",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_percent: "Percentage",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_nights: "Nights",
      hotel_reservation_rule_setting_reservation_rule_detail_label_transactio_code: "Transaction Code",
      hotel_reservation_rule_setting_reservation_rule_detail_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_reservation_rule_detail_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_reservation_rule_detail_label_inactive: "Inactive",
      hotel_reservation_rule_setting_reservation_rule_detail_label_yes: "Yes",
      hotel_reservation_rule_setting_reservation_rule_detail_label_no: "No",
      hotel_reservation_rule_setting_reservation_rule_detail_label_created_at: "Created At",
      hotel_reservation_rule_setting_reservation_rule_detail_label_updated_at: "Updated At",
      hotel_reservation_rule_setting_reservation_rule_detail_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_reservation_rule_detail_button_cancel: "Cancel",
      hotel_reservation_rule_setting_reservation_rule_detail_button_delete: "Delete",
      hotel_reservation_rule_setting_reservation_rule_detail_button_edit: "Edit",
      hotel_reservation_rule_setting_reservation_rule_detail_button_ok: "OK",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_type: "* Please select a type.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_reservation_amount: "* Please enter the percentage.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_dayBefore_arrival: "* Please enter payment code.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_transaction_code: "* Please enter the number of days before check-in.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_before_time: "* Please enter the time before check-in.",
      hotel_reservation_rule_setting_deposit_rule_list_title: "DEPOSIT RULE LIST",
      hotel_reservation_rule_setting_deposit_rule_list_select_active_type: "Active Type ( ALL )",
      hotel_reservation_rule_setting_deposit_rule_list_select_active: "Active",
      hotel_reservation_rule_setting_deposit_rule_list_select_inactive: "Inactive",
      hotel_reservation_rule_setting_deposit_rule_list_placeholder_search: "Search...",
      hotel_reservation_rule_setting_deposit_rule_list_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_deposit_rule_list_label_deposit_amount: "Deposit Amount",
      hotel_reservation_rule_setting_deposit_rule_list_label_type: "Type",
      hotel_reservation_rule_setting_deposit_rule_list_label_day_before_arrival: "Day Before Arrival",
      hotel_reservation_rule_setting_deposit_rule_list_label_before_time: "Before Time",
      hotel_reservation_rule_setting_deposit_rule_list_label_inactive: "Inactive",
      hotel_reservation_rule_setting_deposit_rule_list_label_created_at: "Created At",
      hotel_reservation_rule_setting_deposit_rule_list_button_delete: "Delete",
      hotel_reservation_rule_setting_deposit_rule_list_button_add: "Add",
      hotel_reservation_rule_setting_deposit_rule_add_title: "ADD DEPOSIT RULE",
      hotel_reservation_rule_setting_deposit_rule_add_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_deposit_rule_add_label_description: "Description",
      hotel_reservation_rule_setting_deposit_rule_add_label_type: "Type",
      hotel_reservation_rule_setting_deposit_rule_add_placeholder_select_box_select: "Select",
      hotel_reservation_rule_setting_deposit_rule_add_select_percent: "Percentage",
      hotel_reservation_rule_setting_deposit_rule_add_select_nights: "Nights",
      hotel_reservation_rule_setting_deposit_rule_add_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_amount: "Amount",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_percent: "Percentage",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_nights: "Nights",
      hotel_reservation_rule_setting_deposit_rule_add_label_transaction_code: "Transaction Code",
      hotel_reservation_rule_setting_deposit_rule_add_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_deposit_rule_add_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_deposit_rule_add_label_inactive: "Inactive",
      hotel_reservation_rule_setting_deposit_rule_add_label_yes: "Yes",
      hotel_reservation_rule_setting_deposit_rule_add_label_no: "No",
      hotel_reservation_rule_setting_deposit_rule_add_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_deposit_rule_add_button_add: "Add",
      hotel_reservation_rule_setting_deposit_rule_add_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_type: "* Please select a type.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_deposit_amount: "* Please enter the percentage.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_transaction_code: "* Please enter payment code.",
      hotel_reservation_rule_setting_deposit_rule_add_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_deposit_rule_detail_title: "DEPOSIT RULE INFORMATION",
      hotel_reservation_rule_setting_deposit_rule_detail_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_deposit_rule_detail_label_description: "Description",
      hotel_reservation_rule_setting_deposit_rule_detail_label_type: "Type",
      hotel_reservation_rule_setting_deposit_rule_detail_select_percent: "Percentage",
      hotel_reservation_rule_setting_deposit_rule_detail_select_nights: "Nights",
      hotel_reservation_rule_setting_deposit_rule_detail_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_amount: "Amount",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_percent: "Percentage",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_nights: "Nights",
      hotel_reservation_rule_setting_deposit_rule_detail_label_transactio_code: "Transaction Code",
      hotel_reservation_rule_setting_deposit_rule_detail_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_deposit_rule_detail_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_deposit_rule_detail_label_inactive: "Inactive",
      hotel_reservation_rule_setting_deposit_rule_detail_label_yes: "Yes",
      hotel_reservation_rule_setting_deposit_rule_detail_label_no: "No",
      hotel_reservation_rule_setting_deposit_rule_detail_label_created_at: "Created At",
      hotel_reservation_rule_setting_deposit_rule_detail_label_updated_at: "Updated At",
      hotel_reservation_rule_setting_deposit_rule_detail_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_deposit_rule_detail_button_cancel: "Cancel",
      hotel_reservation_rule_setting_deposit_rule_detail_button_delete: "Delete",
      hotel_reservation_rule_setting_deposit_rule_detail_button_edit: "Edit",
      hotel_reservation_rule_setting_deposit_rule_detail_button_ok: "OK",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_type: "* Please select a type.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_deposit_amount: "* Please enter the percentage.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_transaction_code: "* Please enter payment code.",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_cancellation_rule_list_title: "CANCELLATION RULE LIST",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active_type: "Active Type ( ALL )",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active: "Active",
      hotel_reservation_rule_setting_cancellation_rule_list_select_inactive: "Inactive",
      hotel_reservation_rule_setting_cancellation_rule_list_placeholder_search: "Search...",
      hotel_reservation_rule_setting_cancellation_rule_list_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_cancellation_rule_list_label_penalty_amount: "Penalty Amount",
      hotel_reservation_rule_setting_cancellation_rule_list_label_type: "Type",
      hotel_reservation_rule_setting_cancellation_rule_list_label_day_before_arrival: "Day Before Arrival",
      hotel_reservation_rule_setting_cancellation_rule_list_label_before_time: "Before Time",
      hotel_reservation_rule_setting_cancellation_rule_list_label_inactive: "Inactive",
      hotel_reservation_rule_setting_cancellation_rule_list_label_created_at: "Created At",
      hotel_reservation_rule_setting_cancellation_rule_list_button_delete: "Delete",
      hotel_reservation_rule_setting_cancellation_rule_list_button_add: "Add",
      hotel_reservation_rule_setting_cancellation_rule_list_guide_empty: "List is empty",
      hotel_reservation_rule_setting_cancellation_rule_add_title: "ADD CANCELLATION RULE",
      hotel_reservation_rule_setting_cancellation_rule_add_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_cancellation_rule_add_label_description: "Description",
      hotel_reservation_rule_setting_cancellation_rule_add_label_type: "Type",
      hotel_reservation_rule_setting_cancellation_rule_add_placeholder_select_box_select: "Select",
      hotel_reservation_rule_setting_cancellation_rule_add_select_percent: "Percentage",
      hotel_reservation_rule_setting_cancellation_rule_add_select_nights: "Nights",
      hotel_reservation_rule_setting_cancellation_rule_add_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_amount: "Amount",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_percent: "Percentage",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_nights: "Nights",
      hotel_reservation_rule_setting_cancellation_rule_add_label_transaction_code: "Transaction Code",
      hotel_reservation_rule_setting_cancellation_rule_add_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_cancellation_rule_add_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_cancellation_rule_add_label_inactive: "Inactive",
      hotel_reservation_rule_setting_cancellation_rule_add_label_yes: "Yes",
      hotel_reservation_rule_setting_cancellation_rule_add_label_no: "No",
      hotel_reservation_rule_setting_cancellation_rule_add_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_cancellation_rule_add_button_add: "Add",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_type: "* Please select a type.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_penalty_amount: "* Please enter the percentage.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_dayBefore_arrival: "* Please enter payment code.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_transaction_code: "* Please enter the number of days before check-in.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_before_time: "* Please enter the time before check-in.",
      hotel_reservation_rule_setting_cancellation_rule_detail_title: "CANCELLATION RULE INFORMATION",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_description: "Description",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_type: "Type",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_percent: "Percentage",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_nights: "Nights",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_amount: "Amount",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_percent: "Percentage",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_nights: "Nights",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_transactio_code: "Transaction Code",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_inactive: "Inactive",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_yes: "Yes",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_no: "No",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_created_at: "Created At",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_updated_at: "Updated At",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_cancel: "Cancel",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_delete: "Delete",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_edit: "Edit",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_ok: "OK",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_type: "* Please select a type.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_penalty_amount: "* Please enter the percentage.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_dayBefore_arrival: "* Please enter payment code.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_transaction_code: "* Please enter the number of days before check-in.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_before_time: "* Please enter the time before check-in.",
      hotel_reservation_rule_setting_no_show_rule_list_title: "NO SHOW RULE LIST",
      hotel_reservation_rule_setting_no_show_rule_list_select_posting_rule: "Posting Rule ( ALL )",
      hotel_reservation_rule_setting_no_show_rule_list_select_all_nights: "All Nights",
      hotel_reservation_rule_setting_no_show_rule_list_select_deposit_only: "Deposit Only",
      hotel_reservation_rule_setting_no_show_rule_list_select_first_night: "First Night",
      hotel_reservation_rule_setting_no_show_rule_list_select_percent: "Percentage",
      hotel_reservation_rule_setting_no_show_rule_list_placeholder_search: "Search...",
      hotel_reservation_rule_setting_no_show_rule_list_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_no_show_rule_list_label_penalty_amount: "Penalty Amount",
      hotel_reservation_rule_setting_no_show_rule_list_label_source_code: "Source Code",
      hotel_reservation_rule_setting_no_show_rule_list_label_guarantee_type: "Guarantee Type",
      hotel_reservation_rule_setting_no_show_rule_list_label_posting_rule: "Posting Rule",
      hotel_reservation_rule_setting_no_show_rule_list_label_created_at: "Created At",
      hotel_reservation_rule_setting_no_show_rule_list_button_delete: "Delete",
      hotel_reservation_rule_setting_no_show_rule_list_button_add: "Add",
      hotel_reservation_rule_setting_no_show_rule_list_guide_empty: "List is empty",
      hotel_reservation_rule_setting_no_show_rule_add_title: "ADD NO SHOW RULE",
      hotel_reservation_rule_setting_no_show_rule_add_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_no_show_rule_add_label_description: "Description",
      hotel_reservation_rule_setting_no_show_rule_add_label_source_code: "Source Code",
      hotel_reservation_rule_setting_no_show_rule_add_label_guarantee_type: "Guarantee Type",
      hotel_reservation_rule_setting_no_show_rule_add_label_posting_rule: "Posting Rule",
      hotel_reservation_rule_setting_no_show_rule_add_select_all_nights: "All Nights",
      hotel_reservation_rule_setting_no_show_rule_add_select_deposit_only: "Deposit Only",
      hotel_reservation_rule_setting_no_show_rule_add_select_first_night: "First Night",
      hotel_reservation_rule_setting_no_show_rule_add_select_percent: "Percentage",
      hotel_reservation_rule_setting_no_show_rule_add_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_amount: "Penalty Amount",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_percent: "Penalty Percentage",
      hotel_reservation_rule_setting_no_show_rule_add_label_transaction_code: "Transaction Code",
      hotel_reservation_rule_setting_no_show_rule_add_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_no_show_rule_add_button_add: "Add",
      hotel_reservation_rule_setting_no_show_rule_add_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_no_show_rule_add_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_no_show_rule_add_validation_posting_rule: "* Please select a billing rule.",
      hotel_reservation_rule_setting_no_show_rule_add_validation_penalty_amount: "* Please enter payment code.",
      hotel_reservation_rule_setting_no_show_rule_detail_title: "NO SHOW RULE INFORMATION",
      hotel_reservation_rule_setting_no_show_rule_detail_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_no_show_rule_detail_label_description: "Description",
      hotel_reservation_rule_setting_no_show_rule_detail_label_source_code: "Source Code",
      hotel_reservation_rule_setting_no_show_rule_detail_label_guarantee_type: "Guarantee Type",
      hotel_reservation_rule_setting_no_show_rule_detail_label_posting_rule: "Posting Rule",
      hotel_reservation_rule_setting_no_show_rule_detail_select_all_nights: "All Nights",
      hotel_reservation_rule_setting_no_show_rule_detail_select_deposit_only: "Deposit Only",
      hotel_reservation_rule_setting_no_show_rule_detail_select_first_night: "First Night",
      hotel_reservation_rule_setting_no_show_rule_detail_select_percent: "Percentage",
      hotel_reservation_rule_setting_no_show_rule_detaill_select_flat: "Flat Rate",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_amount: "Penalty Amount",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_percent: "Penalty Percentage",
      hotel_reservation_rule_setting_no_show_rule_detail_label_transactio_code: "Transaction Code",
      hotel_reservation_rule_setting_no_show_rule_detail_label_created_at: "Created At",
      hotel_reservation_rule_setting_no_show_rule_detail_label_updated_at: "Updated At",
      hotel_reservation_rule_setting_no_show_rule_detail_button_go_to_list: "Go to list",
      hotel_reservation_rule_setting_no_show_rule_detail_button_cancel: "Cancel",
      hotel_reservation_rule_setting_no_show_rule_detail_button_delete: "Delete",
      hotel_reservation_rule_setting_no_show_rule_detail_button_edit: "Edit",
      hotel_reservation_rule_setting_no_show_rule_detail_button_ok: "OK",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_rule_name: "* Please enter the rule name.",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_posting_rule: "* Please select a billing rule.",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_penalty_amount: "* Please enter payment code.",
      hotel_reservation_rule_setting_rule_schedule_list_title: "RULE SCHEDULE LIST",
      hotel_reservation_rule_setting_rule_schedule_list_label_active: "Active",
      hotel_reservation_rule_setting_rule_schedule_list_label_reservation_rule: "Reservation Rule",
      hotel_reservation_rule_setting_rule_schedule_list_label_no: "No.",
      hotel_reservation_rule_setting_rule_schedule_list_label_schedule_name: "Schedule Name",
      hotel_reservation_rule_setting_rule_schedule_list_label_rate_code: "Rate Code",
      hotel_reservation_rule_setting_rule_schedule_list_label_begin_date: "Begin Date",
      hotel_reservation_rule_setting_rule_schedule_list_label_end_date: "End Date",
      hotel_reservation_rule_setting_rule_schedule_list_label_week: "Day Of The Week",
      hotel_reservation_rule_setting_rule_schedule_list_label_week_all: "All",
      hotel_reservation_rule_setting_rule_schedule_list_label_created_at: "Created At",
      hotel_reservation_rule_setting_rule_schedule_list_label_sorting: "Sorting",
      hotel_reservation_rule_setting_rule_schedule_list_label_deposit_rule: "Deposit Rule",
      hotel_reservation_rule_setting_rule_schedule_list_label_cancellation_rule: "Cancellation Rule",
      hotel_reservation_rule_setting_rule_schedule_list_label_no_show_rule: "No Show Rule",
      hotel_reservation_rule_setting_rule_schedule_list_label_inactive: "Inactive",
      hotel_reservation_rule_setting_rule_schedule_list_select_rule_type: "Rule Type ( ALL )",
      hotel_reservation_rule_setting_rule_schedule_list_select_reservation_rule: "Reservation Rule",
      hotel_reservation_rule_setting_rule_schedule_list_select_deposit_rule: "Deposit Rule",
      hotel_reservation_rule_setting_rule_schedule_list_select_cancellation_rule: "Cancellation Rule",
      hotel_reservation_rule_setting_rule_schedule_list_select_no_show_rule: "No Show Rule",
      hotel_reservation_rule_setting_rule_schedule_list_placeholder_search: "Search...",
      hotel_reservation_rule_setting_rule_schedule_list_label_rule_type: "Rule Type",
      hotel_reservation_rule_setting_rule_schedule_list_label_delete: "Delete",
      hotel_reservation_rule_setting_rule_schedule_list_label_add: "Add",
      hotel_reservation_rule_setting_rule_schedule_list_guide_empty: "List is empty",
      hotel_reservation_rule_setting_rule_schedule_add_title: "ADD RULE SCHEDULE",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_type: "Rule Type",
      hotel_reservation_rule_setting_rule_schedule_add_placeholder_select_box_select: "Select",
      hotel_reservation_rule_setting_rule_schedule_add_select_reservation_rule: "Reservation Rule",
      hotel_reservation_rule_setting_rule_schedule_add_select_deposit_rule: "Deposit Rule",
      hotel_reservation_rule_setting_rule_schedule_add_select_cancellation_rule: "Cancellation Rule",
      hotel_reservation_rule_setting_rule_schedule_add_select_no_show_rule: "No Show Rule",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_rule_schedule_add_label_schedule_name: "Schedule Name",
      hotel_reservation_rule_setting_rule_schedule_add_label_description: "Description",
      hotel_reservation_rule_setting_rule_schedule_add_label_rate_code: "Rate Code",
      hotel_reservation_rule_setting_rule_schedule_add_label_begin_date: "Begin Date",
      hotel_reservation_rule_setting_rule_schedule_add_label_end_date: "End Date",
      hotel_reservation_rule_setting_rule_schedule_add_label_week: "Day of the week",
      hotel_reservation_rule_setting_rule_schedule_add_label_inactive: "Inactive",
      hotel_reservation_rule_setting_rule_schedule_add_label_yes: "Yes",
      hotel_reservation_rule_setting_rule_schedule_add_label_no: "No",
      hotel_reservation_rule_setting_rule_schedule_add_label_sequence: "Sequence",
      hotel_reservation_rule_setting_rule_schedule_add_label_type: "Type",
      hotel_reservation_rule_setting_rule_schedule_add_label_amount: "Amount",
      hotel_reservation_rule_setting_rule_schedule_add_label_percent: "Percentage",
      hotel_reservation_rule_setting_rule_schedule_add_label_nights: "Nights",
      hotel_reservation_rule_setting_rule_schedule_add_label_transaction_code: "Transaction Code",
      hotel_reservation_rule_setting_rule_schedule_add_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_rule_schedule_add_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_rule_schedule_add_label_source_code: "Source Code",
      hotel_reservation_rule_setting_rule_schedule_add_label_guarantee_type: "Guarantee Type",
      hotel_reservation_rule_setting_rule_schedule_add_label_posting_rule: "Posting Rule",
      hotel_reservation_rule_setting_rule_schedule_add_label_go_to_list: "Go to list",
      hotel_reservation_rule_setting_rule_schedule_add_label_add: "Add",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_type: "* Please select a rule type.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_name: "* Please select a rule name.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_schedule_name: "* Please enter the schedule name.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rate_code: "* Please enter the rate code.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_begin_date: "* Please select a start date.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_end_date: "* Please select the final date.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_week: "* Please select a day.",
      hotel_reservation_rule_setting_rule_schedule_add_validation_inactive: "* Please select the deactivation status.",
      hotel_reservation_rule_setting_rule_schedule_detail_title: "RULE SCHEDULE INFORMATION",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_type: "Rule Type",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_name: "Rule Name",
      hotel_reservation_rule_setting_rule_schedule_detail_label_schedule_name: "Schedule Name",
      hotel_reservation_rule_setting_rule_schedule_detail_label_description: "Description",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rate_code: "Rate Code",
      hotel_reservation_rule_setting_rule_schedule_detail_label_begin_date: "Begin Date",
      hotel_reservation_rule_setting_rule_schedule_detail_label_end_date: "End Date",
      hotel_reservation_rule_setting_rule_schedule_detail_label_week: "Day of the week",
      hotel_reservation_rule_setting_rule_schedule_detail_label_inactive: "Inactive",
      hotel_reservation_rule_setting_rule_schedule_detail_label_yes: "Yes",
      hotel_reservation_rule_setting_rule_schedule_detail_label_no: "No",
      hotel_reservation_rule_setting_rule_schedule_detail_label_sequence: "Sequence",
      hotel_reservation_rule_setting_rule_schedule_detail_label_created_at: "Created At",
      hotel_reservation_rule_setting_rule_schedule_detail_label_updated_at: "Updated At",
      hotel_reservation_rule_setting_rule_schedule_detail_label_type: "Type",
      hotel_reservation_rule_setting_rule_schedule_detail_label_amount: "Amount",
      hotel_reservation_rule_setting_rule_schedule_detail_label_percent: "Percentage",
      hotel_reservation_rule_setting_rule_schedule_detail_label_nights: "Nights",
      hotel_reservation_rule_setting_rule_schedule_detail_label_transaction_code: "Transaction Code",
      hotel_reservation_rule_setting_rule_schedule_detail_label_day_before_arrival: "Day Before Arrival (Day)",
      hotel_reservation_rule_setting_rule_schedule_detail_label_before_time: "Before Time (Hour:Minutes)",
      hotel_reservation_rule_setting_rule_schedule_detail_label_source_code: "Source Code",
      hotel_reservation_rule_setting_rule_schedule_detail_label_guarantee_type: "Guarantee Type",
      hotel_reservation_rule_setting_rule_schedule_detail_label_posting_rule: "Posting Rule",
      hotel_reservation_rule_setting_rule_schedule_detail_label_go_to_list: "Go to list",
      hotel_reservation_rule_setting_rule_schedule_detail_label_cancel: "Cancel",
      hotel_reservation_rule_setting_rule_schedule_detail_label_delete: "Delete",
      hotel_reservation_rule_setting_rule_schedule_detail_label_edit: "Edit",
      hotel_reservation_rule_setting_rule_schedule_detail_label_ok: "OK",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_type: "* Please select a rule type.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_name: "* Please select a rule name.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_schedule_name: "* Please enter the schedule name.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_description: "* Please enter a description.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rate_code: "* Please enter the rate code.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_begin_date: "* Please select a start date.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_end_date: "* Please select the final date.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_week: "* Please select a day.",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_inactive: "* Please select the deactivation status.",
      hotel_inhouse_hotel_info_list_title: "HOTEL INFORMATION MENU",
      hotel_inhouse_hotel_info_list_button_add: "Add",
      hotel_inhouse_hotel_info_list_button_delete: "Delete",
      hotel_inhouse_hotel_info_list_button_edit: "Edit",
      hotel_inhouse_hotel_info_list_guide_add: "Please add hotel information.",
      hotel_inhouse_hotel_info_add_title: "ADD HOTEL INFORMATION",
      hotel_inhouse_hotel_info_add_placeholder_title: "Please enter the title.",
      hotel_inhouse_hotel_info_add_placeholder_index: "Please enter the index.",
      hotel_inhouse_hotel_info_add_select_choose: "Please select type.",
      hotel_inhouse_hotel_info_add_select_address: "Hotel Address",
      hotel_inhouse_hotel_info_add_select_wifi: "WiFi",
      hotel_inhouse_hotel_info_add_select_smoking: "Non-Smoking",
      hotel_inhouse_hotel_info_add_select_transportation: "Transportation",
      hotel_inhouse_hotel_info_add_select_parking: "Parking",
      hotel_inhouse_hotel_info_add_select_fitness_center: "Fitness Center",
      hotel_inhouse_hotel_info_add_select_business_center: "Business Center",
      hotel_inhouse_hotel_info_add_select_guest_service: "Guest Services",
      hotel_inhouse_hotel_info_add_select_room_service: "Room Service",
      hotel_inhouse_hotel_info_add_select_breakfast: "Breakfast",
      hotel_inhouse_hotel_info_add_select_dining: "Dining",
      hotel_inhouse_hotel_info_add_select_executive_lounge: "Executive Lounge",
      hotel_inhouse_hotel_info_add_toolbar_bold: "Bold",
      hotel_inhouse_hotel_info_add_toolbar_italic: "Italic",
      hotel_inhouse_hotel_info_add_toolbar_underline: "Underline",
      hotel_inhouse_hotel_info_add_toolbar_remove_font_style: "Remove font style",
      hotel_inhouse_hotel_info_add_toolbar_font_family: "Font family",
      hotel_inhouse_hotel_info_add_toolbar_font_size: "Font size",
      hotel_inhouse_hotel_info_add_toolbar_recent_color: "Recent color",
      hotel_inhouse_hotel_info_add_toolbar_recent_more_color: "More Colors",
      hotel_inhouse_hotel_info_add_toolbar_unordered_list: "Unordered list",
      hotel_inhouse_hotel_info_add_toolbar_ordered_list: "Ordered list",
      hotel_inhouse_hotel_info_add_toolbar_paragraph: "Paragraph",
      hotel_inhouse_hotel_info_add_toolbar_line_height: "Line height",
      hotel_inhouse_hotel_info_add_placeholder_content: "Please enter the content.",
      hotel_inhouse_hotel_info_add_button_cancel: "Cancel",
      hotel_inhouse_hotel_info_add_button_ok: "OK",
      hotel_inhouse_hotel_info_edit_title: "EDIT HOTEL INFORMATION",
      hotel_inhouse_hotel_info_edit_placeholder_title: "Title",
      hotel_inhouse_hotel_info_edit_select_kr: "KR",
      hotel_inhouse_hotel_info_edit_select_en: "EN",
      hotel_inhouse_hotel_info_edit_select_cn: "CN",
      hotel_inhouse_hotel_info_edit_select_jp: "JP",
      hotel_inhouse_hotel_info_edit_placeholder_index: "Index",
      hotel_inhouse_hotel_info_edit_toolbar_bold: "Bold",
      hotel_inhouse_hotel_info_edit_toolbar_italic: "Italic",
      hotel_inhouse_hotel_info_edit_toolbar_underline: "Underline",
      hotel_inhouse_hotel_info_edit_toolbar_remove_font_style: "Remove font style",
      hotel_inhouse_hotel_info_edit_toolbar_font_family: "Font family",
      hotel_inhouse_hotel_info_edit_toolbar_font_size: "Font size",
      hotel_inhouse_hotel_info_edit_toolbar_recent_color: "Recent color",
      hotel_inhouse_hotel_info_edit_toolbar_recent_more_color: "More colors",
      hotel_inhouse_hotel_info_edit_toolbar_unordered_list: "Unordered list",
      hotel_inhouse_hotel_info_edit_toolbar_ordered_list: "Ordered list",
      hotel_inhouse_hotel_info_edit_toolbar_paragraph: "Paragraph",
      hotel_inhouse_hotel_info_edit_toolbar_code_view: "Code View",
      hotel_inhouse_hotel_info_edit_toolbar_line_height: "Line Height",
      hotel_inhouse_hotel_info_edit_button_append: "Append",
      hotel_inhouse_hotel_info_edit_button_remove: "Remove",
      hotel_inhouse_hotel_info_edit_button_cancel: "Cancel",
      hotel_inhouse_hotel_info_edit_button_ok: "OK",
      hotel_inhouse_room_to_room_list_title: "ROOM TO ROOM LIST",
      hotel_inhouse_room_to_room_list_button_alive: "Active",
      hotel_inhouse_room_to_room_list_placeholder_select_box_room_to_room: "Room to Room",
      hotel_inhouse_room_to_room_list_select_room_no: "Room No.",
      hotel_inhouse_room_to_room_list_select_email: "Email",
      hotel_inhouse_room_to_room_list_select_user_name: "User Name",
      hotel_inhouse_room_to_room_list_placeholder_search: "Search...",
      hotel_inhouse_room_to_room_list_label_no: "No.",
      hotel_inhouse_room_to_room_list_label_sender_room_no: "Sender Room No.",
      hotel_inhouse_room_to_room_list_label_sender_name: "Sender Name",
      hotel_inhouse_room_to_room_list_label_sender_email: "Sender Email",
      hotel_inhouse_room_to_room_list_label_key_type: "Key Type",
      hotel_inhouse_room_to_room_list_label_expired_at: "Expired At",
      hotel_inhouse_room_to_room_list_label_receiver_room_no: "Receiver Room No.",
      hotel_inhouse_room_to_room_list_label_receiver_email: "Receiver Email",
      hotel_inhouse_room_to_room_list_label_receiver_status: "Receiver Status",
      hotel_inhouse_room_to_room_list_label_date: "Date",
      hotel_inhouse_room_to_room_list_guide_empty: "List is empty",
      hotel_inhouse_room_to_room_detail_title: "ROOM TO ROOM DETAILS",
      hotel_inhouse_room_to_room_detail_label_sender_room_no: "Sender Room No.",
      hotel_inhouse_room_to_room_detail_label_sender_name: "Sender Name",
      hotel_inhouse_room_to_room_detail_label_sender_email: "Sender Email",
      hotel_inhouse_room_to_room_detail_label_key_type: "Room to Room Key Type",
      hotel_inhouse_room_to_room_detail_label_expired_at: "Room to Room Expiration",
      hotel_inhouse_room_to_room_detail_label_receiver_room_no: "Receiver Room No.",
      hotel_inhouse_room_to_room_detail_label_receiver_email: "Receiver Email",
      hotel_inhouse_room_to_room_detail_label_receiver_status: "Receiver Status",
      hotel_inhouse_room_to_room_detail_label_created_at: "Created At",
      hotel_inhouse_room_to_room_detail_button_go_to_list: "Go to list",
      hotel_inhouse_concierge_request_list_title: "CONCIERGE REQUEST LIST (Before Confirmation)",
      hotel_inhouse_concierge_request_list_label_that_date_request: "Number of Guest Requests",
      hotel_inhouse_concierge_request_list_label_that_date_not_confirmed: "Unconfirmed Guest Requests",
      hotel_inhouse_concierge_request_list_label_taxi_ex: "Taxi Express",
      hotel_inhouse_concierge_request_list_label_taxi: "Taxi",
      hotel_inhouse_concierge_request_list_label_parcel_ex: "Parcel Service Express",
      hotel_inhouse_concierge_request_list_label_parcel: "Parcel Service",
      hotel_inhouse_concierge_request_list_label_luggage_ex: "Luggage Service Express",
      hotel_inhouse_concierge_request_list_label_luggage: "Luggage Service",
      hotel_inhouse_concierge_request_list_label_total: "Total",
      hotel_inhouse_concierge_request_list_select_request_type: "Request Type ( ALL )",
      hotel_inhouse_concierge_request_list_select_request_type_all: "All",
      hotel_inhouse_concierge_request_list_select_taxi: "Taxi",
      hotel_inhouse_concierge_request_list_select_parcel: "Parcel Service",
      hotel_inhouse_concierge_request_list_select_luggage: "Luggage Service",
      hotel_inhouse_concierge_request_list_select_express: "Task ( ALL )",
      hotel_inhouse_concierge_request_list_select_express_all: "All",
      hotel_inhouse_concierge_request_list_select_yes: "Express",
      hotel_inhouse_concierge_request_list_select_no: "Normal",
      hotel_inhouse_concierge_request_list_select_request: "Request ( ALL )",
      hotel_inhouse_concierge_request_list_select_request_all: "All",
      hotel_inhouse_concierge_request_list_select_room_no: "Room No.",
      hotel_inhouse_concierge_request_list_select_user_name: "User Name",
      hotel_inhouse_concierge_request_list_placeholder_search: "Search...",
      hotel_inhouse_concierge_request_list_label_room_no: "Room No.",
      hotel_inhouse_concierge_request_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_concierge_request_list_label_user_name: "User Name",
      hotel_inhouse_concierge_request_list_label_request_date: "Request Date",
      hotel_inhouse_concierge_request_list_label_request_type: "Request Type",
      hotel_inhouse_concierge_request_list_label_express: "Task",
      hotel_inhouse_concierge_request_list_label_number_of_item: "Number of Items",
      hotel_inhouse_concierge_request_list_label_action_status: "Action Status",
      hotel_inhouse_concierge_request_list_label_date: "Date",
      hotel_inhouse_concierge_request_list_guide_empty: "List is empty",
      hotel_inhouse_concierge_request_detail_title: "REQUEST DETAILS",
      hotel_inhouse_concierge_request_detail_label_room_no: "Room No.",
      hotel_inhouse_concierge_request_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_concierge_request_detail_label_user_name: "User Name",
      hotel_inhouse_concierge_request_detail_label_email: "Email",
      hotel_inhouse_concierge_request_detail_label_stay: "Stay",
      hotel_inhouse_concierge_request_detail_label_request_type: "Request Type",
      hotel_inhouse_concierge_request_detail_label_status: "Status",
      hotel_inhouse_concierge_request_detail_label_number_of_item: "Number of Items",
      hotel_inhouse_concierge_request_detail_label_request_at: "Requested At",
      hotel_inhouse_concierge_request_detail_label_created_at: "Created At",
      hotel_inhouse_concierge_request_detail_label_total: "Total",
      hotel_inhouse_concierge_request_detail_button_not_available: "Not Available",
      hotel_inhouse_concierge_request_detail_button_myconect: "MyCONECT",
      hotel_inhouse_concierge_request_detail_button_confirm: "Confirm",
      hotel_inhouse_concierge_request_detail_button_go_to_list: "Go to list",
      hotel_inhouse_concierge_request_modal_title: "MyCONECT",
      hotel_inhouse_concierge_request_modal_button_one_time: "One Time",
      hotel_inhouse_concierge_request_modal_button_one_hour: "After 1 hr",
      hotel_inhouse_concierge_request_modal_button_two_hour: "After 2 hrs",
      hotel_inhouse_concierge_request_modal_button_three_hour: "After 3 hrs",
      hotel_inhouse_concierge_request_modal_button_unlimited: "Unknown",
      hotel_inhouse_concierge_request_modal_button_cancel: "Cancel",
      hotel_inhouse_concierge_process_list_title: "CONCIERGE PROGRESS LIST\n (After Confirmation)",
      hotel_inhouse_concierge_process_list_label_request: "Number of Guest Requests",
      hotel_inhouse_concierge_process_list_label_unassigned: "Date Unassigned",
      hotel_inhouse_concierge_process_list_label_taxi_ex: "Taxi Express",
      hotel_inhouse_concierge_process_list_label_taxi: "Taxi",
      hotel_inhouse_concierge_process_list_label_parcel_ex: "Parcel Service Express",
      hotel_inhouse_concierge_process_list_label_parcel: "Parcel Service",
      hotel_inhouse_concierge_process_list_label_luggage_ex: "Luggage Service Express",
      hotel_inhouse_concierge_process_list_label_luggage: "Luggage Service",
      hotel_inhouse_concierge_process_list_label_total: "Total",
      hotel_inhouse_concierge_process_list_select_request_type: "Request Type ( ALL )",
      hotel_inhouse_concierge_process_list_select_taxi: "Taxi",
      hotel_inhouse_concierge_process_list_select_parcel: "Parcel Service",
      hotel_inhouse_concierge_process_list_select_luggage: "Luggage Service",
      hotel_inhouse_concierge_process_list_select_express: "Task ( ALL )",
      hotel_inhouse_concierge_process_list_select_yes: "Express",
      hotel_inhouse_concierge_process_list_select_no: "Normal",
      hotel_inhouse_concierge_process_list_select_request: "Request ( ALL )",
      hotel_inhouse_concierge_process_list_select_room_no: "Room No.",
      hotel_inhouse_concierge_process_list_select_user_name: "User Name",
      hotel_inhouse_concierge_process_list_placeholder_search: "Search...",
      hotel_inhouse_concierge_process_list_label_room_no: "Room No.",
      hotel_inhouse_concierge_process_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_concierge_process_list_label_user_name: "User Name",
      hotel_inhouse_concierge_process_list_label_request_date: "Request Date",
      hotel_inhouse_concierge_process_list_label_request_type: "Request Type",
      hotel_inhouse_concierge_process_list_label_express: "Task",
      hotel_inhouse_concierge_process_list_label_number_of_item: "Number of Items",
      hotel_inhouse_concierge_process_list_label_action_status: "Action Status",
      hotel_inhouse_concierge_process_list_label_date: "Date",
      hotel_inhouse_concierge_process_list_guide_empty: "List is empty",
      hotel_inhouse_concierge_process_detail_title: "REQUEST (After Confirmation) DETAIL",
      hotel_inhouse_concierge_process_detail_label_room_no: "Room No.",
      hotel_inhouse_concierge_process_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_concierge_process_detail_label_user_name: "User Name",
      hotel_inhouse_concierge_process_detail_label_email: "Email",
      hotel_inhouse_concierge_process_detail_label_stay: "Stay",
      hotel_inhouse_concierge_process_detail_label_request_type: "Request Type",
      hotel_inhouse_concierge_process_detail_label_status: "Status",
      hotel_inhouse_concierge_process_detail_label_number_of_item: "Number of Items",
      hotel_inhouse_concierge_process_detail_label_request_at: "Request At",
      hotel_inhouse_concierge_process_detail_label_created_at: "Created At",
      hotel_inhouse_concierge_process_detail_button_stand_by: "Stand by",
      hotel_inhouse_concierge_process_detail_button_vehicle_no: "Vehicle No.",
      hotel_inhouse_concierge_process_detail_button_complete: "Complete",
      hotel_inhouse_concierge_process_detail_button_stop: "Stop",
      hotel_inhouse_concierge_process_detail_button_pickup: "Pickup",
      hotel_inhouse_concierge_process_detail_button_start: "Start",
      hotel_inhouse_concierge_process_detail_button_deliver: "Deliver",
      hotel_inhouse_concierge_process_detail_button_change: "Change",
      hotel_inhouse_concierge_process_detail_button_go_to_list: "Go to list",
      hotel_inhouse_concierge_process_modal_title_vehicle_no: "Vehicle No.",
      hotel_inhouse_concierge_process_modal_placeholder_vehicle_no: "Enter vehicle no.",
      hotel_inhouse_concierge_process_modal_title_luggage: "No. of Luggage",
      hotel_inhouse_concierge_process_modal_placeholder_luggage: "Enter number of luggage.",
      hotel_inhouse_concierge_process_modal_title_parcel: "Parcel Info",
      hotel_inhouse_concierge_process_modal_button_cancel: "Cancel",
      hotel_inhouse_concierge_process_modal_button_ok: "OK",
      hotel_inhouse_concierge_process_modal_title_stop_message: "Stop Message",
      hotel_inhouse_concierge_process_modal_button_unknown_situation: "Status Unknown",
      hotel_inhouse_concierge_process_modal_button_no_taxi: "Taxi no show",
      hotel_inhouse_concierge_process_modal_button_no_guest: "Guest no show",
      hotel_inhouse_concierge_process_modal_button_waiting_30: "Waiting time exceeded 30 min",
      hotel_inhouse_concierge_process_modal_button_waiting_60: "Waiting time exceeded 60 min",
      hotel_inhouse_concierge_process_modal_button_no_motorcycle: "Delivery motorcycle no show",
      hotel_inhouse_concierge_process_modal_button_not_luggage: "Luggage not ready",
      hotel_inhouse_concierge_myconect_list_title: "CONCIERGE SCHEDULE PROGRESS LIST",
      hotel_inhouse_concierge_myconect_list_label_user_count: "Canceled By User Count",
      hotel_inhouse_concierge_myconect_list_label_hotel_count: "Canceled By Hotel Count",
      hotel_inhouse_concierge_myconect_list_label_taxi_ex: "Taxi Express",
      hotel_inhouse_concierge_myconect_list_label_taxi: "Taxi",
      hotel_inhouse_concierge_myconect_list_label_parcel_ex: "Parcel Service Express",
      hotel_inhouse_concierge_myconect_list_label_parcel: "Parcel Service",
      hotel_inhouse_concierge_myconect_list_label_luggage_ex: "Luggage Service Express",
      hotel_inhouse_concierge_myconect_list_label_luggage: "Luggage Service",
      hotel_inhouse_concierge_myconect_list_label_total: "Total",
      hotel_inhouse_concierge_myconect_list_label_room_no: "Room No.",
      hotel_inhouse_concierge_myconect_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_concierge_myconect_list_label_user_name: "User Name",
      hotel_inhouse_concierge_myconect_list_label_request_date: "Request Date",
      hotel_inhouse_concierge_myconect_list_label_request_type: "Request Type",
      hotel_inhouse_concierge_myconect_list_label_express: "Task",
      hotel_inhouse_concierge_myconect_list_label_action_status: "Action Status",
      hotel_inhouse_concierge_myconect_list_label_myconect_at: "MyCONECT At",
      hotel_inhouse_concierge_myconect_list_label_completed_at: "Completed At",
      hotel_inhouse_concierge_myconect_list_label_date: "Date",
      hotel_inhouse_concierge_myconect_list_guide_empty: "List is empty",
      hotel_inhouse_concierge_myconect_detail_title: "SCHEDULE PROGRESS DETAILS",
      hotel_inhouse_concierge_myconect_detail_label_room_no: "Room No.",
      hotel_inhouse_concierge_myconect_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_concierge_myconect_detail_label_user_name: "User Name",
      hotel_inhouse_concierge_myconect_detail_label_email: "Email",
      hotel_inhouse_concierge_myconect_detail_label_stay: "Stay",
      hotel_inhouse_concierge_myconect_detail_label_request_type: "Request Type",
      hotel_inhouse_concierge_myconect_detail_label_status: "Status",
      hotel_inhouse_concierge_myconect_detail_label_number_of_item: "Number of Items",
      hotel_inhouse_concierge_myconect_detail_label_request_at: "Requested At",
      hotel_inhouse_concierge_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_concierge_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_concierge_multi_myconect_list_title: "CONCIERGE SCHEDULE SETTING LIST",
      hotel_inhouse_concierge_multi_myconect_list_button_alive: "Active",
      hotel_inhouse_concierge_multi_myconect_list_label_staff_number: "Staff Number",
      hotel_inhouse_concierge_multi_myconect_list_label_request: "Request",
      hotel_inhouse_concierge_multi_myconect_list_label_department: "Department",
      hotel_inhouse_concierge_multi_myconect_list_label_email: "Email",
      hotel_inhouse_concierge_multi_myconect_list_label_respond: "MyCONECT Response",
      hotel_inhouse_concierge_multi_myconect_list_label_from: "From",
      hotel_inhouse_concierge_multi_myconect_list_label_to: "To",
      hotel_inhouse_concierge_multi_myconect_list_label_date: "Date",
      hotel_inhouse_concierge_multi_myconect_list_button_add: "Add",
      hotel_inhouse_concierge_multi_myconect_add_title: "ADD CONCIERGE SCHEDULE SETTING",
      hotel_inhouse_concierge_multi_myconect_add_label_request_type: "Request Type",
      hotel_inhouse_concierge_multi_myconect_add_placeholder_select_box_choose: "Select",
      hotel_inhouse_concierge_multi_myconect_add_select_box_luggage: "Luggage Service",
      hotel_inhouse_concierge_multi_myconect_add_select_box_parcel: "Parcel Service",
      hotel_inhouse_concierge_multi_myconect_add_select_box_taxi: "Taxi",
      hotel_inhouse_concierge_multi_myconect_add_label_date: "Date",
      hotel_inhouse_concierge_multi_myconect_add_label_respond: "MyCONECT Response",
      hotel_inhouse_concierge_multi_myconect_add_button_go_to_list: "Go to list",
      hotel_inhouse_concierge_multi_myconect_add_button_add: "Add",
      hotel_inhouse_concierge_multi_myconect_add_validation_type_request: "* Please Select the Request Type.",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_start: "* Please Select the Date.",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_end: "* Please Select the Date.",
      hotel_inhouse_concierge_multi_myconect_add_validation_respond: "* Please Select the MyCONECT Response.",
      hotel_inhouse_concierge_multi_myconect_detail_title: "CONCIERGE SCHEDULE SETTING INFORMATION",
      hotel_inhouse_concierge_multi_myconect_detail_label_update: "Date Updated",
      hotel_inhouse_concierge_multi_myconect_detail_label_date: "Date",
      hotel_inhouse_concierge_multi_myconect_detail_label_respond: "MyCONECT Response",
      hotel_inhouse_concierge_multi_myconect_detail_label_staff_number: "Staff Number",
      hotel_inhouse_concierge_multi_myconect_detail_label_request: "Request",
      hotel_inhouse_concierge_multi_myconect_detail_label_department: "Department",
      hotel_inhouse_concierge_multi_myconect_detail_label_email: "Email",
      hotel_inhouse_concierge_multi_myconect_detail_label_released_at: "Released At",
      hotel_inhouse_concierge_multi_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_concierge_multi_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_concierge_multi_myconect_detail_button_cancel: "Cancel",
      hotel_inhouse_concierge_multi_myconect_detail_button_release: "Release",
      hotel_inhouse_concierge_multi_myconect_detail_button_edit: "Edit",
      hotel_inhouse_concierge_multi_myconect_detail_button_ok: "OK",
      hotel_inhouse_housekeeping_request_list_title: "HOUSEKEEPING REQUEST LIST\n (Before Confirmation)",
      hotel_inhouse_housekeeping_request_list_label_request: "Number of Guest Requests",
      hotel_inhouse_housekeeping_request_list_label_not_confirmed: "Unconfirmed Guest Requests",
      hotel_inhouse_housekeeping_request_list_label_mur: "Make Up Room",
      hotel_inhouse_housekeeping_request_list_label_tds: "Turndown Service",
      hotel_inhouse_housekeeping_request_list_label_green: "Green Campaign",
      hotel_inhouse_housekeeping_request_list_label_ironing_ex: "Ironing Service Express",
      hotel_inhouse_housekeeping_request_list_label_ironing: "Ironing Service",
      hotel_inhouse_housekeeping_request_list_label_laundry_ex: "Laundry Service Express",
      hotel_inhouse_housekeeping_request_list_label_laundry: "Laundry Service",
      hotel_inhouse_housekeeping_request_list_label_amenity: "Amenity Service",
      hotel_inhouse_housekeeping_request_list_label_total: "Total",
      hotel_inhouse_housekeeping_request_list_select_request_type: "Request Type ( ALL )",
      hotel_inhouse_housekeeping_request_list_select_mur: "Make Up Room",
      hotel_inhouse_housekeeping_request_list_select_tds: "Turndown Service",
      hotel_inhouse_housekeeping_request_list_select_green: "Green Campaign",
      hotel_inhouse_housekeeping_request_list_select_ironing: "Ironing Service",
      hotel_inhouse_housekeeping_request_list_select_laundry: "Laundry Service",
      hotel_inhouse_housekeeping_request_list_select_amenity: "Amenity Service",
      hotel_inhouse_housekeeping_request_list_select_request_express: "Task ( ALL )",
      hotel_inhouse_housekeeping_request_list_select_yes: "Express",
      hotel_inhouse_housekeeping_request_list_select_no: "Normal",
      hotel_inhouse_housekeeping_request_list_select_request: "Request ( ALL )",
      hotel_inhouse_housekeeping_request_list_select_room_no: "Room No.",
      hotel_inhouse_housekeeping_request_list_select_user_name: "User Name",
      hotel_inhouse_housekeeping_request_list_placeholder_search: "Search...",
      hotel_inhouse_housekeeping_request_list_label_roon_no: "Room No.",
      hotel_inhouse_housekeeping_request_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_housekeeping_request_list_label_user_name: "User Name",
      hotel_inhouse_housekeeping_request_list_label_request_date: "Request Date",
      hotel_inhouse_housekeeping_request_list_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_request_list_label_express: "Task",
      hotel_inhouse_housekeeping_request_list_label_action_status: "Action Status",
      hotel_inhouse_housekeeping_request_list_label_date: "Date",
      hotel_inhouse_housekeeping_request_list_guide_empty: "List is empty",
      hotel_inhouse_housekeeping_request_detail_title: "REQUEST DETAILS",
      hotel_inhouse_housekeeping_request_detail_label_roon_no: "Room No.",
      hotel_inhouse_housekeeping_request_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_housekeeping_request_detail_label_user_name: "User Name",
      hotel_inhouse_housekeeping_request_detail_label_email: "Email",
      hotel_inhouse_housekeeping_request_detail_label_stay: "Stay",
      hotel_inhouse_housekeeping_request_detail_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_request_detail_label_status: "Status",
      hotel_inhouse_housekeeping_request_detail_label_request_at: "Requested At",
      hotel_inhouse_housekeeping_request_detail_label_created_at: "Created At",
      hotel_inhouse_housekeeping_request_detail_label_total: "Total",
      hotel_inhouse_housekeeping_request_detail_button_not_availble: "Not Available",
      hotel_inhouse_housekeeping_request_detail_button_myconect: "MyCONECT",
      hotel_inhouse_housekeeping_request_detail_button_auto: "Auto",
      hotel_inhouse_housekeeping_request_detail_button_delay: "Delay",
      hotel_inhouse_housekeeping_request_detail_button_confirm: "Confirm",
      hotel_inhouse_housekeeping_request_detail_button_go_to_list: "Go to list",
      hotel_inhouse_housekeeping_request_modal_title_delay: "Put Off Message",
      hotel_inhouse_housekeeping_request_modal_button_delay_30: "Delay 30 min",
      hotel_inhouse_housekeeping_request_modal_button_delay_60: "Delay 60 min",
      hotel_inhouse_housekeeping_request_modal_button_as_soon_as: "As soon as available",
      hotel_inhouse_housekeeping_request_modal_title_myconect: "MyCONECT",
      hotel_inhouse_housekeeping_request_modal_button_one_time: "One Time",
      hotel_inhouse_housekeeping_request_modal_button_one_hour: "After 1 hr",
      hotel_inhouse_housekeeping_request_modal_button_two_hour: "After 2 hrs",
      hotel_inhouse_housekeeping_request_modal_button_three_hour: "After 3 hrs",
      hotel_inhouse_housekeeping_request_modal_button_unlimited: "Unknown",
      hotel_inhouse_housekeeping_request_modal_button_cancel: "Cancel",
      hotel_inhouse_housekeeping_process_list_title: "HOUSEKEEPING PROGRESS LIST (Confirmed)",
      hotel_inhouse_housekeeping_process_list_label_request: "Number of Guest Requests",
      hotel_inhouse_housekeeping_process_list_label_unassigned: "Date Unassigned",
      hotel_inhouse_housekeeping_process_list_label_mur: "Make Up Room",
      hotel_inhouse_housekeeping_process_list_label_tds: "Turndown Service",
      hotel_inhouse_housekeeping_process_list_label_housekeeping_total: "Total",
      hotel_inhouse_housekeeping_process_list_label_ironing_ex: "Ironing Service Express",
      hotel_inhouse_housekeeping_process_list_label_ironing: "Ironing Service",
      hotel_inhouse_housekeeping_process_list_label_laundry_ex: "Laundry Service Express",
      hotel_inhouse_housekeeping_process_list_label_laundry: "Laundry Service",
      hotel_inhouse_housekeeping_process_list_label_guest_service_total: "Total",
      hotel_inhouse_housekeeping_process_list_label_green: "Green Campaign",
      hotel_inhouse_housekeeping_process_list_label_dnd: "DND",
      hotel_inhouse_housekeeping_process_list_label_amenity_number: "Amenity No. of Items",
      hotel_inhouse_housekeeping_process_list_label_bath_towel: "Bath Towel",
      hotel_inhouse_housekeeping_process_list_label_face_towel: "Face Towel",
      hotel_inhouse_housekeeping_process_list_label_hand_towel: "Hand Towel",
      hotel_inhouse_housekeeping_process_list_label_bath_gown: "Bathrobe",
      hotel_inhouse_housekeeping_process_list_label_razor: "Razor",
      hotel_inhouse_housekeeping_process_list_label_shampoo: "Shampoo",
      hotel_inhouse_housekeeping_process_list_label_conditioner: "Conditioner",
      hotel_inhouse_housekeeping_process_list_label_soap: "Soap",
      hotel_inhouse_housekeeping_process_list_label_amenity_total: "Total",
      hotel_inhouse_housekeeping_process_list_select_request_type: "Request Type ( ALL )",
      hotel_inhouse_housekeeping_process_list_select_mur: "Make Up Room",
      hotel_inhouse_housekeeping_process_list_select_tds: "Turndown Service",
      hotel_inhouse_housekeeping_process_list_select_green: "Green Campaign",
      hotel_inhouse_housekeeping_process_list_select_dnd: "DND",
      hotel_inhouse_housekeeping_process_list_select_ironing: "Ironing Service",
      hotel_inhouse_housekeeping_process_list_select_laundry: "Laundry Service",
      hotel_inhouse_housekeeping_process_list_select_amenity: "Amenity Service",
      hotel_inhouse_housekeeping_process_list_select_express: "Task ( ALL )",
      hotel_inhouse_housekeeping_process_list_select_express_all: "All",
      hotel_inhouse_housekeeping_process_list_select_yes: "Express",
      hotel_inhouse_housekeeping_process_list_select_no: "Normal",
      hotel_inhouse_housekeeping_process_list_select_floor: "Floor ( ALL )",
      hotel_inhouse_housekeeping_process_list_select_floor_1: "1 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_2: "2 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_3: "3 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_4: "4 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_5: "5 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_6: "6 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_7: "7 floor",
      hotel_inhouse_housekeeping_process_list_select_floor_8: "8 floor",
      hotel_inhouse_housekeeping_process_list_select_request: "Request ( ALL )",
      hotel_inhouse_housekeeping_process_list_select_room_no: "Room No.",
      hotel_inhouse_housekeeping_process_list_placeholder_search: "Search...",
      hotel_inhouse_housekeeping_process_list_label_roon_no: "Room No.",
      hotel_inhouse_housekeeping_process_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_housekeeping_process_list_label_user_name: "User Name",
      hotel_inhouse_housekeeping_process_list_label_request_date: "Request Date",
      hotel_inhouse_housekeeping_process_list_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_process_list_label_express: "Task",
      hotel_inhouse_housekeeping_process_list_label_action_status: "Action Status",
      hotel_inhouse_housekeeping_process_list_label_date: "Date",
      hotel_inhouse_housekeeping_process_list_guide_empty: "List is empty",
      hotel_inhouse_housekeeping_process_detail_title: "REQUEST DETAILS",
      hotel_inhouse_housekeeping_process_detail_label_roon_no: "Room No.",
      hotel_inhouse_housekeeping_process_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_housekeeping_process_detail_label_user_name: "User Name",
      hotel_inhouse_housekeeping_process_detail_label_email: "Email",
      hotel_inhouse_housekeeping_process_detail_label_stay: "Stay",
      hotel_inhouse_housekeeping_process_detail_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_process_detail_label_status: "Status",
      hotel_inhouse_housekeeping_process_detail_label_request_at: "Requested At",
      hotel_inhouse_housekeeping_process_detail_label_created_at: "Created At",
      hotel_inhouse_housekeeping_process_detail_button_put_off: "Put Off",
      hotel_inhouse_housekeeping_process_detail_button_complete: "Complete",
      hotel_inhouse_housekeeping_process_detail_button_start: "Start",
      hotel_inhouse_housekeeping_process_detail_button_stop: "Stop",
      hotel_inhouse_housekeeping_process_detail_button_deliver: "Deliver",
      hotel_inhouse_housekeeping_process_detail_button_pickup: "Pick Up",
      hotel_inhouse_housekeeping_process_detail_button_go_to_list: "Go to list",
      hotel_inhouse_housekeeping_process_modal_title_put_off: "Put Off Message",
      hotel_inhouse_housekeeping_process_modal_button_cancel: "Cancel",
      hotel_inhouse_housekeeping_process_modal_button_delay_30: "Delay 30 min",
      hotel_inhouse_housekeeping_process_modal_button_delay_60: "Delay 60 min",
      hotel_inhouse_housekeeping_process_modal_button_as_soon_as: "As soon as available",
      hotel_inhouse_housekeeping_process_modal_button_out_of_order: "Out of order",
      hotel_inhouse_housekeeping_process_modal_title_stop_message: "Stop Message",
      hotel_inhouse_housekeeping_process_modal_button_unknown_situation: "Status Unknown",
      hotel_inhouse_housekeeping_process_modal_button_cancel_service: "Cancel Service",
      hotel_inhouse_housekeeping_process_modal_button_not_found_it: "Cannot be found",
      hotel_inhouse_housekeeping_process_modal_button_no_guest_room: "No guest in room",
      hotel_inhouse_housekeeping_process_modal_button_change_items: "Change Items",
      hotel_inhouse_housekeeping_myconect_list_title: "HOUSEKEEPING SCHEDULE PROGRESS LIST",
      hotel_inhouse_housekeeping_myconect_list_label_user_count: "Canceled by User Count",
      hotel_inhouse_housekeeping_myconect_list_label_hotel_count: "Canceled by Hotel Count",
      hotel_inhouse_housekeeping_myconect_list_label_mur: "Make Up Room",
      hotel_inhouse_housekeeping_myconect_list_label_tds: "Turndown Service",
      hotel_inhouse_housekeeping_myconect_list_label_ironing_ex: "Ironing Service Express",
      hotel_inhouse_housekeeping_myconect_list_label_ironing: "Ironing Service",
      hotel_inhouse_housekeeping_myconect_list_label_laundry_ex: "Laundry Service Express",
      hotel_inhouse_housekeeping_myconect_list_label_laundry: "Laundry Service",
      hotel_inhouse_housekeeping_myconect_list_label_total: "Total",
      hotel_inhouse_housekeeping_myconect_list_label_roon_no: "Room No.",
      hotel_inhouse_housekeeping_myconect_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_housekeeping_myconect_list_label_user_name: "User Name",
      hotel_inhouse_housekeeping_myconect_list_label_request_date: "Request Date",
      hotel_inhouse_housekeeping_myconect_list_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_myconect_list_label_express: "Task",
      hotel_inhouse_housekeeping_myconect_list_label_action_status: "Action Status",
      hotel_inhouse_housekeeping_myconect_list_label_myconect_at: "MyCONECT At",
      hotel_inhouse_housekeeping_myconect_list_label_completed_at: "Completed At",
      hotel_inhouse_housekeeping_myconect_list_label_date: "Date",
      hotel_inhouse_housekeeping_myconect_list_guide_empty: "List is empty",
      hotel_inhouse_housekeeping_myconect_detail_title: "SCHEDULE PROCESS DETAILS",
      hotel_inhouse_housekeeping_myconect_detail_label_roon_no: "Room No.",
      hotel_inhouse_housekeeping_myconect_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_housekeeping_myconect_detail_label_user_name: "User Name",
      hotel_inhouse_housekeeping_myconect_detail_label_email: "Email",
      hotel_inhouse_housekeeping_myconect_detail_label_stay: "Stay",
      hotel_inhouse_housekeeping_myconect_detail_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_myconect_detail_label_status: "Status",
      hotel_inhouse_housekeeping_myconect_detail_label_request_at: "Requested At",
      hotel_inhouse_housekeeping_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_housekeeping_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_housekeeping_multi_myconect_list_title: "HOUSEKEEPING SCHEDULE LIST",
      hotel_inhouse_housekeeping_multi_myconect_list_button_alive: "Active",
      hotel_inhouse_housekeeping_multi_myconect_list_label_staff_number: "Staff Number",
      hotel_inhouse_housekeeping_multi_myconect_list_label_request: "Request",
      hotel_inhouse_housekeeping_multi_myconect_list_label_department: "Department",
      hotel_inhouse_housekeeping_multi_myconect_list_label_email: "Email",
      hotel_inhouse_housekeeping_multi_myconect_list_label_respond: "MyCONECT Response",
      hotel_inhouse_housekeeping_multi_myconect_list_label_from: "From",
      hotel_inhouse_housekeeping_multi_myconect_list_label_to: "To",
      hotel_inhouse_housekeeping_multi_myconect_list_label_date: "Date",
      hotel_inhouse_housekeeping_multi_myconect_list_button_add: "Add",
      hotel_inhouse_housekeeping_multi_myconect_add_title: "ADD HOUSEKEEPING SCHEDULE",
      hotel_inhouse_housekeeping_multi_myconect_add_label_request_type: "Request Type",
      hotel_inhouse_housekeeping_multi_myconect_add_placeholder_select_box_choose: "Select",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_ironing: "Ironing Service",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_laundry: "Laundry Service",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_amenity: "Amenity Service",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_make_up_room: "Make Up Room",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_turn_down_service: "Turndown Service",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_green_campaign: "Green Campaign",
      hotel_inhouse_housekeeping_multi_myconect_add_label_date: "Date",
      hotel_inhouse_housekeeping_multi_myconect_add_label_respond: "MyCONECT Response",
      hotel_inhouse_housekeeping_multi_myconect_add_select_not_availble: "Not Available",
      hotel_inhouse_housekeeping_multi_myconect_add_select_confirm: "Confirm",
      hotel_inhouse_housekeeping_multi_myconect_add_button_go_to_list: "Go to list",
      hotel_inhouse_housekeeping_multi_myconect_add_button_add: "Add",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_type_request: "* Please Select the Request Type.",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_start: "* Please Select the Date.",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_end: "* Please Select the Date.",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_respond: "* Please Select the MyCONECT Response.",
      hotel_inhouse_housekeeping_multi_myconect_add_button_set: "Set",
      hotel_inhouse_housekeeping_multi_myconect_detail_title: "HOUSEKEEPING SCHEDULE INFORMATION",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_update: "Date Updated",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_date: "Date",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_respond: "MyCONECT Response",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_staff_number: "Staff Number",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_request: "Request",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_department: "Department",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_email: "Email",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_released_at: "Released At",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_cancel: "Cancel",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_release: "Release",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_edit: "Edit",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_ok: "OK",
      hotel_inhouse_valet_list_title: "VALET PARKING REQUEST LIST",
      hotel_inhouse_valet_list_label_valet_requst: "Valet Request",
      hotel_inhouse_valet_list_label_requesting: "Requesting",
      hotel_inhouse_valet_list_label_parking: "Parking",
      hotel_inhouse_valet_list_label_waiting: "Waiting",
      hotel_inhouse_valet_list_label_preparing: "Preparing",
      hotel_inhouse_valet_list_label_standby: "Standby",
      hotel_inhouse_valet_list_label_return: "Return",
      hotel_inhouse_valet_list_label_total: "Total",
      hotel_inhouse_valet_list_placeholder_select_box_request: "Request ( ALL )",
      hotel_inhouse_valet_list_select_request_all: "All",
      hotel_inhouse_valet_list_select_vehicle_no: "Vehicle No.",
      hotel_inhouse_valet_list_select_room_no: "Room No.",
      hotel_inhouse_valet_list_select_user_name: "User Name",
      hotel_inhouse_valet_list_placeholder_search: "Search...",
      hotel_inhouse_valet_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_valet_list_label_room_no: "Room No.",
      hotel_inhouse_valet_list_label_user_name: "User Name",
      hotel_inhouse_valet_list_label_request_date: "Request Date",
      hotel_inhouse_valet_list_label_location_no: "Location No.",
      hotel_inhouse_valet_list_label_vehicle_no: "Vehicle No.",
      hotel_inhouse_valet_list_label_action_status: "Action Status",
      hotel_inhouse_valet_list_label_date: "Date",
      hotel_inhouse_valet_detail_title: "VALET PARKING REQUEST DETAIL",
      hotel_inhouse_valet_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_valet_detail_label_room_no: "Room No.",
      hotel_inhouse_valet_detail_label_user_name: "User Name",
      hotel_inhouse_valet_detail_label_email: "Email",
      hotel_inhouse_valet_detail_label_request_type: "Request Type",
      hotel_inhouse_valet_detail_label_location_no: "Location No.",
      hotel_inhouse_valet_detail_label_vehicle_no: "Vehicle No.",
      hotel_inhouse_valet_detail_label_status: "Status",
      hotel_inhouse_valet_detail_label_request_at: "Request At",
      hotel_inhouse_valet_detail_label_created_at: "Created At",
      hotel_inhouse_valet_detail_button_valet_no: "Valet No.",
      hotel_inhouse_valet_detail_button_change: "Change",
      hotel_inhouse_valet_detail_button_myconect: "MyCONECT",
      hotel_inhouse_valet_detail_button_stop: "Stop",
      hotel_inhouse_valet_detail_button_location: "Location",
      hotel_inhouse_valet_detail_button_standby: "Standby",
      hotel_inhouse_valet_detail_button_handover: "Handover",
      hotel_inhouse_valet_detail_button_return: "Return",
      hotel_inhouse_valet_detail_button_settlement: "Settlement",
      hotel_inhouse_valet_detail_button_room_posting: "Room posting",
      hotel_inhouse_valet_detail_button_go_to_list: "Go to list",
      hotel_inhouse_valet_detail_button_go_to_detail: "Go to details",
      hotel_inhouse_valet_detail_button_payment: "Payment",
      hotel_inhouse_valet_detail_business_list_label_no: "NO.",
      hotel_inhouse_valet_detail_business_list_label_type: "Type",
      hotel_inhouse_valet_detail_business_list_label_task: "Task",
      hotel_inhouse_valet_detail_business_list_label_time_from: "Available Time From",
      hotel_inhouse_valet_detail_business_list_label_time_to: "Available Time To",
      hotel_inhouse_valet_detail_business_list_label_charge_amount: "Charge Amount",
      hotel_inhouse_valet_detail_business_list_label_currency: "Currency",
      hotel_inhouse_valet_detail_placeholder_select_box_choose: "Select",
      hotel_inhouse_valet_detail_modal_valet_title: "Valet Acceptance No.",
      hotel_inhouse_valet_detail_modal_valet_request_received: "Your request has been received.",
      hotel_inhouse_valet_detail_modal_change_title: "Change Vehice No.",
      hotel_inhouse_valet_detail_modal_change_placeholder_vehicle_no: "Vehice No.",
      hotel_inhouse_valet_detail_modal_myconect_title: "Location No.",
      hotel_inhouse_valet_detail_modal_myconect_placeholder_parking_area_no: "Parking Area No.",
      hotel_inhouse_valet_detail_modal_stop_title: "Stop Message",
      hotel_inhouse_valet_detail_modal_stop_message_unparked: "Unparked and cancel",
      hotel_inhouse_valet_detail_modal_stop_message_parked: "Parked and cancel",
      hotel_inhouse_valet_detail_modal_stop_message_unknown: "Unknown situation",
      hotel_inhouse_valet_detail_modal_valet_location_title: "Parking Location No.",
      hotel_inhouse_valet_detail_modal_valet_location_message: "Start to pick up the vehicle.",
      hotel_inhouse_valet_detail_modal_valet_standby_title: "Standby",
      hotel_inhouse_valet_detail_modal_valet_standby_message: "Proceed with standby the vehicle.",
      hotel_inhouse_valet_detail_modal_valet_handover_title: "Handover",
      hotel_inhouse_valet_detail_modal_valet_handover_message: "Proceed with vehicle delivery to the driver.",
      hotel_inhouse_valet_detail_modal_valet_return_title: "Return",
      hotel_inhouse_valet_detail_modal_valet_return_message: "Start to return the vehicle.",
      hotel_inhouse_valet_detail_modal_return_placeholder_parking_area_no: "Parking Area No.",
      hotel_inhouse_valet_detail_modal_room_number: "Room Number",
      hotel_inhouse_valet_detail_modal_button_cancel: "Cancel",
      hotel_inhouse_valet_detail_modal_button_ok: "OK",
      hotel_inhouse_valet_multi_myconect_list_title: "VALET SCHEDULE SETTING LIST",
      hotel_inhouse_valet_multi_myconect_list_button_alive: "Active",
      hotel_inhouse_valet_multi_myconect_list_label_staff_number: "Staff Number",
      hotel_inhouse_valet_multi_myconect_list_label_request: "Request",
      hotel_inhouse_valet_multi_myconect_list_label_department: "Department",
      hotel_inhouse_valet_multi_myconect_list_label_email: "Email",
      hotel_inhouse_valet_multi_myconect_list_label_respond: "MyCONECT Response",
      hotel_inhouse_valet_multi_myconect_list_label_from: "From",
      hotel_inhouse_valet_multi_myconect_list_label_to: "To",
      hotel_inhouse_valet_multi_myconect_list_label_date: "Date",
      hotel_inhouse_valet_multi_myconect_list_button_add: "Add",
      hotel_inhouse_valet_myconect_list_guide_empty: "List is empty",
      hotel_inhouse_valet_multi_myconect_add_title: "ADD VALET SCHEDULE SETTING",
      hotel_inhouse_valet_multi_myconect_add_label_request_type: "Request Type",
      hotel_inhouse_valet_multi_myconect_add_label_repond: "Respond",
      hotel_inhouse_valet_multi_myconect_add_label_delay_time: "Delay Time",
      hotel_inhouse_valet_multi_myconect_add_placeholder_select_box_choose: "Select",
      hotel_inhouse_valet_multi_myconect_add_select_box_not_available: "Not Available",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay15: "Delay 15 Minutes",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay30: "Delay 30 Minutes",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay45: "Delay 45 Minutes",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay60: "Delay 60 Minutes",
      hotel_inhouse_valet_multi_myconect_add_select_box_asaa: "As soon as available",
      hotel_inhouse_valet_multi_myconect_add_select_box_valet: "Valet",
      hotel_inhouse_valet_multi_myconect_add_select_box_waiting_request: "Standby Request",
      hotel_inhouse_valet_multi_myconect_add_label_date: "Date",
      hotel_inhouse_valet_multi_myconect_add_validation_type_request: "* Please Select the Request Type.",
      hotel_inhouse_valet_multi_myconect_add_validation_repond: "* Please Select the Respond.",
      hotel_inhouse_valet_multi_myconect_add_validation_delay: "* Please Select the Delay Time.",
      hotel_inhouse_valet_multi_myconect_add_button_go_to_list: "Go to list",
      hotel_inhouse_valet_multi_myconect_add_button_add: "Add",
      hotel_inhouse_valet_multi_myconect_detail_title: "VALET SCHEDULE SETTING INFORMATION",
      hotel_inhouse_valet_multi_myconect_detail_label_update: "Date Updated",
      hotel_inhouse_valet_multi_myconect_detail_label_date: "Date",
      hotel_inhouse_valet_multi_myconect_detail_label_delay_time: "Delay Time",
      hotel_inhouse_valet_multi_myconect_detail_placeholder_select_box_choose: "Select",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay15: "Delay 15 Minutes",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay30: "Delay 30 Minutes",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay45: "Delay 45 Minutes",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay60: "Delay 60 Minutes",
      hotel_inhouse_valet_multi_myconect_detail_select_box_asaa: "As soon as available",
      hotel_inhouse_valet_multi_myconect_detail_label_respond: "MyCONECT Reponse",
      hotel_inhouse_valet_multi_myconect_detail_label_staff_number: "Staff Number",
      hotel_inhouse_valet_multi_myconect_detail_label_request: "Request",
      hotel_inhouse_valet_multi_myconect_detail_label_department: "Department",
      hotel_inhouse_valet_multi_myconect_detail_label_email: "Email",
      hotel_inhouse_valet_multi_myconect_detail_label_released_at: "Released At",
      hotel_inhouse_valet_multi_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_valet_multi_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_valet_multi_myconect_detail_button_cancel: "Cancel",
      hotel_inhouse_valet_multi_myconect_detail_button_release: "Release",
      hotel_inhouse_valet_multi_myconect_detail_button_edit: "Edit",
      hotel_inhouse_valet_multi_myconect_detail_button_ok: "OK",
      hotel_inhouse_in_room_dining_cart_list_title: "IN-ROOM DINING CART LIST",
      hotel_inhouse_in_room_dining_cart_list_placeholder_select_box_select: "Select",
      hotel_inhouse_in_room_dining_cart_list_select_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_cart_list_placeholder_search: "Search...",
      hotel_inhouse_in_room_dining_cart_list_label_no: "No.",
      hotel_inhouse_in_room_dining_cart_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_cart_list_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_cart_list_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_cart_list_label_update_date: "Date Updated",
      hotel_inhouse_in_room_dining_cart_list_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_cart_detail_title: "IN-ROOM DINING CART DETAILS",
      hotel_inhouse_in_room_dining_cart_detail_label_info: "Info",
      hotel_inhouse_in_room_dining_cart_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_cart_detail_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_cart_detail_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_cart_detail_label_updated_at: "Date Updated",
      hotel_inhouse_in_room_dining_cart_detail_label_payment: "Payment",
      hotel_inhouse_in_room_dining_cart_detail_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_cart_detail_label_discount_rate: "Discount Rate",
      hotel_inhouse_in_room_dining_cart_detail_label_price: "Price",
      hotel_inhouse_in_room_dining_cart_detail_label_order: "Order",
      hotel_inhouse_in_room_dining_cart_detail_label_no: "No.",
      hotel_inhouse_in_room_dining_cart_detail_label_category: "Category",
      hotel_inhouse_in_room_dining_cart_detail_label_menu: "Menu",
      hotel_inhouse_in_room_dining_cart_detail_label_option: "Option",
      hotel_inhouse_in_room_dining_cart_detail_label_items: "Items",
      hotel_inhouse_in_room_dining_cart_detail_label_quantity: "Quantity",
      hotel_inhouse_in_room_dining_cart_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_cart_detail_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_category_list_title: "IN-ROOM DINING CATEGORY LIST",
      hotel_inhouse_in_room_dining_category_list_label_all: "Show menu (ALL)",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_yes: "Show menu (Yes)",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_no: "Show menu (No)",
      hotel_inhouse_in_room_dining_category_list_placeholder_search: "Search...",
      hotel_inhouse_in_room_dining_category_list_label_no: "No.",
      hotel_inhouse_in_room_dining_category_list_label_name: "Name",
      hotel_inhouse_in_room_dining_category_list_label_date: "Date",
      hotel_inhouse_in_room_dining_category_list_label_sorting: "Sorting",
      hotel_inhouse_in_room_dining_category_list_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_category_list_button_delete: "Delete",
      hotel_inhouse_in_room_dining_category_list_button_add: "Add",
      hotel_inhouse_in_room_dining_category_add_title: "IN-ROOM DINING CATEGORY",
      hotel_inhouse_in_room_dining_category_add_label_name: "Name",
      hotel_inhouse_in_room_dining_category_add_label_show: "Show menu",
      hotel_inhouse_in_room_dining_category_add_select_yes: "Yes",
      hotel_inhouse_in_room_dining_category_add_select_no: "No",
      hotel_inhouse_in_room_dining_category_add_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_category_add_button_add: "Add",
      hotel_inhouse_in_room_dining_category_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_category_detail_title: "IN-ROOM DINING CATEGORY DETAILS",
      hotel_inhouse_in_room_dining_category_detail_label_name: "Name",
      hotel_inhouse_in_room_dining_category_detail_label_date: "Date",
      hotel_inhouse_in_room_dining_category_detail_label_category_details: "Category Details",
      hotel_inhouse_in_room_dining_category_detail_label_language: "Language",
      hotel_inhouse_in_room_dining_category_detail_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_category_detail_button_add: "Add",
      hotel_inhouse_in_room_dining_category_detail_button_delete: "Delete",
      hotel_inhouse_in_room_dining_category_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_category_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_category_edit_title: "IN-ROOM DINING CATEGORY",
      hotel_inhouse_in_room_dining_category_edit_label_category_details: "Category Details",
      hotel_inhouse_in_room_dining_category_edit_label_language: "Language",
      hotel_inhouse_in_room_dining_category_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_category_edit_label_show: "Show menu",
      hotel_inhouse_in_room_dining_category_edit_select_yes: "Yes",
      hotel_inhouse_in_room_dining_category_edit_select_no: "No",
      hotel_inhouse_in_room_dining_category_edit_label_date: "Date",
      hotel_inhouse_in_room_dining_category_edit_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_category_edit_button_add: "Add",
      hotel_inhouse_in_room_dining_category_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_category_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_category_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_category_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_category_edit_guide_empty: "List is empty",
      hotel_hotel_inhouse_in_room_dining_category_translation_add_title: "IN-ROOM DINING CATEGORY TRANSLATION",
      hotel_inhouse_in_room_dining_category_translation_add_label_language: "Language",
      hotel_inhouse_in_room_dining_category_translation_add_select_ko: "Korean",
      hotel_inhouse_in_room_dining_category_translation_add_select_en: "English",
      hotel_inhouse_in_room_dining_category_translation_add_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_category_translation_add_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_category_translation_add_select_ms: "Malay",
      hotel_inhouse_in_room_dining_category_translation_add_label_name: "Name",
      hotel_inhouse_in_room_dining_category_translation_add_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_category_translation_add_button_add: "Add",
      hotel_inhouse_in_room_dining_category_translation_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_category_translation_edit_title: "IN-ROOM DINING CATEGORY TRANSLATION",
      hotel_inhouse_in_room_dining_category_translation_edit_label_language: "Language",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ko: "Korean",
      hotel_inhouse_in_room_dining_category_translation_edit_select_en: "English",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_category_translation_edit_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ms: "Malay",
      hotel_inhouse_in_room_dining_category_translation_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_category_translation_edit_label_date: "Date",
      hotel_inhouse_in_room_dining_category_translation_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_category_translation_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_category_translation_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_category_translation_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_setting_list_title: "IN-ROOM DINING MENU SETTINGS LIST",
      hotel_inhouse_in_room_dining_menu_setting_list_label_no: "No.",
      hotel_inhouse_in_room_dining_menu_setting_list_label_type: "Type",
      hotel_inhouse_in_room_dining_menu_setting_list_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_list_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_setting_list_discount_rate: "Discount Rate",
      hotel_inhouse_in_room_dining_menu_setting_list_price: "Price",
      hotel_inhouse_in_room_dining_menu_setting_list_status: "Status",
      hotel_inhouse_in_room_dining_menu_setting_list_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_setting_list_created_at: "Date",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sold_out: "Sold Out",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sale: "Sale",
      hotel_inhouse_in_room_dining_menu_setting_list_select_preparing: "Preparing",
      hotel_inhouse_in_room_dining_menu_setting_list_select_not_sale: "Not Sale",
      hotel_inhouse_in_room_dining_menu_setting_list_select_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_setting_list_select_no: "No",
      hotel_inhouse_in_room_dining_menu_setting_list_sorting: "Sorting",
      hotel_inhouse_in_room_dining_menu_setting_list_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_setting_list_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_setting_list_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_setting_add_title: "ADD IN-ROOM DINING MENU SETTINGS",
      hotel_inhouse_in_room_dining_menu_setting_add_label_category: "Category",
      hotel_inhouse_in_room_dining_menu_setting_add_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_add_label_about: "About",
      hotel_inhouse_in_room_dining_menu_setting_add_label_main_image: "Main Image",
      hotel_inhouse_in_room_dining_menu_setting_add_label_detail_images: "Detail Images",
      hotel_inhouse_in_room_dining_menu_setting_add_label_upload: "Upload",
      hotel_inhouse_in_room_dining_menu_setting_add_label_more: "More",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_start_time: "Order start time (Hour:Minutes)",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_cutoff_time: "Order end time (Hour:Minutes)",
      hotel_inhouse_in_room_dining_menu_setting_add_label_day_of_week: "Day of the week",
      hotel_inhouse_in_room_dining_menu_setting_add_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_type: "Discount Type",
      hotel_inhouse_in_room_dining_menu_setting_add_select_flat: "Flat Rate",
      hotel_inhouse_in_room_dining_menu_setting_add_select_percent: "Percentage",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_setting_add_label_status: "Status",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sold_out: "Sold Out",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sale: "Sale",
      hotel_inhouse_in_room_dining_menu_setting_add_select_preparing: "Preparing",
      hotel_inhouse_in_room_dining_menu_setting_add_select_not_sale: "Not for Sale",
      hotel_inhouse_in_room_dining_menu_setting_add_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_setting_add_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_setting_add_button_no: "No",
      hotel_inhouse_in_room_dining_menu_setting_add_label_recommended: "Recommendations",
      hotel_inhouse_in_room_dining_menu_setting_add_label_packaging_availability: "Packaging Availability",
      hotel_inhouse_in_room_dining_menu_setting_add_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_setting_add_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_menu_category_ids: "* Please select the category.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_about: "* Please enter the description.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_main_image: "* Please upload the main image.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_detail_images: "* Please upload the detail images.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_more: "* Please enter any additional description.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_from_at: "* Please enter order start time.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_to_at: "* Please enter order end time.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_day_of_week: "* Please select the day of week.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_supply_price: "* Please enter the supply price.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_show: "* Please select whether to show menu.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_recommended: "* Please select whether to show recommendations.",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_packaging_availability: "* Please select 'pack for to-go' availability.",
      hotel_inhouse_in_room_dining_menu_setting_detail_title: "IN-ROOM DINING MENU SETTING DETAILS",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_category: "Category",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_about: "About",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_main_image: "Main Image",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_detail_images: "Detail Images",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_more: "More",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_start_time: "Order start time (Hour:Minutes)",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_cutoff_time: "Order end time (Hour:Minutes)",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_day_of_week: "Day of the week",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_type: "Discount Type",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_flat: "Flat Rate",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_percent: "Percentage",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_sorting: "Sorting",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_status: "Status",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sold_out: "Sold Out",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sale: "Sale",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_preparing: "Preparing",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_not_sale: "Not for Sale",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_no: "No",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_recommended: "Recommended",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_packaging_availability: "Packaging Availability",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_language: "Language",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_date: "Date",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_option: "Option",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_no: "No.",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_items: "Items",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_required: "Required",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_setting_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_setting_edit_title: "IN-ROOM DINING MENU SETTINGS",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_category: "Category",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_about: "About",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_main_image: "Main Image",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_detail_images: "Detail Images",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_upload: "Upload",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_more: "More",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_from_at: "Order start time (Hour:Minutes)",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_to_at: "Order end time (Hour:Minutes)",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_week: "Day of the week",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_type: "Discount Type",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_flat: "Flat Rate",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_percent: "Percentage",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_status: "Status",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sold_out: "Sold Out",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sale: "Sale",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_preparing: "Preparing",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_not_sale: "Not for Sale",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_no: "No",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_recommended: "Recommended",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_packaging_availability: "Packaging Availability",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_language: "Language",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_date: "Date",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_option: "Option",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_no: "No.",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_items: "Items",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_required: "Required",
      hotel_inhouse_in_room_dining_menu_setting_edit_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_menu_category_ids: "* Please select the category.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_about: "* Please enter the about.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_more: "* Please enter the more.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_from_at: "* Please enter order start time.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_to_at: "* Please enter order end time.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_day_of_week: "* Please select the day of week.",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_supply_price: "* Please enter the supply price.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_title: "IN ROOM DINING MENU LANGUAGE ADD",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ko: "Korean",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_en: "English",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ms: "Malay",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_about: "About",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_more: "More",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_language_code: "* Please select the language code.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_about: "* Please enter the about.",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_more: "* Please enter the more.",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_title: "IN ROOM DINING MENU OPTION DETAILS",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_about: "About",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_more: "More",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_title: "IN ROOM DINING MENU LANGUAGE EDIT",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ko: "Korean",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_en: "English",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ms: "Malay",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_about: "About",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_more: "More",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_about: "* Please enter the about.",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_more: "* Please enter the more.",
      hotel_inhouse_in_room_dining_menu_option_group_add_title: "IN ROOM DINING MENU OPTION GROUP ADD",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_max_select: "Maximum number of selections",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_required: "Required",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_no: "No",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_required: "* Please select the required.",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_max_select: "* Please enter the max. number of selections.",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_show: "* Please select whether to show menu.",
      hotel_inhouse_in_room_dining_menu_option_group_detail_title: "IN-ROOM DINING MENU OPTION DETAILS",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_max_select: "Maximum number of selections",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_required: "Required",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_no: "No",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_language: "Language",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_date: "Date",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_sorting: "Sorting",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_option: "Option",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_no: "No.",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_items: "Items",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_option_group_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_option_group_edit_title: "EDIT IN-ROOM DINING MENU OPTION",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_max_select: "Maximum number of selections",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_required: "Required",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_no: "No",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_language: "Language",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_date: "Date",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_option: "Option",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_no: "No.",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_items: "Items",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_option_group_edit_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_required: "* Please select the required.",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_max_select: "* Please enter the max. number of selections.",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_title: "ADD IN-ROOM DINING MENU OPTION LANGUAGE",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ko: "Korean",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_en: "English",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ms: "Malay",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_language_code: "* Please select the language code.",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_title: "EDIT IN-ROOM DINING MENU OPTION LANGUAGE",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ko: "Korean",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_en: "English",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ms: "Malay",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_value_add_title: "ADD IN-ROOM DINING MENU ITEMS",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_no: "No",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_type: "Discount Type",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_flat: "Flat Rate",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_percent: "Percentage",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_supply_price: "* Please enter the supply price.",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_show: "* Please select whether to show menu.",
      hotel_inhouse_in_room_dining_menu_option_value_detail_title: "IN-ROOM DINING MENU ITEM DETAILS",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_no: "No",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_type: "Discount Type",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_language: "Language",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_date: "Date",
      hotel_inhouse_in_room_dining_menu_option_value_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_value_edit_title: "EDIT IN-ROOM DINING MENU ITEMS",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_yes: "Yes",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_no: "No",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_show: "Show menu",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_language: "Language",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_date: "Date",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_option: "Option",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_no: "No.",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_items: "Items",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_required: "Required",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_amount: "Discount Amount",
      hotel_inhouse_in_room_dining_menu_option_value_edit_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_type: "Discount Type",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_flat: "Flat Rate",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_percent: "Percentage",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_price: "Price",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_supply_price: "* Please enter the supply price.",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_title: "ADD IN-ROOM DINING MENU ITEMS LANGUAGE",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ko: "Korean",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_en: "English",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ms: "Malay",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_add: "Add",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_title: "EDIT IN-ROOM DINING MENU OPTION LANGUAGE",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_language_code: "Language Code",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ko: "Korean",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_en: "English",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ja: "Japanese",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_zh: "Chinese",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ms: "Malay",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_name: "Name",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_delete: "Delete",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_ok: "OK",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_validation_name: "* Please enter the name.",
      hotel_inhouse_in_room_dining_process_list_title: "IN-ROOM DINING PROGRESS LIST (After Confirmation)",
      hotel_inhouse_in_room_dining_process_list_label_number_of_guest_requests: "Number of Guest Requests",
      hotel_inhouse_in_room_dining_process_list_label_unconfirmed_guest_requests: "Unconfirmed Guest Requests",
      hotel_inhouse_in_room_dining_process_list_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_process_list_label_all: "Request ( ALL )",
      hotel_inhouse_in_room_dining_process_list_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_process_list_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_process_list_placeholder_search: "Search...",
      hotel_inhouse_in_room_dining_process_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_process_list_label_request_date: "Request Date",
      hotel_inhouse_in_room_dining_process_list_label_request_type: "Request Type",
      hotel_inhouse_in_room_dining_process_list_label_task: "Task",
      hotel_inhouse_in_room_dining_process_list_label_action_status: "Action Status",
      hotel_inhouse_in_room_dining_process_list_label_date: "Date",
      hotel_inhouse_in_room_dining_process_list_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_process_detail_title: "IN-ROOM DINING REQUEST DETAILS",
      hotel_inhouse_in_room_dining_process_detail_label_order_no: "Order No.",
      hotel_inhouse_in_room_dining_process_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_process_detail_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_process_detail_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_process_detail_label_status: "Status",
      hotel_inhouse_in_room_dining_process_detail_label_requests: "Requests",
      hotel_inhouse_in_room_dining_process_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_process_detail_label_request_at: "Requested At",
      hotel_inhouse_in_room_dining_process_detail_button_stop: "Stop",
      hotel_inhouse_in_room_dining_process_detail_button_preparing: "Preparing",
      hotel_inhouse_in_room_dining_process_detail_button_deliver: "Deliver",
      hotel_inhouse_in_room_dining_process_detail_label_payment: "Payment",
      hotel_inhouse_in_room_dining_process_detail_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_process_detail_label_discount_rate: "Discount Rate",
      hotel_inhouse_in_room_dining_process_detail_label_price: "Price",
      hotel_inhouse_in_room_dining_process_detail_label_to_go: "To Go",
      hotel_inhouse_in_room_dining_process_detail_label_order: "Order",
      hotel_inhouse_in_room_dining_process_detail_label_no: "No.",
      hotel_inhouse_in_room_dining_process_detail_label_category: "Category",
      hotel_inhouse_in_room_dining_process_detail_label_menu: "Menu",
      hotel_inhouse_in_room_dining_process_detail_label_option: "Option",
      hotel_inhouse_in_room_dining_process_detail_label_items: "Items",
      hotel_inhouse_in_room_dining_process_detail_label_quantity: "Quantity",
      hotel_inhouse_in_room_dining_process_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_process_detail_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_modal_stop_customer_reason: "Customer reason",
      hotel_inhouse_in_room_dining_modal_stop_product_issue: "Product issue",
      hotel_inhouse_in_room_dining_modal_stop_delivery_problem: "Delivery problem",
      hotel_inhouse_in_room_dining_modal_stop_other: "Other",
      hotel_inhouse_in_room_dining_receipt_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_receipt_label_order_created_date: "Order Created Date",
      hotel_inhouse_in_room_dining_receipt_label_guest_requested_date: "Guest Requested Date",
      hotel_inhouse_in_room_dining_receipt_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_receipt_label_guest_name: "Guest Name",
      hotel_inhouse_in_room_dining_receipt_label_check_in: "Check In",
      hotel_inhouse_in_room_dining_receipt_label_check_out: "Check Out",
      hotel_inhouse_in_room_dining_receipt_label_order_no: "Order No.",
      hotel_inhouse_in_room_dining_receipt_label_to_go: "To Go",
      hotel_inhouse_in_room_dining_receipt_label_description: "Description",
      hotel_inhouse_in_room_dining_receipt_label_unit_price: "Unit Price",
      hotel_inhouse_in_room_dining_receipt_label_quantity: "Quantity",
      hotel_inhouse_in_room_dining_receipt_label_amount: "Amount",
      hotel_inhouse_in_room_dining_receipt_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_receipt_label_subtotal: "Subtotal",
      hotel_inhouse_in_room_dining_receipt_label_discount: "Discount",
      hotel_inhouse_in_room_dining_receipt_label_total: "Total",
      hotel_inhouse_in_room_dining_receipt_label_payment: "Payment",
      hotel_inhouse_in_room_dining_request_receipt_label_room_posting: "Room Posting",
      hotel_inhouse_in_room_dining_request_receipt_label_self_settlement: "Self-settlement",
      hotel_inhouse_in_room_dining_request_receipt_label_balance: "Balance",
      hotel_inhouse_in_room_dining_request_receipt_contents_agreement:
        "I agree that my liabillity for this bill is not waived and agree to hold personal liabiliity in the case that the indicated person, company or assoclation fails to pay for part or the whole amonut of the charges.",
      hotel_inhouse_in_room_dining_request_list_title: "IN-ROOM DINING REQUEST LIST (BEFORE CONFIRMATION)",
      hotel_inhouse_in_room_dining_request_list_label_number_of_guest_requests: "Number of Guest Requests",
      hotel_inhouse_in_room_dining_request_list_label_unconfirmed_guest_requests: "Unconfirmed Guest Requests",
      hotel_inhouse_in_room_dining_request_list_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_request_list_label_all: "Request ( ALL )",
      hotel_inhouse_in_room_dining_request_list_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_request_list_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_request_list_placeholder_search: "Search...",
      hotel_inhouse_in_room_dining_request_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_request_list_label_request_date: "Request Date",
      hotel_inhouse_in_room_dining_request_list_label_request_type: "Request Type",
      hotel_inhouse_in_room_dining_request_list_label_task: "Task",
      hotel_inhouse_in_room_dining_request_list_label_action_status: "Action Status",
      hotel_inhouse_in_room_dining_request_list_label_date: "Date",
      hotel_inhouse_in_room_dining_request_list_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_request_detail_title: "IN ROOM DINING REQUEST DETAIL",
      hotel_inhouse_in_room_dining_request_detail_label_update_at: "Updated At",
      hotel_inhouse_in_room_dining_request_detail_label_order_no: "Order No.",
      hotel_inhouse_in_room_dining_request_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_request_detail_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_request_detail_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_request_detail_label_status: "Status",
      hotel_inhouse_in_room_dining_request_detail_label_requests: "Requests",
      hotel_inhouse_in_room_dining_request_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_request_detail_label_request_at: "Requested At",
      hotel_inhouse_in_room_dining_request_detail_button_pdf: "PDF",
      hotel_inhouse_in_room_dining_request_detail_button_not_available: "Not Available",
      hotel_inhouse_in_room_dining_request_detail_button_confirm: "Confirm",
      hotel_inhouse_in_room_dining_request_detail_label_payment: "Payment",
      hotel_inhouse_in_room_dining_request_detail_label_supply_price: "Supply Price",
      hotel_inhouse_in_room_dining_request_detail_label_discount_rate: "Discount Rate",
      hotel_inhouse_in_room_dining_request_detail_label_price: "Price",
      hotel_inhouse_in_room_dining_request_detail_label_to_go: "To Go",
      hotel_inhouse_in_room_dining_request_detail_label_order: "Order",
      hotel_inhouse_in_room_dining_request_detail_label_no: "No.",
      hotel_inhouse_in_room_dining_request_detail_label_category: "Category",
      hotel_inhouse_in_room_dining_request_detail_label_menu: "Menu",
      hotel_inhouse_in_room_dining_request_detail_label_option: "Option",
      hotel_inhouse_in_room_dining_request_detail_label_items: "Items",
      hotel_inhouse_in_room_dining_request_detail_label_quantity: "Quantity",
      hotel_inhouse_in_room_dining_request_detail_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_request_detail_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_request_detail_button_go_to_list: "Go to List",
      hotel_inhouse_in_room_dining_myconect_list_title: "IN ROOM DINING SCHEDULE PROGRESS LIST",
      hotel_inhouse_in_room_dining_myconect_list_label_user_count: "Canceled By User Count",
      hotel_inhouse_in_room_dining_myconect_list_label_hotel_count: "Canceled By Hotel Count",
      hotel_inhouse_in_room_dining_myconect_list_label_in_room_dining: "In Room Dining",
      hotel_inhouse_in_room_dining_myconect_list_label_room_no: "Room No.",
      hotel_inhouse_in_room_dining_myconect_list_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_myconect_list_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_myconect_list_label_request_date: "Request Date",
      hotel_inhouse_in_room_dining_myconect_list_label_request_type: "Request Type",
      hotel_inhouse_in_room_dining_myconect_list_label_express: "Task",
      hotel_inhouse_in_room_dining_myconect_list_label_action_status: "Action Status",
      hotel_inhouse_in_room_dining_myconect_list_label_myconect_at: "MyCONECT At",
      hotel_inhouse_in_room_dining_myconect_list_label_completed_at: "Completed At",
      hotel_inhouse_in_room_dining_myconect_list_label_date: "Date",
      hotel_inhouse_in_room_dining_myconect_list_guide_empty: "List is empty",
      hotel_inhouse_in_room_dining_myconect_detail_title: "IN-ROOM DINING SCHEDULE PROCESS DETAILS",
      hotel_inhouse_in_room_dining_myconect_detail_label_roon_no: "Room No.",
      hotel_inhouse_in_room_dining_myconect_detail_label_confirmation_no: "Confirmation No.",
      hotel_inhouse_in_room_dining_myconect_detail_label_user_name: "User Name",
      hotel_inhouse_in_room_dining_myconect_detail_label_email: "Email",
      hotel_inhouse_in_room_dining_myconect_detail_label_stay: "Stay",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_type: "Request Type",
      hotel_inhouse_in_room_dining_myconect_detail_label_status: "Status",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_at: "Requested At",
      hotel_inhouse_in_room_dining_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_multi_myconect_list_title: "IN-ROOM DINING SCHEDULE LIST",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_alive: "Active",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_staff_number: "Staff Number",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_request: "Request",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_department: "Department",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_email: "Email",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_respond: "MyCONECT Response",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_from: "From",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_to: "To",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_date: "Date",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_add: "Add",
      hotel_inhouse_in_room_dining_multi_myconect_add_title: "ADD IN-ROOM DINING SCHEDULE",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_request_type: "Request Type",
      hotel_inhouse_in_room_dining_multi_myconect_add_placeholder_select_box_choose: "Select",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_respond: "MyCONECT Response",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_not_availble: "Not Available",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_confirm: "Confirm",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_date: "Date",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_add: "Add",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_set: "Set",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_type_request: "* Please select the request type.",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_start: "* Please select the date.",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_end: "* Please select the date.",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_respond: "* Please select the MyCONECT response.",
      hotel_inhouse_in_room_dining_multi_myconect_detail_title: "IN-ROOM DINING SCHEDULE INFORMATION",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_update: "Date Updated",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_date: "Date",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_respond: "MyCONECT Response",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_staff_number: "Staff Number",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_request: "Request",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_department: "Department",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_email: "Email",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_released_at: "Released At",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_created_at: "Created At",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_go_to_list: "Go to list",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_release: "Release",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_ok: "OK",
      hotel_inhouse_in_room_dining_setting_detail_title: "IN-ROOM DINING SETTINGS",
      hotel_inhouse_in_room_dining_setting_detail_label_update_at: "Updated At",
      hotel_inhouse_in_room_dining_setting_detail_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_setting_detail_label_room_posting: "Room Posting",
      hotel_inhouse_in_room_dining_setting_detail_button_yes: "Yes",
      hotel_inhouse_in_room_dining_setting_detail_button_no: "No",
      hotel_inhouse_in_room_dining_setting_detail_label_payment: "Payment",
      hotel_inhouse_in_room_dining_setting_detail_button_edit: "Edit",
      hotel_inhouse_in_room_dining_setting_edit_title: "IN-ROOM DINING SETTINGS",
      hotel_inhouse_in_room_dining_setting_edit_label_update_at: "Updated At",
      hotel_inhouse_in_room_dining_setting_edit_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_in_room_dining_setting_edit_label_room_posting: "Room Posting",
      hotel_inhouse_in_room_dining_setting_edit_button_yes: "Yes",
      hotel_inhouse_in_room_dining_setting_edit_button_no: "No",
      hotel_inhouse_in_room_dining_setting_edit_label_payment: "Payment",
      hotel_inhouse_in_room_dining_setting_edit_button_cancel: "Cancel",
      hotel_inhouse_in_room_dining_setting_edit_button_ok: "OK",
      hotel_inhouse_setting_business_list_title: "BUSINESS RULE SETTINGS",
      hotel_inhouse_setting_business_list_label_no: "NO.",
      hotel_inhouse_setting_business_list_label_type: "Type",
      hotel_inhouse_setting_business_list_label_task: "Task",
      hotel_inhouse_setting_business_list_label_time_from: "Available Time From",
      hotel_inhouse_setting_business_list_label_time_to: "Available Time To",
      hotel_inhouse_setting_business_list_label_charge_amount: "Charge Amount",
      hotel_inhouse_setting_business_list_label_in_room_dining: "In Room Dining",
      hotel_inhouse_setting_business_list_label_update_at: "Updated At",
      hotel_inhouse_setting_business_list_guide_empty: "List is empty",
      hotel_inhouse_setting_business_detail_title: "BUSINESS RULE SETTING INFORMATION",
      hotel_inhouse_setting_business_detail_label_date: "Date Updated",
      hotel_inhouse_setting_business_detail_label_type: "Type",
      hotel_inhouse_setting_business_detail_label_task: "Task",
      hotel_inhouse_setting_business_detail_label_in_room_dining: "In Room Dining",
      hotel_inhouse_setting_business_detail_label_time_from: "Available Time From",
      hotel_inhouse_setting_business_detail_label_time_to: "Available Time To",
      hotel_inhouse_setting_business_detail_label_min_requirement_time: "Min Requirement Time (Minute)",
      hotel_inhouse_setting_business_detail_label_waiting_time: "MyCONECT Wait Time (Minute)",
      hotel_inhouse_setting_business_detail_label_respond: "MyCONECT Response",
      hotel_inhouse_setting_business_detail_label_amount: "Charge Amount",
      hotel_inhouse_setting_business_detail_label_transaction_code: "Charge Transaction Code",
      hotel_inhouse_setting_business_detail_label_description: "Charge Description",
      hotel_inhouse_setting_business_detail_label_supplement: "Charge Details",
      hotel_inhouse_setting_business_detail_label_myconect_from: "MyCONECT From",
      hotel_inhouse_setting_business_detail_label_myconect_to: "MyCONECT To",
      hotel_inhouse_setting_business_detail_label_additional_charge_count: "Additional Charge Condition Count",
      hotel_inhouse_setting_business_detail_label_additional_charge_repeat_flag: "Additional Charge Condition Repeat Flag",
      hotel_inhouse_setting_business_detail_button_yes: "Yes",
      hotel_inhouse_setting_business_detail_button_no: "No",
      hotel_inhouse_setting_business_detail_button_go_to_list: "Go to list",
      hotel_inhouse_setting_business_detail_button_edit: "Edit",
      hotel_inhouse_setting_business_edit_title: "BUSINESS RULE SETTING INFORMATION",
      hotel_inhouse_setting_business_edit_label_date: "Date Updated",
      hotel_inhouse_setting_business_edit_label_type: "Type",
      hotel_inhouse_setting_business_edit_label_task: "Task",
      hotel_inhouse_setting_business_edit_label_time_from: "Available Time From",
      hotel_inhouse_setting_business_edit_label_time_to: "Available Time To",
      hotel_inhouse_setting_business_edit_label_min_requirement_time: "Min Requirement Time",
      hotel_inhouse_setting_business_edit_label_waiting_time: "MyCONECT Waiting Time",
      hotel_inhouse_setting_business_edit_label_respond: "MyCONECT Response",
      hotel_inhouse_setting_business_edit_select_not_availble: "Not Available",
      hotel_inhouse_setting_business_edit_select_confirm: "Confirm",
      hotel_inhouse_setting_business_edit_select_delay15: "Delay 15 Minutes",
      hotel_inhouse_setting_business_edit_select_delay30: "Delay 30 Minutes",
      hotel_inhouse_setting_business_edit_select_delay45: "Delay 45 Minutes",
      hotel_inhouse_setting_business_edit_select_delay60: "Delay 60 Minutes",
      hotel_inhouse_setting_business_edit_select_asaa: "As soon as available",
      hotel_inhouse_setting_business_edit_label_amount: "Charge Amount",
      hotel_inhouse_setting_business_edit_label_transaction_code: "Charge Transaction Code",
      hotel_inhouse_setting_business_edit_label_description: "Charge Description",
      hotel_inhouse_setting_business_edit_label_supplement: "Charge Details",
      hotel_inhouse_setting_business_edit_label_myconect_from: "MyCONECT From",
      hotel_inhouse_setting_business_edit_label_myconect_to: "MyCONECT To",
      hotel_inhouse_setting_business_edit_validation_available_time_from: "* Please enter the time available from",
      hotel_inhouse_setting_business_edit_validation_available_time_to: "* Please enter the time available to",
      hotel_inhouse_setting_business_edit_validation_min_requirement_time: "* Please enter the min. requirement time.",
      hotel_inhouse_setting_business_edit_validation_myconect_waiting_time: "* Please enter the MyCONECT waiting time.",
      hotel_inhouse_setting_business_edit_validation_myconect_respond: "* Please select the MyCONECT response.",
      hotel_inhouse_setting_business_edit_validation_charge_amount: "* Please enter the charge amount.",
      hotel_inhouse_setting_business_edit_validation_charge_transaction_code: "* Please enter the charge transaction code",
      hotel_inhouse_setting_business_edit_validation_charge_description: "* Please enter the charge description",
      hotel_inhouse_setting_business_edit_validation_charge_supplement: "* Please enter the charge details",
      hotel_inhouse_setting_business_edit_label_in_room_dining: "In-Room Dining",
      hotel_inhouse_setting_business_edit_button_cancel: "Cancel",
      hotel_inhouse_setting_business_edit_button_ok: "OK",
      hotel_inhouse_setting_boundary_list_title: "BOUNDARY LINE SETTING",
      hotel_inhouse_setting_boundary_list_label_no: "NO.",
      hotel_inhouse_setting_boundary_list_label_type: "Type",
      hotel_inhouse_setting_boundary_list_label_task: "Task",
      hotel_inhouse_setting_boundary_list_label_ten_mins: "Ten Mins",
      hotel_inhouse_setting_boundary_list_label_hour: "Hour",
      hotel_inhouse_setting_boundary_list_label_three_hours: "Three Hours",
      hotel_inhouse_setting_boundary_list_label_update_at: "Updated At",
      hotel_inhouse_setting_boundary_list_guide_empty: "List is empty",
      hotel_inhouse_setting_boundary_detail_title: "BOUNDARY LINE SETTING INFORMATION",
      hotel_inhouse_setting_boundary_detail_label_date: "Date Updated",
      hotel_inhouse_setting_boundary_detail_label_type: "Type",
      hotel_inhouse_setting_boundary_detail_label_task: "Task",
      hotel_inhouse_setting_boundary_detail_label_ten_mins: "Ten Mins",
      hotel_inhouse_setting_boundary_detail_label_hour: "Hour",
      hotel_inhouse_setting_boundary_detail_label_three_hours: "Three Hours",
      hotel_inhouse_setting_boundary_detail_button_none: "",
      hotel_inhouse_setting_boundary_detail_button_unlimited: "No time limit",
      hotel_inhouse_setting_boundary_detail_button_value: "Value",
      hotel_inhouse_setting_boundary_detail_label_option_one: "Option One",
      hotel_inhouse_setting_boundary_detail_label_option_two: "Option Two",
      hotel_inhouse_setting_boundary_detail_button_go_to_list: "Go to list",
      hotel_inhouse_setting_boundary_detail_button_edit: "Edit",
      hotel_inhouse_setting_boundary_edit_title: "BOUNDARY LINE SETTING",
      hotel_inhouse_setting_boundary_edit_label_date: "Date Updated",
      hotel_inhouse_setting_boundary_edit_label_type: "Type",
      hotel_inhouse_setting_boundary_edit_label_task: "Task",
      hotel_inhouse_setting_boundary_edit_label_ten_mins: "Ten Mins",
      hotel_inhouse_setting_boundary_edit_label_hour: "Hour",
      hotel_inhouse_setting_boundary_edit_label_three_hours: "Three Hours",
      hotel_inhouse_setting_boundary_edit_button_none: "",
      hotel_inhouse_setting_boundary_edit_button_unlimited: "No time limit",
      hotel_inhouse_setting_boundary_edit_button_value: "Value",
      hotel_inhouse_setting_boundary_edit_label_option_one: "Option One",
      hotel_inhouse_setting_boundary_edit_label_option_two: "Option Two",
      hotel_inhouse_setting_boundary_edit_validation_boundary_line_hour: "* Please enter the hour.",
      hotel_inhouse_setting_boundary_edit_button_cancel: "Cancel",
      hotel_inhouse_setting_boundary_edit_button_ok: "OK",
      hotel_inhouse_setting_amenity_list_title: "AMENITY SETTINGS",
      hotel_inhouse_setting_amenity_list_label_no: "NO.",
      hotel_inhouse_setting_amenity_list_label_item: "Item",
      hotel_inhouse_setting_amenity_list_label_order_by: "Order By",
      hotel_inhouse_setting_amenity_list_label_update_at: "Updated At",
      hotel_inhouse_setting_amenity_list_guide_empty: "List is empty",
      hotel_inhouse_room_controller_detail_title: "ROOM CONTROLLER",
      hotel_inhouse_room_controller_detail_button_choose_file: "Select File",
      hotel_inhouse_room_controller_detail_button_insert: "Input",
      hotel_inhouse_room_controller_detail_button_export: "Export",
      hotel_inhouse_help_list_title: "HELP FOR MOBILE KEY MENU",
      hotel_inhouse_help_list_button_add: "Add",
      hotel_inhouse_help_list_button_delete: "Delete",
      hotel_inhouse_help_list_button_edit: "Edit",
      hotel_inhouse_help_list_guide_add: "Please add hotel information.",
      hotel_inhouse_help_add_title: "ADD HELP FOR MOBILE KEY",
      hotel_inhouse_help_add_placeholder_title: "Please enter the title.",
      hotel_inhouse_help_add_placeholder_index: "Please enter the index.",
      hotel_inhouse_help_add_select_choose: "Please choose type",
      hotel_inhouse_help_add_select_use: "Use",
      hotel_inhouse_help_add_select_access: "Access",
      hotel_inhouse_help_add_select_range: "Range",
      hotel_inhouse_help_add_select_not_working: "Not Working",
      hotel_inhouse_help_add_select_in_room_tech: "In Room Tech",
      hotel_inhouse_help_add_select_trouble: "Trouble",
      hotel_inhouse_help_add_toolbar_bold: "Bold",
      hotel_inhouse_help_add_toolbar_italic: "Italic",
      hotel_inhouse_help_add_toolbar_underline: "Underline",
      hotel_inhouse_help_add_toolbar_remove_font_style: "Remove font style",
      hotel_inhouse_help_add_toolbar_font_family: "Font family",
      hotel_inhouse_help_add_toolbar_font_size: "Font size",
      hotel_inhouse_help_add_toolbar_recent_color: "Recent color",
      hotel_inhouse_help_add_toolbar_recent_more_color: "More colors",
      hotel_inhouse_help_add_toolbar_unordered_list: "Unordered list",
      hotel_inhouse_help_add_toolbar_ordered_list: "Ordered list",
      hotel_inhouse_help_add_toolbar_paragraph: "Paragraph",
      hotel_inhouse_help_add_toolbar_line_height: "Line Height",
      hotel_inhouse_help_add_placeholder_content: "Please enter the content.",
      hotel_inhouse_help_add_button_cancel: "Cancel",
      hotel_inhouse_help_add_button_ok: "OK",
      hotel_inhouse_help_edit_title: "EDIT HOTEL INFORMATION",
      hotel_inhouse_help_edit_placeholder_title: "Title",
      hotel_inhouse_help_edit_select_kr: "KR",
      hotel_inhouse_help_edit_select_en: "EN",
      hotel_inhouse_help_edit_select_cn: "CN",
      hotel_inhouse_help_edit_select_jp: "JP",
      hotel_inhouse_help_edit_select_ms: "MS",
      hotel_inhouse_help_edit_placeholder_index: "Index",
      hotel_inhouse_help_edit_toolbar_bold: "Bold",
      hotel_inhouse_help_edit_toolbar_italic: "Italic",
      hotel_inhouse_help_edit_toolbar_underline: "Underline",
      hotel_inhouse_help_edit_toolbar_remove_font_style: "Remove font style",
      hotel_inhouse_help_edit_toolbar_font_family: "Font family",
      hotel_inhouse_help_edit_toolbar_font_size: "Font size",
      hotel_inhouse_help_edit_toolbar_recent_color: "Recent color",
      hotel_inhouse_help_edit_toolbar_recent_more_color: "More colors",
      hotel_inhouse_help_edit_toolbar_unordered_list: "Unordered list",
      hotel_inhouse_help_edit_toolbar_ordered_list: "Ordered list",
      hotel_inhouse_help_edit_toolbar_paragraph: "Paragraph",
      hotel_inhouse_help_edit_toolbar_code_view: "Code view",
      hotel_inhouse_help_edit_toolbar_line_height: "Line height",
      hotel_inhouse_help_edit_button_append: "Append",
      hotel_inhouse_help_edit_button_remove: "Remove",
      hotel_inhouse_help_edit_button_cancel: "Cancel",
      hotel_inhouse_help_edit_button_ok: "OK",
      hotel_key_guest_list_title: "GUEST KEY LIST",
      hotel_key_guest_list_button_alive: "Active",
      hotel_key_guest_list_placeholder_select_box_key: "Key",
      hotel_key_guest_list_select_room_no: "Room No.",
      hotel_key_guest_list_placeholder_search: "Search...",
      hotel_key_guest_list_label_id: "ID",
      hotel_key_guest_list_label_availble_at: "Available At",
      hotel_key_guest_list_label_expired_at: "Expired At",
      hotel_key_guest_list_label_is_main: "Main",
      hotel_key_guest_list_label_room_no: "Room No.",
      hotel_key_guest_list_label_user: "User",
      hotel_key_guest_list_button_cancel: "Cancel",
      hotel_key_guest_list_guide_empty: "List is empty",
      hotel_key_guest_detail_title: "KEY INFORMATION",
      hotel_key_guest_detail_label_id: "ID",
      hotel_key_guest_detail_label_availble_at: "Available At",
      hotel_key_guest_detail_label_expired_at: "Expired At",
      hotel_key_guest_detail_label_is_main: "Main",
      hotel_key_guest_detail_label_room_no: "Room No.",
      hotel_key_guest_detail_label_confirmation_no: "Confirmation No.",
      hotel_key_guest_detail_label_user: "User",
      hotel_key_guest_detail_label_user_email: "User email",
      hotel_key_guest_detail_button_go_to_list: "Go to list",
      hotel_key_guest_detail_button_cancel: "Cancel",
      hotel_key_staff_list_title: "STAFF KEY LIST",
      hotel_key_staff_list_button_alive: "Active",
      hotel_key_staff_list_placeholder_select_box_key: "Key",
      hotel_key_staff_list_select_task: "Task",
      hotel_key_staff_list_select_emp_id: "EMP. ID",
      hotel_key_staff_list_placeholder_search: "Search...",
      hotel_key_staff_list_label_type: "Type",
      hotel_key_staff_list_label_link_type: "Link Type",
      hotel_key_staff_list_label_link_detail: "Link Detail",
      hotel_key_staff_list_label_link_code: "Link Code",
      hotel_key_staff_list_label_availble_at: "Available At",
      hotel_key_staff_list_label_expired_at: "Expired At",
      hotel_key_staff_list_label_staff_number: "Staff Number",
      hotel_key_staff_list_button_cancel: "Cancel",
      hotel_key_staff_list_button_add: "Add",
      hotel_key_staff_list_guide_empty: "List is empty",
      hotel_key_staff_add_title: "ADD STAFF KEY",
      hotel_key_staff_add_button_append: "Append",
      hotel_key_staff_add_label_staff_number: "Staff Number",
      hotel_key_staff_add_label_type: "Type",
      hotel_key_staff_add_label_detail: "Detail",
      hotel_key_staff_add_label_from_date: "From Date",
      hotel_key_staff_add_label_end_date: "End Date",
      hotel_key_staff_add_placeholder_select_box_choose: "Select",
      hotel_key_staff_add_button_remove: "Remove",
      hotel_key_staff_add_button_go_to_list: "Go to list",
      hotel_key_staff_add_button_add: "Add",
      hotel_key_staff_add_validation_number: "* Please enter the staff number.",
      hotel_key_staff_add_validation_type: "* Please select a type.",
      hotel_key_staff_add_validation_detail: "* Please select details.",
      hotel_key_staff_add_validation_from_date: "* Please select a start date.",
      hotel_key_staff_add_validation_end_date: "* Please select an end date.",
      hotel_key_staff_detail_title: "STAFF KEY INFORMATION",
      hotel_key_staff_detail_label_type: "Type",
      hotel_key_staff_detail_label_link_type: "Link Type",
      hotel_key_staff_detail_label_link_detail: "Link Detail",
      hotel_key_staff_detail_label_link_code: "Link Code",
      hotel_key_staff_detail_label_availble_at: "Available At",
      hotel_key_staff_detail_label_expired_at: "Expired At",
      hotel_key_staff_detail_label_employee_id: "Employee ID",
      hotel_key_staff_detail_label_canceled_at: "Canceled At",
      hotel_key_staff_detail_button_go_to_list: "Go to list",
      hotel_key_staff_detail_button_cancel: "Cancel",
      hotel_inhouse_share_key_list_title: "SHARE KEY LIST",
      hotel_inhouse_share_key_list_button_alive: "Active",
      hotel_inhouse_share_key_list_placeholder_select_box_share_key: "Share Key",
      hotel_inhouse_share_key_list_select_room_no: "Room No.",
      hotel_inhouse_share_key_list_select_email: "Email",
      hotel_inhouse_share_key_list_select_user_name: "User Name",
      hotel_inhouse_share_key_list_placeholder_search: "Search...",
      hotel_inhouse_share_key_list_label_no: "No.",
      hotel_inhouse_share_key_list_label_sender_room_no: "Sender Room No.",
      hotel_inhouse_share_key_list_label_sender_name: "Sender Name",
      hotel_inhouse_share_key_list_label_sender_email: "Sender Email",
      hotel_inhouse_share_key_list_label_key_type: "Key Type",
      hotel_inhouse_share_key_list_label_expired_at: "Expired At",
      hotel_inhouse_share_key_list_label_receiver_room_no: "Receiver Room No.",
      hotel_inhouse_share_key_list_label_receiver_email: "Receiver Email",
      hotel_inhouse_share_key_list_label_receiver_status: "Receiver Status",
      hotel_inhouse_share_key_list_label_date: "Date",
      hotel_inhouse_share_key_list_guide_empty: "List is empty",
      hotel_inhouse_share_key_detail_title: "SHARE KEY DETAILS",
      hotel_inhouse_share_key_detail_label_sender_room_no: "Sender Room No.",
      hotel_inhouse_share_key_detail_label_sender_name: "Sender Name",
      hotel_inhouse_share_key_detail_label_sender_email: "Sender Email",
      hotel_inhouse_share_key_detail_label_key_type: "Share Key Type",
      hotel_inhouse_share_key_detail_label_expired_at: "Share Key Expiration",
      hotel_inhouse_share_key_detail_label_receiver_room_no: "Receiver Room No.",
      hotel_inhouse_share_key_detail_label_receiver_email: "Receiver Email",
      hotel_inhouse_share_key_detail_label_receiver_status: "Receiver Status",
      hotel_inhouse_share_key_detail_label_created_at: "Created At",
      hotel_inhouse_share_key_detail_button_go_to_list: "Go to list",
      help_for_mobile_key_add_validation_title: "* Please enter the subject.",
      help_for_mobile_key_add_validation_index: "* Please enter the number.",
      help_for_mobile_key_add_validation_type: "* Please select a type.",
      help_for_mobile_key_add_validation_content: "* Please enter your details.",
      help_for_mobile_key_edit_validation_title: "* Please enter the subject.",
      help_for_mobile_key_edit_validation_index: "* Please enter the number.",
      help_for_mobile_key_edit_validation_content: "* Please enter your details.",
      help_for_mobile_key_edit_validation_language_code: "* Please select a language code that does not overlap.",
      hotel_key_link_list_title: "STAFF KEY LINK LIST",
      hotel_key_link_list_placeholder_search: "Search...",
      hotel_key_link_list_label_type_code: "Type Code",
      hotel_key_link_list_label_type_description: "Type Description",
      hotel_key_link_list_label_detail_code: "Detail Code",
      hotel_key_link_list_label_detail_description: "Detail Description",
      hotel_key_link_list_label_staff_type: "Staff Type",
      hotel_key_link_list_label_code: "Code",
      hotel_key_link_list_label_date: "Date",
      hotel_key_link_list_button_delete: "Delete",
      hotel_key_link_list_button_add: "Add",
      hotel_key_link_list_guide_empty: "List is empty",
      hotel_key_link_add_title: "ADD STAFF KEY LINK",
      hotel_key_link_add_label_type_code: "Type Code",
      hotel_key_link_add_placeholder_select_box_choose: "Select",
      hotel_key_link_add_select_floor: "Floor",
      hotel_key_link_add_select_task: "Task",
      hotel_key_link_add_select_zone: "Zone",
      hotel_key_link_add_label_type_description: "Type Description",
      hotel_key_link_add_label_detail_code: "Detail Code",
      hotel_key_link_add_label_detail_description: "Detail Description",
      hotel_key_link_add_label_staff_type: "Staff Type",
      hotel_key_link_add_select_concoerge: "Concierge",
      hotel_key_link_add_select_housekeeping: "Housekeeping",
      hotel_key_link_add_select_front_desk: "Front Desk",
      hotel_key_link_add_label_code: "Code",
      hotel_key_link_add_button_go_to_list: "Go to list",
      hotel_key_link_add_button_add: "Add",
      hotel_key_link_add_validation_type_code: "* Please select a type code.",
      hotel_key_link_add_validation_type_description: "* Please enter a type description.",
      hotel_key_link_add_validation_detail_code: "* Please select a detailed code.",
      hotel_key_link_add_validation_detail_description: "* Please enter a detailed description.",
      hotel_key_link_add_validation_staff_type: "* Please select a staff type.",
      hotel_key_link_add_validation_code: "* Please select a code.",
      hotel_key_link_detail_title: "STAFF KEY LINK INFORMATION",
      hotel_key_link_detail_label_type_code: "Type Code",
      hotel_key_link_detail_label_type_description: "Type Description",
      hotel_key_link_detail_label_detail_code: "Detail Code",
      hotel_key_link_detail_label_detail_description: "Detail Description",
      hotel_key_link_detail_label_staff_type: "Staff Type",
      hotel_key_link_detail_label_code: "Code",
      hotel_key_link_detail_label_date: "Date",
      hotel_key_link_detail_button_go_to_list: "Go to list",
      hotel_key_link_detail_button_delete: "Delete",
      hotel_key_link_detail_button_edit: "Edit",
      hotel_key_link_edit_title: "STAFF KEY LINK INFORMATION",
      hotel_key_link_edit_label_type_code: "Type Code",
      hotel_key_link_edit_placeholder_select_box_choose: "Select",
      hotel_key_link_edit_select_floor: "Floor",
      hotel_key_link_edit_select_task: "Task",
      hotel_key_link_edit_select_zone: "Zone",
      hotel_key_link_edit_select_room: "Room",
      hotel_key_link_edit_label_type_description: "Type Description",
      hotel_key_link_edit_label_detail_code: "Detail Code",
      hotel_key_link_edit_label_detail_description: "Detail Description",
      hotel_key_link_edit_label_staff_type: "Staff Type",
      hotel_key_link_edit_select_concoerge: "Concierge",
      hotel_key_link_edit_select_housekeeping: "Housekeeping",
      hotel_key_link_edit_select_front_desk: "Front Desk",
      hotel_key_link_edit_label_code: "Code",
      hotel_key_link_edit_label_date: "Date",
      hotel_key_link_edit_button_cancel: "Cancel",
      hotel_key_link_edit_button_delete: "Delete",
      hotel_key_link_edit_button_ok: "OK",
      hotel_key_link_edit_validation_type_code: "* Please select a type code.",
      hotel_key_link_edit_validation_type_description: "* Please enter a type description.",
      hotel_key_link_edit_validation_detail_code: "* Please select a detailed code.",
      hotel_key_link_edit_validation_detail_description: "* Please enter a detailed description.",
      hotel_key_link_edit_validation_staff_type: "* Please select a staff type.",
      hotel_key_link_edit_validation_code: "* Please select a code.",
      hotel_key_access_list_title: "ACCESS LIST",
      hotel_key_access_list_label_title: "Title",
      hotel_key_access_list_label_type: "Type",
      hotel_key_access_list_label_content: "Description",
      hotel_key_access_list_label_position_code: "Position Code",
      hotel_key_access_list_label_position_name: "Position Name",
      hotel_key_access_list_label_remark: "Remark",
      hotel_key_access_list_label_lcon: "Icon",
      hotel_key_access_list_label_update_at: "Updated At",
      hotel_key_access_list_button_delete: "Delete",
      hotel_key_access_list_button_add: "Add",
      hotel_key_access_list_guide_empty: "List is empty",
      hotel_key_access_add_title: "ADD ACCESS",
      hotel_key_access_add_label_title: "Title",
      hotel_key_access_add_label_type: "Type",
      hotel_key_access_add_label_content: "Content",
      hotel_key_access_add_label_position_code: "Position Code",
      hotel_key_access_add_label_position_name: "Position Name",
      hotel_key_access_add_label_remark: "Remark",
      hotel_key_access_add_placeholder_select_box_choose: "Select",
      hotel_key_access_add_label_icon: "Icon",
      hotel_key_access_add_button_upload: "Upload",
      hotel_key_access_add_placeholder_upload: "ex) MyCONECT.png",
      hotel_key_access_add_button_go_to_list: "Go to list",
      hotel_key_access_add_button_add: "Add",
      hotel_key_access_add_validation_title: "* Please enter your title.",
      hotel_key_access_add_validation_type: "* Please enter the type.",
      hotel_key_access_add_validation_content: "* Please enter your details.",
      hotel_key_access_add_validation_position_name: "* Please select a position name.",
      hotel_key_access_add_validation_file: "* Please upload an icon.",
      hotel_key_access_detail_title: "ACCESS INFORMATION",
      hotel_key_access_detail_label_title: "Title",
      hotel_key_access_detail_label_type: "Type",
      hotel_key_access_detail_label_content: "Content",
      hotel_key_access_detail_label_position_code: "Position Code",
      hotel_key_access_detail_label_position_name: "Position Name",
      hotel_key_access_detail_label_remark: "Remark",
      hotel_key_access_detail_label_lcon: "Icon",
      hotel_key_access_detail_label_update_date: "Date Updated",
      hotel_key_access_detail_button_upload: "Upload",
      hotel_key_access_detail_button_go_to_list: "Go to list",
      hotel_key_access_detail_button_cancel: "Cancel",
      hotel_key_access_detail_button_delete: "Delete",
      hotel_key_access_detail_button_edit: "Edit",
      hotel_key_access_detail_button_ok: "OK",
      hotel_key_access_edit_validation_title: "* Please enter your title.",
      hotel_key_access_edit_validation_type: "* Please enter the type.",
      hotel_key_access_edit_validation_content: "* Please enter your details.",
      hotel_key_access_edit_validation_position_name: "* Please select a position name.",
      hotel_key_access_edit_validation_file: "* Please upload an icon.",
      hotel_key_access_detail_list_title: "ACCESS DETAIL LIST",
      hotel_key_access_detail_list_label_access_title: "Access Title",
      hotel_key_access_detail_list_label_language_code: "Language Code",
      hotel_key_access_detail_list_label_access_detail_title: "Access Detail Title",
      hotel_key_access_detail_list_label_date: "Date",
      hotel_key_access_detail_list_button_delete: "Delete",
      hotel_key_access_detail_list_button_add: "Add",
      hotel_key_access_detail_list_guide_empty: "List is empty",
      hotel_key_access_detail_add_title: "ADD ACCESS DETAIL",
      hotel_key_access_detail_add_label_access_title: "Access Title",
      hotel_key_access_detail_add_placeholder_select_box_choose: "Select",
      hotel_key_access_detail_add_label_language_code: "Language Code",
      hotel_key_access_detail_add_select_kr: "KR",
      hotel_key_access_detail_add_select_en: "EN",
      hotel_key_access_detail_add_select_cn: "CN",
      hotel_key_access_detail_add_select_jp: "JP",
      hotel_key_access_detail_add_label_access_detail_title: "Access Detail Title",
      hotel_key_access_detail_add_label_content: "Content",
      hotel_key_access_detail_add_button_go_to_list: "Go to list",
      hotel_key_access_detail_add_button_add: "Add",
      hotel_key_access_detail_add_validation_hotel_access_area_id: "* Please select an access title.",
      hotel_key_access_detail_add_validation_language_code: "* Please select a language code.",
      hotel_key_access_detail_add_validation_title: "* Please enter the access details title.",
      hotel_key_access_detail_add_validation_content: "* Please enter the details.",
      hotel_key_access_detail_detail_title: "ACCESS DETAIL",
      hotel_key_access_detail_detail_label_date: "Date Updated",
      hotel_key_access_detail_detail_label_access_title: "Access Title",
      hotel_key_access_detail_detail_placeholder_select_box_choose: "Select",
      hotel_key_access_detail_detail_label_language_code: "Language Code",
      hotel_key_access_detail_detail_select_kr: "KR",
      hotel_key_access_detail_detail_select_en: "EN",
      hotel_key_access_detail_detail_select_cn: "CN",
      hotel_key_access_detail_detail_select_jp: "JP",
      hotel_key_access_detail_detail_label_access_detail_title: "Access Detail Title",
      hotel_key_access_detail_detail_label_content: "Content",
      hotel_key_access_detail_detail_button_go_to_list: "Go to list",
      hotel_key_access_detail_detail_button_cancel: "Cancel",
      hotel_key_access_detail_detail_button_delete: "Delete",
      hotel_key_access_detail_detail_button_edit: "Edit",
      hotel_key_access_detail_detail_button_ok: "OK",
      hotel_key_access_detail_edit_validation_language_code: "* Please select the language code.",
      hotel_key_access_detail_edit_validation_title: "* Please enter the access details title.",
      hotel_key_access_detail_edit_validation_content: "* Please enter the details.",
      hotel_key_reservation_access_add_validation_type: "* Please select a type.",
      hotel_key_reservation_access_add_validation_code: "* Please select a code.",
      hotel_key_reservation_access_add_validation_name: "* Please enter the room name.",
      hotel_key_reservation_access_add_validation_description: "* Please enter the details.",
      hotel_key_reservation_access_add_validation_checked_list: "* Please check the reservation access permission list.",
      hotel_key_reservation_access_edit_validation_checked_list: "* Please check the reservation access permission list.",
      hotel_key_setting_detail_title: "KEY SETTINGS",
      hotel_key_setting_detail_label_update_date: "Date Updated",
      hotel_key_setting_detail_label_card_key: "Card Key",
      hotel_key_setting_detail_label_card_key_type: "Card Key Type",
      hotel_key_setting_detail_label_card_key_ip: "Card Key IP",
      hotel_key_setting_detail_label_card_key_port: "Card Key Port",
      hotel_key_setting_detail_label_card_key_path: "Card Key Path",
      hotel_key_setting_detail_label_card_key_id: "Card Key ID",
      hotel_key_setting_detail_label_card_key_password: "Card Key Password",
      hotel_key_setting_detail_label_special_guest: "Special Area for Guest",
      hotel_key_setting_detail_label_special_staff: "Special Area for Staff",
      hotel_key_setting_detail_label_rssi_guest: "RSSI for Guest",
      hotel_key_setting_detail_label_rrssi_staff: "RSSI for Staff",
      hotel_key_setting_detail_label_room_show_update_flag: "Room Show Update Flag",
      hotel_key_setting_detail_label_room_open_update_flag: "Room Open Update Flag",
      hotel_key_setting_detail_label_room_show_key: "Room Show Key Issued at Front Desk",
      hotel_key_setting_detail_button_yes: "Yes",
      hotel_key_setting_detail_button_no: "No",
      hotel_key_setting_detail_label_key_expired_time: "Key Expired Time (Hour:Minutes)",
      hotel_key_setting_detail_label_accompany_room_to_room_issue: "Accompany Room To Room Issue",
      hotel_key_setting_detail_label_room_to_room_not_available_time: "Room to Room Acceptance Wait Time(minutes)",
      hotel_key_setting_detail_label_share_key_issue: "Share Key Issue",
      hotel_key_setting_detail_label_accompany_share_key_issue: "Accompany Share Key Issue",
      hotel_key_setting_detail_label_share_key_not_available_time: "Share Key Acceptance Wait Time(minutes)",
      hotel_key_setting_detail_label_share_key_room_key_issued_max_count: "Room Key Issue Max Count",
      hotel_key_setting_detail_label_test_room_number: "Test Room Number",
      hotel_key_setting_detail_button_cancel: "Cancel",
      hotel_key_setting_detail_button_edit: "Edit",
      hotel_key_setting_edit_title: "KEY SETTINGS",
      hotel_key_setting_edit_label_update_date: "Date Updated",
      hotel_key_setting_edit_label_card_key: "Card Key",
      hotel_key_setting_edit_label_card_key_type: "Card Key Type",
      hotel_key_setting_edit_placeholder_select_box_choose: "Select",
      hotel_key_setting_edit_select_messerschmitt: "Messerschmitt",
      hotel_key_setting_edit_select_racos: "RACOS",
      hotel_key_setting_edit_select_racos_cms: "RACOS CMS",
      hotel_key_setting_edit_select_assa_abloy: "ASSA ABLOY",
      hotel_key_setting_edit_select_none: "",
      hotel_key_setting_edit_label_card_key_ip: "Card Key IP",
      hotel_key_setting_edit_placeholder_card_key_ip: "ex) 127.0.0.1",
      hotel_key_setting_edit_label_card_key_port: "Card Key Port",
      hotel_key_setting_edit_placeholder_card_key_port: "ex) 8080",
      hotel_key_setting_edit_label_card_key_path: "Card Key Path",
      hotel_key_setting_edit_label_card_key_id: "Card Key ID",
      hotel_key_setting_edit_label_card_key_password: "Card Key Password",
      hotel_key_setting_edit_label_special_guest: "Special Area for Guest",
      hotel_key_setting_edit_label_special_staff: "Special Area for Staff",
      hotel_key_setting_edit_label_rssi_guest: "RSSI for Guest",
      hotel_key_setting_edit_label_rrssi_staff: "RSSI for Staff",
      hotel_key_setting_edit_label_room_show_update_flag: "Room Show Update Flag",
      hotel_key_setting_edit_label_room_show_key: "Room Show Key Issue at Front Desk",
      hotel_key_setting_edit_button_yes: "Yes",
      hotel_key_setting_edit_button_no: "No",
      hotel_key_setting_edit_label_test_room_number: "Test Room Number",
      hotel_key_setting_edit_button_cancel: "Cancel",
      hotel_key_setting_edit_button_ok: "OK",
      hotel_key_setting_edit_validation_card_key_type: "* Please select a card key type.",
      hotel_key_setting_edit_validation_card_key_IP: "* Please enter the card key IP.",
      hotel_key_setting_edit_validation_card_key_port: "* Please enter the card key port.",
      hotel_key_setting_edit_validation_card_key_path: "* Please enter the card key path.",
      hotel_key_setting_edit_validation_card_key_client_id: "* Please enter your card key ID.",
      hotel_key_setting_edit_validation_card_key_client_password: "* Please enter your card key password.",
      hotel_key_setting_edit_validation_special_area_for_guest: "* Please enter the guest special area.",
      hotel_key_setting_edit_validation_special_area_for_staff: "* Please enter the staff special area.",
      hotel_key_setting_edit_validation_RSSI_for_guest: "* Please enter customer RSSI.",
      hotel_key_setting_edit_validation_RSSI_for_staff: "* Please enter staff RSSI.",
      hotel_key_setting_edit_validation_room_show_flag: "* Please select to display room show updates.",
      hotel_key_setting_edit_validation_room_show_issued_front_desk: "* Please select Front Desk Room Show Key Issue.",
      hotel_key_setting_edit_validation_key_expired_time: "* Please enter the key expiration time.",
      hotel_key_setting_edit_validation_room_to_room_not_available_time: "* Please enter the waiting time for room-to-room acceptance.",
      hotel_key_setting_edit_validation_is_share_key_issue: "* Select Issue shared key.",
      hotel_key_setting_edit_validation_share_key_not_available_time: "* Please enter the waiting time for sharing key acceptance.",
      hotel_key_setting_edit_validation_room_key_issued_max_count: "* Please enter the maximum number of room keys issued.",
      hotel_key_setting_edit_validation_test_room_no: "* Please enter the test room number.",
      hotel_key_reservation_access_list_title: "ACCESS LINK",
      hotel_key_reservation_access_list_label_type: "Type",
      hotel_key_reservation_access_list_label_name: "Name",
      hotel_key_reservation_access_list_label_code: "Code",
      hotel_key_reservation_access_list_label_access_area: "Access Area",
      hotel_key_reservation_access_list_label_date: "Date",
      hotel_key_reservation_access_list_label_description: "Description",
      hotel_key_reservation_access_list_button_cancel: "Delete",
      hotel_key_reservation_access_list_button_add: "Add",
      hotel_key_reservation_access_list_guide_empty: "List is empty",
      hotel_key_reservation_access_add_title: "ADD ACCESS LINK",
      hotel_key_reservation_access_add_label_type: "Type",
      hotel_key_reservation_access_add_placeholder_select_box_choose: "Select",
      hotel_key_reservation_access_add_select_transaction: "Transaction",
      hotel_key_reservation_access_add_select_package: "Package",
      hotel_key_reservation_access_add_label_code: "Code",
      hotel_key_reservation_access_add_label_name: "Name",
      hotel_key_reservation_access_add_label_description: "Description",
      hotel_key_reservation_access_add_label_list: "Reservation Access List",
      hotel_key_reservation_access_add_label_list_title: "Title",
      hotel_key_reservation_access_add_label_list_type: "Type",
      hotel_key_reservation_access_add_label_list_content: "Content",
      hotel_key_reservation_access_add_label_list_position_code: "Position Code",
      hotel_key_reservation_access_add_label_list_position_name: "Position Name",
      hotel_key_reservation_access_add_label_list_remark: "Remark",
      hotel_key_reservation_access_add_button_go_to_list: "Go to list",
      hotel_key_reservation_access_add_button_add: "Add",
      hotel_key_reservation_access_detail_title: "ACCESS LINK INFORMATION",
      hotel_key_reservation_access_detail_label_type: "Type",
      hotel_key_reservation_access_detail_label_code: "Code",
      hotel_key_reservation_access_detail_label_name: "Name",
      hotel_key_reservation_access_detail_label_description: "Description",
      hotel_key_reservation_access_detail_label_list: "Reservation Access List",
      hotel_key_reservation_access_detail_label_list_title: "Title",
      hotel_key_reservation_access_detail_label_list_type: "Type",
      hotel_key_reservation_access_detail_label_list_content: "Content",
      hotel_key_reservation_access_detail_label_list_position_code: "Position Code",
      hotel_key_reservation_access_detail_label_list_position_name: "Position Name",
      hotel_key_reservation_access_detail_label_list_remark: "Remark",
      hotel_key_reservation_access_detail_button_go_to_list: "Go to list",
      hotel_key_reservation_access_detail_button_delete: "Delete",
      hotel_key_reservation_access_detail_button_edit: "Edit",
      hotel_key_reservation_access_edit_title: "ACCESS LINK INFORMATION",
      hotel_key_reservation_access_edit_label_type: "Type",
      hotel_key_reservation_access_edit_placeholder_select_box_choose: "Select",
      hotel_key_reservation_access_edit_select_transaction: "Transaction",
      hotel_key_reservation_access_edit_select_package: "Package",
      hotel_key_reservation_access_edit_label_code: "Code",
      hotel_key_reservation_access_edit_label_description: "Description",
      hotel_key_reservation_access_edit_label_list: "Reservation Access List",
      hotel_key_reservation_access_edit_label_list_title: "Title",
      hotel_key_reservation_access_edit_label_list_type: "Type",
      hotel_key_reservation_access_edit_label_list_content: "Content",
      hotel_key_reservation_access_edit_label_list_position_code: "Position Code",
      hotel_key_reservation_access_edit_label_list_position_name: "Position Name",
      hotel_key_reservation_access_edit_label_list_remark: "Remark",
      hotel_key_reservation_access_edit_button_cancel: "Cancel",
      hotel_key_reservation_access_edit_button_delete: "Delete",
      hotel_key_reservation_access_edit_button_ok: "OK",
      hotel_terms_list_title: "TERMS LIST",
      hotel_terms_list_select_type: "Type ( ALL )",
      hotel_terms_list_select_hotel_terms: "Hotel Terms",
      hotel_terms_list_select_hotel_privacy_policies: "Hotel Privacy Policies",
      hotel_terms_list_select_reservation_terms: "Reservation Terms",
      hotel_terms_list_select_reservation_privacy_policies: "Reservation Privacy Policies",
      hotel_terms_list_select_language_code: "Language Code ( ALL )",
      hotel_terms_list_select_ko: "KO",
      hotel_terms_list_select_en: "EN",
      hotel_terms_list_select_cn: "CN",
      hotel_terms_list_select_jp: "JP",
      hotel_terms_list_label_id: "ID",
      hotel_terms_list_label_language_code: "Language Code",
      hotel_terms_list_label_type: "Type",
      hotel_terms_list_label_title: "Title",
      hotel_terms_list_label_version: "Version",
      hotel_terms_list_label_link_text: "Link Text",
      hotel_terms_list_label_created_at: "Created At",
      hotel_terms_list_select_posted: "Posted ( ALL )",
      hotel_terms_list_select_yes: "Yes",
      hotel_terms_list_select_no: "No",
      hotel_terms_list_placeholder_search: "Search...",
      hotel_terms_list_guide_empty: "List is empty",
      hotel_terms_list_button_delete: "Delete",
      hotel_terms_list_button_add: "Add",
      hotel_terms_add_title: "ADD TERMS",
      hotel_terms_add_label_language_code: "Language Code",
      hotel_terms_add_placeholder_select_box_select: "Select",
      hotel_terms_add_select_ko: "KO",
      hotel_terms_add_select_en: "EN",
      hotel_terms_add_select_cn: "CN",
      hotel_terms_add_select_jp: "JP",
      hotel_terms_add_label_type: "Type",
      hotel_terms_add_select_hotel_terms: "Hotel Terms",
      hotel_terms_add_select_hotel_privacy_policies: "Hotel Privacy Policies",
      hotel_terms_add_select_reservation_terms: "Reservation Terms",
      hotel_terms_add_select_reservation_privacy_policies: "Reservation Privacy Policies",
      hotel_terms_add_label_title: "Title",
      hotel_terms_add_label_required: "Required",
      hotel_terms_add_button_yes: "Yes",
      hotel_terms_add_button_no: "No",
      hotel_terms_add_label_description: "Description",
      hotel_terms_add_label_link_text: "Link Text",
      hotel_terms_add_label_posted: "Posted",
      hotel_terms_add_label_version: "Version",
      hotel_terms_add_label_content_type: "Content Type",
      hotel_terms_add_select_file: "File",
      hotel_terms_add_select_text: "Text",
      hotel_terms_add_label_content: "Content",
      hotel_terms_add_label_file_url: "File URL",
      hotel_terms_add_button_upload: "Upload",
      hotel_terms_add_button_go_to_list: "Go to list",
      hotel_terms_add_button_add: "Add",
      hotel_terms_add_validation_language_code: "* Please select a language code.",
      hotel_terms_add_validation_type: "* Please select a type.",
      hotel_terms_add_validation_title: "* Please enter your title.",
      hotel_terms_add_validation_required: "* Please select whether it is required or not.",
      hotel_terms_add_validation_description: "* Please enter a description.",
      hotel_terms_add_validation_link_text: "* Please enter link text.",
      hotel_terms_add_validation_posted: "* Please choose whether to post or not.",
      hotel_terms_add_validation_version: "* Please enter the version.",
      hotel_terms_add_validation_content_type: "* Please select a content type.",
      hotel_terms_add_validation_content: "* Please enter the details.",
      hotel_terms_add_validation_hotel_terms_file_url: "* Please upload the file.",
      hotel_terms_detail_title: "TERMS INFORMATION",
      hotel_terms_detail_label_terms_id: "Terms ID",
      hotel_terms_detail_label_language_code: "Language Code",
      hotel_terms_detail_select_ko: "KO",
      hotel_terms_detail_select_en: "EN",
      hotel_terms_detail_select_cn: "CN",
      hotel_terms_detail_select_jp: "JP",
      hotel_terms_detail_label_type: "Type",
      hotel_terms_detail_select_hotel_terms: "Hotel Terms",
      hotel_terms_detail_select_hotel_privacy_policies: "Hotel Privacy Policies",
      hotel_terms_detail_select_reservation_terms: "Reservation Terms",
      hotel_terms_detail_select_reservation_privacy_policies: "Reservation Privacy Policies",
      hotel_terms_detail_label_version: "Version",
      hotel_terms_detail_label_title: "Title",
      hotel_terms_detail_label_posted: "Posted",
      hotel_terms_detail_button_yes: "Yes",
      hotel_terms_detail_button_no: "No",
      hotel_terms_detail_label_required: "Required",
      hotel_terms_detail_label_description: "Description",
      hotel_terms_detail_label_link_text: "Link Text",
      hotel_terms_detail_label_content_type: "Content Type",
      hotel_terms_detail_select_file: "File",
      hotel_terms_detail_select_text: "Text",
      hotel_terms_detail_label_file_url: "File URL",
      hotel_terms_detail_button_upload: "Upload",
      hotel_terms_detail_label_content: "Content",
      hotel_terms_detail_label_created_at: "Created At",
      hotel_terms_detail_label_updated_at: "Updated At",
      hotel_terms_detail_button_go_to_list: "Go to list",
      hotel_terms_edit_button_cancel: "Cancel",
      hotel_terms_detail_button_delete: "Delete",
      hotel_terms_edit_button_download: "Download",
      hotel_terms_detail_button_edit: "Edit",
      hotel_terms_edit_button_ok: "OK",
      hotel_terms_edit_validation_language_code: "* Please select a language code.",
      hotel_terms_edit_validation_type: "* Please select a type.",
      hotel_terms_edit_validation_title: "* Please enter your title.",
      hotel_terms_edit_validation_required: "* Please select whether it is required or not.",
      hotel_terms_edit_validation_description: "* Please enter a description.",
      hotel_terms_edit_validation_link_text: "* Please enter link text.",
      hotel_terms_edit_validation_posted: "* Please choose whether to post or not.",
      hotel_terms_edit_validation_version: "* Please enter the version.",
      hotel_terms_edit_validation_content_type: "* Please select a content type.",
      hotel_terms_edit_validation_content: "* Please enter the details.",
      hotel_terms_edit_validation_hotel_terms_file_url: "* Please upload the file.",
      hotel_agreement_list_title: "AGREEMENT HISTORY LIST",
      hotel_agreement_list_placeholder_select_box_choose: "Select",
      hotel_agreement_list_select_email: "Email",
      hotel_agreement_list_select_content: "Content",
      hotel_agreement_list_select_confirmation_no: "Confirmation No.",
      hotel_agreement_list_placeholder_search: "Search...",
      hotel_agreement_list_label_no: "NO.",
      hotel_agreement_list_label_terms_type: "Terms Type",
      hotel_agreement_list_label_terms_id: "Terms ID",
      hotel_agreement_list_label_terms_title: "Terms Title",
      hotel_agreement_list_label_confirmation_no: "Confirmation No.",
      hotel_agreement_list_label_email: "Email",
      hotel_agreement_list_label_name: "Name",
      hotel_agreement_list_label_created_at: "Created At",
      hotel_agreement_detail_title: "AGREEMENT HISTORY INFORMATION",
      hotel_agreement_detail_label_agreement: "Agreement",
      hotel_agreement_detail_label_agreement_at: "Agreement Confirmed At",
      hotel_agreement_detail_label_user: "User",
      hotel_agreement_detail_label_email: "Email",
      hotel_agreement_detail_label_name: "Name",
      hotel_agreement_detail_label_language_code: "Language Code",
      hotel_agreement_detail_label_reservation: "Reservation",
      hotel_agreement_detail_label_confirmation_no: "Confirmation No.",
      hotel_agreement_detail_label_terms: "Terms",
      hotel_agreement_detail_label_terms_id: "Terms ID",
      hotel_agreement_detail_label_type: "Type",
      hotel_agreement_detail_label_version: "Version",
      hotel_agreement_detail_label_title: "Title",
      hotel_agreement_detail_label_required: "Required",
      hotel_agreement_detail_button_go_to_list: "Go to list",
      hotel_event_list_title: "EVENT",
      hotel_event_list_label_category_all: "Category ( ALL )",
      hotel_event_list_label_search_type_all: "Search Type ( ALL )",
      hotel_event_list_label_title: "Title",
      hotel_event_list_label_description: "Description",
      hotel_event_list_placeholder_search: "Search...",
      hotel_event_list_label_no: "No.",
      hotel_event_list_label_category: "Category",
      hotel_event_list_label_date: "Date",
      hotel_event_list_guide_empty: "List is empty",
      hotel_event_list_button_delete: "Delete",
      hotel_event_list_button_add: "Add",
      hotel_event_add_validation_category: "* Please select category.",
      hotel_event_add_validation_templates: "* Please select template.",
      hotel_event_add_validation_users: "* Please select users.",
      hotel_event_add_title: "EVENT ADD",
      hotel_input_detail_label_details: "Details",
      hotel_event_add_label_category: "Category",
      hotel_event_add_label_templates: "Templates",
      hotel_event_add_select_all: "ALL",
      hotel_event_add_label_notification_time: "Notification Time",
      hotel_event_add_label_title: "Title",
      hotel_event_add_label_content: "Content",
      hotel_event_add_label_description: "Description",
      hotel_event_add_label_template_language: "Templates Language",
      hotel_event_add_template_add_empty_guide: "Please Add Content",
      hotel_event_add_label_user: "User",
      hotel_event_add_label_search_time: "Search Time",
      hotel_event_add_label_myconect_id: "MyCONECT ID",
      hotel_event_add_label_check_in: "Check In",
      hotel_event_add_placeholder_search: "Search...",
      hotel_event_add_label_no: "No.",
      hotel_event_add_label_first_name: "First Name",
      hotel_event_add_label_last_name: "Last Name",
      hotel_event_add_label_email: "Email",
      hotel_event_add_label_tile: "Title",
      hotel_event_add_label_check_out: "Check Out",
      hotel_event_add_guide_empty: "List is empty",
      hotel_event_add_button_go_to_list: "Go to List",
      hotel_event_add_button_delete: "Delete",
      hotel_event_add_button_ok: "OK",
      hotel_event_add_user_validation_users: "* Please select users.",
      hotel_event_add_user_title: "EVENT ADD",
      hotel_event_add_user_label_user: "User",
      hotel_event_add_user_label_current_time: "Current Time",
      hotel_event_add_user_label_search_time: "Search Time",
      hotel_event_add_user_label_all: "ALL",
      hotel_event_add_user_label_myconect_id: "MyCONECT ID",
      hotel_event_add_user_label_profile_id: "Profile ID",
      hotel_event_add_user_label_check_in: "Check In",
      hotel_event_add_user_placeholder_search: "Search...",
      hotel_event_add_user_label_floor: "Floor",
      hotel_event_add_user_label_reservation_status: "Reservation Status",
      hotel_event_add_user_label_room_no: "Room No.",
      hotel_event_add_user_label_reserved: "Reserved",
      hotel_event_add_user_label_due_in: "Due in",
      hotel_event_add_user_label_due_out: "Due out",
      hotel_event_add_user_label_in_house: "In-House",
      hotel_event_add_user_label_checked_out: "Checked out",
      hotel_event_add_user_label_title: "Title",
      hotel_event_add_user_label_no: "No.",
      hotel_event_add_user_label_first_name: "First Name",
      hotel_event_add_user_label_last_name: "Last Name",
      hotel_event_add_user_label_email: "Email",
      hotel_event_add_user_label_check_out: "Check Out",
      hotel_event_add_user_guide_empty: "List is empty",
      hotel_event_add_user_button_cancel: "Cancel",
      hotel_event_add_user_button_ok: "OK",
      hotel_event_detail_title: "EVENT DETAIL",
      hotel_event_detail_label_details: "Details",
      hotel_event_detail_label_category: "Category",
      hotel_event_detail_label_templates: "Templates",
      hotel_event_detail_label_notification_time: "Notification Time",
      hotel_event_detail_label_updated_at: "Updated At",
      hotel_event_detail_label_title: "Title",
      hotel_event_detail_label_content: "Content",
      hotel_event_detail_label_description: "Description",
      hotel_event_detail_label_templates_language: "Templates Language",
      hotel_event_detail_template_add_empty_guide: "Please Add Content",
      hotel_event_detail_label_all: "ALL",
      hotel_event_detail_label_myconect_id: "MyCONECT ID",
      hotel_event_detail_label_profile_id: "Profile ID",
      hotel_event_detail_placeholder_search: "Search...",
      hotel_event_detail_label_no: "No.",
      hotel_event_detail_label_first_name: "First Name",
      hotel_event_detail_label_last_name: "Last Name",
      hotel_event_detail_label_email: "Email",
      hotel_event_detail_label_gender: "Title",
      hotel_event_detail_label_send: "Send",
      hotel_event_detail_label_check_up: "Check Up",
      hotel_event_detail_label_check_in: "Check In",
      hotel_event_detail_label_check_out: "Check Out",
      hotel_event_detail_label_success: "Success",
      hotel_event_detail_label_failed: "Failed",
      hotel_event_detail_guide_empty: "List is empty",
      hotel_event_detail_button_go_to_list: "Go to list",
      hotel_event_detail_button_delete: "Delete",
      hotel_event_detail_button_edit: "Edit",
      hotel_event_edit_validation_category: "* Please select category.",
      hotel_event_edit_validation_templates: "* Please select template.",
      hotel_event_edit_validation_users: "* Please select users.",
      hotel_event_edit_title: "EVENT EDIT",
      hotel_event_edit_label_details: "Details",
      hotel_event_edit_label_category: "Category",
      hotel_event_edit_label_templates: "Templates",
      hotel_event_edit_label_all: "ALL",
      hotel_event_edit_label_notification_time: "Notification Time",
      hotel_event_edit_label_title: "Title",
      hotel_event_edit_label_content: "Content",
      hotel_event_edit_label_description: "Description",
      hotel_input_edit_label_templates_language: "Templates Language",
      hotel_event_edit_template_add_empty_guide: "Please Add Content",
      hotel_event_edit_label_user: "User",
      hotel_event_edit_label_myconect_id: "MyCONECT ID",
      hotel_event_edit_label_check_in: "Check In",
      hotel_event_edit_label_check_out: "Check Out",
      hotel_event_edit_placeholder_search: "Search...",
      hotel_event_edit_button_delete: "Delete",
      hotel_event_edit_label_no: "No.",
      hotel_event_edit_label_first_name: "First Name",
      hotel_event_edit_label_last_name: "Last Name",
      hotel_event_edit_label_email: "Email",
      hotel_event_edit_label_gender: "Title",
      hotel_event_edit_guide_empty: "List is empty",
      hotel_event_edit_button_cancel: "Cancel",
      hotel_event_edit_button_ok: "OK",
      hotel_event_category_list_title: "EVENT CATEGORY",
      hotel_event_category_list_label_all: "ALL",
      hotel_event_category_list_label_type: "Type",
      hotel_event_category_list_label_show: "Show",
      hotel_event_category_list_placeholder_search: "Search...",
      hotel_event_category_list_label_no: "No.",
      hotel_event_category_list_label_date: "Date",
      hotel_event_category_list_guide_empty: "List is empty",
      hotel_event_category_list_button_delete: "Delete",
      hotel_event_category_list_button_add: "Add",
      hotel_event_category_add_title: "EVENT CATEGORY ADD",
      hotel_event_category_add_label_type: "Type",
      hotel_event_category_add_label_show: "Show",
      hotel_event_category_add_label_yes: "Yes",
      hotel_event_category_add_label_no: "No",
      hotel_event_category_add_button_go_to_list: "Go to List",
      hotel_event_category_add_button_add: "Add",
      hotel_event_category_add_validation_type: "* Please enter the type.",
      hotel_event_category_add_validation_show: "* Please select the show.",
      hotel_event_category_detail_title: "EVENT CATEGORY DETAIL",
      hotel_event_category_detail_label_detail: "Detail",
      hotel_event_category_detail_button_edit: "Edit",
      hotel_event_category_detail_label_type: "Type",
      hotel_event_category_detail_label_show: "Show",
      hotel_event_category_detail_label_date: "Date",
      hotel_event_category_detail_button_go_to_list: "Go to List",
      hotel_event_category_detail_button_delete: "Delete",
      hotel_event_category_detail_label_yes: "Yes",
      hotel_event_category_detail_label_no: "No",
      hotel_event_category_detail_label_template: "Template",
      hotel_event_category_detail_button_add: "Add",
      hotel_event_category_detail_label_number: "No.",
      hotel_event_category_detail_label_title: "Title",
      hotel_event_category_detail_guide_empty: "List is empty",
      hotel_event_category_edit_title: "EVENT CATEGORY EDIT",
      hotel_event_category_edit_label_type: "Type",
      hotel_event_category_edit_label_show: "Show",
      hotel_event_category_edit_label_yes: "Yes",
      hotel_event_category_edit_label_no: "No",
      hotel_event_category_edit_label_date: "Date",
      hotel_event_category_edit_button_cancel: "Cancel",
      hotel_event_category_edit_button_delete: "Delete",
      hotel_event_category_edit_button_ok: "OK",
      hotel_event_category_edit_validation_type: "* Please enter the type.",
      hotel_event_category_template_add_title: "EVENT CATEGORY TEMPLATE ADD",
      hotel_event_category_template_add_label_title: "Title",
      hotel_event_category_template_add_label_content: "Content",
      hotel_event_category_template_add_label_description: "Description",
      hotel_event_category_template_add_label_language: "Language",
      hotel_event_category_template_add_button_add: "Add",
      hotel_event_category_template_add_button_delete: "Delete",
      hotel_event_category_template_add_empty_guide: "Please Add Content",
      hotel_event_category_template_add_button_go_to_list: "Go to List",
      hotel_event_category_template_add_button_ok: "OK",
      hotel_event_category_template_add_validation_title: "* Please enter the title.",
      hotel_event_category_template_add_validation_content: "* Please enter the content.",
      hotel_event_category_template_add_validation_description: "* Please enter the description.",
      hotel_event_category_template_detail_title: "EVENT CATEGORY TEMPLATE DETAIL",
      hotel_event_category_template_detail_label_templates: "Templates",
      hotel_event_category_template_detail_label_category: "Category",
      hotel_event_category_template_detail_label_title: "Title",
      hotel_event_category_template_detail_label_content: "Content",
      hotel_event_category_template_detail_label_description: "Description",
      hotel_event_category_template_detail_label_date: "Date",
      hotel_event_category_template_detail_label_language: "Language",
      hotel_event_category_template_detail_empty_guide: "Please Add Content",
      hotel_event_category_template_detail_button_go_to_list: "Go to List",
      hotel_event_category_template_detail_button_delete: "Delete",
      hotel_event_category_template_detail_button_edit: "Edit",
      hotel_event_category_template_edit_title: "EVENT CATEGORY TEMPLATE EDIT",
      hotel_event_category_template_edit_label_title: "Title",
      hotel_event_category_template_edit_label_content: "Content",
      hotel_event_category_template_edit_label_description: "Description",
      hotel_event_category_template_edit_label_date: "Date",
      hotel_event_category_template_edit_label_language: "Language",
      hotel_event_category_template_edit_button_add: "Add",
      hotel_event_category_template_edit_button_delete: "Delete",
      hotel_event_category_template_edit_empty_guide: "Please Add Content",
      hotel_event_category_template_edit_button_go_to_list: "Go to List",
      hotel_event_category_template_edit_button_ok: "OK",
      hotel_event_category_template_edit_validation_title: "* Please enter the title.",
      hotel_event_category_template_edit_validation_content: "* Please enter the content.",
      hotel_event_category_template_edit_validation_description: "* Please enter the description.",
      hotel_staff_user_list_title: "STAFF LIST",
      hotel_staff_user_list_select_staff: "Staff ( ALL )",
      hotel_staff_user_list_select_all: "All",
      hotel_staff_user_list_select_department: "Department",
      hotel_staff_user_list_select_team: "Team",
      hotel_staff_user_list_select_level: "Level",
      hotel_staff_user_list_select_task: "Task",
      hotel_staff_user_list_select_staff_number: "Staff Number",
      hotel_staff_user_list_select_email: "Email",
      hotel_staff_user_list_placeholder_search: "Search...",
      hotel_staff_user_list_label_department: "Department",
      hotel_staff_user_list_label_team: "Team",
      hotel_staff_user_list_label_level: "Level",
      hotel_staff_user_list_label_task: "Task",
      hotel_staff_user_list_label_number: "Number",
      hotel_staff_user_list_label_employee_id: "Employee ID",
      hotel_staff_user_list_label_available_at: "Available At",
      hotel_staff_user_list_label_expired_at: "Expired At",
      hotel_staff_user_list_label_login_at: "Logged in at",
      hotel_staff_user_list_label_date: "Date",
      hotel_staff_user_list_button_delete: "Delete",
      hotel_staff_user_list_button_add: "Add",
      hotel_staff_user_add_title: "NEW STAFF",
      hotel_staff_user_add_guide_click_add: 'To add new staff, enter required information and click "Add".',
      hotel_staff_user_add_validation_department: "* Please select staff department.",
      hotel_staff_user_add_validation_team: "* Please select staff team.",
      hotel_staff_user_add_validation_level: "* Please select staff position.",
      hotel_staff_user_add_validation_guest_inquiry_level: "* Please select staff guest inquiry level.",
      hotel_staff_user_add_validation_number: "* Please enter staff number.",
      hotel_staff_user_add_validation_task: "* Please enter staff task.",
      hotel_staff_user_add_validation_number_only: "* Please enter using numbers only.",
      hotel_staff_user_add_validation_email: "* Please enter staff email.",
      hotel_staff_user_add_validation_email_format: "* Please enter according to the email format.",
      hotel_staff_user_add_validation_password: "* Please enter staff password.",
      hotel_staff_user_add_validation_character_number_combining: "* Please enter at least 8 characters combining letters and numbers.",
      hotel_staff_user_add_validation_not_mach: "* Passwords do not match.",
      hotel_staff_user_add_label_type: "Type",
      hotel_staff_user_add_select_full_time: "Full-time",
      hotel_staff_user_add_select_temporary: "Temporary",
      hotel_staff_user_add_placeholder_select_box_choose: "Select",
      hotel_staff_user_add_label_department: "Department",
      hotel_staff_user_add_select_manage: "Management",
      hotel_staff_user_add_select_concierge: "Concierge",
      hotel_staff_user_add_select_housekeeping: "Housekeeping",
      hotel_staff_user_add_select_front_desk: "Front Desk",
      hotel_staff_user_add_select_valet: "Valet",
      hotel_staff_user_add_label_team: "Team",
      hotel_staff_user_add_select_none: "",
      hotel_staff_user_add_select_guest_service: "Guest Services",
      hotel_staff_user_add_label_level: "Level",
      hotel_staff_user_add_select_manager: "Manager",
      hotel_staff_user_add_select_supervisor: "Supervisor",
      hotel_staff_user_add_select_staff: "Staff",
      hotel_staff_user_add_select_duty_manager: "Duty Manager",
      hotel_staff_user_add_label_guest_inquiry_level: "Guest Inquiry Level",
      hotel_staff_user_add_label_number: "Number",
      hotel_staff_user_add_placeholder_staff_number: "Staff Number",
      hotel_staff_user_add_label_location: "Location",
      hotel_staff_user_add_placeholder_staff_location: "Staff Location",
      hotel_staff_user_add_label_task: "Task",
      hotel_staff_user_add_placeholder_staff_task: "Staff Task",
      hotel_staff_user_add_label_email: "Email",
      hotel_staff_user_add_placeholder_staff_email: "Staff Email",
      hotel_staff_user_add_label_password: "Password",
      hotel_staff_user_add_placeholder_staff_password: "Staff Password",
      hotel_staff_user_add_label_confirm_password: "Confirm Password",
      hotel_staff_user_add_placeholder_staff_confirm_password: "Confirm Password",
      hotel_staff_user_add_label_available_at: "Available At",
      hotel_staff_user_add_label_expired_at: "Expired At",
      hotel_staff_user_add_button_add: "Add",
      hotel_staff_user_add_button_go_to_list: "Go to list",
      hotel_staff_user_detail_title: "STAFF INFORMATION",
      hotel_staff_user_detail_label_update_date: "Date Updated",
      hotel_staff_user_detail_label_department: "Department",
      hotel_staff_user_detail_label_team: "Team",
      hotel_staff_user_detail_label_level: "Level",
      hotel_staff_user_detail_label_guest_inquiry_level: "Guest inquiry level",
      hotel_staff_user_detail_label_task: "Task",
      hotel_staff_user_detail_label_staff_number: "Staff Number",
      hotel_staff_user_detail_label_email: "Email",
      hotel_staff_user_detail_label_location: "Location",
      hotel_staff_user_detail_label_room: "Rooms",
      hotel_staff_user_detail_label_login_at: "Logged in at",
      hotel_staff_user_detail_label_date: "Date",
      hotel_staff_user_detail_label_available_at: "Available At",
      hotel_staff_user_detail_label_expired_at: "Expired At",
      hotel_staff_user_detail_label_locked_information: "Locked Information",
      hotel_staff_user_detail_label_locked_reason: "Locked Reason",
      hotel_staff_user_detail_label_locked_at: "Locked At",
      hotel_staff_user_detail_label_password: "Password",
      hotel_staff_user_detail_label_password_change: "Password Change",
      hotel_staff_user_detail_button_go_to_list: "Go to list",
      hotel_staff_user_detail_button_delete: "Delete",
      hotel_staff_user_detail_button_edit: "Edit",
      hotel_staff_user_edit_title: "STAFF INFORMATION",
      hotel_staff_user_edit_label_update_date: "Date Updated",
      hotel_staff_user_edit_label_department: "Department",
      hotel_staff_user_edit_placeholder_select_box_choose: "Select",
      hotel_staff_user_edit_validation_department: "* Please select staff department.",
      hotel_staff_user_edit_select_manage: "Management",
      hotel_staff_user_edit_select_concierge: "Concierge",
      hotel_staff_user_edit_select_housekeeping: "Housekeeping",
      hotel_staff_user_edit_select_front_desk: "Front Desk",
      hotel_staff_user_edit_select_valet: "Valet",
      hotel_staff_user_edit_label_team: "Team",
      hotel_staff_user_edit_select_none: "",
      hotel_staff_user_edit_select_guest_service: "Guest Services",
      hotel_staff_user_edit_label_level: "Level",
      hotel_staff_user_edit_select_manager: "Manager",
      hotel_staff_user_edit_select_supervisor: "Supervisor",
      hotel_staff_user_edit_select_staff: "Staff",
      hotel_staff_user_edit_select_duty_manager: "Duty Manager",
      hotel_staff_user_edit_label_task: "Task",
      hotel_staff_user_edit_placeholder_task: "Task",
      hotel_staff_user_edit_label_staff_number: "Staff Number",
      hotel_staff_user_edit_placeholder_staff_number: "Staff Number",
      hotel_staff_user_edit_label_email: "Email",
      hotel_staff_user_edit_placeholder_email: "Email",
      hotel_staff_user_edit_label_location: "Location",
      hotel_staff_user_edit_placeholder_location: "Location",
      hotel_staff_user_edit_label_room: "Rooms",
      hotel_staff_user_edit_label_login_at: "Logged in at",
      hotel_staff_user_edit_label_date: "Date",
      hotel_staff_user_edit_label_available_at: "Available At",
      hotel_staff_user_edit_label_expired_at: "Expired At",
      hotel_staff_user_edit_label_locked_information: "Locked Information",
      hotel_staff_user_edit_label_locked_reason: "Locked Reason",
      hotel_staff_user_edit_label_locked_at: "Locked At",
      hotel_staff_user_edit_button_cancel: "Cancel",
      hotel_staff_user_edit_button_delete: "Delete",
      hotel_staff_user_edit_button_ok: "OK",
      contents_failed_unable_connect_server: "Unable to connect to server. \n  Please check your Internet connection.",
      button_ok: "OK",
      button_cancel: "Cancel",
      button_failed_login_message: "Email and password do not match.",
      button_delete_message: "Are you sure you want to delete the selected information?",
      button_no_select_delete_message: "None selected. \n Please select a list to delete.",
      button_no_select_hotel_copy_message: "None selected.\nPlease select a hotel to copy.",
      button_only_one_hotel_copy_message: "Please select only one hotel.",
      button_cancel_message: "Are you sure you want to cancel the selected information?",
      button_no_select_cancel_message: "None selected. \n Please select a list to cancel.",
      button_admin_cant_delete_message: "You cannot delete ADMIN. Please deselect.",
      button_manager_cant_delete_message: "You cannot delete Manager. \n  Please deselect.",
      button_cant_release_message: "You cannot release this member. \n  Please deselect.",
      button_withdraw_message: "Would you like to withdraw?",
      button_release_message: "Are you sure you want to release the selected information?",
      button_no_select_release_message: "None selected. \n Please select a list to release.",
      button_delete_qna_message: "Are you sure you want to delete the QnA?",
      button_update_information_message: "Matching information exists. \n  Do you want to update this information?",
      button_delete_information_message: "Delete existing information and update \n with new information.",
      button_insert_information_message: "Input new information.",
      button_qna_loading_message: "Loading…",
      button_cancel_not_save_message: "If you cancel, the content will not be saved.\n Are you sure you want to cancel?",
      button_cancel_booking_message: "Are you sure you want to cancel the selected reservation?",
      button_delete_owner_message: "Are you sure you want to delete the selected main guest?",
      button_success_message: "Modifications have been made.",
      button_stop_message: "Do you want to stop the selected request? \n  (Unknown situation)",
      button_no_select_stop_message: "None selected. Please select a list to stop.",
      button_upload_success_message: "Upload Success. \n  [[image]]",
      button_reservation_message: "There are requests that have \n  not yet been completed. Would you like to confirm the requests?",
      button_amenity_message_title: "Item No.",
      button_amenity_message: "Would you like to modify the Item No. of the corresponding Amenity as follows?",
      button_transfer_user_id_message: "Enter guest MyCONECT user ID to resync.",
      contents_failed_session_password: "Please enter a new password\n with at least 8 characters.",
      contents_session_out_message: "Session timeout. \n  Please go to the login page.",
      contents_failed_session_message: "Failed to retrieve session.",
      contents_failed_password_incorrect_message: "Current password is incorrect.",
      contents_failed_get_hotel_detail: "Failed to retrieve hotel details.",
      contents_failed_get_region_detail: "Failed to retrieve region details.",
      contents_failed_get_region_list: "Failed to retrieve region list",
      contents_failed_get_country_detail: "Failed to retrieve country details.",
      contents_failed_get_country_list: "Failed to retrieve country list",
      contents_failed_get_city_detail: "Failed to retrieve city details.",
      contents_failed_get_city_list: "Failed to retrieve city list",
      contents_failed_edit_hotel: "Failed to edit hotel.",
      contents_failed_edit_region: "Failed to edit region.",
      contents_failed_edit_country: "Failed to edit country.",
      contents_failed_edit_city: "Failed to edit city.",
      contents_failed_edit_amenity: "Failed to edit amenity.",
      contents_failed_add_amenity: "Unable to add Amenity.",
      contents_failed_edit_recommend_app: "Failed to edit Recommend App.",
      contents_failed_add_recommend_app: "Unable to add Recommend App.",
      contents_failed_edit_id_recognition_information: "Failed to edit ID Recognition Information.",
      contents_failed_add_id_recognition_information: "Unable to add ID Recognition Information.",
      contents_failed_get_list: "Failed to get corresponding page list.",
      contents_failed_logout: "Logout failed.",
      contents_failed_get_informarion: "Failed to get user information.",
      contents_failed_edit_information: "Failed to edit information.",
      contents_failed_modify: "Password Change Failed.",
      contents_failed_add_user: "Unable to add user. Please check if the email already exists.",
      contents_failed_transfer: "Link transfer failed.",
      contents_failed_change_duty: "Duty change failed.",
      contents_failed_edit_user: "Failed to edit user.",
      contents_failed_get_withdraw: "Failed to withdraw.",
      contents_failed_add_version: "Unable to add Version.",
      contents_failed_edit_version: "Failed to edit version.",
      contents_failed_delete: "Failed to delete.",
      contents_failed_image: "Failed to get image information.",
      contents_failed_get_area_list: "Failed to get special area list.",
      contents_failed_get_hotel_detail_list: "Failed to retrieve hotel details list.",
      contents_failed_get_hotel_detail_detail: "Failed to get Hotel details.",
      contents_failed_get_facility_icon_list: "Failed to retrieve facility icon list.",
      contents_failed_get_hotel_amenity_list: "Failed to retrieve hotel amenity list.",
      contents_failed_add_hotel_detail_content: "Unable to add Hotel Detail Content.",
      contents_failed_add_hotel_detail_amenity: "Unable to add Hotel Detail Amenity.",
      contents_failed_get_hotel_recommend_application_list: "Failed to retrieve hotel recommended application list.",
      contents_failed_add_hotel_detail_recommend_application: "Unable to add hotel recommended application.",
      contents_failed_get_hotel_img_detail: "Failed to get Hotel Image details.",
      contents_failed_get_facility_img_detail: "Failed to get Hotel Facility Image details.",
      contents_failed_add_hotel_img: "Unable to add Hotel Image.",
      contents_failed_add_facility_img: "Unable to add Hotel Facility Image.",
      contents_failed_add_room_img: "Unable to add Room Image.",
      contents_failed_get_room_amenity_list: "Failed to retrieve room amenity list.",
      contents_failed_add_room_detail_amenity: "Unable to add Room Detail Amenity.",
      contents_failed_get_room_img_detail: "Failed to get Room Image details.",
      contents_failed_edit_hotel_detail_content: "Failed to edit Hotel Detail Content.",
      contents_failed_edit_room: "Failed to edit Room.",
      contents_failed_edit_room_detail_content: "Failed to edit Room Detail Content.",
      contents_failed_add_package: "Unable to add Package.",
      contents_failed_add_package_detail: "Unable to add Package Detail.",
      contents_failed_get_package_detail: "Failed to retrieve package.",
      contents_failed_get_package_detail_detail: "Failed to retrieve package details.",
      contents_failed_get_access_detail_detail: "Failed to retrieve access details.",
      contents_failed_add_access: "Unable to add Access.",
      contents_failed_edit_access: "Failed to edit Access.",
      contents_failed_add_access_detail: "Unable to add Access Detail.",
      contents_failed_add_beacon: "Unable to add Beacon.",
      contents_failed_add_region: "Unable to add Region.",
      contents_failed_add_country: "Unable to add Country.",
      contents_failed_add_city: "Unable to add City.",
      contents_failed_edit_beacon: "Failed to edit Beacon.",
      contents_failed_add_currency: "Unable to add Currency.",
      contents_failed_get_currency_detail: "Failed to get hotel currency details.",
      contents_not_supported_exchange_rate: "The exchange rate is not supported.",
      contents_failed_get_currency_pms: "Failed to get PMS Hotel Currency.",
      contents_failed_get_currency_update: "Failed to update Hotel Currency.",
      contents_failed_request_stop: "Failed to Request Stop.",
      contents_failed_upload_file: "Failed to upload files.",
      contents_failed_get_count_canceled: "Failed to get Canceled Count Board.",
      contents_failed_get_business_rule_list: "Failed to get business rule list.",
      contents_failed_uptade_myconect: "Failed to update MyCONECT operation time settings.",
      contents_failed_get_concierge_myconect: "Failed to retrieve MyCONECT Concierge List.",
      contents_request_type_not_exist: "Request Type does not exist.",
      contents_failed_get_request_detail: "Failed to get request details.",
      contents_failed_request_stand_by: "Failed to request Standby.",
      contents_failed_request_pickup: "Failed to request Pickup.",
      contents_failed_request_start: "Failed to request Start.",
      contents_failed_request_deliver: "Failed to request Deliver.",
      contents_failed_request_valet_no: "Failed to request Valet No.",
      contents_failed_get_hotel_setting: "Failed to get Hotel Settings.",
      contents_failed_get_count_request_board: "Failed to get Request Count Board.",
      contents_failed_get_concierge_process_list: "Failed to get Concierge Process List.",
      contents_failed_get_valet_parking_list: "Failed to get Valet Parking List.",
      contents_failed_add_parking_area_no: "Unable to add Parking Area No.",
      contents_failed_change_veicle_no: "Vehicle No. change failed.",
      contents_failed_request_location: "Failed to prepare vehicle.",
      contents_failed_request_return: "Failed to return vehicle.",
      contents_failed_request_handover: "Failed to hand over vehicle to guest.",
      contents_failed_get_business_rule: "Failed to retrieve business rule.",
      contents_failed_get_boundary_line_setting: "Failed to retrieve Boundary Line Settings.",
      contents_failed_request_confirm: "Failed to request 'Confirmation'.",
      contents_failed_request_not_available: "Failed to request 'Not Available'.",
      contents_failed_request_not_available_myconect: "Failed to request 'Not Available by MyCONECT'.",
      contents_failed_get_concierge_request_list: "Failed to retrieve Concierge Request List.",
      contents_already_exists: "This type already exists.",
      contents_failed_add_help_for_mobile_key: "Failed to add 'Help for Mobile Key'.",
      contents_failed_get_help_for_mobile_key: "Failed to get 'Help for Mobile Key'.",
      contents_failed_edit_help_for_mobile_key: "Failed to edit 'Help for Mobile Key'.",
      contents_failed_delete_help_for_mobile_key: "Failed to delete 'Help for Mobile Key' information.",
      contents_failed_add_service_description: "Failed to add 'Service Description'.",
      contents_failed_get_service_description: "Failed to get 'Service Description'.",
      contents_failed_edit_service_description: "Failed to edit 'Service Description'.",
      contents_failed_delete_service_description: "Failed to delete 'Service Description'.",
      contents_failed_add_hotel_information: "Failed to add Hotel Information.",
      contents_failed_get_hotel_information: "Failed to get Hotel Information.",
      contents_failed_edit_hotel_information: "Failed to edit Hotel Information.",
      contents_failed_edit_language_code_translation: "Language has already been selected.",
      contents_failed_all_language_code_exist: "All translations have been added.",
      contents_failed_delete_hotel_information: "Failed to delete hotel information.",
      contents_failed_get_canceled_user_count: "Failed to get Canceled By User Count.",
      contents_failed_get_canceled_staff_count: "Failed to get Canceled By MyCONECT or Staff Count.",
      contents_failed_get_housekeeping_myconect: "Failed to get Housekeeping MyCONECT List.",
      contents_failed_add_multi_myconect: "The date you selected is incorrect",
      contents_failed_request_complete: "Failed to request 'Complete'.",
      contents_failed_request_put_off: "Failed to request 'Put Off'.",
      contents_failed_get_amenity_count: "Failed to retrieve Amenity Count.",
      contents_failed_get_valet_count: "Failed to retrieve Valet Count.",
      contents_failed_get_housekeeping_process_list: "Failed to retrieve Housekeeping Progress List.",
      contents_failed_get_boundary_line_request_count: "Failed to retrieve Boundary Line Request Count.",
      contents_failed_update_auto: "Failed to update Green Campaign auto mode time",
      contents_failed_get_housekeeping_request_list: "Failed to retrieve Housekeeping Request List.",
      contents_failed_get_room_controller_detail: "Failed to retrieve Room Control details.",
      contents_failed_insert_files: "Failed to insert files.",
      contents_failed_get_room_to_room_detail: "Failed to retrieve Room to Room details.",
      contents_failed_get_share_key_detail: "Failed to retrieve Share key details.",
      contents_failed_modify_amenity: "Failed to modify Amenity Item No.",
      contents_failed_get_boundary_line_detail: "Failed to retrieve boundary line details.",
      contents_failed_modify_boundary_line_detail: "Failed to modify boundary line details.",
      contents_failed_modify_boundary_line_unasigned_count: "Failed to retrieve unassigned boundary line count.",
      contents_failed_get_business_rule_detail: "Failed to retrieve business rule details.",
      contents_failed_modify_business_rule_detail: "Failed to modify business rule details.",
      contents_failed_modify_waiting_time: "Failed to modify Room Wait time.",
      contents_failed_cancel: "Failed to Cancel.",
      contents_failed_add_member_type: "Unable to add Member Type.",
      contents_failed_edit_member_type: "Failed to edit Member Type.",
      contents_failed_edit_pms: "Failed to edit PMS.",
      contents_failed_sync: "Failed to Sync.",
      contents_failed_send_reservation_email: "Failed to send Reservation Email.",
      contents_failed_get_staff_key_link_list: "Failed to retrieve staff key link list.",
      contents_failed_get_staff_key_link_type_list: "Failed to retrieve staff key link type list.",
      contents_failed_get_staff_key_link_floor_list: "Failed to retrieve staff key link floor list.",
      contents_failed_get_staff_key_link_zone_list: "Failed to retrieve staff key link zone list.",
      contents_failed_get_staff_key_link_code_list: "Failed to retrieve staff key link code list.",
      contents_failed_get_staff_detail: "Failed to retrieve staff details.",
      contents_failed_edit_staff_user: "Failed to edit staff user.",
      contents_failed_add_title: "Unable to add title.",
      contents_failed_edit_title: "Failed to edit title.",
      contents_failed_get_list_search_page: "Failed to retrieve list of search pages.",
      contents_failed_edit_setting: "Failed to edit Settings.",
      contents_failed_add_staff_key_link: "Unable to add Staff Key Link.",
      contents_failed_edit_staff_key_link: "Failed to edit Staff Key Link.",
      contents_failed_edit_hotel_img: "Failed to edit Hotel Image.",
      contents_failed_edit_facility_img: "Failed to edit Hotel Facility Image.",
      contents_failed_edit_room_img: "Failed to edit Room Image.",
      contents_failed_edit_package_detail: "Failed to edit Package Details.",
      contents_failed_edit_access_detail: "Failed to edit Access Details.",
      contents_failed_add_guest_app_version: "Failed to add guest app version.",
      contents_failed_add_staff_app_version: "Failed to add staff app version.",
      contents_failed_get_rule_information: "Failed to retrieve Rule Information.",
      contents_failed_edit_rule_information: "Failed to edit Rule Information.",
      contents_failed_edit_sequence_information: "Failed to edit Sequence.",
      contents_failed_get_rule_schedule_information: "Failed to retrieve Rule Schedule Information.",
      contents_failed_edit_rule_schedule_information: "Failed to edit Rule Schedule Information.",
      None: "Failed to retrieve Compare Face Information.",
      contents_modal_stop_message_unknown: "Stop message is saved as follows: \n  'Unknown Situation' \n  Would you like to proceed?",
      contents_modal_stop_message_no_taxi: "Stop message is saved as follows: \n  'Taxi No-Show' \n  Would you like to proceed?",
      contents_modal_stop_message_no_guest: "Stop message is saved as follows: \n  'Guest No-Show' \n  Would you like to proceed?",
      contents_modal_stop_message_time_out_30: "Stop message is saved as follows: \n  'Waiting time exceeded 30 min' \n  Would you like to proceed?",
      contents_modal_stop_message_time_out_60: "Stop message is saved as follows: \n  'Waiting time exceeded 60 min' \n  Would you like to proceed?",
      contents_modal_stop_message_no_motorcycle: "Stop message is saved as follows: \n  'Delivery Motorcycle No-Show' \n  Would you like to proceed?",
      contents_modal_stop_message_no_luggage: "Stop message is saved as follows: \n  'Luggage not ready' \n  Would you like to proceed?",
      contents_modal_stop_message_delay_30: "Put Off message is saved as follows: \n  'Delay 30 min' \n  Would you like to proceed?",
      contents_modal_stop_message_delay_60: "Put Off message is saved as follows: \n  'Delay 60 min' \n  Would you like to proceed?",
      contents_modal_stop_message_as_soon_as: "Put Off message is saved as follows: \n  'As soon as available' \n  Would you like to proceed?",
      contents_modal_stop_message_out_of_order: "Stop message is saved as follows: \n  'Out of order' \n  Would you like to proceed?",
      contents_modal_stop_message_cancel_service: "Stop message is saved as follows: \n  'Cancel service' \n  Would you like to proceed?",
      contents_modal_stop_message_not_found_it: "Stop message is saved as follows: \n  'Cannot be found' \n  Would you like to proceed?",
      contents_modal_stop_message_no_guest_room: "Stop message is saved as follows: \n  'Guest not in room' \n  Would you like to proceed?",
      contents_modal_stop_message_change_items: "Stop message is saved as follows: \n  'Change items' \n  Would you like to proceed?",
      contents_modal_stop_message_unparked: "Stop message is saved as follows: \n  'Unparked and cancel' \n  Would you like to proceed?",
      contents_modal_stop_message_parked: "Stop message is saved as follows: \n  'Parked and cancel' \n  Would you like to proceed?",
      contents_modal_myconect_release_proceed: "MyCONECT is progressed as follows: \n  'Release Not Available by MyCONECT' \n  Would you like to proceed?",
      contents_modal_pickup_request: "Do you want to Pickup the request?",
      contents_modal_deliver_request: "Do you want to Deliver the request?",
      contents_modal_start_request: "Do you want to Start the request?",
      contents_modal_confirm_request: "Do you want to Confirm the request?",
      contents_modal_not_available_request: "Do you want to set the request to 'Not Available'?",
      contents_modal_complete_request: "Do you want to Complete the request?",
      contents_modal_greencampaign_auto_process: "Do you want to progress Auto Green Campaign?",
      contents_modal_select_staff_payment: "Do you want to proceed with the selected information?",
      contents_modal_select_business_rule: "Do you want to proceed with the selected information?",
      contents_modal_delay_request: "Do you want to Delay the request?",
      contents_modal_put_off_request: "Do you want to Put Off the request?",
      contents_modal_change_request: "Do you want to change it?",
      contents_modal_save_these_modifications: "Do you want to save these modifications?",
      contents_modal_select_myconect_not_available: "Select 'Not Available by MyCONECT' set time",
      contents_modal_select_delay: "Select 'Delay' set time",
      contents_modal_select_status: "Please select the status.",
      contents_modal_make_sure_reason: "Please select the reason.",
      contents_modal_myconect_not_available_proceed: "MyCONECT will be progressed as follows: \n  'This request will be set as 'Unavailable' \n  Would you like to proceed?",
      contents_modal_myconect_time_proceed: "MyCONECT will be progressed as follows: \n  From: {{fromTime}} / To: {{endTime}} \n  Would you like to proceed?",
      contents_modal_greencampaign_time_proceed: "Green Campagin will be progressed as follows: \n  From: {{fromTime}} / To: {{endTime}} \n  Would you like to proceed?",
      contents_modal_cannot_empty_value: "Value cannot be an empty. \n  Please enter the value.",
      contents_modal_information_already_exists: "Information already exists. \n  Please try again.",
      contents_modal_placeholder_user_email: "Please enter user email.",
      contents_modal_check_reset: "Previously selected users will be reset. Would you like to continue?",
    },
  },
  date_format: {
    en: {
      date_format_slash_time_date: {
        date_format: "hh:mm a MM/dd/yy",
        language_code: "en",
      },
      date_format_slash_date: {
        date_format: "MM/dd/yy",
        language_code: "en",
      },
      date_format_hyphen_date: {
        date_format: "yyyy-MM-dd",
        language_code: "en",
      },
      date_format_dot_month_year: {
        date_format: "MMM.yyyy",
        language_code: "en",
      },
      date_format_dot_year_month: {
        date_format: "yyyy.MM",
        language_code: "en",
      },
      date_format_slash_year_month: {
        date_format: "MM/yy",
        language_code: "en",
      },
      date_format_ampm_time: {
        date_format: "a hh:mm",
        language_code: "en",
      },
      date_format_time_ampm: {
        date_format: "hh:mm a",
        language_code: "en",
      },
      date_format_24_hours_time: {
        date_format: "HH:mm",
        language_code: "en",
      },
      date_format_hours_ampm: {
        date_format: "ha",
        language_code: "en",
      },
      date_format_full_year: {
        date_format: "yyyy",
        language_code: "en",
      },
      date_format_word_month: {
        date_format: "MMM",
        language_code: "en",
      },
      date_format_full_day: {
        date_format: "dd",
        language_code: "en",
      },
      date_format_short_day: {
        date_format: "d",
        language_code: "en",
      },
      date_format_day_of_week: {
        date_format: "E",
        language_code: "en",
      },
      date_format_ampm: {
        date_format: "a",
        language_code: "en",
      },
      date_format_day_of_week_full: {
        date_format: "EEEE",
        language_code: "en",
      },
      date_format_dot_month_day: {
        date_format: "MM.dd",
        language_code: "en",
      },
      date_format_day_of_week_date: {
        date_format: "E. MMM. dd, yyyy",
        language_code: "en",
      },
      date_format_slash_date_time: {
        date_format: "MM/dd/yy hh:mm a",
        language_code: "en",
      },
      date_format_slash_month_day_time: {
        date_format: "MM/dd hh:mm a",
        language_code: "en",
      },
      date_format_dot_date_time_full: {
        date_format: "yyyy.MM.dd hh:mm a",
        language_code: "en",
      },
      date_format_dot_year_month_day: {
        date_format: "MM.dd.yy",
        language_code: "en",
      },
      date_format_slash_full_year_date: {
        date_format: "yyyy/MM/dd",
        language_code: "en",
      },
      date_format_space_date: {
        date_format: "dd MMM yyyy",
        language_code: "en",
      },
      date_format_create_at_time_full: {
        date_format: "yyyy.MM.dd\nhh:mm a",
        language_code: "en",
      },
    },
  },
};

export default EnglishLanguage;
