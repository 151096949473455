import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import RegionAddData from "../Add/RegionAddData";


//css
import "../../../css/common.css";
import "../../../css/location/region/regionAdd.css";

class RegionAddView extends RegionAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      region: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change region
  onChangeRegion = (e) => {
    const inputValue = e.target.value;
    this.setState({ region: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("region", "admin_region_add_validation_name")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddRegion(this.state.region);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/regionListPage");
  };

  render() {
    return (
      <div className="addRegion">
        {this.state.isShowAllLoading && <Loading />}
        <div className="region_add_container">
          <div className="addregion_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_region_add_title">ADD REGION</p>
              </div>
              <div className="sub_text">
                <p data-detect="admin_region_add_guide_click_add">Enter information to add REGION. And Click the "Add" button.</p>
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addregion_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addregion_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_region_detail_label_region">Area</p>
                        </div>
                      </div>
                      <div className="detail_cell content region_name">
                        <input
                          type="text"
                          id="region"
                          name="region"
                          data-detect-placeholder="admin_region_add_placeholder_region_name"
                          placeholder="Region Name"
                          autoComplete="off"
                          value={this.state.region}
                          onChange={this.onChangeRegion}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_region_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_region_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RegionAddData.mapStateToProps;
let mapDispatchToProps = RegionAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegionAddView));
