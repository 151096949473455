import React, { Component } from "react";

// img
import prevBtn from "../../icon/arrow_left.svg";
import nextBtn from "../../icon/arrow_right.svg";
import doublePrevBtn from "../../icon/double_arrow_left.svg";
import doubleNextBtn from "../../icon/double_arrow_right.svg";
//css
import "../../css/common/myConectPagination.css";
class Pagination extends Component {
  handlePageChange = (page) => {
    this.props.handlePageChange(page);
  };

  render() {
    let totalCnt = parseInt(this.props.total); // 전체레코드수
    let dataSize = parseInt(this.props.dataSize); // 페이지당 보여줄 데이타수
    let pageSize = parseInt(this.props.pageSize); // 페이지 그룹 범위 1 2 3 5 6 7 8 9 10
    let pageNo = parseInt(this.props.pageNo); // 현재페이지
    let firstPageNum = 1; // 처음 페이지
    let lastPageNum = Math.floor((totalCnt - 1) / dataSize) + 1; // 마지막 페이지

    let html = [];
    if (this.props.total == undefined || totalCnt == 0 || totalCnt == NaN) {
      return "";
    }
    // 페이지 카운트
    var pageCnt = totalCnt % dataSize;
    if (pageCnt == 0) {
      pageCnt = parseInt(totalCnt / dataSize);
    } else {
      pageCnt = parseInt(totalCnt / dataSize) + 1;
    }

    var pRCnt = parseInt(pageNo / pageSize);
    if (pageNo % pageSize == 0) {
      pRCnt = parseInt(pageNo / pageSize) - 1;
    }

    //이전 화살표
    if (pageNo > pageSize) {
      let s2;
      if (pageNo % pageSize == 0) {
        s2 = pageNo - pageSize;
      } else {
        s2 = pageNo - (pageNo % pageSize);
      }
      html.push(
        <React.Fragment key="prevButton">
          <button
            className="doublePrevBtn"
            onClick={() => this.handlePageChange(firstPageNum)}
          >
            <img className="paginationImage" src={doublePrevBtn} alt="doublePrev" color="white" />
          </button>
          <button
            className="prevBtn"
            onClick={() => this.handlePageChange(s2)}
          >
            <img className="paginationImage" src={prevBtn} alt="prev" />
          </button>
        </React.Fragment>
        
      );
    } else {
      html.push(
        <button className="prevBtn" key="prevButton">
          {/* <img className="paginationImage" src={whitePrevBtn} alt="prev" /> */}
          <img className="paginationImage" src={prevBtn} alt="prev" />
        </button>
      );
    }
    //paging Bar
    for (
      let index = pRCnt * pageSize + 1;
      index < (pRCnt + 1) * pageSize + 1;
      index++
    ) {
      if (index == pageNo) {
        html.push(<strong className="selectedNum" key={index}>{index}</strong>);
      } else {
        html.push(
          <button className="pageNumBtn" key={index} onClick={() => this.handlePageChange(index)}>
            {index}
          </button>
        );
      }
      if (index == pageCnt) {
        break;
      }
    }
    //다음 화살표
    if (pageCnt > (pRCnt + 1) * pageSize) {
      let nextPageNum = (pRCnt + 1) * pageSize + 1;
      html.push(
        <React.Fragment key={"nextButton"}>
          <button
            className="nextBtn"
            onClick={() => this.handlePageChange(nextPageNum)}
          >
            <img className="paginationImage" src={nextBtn} alt="next" color="white" />
          </button>
          <button
            className="doubleNextBtn"
            onClick={() => this.handlePageChange(lastPageNum)}
          >
            <img className="paginationImage" src={doubleNextBtn} alt="doubleNext" color="white" />
          </button>
        </React.Fragment>
        
      );
    } else {
      html.push(
        <button className="nextBtn" key="next">
          <img className="paginationImage" src={nextBtn} alt="next" />
        </button>
      );
    }

    return <div className="myConectPagination">{html}</div>;
  }
}

export default Pagination;
