import axiosInstance from "../api/axios";

//recommend
//------------------------------- get recommend List ------------------------------------------------------//
export function onGetAllRecommend(pageNo, name, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/v2/recommendApp/?name=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get recommend One ------------------------------------------------------//
export function onGetOneRecommend(recommendId) {
  return axiosInstance.get("/admin/api/v2/recommendApp/" + recommendId);
}

//------------------------------- add recommend recommend ------------------------------------------------------//
export function onAddRecommend(name, iosScheme, iosItunesUrl, aosPackageId, aosPlayStoreUrl) {
  const requestData = {
    name: name,
    iosScheme: iosScheme,
    iosITunesUrl: iosItunesUrl,
    aosPackageId: aosPackageId,
    aosPlayStoreUrl: aosPlayStoreUrl,
  };

  return axiosInstance.post("/admin/api/v2/recommendApp", requestData);
}
//------------------------------- delete recommend recommend ------------------------------------------------------//
export function onDeleteRecommend(recommendId) {
  return axiosInstance.delete("/admin/api/v2/recommendApp/" + recommendId);
}

//------------------------------- edit recommend ------------------------------------------------------//
export function onEditRecommend(name, iosScheme, iosItunesUrl, aosPackageId, aosPlayStoreUrl, recommendId) {
  const requestData = {
    name: name,
    iosScheme: iosScheme,
    iosITunesUrl: iosItunesUrl,
    aosPackageId: aosPackageId,
    aosPlayStoreUrl: aosPlayStoreUrl,
  };

  return axiosInstance.put("/admin/api/v2/recommendApp/" + recommendId, requestData);
}
