import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import HotelCopyData from "../Copy/HotelCopyData";



//css
import "../../css/common.css";
import "../../css/hotel/hotelCopy.css";

class HotelCopyView extends HotelCopyData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      name: "",
      address: "",
      email: "",
      password: "",
      confirmPwd: "",
      logoFile: "",
      logoText: "",
      copyId: "",
    };
  }

  componentDidMount() {
    this.onCheckValidation();
    const params = new URLSearchParams(window.location.search);
    const copyId = params.get("copyId");
    this.setState({
      copyId: copyId,
    });
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change address
  onChangeAddress = (e) => {
    const inputValue = e.target.value;
    this.setState({ address: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change email
  onChangeEmail = (e) => {
    const inputValue = e.target.value;
    this.setState({ email: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change password
  onChangePassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ password: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change password confirm
  onChangePwdConfirm = (e) => {
    const inputValue = e.target.value;
    this.setState({ confirmPwd: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // check validation
  onCheckValidation = () => {
    const { name, address, email, password, confirmPwd } = this.state;

    if (
      name !== "" &&
      address !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPwd !== ""
    ) {
      document.querySelector(".copy_btn").removeAttribute("disabled");
    } else {
      document.querySelector(".copy_btn").setAttribute("disabled", "disabled");
    }
  };

  //file image 미리보기
  preview = () => {
    if (!this.state.logoFile || !this.state.logoFile instanceof Blob) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }

    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const imgEL = document.querySelector(".img__box");
    const reader = new FileReader();
    reader.onload = () =>
      (imgEL.style.backgroundImage = `url(${reader.result})`);

    // 이미지 미리보기가 완료되면 로딩 종료
    reader.onloadend = () => {
      this.setState({
        isShowAllLoading: false,
      });
    };

    reader.readAsDataURL(this.state.logoFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(
      { logoFile: file, logoText: file ? file.name : "ex) MyCONECT.png" },
      this.preview
    );
  };

  //copy 버튼을 눌렀을때 전달
  copy_btn = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestCopyHotel(
      this.state.name,
      this.state.address,
      this.state.email,
      this.state.password,
      this.state.confirmPwd,
      this.state.logoFile,
      this.state.logoText,
      this.state.copyId
    );
  };

  render() {
    const imgBoxStyle = {
      marginLeft:
        this.state.logoFile && this.state.logoFile instanceof Blob
          ? "300px"
          : "0",
    };
    return (
      <div className="hotelCopy">
        {this.state.isShowAllLoading && <Loading />}
        <div className="hotel_copy_container">
          <div className="copyhotel_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_hotel_copy_title">COPY HOTEL</p>
              </div>
              <div className="sub_text" style={{ color: "white" }}>
                <p data-detect="admin_hotel_copy_guide_click_copy">
                  Enter information to copy HOTEL. And Click the "COPY" button.
                </p>
              </div>
            </div>
            <div className="copyhotel_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="copyhotel_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_name">Name</p>
                      </div>
                      <div className="detail_cell content hotel_name">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          data-detect-placeholder="admin_hotel_add_placeholder_hotel_name"
                          placeholder="Hotel Name"
                          autoComplete="off"
                          value={this.state.name}
                          onChange={this.onChangeName}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_address">
                          Address
                        </p>
                      </div>
                      <div className="detail_cell content hotel_address">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          data-detect-placeholder="admin_hotel_add_placeholder_hotel_address"
                          placeholder="Hotel Address"
                          autoComplete="off"
                          value={this.state.address}
                          onChange={this.onChangeAddress}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_email">Email</p>
                      </div>
                      <div className="detail_cell content hotel_email">
                        <input
                          type="text"
                          id="email"
                          name="email"
                          data-detect-placeholder="admin_hotel_add_placeholder_staff_email"
                          placeholder="Staff E-mail"
                          autoComplete="off"
                          value={this.state.email}
                          onChange={this.onChangeEmail}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_password">
                          Password
                        </p>
                      </div>
                      <div className="admin_hotel_add_label_password">
                        <input
                          type="password"
                          id="password"
                          name="password"
                          data-detect-placeholder="admin_hotel_add_placeholder_staff_password"
                          placeholder="Staff Password"
                          autoComplete="off"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_add_label_confirm_password">
                          Password Confirm
                        </p>
                      </div>
                      <div className="detail_cell content hotel_con_password">
                        <input
                          type="password"
                          id="confirmPwd"
                          name="confirmPwd"
                          data-detect-placeholder="admin_hotel_add_placeholder_staff_confirm_password"
                          placeholder="Staff Confirm Password"
                          autoComplete="off"
                          value={this.state.confirmPwd}
                          onChange={this.onChangePwdConfirm}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_hotel_add_label_logo"
                      >
                        Logo
                      </div>
                      <div
                        style={{
                          display:
                            this.state.logoFile &&
                            this.state.logoFile instanceof Blob
                              ? "block"
                              : "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <div style={imgBoxStyle}>
                          {this.state.logoFile &&
                            this.state.logoFile instanceof Blob && (
                              <div className="img__box"></div>
                            )}
                          <div
                            className="file_upload_button_container"
                            style={{ display: "flex" }}
                          >
                            <form>
                              <label
                                htmlFor="logoFile"
                                className="file_upload_button"
                              >
                                Upload
                              </label>
                              <input
                                type="file"
                                id="logoFile"
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={this.onFileChange}
                                title="Upload"
                                style={{ display: "none" }} // input 요소를 화면에서 감추기
                              />
                            </form>
                            {this.state.logoText ? (
                              <p className="file_textName">
                                {this.state.logoText}
                              </p>
                            ) : (
                              <p className="file_textName">
                                {"ex) My conect.png"}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button
                type="button"
                className="gotolist_btn"
                data-detect="admin_hotel_add_button_go_to_list"
                onClick={this.cancelBtn}
              >
                Go to list
              </button>
              <button
                type="button"
                className="copy_btn"
                data-detect="admin_hotel_copy_button_copy"
                onClick={this.copy_btn}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = HotelCopyData.mapStateToProps;
let mapDispatchToProps = HotelCopyData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HotelCopyView)
);
