import CommonComponent from "../../components/CommonComponent.js";

// modules
import * as useKeyActions from "../../../redux/modules/UseKey.js";

class UseKeyLogListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      useKeyLogList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.useKeyLogList != this.props.useKeyLogList) {
      if (this.state.requestType == 'GET_USEKEY_LOG_LIST') {
        this.onGetUseKeyLogListResults(nextProps);
      }
    }
  }

  // redux에 get useKey list 요청
  requestGetUseKeyLogList = (
    pageNo,
    keyLogStatus,
    search,
    startDate,
    endDate
  ) => {
    this.setState({
      requestType: "GET_USEKEY_LOG_LIST",
    });
    this.props.onGetAll(pageNo, keyLogStatus, search, startDate, endDate);
  };

  // get transaciton list results
  onGetUseKeyLogListResults = (props) => {
    if (props.useKeyLogList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.useKeyLogList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      useKeyLogList: state.useKeyLog.useKeyLogList,
      failData: state.useKeyLog.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, keyLogStatus, search, startDate, endDate) =>
        dispatch(
          useKeyActions.onGetAllUseKeyLogList(
            pageNo,
            keyLogStatus,
            search,
            startDate,
            endDate
          )
        ),
    };

    return returnProps;
  };
}

export default UseKeyLogListData;
