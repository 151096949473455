import CommonComponent from "../../components/CommonComponent";

// modules
import * as authActions from "../../../redux/modules/Auth";
import * as paymentActions from "../../../redux/modules/Payment";

class PaymentListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      paymentList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.paymentList != this.props.paymentList) {
      if (this.state.requestType == 'GET_PAYMENT_LIST') {
        this.onGetPaymentListResults(nextProps);
      }
    }
  }



  // redux에 get payment list 요청
  requestGetPaymentList = (
    pageNo,
    startDate,
    endDate,
    hotelId,
    userName,
    search
  ) => {
    this.setState({
      requestType: "GET_PAYMENT_LIST",
    });
    this.props.onGetAll(pageNo, startDate, endDate, hotelId, userName, search);
  };

  // get payment list results
  onGetPaymentListResults = (props) => {
    if (props.paymentList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.paymentList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      paymentList: state.payment.paymentList,
      failData: state.payment.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAll: (pageNo, startDate, endDate, hotelId, userName, search) =>
        dispatch(
          paymentActions.onGetAll(
            pageNo,
            startDate,
            endDate,
            hotelId,
            userName,
            search
          )
        ),
      onDeleteOne: (paymentId) => dispatch(paymentActions.onDelete(paymentId)),
    };

    return returnProps;
  };
}

export default PaymentListData;
