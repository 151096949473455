//Common Component

import React from "react";

//modules
import * as constantActions from "../../redux/modules/Constant";

class CommonComponent extends React.Component {
  render() {
    return (
      <div>
      </div>
    )
  }

  static mapStateToProps = (state, props) => {
    return {
      MultiLanguage: state.constant.MultiLanguage,
      multiLanguageCode: state.constant.multiLanguageCode
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      showSimpleTopPopupDialog: (dialogContent, closeFunction) => dispatch(constantActions.showTopPopupDialog("OK", dialogContent, () => { }, () => { }, closeFunction)),
      showTopPopupDialog: (isShowDialog, dialogType, dialogContent, positiveFunction, negativeFunction, closeFunction
        ) => dispatch( constantActions.showTopPopupDialog(isShowDialog, dialogType, dialogContent, positiveFunction, negativeFunction, closeFunction)),
      hideTopPopupDialog: () => dispatch( constantActions.hideTopPopupDialog()),
      occuredCommonError: (code,description) => dispatch(constantActions.occuredCommonError(code,description)),
      checkedCommonError: () => dispatch(constantActions.checkedCommonError()),
      loadMultiLanguage: () => dispatch(constantActions.loadMultiLanguage()),
      updateMultiLanguage: (multiLang) => dispatch(constantActions.updateMultiLanguage(multiLang)),
    };
  };
}

export default CommonComponent;