// HotelAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as hotelActions from "../../../redux/modules/Hotel";

class HotelAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_HOTEL") {
      this.onAddHotelResults(props);
    }
  }

  // redux에 add hotel 요청
  requestAddHotel = (
    name,
    address,
    email,
    password,
    confirmPwd,
    logoFile,
    logoText
  ) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("address", address);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("con_password", confirmPwd);
    formData.append("logoFile", logoFile);
    formData.append("logoText", logoText);

    this.setState({
      requestType: "ADD_HOTEL",
    });
    this.props.onAdd(formData);
  };

  // get add hotel results
  onAddHotelResults = (props) => {
    if (props.hotelAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.hotelAdd?.success == true) {
      // 성공
      this.props.history.push("/hotelListPage");
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(props.hotelAdd.reason, () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      hotelAdd: state.hotel.hotelAdd,
      failData: state.hotel.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (formData) => dispatch(hotelActions.onAdd(formData)),
    };
  };
}

export default HotelAddData;
