// IDRecognitionListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import IDRecognitionListData from "../List/IDRecognitionListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/idRecognition/idRecognitionList.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class IDRecognitionListView extends IDRecognitionListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      idType: "",
      search: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
      tempIdType: "",
    };
  }

  /* ---------------------------------------------------- lifecycle --------------------------------------------------- */
  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetIDRecognitionList(
        this.state.pageNo,
        this.state.search,
        this.state.idType
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- event handler ------------------------------------------------- */
  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const value = e.target.value;

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        idType: this.state.tempIdType,
      },
      () => {
        this.requestGetIDRecognitionList(this.state.pageNo, this.state.search, this.state.idType);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          idType: this.state.tempIdType,
        },
        () => {
          this.requestGetIDRecognitionList(this.state.pageNo, this.state.search, this.state.idType);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempIdType: this.state.tempIdType }, () => {
      this.requestGetIDRecognitionList(pageNo, this.state.search, this.state.idType);
    });
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id) => {
    const currentCheckedList = [...this.state.checkedList];
    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);
    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // onClickOK
  onClickOK = () => {
    const { checkedList } = this.state;

    if (checkedList.length > 0) {
      checkedList.forEach((idRecognitionId) => {
        this.requestDeleteIDRecognition(idRecognitionId);
      });

      this.setState({ checkedList: [] });
    }
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkIDRecognitionDetail(id);
    }
  }

  // delete 버튼 클릭시
  deleteSelectedIDRecognitions = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteIDRecognition();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (idRecognitionList) => {
    const { checkedList } = this.state;
    const allIDRecognitionCnt = idRecognitionList.length;
    const isChecked = allIDRecognitionCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : idRecognitionList.map((idRecognition) => idRecognition.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  // 파람값 url로 전달
  linkIDRecognitionDetail = (idRecognitionId) => {
    this.props.history.push(`/idRecognitionDetailPage?idRecognitionId=${idRecognitionId}`);
  };

  //insert
  linkIDRecognitionAdd = () => {
    this.props.history.push("/idRecognitionAddPage");
  };

  /* ------------------------------------------------------- etc ------------------------------------------------------ */
  // delete 요청하기
  deleteIDRecognition = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.setState({ isShowAllLoading: true });
      this.requestDeleteIDRecognition(checkedList[0]);
    }
  };

  render() {
    let count;
    let idRecognitionList = [];

    if (this.props.idRecognitionList != null && this.props.idRecognitionList?.success == true) {
      count = this.props.idRecognitionList.results.count;
      idRecognitionList = this.props.idRecognitionList.results.rows;
    }

    return (
      <div className="idRecognitionList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="id_recognition_list_container">
          <div className="id_recognition_list_container_inner">
            <div className="title" data-detect="admin_id_recognition_information_list_title">
              REGION LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select id="id_type_select" className="search_select" name="id_type_select" onChange={(e) => this.onChangeValue(e, "tempIdType")} value={this.state.tempIdType}>
                      <option data-detect="admin_id_recognition_information_list_select_id_type_all" value="">
                        ID Type ( ALL )
                      </option>
                      <option data-detect="admin_id_recognition_information_list_select_id_type_registration" value="Registration">
                        Registration
                      </option>
                      <option data-detect="admin_id_recognition_information_list_select_id_type_passport" value="Passport">
                        Passport
                      </option>
                      <option data-detect="admin_id_recognition_information_list_select_id_type_driver_license" value="DriverLicense">
                        Driver License
                      </option>
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_id_recognition_information_list_placeholder_search"
                      placeholder="Country Name"
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- idRecognition list table  --> */}
            <div className="id_recognition_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(idRecognitionList, this.state.pageNo)}
                        checked={idRecognitionList && this.state.checkedList.length == idRecognitionList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_id_recognition_information_list_label_country_name">
                      Country Name
                    </div>
                    <div className="cell" data-detect="admin_id_recognition_information_list_label_id_type">
                      ID Type
                    </div>
                    <div className="cell" data-detect="admin_id_recognition_information_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {idRecognitionList?.length > 0 ? (
                    idRecognitionList.map((idRecognition, index) => (
                      <div className="row tbodyRow" key={idRecognition.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={idRecognition.id}
                            id={idRecognition.id}
                            onClick={() => this.onClickInput(idRecognition.id)}
                            checked={this.state.checkedList.includes(idRecognition.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={idRecognition.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(idRecognition.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {idRecognition.countryName}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(idRecognition.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {idRecognition.idType}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(idRecognition.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(idRecognition.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_id_recognition_information_list_guide_empty"></div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedIDRecognitions();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_id_recognition_information_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkIDRecognitionAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_id_recognition_information_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = IDRecognitionListData.mapStateToProps;
let mapDispatchToProps = IDRecognitionListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IDRecognitionListView)
);
