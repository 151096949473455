import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import CountryDetailData from "../Detail/CountryDetailData";

//css
import "../../../css/common.css";
import "../../../css/location/country/countryDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class CountryDetailView extends CountryDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      countryId: "",
      countryDetail: null,
    };
  }

  // 첫 실행시 URL에서 countryId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const countryId = params.get("countryId");
    this.setState({
      countryId: countryId,
    });
    this.requestGetCountryOne(countryId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkCountryEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const countryId = params.get("countryId");
    this.props.history.push(`/countryEditPage?countryId=${countryId}`);
  };



  render() {
    let countryDetail = null;

    if (
      this.props.countryDetail != null &&
      this.props.countryDetail?.success == true
    ) {
      countryDetail = this.props.countryDetail.results;
    }

    return (
      <div className="countryDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="countryinfo_detail_container">
          <div className="countryinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_header_link_country_list"
            >
              country Information
            </div>
            <div className="countryinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="country_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_country_list_label_region"
                      >
                        Region
                      </div>
                      <div
                        className="detail_cell content country_name"
                        style={{ color: "white" }}
                      >
                        <span id="country_id">
                          {countryDetail?.hotelRegion.name??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_country_list_label_country"
                      >
                        Country
                      </div>
                      <div
                        className="detail_cell content country_name"
                        style={{ color: "white" }}
                      >
                        <span id="country_id">
                          {countryDetail?.name??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_endpoint_list_label_created_at"
                      >
                        Created At
                      </div>
                      <div
                        className="detail_cell content country_name"
                        style={{ color: "white" }}
                      >
                        {countryDetail ?
                          formatDateTimeDot(countryDetail.created_at) : "-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_terms_detail_label_updated_at"
                      >
                        Updated At
                      </div>
                      <div
                        className="detail_cell content country_name"
                        style={{ color: "white" }}
                      >
                        {countryDetail ?
                          formatDateTimeDot(countryDetail.updated_at) : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_country_detail_button_go_to_list"
                >
                  {" "}
                  Go to List{" "}
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkCountryEdit();
                  }}
                  data-detect="admin_country_detail_button_edit"
                >
                  {" "}
                  Edit{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CountryDetailData.mapStateToProps;
let mapDispatchToProps = CountryDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CountryDetailView)
);
