import CommonComponent from "../../components/CommonComponent";

// Modules
import * as HotelAppDownloadActions from "../../../redux/modules/HotelAppDownload";

class HotelAppDownloadListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentWillReceiveProps(nextProps) {
    if (nextProps.hotelAppDownloadIOSList != this.props.hotelAppDownloadIOSList) {
      if (this.state.requestType == 'GET_HOTEL_APP_DOWNLOAD_IOS') {
        this.onGetHotelAppDownloadIOSResults(nextProps);
      }
    }
    if (nextProps.hotelAppDownloadAOSList != this.props.hotelAppDownloadAOSList) {
      if (this.state.requestType == 'GET_HOTEL_APP_DOWNLOAD_AOS') {
        this.onGetHotelAppDownloadAOSResults(nextProps);
      }
    }
  }

  /* ----------------------------------------------------- Request ---------------------------------------------------- */
  requestGetHotelAppDownloadIOS = () => {
    // onGetIOS 요청
    this.setState({
      requestType: "GET_HOTEL_APP_DOWNLOAD_IOS",
    });
    this.props.onGetIOS();
  };

  requestGetHotelAppDownloadAOS = () => {
    // onGetAOS 요청
    this.setState({
      requestType: "GET_HOTEL_APP_DOWNLOAD_AOS",
    });
    this.props.onGetAOS();
  };

  /* ------------------------------------------------- Result Handling ------------------------------------------------ */
  // onGetIOS 결과
  onGetHotelAppDownloadIOSResults = (props) => {
    if (props.hotelAppDownloadIOSList === null && props.failData === null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });


    if (props.hotelAppDownloadIOSList && props.hotelAppDownloadIOSList.success === true) {
      // 성공
    } else if (props.failData?.code !== "INVALID_TOKEN") {
      this.props.showSimpleTopPopupDialog(
        this.props.MultiLanguage["language"][this.props.multiLanguageCode][
          "contents_failed_get_list"
        ],
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };
  
  // onGetAOS 결과
  onGetHotelAppDownloadAOSResults = (props) => {
    if (props.hotelAppDownloadAOSList === null && props.failData === null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.hotelAppDownloadAOSList && props.hotelAppDownloadAOSList.success === true) {
      // 성공
    } else if (props.failData?.code !== "INVALID_TOKEN") {
      this.props.showSimpleTopPopupDialog(
        this.props.MultiLanguage["language"][this.props.multiLanguageCode][
          "contents_failed_get_list"
        ],
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };
  
  onFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };
  
  /* -------------------------------------------------- Connect Redux ------------------------------------------------- */
  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      hotelAppDownloadIOSList: state.hotelAppDownload.hotelAppDownloadIOSList,
      hotelAppDownloadAOSList: state.hotelAppDownload.hotelAppDownloadAOSList,
      failData: state.hotelAppDownload.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetIOS: () => dispatch(HotelAppDownloadActions.onGetIOS()),
      onGetAOS: () => dispatch(HotelAppDownloadActions.onGetAOS()),
    };

    return returnProps;
  };
}

export default HotelAppDownloadListData;
