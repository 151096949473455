import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as authDataApis from "../lib/authData_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const AUTHDATA_GET_ALL = "authData/AUTHDATA_GET_ALL";

// 액션 생성자
export const onGetAllAuthDataList = createAction(AUTHDATA_GET_ALL, authDataApis.onGetAllAuthDataList);


// 초기 상태
const initialState = {
    authDataList: [],
    failData: null,
};

export default handleActions(
    {
        ...pender({
            type: AUTHDATA_GET_ALL,
            onPending: (state, action) => {
                return { ...state, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, authDataList: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, authDataList: [], failData: action.payload.response?.data };
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
