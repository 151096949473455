import CommonComponent from "../../components/CommonComponent";

// modules
import * as settingActions from "../../../redux/modules/Setting";



class SettingEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      settingList: this.props.settingList,
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_UPDATE_LIST") {
      this.onUpdateListResults(props);
    }
  }

  // redux에 edit setting 요청
  requestEditSetting = (id, coordinateDistance) => {
    this.setState({
      requestType: "GET_UPDATE_LIST",
    });
    this.props.onUpdate(id, coordinateDistance);
  };

  // update list
  onUpdateListResults = (props) => {
    if (props.updateList == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.updateList?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_upload_file"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    this.props.history.push("/settingListPage");
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //업데이트 눌렀을때 전달
  onEditBtn = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editSetting();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editSetting = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestEditSetting(this.props.settingList.results.id, this.state.coordinateDistance);
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //뒤로가기 버튼
  onBackBtn = () => {
    this.props.history.push("/settingListPage");
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      settingList: state.setting.settingList,
      updateList: state.setting.updateList,
      failData: state.setting.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: () => dispatch(settingActions.onGetAll()),
      onUpdate: (id, coordinateDistance) => dispatch(settingActions.onUpdate(id, coordinateDistance)),
    };
    return returnProps;
  };
}

export default SettingEditData;
