import CommonComponent from "../../components/CommonComponent";

// modules
import * as facilityImageActions from "../../../redux/modules/FacilityImage";

class FacilityImageListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      facilityImageList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.facilityImageList != this.props.facilityImageList) {
      if (this.state.requestType == 'GET_FACILITY_IMAGE_LIST') {
        this.onGetFacilityImageListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_FACILITY_IMAGE_ONE") {
      this.onDeleteFacilityImageListResults(nextProps);
    }
  }


  // redux에 get facilityImage list 요청
  requestGetFacilityImageList = (pageNo) => {
    this.setState({
      requestType: "GET_FACILITY_IMAGE_LIST",
    });
    this.props.onGetAll(pageNo);
  };
  // redux에 순차적으로 delete facilityImage 요청
  requestDeleteFacilityImage = (facilityImageId) => {
    this.setState({
      requestType: "DELETE_FACILITY_IMAGE_ONE",
    });
    this.props.onDeleteOne(facilityImageId);
  };

  // get facilityImage list results
  onGetFacilityImageListResults = (props) => {
    if (props.facilityImageList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.facilityImageList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // onDeleteFacilityImageListResults 함수 수정
  onDeleteFacilityImageListResults(props) {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteFacilityImage();
        } else {
          // 수정이 완료된 후에 페이지 이동
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetFacilityImageList(this.state.pageNo);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };


  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, facilityImageList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  deleteSelectedFacilityImages = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteFacilityImage();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteFacilityImage = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.requestDeleteFacilityImage(checkedList[0]);
    }
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/facilityImageListPage");
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (facilityImageList) => {
    const { checkedList } = this.state;
    const allFacilityImageCnt = facilityImageList.length;
    const isChecked = allFacilityImageCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : facilityImageList.map((facilityImage) => facilityImage.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  //copy facilityImage
  copyFacilityImage = () => {
    const { checkedList } = this.state;

    if (checkedList.length == 1) {
      const copyId = checkedList[0];
      this.props.history.push(`/facilityImagecopyPage?copyId=${copyId}`);
    } else if (checkedList.length > 1) {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_only_one_facilityImage_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
      //초기화
      this.setState({
        checkedList: [],
      });
    } else {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_facilityImage_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      facilityImageList: state.facilityImage.facilityImageList,
      failData: state.facilityImage.failData,
      successDelete: state.facilityImage.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo) => dispatch(facilityImageActions.onGetAllFacilityImage(pageNo)),
      onDeleteOne: (facilityImageId) => dispatch(facilityImageActions.onDeleteFacilityImage(facilityImageId)),
    };

    return returnProps;
  };
}

export default FacilityImageListData;
