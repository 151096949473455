import React, { Component } from 'react';

//img
import SearchIcon from "../../../img/icon_search.png"

class SearchButton extends Component {
    render() {
        const { disabled } = this.props;

        return (
            <button className={`myConect_search_button ${disabled ? 'disabled' : ''}`} disabled={disabled}>
                <div className='myConect_buttton_subTitle'>
                    <img src={SearchIcon} style={{ width: "18px" }} alt="Search Icon" />
                </div>
            </button>
        );
    }
}

export default SearchButton;
