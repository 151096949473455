import axiosTransferInstance from "../api/transferAxios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllAuthDataList(pageNo, authenticationStatus, search, startDate, endDate) {
  var hotelId = "";
  var email = "";

  if (authenticationStatus == "hotelId") {
    hotelId = search;
  }
  if (authenticationStatus == "email") {
    email = search;
  }

  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get(
    "/admin/api/transferLog/authData?fromDate=" + startDate + "&endDate=" + endDate + "&userEmail=" + email + "&hotelId=" + hotelId + "&offset=" + offset + "&limit=" + 10
  );
}
