// PaymentListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import LogListData from "../LogList/LogListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/payment/paymentLogList.css";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class LogListView extends LogListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      paymentId: "",
      hotelId: "",
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const paymentId = params.get("paymentId");
    const hotelId = this.props.paymentDetail.results.hotel.id;
    this.setState({
      paymentId: paymentId,
      hotelId: hotelId,
    });
    this.requestGetLogtList(paymentId, hotelId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkPaymentLogDetail(id);
    }
  }

  //파람값 url로 전달
  linkPaymentLogDetail = (logId) => {
    this.props.history.push(`/paymentLogDetailPage?logId=${logId}`);
  };



  render() {
    let logList = [];
    if (this.props.logList && this.props.logList?.success == true) {
      logList = this.props.logList.results.rows;
    }

    return (
      <div className="logList">
        {this.state.isShowAllLoading && <Loading />}

        <div className="payment_log_list_container">
          <div className="payment_log_list_container_inner">
            <div className="title" data-detect="admin_payment_log_list_title">
              PAYMENT LOG LIST
            </div>
            <div className="payment_log_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div
                      id="theadFistCell"
                      className="cell"
                      data-detect="admin_payment_log_list_label_no"
                    >
                      NO.
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_payment_log_list_label_type"
                    >
                      Type
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_payment_log_list_label_method"
                    >
                      Method
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_payment_log_list_label_path"
                    >
                      Path
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_payment_log_list_label_status"
                    >
                      Status
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_payment_log_list_label_success"
                    >
                      Success
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_payment_log_list_label_created_at"
                    >
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {logList.map((log, index) => (
                    <div
                      className="row"
                      key={index}
                      id={log.id}
                      onMouseUp={() => this.handleMouseUp(log.id)}
                      onMouseMove={this.handleMouseMove}
                      onMouseDown={this.handleMouseDown}
                    >
                      <div className="cell">
                        <div className="eachIndexNo">
                          <span>{log.id}</span>
                        </div>
                      </div>
                      <div className={`cell ${log.type}`}>{log.type}</div>
                      <div className={`cell ${log.method}`}>{log.method}</div>
                      <div className="cell" title={log.path}>
                        {log.path}
                      </div>
                      <div className="cell">{log.status}</div>
                      <div className="cell">
                        {log.type == "RESPONSE"
                          ? (() => {
                              const includeTrue = log.body.indexOf("true");
                              const includeFalse = log.body.indexOf("false");
                              if (includeTrue == -1 && includeFalse == -1) {
                                return "-";
                              } else if (
                                (includeTrue == -1
                                  ? Number.MAX_SAFE_INTEGER
                                  : includeTrue) <
                                (includeFalse == -1
                                  ? Number.MAX_SAFE_INTEGER
                                  : includeFalse)
                              ) {
                                return "true";
                              } else {
                                return "false";
                              }
                            })()
                          : "-"}
                      </div>
                      <div className="cell">
                        <span>
                          {log && formatDateTimeSlash(log.created_at)}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="button_container">
            <div className="gotolist_btn_container">
              <button
                className="gotolist_btn"
                onClick={() => history.back()}
                data-detect="admin_payment_log_list_button_go_to_detail"
              >
                Go to detail
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = LogListData.mapStateToProps;
let mapDispatchToProps = LogListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogListView)
);
