import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import ApplicationEndPointDetailData from "../Detail/ApplicationEndPointDetailData";

//css
import "../../css/applicationEndPoint/applicationEndPointDetail.css";
import "../../css/common.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class ApplicationEndPointDetailView extends ApplicationEndPointDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      applicationEndPointId: "",
      applicationEndPointDetail: null,
    };
  }

  // 첫 실행시 URL에서 applicationEndPointId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const applicationEndPointId = params.get("applicationEndPointId");
    this.setState({
      applicationEndPointId: applicationEndPointId,
    });
    this.requestGetApplicationEndPointOne(applicationEndPointId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  onDeleteApplicationEndPoints = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.requestDeleteApplicationEndPoint(this.state.applicationEndPointId);
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      } //cancel 버튼시
    );
  };

  //edit page
  linkApplicationEndPointEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const applicationEndPointId = params.get("applicationEndPointId");
    this.props.history.push(`/applicationEndPointEditPage?applicationEndPointId=${applicationEndPointId}`);
  };

  render() {
    let applicationEndPointDetail = null;

    if (this.props.applicationEndPointDetail != null && this.props.applicationEndPointDetail?.success == true) {
      applicationEndPointDetail = this.props.applicationEndPointDetail.results;
    }

    return (
      <div className="applicationEndPointDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="applicationEndPointinfo_detail_container">
          <div className="applicationEndPointinfo_detail_container_inner">
            <div className="title_container" data-detect="admin_endpoint_detail_title">
              APPLICATION ENDPOINT INFORMATION
            </div>
            <div className="applicationEndPointinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="applicationEndPoint_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_detail_label_package_name">
                        Package Name
                      </div>
                      <div className="detail_cell content region_name" style={{ color: "white" }}>
                        <span id="region_id">{applicationEndPointDetail?.packageName??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_detail_label_os">
                        OS
                      </div>
                      <div className="detail_cell content country_version" style={{ color: "white" }}>
                        <span id="country_id">{applicationEndPointDetail?.os??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_detail_label_version">
                        Version
                      </div>
                      <div className="detail_cell content applicationEndPoint_url" style={{ color: "white" }}>
                        <span id="country_id">{applicationEndPointDetail?.version??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_detail_label_url">
                        URL
                      </div>
                      <div className="detail_cell content applicationEndPoint_url" style={{ color: "white" }}>
                        <span id="country_id">{applicationEndPointDetail?.url??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_endpoint_list_label_created_at">
                        Created At
                      </div>
                      <div className="detail_cell content created_at" style={{ color: "white" }}>
                        {applicationEndPointDetail && formatDateTimeDot(applicationEndPointDetail && applicationEndPointDetail.created_at)}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_terms_detail_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content updated_at" style={{ color: "white" }}>
                        {applicationEndPointDetail && formatDateTimeDot(applicationEndPointDetail.updated_at)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_endpoint_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
              <div className="delete_btn_container">
                <button className="delete_btn" data-detect="admin_endpoint_detail_button_delete" onClick={this.onDeleteApplicationEndPoints}>
                  Delete
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkApplicationEndPointEdit();
                  }}
                  data-detect="admin_endpoint_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationEndPointDetailData.mapStateToProps;
let mapDispatchToProps = ApplicationEndPointDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationEndPointDetailView));
