import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import UserTransferLogDetailInfoData from "../LogDetail/userTransferLogHistoryDetailInfoData";

//css
import "../../../css/common.css";
import "../../../css/transfer/userTransferLogHistory/userTransferLogHistoryDetailInfo.css";

// utils
import { changeEmptyValueToDash, formatDateTimeDot, prettyPrint } from "../../../common/Utils";

class UserTransferLogInfoDetailView extends UserTransferLogDetailInfoData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      userTransferId: "",
    };
  }

  // 첫 실행시 URL에서 userTransferId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const userTransferId = params.get("userTransferId");
    this.setState({
      userTransferId: userTransferId,
      isShowAllLoading: false,
    });
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let userTransferLogHistoryDetail = null;

    if (this.props.userTransferLogHistoryDetail != null && this.props.userTransferLogHistoryDetail?.success == true) {
      const userTransferId = this.state.userTransferId;
      const rows = this.props.userTransferLogHistoryDetail.results.rows;

      const filteredItems = rows.filter((item) => item.id == userTransferId);

      if (filteredItems.length > 0) {
        userTransferLogHistoryDetail = changeEmptyValueToDash(filteredItems[0]);
      }
    }

    return (
      <div className="userTransferLogInfoDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="userTransfer_detail_container">
          <div className="userTransfer_detail_innder">
            <div className="title" data-detect="admin_user_transfer_log_detail_title">
              User Transfer Detail
            </div>
            <div className="userTransfer_container">
              <div className="userTransfer_title_container"></div>
              <div className="userTransfer_info_container">
                <div className="userTransfer_info_table">
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_id"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span>{userTransferLogHistoryDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_type"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span> {userTransferLogHistoryDetail?.type??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_method"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span> {userTransferLogHistoryDetail?.method??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_path"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span> {userTransferLogHistoryDetail?.path??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_header"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogHistoryDetail?.header)}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_param"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogHistoryDetail?.param)}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_body"></span>
                    </div>
                    <div className="userTransfer_info_cell content user_transfer_info_body">
                      <span className="format">{prettyPrint(userTransferLogHistoryDetail?.body)}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_soap"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogHistoryDetail?.soap)}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_status"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span>{(userTransferLogHistoryDetail?.status == "" || userTransferLogHistoryDetail?.status)  && "-"}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_session"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogHistoryDetail?.session)}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_token"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogHistoryDetail?.token)}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_user_email"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span>{(userTransferLogHistoryDetail?.userEmail == "" || userTransferLogHistoryDetail?.userEmail) && "-"}</span>
                    </div>
                  </div>
                  <div className="userTransfer_info_row">
                    <div className="userTransfer_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_created_at"></span>
                    </div>
                    <div className="userTransfer_info_cell content">
                      <span> {(userTransferLogHistoryDetail ? formatDateTimeDot(userTransferLogHistoryDetail.created_at) : "-")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_transaction_log_detail_button_go_to_list"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserTransferLogDetailInfoData.mapStateToProps;
let mapDispatchToProps = UserTransferLogDetailInfoData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTransferLogInfoDetailView));
