// TermsGroupListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import TermsGroupListData from "../List/TermsGroupListData";


//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/termsAndCondition/TermsGroup/TermsGroupList.css";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class TermsGroupListView extends TermsGroupListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
    };
  }

  //초기값 pageNo=1 , content=null;
  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetTermsGroupList(
        this.state.pageNo,
        this.state.search,
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
      },
      () => {
        this.requestGetTermsGroupList(this.state.pageNo, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;

    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
        },
        () => {
          this.requestGetTermsGroupList(this.state.pageNo, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리

    this.setState(
      {
        isShowAllLoading: true,
        tempSearch: this.state.search,
      },
      () => {
        // 페이지 클릭했을 때 넘어가는 로직
        this.requestGetTermsGroupList(pageNo, this.state.search); // 지역 목록 요청
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkTermsGroupDetail(id);
    }
  }

  // //파람값 url로 전달
  linkTermsGroupDetail = (termsGroupId) => {
    this.props.history.push(
      `/termsGroupDetailPage?termsGroupId=${termsGroupId}`
    );
  };

  //insert
  linkTermsGroupAdd = () => {
    this.props.history.push("/termsGroupAddPage");
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  render() {
    let count;
    let termsGroupList = [];
    if (
      this.props.termsGroupList.length !== 0 &&
      this.props.termsGroupList?.success == true
    ) {
      count = this.props.termsGroupList.results.count;
      termsGroupList = this.props.termsGroupList.results.rows;
    }

    return (
      <div className="termsGroupList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="termsGroup_list_container">
          <div className="termsGroup_list_container_inner">
            <div className="title" data-detect="admin_terms_group_list_title">
              TTERMS GROUP LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_terms_group_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- termsGroup list table  --> */}
            <div className="termsGroup_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(termsGroupList, this.state.pageNo)}
                        checked={termsGroupList && this.state.checkedList.length == termsGroupList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_terms_group_list_label_title">
                      Title
                    </div>
                    <div className="cell" data-detect="admin_terms_group_list_label_country_code">
                      Country Code
                    </div>
                    <div className="cell" data-detect="admin_terms_group_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {termsGroupList?.length > 0 ? (
                    termsGroupList.map((termsGroup, index) => (
                      <div className="row tbodyRow" key={termsGroup.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={termsGroup.id}
                            id={termsGroup.id}
                            onClick={() => this.onClickInput(termsGroup.id, termsGroupList)}
                            checked={this.state.checkedList.includes(termsGroup.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={termsGroup.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(termsGroup.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {termsGroup.title}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(termsGroup.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {termsGroup.countryCode}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(termsGroup.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(termsGroup.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_terms_group_list_guide_empty"></div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedTermsGroups();
                  }}
                >
                  <span data-detect="admin_terms_group_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkTermsGroupAdd();
                  }}
                >
                  <span data-detect="admin_terms_group_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsGroupListData.mapStateToProps;
let mapDispatchToProps = TermsGroupListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TermsGroupListView)
);
