//CountryAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class CountryDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_COUNTRY_ONE") {

      this.onGetCountryOneResults(props);
    }
  }

  // redux에 get country Detail 요청
  requestGetCountryOne = (countryId) => {
    this.setState({
      requestType: "GET_COUNTRY_ONE",
    });
    this.props.onGetOne(countryId);
  };

  // get country One results
  onGetCountryOneResults = (props) => {
    if (props.countryDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.countryDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_country_detail"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      countryDetail: state.location.countryDetail,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (countryId) => dispatch(locationActions.onGetOneCountry(countryId)),
    };
  };
}

export default CountryDetailData;
