import React from "react";
import { connect } from "react-redux";
import UserInfoEditData from "../Edit/UserInfoEditData";

//css
import "../../css/common.css";
import "../../css/userInfo/myInfoEdit.css";

//image
import department from "../../img/icon_company.svg";
import email from "../../img/icon_mail.svg";
import name from "../../img/icon_name.svg";

class MyInfoEditView extends UserInfoEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      name: "",
      department: "",
      userInfoId: "",
    };
  }

  // 첫 실행시 URL에서 userInfoId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const userInfoId = params.get("userInfoId");
    this.setState({
      userInfoId: userInfoId,
      name: this.props.userInfoDetail.results.name,
      department: this.props.userInfoDetail.results.department,
    });
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //name 값 변경
  onChangeName = (e) => {
    var inputValue = e.target.value;
    this.setState({
      name: inputValue,
    });
  };
  //department 값 변경
  onChangeDepartment = (e) => {
    var inputValue = e.target.value;
    this.setState({
      department: inputValue,
    });
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/userInfoListPage");
  };

  // edit info
  onSubmit = () => {
    this.requestEditMyInfo(
      this.state.userInfoId,
      this.state.name,
      this.state.department
    );
  };



  render() {
    let userInfoDetail = null;

    if (
      this.props.userInfoDetail != null &&
      this.props.userInfoDetail?.success == true
    ) {
      userInfoDetail = this.props.userInfoDetail.results;
    }

    return (
      <div className="myInfoEdit">
        {/* {this.state.isShowAllLoading && <Loading />} */}
        {userInfoDetail !== null && (
          <div className="myInfo_detail_container">
            <div className="myInfo_detail_innder">
              <div className="title" data-detect="admin_admin_user_edit_title">
                EDIT ADMIN
              </div>
              <div className="sub_text">
                <p data-detect="admin_admin_user_edit_guide_edit_or_leave">
                  You can edit your information except e-mail
                </p>
              </div>
              <div className="myInfo_container">
                <div className="myInfo_title_container"></div>
                <div className="myInfo_info_container">
                  <div className="myInfo_info_table">
                    <div className="myInfo_info_row">
                      <div className="myInfo_info_cell index">
                        <img
                          src={email}
                          alt="email icon"
                          className="index-image"
                        />
                      </div>
                      <div
                        className="myInfo_info_cell content"
                        style={{ color: "grey" }}
                      >
                        <span>{userInfoDetail.email}</span>
                      </div>
                    </div>
                    <div className="myInfo_info_row">
                      <div className="myInfo_info_cell index">
                        <img
                          src={name}
                          alt="email icon"
                          className="index-image"
                        />
                      </div>
                      <div className="myInfo_info_cell content">
                        <input
                          type="text"
                          value={this.state.name}
                          onChange={this.onChangeName}
                        />
                      </div>
                    </div>
                    <div className="myInfo_info_row">
                      <div className="myInfo_info_cell index">
                        <img
                          src={department}
                          alt="email icon"
                          className="index-image"
                        />
                      </div>
                      <div className="myInfo_info_cell content">
                        <input
                          type="text"
                          value={this.state.department}
                          onChange={this.onChangeDepartment}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* button */}
              <div className="button_container">
                <div className="gotolist_btn_container">
                  <button
                    className="gotolist_btn"
                    onClick={this.onBackBtn}
                    data-detect="admin_hotel_detail_button_go_to_list"
                  ></button>
                </div>
                <div className="edit_btn_container">
                  <button
                    className="edit_btn"
                    onClick={this.onSubmit}
                    data-detect="admin_admin_user_edit_button_save"
                  ></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let mapStateToProps = UserInfoEditData.mapStateToProps;
let mapDispatchToProps = UserInfoEditData.mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MyInfoEditView);
