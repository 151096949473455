// ApplicationTranslationListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import ApplicationTranslationListData from "../List/ApplicationTranslationListData";

//css
import "../../css/applicationTranslation/applicationTranslationList.css";
import "../../css/common.css";
import "../../css/common/myConectTable.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class ApplicationTranslationListView extends ApplicationTranslationListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      appType: "",
      osType: "",
      appVersion: "",
      search: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
      tempAppType: "",
      tempOSType: "",
      tempAppVersion: "",
    };
  }

  /* ---------------------------------------------------- lifecycle --------------------------------------------------- */
  //초기값 pageNo=1 , search=null;
  componentDidMount() {
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetApplicationTranslationList(this.state.pageNo, this.state.appType, this.state.osType, this.state.appVersion, this.state.search);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- event handler ------------------------------------------------- */

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = e.target.value;

    this.setState({ [stateName]: value });
  };

  //검색 버튼을 누르면
  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        appType: this.state.tempAppType,
        osType: this.state.tempOSType,
        appVersion: this.state.tempAppVersion,
      },
      () => {
        this.requestGetApplicationTranslationList(this.state.pageNo, this.state.appType, this.state.osType, this.state.appVersion, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: tempSearch,
          appType: tempAppType,
          osType: tempOSType,
          appVersion: tempAppVersion,
        },
        () => {
          this.requestGetApplicationTranslationList(this.state.pageNo, this.state.appType, this.state.osType, this.state.appVersion, this.state.search);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempAppType: this.state.appType, tempOSType: this.state.osType, tempAppVersion: this.state.appVersion }, () => {
      this.requestGetApplicationTranslationList(pageNo, this.state.appType, this.state.osType, this.state.appVersion, this.state.search);
    });
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // onClickOK
  onClickOK = () => {
    const { checkedList } = this.state;

    if (checkedList.length > 0) {
      checkedList.forEach((applicationTranslationId) => {
        this.requestDeleteApplicationTranslation(applicationTranslationId);
      });
      this.setState({ checkedList: [] });
    }
  };

  deleteSelectedAppTranslation = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteApplicationTranslation();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (applicationTranslationList) => {
    const { checkedList } = this.state;
    const allApplicationTranslationCnt = applicationTranslationList.length;
    const isChecked = allApplicationTranslationCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : applicationTranslationList.map((applicationTranslation) => applicationTranslation.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if (isMoved) return;

    this.setState({
      isMoved: true,
    });
  };

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false,
    });
  };

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if (isMoved) {
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkApplicationTranslationDetail(id);
    }
  };

  // //파람값 url로 전달
  linkApplicationTranslationDetail = (applicationTranslationId) => {
    this.props.history.push(`/applicationTranslationDetailPage?applicationTranslationId=${applicationTranslationId}`);
  };

  //insert
  linkApplicationTranslationAdd = () => {
    this.props.history.push("/applicationTranslationAddPage");
  };

  /* ------------------------------------------------------- etc ------------------------------------------------------ */

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState(
      {
        pageNo: newPageNo,
        checkedList: [],
      },
      () => {
        history.replace(location.pathname, this.state);
      }
    );
  };

  deleteApplicationTranslation = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.requestDeleteApplicationTranslation(checkedList[0]);
    }
  };

  render() {
    let count;
    let applicationTranslationList = [];

    if (this.props.applicationTranslationList != null && this.props.applicationTranslationList?.success == true) {
      applicationTranslationList = this.props.applicationTranslationList.results.rows;
      count = this.props.applicationTranslationList.results.count;
    }

    return (
      <div className="applicationTranslationList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="application_translation_list_container">
          <div className="application_translation_list_container_inner">
            <div className="title" data-detect="admin_application_translation_list_title">
              APPLICATION TRANSLATION LIST
            </div>

            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select id="application_type_select" className="search_select" name="application_type_select" onChange={(e) => this.onChangeValue(e, "tempAppType")} value={this.state.tempAppType}>
                      <option data-detect="admin_application_translation_list_select_application_type" value="">
                        Application Type ( ALL )
                      </option>
                      <option data-detect="admin_application_translation_list_select_application_type_guest" value="GUEST">
                        Guest
                      </option>
                      <option data-detect="admin_application_translation_list_select_application_type_staff" value="STAFF">
                        Staff
                      </option>
                      <option data-detect="admin_application_translation_list_select_application_type_parking_staff" value="PARKING_STAFF">
                        Parking Staff
                      </option>
                    </select>
                  </div>
                  <div className="find">
                    <select id="os_type_select" className="search_select" name="os_type_select" onChange={(e) => this.onChangeValue(e, "tempOSType")} value={this.state.tempOSType}>
                      <option data-detect="admin_application_translation_list_select_os_type" value="">
                        OS Type ( ALL )
                      </option>
                      <option data-detect="admin_application_translation_list_select_os_type_aos" value="AOS">
                        AOS
                      </option>
                      <option data-detect="admin_application_translation_list_select_os_type_ios" value="iOS">
                        iOS
                      </option>
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="application_version_input"
                      data-detect-placeholder="admin_application_translation_list_placehoder_application_version"
                      placeholder="Application Version"
                      onChange={(e) => this.onChangeValue(e, "tempAppVersion")}
                      value={this.state.tempAppVersion}
                      onKeyUp={this.handleKeyUp}
                    />
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="file_name_input"
                      data-detect-placeholder="admin_application_translation_list_placehoder_file_name"
                      placeholder="File Name"
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                      value={this.state.tempSearch}
                      onKeyUp={this.handleKeyUp}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            <div className="application_translation_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(applicationTranslationList, this.state.pageNo)}
                        checked={applicationTranslationList && this.state.checkedList.length == applicationTranslationList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_application_translation_list_label_application_type">
                      Application Type
                    </div>
                    <div className="cell" data-detect="admin_application_translation_list_label_application_type">
                      Application Type
                    </div>
                    <div className="cell" data-detect="admin_application_translation_list_label_os">
                      OS
                    </div>
                    <div className="cell" data-detect="admin_application_translation_list_label_version">
                      Version
                    </div>
                    <div className="cell" data-detect="admin_application_translation_list_label_file_name">
                      File Name
                    </div>
                    <div className="cell" data-detect="admin_application_translation_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {applicationTranslationList?.length > 0 ? (
                    applicationTranslationList.map((appTranslation, index) => (
                      <div className="row tbodyRow" key={appTranslation.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={appTranslation.id}
                            id={appTranslation.id}
                            onClick={() => this.onClickInput(appTranslation.id, applicationTranslationList)}
                            checked={this.state.checkedList.includes(appTranslation.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={appTranslation.id}></label>
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(appTranslation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {appTranslation.applicationType}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(appTranslation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {appTranslation.osType}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(appTranslation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {appTranslation.applicationVersion}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(appTranslation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {appTranslation.version}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(appTranslation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {appTranslation.fileName}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(appTranslation.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {appTranslation && formatDateTimeSlash(appTranslation.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_application_translation_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedAppTranslation();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_application_translation_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkApplicationTranslationAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_application_translation_list_button_add">Add</span>
                </button>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationTranslationListData.mapStateToProps;
let mapDispatchToProps = ApplicationTranslationListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationTranslationListView));
