import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import ApplicationVersionAddData from "../Add/ApplicationVersionAddData";


//css
import "../../../css/common.css";
import "../../../css/version/applicationVersion/applicationVersionAdd.css";

class ApplicationAddView extends ApplicationVersionAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      os: "",
      type: "",
      version: "",
      link: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change os
  onChangeOS = (e) => {
    const inputValue = e.target.value;
    this.setState({ os: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change type
  onChangeType = (e) => {
    const inputValue = e.target.value;
    this.setState({ type: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change version
  onChangeVersion = (e) => {
    const inputValue = e.target.value;
    this.setState({ version: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change link
  onChangeLink = (e) => {
    const inputValue = e.target.value;
    this.setState({ link: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("os", "admin_version_app_down_version_add_validation_os") &&
      this.confirmField("type", "admin_version_app_down_version_add_validation_type") &&
      this.confirmField("version", "admin_version_app_down_version_add_validation_version") &&
      this.confirmField("link", "admin_version_app_down_version_add_validation_link")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  // 데이터 저장을 로딩으로 감싸기
  add_btn = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddApplicationVersion(this.state.os, this.state.type, this.state.version, this.state.link);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/applicationVersionListPage");
  };

  render() {
    return (
      <div className="addAplicationVerison">
        {this.state.isShowAllLoading && <Loading />}
        <div className="version_add_container">
          <div className="addversion_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_version_app_down_version_add_title">ADD VERSION</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addversion_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addversion_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_app_down_version_add_placeholder_os">OS</p>
                      </div>
                      <div className="detail_cell content">
                        <select id="os" className="os_select" name="os" style={{ color: "white" }} onChange={this.onChangeOS}>
                          <option disabled selected value="0" data-detect="admin_version_app_down_version_add_placeholder_os">
                            OS
                          </option>
                          <option value="iOS" data-detect="admin_version_app_down_version_add_select_ios">
                            iOS
                          </option>
                          <option value="AOS" data-detect="admin_version_app_down_version_add_select_aos">
                            AOS
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_app_down_version_add_label_type">Type</p>
                      </div>
                      <div className="detail_cell content">
                        <select id="os" className="os_select" name="os" style={{ color: "white" }} onChange={this.onChangeType}>
                          <option disabled selected value="0" data-detect="admin_version_app_down_version_add_placeholder_type">
                            Type
                          </option>
                          <option value="GUEST" data-detect="admin_version_app_down_version_add_select_guest">
                            GUEST
                          </option>
                          <option value="STAFF" data-detect="admin_version_app_down_version_add_select_staff">
                            STAFF
                          </option>
                          <option value="PARKING_STAFF" data-detect="admin_version_app_down_version_add_select_parking_staff">
                            PARKING STAFF
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_app_down_version_add_label_version">Version</p>
                      </div>
                      <div className="detail_cell content version_name">
                        <input
                          type="text"
                          id="version"
                          name="version"
                          data-detect-placeholder="admin_version_app_down_version_add_label_version"
                          autoComplete="off"
                          value={this.state.version}
                          onChange={this.onChangeVersion}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_version_app_down_version_add_label_link">Link</p>
                      </div>
                      <div className="detail_cell content version_name">
                        <input
                          type="text"
                          id="link"
                          name="link"
                          data-detect-placeholder="admin_version_app_down_version_add_label_link"
                          autoComplete="off"
                          value={this.state.link}
                          onChange={this.onChangeLink}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_version_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_version_add_button_add" onClick={this.add_btn} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationVersionAddData.mapStateToProps;
let mapDispatchToProps = ApplicationVersionAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationAddView));
