import React from "react";
import CommonComponent from "../../../components/CommonComponent";



// CSS
import "../../../css/common.css";
import "../../../css/emailForm/createEmailConfirmFailure/createEmailConfirmFailureCharacter.css";

class CreateEmailConfirmFailureCharacterView extends CommonComponent {
  constructor(props) {
    super(props);
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    return (
      <div className="createEmailContirmFailureCharacter">
        <div className="create_confirm_failure_character_container">
          <div className="create_confirm_failure_character_container_inner">
            <div className="title" data-detect="admin_email_form_create_confirm_failure_detail_title">
              CREATE CONFIRM FAILURE CHARACTER
            </div>
            <div className="create_confirm_failure_text_box table">
              <div className="row">
                <div className="create_confirm_failure_text confirmation_number cell">
                  <div className="create_confirm_failure_title row" data-detect="admin_email_form_create_confirm_failure_detail_label_reason">
                    Reason
                  </div>
                  <div className="create_confirm_failure_character row" data-detect="admin_email_form_create_confirm_failure_detail_label_example_reason">{`{{ reason }}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateEmailConfirmFailureCharacterView;
