import CommonComponent from "../../components/CommonComponent";

// modules
import * as paymentActions from "../../../redux/modules/Payment";



class LogListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      logList: [],
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_LOG_LIST") {
      this.onGetLogListResults(props);
    }
  }

  // redux에 get payment list 요청
  requestGetLogtList = (paymentId, hotelId) => {
    this.setState({
      requestType: "GET_LOG_LIST",
    });
    this.props.onGetLogList(paymentId, hotelId);
  };

  // get payment list results
  onGetLogListResults = (props) => {
    if (props.logList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.logList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      logList: state.payment.logList,
      paymentDetail: state.payment.paymentDetail,
      failData: state.payment.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetLogList: (paymentId, hotelId) =>
        dispatch(paymentActions.onGetLogList(paymentId, hotelId)),
    };

    return returnProps;
  };
}

export default LogListData;
