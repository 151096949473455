import CommonComponent from "../../components/CommonComponent";

// modules
import * as recommendActions from "../../../redux/modules/Recommend";

class RecommendListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      recommendList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.recommendList != this.props.recommendList) {
      if (this.state.requestType == 'GET_RECOMMEND_LIST') {
        this.onGetRecommendListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_RECOMMEND_ONE") {
      this.onDeleteRecommendListResults(nextProps);
    }
  }
  
  // redux에 get recommend list 요청
  requestGetRecommendList = (pageNo, name, search) => {
    this.setState({
      requestType: "GET_RECOMMEND_LIST",
    });
    this.props.onGetAll(pageNo, name, search);
  };

  // redux에 순차적으로 delete Recommend 요청
  requestDeleteRecommend = (recommendId) => {
    this.setState({
      requestType: "DELETE_RECOMMEND_ONE",
    });
    this.props.onDeleteOne(recommendId);
  };

  // get recommend list results
  onGetRecommendListResults = (props) => {
    if (props.recommendList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.recommendList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete recommend results
  onDeleteRecommendListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteRecommend();
        } else {
          // 삭제 완료
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetRecommendList(this.state.pageNo, this.state.name, this.state.search);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, recommendList) => {
    const currentCheckedList = [...this.state.checkedList];
    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // onClickOK
  onClickOK = () => {
    const { checkedList } = this.state;

    if (checkedList.length > 0) {
      checkedList.forEach((recommendId) => {
        this.requestDeleteRecommend(recommendId);
      });

      this.setState({ checkedList: [] });
    }
  };

  deleteSelectedRecommends = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.deleteRecommend();
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteRecommend = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.requestDeleteRecommend(checkedList[0]);
    }
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/RecommendListPage");
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (recommendList) => {
    const { checkedList } = this.state;
    const allRecommendCnt = recommendList.length;
    const isChecked = allRecommendCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : recommendList.map((recommend) => recommend.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      recommendList: state.recommend.recommendList,
      failData: state.recommend.failData,
      successDelete: state.recommend.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, name, search) => dispatch(recommendActions.onGetAllRecommend(pageNo, name, search)),
      onDeleteOne: (recommendId) => dispatch(recommendActions.onDeleteRecommend(recommendId)),
    };

    return returnProps;
  };
}

export default RecommendListData;
