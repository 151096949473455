//ApplicationEndPointAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as applicationEndPointActions from "../../../redux/modules/ApplicationEndPoint";


class ApplicationEndPointDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_APPLICATION_ENDPOINT_ONE") {

      this.onGetApplicationEndPointOneResults(props);
    }
    if (this.state.requestType == "DELETE_APPLICATION_ENDPOINT_ONE") {
      this.onDeleteApplicationEndPointListResults(props);
    }
  }

  // redux에 get applicationEndPoint Detail 요청
  requestGetApplicationEndPointOne = (applicationEndPointId) => {
    this.setState({
      requestType: "GET_APPLICATION_ENDPOINT_ONE",
    });
    this.props.onGetOne(applicationEndPointId);
  };

  // redux에 delete ApplicationEndPoint 요청
  requestDeleteApplicationEndPoint = (applicationEndPointId) => {
    this.setState({
      requestType: "DELETE_APPLICATION_ENDPOINT_ONE",
    });
    this.props.onDeleteOne(applicationEndPointId);
  };

  // get applicationEndPoint One results
  onGetApplicationEndPointOneResults = (props) => {
    if (props.applicationEndPointDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationEndPointDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // delete applicationEndPoint results
  onDeleteApplicationEndPointListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      this.onDeleteSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.props.history.push("/applicationEndPointListPage");
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationEndPointDetail: state.applicationEndPoint.applicationEndPointDetail,
      failData: state.applicationEndPoint.failData,
      successDelete: state.applicationEndPoint.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (applicationEndPointId) => dispatch(applicationEndPointActions.onGetOneApplicationEndPoint(applicationEndPointId)),
      onDeleteOne: (applicationEndPointId) => dispatch(applicationEndPointActions.onDeleteApplicationEndPoint(applicationEndPointId)),
    };
  };
}

export default ApplicationEndPointDetailData;
