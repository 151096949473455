import CommonComponent from "../../../components/CommonComponent.js";

// modules
import * as authActions from "../../../../redux/modules/Auth.js";
import * as transferActions from "../../../../redux/modules/Transfer.js";


class UserTransferLogHistoryListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      userTransferLogHistoryList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userTransferLogHistoryList != this.props.userTransferLogHistoryList) {
      if (this.state.requestType == 'GET_USER_TRANSFER_LOG_HISTORY_LIST') {
        this.onGetUserTransferLogHistoryListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_LOG_USER_TRANSFER_ONE") {
      this.onDeleteTransferLogListResults(nextProps);
    }
  }



  // redux에 get transfer list 요청
  requestGetUserTransferLogHistoryList = (pageNo, email, search, startDate, endDate) => {
    this.setState({
      requestType: "GET_USER_TRANSFER_LOG_HISTORY_LIST",
    });
    this.props.onGetAll(pageNo, email, search, startDate, endDate);
  };

  // get transfer list results
  onGetUserTransferLogHistoryListResults = (props) => {
    if (props.userTransferLogHistoryList?.length === 0 && props.failData == null) {
      return;
    }

    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.userTransferLogHistoryList.success === true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      userTransferLogHistoryList: state.transfer.userTransferLogHistoryList,
      failData: state.transfer.failData,
      successDelete: state.transfer.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAll: (pageNo, email, search, startDate, endDate) => dispatch(transferActions.onGetAllUserTransferLogHistoryList(pageNo, email, search, startDate, endDate)),
    };

    return returnProps;
  };
}

export default UserTransferLogHistoryListData;
