import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import FacilityImageAddData from "../Add/FacilityImageAddData";



//css
import "../../css/common.css";
import "../../css/facilityImage/facilityImageAdd.css";

class FacilityImageAddView extends FacilityImageAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      description: "",
      iconFile: null,
      iconText: "",
      imageUrl: null,
      errorMessage: "",
      isCheckValidation: false,
    };
  }
  
  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change description
  onChangeDescription = (e) => {
    const inputValue = e.target.value;
    this.setState({ description: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("iconFile", "admin_facility_img_add_validation_image") && //
      this.confirmField("description", "admin_facility_img_add_validation_description")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file image 미리보기
  preview = () => {
    if (!this.state.iconFile || !this.state.iconFile instanceof Blob) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }

    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const reader = new FileReader();

    // 이미지 미리보기가 완료되면 로딩 종료
    reader.onloadend = () => {
      this.setState({
        imageUrl: reader.result,
        isShowAllLoading: false,
      });
    };

    reader.readAsDataURL(this.state.iconFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ iconFile: file, iconText: file ? file.name : "ex) MyCONECT.png" }, () => {
      this.preview();
      this.onCheckValidation();
    });
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = async () => {
    this.setState({
      isShowAllLoading: true,
    });
    await this.requestAddFacilityImage(this.state.description, this.state.iconFile, this.state.iconText);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/facilityImageListPage");
  };

  render() {
    const imgBoxStyle = {
      marginLeft: this.state.iconFile && this.state.iconFile instanceof Blob ? "300px" : "0",
    };

    return (
      <div className="addFacilityImage">
        {this.state.isShowAllLoading && <Loading />}
        <div className="facilityImage_add_container">
          <div className="addfacilityImage_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_facility_img_add_title">ADD HOTEL IMAGE</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addfacilityImage_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addfacilityImage_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_facility_img_add_label_img">
                        Image
                      </div>
                      <div
                        style={{
                          display: this.state.iconFile && this.state.iconFile instanceof Blob ? "block" : "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <div style={imgBoxStyle}>
                          {this.state.iconFile && this.state.iconFile instanceof Blob && <img className="preImg" src={this.state.imageUrl} alt="hotel facility image" />}
                          <div className="file_upload_button_container">
                            <form>
                              <label htmlFor="iconFile" className="file_upload_button" data-detect="admin_facility_img_detail_button_upload">
                                Upload
                              </label>
                              <input
                                type="file"
                                id="iconFile"
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={this.onFileChange}
                                title="Upload"
                                style={{ display: "none" }} // input 요소를 화면에서 감추기
                              />
                            </form>
                            {this.state.iconText ? <p className="file_textName">{this.state.iconText}</p> : <p className="file_textName">{"ex) MyCONECT.png"}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_facility_img_add_label_description">description</p>
                      </div>
                      <div className="detail_cell content facilityImage_description">
                        <input type="text" id="description" name="description" autoComplete="off" value={this.state.description} onChange={this.onChangeDescription} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_facility_img_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_facility_img_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = FacilityImageAddData.mapStateToProps;
let mapDispatchToProps = FacilityImageAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FacilityImageAddView));
