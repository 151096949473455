import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as locationApis from "../lib/location_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const REGION_GET_ALL = "region/REGION_GET_ALL";
const REGION_GET_ONE = "region/REGION_GET_ONE";
const ADD_REGION = "region/ADD_REGION";
const DELETE_REGION = "region/DELETE_REGION";
const EDIT_REGION = "region/EDIT_REGION";
const COUNTRY_GET_ALL = "country/COUNTRY_GET_ALL";
const COUNTRY_GET_ONE = "country/COUNTRY_GET_ONE";
const ADD_COUNTRY = "country/ADD_COUNTRY";
const DELETE_COUNTRY = "country/DELETE_COUNTRY";
const EDIT_COUNTRY = "country/EDIT_COUNTRY";
const CITY_GET_ALL = "city/CITY_GET_ALL";
const CITY_GET_ONE = "city/CITY_GET_ONE";
const ADD_CITY = "city/ADD_CITY";
const DELETE_CITY = "city/DELETE_CITY";
const EDIT_CITY = "city/EDIT_CITY";
const GET_SELECT_CITY = "city/GET_SELECT_CITY";

// 액션 생성자
export const onGetAll = createAction(REGION_GET_ALL, locationApis.onGetAll);
export const onGetOne = createAction(REGION_GET_ONE, locationApis.onGetOne);
export const onAdd = createAction(ADD_REGION, locationApis.onAdd);
export const onDelete = createAction(DELETE_REGION, locationApis.onDelete);
export const onEdit = createAction(EDIT_REGION, locationApis.onEdit);
export const onGetAllCountry = createAction(COUNTRY_GET_ALL, locationApis.onGetAllCountry);
export const onGetOneCountry = createAction(COUNTRY_GET_ONE, locationApis.onGetOneCountry);
export const onAddCountry = createAction(ADD_COUNTRY, locationApis.onAddCountry);
export const onDeleteCountry = createAction(DELETE_COUNTRY, locationApis.onDeleteCountry);
export const onEditCountry = createAction(EDIT_COUNTRY, locationApis.onEditCountry);
export const onGetAllCity = createAction(CITY_GET_ALL, locationApis.onGetAllCity);
export const onGetOneCity = createAction(CITY_GET_ONE, locationApis.onGetOneCity);
export const onAddCity = createAction(ADD_CITY, locationApis.onAddCity);
export const onDeleteCity = createAction(DELETE_CITY, locationApis.onDeleteCity);
export const onEditCity = createAction(EDIT_CITY, locationApis.onEditCity);
export const onGetCountry = createAction(GET_SELECT_CITY, locationApis.onGetCountry);


// 초기 상태
const initialState = {
  regionList: [],
  regionDetail: null,
  regionAdd: null,
  regionEdit: null,
  countryList: [],
  countryDetail: null,
  countryEdit: null,
  countryAdd: null,
  cityList: [],
  cityDetail: null,
  cityEdit: null,
  cityAdd: null,
  cityData: [],
  failData: null,
  successDelete: null,
};

export default handleActions(
  {
    ...pender({
      type: REGION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, regionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, regionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: REGION_GET_ONE,
      onPending: (state, action) => {
        return { ...state, regionDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, regionDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_REGION,
      onPending: (state, action) => {
        return { ...state, regionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {

        return { ...state, regionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_REGION,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_REGION,
      onPending: (state, action) => {
        return { ...state, regionEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, regionEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: COUNTRY_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, countryList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: COUNTRY_GET_ONE,
      onPending: (state, action) => {
        return { ...state, countryDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_COUNTRY,
      onPending: (state, action) => {
        return { ...state, countryAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_COUNTRY,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_COUNTRY,
      onPending: (state, action) => {
        return { ...state, countryEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),

    ...pender({
      type: CITY_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, cityList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, cityList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: CITY_GET_ONE,
      onPending: (state, action) => {
        return { ...state, cityDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, cityDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_CITY,
      onPending: (state, action) => {
        return { ...state, cityAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, cityAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_CITY,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_CITY,
      onPending: (state, action) => {
        return { ...state, cityEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, cityEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_SELECT_CITY,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, cityData: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, cityData: [], failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
