// RegionListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import RegionListData from "../List/RegionListData";


//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/location/region/regionList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class RegionListView extends RegionListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],
      // 임시값
      tempSearch: "",
    };
  }

  //초기값 pageNo=1 , content=null;
  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // 컴포넌트가 처음 마운트될 때 list API 호출
      this.requestGetRegionList(this.state.pageNo, this.state.search);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;

    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
      },
      () => {
        this.requestGetRegionList(this.state.pageNo, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
        },
        () => {
          this.requestGetRegionList(this.state.pageNo, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search }, () => {
      this.requestGetRegionList(pageNo, this.state.search); // 호텔 목록 요청
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkRegionDetail(id);
    }
  }

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // //파람값 url로 전달
  linkRegionDetail = (regionId) => {
    this.props.history.push(`/regionDetailPage?regionId=${regionId}`);
  };

  //insert
  linkRegionAdd = () => {
    this.props.history.push("/regionAddPage");
  };


  render() {
    let count;
    let regionList = [];
    if (
      this.props.regionList != null &&
      this.props.regionList?.success == true
    ) {
      count = this.props.regionList.results.count;
      regionList = this.props.regionList.results.rows;
    }

    return (
      <div className="regionList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="region_list_container">
          <div className="region_list_container_inner">
            <div className="title" data-detect="admin_region_list_title">
              REGION LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_region_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- region list table  --> */}
            <div className="region_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(regionList, this.state.pageNo)}
                        checked={regionList && this.state.checkedList.length == regionList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_region_add_label_region">
                      Region No.
                    </div>
                    <div className="cell" data-detect="admin_region_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {regionList?.length > 0 ? (
                    regionList.map((region, index) => (
                      <div className="row tbodyRow" key={region.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={region.id}
                            id={region.id}
                            onClick={() => this.onClickInput(region.id, regionList)}
                            checked={this.state.checkedList.includes(region.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={region.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(region.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {region.name}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(region.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(region.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_region_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedRegions();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_region_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkRegionAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_region_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RegionListData.mapStateToProps;
let mapDispatchToProps = RegionListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegionListView)
);
