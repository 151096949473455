import axios from "axios";
import serverUrl from "../../react/common/ServerUrl";
import { languageCode } from "../../react/components/common/Language";
import { handleErrors } from "./axios";

const axiosTransferConfig = {
  baseURL: serverUrl,
  headers: {
    "Content-Type": "application/json",
    os: "WEB",
    "Content-Language": languageCode,
  },
  timeout: 0, // 타임 아웃 없음
};

const axiosTransferInstance = axios.create(axiosTransferConfig);

// transfer 요청이 전달되기 전에 토큰 저장하기
axiosTransferInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");

    config.headers["x-access-token"] = accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// transfer 요청 후 401 에러 응답시 refreshtoken으로 access토큰 재요청
axiosTransferInstance.interceptors.response.use(
  (response) => {
    if (response.status === 404) {
      console.log("error status: 404");
    }
    return response;
  },
  async (error) => {
    await handleErrors(error);
  }
);

export default axiosTransferInstance;
