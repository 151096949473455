import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as authApis from "../lib/auth_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const AUTH_SIGN_IN = "auth/AUTH_SIGN_IN";
const AUTH_SIGN_GET = "auth/AUTH_SIGN_GET";
const AUTH_SIGN_OUT = "auth/AUTH_SIGN_OUT";

// 액션 생성자
// export const setUserToken = authApis.setUserToken;
//login
export const onSignIn = createAction(AUTH_SIGN_IN, authApis.onPost);
//logout
export const onSignOut = createAction(AUTH_SIGN_OUT, authApis.onDelete);
//session data
export const onGet = createAction(AUTH_SIGN_GET, authApis.onGet);

// 초기 상태
const initialState = {
  data: [],
  sessionData: null,
  error: null,
};

export default handleActions(
  {
    ...pender({
      type: AUTH_SIGN_IN,
      // 로그인 성공
      onSuccess: (state, action) => {
        return { ...state, data: action.payload.data };
      },
      // 로그인 실패
      onFailure: (state, action) => {
        console.log("fail to login:",action.payload);
        return { ...state, error: action.payload };
      },
    }),
    ...pender({
      type: AUTH_SIGN_OUT,
      // onPending: (state, action) => {
      //   return { ...state, data: null, userOutedData: null };
      // },
      // 로그아웃 성공
      onSuccess: (state, action) => {
        return { ...state, data: null, userOutedData: action.payload.data };
      },
      // 로그아웃 실패
      onFailure: (state, action) => {
        return { ...state, error: action.payload };
      },
    }),
    //세션 데이터 가져오기
    ...pender({
      type: AUTH_SIGN_GET,
      onSuccess: (state, action) => {
        return { ...state, sessionData: action.payload.data };
      },
      onFailure: (state, action) => {
        console.log("fail to get session data:", action.payload);
        return { ...state, error: action.payload };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
