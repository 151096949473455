import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import { loadLanguage, updateTextLanguage } from "../../react/components/common/Language";
import { PURGE } from "redux-persist";

// 액션 타입
const SHOW_TOP_POPUP_DIALOG = "constant/SHOW_TOP_POPUP_DIALOG";
const HIDE_TOP_POPUP_DIALOG = "constant/HIDE_TOP_POPUP_DIALOG";

const OCCURED_COMMON_ERROR = "constant/OCCURED_COMMON_ERROR";
const CHECKED_COMMON_ERROR = "constant/CHECKED_COMMON_ERROR";

const LOAD_LANGUAGE = "constant/LOAD_LANGUAGE";
const UPDATE_LANGUAGE = "constant/UPDATE_LANGUAGE";

export const showTopPopupDialog = createAction(SHOW_TOP_POPUP_DIALOG, innerShowTopPopupDialog);

export const hideTopPopupDialog = createAction(HIDE_TOP_POPUP_DIALOG, innerHideTopPopupDialog);

export const occuredCommonError = createAction(OCCURED_COMMON_ERROR, innerOccuredCommonError);

export const checkedCommonError = createAction(CHECKED_COMMON_ERROR, innerCheckedCommonError);

export const loadMultiLanguage = createAction(LOAD_LANGUAGE, innerLoadMultiLanguage);

export const updateMultiLanguage = createAction(UPDATE_LANGUAGE, innerUpdateMultiLanguage);

async function innerShowTopPopupDialog(dialogType, dialogContent, positiveFunction, negativeFunction, closeFunction) {
    return {
        isShowDialog: true,
        dialogType: dialogType,
        dialogContent: dialogContent,
        positiveFunction: positiveFunction,
        negativeFunction: negativeFunction,
        closeFunction: closeFunction,
    };
}

async function innerHideTopPopupDialog() {
    return {
        isShowDialog: false,
        dialogType: null,
        dialogContent: null,
        positiveFunction: null,
        negativeFunction: null,
        closeFunction: null,
    };
}

async function innerOccuredCommonError(code, description) {
    return {
        code: code,
        description: description,
    };
}

async function innerCheckedCommonError() {
    return {
        code: "",
        description: "",
    };
}

async function innerLoadMultiLanguage() {
    let multiLang = null;
    let multiLangCode = "";

    await loadLanguage().then((data) => {
        multiLang = data?.MultiLanguage;
        multiLangCode = data?.multiLanguageCode;
    });
    return {
        MultiLanguage: multiLang,
        multiLanguageCode: multiLangCode,
    };
}
async function innerUpdateMultiLanguage(MultiLang) {
    updateTextLanguage(MultiLang);
    return {
    };
}

// 초기 상태
const initialState = {
    error: null,
    dialog: {
        isShowDialog: false,
        dialogType: null,
        dialogContent: null,
        positiveFunction: null,
        negativeFunction: null,
        closeFunction: null,
    },
    commonError: {
        code: "",
        description: "",
    },
    MultiLanguage: null,
    multiLanguageCode: "en",
};

export default handleActions(
    {
        ...pender({
        type: SHOW_TOP_POPUP_DIALOG,
        onSuccess: (state, action) => {
            return {
            ...state,
            dialog: {
                isShowDialog: action.payload.isShowDialog,
                dialogType: action.payload.dialogType,
                dialogContent: action.payload.dialogContent,
                positiveFunction: action.payload.positiveFunction,
                negativeFunction: action.payload.negativeFunction,
                closeFunction: action.payload.closeFunction,
            },
            };
        },
        onFailure: (state, action) => {
            return {
            ...state,
            dialog: {
                isShowDialog: false,
                dialogType: null,
                dialogContent: null,
                positiveFunction: null,
                negativeFunction: null,
                closeFunction: null,
            },
            };
        },
        }),
        ...pender({
        type: HIDE_TOP_POPUP_DIALOG,
        onSuccess: (state, action) => {
            return {
            ...state,
            dialog: {
                isShowDialog: action.payload.isShowDialog,
                dialogType: action.payload.dialogType,
                dialogContent: action.payload.dialogContent,
                positiveFunction: action.payload.positiveFunction,
                negativeFunction: action.payload.negativeFunction,
                closeFunction: action.payload.closeFunction,
            },
            };
        },
        onFailure: (state, action) => {
            return {
            ...state,
            dialog: {
                isShowDialog: false,
                dialogType: null,
                dialogContent: null,
                positiveFunction: null,
                negativeFunction: null,
                closeFunction: null,
            },
            };
        },
        }),
        ...pender({
        type: OCCURED_COMMON_ERROR,
        onSuccess: (state, action) => {
            return {
            ...state,
            commonError: {
                code: action.payload.code,
                description: action.payload.description,
            },
            };
        },
        }),
        ...pender({
        type: CHECKED_COMMON_ERROR,
        onSuccess: (state, action) => {
            return {
            ...state,
            commonError: {
                code: action.payload.code,
                description: action.payload.description,
            },
            };
        },
        }),
        ...pender({
        type: LOAD_LANGUAGE,
        onSuccess: (state, action) => {
            return {
            ...state,
            MultiLanguage: action.payload.MultiLanguage,
            multiLanguageCode: action.payload.multiLanguageCode,
            };
        },
        onFailure: (state, action) => {
            return {
            ...state,
            MultiLanguage: null,
            multiLanguageCode: "en",
            };
        },
        }),
        ...pender({
        type: UPDATE_LANGUAGE,
        onSuccess: (state, action) => {
            return {
            ...state,
            };
        },
        onFailure: (state, action) => {
            return {
            ...state,
            };
        },
        }),
    },
    initialState
);
