// TermsEditData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as authActions from "../../../../redux/modules/Auth";
import * as termsAndConditionActions from "../../../../redux/modules/TermsAndCondition";

// language
import { multiLanguageCode, MultiLanguage } from "../../../components/common/Language";


class TermsEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }
  // request redux
  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_TERMS") {
      this.onEditTermsResults(props);
    }
  }

  // redux에 edit terms 요청
  requestEditTerms = (title, languageCode, description, linkText, required, termsGroupId, fileName, fileId, termsId) => {
    const changedDescription = description
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, ' ');

    const formData = new FormData();
    formData.append('title', title);
    formData.append('languageCode', languageCode);
    formData.append('description', changedDescription);
    formData.append("linkText", linkText);
    formData.append("required", required);
    formData.append("termsGroupId", termsGroupId);
    formData.append("termsFile", fileId);
    formData.append("termsText", fileName);

    this.setState({
      requestType: "EDIT_TERMS",
    });
    this.props.onEdit(formData, termsId);
  };

  // redux에 get terms list 요청
  requestTermsGroupList = () => {
    this.setState({
      requestType: "GET_TERMS_GROUP_LIST",
    });
    this.props.onGetTermsGroupList();
  };

  // get edit terms results
  onEditTermsResults = (props) => {
    if (props.termsEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.termsEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    // 페이지 이동
    this.props.history.goBack();
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      termsEdit: state.termsAndCondition.termsEdit,
      termsDetail: state.termsAndCondition.termsDetail,
      termsGroupList: state.termsAndCondition.termsGroupList,
      failData: state.termsAndCondition.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onEdit: (formData, termsId) => dispatch(termsAndConditionActions.onEditTerms(formData, termsId)),
    };
  };
}

export default TermsEditData;
