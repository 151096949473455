import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../components/Paths";
import OTAReservationData from "./OTAReservationData";

// datePicker
import DatePicker from "react-datepicker";

// css
import "../css/common.css";
import "../css/common/myConectTable.css";
import "../css/otaReservation/otaReservation.css";

// Icon
import closeIcon from "../icon/close_icon.svg";
import glassIcon from "../icon/glass_icon.svg";
import minusIcon from "../icon/minus_icon.svg";
import plusIcon from "../icon/plus_icon.svg";
import roomBedIcon from "../icon/room_bed_icon.svg";
import roomPeopleIcon from "../icon/room_people_icon.svg";
import roomSizeIcon from "../icon/room_size_icon.svg";
import roomWindowIcon from "../icon/room_window_icon.svg";
import logo from "../img/logo_myconect.svg";

// language
import ServerUrl from "../common/ServerUrl";
import multiLanguageCode, { updateLanguage } from "../components/otaReservation/Language";
import MultiLanguage from "../components/otaReservation/MultiLanguage";

class OTAReservationView extends OTAReservationData {
  constructor(props) {
    super(props);

    let today = new Date();
    today = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, "0")}-${today
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1).toString().padStart(2, "0")}-${tomorrow
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,

      // value
      searchType: "",

      // change
      isFindHotel: true,
      isFindRoom: true,

      // open
      isOpenSelectHotel: true,
      isOpenSelectRoom: false,
      isRoomList: false,
      isOpenGuestInformation: false,
      isMembershipForm: false,
      viewTerms: "",
      isPaymentContents: false,
      isOpenEnterCardInfo: false,
      isTerms: false,
      isCompleteButton: false,
      isComplete: false,
      isGuestContents: false,
      isValidationBox: false,

      // data
      hotelId: "",
      hotelName: "",

      adults: 1,
      children: 0,

      email: "",
      membershipLastName: "",
      membershipType: "0",
      searchEmail: "",
      
      startDate: today,
      endDate: tomorrow,
      ratePlanCode: "",
      roomTypeCode: "",
      numberOfUnits: 1,
      guaranteeType: "",
      cardType: "0",
      cardHolderName: "",
      cardNumber: "",
      expirationDate: new Date(),
      year: "",
      month: "",
      nameTitle: "",
      firstName: "",
      lastName: "",
      phone: "",
      alternativeFirstName: "",
      alternativeLastName: "",
      profileId: "",
      requestedPageType: "",
    };
  }

  mailRegExp = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  numberRegExp = /^\d+$/;

  /* ------------------------------------------------------ 생명 주기 ----------------------------------------------------- */
  componentDidMount() {
    updateLanguage();
    this.requestGetHotelList();

    document.body.setAttribute("style", "background-color: #fff;");
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    updateLanguage();
  }

  /* -------------------------------------------------- Event Handler ------------------------------------------------- */
  openSelectHotel = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    this.setState({
      isOpenSelectHotel: true,
    });
  };

  openSelectRoom = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    this.setState({
      isOpenSelectRoom: true,
    });
  };

  openEnterInfo = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    this.setState({
      isOpenGuestInformation: true,
    });
  };

  openEnterCardInfo = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    this.setState({
      isOpenEnterCardInfo: true,
    });
  };

  onChangeValue = (e, stateName) => {
    const value = e.target.value;
    if (stateName == "searchType" && value == "email") {
      this.setState({
        searchEmail: "",
        nameTitle: "",
        firstName: "",
        lastName: "",
        alternativeFirstName: "",
        alternativeLastName: "",
        email: "",
        phone: "",
        searchType: value,
      });
    } else {
      this.setState({ [stateName]: value });
    }
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];

    if (field === "setRatePlanCode") {
      if ((this.state["setRatePlanCode"] !== "" && this.state["setRatePlanCode"] !== null) || (this.state["setRoomTypeCode"] !== "" && this.state["setRoomTypeCode"] !== null)) {
        this.setState({
          isValidationBox: false,
        });
        return true;
      } else {
        this.setState({
          message: messageKey,
          isValidationBox: true,
        });
        return false;
      }
    } else if (field === "email") {
      if (this.mailRegExp.test(this.state.email)) {
        this.setState({
          isValidationBox: false,
        });
        return true;
      } else {
        this.setState({
          message: messageKey,
          isValidationBox: true,
        });
        return false;
      }
    } else {
      if (value === "" || value === null) {
        this.setState({
          message: messageKey,
          isValidationBox: true,
        });
        return false;
      } else {
        this.setState({
          isValidationBox: false,
        });
        return true;
      }
    }
  };

  onBookingButton = () => {
    if (this.state.hotelId == "") {
      this.setState({
        message: "hotel_reservation_validation_select_hotel",
        isValidationBox: true,
      });
    } else if (this.state.startDate == "") {
      this.setState({
        message: "hotel_reservation_validation_select_check_in_date",
        isValidationBox: true,
      });
    } else if (this.state.endDate == "") {
      this.setState({
        message: "hotel_reservation_validation_select_check_out_date",
        isValidationBox: true,
      });
    } else if (this.state.adults < 1) {
      this.setState({
        message: "hotel_reservation_validation_enter_adults",
        isValidationBox: true,
      });
    } else if (this.state.children < 0) {
      this.setState({
        message: "hotel_reservation_validation_enter_children",
        isValidationBox: true,
      });
    } else if (this.state.ratePlanCode == "" || this.state.roomTypeCode == "") {
      this.setState({
        message: "hotel_reservation_validation_select_room",
        isValidationBox: true,
      });
    } else if (this.state.cardType == "") {
      this.setState({
        message: "hotel_reservation_validation_select_card_type",
        isValidationBox: true,
      });
    } else if (this.state.cardHolderName == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_card_name",
        isValidationBox: true,
      });
    } else if (this.state.cardNumber == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_card_number",
        isValidationBox: true,
      });
    } else if (this.state.month == "" || this.state.year == "") {
      this.setState({
        message: "hotel_reservation_validation_select_card_expiration",
        isValidationBox: true,
      });
    } else if (this.state.termsCheck == false) {
      this.setState({
        message: "hotel_reservation_validation_check_agreement",
        isValidationBox: true,
      });
    } else {
      if (this.state.profileId == "") {
        if (this.state.nameTitle == "0") {
          this.setState({
            message: "hotel_reservation_validation_select_title",
            isValidationBox: true,
          });
        } else if (this.state.firstName == "") {
          this.setState({
            message: "hotel_reservation_validation_enter_first_name",
            isValidationBox: true,
          });
        } else if (this.state.lastName == "") {
          this.setState({
            message: "hotel_reservation_validation_enter_last_name",
            isValidationBox: true,
          });
        } else if (this.state.email == "") {
          this.setState({
            message: "hotel_reservation_validation_email",
            isValidationBox: true,
          });
        } else if (this.state.phone == "") {
          this.setState({
            message: "hotel_reservation_validation_enter_phone",
            isValidationBox: true,
          });
        } else {
          this.setState({
            isShowAllLoading: true,
          });
          this.requestAddBookingWithProfile(
            this.state.hotelId,
            this.state.startDate,
            this.state.endDate,
            this.state.ratePlanCode,
            this.state.roomTypeCode,
            this.state.adults,
            this.state.children,
            this.state.numberOfUnits,
            this.state.guaranteeType,
            this.state.cardType,
            this.state.cardHolderName,
            this.state.cardNumber,
            this.state.year + "-" + this.state.month,
            this.state.nameTitle,
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.phone,
            this.state.alternativeFirstName,
            this.state.alternativeLastName,
            this.state.requestedPageType
          );
        }
      } else {
        this.setState({
          isShowAllLoading: true,
        });
        this.requestAddBooking(
          this.state.hotelId,
          this.state.startDate,
          this.state.endDate,
          this.state.ratePlanCode,
          this.state.roomTypeCode,
          this.state.adults,
          this.state.children,
          this.state.numberOfUnits,
          this.state.guaranteeType,
          this.state.cardType,
          this.state.cardHolderName,
          this.state.cardNumber,
          this.state.year + "-" + this.state.month,
          this.state.profileId,
          this.state.requestedPageType
        );
      }
    }
  };

  closeSelectHotel = () => {
    this.setState({
      isOpenSelectHotel: false,
      isHotelList: false,
      isShowAllLoading: false,
    });
  };

  closeSelectRoom = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";

    this.setState({
      isOpenSelectRoom: false,
      isRoomList: false,
      isShowAllLoading: false,
    });
  };

  adultMinusButton = () => {
    this.setState({
      isRoomList: false,
    });
    if (this.state.adults > 1) {
      let adultNo = this.state.adults - 1;
      this.setState({
        adults: adultNo,
      });
    }
  };

  adultPlusButton = () => {
    this.setState({
      isRoomList: false,
    });
    if (this.state.adults < 9) {
      let adultNo = this.state.adults + 1;
      this.setState({
        adults: adultNo,
      });
    }
  };

  chlidrenMinusButton = () => {
    this.setState({
      isRoomList: false,
    });
    if (this.state.children > 0) {
      let chlidrenNo = this.state.children - 1;
      this.setState({
        children: chlidrenNo,
      });
    }
  };

  chlidrenPlusButton = () => {
    this.setState({
      isRoomList: false,
    });
    if (this.state.children < 9) {
      let chlidrenNo = this.state.children + 1;
      this.setState({
        children: chlidrenNo,
      });
    }
  };

  getRoomList = () => {
    this.setState({
      isShowAllLoading: true,
      isRoomList: true,
    });
    this.requestGetHotelReservationList(this.state.hotelId, this.state.startDate, this.state.endDate, this.state.adults, this.state.children);
  };

  selectHotel = (hotelId, name, address, phone) => {
    let today = new Date();
    today = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow = `${tomorrow.getFullYear()}-${(tomorrow.getMonth() + 1).toString().padStart(2, "0")}-${tomorrow.getDate().toString().padStart(2, "0")}`;

    this.setState(
      {
        isShowAllLoading: true,
        isOpenSelectHotel: false,
        isFindHotel: false,
        hotelId: hotelId,
        hotelName: name,
        hotelAddress: address,
        hotelPhone: phone,
        isFindRoom: true,

        isOpenGuestInformation: false,
        isMembershipForm: false,
        viewTerms: "",
        isPaymentContents: false,
        isOpenEnterCardInfo: false,
        isTerms: false,
        isCompleteButton: false,
        isComplete: false,
        isGuestContents: false,
        isValidationBox: false,

        email: "",
        membershipLastName: "",
        membershipType: "0",
        adults: 1,
        children: 0,
        roomName: "",
        size: "",
        roomName: "",
        bedType: "",
        people: "",
        view: "",
        startDate: today,
        endDate: tomorrow,
        ratePlanCode: "",
        roomTypeCode: "",
        numberOfUnits: 1,
        guaranteeType: "",
        cardType: "0",
        cardHolderName: "",
        cardNumber: "",
        expirationDate: new Date(),
        year: "",
        month: "",
        nameTitle: "",
        firstName: "",
        lastName: "",
        phone: "",
        alternativeFirstName: "",
        alternativeLastName: "",
        profileId: "",
        requestedPageType: "",
      },
      () => {
        this.requestGetHotelReservationOne(hotelId);
        this.requestGetHotelReservationMembershipType(hotelId);
        this.requestGetHotelReservationTitleType(hotelId);
        this.requestGetHotelReservationCardType(hotelId);
      }
    );
  };

  selectRoom = (id, roomName, size, bedType, people, view, ratePlanCode, roomTypeCode) => {
    this.setState({
      isShowAllLoading: true,
      isFindRoom: false,
      isGuestContents: true,
      id: id,
      roomName: roomName,
      size: size,
      bedType: bedType,
      people: people,
      view: view,
      ratePlanCode: ratePlanCode,
      roomTypeCode: roomTypeCode,
    });

    // 팝업 창 닫기
    this.closeSelectRoom();
  };

  closeGuestInfo = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    this.setState({
      isOpenGuestInformation: false,
      isShowAllLoading: false,
      isMembershipForm: true,
    });
    $("#searchEmail").removeClass("w400");
    $("#searchEmail").addClass("w200");
  };

  getProfileInfo = () => {
    if (this.state.searchType == "email") {
      this.setState({ isShowAllLoading: true });
      this.requestGetHotelReservationProfileByEmail(this.state.hotelId, this.state.searchEmail);
    }
  };

  selectGuestInfo = () => {
    this.setState({
      isShowAllLoading: false,
    });
    const { nameTitle, firstName, lastName, email, phone, searchType } = this.state;
    if (this.state.nameTitle == null) {
      this.setState({
        message: "hotel_reservation_validation_select_title",
        isValidationBox: true,
      });
    } else if (this.state.firstName == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_first_name",
        isValidationBox: true,
      });
    } else if (this.state.lastName == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_last_name",
        isValidationBox: true,
      });
    } else if (this.state.email == "") {
      this.setState({
        message: "hotel_reservation_validation_email",
        isValidationBox: true,
      });
    } else if (!this.mailRegExp.test(this.state.email)) {
      this.setState({
        message: "hotel_reservation_validation_invalid_email",
        isValidationBox: true,
      });
    } else if (searchType == "direct" && this.state.phone == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_phone",
        isValidationBox: true,
      });
    } else {
      this.setState({
        isGuestContentsEdit: true,
        isPaymentContents: true,
      });

      // 팝업 창 닫기
      this.closeGuestInfo();
    }
  };

  closeEnterCardInfo = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";

    this.setState({
      isOpenEnterCardInfo: false,
      isShowAllLoading: false,
    });
  };

  selectCardInfo = () => {
    if (this.state.cardType == null) {
      this.setState({
        message: "hotel_reservation_validation_select_card_type",
        isValidationBox: true,
      });
    } else if (this.state.cardHolderName == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_card_name",
        isValidationBox: true,
      });
    } else if (this.state.cardNumber == "") {
      this.setState({
        message: "hotel_reservation_validation_enter_card_number",
        isValidationBox: true,
      });
    } else if (!this.numberRegExp.test(this.state.cardNumber)) {
      this.setState({
        message: "hotel_reservation_validation_enter_card_number",
        isValidationBox: true,
      });
    } else if (this.state.month == "") {
      this.setState({
        message: "hotel_reservation_validation_select_card_expiration",
        isValidationBox: true,
      });
    } else if (this.state.year == "") {
      this.setState({
        message: "hotel_reservation_validation_select_card_expiration",
        isValidationBox: true,
      });
    } else {
      this.setState({
        isCompleteButton: true,
        isShowAllLoading: true,
      });

      // 팝업 창 닫기
      this.closeEnterCardInfo();
    }
  };

  onClickTermsButton = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    this.setState({
      isShowAllLoading: true,
    });
    this.requestGetHotelReservationTermsConditions(this.state.hotelId);
  };

  onClickPrivacyButton = () => {
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    this.setState({
      isShowAllLoading: true,
    });
    this.requestGetHotelReservationPrivacyPolicy(this.state.hotelId);
  };

  closeViewTerms = () => {
    const body = document.querySelector("body");
    body.style.overflow = "auto";
    this.setState({
      viewTerms: "",
    });
  };

  generateYearOptions() {
    const currentYear = new Date().getFullYear();
    const futureYear = currentYear + 12;

    const options = [];
    for (let i = currentYear; i <= futureYear; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  }

  updateClassName = () => {
    let newClassName = "";

    if (window.innerWidth >= 1025 && window.innerWidth <= 1240) {
      newClassName = "hotelReservation1240";
    } else if (window.innerWidth >= 769 && window.innerWidth <= 1024) {
      newClassName = "hotelReservation1024";
    } else if (window.innerWidth >= 561 && window.innerWidth <= 768) {
      newClassName = "hotelReservation768";
    } else if (window.innerWidth >= 0 && window.innerWidth <= 560) {
      newClassName = "hotelReservation560";
    }

    if (this.state.className !== newClassName) {
      this.setState({ className: newClassName });
    }
  };

  handleChangeCheckbox = () => {
    this.setState((prevState) => ({
      termsCheck: !prevState.termsCheck,
    }));
  };

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    let className;
    let hotelList = [];
    let hotelReservationList = [];
    let hotelReservationDetail = null;
    let hotelReservationMembershipTypeList = [];
    let hotelReservationTitleTypeList = [];
    let hotelReservationCardTypeList = [];
    let hotelReservationProfileByEmailDetail = null;
    let hotelReservationProfileByMembershipDetail = null;
    let hotelReservationTermsConditionsDetail = null;
    let hotelReservationPrivacyPolicyDetail = null;
    let hotelReservationBookingWithProfileAdd = null;
    let hotelReservationBookingAdd = null;

    if (this.props.hotelList != null && this.props.hotelList?.success == true) {
      hotelList = this.props.hotelList.results.rows;
    }

    if (this.props.hotelReservationList != null && this.props.hotelReservationList?.success == true) {
      hotelReservationList = this.props.hotelReservationList.results;
    }

    if (this.props.hotelReservationDetail != null && this.props.hotelReservationDetail?.success == true) {
      hotelReservationDetail = this.props.hotelReservationDetail.results;
    }

    if (this.props.hotelReservationMembershipTypeList != null && this.props.hotelReservationMembershipTypeList?.success == true) {
      hotelReservationMembershipTypeList = this.props.hotelReservationMembershipTypeList.results.rows;
    }

    if (this.props.hotelReservationTitleTypeList != null && this.props.hotelReservationTitleTypeList?.success == true) {
      hotelReservationTitleTypeList = this.props.hotelReservationTitleTypeList.results.rows;
    }

    if (this.props.hotelReservationCardTypeList != null && this.props.hotelReservationCardTypeList?.success == true) {
      hotelReservationCardTypeList = this.props.hotelReservationCardTypeList.results;
    }

    if (this.props.hotelReservationProfileByEmailDetail != null && this.props.hotelReservationProfileByEmailDetail?.success == true) {
      hotelReservationProfileByEmailDetail = this.props.hotelReservationProfileByEmailDetail.results;
    }

    if (this.props.hotelReservationProfileByMembershipDetail != null && this.props.hotelReservationProfileByMembershipDetail?.success == true) {
      hotelReservationProfileByMembershipDetail = this.props.hotelReservationProfileByMembershipDetail.results;
    }

    if (this.props.hotelReservationTermsConditionsDetail != null && this.props.hotelReservationTermsConditionsDetail?.success == true) {
      hotelReservationTermsConditionsDetail = this.props.hotelReservationTermsConditionsDetail.results.rows;
    }

    if (this.props.hotelReservationPrivacyPolicyDetail != null && this.props.hotelReservationPrivacyPolicyDetail?.success == true) {
      hotelReservationPrivacyPolicyDetail = this.props.hotelReservationPrivacyPolicyDetail.results.rows;
    }

    if (this.props.hotelReservationBookingWithProfileAdd != null && this.props.hotelReservationBookingWithProfileAdd?.success == true) {
      hotelReservationBookingWithProfileAdd = this.props.hotelReservationBookingWithProfileAdd.results;
    }

    if (this.props.hotelReservationBookingAdd != null && this.props.hotelReservationBookingAdd?.success == true) {
      hotelReservationBookingAdd = this.props.hotelReservationBookingAdd.results;
    }
    return (
      <div className={`hotelReservationDefault ${className}`}>
        {this.state.isShowAllLoading && <Loading />}
        <section>
          <article>
            <div id="main">
              <div className="main_banner">
                <div className="logo">
                  <img src={logo} alt="Logo" />
                </div>
              </div>
              <div className="main_container">
                {/* hotel_contents */}
                <div className="contents hotel_contents">
                  <div>
                    <div className="title_box">
                      <div className="title" data-detect={this.state.isFindHotel ? "hotel_reservation_title_find_hotel" : "hotel_reservation_title_hotel_details"}>
                        {this.state.isFindHotel ? "Find Hotel" : "Arrival Details"}
                      </div>
                      <div className="select_button">
                        <button type="button" onClick={() => this.openSelectHotel()} data-detect={this.state.isFindHotel ? "hotel_reservation_button_select" : "hotel_reservation_button_change"}>
                          {this.state.isFindHotel ? "SELECT" : "CHANGE"}
                        </button>
                      </div>
                    </div>
                    {this.state.isFindHotel == false && (
                      <div className="contents_box">
                        <div className="title_box">
                          <div className="title"></div>
                        </div>
                        <div className="hotel_details">
                          <h3>{this.state.hotelName}</h3>
                          <p className="address">{this.state.hotelAddress}</p>
                          <p className="phone">{this.state.hotelPhone}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* arrival_contents */}
                {!this.state.isFindHotel && this.state.isComplete == false && (
                  <div className="contents arrival_contents">
                    <div>
                      <div className="title_box">
                        <div className="title" data-detect={this.state.isFindRoom ? "hotel_reservation_title_find_room" : "hotel_reservation_title_arrival_details"}>
                          {this.state.isFindRoom ? "Find Room" : "Arrival Details"}
                        </div>
                        <div className="select_button">
                          <button type="button" onClick={() => this.openSelectRoom()} data-detect={this.state.isFindRoom ? "hotel_reservation_button_select" : "hotel_reservation_button_change"}>
                            {this.state.isFindRoom ? "SELECT" : "CHANGE"}
                          </button>
                        </div>
                      </div>
                      {this.state.isFindRoom == false && (
                        <div className="contents_box">
                          <div className="arrival_details">
                            <div className="check_in">
                              <p data-detect="hotel_reservation_label_check_in"></p>
                              <span>{this.state.startDate}</span>
                            </div>
                            <div className="check_out">
                              <p data-detect="hotel_reservation_label_check_out"></p>
                              <span>{this.state.endDate}</span>
                            </div>
                            <div className="adult">
                              <p data-detect="hotel_reservation_label_adult"></p>
                              <span>{this.state.adults}</span>
                            </div>
                            <div className="children">
                              <p data-detect="hotel_reservation_label_children"></p>
                              <span>{this.state.children}</span>
                            </div>
                          </div>
                          <div className="title_box">
                            <div className="title" data-detect="hotel_reservation_label_room_details"></div>
                          </div>
                          <div className="room_details">
                            <h3>{this.state.roomName}</h3>
                            <div>
                              <p className="size">
                                <span data-detect="hotel_reservation_label_size"></span> : {this.state.size}
                              </p>
                              <p className="bed">
                                <span data-detect="hotel_reservation_label_bed_type"></span> : {this.state.bedType}
                              </p>
                              <p className="guests">
                                <span data-detect="hotel_reservation_label_guests"></span> : {this.state.people}
                              </p>
                              <p className="view">
                                <span data-detect="hotel_reservation_label_view"></span> : {this.state.view}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* guest contents */}
                {this.state.isGuestContents && this.state.isComplete === false && (
                  <div className="contents guest_contents">
                    <div>
                      <div className="title_box">
                        <div className="title" data-detect="hotel_reservation_title_guest_details">
                          Guest Details
                        </div>
                        <div className="select_button">
                          <button
                            type="button"
                            onClick={() => this.openEnterInfo()}
                            data-detect={this.state.isGuestContentsEdit ? "hotel_reservation_button_change" : "hotel_reservation_button_select"}
                          >
                            SELECT
                          </button>
                        </div>
                      </div>
                      {this.state.isPaymentContents && (
                        <div className="contents_box">
                          <div className="guest_details">
                            <h3>
                              {this.state.nameTitle} {this.state.firstName} {this.state.lastName}
                              <span>
                                {this.state.alternativeFirstName} {this.state.alternativeLastName}
                              </span>
                            </h3>
                            <p>{this.state.email}</p>
                            <p>{this.state.phone}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* payment contents */}
                {this.state.isPaymentContents && this.state.isComplete === false && (
                  <div className="contents payment_contents">
                    <div>
                      <div className="title_box">
                        <div className="title" data-detect={this.state.isFindHotel ? "hotel_reservation_title_payment_details" : "hotel_reservation_title_credit_card_info"}>
                          Credit Card Informaition
                        </div>
                        <div className="select_button">
                          <button
                            type="button"
                            onClick={() => this.openEnterCardInfo()}
                            data-detect={this.state.isCompleteButton ? "hotel_reservation_button_change" : "hotel_reservation_button_insert"}
                          >
                            INSERT
                          </button>
                        </div>
                      </div>
                      {this.state.isCompleteButton && (
                        <div className="contents_box">
                          <div className="payment_details">
                            <h3>{this.state.cardHolderName}</h3>
                            <p>{this.state.cardNumber.replace(/\d{4}(?=\d)/g, "XXXX ")}</p>
                            <p>
                              {this.state.cardType}
                              <span>{this.state.month + "/" + this.state.year}</span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.state.isCompleteButton && this.state.isComplete == false && (
                  <>
                    {/* terms */}
                    <div className="terms">
                      <input type="checkbox" id="termsCheck" checked={this.state.termsCheck} onChange={this.handleChangeCheckbox} />
                      <label htmlFor="termsCheck"></label>
                      <p
                        data-detect="hotel_reservation_label_agree_check"
                        onClick={(e) => {
                          let termsConditions = "";
                          let privacyPolicy = "";
                          const agreeCheckText = MultiLanguage["language"][multiLanguageCode]["hotel_reservation_label_agree_check"];
                          const findRegex = /<<([^<>]+)>>/g;
                          const matches = agreeCheckText.match(findRegex);

                          if (matches) {
                            termsConditions = matches[0].slice(2, -2);
                            privacyPolicy = matches[1].slice(2, -2);
                          }

                          const target = e.target;
                          if (target.tagName === "B") {
                            const textContent = target.textContent;
                            if (textContent == termsConditions) {
                              this.onClickTermsButton();
                            } else if (textContent == privacyPolicy) {
                              this.onClickPrivacyButton();
                            }
                          }
                        }}
                      >
                        I have confirmed the booking details and agree to the <b>Terms & Conditions</b> and the <b>Privacy Policy</b> of the hotel.
                      </p>
                    </div>

                    {/* complete button */}
                    <div className="complete_button">
                      <button type="button" onClick={() => this.onBookingButton()} data-detect="hotel_reservation_button_complete">
                        COMPLETE RESERVATION
                      </button>
                    </div>
                  </>
                )}

                {/* complete */}
                {this.state.isComplete && (
                  <div className="complete">
                    <div className="title" data-detect="hotel_reservation_title_complete">
                      Your reservation has been successfully completed.
                    </div>
                    <div className="contents">
                      <div className="complete_hotel_img">
                        {this.props.hotelReservationDetail.results?.hotelCenterImage?.fileId && (
                          <img src={`${ServerUrl}/image/hotel/${this.props.hotelReservationDetail.results.hotelCenterImage?.fileId}`} alt="hotel img" />
                        )}
                      </div>
                      <div className="complete_contents">
                        <h3>{hotelReservationDetail.name}</h3>
                        <p>
                          {MultiLanguage["language"][multiLanguageCode]["hotel_reservation_label_ota_no"]}
                          <span>{this.state.referenceNumber}</span>
                        </p>
                        <div>
                          <h4 data-detect="hotel_reservation_title_room"></h4>
                          <p>{this.state.roomType}</p>
                        </div>
                        <div>
                          <h4 data-detect="hotel_reservation_title_date_of_stay"></h4>
                          <p>
                            {this.state.arrivalDate} ~ {this.state.departureDate}
                          </p>
                        </div>
                        <div>
                          <h4 data-detect="hotel_reservation_title_number_of_people"></h4>
                          <p>
                            {MultiLanguage["language"][multiLanguageCode]["hotel_reservation_label_adult"]}
                            <span>{this.state.adults}</span>
                          </p>
                          <p>
                            {MultiLanguage["language"][multiLanguageCode]["hotel_reservation_label_children"]}
                            <span>{this.state.children}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="go_to_main">
                      <button type="button" onClick={() => document.location.reload()} data-detect="hotel_reservation_button_main">
                        Go to Main
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {this.state.isOpenSelectHotel && this.state.isComplete === false && (
              <div id="selectHotel">
                <div className="select_container">
                  <div className="close_button">
                    <img src={closeIcon} alt="Close" onClick={() => this.closeSelectHotel()} />
                  </div>
                  <div className="select_title">
                    <h2>{MultiLanguage["language"][multiLanguageCode]["hotel_reservation_title_select_hotel"]}</h2>
                  </div>
                  <div className="hotel_list_container">
                    {hotelList.length > 0 &&
                      hotelList.map((hotel, index) => {
                        return (
                          <div className="select_list" key={index}>
                            <div className="hotel_img">{hotel.hotelCenterImage?.fileId && <img src={`${ServerUrl}/image/hotel/${hotel.hotelCenterImage.fileId}`} alt="hotel img" />}</div>
                            <div className="hotel_list_contents">
                              <div>
                                <h3>{hotel.name}</h3>
                                <p>{hotel.address}</p>
                                <span>{hotel.phone}</span>
                              </div>
                              <div>
                                <button type="button" onClick={() => this.selectHotel(hotel.hotelId, hotel.name, hotel.address, hotel.phone)}>
                                  {MultiLanguage["language"][multiLanguageCode]["hotel_reservation_button_select"]}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {this.state.isOpenSelectRoom && this.state.isComplete === false && (
              <div id="selectRoom">
                <div className="select_container">
                  <div className="close_button">
                    <img src={closeIcon} alt="Close" onClick={() => this.closeSelectRoom()} />
                  </div>
                  <div className="select_title">
                    <h2 data-detect="hotel_reservation_title_select_room">
                      Select Room
                      <span>( Chilren is aged 37 months to 12 years )</span>
                    </h2>
                  </div>
                  <div className="select_date">
                    <div className="check_in">
                      <label htmlFor="startDate" data-detect="hotel_reservation_label_check_in">
                        Check-in
                      </label>
                      <DatePicker
                        portalId="root"
                        id="startDate"
                        selected={new Date(this.state.startDate)}
                        onChange={(date) =>
                          this.setState({
                            isRoomList: false,
                            startDate: date.toISOString().slice(0, 10),
                          })
                        }
                        dateFormat="yyyy-MM-dd"
                        autoComplete="off"
                        popperProps={{
                          strategy: "fixed",
                          positionFixed: true,
                        }}
                      />
                      {/* <input type="search" id="startDate" name="startDate" placeholder="" autoComplete="off" value={this.state.startDate} onChange={(e) => this.onChangeValue(e, "startDate")} /> */}
                    </div>
                    <div className="check_out">
                      <label htmlFor="endDate" data-detect="hotel_reservation_label_check_out">
                        Check-out
                      </label>
                      <DatePicker
                        portalId="root"
                        id="endDate"
                        selected={new Date(this.state.endDate)}
                        onChange={(date) =>
                          this.setState({
                            isRoomList: false,
                            endDate: date.toISOString().slice(0, 10),
                          })
                        }
                        dateFormat="yyyy-MM-dd"
                        autoComplete="off"
                        popperProps={{
                          strategy: "fixed",
                          positionFixed: true,
                        }}
                      />
                      {/* <input type="search" id="endDate" name="endDate" placeholder="" autoComplete="off" value={this.state.endDate} onChange={(e) => this.onChangeValue(e, "endDate")} /> */}
                    </div>
                    <div className="adult">
                      <label htmlFor="adult" data-detect="hotel_reservation_label_adult">
                        Adult
                      </label>
                      <div className="adult_number">
                        <button type="button" onClick={() => this.adultMinusButton()}>
                          <img src={minusIcon} alt="minus" />
                        </button>
                        <span id="adults">{this.state.adults}</span>
                        <button type="button" onClick={() => this.adultPlusButton()}>
                          <img src={plusIcon} alt="plus" />
                        </button>
                      </div>
                    </div>
                    <div className="chlidren">
                      <label htmlFor="chlidren" data-detect="hotel_reservation_label_children">
                        Chlidren
                      </label>
                      <div className="chlidren_number">
                        <button type="button" onClick={() => this.chlidrenMinusButton()}>
                          <img src={minusIcon} alt="minus" />
                        </button>
                        <span id="children">{this.state.children}</span>
                        <button type="button" onClick={() => this.chlidrenPlusButton()}>
                          <img src={plusIcon} alt="plus" />
                        </button>
                      </div>
                    </div>
                    <div className="search_date">
                      <span></span>
                      <button type="button" onClick={() => this.getRoomList()} data-detect="hotel_reservation_button_search">
                        SEARCH
                      </button>
                    </div>
                  </div>

                  {this.state.isRoomList && hotelReservationList && hotelReservationList.availableRoomArray?.length > 0 && (
                    <div>
                      {hotelReservationList.availableRoomArray.map((hotelReservation, index) => (
                        <div className="room_list_container" key={index}>
                          <div className="select_list">
                            <div className="room_img">
                              {hotelReservation?.roomDetail?.roomCenterImage?.fileId && <img src={`${ServerUrl}/image/room/${hotelReservation.roomDetail.roomCenterImage.fileId}`} alt="room img" />}
                            </div>
                            <div className="room_list_contents">
                              <div>
                                <h3>{hotelReservation.roomDetail.name}</h3>
                                <div className="room_icon_container">
                                  <div className="size">
                                    <img src={roomSizeIcon} alt="Size" />
                                    <span>{hotelReservation.roomDetail.size}</span>
                                  </div>
                                  <div className="people">
                                    <img src={roomPeopleIcon} alt="People" />
                                    <span>{hotelReservation.roomDetail.standardOccupancy + "/" + hotelReservation.roomDetail.maxOccupancy}</span>
                                  </div>
                                  <div className="bedType">
                                    <img src={roomBedIcon} alt="Bed" />
                                    <span>{hotelReservation.roomDetail.bedType}</span>
                                  </div>
                                  <div className="view">
                                    <img src={roomWindowIcon} alt="View" />
                                    <span>{hotelReservation.roomDetail.view}</span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="amount_container">
                                  <p className="amount">
                                    <span className="currencyCode">{hotelReservation.currencyCode}</span>
                                    {hotelReservation.totalStayAmount}
                                  </p>
                                </div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.selectRoom(
                                      hotelReservation.roomDetail.id,
                                      hotelReservation.roomDetail.name,
                                      hotelReservation.roomDetail.size,
                                      hotelReservation.roomDetail.bedType,
                                      hotelReservation.roomDetail.standardOccupancy + "/" + hotelReservation.roomDetail.maxOccupancy,
                                      hotelReservation.roomDetail.view,
                                      hotelReservation.ratePlanCode,
                                      hotelReservation.roomTypeCode
                                    )
                                  }
                                >
                                  {MultiLanguage["language"][multiLanguageCode]["hotel_reservation_button_select"]}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {this.state.isOpenGuestInformation && this.state.isComplete === false && (
              <div id="guestInformation">
                <div className="guest_container">
                  <div className="close_button">
                    <img src={closeIcon} alt="Close" onClick={() => this.closeGuestInfo()} />
                  </div>
                  <div className="guest_title">
                    <h2 data-detect="hotel_reservation_title_enter_info">Enter Information</h2>
                  </div>
                  <div className="guest_info_form">
                    <div className="search_email">
                      <div>
                        <select id="searchType" name="searchType" className="search_type w150" value={this.state.searchType} onChange={(e) => this.onChangeValue(e, "searchType")}>
                          <option disabled value="" data-detect="hotel_reservation_select_select">
                            Select
                          </option>
                          <option value="email" data-detect="hotel_reservation_select_email">
                            Email
                          </option>
                          <option value="direct" data-detect="hotel_reservation_select_direct_input">
                            Direct input
                          </option>
                        </select>
                      </div>
                      <div className="search_email_form">
                        <input
                          type="search"
                          id="searchEmail"
                          className="w200"
                          name="searchEmail"
                          disabled={this.state.searchType == "" || this.state.searchType == "direct"}
                          value={this.state.searchEmail}
                          onChange={(e) => this.onChangeValue(e, "searchEmail")}
                        />
                        <button type="button" onClick={() => this.getProfileInfo()}>
                          <img src={glassIcon} alt="search" />
                        </button>
                      </div>
                    </div>
                    <div className="guest_title_type">
                      <div>
                        <label htmlFor="nameTitle" data-detect="hotel_reservation_label_title">
                          Title
                        </label>
                        <select
                          id="nameTitle"
                          className="title_type w200"
                          name="searchDirect"
                          value={this.state.nameTitle}
                          onChange={(e) => this.onChangeValue(e, "nameTitle")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        >
                          <option disabled value="" data-detect="hotel_reservation_select_select">
                            Select
                          </option>
                          {hotelReservationTitleTypeList &&
                            hotelReservationTitleTypeList.map((titleType, index) => (
                              <option key={index} value={titleType.title}>
                                {titleType.title}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="guest_name">
                      <div>
                        <label htmlFor="firstName" data-detect="hotel_reservation_label_first_name">
                          First Name
                        </label>
                        <input
                          type="text"
                          className="w425"
                          id="firstName"
                          name="searchDirect"
                          placeholder=""
                          autoComplete="off"
                          value={this.state.firstName}
                          onChange={(e) => this.onChangeValue(e, "firstName")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        />
                      </div>
                      <div>
                        <label htmlFor="lastName" data-detect="hotel_reservation_label_last_name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          className="w425"
                          id="lastName"
                          name="searchDirect"
                          placeholder=""
                          autoComplete="off"
                          value={this.state.lastName}
                          onChange={(e) => this.onChangeValue(e, "lastName")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        />
                      </div>
                    </div>
                    <div className="guest_alternative_name">
                      <div>
                        <label htmlFor="alternativeFirstName" data-detect="hotel_reservation_label_alter_first_name">
                          First Name (Alternative)
                        </label>
                        <input
                          type="text"
                          className="w425"
                          id="alternativeFirstName"
                          name="searchDirect"
                          placeholder=""
                          autoComplete="off"
                          value={this.state.alternativeFirstName}
                          onChange={(e) => this.onChangeValue(e, "alternativeFirstName")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        />
                      </div>
                      <div>
                        <label htmlFor="alternativeLastName" data-detect="hotel_reservation_label_alter_last_name">
                          Last Name (Alternative)
                        </label>
                        <input
                          type="text"
                          className="w425"
                          id="alternativeLastName"
                          name="searchDirect"
                          placeholder=""
                          autoComplete="off"
                          value={this.state.alternativeLastName}
                          onChange={(e) => this.onChangeValue(e, "alternativeLastName")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        />
                      </div>
                    </div>
                    <div className="guest_email">
                      <div>
                        <label htmlFor="email" data-detect="hotel_reservation_label_email">
                          Email
                        </label>
                        <input
                          type="text"
                          className="w425"
                          id="email"
                          name="searchDirect"
                          placeholder=""
                          autoComplete="off"
                          value={this.state.email}
                          onChange={(e) => this.onChangeValue(e, "email")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        />
                      </div>
                      <div>
                        <label htmlFor="phone" data-detect="hotel_reservation_label_phone">
                          Phone
                        </label>
                        <input
                          type="text"
                          className="w425"
                          id="phone"
                          name="searchDirect"
                          placeholder=""
                          autoComplete="off"
                          value={this.state.phone}
                          onChange={(e) => this.onChangeValue(e, "phone")}
                          disabled={this.state.searchType === "" || this.state.searchType !== "direct"}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <button type="button" id="addProfile" onClick={() => this.selectGuestInfo()} data-detect="hotel_reservation_button_select">
                      SELECT
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.isOpenEnterCardInfo && this.state.isComplete === false && (
              <div id="enterCardInfo">
                <div className="enter_card_container">
                  <div className="close_button">
                    <img src={closeIcon} alt="Close" onClick={() => this.closeEnterCardInfo()} />
                  </div>
                  <div className="enter_card_title">
                    <h2 data-detect="hotel_reservation_title_credit_card_info">Credit Card Information</h2>
                  </div>
                  <div className="card_info_form">
                    <div className="enter_card_type">
                      <div>
                        <label htmlFor="cardType" data-detect="hotel_reservation_label_card_type">
                          Card Type
                        </label>
                        <select id="cardType" className="card_type" name="cardType" value={this.state.cardType} onChange={(e) => this.onChangeValue(e, "cardType")}>
                          <option disabled value="0" data-detect="hotel_reservation_select_select">
                            Select
                          </option>
                          {hotelReservationCardTypeList &&
                            hotelReservationCardTypeList.map((cardType, index) => (
                              <option key={index} value={cardType.value}>
                                {cardType.description}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="enter_card_name">
                      <div>
                        <label htmlFor="cardHolderName" data-detect="hotel_reservation_label_holder_name">
                          Holder Name
                        </label>
                        <input type="text" id="cardHolderName" placeholder="" autoComplete="off" value={this.state.cardHolderName} onChange={(e) => this.onChangeValue(e, "cardHolderName")} />
                      </div>
                    </div>
                    <div className="enter_card_number">
                      <div>
                        <label htmlFor="cardNumber" data-detect="hotel_reservation_label_card_number">
                          Card Number
                        </label>
                        <input type="text" id="cardNumber" placeholder="" autoComplete="off" value={this.state.cardNumber} onChange={(e) => this.onChangeValue(e, "cardNumber")} />
                      </div>
                    </div>
                    <div className="enter_card_date">
                      <div>
                        <label htmlFor="month" data-detect="hotel_reservation_label_month">
                          Month
                        </label>
                        <select id="month" className="month" name="month" value={this.state.month} onChange={(e) => this.onChangeValue(e, "month")}>
                          <option disabled value="" data-detect="hotel_reservation_select_select">
                            Select
                          </option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="year" data-detect="hotel_reservation_label_year">
                          Year
                        </label>
                        <select id="year" className="year" name="year" value={this.state.year} onChange={(e) => this.onChangeValue(e, "year")}>
                          <option disabled value="" data-detect="hotel_reservation_select_select">
                            Select
                          </option>
                          {this.generateYearOptions()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button type="button" onClick={() => this.selectCardInfo()} data-detect="hotel_reservation_button_select">
                      SELECT
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.viewTerms != "" && this.state.isComplete === false && (
              <div id="viewTerms">
                <div className="view_terms_container">
                  <div className="close_button">
                    <img src={closeIcon} alt="Close" onClick={() => this.closeViewTerms()} />
                  </div>
                  <div id="view_terms">
                    {this.state.viewTerms === "terms" && (
                      <div>
                        {hotelReservationTermsConditionsDetail?.length > 0 &&
                          hotelReservationTermsConditionsDetail.map((item) => {
                            if (item.type === "TERMS") {
                              return (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: hotelReservationTermsConditionsDetail
                                      ?.filter((item) => item.type == "TERMS")
                                      .map((item) => item.content)
                                      .join(""),
                                  }}
                                  key={item.id}
                                ></div>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                    )}
                    {this.state.viewTerms === "privacy" && (
                      <div>
                        {hotelReservationPrivacyPolicyDetail?.length > 0 &&
                          hotelReservationPrivacyPolicyDetail.map((item) => {
                            if (item.type === "PRIVACY_POLICIES") {
                              return (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: hotelReservationPrivacyPolicyDetail
                                      ?.filter((item) => item.type === "PRIVACY_POLICIES")
                                      .map((item) => item.content)
                                      .join(""),
                                  }}
                                  key={item.id}
                                ></div>
                              );
                            } else {
                              return null;
                            }
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {this.state.isValidationBox && this.state.isComplete === false && (
              <div id="validationBox">
                <div className="validation_container">
                  <div id="validationText" data-detect={this.state.message}></div>
                  <div className="validation_button">
                    <button data-detect="hotel_reservation_button_ok" type="button" className="ok" onClick={() => this.setState({ isValidationBox: false })} />
                  </div>
                </div>
              </div>
            )}
          </article>
        </section>
      </div>
    );
  }
}

let mapStateToProps = OTAReservationData.mapStateToProps;
let mapDispatchToProps = OTAReservationData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OTAReservationView));
