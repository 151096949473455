import CommonComponent from "../../components/CommonComponent";

// modules
import * as authActions from "../../../redux/modules/Auth";
import * as userInfoActions from "../../../redux/modules/UserInfo";


class UserInfoListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      userInfoList: [],
      isAllChecked: false,
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userInfoList != this.props.userInfoList) {
      if (this.state.requestType == 'GET_USER_INFO_LIST') {
        this.onGetUserInfoListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_USER_INFO_ONE") {
      this.onDeleteUserInfoListResults(nextProps);
    }
  }

  // 검색시 redux에 조건에 맞는 list 요청
  requestGetUserInfoList = (pageNo, type, search) => {
    this.setState({
      requestType: "GET_USER_INFO_LIST",
    });
    this.props.onGetAllUserInfo(pageNo, type, search);
  };

  // redux에 순차적으로 delete UserInfo 요청
  requestDeleteUserInfo = (userInfoId) => {
    this.setState({
      requestType: "DELETE_USER_INFO_ONE",
    });
    this.props.onDeleteOne(userInfoId);
  };

  // get userInfo list results
  onGetUserInfoListResults = (props) => {
    if (props.userInfoList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.userInfoList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete userInfo results
  onDeleteUserInfoListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteUserInfo();
        } else {
          // 삭제 완료
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetUserInfoList(
      this.state.pageNo,
      this.state.type,
      this.state.search
    );
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, userInfoList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // onClickOK
  onClickOK = () => {
    const { checkedList } = this.state;

    if (checkedList.length > 0) {
      checkedList.forEach((userInfoId) => {
        this.requestDeleteUserInfo(userInfoId);
      });

      this.setState({ checkedList: [] });
    }
  };

  deleteSelectedUserInfo = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteUserInfo();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode][
          "button_no_select_delete_message"
        ],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteUserInfo = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.requestDeleteUserInfo(checkedList[0]);
    }
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (myDuty, userInfoList) => {
    const { checkedList } = this.state;
    const enabledCheckboxes = userInfoList.filter((userInfo) => {
      return (
        myDuty == "USER" ||
        (myDuty == "ADMIN" && userInfo.duty !== "USER") ||
        (myDuty == "SUPER_ADMIN" && userInfo.duty !== "SUPER_ADMIN")
      );
    });
    const numberOfEnabledCheckboxes =
      userInfoList.length - enabledCheckboxes.length;

    const isChecked =
      numberOfEnabledCheckboxes != 0 &&
      numberOfEnabledCheckboxes == checkedList.length;
    
    this.setState({
      isAllChecked: isChecked,
    })
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.sessionData,
      userInfoList: state.userInfo.userInfoList,
      failData: state.userInfo.failData,
      successDelete: state.userInfo.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAllUserInfo: (pageNo, type, search) =>
        dispatch(userInfoActions.onGetAllUserInfo(pageNo, type, search)),
      onDeleteOne: (userInfoId) =>
        dispatch(userInfoActions.onDeleteUserInfo(userInfoId)),
    };

    return returnProps;
  };
}

export default UserInfoListData;
