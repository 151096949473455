import React, { Component } from 'react';
import MyConectColors from '../../../components/colors/MyConectColors';

class LightSquareAddButton extends Component {
    render() {
        const { disabled } = this.props;

        const textColor = {
            color: !disabled ? MyConectColors.BG_PRIMARY.TEXT_DEFAULT : MyConectColors.BUTTON_TEXT_DISABLE_GREY,
        };

        return (
            <button className={`myConect_light_square_add_button ${disabled ? 'disabled' : ''}`} disabled={disabled} style={textColor}>
                <div className='myConect_buttton_subTitle'>
                    Add patient
                </div>
            </button>
        );
    }
}

export default LightSquareAddButton;
