// RecommendListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import RecommendListData from "../List/RecommendListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/recommend/recommendList.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class RecommendListView extends RecommendListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      name: "name",
      checkedList: [],
      // 임시값
      tempSearch: "",
      tempName: "name",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === 'POP') {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetRecommendList(this.state.pageNo, this.state.name, this.state.search);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const value = e.target.value;

    this.setState({ [stateName]: value });
  };

  //검색 버튼을 누르면
  onClickSearch = (e) => {
    const { history, location } = this.props;

    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        name: this.state.tempName,
      },
      () => {
        this.requestGetRecommendList(this.state.pageNo, this.state.name, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          name: this.state.tempName,
        },
        () => {
          this.requestGetRecommendList(this.state.pageNo, this.state.name, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState(
      {
        isShowAllLoading: true,
        tempSearch: this.state.search,
        tempName: this.state.name,
      },
      () => {
        // 페이지 클릭했을 때 넘어가는 로직
        this.requestGetRecommendList(pageNo, this.state.name, this.state.search); //  목록 요청
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if (isMoved) return;

    this.setState({
      isMoved: true,
    });
  };

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false,
    });
  };

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if (isMoved) {
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkRecommendDetail(id);
    }
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // //파람값 url로 전달
  linkRecommendDetail = (recommendId) => {
    this.props.history.push(`/recommendDetailPage?recommendId=${recommendId}`);
  };

  //insert
  linkRecommendAdd = () => {
    this.props.history.push('/recommendAddPage');
  };

  render() {
    let count;
    let recommendList = [];
    if (this.props.recommendList.length !== 0 && this.props.recommendList?.success == true) {
      count = this.props.recommendList.results.count;
      recommendList = this.props.recommendList.results.rows;
    }

    return (
      <div className="recommendList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="recommend_list_container">
          <div className="recommend_list_container_inner">
            <div className="title" data-detect="admin_recommend_app_list_title">
              RECOMMEND APP LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select id="name" className="select_name" name="name" onKeyUp={this.handleKeyUp} onChange={(e) => this.onChangeValue(e, "tempName")} value={this.state.tempName}>
                      <option data-detect="admin_recommend_app_list_select_name" value="name">
                        Name
                      </option>
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_recommend_app_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- recommend list table  --> */}
            <div className="recommend_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(recommendList, this.state.pageNo)}
                        checked={recommendList && this.state.checkedList.length == recommendList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_recommend_app_list_label_name">
                      Name
                    </div>
                    <div className="cell" data-detect="admin_recommend_app_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {recommendList?.length > 0 ? (
                    recommendList.map((recommend, index) => (
                      <div className="row tbodyRow" key={recommend.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={recommend.id}
                            id={recommend.id}
                            onClick={() => this.onClickInput(recommend.id, recommendList)}
                            checked={this.state.checkedList.includes(recommend.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={recommend.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(recommend.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {recommend.name}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(recommend.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {recommend && formatDateTimeSlash(recommend.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_recommend_app_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedRecommends();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_recommend_app_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkRecommendAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_recommend_app_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RecommendListData.mapStateToProps;
let mapDispatchToProps = RecommendListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecommendListView)
);
