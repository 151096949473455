import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import RecommendDetailData from "../Detail/RecommendDetailData";

//css
import "../../css/common.css";
import "../../css/recommend/recommendDetail.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class RecommendDetailView extends RecommendDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      recommendId: "",
      recommendDetail: null,
    };
  }

  // 첫 실행시 URL에서 recommendId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const recommendId = params.get("recommendId");
    this.setState({
      recommendId: recommendId,
    });
    this.requestGetRecommendOne(recommendId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkRecommendEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const recommendId = params.get("recommendId");
    this.props.history.push(`/recommendEditPage?recommendId=${recommendId}`);
  };



  render() {
    let recommendDetail = null;

    if (
      this.props.recommendDetail != null &&
      this.props.recommendDetail?.success == true
    ) {
      recommendDetail = this.props.recommendDetail.results;
    }

    return (
      <div className="recommendDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="recommendinfo_detail_container">
          <div className="recommendinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_recommend_app_detail_title"
            >
              RECOMMEND APP INFORMATION
            </div>
            <div className="recommendinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="recommend_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_recommend_app_detail_label_name"
                      >
                        Name
                      </div>
                      <div
                        className="detail_cell content recommend_name"
                        style={{ color: "white" }}
                      >
                        <span id="recommend_id">{recommendDetail?.name??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_recommend_app_detail_label_ios_scheme"
                      >
                        iOS Scheme
                      </div>
                      <div
                        className="detail_cell content recommend_ios_scheme"
                        style={{ color: "white" }}
                      >
                        <span id="recommend_id">
                          {recommendDetail?.iosScheme??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_recommend_app_detail_label_ios_itunes_url"
                      >
                        iOS iTunes URL
                      </div>
                      <div
                        className="detail_cell content recommend_ios_itunes_url"
                        style={{ color: "white" }}
                      >
                        {recommendDetail?.iosITunesUrl??"-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_recommend_app_detail_label_aos_package_id"
                      >
                        AOS Package ID
                      </div>
                      <div
                        className="detail_cell content recommend_aos_package_id"
                        style={{ color: "white" }}
                      >
                        {recommendDetail?.aosPackageId??"-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_recommend_app_detail_label_aos_play_store_url"
                      >
                        AOS Play Store URL
                      </div>
                      <div
                        className="detail_cell content recommend_aos_package_aos_play_store_url"
                        style={{ color: "white" }}
                      >
                        {recommendDetail?.aosPlayStoreUrl??"-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_recommend_app_detail_label_updated_at"
                      >
                        Update Date
                      </div>
                      <div
                        className="detail_cell content recommend_aos_package_updated_at"
                        style={{ color: "white" }}
                      >
                        {recommendDetail ?
                          formatDateTimeDot(recommendDetail.updated_at) : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_recommend_app_detail_button_go_to_list"
                >
                  {" "}
                  Go to List{" "}
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkRecommendEdit();
                  }}
                  data-detect="admin_recommend_app_detail_button_edit"
                >
                  {" "}
                  Edit{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RecommendDetailData.mapStateToProps;
let mapDispatchToProps = RecommendDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecommendDetailView)
);
