import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import ApplicationEndPointAddData from "../Add/ApplicationEndPointAddData";



//css
import "../../css/applicationEndPoint/applicationEndPointAdd.css";
import "../../css/common.css";

class ApplicationEndPointAddView extends ApplicationEndPointAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      packageName: "",
      os: "",
      version: "",
      url: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change packageName
  onChangePackageName = (e) => {
    const inputValue = e.target.value;
    this.setState({ packageName: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change os
  onChangeos = (e) => {
    const inputValue = e.target.value;
    this.setState({ os: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change version
  onChangeVersion = (e) => {
    const inputValue = e.target.value;
    this.setState({ version: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change url
  onChangeUrl = (e) => {
    const inputValue = e.target.value;
    this.setState({ url: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("packageName", "admin_endpoint_add_validation_package_name") &&
      this.confirmField("os", "admin_endpoint_add_validation_os") &&
      this.confirmField("version", "admin_endpoint_add_validation_version") &&
      this.confirmField("url", "admin_endpoint_add_validation_url")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddApplicationEndPoint(this.state.packageName, this.state.os, this.state.version, this.state.url);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/applicationEndPointListPage");
  };

  render() {
    return (
      <div className="addApplicationEndPoint">
        {this.state.isShowAllLoading && <Loading />}
        <div className="country_add_container">
          <div className="addcountry_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_endpoint_add_title">ADD APPLICATION ENDPOINT</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addapplicationEndPoint_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addapplicationEndPoint_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_endpoint_add_label_package_name">Package Name</p>
                      </div>
                      <div className="detail_cell content applicationEndPoint_name">
                        <select id="package_name" className="package_name_select" name="package_name" value={this.state.packageName} onChange={this.onChangePackageName}>
                          <option disabled selected value="" data-detect="admin_endpoint_add_placeholder_choose">
                            Choose
                          </option>
                          <option value="net.myconect.staff" data-detect="admin_endpoint_add_select_os_staff">
                            Staff
                          </option>
                          <option value="net.myconect.guest" data-detect="admin_endpoint_add_select_os_guest">
                            Guest
                          </option>
                          <option value="net.myconect.staff.parking" data-detect="admin_endpoint_add_select_os_staff_parking">
                            Staff Parking
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_endpoint_add_label_os">OS</p>
                      </div>
                      <div className="detail_cell content applicationEndPoint_os">
                        <select id="os" className="os_select" name="os" value={this.state.os} onChange={this.onChangeos}>
                          <option disabled selected value="" data-detect="admin_endpoint_add_placeholder_choose">
                            Choose
                          </option>
                          <option value="iOS" data-detect="admin_endpoint_add_select_os_ios">
                            iOS
                          </option>
                          <option value="AOS" data-detect="admin_endpoint_add_select_os_aos">
                            AOS
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_endpoint_add_label_version">Version</p>
                      </div>
                      <div className="detail_cell content applicationEndPoint_version">
                        <input type="text" id="version" name="version" autoComplete="off" value={this.state.version} onChange={this.onChangeVersion} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_endpoint_add_label_url">URL</p>
                      </div>
                      <div className="detail_cell content applicationEndPoint_url">
                        <select id="url" className="url_select" name="url" value={this.state.url} onChange={this.onChangeUrl}>
                          <option disabled selected value="" data-detect="admin_endpoint_add_placeholder_choose">
                            Choose
                          </option>
                          <option value="http://holich.iptime.org:8300">http://holich.iptime.org:8300</option>
                          <option value="https://holichsoft.com:8082">https://holichsoft.com:8082</option>
                          <option value="https://dev.myconect.net">https://dev.myconect.net</option>
                          <option value="https://rel.myconect.net">https://rel.myconect.net</option>
                          <option value="https://app.api.beta.myconect.net">https://app.api.beta.myconect.net</option>
                          <option value="https://app.api.rel.myconect.net">https://app.api.rel.myconect.net</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_endpoint_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_endpoint_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationEndPointAddData.mapStateToProps;
let mapDispatchToProps = ApplicationEndPointAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationEndPointAddView));
