// AmenityListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import AmenityListData from "../List/AmenityListData";

//css
import "../../css/amenity/amenityList.css";
import "../../css/common.css";
import "../../css/common/myConectTable.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class AmenityListView extends AmenityListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetAmenityList(this.state.pageNo, this.state.search);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
      },
      () => {
        this.requestGetAmenityList(this.state.pageNo, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
        },
        () => {
          this.requestGetAmenityList(this.state.pageNo, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search }, () => {
      this.requestGetAmenityList(pageNo, this.state.search); // amenity 목록 요청
    });
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, amenityList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  deleteSelectedAmenitys = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteAmenity();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (amenityList) => {
    const { checkedList } = this.state;
    const allAmenityCnt = amenityList.length;
    const isChecked = allAmenityCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : amenityList.map((amenity) => amenity.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  //copy amenity
  copyAmenity = () => {
    const { checkedList } = this.state;

    if (checkedList.length == 1) {
      const copyId = checkedList[0];
      this.props.history.push(`/amenitycopyPage?copyId=${copyId}`);
    } else if (checkedList.length > 1) {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_only_one_amenity_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
      //초기화
      this.setState({
        checkedList: [],
      });
    } else {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_amenity_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if (isMoved) return;

    this.setState({
      isMoved: true,
    });
  };

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false,
    });
  };

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if (isMoved) {
      // 먀우스로 드래그 했을 경우 detail페이지로 이동하지 않음
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkAmenityDetail(id);
    }
  };

  //파람값 url로 전달
  linkAmenityDetail = (amenityId) => {
    this.props.history.push(`/amenityDetailPage?amenityId=${amenityId}`);
  };

  //insert
  linkAmenityAdd = () => {
    this.props.history.push("/amenityAddPage");
  };

  // redux에 delete amenity 요청
  deleteAmenity = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      // this.props.onClearAmenityDeleteState()
      this.requestDeleteAmenity(checkedList[0]);
    }
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState(
      {
        pageNo: newPageNo,
        checkedList: [],
      },
      () => {
        history.replace(location.pathname, { ...location.state, pageNo: newPageNo });
      }
    );
  };

  render() {
    let count;
    let amenityList = [];
    if (this.props.amenityList && this.props.amenityList?.success == true) {
      count = this.props.amenityList.results.count;
      amenityList = this.props.amenityList.results.rows;
    }

    return (
      <div className="amenityList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="amenity_list_container">
          <div className="amenity_list_container_inner">
            <div className="title" data-detect="admin_amenity_list_title">
              AMENITY LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_amenity_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- amenity list table  --> */}
            <div className="amenity_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(amenityList, this.state.pageNo)}
                        checked={amenityList && this.state.checkedList.length == amenityList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_amenity_list_label_type">
                      Type
                    </div>
                    <div className="cell" data-detect="admin_amenity_list_label_group">
                      Group
                    </div>
                    <div className="cell" data-detect="admin_amenity_list_label_code">
                      Code
                    </div>
                    <div className="cell" data-detect="admin_amenity_list_label_name">
                      Name
                    </div>
                    <div className="cell" data-detect="admin_amenity_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {amenityList?.length > 0 ? (
                    amenityList.map((amenity, index) => (
                      <div className="row tbodyRow" key={amenity.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={amenity.id}
                            id={amenity.id}
                            onClick={() => this.onClickInput(amenity.id, amenityList)}
                            checked={this.state.checkedList.includes(amenity.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={amenity.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(amenity.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {amenity.type}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(amenity.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {amenity.group}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(amenity.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {amenity.code}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(amenity.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {amenity.name}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(amenity.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {amenity && formatDateTimeDot(amenity.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_amenity_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedAmenitys();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_amenity_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkAmenityAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_amenity_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AmenityListData.mapStateToProps;
let mapDispatchToProps = AmenityListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmenityListView));
