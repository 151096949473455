import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import ApplicationTranslationAddData from "../Add/ApplicationTranslationAddData";



//css
import "../../css/applicationTranslation/applicationTranslationAdd.css";
import "../../css/common.css";

class ApplicationTranslationAddView extends ApplicationTranslationAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      appType: "",
      osType: "",
      appVersion: "",
      version: "",
      translationFile: null,
      translationText: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change appType
  onChangeAppType = (e) => {
    const inputValue = e.target.value;
    this.setState({ appType: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change osType
  onChangeOsType = (e) => {
    const inputValue = e.target.value;
    this.setState({ osType: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change appVersion
  onChangeAppVersion = (e) => {
    const inputValue = e.target.value;
    this.setState({ appVersion: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change version
  onChangeVersion = (e) => {
    const inputValue = e.target.value;
    this.setState({ version: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("appType", "admin_application_translation_add_validation_application_type") &&
      this.confirmField("osType", "admin_application_translation_add_validation_os_type") &&
      this.confirmField("appVersion", "admin_application_translation_add_validation_application_version") &&
      this.confirmField("version", "admin_application_translation_add_validation_version") &&
      this.confirmField("translationFile", "admin_application_translation_add_validation_translation_file")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(
      {
        translationFile: file,
        translationText: file ? file.name : "ex) MyCONECT.png",
      },
      () => {
        this.onCheckValidation(); // 파일이 선택되면 바로 검증 실행
      }
    );
  };

  // 데이터 저장을 로딩으로 감싸기
  add_btn = () => {
    const { copyId } = this.state;
    this.onSubmit();
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = async () => {
    this.setState({
      isShowAllLoading: true,
    });
    await this.requestAddApplicationTranslation(this.state.appType, this.state.osType, this.state.appVersion, this.state.version, this.state.translationFile, this.state.translationText);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/applicationTranslationListPage");
  };

  render() {
    return (
      <div className="addApplicationTranslation">
        {this.state.isShowAllLoading && <Loading />}
        <div className="applicationTranslation_add_container">
          <div className="addapplicationTranslation_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_application_translation_add_title">ADD APPLICATION TRANSLATION</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addapplicationTranslation_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addapplicationTranslation_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_add_label_admin_application_type">Application Type</p>
                      </div>
                      <div className="detail_cell content">
                        <select id="appType" className="appType_select" name="appType" onChange={this.onChangeAppType}>
                          <option disabled value="" data-detect="admin_application_translation_add_placeholder_admin_application_type">
                            Application Type
                          </option>
                          <option value="GUEST" data-detect="admin_application_translation_add_select_admin_application_type_guest">
                            Guest
                          </option>
                          <option value="STAFF" data-detect="admin_application_translation_add_select_admin_application_type_staff">
                            Staff
                          </option>
                          <option value="PARKING_STAFF" data-detect="admin_application_translation_add_select_admin_application_type_parking_staff">
                            Parking Staff
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_add_label_os_type">OS Type</p>
                      </div>
                      <div className="detail_cell content">
                        <select id="osType" className="osType_select" name="osType" onChange={this.onChangeOsType}>
                          <option disabled value="" data-detect="admin_application_translation_add_placeholder_os_type">
                            OS
                          </option>
                          <option value="AOS" data-detect="admin_application_translation_add_select_os_type_aos">
                            AOS
                          </option>
                          <option value="iOS" data-detect="admin_application_translation_add_select_os_type_ios">
                            iOS
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_add_label_admin_application_version">Application Version</p>
                      </div>
                      <div className="detail_cell content applicationTranslation_appVersion">
                        <input
                          type="text"
                          id="appVersion"
                          name="appVersion"
                          data-detect-placeholder="admin_application_translation_add_label_admin_application_version"
                          placeholder="admin_application_translation_add_label_admin_application_version"
                          autoComplete="off"
                          value={this.state.appVersion}
                          onChange={this.onChangeAppVersion}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_add_label_version">Version</p>
                      </div>
                      <div className="admin_application_translation_add_label_version">
                        <input
                          type="text"
                          id="version"
                          name="version"
                          data-detect-placeholder="admin_application_translation_add_label_version"
                          placeholder="Version"
                          autoComplete="off"
                          value={this.state.version}
                          onChange={this.onChangeVersion}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_application_translation_add_label_translation_file">
                        Translation File
                      </div>
                      <div className="file_upload_button_container" style={{ display: "flex" }}>
                        <form>
                          <label htmlFor="translationFile" className="file_upload_button" data-detect="admin_application_translation_add_button_upload">
                            Upload
                          </label>
                          <input
                            type="file"
                            id="translationFile"
                            accept=".jpg, .jpeg, .png, .gif, .json"
                            onChange={this.onFileChange}
                            title="Upload"
                            style={{ display: "none" }} // input 요소를 화면에서 감추기
                          />
                        </form>
                        {this.state.translationText ? <p className="file_textName">{this.state.translationText}</p> : <p className="file_textName">{"ex) My conect.png"}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_application_translation_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_application_translation_add_button_add" onClick={this.add_btn} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationTranslationAddData.mapStateToProps;
let mapDispatchToProps = ApplicationTranslationAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationTranslationAddView));
