import CommonComponent from "../../components/CommonComponent";

//modules
import * as passwordActions from "../../../redux/modules/Password";


class PasswordEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_PASSWORD") {

      this.onEditPasswordResults(props);
    } 
    if(this.state.requestType == "MATCH_PASSWORD") {
      this.onMatchPasswordResults(props);
    }
  }

   // redux에 match password 요청
  requestMatchPassword = () => {
    this.setState({ requestType: "MATCH_PASSWORD" });
    const { oldPassword } = this.state;
    const email = this.props.authData.results.email;
    this.props.onMatchPassword(email, oldPassword);
  };

  // redux에 edit password 요청
  requestEditPassword = () => {
    this.setState({ requestType: "EDIT_PASSWORD" });
    const { oldPassword, newPassword } = this.state;
    this.props.onEditPassword(oldPassword, newPassword);
  };

  onMatchPasswordResults(props) {
    if (props.matchPassword == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
      
    if (props.matchPassword?.success == true) {
      this.requestEditPassword();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_password_incorrect_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  }

  onEditPasswordResults(props) {
    if (props.password == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.password?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_modify"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  }

  onEditSuccess = () => {
    this.props.history.push("/login");
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      authData: state.auth.sessionData,
      matchPassword: state.password.matchPassword,
      password: state.password.passwordData,
      failData: state.password.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onMatchPassword: (email, password) => dispatch(passwordActions.onMatchPassword(email, password)),
      onEditPassword: (oldPassword, newPassword) => dispatch(passwordActions.onEditPassword(oldPassword, newPassword)),
    };

    return returnProps;
  };
}

export default PasswordEditData;
