import CommonComponent from "../../../components/CommonComponent.js";

// modules
import * as termsAndConditionActions from "../../../../redux/modules/TermsAndCondition.js";

class AgreementHistoryDetailListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      agreementHistoryList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.agreementHistoryList != this.props.agreementHistoryList) {
      if (this.state.requestType == 'GET_AGREEMENT_HISTORY_LIST') {
        this.onGetAgreementHistoryDetailListResults(nextProps);
      }
    }
  }



  // redux에 get agreementHistory list 요청
  requestGetAgreementHistoryList = (pageNo, email, search) => {
    this.setState({
      requestType: "GET_AGREEMENT_HISTORY_LIST",
    });
    this.props.onGetAll(pageNo, email, search);
  };

  // get agreementHistory list results
  onGetAgreementHistoryDetailListResults = (props) => {
    if (props.agreementHistoryList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.agreementHistoryList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };


  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      agreementHistoryList: state.termsAndCondition.agreementHistoryList,
      failData: state.termsAndCondition.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, email, search) =>
        dispatch(
          termsAndConditionActions.onGetAllAgreementHistoryList(
            pageNo,
            email,
            search
          )
        ),
      // onDeleteOne: (agreementHistoryId) =>
      //   dispatch(termsAndConditionActions.onDelete(agreementHistoryId)),
    };

    return returnProps;
  };
}

export default AgreementHistoryDetailListData;
