import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import serverUrl from "../../../react/common/ServerUrl";
import { Loading } from "../../components/Paths";
import HotelEditData from "../Edit/HotelEditData";


//css
import "../../css/common.css";
import "../../css/hotel/hotelEdit.css";

class HotelEditView extends HotelEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      hotelId: "",
      region: "",
      regionId: "",
      country: "",
      countryId: "",
      city: "",
      countryName: "",
      logoText: "",
      logoUrl: "",
      paymentServerURL: "",
      isDirectPayable: "",
      isCardTokenizable: "",
      isShow: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  /* --------------------------------------------------- Life cycle --------------------------------------------------- */
  // 첫 실행시 URL에서 hotelId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const hotelId = params.get("hotelId");
    this.setState({
      hotelId: hotelId,
      region: this.props.hotelDetail?.results.region,
      country: this.props.hotelDetail?.results.country,
      city: this.props.hotelDetail?.results.city,
      countryName: this.props.hotelDetail?.results.countryName,
      paymentServerURL: this.props.paymentSetting?.results.paymentServerURL,
      isDirectPayable: this.props.paymentSetting?.results.isDirectPayable,
      isCardTokenizable: this.props.paymentSetting?.results.isCardTokenizable,
      isShow: this.props.hotelDetail?.results.isShow,
    });
    this.requestGetRegion(); //region 함수 호출
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- Event handler ------------------------------------------------- */
  // change region
  onChangeRegion = (e, regionId) => {
    const inputValue = e.target.value;
    this.setState({ region: inputValue, regionId: regionId, isShowAllLoading: true, country: "", city: "" }, () => {
      this.requestGetCountry(regionId); //country 함수 호출
      this.onCheckValidation();
    });
  };
  // change country
  onChangeCountry = (e, countryId) => {
    const inputValue = e.target.value;
    const regionId = this.state.regionId;
    this.setState({ country: inputValue, countryId: countryId, isShowAllLoading: true, city: "" }, () => {
      this.requestGetCity(regionId, countryId); //country 함수 호출
      this.onCheckValidation();
    });
  };
  // change city
  onChangeCity = (e) => {
    const inputValue = e.target.value;
    this.setState({ city: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change country name
  onChangeCountryName = (e) => {
    const inputValue = e.target.value;
    this.setState({ countryName: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const value = e.target.value;
    this.setState({ [stateName]: value }, () => {
      this.onCheckValidation();
    });
  };

  // change value of state  "N" <=> "Y"
  onChangeRadio = (e) => {
    const name = e.target.name;
    if (e.target.value == "Y") {
      this.setState(
        {
          [name]: "Y",
        },
        () => this.onCheckValidation()
      );
    } else {
      this.setState(
        {
          [name]: "N",
        },
        () => this.onCheckValidation()
      );
    }
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    // this.props.onClearHotelEditState()
    this.props.showTopPopupDialog(
      "N",
      // "Are you sure you want to delete it?",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editHotel();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editHotel = () => {
    // 모든 필드가 값이 있으면 버튼을 비활성화
    document.querySelector(".edit_btn").setAttribute("disabled", "disabled");
    // 현재 선택된 값들을 default로 저장
    this.requestEditHotel(this.state.hotelId, this.state.region, this.state.country, this.state.city);
  };

  //detail로 돌아가기
  onBackBtn = () => {
    const { hotelId } = this.state;
    this.props.history.push(`/hotelDetailPage?hotelId=${hotelId}`);
  };

  /* ------------------------------------------------- Other functions ------------------------------------------------ */
  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value == "" || value == null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("region", "admin_hotel_edit_validation_region") &&
      this.confirmField("country", "admin_hotel_edit_validation_country") &&
      this.confirmField("city", "admin_hotel_edit_validation_city") &&
      this.confirmField("countryName", "admin_hotel_edit_validation_country_name") &&
      this.confirmField("isShow", "admin_hotel_edit_validation_is_show") 
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file image 미리보기
  preview = () => {
    if (!this.state.logoFile || !this.state.logoFile instanceof Blob) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }
    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        logoUrl: reader.result,
        isShowAllLoading: false,
      });
    };
    reader.readAsDataURL(this.state.logoFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(
      { logoFile: file, logoText: file ? file.name : "" }, // 파일이 있는 경우 파일 이름을 설정
      this.preview
    );
  };

  render() {
    let hotelDetail = null;
    let regionList = null;
    let countryList = null;
    let cityList = null;
    let countryNameList = null;

    if (this.props.hotelDetail != null && this.props.hotelDetail?.success == true) {
      hotelDetail = this.props.hotelDetail?.results;
    }
    if (this.props.regionData != null && this.props.regionData?.success == true) {
      regionList = this.props.regionData.results.rows;
    }
    if (this.props.countryData != null && this.props.countryData?.success == true) {
      countryList = this.props.countryData.results.rows;
    }
    if (this.props.cityData != null && this.props.cityData?.success == true) {
      cityList = this.props.cityData.results.rows;
    }
    if (this.props.countryNameData != null && this.props.countryNameData?.success == true) {
      countryNameList = this.props.countryNameData.results.rows;
    }

    return (
      <div className="hotelEdit">
        {this.state.isShowAllLoading && <Loading />}
          <div className="hotelinfo_detail_container">
            <div className="hotelinfo_detail_container_inner">
              <div className="title_container">
                <div className="main_text">
                  <p data-detect="admin_hotel_edit_title">Hotel Information</p>
                </div>
                <div className="sub_text">
                  <p className="message" data-detect={this.state.errorMessage}></p>
                </div>
              </div>
              <div className="hotelinfo_container">
                <div className="detail_table">
                  <div className="detail_tbody">
                    <div className="hotel_info">
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_hotel_no">
                          Hotel No.
                        </div>
                        <div className="detail_cell content hotel_no" style={{ color: "white" }}>
                          <span id="hotel_id">{hotelDetail?.id??"-"}</span>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_name">
                          Name
                        </div>
                        <div className="detail_cell content hotel_name" style={{ color: "white" }}>
                          <span id="hotel_id">{hotelDetail?.name??"-"}</span>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_address">
                          Address
                        </div>
                        <div className="detail_cell content hotel_address" style={{ color: "white" }}>
                          <span id="hotel_id">{hotelDetail?.address??"-"}</span>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_region">
                          Region
                        </div>
                        <div>
                          <select
                            className="regionSelect"
                            id="regionSelect"
                            onChange={(e) => {
                              const selectedRow = this.props.regionData?.results.rows.find((row) => row.name == e.target.value);
                              this.onChangeRegion(e, selectedRow.id);
                            }}
                            value={this.state.region ?? ""}
                          >
                            <option value="" disabled data-detect="admin_hotel_edit_label_choose">
                              Select
                            </option>
                            {regionList &&
                              regionList.length > 0 &&
                              regionList.map((region) => (
                                <option key={region.id} value={region.name}>
                                  {region.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_country">
                          Country
                        </div>
                        <div className="detail_cell content hotel_country">
                          <select
                            className="countrySelect"
                            id="countrySelect"
                            onChange={(e) => {
                              const selectedCountry = this.props.countryData?.results.rows.find((row) => row.name == e.target.value);
                              this.onChangeCountry(e, selectedCountry.id);
                            }}
                            value={this.state.country ?? ""}
                          >
                            <option value="" disabled data-detect="admin_hotel_edit_label_choose">
                              Select
                            </option>
                            {countryList &&
                              countryList.length > 0 &&
                              countryList.map((country) => (
                                <option key={country.id} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_city">
                          City
                        </div>
                        <div className="detail_cell content hotel_city">
                          <select className="citySelect" id="citySelect" onChange={this.onChangeCity} value={this.state.city ?? ""}>
                            <option value="" disabled data-detect="admin_hotel_edit_label_choose">
                              Select
                            </option>
                            {cityList &&
                              cityList.length > 0 &&
                              cityList.map((city) => (
                                <option key={city.id} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_country_name">
                          Country Name
                        </div>
                        <div className="detail_cell content hotel_country_name">
                          <select className="citySelect" id="citySelect" onChange={this.onChangeCountryName} value={this.state.countryName ?? ""}>
                            <option value="" disabled data-detect="admin_hotel_edit_label_choose">
                              Select
                            </option>
                            {countryNameList &&
                              countryNameList.length > 0 &&
                              countryNameList.map((country) => (
                                <option key={country.id} value={country.countryName}>
                                  {country.countryName}
                                </option>
                              ))}
                          </select>
                        </div>
                    </div>
                      {/* payment setting */}
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_payment_server_url">
                          Payment Server URL
                        </div>
                        <div className="detail_cell content hotel_paymentURL">
                          <input
                            type="text"
                            id="paymentServerURL"
                            className="input"
                            placeholder="Payment Server URL"
                            value={this.state.paymentServerURL}
                            onChange={(e) => this.onChangeValue(e, "paymentServerURL")}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_is_direct_payable">
                          Is Direct Payable
                        </div>
                        <div className="detail_cell content hotel_isDirectPayable radio_container">
                          <div className="radio_inner">
                            <label>
                              <input
                                type="radio"
                                name="isDirectPayable"
                                value="Y"
                                checked={this.state.isDirectPayable == "Y"}
                                onChange={this.onChangeRadio}
                                data-detect="admin_hotel_edit_button_yes"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="radio_inner">
                            <label>
                              <input type="radio" name="isDirectPayable" value="N" checked={this.state.isDirectPayable == "N"} onChange={this.onChangeRadio} data-detect="admin_hotel_edit_button_no" />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_is_card_tokenizable">
                          Is Card Tokenizable
                        </div>
                        <div className="detail_cell content hotel_isCardTokenizable radio_container">
                          <div className="radio_inner">
                            <label>
                              <input
                                type="radio"
                                name="isCardTokenizable"
                                value="Y"
                                checked={this.state.isCardTokenizable == "Y"}
                                onChange={this.onChangeRadio}
                                data-detect="admin_hotel_edit_button_yes"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="radio_inner">
                            <label>
                              <input
                                type="radio"
                                name="isCardTokenizable"
                                value="N"
                                checked={this.state.isCardTokenizable == "N"}
                                onChange={this.onChangeRadio}
                                data-detect="admin_hotel_edit_button_no"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_is_show">
                          Is Show
                        </div>
                        <div className="detail_cell content hotel_is_show radio_container">
                          <div className="radio_inner">
                            <label>
                              <input
                                type="radio"
                                name="isShow"
                                value="Y"
                                checked={this.state.isShow == "Y"}
                                onChange={this.onChangeRadio}
                                data-detect="admin_hotel_edit_button_yes"
                              />
                              Yes
                            </label>
                          </div>
                          <div className="radio_inner">
                            <label>
                              <input
                                type="radio"
                                name="isShow"
                                value="N"
                                checked={this.state.isShow == "N"}
                                onChange={this.onChangeRadio}
                                data-detect="admin_hotel_edit_button_no"
                              />
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_hotel_edit_label_logo">
                          Logo
                        </div>
                        <div className="logo__box">
                          <div className="img__box">
                            {this.state.logoUrl ? (
                              // 이미지 URL 변경시 변경된 이미지 노출
                              <img className="preImg" src={this.state.logoUrl} alt="Hotel Logo" />
                            ) : (
                              // 새로운 파일을 선택하기 전 기존 이미지 받아오기
                              <img className="preImg" src={serverUrl + "/image/hotel/image/" + hotelDetail?.logoFileId} alt="Hotel Logo" />
                            )}
                          </div>
                          <div className="file_upload_button_container" style={{ display: "flex" }}>
                            <div>
                              <label htmlFor="logo_image_file" className="file_upload_button" data-detect="admin_hotel_edit_button_upload">
                                Upload
                              </label>
                              <input id="logo_image_file" type="file" name="logoFile" className="image_files" onChange={this.onFileChange} style={{ display: "none" }} />
                            </div>

                            {this.state.logoText ? <p className="file_textName">{this.state.logoText}</p> : <p className="file_textName">{"ex) MyCONECT.png"}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button_form_container">
                <div className="cancel_btn_container">
                  <button className="cancel_btn" onClick={this.cancelBtn} data-detect="admin_hotel_edit_button_cancel">
                    Cancel
                  </button>
                </div>
                <div className="ok_btn_container">
                  <button className="edit_btn" onClick={this.onSubmit} data-detect="admin_hotel_edit_button_ok" disabled={!this.state.isCheckValidation}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

let mapStateToProps = HotelEditData.mapStateToProps;
let mapDispatchToProps = HotelEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HotelEditView));
