import { getYear, isAfter } from 'date-fns';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// img

import "../dateRangeCalendar/dateRangeCalendar.css";

const YEARS = Array.from({ length: getYear(new Date()) + 1 - 2000 }, (_, i) => getYear(new Date()) - i);
const MONTHS = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
];

class DateRangeCalendar extends Component {
  constructor(props) {
    super(props);
    // this.todayDate = this.todayDate.bind(this);

    // 오늘 날짜를 Default로 설정
    const today = new Date();
    this.state = {
      startDate: today,
      endDate: today,
      getSelectedDate: today,
      isInitialDate: true,
    };
  }

  // todayDate(date) {
  //   return todayDate(date);
  // }

  setDateRange(date) {
    const { disabled } = this.props;

    // disabled prop이 존재하면 선택된 날짜를 변경하지 않음
    if (!disabled) {
      this.setState((prevState) => ({
        startDate: date[0] || prevState.startDate,
        endDate: date[1] || prevState.endDate,
        getSelectedDate: date[0] || prevState.startDate,
        isInitialDate: false,
      }));
    }
  }

  // DatePicker 내부에서 월 변경 시 강제로 리렌더링
  onMonthChange = (date) => {
    this.setState({
      getSelectedDate: date,
      isInitialDate: false,
    });
    this.forceUpdate();
  };

  //날짜 포맷팅 함수
  formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // 오늘 날짜로부터 1년 이상 차이나는지 확인
  errorTest = (date) => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return !isAfter(date, oneYearAgo);
  };

  render() {
    const { disabled } = this.props;
    const { startDate, endDate, getSelectedDate } = this.state;

    const formatStartDate = startDate ? this.formatDate(startDate.toLocaleDateString()) : 'None';
    const formatEndDate = endDate ? this.formatDate(endDate.toLocaleDateString()) : 'None';

    return (
      <div className="selectedDateRangeContainer">
        <div>
          <div className={`selectedDate ${this.errorTest(getSelectedDate) ? 'error' : ''}`}>
            {formatStartDate} - {formatEndDate}
          </div>
          <div className={`datePickerWrapper ${this.errorTest(getSelectedDate) ? 'error' : ''}`}>
            <DatePicker
              selectsRange={true}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={(update) => {
                this.setState({
                  startDate: update[0],
                  endDate: update[1],
                })
              }}
            // isClearable={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DateRangeCalendar;
