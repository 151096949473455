// IDRecognitionAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as idRecognitionActions from "../../../redux/modules/IDRecognition";

class IDRecognitionDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_ID_RECOGNITION_ONE") {

      this.onGetIDRecognitionOneResults(props);
    }
  }

  // redux에 get idRecognition Detail 요청
  requestGetIDRecognitionOne = (idRecognitionId) => {
    this.setState({
      requestType: "GET_ID_RECOGNITION_ONE",
    });
    this.props.onGetOne(idRecognitionId);
  };

  // get idRecognition One results
  onGetIDRecognitionOneResults = (props) => {
    if (props.idRecognitionDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.idRecognitionDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_id_recognition_information_detail"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      idRecognitionDetail: state.idRecognition.idRecognitionDetail,
      failData: state.idRecognition.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (idRecognitionId) => dispatch(idRecognitionActions.onGetOne(idRecognitionId)),
    };
  };
}

export default IDRecognitionDetailData;
