// TransferLogListView.js

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import TransactionLogListData from "../List/transactionLogData";

//datePicker
import DatePicker from 'react-datepicker';


//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/transfer/transactionLog/transactionLogList.css";

// icon
import CalendarIcon from '../../../img/data_icon.svg';

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class TransactionLogListView extends TransactionLogListData {
  constructor(props) {
    super(props);
    
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      movementStatus: "",
      search: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempSearch: "",
      tempMovementStatus: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // Date 설정
      const startDate = new Date();
      const yesterday = startDate.getDate() - 1;
      startDate.setHours(0, 0, 0, 0);
      startDate.setDate(yesterday);

      const endDate = new Date();
      endDate.setHours(23, 59, 59, 0);

      this.setState(
        {
          startDate: startDate, // startDate를 어제로 설정
          endDate: endDate,
          tempStartDate: startDate,
          tempEndDate: endDate,
        },
        () => {
          this.requestGetTransactionLogList(this.state.pageNo, this.state.movementStatus, this.state.search, this.formatDateISO(startDate), this.formatDateISO(endDate));
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = "";

    if (stateName == "tempStartDate" || stateName == "tempEndDate") {
      value = e;
    } else {
      value = e.target.value;
    }

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        movementStatus: this.state.tempMovementStatus,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetTransactionLogList(this.state.pageNo, this.state.movementStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          movementStatus: this.state.tempMovementStatus,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetTransactionLogList(this.state.pageNo, this.state.movementStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        }
      );
      history.replace(location.pathname, this.state);
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempEmail: this.state.email, tempStartDate: this.state.startDate, tempEndDate: this.state.endDate }, () => {
      this.requestGetTransactionLogList(pageNo, this.state.movementStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate)); // 호텔 목록 요청
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linktransactionLogDetail(id);
    }
  }

  //파람값 url로 전달
  linktransactionLogDetail = (transactionLogId) => {
    this.props.history.push(
      `/transactionLogDetailPage?transactionLogId=${transactionLogId}`
    );
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if(!date || date == "") return
    return date.toISOString();
  };

  render() {
    let count;
    let transactionLogList = [];
    if (
      this.props.transactionLogList &&
      this.props.transactionLogList?.success == true
    ) {
      transactionLogList = this.props.transactionLogList.results.rows;
      count = this.props.transactionLogList.results.count;
    }

    return (
      <div className="transactionLogList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="transfer_list_container">
          <div className="transfer_list_container_inner">
            <div className="title" data-detect="admin_transaction_log_list_title">
              TRANSACTION LOG LIST
            </div>
            <div className="period_container">
              <div className="period_start">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  shouldCloseOnSelect
                  selected={this.state.tempStartDate}
                  onChange={(e) => this.onChangeValue(e, "tempStartDate")}
                  id="termStartPicker"
                  name="startDate"
                />
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  onClick={() => {
                    document.getElementById("termStartPicker").click();
                  }}
                />
              </div>
              <div className="period_line"></div>
              <div className="period_end">
                <DatePicker dateFormat="yyyy-MM-dd" shouldCloseOnSelect selected={this.state.tempEndDate} onChange={(e) => this.onChangeValue(e, "tempEndDate")} id="termEndPicker" />
                <img
                  src={CalendarIcon}
                  alt="calendar icon"
                  onClick={() => {
                    document.getElementById("termEndPicker").click();
                  }}
                />
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select
                      id="complete_action_select"
                      className="search_select"
                      name="complete_action_select"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempMovementStatus")}
                      value={this.state.tempMovementStatus}
                    >
                      <option data-detect="admin_transaction_log_list_select_complete_action" value="">
                        Complete Action ( ALL )
                      </option>
                      <option data-detect="admin_transaction_log_list_select_complete_action_commit" value="commit">
                        Commit
                      </option>
                      <option data-detect="admin_transaction_log_list_select_complete_action_rollback" value="rollback">
                        Rollback
                      </option>
                      <option data-detect="admin_transaction_log_list_select_complete_action_none" value="none">
                        None
                      </option>
                    </select>
                    <div
                      style={{
                        position: "relative",
                        top: "-9999px",
                        left: "-9999px",
                        opacity: 0,
                        transform: "translate(-100%, -100%)",
                        height: 0,
                      }}
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_transaction_log_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="transfer_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_transaction_log_list_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_transaction_log_list_label_act_on_function">
                      Act on function
                    </div>
                    <div className="cell" data-detect="admin_transaction_log_list_label_complete_action">
                      Complete action
                    </div>
                    <div className="cell" data-detect="admin_transaction_log_list_label_completed_at">
                      Completed at
                    </div>
                    <div className="cell" data-detect="admin_transaction_log_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {transactionLogList.length > 0 ? (
                    transactionLogList.map((transactionLog, index) => (
                      <div className="row tbodyRow" key={transactionLog.id}>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(transactionLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {transactionLog.id || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(transactionLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {transactionLog.actOnFunction || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(transactionLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {transactionLog.completeAction || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(transactionLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {(transactionLog && formatDateTimeSlash(transactionLog.completedAt)) || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(transactionLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {(transactionLog && formatDateTimeSlash(transactionLog.created_at)) || "-"}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_transfer_log_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TransactionLogListData.mapStateToProps;
let mapDispatchToProps = TransactionLogListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionLogListView)
);
