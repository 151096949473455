import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as otaReservationApis from "../lib/otaReservation_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const GET_OTA_HOTEL_ALL = "otaReservation/GET_OTA_HOTEL_ALL";
const GET_OTA_RESERVATION_ALL = "otaReservation/GET_OTA_RESERVATION_ALL";
const GET_OTA_RESERVATION_ONE = "otaReservation/GET_OTA_RESERVATION_ONE";
const GET_OTA_RESERVATION_MEMBERSHIP_TYPE = "otaReservation/GET_OTA_RESERVATION_MEMBERSHIP_TYPE";
const GET_OTA_RESERVATION_TITLE_TYPE = "otaReservation/GET_OTA_RESERVATION_TITLE_TYPE";
const GET_OTA_RESERVATION_CARD_TYPE = "otaReservation/GET_OTA_RESERVATION_CARD_TYPE";
const GET_OTA_RESERVATION_PROFILE_BY_EMAIL = "otaReservation/GET_OTA_RESERVATION_PROFILE_BY_EMAIL";
const GET_OTA_RESERVATION_PROFILE_BY_MEMBERSHIP = "otaReservation/GET_OTA_RESERVATION_PROFILE_BY_MEMBERSHIP";
const GET_OTA_RESERVATION_TERMS_CONDITIONS = "otaReservation/GET_OTA_RESERVATION_TERMS_CONDITIONS";
const GET_OTA_RESERVATION_PRIVACY_POLICY = "otaReservation/GET_OTA_RESERVATION_PRIVACY_POLICY";
const ADD_OTA_RESERVATION_BOOKING_WITH_PROFILE = "otaReservation/ADD_OTA_RESERVATION_BOOKING_WITH_PROFILE";
const ADD_OTA_RESERVATION_BOOKING = "otaReservation/ADD_OTA_RESERVATION_BOOKING";

// 액션 생성자
export const onGetHotelAll = createAction(GET_OTA_HOTEL_ALL, otaReservationApis.onGetHotelAll);
export const onGetAll = createAction(GET_OTA_RESERVATION_ALL, otaReservationApis.onGetAll);
export const onGetOne = createAction(GET_OTA_RESERVATION_ONE, otaReservationApis.onGetOne);
export const onGetMembershipType = createAction(GET_OTA_RESERVATION_MEMBERSHIP_TYPE, otaReservationApis.onGetMembershipType);
export const onGetTitleType = createAction(GET_OTA_RESERVATION_TITLE_TYPE, otaReservationApis.onGetTitleType);
export const onGetCardType = createAction(GET_OTA_RESERVATION_CARD_TYPE, otaReservationApis.onGetCardType);
export const onGetProfileByEmail = createAction(GET_OTA_RESERVATION_PROFILE_BY_EMAIL, otaReservationApis.onGetProfileByEmail);
export const onGetProfileByMembership = createAction(GET_OTA_RESERVATION_PROFILE_BY_MEMBERSHIP, otaReservationApis.onGetProfileByMembership);
export const onGetTermsConditions = createAction(GET_OTA_RESERVATION_TERMS_CONDITIONS, otaReservationApis.onGetTermsConditions);
export const onGetPrivacyPolicy = createAction(GET_OTA_RESERVATION_PRIVACY_POLICY, otaReservationApis.onGetPrivacyPolicy);
export const onAddBookingWithProfile = createAction(ADD_OTA_RESERVATION_BOOKING_WITH_PROFILE, otaReservationApis.onAddBookingWithProfile);
export const onAddBooking = createAction(ADD_OTA_RESERVATION_BOOKING, otaReservationApis.onAddBooking);


// 초기 상태
const initialState = {
    hotelList: [],
    hotelReservationList: [],
    hotelReservationDetail: null,
    hotelReservationMembershipTypeList: [],
    hotelReservationTitleTypeList: [],
    hotelReservationCardTypeList: [],
    hotelReservationProfileByEmailDetail: null,
    hotelReservationProfileByMembershipDetail: null,
    hotelReservationTermsConditionsDetail: null,
    hotelReservationPrivacyPolicyDetail: null,
    hotelReservationBookingWithProfileAdd: null,
    hotelReservationBookingAdd: null,
    failData: null,
};

export default handleActions({
        ...pender({
            type: GET_OTA_HOTEL_ALL,
            onSuccess: (state, action) => {
                return {...state, hotelList: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_ALL,
            onPending: (state, action) => {
                return {...state, failData: null };
            },
            onSuccess: (state, action) => {
                return {...state, hotelReservationList: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_ONE,
            onPending: (state, action) => {
                return {...state, hotelReservationDetail: null, failData: null };
            },
            onSuccess: (state, action) => {
                return {...state, hotelReservationDetail: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_MEMBERSHIP_TYPE,
            onSuccess: (state, action) => {
                return {...state, hotelReservationMembershipTypeList: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_TITLE_TYPE,
            onSuccess: (state, action) => {
                return {...state, hotelReservationTitleTypeList: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_CARD_TYPE,
            onSuccess: (state, action) => {
                return {...state, hotelReservationCardTypeList: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_PROFILE_BY_EMAIL,
            onSuccess: (state, action) => {
                return {...state, hotelReservationProfileByEmailDetail: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_PROFILE_BY_MEMBERSHIP,
            onSuccess: (state, action) => {
                return {...state, hotelReservationProfileByMembershipDetail: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_TERMS_CONDITIONS,
            onSuccess: (state, action) => {
                return {...state, hotelReservationTermsConditionsDetail: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: GET_OTA_RESERVATION_PRIVACY_POLICY,
            onSuccess: (state, action) => {
                return {...state, hotelReservationPrivacyPolicyDetail: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: ADD_OTA_RESERVATION_BOOKING_WITH_PROFILE,
            onPending: (state, action) => {
                return {...state, hotelReservationBookingWithProfileAdd: null, failData: null};
            },
            onSuccess: (state, action) => {
                return {...state, hotelReservationBookingWithProfileAdd: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        ...pender({
            type: ADD_OTA_RESERVATION_BOOKING,
            onPending: (state, action) => {
                return {...state, hotelReservationBookingAdd: null,failData: null};
            },
            onSuccess: (state, action) => {
                return {...state, hotelReservationBookingAdd: action.payload.data};
            },
            onFailure: (state, action) => {
                return {...state, failData: action.payload.response?.data};
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
