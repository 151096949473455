import React, { Component } from 'react';

class SelectBoxContainTypeButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenSelectBox: false,
            selectedValue: null,
            hasFocus: false,
        };

        this.onChangeToggleValue = this.onChangeToggleValue.bind(this);
        this.onChangeSelectValue = this.onChangeSelectValue.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);

        this.selectBoxRef = React.createRef();
    }

    onChangeToggleValue() {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState((prevState) => ({
                isOpenSelectBox: !prevState.isOpenSelectBox,
                hasFocus: true,
            }));
        }
    }

    onChangeSelectValue(value) {
        this.setState({
            selectedValue: value,
            isOpenSelectBox: false,
            hasFocus: false,
        },
            this.onCheckValidation);
    }

    // check validation
    onCheckValidation = () => {
        const { selectedValue } = this.state;

        if (selectedValue == 'errorTest') {
        }
    };

    onBlur() {
        this.setState({
            hasFocus: false,
        });
    }

    onClickOutside(event) {
        if (this.selectBoxRef.current && !this.selectBoxRef.current.contains(event.target)) {
            this.setState({
                isOpenSelectBox: false,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside);
    }

    render() {
        const { disabled } = this.props;
        const { isOpenSelectBox, selectedValue, hasFocus } = this.state;
        const isError = selectedValue == 'errorTest';

        const options = ['Test 1', 'Test 2', 'Test 3', 'Test 4', 'errorTest'];
        return (
            <div className={`myConectSelectBoxContainType ${hasFocus ? 'focused' : ''}`}
                onBlur={this.onBlur}
                tabIndex={0}
                ref={this.selectBoxRef}
            >
                <div
                    className={`myConect_input_select_box_contain_type ${disabled ? 'disabled' : ''} ${isError ? 'inputError' : ''} ${hasFocus ? 'focused' : ''}`}
                    onClick={this.onChangeToggleValue}
                    readOnly={disabled}
                >
                    <div className='search_type'>Search Type : </div>{selectedValue}
                    <div className='selectBox_line'></div>
                </div>

                {isOpenSelectBox && (
                    <div className="select_options">
                        {options.map((option, index) => (
                            <div
                                className={option == selectedValue ? 'selected' : ''}
                                key={index}
                                onClick={() => this.onChangeSelectValue(option)}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default SelectBoxContainTypeButton;
