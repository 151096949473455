import React from "react";
import { connect } from "react-redux";
import { Loading } from "../../components/Paths";
import HotelAppDownloadListData from "./HotelAppDownloadListData";

// CSS
import "../../css/common.css";
import "../../css/hotelAppDownload/hotelAppDownloadList.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class HotelAppDownloadListView extends HotelAppDownloadListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      hotelAppDownloadIOSList: [],
      hotelAppDownloadAOSList: [],
    };
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidMount() {
    this.requestGetHotelAppDownloadIOS();
    this.requestGetHotelAppDownloadAOS();
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    let hotelAppDownloadIOSList = [];
    let hotelAppDownloadAOSList = [];
    if (this.props.hotelAppDownloadIOSList != null && this.props.hotelAppDownloadIOSList?.success == true) {
      hotelAppDownloadIOSList = this.props.hotelAppDownloadIOSList.results;
    }
    if (this.props.hotelAppDownloadAOSList != null && this.props.hotelAppDownloadAOSList?.success == true) {
      hotelAppDownloadAOSList = this.props.hotelAppDownloadAOSList.results;
    }

    return (
      <div className="HotelAppDownload">
        {this.state.isShowAllLoading && <Loading />}
        <div className="app_download_container">
          <div className="android_container_inner">
            <div className="title">AOS</div>
            <div className="android_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div className="cell">Type</div>
                    <div className="cell">Version</div>
                    <div className="cell">Date</div>
                    <div className="cell">Download</div>
                  </div>
                </div>
                <div className="tbody android_tbody">
                  {hotelAppDownloadAOSList?.length > 0 ? (
                    hotelAppDownloadAOSList.map((item) => (
                      <div className="row tbodyRow" key={item.id}>
                        <div className="cell type">
                          <span>{item.type}</span>
                        </div>
                        <div className="cell version">
                          <span>{item.version}</span>
                        </div>
                        <div className="cell date">
                          <span>{formatDateTimeDot(item.created_at)}</span>
                        </div>
                        <div className="cell link">
                          <a href={item.link} className="add_btn" target="_blank" rel="noopener noreferrer">
                            Download
                          </a>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_hotel_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="ios_container_inner">
            <div className="title">IOS</div>
            <div className="ios_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div className="cell">Type</div>
                    <div className="cell">Version</div>
                    <div className="cell">Date</div>
                    <div className="cell">Download</div>
                  </div>
                </div>
                <div className="tbody ios_tbody">
                  {hotelAppDownloadIOSList?.length > 0 ? (
                    hotelAppDownloadIOSList.map((item) => (
                      <div className="row tbodyRow" key={item.id}>
                        <div className="cell type">
                          <span>{item.type}</span>
                        </div>
                        <div className="cell version">
                          <span>{item.version}</span>
                        </div>
                        <div className="cell date">
                          <span>{formatDateTimeDot(item.created_at)}</span>
                        </div>
                        <div className="cell link">
                          <a href={item.link} className="add_btn" target="_blank" rel="noopener noreferrer">
                            Download
                          </a>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_hotel_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* -------------------------------------------------- Connect Redux ------------------------------------------------- */
let mapStateToProps = HotelAppDownloadListData.mapStateToProps;
let mapDispatchToProps = HotelAppDownloadListData.mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelAppDownloadListView);
