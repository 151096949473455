// FacilityImageAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as facilityImageActions from "../../../redux/modules/FacilityImage";



class FacilityImageAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_FACILITY_IMAGE") {
      this.onAddFacilityImageResults(props);
    }
  }

  // redux에 add facilityImage 요청
  requestAddFacilityImage = (description, iconFile, iconText) => {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("hotelFacilityImageFile", iconFile);
    formData.append("imageText", iconText);

    this.setState({
      requestType: "ADD_FACILITY_IMAGE",
    });
    this.props.onAdd(formData);
  };

  // get add facilityImage results
  onAddFacilityImageResults = (props) => {
    if (props.facilityImageAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.facilityImageAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_facility_img"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/facilityImageListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      facilityImageAdd: state.facilityImage.facilityImageAdd,
      failData: state.facilityImage.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (formData) => dispatch(facilityImageActions.onAddFacilityImage(formData)),
    };
  };
}

export default FacilityImageAddData;
