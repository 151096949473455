import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import IDRecognitionEditData from "../Edit/IDRecognitionEditData";

//css
import "../../css/common.css";
import "../../css/idRecognition/idRecognitionEdit.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class IDRecognitionEditView extends IDRecognitionEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      countryName: "",
      idType: "",
      recognizedTexts: "",
      idRecognitionId: "",
      errorMessage: "",
      isCheckValidation: true,
      recognizedTextsPlaceholder: `ex)
  REPUBLIC OF KOREA
  Passport No.
  Surname
  Given names
  Date of birth
  ...`
    };
    this.textareaRef = React.createRef();
  }

  /* ---------------------------------------------------- lifecycle --------------------------------------------------- */
  componentDidMount() {
    if (this.props.idRecognitionDetail != null && this.props.idRecognitionDetail.success) {
      this.setState({
        countryName: this.props.idRecognitionDetail.results.countryName, //default값 설정
        idType: this.props.idRecognitionDetail.results.idType, //default값 설정
        recognizedTexts: this.changeArrayToString(this.props.idRecognitionDetail.results.recognizedTextArray), //default값 설정
        idRecognitionId: this.props.idRecognitionDetail.results.id,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* -------------------------------------------------- event handler ------------------------------------------------- */
  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const value = e.target.value;
    this.setState({ [stateName]: value }, () => {
      this.onCheckValidation();
    });
  };

  //edit 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editIDRecognition();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editIDRecognition = () => {
    // 모든 필드가 값이 있으면 버튼을 비활성화
    document.querySelector(".edit_btn").setAttribute("disabled", "disabled");
    this.setState({
      isShowAllLoading: true,
    });
    const { countryName, idType, recognizedTexts} = this.state;
    const recognizedTextArray = this.changeStringToArray(recognizedTexts);
    const idRecognitionInfo = {
      countryName: countryName,
      idType: idType,
      recognizedTextArray: recognizedTextArray
    }
    this.requestEditIDRecognition(this.state.idRecognitionId, idRecognitionInfo);
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/idRecognitionListPage");
  };

  // textarea 변경시
  onChangeTextarea = (e) => {
    this.onResizeHeight();
    const value = e.target.value;
    this.setState({
      recognizedTexts: value
    }, () => {
      this.onCheckValidation();
    })
  }

  // 줄바꿈할 때 height을 조절함.
  onResizeHeight = () => {
    this.textareaRef.current.style.height = 'auto'; //height 초기화
    this.textareaRef.current.style.height = this.textareaRef.current.scrollHeight + 'px';
  };

  /* ------------------------------------------------------- etc ------------------------------------------------------ */
  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null || value.length == 0) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("countryName", "admin_id_recognition_information_edit_validation_country_name") &&
      this.confirmField("idType", "admin_id_recognition_information_edit_validation_id_type") &&
      this.confirmField("recognizedTexts", "admin_id_recognition_information_edit_validation_recognized_texts")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  changeArrayToString = (array) => {
    if(!array) return;
    const initialArray = array;
    return initialArray.join("\n");
  }

  changeStringToArray = (string) => {
    if(!string) return;

    let initialString = string;
    let textArray = [];
    
    textArray = initialString.split("\n");

    return textArray.map((str) => {
      return str.trim();
    }).filter(str => str != "");
  }

  render() {
    let idRecognitionDetail = null;

    if (this.props.idRecognitionDetail != null && this.props.idRecognitionDetail?.success == true) {
      idRecognitionDetail = this.props.idRecognitionDetail.results;
    }
    return (
      <div className="idRecognitionEdit">
        {this.state.isShowAllLoading && <Loading />}
        {idRecognitionDetail !== null && (
          <div className="id_recognition_detail_container">
            <div className="id_recognition_detail_container_inner">
              <div className="title_container">
                <div className="main_text">
                  <p data-detect="admin_id_recognition_information_edit_title">COUNTRY</p>
                </div>
                <div className="sub_text">
                  <p className="message" data-detect={this.state.errorMessage}></p>
                </div>
              </div>
              <div className="id_recognition_information_info_container">
                <div className="detail_table">
                  <div className="detail_tbody">
                    <div className="id_recognition_information_info">
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_id_recognition_information_edit_label_country_name">
                        Country Name
                        </div>
                        <div className="detail_cell content country_name">
                          <input type="text" value={this.state.countryName} onChange={(e) => this.onChangeValue(e, "countryName")} placeholder="Country Name" data-detect-placeholder="admin_id_recognition_information_edit_placeholder_country_name" />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_id_recognition_information_edit_label_id_type">
                        ID Type
                        </div>
                        <div className="detail_cell content id_type">
                          {/* <input type="text" value={this.state.idType} onChange={(e) => this.onChangeValue(e, "idType")} placeholder="ID Type" data-detect-placeholder="admin_id_recognition_information_edit_placeholder_id_type" /> */}
                          <select id="idType" className="id_type_select" name="idType" onChange={(e) => this.onChangeValue(e, "idType")} value={this.state.idType}>
                            <option disabled value="" data-detect="admin_id_recognition_information_edit_placeholder_select_box_choose">
                              Choose
                            </option>
                            <option data-detect="admin_id_recognition_information_edit_select_id_type_registration" value="Registration">
                              Registration
                            </option>
                            <option data-detect="admin_id_recognition_information_edit_select_id_type_passport" value="Passport">
                              Passport
                            </option>
                            <option data-detect="admin_id_recognition_information_edit_select_id_type_driver_license" value="DriverLicense">
                              Driver License
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_id_recognition_information_edit_label_recognized_texts">
                        Recongnized Texts
                        </div>
                        <div className="detail_cell content recognized_texts">
                        <textarea
                          type="text"
                          id="recognizedTexts"
                          name="recognizedTexts"
                          ref={this.textareaRef}
                          placeholder={this.state.recognizedTextsPlaceholder}
                          autoComplete="off"
                          value={this.state.recognizedTexts}
                          onChange={this.onChangeTextarea}
                        ></textarea>
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index" data-detect="admin_id_recognition_information_edit_label_updated_at">
                          Updated At
                        </div>
                        <div className="detail_cell content">{idRecognitionDetail && formatDateTimeDot(idRecognitionDetail.updated_at)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button_form_container">
                <div className="cancel_btn_container">
                  <button className="cancel_btn" onClick={this.cancelBtn} data-detect="admin_id_recognition_information_edit_button_cancel"></button>
                </div>
                <div className="edit_btn_container">
                  <button className="edit_btn" onClick={this.onSubmit} data-detect="admin_id_recognition_information_edit_button_ok" disabled={!this.state.isCheckValidation}></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let mapStateToProps = IDRecognitionEditData.mapStateToProps;
let mapDispatchToProps = IDRecognitionEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IDRecognitionEditView));
