import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import IDRecognitionDetailData from "../Detail/IDRecognitionDetailData";

//css
import "../../css/common.css";
import "../../css/idRecognition/idRecognitionDetail.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class IDRecognitionDetailView extends IDRecognitionDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      idRecognitionId: "",
    };
  }

  // 첫 실행시 URL에서 idRecognitionId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const idRecognitionId = params.get("idRecognitionId");
    this.setState({
      idRecognitionId: idRecognitionId,
    });
    this.requestGetIDRecognitionOne(idRecognitionId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkIDRecognitionEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const idRecognitionId = params.get("idRecognitionId");
    this.props.history.push(`/idRecognitionEditPage?idRecognitionId=${idRecognitionId}`);
  };



  joinArray = (array) => {
    if(!array || array?.length == 0) return "-";

    const initialArray = array;

    return initialArray.join("<br/>");
  }

  render() {
    let idRecognitionDetail = null;

    if (
      this.props.idRecognitionDetail != null &&
      this.props.idRecognitionDetail?.success == true
    ) {
      idRecognitionDetail = this.props.idRecognitionDetail.results;
    }

    return (
      <div className="idRecognitionDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="id_recognition_detail_container">
          <div className="id_recognition_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_id_recognition_information_detail_title"
            >
              ID RECOGNITION INFORMATION
            </div>
            <div className="regioninfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="region_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_id_recognition_information_detail_label_country_name"
                      >
                        Country Name
                      </div>
                      <div
                        className="detail_cell content region_name"
                        style={{ color: "white" }}
                      >
                        <span id="region_id">{idRecognitionDetail?.countryName??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_id_recognition_information_detail_label_id_type"
                      >
                        ID Type
                      </div>
                      <div
                        className="detail_cell content region_name"
                        style={{ color: "white" }}
                      >
                        <span id="region_id">{idRecognitionDetail?.idType??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_id_recognition_information_detail_label_recognized_texts"
                      >
                        Recongnized Texts
                      </div>
                      <div
                        className="detail_cell content region_name"
                        style={{ color: "white" }}
                      >
                        <pre dangerouslySetInnerHTML={{ __html: this.joinArray(idRecognitionDetail?.recognizedTextArray)}}></pre>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_id_recognition_information_detail_label_created_at"
                      >
                        Created At
                      </div>
                      <div
                        className="detail_cell content region_name"
                        style={{ color: "white" }}
                      >
                        {idRecognitionDetail ?
                          formatDateTimeDot(idRecognitionDetail.created_at) : "-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_id_recognition_information_detail_label_updated_at"
                      >
                        Updated At
                      </div>
                      <div
                        className="detail_cell content region_name"
                        style={{ color: "white" }}
                      >
                        {idRecognitionDetail ?
                          formatDateTimeDot(idRecognitionDetail.updated_at) : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_id_recognition_information_detail_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkIDRecognitionEdit();
                  }}
                  data-detect="admin_id_recognition_information_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = IDRecognitionDetailData.mapStateToProps;
let mapDispatchToProps = IDRecognitionDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(IDRecognitionDetailView)
);
