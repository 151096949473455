import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import TermsGroupDetailData from "../Detail/TermsGroupDetailData";

//css
import "../../../css/common.css";
import "../../../css/termsAndCondition/TermsGroup/TermsGroupDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class TermsGroupDetailView extends TermsGroupDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      termsGroupId: "",
    };
  }

  // 첫 실행시 URL에서 termsGroupId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const termsGroupId = params.get("termsGroupId");
    this.setState({
      termsGroupId: termsGroupId,
    });
    this.requestGetTermsGroupOne(termsGroupId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkTermsGroupEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const termsGroupId = params.get("termsGroupId");
    this.props.history.push(`/termsGroupEditPage?termsGroupId=${termsGroupId}`);
  };



  render() {
    let termsGroupDetail = null;

    if (
      this.props.termsGroupDetail != null &&
      this.props.termsGroupDetail?.success == true
    ) {
      termsGroupDetail = this.props.termsGroupDetail.results;
    }

    return (
      <div className="termsGroupDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="termsGroupinfo_detail_container">
          <div className="termsGroupinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_terms_group_detail_title"
            >
              TERMS GROUP INFORMATION
            </div>
            <div className="termsGroupinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="termsGroup_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_terms_group_detail_label_title"
                      >
                        Title
                      </div>
                      <div
                        className="detail_cell content termsGroup_title"
                        style={{ color: "white" }}
                      >
                        <span id="termsGroup_id">
                          {termsGroupDetail?.title??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_terms_group_detail_label_country_code"
                      >
                        Country Code
                      </div>
                      <div
                        className="detail_cell content termsGroup_countryCode"
                        style={{ color: "white" }}
                      >
                        {termsGroupDetail?.countryCode??"-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_terms_group_detail_label_created_at"
                      >
                        Created At
                      </div>
                      <div
                        className="detail_cell content termsGroup_created_at"
                        style={{ color: "white" }}
                      >
                        {termsGroupDetail ?
                          formatDateTimeDot(termsGroupDetail.created_at) : "-"}
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_terms_group_detail_label_updated_at"
                      >
                        Updated At
                      </div>
                      <div
                        className="detail_cell content termsGroup_name"
                        style={{ color: "white" }}
                      >
                        {termsGroupDetail ?
                          formatDateTimeDot(termsGroupDetail.updated_at) : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_terms_group_detail_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
              <div className="edit_btn_container">
                <button
                  className="edit_btn on"
                  onClick={() => {
                    this.linkTermsGroupEdit();
                  }}
                  data-detect="admin_terms_group_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsGroupDetailData.mapStateToProps;
let mapDispatchToProps = TermsGroupDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TermsGroupDetailView)
);
