import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as transferApis from "../lib/transfer_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const TRANSFER_GET_ALL = "transfer/TRANSFER_GET_ALL";
const TRANSFER_GET_ONE = "transfer/TRANSFER_GET_ONE";
const USER_TRANSFER_GET_ALL = "transfer/USER_TRANSFER_GET_ALL";
const USER_TRANSFER_GET_ONE = "transfer/USER_TRANSFER_GET_ONE"
const TRANSACTION_GET_ALL = "transfer/TRANSACTION_GET_ALL";
const TRANSACTION_GET_ONE = "transefer/TRANSACTION_GET_ONE"
const USER_TRANSFER_LOG_HISTORY_GET_ALL = "transfer/USER_TRANSFER_LOG_HISTORY_GET_ALL"
const USER_TRANSFER_LOG_HISOTRY_GET_SOMETHING = "transfer/USER_TRANSFER_LOG_HISOTRY_GET_SOMETHING"
const STAFF_TRANSFER_LOG_HISTORY_GET_ALL = "transfer/STAFF_TRANSFER_LOG_HISTORY_GET_ALL";
const STAFF_TRANSFER_LOG_HISOTRY_GET_SOMETHING = "transfer/STAFF_TRANSFER_LOG_HISOTRY_GET_SOMETHING";
const USER_NOTIFICATION_LOG_GET_ALL = "transfer/USER_NOTIFICATION_LOG_GET_ALL";
const USER_NOTIFICATION_LOG_GET_ONE = "transfer/USER_NOTIFICATION_LOG_GET_ONE";
const STAFF_NOTIFICATION_LOG_GET_ALL = "transfer/STAFF_NOTIFICATION_LOG_GET_ALL";
const STAFF_NOTIFICATION_LOG_GET_ONE = "transfer/STAFF_NOTIFICATION_LOG_GET_ONE";
// 액션 생성자
export const onGetAllTransferLogList = createAction(TRANSFER_GET_ALL, transferApis.onGetAllTransferLogList);
export const onGetTransferLogDetail = createAction(TRANSFER_GET_ONE, transferApis.onGetTransferLogDetail);
export const onGetAllUserTransferLogList = createAction(USER_TRANSFER_GET_ALL, transferApis.onGetAllUserTransferLogList);
export const onGetUserTransferLogDetail = createAction(USER_TRANSFER_GET_ONE, transferApis.onGetUserTransferLogDetail);
export const onGetAllTransactionLogList = createAction(TRANSACTION_GET_ALL, transferApis.onGetAllTransactionLogList);
export const onGetTransactionLogDetail = createAction(TRANSACTION_GET_ONE, transferApis.onGetTransactionLogDetail);
export const onGetAllUserTransferLogHistoryList = createAction(USER_TRANSFER_LOG_HISTORY_GET_ALL, transferApis.onGetAllUserTransferLogHistoryList);
export const onGetUserTransferLogHistoryDetail = createAction(USER_TRANSFER_LOG_HISOTRY_GET_SOMETHING, transferApis.onGetUserTransferLogHistoryDetail);
export const onGetAllStaffTransferLogHistoryList = createAction(STAFF_TRANSFER_LOG_HISTORY_GET_ALL, transferApis.onGetAllStaffTransferLogHistoryList);
export const onGetStaffTransferLogHistoryDetail = createAction(STAFF_TRANSFER_LOG_HISOTRY_GET_SOMETHING, transferApis.onGetStaffTransferLogHistoryDetail);
export const onGetAllUserNotificationLogList = createAction(USER_NOTIFICATION_LOG_GET_ALL, transferApis.onGetAllUserNotificationLogList);
export const onGetUserNotificationLogDetail = createAction(USER_NOTIFICATION_LOG_GET_ONE, transferApis.onGetUserNotificationLogDetail);
export const onGetAllStaffNotificationLogList = createAction(STAFF_NOTIFICATION_LOG_GET_ALL, transferApis.onGetAllStaffNotificationLogList);
export const onGetStaffNotificationLogDetail = createAction(STAFF_NOTIFICATION_LOG_GET_ONE, transferApis.onGetStaffNotificationLogDetail);

// 초기 상태
const initialState = {
  transferLogList: [],
  transferLogDetail: null,
  userTransferLogList: [],
  userTransferLogDetail: null,
  transactionLogList: [],
  transactionLogDetail: null,
  userTransferLogHistoryList: [],
  userTransferLogHistoryDetail: null,
  staffTransferLogHistoryList: [],
  staffTransferLogHistoryDetail: null,
  userNotificationLogList: [],
  userNotificationLogDetail: null,
  staffNotificationLogList: [],
  staffNotificationLogDetail: null,
  successDelete: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: TRANSFER_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, transferLogList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, transferLogList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: TRANSFER_GET_ONE,
      onPending: (state, action) => {
        return { ...state, transferLogDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, transferLogDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: USER_TRANSFER_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, userTransferLogList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, userTransferLogList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: USER_TRANSFER_GET_ONE,
      onPending: (state, action) => {
        return { ...state, userTransferLogDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, userTransferLogDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: TRANSACTION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, transactionLogList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, transactionLogList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: TRANSACTION_GET_ONE,
      onPending: (state, action) => {
        return { ...state, transactionLogDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, transactionLogDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: USER_TRANSFER_LOG_HISTORY_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, userTransferLogHistoryList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, userTransferLogHistoryList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: USER_TRANSFER_LOG_HISOTRY_GET_SOMETHING,
      onPending: (state, action) => {
        return { ...state, userTransferLogHistoryDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, userTransferLogHistoryDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: STAFF_TRANSFER_LOG_HISTORY_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffTransferLogHistoryList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, staffTransferLogHistoryList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: STAFF_TRANSFER_LOG_HISOTRY_GET_SOMETHING,
      onPending: (state, action) => {
        return { ...state, staffTransferLogHistoryDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffTransferLogHistoryDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: USER_NOTIFICATION_LOG_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, userNotificationLogList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, userNotificationLogList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: USER_NOTIFICATION_LOG_GET_ONE,
      onPending: (state, action) => {
        return { ...state, userNotificationLogDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, userNotificationLogDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: STAFF_NOTIFICATION_LOG_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffNotificationLogList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, staffNotificationLogList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: STAFF_NOTIFICATION_LOG_GET_ONE,
      onPending: (state, action) => {
        return { ...state, staffNotificationLogDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, staffNotificationLogDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
    },
  },
  initialState
);
