import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as bookingEmailApis from "../lib/bookingEmail_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const EDIT_BOOKING_EMAIL = "bookingEmail/EDIT_BOOKING_EMAIL";

// 액션 생성자
export const onEditBookingEmail = createAction(EDIT_BOOKING_EMAIL, bookingEmailApis.onEditBookingEmail);


// 초기 상태
const initialState = {
  bookingEmailEdit: null,
  successDelete: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: EDIT_BOOKING_EMAIL,
      onPending: (state, action) => {
        return { ...state, bookingEmailEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, bookingEmailEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
