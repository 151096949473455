import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import RegionEditData from "../Edit/RegionEditData";

//css
import "../../../css/common.css";
import "../../../css/location/region/regionEdit.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class RegionEditView extends RegionEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      region: "",
      regionId: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    if (this.props.regionDetail != null && this.props.regionDetail.success) {
      this.setState({
        region: this.props.regionDetail.results.name, //default값 설정
        regionId: this.props.regionDetail.results.id,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change region
  onRegionChange = (e) => {
    this.setState({ region: e.target.value }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("region", "admin_region_edit_validation_name")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };



  render() {
    let regionDetail = null;

    if (this.props.regionDetail != null && this.props.regionDetail?.success == true) {
      regionDetail = this.props.regionDetail.results;
    }

    return (
      <div className="regionEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="regioninfo_detail_container">
          <div className="regioninfo_detail_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_region_edit_title">COUNTRY</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="regioninfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="region_info">
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_region_edit_label_region">
                        Region
                      </div>
                      <div className="detail_cell content region_name">
                        <input type="text" value={this.state.region} onChange={this.onRegionChange} placeholder="Region Name" data-detect-placeholder="admin_region_edit_placeholder_region_name" />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_region_edit_label_created_at">
                        Created At
                      </div>
                      <div className="detail_cell content region_name">{regionDetail && formatDateTimeDot(regionDetail.created_at)}</div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_region_edit_label_updated_at">
                        Updated At
                      </div>
                      <div className="detail_cell content region_name">{regionDetail && formatDateTimeDot(regionDetail.updated_at)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="cancel_btn_container">
                <button className="cancel_btn" onClick={this.cancelBtn} data-detect="admin_region_edit_button_cancel"></button>
              </div>
              <div className="edit_btn_container">
                <button className="edit_btn" onClick={this.onSubmit} data-detect="admin_region_edit_button_ok" disabled={!this.state.isCheckValidation}></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = RegionEditData.mapStateToProps;
let mapDispatchToProps = RegionEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegionEditView));
