import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import RecommendEditData from "../Edit/RecommendEditData";

//css
import "../../css/common.css";
import "../../css/recommend/recommendEdit.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class RecommendEditView extends RecommendEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      recommendId: "",
      name: "",
      iosScheme: "",
      iosItunesUrl: "",
      aosPackageId: "",
      aosPlayStoreUrl: "",
      updatedAt: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const recommendId = params.get("recommendId");
    this.setState({
      recommendId: recommendId,
    });

    if (this.props.recommendDetail != null && this.props.recommendDetail.success) {
      this.setState({
        name: this.props.recommendDetail.results.name, //default값 설정
        iosScheme: this.props.recommendDetail.results.iosScheme,
        iosItunesUrl: this.props.recommendDetail.results.iosITunesUrl,
        aosPackageId: this.props.recommendDetail.results.aosPackageId,
        aosPlayStoreUrl: this.props.recommendDetail.results.aosPlayStoreUrl,
        updatedAt: this.props.recommendDetail.results.updated_at,
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change iosScheme
  onChangeIosScheme = (e) => {
    const inputValue = e.target.value;
    this.setState({ iosScheme: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change iosItunesUrl
  onChangeIosItunesUrl = (e) => {
    const inputValue = e.target.value;
    this.setState({ iosItunesUrl: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change aosPackageId
  onChangeAosPackageId = (e) => {
    const inputValue = e.target.value;
    this.setState({ aosPackageId: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // change aosPlayStoreUrl
  onChangeAosPlayStoreUrl = (e) => {
    const inputValue = e.target.value;
    this.setState({ aosPlayStoreUrl: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  //edit 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editRecommend();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editRecommend = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestEditRecommend(this.state.name, this.state.iosScheme, this.state.iosItunesUrl, this.state.aosPackageId, this.state.aosPlayStoreUrl, this.state.recommendId);
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/recommendListPage");
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("name", "admin_recommend_app_edit_validation_name") &&
      this.confirmField("iosScheme", "admin_recommend_app_edit_validation_ios_scheme") &&
      this.confirmField("iosItunesUrl", "admin_recommend_app_edit_validation_ios_itunes_url") &&
      this.confirmField("aosPackageId", "admin_recommend_app_edit_validation_aos_package_id") &&
      this.confirmField("aosPlayStoreUrl", "admin_recommend_app_edit_validation_aos_play_store_url")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };



  render() {
    let recommendDetail = [];

    if (this.props.recommendDetail != null && this.props.recommendDetail?.success == true) {
      recommendDetail = this.props.recommendDetail.results;
    }

    return (
      <div className="recommendEdit">
        {this.state.isShowAllLoading && <Loading />}
        {recommendDetail !== null && (
          <div className="recommendinfo_detail_container">
            <div className="recommendinfo_detail_container_inner">
              <div className="title_container">
                <div className="main_text">
                  <p data-detect="admin_recommend_app_edit_title">UPDATE APP RECOMMENDATION</p>
                </div>
                <div className="sub_text">
                  <p className="message" data-detect={this.state.errorMessage}></p>
                </div>
              </div>
              <div className="recommendinfo_container">
                <div className="detail_table">
                  <div className="detail_tbody">
                    <div className="recommend_info">
                      <div className="detail_row">
                        <div className="detail_cell index">
                          <div className="form_title">
                            <p data-detect="admin_recommend_app_edit_label_name">Name</p>
                          </div>
                        </div>
                        <div className="detail_cell content recommend_name">
                          <input type="text" id="name" name="name" autoComplete="off" value={this.state.name} onChange={this.onChangeName} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index">
                          <div className="form_title">
                            <p data-detect="admin_recommend_app_edit_label_ios_scheme">iOS Scheme</p>
                          </div>
                        </div>
                        <div className="detail_cell content recommend_iosScheme">
                          <input type="text" id="iosScheme" name="iosScheme" autoComplete="off" value={this.state.iosScheme} onChange={this.onChangeIosScheme} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index">
                          <div className="form_title">
                            <p data-detect="admin_recommend_app_edit_label_ios_itunes_url">iOS iTunes Url</p>
                          </div>
                        </div>
                        <div className="detail_cell content recommend_iosItunesUrl">
                          <input type="text" id="iosItunesUrl" name="iosItunesUrl" autoComplete="off" value={this.state.iosItunesUrl} onChange={this.onChangeIosItunesUrl} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index">
                          <div className="form_title">
                            <p data-detect="admin_recommend_app_edit_label_aos_package_id">AOS Package ID</p>
                          </div>
                        </div>
                        <div className="detail_cell content recommend_aosPackageId">
                          <input type="text" id="aosPackageId" name="aosPackageId" autoComplete="off" value={this.state.aosPackageId} onChange={this.onChangeAosPackageId} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index">
                          <div className="form_title">
                            <p data-detect="admin_recommend_app_edit_label_aos_play_store_url">AOS Play Store Url</p>
                          </div>
                        </div>
                        <div className="detail_cell content recommend_name">
                          <input type="text" id="aosPlayStoreUrl" name="aosPlayStoreUrl" autoComplete="off" value={this.state.aosPlayStoreUrl} onChange={this.onChangeAosPlayStoreUrl} />
                        </div>
                      </div>
                      <div className="detail_row">
                        <div className="detail_cell index">
                          <div className="form_title">
                            <p data-detect="admin_recommend_app_editl_label_updated_at">Update Date</p>
                          </div>
                        </div>
                        <div className="detail_cell content info_update_date">
                          <span>{formatDateTimeDot(this.state.updatedAt)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button_form_container">
                <div className="cancel_btn_container">
                  <button className="cancel_btn" onClick={this.cancelBtn} data-detect="admin_recommend_app_edit_button_cancel"></button>
                </div>
                <div className="ok_btn_container">
                  <button className="ok_btn" onClick={this.onSubmit} data-detect="admin_recommend_app_edit_button_ok" disabled={!this.state.isCheckValidation}></button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let mapStateToProps = RecommendEditData.mapStateToProps;
let mapDispatchToProps = RecommendEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecommendEditView));
