import CommonComponent from "../../../components/CommonComponent";

// module
import * as authActions from "../../../../redux/modules/Auth";
import * as transferActions from "../../../../redux/modules/Transfer";

class StaffTransferLogHistoryDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_STAFF_TRANSFER_LOG_HISTORY_DETAIL") {

      this.onGetStaffTransferLogHistoryDetailResults(props);
    }
  }

  // redux에 get payment Detail 요청
  requestGetStaffTransferLogDetailHistory = (staffTransferCode) => {
    this.setState({
      requestType: "GET_STAFF_TRANSFER_LOG_HISTORY_DETAIL",
    });
    this.props.onGetStaffTransferLogHistoryDetail(staffTransferCode);
  };

  // get transfer log detail results
  onGetStaffTransferLogHistoryDetailResults = (props) => {
    if (props.staffTransferLogHistoryDetail == null && props.failData == null) {
      return;
    }

    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.staffTransferLogHistoryDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      staffTransferLogHistoryList: state.transfer.staffTransferLogHistoryList,
      staffTransferLogHistoryDetail:
        state.transfer.staffTransferLogHistoryDetail,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetStaffTransferLogHistoryDetail: (staffTransferCode) =>
        dispatch(
          transferActions.onGetStaffTransferLogHistoryDetail(staffTransferCode)
        ),
    };
  };
}

export default StaffTransferLogHistoryDetailData;
