import CommonComponent from "../../../components/CommonComponent";

// module
import * as versionActions from "../../../../redux/modules/Version";

class VersionDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_VERSION_ONE") {
      this.setState({});
      this.onGetVersionOneResults(props);
    }
  }

  // redux에 get version Detail 요청
  requestGetVersionOne = (versionId) => {
    this.setState({
      requestType: "GET_VERSION_ONE",
    });
    this.props.onGetOne(versionId);
  };

  // get version One results
  onGetVersionOneResults = (props) => {
    if (props.versionDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.versionDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };
  
  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      versionDetail: state.version.versionDetail,
      failData: state.version.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (versionId) => dispatch(versionActions.onGetOne(versionId)),
    };
  };
}

export default VersionDetailData;
