var month = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");

function yyyymmdd(date) {
  var x = date;
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  d.length == 1 && (d = "0" + d);
  m.length == 1 && (m = "0" + m);
  var yyyymmdd = y + m + d;
  return yyyymmdd;
}
function yyyy_mm_dd(date) {
  var x = date;
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  d.length == 1 && (d = "0" + d);
  m.length == 1 && (m = "0" + m);
  var yyyymmdd = y + "." + m + "." + d;
  return yyyymmdd;
}
function mm_ddyyyy(date) {
  var x = date;
  var y = x.getFullYear().toString();
  var m = month[x.getMonth()];
  var d = x.getDate().toString();
  d.length == 1 && (d = "0" + d);
  m.length == 1 && (m = "0" + m);
  var mmddyyyy = m + " " + d + ", " + y;
  return mmddyyyy;
}
function mm_dd(date) {
  var x = date;
  var m = month[x.getMonth()];
  var d = x.getDate().toString();
  d.length == 1 && (d = "0" + d);
  m.length == 1 && (m = "0" + m);
  var mmdd = m + " " + d;
  return mmdd;
}
function hhmmss(date) {
  var x = date;
  var h = x.getHours().toString();
  var m = x.getMinutes().toString();
  var s = x.getSeconds().toString();
  h.length == 1 && (h = "0" + h);
  s.length == 1 && (s = "0" + s);
  m.length == 1 && (m = "0" + m);
  var hhmmss = h + m + s;
  return hhmmss;
}
function hh_mm_ss(date) {
  var x = date;
  var h = x.getHours().toString();
  var m = x.getMinutes().toString();
  var s = x.getSeconds().toString();
  h.length == 1 && (h = "0" + h);
  s.length == 1 && (s = "0" + s);
  m.length == 1 && (m = "0" + m);
  var hhmmss = h + ":" + m + ":" + s;
  return hhmmss;
}
function hh_mm(date) {
  var x = date;
  var h = x.getHours().toString();
  var m = x.getMinutes().toString();
  h.length == 1 && (h = "0" + h);
  m.length == 1 && (m = "0" + m);
  var hhmm = h + ":" + m;
  return hhmm;
}
function hh_mm_am_pm(date) {
  var x = date;
  var h = x.getHours() > 12 ? x.getHours() - 12 : x.getHours();
  var am_pm = date.getHours() >= 12 ? "PM" : "AM";
  h = h < 10 ? "0" + h : h;
  var m = x.getMinutes() < 10 ? "0" + x.getMinutes() : x.getMinutes();
  var hhmm = am_pm + " " + h + ":" + m;

  return hhmm;
}
function yyyymmdd_hhmmss(time) {
  let date = new Date(time);
  let dayString = yyyymmdd(date);
  let timeString = hhmmss(date);
  return dayString + " " + timeString;
}

function yyyy_mm_dd_hh_mm_ss(time) {
  let date = new Date(time);
  let dayString = yyyy_mm_dd(date);
  let timeString = hh_mm_ss(date);
  return dayString + " " + timeString;
}
function yyyy_mm_dd_hh_mm(time) {
  let date = new Date(time);
  let dayString = yyyy_mm_dd(date);
  let timeString = hh_mm(date);
  return dayString + ", " + timeString;
}

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  console.log("Query variable %s not found", variable);
  return null;
}
function getDateInTimeZone(dateString, fromOffset, toOffset) {
  // dateString을 UTC로 간주하고 Date 객체 생성
  const date = new Date(dateString + "Z");

  // UTC 시간에서 오프셋을 빼서 실제 시간대 시간을 계산
  const dateInUTC = new Date(date.getTime() - fromOffset * 60 * 60 * 1000);
  // UTC 시간에 오프셋을 더하여 원하는 시간대로 변환
  const convertedDate = new Date(dateInUTC.getTime() + toOffset * 60 * 60 * 1000);
  const convertedDateDay = convertedDate.split("T")[0];

  return convertedDateDay;
}
function getTimeInTimeZone(dateString, fromOffset, toOffset) {
  // dateString을 UTC로 간주하고 Date 객체 생성
  const date = new Date(dateString + "Z");

  // UTC 시간에서 오프셋을 빼서 실제 시간대 시간을 계산
  const dateInUTC = new Date(date.getTime() - fromOffset * 60 * 60 * 1000);
  // UTC 시간에 오프셋을 더하여 원하는 시간대로 변환
  const convertedDate = new Date(dateInUTC.getTime() + toOffset * 60 * 60 * 1000);
  const convertedDateTime = convertedDate.split("T")[1];

  return convertedDateTime;
}
// 2022-07-07T12:00:00.000Z GMT기준시 문자열을 받아 Hotel TimeZone을 계산하여 Hotel기준의 시간(시분초)을 가져오는 함수.
function hhMMssByTimeZone(dateString, timeZone) {
  if (dateString == null || dateString == undefined || dateString == "") {
    return "-";
  }

  let reversedTimeZone = timeZone;
  if (timeZone.includes("+")) {
    reversedTimeZone = timeZone.replace("+", "-");
  } else if (timeZone.includes("-")) {
    reversedTimeZone = timeZone.replace("-", "+");
  }

  let hotelBaseRequestAt = new Date(dateString.replace("Z", "") + reversedTimeZone);
  return hotelBaseRequestAt
    .toJSON()
    .split("T")[1]
    .substring(0, 8);
}

// 2022-07-07T12:00:00.000Z GMT기준시 문자열을 받아 Hotel TimeZone을 계산하여 Hotel기준의 날짜(년월일)을 가져오는 함수.
function yyyymmddDashByTimeZone(dateString, timeZone) {
  if (dateString == null || dateString == undefined || dateString == "") {
    return "-";
  }

  let reversedTimeZone = timeZone;
  if (timeZone.includes("+")) {
    reversedTimeZone = timeZone.replace("+", "-");
  } else if (timeZone.includes("-")) {
    reversedTimeZone = timeZone.replace("-", "+");
  }
  // 오늘
  let hotelBaseRequestAt = new Date(dateString.replace("Z", "") + reversedTimeZone);
  return hotelBaseRequestAt.toJSON().split("T")[0];
}

// dateString을 받아 Hotel TimeZone을 계산하여 Hotel기준의 날짜(년월일시분초)을 가져오는 함수
function yyyymmddDot_hh_mm_ssByTimeZone(dateString, timeZone) {
  if (dateString == null || dateString == undefined || dateString == "") {
    return "-";
  }

  let dayString = yyyymmddDashByTimeZone(dateString, timeZone);
  let timeString = hhMMssByTimeZone(dateString, timeZone);
  return `${dayString} ${timeString}`;
}

// yyyy-mm-dd
function formatDash(dateStr) {
  if (dateStr == null) return;

  const date = new Date(dateStr);
  const year = date.getFullYear(); //yyyy
  let month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  let day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  const strTime = year + "-" + month + "-" + day;
  return strTime;
}

// yyyy/MM/dd
function formatSlash(dateStr) {
  if (dateStr == null) return;

  const date = new Date(dateStr);
  const year = date.getFullYear(); //yyyy
  let month = 1 + date.getMonth(); //M
  month = month >= 10 ? month : "0" + month; //month 두자리로 저장
  let day = date.getDate(); //d
  day = day >= 10 ? day : "0" + day; //day 두자리로 저장
  const strTime = year + "/" + month + "/" + day;
  return strTime;
}

// AM/PM 구분
function formatAMPM(dateStr) {
  if (dateStr == null) return;

  const date = new Date(dateStr);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (minutes == 0) {
    minutes = String(minutes);
    minutes = "00";
  } else if (minutes < 10) {
    minutes = String(minutes);
    minutes = "0" + minutes;
  }
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  if (hours < 10) {
    hours = String(hours);
    hours = "0" + hours;
  }
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

//날자 포맷팅 함수
function formatDateTimeDot(isoString) {
  if (isoString == null || isoString == undefined || isoString == "") {
    return "-";
  }
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}

function formatDateTimeSlash(isoString) {
  if (isoString == "" || isoString == null) return "-";
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function formatDateMinutesSlash(isoString) {
  if (isoString == "" || isoString == null) return;

  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}/${month}/${day} ${hours}:${minutes}`;
}

function roundDownToTenMinutes(requestDate) {
  const time = new Date(requestDate);
  const minute = time.getMinutes();

  let setTime = "";

  if (minute >= 0 && minute < 10) {
    setTime = new Date(time.setMinutes(0));
  } else if (minute >= 10 && minute < 20) {
    setTime = new Date(time.setMinutes(10));
  } else if (minute >= 20 && minute < 30) {
    setTime = new Date(time.setMinutes(20));
  } else if (minute >= 30 && minute < 40) {
    setTime = new Date(time.setMinutes(30));
  } else if (minute >= 40 && minute < 50) {
    setTime = new Date(time.setMinutes(40));
  } else if (minute >= 50 && minute < 60) {
    setTime = new Date(time.setMinutes(50));
  } else {
    setTime = requestDate;
  }
  return setTime;
}

function addMinutes(time, minute) {
  const addTime = new Date(time.getTime() + minute * 60 * 1000);
  return addTime;
}

// 해당 view에서 사용되는 state를 sessionStorage에서 불러옴
function getSessionStorageItem(viewName, stateName) {
  // viewName = hotelList
  // stateName = search
  if (!viewName || !stateName) return undefined;
  // 뒤로가기, 새로고침 시에도 값 유지되도록 함
  const storedItem = sessionStorage.getItem(`${viewName}_${stateName}`);
  return storedItem;
}

// 해당 view에서 사용되는 state를 sessionStorage에 저장
function setSessionStorageItem(viewName, stateName, value) {
  if (!viewName || !stateName) return;
  // 뒤로가기, 새로고침 시에도 값 유지되도록 함
  sessionStorage.setItem(`${viewName}_${stateName}`, value);
}

function formatXml(xml) {
  let formatted = "";
  let reg = /(>)(<)(\/*)/g;
  xml = xml.replace(reg, "$1\r\n$2$3");
  let pad = 0;
  jQuery.each(xml.split("\r\n"), function(index, node) {
    let indent = 0;
    if (node.match(/.+<\/\w[^>]*>$/)) {
      indent = 0;
    } else if (node.match(/^<\/\w/)) {
      if (pad != 0) {
        pad -= 1;
      }
    } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
      indent = 1;
    } else {
      indent = 0;
    }

    let padding = "";
    for (let i = 0; i < pad; i++) {
      padding += "  ";
    }

    formatted += padding + node + "\r\n";
    pad += indent;
  });
  return formatted;
}

function formatHtml(html) {
  let formatted = "";
  let reg = /(\n)(<)(\/*)/g;
  html = html.replace(reg, "$1$2$3");
  let pad = 0;
  const lines = html.split("\n");
  lines.forEach((node) => {
    let indent = 0;
    if (node.match(/.+<\/\w[^>]*>$/)) {
      // Closing tag after content on the same line
      indent = 0;
    } else if (node.match(/^<\/\w/)) {
      // Closing tag
      if (pad != 0) {
        pad -= 1;
      }
    } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
      // Opening tag
      indent = 1;
    } else {
      // Self-closing tag or text content
      indent = 0;
    }

    let padding = "";
    for (let i = 0; i < pad; i++) {
      padding += "  ";
    }

    formatted += padding + node + "\n";
    pad += indent;
    console.log(formatted);
  });
  return escapeHTML(formatted);
}

function escapeHTML(html) {
  if (html == null || html == "" || html == undefined) return "-";

  let replaceHtml = html;
  return replaceHtml
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/\n/g, "\n");
}

function formatJson(json){
    // return JSON.stringify(json, null, 6).replace(/\n( {6,})/g, function(match, p1) {
    //   return "<br>" + "&nbsp;".repeat(p1.length);
    // });
    return JSON.stringify(json, null, 6);
}

function formatLog(log) {
  if (log == null || log == "" || log == undefined) return "-";

  let replaceLog = log;

  // replaceLog = replaceLog.replace(/&/g, "&amp;");
  // replaceLog = replaceLog.replace(/</g, "&lt;");
  // replaceLog = replaceLog.replace(/>/g, "&gt;");
  // replaceLog = replaceLog.replace(/ /g, "&nbsp;");
  // replaceLog = replaceLog.replace(/\n/g, "\n");

  return replaceLog;
}

// json, xml, html형식의 string을 보기 좋게 정리함.
function prettyPrint(str) {
  if (str == null || str == "" || str == undefined || str == "-") return "-";

  if (str.includes("<!DOCTYPE html>")) {
    // html 문자열일 경우
    const html = str;
    const formattedHtmlContent = formatHtml(html);

    return formattedHtmlContent;
  } else if (str.includes("<?xml")) {
    // xml 문자열일 경우
    const xml = str;
    const formattedXmlContent = formatXml(xml);

    return formattedXmlContent;
  } else if (str.startsWith("\nlog:")) {
    // log 일 경우
    const log = str;
    const formattedLogContent = formatLog(log);

    return formattedLogContent;
  } else {
    // json 문자열일 경우
    const json = str;
    const parsedJson = JSON.parse(json);
    return formatJson(parsedJson);
  }
}

function changeEmptyValueToDash (object) {
  if(!object) return;
  // 속성의 값을 하나씩 검사
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      // 객체 자체의 속성만 검사
      if (object[key] === '') {
        object[key] = '-';
      }
    }
  }
  return object;
}

exports.getDateInTimeZone = getDateInTimeZone;
exports.getTimeInTimeZone = getTimeInTimeZone;
exports.yyyymmdd = yyyymmdd;
exports.yyyy_mm_dd = yyyy_mm_dd;
exports.mm_ddyyyy = mm_ddyyyy;
exports.mm_dd = mm_dd;
exports.hhmmss = hhmmss;
exports.hh_mm_ss = hh_mm_ss;
exports.hh_mm = hh_mm;
exports.hh_mm_am_pm = hh_mm_am_pm;
exports.yyyymmdd_hhmmss = yyyymmdd_hhmmss;
exports.yyyy_mm_dd_hh_mm_ss = yyyy_mm_dd_hh_mm_ss;
exports.yyyy_mm_dd_hh_mm = yyyy_mm_dd_hh_mm;
exports.getQueryVariable = getQueryVariable;
exports.hhMMssByTimeZone = hhMMssByTimeZone;
exports.yyyymmddDashByTimeZone = yyyymmddDashByTimeZone;
exports.yyyymmddDot_hh_mm_ssByTimeZone = yyyymmddDot_hh_mm_ssByTimeZone;
exports.formatSlash = formatSlash;
exports.formatDash = formatDash;
exports.formatAMPM = formatAMPM;
exports.formatDateTimeDot = formatDateTimeDot;
exports.formatDateTimeSlash = formatDateTimeSlash;
exports.formatDateMinutesSlash = formatDateMinutesSlash;
exports.roundDownToTenMinutes = roundDownToTenMinutes;
exports.addMinutes = addMinutes;
exports.getSessionStorageItem = getSessionStorageItem;
exports.setSessionStorageItem = setSessionStorageItem;
exports.formatXml = formatXml;
exports.formatHtml = formatHtml;
exports.formatJson = formatJson;
exports.prettyPrint = prettyPrint;
exports.changeEmptyValueToDash = changeEmptyValueToDash;
