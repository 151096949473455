// RegionAddData.js
import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class RegionEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_REGION") {
      this.onEditRegionOneResults(props);
    }
  }

  // redux에 edit region 요청
  requestEditRegion = (regionId, region) => {
    if (region == "") {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_region"],
        () => {},
        () => {},
        () => {}
      );
    } else {
      this.setState({
        requestType: "EDIT_REGION",
      });
      this.props.onEdit(regionId, region);
    }
  };

  // get edit region results
  onEditRegionOneResults = (props) => {
    if (props.regionEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.regionEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_region"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onEditSuccess = () => {
    const regionId = this.state.regionId;
    this.props.history.push(`/regionDetailPage?regionId=${regionId}`);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //edit 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.editRegion();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editRegion = () => {
    // 모든 필드가 값이 있으면 버튼을 비활성화
    document.querySelector(".edit_btn").setAttribute("disabled", "disabled");
    this.setState({
      isShowAllLoading: true,
    });
    this.requestEditRegion(this.state.regionId, this.state.region);
  };

  //cancelBtn
  cancelBtn = () => {
    this.setState({ requestType: "" });
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_cancel_not_save_message"],
      () => {
        this.props.hideTopPopupDialog();
        this.onBackBtn();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/regionListPage");
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      regionDetail: state.location.regionDetail,
      regionEdit: state.location.regionEdit,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (regionId, region) => dispatch(locationActions.onEdit(regionId, region)),
    };
  };
}

export default RegionEditData;
