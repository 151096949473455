import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as termsAndConditionApis from "../lib/termsAndCondition_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const TERMS_GROUP_GET_ALL = "termsGroup/TERMS_GROUP_GET_ALL";
const TERMS_GROUP_GET_ONE = "termsGroup/TERMS_GROUP_GET_ONE";
const ADD_TERMS_GROUP = "termsGroup/ADD_TERMS_GROUP";
const DELETE_TERMS_GROUP = "termsGroup/DELETE_TERMS_GROUP";
const EDIT_TERMS_GROUP = "termsGroup/EDIT_TERMS_GROUP";

const AGREEMENT_HISTORY_GET_ALL = "agreementHistory/AGREEMENT_HISTORY_GET_ALL";
const AGREEMENT_HISTORY_GET_ONE = "agreementHistory/AGREEMENT_HISTORY_GET_ONE";

const TERMS_GET_ALL = "terms/TERMS_GET_ALL";
const GET_TERMS_GROUP_LIST = "terms/GET_TERMS_GROUP_LIST";
const TERMS_GET_ONE = "terms/TERMS_GET_ONE";
const ADD_TERMS = "terms/ADD_TERMS";
const EDIT_TERMS = "terms/EDIT_TERMS";
const DELETE_TERMS = "terms/DELETE_TERMS";

// 액션 생성자
export const onGetAllTermsGroup = createAction(TERMS_GROUP_GET_ALL, termsAndConditionApis.onGetAllTermsGroup);
export const onGetOneTermsGroup = createAction(TERMS_GROUP_GET_ONE, termsAndConditionApis.onGetOneTermsGroup);
export const onAddTermsGroup = createAction(ADD_TERMS_GROUP, termsAndConditionApis.onAddTermsGroup);
export const onDeleteTermsGroup = createAction(DELETE_TERMS_GROUP, termsAndConditionApis.onDeleteTermsGroup);
export const onEditTermsGroup = createAction(EDIT_TERMS_GROUP, termsAndConditionApis.onEditTermsGroup);

export const onGetAllAgreementHistoryList = createAction(AGREEMENT_HISTORY_GET_ALL, termsAndConditionApis.onGetAllAgreementHistoryList);
export const onGetAgreementHistory = createAction(AGREEMENT_HISTORY_GET_ONE, termsAndConditionApis.onGetAgreementHistory);

export const onGetAllTerms = createAction(TERMS_GET_ALL, termsAndConditionApis.onGetAllTerms);
export const onGetTermsGroupList = createAction(GET_TERMS_GROUP_LIST, termsAndConditionApis.onGetTermsGroupList);
export const onGetOneTerms = createAction(TERMS_GET_ONE, termsAndConditionApis.onGetOneTerms);
export const onAddTerms = createAction(ADD_TERMS, termsAndConditionApis.onAddTerms);
export const onEditTerms = createAction(EDIT_TERMS, termsAndConditionApis.onEditTerms);
export const onDeleteTerms = createAction(DELETE_TERMS, termsAndConditionApis.onDeleteTerms);


// 초기 상태
const initialState = {
  termsGroupList: [],
  termsGroupDetail: null,
  termsGroupAdd: null,
  termsGroupEdit: null,
  agreementHistoryList: [],
  agreementHistoryDetail: null,
  termsList: [],
  termsDetail: null,
  termsAdd: null,
  termsEdit: null,
  failData: null,
  successDelete: null,
};

export default handleActions(
  {
    ...pender({
      type: TERMS_GROUP_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsGroupList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, termsGroupList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: TERMS_GROUP_GET_ONE,
      onPending: (state, action) => {
        return { ...state, termsGroupDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsGroupDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_TERMS_GROUP,
      onPending: (state, action) => {
        return { ...state, termsGroupAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsGroupAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_TERMS_GROUP,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_TERMS_GROUP,
      onPending: (state, action) => {
        return { ...state, termsGroupEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsGroupEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: AGREEMENT_HISTORY_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, agreementHistoryList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: AGREEMENT_HISTORY_GET_ONE,
      onPending: (state, action) => {
        return { ...state, termsGroupDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, agreementHistoryDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: TERMS_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, termsList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_TERMS_GROUP_LIST,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsGroupList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, termsGroupList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: TERMS_GET_ONE,
      onPending: (state, action) => {
        return { ...state, termsDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_TERMS,
      onPending: (state, action) => {
        return { ...state, termsAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_TERMS,
      onPending: (state, action) => {
        return { ...state, termsEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, termsEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_TERMS,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
