import { getMonth, getYear, isAfter } from 'date-fns';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// img
import LeftArrow from "../../../img/icon_calendar_prev_btn.png";
import RightArrow from "../../../img/icon_calender_next_btn.png";

import "../dateCalendar/dateCalendar.css";
import { formatDate } from "../../../common/Utils";

const YEARS = Array.from({ length: getYear(new Date()) + 1 - 2000 }, (_, i) => getYear(new Date()) - i);
const MONTHS = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
];

class DateCalendar extends Component {
  constructor(props) {
    super(props);
    // this.todayDate = this.todayDate.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);

    // 오늘 날짜를 Default로 설정
    const today = new Date();
    this.state = {
      getSelectedDate: today,
      isInitialDate: true,
    };
  }

  // todayDate(date) {
  //   return todayDate(date);
  // }

  onChangeDate(date) {
    const { disabled } = this.props;

    // disabled prop이 존재하면 선택된 날짜를 변경하지 않음
    if (!disabled) {
      this.setState({
        getSelectedDate: date,
        isInitialDate: false,
      });
    }
  }

  // DatePicker 내부에서 월 변경 시 강제로 리렌더링
  onMonthChange = (date) => {
    this.setState({
      getSelectedDate: date,
      isInitialDate: false,
    });
    this.forceUpdate();
  };

  // 오늘 날짜로부터 1년 이상 차이나는지 확인
  errorTest = (date) => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    return !isAfter(date, oneYearAgo);
  };

  render() {
    const { selectedDate, disabled } = this.props;
    const { getSelectedDate } = this.state;

    const formatedDate = getSelectedDate
      ? formatDate(getSelectedDate.toLocaleDateString())
      : 'None';

    return (
      <div className="selectedDateContainer">
        <div>
          <div className={`selectedDate ${this.errorTest(getSelectedDate) ? 'error' : ''}`}>
            {formatedDate}
          </div>
          <div className={`datePickerWrapper ${this.errorTest(getSelectedDate) ? 'error' : ''}`}>
            <DatePicker
              dateFormat='yyyy-MM-dd'
              formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
              minDate={new Date('2000-01-01')}
              maxDate={null}
              selected={selectedDate}
              openToDate={getSelectedDate}
              calendarClassName="calenderWrapper"
              onChange={this.onChangeDate}
              onMonthChange={this.onMonthChange}
              disabled={disabled}
              className="datePicker"
              dayClassName={(date) => {
                const isInCurrentMonth = getMonth(date) == getMonth(getSelectedDate);
                const isToday = isAfter(date, new Date()) && !isAfter(date, new Date(new Date().setHours(23, 59, 59, 999)));
                const isPastOrFuture = !isInCurrentMonth ? 'pastOrFuture' : '';

                return `${isInCurrentMonth ? 'currentMonth' : ''} ${isToday ? 'today' : ''} ${isPastOrFuture}`;
              }}
              renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                <div className="customHeaderContainer">
                  <div>
                    <span className="month">
                      <select
                        value={getYear(date)}
                        className="year"
                        onChange={({ target: { value } }) => changeYear(+value)}
                      >
                        {YEARS.map((option) => (
                          <option key={option} value={option}>
                            {option + "년 " + MONTHS[getMonth(date)]}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                  <div className='calendar_btn'>
                    <button
                      type='button'
                      onClick={() => decreaseMonth()}
                      className="monthButton"
                      disabled={prevMonthButtonDisabled}
                    >
                      <img src={LeftArrow} alt={"LeftArrow"} />
                    </button>
                    <button
                      type='button'
                      onClick={() => increaseMonth()}
                      className="monthButton"
                      disabled={nextMonthButtonDisabled}
                    >
                      <img src={RightArrow} alt={"RightArrow"} />
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DateCalendar;
