import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import VersionDetailData from "../Detail/VersionDetailData";


//css
import "../../../css/common.css";
import "../../../css/version/version/versionDetail.css";

class VersionDetailView extends VersionDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      versionId: "",
    };
  }

  // 첫 실행시 URL에서 versionId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const versionId = params.get("versionId");
    this.setState({
      versionId: versionId,
    });
    this.requestGetVersionOne(versionId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //edit page
  linkVersionEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const versionId = params.get("versionId");
    this.props.history.push(`/versionEditPage?versionId=${versionId}`);
  };

  render() {
    let versionDetail = null;

    if (
      this.props.versionDetail != null &&
      this.props.versionDetail?.success == true
    ) {
      versionDetail = this.props.versionDetail.results;
    }

    return (
      <div className="versionDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="versioninfo_detail_container">
          <div className="versioninfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_version_detail_title"
            >
              VERSION INFORMATION
            </div>
            <div className="versioninfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="version_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_version_detail_label_version_name"
                      >
                        Version Name
                      </div>
                      <div
                        className="detail_cell content versionName"
                        style={{ color: "white" }}
                      >
                        <span id="version_id">
                          {versionDetail?.versionName??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_version_detail_label_ios"
                      >
                        IOS
                      </div>
                      <div
                        className="detail_cell content ios"
                        style={{ color: "white" }}
                      >
                        <span id="version_id">{versionDetail?.ios??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_version_detail_label_ios_debug"
                      >
                        IOS Debug
                      </div>
                      <div
                        className="detail_cell content iosDebug"
                        style={{ color: "white" }}
                      >
                        <span id="version_id">{versionDetail?.iosDebug??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_version_detail_label_android"
                      >
                        Android
                      </div>
                      <div
                        className="detail_cell content android"
                        style={{ color: "white" }}
                      >
                        <span id="version_id">{versionDetail?.android??"-"}</span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_version_detail_label_android_debug"
                      >
                        Android Debug
                      </div>
                      <div
                        className="detail_cell content addressDebug"
                        style={{ color: "white" }}
                      >
                        <span id="version_id">
                          {versionDetail?.androidDebug??"-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_version_detail_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = VersionDetailData.mapStateToProps;
let mapDispatchToProps = VersionDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VersionDetailView)
);
