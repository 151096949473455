import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import ApplicaitonTranslationEditData from "../Edit/ApplicationTranslationEditData";

//css
import "../../css/applicationTranslation/applicationTranslationEdit.css";
import "../../css/common.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class ApplicationTranslationEditView extends ApplicaitonTranslationEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      appType: "",
      osType: "",
      appVersion: "",
      version: "",
      translationFile: null,
      translationText: "",
      applicationTranslationId: "",
      createdAt: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const applicationTranslationId = params.get("applicationTranslationId");
    this.setState({
      applicationTranslationId: applicationTranslationId,
      appType: this.props.applicationTranslationDetail.results.applicationType, //default값 설정
      osType: this.props.applicationTranslationDetail.results.osType, //default값 설정
      appVersion: this.props.applicationTranslationDetail.results.applicationVersion, //default값 설정
      version: this.props.applicationTranslationDetail.results.version, //default값 설정
      translationFile: this.props.applicationTranslationDetail.results.fileId, //default값 설정
      translationText: this.props.applicationTranslationDetail.results.fileName, //default값 설정
      createdAt: this.props.applicationTranslationDetail.results.created_at, //default값 설정
    });
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change appType
  onChangeAppType = (e) => {
    const inputValue = e.target.value;
    this.setState({ appType: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change osType
  onChangeOsType = (e) => {
    const inputValue = e.target.value;
    this.setState({ osType: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change appVersion
  onChangeAppVersion = (e) => {
    const inputValue = e.target.value;
    this.setState({ appVersion: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change version
  onChangeVersion = (e) => {
    const inputValue = e.target.value;
    this.setState({ version: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  //파일 다운로드
  onDownloadFile = () => {
    const { fileId, fileName } = this.props.applicationTranslationDetail.results;

    if (fileId && fileName) {
      const downloadUrl = `/translation/${fileId}`;

      // 새로운 링크 엘리먼트 생성
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = fileName;

      // 다운로드를 시작하고 클릭 이벤트를 트리거
      downloadLink.style.display = "none"; // 숨겨진 링크를 만들어 렌더링하지 않도록 함
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.error("fileId 또는 fileName이 없습니다.");
    }
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("appType", "admin_application_translation_edit_validation_application_type") &&
      this.confirmField("osType", "admin_application_translation_edit_validation_os_type") &&
      this.confirmField("appVersion", "admin_application_translation_edit_validation_application_version") &&
      this.confirmField("version", "admin_application_translation_edit_validation_version") &&
      this.confirmField("translationFile", "admin_application_translation_edit_validation_translation_file")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState(
      {
        translationFile: file,
        translationText: file ? file.name : "ex) MyCONECT.png",
      },
      () => {
        this.onCheckValidation(); // 파일이 선택되면 바로 검증 실행
      }
    );
  };

  // 데이터 저장을 로딩으로 감싸기
  add_btn = () => {
    const { copyId } = this.state;
    this.onSubmit();
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/applicationTranslationListPage");
  };



  render() {
    let applicationTranslationDetail = null;

    if (this.props.applicationTranslationDetail != null && this.props.applicationTranslationDetail?.success == true) {
      applicationTranslationDetail = this.props.applicationTranslationDetail.results;
    }

    return (
      <div className="applicationTranslationEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="applicationTranslation_add_container">
          <div className="addapplicationTranslation_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_application_translation_edit_title">APPLICATION TRANSLATION DETAIL</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addapplicationTranslation_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addapplicationTranslation_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_edit_label_admin_application_type">Application Type</p>
                      </div>
                      <div className="detail_cell content">
                        <select
                          id="appType"
                          className="appType_select"
                          name="appType"
                          onChange={this.onChangeAppType}
                          value={this.state.appType !== "" ? this.state.appType : this.props.applicationTranslationDetail ? this.props.applicationTranslationDetail.appType : "0"}
                        >
                          <option disabled value="" data-detect="admin_application_translation_edit_placeholder_select_box_choose">
                            Select
                          </option>
                          <option value="GUEST" data-detect="admin_application_translation_edit_select_admin_application_type_guest">
                            Guest
                          </option>
                          <option value="STAFF" data-detect="admin_application_translation_edit_select_admin_application_type_staff">
                            Staff
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_edit_label_os_type">OS Type</p>
                      </div>
                      <div className="detail_cell content">
                        <select
                          id="osType"
                          className="osType_select"
                          name="osType"
                          value={this.state.osType !== "" ? this.state.osType : this.props.applicationTranslationDetail ? this.props.applicationTranslationDetail.osType : "0"}
                          onChange={this.onChangeOsType}
                        >
                          <option disabled value="" data-detect="admin_application_translation_edit_placeholder_select_box_choose">
                            Select
                          </option>
                          <option value="AOS" data-detect="admin_application_translation_edit_select_os_type_aos">
                            AOS
                          </option>
                          <option value="iOS" data-detect="admin_application_translation_edit_select_os_type_ios">
                            iOS
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_edit_label_admin_application_version">Application Version</p>
                      </div>
                      <div className="detail_cell content applicationTranslation_appVersion">
                        <input type="text" id="appVersion" name="appVersion" autoComplete="off" value={this.state.appVersion} onChange={this.onChangeAppVersion} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_edit_label_version">Version</p>
                      </div>
                      <div className="admin_application_translation_add_label_version">
                        <input type="text" id="version" name="version" autoComplete="off" value={this.state.version} onChange={this.onChangeVersion} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_application_translation_edit_label_file_name">
                        File Name
                      </div>
                      <div className="file_upload_button_container">
                        {this.state.translationText ? <p className="file_textName">{this.state.translationText}</p> : <p className="file_textName">{"ex) My conect.png"}</p>}
                        <form>
                          <label htmlFor="translationFile" className="file_upload_button" data-detect="admin_application_translation_edit_button_upload">
                            Upload
                          </label>
                          <input
                            type="file"
                            id="translationFile"
                            accept=".jpg, .jpeg, .png, .gif, .json"
                            onChange={this.onFileChange}
                            title="Upload"
                            style={{ display: "none" }} // input 요소를 화면에서 감추기
                          />
                        </form>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_application_translation_edit_label_date">Date</p>
                      </div>
                      <div className="detail_cell content info_date">
                        <span>{applicationTranslationDetail && formatDateTimeDot(applicationTranslationDetail.created_at)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="cancel_btn_container">
                <button type="button" className="cancel_btn" data-detect="admin_application_translation_edit_button_cancel" onClick={this.onBackBtn}>
                  Cancel
                </button>
              </div>
              <div className="download_btn_container">
                <button className="download_btn">
                  <a id="download_link" href="#" download data-detect="admin_application_translation_edit_button_download" onClick={this.onDownloadFile}></a>
                </button>
              </div>
              <div className="ok_btn_container">
                <button type="button" className="ok_btn" data-detect="admin_application_translation_edit_button_ok" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicaitonTranslationEditData.mapStateToProps;
let mapDispatchToProps = ApplicaitonTranslationEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationTranslationEditView));
