import CommonComponent from "../components/CommonComponent";

// Modules
import * as otaReservationActions from "../../redux/modules/OTAReservation";

// Language

class OTAReservationData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: '',
    };
  }

  /* ------------------------------------------------------ 생명 주기 ----------------------------------------------------- */
  componentWillReceiveProps(props) {
    if (this.state.requestType == 'GET_OTA_HOTEL_LIST') {
      this.onGetHotelAllResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_ALL') {
      this.onGetHotelReservationAllResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_ONE') {
      this.onGetHotelReservationOneResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_MEMBERSHIP_TYPE') {
      this.onGetHotelReservationMembershipTypeResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_TITLE_TYPE') {
      this.onGetHotelReservationTitleTypeResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_CARD_TYPE') {
      this.onGetHotelReservationCardTypeResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_PROFILE_BY_EMAIL') {
      this.onGetHotelReservationProfileByEmailResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_PROFILE_BY_MEMBERSHIP') {
      this.onGetHotelReservationProfileByMembershipResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_TERMS_CONDITIONS') {
      this.onGetHotelReservationTermsConditionsResults(props);
    }
    if (this.state.requestType == 'GET_OTA_RESERVATION_PRIVACY_POLICY') {
      this.onGetHotelReservatioPrivacyPolicynResults(props);
    }
    if (this.state.requestType == 'ADD_OTA_RESERVATION_BOOKING_WITH_PROFILE') {
      this.onAddHotelReservationBookingWithProfileResults(props);
    }
    if (this.state.requestType == 'ADD_OTA_RESERVATION_BOOKING') {
      this.onAddHotelReservationBookingResults(props);
    }
  }

  /* ---------------------------------------------------- Requests ---------------------------------------------------- */
  requestGetHotelList = () => {
    this.setState({
      requestType: 'GET_OTA_HOTEL_LIST',
    });
    this.props.onGetHotelAll();
  };

  requestGetHotelReservationList = (setHotelId, checkIn, checkOut, adultNumber, chlidrenNumber) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_ALL',
    });

    this.props.onGetAll(setHotelId, checkIn, checkOut, adultNumber, chlidrenNumber);
  };

  requestGetHotelReservationOne = (setHotelId) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_ONE',
    });
    this.props.onGetOne(setHotelId);
  };

  requestGetHotelReservationMembershipType = (setHotelId) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_MEMBERSHIP_TYPE',
    });
    this.props.onGetMembershipType(setHotelId);
  };

  requestGetHotelReservationTitleType = (setHotelId) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_TITLE_TYPE',
    });
    this.props.onGetTitleType(setHotelId);
  };

  requestGetHotelReservationCardType = (setHotelId) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_CARD_TYPE',
    });
    this.props.onGetCardType(setHotelId);
  };

  requestGetHotelReservationProfileByEmail = (setHotelId, searchEmailVal) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_PROFILE_BY_EMAIL',
    });
    this.props.onGetProfileByEmail(setHotelId, searchEmailVal);
  };

  requestGetHotelReservationProfileByMembership = (setHotelId, membershipLastName, membershipType, searchEmailVal) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_PROFILE_BY_MEMBERSHIP',
    });
    this.props.onGetProfileByMembership(setHotelId, membershipLastName, membershipType, searchEmailVal);
  };

  requestGetHotelReservationTermsConditions = (setHotelId) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_TERMS_CONDITIONS',
    });
    this.props.onGetTermsConditions(setHotelId);
  };

  requestGetHotelReservationPrivacyPolicy = (setHotelId) => {
    this.setState({
      requestType: 'GET_OTA_RESERVATION_PRIVACY_POLICY',
    });
    this.props.onGetPrivacyPolicy(setHotelId);
  };

  requestAddBookingWithProfile = (
    hotelId,
    startDate,
    endDate,
    ratePlanCode,
    roomTypeCode,
    adults,
    children,
    numberOfUnits,
    guaranteeType,
    cardType,
    cardHolderName,
    cardNumber,
    expirationDate,
    nameTitle,
    firstName,
    lastName,
    email,
    phone,
    alternativeFirstName,
    alternativeLastName,
    requestedPageType
  ) => {
    this.setState({
      requestType: 'ADD_OTA_RESERVATION_BOOKING_WITH_PROFILE',
    });
    this.props.onAddBookingWithProfile(
      hotelId,
      startDate,
      endDate,
      ratePlanCode,
      roomTypeCode,
      adults,
      children,
      numberOfUnits,
      guaranteeType,
      cardType,
      cardHolderName,
      cardNumber,
      expirationDate,
      nameTitle,
      firstName,
      lastName,
      email,
      phone,
      alternativeFirstName,
      alternativeLastName,
      requestedPageType
    );
  };

  requestAddBooking = (
    hotelId,
    startDate,
    endDate,
    ratePlanCode,
    roomTypeCode,
    adults,
    children,
    numberOfUnits,
    guaranteeType,
    cardType,
    cardHolderName,
    cardNumber,
    expirationDate,
    profileId,
    requestedPageType
  ) => {
    this.setState({
      requestType: 'ADD_OTA_RESERVATION_BOOKING',
    });
    this.props.onAddBooking(
      hotelId,
      startDate,
      endDate,
      ratePlanCode,
      roomTypeCode,
      adults,
      children,
      numberOfUnits,
      guaranteeType,
      cardType,
      cardHolderName,
      cardNumber,
      expirationDate,
      profileId,
      requestedPageType
    );
  };

  /* ----------------------------------------------------- Results ---------------------------------------------------- */
  onGetHotelAllResults = (props) => {
    if (props.hotelList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({
      isShowAllLoading: false,
      requestType: '',
    });
    if (props.hotelList?.success == true) {
    } else {
      this.setState({
        message: 'hotel_reservation_failed_get_hotel_list',
        isValidationBox: true,
      });
    }
  };

  onGetHotelReservationAllResults = (props) => {
    if (props.hotelReservationList?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationList != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationList?.success == true) {
        this.setState({
          isRoomList: true,
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_room_list',
          isValidationBox: true,
        });
      }
    }
  };

  onGetHotelReservationOneResults = (props) => {
    if (props.hotelReservationDetail == null && props.failData == null) {
      return;
    }
    if (props.hotelReservationDetail != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationDetail?.success == true) {
        this.requestGetHotelReservationMembershipType(this.state.hotelId);
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_hotel_info',
          isValidationBox: true,
          isOpenSelectRoom: false,
        });
      }
    }
  };

  onGetHotelReservationMembershipTypeResults = (props) => {
    if (props.hotelReservationMembershipTypeList?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationMembershipTypeList != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationMembershipTypeList?.success == true) {
        this.requestGetHotelReservationTitleType(this.state.hotelId);
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_membership_type',
          isValidationBox: true,
          isOpenSelectRoom: false,
        });
      }
    }
  };

  onGetHotelReservationTitleTypeResults = (props) => {
    if (props.hotelReservationTitleTypeList?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationTitleTypeList != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationTitleTypeList?.success == true) {
        this.requestGetHotelReservationCardType(this.state.hotelId);
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_title',
          isValidationBox: true,
          isOpenSelectRoom: false,
        });
      }
    }
  };

  onGetHotelReservationCardTypeResults = (props) => {
    if (props.hotelReservationCardTypeList?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationCardTypeList != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationCardTypeList?.success == true) {
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_card_type',
          isValidationBox: true,
          isOpenSelectRoom: false,
        });
      }
    }
  };

  onGetHotelReservationProfileByEmailResults = (props) => {
    if (props.hotelReservationProfileByEmailDetail?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationProfileByEmailDetail != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationProfileByEmailDetail?.success == true) {
        this.setState({
          profileId: props.hotelReservationProfileByEmailDetail.results.profileId,
          nameTitle: props.hotelReservationProfileByEmailDetail.results.nameTitle,
          firstName: props.hotelReservationProfileByEmailDetail.results.firstName,
          lastName: props.hotelReservationProfileByEmailDetail.results.lastName,
          alternativeLanguageCode: props.hotelReservationProfileByEmailDetail.results.alternativeLanguageCode,
          alternativeFirstName: props.hotelReservationProfileByEmailDetail.results.alternativeFirstName,
          alternativeLastName: props.hotelReservationProfileByEmailDetail.results.alternativeLastName,
          email: props.hotelReservationProfileByEmailDetail.results.email,
          phone: props.hotelReservationProfileByEmailDetail.results.phone,
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_email_missing',
          isValidationBox: true,
        });
      }
    }
  };

  onGetHotelReservationProfileByMembershipResults = (props) => {
    if (props.hotelReservationProfileByMembershipDetail?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationProfileByMembershipDetail != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationProfileByMembershipDetail?.success == true) {
        this.setState({
          profileId: props.hotelReservationProfileByMembershipDetail.results.profileId,
          nameTitle: props.hotelReservationProfileByMembershipDetail.results.nameTitle,
          firstName: props.hotelReservationProfileByMembershipDetail.results.firstName,
          lastName: props.hotelReservationProfileByMembershipDetail.results.lastName,
          alternativeLanguageCode: props.hotelReservationProfileByMembershipDetail.results.alternativeLanguageCode,
          alternativeFirstName: props.hotelReservationProfileByMembershipDetail.results.alternativeFirstName,
          alternativeLastName: props.hotelReservationProfileByMembershipDetail.results.alternativeLastName,
          email: props.hotelReservationProfileByMembershipDetail.results.email,
          phone: props.hotelReservationProfileByMembershipDetail.results.phone,
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_membership_missing',
          isValidationBox: true,
        });
      }
    }
  };

  onGetHotelReservationTermsConditionsResults = (props) => {
    if (props.hotelReservationTermsConditionsDetail?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationTermsConditionsDetail != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationTermsConditionsDetail?.success == true) {
        this.setState({
          viewTerms: 'terms',
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_terms_conditions',
          isValidationBox: true,
        });
      }
    }
  };

  onGetHotelReservatioPrivacyPolicynResults = (props) => {
    if (props.hotelReservationPrivacyPolicyDetail?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationPrivacyPolicyDetail != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationPrivacyPolicyDetail?.success == true) {
        this.setState({
          viewTerms: 'privacy',
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_get_privacy_policy',
          isValidationBox: true,
        });
      }
    }
  };

  onAddHotelReservationBookingWithProfileResults = (props) => {
    if (props.hotelReservationBookingWithProfileAdd?.length === 0 && props.failData == null) {
      return;
    }
    console.log('get to book with profile', props.hotelReservationBookingWithProfileAdd);

    if (props.hotelReservationBookingWithProfileAdd != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      console.log('success to book with profile', props.hotelReservationBookingWithProfileAdd);
      if (props.hotelReservationBookingWithProfileAdd?.success == true) {
        this.setState({
          referenceNumber: props.hotelReservationBookingWithProfileAdd.results.referenceNumber,
          roomType: props.hotelReservationBookingWithProfileAdd.results.roomType,
          arrivalDate: props.hotelReservationBookingWithProfileAdd.results.arrivalDate.split('T')[0],
          departureDate: props.hotelReservationBookingWithProfileAdd.results.departureDate.split('T')[0],
          adults: props.hotelReservationBookingWithProfileAdd.results.adults,
          children: props.hotelReservationBookingWithProfileAdd.results.children,
          isComplete: true,
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_reservation',
          isValidationBox: true,
        });
      }
    }
  };

  onAddHotelReservationBookingResults = (props) => {
    if (props.hotelReservationBookingAdd?.length === 0 && props.failData == null) {
      return;
    }

    if (props.hotelReservationBookingAdd != undefined || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: '',
      });
      if (props.hotelReservationBookingAdd?.success == true) {
        this.setState({
          referenceNumber: props.hotelReservationBookingAdd.results.referenceNumber,
          roomType: props.hotelReservationBookingAdd.results.roomType,
          arrivalDate: props.hotelReservationBookingAdd.results.arrivalDate.split('T')[0],
          departureDate: props.hotelReservationBookingAdd.results.departureDate.split('T')[0],
          adults: props.hotelReservationBookingAdd.results.adults,
          children: props.hotelReservationBookingAdd.results.children,
          isComplete: true,
        });
      } else {
        this.setState({
          message: 'hotel_reservation_failed_reservation',
          isValidationBox: true,
        });
      }
    }
  };

  /* ------------------------------------------- props으로 보낼 state, dispatch ------------------------------------------- */
  static mapStateToProps = (state, props) => {
    return {
      data: state.auth.data,
      userToken: state.auth.sessionData,
      hotelList: state.otaReservation.hotelList,
      hotelReservationList: state.otaReservation.hotelReservationList,
      hotelReservationDetail: state.otaReservation.hotelReservationDetail,
      hotelReservationMembershipTypeList: state.otaReservation.hotelReservationMembershipTypeList,
      hotelReservationTitleTypeList: state.otaReservation.hotelReservationTitleTypeList,
      hotelReservationCardTypeList: state.otaReservation.hotelReservationCardTypeList,
      hotelReservationProfileByEmailDetail: state.otaReservation.hotelReservationProfileByEmailDetail,
      hotelReservationProfileByMembershipDetail: state.otaReservation.hotelReservationProfileByMembershipDetail,
      hotelReservationTermsConditionsDetail: state.otaReservation.hotelReservationTermsConditionsDetail,
      hotelReservationPrivacyPolicyDetail: state.otaReservation.hotelReservationPrivacyPolicyDetail,
      hotelReservationBookingWithProfileAdd: state.otaReservation.hotelReservationBookingWithProfileAdd,
      hotelReservationBookingAdd: state.otaReservation.hotelReservationBookingAdd,
      failData: state.otaReservation.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    const returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetHotelAll: () => dispatch(otaReservationActions.onGetHotelAll()),
      onGetAll: (setHotelId, checkIn, checkOut, adultNumber, chlidrenNumber) => dispatch(otaReservationActions.onGetAll(setHotelId, checkIn, checkOut, adultNumber, chlidrenNumber)),
      onGetOne: (setHotelId) => dispatch(otaReservationActions.onGetOne(setHotelId)),
      onGetMembershipType: (setHotelId) => dispatch(otaReservationActions.onGetMembershipType(setHotelId)),
      onGetTitleType: (setHotelId) => dispatch(otaReservationActions.onGetTitleType(setHotelId)),
      onGetCardType: (setHotelId) => dispatch(otaReservationActions.onGetCardType(setHotelId)),
      onGetProfileByEmail: (setHotelId, searchEmailVal) => dispatch(otaReservationActions.onGetProfileByEmail(setHotelId, searchEmailVal)),
      onGetProfileByMembership: (setHotelId, membershipLastName, membershipType, searchEmailVal) =>
        dispatch(otaReservationActions.onGetProfileByMembership(setHotelId, membershipLastName, membershipType, searchEmailVal)),
      onGetTermsConditions: (setHotelId) => dispatch(otaReservationActions.onGetTermsConditions(setHotelId)),
      onGetPrivacyPolicy: (setHotelId) => dispatch(otaReservationActions.onGetPrivacyPolicy(setHotelId)),
      onAddBookingWithProfile: (
        hotelId,
        startDate,
        endDate,
        ratePlanCode,
        roomTypeCode,
        adults,
        children,
        numberOfUnits,
        guaranteeType,
        cardType,
        cardHolderName,
        cardNumber,
        expirationDate,
        nameTitle,
        firstName,
        lastName,
        email,
        phone,
        alternativeFirstName,
        alternativeLastName,
        requestedPageType
      ) =>
        dispatch(
          otaReservationActions.onAddBookingWithProfile(
            hotelId,
            startDate,
            endDate,
            ratePlanCode,
            roomTypeCode,
            adults,
            children,
            numberOfUnits,
            guaranteeType,
            cardType,
            cardHolderName,
            cardNumber,
            expirationDate,
            nameTitle,
            firstName,
            lastName,
            email,
            phone,
            alternativeFirstName,
            alternativeLastName,
            requestedPageType
          )
        ),
      onAddBooking: (
        hotelId,
        startDate,
        endDate,
        ratePlanCode,
        roomTypeCode,
        adults,
        children,
        numberOfUnits,
        guaranteeType,
        cardType,
        cardHolderName,
        cardNumber,
        expirationDate,
        profileId,
        requestedPageType
      ) =>
        dispatch(
          otaReservationActions.onAddBooking(
            hotelId,
            startDate,
            endDate,
            ratePlanCode,
            roomTypeCode,
            adults,
            children,
            numberOfUnits,
            guaranteeType,
            cardType,
            cardHolderName,
            cardNumber,
            expirationDate,
            profileId,
            requestedPageType
          )
        ),
    };

    return returnProps;
  };
}

export default OTAReservationData;
