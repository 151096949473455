import CommonComponent from "../../../components/CommonComponent";

// modules
import * as authActions from "../../../../redux/modules/Auth";
import * as locationActions from "../../../../redux/modules/Location";

class CityListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      regionList: [],
      cityList: [],
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.regionList != this.props.regionList) {
      if (this.state.requestType == 'GET_REGION_LIST') {
        this.onGetRegionListResults(nextProps);
      }
    }
    if (nextProps.cityList != this.props.cityList) {
      if (this.state.requestType == 'GET_CITY_LIST') {
        this.onGetCityListResults(nextProps);
      }
    }
    if (nextProps.cityData != this.props.cityData) {
      if (this.state.requestType == 'GET_COUNTRY') {
        this.onGetCountryResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_CITY_ONE") {
      this.onDeleteCityListResults(nextProps);
    }

  }

  // redux에 get region list 요청
  requestGetRegionList = (pageNo, search) => {
    this.setState({
      requestType: "GET_REGION_LIST",
    });
    this.props.onGetAll(pageNo, search);
  };
  // region값이 변경될때마다 redux에 get country  요청
  requestGetCountry = (regionId) => {
    this.setState({
      requestType: "GET_COUNTRY",
    });
    this.props.onGetCountry(regionId);
  };

  // 검색시 redux에 조건에 맞는 list 요청
  requestGetCityList = (pageNo, search, regionId, countryId) => {
    this.setState({
      requestType: "GET_CITY_LIST",
    });
    this.props.onGetAllCity(pageNo, search, regionId, countryId);
  };

  // redux에 순차적으로 delete City 요청
  requestDeleteCity = (cityId) => {
    this.setState({
      requestType: "DELETE_CITY_ONE",
    });
    this.props.onDeleteOne(cityId);
  };

  // get region list results
  onGetRegionListResults = (props) => {
    if (props.regionList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ requestType: "" });
    this.requestGetCityList(this.state.pageNo, this.state.search, this.state.regionId, this.state.countryId);

    if (props.regionList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_region_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // get Country results
  onGetCountryResults = (props) => {
    if (props.cityData?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.cityData?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_country_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // get city list results
  onGetCityListResults = (props) => {
    if (props.cityList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.cityList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete city results
  onDeleteCityListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteCity();
        } else {
          // 삭제 완료
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };


  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetCityList(this.state.pageNo, this.state.search, this.state.regionId, this.state.countryId);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, cityList) => {
    const currentCheckedList = [...this.state.checkedList];
    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // onClickOK
  onClickOK = () => {
    const { checkedList } = this.state;

    if (checkedList.length > 0) {
      checkedList.forEach((cityId) => {
        this.requestDeleteCity(cityId);
      });

      this.setState({ checkedList: [] });
    }
  };

  deleteSelectedCitys = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.deleteCity();
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteCity = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.requestDeleteCity(checkedList[0]);
    }
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/CityListPage");
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (cityList) => {
    const { checkedList } = this.state;
    const allCityCnt = cityList.length;
    const isChecked = allCityCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : cityList.map((city) => city.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      regionList: state.location.regionList,
      //city list
      cityList: state.location.cityList,
      //region에 따라 선택된 selectbox 값
      cityData: state.location.cityData,
      failData: state.location.failData,
      successDelete: state.location.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAll: (pageNo, search) => dispatch(locationActions.onGetAll(pageNo, search)),
      onGetAllCity: (pageNo, search, regionId, countryId) => dispatch(locationActions.onGetAllCity(pageNo, search, regionId, countryId)),
      onGetCountry: (regionId) => dispatch(locationActions.onGetCountry(regionId)),
      onDeleteOne: (cityId) => dispatch(locationActions.onDeleteCity(cityId)),
    };

    return returnProps;
  };
}

export default CityListData;
