import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as recommendApis from "../lib/recommend_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const RECOMMEND_GET_ALL = "recommend/RECOMMEND_GET_ALL";
const RECOMMEND_GET_ONE = "recommend/RECOMMEND_GET_ONE";
const ADD_RECOMMEND = "recommend/ADD_RECOMMEND";
const DELETE_RECOMMEND = "recommend/DELETE_RECOMMEND";
const EDIT_RECOMMEND = "recommend/EDIT_RECOMMEND";

// 액션 생성자
export const onGetAllRecommend = createAction(RECOMMEND_GET_ALL, recommendApis.onGetAllRecommend);
export const onGetOneRecommend = createAction(RECOMMEND_GET_ONE, recommendApis.onGetOneRecommend);
export const onAddRecommend = createAction(ADD_RECOMMEND, recommendApis.onAddRecommend);
export const onDeleteRecommend = createAction(DELETE_RECOMMEND, recommendApis.onDeleteRecommend);
export const onEditRecommend = createAction(EDIT_RECOMMEND, recommendApis.onEditRecommend);


// 초기 상태
const initialState = {
    recommendList: [],
    recommendDetail: null,
    recommendEdit: null,
    recommendAdd: null,
    failData: null,
    successDelete: null,
};

export default handleActions(
    {
        ...pender({
            type: RECOMMEND_GET_ALL,
            onPending: (state, action) => {
                return { ...state, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, recommendList: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, recommendList: [], failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: RECOMMEND_GET_ONE,
            onPending: (state, action) => {
                return { ...state, recommendDetail: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, recommendDetail: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: ADD_RECOMMEND,
            onPending: (state, action) => {
                return { ...state, recommendAdd: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, recommendAdd: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: DELETE_RECOMMEND,
            onPending: (state, action) => {
                return { ...state, successDelete: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, successDelete: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        ...pender({
            type: EDIT_RECOMMEND,
            onPending: (state, action) => {
                return { ...state, recommendEdit: null, failData: null };
            },
            onSuccess: (state, action) => {
                return { ...state, recommendEdit: action.payload.data };
            },
            onFailure: (state, action) => {
                return { ...state, failData: action.payload.response?.data };
            },
        }),
        [PURGE]: (state, action) => {
            return initialState;
        },
    },
    initialState
);
