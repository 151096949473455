import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllUserInfo(pageNo, type, search) {
  let name = "";
  let email = "";
  let department = "";
  let duty = "";

  if (type == "name") {
    name = search;
  } else if (type == "email") {
    email = search;
  } else if (type == "department") {
    department = search;
  } else if (type == "duty") {
    duty = search;
  }

  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/user/?email=" + email + "&name=" + name + "&department=" + department + "&duty=" + duty + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetUserInfoOne(userInfoId) {
  return axiosInstance.get("/admin/api/user/" + userInfoId);
}

//------------------------------- add userInfo ------------------------------------------------------//
export function onAdduserInfo(email, name, password, confirmPassword, department, duty) {
  const requestData = {
    email: email,
    password: password,
    name: name,
    department: department,
    duty: duty,
  };

  return axiosInstance.post("/admin/api/user/", requestData);
}
//------------------------------- delete application translation ------------------------------------------------------//
export function onDeleteUserInfo(id) {
  return axiosInstance.delete("/admin/api/user/" + id);
}

//------------------------------- edit UserInfo ------------------------------------------------------//
export function onEditUserInfo(userInfoId, duty) {
  const requestData = {
    duty: duty,
  };

  return axiosInstance.put("/admin/api/user/" + userInfoId, requestData);
}

//------------------------------- edit MyInfo ------------------------------------------------------//
export function onEditMyInfo(userInfoId, name, department) {
  const requestData = {
    name: name,
    department: department,
  };

  return axiosInstance.put("/admin/api/user/" + userInfoId, requestData);
}
