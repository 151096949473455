// ApplicationVersionListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import GuestApplicationVersionListData from "../List/guestApplicationVersionListData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/version/guestApplicationVersion/guestApplicationVersionList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class GuestApplicationVersionListView extends GuestApplicationVersionListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],
    };
  }

  componentDidMount() {
    this.requestGetGuestActiveList(); //active list 호출
    this.requestGetApplicationVersionList(this.state.pageNo, this.state.search); //Application List 호출
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      search: inputValue,
    });
  };

  onClickSearch = (e) => {
    this.setState({
      isShowAllLoading: true,
    });

    this.requestGetApplicationVersionList(1, this.state.search);
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const inputValue = e.target.value;
    if (window.event.keyCode == 13) {
      this.setState({
        isShowAllLoading: true,
      });

      this.requestGetApplicationVersionList(1, this.state.search);
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo }, () => {
      this.onPageChange(pageNo); // 페이지 변경 처리
      this.requestGetApplicationVersionList(pageNo, this.state.search); // 호텔 목록 요청
    });
  };

  //insert
  linkGuestApplicationVersionAdd = () => {
    this.props.history.push("/guestApplicationVersionAddPage");
  };

  //download Applicaiton
  downloadApplication = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true);
    link.click();
  };

  render() {
    let count;
    let activeList = [];
    let guestApplicationVersionList = [];

    if (
      this.props.guestApplicationVersionList.length !== 0 &&
      this.props.guestApplicationVersionList?.success == true
    ) {
      count = this.props.guestApplicationVersionList.results.count;
      guestApplicationVersionList = this.props.guestApplicationVersionList.results.rows;
    }
    if (
      this.props.activeList.length !== 0 &&
      this.props.activeList?.success == true
    ) {
      activeList = this.props.activeList.results;
    }

    return (
      <div className="gusetApplicationVersionList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="version_list_container">
          <div className="applicationVersion_list_container_inner">
            <div
              className="title"
              data-detect="admin_version_guest_app_version_list_title"
            >
              GUEST APPLICATION DOWNLOAD LIST
            </div>
            {/* active_version_list_table */}
            <div className="active_version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() =>
                          this.allCheck(activeList, this.state.pageNo)
                        }
                        checked={
                          activeList &&
                          this.state.checkedList.length == activeList.length
                        }
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_app_down_version_list_label_os"
                    >
                      OS
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_app_down_version_list_label_version"
                    >
                      Version
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_app_down_version_list_label_link"
                    >
                      Link
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_app_down_version_list_label_created_at"
                    >
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {activeList?.length > 0 ? (activeList.map((activeList, index) => (
                    <div className="row tbodyRow" key={activeList.id}>
                      <div className="cell">
                        <div className="eachIndexNo blind">
                          <span>{index + 1}</span>
                        </div>
                        <input
                          type="checkbox"
                          name="eachCheck"
                          key={activeList.id}
                          id={activeList.id}
                          onClick={() =>
                            this.onClickInput(activeList.id, activeList)
                          }
                          checked={this.state.checkedList.includes(
                            activeList.id
                          )}
                          readOnly
                        />
                        <label
                          className="tbodyLabel"
                          htmlFor={activeList.id}
                        ></label>
                      </div>
                      <div className="cell ellipsis">{activeList.os}</div>
                      <div className="cell">{activeList.version}</div>
                      <div className="cell ellipsis" title={activeList.link}>
                          {activeList.link}
                      </div>
                      <div className="cell">
                        {" "}
                        {formatDateTimeSlash(activeList.created_at)}{" "}
                      </div>
                    </div>
                  ))) : (
                    <div className="no-data-in-list" data-detect="admin_version_guest_app_version_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
            </div>
            <div className="btn_container_left">
              <button
                className="delete_btn"
                id="delete_btn"
                type="button"
                onClick={() => {
                  this.deleteGuestSelectedApplicationVersions();
                }}
              >
                <img src={deleteIcon} alt="delete button"/>
                <span data-detect="admin_version_guest_app_version_list_button_delete">
                  Delete
                </span>
              </button>
              <button
                className="add_btn"
                id="add_btn"
                type="button"
                onClick={() => {
                  this.linkGuestApplicationVersionAdd();
                }}
              >
                <img src={addIcon} alt="add button" />
                <span data-detect="admin_version_guest_app_version_list_button_add">
                  Add
                </span>
              </button>
            </div>

            {/* inactive_version_list_table */}
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_version_guest_app_version_list_placeholiser_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.search}
                      onChange={this.onChangeSearchText}
                    />
                    <button
                      type="button"
                      className="search_btn"
                      onClick={this.onClickSearch}
                    ></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="inactive_version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div
                      id="theadFistCell"
                      className="cell"
                      data-detect="admin_version_guest_app_version_list_label_no"
                    >
                      No.
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_guest_app_version_list_label_os"
                    >
                      OS
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_guest_app_version_list_label_version"
                    >
                      Version
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_guest_app_version_list_label_link"
                    >
                      Link
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_version_guest_app_version_list_label_created_at"
                    >
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {guestApplicationVersionList?.length > 0 ? (guestApplicationVersionList.map(
                    (guestAppVersionList, index) => (
                      <div
                        className={`row tbodyRow ${guestAppVersionList.id}`}
                        key={index}
                      >
                        <div className="cell">
                          <div className="eachIndexNo">
                            <span>{index}</span>
                          </div>
                        </div>
                        <div className="cell ellipsis">
                          {guestAppVersionList.os}
                        </div>
                        <div className="cell">
                          {guestAppVersionList.version}
                        </div>
                        <div className="cell ellipsis" title={guestAppVersionList.link}>
                            {guestAppVersionList.link}
                        </div>
                        <div className="cell">
                          {formatDateTimeSlash(guestAppVersionList.created_at)}{" "}
                        </div>
                      </div>
                    )
                  )): (
                    <div className="no-data-in-list" data-detect="admin_version_guest_app_version_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
              <Pagination
                total={count}
                dataSize={"10"}
                pageSize={"10"}
                handlePageChange={this.handlePageChange}
                pageNo={this.state.pageNo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = GuestApplicationVersionListData.mapStateToProps;
let mapDispatchToProps = GuestApplicationVersionListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GuestApplicationVersionListView)
);
