// Dialog.js

import React, { Component } from "react";
import "../../css/common/myConectErrorMessage.css";
import warningImg from "../../img/icon_warning.svg";

class Dialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowDialog: props.isShowDialog,
      dialogType: props.dialogType,
      content: props.content,
      subMessage: props.subMessage,
      modalClass: "errorMessage"
    };
  }

  onClose = () => {
    this.props.onClose();
  };

  onClickOK = () => {
    this.props.onClickOK();

  };

  render() {
    var buttons = [];

    if (this.state.dialogType == "OK") {
      buttons = [];
      buttons.push(
        <div className="okBtn ok" onClick={() => this.onClose()} key={"okBtn"}>
          OK
        </div>
      );

    } else {
      buttons = [];
      buttons.push(
        <div
          className="halfWidthBtn cancel"
          onClick={() => this.onClose()}
          key={"cancelBtn"}
        >
          Cancel
        </div>
      );
      buttons.push(
        <div
          className="halfWidthBtn ok"
          onClick={() => this.onClickOK()}
          key={"okBtn"}
        >
          OK
        </div>
      );
    }

    return (
      <div>
        <div className={this.state.modalClass}>
          <div>
            <img style={{ width: "100px" }} src={warningImg} alt={"warning"} />
            <p className="errorText">
              {this.state.content}
              <span className="subMessage">{this.state.subMessage}</span>
            </p>
            <div style={{ display: "flex" }}>{buttons}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dialog;
