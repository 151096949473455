import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as idRecognitionApis from "../lib/idRecognition_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const ID_RECOGNITION_GET_ALL = "idRecognition/ID_RECOGNITION_GET_ALL";
const ID_RECOGNITION_GET_ONE = "idRecognition/ID_RECOGNITION_GET_ONE";
const ADD_ID_RECOGNITION = "idRecognition/ADD_ID_RECOGNITION";
const DELETE_ID_RECOGNITION = "idRecognition/DELETE_ID_RECOGNITION";
const EDIT_ID_RECOGNITION = "idRecognition/EDIT_ID_RECOGNITION";

// 액션 생성자
export const onGetAll = createAction(ID_RECOGNITION_GET_ALL, idRecognitionApis.onGetAll);
export const onGetOne = createAction(ID_RECOGNITION_GET_ONE, idRecognitionApis.onGetOne);
export const onAdd = createAction(ADD_ID_RECOGNITION, idRecognitionApis.onAdd);
export const onDelete = createAction(DELETE_ID_RECOGNITION, idRecognitionApis.onDelete);
export const onEdit = createAction(EDIT_ID_RECOGNITION, idRecognitionApis.onEdit);


// 초기 상태
const initialState = {
  idRecognitionList: [],
  idRecognitionDetail: null,
  idRecognitionAdd: null,
  idRecognitionEdit: null,
  successDelete: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: ID_RECOGNITION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, idRecognitionList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, idRecognitionList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ID_RECOGNITION_GET_ONE,
      onPending: (state, action) => {
        return { ...state, idRecognitionDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, idRecognitionDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_ID_RECOGNITION,
      onPending: (state, action) => {
        return { ...state, idRecognitionAdd: null, failData: null };
      },
      onSuccess: (state, action) => {

        return { ...state, idRecognitionAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_ID_RECOGNITION,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_ID_RECOGNITION,
      onPending: (state, action) => {
        return { ...state, idRecognitionEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, idRecognitionEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
