// UseKeyLogListView.js

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTimePicker from "../../components/DateTimePicker/DataTimePicker";
import { Loading, Pagination } from "../../components/Paths";
import UseKeyLogListData from "../List/useKeyLogData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/useKey/useKeyLogList.css";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class UseKeyLogListView extends UseKeyLogListData {
  constructor(props) {
    super(props);

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      keyLogStatus: "",
      search: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempSearch: "",
      tempKeyLogStatus: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // Date 설정
      const startDate = new Date();
      const yesterday = startDate.getDate() - 1;
      startDate.setDate(yesterday);

      const endDate = new Date();

      this.setState(
        {
          startDate: startDate, // startDate를 어제로 설정
          endDate: endDate,
          tempStartDate: startDate,
          tempEndDate: endDate,
        },
        () => {
          this.requestGetUseKeyLogList(this.state.pageNo, this.state.keyLogStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        }
      );
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = "";

    if (stateName == "tempStartDate" || stateName == "tempEndDate") {
      value = e;
    } else {
      value = e.target.value;
    }

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        keyLogStatus: this.state.tempKeyLogStatus,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetUseKeyLogList(this.state.pageNo, this.state.keyLogStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          keyLogStatus: this.state.tempKeyLogStatus,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetUseKeyLogList(this.state.pageNo, this.state.keyLogStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempKeyLogStatus: this.state.keyLogStatus, tempStartDate: this.state.startDate, tempEndDate: this.state.endDate }, () => {
      this.requestGetUseKeyLogList(pageNo, this.state.keyLogStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkuseKeyLogDetail(id);
    }
  }

  //파람값 url로 전달
  linkuseKeyLogDetail = (useKeyLogId) => {
    this.props.history.push(`/useKeyLogDetailPage?useKeyLogId=${useKeyLogId}`);
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if(!date || date == "") return;
    return date.toISOString();
  };

  render() {
    let count;
    let useKeyLogList = [];
    if (this.props.useKeyLogList && this.props.useKeyLogList?.success == true) {
      useKeyLogList = this.props.useKeyLogList.results.rows;
      count = this.props.useKeyLogList.results.count;
    }

    return (
      <div className="useKeyLogList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="useKey_list_container">
          <div className="useKey_list_container_inner">
            <div className="title" data-detect="admin_use_key_log_list_title">
              USE KEY LOG LIST
            </div>
            <div className="period_container">
              <div className="period_start">
                <DataTimePicker value={this.state.tempStartDate} onDateTimeChange={(e) => this.onChangeValue(e, "tempStartDate")} />
              </div>
              <div className="period_line"></div>
              <div className="period_end">
                <DataTimePicker value={this.state.tempEndDate} onDateTimeChange={(e) => this.onChangeValue(e, "tempEndDate")} />
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select
                      id="complete_action_select"
                      className="search_select"
                      name="complete_action_select"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempKeyLogStatus")}
                      value={this.state.tempKeyLogStatus}
                    >
                      <option data-detect="admin_use_key_log_list_placeholder_select_box_use_key_log" value="">
                        Use key log
                      </option>
                      <option data-detect="admin_use_key_log_list_select_hotel_id" value="hotelId">
                        Hotel ID
                      </option>
                      <option data-detect="admin_use_key_log_list_select_email" value="email">
                        Email
                      </option>
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_use_key_log_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="useKey_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_use_key_log_list_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_use_key_log_list_label_user_email">
                      User Email
                    </div>
                    <div className="cell" data-detect="admin_use_key_log_list_label_device">
                      Device
                    </div>
                    <div className="cell" data-detect="admin_use_key_log_list_label_app_version">
                      Application version
                    </div>
                    <div className="cell" data-detect="admin_use_key_log_list_label_hotel_id">
                      Hotel ID
                    </div>
                    <div className="cell" data-detect="admin_use_key_log_list_label_key_id">
                      Key ID
                    </div>
                    <div className="cell" data-detect="admin_use_key_log_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {useKeyLogList.length > 0 ? (
                    useKeyLogList.map((useKeyLog, index) => (
                      <div className="row tbodyRow" key={useKeyLog.id} onMouseUp={() => this.handleMouseUp(useKeyLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                        <div className="cell">{useKeyLog.id || "-"}</div>
                        <div className="cell">{useKeyLog.user.email || "-"}</div>
                        <div className="cell">{useKeyLog.device || "-"}</div>
                        <div className="cell">{useKeyLog.appVersion || "-"}</div>
                        <div className="cell">{useKeyLog.hotelKey.hotelReservation.hotel.id || "-"}</div>
                        <div className="cell">{useKeyLog.hotelKey.id || "-"}</div>
                        <div className="cell">{useKeyLog ? formatDateTimeSlash(useKeyLog.created_at) : "-"}</div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_use_key_log_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UseKeyLogListData.mapStateToProps;
let mapDispatchToProps = UseKeyLogListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UseKeyLogListView)
);
