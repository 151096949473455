import React, { Component } from 'react';
import 'react-datetime/css/react-datetime.css';

//datePicker
import DatePicker from 'react-datepicker';
//icon
import CalendarIcon from '../../img/data_icon.svg';

// css
import "../DateTimePicker/dateTimePicker.css";

// utils
import "../../common/Utils";

class DataTimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDateTime: this.props.value || new Date(), // 기본 값 설정
      calendarOpen: false,
    };
    this.picker = null;
  }

  componentDidMount() {
    this.datePickerRef = React.createRef();
  }


  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selectedDateTime: this.props.value || new Date() });
    }
  }

  handleDateTimeChange = (newDateTime) => {
    this.setState({
      selectedDateTime: newDateTime,
      calendarOpen: false,
    });
    this.props.onDateTimeChange(newDateTime);
  };

  toggleCalendar = () => {
    this.setState((prevState) => ({
      calendarOpen: !prevState.calendarOpen,
    }));
  };

  //날자 포맷팅 함수
  formatDate = (isoString) => {
    if(isoString == "" || isoString == null) return;

    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  render() {
    const dateFormat = 'yyyy/MM/dd HH:mm';
    return (
      <div className="datetime_picker">
        <DatePicker
          ref={this.datePickerRef}
          dateFormat={dateFormat}
          shouldCloseOnSelect
          showTimeSelect
          selected={this.state.selectedDateTime}
          onChange={this.handleDateTimeChange}
          timeFormat="p"
          timeIntervals={1}
        />
        <img
          src={CalendarIcon}
          alt="calendar icon"
          onClick={() => {
            this.datePickerRef.current.toggleCalendar();
          }}
        />
      </div>
    );
  }
}

export default DataTimePicker;
