import React from "react";
import { connect } from "react-redux";
import { Loading } from "../../../components/Paths";
import AgreementHistoryDetailDetailData from "../Detail/AgreementHistoryDetailData";

//css
import "../../../css/common.css";
import "../../../css/termsAndCondition/AgreementHistory/agreementHistoryDetail.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";

class AgreementHistoryDetailDetailView extends AgreementHistoryDetailDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      agreementHistoryId: "",
    };
  }

  getBeautifyJson = (json) => {
    return JSON.stringify(json, null, 6).replace(/\n( *)/g, function(
      match,
      p1
    ) {
      return "<br>" + "&nbsp;".repeat(p1.length);
    });
  };
  // 첫 실행시 URL에서 agreementHistoryId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const agreementHistoryId = params.get("agreementHistoryId");
    this.setState({
      agreementHistoryId: agreementHistoryId,
    });
    this.requestGetAgreementHistoryDetail(agreementHistoryId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };



  render() {
    let agreementHistoryDetail = null;

    if (
      this.props.agreementHistoryDetail != null &&
      this.props.agreementHistoryDetail?.success == true
    ) {
      agreementHistoryDetail = this.props.agreementHistoryDetail.results;
    }

    return (
      <div className="agreementHistoryDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="agreementHistory_detail_container">
          <div className="agreementHistory_detail_innder">
            <div className="title" data-detect="admin_agreement_detail_title">
              AGREEMENT HISTORY INFORMATION
            </div>

            <div className="agreementHistory_container">
              <div className="agreementHistory_title_container"></div>
              <div className="agreementHistory_info_container">
                <div className="agreementHistory_info_table">
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_agreement">
                        Agreement
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>{agreementHistoryDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_agreed">
                        Agreed
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span> {agreementHistoryDetail?.agreed??"-"}</span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_agreement_created_at">
                        Agreement Created At
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail ?
                          formatDateTimeDot(
                            agreementHistoryDetail.created_at
                          ) : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_user_id">
                        User ID
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span> {agreementHistoryDetail?.user.id??"-"}</span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_email">
                        Email
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span> {agreementHistoryDetail?.user.email??"-"}</span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_name">
                        Name
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail ? (agreementHistoryDetail.user.gender +
                          agreementHistoryDetail.user.lastName +
                          agreementHistoryDetail.user.firstName) : "-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_email_confimed_at">
                        Email Confimed At
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.user.mailConfimedAt??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_user_language_code">
                        User Language Code
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.user.languageCode??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_user_created_at">
                        User Created At
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.user.created_at??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_terms_id">
                        Terms ID
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>{agreementHistoryDetail?.terms.id??"-"}</span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_terms_language_code">
                        Terms Language Code
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.terms.languageCode??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_file_name">
                        
                        File Name
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.terms.fileName??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_required">
                        Required
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.terms.required??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_title">
                        Title
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>{agreementHistoryDetail?.terms.title??"-"}</span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_description">
                        Description
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.terms.description??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_link_text">
                        Link Text
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.terms.linkText??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_agreement_detail_label_terms_group_id">
                        
                        Terms Group ID
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail?.terms.termsGroupId??"-"}
                      </span>
                    </div>
                  </div>
                  <div className="agreementHistory_info_row">
                    <div className="agreementHistory_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_created_at">
                        Terms Created At
                      </span>
                    </div>
                    <div className="agreementHistory_info_cell content">
                      <span>
                        {agreementHistoryDetail ?
                          formatDateTimeDot(
                            agreementHistoryDetail.terms.created_at
                          ) : "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_agreement_detail_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AgreementHistoryDetailDetailData.mapStateToProps;
let mapDispatchToProps = AgreementHistoryDetailDetailData.mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementHistoryDetailDetailView);
