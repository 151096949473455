import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAll(pageNo, startDate, endDate, hotelId, userName, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get(
    "/admin/api/payment/searchList?content=" + search + "&hotelId=" + hotelId + "&userName=" + userName + "&offset=" + offset + "&limit=" + 10 + "&fromDate=" + startDate + "&endDate=" + endDate
  );
}

//------------------------------- get One ------------------------------------------------------//
export function onGetOne(paymentId) {
  return axiosInstance.get("/admin/api/payment/" + paymentId);
}

//------------------------------- get Reservation ------------------------------------------------------//
export function onGetReservation(reservationId, hotelId) {
  return axiosInstance.get("/admin/api/reservation/byReservationId?reservationId=" + reservationId + "&hotelId=" + hotelId);
}

//------------------------------- get logList ------------------------------------------------------//
export function onGetLogList(paymentId, hotelId) {
  return axiosInstance.get("/admin/api/van/byPaymentId?paymentId=" + paymentId + "&hotelId=" + hotelId);
}

//------------------------------- get logDetail ------------------------------------------------------//
export function onGetLogDetail(logId) {
  return axiosInstance.get("/admin/api/van/" + logId);
}

//------------------------------- add Payment ------------------------------------------------------//
export function onAdd(formData) {
  return axiosInstance.post("/admin/api/payment/", formData);
}
//------------------------------- delete Payment ------------------------------------------------------//
export function onDelete(paymentId) {
  return axiosInstance.delete("/admin/api/payment/" + paymentId);
}

//------------------------------- edit Payment ------------------------------------------------------//
export async function onEdit(paymentId, region, country, city) {
  const data = {
    region: region,
    country: country,
    city: city,
  };

  return axiosInstance.put("/admin/api/payment/area/" + paymentId, data);
}

//------------------------------- copy Payment ------------------------------------------------------//
export function onCopy(formData) {
  return axiosInstance.post("/admin/api/v2/payment/copyPayment", formData);
}

//------------------------------- get Region ------------------------------------------------------//
export function onGetRegion() {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/region/?content=&offset=0&limit=" + maxNumber);
}

//------------------------------- get Country ------------------------------------------------------//
export function onGetCountry(region) {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/country/?content=&offset=0&limit=" + maxNumber + "&paymentRegionId=" + region);
}

//------------------------------- get City ------------------------------------------------------//
export function onGetCity(regionId, countryId) {
  var maxNumber = Number.MAX_SAFE_INTEGER;

  return axiosInstance.get("/admin/api/city/?content=&offset=0&limit=" + maxNumber + "&paymentCountryId=" + countryId + "&paymentRegionId=" + regionId);
}
