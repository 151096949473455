// LoginData.js
import CommonComponent from "../components/CommonComponent";

// modules
import * as authActions from "../../redux/modules/Auth";


class LoginData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "SIGN_IN") {
      this.onSignInResults(props);
    }
  }

  // Sign In 호출
  requestLogin = (email, password) => {
    this.setState({
      requestType: "SIGN_IN",
      isShowLoading: true,
    });
    this.props.onSignIn(email, password);
  };
  f;
  
  // Sign In 결과값
  onSignInResults = (props) => {
    if (props.data != null) {
      this.setState({ requestType: "", isShowLoading: false  });
      if (props.data?.success == true) {
        // 로그인 성공한 사용자의 token값
        localStorage.setItem("accessToken", props.data.accessToken);
        localStorage.setItem("refreshToken", props.data.refreshToken);

        // 로그인 성공 후 페이지로 이동
        this.props.history.push("/hotelListPage");
      } else if (props.data.code != "INVALID_TOKEN") {
        // 로그인 실패시 팝업
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_failed_login_message"], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  onFailed = (props) => {
    if (props.error != null) {
      let code = props.error.code;
      let reason = props.error.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      sessionData: state.auth.sessionData,
      error: state.auth.error,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onSignIn: (email, password) => dispatch(authActions.onSignIn(email, password)),
    };
  };
}

export default LoginData;
