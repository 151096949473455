// CityAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as locationActions from "../../../../redux/modules/Location";



class CityAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_CITY") {
      this.onAddCityResults(props);
    }
    if (this.state.requestType == "GET_COUNTRY") {
      this.onGetCountryResults(props);
    }
  }

  // redux에 add city 요청
  requestAddCity = (regionId, countryId, city) => {
    this.setState({
      requestType: "ADD_CITY",
    });
    this.props.onAdd(regionId, countryId, city);
  };

  // region값이 변경될때마다 redux에 get country  요청
  requestGetCountry = (regionId) => {
    this.setState({
      requestType: "GET_COUNTRY",
      isShowAllLoading: true,
    });
    this.props.onGetCountry(regionId);
  };

  // get add city results
  onAddCityResults = (props) => {
    if (props.cityAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.cityAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_city"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/cityListPage");
  };

  // get Country results
  onGetCountryResults = (props) => {
    if (props.cityData?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.cityData?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_country_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      regionList: state.location.regionList,
      cityAdd: state.location.cityAdd,
      //region에 따라 선택된 selectbox 값
      cityData: state.location.cityData,
      failData: state.location.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (regionId, countryId, city) => dispatch(locationActions.onAddCity(regionId, countryId, city)),
      onGetCountry: (regionId) => dispatch(locationActions.onGetCountry(regionId)),
    };
  };
}

export default CityAddData;
