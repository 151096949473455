import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as userApis from "../lib/user_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const USER_GET = "user/USER_GET";

// 액션 생성자
// export const setUserToken = authApis.setUserToken
export const onGet = createAction(USER_GET, userApis.onGet);

// 초기 상태
const initialState = {
  userData: null,
  failData: null,
};

export default handleActions({
  ...pender({
    type: USER_GET,
    onSuccess: (state, action) => {
      return {...state, userData: action.payload.data};
    },
    onFailure: (state, action) => {
      return { ...state, failData: action.payload.response?.data };
    },
    [PURGE]: (state, action) => {
      return initialState;
  },
  }),
}, initialState);
