const JapaneseLanguage = {
  version: "2.0.18",
  language: {
    ja: {
      login_button_admin: "管理者ログインページ",
      login_button_hotel: "ホテルのログインページ",
      login_guide_login: "ログインページをクリックしてください",
      admin_login_admin_label_admin: "管理者",
      admin_login_admin_placeholder_email: "Eメール",
      admin_login_admin_placeholder_password: "パスワード",
      admin_login_admin_button_login: "ログイン",
      admin_login_admin_guide_login: "管理者としてログインしてください。",
      hotel_login_hotel_label_hotel: "ホテル",
      hotel_login_hotel_placeholder_email: "Eメール",
      hotel_login_hotel_placeholder_password: "パスワード",
      hotel_login_hotel_button_login: "ログイン",
      hotel_login_hotel_guide_login: "ホテルのユーザーとしてログインしてください。",
      common_taxi_call: "タクシー",
      common_parcel_service: "宅配サービス",
      common_luggage_service: "手荷物サービス",
      common_taxi_call_ex: "エクスプレス·タクシー",
      common_parcel_service_ex: "エクスプレス宅配便サービス",
      common_luggage_service_ex: "エクスプレス手荷物サービス",
      common_green_campaign: "グリーン·キャンペーン",
      common_make_up_room: "客室清掃",
      common_turn_down_service: "客室整頓",
      common_emergency: "救急サービス",
      common_dnd: "DND",
      common_in_room_dining: "インルーム·ダイニング·サービス",
      common_ironing_service: "アイロンサービス",
      common_laundry_service: "ランドリーサービス",
      common_amenity_service: "アメニティ·サービス",
      common_in_room_dining_ex: "エクスプレスインルームダイニングサービス",
      common_ironing_service_ex: "エクスプレス アイロンサービス",
      common_laundry_service_ex: "エクスプレス 洗濯サービス",
      common_waiting_request: "出車要請サービス",
      common_valet_return_additional_charge: "バレット再入車追加料金",
      common_requested: "要請",
      common_confirmed: "確認",
      common_change: "変更",
      common_cancled: "キャンセル",
      common_standby: "待機中",
      common_completed: "完了",
      common_stopped: "中止",
      common_picked_up: "収去",
      common_started: "始まり",
      common_delivered: "配達完了",
      common_not_available: "サービス不可",
      common_delay: "遅延",
      common_room_posting: "客室請求",
      common_room_posting_refund: "客室請求の払い戻し",
      common_self_settlement: "直接決済",
      common_self_settlement_refund: "直接決済·払い戻し",
      common_staff_room_posting: "客室請求(職員処理)",
      common_staff_settlement: "直接決済(スタッフ処理)",
      common_put_off: "煙",
      common_vehicle_change: "車両番号変更",
      common_valet: "バレット受付",
      common_location: "駐車位置",
      common_waiting: "出車待ちの要請",
      common_waiting_confirm: "出車待ちの確認",
      common_date_change: "出車待機時間の変更",
      common_vehicle_standby: "車の準備完了",
      common_preparing: "準備中",
      common_return: "回次",
      common_handover: "伝達完了",
      common_deleted: "削除",
      commom_delay_15: "15分遅れ",
      commom_delay_30: "30分遅れ",
      commom_delay_45: "45分遅れ",
      commom_delay_60: "60分遅れ",
      commom_delay_asaa: "早いうちに",
      common_normal: "一般",
      common_express: "エクスプレス",
      common_boundary_line: "制限線",
      common_accepted: "受諾艦",
      common_rejected: "拒絶",
      common_key_expired: "鍵切れ",
      common_disposable_key: "使い捨て",
      common_today_only_key: "当日使用",
      common_entire_stay_key: "全宿泊期間使用",
      common_validation_name: "名前を入力してください。",
      common_validation_content: "内容を入力してください。",
      common_validation_select_img: "画像を選択してください。",
      common_summernote_lang: "ko-KR",
      common_clean: "清掃完了",
      common_inspected: "検査済み",
      common_dirty: "汚い",
      common_out_of_order: "故障中",
      common_out_of_service: "故障中",
      common_pick_up: "選び出す",
      common_cleaning: "クリーニング",
      common_done: "完了しました",
      common_not_reserved: "予約されていません",
      common_departed: "出発しました",
      common_arrived: "到着した",
      common_week_mon: "月",
      common_week_tue: "火",
      common_week_wed: "水",
      common_week_thu: "木",
      common_week_fri: "金",
      common_week_sat: "土",
      common_week_sun: "日",
      common_percent: "パーセント",
      common_nights: "宿泊日",
      common_flat: "固定料金",
      common_all_nights: "全宿泊日",
      common_deposit_only: "敷金",
      common_first_night: "宿泊初日",
      common_night: "日",
      common_inactive: "非活性化",
      common_active: "活性化",
      common_success: "成功",
      common_failure: "失敗",
      admin_header_link_password: "パスワード",
      admin_header_link_logout: "ログアウト",
      admin_header_link_hotel: "ホテル",
      admin_header_link_setting: "設定",
      admin_header_accordion_location: "位置",
      admin_header_link_region_list: "地域一覧",
      admin_header_link_country_list: "国の一覧",
      admin_header_link_city_list: "都市の一覧",
      admin_header_link_application_user: "アプリユーザー",
      admin_header_link_payment: "決済",
      admin_header_accordion_transfer: "通信",
      admin_header_link_transfer_log_list: "通信ログリスト",
      admin_header_link_user_transfer_log_list: "ユーザー通信ログリスト",
      admin_header_link_transaction_log_list: "ステータス処理プロセスリスト",
      admin_header_link_user_transfer_log_history: "ユーザー通信ログヒストリー",
      admin_header_link_staff_transfer_log_history: "Staff通信ログヒストリー",
      admin_header_link_user_notification_log_list: "ユーザー通知ログ一覧",
      admin_header_link_staff_notification_log_list: "スタッフ通知ログ一覧",
      admin_header_accordion_version: "バージョン",
      admin_header_link_version: "バージョン",
      admin_header_link_application_down_version_list: "アプリダウンロード画面の設定",
      admin_header_link_guest_application_down_list: "ゲストアプリの最小バージョン設定",
      admin_header_link_staff_application_down_list: "スタッフアプリの最小バージョン設定",
      admin_header_link_paking_staff_application_down_list: "駐車スタッフアプリの最小バージョン設定",
      admin_header_link_application_translation: "アプリ翻訳",
      admin_header_link_booking_email: "Eメール画像",
      admin_header_accordion_email_form: "Eメールフォーム",
      admin_header_link_change_password: "パスワード変更",
      admin_header_link_forgot_id: "ID検索",
      admin_header_link_forgot_password: "パスワード検索",
      admin_header_link_create_confirm_email: "Eメール確認",
      admin_header_link_create_confirm_email_fail: "Eメールの確認に失敗",
      admin_header_link_create_confirm_email_success: "Eメール確認に成功",
      admin_header_link_update_profile: "プロフィールアップデート",
      admin_header_link_withdraw: "会員脱退",
      admin_header_link_app_endpoint: "アプリ·エンドポイント",
      admin_header_accordion_terms_and_conditions: "利用規約",
      admin_header_link_terms: "条件",
      admin_header_link_terms_group: "条件グループ",
      admin_header_link_agreement_history: "同意内訳",
      admin_header_current_version: "現在のバージョン",
      admin_header_translation_version: "翻訳バージョン",
      admin_header_link_admin_user: "管理者",
      admin_header_accordion_auth_data: "ビーコン/GPS接続記録",
      admin_header_link_auth_data_log_list: "ビーコン/GPS接続リスト",
      admin_header_link_use_key_log: "鍵使用ログ",
      admin_header_link_amenity: "便宜施設",
      admin_header_link_facility_image: "ホテル施設のイメージ",
      admin_header_link_recommend_app: "おすすめアプリ",
      admin_header_link_id_recognition_information: "ID認識情報",
      admin_header_link_statistics: "Statistics",
      admin_password_title: "パスワード変更",
      admin_password_guide_password: "少なくとも 8 文字以上の新しいパスワードを入力してください。",
      admin_password_validation_current_password: "* 現在のパスワードを入力してください。",
      admin_password_validation_character_number_combining: "* 英文と数字を組み合わせて8文字以上入力してください。",
      admin_password_validation_new_password: "* 新しいパスワードを入力してください。",
      admin_password_validation_new_password_again: "* 新しいパスワードを再入力してください。",
      admin_password_validation_not_match: "* パスワードが一致しません。",
      admin_password_placeholder_password: "パスワード",
      admin_password_placeholder_new_password: "新しいパスワード",
      admin_password_placeholder_new_confirm_password: "新しいパスワード確認",
      admin_password_button_save: "貯蔵",
      admin_hotel_list_title: "ホテルの一覧",
      admin_hotel_list_placeholder_search: "検索",
      admin_hotel_list_label_hotel_no: "ホテルの番号",
      admin_hotel_list_label_name: "名前",
      admin_hotel_list_label_address: "住所",
      admin_hotel_list_label_is_show: "展示の可否",
      admin_hotel_list_label_date: "日付",
      admin_hotel_list_guide_empty: "リストが空です",
      admin_hotel_list_button_delete: "削除",
      admin_hotel_list_button_add: "追加",
      admin_hotel_list_button_copy: "複写",
      admin_hotel_add_title: "ホテル追加",
      admin_hotel_add_guide_click_add: "情報を入力し、「<追加>」ボタンを押してホテルを追加してください。",
      admin_hotel_add_validation_name: "ホテル名を入力してください。",
      admin_hotel_add_validation_address: "ホテルの住所を入力してください。",
      admin_hotel_add_validation_korea_english: "韓国語、英語、または .__ を使用して入力してください。",
      admin_hotel_add_validation_email: "ユーザーのメールアドレスを入力してください。",
      admin_hotel_add_validation_email_format: "メールの形式に合わせて入力してください。",
      admin_hotel_add_validation_password: "ユーザーのパスワードを入力してください。",
      admin_hotel_add_validation_character_number_combining: "英文と数字を組み合わせて8文字以上入力してください。",
      admin_hotel_add_validation_not_match: "パスワードが一致しません。",
      admin_hotel_add_validation_image_file: "ロゴ画像をアップロードしてください。",
      admin_hotel_add_label_name: "名前",
      admin_hotel_add_placeholder_hotel_name: "ホテル名",
      admin_hotel_add_label_address: "住所",
      admin_hotel_add_placeholder_hotel_address: "ホテルの住所",
      admin_hotel_add_label_email: "Eメール",
      admin_hotel_add_placeholder_staff_email: "職員の電子メール",
      admin_hotel_add_label_password: "パスワード",
      admin_hotel_add_placeholder_staff_password: "職員パスワード",
      admin_hotel_add_label_confirm_password: "パスワード確認",
      admin_hotel_add_placeholder_staff_confirm_password: "職員パスワード確認",
      admin_hotel_add_label_logo: "ロゴ",
      admin_hotel_add_button_upload: "アップロード",
      admin_hotel_add_placeholder_upload: "ex) MyCONECT。png",
      admin_hotel_add_button_go_to_list: "リストへのアクセス",
      admin_hotel_add_button_add: "追加",
      admin_hotel_detail_title: "ホテル情報",
      admin_hotel_detail_label_hotel_no: "ホテルの番号",
      admin_hotel_detail_label_name: "ホテル名",
      admin_hotel_detail_label_address: "住所",
      admin_hotel_detail_label_region: "地域",
      admin_hotel_detail_label_country: "国家",
      admin_hotel_detail_label_city: "都市",
      admin_hotel_detail_label_country_name: "国の名前",
      admin_hotel_detail_label_payment_server_url: "決済サーバURL",
      admin_hotel_detail_label_is_direct_payable: "Direct payが可能かどうか",
      admin_hotel_detail_label_is_card_tokenizable: "カード情報をトークン化できるかどうか",
      admin_hotel_detail_label_is_show: "展示の可否",
      admin_hotel_detail_button_yes: "例",
      admin_hotel_detail_button_no: "いいえ",
      admin_hotel_detail_label_logo: "ロゴ",
      admin_hotel_detail_label_hotel_photo: "ホテルの写真",
      admin_hotel_detail_label_hotel_map: "地図",
      admin_hotel_detail_button_go_to_list: "リストへのアクセス",
      admin_hotel_detail_button_edit: "修整",
      admin_hotel_edit_title: "ホテル情報",
      admin_hotel_edit_label_hotel_no: "ホテルの番号",
      admin_hotel_edit_label_name: "ホテル名",
      admin_hotel_edit_label_address: "住所",
      admin_hotel_edit_label_region: "地域",
      admin_hotel_edit_label_country: "国家",
      admin_hotel_edit_label_choose: "選択",
      admin_hotel_edit_label_city: "都市",
      admin_hotel_edit_label_country_name: "国の名前",
      admin_hotel_edit_label_logo: "ロゴ",
      admin_hotel_edit_label_payment_server_url: "決済サーバURL",
      admin_hotel_edit_label_is_direct_payable: "Direct payが可能かどうか",
      admin_hotel_edit_label_is_card_tokenizable: "カード情報をトークン化できるかどうか",
      admin_hotel_edit_label_is_show: "展示の可否",
      admin_hotel_edit_button_yes: "例",
      admin_hotel_edit_button_no: "いいえ",
      admin_hotel_edit_button_upload: "アップロード",
      admin_hotel_edit_placeholder_upload: "ex) MyCONECT。png",
      admin_hotel_edit_label_hotel_photo: "ホテルの写真",
      admin_hotel_edit_label_hotel_map: "地図",
      admin_hotel_edit_button_search: "検索",
      admin_hotel_edit_button_cancel: "キャンセル",
      admin_hotel_edit_button_ok: "確認",
      admin_hotel_edit_validation_region: "地域を選択してください。",
      admin_hotel_edit_validation_country: "国を選択してください。",
      admin_hotel_edit_validation_city: "都市を選択してください。",
      admin_hotel_edit_validation_country_name: "* 国名を選択してください。",
      admin_hotel_edit_validation_is_show: "展示するかどうかを選択してください。",
      admin_hotel_copy_title: "ホテルのコピー",
      admin_hotel_copy_guide_click_copy: "HOTELをコピーするには情報を入力してください。 そして、「コピー」ボタンをクリックしてください。",
      admin_hotel_copy_button_copy: "複写",
      admin_setting_detail_title: "MyCONECT設定",
      admin_setting_detail_label_date: "アップデートの日付",
      admin_setting_detail_label_setting: "設定",
      admin_setting_detail_label_coordinate_distance: "座標距離",
      admin_setting_detail_button_edit: "修整",
      admin_setting_edit_title: "MyCONECT設定",
      admin_setting_edit_label_date: "アップデートの日付",
      admin_setting_edit_label_setting: "設定",
      admin_setting_edit_label_coordinate_distance: "座標距離",
      admin_setting_edit_button_ok: "確認",
      admin_setting_edit_button_cancel: "キャンセル",
      admin_setting_edit_validation_coordinate_distance: "座標距離を入力してください。",
      admin_region_list_title: "地域一覧",
      admin_region_list_placeholder_search: "検索",
      admin_region_list_label_region: "地域",
      admin_region_list_label_date: "日付",
      admin_region_list_guide_empty: "リストが空です。",
      admin_region_list_button_delete: "削除",
      admin_region_list_button_add: "追加",
      admin_region_add_title: "地域追加",
      admin_region_add_guide_click_add: "情報入力後、「<追加>」ボタンを押して地域を追加してください。",
      admin_region_add_validation_name: "* エリア名を入力してください。",
      admin_region_add_label_region: "地域",
      admin_region_add_placeholder_region_name: "地域名",
      admin_region_add_button_go_to_list: "リストへのアクセス",
      admin_region_add_button_add: "追加",
      admin_region_detail_title: "地域",
      admin_region_detail_label_region: "地域",
      admin_region_detail_label_created_at: "生成時間",
      admin_region_detail_label_updated_at: "アップデート時間",
      admin_region_detail_button_go_to_list: "リストへのアクセス",
      admin_region_detail_button_edit: "修整",
      admin_region_edit_title: "地域",
      admin_region_edit_validation_name: "* エリア名を入力してください。",
      admin_region_edit_label_region: "地域",
      admin_region_edit_placeholder_region_name: "地域名",
      admin_region_edit_label_created_at: "生成時間",
      admin_region_edit_label_updated_at: "アップデート時間",
      admin_region_edit_button_cancel: "キャンセル",
      admin_region_edit_button_ok: "確認",
      admin_country_list_title: "国の一覧",
      admin_country_list_select_region_all: "地域(すべて)",
      admin_country_list_placeholder_search: "検索",
      admin_country_list_label_region: "地域",
      admin_country_list_label_country: "国家",
      admin_country_list_label_date: "日付",
      admin_country_list_guide_empty: "リストが空です。",
      admin_country_list_button_delete: "削除",
      admin_country_list_button_add: "追加",
      admin_country_add_title: "国家追加",
      admin_country_add_guide_click_add: "情報を入力した後、「<追加>」ボタンを押して国を追加してください。",
      admin_country_add_validation_region: "* 地域を選択してください。",
      admin_country_add_validation_country: "* 国名を入力してください。",
      admin_country_add_label_region: "地域",
      admin_country_add_label_choose: "選択",
      admin_country_add_label_country: "国家",
      admin_country_add_placeholder_country_name: "国名",
      admin_country_add_button_go_to_list: "リストへのアクセス",
      admin_country_add_button_add: "追加",
      admin_country_detail_title: "国家",
      admin_country_detail_label_region: "地域",
      admin_country_detail_label_country: "国家",
      admin_country_detail_placeholder_country_name: "国名",
      admin_country_detail_label_created_at: "生成時間",
      admin_country_detail_label_updated_at: "アップデート時間",
      admin_country_detail_button_go_to_list: "リストへのアクセス",
      admin_country_detail_button_edit: "修整",
      admin_country_edit_title: "国家",
      admin_country_edit_validation_country: "* 国名を入力してください。",
      admin_country_edit_label_region: "地域",
      admin_country_edit_label_country: "国家",
      admin_country_edit_placeholder_country_name: "国名",
      admin_country_edit_label_created_at: "生成時間",
      admin_country_edit_label_updated_at: "アップデート時間",
      admin_country_edit_button_cancel: "キャンセル",
      admin_country_edit_button_ok: "確認",
      admin_city_list_title: "都市の一覧",
      admin_city_list_select_region_all: "地域(すべて)",
      admin_city_list_select_country_all: "国(すべて)",
      admin_city_list_placeholder_search: "検索",
      admin_city_list_label_region: "地域",
      admin_city_list_label_country: "国家",
      admin_city_list_label_city: "都市",
      admin_city_list_label_date: "日付",
      admin_city_list_guide_empty: "リストが空です。",
      admin_city_list_button_delete: "削除",
      admin_city_list_button_add: "追加",
      admin_city_add_title: "都市追加",
      admin_city_add_guide_click_add: "情報を入力し、「<追加>」ボタンを押して都市を追加してください。",
      admin_city_add_validation_region: "* 地域を選択してください。",
      admin_city_add_validation_country: "* 国を選択してください。",
      admin_city_add_validation_city: "* 都市名を入力してください。",
      admin_city_add_label_region: "地域",
      admin_city_add_label_country: "国家",
      admin_city_add_label_choose: "選択",
      admin_city_add_label_city: "都市",
      admin_city_add_placeholder_city_name: "都市名",
      admin_city_add_button_go_to_list: "リストへのアクセス",
      admin_city_add_button_add: "追加",
      admin_city_detail_label_region: "地域",
      admin_city_detail_title: "都市",
      admin_city_detail_label_country: "国家",
      admin_city_detail_label_city: "都市",
      admin_city_detail_placeholder_city_name: "都市名",
      admin_city_detail_label_created_at: "生成時間",
      admin_city_detail_label_updated_at: "アップデート時間",
      admin_city_detail_button_go_to_list: "リストへのアクセス",
      admin_city_detail_button_edit: "修整",
      admin_city_edit_title: "都市",
      admin_city_edit_validation_city: "* 都市名を入力してください。",
      admin_city_edit_label_region: "地域",
      admin_city_edit_label_country: "国家",
      admin_city_edit_label_city: "都市",
      admin_city_edit_placeholder_city_name: "都市名",
      admin_city_edit_label_created_at: "生成時間",
      admin_city_edit_label_updated_at: "アップデート時間",
      admin_city_edit_button_cancel: "キャンセル",
      admin_city_edit_button_ok: "確認",
      admin_application_user_list_title: "アプリユーザー一覧",
      admin_application_user_list_placeholder_select_box_user: "使用者",
      admin_application_user_list_select_email: "Eメール",
      admin_application_user_list_select_name: "名前",
      admin_application_user_list_placeholder_search: "検索",
      admin_application_user_list_label_email: "Eメール",
      admin_application_user_list_label_backup_email: "補助電子メール",
      admin_application_user_list_label_name: "名前",
      admin_application_user_list_label_alternative_name: "ハングルの名前",
      admin_application_user_list_label_mail_confirmed_at: "Eメール確認時間",
      admin_application_user_list_label_date: "日付",
      admin_application_user_list_button_delete: "削除",
      admin_application_user_list_guide_empty: "リストが空です",
      admin_application_user_detail_title: "ユーザーEメール",
      admin_application_user_detail_label_user_information: "アプリユーザー情報",
      admin_application_user_detail_user_info_email: "Eメール",
      admin_application_user_detail_user_info_gender: "性別",
      admin_application_user_detail_user_info_first_name: "名前",
      admin_application_user_detail_user_info_last_name: "性別",
      admin_application_user_detail_user_info_alternative_first_name: "ハングルの名前",
      admin_application_user_detail_user_info_alternative_last_name: "ハングルの姓",
      admin_application_user_detail_user_info_member_type: "会員タイプ",
      admin_application_user_detail_user_info_backup_email: "補助電子メール",
      admin_application_user_detail_user_info_mail_confirmed_at: "Eメール確認時間",
      admin_application_user_detail_user_info_push_token: "プッシュ·トークン",
      admin_application_user_detail_user_info_os_type: "OSタイプ",
      admin_application_user_detail_user_info_language_code: "言語コード",
      admin_application_user_detail_user_info_updated_at: "修正時間",
      admin_application_user_detail_user_info_date: "日付",
      admin_application_user_detail_button_go_to_list: "リストへのアクセス",
      admin_payment_list_title: "決済リスト",
      admin_payment_list_placeholder_hotel_id: "ホテルID",
      admin_payment_list_placeholder_user_name: "ユーザー名",
      admin_payment_list_placeholder_search: "検索",
      admin_payment_list_label_no: "番号",
      admin_payment_list_label_reservation_id: "予約ID",
      admin_payment_list_label_user_name: "ユーザー名",
      admin_payment_list_label_long_info: "ロング情報",
      admin_payment_list_label_amount: "金額",
      admin_payment_list_label_unit: "単位",
      admin_payment_list_label_pg_status_code: "PGステータスコード",
      admin_payment_list_label_pg_status_message: "PGステータスメッセージ",
      admin_payment_list_label_hotel_id: "ホテルID",
      admin_payment_list_label_date: "日付",
      admin_payment_detail_label_order_no: "注文番号",
      admin_payment_detail_label_user: "使用者",
      admin_payment_detail_label_user_profile_id: "プロフィールID",
      admin_payment_detail_label_user_title: "敬称",
      admin_payment_detail_label_user_first_name: "名前",
      admin_payment_detail_label_user_last_name: "性別",
      admin_payment_detail_label_user_email: "Eメール",
      admin_payment_detail_label_user_date: "日付",
      admin_payment_detail_label_reservation: "予約",
      admin_payment_detail_label_reservation_confirmation_no: "予約番号",
      admin_payment_detail_label_reservation_reservation_id: "予約ID",
      admin_payment_detail_label_reservation_reservation_status: "予約状態",
      admin_payment_detail_label_reservation_check_in_date: "チェックインの日付",
      admin_payment_detail_label_reservation_check_out_date: "チェックアウトの日付",
      admin_payment_detail_label_hotel: "ホテル",
      admin_payment_detail_label_hotel_hotel_id: "ホテルID",
      admin_payment_detail_label_hotel_hotel_name: "ホテル名",
      admin_payment_detail_label_hotel_region: "地域",
      admin_payment_detail_label_hotel_country: "国家",
      admin_payment_detail_label_hotel_city: "都市",
      admin_payment_detail_label_payment: "決済",
      admin_payment_detail_button_go_to_list: "リストへのアクセス",
      admin_payment_detail_button_go_to_log: "ログリストへ行く",
      admin_payment_detail_label_payment_order_no: "注文番号",
      admin_payment_detail_label_payment_reservation_id: "予約ID",
      admin_payment_detail_label_payment_long_info: "ロング情報",
      admin_payment_detail_label_payment_folio_view_no: "ポリオ照会番号",
      admin_payment_detail_label_payment_amount: "金額",
      admin_payment_detail_label_payment_unit: "単位",
      admin_payment_detail_label_payment_pg_order_no: "PG 注文番号",
      admin_payment_detail_label_payment_pg_status: "PG状態",
      admin_payment_detail_label_payment_pg_status_code: "PGステータスコード",
      admin_payment_detail_label_payment_pg_status_message: "PGステータスメッセージ",
      admin_payment_detail_label_payment_pg_amount: "PG 金額",
      admin_payment_detail_label_payment_pg_unit: "PG単位",
      admin_payment_detail_label_payment_pg_billing_no: "PG請求番号",
      admin_payment_detail_label_payment_confirmed_at: "確認時間",
      admin_payment_detail_label_payment_created_at: "生成時間",
      admin_payment_detail_label_payment_updated_at: "アップデート時間",
      admin_payment_log_list_title: "決済ログリスト",
      admin_payment_log_list_label_no: "番号",
      admin_payment_log_list_label_type: "タイプ",
      admin_payment_log_list_label_method: "方法",
      admin_payment_log_list_label_path: "経路",
      admin_payment_log_list_label_status: "状態",
      admin_payment_log_list_label_success: "成功",
      admin_payment_log_list_label_created_at: "生成時間",
      admin_payment_log_list_guide_empty: "リストが空です",
      admin_payment_log_list_button_go_to_detail: "詳細に行く",
      admin_payment_log_detail_title: "決済ログの詳細情報",
      admin_payment_log_detail_label_id: "ID",
      admin_payment_log_detail_label_hotel_id: "ホテルID",
      admin_payment_log_detail_label_payment_id: "決済ID",
      admin_payment_log_detail_label_transfer_code: "通信コード",
      admin_payment_log_detail_label_type: "タイプ",
      admin_payment_log_detail_label_method: "方法",
      admin_payment_log_detail_label_path: "経路",
      admin_payment_log_detail_label_header: "前書き",
      admin_payment_log_detail_label_param: "変数",
      admin_payment_log_detail_label_body: "本文",
      admin_payment_log_detail_label_status: "状態",
      admin_payment_log_detail_label_session: "セッション",
      admin_payment_log_detail_label_created_at: "生成時間",
      admin_payment_log_detail_button_go_to_list: "リストへのアクセス",
      admin_transfer_log_list_title: "通信情報",
      admin_transfer_log_list_placeholder_user_id: "ユーザーID",
      admin_transfer_log_list_placeholder_hotel_id: "ホテルID",
      admin_transfer_log_list_placeholder_search: "検索",
      admin_transfer_log_list_label_no: "番号",
      admin_transfer_log_list_label_type: "タイプ",
      admin_transfer_log_list_label_user: "使用者",
      admin_transfer_log_list_label_hotel_id: "ホテルID",
      admin_transfer_log_list_label_path: "経路",
      admin_transfer_log_list_label_status: "状態",
      admin_transfer_log_list_label_success: "成功",
      admin_transfer_log_list_label_created_at: "生成時間",
      admin_transfer_log_list_guide_empty: "リストが空です",
      admin_transfer_log_detail_title: "通信詳細情報",
      admin_transfer_log_detail_label_transfer_id: "通信ID",
      admin_transfer_log_detail_label_type: "タイプ",
      admin_transfer_log_detail_label_user: "使用者",
      admin_transfer_log_detail_label_hotel_id: "ホテルID",
      admin_transfer_log_detail_label_user_email: "ユーザーEメール",
      admin_transfer_log_detail_label_session: "セッション",
      admin_transfer_log_detail_label_token: "トークン",
      admin_transfer_log_detail_label_path: "経路",
      admin_transfer_log_detail_label_status: "状態",
      admin_transfer_log_detail_label_header: "前書き",
      admin_transfer_log_detail_label_param: "変数",
      admin_transfer_log_detail_label_body: "本文",
      admin_transfer_log_detail_label_created_at: "生成時間",
      admin_transfer_log_detail_label_updated_at: "アップデート時間",
      admin_transfer_log_detail_button_go_to_list: "リストへのアクセス",
      admin_user_transfer_log_list_title: "ユーザー通信リスト",
      admin_user_transfer_log_list_placeholder_email: "Eメールを入力してください",
      admin_user_transfer_log_list_placeholder_search: "検索",
      admin_user_transfer_log_list_label_no: "番号",
      admin_user_transfer_log_list_label_type: "タイプ",
      admin_user_transfer_log_list_label_method: "方法",
      admin_user_transfer_log_list_label_path: "経路",
      admin_user_transfer_log_list_label_status: "状態",
      admin_user_transfer_log_list_label_success: "成功",
      admin_user_transfer_log_list_label_user_email: "ユーザーEメール",
      admin_user_transfer_log_list_label_created_at: "生成時間",
      admin_user_transfer_log_list_guide_empty: "リストが空です",
      admin_user_transfer_log_detail_title: "ユーザー通信の詳細情報",
      admin_user_transfer_log_detail_label_id: "ID",
      admin_user_transfer_log_detail_label_type: "タイプ",
      admin_user_transfer_log_detail_label_method: "方法",
      admin_user_transfer_log_detail_label_path: "経路",
      admin_user_transfer_log_detail_label_header: "前書き",
      admin_user_transfer_log_detail_label_param: "変数",
      admin_user_transfer_log_detail_label_body: "本文",
      admin_user_transfer_log_detail_label_soap: "SOAP",
      admin_user_transfer_log_detail_label_status: "状態",
      admin_user_transfer_log_detail_label_session: "セッション",
      admin_user_transfer_log_detail_label_token: "トークン",
      admin_user_transfer_log_detail_label_user_email: "ユーザーEメール",
      admin_user_transfer_log_detail_label_created_at: "生成時間",
      admin_user_transfer_log_detail_button_go_to_list: "リストへのアクセス",
      admin_transaction_log_list_title: "ステータス処理プロセスログリスト",
      admin_transaction_log_list_select_complete_action: "動作完了(すべて)",
      admin_transaction_log_list_select_complete_action_commit: "実行",
      admin_transaction_log_list_select_complete_action_rollback: "巻き戻し",
      admin_transaction_log_list_select_complete_action_none: "なし",
      admin_transaction_log_list_placeholder_search: "検索",
      admin_transaction_log_list_label_no: "番号",
      admin_transaction_log_list_label_act_on_function: "機能に応じて動作",
      admin_transaction_log_list_label_complete_action: "動作完了",
      admin_transaction_log_list_label_completed_at: "完了時間",
      admin_transaction_log_list_label_date: "日付",
      admin_transaction_log_detail_title: "ステータス処理プロセスログの詳細",
      admin_transaction_log_detail_label_transaction_id: "取引ID",
      admin_transaction_log_detail_label_act_on_function: "機能に応じて動作",
      admin_transaction_log_detail_label_complete_action: "動作完了",
      admin_transaction_log_detail_label_rollback_reason: "思惟の巻き戻し",
      admin_transaction_log_detail_label_completed_at: "完了時間",
      admin_transaction_log_detail_label_created_at: "生成時間",
      admin_transaction_log_detail_label_updated_at: "アップデート時間",
      admin_transaction_log_detail_button_go_to_list: "リストへのアクセス",
      admin_transaction_log_detail_rollback_reason_stack: "スタック",
      admin_transaction_log_detail_rollback_reason_error_message: "エラーメッセージ",
      admin_user_transfer_log_history_title: "ユーザー通信ログヒストリー",
      admin_user_transfer_log_history_placeholder_email: "メールを入力してください。",
      admin_user_transfer_log_history_placeholder_search: "検索",
      admin_user_transfer_log_history_label_no: "番号",
      admin_user_transfer_log_history_label_method: "方法",
      admin_user_transfer_log_history_label_path: "経路",
      admin_user_transfer_log_history_label_status: "状態",
      admin_user_transfer_log_history_label_success: "成功",
      admin_user_transfer_log_history_label_user_email: "ユーザーEメール",
      admin_user_transfer_log_history_label_created_at: "生成時間",
      admin_user_transfer_log_history_label_process_time: "処理時間(ms)",
      admin_user_transfer_log_history_detail_title: "ユーザー 通信 ログ 詳細 ヒストリー ( ↓ )",
      admin_user_transfer_log_history_detail_label_no: "番号",
      admin_user_transfer_log_history_detail_label_type: "タイプ",
      admin_user_transfer_log_history_detail_label_method: "方法",
      admin_user_transfer_log_history_detail_label_path: "経路",
      admin_user_transfer_log_history_detail_label_status: "状態",
      admin_user_transfer_log_history_detail_label_success: "成功",
      admin_user_transfer_log_history_detail_label_user_email: "ユーザーEメール",
      admin_user_transfer_log_history_detail_label_created_at: "生成時間",
      admin_user_transfer_log_history_detail_button_go_to_list: "リストへのアクセス",
      admin_staff_transfer_log_history_title: "STAFF通信ログヒストリー",
      admin_staff_transfer_log_history_list_placeholder_user_id: "ユーザーID",
      admin_staff_transfer_log_history_list_placeholder_hotel_id: "ホテルID",
      admin_staff_transfer_log_history_list_placeholder_search: "検索",
      admin_staff_transfer_log_history_list_label_no: "番号",
      admin_staff_transfer_log_history_list_label_method: "Method",
      admin_staff_transfer_log_history_list_label_user: "使用者",
      admin_staff_transfer_log_history_list_label_hotel_id: "ホテルID",
      admin_staff_transfer_log_history_list_label_path: "経路",
      admin_staff_transfer_log_history_list_label_status: "状態",
      admin_staff_transfer_log_history_list_label_success: "成功",
      admin_staff_transfer_log_history_list_label_created_at: "生成時間",
      admin_staff_transfer_log_history_list_label_process_time: "処理時間(ms)",
      admin_staff_transfer_log_history_list_guide_empty: "リストが空です",
      admin_staff_transfer_log_history_detail_title: "Staff 通信 ログ 詳細 ヒストリー ( ↓ )",
      admin_staff_transfer_log_history_detail_label_no: "番号",
      admin_staff_transfer_log_history_detail_label_type: "タイプ",
      admin_staff_transfer_log_history_detail_label_method: "方法",
      admin_staff_transfer_log_history_detail_label_user: "使用者",
      admin_staff_transfer_log_history_detail_label_hotel_id: "ホテルID",
      admin_staff_transfer_log_history_detail_label_path: "経路",
      admin_staff_transfer_log_history_detail_label_status: "状態",
      admin_staff_transfer_log_history_detail_label_success: "成功",
      admin_staff_transfer_log_history_detail_label_created_at: "生成時間",
      admin_staff_transfer_log_history_detail_button_go_to_list: "リストへのアクセス",
      admin_user_notification_log_list_title: "ユーザー通知ログ一覧",
      admin_user_notification_log_list_placeholder_email: "Eメール",
      admin_user_notification_log_list_placeholder_search: "検索",
      admin_user_notification_log_list_label_no: "番号",
      admin_user_notification_log_list_label_type: "タイプ",
      admin_user_notification_log_list_label_title: "タイトル",
      admin_user_notification_log_list_label_email: "Eメール",
      admin_user_notification_log_list_label_user: "使用者",
      admin_user_notification_log_list_label_success: "成功",
      admin_user_notification_log_list_label_os: "OS",
      admin_user_notification_log_list_label_language_code: "言語コード",
      admin_user_notification_log_list_label_created_at: "生成時間",
      admin_user_notification_log_detail_title: "ユーザー通知ログ詳細",
      admin_user_notification_log_detail_label_notification_id: "通知ID",
      admin_user_notification_log_detail_label_user_id: "ユーザーID",
      admin_user_notification_log_detail_label_success: "成功",
      admin_user_notification_log_detail_label_user: "使用者",
      admin_user_notification_log_detail_label_user_email: "ユーザーEメール",
      admin_user_notification_log_detail_label_language_code: "言語コード",
      admin_user_notification_log_detail_label_os_type: "OSタイプ",
      admin_user_notification_log_detail_label_type: "タイプ",
      admin_user_notification_log_detail_label_title: "タイトル",
      admin_user_notification_log_detail_label_content: "内容",
      admin_user_notification_log_detail_label_data: "データ",
      admin_user_notification_log_detail_label_token: "トークン",
      admin_user_notification_log_detail_label_created_at: "生成時間",
      admin_user_notification_log_detail_label_updated_at: "アップデート時間",
      admin_user_notification_log_detail_button_go_to_list: "リストへのアクセス",
      admin_staff_notification_log_list_title: "スタッフ通知ログ一覧",
      admin_staff_notification_log_list_placeholder_email: "Eメール",
      admin_staff_notification_log_list_placeholder_search: "検索",
      admin_staff_notification_log_list_label_no: "番号",
      admin_staff_notification_log_list_label_type: "タイプ",
      admin_staff_notification_log_list_label_title: "タイトル",
      admin_staff_notification_log_list_label_email: "Eメール",
      admin_staff_notification_log_list_label_hotel: "ホテル",
      admin_staff_notification_log_list_label_staff: "スタッフ",
      admin_staff_notification_log_list_label_task: "タスク",
      admin_staff_notification_log_list_label_department: "部署",
      admin_staff_notification_log_list_label_success: "成功",
      admin_staff_notification_log_list_label_os: "OS",
      admin_staff_notification_log_list_label_language_code: "言語コード",
      admin_staff_notification_log_list_label_created_at: "生成時間",
      admin_staff_notification_log_detail_title: "スタッフ通知ログ詳細",
      admin_staff_notification_log_detail_label_notification_id: "通知ID",
      admin_staff_notification_log_detail_label_hotel_id: "ホテルID",
      admin_staff_notification_log_detail_label_staff_id: "スタッフID",
      admin_staff_notification_log_detail_label_success: "成功",
      admin_staff_notification_log_detail_label_staff_email: "Eメール",
      admin_staff_notification_log_detail_label_staff_no: "スタッフ番号",
      admin_staff_notification_log_detail_label_department: "部署",
      admin_staff_notification_log_detail_label_team: "チーム",
      admin_staff_notification_log_detail_label_level: "職責",
      admin_staff_notification_log_detail_label_task: "タスク",
      admin_staff_notification_log_detail_label_location: "位置",
      admin_staff_notification_log_detail_label_zone: "区域",
      admin_staff_notification_log_detail_label_room: "客室",
      admin_staff_notification_log_detail_label_guest_inquiry_level: "顧客照会レベル",
      admin_staff_notification_log_detail_label_language_code: "言語コード",
      admin_staff_notification_log_detail_label_os_type: "OSタイプ",
      admin_staff_notification_log_detail_label_type: "タイプ",
      admin_staff_notification_log_detail_label_title: "タイトル",
      admin_staff_notification_log_detail_label_content: "内容",
      admin_staff_notification_log_detail_label_data: "データ",
      admin_staff_notification_log_detail_label_token: "トークン",
      admin_staff_notification_log_detail_label_created_at: "生成時間",
      admin_staff_notification_log_detail_label_updated_at: "アップデート時間",
      admin_staff_notification_log_detail_button_go_to_list: "リストへのアクセス",
      admin_version_list_title: "バージョンリスト",
      admin_version_list_placeholiser_search: "検索",
      admin_version_list_label_version_name: "バージョン名",
      admin_version_list_label_ios: "iOS",
      admin_version_list_label_ios_debug: "iOSデバッグ",
      admin_version_list_label_android: "Android",
      admin_version_list_label_android_debug: "Androidデバッグ",
      admin_version_list_guide_empty: "リストが空です",
      admin_version_list_button_delete: "削除",
      admin_version_list_button_add: "追加",
      admin_version_add_title: "バージョン追加",
      admin_version_add_label_version_name: "バージョン名",
      admin_version_add_label_ios: "iOS",
      admin_version_add_label_ios_debug: "iOSデバッグ",
      admin_version_add_placeholder_ios_debug: "iOSデバッグ",
      admin_version_add_select_ios_true: "TRUE",
      admin_version_add_select_ios_false: "FALSE",
      admin_version_add_label_android: "Android",
      admin_version_add_label_android_debug: "Androidデバッグ",
      admin_version_add_placeholder_android_debug: "Androidデバッグ",
      admin_version_add_validation_name: "バージョン名を入力してください。",
      admin_version_add_validation_ios: "iOSを入力してください。",
      admin_version_add_validation_ios_debug: "iOSデバッグを選択してください。",
      admin_version_add_validation_andriod: "Androidを入力してください。",
      admin_version_add_validation_andriod_debug: "Androidデバッグを選択してください。",
      admin_version_add_select_android_true: "TRUE",
      admin_version_add_select_android_false: "FALSE",
      admin_version_add_button_go_to_list: "リストへのアクセス",
      admin_version_add_button_add: "追加",
      admin_version_detail_title: "バージョン情報",
      admin_version_detail_label_version_name: "バージョン名",
      admin_version_detail_label_ios: "iOS",
      admin_version_detail_label_ios_debug: "iOSデバッグ",
      admin_version_detail_label_android: "Android",
      admin_version_detail_label_android_debug: "Androidデバッグ",
      admin_version_detail_button_go_to_list: "リストへのアクセス",
      admin_version_detail_button_delete: "削除",
      admin_version_detail_button_edit: "修整",
      admin_version_edit_title: "バージョン情報",
      admin_version_edit_label_version_name: "バージョン名",
      admin_version_edit_label_ios: "iOS",
      admin_version_edit_label_ios_debug: "iOSデバッグ",
      admin_version_edit_select_ios_true: "TRUE",
      admin_version_edit_select_ios_false: "FALSE",
      admin_version_edit_label_android: "Android",
      admin_version_edit_label_android_debug: "Androidデバッグ",
      admin_version_edit_select_android_true: "TRUE",
      admin_version_edit_select_android_false: "FALSE",
      admin_version_edit_button_cancel: "キャンセル",
      admin_version_edit_button_delete: "削除",
      admin_version_edit_button_ok: "確認",
      admin_version_app_down_version_list_title: "アプリバージョン一覧",
      admin_version_app_down_version_list_label_os: "OS",
      admin_version_app_down_version_list_label_type: "タイプ",
      admin_version_app_down_version_list_label_version: "バージョン",
      admin_version_app_down_version_list_label_link: "リンク",
      admin_version_app_down_version_list_label_created_at: "生成時間",
      admin_version_app_down_version_list_button_delete: "削除",
      admin_version_app_down_version_list_button_add: "追加",
      admin_version_app_down_version_list_placeholiser_search: "検索",
      admin_version_app_down_version_list_label_no: "番号",
      admin_version_app_down_version_list_guide_empty: "リストが空です",
      admin_version_app_down_version_add_title: "アプリダウンロードバージョン追加",
      admin_version_app_down_version_add_validation_os: "OSを選択してください。",
      admin_version_app_down_version_add_validation_type: "タイプを選択してください。",
      admin_version_app_down_version_add_validation_version: "バージョンを選択してください。",
      admin_version_app_down_version_add_validation_link: "リンクを選択してください。",
      admin_version_app_down_version_add_label_os: "OS",
      admin_version_app_down_version_add_placeholder_os: "OS",
      admin_version_app_down_version_add_select_ios: "iOS",
      admin_version_app_down_version_add_select_aos: "AOS",
      admin_version_app_down_version_add_label_type: "タイプ",
      admin_version_app_down_version_add_placeholder_type: "タイプ",
      admin_version_app_down_version_add_select_guest: "ゲスト",
      admin_version_app_down_version_add_select_staff: "スタッフ",
      admin_version_app_down_version_add_select_valet: "バレット",
      admin_version_app_down_version_add_select_parking_staff: "駐車スタッフ",
      admin_version_app_down_version_add_label_version: "バージョン",
      admin_version_app_down_version_add_label_link: "リンク",
      admin_version_app_down_version_add_button_go_to_list: "リストへのアクセス",
      admin_version_app_down_version_add_button_add: "追加",
      admin_version_guest_app_version_list_title: "ゲストアプリバージョンリスト",
      admin_version_guest_app_version_list_placeholiser_search: "検索",
      admin_version_guest_app_version_list_label_no: "番号",
      admin_version_guest_app_version_list_label_os: "OS",
      admin_version_guest_app_version_list_label_version: "バージョン名",
      admin_version_guest_app_version_list_label_link: "リンク",
      admin_version_guest_app_version_list_label_created_at: "生成時間",
      admin_version_guest_app_version_list_button_delete: "削除",
      admin_version_guest_app_version_list_button_add: "追加",
      admin_version_guest_app_version_list_guide_empty: "リストが空です",
      admin_version_guest_app_version_add_title: "ゲストバージョン追加",
      admin_version_guest_app_version_add_validation_os: "OSを選択してください。",
      admin_version_guest_app_version_add_validation_version: "バージョンを選択してください。",
      admin_version_guest_app_version_add_validation_link: "リンクを選択してください。",
      admin_version_guest_app_version_add_label_os: "OS",
      admin_version_guest_app_version_add_placeholder_os: "OS",
      admin_version_guest_app_version_add_select_ios: "iOS",
      admin_version_guest_app_version_add_select_aos: "AOS",
      admin_version_guest_app_version_add_label_version: "バージョン",
      admin_version_guest_app_version_add_label_link: "リンク",
      admin_version_guest_app_version_add_button_go_to_list: "リストへのアクセス",
      admin_version_guest_app_version_add_button_add: "追加",
      admin_version_staff_app_version_list_title: "スタッフアプリバージョン一覧",
      admin_version_staff_app_version_add_validation_os: "OSを選択してください。",
      admin_version_staff_app_version_add_validation_version: "バージョンを選択してください。",
      admin_version_staff_app_version_add_validation_link: "リンクを選択してください。",
      admin_version_staff_app_version_list_placeholiser_search: "検索",
      admin_version_staff_app_version_list_label_no: "番号",
      admin_version_staff_app_version_list_label_os: "OS",
      admin_version_staff_app_version_list_label_version: "バージョン",
      admin_version_staff_app_version_list_label_link: "リンク",
      admin_version_staff_app_version_list_label_created_at: "生成時間",
      admin_version_staff_app_version_list_button_delete: "削除",
      admin_version_staff_app_version_list_button_add: "追加",
      admin_version_staff_app_version_list_guide_empty: "リストが空です",
      admin_version_staff_app_version_add_title: "スタッフバージョン追加",
      admin_version_staff_app_version_add_label_os: "OS",
      admin_version_staff_app_version_add_placeholder_os: "OS",
      admin_version_staff_app_version_add_select_ios: "iOS",
      admin_version_staff_app_version_add_select_aos: "AOS",
      admin_version_staff_app_version_add_label_version: "バージョン",
      admin_version_staff_app_version_add_label_link: "リンク",
      admin_version_staff_app_version_add_button_go_to_list: "リストへのアクセス",
      admin_version_staff_app_version_add_button_add: "追加",
      admin_version_parking_staff_app_version_list_title: "駐車スタッフアプリダウンロード一覧",
      admin_version_parking_staff_app_version_add_validation_os: "OSを選択してください。",
      admin_version_parking_staff_app_version_add_validation_version: "バージョンを入力してください。",
      admin_version_parking_staff_app_version_add_validation_link: "リンクを入力してください。",
      admin_version_parking_staff_app_version_list_placeholiser_search: "検索",
      admin_version_parking_staff_app_version_list_label_no: "番号",
      admin_version_parking_staff_app_version_list_label_os: "OS",
      admin_version_parking_staff_app_version_list_label_version: "バージョン",
      admin_version_parking_staff_app_version_list_label_link: "リンク",
      admin_version_parking_staff_app_version_list_label_created_at: "生成時間",
      admin_version_parking_staff_app_version_list_button_delete: "削除",
      admin_version_parking_staff_app_version_list_button_add: "追加",
      admin_version_parking_staff_app_version_add_title: "駐車スタッフダウンロード追加",
      admin_version_parking_staff_app_version_add_label_os: "OS",
      admin_version_parking_staff_app_version_add_placeholder_os: "OS",
      admin_version_parking_staff_app_version_add_select_ios: "iOS",
      admin_version_parking_staff_app_version_add_select_aos: "AOS",
      admin_version_parking_staff_app_version_add_label_version: "バージョン",
      admin_version_parking_staff_app_version_add_label_link: "リンク",
      admin_version_parking_staff_app_version_add_button_go_to_list: "リストへのアクセス",
      admin_version_parking_staff_app_version_add_button_add: "追加",
      admin_application_translation_list_title: "アプリ翻訳リスト",
      admin_application_translation_list_select_application_type: "アプリタイプ（全て）",
      admin_application_translation_list_select_application_type_guest: "ゲスト",
      admin_application_translation_list_select_application_type_staff: "スタッフ",
      admin_application_translation_list_select_application_type_parking_staff: "駐車スタッフ",
      admin_application_translation_list_select_os_type: "OSタイプ（すべて）",
      admin_application_translation_list_select_os_type_aos: "AOS",
      admin_application_translation_list_select_os_type_ios: "iOS",
      admin_application_translation_list_placehoder_application_version: "アプリバージョン",
      admin_application_translation_list_placehoder_file_name: "ファイル名",
      admin_application_translation_list_label_application_type: "アプリ·タイプ",
      admin_application_translation_list_label_os: "OS",
      admin_application_translation_list_label_application_version: "アプリバージョン",
      admin_application_translation_list_label_version: "バージョン",
      admin_application_translation_list_label_file_name: "ファイル名",
      admin_application_translation_list_label_date: "日付",
      admin_application_translation_list_button_delete: "削除",
      admin_application_translation_list_button_add: "追加",
      admin_application_translation_list_guide_empty: "リストが空です",
      admin_application_translation_add_title: "アプリ翻訳追加",
      admin_application_translation_add_validation_application_type: "アプリのタイプを選択してください。",
      admin_application_translation_add_validation_os_type: "OS Typeを選択してください。",
      admin_application_translation_add_validation_application_version: "アプリのバージョンを入力してください。",
      admin_application_translation_add_validation_version: "バージョンを入力してください。",
      admin_application_translation_add_validation_translation_file: "翻訳ファイルを入力してください。",
      admin_application_translation_add_label_admin_application_type: "アプリ·タイプ",
      admin_application_translation_add_placeholder_admin_application_type: "アプリ·タイプ",
      admin_application_translation_add_select_admin_application_type_guest: "ゲスト",
      admin_application_translation_add_select_admin_application_type_staff: "スタッフ",
      admin_application_translation_add_select_admin_application_type_parking_staff: "駐車スタッフ",
      admin_application_translation_add_label_os_type: "OSタイプ",
      admin_application_translation_add_placeholder_os_type: "OSタイプ",
      admin_application_translation_add_select_os_type_aos: "AOS",
      admin_application_translation_add_select_os_type_ios: "iOS",
      admin_application_translation_add_label_admin_application_version: "アプリバージョン",
      admin_application_translation_add_label_version: "バージョン",
      admin_application_translation_add_label_translation_file: "翻訳ファイル",
      admin_application_translation_add_button_upload: "アップロード",
      admin_application_translation_add_placeholder_upload: "ex) Translation.json",
      admin_application_translation_add_button_go_to_list: "リストへのアクセス",
      admin_application_translation_add_button_add: "追加",
      admin_application_translation_detail_title: "アプリ翻訳の詳細",
      admin_application_translation_detail_label_admin_application_type: "アプリ·タイプ",
      admin_application_translation_detail_placeholder_select_box_choose: "選択",
      admin_application_translation_detail_select_admin_application_type_guest: "ゲスト",
      admin_application_translation_detail_select_admin_application_type_staff: "スタッフ",
      admin_application_translation_detail_label_os_type: "OSタイプ",
      admin_application_translation_detail_select_os_type_aos: "AOS",
      admin_application_translation_detail_select_os_type_ios: "iOS",
      admin_application_translation_detail_label_admin_application_version: "アプリバージョン",
      admin_application_translation_detail_label_version: "バージョン",
      admin_application_translation_detail_label_file_name: "ファイル名",
      admin_application_translation_detail_label_date: "日付",
      admin_application_translation_detail_button_edit: "修整",
      admin_application_translation_detail_button_go_to_list: "リストへのアクセス",
      admin_application_translation_detail_button_download: "ダウンロード",
      admin_application_translation_edit_title: "アプリ翻訳の詳細",
      admin_application_translation_edit_button_download: "ダウンロード",
      admin_application_translation_edit_validation_application_type: "アプリのタイプを選択してください。",
      admin_application_translation_edit_validation_os_type: "OS Typeを選択してください。",
      admin_application_translation_edit_validation_application_version: "アプリのバージョンを入力してください。",
      admin_application_translation_edit_validation_version: "バージョンを入力してください。",
      admin_application_translation_edit_validation_translation_file: "翻訳ファイルを入力してください。",
      admin_application_translation_edit_label_admin_application_type: "アプリ·タイプ",
      admin_application_translation_edit_placeholder_select_box_choose: "選択",
      admin_application_translation_edit_select_admin_application_type_guest: "ゲスト",
      admin_application_translation_edit_select_admin_application_type_staff: "スタッフ",
      admin_application_translation_edit_label_os_type: "OSタイプ",
      admin_application_translation_edit_select_os_type_aos: "AOS",
      admin_application_translation_edit_select_os_type_ios: "iOS",
      admin_application_translation_edit_label_admin_application_version: "アプリバージョン",
      admin_application_translation_edit_label_version: "バージョン",
      admin_application_translation_edit_label_file_name: "ファイル名",
      admin_application_translation_edit_label_date: "日付",
      admin_application_translation_edit_button_upload: "アップロード",
      admin_application_translation_edit_button_ok: "確認",
      admin_application_translation_edit_button_cancel: "キャンセル",
      admin_booking_email_detail_label_format: "Eメール画像形式",
      admin_booking_email_detail_label_image: "Eメール画像",
      admin_booking_email_detail_button_upload: "アップロード",
      admin_booking_email_detail_placeholder_upload: "ex) MyCONECT。png",
      admin_booking_email_detail_button_edit: "修整",
      admin_email_form_change_password_edit_title: "パスワード変更",
      admin_email_form_change_password_edit_validation_content: "内容を入力してください。",
      admin_email_form_change_password_edit_select_kr: "韓国語",
      admin_email_form_change_password_edit_select_en: "英語",
      admin_email_form_change_password_edit_select_cn: "中国語",
      admin_email_form_change_password_edit_select_jp: "日本語",
      admin_email_form_change_password_edit_select_ms: "MS",
      admin_email_form_change_password_edit_button_remove: "除去",
      admin_email_form_change_password_edit_button_append: "追加",
      admin_email_form_change_password_edit_button_save: "貯蔵",
      admin_email_form_change_password_edit_toolbar_bold: "太く",
      admin_email_form_change_password_edit_toolbar_italic: "イタリック体",
      admin_email_form_change_password_edit_toolbar_underline: "下線",
      admin_email_form_change_password_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_change_password_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_change_password_edit_toolbar_font_family: "フォント集",
      admin_email_form_change_password_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_change_password_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_change_password_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_change_password_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_change_password_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_change_password_edit_toolbar_table: "テーブル",
      admin_email_form_change_password_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_change_password_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_change_password_edit_toolbar_paragraph: "短絡",
      admin_email_form_change_password_edit_toolbar_line_height: "線の高さ",
      admin_email_form_change_password_edit_toolbar_picture: "写真",
      admin_email_form_change_password_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_change_password_edit_toolbar_full_screen: "全画面",
      admin_email_form_change_password_edit_toolbar_help: "ヘルプ",
      admin_email_form_change_password_edit_toolbar_tip: "チップ",
      admin_email_form_change_password_edit_placeholder_content: "内容を入力してください",
      admin_email_form_forgot_id_edit_title: "ID検索",
      admin_email_form_forgot_id_edit_validation_content: "内容を入力してください。",
      admin_email_form_forgot_id_edit_select_kr: "韓国語",
      admin_email_form_forgot_id_edit_select_en: "英語",
      admin_email_form_forgot_id_edit_select_cn: "中国語",
      admin_email_form_forgot_id_edit_select_jp: "日本語",
      admin_email_form_forgot_id_edit_select_ms: "MS",
      admin_email_form_forgot_id_edit_button_remove: "除去",
      admin_email_form_forgot_id_edit_button_append: "追加",
      admin_email_form_forgot_id_edit_button_save: "貯蔵",
      admin_email_form_forgot_id_edit_toolbar_bold: "太く",
      admin_email_form_forgot_id_edit_toolbar_italic: "イタリック体",
      admin_email_form_forgot_id_edit_toolbar_underline: "下線",
      admin_email_form_forgot_id_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_forgot_id_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_forgot_id_edit_toolbar_font_family: "フォント集",
      admin_email_form_forgot_id_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_forgot_id_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_forgot_id_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_forgot_id_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_forgot_id_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_forgot_id_edit_toolbar_table: "テーブル",
      admin_email_form_forgot_id_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_forgot_id_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_forgot_id_edit_toolbar_paragraph: "短絡",
      admin_email_form_forgot_id_edit_toolbar_line_height: "線の高さ",
      admin_email_form_forgot_id_edit_toolbar_picture: "写真",
      admin_email_form_forgot_id_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_forgot_id_edit_toolbar_full_screen: "全画面",
      admin_email_form_forgot_id_edit_toolbar_help: "ヘルプ",
      admin_email_form_forgot_id_edit_toolbar_tip: "チップ",
      admin_email_form_forgot_id_edit_placeholder_content: "内容を入力してください",
      admin_email_form_forgot_id_detail_title: "ID検索文字",
      admin_email_form_forgot_id_detail_label_user_info: "顧客情報",
      admin_email_form_forgot_id_detail_label_example_user_info: "{{userInfo}}",
      admin_email_form_forgot_password_edit_title: "パスワード検索",
      admin_email_form_forgot_password_edit_validation_content: "内容を入力してください。",
      admin_email_form_forgot_password_edit_select_kr: "韓国語",
      admin_email_form_forgot_password_edit_select_en: "英語",
      admin_email_form_forgot_password_edit_select_cn: "中国語",
      admin_email_form_forgot_password_edit_select_jp: "日本語",
      admin_email_form_forgot_password_edit_select_ms: "MS",
      admin_email_form_forgot_password_edit_button_remove: "除去",
      admin_email_form_forgot_password_edit_button_append: "追加",
      admin_email_form_forgot_password_edit_button_save: "貯蔵",
      admin_email_form_forgot_password_edit_toolbar_bold: "太く",
      admin_email_form_forgot_password_edit_toolbar_italic: "イタリック体",
      admin_email_form_forgot_password_edit_toolbar_underline: "下線",
      admin_email_form_forgot_password_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_forgot_password_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_forgot_password_edit_toolbar_font_family: "フォント集",
      admin_email_form_forgot_password_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_forgot_password_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_forgot_password_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_forgot_password_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_forgot_password_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_forgot_password_edit_toolbar_table: "テーブル",
      admin_email_form_forgot_password_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_forgot_password_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_forgot_password_edit_toolbar_paragraph: "短絡",
      admin_email_form_forgot_password_edit_toolbar_line_height: "線の高さ",
      admin_email_form_forgot_password_edit_toolbar_picture: "写真",
      admin_email_form_forgot_password_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_forgot_password_edit_toolbar_full_screen: "全画面",
      admin_email_form_forgot_password_edit_toolbar_help: "ヘルプ",
      admin_email_form_forgot_password_edit_toolbar_tip: "チップ",
      admin_email_form_forgot_password_edit_placeholder_content: "内容を入力してください",
      admin_email_form_forgot_password_detail_title: "パスワード検索文字",
      admin_email_form_forgot_password_detail_label_email: "Eメール",
      admin_email_form_forgot_password_detail_label_example_email: "{{email}}",
      admin_email_form_forgot_password_detail_label_password: "パスワード",
      admin_email_form_forgot_password_detail_label_example_password: "{{password}}",
      admin_email_form_create_confirm_edit_title: "Eメール生成確認",
      admin_email_form_create_confirm_edit_validation_content: "内容を入力してください。",
      admin_email_form_create_confirm_edit_select_kr: "韓国語",
      admin_email_form_create_confirm_edit_select_en: "英語",
      admin_email_form_create_confirm_edit_select_cn: "中国語",
      admin_email_form_create_confirm_edit_select_jp: "日本語",
      admin_email_form_create_confirm_edit_select_ms: "MS",
      admin_email_form_create_confirm_edit_button_remove: "除去",
      admin_email_form_create_confirm_edit_button_append: "追加",
      admin_email_form_create_confirm_edit_button_save: "貯蔵",
      admin_email_form_create_confirm_edit_toolbar_bold: "太く",
      admin_email_form_create_confirm_edit_toolbar_italic: "イタリック体",
      admin_email_form_create_confirm_edit_toolbar_underline: "下線",
      admin_email_form_create_confirm_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_create_confirm_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_create_confirm_edit_toolbar_font_family: "フォント集",
      admin_email_form_create_confirm_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_create_confirm_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_create_confirm_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_create_confirm_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_create_confirm_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_create_confirm_edit_toolbar_table: "テーブル",
      admin_email_form_create_confirm_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_create_confirm_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_create_confirm_edit_toolbar_paragraph: "短絡",
      admin_email_form_create_confirm_edit_toolbar_line_height: "線の高さ",
      admin_email_form_create_confirm_edit_toolbar_picture: "写真",
      admin_email_form_create_confirm_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_create_confirm_edit_toolbar_full_screen: "全画面",
      admin_email_form_create_confirm_edit_toolbar_help: "ヘルプ",
      admin_email_form_create_confirm_edit_toolbar_tip: "チップ",
      admin_email_form_create_confirm_edit_placeholder_content: "内容を入力してください",
      admin_email_form_create_confirm_detail_title: "Eメール生成確認文字",
      admin_email_form_create_confirm_detail_label_url: "URL",
      admin_email_form_create_confirm_detail_example_url: "{{url}}",
      admin_email_form_create_confirm_failure_edit_title: "Eメールの確認に失敗",
      admin_email_form_create_confirm_failure_edit_validation_content: "内容を入力してください。",
      admin_email_form_create_confirm_failure_edit_select_kr: "韓国語",
      admin_email_form_create_confirm_failure_edit_select_en: "英語",
      admin_email_form_create_confirm_failure_edit_select_cn: "中国語",
      admin_email_form_create_confirm_failure_edit_select_jp: "日本語",
      admin_email_form_create_confirm_failure_edit_select_ms: "MS",
      admin_email_form_create_confirm_failure_edit_button_remove: "除去",
      admin_email_form_create_confirm_failure_edit_button_append: "追加",
      admin_email_form_create_confirm_failure_edit_button_save: "貯蔵",
      admin_email_form_create_confirm_failure_edit_toolbar_bold: "太く",
      admin_email_form_create_confirm_failure_edit_toolbar_italic: "イタリック体",
      admin_email_form_create_confirm_failure_edit_toolbar_underline: "下線",
      admin_email_form_create_confirm_failure_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_create_confirm_failure_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_create_confirm_failure_edit_toolbar_font_family: "フォント集",
      admin_email_form_create_confirm_failure_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_create_confirm_failure_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_create_confirm_failure_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_create_confirm_failure_edit_toolbar_table: "テーブル",
      admin_email_form_create_confirm_failure_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_create_confirm_failure_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_create_confirm_failure_edit_toolbar_paragraph: "短絡",
      admin_email_form_create_confirm_failure_edit_toolbar_line_height: "線の高さ",
      admin_email_form_create_confirm_failure_edit_toolbar_picture: "写真",
      admin_email_form_create_confirm_failure_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_create_confirm_failure_edit_toolbar_full_screen: "全画面",
      admin_email_form_create_confirm_failure_edit_toolbar_help: "ヘルプ",
      admin_email_form_create_confirm_failure_edit_toolbar_tip: "チップ",
      admin_email_form_create_confirm_failure_edit_placeholder_content: "内容を入力してください",
      admin_email_form_create_confirm_failure_detail_title: "Eメール生成失敗文字",
      admin_email_form_create_confirm_failure_detail_label_reason: "私有",
      admin_email_form_create_confirm_failure_detail_label_example_reason: "{{reason}}",
      admin_email_form_create_confirm_success_edit_title: "Eメール作成に成功",
      admin_email_form_create_confirm_success_edit_validation_content: "内容を入力してください。",
      admin_email_form_create_confirm_success_edit_select_kr: "韓国語",
      admin_email_form_create_confirm_success_edit_select_en: "英語",
      admin_email_form_create_confirm_success_edit_select_cn: "中国語",
      admin_email_form_create_confirm_success_edit_select_jp: "日本語",
      admin_email_form_create_confirm_success_edit_select_ms: "MS",
      admin_email_form_create_confirm_success_edit_button_remove: "除去",
      admin_email_form_create_confirm_success_edit_button_append: "追加",
      admin_email_form_create_confirm_success_edit_button_save: "貯蔵",
      admin_email_form_create_confirm_success_edit_toolbar_bold: "太く",
      admin_email_form_create_confirm_success_edit_toolbar_italic: "イタリック体",
      admin_email_form_create_confirm_success_edit_toolbar_underline: "下線",
      admin_email_form_create_confirm_success_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_create_confirm_success_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_create_confirm_success_edit_toolbar_font_family: "フォント集",
      admin_email_form_create_confirm_success_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_create_confirm_success_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_create_confirm_success_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_create_confirm_success_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_create_confirm_success_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_create_confirm_success_edit_toolbar_table: "テーブル",
      admin_email_form_create_confirm_success_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_create_confirm_success_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_create_confirm_success_edit_toolbar_paragraph: "短絡",
      admin_email_form_create_confirm_success_edit_toolbar_line_height: "線の高さ",
      admin_email_form_create_confirm_success_edit_toolbar_picture: "写真",
      admin_email_form_create_confirm_success_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_create_confirm_success_edit_toolbar_full_screen: "全画面",
      admin_email_form_create_confirm_success_edit_toolbar_help: "ヘルプ",
      admin_email_form_create_confirm_success_edit_toolbar_tip: "チップ",
      admin_email_form_create_confirm_success_edit_placeholder_content: "内容を入力してください",
      admin_email_form_update_profile_edit_title: "プロフィールアップデート",
      admin_email_form_update_profile_edit_validation_content: "内容を入力してください。",
      admin_email_form_update_profile_edit_select_kr: "韓国語",
      admin_email_form_update_profile_edit_select_en: "英語",
      admin_email_form_update_profile_edit_select_cn: "中国語",
      admin_email_form_update_profile_edit_select_jp: "日本語",
      admin_email_form_update_profile_edit_select_ms: "MS",
      admin_email_form_update_profile_edit_button_remove: "除去",
      admin_email_form_update_profile_edit_button_append: "追加",
      admin_email_form_update_profile_edit_button_save: "貯蔵",
      admin_email_form_update_profile_edit_toolbar_bold: "太く",
      admin_email_form_update_profile_edit_toolbar_italic: "イタリック体",
      admin_email_form_update_profile_edit_toolbar_underline: "下線",
      admin_email_form_update_profile_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_update_profile_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_update_profile_edit_toolbar_font_family: "フォント集",
      admin_email_form_update_profile_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_update_profile_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_update_profile_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_update_profile_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_update_profile_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_update_profile_edit_toolbar_table: "テーブル",
      admin_email_form_update_profile_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_update_profile_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_update_profile_edit_toolbar_paragraph: "短絡",
      admin_email_form_update_profile_edit_toolbar_line_height: "線の高さ",
      admin_email_form_update_profile_edit_toolbar_picture: "写真",
      admin_email_form_update_profile_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_update_profile_edit_toolbar_full_screen: "全画面",
      admin_email_form_update_profile_edit_toolbar_help: "ヘルプ",
      admin_email_form_update_profile_edit_toolbar_tip: "チップ",
      admin_email_form_update_profile_edit_placeholder_content: "内容を入力してください",
      admin_email_form_withdraw_edit_title: "会員脱退",
      admin_email_form_withdraw_edit_validation_content: "内容を入力してください。",
      admin_email_form_withdraw_edit_select_kr: "韓国語",
      admin_email_form_withdraw_edit_select_en: "英語",
      admin_email_form_withdraw_edit_select_cn: "中国語",
      admin_email_form_withdraw_edit_select_jp: "日本語",
      admin_email_form_withdraw_edit_select_ms: "MS",
      admin_email_form_withdraw_edit_button_remove: "除去",
      admin_email_form_withdraw_edit_button_append: "追加",
      admin_email_form_withdraw_edit_button_save: "貯蔵",
      admin_email_form_withdraw_edit_toolbar_bold: "太く",
      admin_email_form_withdraw_edit_toolbar_italic: "イタリック体",
      admin_email_form_withdraw_edit_toolbar_underline: "下線",
      admin_email_form_withdraw_edit_toolbar_strikethrough: "キャンセル線",
      admin_email_form_withdraw_edit_toolbar_remove_font_style: "書体取り",
      admin_email_form_withdraw_edit_toolbar_font_family: "フォント集",
      admin_email_form_withdraw_edit_toolbar_font_size: "フォントサイズ",
      admin_email_form_withdraw_edit_toolbar_text_color: "テキストカラー",
      admin_email_form_withdraw_edit_toolbar_text_more_color: "カラー もっと 見る",
      admin_email_form_withdraw_edit_toolbar_recent_color: "最近の使用色",
      admin_email_form_withdraw_edit_toolbar_recent_more_color: "カラー もっと 見る",
      admin_email_form_withdraw_edit_toolbar_table: "テーブル",
      admin_email_form_withdraw_edit_toolbar_unordered_list: "未注文リスト",
      admin_email_form_withdraw_edit_toolbar_ordered_list: "注文リスト",
      admin_email_form_withdraw_edit_toolbar_paragraph: "短絡",
      admin_email_form_withdraw_edit_toolbar_line_height: "線の高さ",
      admin_email_form_withdraw_edit_toolbar_picture: "写真",
      admin_email_form_withdraw_edit_toolbar_code_view: "コードを見ること",
      admin_email_form_withdraw_edit_toolbar_full_screen: "全画面",
      admin_email_form_withdraw_edit_toolbar_help: "ヘルプ",
      admin_email_form_withdraw_edit_toolbar_tip: "チップ",
      admin_email_form_withdraw_edit_placeholder_content: "内容を入力してください",
      admin_endpoint_list_title: "アプリ·エンドポイント",
      admin_endpoint_list_placeholiser_search: "検索",
      admin_endpoint_list_label_package_name: "パッケージ名",
      admin_endpoint_list_label_os: "OS",
      admin_endpoint_list_label_version: "バージョン",
      admin_endpoint_list_label_url: "URL",
      admin_endpoint_list_label_created_at: "生成時間",
      admin_endpoint_list_guide_empty: "リストが空です",
      admin_endpoint_list_button_delete: "削除",
      admin_endpoint_list_button_add: "追加",
      admin_endpoint_add_title: "アプリエンドポイント追加",
      admin_endpoint_add_validation_package_name: "パッケージ名を選択してください。",
      admin_endpoint_add_validation_os: "OSを選択してください。",
      admin_endpoint_add_validation_version: "バージョンを入力してください。",
      admin_endpoint_add_validation_url: "urlを選択してください。",
      admin_endpoint_add_label_package_name: "パッケージ名",
      admin_endpoint_add_placeholder_choose: "選択",
      admin_endpoint_add_select_os_staff: "スタッフ",
      admin_endpoint_add_select_os_guest: "ゲスト",
      admin_endpoint_add_select_os_staff_parking: "駐車スタッフ",
      admin_endpoint_add_label_os: "OS",
      admin_endpoint_add_select_os_ios: "iOS",
      admin_endpoint_add_select_os_aos: "AOS",
      admin_endpoint_add_label_version: "バージョン",
      admin_endpoint_add_label_url: "URL",
      admin_endpoint_add_button_go_to_list: "リストへのアクセス",
      admin_endpoint_add_button_add: "追加",
      admin_endpoint_detail_title: "アプリエンドポイント情報",
      admin_endpoint_detail_label_package_name: "パッケージ名",
      admin_endpoint_detail_placeholder_choose: "選択",
      admin_endpoint_detail_select_os_staff: "スタッフ",
      admin_endpoint_detail_select_os_guest: "ゲスト",
      admin_endpoint_detail_select_os_staff_parking: "駐車スタッフ",
      admin_endpoint_detail_label_os: "OS",
      admin_endpoint_detail_select_os_ios: "iOS",
      admin_endpoint_detail_select_os_aos: "AOS",
      admin_endpoint_detail_label_version: "バージョン",
      admin_endpoint_detail_label_url: "URL",
      admin_endpoint_detail_label_create_at: "生成時間",
      admin_endpoint_detail_button_go_to_list: "リストへのアクセス",
      admin_endpoint_detail_button_delete: "削除",
      admin_endpoint_detail_button_edit: "修整",
      admin_endpoint_edit_title: "アプリエンドポイント情報",
      admin_endpoint_edit_validation_package_name: "パッケージ名を選択してください。",
      admin_endpoint_edit_validation_os: "OSを選択してください。",
      admin_endpoint_edit_validation_version: "バージョンを入力してください。",
      admin_endpoint_edit_validation_url: "urlを選択してください。",
      admin_endpoint_edit_label_package_name: "パッケージ名",
      admin_endpoint_edit_placeholder_choose: "選択",
      admin_endpoint_edit_select_os_staff: "スタッフ",
      admin_endpoint_edit_select_os_guest: "ゲスト",
      admin_endpoint_edit_select_os_staff_parking: "駐車スタッフ",
      admin_endpoint_edit_label_os: "OS",
      admin_endpoint_edit_select_os_ios: "iOS",
      admin_endpoint_edit_select_os_aos: "AOS",
      admin_endpoint_edit_label_version: "バージョン",
      admin_endpoint_edit_label_url: "URL",
      admin_endpoint_edit_label_create_at: "生成時間",
      admin_endpoint_edit_button_cancel: "キャンセル",
      admin_endpoint_edit_button_delete: "削除",
      admin_endpoint_edit_button_ok: "確認",
      admin_terms_list_title: "条件目録",
      admin_terms_list_placeholder_search: "検索",
      admin_terms_list_label_language_code: "言語コード",
      admin_terms_list_label_file_name: "ファイル名",
      admin_terms_list_label_required: "要否",
      admin_terms_list_label_title: "呼称",
      admin_terms_list_label_terms_group_id: "条件グループID",
      admin_terms_list_label_created_at: "生成時間",
      admin_terms_list_guide_empty: "リストが空です",
      admin_terms_list_button_delete: "削除",
      admin_terms_list_button_add: "追加",
      admin_terms_add_title: "条件追加",
      admin_terms_add_validation_title: "呼称を入力してください。",
      admin_terms_add_validation_terms_group_id: "条件グループIDを選択してください。",
      admin_terms_add_validation_language_code: "言語コードを入力してください。",
      admin_terms_add_validation_required: "必須かどうかを選択してください。",
      admin_terms_add_validation_description: "説明を入力してください。",
      admin_terms_add_validation_link_text: "リンク テキストを入力してください。",
      admin_terms_add_validation_file: "ファイルをアップロードしてください。",
      admin_terms_add_label_title: "呼聴",
      admin_terms_add_label_terms_group_id: "条件グループID",
      admin_terms_add_label_choose: "選択",
      admin_terms_add_label_language_code: "言語コード",
      admin_terms_add_label_required: "要否",
      admin_terms_add_button_yes: "例",
      admin_terms_add_button_no: "いいえ",
      admin_terms_add_label_description: "説明",
      admin_terms_add_label_link_text: "リンク·テキスト",
      admin_terms_add_label_file_name: "ファイル名",
      admin_terms_add_label_file_url: "ファイルURL",
      admin_terms_add_button_upload: "アップロード",
      admin_terms_add_button_go_to_list: "リストへのアクセス",
      admin_terms_add_button_add: "追加",
      admin_terms_detail_title: "条件詳細",
      admin_terms_detail_label_title: "呼称",
      admin_terms_detail_label_language_code: "言語コード",
      admin_terms_detail_label_description: "説明",
      admin_terms_detail_label_link_text: "リンク·テキスト",
      admin_terms_detail_label_required: "要否",
      admin_terms_detail_button_yes: "例",
      admin_terms_detail_button_no: "いいえ",
      admin_terms_detail_label_terms_group_id: "条件グループID",
      admin_terms_detail_label_choose: "選択",
      admin_terms_detail_label_file_name: "ファイル名",
      admin_terms_detail_label_file_url: "ファイルURL",
      admin_terms_detail_button_upload: "アップロード",
      admin_terms_detail_label_created_at: "生成時間",
      admin_terms_detail_label_updated_at: "アップデート時間",
      admin_terms_detail_button_go_to_list: "リストへのアクセス",
      admin_terms_detail_button_edit: "修整",
      admin_terms_edit_title: "条件詳細",
      admin_terms_edit_validation_title: "呼称を入力してください。",
      admin_terms_edit_validation_terms_group_id: "条件グループIDを選択してください。",
      admin_terms_edit_validation_language_code: "言語コードを入力してください。",
      admin_terms_edit_validation_required: "必須かどうかを選択してください。",
      admin_terms_edit_validation_description: "説明を入力してください。",
      admin_terms_edit_validation_link_text: "リンク テキストを入力してください。",
      admin_terms_edit_validation_file: "ファイルをアップロードしてください。",
      admin_terms_edit_label_title: "呼称",
      admin_terms_edit_label_language_code: "言語コード",
      admin_terms_edit_label_description: "説明",
      admin_terms_edit_label_link_text: "リンク·テキスト",
      admin_terms_edit_label_required: "要否",
      admin_terms_edit_button_yes: "例",
      admin_terms_edit_button_no: "いいえ",
      admin_terms_edit_label_terms_group_id: "条件グループID",
      admin_terms_edit_label_choose: "選択",
      admin_terms_edit_label_file_name: "ファイル名",
      admin_terms_edit_label_file_url: "ファイルURL",
      admin_terms_edit_button_upload: "アップロード",
      admin_terms_edit_label_created_at: "生成時間",
      admin_terms_edit_label_updated_at: "アップデート時間",
      admin_terms_edit_button_cancel: "キャンセル",
      admin_terms_edit_button_ok: "確認",
      admin_terms_group_list_title: "条件グループリスト",
      admin_terms_group_list_placeholder_search: "検索",
      admin_terms_group_list_label_title: "呼称",
      admin_terms_group_list_label_country_code: "国コード",
      admin_terms_group_list_label_created_at: "生成時間",
      admin_terms_group_list_guide_empty: "リストが空です。",
      admin_terms_group_list_button_delete: "削除",
      admin_terms_group_list_button_add: "追加",
      admin_terms_group_add_title: "条件グループ追加",
      admin_terms_group_add_validation_title: "呼称を入力してください。",
      admin_terms_group_add_validation_country_code: "国コードを入力してください。",
      admin_terms_group_add_label_title: "呼称",
      admin_terms_group_add_label_country_code: "国コード",
      admin_terms_group_add_button_go_to_list: "リストへのアクセス",
      admin_terms_group_add_button_add: "追加",
      admin_terms_group_detail_title: "条件 グループ 詳細",
      admin_terms_group_detail_label_title: "呼称",
      admin_terms_group_detail_label_country_code: "国コード",
      admin_terms_group_detail_label_created_at: "生成時間",
      admin_terms_group_detail_label_updated_at: "アップデート時間",
      admin_terms_group_detail_button_go_to_list: "リストへのアクセス",
      admin_terms_group_detail_button_edit: "修整",
      admin_terms_group_edit_title: "条件 グループ 詳細",
      admin_terms_group_edit_validation_title: "呼称を入力してください。",
      admin_terms_group_edit_validation_country_code: "国コードを入力してください。",
      admin_terms_group_edit_label_title: "呼称",
      admin_terms_group_edit_label_country_code: "国コード",
      admin_terms_group_edit_label_created_at: "生成時間",
      admin_terms_group_edit_label_updated_at: "アップデート時間",
      admin_terms_group_edit_button_cancel: "キャンセル",
      admin_terms_group_edit_button_ok: "確認",
      admin_agreement_list_title: "同意履歴リスト",
      admin_agreement_list_placeholder_email: "メールを入力してください。",
      admin_agreement_list_placeholder_search: "検索",
      admin_agreement_list_label_no: "番号",
      admin_agreement_list_label_user_id: "ユーザーID",
      admin_agreement_list_label_terms_id: "条件",
      admin_agreement_list_label_agreed: "同意の可否",
      admin_agreement_list_label_email: "Eメール",
      admin_agreement_list_label_name: "名前",
      admin_agreement_list_label_file_name: "ファイル名",
      admin_agreement_list_label_created_at: "生成時間",
      admin_agreement_detail_title: "同意内訳詳細",
      admin_agreement_detail_label_agreement: "同意",
      admin_agreement_detail_label_agreed: "同意の可否",
      admin_agreement_detail_label_agreement_created_at: "同意時間",
      admin_agreement_detail_label_user: "使用者",
      admin_agreement_detail_label_user_id: "ユーザーID",
      admin_agreement_detail_label_email: "Eメール",
      admin_agreement_detail_label_name: "名前",
      admin_agreement_detail_label_email_confimed_at: "Eメール確認時間",
      admin_agreement_detail_label_user_language_code: "ユーザー言語コード",
      admin_agreement_detail_label_user_created_at: "ユーザー作成時間",
      admin_agreement_detail_label_terms: "条件",
      admin_agreement_detail_label_terms_id: "条件ID",
      admin_agreement_detail_label_terms_language_code: "条件言語コード",
      admin_agreement_detail_label_file_name: "ファイル名",
      admin_agreement_detail_label_required: "要否",
      admin_agreement_detail_label_title: "呼称",
      admin_agreement_detail_label_description: "説明",
      admin_agreement_detail_label_link_text: "リンク·テキスト",
      admin_agreement_detail_label_terms_group_id: "条件グループID",
      admin_agreement_detail_label_terms_created_at: "条件生成時間",
      admin_agreement_detail_button_go_to_list: "リストへのアクセス",
      admin_auth_data_list_title: "位置情報利用·提供事実確認資料",
      admin_auth_data_list_placeholder_select_box_authentication_data: "確認データ",
      admin_auth_data_list_select_hotel_id: "ホテルID",
      admin_auth_data_list_select_email: "Eメール",
      admin_auth_data_list_label_no: "番号",
      admin_auth_data_list_placeholder_search: "検索",
      admin_auth_data_list_label_user_email: "対象",
      admin_auth_data_list_label_acquisition_path: "取得経路",
      admin_auth_data_list_label_service: "提供サービス",
      admin_auth_data_list_label_recipient: "提供される者",
      admin_auth_data_list_label_request_date: "収集要請日時",
      admin_auth_data_list_label_end_date: "収集終了日時",
      admin_auth_data_list_guide_empty: "リストが空です",
      admin_use_key_log_list_title: "キー使用ログ一覧",
      admin_use_key_log_list_placeholder_select_box_use_key_log: "鍵使用ログ",
      admin_use_key_log_list_select_hotel_id: "ホテルID",
      admin_use_key_log_list_select_email: "Eメール",
      admin_use_key_log_list_placeholder_search: "検索",
      admin_use_key_log_list_label_no: "番号",
      admin_use_key_log_list_label_user_email: "ユーザーEメール",
      admin_use_key_log_list_label_device: "デバイス",
      admin_use_key_log_list_label_app_version: "アプリバージョン",
      admin_use_key_log_list_label_hotel_id: "ホテルID",
      admin_use_key_log_list_label_key_id: "キーID",
      admin_use_key_log_list_label_date: "日付",
      admin_use_key_log_list_guide_empty: "リストが空です",
      admin_use_key_log_detail_title: "キー使用ログの詳細情報",
      admin_use_key_log_detail_label_use_key_log_id: "キー使用ログID",
      admin_use_key_log_detail_label_user_id: "ユーザーID",
      admin_use_key_log_detail_label_user_email: "ユーザーEメール",
      admin_use_key_log_detail_label_hotel_key_id: "ホテルキーID",
      admin_use_key_log_detail_label_hotel_id: "ホテルID",
      admin_use_key_log_detail_label_device: "デバイス",
      admin_use_key_log_detail_label_app_version: "アプリバージョン",
      admin_use_key_log_detail_label_log: "対数",
      admin_use_key_log_detail_label_created_at: "生成時間",
      admin_use_key_log_detail_label_updated_at: "アップデート時間",
      admin_use_key_log_detail_button_go_to_list: "リストへのアクセス",
      admin_amenity_list_title: "便益施設及びサービスリスト",
      admin_amenity_list_placeholder_search: "検索······",
      admin_amenity_list_label_type: "タイプ",
      admin_amenity_list_label_group: "グループ",
      admin_amenity_list_label_code: "コード",
      admin_amenity_list_label_name: "名前",
      admin_amenity_list_label_date: "日付",
      admin_amenity_list_button_delete: "削除",
      admin_amenity_list_button_add: "追加",
      admin_amenity_list_guide_empty: "リストが空です",
      admin_amenity_add_title: "便益施設追加",
      admin_amenity_add_validation_type: "* タイプを選択してください。",
      admin_amenity_add_validation_group: "* グループを入力してください。",
      admin_amenity_add_validation_code: "* コードを入力してください。",
      admin_amenity_add_validation_name: "* 名前を入力してください。",
      admin_amenity_add_validation_icon_image: "* アイコン画像をアップロードしてください。",
      admin_amenity_add_label_type: "タイプ",
      admin_amenity_add_placeholder_select_box_choose: "選択",
      admin_amenity_add_select_hotel: "HOTEL",
      admin_amenity_add_select_room: "ROOM",
      admin_amenity_add_label_group: "グループ",
      admin_amenity_add_label_code: "コード",
      admin_amenity_add_label_name: "名前",
      admin_amenity_add_label_icon: "アイコン",
      admin_amenity_add_button_upload: "アップロード",
      admin_amenity_add_placeholder_upload: "ex) MyCONECT。png",
      admin_amenity_add_button_go_to_list: "リストへのアクセス",
      admin_amenity_add_button_add: "追加",
      admin_amenity_detail_title: "便宜施設情報",
      admin_amenity_detail_label_type: "タイプ",
      admin_amenity_detail_select_hotel: "HOTEL",
      admin_amenity_detail_select_room: "ROOM",
      admin_amenity_detail_label_group: "グループ",
      admin_amenity_detail_label_code: "コード",
      admin_amenity_detail_label_name: "名前",
      admin_amenity_detail_label_icon: "アイコン",
      admin_amenity_detail_button_upload: "アップロード",
      admin_amenity_detail_placeholder_upload: "ex) MyCONECT。png",
      admin_amenity_detail_label_update_date: "修正日",
      admin_amenity_detail_button_go_to_list: "リストへのアクセス",
      admin_amenity_detail_button_cancel: "キャンセル",
      admin_amenity_detail_button_delete: "削除",
      admin_amenity_detail_button_edit: "修整",
      admin_amenity_detail_button_ok: "確認",
      admin_amenity_edit_title: "便宜施設情報",
      admin_amenity_edit_validation_type: "タイプを選択してください。",
      admin_amenity_edit_validation_group: "グループを入力してください。",
      admin_amenity_edit_validation_code: "コードを入力してください。",
      admin_amenity_edit_validation_name: "名前を入力してください。",
      admin_amenity_edit_validation_icon_image: "アイコン画像をアップロードしてください。",
      admin_amenity_edit_label_type: "タイプ",
      admin_amenity_edit_placeholder_select_box_choose: "選択",
      admin_amenity_edit_select_hotel: "HOTEL",
      admin_amenity_edit_select_room: "ROOM",
      admin_amenity_edit_label_group: "グループ",
      admin_amenity_edit_label_code: "コード",
      admin_amenity_edit_label_name: "名前",
      admin_amenity_edit_label_icon: "アイコン",
      admin_amenity_edit_button_upload: "アップロード",
      admin_amenity_edit_placeholder_upload: "ex) MyCONECT。png",
      admin_amenity_edit_label_update_date: "修正日",
      admin_amenity_edit_button_cancel: "キャンセル",
      admin_amenity_edit_button_delete: "削除",
      admin_amenity_edit_button_ok: "確認",
      admin_facility_img_list_title: "ホテル施設の画像一覧",
      admin_facility_img_list_label_facility_img: "ホテル施設のイメージ",
      admin_facility_img_list_label_description: "説明",
      admin_facility_img_list_label_date: "日付",
      admin_facility_img_list_button_delete: "削除",
      admin_facility_img_list_button_add: "追加",
      admin_facility_img_list_guide_empty: "リストが空です",
      admin_facility_img_add_title: "ホテル施設の画像追加",
      admin_facility_img_add_validation_image: "画像をアップロードしてください。",
      admin_facility_img_add_validation_description: "説明を入力してください。",
      admin_facility_img_add_label_img: "イメージ",
      admin_facility_img_add_button_upload: "アップロード",
      admin_facility_img_add_label_description: "説明",
      admin_facility_img_add_button_go_to_list: "リストへのアクセス",
      admin_facility_img_add_button_add: "追加",
      admin_facility_img_detail_title: "ホテル施設の画像情報",
      admin_facility_img_detail_label_img: "イメージ",
      admin_facility_img_detail_button_upload: "アップロード",
      admin_facility_img_detail_label_description: "説明",
      admin_facility_img_detail_label_updated_at: "アップデート時間",
      admin_facility_img_detail_button_go_to_list: "リストへのアクセス",
      admin_facility_img_detail_button_cancel: "キャンセル",
      admin_facility_img_detail_button_delete: "削除",
      admin_facility_img_detail_button_edit: "修整",
      admin_facility_img_detail_button_ok: "確認",
      admin_facility_img_edit_title: "ホテル施設の画像情報",
      admin_facility_img_edit_validation_image: "画像をアップロードしてください。",
      admin_facility_img_edit_validation_description: "説明を入力してください。",
      admin_facility_img_edit_label_img: "イメージ",
      admin_facility_img_edit_button_upload: "アップロード",
      admin_facility_img_edit_label_description: "説明",
      admin_facility_img_edit_label_updated_at: "アップデート時間",
      admin_facility_img_edit_button_cancel: "キャンセル",
      admin_facility_img_edit_button_delete: "削除",
      admin_facility_img_edit_button_ok: "確認",
      admin_recommend_app_list_title: "アプリのおすすめリスト",
      admin_recommend_app_list_select_name: "名前",
      admin_recommend_app_list_placeholder_search: "検索",
      admin_recommend_app_list_label_name: "名前",
      admin_recommend_app_list_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_list_label_aos_package_id: "AOS パッケージ ID",
      admin_recommend_app_list_label_date: "日付",
      admin_recommend_app_list_button_delete: "削除",
      admin_recommend_app_list_button_add: "追加",
      admin_recommend_app_list_guide_empty: "リストが空です。",
      admin_recommend_app_add_title: "アプリのおすすめ追加",
      admin_recommend_app_add_validation_name: "名前を入力してください。",
      admin_recommend_app_add_validation_ios_scheme: "iOS Schemeを入力してください。",
      admin_recommend_app_add_validation_ios_itunes_url: "iOSiTunes URLを入力してください。",
      admin_recommend_app_add_validation_aos_package_id: "AOSパッケージIDを入力してください。",
      admin_recommend_app_add_validation_aos_play_store_url: "AOSプレイストアURLを入力してください。",
      admin_recommend_app_add_label_name: "名前",
      admin_recommend_app_add_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_add_label_ios_itunes_url: "iOS アイチューンズ URL",
      admin_recommend_app_add_label_aos_package_id: "AOS パッケージ ID",
      admin_recommend_app_add_label_aos_play_store_url: "AOSプレイストア URL",
      admin_recommend_app_add_button_go_to_list: "リストへのアクセス",
      admin_recommend_app_add_button_add: "追加",
      admin_recommend_app_detail_title: "アプリのおすすめ情報",
      admin_recommend_app_detail_label_name: "名前",
      admin_recommend_app_detail_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_detail_label_ios_itunes_url: "iOS アイチューンズ URL",
      admin_recommend_app_detail_label_aos_package_id: "AOS パッケージ ID",
      admin_recommend_app_detail_label_aos_play_store_url: "AOSプレイストア URL",
      admin_recommend_app_detail_label_updated_at: "アップデート時間",
      admin_recommend_app_detail_button_go_to_list: "リストへのアクセス",
      admin_recommend_app_detail_button_delete: "削除",
      admin_recommend_app_detail_button_edit: "修整",
      admin_recommend_app_edit_title: "アプリのおすすめ情報",
      admin_recommend_app_edit_validation_name: "名前を入力してください。",
      admin_recommend_app_edit_validation_ios_scheme: "iOS Schemeを入力してください。",
      admin_recommend_app_edit_validation_ios_itunes_url: "iOSiTunes URLを入力してください。",
      admin_recommend_app_edit_validation_aos_package_id: "AOSパッケージIDを入力してください。",
      admin_recommend_app_edit_validation_aos_play_store_url: "AOSプレイストアURLを入力してください。",
      admin_recommend_app_edit_label_name: "名前",
      admin_recommend_app_edit_label_ios_scheme: "iOS Scheme",
      admin_recommend_app_edit_label_ios_itunes_url: "iOS アイチューンズ URL",
      admin_recommend_app_edit_label_aos_package_id: "AOS パッケージ ID",
      admin_recommend_app_edit_label_aos_play_store_url: "AOSプレイストア URL",
      admin_recommend_app_editl_label_updated_at: "アップデート時間",
      admin_recommend_app_edit_button_cancel: "キャンセル",
      admin_recommend_app_edit_button_delete: "削除",
      admin_recommend_app_edit_button_ok: "確認",
      admin_admin_user_list_title: "ユーザーリスト",
      admin_admin_user_list_placeholder_select_box_user: "使用者",
      admin_admin_user_list_select_name: "名前",
      admin_admin_user_list_select_email: "Eメール",
      admin_admin_user_list_select_department: "部署",
      admin_admin_user_list_select_duty: "職務",
      admin_admin_user_list_placeholder_search: "検索",
      admin_admin_user_list_label_name: "名前",
      admin_admin_user_list_label_email: "Eメール",
      admin_admin_user_list_label_department: "部署",
      admin_admin_user_list_label_duty: "職務",
      admin_admin_user_list_label_date: "日付",
      admin_admin_user_list_guide_empty: "リストが空です",
      admin_admin_user_list_button_delete: "削除",
      admin_admin_user_list_button_add: "追加",
      admin_admin_user_add_title: "新しい管理者",
      admin_admin_user_add_guide_click_add: "情報入力後、「<追加>」ボタンを押してユーザーを追加してください。",
      admin_admin_user_add_validation_email: "ユーザーのメールアドレスを入力してください。",
      admin_admin_user_add_validation_email_format: "メールの形式に合わせて入力してください。",
      admin_admin_user_add_validation_password: "ユーザーのパスワードを入力してください。",
      admin_admin_user_add_validation_character_number_combining: "英文と数字を組み合わせて8文字以上入力してください。",
      admin_admin_user_add_validation_not_match: "パスワードが一致しません。",
      admin_admin_user_add_validation_name: "名前を入力してください。",
      admin_admin_user_add_validation_korea_english: "韓国語、英語、または .__ を使用して入力してください。",
      admin_admin_user_add_validation_department: "部署を入力してください。",
      admin_admin_user_add_validation_select_duty: "スタッフの担当業務を選択してください。",
      admin_admin_user_add_label_email: "Eメール",
      admin_admin_user_add_placeholder_email: "Eメール",
      admin_admin_user_add_label_name: "名前",
      admin_admin_user_add_placeholder_name: "名前",
      admin_admin_user_add_label_passoword: "パスワード",
      admin_admin_user_add_placeholder_password: "パスワード",
      admin_admin_user_add_label_confirm_passoword: "パスワード確認",
      admin_admin_user_add_placeholder_confirm_password: "パスワード確認",
      admin_admin_user_add_label_department: "部署",
      admin_admin_user_add_placeholder_department: "部署",
      admin_admin_user_add_label_duty: "担当業務",
      admin_admin_user_add_select_choose: "選択",
      admin_admin_user_add_select_super_admin: "最高管理者",
      admin_admin_user_add_select_admin: "管理者",
      admin_admin_user_add_select_user: "使用者",
      admin_admin_user_add_button_add: "追加",
      admin_admin_user_add_button_go_to_list: "リストへのアクセス",
      admin_admin_user_detail_title: "ユーザー情報",
      admin_admin_user_detail_select_user: "使用者",
      admin_admin_user_detail_select_admin: "管理者",
      admin_admin_user_detail_select_super_admin: "最高管理者",
      admin_admin_user_detail_label_admin: "管理者",
      admin_admin_user_detail_label_name: "名前",
      admin_admin_user_detail_label_department: "部署",
      admin_admin_user_detail_label_duty: "職務",
      admin_admin_user_detail_label_date: "日付",
      admin_admin_user_detail_button_delete: "削除",
      admin_admin_user_detail_button_go_to_list: "リストへのアクセス",
      admin_admin_user_detail_button_ok: "確認",
      admin_admin_user_edit_title: "管理者修正",
      admin_admin_user_edit_guide_edit_or_leave: "電子メールを除くすべての情報を修正できます。",
      admin_admin_user_edit_validation_name: "名前を入力してください。",
      admin_admin_user_edit_validation_department: "部署を入力してください。",
      admin_admin_user_edit_validation_korea_english: "韓国語、英語、または .__ を使用して入力してください。",
      admin_admin_user_edit_button_withdraw: "撤回",
      admin_admin_user_edit_button_save: "貯蔵",
      admin_id_recognition_information_list_title: "ID RECOGNITION INFORMATION LIST",
      admin_id_recognition_information_list_select_id_type_all: "ID Type ( ALL )",
      admin_id_recognition_information_list_select_id_type_registration: "Registration",
      admin_id_recognition_information_list_select_id_type_passport: "Passport",
      admin_id_recognition_information_list_select_id_type_driver_license: "Driver License",
      admin_id_recognition_information_list_placeholder_search: "Country Name",
      admin_id_recognition_information_list_label_country_name: "Country Name",
      admin_id_recognition_information_list_label_id_type: "Country Name",
      admin_id_recognition_information_list_label_date: "Date",
      admin_id_recognition_information_list_guide_empty: "List is empty",
      admin_id_recognition_information_list_button_delete: "Delete",
      admin_id_recognition_information_list_button_add: "Add",
      admin_id_recognition_information_add_title: "ADD ID RECOGNITION INFORMATION",
      admin_id_recognition_information_add_guide_click_add: "Enter ID Recognition Information and click the <<'Add'>> button.",
      admin_id_recognition_information_add_label_country_name: "Country Name",
      admin_id_recognition_information_add_placeholder_country_name: "Country Name",
      admin_id_recognition_information_add_label_id_type: "ID Type",
      admin_id_recognition_information_add_placeholder_select_box_choose: "Choose",
      admin_id_recognition_information_add_select_id_type_registration: "Registration",
      admin_id_recognition_information_add_select_id_type_passport: "Passport",
      admin_id_recognition_information_add_select_id_type_driver_license: "Driver License",
      admin_id_recognition_information_add_label_recognized_texts: "Recongnized Texts",
      admin_id_recognition_information_add_placeholder_recognized_texts: "ex) REPUBLIC OF KOREA, Passport No., Surname, Given names, Date of birth, ...",
      admin_id_recognition_information_add_validation_country_name: "* Please enter the country name.",
      admin_id_recognition_information_add_validation_id_type: "* Please enter _x0008_a ID type.",
      admin_id_recognition_information_add_validation_recognized_texts: "* Enter recognition texts using ,.",
      admin_id_recognition_information_add_button_go_to_list: "Go to list",
      admin_id_recognition_information_add_button_add: "Add",
      admin_id_recognition_information_detail_title: "ID RECOGNITION INFORMATION",
      admin_id_recognition_information_detail_label_country_name: "Country Name",
      admin_id_recognition_information_detail_label_id_type: "ID Type",
      admin_id_recognition_information_detail_label_recognized_texts: "Recongnized Texts",
      admin_id_recognition_information_detail_label_created_at: "Created At",
      admin_id_recognition_information_detail_label_updated_at: "Updated At",
      admin_id_recognition_information_detail_button_go_to_list: "Go to List",
      admin_id_recognition_information_detail_button_edit: "Edit",
      admin_id_recognition_information_edit_title: "ID RECOGNITION INFORMATION",
      admin_id_recognition_information_edit_label_country_name: "Country Name",
      admin_id_recognition_information_edit_placeholder_country_name: "Country Name",
      admin_id_recognition_information_edit_label_id_type: "ID Type",
      admin_id_recognition_information_edit_placeholder_select_box_choose: "Choose",
      admin_id_recognition_information_edit_select_id_type_registration: "Registration",
      admin_id_recognition_information_edit_select_id_type_passport: "Passport",
      admin_id_recognition_information_edit_select_id_type_driver_license: "Driver License",
      admin_id_recognition_information_edit_label_recognized_texts: "Recongnized Texts",
      admin_id_recognition_information_edit_placeholder_recognized_texts: "ex) REPUBLIC OF KOREA, Passport No., Surname, Given names, Date of birth, ...",
      admin_id_recognition_information_edit_validation_country_name: "* Please enter the country name.",
      admin_id_recognition_information_edit_validation_id_type: "* Please enter _x0008_a ID type.",
      admin_id_recognition_information_edit_validation_recognized_texts: "* Enter recognition texts using ,.",
      admin_id_recognition_information_edit_label_updated_at: "Updated At",
      admin_id_recognition_information_edit_button_cancel: "Cancel",
      admin_id_recognition_information_edit_button_ok: "OK",
      admin_statistics_list_title: "STATISTICS LIST",
      admin_statistics_list_placeholder_select_box_choose: "Choose (All)",
      admin_statistics_list_placeholder_search: "検索",
      admin_statistics_list_label_date: "日付",
      admin_statistics_list_label_reservation: "予約",
      admin_statistics_list_label_walk_in: "Walk-In",
      admin_statistics_list_label_check_in: "チェックイン",
      admin_statistics_list_label_checked_out: "チェックアウトされた",
      admin_statistics_list_button_go_to_statistics: "Go to Statistics",
      hotel_header_link_password: "パスワード",
      hotel_header_link_logout: "ログアウト",
      hotel_header_accordion_hotel: "ホテル",
      hotel_header_link_hotel_information: "情報",
      hotel_header_link_hotel_beacon: "ビーコン",
      hotel_header_link_hotel_currency: "通貨",
      hotel_header_link_hotel_setting: "設定",
      hotel_header_link_hotel_detail: "ホテルの詳細情報",
      hotel_header_link_hotel_image: "ホテルのイメージ",
      hotel_header_accordion_room: "客室",
      hotel_header_link_room: "客室",
      hotel_header_link_room_detail: "客室の詳細情報",
      hotel_header_link_room_image: "客室のイメージ",
      hotel_header_accordion_room_management: "部屋の管理",
      hotel_header_link_room_list: "部屋一覧",
      hotel_header_link_room_management_setting: "設定",
      hotel_header_accordion_package: "パッケージ",
      hotel_header_link_package_package: "パッケージ",
      hotel_header_link_package_package_detail: "パッケージ 詳細情報",
      hotel_header_accordion_member: "会員",
      hotel_header_link_member_member: "会員",
      hotel_header_link_member_non_member: "Non-Member",
      hotel_header_accordion_reservation: "予約",
      hotel_header_link_reservation_reservation: "予約",
      hotel_header_link_reservation_future_booking: "未来の予約",
      hotel_header_link_reservation_waiting_room: "部屋割りの待機",
      hotel_header_accordion_reservation_policy: "予約ポリシー",
      hotel_header_link_reservation_rule_setting_deposit_rule: "敷金規則",
      hotel_header_link_reservation_rule_setting_reservation_rule: "予約規則",
      hotel_header_link_reservation_rule_setting_cancellation_rule: "キャンセル規則",
      hotel_header_link_reservation_rule_setting_no_show_rule: "ノーショー規則",
      hotel_header_link_reservation_rule_setting_rule_schedule: "ルール·スケジュール",
      hotel_header_accordion_payment: "決済",
      hotel_header_link_payment_history: "内訳",
      hotel_header_link_payment_setting: "設定",
      hotel_header_accordion_service: "サービス",
      hotel_header_link_service_hotel_info: "ホテル情報",
      hotel_header_accordion_concierge: "コンシェルジュ",
      hotel_header_link_service_concierge_request: "リクエスト(確認前)",
      hotel_header_link_service_concierge_process: "リクエスト (確認後)",
      hotel_header_link_service_concierge_schedule_process: "スケジュール処理結果",
      hotel_header_link_service_concierge_schedule: "スケジュール設定",
      hotel_header_link_service_concierge_business_rule: "業務規則の設定",
      hotel_header_link_service_concierge_boundary_line: "制限設定",
      hotel_header_accordion_housekeeping: "ハウスキーピング",
      hotel_header_link_service_housekeeping_request: "リクエスト(確認前)",
      hotel_header_link_service_housekeeping_process: "リクエスト (確認後)",
      hotel_header_link_service_housekeeping_schedule_process: "スケジュール処理結果",
      hotel_header_link_service_housekeeping_schedule: "スケジュール設定",
      hotel_header_link_service_housekeeping_business_rule: "業務規則の設定",
      hotel_header_link_service_housekeeping_boundary_line: "制限設定",
      hotel_header_link_service_setting_amenity: "アメニティ設定",
      hotel_header_link_service_housekeeping_setting: "設定",
      hotel_header_accordion_in_room_dining: "イン·ルーム·ダイニング",
      hotel_header_link_service_in_room_dining_request: "リクエスト(確認前)",
      hotel_header_link_service_in_room_dining_process: "リクエスト (確認後)",
      hotel_header_link_service_in_room_dining_schedule_process: "スケジュール処理結果",
      hotel_header_link_service_in_room_dining_schedule: "スケジュール設定",
      hotel_header_link_service_in_room_dining_business_rule: "業務規則の設定",
      hotel_header_link_service_in_room_dining_boundary_line: "制限設定",
      hotel_header_link_service_in_room_dining_cart: "買い物かご",
      hotel_header_link_service_in_room_dining_category: "カテゴリー",
      hotel_header_link_service_in_room_dining_menu_setting: "メニュー設定",
      hotel_header_link_service_in_room_dining_setting: "設定",
      hotel_header_accordion_valet: "バレットパーキング",
      hotel_header_link_service_valet_process: "要請",
      hotel_header_link_service_valet_schedule: "スケジュール設定",
      hotel_header_link_service_valet_business_rule: "業務規則の設定",
      hotel_header_link_service_valet_parking_setting: "設定",
      hotel_header_accordion_self: "セルフパーキング",
      hotel_header_link_service_self_parking_setting: "設定",
      hotel_header_link_service_hotel_inhouse_menu_setting: "インハウスメニュー設定",
      hotel_header_link_service_hotel_inhouse_menu_description: "サービス説明",
      hotel_header_accordion_key: "身長",
      hotel_header_link_key_room_to_room: "ルームトゥルーム",
      hotel_header_link_key_guest_key: "ゲスト·キー",
      hotel_header_link_key_staff_key: "スタッフ·キー",
      hotel_header_link_key_share_key: "共有キー",
      hotel_header_link_key_help_for_mobile_key: "モバイルキーヘルプ",
      hotel_header_link_key_staff_key_link: "リンク",
      hotel_header_link_key_access: "アクセス権限設定",
      hotel_header_link_key_access_detail: "アクセス権限の詳細情報設定",
      hotel_header_link_key_key_setting: "設定",
      hotel_header_link_key_reservation_access: "アクセス権限リンク",
      hotel_header_accordion_pms: "PMS",
      hotel_header_link_pms_title: "呼称",
      hotel_header_link_pms_card_type: "カードタイプ",
      hotel_header_link_pms_membership_type: "会員権タイプ",
      hotel_header_link_pms_fias_manager: "FIASマネージャー",
      hotel_header_link_pms_notification_callback: "通知コールバック",
      hotel_header_link_pms_setting: "設定",
      hotel_header_accordion_room_controller: "客室制御",
      hotel_header_link_room_controller_info: "情報",
      hotel_header_link_room_controller_setting: "設定",
      hotel_header_accordion_document: "文書",
      hotel_header_accordion_document_email: "Eメール",
      hotel_header_accordion_event: "イベント",
      hotel_header_link_event_event: "イベント",
      hotel_header_link_event_event_category: "イベントカテゴリー",
      hotel_header_link_email_reservation: "予約",
      hotel_header_link_email_reservation_change: "予約変更",
      hotel_header_link_email_reservation_cancel: "予約キャンセル",
      hotel_header_link_email_no_show: "ノーショー",
      hotel_header_link_email_reservation_pack_add: "予約パッケージの追加",
      hotel_header_link_email_reservation_pack_delete: "予約パッケージ削除",
      hotel_header_link_email_reservation_accom_add: "予約同伴者追加",
      hotel_header_link_email_reservation_accom_delete: "予約同伴者削除",
      hotel_header_link_email_reservation_accom_email: "同伴者予約Eメール",
      hotel_header_link_email_reservation_accom_change: "同伴者の予約変更",
      hotel_header_link_email_reservation_accom_cancel: "同伴者の予約キャンセル",
      hotel_header_link_email_reservation_folio_email: "ポリオ·メール",
      hotel_header_link_email_reservation_enroll_profile: "登録プロファイル",
      hotel_header_accordion_document_pdf: "PDF",
      hotel_header_link_pdf_reservation: "予約PDF",
      hotel_header_link_pdf_reservation_accom_pdf: "同伴者予約PDF",
      hotel_header_link_pdf_folio_pdf: "ポリオ PDF",
      hotel_header_accordion_terms_and_conditions: "利用規約",
      hotel_header_link_terms_and_conditions_terms: "弱冠",
      hotel_header_link_terms_and_conditions_agreement: "同意内訳",
      hotel_header_current_version: "現在のバージョン",
      hotel_header_translation_version: "翻訳バージョン",
      hotel_header_link_staff_user: "スタッフ使用者",
      hotel_header_link_app_download: "アプリのインストール",
      hotel_password_title: "パスワード変更",
      hotel_password_guide_password: "8 桁以上の新しいパスワードを入力してください。",
      hotel_password_validation_current_password: "* 現在のパスワードを入力してください。",
      hotel_password_validation_character_number_combining: "* 英文と数字を組み合わせて8文字以上入力してください。",
      hotel_password_validation_new_password: "* 新しいパスワードを入力してください。",
      hotel_password_validation_new_password_again: "* 新しいパスワードを再入力してください。",
      hotel_password_validation_not_match: "* パスワードが一致しません。",
      hotel_password_placeholder_password: "パスワード",
      hotel_password_placeholder_new_password: "新しいパスワード",
      hotel_password_placeholder_new_confirm_password: "新しいパスワード確認",
      hotel_password_button_save: "貯蔵",
      hotel_hotel_info_detail_title: "ホテル情報",
      hotel_hotel_info_detail_label_hotel_no: "ホテルの番号",
      hotel_hotel_info_detail_label_name: "ホテル名",
      hotel_hotel_info_detail_label_address: "住所",
      hotel_hotel_info_detail_label_region: "地域",
      hotel_hotel_info_detail_label_country: "国家",
      hotel_hotel_info_detail_label_city: "都市",
      hotel_hotel_info_detail_label_logo: "ロゴ",
      hotel_hotel_info_detail_label_hotel_photo: "ホテル",
      hotel_hotel_info_detail_label_hotel_map: "地図",
      hotel_hotel_info_detail_button_edit: "修整",
      hotel_hotel_info_edit_title: "ホテル情報",
      hotel_hotel_info_edit_label_hotel_no: "ホテルの番号",
      hotel_hotel_info_edit_label_name: "ホテル名",
      hotel_hotel_info_edit_label_address: "住所",
      hotel_hotel_info_edit_label_region: "地域",
      hotel_hotel_info_edit_label_country: "国家",
      hotel_hotel_info_edit_label_city: "都市",
      hotel_hotel_info_edit_label_logo: "ロゴ",
      hotel_hotel_info_edit_button_upload: "アップロード",
      hotel_hotel_info_edit_placeholder_upload: "ex) MyCONECT。png",
      hotel_hotel_info_edit_label_hotel_photo: "ホテルの写真",
      hotel_hotel_info_edit_label_hotel_map: "地図",
      hotel_hotel_info_edit_button_search: "検索",
      hotel_hotel_info_edit_button_ok: "確認",
      hotel_hotel_info_edit_validation_hotel_name: "* ホテル名を入力してください。",
      hotel_hotel_info_edit_validation_hotel_main_tel: "* ホテルの代表電話番号を入力してください。",
      hotel_hotel_info_edit_validation_hotel_service_tel: "* ホテルサービスの電話番号を入力してください。",
      hotel_hotel_info_edit_validation_hotel_address: "* 住所を入力してください。",
      hotel_hotel_pms_detail_title: "設定",
      hotel_hotel_pms_detail_label_date: "アップデートの日付",
      hotel_hotel_pms_detail_label_type: "タイプ",
      hotel_hotel_pms_detail_label_cloud_flag: "クラウド可否",
      hotel_hotel_pms_detail_label_server_url: "サーバーURL",
      hotel_hotel_pms_detail_label_origin_entity_id: "Origin Entity ID",
      hotel_hotel_pms_detail_label_origin_system_type: "Origin System Type",
      hotel_hotel_pms_detail_label_destination_entity_id: "Destination Entity ID",
      hotel_hotel_pms_detail_label_destination_system_type: "Destination System Type",
      hotel_hotel_pms_detail_label_user_name: "ユーザー名",
      hotel_hotel_pms_detail_label_user_password: "ユーザーパスワード",
      hotel_hotel_pms_detail_label_domain: "ドメイン",
      hotel_hotel_pms_detail_label_hotel_code: "Hotel Code",
      hotel_hotel_pms_detail_label_chain_code: "Chain Code",
      hotel_hotel_pms_detail_label_reservation_transaction_code_is_credit: "Reservation Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_cancellation_transaction_code_is_credit: "Cancellation Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_deposit_transaction_code_is_credit: "Deposit Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_noshow_transaction_code_is_credit: "No-Show Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_service_fee_transaction_code_is_credit: "Service Fee Transaction Code Is Credit",
      hotel_hotel_pms_detail_label_default_reservation_transaction_code: "Default Reservation Transaction Code",
      hotel_hotel_pms_detail_label_default_cancellation_transaction_code: "Default Cancellation Transaction Code",
      hotel_hotel_pms_detail_label_default_deposit_transaction_code: "Default Deposit Transaction Code",
      hotel_hotel_pms_detail_label_default_noshow_transaction_code: "Default No-Show Transaction Code",
      hotel_hotel_pms_detail_label_default_service_fee_transaction_code: "Default Service Fee Transaction Code",
      hotel_hotel_pms_detail_label_cancellation_debit_transaction_code: "Cancellation Debit Transaction Code",
      hotel_hotel_pms_detail_label_noshow_debit_transaction_code: "No-Show Debit Transaction Code",
      hotel_hotel_pms_detail_label_market_code: "Market Code",
      hotel_hotel_pms_detail_label_future_booking: "Future Booking",
      hotel_hotel_pms_detail_label_walk_in: "Walk-In",
      hotel_hotel_pms_detail_label_web_booking: "Web Booking",
      hotel_hotel_pms_detail_label_ota_booking: "OTA Booking",
      hotel_hotel_pms_detail_label_origin_code: "Origin Code",
      hotel_hotel_pms_detail_label_rate_code: "Rate Code",
      hotel_hotel_pms_detail_label_yes: "Yes",
      hotel_hotel_pms_detail_label_no: "No",
      hotel_hotel_pms_detail_label_earliest_checkin_time: "最速のチェックイン時間",
      hotel_hotel_pms_detail_label_dummy_pms_profile_id: "ダミーPMSプロフィールID",
      hotel_hotel_pms_detail_button_go_to_info: "情報に行く",
      hotel_hotel_pms_detail_button_edit: "修整",
      hotel_hotel_pms_edit_opera: "オペラ",
      hotel_hotel_pms_edit_planyo: "プランヨー",
      hotel_hotel_pms_edit_title: "設定",
      hotel_hotel_pms_edit_label_date: "アップデートの日付",
      hotel_hotel_pms_edit_label_type: "タイプ",
      hotel_hotel_pms_edit_label_cloud_flag: "クラウド可否",
      hotel_hotel_pms_edit_label_server_url: "サーバーURL",
      hotel_hotel_pms_edit_label_origin_entity_id: "Origin Entity ID",
      hotel_hotel_pms_edit_label_origin_system_type: "既存のシステムタイプ",
      hotel_hotel_pms_edit_label_destination_entity_id: "Destination Entity ID",
      hotel_hotel_pms_edit_label_destination_system_type: "Destination System Type",
      hotel_hotel_pms_edit_label_user_name: "ユーザー名",
      hotel_hotel_pms_edit_label_user_password: "ユーザーパスワード",
      hotel_hotel_pms_edit_label_domain: "ドメイン",
      hotel_hotel_pms_edit_label_hotel_code: "Hotel Code",
      hotel_hotel_pms_edit_label_chain_code: "Chain Code",
      hotel_hotel_pms_edit_label_reservation_transaction_code_is_credit: "Reservation Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_cancellation_transaction_code_is_credit: "Cancellation Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_deposit_transaction_code_is_credit: "Deposit Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_noshow_transaction_code_is_credit: "No-Show Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_service_fee_transaction_code_is_credit: "Service Fee Transaction Code Is Credit",
      hotel_hotel_pms_edit_label_default_reservation_transaction_code: "Default Reservation Transaction Code",
      hotel_hotel_pms_edit_label_default_cancellation_transaction_code: "Default Cancellation Transaction Code",
      hotel_hotel_pms_edit_label_default_deposit_transaction_code: "Default Deposit Transaction Code",
      hotel_hotel_pms_edit_label_default_noshow_transaction_code: "Default No-Show Transaction Code",
      hotel_hotel_pms_edit_label_default_service_fee_transaction_code: "Default Service Fee Transaction Code",
      hotel_hotel_pms_edit_label_cancellation_debit_transaction_code: "Cancellation Debit Transaction Code",
      hotel_hotel_pms_edit_label_noshow_debit_transaction_code: "No-Show Debit Transaction Code",
      hotel_hotel_pms_edit_label_market_code: "Market Code",
      hotel_hotel_pms_edit_label_future_booking: "Future Booking",
      hotel_hotel_pms_edit_label_walk_in: "Walk-In",
      hotel_hotel_pms_edit_label_web_booking: "Web Booking",
      hotel_hotel_pms_edit_label_ota_booking: "OTA Booking",
      hotel_hotel_pms_edit_label_origin_code: "Origin Code",
      hotel_hotel_pms_edit_label_rate_code: "Rate Code",
      hotel_hotel_pms_edit_label_yes: "Yes",
      hotel_hotel_pms_edit_label_no: "No",
      hotel_hotel_pms_edit_button_cancel: "キャンセル",
      hotel_hotel_pms_edit_button_ok: "確認",
      hotel_hotel_pms_edit_validation_server_url: "* サーバーURLを入力してください。",
      hotel_hotel_pms_edit_validation_origin_entity_id: "* Origin Entity IDを入力してください。",
      hotel_hotel_pms_edit_validation_origin_system_type: "* Origin System Typeを入力してください。",
      hotel_hotel_pms_edit_validation_destination_entity_id: "* Destination Entity IDを入力してください。",
      hotel_hotel_pms_edit_validation_destination_system_type: "* Destination System Typeを入力してください。",
      hotel_hotel_pms_edit_validation_user_name: "* ユーザー名を入力してください。",
      hotel_hotel_pms_edit_validation_user_password: "* ユーザーのパスワードを入力してください。",
      hotel_hotel_pms_edit_validation_domain: "* ドメインを入力してください。",
      hotel_hotel_setting_detail_title: "設定",
      hotel_hotel_setting_detail_label_date: "アップデートの日付",
      hotel_hotel_setting_detail_label_room_control: "客室制御",
      hotel_hotel_setting_detail_label_room_control_ip: "客室制御IP",
      hotel_hotel_setting_detail_label_room_control_port: "客室制御ポート",
      hotel_hotel_setting_detail_label_fias: "FIAS",
      hotel_hotel_setting_detail_label_notification_callback: "通知コールバック",
      hotel_hotel_setting_detail_label_fias_hotel_id: "ホテルID",
      hotel_hotel_setting_detail_label_fias_auth_code: "認証コード",
      hotel_hotel_setting_detail_label_payment: "決済",
      hotel_hotel_setting_detail_label_payment_auth_code: "認証コード",
      hotel_hotel_setting_detail_label_store_id: "ストアID",
      hotel_hotel_setting_detail_label_store_key: "ストア·キー",
      hotel_hotel_setting_detail_label_store_api_id: "ストア API ID",
      hotel_hotel_setting_detail_label_payment_salt: "決済セキュリティ任意データ",
      hotel_hotel_setting_detail_label_payment_token_password: "決済トークンパスワード",
      hotel_hotel_setting_detail_label_secure_hash_secret: "Secure Hash Secret",
      hotel_hotel_setting_detail_label_payment_url: "決済URL",
      hotel_hotel_setting_detail_label_refund_url: "返金URL",
      hotel_hotel_setting_detail_label_payment_type: "決済タイプ",
      hotel_hotel_setting_detail_placeholder_select_box_choose: "選択",
      hotel_hotel_setting_detail_select_nicepay: "Nicepay",
      hotel_hotel_setting_detail_select_asiapay: "Asiapay",
      hotel_hotel_setting_detail_label_booking_payment_using_myconect_flag: "ご予約の際、MyCONECT決済をご利用になるかどうか",
      hotel_hotel_setting_detail_label_checkin_payment_using_myconect_flag: "チェックインの際、MyCONECT決済の可否",
      hotel_hotel_setting_detail_label_walkin_payment_using_myconect_flag: "ウォークイン時 MyCONECT 決済使用可否",
      hotel_hotel_setting_detail_label_noshow_payment_using_myconect_flag: "ノーショーの際、MyCONECT決済の可否",
      hotel_hotel_setting_detail_label_checkout_payment_using_myconect_flag: "チェックアウト時にMyCONECT決済をご利用ください",
      hotel_hotel_setting_detail_label_cancel_booking_payment_using_myconect_flag: "予約をキャンセルする場合、MyCONECT決済が使用されるかどうか",
      hotel_hotel_setting_detail_label_need_card_information_for_booking: "予約の際、カード情報が必要かどうか",
      hotel_hotel_setting_detail_label_need_card_information_for_checkin: "チェックインの際、カード情報が必要かどうか",
      hotel_hotel_setting_detail_label_need_card_information_for_walkin: "ウォークイン時にカード情報が必要かどうか",
      hotel_hotel_setting_detail_label_is_direct_payable: "トークン情報でダイレクトペイを使用するかどうか",
      hotel_hotel_setting_detail_label_is_card_tokenizable: "カード情報トークン化使用可否",
      hotel_hotel_setting_detail_label_min_card_expiry_days_post_checkout: "チェックアウト後のカード有効期限",
      hotel_hotel_setting_detail_label_time: "時間",
      hotel_hotel_setting_detail_label_early_check_in: "アーリー·チェックイン",
      hotel_hotel_setting_detail_label_assign_room_time: "客室割り当て時間(時間:分)",
      hotel_hotel_setting_detail_label_do_not_disturb_time: "妨害禁止要請時間(時間:分)",
      hotel_hotel_setting_detail_label_time_zone: "時間帯",
      hotel_hotel_setting_detail_label_tell: "電話番号",
      hotel_hotel_setting_detail_label_hotel_main_tel: "ホテルの代表電話番号",
      hotel_hotel_setting_detail_label_hotel_service_tel: "ホテルサービスの電話番号",
      hotel_hotel_setting_detail_label_setting: "設定",
      hotel_hotel_setting_detail_label_dnd: "DND",
      hotel_hotel_setting_detail_label_dnd_status: "DND 状態 ユーザー 確認 お知らせ 期間 (時間:分)",
      hotel_hotel_setting_detail_label_dnd_relese: "サービス通知時間に対するDNDリリース事前リクエスト(分)",
      hotel_hotel_setting_detail_button_yes: "例",
      hotel_hotel_setting_detail_button_no: "いいえ",
      hotel_hotel_setting_detail_button_refresh: "リロード",
      hotel_hotel_setting_detail_label_member_number_check: "顧客人数確認",
      hotel_hotel_setting_detail_label_passport: "パスポート確認モジュール",
      hotel_hotel_setting_detail_select_detion: "Detion",
      hotel_hotel_setting_detail_select_inzi_soft: "Inzi Soft",
      hotel_hotel_setting_detail_label_accompany_local_check: "同伴のお客様(韓国人)の身分証明書スキャン",
      hotel_hotel_setting_detail_label_accompany_foreigner_check: "同伴のお客様(外国人)の身分証明書スキャン",
      hotel_hotel_setting_detail_label_room_number: "客室番号表示",
      hotel_hotel_setting_detail_label_hotel_logo: "ホテルのロゴ表示",
      hotel_hotel_setting_detail_label_member_lock_count: "会員ロック回数",
      hotel_hotel_setting_detail_label_payment_lock_count: "決済ロック回数",
      hotel_hotel_setting_detail_label_staff_login_lock_count: "スタッフログインロック回数",
      hotel_hotel_setting_detail_label_default_currency: "基本通貨",
      hotel_hotel_setting_detail_label_percent_for_hotel_charge: "ホテル料金比率(%)",
      hotel_hotel_setting_detail_label_language_code: "言語コード",
      hotel_hotel_setting_detail_label_alternative_name_code: "ハングルの姓名コード",
      hotel_hotel_setting_detail_label_floor: "層",
      hotel_hotel_setting_detail_label_out_of_order_check: "客室不良の確認",
      hotel_hotel_setting_detail_label_password_change_day: "パスワード変更日(日)",
      hotel_hotel_setting_detail_label_password_change_notice: "パスワード変更のお知らせ期間(日)",
      hotel_hotel_setting_detail_label_occupy_minutes: "占拠時間(分)",
      hotel_hotel_setting_detail_label_reservation_charge_waiting_minutes: "予約料金待ち時間(分)",
      hotel_hotel_setting_detail_label_beacon_check_hours: "ビーコン確認時間(時)",
      hotel_hotel_setting_detail_label_compare_faces_flag: "Compare Faces for Foreigner Flag",
      hotel_hotel_setting_detail_label_compare_face_for_local_flag: "Compare Face for Local Flag",
      hotel_hotel_setting_detail_label_singapore_government_eva_flag: "シンガポールEVA使用",
      hotel_hotel_setting_detail_label_singapore_government_eva_hotel_code: "シンガポールEVAホテルコード",
      hotel_hotel_setting_detail_label_date_reservation_flag: "予約の際に日付選択の有無",
      hotel_hotel_setting_detail_label_time_reservation_flag: "予約の際、時間選択の有無",
      hotel_hotel_setting_detail_label_transit_hotel_flag: "経由ホテルかどうか",
      hotel_hotel_setting_detail_label_request_make_up_room_at_checkout: "チェックアウトの際は客室清掃を希望するかどうか",
      hotel_hotel_setting_detail_label_show_rate_for_ota_flag: "Show Rate for OTA Flag",
      hotel_hotel_setting_detail_button_edit: "修整",
      hotel_hotel_setting_detail_button_cancel: "キャンセル",
      hotel_hotel_setting_edit_title: "設定",
      hotel_hotel_setting_edit_label_date: "日付更新",
      hotel_hotel_setting_edit_label_room_control: "客室制御",
      hotel_hotel_setting_edit_label_room_control_ip: "客室制御IP",
      hotel_hotel_setting_edit_placeholder_room_control_ip: "ex) 127.0.0.1",
      hotel_hotel_setting_edit_label_room_control_port: "客室制御ポート",
      hotel_hotel_setting_edit_placeholder_room_control_port: "ex) 80",
      hotel_hotel_setting_edit_label_payment: "決済",
      hotel_hotel_setting_edit_label_store_id: "ストアID",
      hotel_hotel_setting_edit_placeholder_store_id: "ストアID",
      hotel_hotel_setting_edit_label_payment_url: "決済URL",
      hotel_hotel_setting_edit_placeholder_payment_url: "決済URL",
      hotel_hotel_setting_edit_label_refund_url: "返金URL",
      hotel_hotel_setting_edit_placeholder_refund_url: "返金URL",
      hotel_hotel_setting_edit_label_time: "時間",
      hotel_hotel_setting_edit_label_assign_room_time: "客室の割り当て時間",
      hotel_hotel_setting_edit_placeholder_assign_room_time: "客室の割り当て時間",
      hotel_hotel_setting_edit_label_do_not_disturb_time: "DNDリクエスト時間",
      hotel_hotel_setting_edit_placeholder_do_not_disturb_time: "DNDリクエスト時間",
      hotel_hotel_setting_edit_label_time_zone: "時間帯",
      hotel_hotel_setting_edit_placeholder_time_zone: "時間帯",
      hotel_hotel_setting_edit_label_tell: "電話番号",
      hotel_hotel_setting_edit_label_hotel_main_tel: "ホテルの代表電話番号",
      hotel_hotel_setting_edit_placeholder_hotel_main_tel: "ホテルの代表電話番号",
      hotel_hotel_setting_edit_label_hotel_service_tel: "ホテルサービスの電話番号",
      hotel_hotel_setting_edit_placeholder_hotel_service_tel: "ホテルサービスの電話番号",
      hotel_hotel_setting_edit_label_setting: "設定",
      hotel_hotel_setting_edit_button_yes: "例",
      hotel_hotel_setting_edit_button_no: "いいえ",
      hotel_hotel_setting_edit_label_member_number_check: "顧客人数確認",
      hotel_hotel_setting_edit_label_accompany_local_check: "同伴のお客様(韓国人)の身分証明書スキャン",
      hotel_hotel_setting_edit_label_accompany_foreigner_check: "同伴のお客様(外国人)の身分証明書スキャン",
      hotel_hotel_setting_edit_label_room_number: "客室番号表示",
      hotel_hotel_setting_edit_label_hotel_logo: "ホテルのロゴ表示",
      hotel_hotel_setting_edit_label_member_lock_count: "会員ロック回数",
      hotel_hotel_setting_edit_placeholder_member_lock_count: "会員ロック回数",
      hotel_hotel_setting_edit_label_payment_lock_count: "決済ロック回数",
      hotel_hotel_setting_edit_placeholder_payment_lock_count: "決済ロック回数",
      hotel_hotel_setting_edit_label_default_currency: "基本通貨",
      hotel_hotel_setting_edit_placeholder_default_currency: "基本通貨",
      hotel_hotel_setting_edit_label_percent_for_hotel_charge: "ホテル料金の比率",
      hotel_hotel_setting_edit_placeholder_percent_for_hotel_charge: "ホテル料金の比率",
      hotel_hotel_setting_edit_label_language_code: "言語コード",
      hotel_hotel_setting_edit_placeholder_language_code: "言語コード",
      hotel_hotel_setting_edit_label_alternative_name_code: "ハングルの姓名コード",
      hotel_hotel_setting_edit_placeholder_alternative_name_code: "ハングルの姓名コード",
      hotel_hotel_setting_edit_label_floor: "層",
      hotel_hotel_setting_edit_placeholder_floor: "層",
      hotel_hotel_setting_edit_button_go_to_info: "情報に行く",
      hotel_hotel_setting_edit_button_cancel: "キャンセル",
      hotel_hotel_setting_edit_button_ok: "確認",
      hotel_hotel_setting_edit_button_refresh: "リロード",
      hotel_hotel_setting_edit_validation_assign_room_time: "* 客室の割り当て時間を入力してください。",
      hotel_hotel_setting_edit_validation_time_zone: "* 時間帯を選択してください。",
      hotel_hotel_setting_edit_validation_member_lock_count: "* 会員ロック回数を入力してください。",
      hotel_hotel_setting_edit_validation_payment_lock_count: "* 決済ロック回数を入力してください。",
      hotel_hotel_setting_edit_validation_staff_lock_count: "* スタッフのログインロック回数を入力してください。",
      hotel_hotel_setting_edit_validation_default_currency: "* 基本通貨を入力してください。",
      hotel_hotel_setting_edit_validation_language_code: "* 言語コードを入力してください。",
      hotel_hotel_setting_edit_validation_alternative_name_code: "* ハングルの姓名コードを入力してください。",
      hotel_hotel_setting_edit_validation_floors: "* 階を入力してください。",
      hotel_hotel_setting_edit_validation_password_change_day: "* パスワードの変更日を入力してください。",
      hotel_hotel_setting_edit_validation_password_change_notice_period: "* パスワード変更通知期間を入力してください。",
      hotel_hotel_setting_edit_validation_occupy_minutes: "* 占拠時間を入力してください。",
      hotel_hotel_setting_edit_validation_reservation_charge_waiting_minutes: "* 予約料金の待ち時間を入力してください。",
      hotel_hotel_setting_edit_validation_beacon_check_hours: "* ビーコン確認時間を入力してください。",
      hotel_hotel_setting_edit_validation_gov_sg_eva_hotel_code: "* シンガポールEVAホテルコードを入力してください。",
      hotel_hotel_setting_edit_validation_store_id: "* ストアIDを入力してください。",
      hotel_hotel_setting_edit_validation_store_key: "* ストアキーを入力してください。",
      hotel_hotel_setting_edit_validation_payment_url: "* 決済URLを入力してください。",
      hotel_hotel_setting_edit_validation_refund_url: "* 払い戻しURLを入力してください。",
      hotel_hotel_setting_edit_validation_payment_type: "* 決済タイプを選択してください。",
      hotel_hotel_setting_edit_validation_payment_salt: "* 決済salt情報を入力してください。",
      hotel_hotel_setting_edit_validation_payment_token_password: "* 決済トークンのパスワードを入力してください。",
      hotel_hotel_setting_edit_validation_secure_hash_secret: "* Please enter the secure hash secret.",
      hotel_hotel_setting_edit_validation_booking_payment_using_myconect_flag: "* ご予約の際、MyCONECT決済をご使用するかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_checkin_payment_using_myconect_flag: "* チェックインの際はMyCONECT決済をご利用いただくかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_walkin_payment_using_myconect_flag: "* ウォークイン時にMyCONECT決済を使用するかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_noshow_payment_using_myconect_flag: "* ノーショーの際MyCONECT決済をご利用になるかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_checkout_payment_using_myconect_flag: "* チェックアウトの際 MyCONECT決済をご利用になるかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_cancel_booking_payment_using_myconect_flag: "* 予約をキャンセルする場合、My CONECT決済を使用するかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_need_card_information_for_booking: "* ご予約の際、カード情報が必要かどうかを選択してください。",
      hotel_hotel_setting_edit_validation_need_card_information_for_checkin: "* チェックインの際、カード情報が必要かどうかを選択してください。",
      hotel_hotel_setting_edit_validation_need_card_information_for_walkin: "* ウォークイン時にカード情報が必要かどうかを選択してください。",
      hotel_hotel_setting_edit_validation_is_direct_payable: "* Direct payが可能かどうかを選択してください。",
      hotel_hotel_setting_edit_validation_is_card_tokenizable: "* カード情報をトークン化できるかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_cancel_booking_post_charge_using_flag: "* 予約をキャンセルする場合、後払い請求を使用するかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_no_show_post_charge_using_flag: "* ノーショーの際は後払い請求を使用するかどうかを選択してください。",
      hotel_hotel_setting_edit_validation_min_card_expiry_days_checkout: "* チェックアウト後、最低カードの有効期限を入力してください。",
      hotel_hotel_setting_detail_label_cancel_booking_post_charge_using_flag: "予約をキャンセルする場合、後払い請求を使用するかどうか",
      hotel_hotel_setting_detail_label_noshow_post_charge_using_flag: "ノーショーの際、後払い請求の有無",
      hotel_hotel_setting_edit_validation_dnd_status: "* DND状態のユーザー確認通知期間を入力してください。",
      hotel_hotel_setting_edit_validation_dnd_relese: "* サービス開始前にDND解除要請通知時間を入力してください。",
      hotel_hotel_setting_edit_validation_room_control_property_id: "* ホテルIDを入力してください。",
      hotel_hotel_setting_edit_validation_room_control_ip: "* 客室制御IPを入力してください。",
      hotel_hotel_setting_edit_validation_room_control_port: "* 客室制御ポートを入力してください。",
      hotel_hotel_detail_list_title: "ホテルの詳細情報リスト",
      hotel_hotel_detail_list_label_name: "名前",
      hotel_hotel_detail_list_label_language_code: "言語コード",
      hotel_hotel_detail_list_label_rating: "等級",
      hotel_hotel_detail_list_label_address: "住所",
      hotel_hotel_detail_list_label_date: "日付",
      hotel_hotel_detail_list_button_delete: "削除",
      hotel_hotel_detail_list_button_add: "追加",
      hotel_hotel_detail_add_title: "ホテルの詳細情報追加",
      hotel_hotel_detail_add_label_name: "名前",
      hotel_hotel_detail_add_label_language_code: "言語コード",
      hotel_hotel_detail_add_placeholder_select_box_choose: "選択",
      hotel_hotel_detail_add_select_ko: "韓国語",
      hotel_hotel_detail_add_select_en: "英語",
      hotel_hotel_detail_add_select_cn: "中国語",
      hotel_hotel_detail_add_select_jp: "日本語",
      hotel_hotel_detail_add_label_rating: "等級",
      hotel_hotel_detail_add_label_address: "住所",
      hotel_hotel_detail_add_label_phone: "電話番号",
      hotel_hotel_detail_add_label_email: "Eメールアドレス",
      hotel_hotel_detail_add_button_go_to_list: "リストへのアクセス",
      hotel_hotel_detail_add_button_add: "追加",
      hotel_hotel_detail_add_validation_name: "* ホテル名を入力してください。",
      hotel_hotel_detail_add_validation_languageCode: "* 言語コードを選択してください。",
      hotel_hotel_detail_add_validation_rating: "* ホテルのランクを入力してください。",
      hotel_hotel_detail_add_validation_address: "* 住所を入力してください。",
      hotel_hotel_detail_add_validation_phone: "* 電話番号を入力してください。",
      hotel_hotel_detail_add_validation_email: "* ホテルのメールアドレスを入力してください。",
      hotel_hotel_detail_add_validation_about: "* 客室の説明を入力してください。",
      hotel_hotel_detail_detail_title: "ホテルの詳細情報",
      hotel_hotel_detail_detail_label_details: "詳細情報",
      hotel_hotel_detail_detail_label_name: "名前",
      hotel_hotel_detail_detail_label_language_code: "言語コード",
      hotel_hotel_detail_detail_placeholder_select_box_choose: "選択",
      hotel_hotel_detail_detail_select_ko: "韓国語",
      hotel_hotel_detail_detail_select_en: "英語",
      hotel_hotel_detail_detail_select_cn: "中国語",
      hotel_hotel_detail_detail_select_jp: "日本語",
      hotel_hotel_detail_detail_label_rating: "等級",
      hotel_hotel_detail_detail_label_address: "住所",
      hotel_hotel_detail_detail_label_phone: "電話番号",
      hotel_hotel_detail_detail_label_email: "Eメールアドレス",
      hotel_hotel_detail_detail_label_about: "ホテルの説明",
      hotel_hotel_detail_detail_label_date: "日付",
      hotel_hotel_detail_detail_label_facility: "施設",
      hotel_hotel_detail_detail_label_add_facility: "施設追加",
      hotel_hotel_detail_detail_label_edit_facility: "施設修正",
      hotel_hotel_detail_detail_add_placeholder_name: "名前を入力してください。",
      hotel_hotel_detail_detail_label_amenity: "便宜施設",
      hotel_hotel_detail_detail_label_add_amenity: "便益施設追加",
      hotel_hotel_detail_detail_type: "タイプ",
      hotel_hotel_detail_detail_group: "グループ",
      hotel_hotel_detail_detail_code: "コード",
      hotel_hotel_detail_detail_name: "名前",
      hotel_hotel_detail_detail_icon: "アイコン",
      hotel_hotel_detail_detail_date: "日付",
      hotel_hotel_detail_detail_label_recommend_application: "おすすめアプリ",
      hotel_hotel_detail_detail_application: "アプリ",
      hotel_hotel_detail_detail_label_add_recommend_app: "おすすめアプリの追加",
      hotel_hotel_detail_detail_empty_guide: "内容を追加してください。",
      hotel_hotel_detail_detail_button_go_to_list: "リストへのアクセス",
      hotel_hotel_detail_detail_button_delete: "削除",
      hotel_hotel_detail_detail_button_add: "追加",
      hotel_hotel_detail_detail_button_cancel: "キャンセル",
      hotel_hotel_detail_detail_button_edit: "修整",
      hotel_hotel_detail_detail_button_ok: "確認",
      hotel_hotel_detail_edit_validation_name: "* ホテル名を入力してください。",
      hotel_hotel_detail_edit_validation_languageCode: "* 言語コードを選択してください。",
      hotel_hotel_detail_edit_validation_rating: "* ホテルのランクを入力してください。",
      hotel_hotel_detail_edit_validation_address: "* 住所を入力してください。",
      hotel_hotel_detail_edit_validation_phone: "* 電話番号を入力してください。",
      hotel_hotel_detail_edit_validation_email: "* ホテルのメールアドレスを入力してください。",
      hotel_hotel_detail_edit_validation_about: "* 客室の説明を入力してください。",
      hotel_hotel_img_list_title: "ホテルの画像一覧",
      hotel_hotel_img_list_label_category: "カテゴリー",
      hotel_hotel_img_list_label_type: "タイプ",
      hotel_hotel_img_list_label_hotel_img: "ホテルのイメージ",
      hotel_hotel_img_list_label_date: "日付",
      hotel_hotel_img_list_button_delete: "削除",
      hotel_hotel_img_list_button_add: "追加",
      hotel_hotel_img_add_title: "ホテルの画像追加",
      hotel_hotel_img_add_label_category: "カテゴリー",
      hotel_hotel_img_add_label_type: "タイプ",
      hotel_hotel_img_add_placeholder_select_box_choose: "選択",
      hotel_hotel_img_add_select_gallery: "ギャラリー",
      hotel_hotel_img_add_select_hotel_side: "ホテル·サイド",
      hotel_hotel_img_add_select_hotel_detail: "ホテル詳細",
      hotel_hotel_img_add_select_hotel_center: "ホテル·メイン",
      hotel_hotel_img_add_select_hotel_front: "ホテル·フロント",
      hotel_hotel_img_add_select_hotel_background: "ホテルの背景",
      hotel_hotel_img_add_select_logo_dark: "ダーク·ロゴ",
      hotel_hotel_img_add_select_logo_light: "ライト·ロゴ",
      hotel_hotel_img_add_select_logo_chat: "チャット·ロゴ",
      hotel_hotel_img_add_select_card_key: "キー·カード",
      hotel_hotel_img_add_select_direct: "直接入力",
      hotel_hotel_img_add_label_hotel_img: "ホテルのイメージ",
      hotel_hotel_img_add_button_upload: "アップロード",
      hotel_hotel_img_add_button_go_to_list: "リストへのアクセス",
      hotel_hotel_img_add_button_add: "追加",
      hotel_hotel_img_add_validation_category: "* カテゴリを選択してください。",
      hotel_hotel_img_add_validation_hotel_img: "* ホテルの画像をアップロードしてください。",
      hotel_hotel_img_detail_title: "ホテルの画像情報",
      hotel_hotel_img_detail_label_category: "カテゴリー",
      hotel_hotel_img_detail_label_type: "タイプ",
      hotel_hotel_img_detail_label_type_gallery: "ギャラリー",
      hotel_hotel_img_detail_placeholder_select_box_choose: "選択",
      hotel_hotel_img_detail_select_direct: "直接入力",
      hotel_hotel_img_detail_select_gallery: "ギャラリー",
      hotel_hotel_img_detail_select_hotel_side: "ホテル·サイド",
      hotel_hotel_img_detail_select_hotel_detail: "ホテル詳細",
      hotel_hotel_img_detail_select_hotel_center: "ホテル·メイン",
      hotel_hotel_img_detail_select_hotel_front: "ホテル·フロント",
      hotel_hotel_img_detail_select_hotel_background: "ホテルの背景",
      hotel_hotel_img_detail_select_logo_dark: "ダーク·ロゴ",
      hotel_hotel_img_detail_select_logo_light: "ライト·ロゴ",
      hotel_hotel_img_detail_select_logo_chat: "チャット·ロゴ",
      hotel_hotel_img_detail_select_card_key: "キー·カード",
      hotel_hotel_img_detail_label_hotel_img: "ホテルのイメージ",
      hotel_hotel_img_detail_button_upload: "アップロード",
      hotel_hotel_img_detail_label_updated_at: "アップデート時間",
      hotel_hotel_img_detail_button_go_to_list: "リストへのアクセス",
      hotel_hotel_img_detail_button_delete: "削除",
      hotel_hotel_img_detail_button_edit: "修整",
      hotel_hotel_img_detail_button_ok: "確認",
      hotel_hotel_img_detail_button_cancel: "キャンセル",
      hotel_room_list_title: "客室の一覧",
      hotel_room_list_placeholder_select_box_choose: "選択",
      hotel_room_list_select_content: "内容",
      hotel_room_list_placeholder_search: "検索",
      hotel_room_list_label_rate_plan: "料金制",
      hotel_room_list_label_room_type: "客室タイプ",
      hotel_room_list_label_date: "日付",
      hotel_room_list_button_delete: "削除",
      hotel_room_list_button_add: "追加",
      hotel_room_list_guide_empty: "リストが空です",
      hotel_room_add_title: "客室追加",
      hotel_room_add_label_rate_plan: "料金制",
      hotel_room_add_label_room_type: "客室タイプ",
      hotel_room_add_button_go_to_list: "リストへのアクセス",
      hotel_room_add_button_add: "追加",
      hotel_room_add_validation_rate_plan: "* 料金プランを入力してください。",
      hotel_room_add_validation_room_type: "* 客室タイプを入力してください。",
      hotel_room_detail_title: "客室情報",
      hotel_room_detail_label_rate_plan: "料金制",
      hotel_room_detail_label_room_type: "客室タイプ",
      hotel_room_detail_label_room_date: "日付",
      hotel_room_detail_label_resource_information: "リソース情報",
      hotel_room_detail_label_resource_information_resource_id: "リソースID",
      hotel_room_detail_label_resource_information_name: "リソース名",
      hotel_room_detail_label_resource_information_unit_price: "単位価格",
      hotel_room_detail_label_resource_information_currency: "通貨",
      hotel_room_detail_label_resource_information_quantity: "数量",
      hotel_room_detail_label_resource_information_time_unit: "リソースのデフォルトの時間単位",
      hotel_room_detail_label_resource_information_min_rental_time: "最小レンタル時間",
      hotel_room_detail_label_resource_information_max_rental_time: "最大レンタル時間",
      hotel_room_detail_label_resource_information_min_time_between_rentals: "予約と予約の間の最小区分時間",
      hotel_room_detail_label_resource_information_min_hours_to_rental: "最低予約とレンタルの間の時間",
      hotel_room_detail_label_resource_information_start_quarters: "許容開始分岐",
      hotel_room_detail_label_resource_information_start_hour: "レンタル開始時間",
      hotel_room_detail_label_resource_information_end_hour: "レンタル終了時間",
      hotel_room_detail_label_resource_information_unit_names: "リソースのユニット名",
      hotel_room_detail_button_go_to_list: "リストへのアクセス",
      hotel_room_detail_button_cancel: "キャンセル",
      hotel_room_detail_button_delete: "削除",
      hotel_room_detail_button_edit: "修整",
      hotel_room_detail_button_ok: "確認",
      hotel_room_edit_validation_rate_plan: "* 料金プランを入力してください。",
      hotel_room_edit_validation_room_type: "* 客室タイプを入力してください。",
      hotel_room_detail_list_title: "客室の詳細情報一覧",
      hotel_room_detail_list_placeholder_select_box_choose: "選択",
      hotel_room_detail_list_select_room_type: "客室タイプ",
      hotel_room_detail_list_select_rate_plan: "料金制",
      hotel_room_detail_list_placeholder_search: "検索",
      hotel_room_detail_list_label_language_code: "言語コード",
      hotel_room_detail_list_label_name: "名前",
      hotel_room_detail_list_label_view: "展望",
      hotel_room_detail_list_label_type: "タイプ",
      hotel_room_detail_list_label_room_type: "客室タイプ",
      hotel_room_detail_list_label_rate_plan: "料金制",
      hotel_room_detail_list_label_date: "日付",
      hotel_room_detail_list_button_delete: "削除",
      hotel_room_detail_list_button_add: "追加",
      hotel_room_detail_list_guide_empty: "リストが空です",
      hotel_room_detail_add_title: "客室の詳細情報の追加",
      hotel_room_detail_add_label_language_code: "言語コード",
      hotel_room_detail_add_placeholder_select_box_choose: "選択",
      hotel_room_detail_add_select_ko: "韓国語",
      hotel_room_detail_add_select_en: "英語",
      hotel_room_detail_add_select_cn: "中国語",
      hotel_room_detail_add_select_jp: "日本語",
      hotel_room_detail_add_label_name: "名前",
      hotel_room_detail_add_label_view: "展望",
      hotel_room_detail_add_label_type: "タイプ",
      hotel_room_detail_add_label_bed_type: "ベッド タイプ",
      hotel_room_detail_add_label_max_occupancy: "最大人数",
      hotel_room_detail_add_label_standard_occupancy: "最小人数",
      hotel_room_detail_add_label_size: "大きさ (m²)",
      hotel_room_detail_add_label_checkin_time: "チェックイン時間(時間:分)",
      hotel_room_detail_add_label_checkout_time: "チェックアウト時間(時間:分)",
      hotel_room_detail_add_label_about: "客室の説明",
      hotel_room_detail_add_label_more: "客室追加説明",
      hotel_room_detail_add_label_room_type: "客室タイプ",
      hotel_room_detail_add_button_go_to_list: "リストへのアクセス",
      hotel_room_detail_add_button_add: "追加",
      hotel_room_detail_add_validation_room_type: "* 客室タイプをお選びください。",
      hotel_room_detail_add_validation_language_code: "* 言語コードを選択してください。",
      hotel_room_detail_add_validation_name: "* 客室名を入力してください。",
      hotel_room_detail_add_validation_view: "* 客室のビューを入力してください。",
      hotel_room_detail_add_validation_type: "* 客室タイプを入力してください。",
      hotel_room_detail_add_validation_bed_type: "* ベッドタイプを入力してください。",
      hotel_room_detail_add_validation_max_occupancy: "* 宿泊客の最大人数を入力してください。",
      hotel_room_detail_add_validation_standard_occupancy: "* 宿泊客の最小人数を入力してください。",
      hotel_room_detail_add_validation_checkin_time: "* チェックイン時間を入力してください。",
      hotel_room_detail_add_validation_checkout_time: "* チェックアウト時間を入力してください。",
      hotel_room_detail_add_validation_about: "* 客室の説明を入力してください。",
      hotel_room_detail_add_validation_more: "* 客室の追加説明を入力してください。",
      hotel_room_detail_detail_title: "客室の詳細情報",
      hotel_room_detail_detail_label_details: "詳細情報",
      hotel_room_detail_detail_label_name: "名前",
      hotel_room_detail_detail_label_language_code: "言語コード",
      hotel_room_detail_detail_placeholder_select_box_choose: "選択",
      hotel_room_detail_detail_select_ko: "韓国語",
      hotel_room_detail_detail_select_en: "英語",
      hotel_room_detail_detail_select_cn: "中国語",
      hotel_room_detail_detail_select_jp: "日本語",
      hotel_room_detail_detail_label_view: "展望",
      hotel_room_detail_detail_label_type: "タイプ",
      hotel_room_detail_detail_label_bed_type: "ベッド タイプ",
      hotel_room_detail_detail_label_max_occupancy: "最大人数",
      hotel_room_detail_detail_label_standard_occupancy: "最小人数",
      hotel_room_detail_detail_label_size: "大きさ (m²)",
      hotel_room_detail_detail_label_checkin_time: "チェックイン時間(時間:分)",
      hotel_room_detail_detail_label_checkout_time: "チェックアウト時間(時間:分)",
      hotel_room_detail_detail_label_about: "客室の説明",
      hotel_room_detail_detail_label_more: "客室追加説明",
      hotel_room_detail_detail_label_date: "日付",
      hotel_room_detail_detail_title_amenity: "便宜施設",
      hotel_room_detail_detail_type: "タイプ",
      hotel_room_detail_detail_group: "グループ",
      hotel_room_detail_detail_code: "コード",
      hotel_room_detail_detail_name: "名前",
      hotel_room_detail_detail_icon: "アイコン",
      hotel_room_detail_detail_date: "日付",
      hotel_room_detail_detail_title_add_amenity: "便益施設追加",
      hotel_room_detail_detail_button_go_to_list: "リストへのアクセス",
      hotel_room_detail_detail_button_add: "追加",
      hotel_room_detail_detail_button_delete: "削除",
      hotel_room_detail_detail_button_cancel: "キャンセル",
      hotel_room_detail_detail_button_edit: "修整",
      hotel_room_detail_detail_button_ok: "確認",
      hotel_room_detail_edit_validation_room_type: "* 客室タイプをお選びください。",
      hotel_room_detail_edit_validation_language_code: "* 言語コードを選択してください。",
      hotel_room_detail_edit_validation_name: "* 客室名を入力してください。",
      hotel_room_detail_edit_validation_view: "* 客室のビューを入力してください。",
      hotel_room_detail_edit_validation_type: "* 客室タイプを入力してください。",
      hotel_room_detail_edit_validation_bed_type: "* ベッドタイプを入力してください。",
      hotel_room_detail_edit_validation_max_occupancy: "* 宿泊客の最大人数を入力してください。",
      hotel_room_detail_edit_validation_standard_occupancy: "* 宿泊客の最小人数を入力してください。",
      hotel_room_detail_edit_validation_size: "* 客室サイズを入力してください。",
      hotel_room_detail_edit_validation_checkin_time: "* チェックイン時間を入力してください。",
      hotel_room_detail_edit_validation_checkout_time: "* チェックアウト時間を入力してください。",
      hotel_room_detail_edit_validation_about: "* 客室の説明を入力してください。",
      hotel_room_detail_edit_validation_more: "* 客室の追加説明を入力してください。",
      hotel_room_img_list_title: "客室画像一覧",
      hotel_room_img_list_label_rate_plan: "料金制",
      hotel_room_img_list_label_room_type: "客室タイプ",
      hotel_room_img_list_label_type: "タイプ",
      hotel_room_img_list_label_room_img: "客室のイメージ",
      hotel_room_img_list_label_date: "日付",
      hotel_room_img_list_button_delete: "削除",
      hotel_room_img_list_button_add: "追加",
      hotel_room_img_add_title: "客室イメージ追加",
      hotel_room_img_add_label_room_type: "客室タイプ",
      hotel_room_img_add_label_type: "タイプ",
      hotel_room_img_add_placeholder_select_box_choose: "選択",
      hotel_room_img_add_select_gallery: "ギャラリー",
      hotel_room_img_add_select_room_detail: "客室の詳細",
      hotel_room_img_add_select_room_center: "客室のメイン",
      hotel_room_img_add_label_hotel_img: "客室のイメージ",
      hotel_room_img_add_button_upload: "アップロード",
      hotel_room_img_add_button_go_to_list: "リストへのアクセス",
      hotel_room_img_add_button_add: "追加",
      hotel_room_img_add_validation_room_type: "* 客室タイプをお選びください。",
      hotel_room_img_add_validation_image_file: "* ホテルの画像をアップロードしてください。",
      hotel_room_img_detail_title: "客室画像情報",
      hotel_room_img_detail_label_room_type: "客室タイプ",
      hotel_room_img_detail_placeholder_select_box_choose: "選択",
      hotel_room_img_detail_label_type: "タイプ",
      hotel_room_img_detail_select_gallery: "ギャラリー",
      hotel_room_img_detail_select_room_detail: "客室の詳細",
      hotel_room_img_detail_select_room_center: "客室のメイン",
      hotel_room_img_detail_label_room_img: "客室のイメージ",
      hotel_room_img_detail_button_upload: "アップロード",
      hotel_room_img_detail_label_updated_at: "アップデートの日付",
      hotel_room_img_detail_button_go_to_list: "リストへのアクセス",
      hotel_room_img_detail_button_delete: "削除",
      hotel_room_img_detail_button_edit: "修整",
      hotel_room_img_detail_button_ok: "確認",
      hotel_room_img_detail_button_cancel: "キャンセル",
      hotel_room_management_room_list_list_title: "ROOM LIST",
      hotel_room_management_room_list_list_select_floor: "Floor",
      hotel_room_management_room_list_list_select_cleaning_status: "Cleaning Status",
      hotel_room_management_room_list_list_select_room_type: "Room Type",
      hotel_room_management_room_list_list_select_rooms_status: "Room Status",
      hotel_room_management_room_list_list_select_fo_status: "FO Status",
      hotel_room_management_room_list_list_select_reservation_status: "Reservation Status",
      hotel_room_management_room_list_list_placeholder_room_no: "Room No.",
      hotel_room_management_room_list_list_button_all: "All",
      hotel_room_management_room_list_list_button_change: "Change",
      hotel_room_management_room_list_list_guide_empty: "List is empty",
      hotel_room_management_room_list_detail_title: "ROOM DETAIL",
      hotel_room_management_room_list_detail_label_floor: "Floor",
      hotel_room_management_room_list_detail_label_room_no: "Room No.",
      hotel_room_management_room_list_detail_label_room_type: "Room Type",
      hotel_room_management_room_list_detail_label_room_status: "Room Status",
      hotel_room_management_room_list_detail_label_fo_status: "FO Status",
      hotel_room_management_room_list_detail_label_reservation_status: "Reservation Status",
      hotel_room_management_room_list_detail_label_created_at: "Created At",
      hotel_room_management_room_list_detail_button_status: "Status",
      hotel_room_management_room_list_detail_button_cleaning: "Cleaning",
      hotel_room_management_room_list_detail_label_history: "History",
      hotel_room_management_room_list_detail_placeholder_select_box_choose: "Select",
      hotel_room_management_room_list_detail_select_housekeeping: "Housekeeping",
      hotel_room_management_room_list_detail_placeholder_search: "Search...",
      hotel_room_management_room_list_detail_label_number: "No.",
      hotel_room_management_room_list_detail_label_housekeeping: "Housekeeping",
      hotel_room_management_room_list_detail_label_staff: "Staff",
      hotel_room_management_room_list_detail_label_date: "Date",
      hotel_room_management_room_list_detail_guide_empty: "List is empty",
      hotel_room_management_room_list_modal_title_status_message: "Room Status",
      hotel_room_management_room_list_modal_status_clean: "Clean",
      hotel_room_management_room_list_modal_status_inspected: "Inspected",
      hotel_room_management_room_list_modal_status_dirty: "Dirty",
      hotel_room_management_room_list_modal_status_pick_up: "Pick Up",
      hotel_room_management_setting_detail_title: "SETTINGS",
      hotel_room_management_setting_detail_label_total_floors: "Total floors",
      hotel_room_management_setting_detail_label_total_number_of_rooms: "Total number of rooms",
      hotel_room_management_setting_detail_label_floor_detail: "Floor Detail",
      hotel_room_management_setting_detail_label_number: "No.",
      hotel_room_management_setting_detail_label_number_of_rooms_on_the_floor: "Number of rooms on the floor",
      hotel_room_management_setting_detail_label_number_of_rooms: "Number of rooms",
      hotel_room_management_setting_detail_guide_empty: "List is empty",
      hotel_room_management_setting_detail_button_edit: "Edit",
      hotel_room_management_setting_edit_validation_total_floors: "* Please enter the total floors.",
      hotel_room_management_setting_edit_validation_number_of_rooms: "* Please enter the number of rooms.",
      hotel_room_management_setting_edit_label_total_floors: "Total floors",
      hotel_room_management_setting_edit_label_total_number_of_rooms: "Total number of rooms",
      hotel_room_management_setting_edit_label_floor_detail: "Floor Detail",
      hotel_room_management_setting_edit_label_number: "No.",
      hotel_room_management_setting_edit_label_number_of_rooms_on_the_floor: "Number of rooms on the floor",
      hotel_room_management_setting_edit_label_number_of_rooms: "Number of rooms",
      hotel_room_management_setting_edit_guide_empty: "List is empty",
      hotel_room_management_setting_edit_button_cancel: "Cancel",
      hotel_room_management_setting_edit_button_ok: "OK",
      hotel_package_list_title: "パッケージリスト",
      hotel_package_list_label_type: "タイプ",
      hotel_package_list_label_code: "コード",
      hotel_package_list_label_name: "名前",
      hotel_package_list_label_description: "説明",
      hotel_package_list_label_date: "日付",
      hotel_package_list_button_delete: "削除",
      hotel_package_list_button_add: "追加",
      hotel_package_list_guide_empty: "リストが空です",
      hotel_package_add_title: "パッケージ追加",
      hotel_package_add_label_type: "タイプ",
      hotel_package_add_placeholder_select_box_choose: "選択",
      hotel_package_add_placeholder_select_box_package: "パッケージ",
      hotel_package_add_placeholder_select_box_transaction: "決済",
      hotel_package_add_label_code: "コード",
      hotel_package_add_label_name: "名前",
      hotel_package_add_label_description: "説明",
      hotel_package_add_button_go_to_list: "リストへのアクセス",
      hotel_package_add_button_add: "追加",
      hotel_package_add_validation_type: "* パッケージタイプを選択してください。",
      hotel_package_add_validation_code: "* パッケージコードを入力してください。",
      hotel_package_add_validation_name: "* パッケージ名を入力してください。",
      hotel_package_add_validation_description: "* パッケージの説明を入力してください。",
      hotel_package_detail_title: "パッケージ情報",
      hotel_package_detail_label_type: "タイプ",
      hotel_package_detail_label_code: "コード",
      hotel_package_detail_label_name: "名前",
      hotel_package_detail_label_description: "説明",
      hotel_package_detail_label_updated_at: "日付",
      hotel_package_detail_button_go_to_list: "リストへのアクセス",
      hotel_package_detail_button_cancel: "キャンセル",
      hotel_package_detail_button_delete: "削除",
      hotel_package_detail_button_edit: "修整",
      hotel_package_detail_button_ok: "確認",
      hotel_package_edit_validation_type: "* パッケージタイプを選択してください。",
      hotel_package_edit_validation_code: "* パッケージコードを入力してください。",
      hotel_package_edit_validation_name: "* パッケージ名を入力してください。",
      hotel_package_edit_validation_description: "* パッケージの説明を入力してください。",
      hotel_package_detail_list_title: "パッケージ 詳細情報一覧",
      hotel_package_detail_list_label_code: "コード",
      hotel_package_detail_list_label_name: "名前",
      hotel_package_detail_list_label_language_code: "言語コード",
      hotel_package_detail_list_label_date: "日付",
      hotel_package_detail_list_button_delete: "削除",
      hotel_package_detail_list_button_add: "追加",
      hotel_package_detail_list_guide_empty: "リストが空です",
      hotel_package_detail_add_title: "パッケージ 詳細情報の追加",
      hotel_package_detail_add_label_name: "名前",
      hotel_package_detail_add_label_package_code: "パッケージ·コード",
      hotel_package_detail_add_placeholder_select_box_choose: "選択",
      hotel_package_detail_add_label_language_code: "言語コード",
      hotel_package_detail_add_select_ko: "韓国語",
      hotel_package_detail_add_select_en: "英語",
      hotel_package_detail_add_select_cn: "中国語",
      hotel_package_detail_add_select_jp: "日本語",
      hotel_package_detail_add_label_description: "説明",
      hotel_package_detail_add_label_content: "内容",
      hotel_package_detail_add_label_package_img: "パッケージ·イメージ",
      hotel_package_detail_add_button_upload: "アップロード",
      hotel_package_detail_add_button_go_to_list: "リストへのアクセス",
      hotel_package_detail_add_button_add: "追加",
      hotel_package_detail_add_validation_package_code: "* パッケージコードを選択してください。",
      hotel_package_detail_add_validation_language_code: "* 言語コードを選択してください。",
      hotel_package_detail_add_validation_description: "* パッケージの説明を入力してください。",
      hotel_package_detail_add_validation_image_file: "* パッケージ画像をアップロードしてください。",
      hotel_package_detail_add_validation_content: "* パッケージの内容を入力してください。",
      hotel_package_detail_detail_title: "パッケージ 詳細情報",
      hotel_package_detail_detail_label_date: "アップデートの日付",
      hotel_package_detail_detail_label_name: "名前",
      hotel_package_detail_detail_label_package_code: "パッケージ·コード",
      hotel_package_detail_detail_placeholder_select_box_choose: "選択",
      hotel_package_detail_detail_label_language_code: "言語コード",
      hotel_package_detail_detail_select_ko: "韓国語",
      hotel_package_detail_detail_select_en: "英語",
      hotel_package_detail_detail_select_cn: "中国語",
      hotel_package_detail_detail_select_jp: "日本語",
      hotel_package_detail_detail_label_description: "説明",
      hotel_package_detail_detail_label_content: "内容",
      hotel_package_detail_detail_label_package_img: "パッケージ·イメージ",
      hotel_package_detail_detail_button_upload: "アップロード",
      hotel_package_detail_detail_button_go_to_list: "リストへのアクセス",
      hotel_package_detail_detail_button_cancel: "キャンセル",
      hotel_package_detail_detail_button_delete: "削除",
      hotel_package_detail_detail_button_edit: "修整",
      hotel_package_detail_detail_button_ok: "確認",
      hotel_package_detail_edit_validation_package_code: "* パッケージコードを選択してください。",
      hotel_package_detail_edit_validation_language_code: "* 言語コードを選択してください。",
      hotel_package_detail_edit_validation_description: "* パッケージの説明を入力してください。",
      hotel_package_detail_edit_validation_image_file: "* パッケージ画像をアップロードしてください。",
      hotel_package_detail_edit_validation_content: "* パッケージの内容を入力してください。",
      hotel_hotel_valet_setting_detail_title: "バレットパーキング設定",
      hotel_hotel_valet_setting_detail_label_date: "アップデートの日付",
      hotel_hotel_valet_setting_detail_label_parking: "駐車",
      hotel_hotel_valet_setting_detail_label_parking_availability: "駐車可能",
      hotel_hotel_valet_setting_detail_button_yes: "例",
      hotel_hotel_valet_setting_detail_button_no: "いいえ",
      hotel_hotel_valet_setting_detail_label_time: "時間",
      hotel_hotel_valet_setting_detail_label_delay_time: "遅延時間",
      hotel_hotel_valet_setting_detail_button_15: "15分",
      hotel_hotel_valet_setting_detail_button_30: "30分",
      hotel_hotel_valet_setting_detail_button_45: "45分",
      hotel_hotel_valet_setting_detail_button_60: "60分",
      hotel_hotel_valet_setting_detail_button_as_soon_as: "早いうちに",
      hotel_hotel_valet_setting_detail_label_calculation_time: "待機リクエスト時間(時間:分)",
      hotel_hotel_valet_setting_detail_label_vehicle_time: "返却時間(時間:分)",
      hotel_hotel_valet_setting_detail_label_request_caution_time: "リクエスト週の時間(分)",
      hotel_hotel_valet_setting_detail_label_delay_available_time: "遅延適用時間(分)",
      hotel_hotel_valet_setting_detail_label_requirement_time: "編集可能な最小要求事項時間(分)",
      hotel_hotel_valet_setting_detail_label_log_active_hours: "活動時間記録(時)",
      hotel_hotel_valet_setting_detail_label_return_additional_charge: "回次追加料金",
      hotel_hotel_valet_setting_detail_label_return_push_message: "返戻メッセージ通知",
      hotel_hotel_valet_setting_detail_label_return_charge_count: "回次追加料金の適用回数",
      hotel_hotel_valet_setting_detail_label_return_repeat_message: "回次リクエスト時に繰り返しペナルティ",
      hotel_hotel_valet_setting_detail_button_edit: "修整",
      hotel_hotel_valet_setting_edit_title: "バレットパーキング設定",
      hotel_hotel_valet_setting_edit_label_date: "アップデートの日付",
      hotel_hotel_valet_setting_edit_label_parking: "駐車",
      hotel_hotel_valet_setting_edit_label_parking_availability: "駐車可能",
      hotel_hotel_valet_setting_edit_button_yes: "例",
      hotel_hotel_valet_setting_edit_button_no: "いいえ",
      hotel_hotel_valet_setting_edit_label_time: "時間",
      hotel_hotel_valet_setting_edit_label_delay_time: "遅延時間",
      hotel_hotel_valet_setting_edit_placeholder_delay_time: "ex) 80",
      hotel_hotel_valet_setting_edit_label_calculation_time: "待機要請時間",
      hotel_hotel_valet_setting_edit_placeholder_calculation_time: "ex) 24:00",
      hotel_hotel_valet_setting_edit_label_vehicle_time: "車両回次時間",
      hotel_hotel_valet_setting_edit_placeholder_vehicle_time: "ex) 80",
      hotel_hotel_valet_setting_edit_button_ok: "確認",
      hotel_hotel_valet_setting_edit_validation_parking_availability: "* 駐車可能な状態を選択してください。",
      hotel_hotel_valet_setting_edit_validation_waiting_calculation_time: "* 出車待機リクエスト時間を入力してください。",
      hotel_hotel_valet_setting_edit_validation_vehicle_time: "* 車の返却時間を入力してください。",
      hotel_hotel_valet_setting_edit_validation_request_caution_time: "* リクエスト 週の時間を入力してください。",
      hotel_hotel_valet_setting_edit_validation_requirement_time: "* 編集可能な最小要件時間を入力してください。",
      hotel_hotel_valet_setting_edit_validation_charge_log_active_hours: "* 活動時間の記録を入力してください。",
      hotel_hotel_valet_setting_edit_validation_return_push_message: "* 返戻メッセージ通知ステータスを選択してください。",
      hotel_hotel_self_setting_detail_title: "セルフ駐車設定",
      hotel_hotel_self_setting_detail_label_date: "アップデートの日付",
      hotel_hotel_self_setting_detail_label_parking: "駐車",
      hotel_hotel_self_setting_detail_label_parking_availability: "駐車可能",
      hotel_hotel_self_setting_detail_button_yes: "例",
      hotel_hotel_self_setting_detail_button_no: "いいえ",
      hotel_hotel_self_setting_detail_button_edit: "修整",
      hotel_hotel_self_setting_edit_title: "セルフ駐車設定",
      hotel_hotel_self_setting_edit_label_date: "アップデートの日付",
      hotel_hotel_self_setting_edit_label_parking: "駐車",
      hotel_hotel_self_setting_edit_label_parking_availability: "駐車可能",
      hotel_hotel_self_setting_edit_button_yes: "例",
      hotel_hotel_self_setting_edit_button_no: "いいえ",
      hotel_hotel_self_setting_edit_placeholder_vehicle_time: "ex) 80",
      hotel_hotel_self_setting_edit_button_ok: "確認",
      hotel_hotel_service_setting_detail_title: "インハウスメニュー設定",
      hotel_hotel_service_setting_detail_label_date: "アップデートの日付",
      hotel_hotel_service_setting_detail_button_yes: "例",
      hotel_hotel_service_setting_detail_button_no: "いいえ",
      hotel_hotel_service_setting_detail_label_housekeeping: "ハウスキーピング",
      hotel_hotel_service_setting_detail_label_housekeeping_dnd: "妨害禁止",
      hotel_hotel_service_setting_detail_label_housekeeping_green_campaign: "グリーン·キャンペーン",
      hotel_hotel_service_setting_detail_label_housekeeping_emergency_service: "応急",
      hotel_hotel_service_setting_detail_label_housekeeping_make_up_room: "客室清掃",
      hotel_hotel_service_setting_detail_label_housekeeping_turndown_service: "客室整頓",
      hotel_hotel_service_setting_detail_label_concierge: "コンシェルジュ",
      hotel_hotel_service_setting_detail_label_concierge_luggage_service: "手荷物",
      hotel_hotel_service_setting_detail_label_concierge_parcel_service: "宅配",
      hotel_hotel_service_setting_detail_label_concierge_taxi_call_service: "タクシー",
      hotel_hotel_service_setting_detail_label_guest_service: "顧客サービス",
      hotel_hotel_service_setting_detail_label_guest_service_amenity_service: "アメニティ",
      hotel_hotel_service_setting_detail_label_guest_service_laundry_service: "洗濯",
      hotel_hotel_service_setting_detail_label_guest_service_in_room_dining: "イン·ルーム·ダイニング",
      hotel_hotel_service_setting_detail_label_guest_service_ironing_service: "アイロンがけ",
      hotel_hotel_service_setting_detail_label_etc: "その他",
      hotel_hotel_service_setting_detail_label_room_to_room: "ルームトゥルーム",
      hotel_hotel_service_setting_detail_label_share_key: "共有キー",
      hotel_hotel_service_setting_detail_label_in_room_control: "客室制御",
      hotel_hotel_service_setting_detail_button_edit: "修整",
      hotel_hotel_service_setting_detail_button_cancel: "キャンセル",
      hotel_hotel_service_setting_detail_button_ok: "確認",
      hotel_service_description_list_title: "サービス説明",
      hotel_service_description_list_button_add: "追加",
      hotel_service_description_list_button_delete: "削除",
      hotel_service_description_list_button_edit: "修整",
      hotel_service_description_list_guide_add: "サービス説明を追加してください",
      hotel_service_description_add_title: "サービス説明の追加",
      hotel_service_description_add_select_choose: "タイプをお選びください",
      hotel_service_description_add_select_in_room_dining: "イン·ルーム·ダイニング",
      hotel_service_description_add_select_luggage_service: "手荷物サービス",
      hotel_service_description_add_select_parcel_service: "宅配サービス",
      hotel_service_description_add_select_taxi_call: "タクシー",
      hotel_service_description_add_select_amenity_service: "アメニティ·サービス",
      hotel_service_description_add_select_ironing_service: "アイロンサービス",
      hotel_service_description_add_select_laundry_service: "ランドリーサービス",
      hotel_service_description_add_select_dnd: "妨害禁止",
      hotel_service_description_add_select_green_campaign: "グリーン·キャンペーン",
      hotel_service_description_add_select_emergency_service: "救急サービス",
      hotel_service_description_add_toolbar_bold: "太く",
      hotel_service_description_add_toolbar_italic: "イタリック体",
      hotel_service_description_add_toolbar_underline: "下線",
      hotel_service_description_add_toolbar_remove_font_style: "書体取り",
      hotel_service_description_add_toolbar_font_family: "フォント集",
      hotel_service_description_add_toolbar_font_size: "フォントサイズ",
      hotel_service_description_add_toolbar_recent_color: "最近の使用色",
      hotel_service_description_add_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_service_description_add_toolbar_unordered_list: "迷走文目録",
      hotel_service_description_add_toolbar_ordered_list: "注文リスト",
      hotel_service_description_add_toolbar_paragraph: "短絡",
      hotel_service_description_add_toolbar_line_height: "線の高さ",
      hotel_service_description_add_placeholder_content: "内容を入力してください",
      hotel_service_description_add_button_cancel: "キャンセル",
      hotel_service_description_add_button_ok: "確認",
      hotel_service_description_add_validation_type: "* タイプを選択してください。",
      hotel_service_description_add_validation_content: "* 内容を入力してください。",
      hotel_service_description_edit_title: "サービス説明修正",
      hotel_service_description_edit_select_kr: "韓国語",
      hotel_service_description_edit_select_en: "英語",
      hotel_service_description_edit_select_cn: "中国語",
      hotel_service_description_edit_select_jp: "日本語",
      hotel_service_description_edit_toolbar_bold: "太く",
      hotel_service_description_edit_toolbar_italic: "イタリック体",
      hotel_service_description_edit_toolbar_underline: "下線",
      hotel_service_description_edit_toolbar_remove_font_style: "書体取り",
      hotel_service_description_edit_toolbar_font_family: "フォント集",
      hotel_service_description_edit_toolbar_font_size: "フォントサイズ",
      hotel_service_description_edit_toolbar_recent_color: "最近の使用色",
      hotel_service_description_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_service_description_edit_toolbar_unordered_list: "迷走文目録",
      hotel_service_description_edit_toolbar_ordered_list: "注文リスト",
      hotel_service_description_edit_toolbar_paragraph: "短絡",
      hotel_service_description_edit_toolbar_code_view: "コードを見ること",
      hotel_service_description_edit_toolbar_line_height: "線の高さ",
      hotel_service_description_edit_button_append: "追加",
      hotel_service_description_edit_button_remove: "除去",
      hotel_service_description_edit_button_cancel: "キャンセル",
      hotel_service_description_edit_button_ok: "確認",
      hotel_service_description_edit_validation_language_code: "* 重複しない言語コードを選択してください。",
      hotel_service_description_edit_validation_content: "* 内容を入力してください。",
      hotel_hotel_beacon_list_title: "ビーコン一覧",
      hotel_hotel_beacon_list_placeholder_search: "検索",
      hotel_hotel_beacon_list_label_uuid: "UUID",
      hotel_hotel_beacon_list_label_code: "コード",
      hotel_hotel_beacon_list_label_type: "タイプ",
      hotel_hotel_beacon_list_label_content: "内容",
      hotel_hotel_beacon_list_label_date: "日付",
      hotel_hotel_beacon_list_guide_empty: "リストが空です",
      hotel_hotel_beacon_list_button_delete: "削除",
      hotel_hotel_beacon_list_button_add: "追加",
      hotel_hotel_beacon_add_title: "ビーコン追加",
      hotel_hotel_beacon_add_label_uuid: "UUID",
      hotel_hotel_beacon_add_label_code: "コード",
      hotel_hotel_beacon_add_label_type: "タイプ",
      hotel_hotel_beacon_add_label_content: "内容",
      hotel_hotel_beacon_add_button_go_to_list: "リストへのアクセス",
      hotel_hotel_beacon_add_button_add: "追加",
      hotel_hotel_beacon_add_validation_uuid: "* UUIDを入力してください。",
      hotel_hotel_beacon_add_validation_code: "* コードを入力してください。",
      hotel_hotel_beacon_add_validation_type: "* タイプを入力してください。",
      hotel_hotel_beacon_add_validation_content: "* 内容を入力してください。",
      hotel_hotel_beacon_detail_title: "ビーコン情報",
      hotel_hotel_beacon_detail_label_uuid: "UUID",
      hotel_hotel_beacon_detail_label_code: "コード",
      hotel_hotel_beacon_detail_label_type: "タイプ",
      hotel_hotel_beacon_detail_label_content: "内容",
      hotel_hotel_beacon_detail_label_date: "日付",
      hotel_hotel_beacon_detail_button_cancel: "キャンセル",
      hotel_hotel_beacon_detail_button_go_to_list: "リストへのアクセス",
      hotel_hotel_beacon_detail_button_delete: "削除",
      hotel_hotel_beacon_detail_button_edit: "修整",
      hotel_hotel_beacon_edit_title: "ビーコン情報",
      hotel_hotel_beacon_edit_label_uuid: "UUID",
      hotel_hotel_beacon_edit_label_code: "コード",
      hotel_hotel_beacon_edit_label_type: "タイプ",
      hotel_hotel_beacon_edit_label_content: "内容",
      hotel_hotel_beacon_edit_label_date: "日付",
      hotel_hotel_beacon_edit_button_cancel: "キャンセル",
      hotel_hotel_beacon_edit_button_delete: "削除",
      hotel_hotel_beacon_edit_button_ok: "確認",
      hotel_hotel_beacon_edit_validation_uuid: "* UUIDを入力してください。",
      hotel_hotel_beacon_edit_validation_code: "* コードを入力してください。",
      hotel_hotel_beacon_edit_validation_type: "* タイプを入力してください。",
      hotel_hotel_beacon_edit_validation_content: "* 内容を入力してください。",
      hotel_hotel_currency_list_title: "通貨目録",
      hotel_hotel_currency_list_guide_exchange_rate: "為替レート（KRW）:",
      hotel_hotel_currency_list_guide_won: "{{data}} 원 / {{data}}",
      hotel_hotel_currency_list_placeholder_currency: "通話 (ぜんぶ)",
      hotel_hotel_currency_list_select_display: "通話表示",
      hotel_hotel_currency_list_select_name: "通話名",
      hotel_hotel_currency_list_placeholder_search: "検索",
      hotel_hotel_currency_list_label_display: "通話表示",
      hotel_hotel_currency_list_label_name: "通話名",
      hotel_hotel_currency_list_label_exchange_rate: "為替レート",
      hotel_hotel_currency_list_label_date: "日付",
      hotel_hotel_currency_list_label_syne: "同期化",
      hotel_hotel_currency_list_guide_empty: "リストが空です",
      hotel_hotel_currency_list_button_syne: "同期化",
      hotel_hotel_currency_list_button_delete: "削除",
      hotel_hotel_currency_list_button_add: "追加",
      hotel_hotel_currency_add_title: "通貨の追加",
      hotel_hotel_currency_add_guide_currency: "為替レート（KRW）:",
      hotel_hotel_currency_add_guide_won: "{{data}} 원 / {{data}}",
      hotel_hotel_currency_add_select_display: "通話表示",
      hotel_hotel_currency_add_select_name: "通話名",
      hotel_hotel_currency_add_label_exchange_rate: "為替レート",
      hotel_hotel_currency_add_button_go_to_list: "リストへのアクセス",
      hotel_hotel_currency_add_button_add: "追加",
      hotel_hotel_currency_add_validation_currency_display: "* 通話表示を入力してください。",
      hotel_hotel_currency_add_validation_currency_name: "* 通話名を入力してください。",
      hotel_hotel_currency_add_validation_exchange_rate: "* 為替レートを入力してください。",
      hotel_hotel_membership_list_title: "会員権タイプ一覧",
      hotel_hotel_membership_list_placeholder_search: "検索",
      hotel_hotel_membership_list_label_membership_type: "会員権タイプ",
      hotel_hotel_membership_list_label_description: "内容",
      hotel_hotel_membership_list_label_date: "日付",
      hotel_hotel_membership_list_guide_empty: "リストが空です",
      hotel_hotel_membership_list_button_delete: "削除",
      hotel_hotel_membership_list_button_sync: "同期化",
      hotel_hotel_membership_list_button_add: "追加",
      hotel_hotel_membership_add_title: "会員タイプ追加",
      hotel_hotel_membership_add_label_membership_type: "会員権タイプ",
      hotel_hotel_membership_add_label_description: "内容",
      hotel_hotel_membership_add_button_go_to_list: "リストへのアクセス",
      hotel_hotel_membership_add_button_add: "追加",
      hotel_hotel_membership_add_validation_membership_type: "* 会員券タイプを入力してください。",
      hotel_hotel_membership_add_validation_description: "* 内容を入力してください。",
      hotel_hotel_membership_detail_title: "会員権タイプ情報",
      hotel_hotel_membership_detail_label_membership_type: "会員権タイプ",
      hotel_hotel_membership_detail_label_description: "内容",
      hotel_hotel_membership_detail_label_date: "日付",
      hotel_hotel_membership_detail_button_go_to_list: "リストへのアクセス",
      hotel_hotel_membership_detail_button_delete: "削除",
      hotel_hotel_membership_detail_button_edit: "修整",
      hotel_hotel_membership_edit_title: "会員権タイプ情報",
      hotel_hotel_membership_edit_label_membership_type: "会員権タイプ",
      hotel_hotel_membership_edit_label_description: "内容",
      hotel_hotel_membership_edit_label_date: "日付",
      hotel_hotel_membership_edit_button_cancel: "キャンセル",
      hotel_hotel_membership_edit_button_delete: "削除",
      hotel_hotel_membership_edit_button_ok: "確認",
      hotel_hotel_membership_edit_validation_membership_type: "* 会員券タイプを入力してください。",
      hotel_hotel_membership_edit_validation_description: "* 内容を入力してください。",
      hotel_Title_list_title: "呼称一覧",
      hotel_Title_list_placeholder_search: "検索",
      hotel_Title_list_label_title: "呼称",
      hotel_Title_list_label_description: "内容",
      hotel_Title_list_label_date: "日付",
      hotel_Title_list_guide_empty: "リストが空です",
      hotel_Title_list_button_delete: "削除",
      hotel_Title_list_button_sync: "同期化",
      hotel_Title_list_button_add: "追加",
      hotel_Title_add_title: "追加",
      hotel_Title_add_label_title: "呼称",
      hotel_Title_add_label_description: "内容",
      hotel_Title_add_button_go_to_list: "リストへのアクセス",
      hotel_Title_add_button_add: "追加",
      hotel_Title_detail_title: "呼称情報",
      hotel_Title_detail_label_title: "呼称",
      hotel_Title_detail_label_description: "内容",
      hotel_Title_detail_label_date: "日付",
      hotel_Title_detail_button_cancel: "キャンセル",
      hotel_Title_detail_button_go_to_list: "リストへのアクセス",
      hotel_Title_detail_button_delete: "削除",
      hotel_hotel_card_type_list_title: "カードタイプ",
      hotel_hotel_card_type_list_label_ok: "番号",
      hotel_hotel_card_type_list_label_value: "値段",
      hotel_hotel_card_type_list_label_description: "内容",
      hotel_hotel_payment_list_title: "決済リスト",
      hotel_hotel_payment_list_placeholder_user_name: "ユーザー名",
      hotel_hotel_payment_list_placeholder_search: "検索",
      hotel_hotel_payment_list_label_no: "番号",
      hotel_hotel_payment_list_label_reservation_id: "予約ID",
      hotel_hotel_payment_list_label_user_name: "ユーザー名",
      hotel_hotel_payment_list_label_long_info: "ロング情報",
      hotel_hotel_payment_list_label_amount: "金額",
      hotel_hotel_payment_list_label_unit: "単位",
      hotel_hotel_payment_list_label_pg_status_code: "PGステータスコード",
      hotel_hotel_payment_list_label_pg_status_message: "PGステータスメッセージ",
      hotel_hotel_payment_list_label_date: "日付",
      hotel_hotel_payment_list_guide_empty: "リストが空です",
      hotel_hotel_payment_detail_label_order_no: "注文番号",
      hotel_hotel_payment_detail_label_user: "使用者",
      hotel_hotel_payment_detail_label_user_profile_id: "プロフィールID",
      hotel_hotel_payment_detail_label_user_title: "呼称",
      hotel_hotel_payment_detail_label_user_first_name: "名前",
      hotel_hotel_payment_detail_label_user_last_name: "性",
      hotel_hotel_payment_detail_label_user_email: "Eメール",
      hotel_hotel_payment_detail_label_user_date: "日付",
      hotel_hotel_payment_detail_label_reservation: "予約",
      hotel_hotel_payment_detail_label_reservation_confirmation_no: "予約番号",
      hotel_hotel_payment_detail_label_reservation_reservation_id: "予約ID",
      hotel_hotel_payment_detail_label_reservation_reservation_status: "予約状態",
      hotel_hotel_payment_detail_label_reservation_check_in_date: "チェックインの日付",
      hotel_hotel_payment_detail_label_reservation_check_out_date: "チェックアウトの日付",
      hotel_hotel_payment_detail_label_payment: "決済",
      hotel_hotel_payment_detail_button_go_to_list: "リストへのアクセス",
      hotel_hotel_payment_detail_button_go_to_log: "ログリストへ行く",
      hotel_hotel_payment_detail_label_payment_order_no: "注文番号",
      hotel_hotel_payment_detail_label_payment_reservation_id: "予約ID",
      hotel_hotel_payment_detail_label_payment_long_info: "ロング情報",
      hotel_hotel_payment_detail_label_payment_folio_view_no: "ポリオ照会番号",
      hotel_hotel_payment_detail_label_payment_amount: "金額",
      hotel_hotel_payment_detail_label_payment_unit: "ユニット",
      hotel_hotel_payment_detail_label_payment_pg_order_no: "PG 注文番号",
      hotel_hotel_payment_detail_label_payment_pg_status: "PG状態",
      hotel_hotel_payment_detail_label_payment_pg_status_code: "PGステータスコード",
      hotel_hotel_payment_detail_label_payment_pg_status_message: "PGステータスメッセージ",
      hotel_hotel_payment_detail_label_payment_pg_amount: "PG 金額",
      hotel_hotel_payment_detail_label_payment_pg_unit: "PG単位",
      hotel_hotel_payment_detail_label_payment_pg_billing_no: "PG決済番号",
      hotel_hotel_payment_detail_label_payment_confirmed_at: "確認時間",
      hotel_hotel_payment_detail_label_payment_created_at: "生成時間",
      hotel_hotel_payment_detail_label_payment_updated_at: "アップデート時間",
      hotel_hotel_payment_detail_label_schedule: "スケジュール",
      hotel_hotel_payment_detail_label_schedule_rule_type: "ルールタイプ",
      hotel_hotel_payment_detail_label_schedule_name: "名前",
      hotel_hotel_payment_detail_label_schedule_description: "説明",
      hotel_hotel_payment_detail_label_schedule_rate_code: "料金コード",
      hotel_hotel_payment_detail_label_schedule_begin_date: "開始日",
      hotel_hotel_payment_detail_label_schedule_end_date: "最終日",
      hotel_hotel_payment_detail_label_schedule_day_of_the_week: "曜日",
      hotel_hotel_payment_detail_label_schedule_created_at: "生成時間",
      hotel_hotel_payment_detail_label_schedule_updated_at: "アップデート時間",
      hotel_hotel_payment_detail_label_reservation_rule: "予約規則",
      hotel_hotel_payment_detail_label_rule_name: "規則名",
      hotel_hotel_payment_detail_label_description: "説明",
      hotel_hotel_payment_detail_label_reservation_amount: "予約料金",
      hotel_hotel_payment_detail_label_day_before_arrival: "チェックイン~日前",
      hotel_hotel_payment_detail_label_before_time: "チェックイン~時間前",
      hotel_hotel_payment_detail_label_deposit_rule: "敷金規則",
      hotel_hotel_payment_detail_label_type: "タイプ",
      hotel_hotel_payment_detail_label_deposit_amount: "敷金の金額",
      hotel_hotel_payment_detail_label_cancellation_rule: "キャンセル規則",
      hotel_hotel_payment_detail_label_no_show_rule: "ノーショー規則",
      hotel_hotel_payment_detail_label_source_code: "ソースコード",
      hotel_hotel_payment_detail_label_penalty_amount: "ペナルティ金額",
      hotel_hotel_payment_detail_label_guarantee_type: "ゲレンテ·タイプ",
      hotel_hotel_payment_detail_label_posting_rule: "請求規則",
      hotel_payment_history_log_list_title: "決済ログリスト",
      hotel_payment_history_log_list_label_no: "番号",
      hotel_payment_history_log_list_label_type: "タイプ",
      hotel_payment_history_log_list_label_method: "方法",
      hotel_payment_history_log_list_label_path: "経路",
      hotel_payment_history_log_list_label_status: "状態",
      hotel_payment_history_log_list_label_success: "成功",
      hotel_payment_history_log_list_label_created_at: "生成時間",
      hotel_payment_history_log_list_guide_empty: "リストが空です",
      hotel_payment_history_log_list_button_go_to_detail: "詳細に行く",
      hotel_payment_history_log_detail_title: "決済ログの詳細情報",
      hotel_payment_history_log_detail_label_id: "ID",
      hotel_payment_history_log_detail_label_hotel_id: "ホテルID",
      hotel_payment_history_log_detail_label_payment_id: "決済ID",
      hotel_payment_history_log_detail_label_transfer_code: "通信コード",
      hotel_payment_history_log_detail_label_type: "タイプ",
      hotel_payment_history_log_detail_label_method: "方法",
      hotel_payment_history_log_detail_label_path: "経路",
      hotel_payment_history_log_detail_label_header: "前書き",
      hotel_payment_history_log_detail_label_param: "変数",
      hotel_payment_history_log_detail_label_body: "本文",
      hotel_payment_history_log_detail_label_status: "状態",
      hotel_payment_history_log_detail_label_session: "セッション",
      hotel_payment_history_log_detail_label_created_at: "生成時間",
      hotel_payment_history_log_detail_button_go_to_list: "リストへのアクセス",
      hotel_hotel_reservation_email_edit_title: "予約Eメールフォーム",
      hotel_hotel_reservation_email_edit_select_kr: "韓国語",
      hotel_hotel_reservation_email_edit_select_en: "英語",
      hotel_hotel_reservation_email_edit_select_cn: "中国語",
      hotel_hotel_reservation_email_edit_select_jp: "日本語",
      hotel_hotel_reservation_email_edit_select_ms: "MS",
      hotel_hotel_reservation_email_edit_button_remove: "除去",
      hotel_hotel_reservation_email_edit_button_append: "追加",
      hotel_hotel_reservation_email_edit_button_save: "貯蔵",
      hotel_hotel_reservation_email_edit_toolbar_bold: "太く",
      hotel_hotel_reservation_email_edit_toolbar_italic: "イタリック体",
      hotel_hotel_reservation_email_edit_toolbar_underline: "下線",
      hotel_hotel_reservation_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_reservation_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_reservation_email_edit_toolbar_font_family: "フォント集",
      hotel_hotel_reservation_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_reservation_email_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_reservation_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_reservation_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_email_edit_toolbar_table: "テーブル",
      hotel_hotel_reservation_email_edit_toolbar_unordered_list: "迷走文目録",
      hotel_hotel_reservation_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_reservation_email_edit_toolbar_paragraph: "短絡",
      hotel_hotel_reservation_email_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_reservation_email_edit_toolbar_picture: "イメージ",
      hotel_hotel_reservation_email_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_reservation_email_edit_toolbar_full_screen: "全画面",
      hotel_hotel_reservation_email_edit_toolbar_help: "ヘルプ",
      hotel_hotel_reservation_email_edit_toolbar_tip: "チップ",
      hotel_hotel_reservation_email_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_reservation_email_edit_validation_content: "* 内容を入力してください。",
      hotel_hotel_reservation_email_edit_validation_language_code: "* 重複しない言語コードを選択してください。",
      hotel_hotel_reservation_email_detail_title: "予約Eメールメッセージ",
      hotel_hotel_reservation_email_detail_label_confurmation_number: "予約番号",
      hotel_hotel_reservation_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_email_detail_label_reservation_id: "予約ID",
      hotel_hotel_reservation_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_email_detail_label_arrival_date: "到着日",
      hotel_hotel_reservation_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_email_detail_label_departure_date: "出発の日",
      hotel_hotel_reservation_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_email_detail_label_room_type: "客室タイプ",
      hotel_hotel_reservation_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_email_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_reservation_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_email_detail_label_room_rate: "客室料金",
      hotel_hotel_reservation_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_email_detail_label_total_rate: "総料金",
      hotel_hotel_reservation_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_email_detail_label_current_balance: "利用金額",
      hotel_hotel_reservation_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_email_detail_label_room_number: "客室番号",
      hotel_hotel_reservation_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_email_detail_label_adults: "聖人",
      hotel_hotel_reservation_email_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_email_detail_label_children: "幼児/子供",
      hotel_hotel_reservation_email_detail_example_children: "{{children}}",
      hotel_hotel_reservation_email_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_reservation_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_email_detail_label_name_title: "名前呼称",
      hotel_hotel_reservation_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_email_detail_label_first_name: "英文の名前",
      hotel_hotel_reservation_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_email_detail_label_last_name: "英文性",
      hotel_hotel_reservation_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_email_detail_label_country_code: "国コード",
      hotel_hotel_reservation_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_email_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_reservation_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_email_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_reservation_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_email_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_reservation_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_email_detail_label_phone: "携帯番号",
      hotel_hotel_reservation_email_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_email_detail_label_email: "Eメール",
      hotel_hotel_reservation_email_detail_example_email: "{{email}}",
      hotel_hotel_reservation_email_detail_label_accompany: "同伴のお客様",
      hotel_hotel_reservation_email_detail_example_accompany: "{{accompany}}",
      hotel_hotel_reservation_pdf_edit_title: "予約PDF書式",
      hotel_hotel_reservation_pdf_edit_select_kr: "韓国語",
      hotel_hotel_reservation_pdf_edit_select_en: "英語",
      hotel_hotel_reservation_pdf_edit_select_cn: "中国語",
      hotel_hotel_reservation_pdf_edit_select_jp: "日本語",
      hotel_hotel_reservation_pdf_edit_select_ms: "MS",
      hotel_hotel_reservation_pdf_edit_button_remove: "除去",
      hotel_hotel_reservation_pdf_edit_button_append: "追加",
      hotel_hotel_reservation_pdf_edit_button_save: "貯蔵",
      hotel_hotel_reservation_pdf_edit_toolbar_bold: "太く",
      hotel_hotel_reservation_pdf_edit_toolbar_italic: "イタリック",
      hotel_hotel_reservation_pdf_edit_toolbar_underline: "下線",
      hotel_hotel_reservation_pdf_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_reservation_pdf_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_reservation_pdf_edit_toolbar_font_family: "フォント集",
      hotel_hotel_reservation_pdf_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_reservation_pdf_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_reservation_pdf_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_pdf_edit_toolbar_table: "テーブル",
      hotel_hotel_reservation_pdf_edit_toolbar_unordered_list: "迷走文目録",
      hotel_hotel_reservation_pdf_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_reservation_pdf_edit_toolbar_paragraph: "短絡",
      hotel_hotel_reservation_pdf_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_reservation_pdf_edit_toolbar_picture: "写真",
      hotel_hotel_reservation_pdf_edit_toolbar_code_view: "コード照会",
      hotel_hotel_reservation_pdf_edit_toolbar_full_screen: "全画面",
      hotel_hotel_reservation_pdf_edit_toolbar_help: "ヘルプ",
      hotel_hotel_reservation_pdf_edit_toolbar_tip: "チップ",
      hotel_hotel_reservation_pdf_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_hotel_reservation_pdf_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_reservation_pdf_edit_validation_content: "* 内容を入力してください。",
      hotel_hotel_reservation_pdf_edit_validation_language_code: "* 重複しない言語コードを選択してください。",
      hotel_hotel_reservation_pdf_detail_title: "予約PDFメッセージ",
      hotel_hotel_reservation_pdf_detail_label_confurmation_number: "予約番号",
      hotel_hotel_reservation_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_pdf_detail_label_reservation_id: "予約ID",
      hotel_hotel_reservation_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_pdf_detail_label_arrival_date: "到着日",
      hotel_hotel_reservation_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_pdf_detail_label_departure_date: "出発の日",
      hotel_hotel_reservation_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_pdf_detail_label_room_type: "客室タイプ",
      hotel_hotel_reservation_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_reservation_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate: "客室料金",
      hotel_hotel_reservation_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_pdf_detail_label_total_rate: "総料金",
      hotel_hotel_reservation_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_pdf_detail_label_current_balance: "利用金額",
      hotel_hotel_reservation_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_pdf_detail_label_room_number: "客室番号",
      hotel_hotel_reservation_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_pdf_detail_label_adults: "聖人",
      hotel_hotel_reservation_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_pdf_detail_label_children: "幼児/子供",
      hotel_hotel_reservation_pdf_detail_example_children: "{{children}}",
      hotel_hotel_reservation_pdf_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_reservation_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_pdf_detail_label_name_title: "名前呼称",
      hotel_hotel_reservation_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_pdf_detail_label_first_name: "英文の名前",
      hotel_hotel_reservation_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_pdf_detail_label_last_name: "英文性",
      hotel_hotel_reservation_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_pdf_detail_label_country_code: "国コード",
      hotel_hotel_reservation_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_reservation_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_reservation_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_reservation_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_pdf_detail_label_phone: "携帯番号",
      hotel_hotel_reservation_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_pdf_detail_label_email: "Eメール",
      hotel_hotel_reservation_pdf_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_email_edit_title: "同伴のお客様の予約Eメールフォーム",
      hotel_hotel_reservation_accompany_email_edit_select_kr: "韓国語",
      hotel_hotel_reservation_accompany_email_edit_select_en: "英語",
      hotel_hotel_reservation_accompany_email_edit_select_cn: "中国語",
      hotel_hotel_reservation_accompany_email_edit_select_jp: "日本語",
      hotel_hotel_reservation_accompany_email_edit_select_ms: "MS",
      hotel_hotel_reservation_accompany_email_edit_button_remove: "除去",
      hotel_hotel_reservation_accompany_email_edit_button_append: "追加",
      hotel_hotel_reservation_accompany_email_edit_button_save: "貯蔵",
      hotel_hotel_reservation_accompany_email_edit_toolbar_bold: "太く",
      hotel_hotel_reservation_accompany_email_edit_toolbar_italic: "イタリック体",
      hotel_hotel_reservation_accompany_email_edit_toolbar_underline: "下線",
      hotel_hotel_reservation_accompany_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_reservation_accompany_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_family: "フォント集",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_accompany_email_edit_toolbar_table: "テーブル",
      hotel_hotel_reservation_accompany_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_hotel_reservation_accompany_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_reservation_accompany_email_edit_toolbar_paragraph: "短絡",
      hotel_hotel_reservation_accompany_email_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_reservation_accompany_email_edit_toolbar_picture: "写真",
      hotel_hotel_reservation_accompany_email_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_reservation_accompany_email_edit_toolbar_full_screen: "全画面",
      hotel_hotel_reservation_accompany_email_edit_toolbar_help: "ヘルプ",
      hotel_hotel_reservation_accompany_email_edit_toolbar_tip: "チップ",
      hotel_hotel_reservation_accompany_email_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_reservation_accompany_email_detail_title: "同伴のお客様の予約メールメッセージ",
      hotel_hotel_reservation_accompany_email_detail_label_confurmation_number: "予約番号",
      hotel_hotel_reservation_accompany_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_accompany_email_detail_label_reservation_id: "予約ID",
      hotel_hotel_reservation_accompany_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_accompany_email_detail_label_arrival_date: "到着日",
      hotel_hotel_reservation_accompany_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_accompany_email_detail_label_departure_date: "出発の日",
      hotel_hotel_reservation_accompany_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_type: "客室タイプ",
      hotel_hotel_reservation_accompany_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate: "客室料金",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_accompany_email_detail_label_total_rate: "総料金",
      hotel_hotel_reservation_accompany_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_accompany_email_detail_label_current_balance: "利用金額",
      hotel_hotel_reservation_accompany_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_number: "客室番号",
      hotel_hotel_reservation_accompany_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_accompany_email_detail_label_adults: "聖人",
      hotel_hotel_reservation_accompany_email_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_accompany_email_detail_label_children: "幼児/子供",
      hotel_hotel_reservation_accompany_email_detail_example_children: "{{children}}",
      hotel_hotel_reservation_accompany_email_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_reservation_accompany_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_accompany_email_detail_label_name_title: "名前呼称",
      hotel_hotel_reservation_accompany_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_first_name: "英文の名前",
      hotel_hotel_reservation_accompany_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_last_name: "英文性",
      hotel_hotel_reservation_accompany_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_accompany_email_detail_label_country_code: "国コード",
      hotel_hotel_reservation_accompany_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_accompany_email_detail_label_phone: "携帯番号",
      hotel_hotel_reservation_accompany_email_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_accompany_email_detail_label_email: "Eメール",
      hotel_hotel_reservation_accompany_email_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_title: "同伴のお客様の呼称",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_first_name: "同伴のお客様の英語名",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_last_name: "同伴のお客様の英文性",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_edit_title: "同伴者予約変更メール",
      hotel_document_accom_reservation_change_edit_select_kr: "韓国語",
      hotel_document_accom_reservation_change_edit_select_en: "英語",
      hotel_document_accom_reservation_change_edit_select_cn: "中国語",
      hotel_document_accom_reservation_change_edit_select_jp: "日本語",
      hotel_document_accom_reservation_change_edit_select_ms: "MS",
      hotel_document_accom_reservation_change_edit_button_remove: "除去",
      hotel_document_accom_reservation_change_edit_button_append: "追加",
      hotel_document_accom_reservation_change_edit_button_save: "貯蔵",
      hotel_document_accom_reservation_change_edit_toolbar_bold: "太く",
      hotel_document_accom_reservation_change_edit_toolbar_italic: "イタリック体",
      hotel_document_accom_reservation_change_edit_toolbar_underline: "下線",
      hotel_document_accom_reservation_change_edit_toolbar_strikethrough: "キャンセル線",
      hotel_document_accom_reservation_change_edit_toolbar_remove_font_style: "書体取り",
      hotel_document_accom_reservation_change_edit_toolbar_font_family: "フォント集",
      hotel_document_accom_reservation_change_edit_toolbar_font_size: "フォントサイズ",
      hotel_document_accom_reservation_change_edit_toolbar_text_color: "テキストカラー",
      hotel_document_accom_reservation_change_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_document_accom_reservation_change_edit_toolbar_recent_color: "最近の使用色",
      hotel_document_accom_reservation_change_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_document_accom_reservation_change_edit_toolbar_table: "テーブル",
      hotel_document_accom_reservation_change_edit_toolbar_unordered_list: "未注文リスト",
      hotel_document_accom_reservation_change_edit_toolbar_ordered_list: "注文リスト",
      hotel_document_accom_reservation_change_edit_toolbar_paragraph: "短絡",
      hotel_document_accom_reservation_change_edit_toolbar_line_height: "線の高さ",
      hotel_document_accom_reservation_change_edit_toolbar_picture: "写真",
      hotel_document_accom_reservation_change_edit_toolbar_code_view: "コードを見ること",
      hotel_document_accom_reservation_change_edit_toolbar_full_screen: "全画面",
      hotel_document_accom_reservation_change_edit_toolbar_help: "ヘルプ",
      hotel_document_accom_reservation_change_edit_toolbar_tip: "チップ",
      hotel_document_accom_reservation_change_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_document_accom_reservation_change_edit_placeholder_content: "内容を入力してください",
      hotel_document_accom_reservation_change_detail_title: "同伴者予約変更メール",
      hotel_document_accom_reservation_change_detail_label_confirmation_number: "予約番号",
      hotel_document_accom_reservation_change_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_document_accom_reservation_change_detail_label_accom_first_name: "同伴者英名",
      hotel_document_accom_reservation_change_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_change_detail_label_accom_last_name: "同伴者の英姓",
      hotel_document_accom_reservation_change_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_detail_label_first_name: "英文の名前",
      hotel_document_accom_reservation_change_detail_example_first_name: "{{firstName}}",
      hotel_document_accom_reservation_change_detail_label_last_name: "英文性",
      hotel_document_accom_reservation_change_detail_example_last_name: "{{lastName}}",
      hotel_document_accom_reservation_change_detail_label_room_type: "客室タイプ",
      hotel_document_accom_reservation_change_detail_example_room_type: "{{roomType}}",
      hotel_document_accom_reservation_change_detail_label_arrival_date: "到着日",
      hotel_document_accom_reservation_change_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_document_accom_reservation_change_detail_label_departure_date: "出発の日",
      hotel_document_accom_reservation_change_detail_example_departure_date: "{{departureDate}}",
      hotel_document_accom_reservation_change_detail_label_accom_title: "同伴者呼称",
      hotel_document_accom_reservation_change_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_change_detail_label_adults: "聖人",
      hotel_document_accom_reservation_change_detail_example_adults: "{{adults}}",
      hotel_document_accom_reservation_change_detail_label_night: "日数",
      hotel_document_accom_reservation_change_detail_example_night: "{{night}}",
      hotel_document_accom_reservation_change_detail_label_room_rate: "客室料金",
      hotel_document_accom_reservation_change_detail_example_room_rate: "{{roomRate}}",
      hotel_document_accom_reservation_change_detail_label_room_rate_currency: "客室料金の通話",
      hotel_document_accom_reservation_change_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_document_accom_reservation_change_detail_label_total_rate: "総料金",
      hotel_document_accom_reservation_change_detail_example_total_rate: "{{totalRate}}",
      hotel_document_accom_reservation_change_detail_label_cancellation: "キャンセル政策",
      hotel_document_accom_reservation_change_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_document_accom_reservation_cancel_edit_title: "同伴者の予約キャンセルメール",
      hotel_document_accom_reservation_cancel_edit_select_kr: "韓国語",
      hotel_document_accom_reservation_cancel_edit_select_en: "英語",
      hotel_document_accom_reservation_cancel_edit_select_cn: "中国語",
      hotel_document_accom_reservation_cancel_edit_select_jp: "日本語",
      hotel_document_accom_reservation_cancel_edit_select_ms: "MS",
      hotel_document_accom_reservation_cancel_edit_button_remove: "除去",
      hotel_document_accom_reservation_cancel_edit_button_append: "追加",
      hotel_document_accom_reservation_cancel_edit_button_save: "貯蔵",
      hotel_document_accom_reservation_cancel_edit_toolbar_bold: "太く",
      hotel_document_accom_reservation_cancel_edit_toolbar_italic: "イタリック体",
      hotel_document_accom_reservation_cancel_edit_toolbar_underline: "下線",
      hotel_document_accom_reservation_cancel_edit_toolbar_strikethrough: "キャンセル線",
      hotel_document_accom_reservation_cancel_edit_toolbar_remove_font_style: "書体取り",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_family: "フォント集",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_size: "フォントサイズ",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_color: "テキストカラー",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_color: "最近の使用色",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_document_accom_reservation_cancel_edit_toolbar_table: "テーブル",
      hotel_document_accom_reservation_cancel_edit_toolbar_unordered_list: "未注文リスト",
      hotel_document_accom_reservation_cancel_edit_toolbar_ordered_list: "注文リスト",
      hotel_document_accom_reservation_cancel_edit_toolbar_paragraph: "短絡",
      hotel_document_accom_reservation_cancel_edit_toolbar_line_height: "線の高さ",
      hotel_document_accom_reservation_cancel_edit_toolbar_picture: "写真",
      hotel_document_accom_reservation_cancel_edit_toolbar_code_view: "コードを見ること",
      hotel_document_accom_reservation_cancel_edit_toolbar_full_screen: "全画面",
      hotel_document_accom_reservation_cancel_edit_toolbar_help: "ヘルプ",
      hotel_document_accom_reservation_cancel_edit_toolbar_tip: "チップ",
      hotel_document_accom_reservation_cancel_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_document_accom_reservation_cancel_edit_placeholder_content: "内容を入力してください",
      hotel_document_accom_reservation_cancel_detail_title: "同伴者の予約キャンセルメール",
      hotel_document_accom_reservation_cancel_detail_label_confirmation_number: "予約番号",
      hotel_document_accom_reservation_cancel_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_first_name: "同伴者英名",
      hotel_document_accom_reservation_cancel_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_last_name: "同伴者の英姓",
      hotel_document_accom_reservation_cancel_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_cancel_detail_label_first_name: "英文の名前",
      hotel_document_accom_reservation_cancel_detail_example_first_name: "{{firstName}}",
      hotel_document_accom_reservation_cancel_detail_label_last_name: "英文性",
      hotel_document_accom_reservation_cancel_detail_example_last_name: "{{lastName}}",
      hotel_document_accom_reservation_cancel_detail_label_room_type: "客室タイプ",
      hotel_document_accom_reservation_cancel_detail_example_room_type: "{{roomType}}",
      hotel_document_accom_reservation_cancel_detail_label_arrival_date: "到着日",
      hotel_document_accom_reservation_cancel_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_document_accom_reservation_cancel_detail_label_departure_date: "出発の日",
      hotel_document_accom_reservation_cancel_detail_example_departure_date: "{{departureDate}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_title: "同伴者呼称",
      hotel_document_accom_reservation_cancel_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_cancel_detail_label_adults: "聖人",
      hotel_document_accom_reservation_cancel_detail_example_adults: "{{adults}}",
      hotel_document_accom_reservation_cancel_detail_label_night: "日数",
      hotel_document_accom_reservation_cancel_detail_example_night: "{{night}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate: "客室料金",
      hotel_document_accom_reservation_cancel_detail_example_room_rate: "{{roomRate}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate_currency: "客室料金の通話",
      hotel_document_accom_reservation_cancel_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_document_accom_reservation_cancel_detail_label_total_rate: "総料金",
      hotel_document_accom_reservation_cancel_detail_example_total_rate: "{{totalRate}}",
      hotel_document_accom_reservation_cancel_detail_label_cancellation: "キャンセル政策",
      hotel_document_accom_reservation_cancel_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_hotel_reservation_accompany_pdf_edit_title: "同伴者予約PDF書式",
      hotel_hotel_reservation_accompany_pdf_edit_select_kr: "韓国語",
      hotel_hotel_reservation_accompany_pdf_edit_select_en: "英語",
      hotel_hotel_reservation_accompany_pdf_edit_select_cn: "中国語",
      hotel_hotel_reservation_accompany_pdf_edit_select_jp: "日本語",
      hotel_hotel_reservation_accompany_pdf_edit_select_ms: "MS",
      hotel_hotel_reservation_accompany_pdf_edit_button_remove: "除去",
      hotel_hotel_reservation_accompany_pdf_edit_button_append: "追加",
      hotel_hotel_reservation_accompany_pdf_edit_button_save: "貯蔵",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_bold: "太く",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_italic: "イタリック体",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_underline: "下線",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_family: "フォント集",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_table: "テーブル",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_unordered_list: "未注文リスト",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_paragraph: "短絡",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_picture: "写真",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_full_screen: "全画面",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_help: "ヘルプ",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_tip: "チップ",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_hotel_reservation_accompany_pdf_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_reservation_accompany_pdf_detail_title: "同伴のお客様の予約PDFメッセージ",
      hotel_hotel_reservation_accompany_pdf_detail_label_confurmation_number: "予約番号",
      hotel_hotel_reservation_accompany_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_reservation_id: "予約ID",
      hotel_hotel_reservation_accompany_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_arrival_date: "到着日",
      hotel_hotel_reservation_accompany_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_departure_date: "出発の日",
      hotel_hotel_reservation_accompany_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_type: "客室タイプ",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate: "客室料金",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_total_rate: "総料金",
      hotel_hotel_reservation_accompany_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_current_balance: "利用金額",
      hotel_hotel_reservation_accompany_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_number: "客室番号",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_adults: "聖人",
      hotel_hotel_reservation_accompany_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_children: "幼児/子供",
      hotel_hotel_reservation_accompany_pdf_detail_example_children: "{{children}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_reservation_accompany_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_name_title: "名前呼称",
      hotel_hotel_reservation_accompany_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_first_name: "英文の名前",
      hotel_hotel_reservation_accompany_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_last_name: "英文性",
      hotel_hotel_reservation_accompany_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_country_code: "国コード",
      hotel_hotel_reservation_accompany_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_phone: "携帯番号",
      hotel_hotel_reservation_accompany_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_email: "Eメール",
      hotel_hotel_reservation_accompany_pdf_detail_example_email: "{{email}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_title: "同伴のお客様の呼称",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_first_name: "同伴のお客様の英語名",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_last_name: "同伴のお客様の英文性",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_hotel_folio_email_edit_title: "ポリオEメールフォーム",
      hotel_hotel_folio_email_edit_select_kr: "韓国語",
      hotel_hotel_folio_email_edit_select_en: "英語",
      hotel_hotel_folio_email_edit_select_cn: "中国語",
      hotel_hotel_folio_email_edit_select_jp: "日本語",
      hotel_hotel_folio_email_edit_select_ms: "MS",
      hotel_hotel_folio_email_edit_button_remove: "除去",
      hotel_hotel_folio_email_edit_button_append: "追加",
      hotel_hotel_folio_email_edit_button_save: "貯蔵",
      hotel_hotel_folio_email_edit_toolbar_bold: "太く",
      hotel_hotel_folio_email_edit_toolbar_italic: "イタリック体",
      hotel_hotel_folio_email_edit_toolbar_underline: "下線",
      hotel_hotel_folio_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_folio_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_folio_email_edit_toolbar_font_family: "フォント集",
      hotel_hotel_folio_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_folio_email_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_folio_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_folio_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_folio_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_folio_email_edit_toolbar_table: "テーブル",
      hotel_hotel_folio_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_hotel_folio_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_folio_email_edit_toolbar_paragraph: "短絡",
      hotel_hotel_folio_email_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_folio_email_edit_toolbar_picture: "写真",
      hotel_hotel_folio_email_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_folio_email_edit_toolbar_full_screen: "全画面",
      hotel_hotel_folio_email_edit_toolbar_help: "ヘルプ",
      hotel_hotel_folio_email_edit_toolbar_tip: "チップ",
      hotel_hotel_folio_email_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_folio_email_detail_title: "ポリオ電子メール文字",
      hotel_hotel_folio_email_detail_label_confurmation_number: "予約番号",
      hotel_hotel_folio_email_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_folio_email_detail_label_reservation_id: "予約ID",
      hotel_hotel_folio_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_folio_email_detail_label_arrival_date: "到着日",
      hotel_hotel_folio_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_folio_email_detail_label_departure_date: "出発の日",
      hotel_hotel_folio_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_folio_email_detail_label_room_type: "客室タイプ",
      hotel_hotel_folio_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_folio_email_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_folio_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_folio_email_detail_label_room_rate: "客室料金",
      hotel_hotel_folio_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_folio_email_detail_label_total_rate: "総料金",
      hotel_hotel_folio_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_folio_email_detail_label_current_balance: "残高",
      hotel_hotel_folio_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_folio_email_detail_label_room_number: "客室番号",
      hotel_hotel_folio_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_folio_email_detail_label_adults: "聖人",
      hotel_hotel_folio_email_detail_example_adults: "{{adults}}",
      hotel_hotel_folio_email_detail_label_children: "児童",
      hotel_hotel_folio_email_detail_example_children: "{{children}}",
      hotel_hotel_folio_email_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_folio_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_folio_email_detail_label_name_title: "名前呼称",
      hotel_hotel_folio_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_folio_email_detail_label_first_name: "英文の名前",
      hotel_hotel_folio_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_folio_email_detail_label_last_name: "英文性",
      hotel_hotel_folio_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_folio_email_detail_label_country_code: "国コード",
      hotel_hotel_folio_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_folio_email_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_folio_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_folio_email_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_folio_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_email_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_folio_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_folio_email_detail_label_phone: "携帯番号",
      hotel_hotel_folio_email_detail_example_phone: "{{phone}}",
      hotel_hotel_folio_email_detail_label_email: "Eメール",
      hotel_hotel_folio_email_detail_example_email: "{{email}}",
      hotel_hotel_folio_email_detail_label_text_area: "ポリオテキスト領域",
      hotel_hotel_folio_email_detail_example_text_area: "{{folio_text_area}}",
      hotel_hotel_enroll_profile_edit_title: "登録プロファイル書式",
      hotel_hotel_enroll_profile_edit_select_kr: "韓国語",
      hotel_hotel_enroll_profile_edit_select_en: "英語",
      hotel_hotel_enroll_profile_edit_select_cn: "中国語",
      hotel_hotel_enroll_profile_edit_select_jp: "日本語",
      hotel_hotel_enroll_profile_edit_select_ms: "MS",
      hotel_hotel_enroll_profile_edit_button_remove: "除去",
      hotel_hotel_enroll_profile_edit_button_append: "追加",
      hotel_hotel_enroll_profile_edit_button_save: "貯蔵",
      hotel_hotel_enroll_profile_edit_toolbar_bold: "太く",
      hotel_hotel_enroll_profile_edit_toolbar_italic: "イタリック体",
      hotel_hotel_enroll_profile_edit_toolbar_underline: "下線",
      hotel_hotel_enroll_profile_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_enroll_profile_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_enroll_profile_edit_toolbar_font_family: "フォント集",
      hotel_hotel_enroll_profile_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_enroll_profile_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_enroll_profile_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_enroll_profile_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_enroll_profile_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_enroll_profile_edit_toolbar_table: "テーブル",
      hotel_hotel_enroll_profile_edit_toolbar_unordered_list: "未注文リスト",
      hotel_hotel_enroll_profile_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_enroll_profile_edit_toolbar_paragraph: "短絡",
      hotel_hotel_enroll_profile_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_enroll_profile_edit_toolbar_picture: "写真",
      hotel_hotel_enroll_profile_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_enroll_profile_edit_toolbar_full_screen: "全画面",
      hotel_hotel_enroll_profile_edit_toolbar_help: "ヘルプ",
      hotel_hotel_enroll_profile_edit_toolbar_tip: "チップ",
      hotel_hotel_enroll_profile_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_enroll_profile_detail_title: "登録プロファイル文字",
      hotel_hotel_enroll_profile_detail_label_email: "Eメール",
      hotel_hotel_enroll_profile_detail_example_email: "{{email}}",
      hotel_hotel_enroll_profile_detail_label_hotel_name: "ホテル名",
      hotel_hotel_enroll_profile_detail_example_hotel_name: "{{hotelName}}",
      hotel_hotel_enroll_profile_detail_label_title: "呼称",
      hotel_hotel_enroll_profile_detail_example_title: "{{title}}",
      hotel_hotel_enroll_profile_detail_label_first_name: "英文の名前",
      hotel_hotel_enroll_profile_detail_example_first_name: "{{firstName}}",
      hotel_hotel_enroll_profile_detail_label_last_name: "英文性",
      hotel_hotel_enroll_profile_detail_example_last_name: "{{lastName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_enroll_profile_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_enroll_profile_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_enroll_profile_detail_label_mobile_number: "携帯番号",
      hotel_hotel_enroll_profile_detail_example_mobile_number: "{{mobileNumber}}",
      hotel_hotel_folio_pdf_edit_title: "ポリオ PDF 書式",
      hotel_hotel_folio_pdf_edit_select_kr: "韓国語",
      hotel_hotel_folio_pdf_edit_select_en: "英語",
      hotel_hotel_folio_pdf_edit_select_cn: "中国語",
      hotel_hotel_folio_pdf_edit_select_jp: "日本語",
      hotel_hotel_folio_pdf_edit_select_ms: "MS",
      hotel_hotel_folio_pdf_edit_button_remove: "除去",
      hotel_hotel_folio_pdf_edit_button_append: "追加",
      hotel_hotel_folio_pdf_edit_button_save: "貯蔵",
      hotel_hotel_folio_pdf_edit_toolbar_bold: "太く",
      hotel_hotel_folio_pdf_edit_toolbar_italic: "イタリック体",
      hotel_hotel_folio_pdf_edit_toolbar_underline: "下線",
      hotel_hotel_folio_pdf_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_folio_pdf_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_folio_pdf_edit_toolbar_font_family: "フォント集",
      hotel_hotel_folio_pdf_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_folio_pdf_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_folio_pdf_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_folio_pdf_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_folio_pdf_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_folio_pdf_edit_toolbar_table: "テーブル",
      hotel_hotel_folio_pdf_edit_toolbar_unordered_list: "未注文リスト",
      hotel_hotel_folio_pdf_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_folio_pdf_edit_toolbar_paragraph: "短絡",
      hotel_hotel_folio_pdf_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_folio_pdf_edit_toolbar_picture: "写真",
      hotel_hotel_folio_pdf_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_folio_pdf_edit_toolbar_full_screen: "全画面",
      hotel_hotel_folio_pdf_edit_toolbar_help: "ヘルプ",
      hotel_hotel_folio_pdf_edit_toolbar_tip: "チップ",
      hotel_hotel_folio_pdf_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_hotel_folio_pdf_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_folio_pdf_detail_title: "ポリオPDF文字",
      hotel_hotel_folio_pdf_detail_label_confurmation_number: "予約番号",
      hotel_hotel_folio_pdf_detail_example_confurmation_number: "{{confirmationNumber}}",
      hotel_hotel_folio_pdf_detail_label_reservation_id: "予約ID",
      hotel_hotel_folio_pdf_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_folio_pdf_detail_label_arrival_date: "到着日",
      hotel_hotel_folio_pdf_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_folio_pdf_detail_label_departure_date: "出発の日",
      hotel_hotel_folio_pdf_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_folio_pdf_detail_label_room_type: "客室タイプ",
      hotel_hotel_folio_pdf_detail_example_room_type: "{{roomType}}",
      hotel_hotel_folio_pdf_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_folio_pdf_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_folio_pdf_detail_label_room_rate: "客室料金",
      hotel_hotel_folio_pdf_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_folio_pdf_detail_label_total_rate: "総料金",
      hotel_hotel_folio_pdf_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_folio_pdf_detail_label_current_balance: "残高",
      hotel_hotel_folio_pdf_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_folio_pdf_detail_label_room_number: "客室番号",
      hotel_hotel_folio_pdf_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_folio_pdf_detail_label_adults: "聖人",
      hotel_hotel_folio_pdf_detail_example_adults: "{{adults}}",
      hotel_hotel_folio_pdf_detail_label_children: "児童",
      hotel_hotel_folio_pdf_detail_example_children: "{{children}}",
      hotel_hotel_folio_pdf_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_folio_pdf_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_folio_pdf_detail_label_name_title: "名前呼称",
      hotel_hotel_folio_pdf_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_folio_pdf_detail_label_first_name: "英文の名前",
      hotel_hotel_folio_pdf_detail_example_first_name: "{{firstName}}",
      hotel_hotel_folio_pdf_detail_label_last_name: "英文性",
      hotel_hotel_folio_pdf_detail_example_last_name: "{{lastName}}",
      hotel_hotel_folio_pdf_detail_label_country_code: "国コード",
      hotel_hotel_folio_pdf_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_folio_pdf_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_folio_pdf_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_folio_pdf_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_folio_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_pdf_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_folio_pdf_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_folio_pdf_detail_label_phone: "携帯番号",
      hotel_hotel_folio_pdf_detail_example_phone: "{{phone}}",
      hotel_hotel_folio_pdf_detail_label_email: "Eメール",
      hotel_hotel_folio_pdf_detail_example_email: "{{email}}",
      hotel_hotel_folio_pdf_detail_label_text_area: "ポリオテキスト領域",
      hotel_hotel_folio_pdf_detail_example_text_area: "{{folio_text_area}}",
      hotel_future_booking_change_email_edit_title: "将来の予約変更メール",
      hotel_future_booking_change_email_edit_select_kr: "韓国語",
      hotel_future_booking_change_email_edit_select_en: "英語",
      hotel_future_booking_change_email_edit_select_cn: "中国語",
      hotel_future_booking_change_email_edit_select_jp: "日本語",
      hotel_future_booking_change_email_edit_select_ms: "MS",
      hotel_future_booking_change_email_edit_button_remove: "除去",
      hotel_future_booking_change_email_edit_button_append: "追加",
      hotel_future_booking_change_email_edit_button_save: "貯蔵",
      hotel_future_booking_change_email_edit_toolbar_bold: "太く",
      hotel_future_booking_change_email_edit_toolbar_italic: "イタリック体",
      hotel_future_booking_change_email_edit_toolbar_underline: "下線",
      hotel_future_booking_change_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_future_booking_change_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_future_booking_change_email_edit_toolbar_font_family: "フォント集",
      hotel_future_booking_change_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_future_booking_change_email_edit_toolbar_text_color: "テキストカラー",
      hotel_future_booking_change_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_future_booking_change_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_future_booking_change_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_future_booking_change_email_edit_toolbar_table: "テーブル",
      hotel_future_booking_change_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_future_booking_change_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_future_booking_change_email_edit_toolbar_paragraph: "短絡",
      hotel_future_booking_change_email_edit_toolbar_line_height: "線の高さ",
      hotel_future_booking_change_email_edit_toolbar_picture: "写真",
      hotel_future_booking_change_email_edit_toolbar_code_view: "コードを見ること",
      hotel_future_booking_change_email_edit_toolbar_full_screen: "全画面",
      hotel_future_booking_change_email_edit_toolbar_help: "ヘルプ",
      hotel_future_booking_change_email_edit_toolbar_tip: "チップ",
      hotel_future_booking_change_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_future_booking_change_email_edit_placeholder_content: "内容を入力してください",
      hotel_future_booking_change_email_detail_title: "将来の予約変更メール",
      hotel_future_booking_change_email_detail_label_confirmation_number: "予約番号",
      hotel_future_booking_change_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_change_email_detail_label_first_name: "英文の名前",
      hotel_future_booking_change_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_change_email_detail_label_last_name: "英文性",
      hotel_future_booking_change_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_change_email_detail_label_room_type: "客室タイプ",
      hotel_future_booking_change_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_change_email_detail_label_arrival_date: "到着日",
      hotel_future_booking_change_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_change_email_detail_label_departure_date: "出発の日",
      hotel_future_booking_change_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_change_email_detail_label_name_title: "名前呼称",
      hotel_future_booking_change_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_change_email_detail_label_adults: "聖人",
      hotel_future_booking_change_email_detail_example_adults: "{{adults}}",
      hotel_future_booking_change_email_detail_label_night: "日数",
      hotel_future_booking_change_email_detail_example_night: "{{night}}",
      hotel_future_booking_change_email_detail_label_room_rate: "客室料金",
      hotel_future_booking_change_email_detail_example_room_rate: "{{roomRate}}",
      hotel_future_booking_change_email_detail_label_room_rate_currency: "客室料金の通話",
      hotel_future_booking_change_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_future_booking_change_email_detail_label_total_rate: "総料金",
      hotel_future_booking_change_email_detail_example_total_rate: "{{totalRate}}",
      hotel_future_booking_change_email_detail_label_cancellation: "キャンセル政策",
      hotel_future_booking_change_email_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_future_booking_cancel_email_edit_title: "将来の予約キャンセルメール",
      hotel_future_booking_cancel_email_edit_select_kr: "韓国語",
      hotel_future_booking_cancel_email_edit_select_en: "英語",
      hotel_future_booking_cancel_email_edit_select_cn: "中国語",
      hotel_future_booking_cancel_email_edit_select_jp: "日本語",
      hotel_future_booking_cancel_email_edit_select_ms: "MS",
      hotel_future_booking_cancel_email_edit_button_remove: "除去",
      hotel_future_booking_cancel_email_edit_button_append: "追加",
      hotel_future_booking_cancel_email_edit_button_save: "貯蔵",
      hotel_future_booking_cancel_email_edit_toolbar_bold: "太く",
      hotel_future_booking_cancel_email_edit_toolbar_italic: "イタリック体",
      hotel_future_booking_cancel_email_edit_toolbar_underline: "下線",
      hotel_future_booking_cancel_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_future_booking_cancel_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_future_booking_cancel_email_edit_toolbar_font_family: "フォント集",
      hotel_future_booking_cancel_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_future_booking_cancel_email_edit_toolbar_text_color: "テキストカラー",
      hotel_future_booking_cancel_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_future_booking_cancel_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_future_booking_cancel_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_future_booking_cancel_email_edit_toolbar_table: "テーブル",
      hotel_future_booking_cancel_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_future_booking_cancel_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_future_booking_cancel_email_edit_toolbar_paragraph: "短絡",
      hotel_future_booking_cancel_email_edit_toolbar_line_height: "線の高さ",
      hotel_future_booking_cancel_email_edit_toolbar_picture: "写真",
      hotel_future_booking_cancel_email_edit_toolbar_code_view: "コードを見ること",
      hotel_future_booking_cancel_email_edit_toolbar_full_screen: "全画面",
      hotel_future_booking_cancel_email_edit_toolbar_help: "ヘルプ",
      hotel_future_booking_cancel_email_edit_toolbar_tip: "チップ",
      hotel_future_booking_cancel_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_future_booking_cancel_email_edit_placeholder_content: "内容を入力してください",
      hotel_future_booking_cancel_email_detail_title: "将来の予約キャンセルメール",
      hotel_future_booking_cancel_email_detail_label_confirmation_number: "予約番号",
      hotel_future_booking_cancel_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_cancel_email_detail_label_first_name: "英文の名前",
      hotel_future_booking_cancel_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_cancel_email_detail_label_last_name: "英文性",
      hotel_future_booking_cancel_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_cancel_email_detail_label_room_type: "客室タイプ",
      hotel_future_booking_cancel_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_cancel_email_detail_label_arrival_date: "到着日",
      hotel_future_booking_cancel_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_cancel_email_detail_label_departure_date: "出発の日",
      hotel_future_booking_cancel_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_cancel_email_detail_label_name_title: "名前呼称",
      hotel_future_booking_cancel_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_no_show_email_edit_title: "将来の予約 ノーショー メール",
      hotel_hotel_no_show_email_edit_select_kr: "韓国語",
      hotel_hotel_no_show_email_edit_select_en: "英語",
      hotel_hotel_no_show_email_edit_select_cn: "中国語",
      hotel_hotel_no_show_email_edit_select_jp: "日本語",
      hotel_hotel_no_show_email_edit_select_ms: "MS",
      hotel_hotel_no_show_email_edit_button_remove: "除去",
      hotel_hotel_no_show_email_edit_button_append: "追加",
      hotel_hotel_no_show_email_edit_button_save: "貯蔵",
      hotel_hotel_no_show_email_edit_toolbar_bold: "太く",
      hotel_hotel_no_show_email_edit_toolbar_italic: "イタリック体",
      hotel_hotel_no_show_email_edit_toolbar_underline: "下線",
      hotel_hotel_no_show_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_hotel_no_show_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_hotel_no_show_email_edit_toolbar_font_family: "フォント集",
      hotel_hotel_no_show_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_hotel_no_show_email_edit_toolbar_text_color: "テキストカラー",
      hotel_hotel_no_show_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_hotel_no_show_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_hotel_no_show_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_hotel_no_show_email_edit_toolbar_table: "テーブル",
      hotel_hotel_no_show_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_hotel_no_show_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_hotel_no_show_email_edit_toolbar_paragraph: "短絡",
      hotel_hotel_no_show_email_edit_toolbar_line_height: "線の高さ",
      hotel_hotel_no_show_email_edit_toolbar_picture: "写真",
      hotel_hotel_no_show_email_edit_toolbar_code_view: "コードを見ること",
      hotel_hotel_no_show_email_edit_toolbar_full_screen: "全画面",
      hotel_hotel_no_show_email_edit_toolbar_help: "ヘルプ",
      hotel_hotel_no_show_email_edit_toolbar_tip: "チップ",
      hotel_hotel_no_show_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_hotel_no_show_email_edit_placeholder_content: "内容を入力してください",
      hotel_hotel_no_show_email_detail_title: "将来の予約キャンセルメール",
      hotel_hotel_no_show_email_detail_label_confirmation_number: "予約番号",
      hotel_hotel_no_show_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_hotel_no_show_email_detail_label_reservation_id: "予約ID",
      hotel_hotel_no_show_email_detail_example_reservation_id: "{{reservationID}}",
      hotel_hotel_no_show_email_detail_label_first_name: "英文の名前",
      hotel_hotel_no_show_email_detail_example_first_name: "{{firstName}}",
      hotel_hotel_no_show_email_detail_label_last_name: "英文性",
      hotel_hotel_no_show_email_detail_example_last_name: "{{lastName}}",
      hotel_hotel_no_show_email_detail_label_room_type: "客室タイプ",
      hotel_hotel_no_show_email_detail_example_room_type: "{{roomType}}",
      hotel_hotel_no_show_email_detail_label_arrival_date: "到着日",
      hotel_hotel_no_show_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_hotel_no_show_email_detail_label_departure_date: "出発の日",
      hotel_hotel_no_show_email_detail_example_departure_date: "{{departureDate}}",
      hotel_hotel_no_show_email_detail_label_name_title: "名前呼称",
      hotel_hotel_no_show_email_detail_example_name_title: "{{nameTitle}}",
      hotel_hotel_no_show_email_detail_label_room_rate_currency: "客室料金の通話",
      hotel_hotel_no_show_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_hotel_no_show_email_detail_label_room_rate: "客室料金",
      hotel_hotel_no_show_email_detail_example_room_rate: "{{roomRate}}",
      hotel_hotel_no_show_email_detail_label_total_rate: "総料金",
      hotel_hotel_no_show_email_detail_example_total_rate: "{{totalRate}}",
      hotel_hotel_no_show_email_detail_label_current_balance: "利用金額",
      hotel_hotel_no_show_email_detail_example_current_balance: "{{currentBalance}}",
      hotel_hotel_no_show_email_detail_label_room_number: "客室番号",
      hotel_hotel_no_show_email_detail_example_room_number: "{{roomNumber}}",
      hotel_hotel_no_show_email_detail_label_adults: "聖人",
      hotel_hotel_no_show_email_detail_example_adults: "{{adults}}",
      hotel_hotel_no_show_email_detail_label_children: "幼児/子供",
      hotel_hotel_no_show_email_detail_example_children: "{{children}}",
      hotel_hotel_no_show_email_detail_label_check_out_time: "チェックアウト時間",
      hotel_hotel_no_show_email_detail_example_check_out_time: "{{checkOutTime}}",
      hotel_hotel_no_show_email_detail_label_country_code: "国コード",
      hotel_hotel_no_show_email_detail_example_country_code: "{{countryCode}}",
      hotel_hotel_no_show_email_detail_label_alternative_language_code: "代替言語コード",
      hotel_hotel_no_show_email_detail_example_alternative_language_code: "{{alternativeLanguageCode}}",
      hotel_hotel_no_show_email_detail_label_alternative_first_name: "ハングルの名前",
      hotel_hotel_no_show_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_no_show_email_detail_label_alternative_last_name: "ハングルの姓",
      hotel_hotel_no_show_email_detail_example_alternative_last_name: "{{alternativeLastName}}",
      hotel_hotel_no_show_email_detail_label_phone: "電話番号",
      hotel_hotel_no_show_email_detail_example_phone: "{{phone}}",
      hotel_hotel_no_show_email_detail_label_email: "Eメール",
      hotel_hotel_no_show_email_detail_example_email: "{{email}}",
      hotel_hotel_no_show_email_detail_label_accompany: "同伴のお客様",
      hotel_hotel_no_show_email_detail_example_accompany: "{{accompany}}",
      hotel_future_booking_pack_add_email_edit_title: "将来の予約パッケージを追加メール",
      hotel_future_booking_pack_add_email_edit_select_kr: "韓国語",
      hotel_future_booking_pack_add_email_edit_select_en: "英語",
      hotel_future_booking_pack_add_email_edit_select_cn: "中国語",
      hotel_future_booking_pack_add_email_edit_select_jp: "日本語",
      hotel_future_booking_pack_add_email_edit_select_ms: "MS",
      hotel_future_booking_pack_add_email_edit_button_remove: "除去",
      hotel_future_booking_pack_add_email_edit_button_append: "追加",
      hotel_future_booking_pack_add_email_edit_button_save: "貯蔵",
      hotel_future_booking_pack_add_email_edit_toolbar_bold: "太く",
      hotel_future_booking_pack_add_email_edit_toolbar_italic: "イタリック体",
      hotel_future_booking_pack_add_email_edit_toolbar_underline: "下線",
      hotel_future_booking_pack_add_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_future_booking_pack_add_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_future_booking_pack_add_email_edit_toolbar_font_family: "フォント集",
      hotel_future_booking_pack_add_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_future_booking_pack_add_email_edit_toolbar_text_color: "テキストカラー",
      hotel_future_booking_pack_add_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_future_booking_pack_add_email_edit_toolbar_table: "テーブル",
      hotel_future_booking_pack_add_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_future_booking_pack_add_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_future_booking_pack_add_email_edit_toolbar_paragraph: "短絡",
      hotel_future_booking_pack_add_email_edit_toolbar_line_height: "線の高さ",
      hotel_future_booking_pack_add_email_edit_toolbar_picture: "写真",
      hotel_future_booking_pack_add_email_edit_toolbar_code_view: "コードを見ること",
      hotel_future_booking_pack_add_email_edit_toolbar_full_screen: "全画面",
      hotel_future_booking_pack_add_email_edit_toolbar_help: "ヘルプ",
      hotel_future_booking_pack_add_email_edit_toolbar_tip: "チップ",
      hotel_future_booking_pack_add_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_future_booking_pack_add_email_edit_placeholder_content: "内容を入力してください",
      hotel_future_booking_pack_add_email_detail_title: "未来予約パッケージ追加文字",
      hotel_future_booking_pack_add_email_detail_label_confirmation_number: "予約番号",
      hotel_future_booking_pack_add_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_pack_add_email_detail_label_first_name: "英文の名前",
      hotel_future_booking_pack_add_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_pack_add_email_detail_label_last_name: "英文性",
      hotel_future_booking_pack_add_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_pack_add_email_detail_label_room_type: "客室タイプ",
      hotel_future_booking_pack_add_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_pack_add_email_detail_label_arrival_date: "到着日",
      hotel_future_booking_pack_add_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_pack_add_email_detail_label_departure_date: "出発の日",
      hotel_future_booking_pack_add_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_pack_add_email_detail_label_name_title: "名前呼称",
      hotel_future_booking_pack_add_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_pack_add_email_detail_label_package_code: "パッケージ·コード",
      hotel_future_booking_pack_add_email_detail_example_package_code: "{{packageCode}}",
      hotel_future_booking_pack_add_email_detail_label_package_description: "パッケージ説明",
      hotel_future_booking_pack_add_email_detail_example_package_description: "{{packageDescription}}",
      hotel_future_booking_pack_add_email_detail_label_amount: "金額",
      hotel_future_booking_pack_add_email_detail_example_amount: "{{amount}}",
      hotel_future_booking_pack_add_email_detail_label_package_text_area: "パッケージテキスト領域",
      hotel_future_booking_pack_add_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_pack_delete_email_edit_title: "未来予約パッケージ削除Eメール",
      hotel_future_booking_pack_delete_email_edit_select_kr: "韓国語",
      hotel_future_booking_pack_delete_email_edit_select_en: "英語",
      hotel_future_booking_pack_delete_email_edit_select_cn: "中国語",
      hotel_future_booking_pack_delete_email_edit_select_jp: "日本語",
      hotel_future_booking_pack_delete_email_edit_select_ms: "MS",
      hotel_future_booking_pack_delete_email_edit_button_remove: "除去",
      hotel_future_booking_pack_delete_email_edit_button_append: "追加",
      hotel_future_booking_pack_delete_email_edit_button_save: "貯蔵",
      hotel_future_booking_pack_delete_email_edit_toolbar_bold: "太く",
      hotel_future_booking_pack_delete_email_edit_toolbar_italic: "イタリック体",
      hotel_future_booking_pack_delete_email_edit_toolbar_underline: "下線",
      hotel_future_booking_pack_delete_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_future_booking_pack_delete_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_family: "フォント集",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_color: "テキストカラー",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_future_booking_pack_delete_email_edit_toolbar_table: "テーブル",
      hotel_future_booking_pack_delete_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_future_booking_pack_delete_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_future_booking_pack_delete_email_edit_toolbar_paragraph: "短絡",
      hotel_future_booking_pack_delete_email_edit_toolbar_line_height: "線の高さ",
      hotel_future_booking_pack_delete_email_edit_toolbar_picture: "写真",
      hotel_future_booking_pack_delete_email_edit_toolbar_code_view: "コードを見ること",
      hotel_future_booking_pack_delete_email_edit_toolbar_full_screen: "全画面",
      hotel_future_booking_pack_delete_email_edit_toolbar_help: "ヘルプ",
      hotel_future_booking_pack_delete_email_edit_toolbar_tip: "チップ",
      hotel_future_booking_pack_delete_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_future_booking_pack_delete_email_edit_placeholder_content: "内容を入力してください",
      hotel_future_booking_pack_delete_email_detail_title: "未来予約パッケージ削除文字",
      hotel_future_booking_pack_delete_email_detail_label_confirmation_number: "予約番号",
      hotel_future_booking_pack_delete_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_pack_delete_email_detail_label_first_name: "英文の名前",
      hotel_future_booking_pack_delete_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_pack_delete_email_detail_label_last_name: "英文性",
      hotel_future_booking_pack_delete_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_pack_delete_email_detail_label_room_type: "客室タイプ",
      hotel_future_booking_pack_delete_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_pack_delete_email_detail_label_arrival_date: "到着日",
      hotel_future_booking_pack_delete_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_pack_delete_email_detail_label_departure_date: "出発の日",
      hotel_future_booking_pack_delete_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_pack_delete_email_detail_label_name_title: "名前呼称",
      hotel_future_booking_pack_delete_email_detail_example_name_title: "{{nameTitle}}",
      hotel_future_booking_pack_delete_email_detail_label_package_code: "パッケージ·コード",
      hotel_future_booking_pack_delete_email_detail_example_package_code: "{{packageCode}}",
      hotel_future_booking_pack_delete_email_detail_label_package_description: "パッケージ説明",
      hotel_future_booking_pack_delete_email_detail_example_package_description: "{{packageDescription}}",
      hotel_future_booking_pack_delete_email_detail_label_amount: "金額",
      hotel_future_booking_pack_delete_email_detail_example_amount: "{{amount}}",
      hotel_future_booking_pack_delete_email_detail_label_package_text_area: "パッケージテキスト領域",
      hotel_future_booking_pack_delete_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_accom_add_email_edit_title: "未来の予約同伴者追加メール",
      hotel_future_booking_accom_add_email_edit_select_kr: "韓国語",
      hotel_future_booking_accom_add_email_edit_select_en: "英語",
      hotel_future_booking_accom_add_email_edit_select_cn: "中国語",
      hotel_future_booking_accom_add_email_edit_select_jp: "日本語",
      hotel_future_booking_accom_add_email_edit_select_ms: "MS",
      hotel_future_booking_accom_add_email_edit_button_remove: "除去",
      hotel_future_booking_accom_add_email_edit_button_append: "追加",
      hotel_future_booking_accom_add_email_edit_button_save: "貯蔵",
      hotel_future_booking_accom_add_email_edit_toolbar_bold: "太く",
      hotel_future_booking_accom_add_email_edit_toolbar_italic: "イタリック体",
      hotel_future_booking_accom_add_email_edit_toolbar_underline: "下線",
      hotel_future_booking_accom_add_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_future_booking_accom_add_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_future_booking_accom_add_email_edit_toolbar_font_family: "フォント集",
      hotel_future_booking_accom_add_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_future_booking_accom_add_email_edit_toolbar_text_color: "テキストカラー",
      hotel_future_booking_accom_add_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_future_booking_accom_add_email_edit_toolbar_table: "テーブル",
      hotel_future_booking_accom_add_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_future_booking_accom_add_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_future_booking_accom_add_email_edit_toolbar_paragraph: "短絡",
      hotel_future_booking_accom_add_email_edit_toolbar_line_height: "線の高さ",
      hotel_future_booking_accom_add_email_edit_toolbar_picture: "写真",
      hotel_future_booking_accom_add_email_edit_toolbar_code_view: "コードを見ること",
      hotel_future_booking_accom_add_email_edit_toolbar_full_screen: "全画面",
      hotel_future_booking_accom_add_email_edit_toolbar_help: "ヘルプ",
      hotel_future_booking_accom_add_email_edit_toolbar_tip: "チップ",
      hotel_future_booking_accom_add_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_future_booking_accom_add_email_edit_placeholder_content: "内容を入力してください",
      hotel_future_booking_accom_add_email_detail_title: "未来の予約同伴者追加メール",
      hotel_future_booking_accom_add_email_detail_label_confirmation_number: "予約番号",
      hotel_future_booking_accom_add_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_accom_add_email_detail_label_accom_first_name: "同伴者英名",
      hotel_future_booking_accom_add_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_add_email_detail_label_accom_last_name: "同伴者の英姓",
      hotel_future_booking_accom_add_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_add_email_detail_label_first_name: "英文の名前",
      hotel_future_booking_accom_add_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_accom_add_email_detail_label_last_name: "英文性",
      hotel_future_booking_accom_add_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_accom_add_email_detail_label_room_type: "客室タイプ",
      hotel_future_booking_accom_add_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_accom_add_email_detail_label_arrival_date: "到着日",
      hotel_future_booking_accom_add_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_accom_add_email_detail_label_departure_date: "出発の日",
      hotel_future_booking_accom_add_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_accom_add_email_detail_label_accom_title: "同伴者呼称",
      hotel_future_booking_accom_add_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_add_email_detail_label_adults: "聖人",
      hotel_future_booking_accom_add_email_detail_example_adults: "{{adults}}",
      hotel_future_booking_accom_add_email_detail_label_night: "日数",
      hotel_future_booking_accom_add_email_detail_example_night: "{{night}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate: "客室料金",
      hotel_future_booking_accom_add_email_detail_example_room_rate: "{{roomRate}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate_currency: "客室料金の通話",
      hotel_future_booking_accom_add_email_detail_example_room_rate_currency: "{{roomRateCurrency}}",
      hotel_future_booking_accom_add_email_detail_label_total_rate: "総料金",
      hotel_future_booking_accom_add_email_detail_example_total_rate: "{{totalRate}}",
      hotel_future_booking_accom_add_email_detail_label_cancellation: "キャンセル政策",
      hotel_future_booking_accom_add_email_detail_example_cancellationPolicy: "{{cancellationPolicy}}",
      hotel_future_booking_accom_delete_email_edit_title: "将来の予約同伴者削除メール",
      hotel_future_booking_accom_delete_email_edit_select_kr: "韓国語",
      hotel_future_booking_accom_delete_email_edit_select_en: "英語",
      hotel_future_booking_accom_delete_email_edit_select_cn: "中国語",
      hotel_future_booking_accom_delete_email_edit_select_jp: "日本語",
      hotel_future_booking_accom_delete_email_edit_select_ms: "MS",
      hotel_future_booking_accom_delete_email_edit_button_remove: "除去",
      hotel_future_booking_accom_delete_email_edit_button_append: "追加",
      hotel_future_booking_accom_delete_email_edit_button_save: "貯蔵",
      hotel_future_booking_accom_delete_email_edit_toolbar_bold: "太く",
      hotel_future_booking_accom_delete_email_edit_toolbar_italic: "イタリック体",
      hotel_future_booking_accom_delete_email_edit_toolbar_underline: "下線",
      hotel_future_booking_accom_delete_email_edit_toolbar_strikethrough: "キャンセル線",
      hotel_future_booking_accom_delete_email_edit_toolbar_remove_font_style: "書体取り",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_family: "フォント集",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_size: "フォントサイズ",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_color: "テキストカラー",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_more_color: "カラー もっと 見る",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_color: "最近の使用色",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_future_booking_accom_delete_email_edit_toolbar_table: "テーブル",
      hotel_future_booking_accom_delete_email_edit_toolbar_unordered_list: "未注文リスト",
      hotel_future_booking_accom_delete_email_edit_toolbar_ordered_list: "注文リスト",
      hotel_future_booking_accom_delete_email_edit_toolbar_paragraph: "短絡",
      hotel_future_booking_accom_delete_email_edit_toolbar_line_height: "線の高さ",
      hotel_future_booking_accom_delete_email_edit_toolbar_picture: "写真",
      hotel_future_booking_accom_delete_email_edit_toolbar_code_view: "コードを見ること",
      hotel_future_booking_accom_delete_email_edit_toolbar_full_screen: "全画面",
      hotel_future_booking_accom_delete_email_edit_toolbar_help: "ヘルプ",
      hotel_future_booking_accom_delete_email_edit_toolbar_tip: "チップ",
      hotel_future_booking_accom_delete_email_edit_toolbar_download_pdf: "PDFダウンロード",
      hotel_future_booking_accom_delete_email_edit_placeholder_content: "内容を入力してください",
      hotel_future_booking_accom_delete_email_detail_title: "未来の予約同伴者削除メール",
      hotel_future_booking_accom_delete_email_detail_label_confirmation_number: "予約番号",
      hotel_future_booking_accom_delete_email_detail_example_confirmation_number: "{{confirmationNumber}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_first_name: "同伴者英名",
      hotel_future_booking_accom_delete_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_last_name: "同伴者の英姓",
      hotel_future_booking_accom_delete_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_delete_email_detail_label_first_name: "英文の名前",
      hotel_future_booking_accom_delete_email_detail_example_first_name: "{{firstName}}",
      hotel_future_booking_accom_delete_email_detail_label_last_name: "英文性",
      hotel_future_booking_accom_delete_email_detail_example_last_name: "{{lastName}}",
      hotel_future_booking_accom_delete_email_detail_label_room_type: "客室タイプ",
      hotel_future_booking_accom_delete_email_detail_example_room_type: "{{roomType}}",
      hotel_future_booking_accom_delete_email_detail_label_arrival_date: "到着日",
      hotel_future_booking_accom_delete_email_detail_example_arrival_date: "{{arrivalDate}}",
      hotel_future_booking_accom_delete_email_detail_label_departure_date: "出発の日",
      hotel_future_booking_accom_delete_email_detail_example_departure_date: "{{departureDate}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_title: "同伴者呼称",
      hotel_future_booking_accom_delete_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_delete_email_detail_label_main_guest_name: "メイン顧客名",
      hotel_future_booking_accom_delete_email_detail_example_main_guest_name: "{{mainGuestName}}",
      hotel_member_list_title: "会員一覧",
      hotel_member_list_placeholder_search: "検索",
      hotel_member_list_label_no: "番号",
      hotel_member_list_label_first_name: "英文の名前",
      hotel_member_list_label_last_name: "英文性",
      hotel_member_list_label_email: "Eメール",
      hotel_member_list_label_gender: "性別",
      hotel_member_list_label_member_type: "会員タイプ",
      hotel_member_list_label_profile_id: "プロフィールID",
      hotel_member_list_label_fail_count: "失敗回数",
      hotel_member_list_label_date: "日付",
      hotel_member_list_guide_empty: "リストが空です",
      hotel_member_detail_title: "プロフィールID",
      hotel_member_detail_label_user: "使用者",
      hotel_member_detail_button_user_transfer: "同期化",
      hotel_member_detail_label_user_first_name: "英文の名前",
      hotel_member_detail_label_user_last_name: "英文性",
      hotel_member_detail_label_user_email: "Eメール",
      hotel_member_detail_label_user_gender: "性別",
      hotel_member_detail_label_user_member_type: "会員タイプ",
      hotel_member_detail_label_user_date: "日付",
      hotel_member_detail_label_fail_log_list: "失敗ログリスト",
      hotel_member_detail_label_fail_count: "失敗回数",
      hotel_member_detail_button_unlock: "ロック解除",
      hotel_member_detail_label_fail_log_no: "番号",
      hotel_member_detail_label_fail_log_lock: "ロック",
      hotel_member_detail_label_fail_log_date: "日付",
      hotel_member_detail_label_pms_member_profile: "PMS会員プロフィール",
      hotel_member_detail_label_pms_member_profile_name_title: "名前呼称",
      hotel_member_detail_label_pms_member_profile_first_name: "英文の名前",
      hotel_member_detail_label_pms_member_profile_last_name: "英文性",
      hotel_member_detail_label_pms_member_profile_alternative_language_code: "代替言語コード",
      hotel_member_detail_label_pms_member_profile_alternative_first_name: "ハングルの名前",
      hotel_member_detail_label_pms_member_profile_alternative_last_name: "ハングルの姓",
      hotel_member_detail_label_pms_member_profile_email: "Eメール",
      hotel_member_detail_label_pms_member_profile_email_opera_id: "EメールオペラID",
      hotel_member_detail_label_pms_member_profile_phone: "携帯番号",
      hotel_member_detail_label_pms_member_profile_phone_opera_id: "携帯番号 オペラ ID",
      hotel_member_detail_button_go_to_list: "リストへのアクセス",
      hotel_non_member_list_title: "NON MEMBER LIST",
      hotel_non_member_list_placeholder_search: "検索",
      hotel_non_member_list_label_no: "番号",
      hotel_non_member_list_label_first_name: "英文の名前",
      hotel_non_member_list_label_last_name: "英文性",
      hotel_non_member_list_label_email: "Eメール",
      hotel_non_member_list_label_gender: "性別",
      hotel_non_member_list_label_fail_count: "会員タイプ",
      hotel_non_member_list_label_date: "日付",
      hotel_non_member_list_guide_empty: "リストが空です",
      hotel_non_member_detail_label_user: "使用者",
      hotel_non_member_detail_label_user_first_name: "英文の名前",
      hotel_non_member_detail_label_user_last_name: "英文性",
      hotel_non_member_detail_label_user_email: "Eメール",
      hotel_non_member_detail_label_user_gender: "性別",
      hotel_non_member_detail_label_user_date: "日付",
      hotel_non_member_detail_label_fail_log_list: "失敗ログリスト",
      hotel_non_member_detail_label_fail_count: "失敗回数",
      hotel_non_member_detail_button_unlock: "ロック解除",
      hotel_non_member_detail_label_fail_log_no: "番号",
      hotel_non_member_detail_label_fail_log_lock: "ロック",
      hotel_non_member_detail_label_fail_log_date: "日付",
      hotel_non_member_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_reservation_list_title: "予約リスト",
      hotel_reservation_reservation_list_placeholder_select_box_reservation_status: "予約状況",
      hotel_reservation_reservation_list_select_inhouse: "社内",
      hotel_reservation_reservation_list_select_reserved: "予約済み",
      hotel_reservation_reservation_list_select_changed: "かわった",
      hotel_reservation_reservation_list_select_canceled: "キャンセル",
      hotel_reservation_reservation_list_select_no_show: "全く見せない",
      hotel_reservation_reservation_list_select_checked_out: "チェックアウトされた",
      hotel_reservation_reservation_list_placeholder_select_box_reservation: "予約",
      hotel_reservation_reservation_list_select_confirmation_no: "予約番号",
      hotel_reservation_reservation_list_select_room_no: "部屋番号",
      hotel_reservation_reservation_list_select_name: "名前",
      hotel_reservation_reservation_list_placeholder_search: "検索",
      hotel_reservation_reservation_list_label_no: "番号",
      hotel_reservation_reservation_list_label_confirmation_no: "予約番号",
      hotel_reservation_reservation_list_label_owner: "予約者",
      hotel_reservation_reservation_list_label_main_guest: "主客",
      hotel_reservation_reservation_list_label_status: "状態",
      hotel_reservation_reservation_list_label_checkin_date: "チェックインの日付",
      hotel_reservation_reservation_list_label_checkout_date: "チェックアウトの日付",
      hotel_reservation_reservation_list_label_date: "日付",
      hotel_reservation_reservation_list_label_cancel: "キャンセル",
      hotel_reservation_reservation_list_guide_empty: "リストが空です",
      hotel_reservation_reservation_list_button_cancel: "キャンセル",
      hotel_reservation_reservation_list_button_sync: "同期化",
      hotel_reservation_reservation_detail_label_confirmation_no: "予約番号",
      hotel_reservation_reservation_detail_label_owner_main_guest: "主客",
      hotel_reservation_reservation_detail_label_owner_delete_btn: "削除",
      hotel_reservation_reservation_detail_label_owner_profile_id: "プロフィールID",
      hotel_reservation_reservation_detail_label_owner_title: "呼称",
      hotel_reservation_reservation_detail_label_owner_first_name: "英文の名前",
      hotel_reservation_reservation_detail_label_owner_last_name: "英文性",
      hotel_reservation_reservation_detail_label_owner_confirmed_at: "確認時間",
      hotel_reservation_reservation_detail_label_owner_confirmed_type: "確認タイプ",
      hotel_reservation_reservation_detail_label_owner_use_key: "鍵使用",
      hotel_reservation_reservation_detail_label_owner_waiting_room: "客室待機リクエスト時間",
      hotel_reservation_reservation_detail_label_owner_date: "日付",
      hotel_reservation_reservation_detail_owner_list_guide_empty: "リストが空です",
      hotel_reservation_reservation_detail_label_booking_user_owner: "予約者",
      hotel_reservation_reservation_detail_label_booking_user_title: "呼称",
      hotel_reservation_reservation_detail_label_booking_user_first_name: "英文の名前",
      hotel_reservation_reservation_detail_label_booking_user_last_name: "英文性",
      hotel_reservation_reservation_detail_label_booking_user_confirmed_at: "確認",
      hotel_reservation_reservation_detail_label_booking_user_date: "日付",
      hotel_reservation_reservation_detail_label_accompany: "同伴のお客様",
      hotel_reservation_reservation_detail_label_accompany_no: "番号",
      hotel_reservation_reservation_detail_label_accompany_profile_id: "プロフィールID",
      hotel_reservation_reservation_detail_label_accompany_title: "呼称",
      hotel_reservation_reservation_detail_label_accompany_first_name: "英文の名前",
      hotel_reservation_reservation_detail_label_accompany_last_name: "英文性",
      hotel_reservation_reservation_detail_label_accompany_use_key: "鍵使用",
      hotel_reservation_reservation_detail_label_accompany_confirmed_at: "確認時間",
      hotel_reservation_reservation_detail_label_accompany_confirmed_type: "確認タイプ",
      hotel_reservation_reservation_detail_label_accompany_hotel_member_id: "ホテル会員ID",
      hotel_reservation_reservation_detail_label_accompany_hotel_date: "日付",
      hotel_reservation_reservation_detail_accompany_list_guide_empty: "リストが空です",
      hotel_reservation_reservation_detail_label_agreed: "約款同意",
      hotel_reservation_reservation_detail_label_agreed_title: "呼称",
      hotel_reservation_reservation_detail_label_agreed_agreement: "同意内容",
      hotel_reservation_reservation_detail_label_agreed_agreed_at: "同意時間",
      hotel_reservation_reservation_detail_label_agreed_view_details: "詳細",
      hotel_reservation_reservation_detail_label_agreed_go_to_detail: "詳細を見る",
      hotel_reservation_reservation_detail_label_payment: "決済",
      hotel_reservation_reservation_detail_label_payment_long_info: "ロング情報",
      hotel_reservation_reservation_detail_label_payment_amount: "金額",
      hotel_reservation_reservation_detail_label_payment_unit: "単位",
      hotel_reservation_reservation_detail_label_payment_pg_status_code: "PGステータスコード",
      hotel_reservation_reservation_detail_label_payment_pg_status_message: "PGステータスメッセージ",
      hotel_reservation_reservation_detail_label_payment_date: "日付",
      hotel_reservation_reservation_detail_label_pms: "PMS",
      hotel_reservation_reservation_detail_label_pms_confirmation_no: "予約番号",
      hotel_reservation_reservation_detail_label_pms_reservation_id: "予約ID",
      hotel_reservation_reservation_detail_label_pms_profile_id: "プロフィールID",
      hotel_reservation_reservation_detail_label_pms_reservation_status: "予約状態",
      hotel_reservation_reservation_detail_label_pms_computed_reservation_status: "計算された予約ステータス",
      hotel_reservation_reservation_detail_label_pms_message_exists: "メッセージが存在します",
      hotel_reservation_reservation_detail_label_pms_arrival_date: "到着日",
      hotel_reservation_reservation_detail_label_pms_departure_date: "出発の日",
      hotel_reservation_reservation_detail_label_pms_room_type: "客室タイプ",
      hotel_reservation_reservation_detail_label_pms_room_type_code: "客室タイプコード",
      hotel_reservation_reservation_detail_label_pms_room_rate_currency: "客室料金の通話",
      hotel_reservation_reservation_detail_label_pms_room_rate: "客室料金",
      hotel_reservation_reservation_detail_label_pms_total_rate: "総料金",
      hotel_reservation_reservation_detail_label_pms_current_balance: "利用金額",
      hotel_reservation_reservation_detail_label_pms_room_number: "客室番号",
      hotel_reservation_reservation_detail_label_pms_adults: "聖人",
      hotel_reservation_reservation_detail_label_pms_children: "幼児/子供",
      hotel_reservation_reservation_detail_label_pms_accompany: "同伴のお客様",
      hotel_reservation_reservation_detail_label_pms_accompany_no: "番号",
      hotel_reservation_reservation_detail_label_pms_accompany_profile_id: "プロフィールID",
      hotel_reservation_reservation_detail_label_pms_accompany_first_name: "英文の名前",
      hotel_reservation_reservation_detail_label_pms_accompany_last_name: "英文性",
      hotel_reservation_reservation_detail_label_pms_accompany_nationality: "国家",
      hotel_reservation_reservation_detail_label_pms_accompany_passport_no: "パスポート番号",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_birth: "生年月日",
      hotel_reservation_reservation_detail_label_pms_accompany_gender: "性別",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_expiry: "旅券満了日",
      hotel_reservation_reservation_detail_label_pms_main_guest: "メーンの客",
      hotel_reservation_reservation_detail_label_pms_main_guest_profile_id: "プロフィールID",
      hotel_reservation_reservation_detail_label_pms_main_guest_first_name: "英文の名前",
      hotel_reservation_reservation_detail_label_pms_main_guest_last_name: "英文性",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_nationality: "国家",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_passport_no: "パスポート番号",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_birth: "生年月日",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_gender: "性別",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_expiry: "旅券満了日",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_date: "到着便の日付",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_no: "到着便番号",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_time: "到着便の飛行時間",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_date: "出発便の日付",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_no: "出発便番号",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_time: "出発便の飛行時間",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_wake_up_call: "モーニングコール",
      hotel_reservation_reservation_detail_label_pms_package: "パッケージ",
      hotel_reservation_reservation_detail_label_pms_package_no: "番号",
      hotel_reservation_reservation_detail_label_pms_package_package_code: "パッケージ·コード",
      hotel_reservation_reservation_detail_label_pms_package_description: "内容",
      hotel_reservation_reservation_detail_label_pms_package_amount: "金額",
      hotel_reservation_reservation_detail_label_pms_charges_by_date: "日付別料金",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_no: "番号",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_date: "日付",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_description: "内訳",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_charges: "料金",
      hotel_reservation_reservation_detail_label_pms_invoice: "請求書",
      hotel_reservation_reservation_detail_label_pms_invoice_no: "番号",
      hotel_reservation_reservation_detail_label_pms_invoice_date: "日付",
      hotel_reservation_reservation_detail_label_pms_invoice_description: "内訳",
      hotel_reservation_reservation_detail_label_pms_invoice_currency_code: "通貨コード",
      hotel_reservation_reservation_detail_label_pms_invoice_amount: "金額",
      hotel_reservation_reservation_detail_label_pms_invoice_supplement: "追加説明",
      hotel_reservation_reservation_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_reservation_detail_button_cancel: "キャンセル",
      hotel_reservation_reservation_compare_face_detail_title: "顔の比較情報",
      hotel_reservation_reservation_detail_label_user_name: "ユーザー名",
      hotel_reservation_reservation_detail_label_identity_document_type: "身分証明書の種類",
      hotel_reservation_reservation_detail_label_auth_status: "認証状態",
      hotel_reservation_reservation_detail_label_similarity: "類似度",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response: "シンガポールEVA応答",
      hotel_reservation_reservation_detail_label_government_singapore_eva_status: "シンガポールEVA状態",
      hotel_reservation_reservation_detail_label_government_singapore_eva_request_at: "シンガポールEVA要請時間",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response_at: "シンガポールEVA応答時間",
      hotel_reservation_reservation_detail_label_created_at: "生成時間",
      hotel_reservation_reservation_compare_face_detail_button_go_to_detail: "詳細に行く",
      hotel_reservation_reservation_exist_label_room_no: "客室番号",
      hotel_reservation_reservation_exist_label_period_of_stay: "宿泊期間",
      hotel_reservation_reservation_exist_label_user: "使用者",
      hotel_reservation_reservation_exist_label_user_confirmation_no: "予約番号",
      hotel_reservation_reservation_exist_label_user_first_name: "英文の名前",
      hotel_reservation_reservation_exist_label_user_last_name: "英文性",
      hotel_reservation_reservation_exist_label_user_email: "Eメール",
      hotel_reservation_reservation_exist_label_user_gender: "性別",
      hotel_reservation_reservation_exist_label_user_member_type: "会員タイプ",
      hotel_reservation_reservation_exist_label_user_date: "日付",
      hotel_reservation_reservation_exist_label_exist_request_list: "リクエストリスト",
      hotel_reservation_reservation_exist_label_exist_request_list_: "要請日",
      hotel_reservation_reservation_exist_label_exist_request_list_request_type: "要請タイプ",
      hotel_reservation_reservation_exist_label_exist_request_list_express: "タスク",
      hotel_reservation_reservation_exist_label_exist_request_list_number_of_item: "項目数",
      hotel_reservation_reservation_exist_label_exist_request_list_action_status: "現在の動作状態",
      hotel_reservation_reservation_exist_label_exist_request_list_date: "日付",
      hotel_reservation_reservation_exist_label_exist_request_list_hover: "このリクエストは、中断処理ができません",
      hotel_reservation_reservation_exist_button_go_to_list: "リストへのアクセス",
      hotel_reservation_reservation_exist_button_stop: "中断",
      hotel_reservation_waiting_list_title: "客室待機リスト",
      hotel_reservation_waiting_list_placeholder_select_box_waiting_room: "客間待機",
      hotel_reservation_waiting_list_select_profile_id: "プロフィールID",
      hotel_reservation_waiting_list_select_owner_name: "予約者名",
      hotel_reservation_waiting_list_placeholder_search: "検索",
      hotel_reservation_waiting_list_label_no: "番号",
      hotel_reservation_waiting_list_label_profile_id: "プロフィールID",
      hotel_reservation_waiting_list_label_title: "呼称",
      hotel_reservation_waiting_list_label_main_guest: "主客",
      hotel_reservation_waiting_list_label_main_guest_email: "主な顧客の電子メール",
      hotel_reservation_waiting_list_label_owner_name: "予約者名",
      hotel_reservation_waiting_list_label_owner_email: "予約者のEメール",
      hotel_reservation_waiting_list_label_confirmation_no: "予約番号",
      hotel_reservation_waiting_list_guide_empty: "リストが空です",
      hotel_reservation_waiting_detail_label_confirmation_no: "予約確認番号",
      hotel_reservation_waiting_detail_label_owner: "予約者",
      hotel_reservation_waiting_detail_label_owner_profile_id: "プロフィールID",
      hotel_reservation_waiting_detail_label_owner_title: "呼称",
      hotel_reservation_waiting_detail_label_owner_first_name: "英文の名前",
      hotel_reservation_waiting_detail_label_owner_last_name: "英文性",
      hotel_reservation_waiting_detail_label_owner_confirmed_at: "確認時間",
      hotel_reservation_waiting_detail_label_owner_confirmed_type: "確認タイプ",
      hotel_reservation_waiting_detail_label_owner_waiting_room: "客間待機",
      hotel_reservation_waiting_detail_label_owner_date: "日付",
      hotel_reservation_waiting_detail_label_accompany: "同伴のお客様",
      hotel_reservation_waiting_detail_label_accompany_no: "番号",
      hotel_reservation_waiting_detail_label_accompany_profile_id: "プロフィールID",
      hotel_reservation_waiting_detail_label_accompany_tile: "呼称",
      hotel_reservation_waiting_detail_label_accompany_first_name: "英文の名前",
      hotel_reservation_waiting_detail_label_accompany_last_name: "英文性",
      hotel_reservation_waiting_detail_label_accompany_use_key: "鍵使用",
      hotel_reservation_waiting_detail_label_accompany_confirmed_at: "確認時間",
      hotel_reservation_waiting_detail_label_accompany_confirmed_type: "確認タイプ",
      hotel_reservation_waiting_detail_label_accompany_hotel_member_id: "ホテル会員ID",
      hotel_reservation_waiting_detail_label_accompany_date: "日付",
      hotel_reservation_waiting_detail_label_pms: "PMS",
      hotel_reservation_waiting_detail_label_pms_confirmation_no: "予約確認番号",
      hotel_reservation_waiting_detail_label_pms_reservation_id: "予約ID",
      hotel_reservation_waiting_detail_label_pms_profile_id: "プロフィールID",
      hotel_reservation_waiting_detail_label_pms_reservation_status: "予約状態",
      hotel_reservation_waiting_detail_label_pms_message_exists: "メッセージが存在します。",
      hotel_reservation_waiting_detail_label_pms_arrival_date: "到着日",
      hotel_reservation_waiting_detail_label_pms_departure_date: "出発の日",
      hotel_reservation_waiting_detail_label_pms_room_type: "客室タイプ",
      hotel_reservation_waiting_detail_label_pms_room_type_code: "客室タイプコード",
      hotel_reservation_waiting_detail_label_pms_room_rate_currency: "客室料金の通話",
      hotel_reservation_waiting_detail_label_pms_room_rate: "客室料金",
      hotel_reservation_waiting_detail_label_pms_total_rate: "総料金",
      hotel_reservation_waiting_detail_label_pms_current_balance: "利用金額",
      hotel_reservation_waiting_detail_label_pms_room_number: "客室番号",
      hotel_reservation_waiting_detail_label_pms_adults: "聖人",
      hotel_reservation_waiting_detail_label_pms_children: "幼児/子供",
      hotel_reservation_waiting_detail_label_pms_accompany: "同伴のお客様",
      hotel_reservation_waiting_detail_label_pms_accompany_no: "番号",
      hotel_reservation_waiting_detail_label_pms_accompany_profile_id: "プロフィールID",
      hotel_reservation_waiting_detail_label_pms_accompany_first_name: "英文の名前",
      hotel_reservation_waiting_detail_label_pms_accompany_last_name: "英文性",
      hotel_reservation_waiting_detail_label_pms_package: "パッケージ",
      hotel_reservation_waiting_detail_label_pms_package_no: "番号",
      hotel_reservation_waiting_detail_label_pms_package_package_code: "パッケージ·コード",
      hotel_reservation_waiting_detail_label_pms_package_description: "内容",
      hotel_reservation_waiting_detail_label_pms_package_amount: "金額",
      hotel_reservation_waiting_detail_label_pms_charges_by_date: "日付別料金",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_no: "番号",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_date: "日付",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_description: "内訳",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_charges: "料金",
      hotel_reservation_waiting_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_waiting_detail_button_cancel: "キャンセル",
      hotel_reservation_future_booking_list_title: "未来の予約リスト",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation_status: "予約状況",
      hotel_reservation_future_booking_list_select_inhouse: "社内",
      hotel_reservation_future_booking_list_select_reserved: "予約済み",
      hotel_reservation_future_booking_list_select_changed: "かわった",
      hotel_reservation_future_booking_list_select_canceled: "キャンセル",
      hotel_reservation_future_booking_list_select_no_show: "全く見せない",
      hotel_reservation_future_booking_list_select_checked_out: "チェックアウトされた",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation: "未来の予約",
      hotel_reservation_future_booking_list_select_confirmation_no: "予約番号",
      hotel_reservation_future_booking_list_select_name: "名前",
      hotel_reservation_future_booking_list_placeholder_search: "検索······",
      hotel_reservation_future_booking_list_label_no: "番号",
      hotel_reservation_future_booking_list_label_confirmation_no: "予約番号",
      hotel_reservation_future_booking_list_label_booking_owner: "予約者本人",
      hotel_reservation_future_booking_list_label_status: "状態",
      hotel_reservation_future_booking_list_label_occupy: "仮予約",
      hotel_reservation_future_booking_list_label_date: "日付",
      hotel_reservation_future_booking_list_label_cancel: "キャンセル",
      hotel_reservation_future_booking_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_list_button_cancel: "キャンセル",
      hotel_reservation_future_booking_list_button_sync: "同期化",
      hotel_reservation_future_booking_detail_label_confirmation_no: "予約番号",
      hotel_reservation_future_booking_detail_label_owner: "予約者本人",
      hotel_reservation_future_booking_detail_label_owner_title: "呼称",
      hotel_reservation_future_booking_detail_label_owner_first_name: "英文の名前",
      hotel_reservation_future_booking_detail_label_owner_last_name: "英文性",
      hotel_reservation_future_booking_detail_label_owner_use_key: "鍵使用",
      hotel_reservation_future_booking_detail_label_owner_confirmed_at: "確認",
      hotel_reservation_future_booking_detail_label_owner_date: "日付",
      hotel_reservation_future_booking_detail_label_pms: "PMS",
      hotel_reservation_future_booking_detail_label_pms_confirmation_no: "予約",
      hotel_reservation_future_booking_detail_label_pms_reservation_id: "予約ID",
      hotel_reservation_future_booking_detail_label_pms_profile_id: "プロフィールID",
      hotel_reservation_future_booking_detail_label_pms_reservation_status: "予約状態",
      hotel_reservation_future_booking_detail_label_pms_message_exists: "メッセージが存在します",
      hotel_reservation_future_booking_detail_label_pms_arrival_date: "到着日",
      hotel_reservation_future_booking_detail_label_pms_departure_date: "出発の日",
      hotel_reservation_future_booking_detail_label_pms_room_type: "客室タイプ",
      hotel_reservation_future_booking_detail_label_pms_room_type_code: "客室タイプコード",
      hotel_reservation_future_booking_detail_label_pms_room_rate_currency: "客室料金の通話",
      hotel_reservation_future_booking_detail_label_pms_room_rate: "客室料金",
      hotel_reservation_future_booking_detail_label_pms_total_rate: "総料金",
      hotel_reservation_future_booking_detail_label_pms_current_balance: "利用金額",
      hotel_reservation_future_booking_detail_label_pms_room_number: "客室番号",
      hotel_reservation_future_booking_detail_label_pms_adults: "聖人",
      hotel_reservation_future_booking_detail_label_pms_children: "幼児/子供",
      hotel_reservation_future_booking_detail_label_pms_main_guest: "メーンの客",
      hotel_reservation_future_booking_detail_label_pms_main_guest_profile_id: "プロフィールID",
      hotel_reservation_future_booking_detail_label_pms_main_guest_first_name: "英文の名前",
      hotel_reservation_future_booking_detail_label_pms_main_guest_last_name: "英文性",
      hotel_reservation_future_booking_detail_label_pms_main_guest_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_nationality: "国家",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_passport_no: "パスポート番号",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_birth: "生年月日",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_gender: "性別",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_expiry: "旅券満了日",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_date: "到着便の日付",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_no: "到着便番号",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_time: "到着便の飛行時間",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_date: "出発便の日付",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_no: "出発便番号",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_time: "出発便の飛行時間",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_wake_up_call: "モーニングコール",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_detail_label_pms_accompany: "同伴のお客様",
      hotel_reservation_future_booking_detail_label_pms_accompany_no: "番号",
      hotel_reservation_future_booking_detail_label_pms_accompany_profile_id: "プロフィールID",
      hotel_reservation_future_booking_detail_label_pms_accompany_first_name: "英文の名前",
      hotel_reservation_future_booking_detail_label_pms_accompany_last_name: "英文性",
      hotel_reservation_future_booking_detail_label_pms_accompany_info_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_detail_label_pms_package: "パッケージ",
      hotel_reservation_future_booking_detail_label_pms_package_no: "番号",
      hotel_reservation_future_booking_detail_label_pms_package_package_code: "パッケージ·コード",
      hotel_reservation_future_booking_detail_label_pms_package_description: "内容",
      hotel_reservation_future_booking_detail_label_pms_package_amount: "金額",
      hotel_reservation_future_booking_detail_label_pms_package_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date: "日付別料金",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_no: "番号",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_date: "日付",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_description: "内訳",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_charges: "料金",
      hotel_reservation_future_booking_detail_label_pms_charges_list_guide_empty: "リストが空です",
      hotel_reservation_future_booking_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_future_booking_detail_button_cancel: "キャンセル",
      hotel_reservation_rule_setting_reservation_rule_list_title: "予約規則一覧",
      hotel_reservation_rule_setting_reservation_rule_list_select_active_type: "活性化タイプ（すべて）",
      hotel_reservation_rule_setting_reservation_rule_list_select_active: "活性化",
      hotel_reservation_rule_setting_reservation_rule_list_select_inactive: "非活性化",
      hotel_reservation_rule_setting_reservation_rule_list_placeholder_search: "検索",
      hotel_reservation_rule_setting_reservation_rule_list_label_rule_name: "規則名",
      hotel_reservation_rule_setting_reservation_rule_list_label_reservation_amount: "予約料金",
      hotel_reservation_rule_setting_reservation_rule_list_label_type: "タイプ",
      hotel_reservation_rule_setting_reservation_rule_list_label_day_before_arrival: "チェックイン~日前",
      hotel_reservation_rule_setting_reservation_rule_list_label_before_time: "チェックイン~時間前",
      hotel_reservation_rule_setting_reservation_rule_list_label_inactive: "非活性化",
      hotel_reservation_rule_setting_reservation_rule_list_label_created_at: "生成時間",
      hotel_reservation_rule_setting_reservation_rule_list_button_delete: "削除",
      hotel_reservation_rule_setting_reservation_rule_list_button_add: "追加",
      hotel_reservation_rule_setting_reservation_rule_list_guide_empty: "リストが空です",
      hotel_reservation_rule_setting_reservation_rule_add_title: "予約規則の追加",
      hotel_reservation_rule_setting_reservation_rule_add_label_rule_name: "規則名",
      hotel_reservation_rule_setting_reservation_rule_add_label_description: "説明",
      hotel_reservation_rule_setting_reservation_rule_add_label_type: "タイプ",
      hotel_reservation_rule_setting_reservation_rule_add_placeholder_select_box_select: "選択",
      hotel_reservation_rule_setting_reservation_rule_add_select_percent: "パーセント",
      hotel_reservation_rule_setting_reservation_rule_add_select_nights: "宿泊日",
      hotel_reservation_rule_setting_reservation_rule_add_select_flat: "固定料金",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_amount: "金額",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_percent: "パーセント",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_nights: "宿泊日",
      hotel_reservation_rule_setting_reservation_rule_add_label_transaction_code: "決済コード",
      hotel_reservation_rule_setting_reservation_rule_add_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_reservation_rule_add_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_reservation_rule_add_label_inactive: "非活性化",
      hotel_reservation_rule_setting_reservation_rule_add_label_yes: "はい。",
      hotel_reservation_rule_setting_reservation_rule_add_label_no: "いいえ",
      hotel_reservation_rule_setting_reservation_rule_add_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_reservation_rule_add_button_add: "追加",
      hotel_reservation_rule_setting_reservation_rule_add_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_type: "* タイプを選択してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_reservation_amount: "* パーセントを入力してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_dayBefore_arrival: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_transaction_code: "* チェックイン~日前を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_before_time: "* チェックイン~時間前を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_detail_title: "予約ルール情報",
      hotel_reservation_rule_setting_reservation_rule_detail_label_rule_name: "規則名",
      hotel_reservation_rule_setting_reservation_rule_detail_label_description: "説明",
      hotel_reservation_rule_setting_reservation_rule_detail_label_type: "タイプ",
      hotel_reservation_rule_setting_reservation_rule_detail_select_percent: "パーセント",
      hotel_reservation_rule_setting_reservation_rule_detail_select_nights: "宿泊日",
      hotel_reservation_rule_setting_reservation_rule_detail_select_flat: "固定料金",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_amount: "金額",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_percent: "パーセント",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_nights: "宿泊日",
      hotel_reservation_rule_setting_reservation_rule_detail_label_transactio_code: "決済コード",
      hotel_reservation_rule_setting_reservation_rule_detail_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_reservation_rule_detail_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_reservation_rule_detail_label_inactive: "非活性化",
      hotel_reservation_rule_setting_reservation_rule_detail_label_yes: "はい。",
      hotel_reservation_rule_setting_reservation_rule_detail_label_no: "いいえ",
      hotel_reservation_rule_setting_reservation_rule_detail_label_created_at: "生成時間",
      hotel_reservation_rule_setting_reservation_rule_detail_label_updated_at: "アップデート時間",
      hotel_reservation_rule_setting_reservation_rule_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_reservation_rule_detail_button_cancel: "キャンセル",
      hotel_reservation_rule_setting_reservation_rule_detail_button_delete: "削除",
      hotel_reservation_rule_setting_reservation_rule_detail_button_edit: "修整",
      hotel_reservation_rule_setting_reservation_rule_detail_button_ok: "確認",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_type: "* タイプを選択してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_reservation_amount: "* パーセントを入力してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_dayBefore_arrival: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_transaction_code: "* チェックイン~日前を入力してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_before_time: "* チェックイン~時間前を入力してください。",
      hotel_reservation_rule_setting_deposit_rule_list_title: "デポジットルールリスト",
      hotel_reservation_rule_setting_deposit_rule_list_select_active_type: "活性化タイプ（すべて）",
      hotel_reservation_rule_setting_deposit_rule_list_select_active: "活性化",
      hotel_reservation_rule_setting_deposit_rule_list_select_inactive: "非活性化",
      hotel_reservation_rule_setting_deposit_rule_list_placeholder_search: "検索",
      hotel_reservation_rule_setting_deposit_rule_list_label_rule_name: "規則名",
      hotel_reservation_rule_setting_deposit_rule_list_label_deposit_amount: "敷金の金額",
      hotel_reservation_rule_setting_deposit_rule_list_label_type: "タイプ",
      hotel_reservation_rule_setting_deposit_rule_list_label_day_before_arrival: "チェックイン~日前",
      hotel_reservation_rule_setting_deposit_rule_list_label_before_time: "チェックイン~時間前",
      hotel_reservation_rule_setting_deposit_rule_list_label_inactive: "非活性化",
      hotel_reservation_rule_setting_deposit_rule_list_label_created_at: "生成時間",
      hotel_reservation_rule_setting_deposit_rule_list_button_delete: "削除",
      hotel_reservation_rule_setting_deposit_rule_list_button_add: "追加",
      hotel_reservation_rule_setting_deposit_rule_add_title: "保証金規則追加",
      hotel_reservation_rule_setting_deposit_rule_add_label_rule_name: "規則名",
      hotel_reservation_rule_setting_deposit_rule_add_label_description: "説明",
      hotel_reservation_rule_setting_deposit_rule_add_label_type: "タイプ",
      hotel_reservation_rule_setting_deposit_rule_add_placeholder_select_box_select: "選択",
      hotel_reservation_rule_setting_deposit_rule_add_select_percent: "パーセント",
      hotel_reservation_rule_setting_deposit_rule_add_select_nights: "宿泊日",
      hotel_reservation_rule_setting_deposit_rule_add_select_flat: "固定料金",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_amount: "金額",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_percent: "パーセント",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_nights: "宿泊日",
      hotel_reservation_rule_setting_deposit_rule_add_label_transaction_code: "決済コード",
      hotel_reservation_rule_setting_deposit_rule_add_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_deposit_rule_add_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_deposit_rule_add_label_inactive: "非活性化",
      hotel_reservation_rule_setting_deposit_rule_add_label_yes: "はい。",
      hotel_reservation_rule_setting_deposit_rule_add_label_no: "いいえ",
      hotel_reservation_rule_setting_deposit_rule_add_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_deposit_rule_add_button_add: "追加",
      hotel_reservation_rule_setting_deposit_rule_add_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_type: "* タイプを選択してください。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_deposit_amount: "* パーセントを入力してください。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_transaction_code: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_deposit_rule_detail_title: "デポジットルール情報",
      hotel_reservation_rule_setting_deposit_rule_detail_label_rule_name: "規則名",
      hotel_reservation_rule_setting_deposit_rule_detail_label_description: "説明",
      hotel_reservation_rule_setting_deposit_rule_detail_label_type: "タイプ",
      hotel_reservation_rule_setting_deposit_rule_detail_select_percent: "パーセント",
      hotel_reservation_rule_setting_deposit_rule_detail_select_nights: "宿泊日",
      hotel_reservation_rule_setting_deposit_rule_detail_select_flat: "固定料金",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_amount: "金額",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_percent: "パーセント",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_nights: "宿泊日",
      hotel_reservation_rule_setting_deposit_rule_detail_label_transactio_code: "決済コード",
      hotel_reservation_rule_setting_deposit_rule_detail_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_deposit_rule_detail_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_deposit_rule_detail_label_inactive: "非活性化",
      hotel_reservation_rule_setting_deposit_rule_detail_label_yes: "はい。",
      hotel_reservation_rule_setting_deposit_rule_detail_label_no: "いいえ",
      hotel_reservation_rule_setting_deposit_rule_detail_label_created_at: "生成時間",
      hotel_reservation_rule_setting_deposit_rule_detail_label_updated_at: "アップデート時間",
      hotel_reservation_rule_setting_deposit_rule_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_deposit_rule_detail_button_cancel: "キャンセル",
      hotel_reservation_rule_setting_deposit_rule_detail_button_delete: "削除",
      hotel_reservation_rule_setting_deposit_rule_detail_button_edit: "修整",
      hotel_reservation_rule_setting_deposit_rule_detail_button_ok: "確認",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_type: "* タイプを選択してください。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_deposit_amount: "* パーセントを入力してください。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_transaction_code: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_cancellation_rule_list_title: "キャンセルルールリスト",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active_type: "活性化タイプ（すべて）",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active: "活性化",
      hotel_reservation_rule_setting_cancellation_rule_list_select_inactive: "非活性化",
      hotel_reservation_rule_setting_cancellation_rule_list_placeholder_search: "検索",
      hotel_reservation_rule_setting_cancellation_rule_list_label_rule_name: "規則名",
      hotel_reservation_rule_setting_cancellation_rule_list_label_penalty_amount: "ペナルティ金額",
      hotel_reservation_rule_setting_cancellation_rule_list_label_type: "タイプ",
      hotel_reservation_rule_setting_cancellation_rule_list_label_day_before_arrival: "チェックイン~日前",
      hotel_reservation_rule_setting_cancellation_rule_list_label_before_time: "チェックイン~時間前",
      hotel_reservation_rule_setting_cancellation_rule_list_label_inactive: "非活性化",
      hotel_reservation_rule_setting_cancellation_rule_list_label_created_at: "生成時間",
      hotel_reservation_rule_setting_cancellation_rule_list_button_delete: "削除",
      hotel_reservation_rule_setting_cancellation_rule_list_button_add: "追加",
      hotel_reservation_rule_setting_cancellation_rule_list_guide_empty: "リストが空です",
      hotel_reservation_rule_setting_cancellation_rule_add_title: "キャンセルルールの追加",
      hotel_reservation_rule_setting_cancellation_rule_add_label_rule_name: "規則名",
      hotel_reservation_rule_setting_cancellation_rule_add_label_description: "説明",
      hotel_reservation_rule_setting_cancellation_rule_add_label_type: "タイプ",
      hotel_reservation_rule_setting_cancellation_rule_add_placeholder_select_box_select: "選択",
      hotel_reservation_rule_setting_cancellation_rule_add_select_percent: "パーセント",
      hotel_reservation_rule_setting_cancellation_rule_add_select_nights: "宿泊日",
      hotel_reservation_rule_setting_cancellation_rule_add_select_flat: "固定料金",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_amount: "金額",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_percent: "パーセント",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_nights: "宿泊日",
      hotel_reservation_rule_setting_cancellation_rule_add_label_transaction_code: "決済コード",
      hotel_reservation_rule_setting_cancellation_rule_add_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_cancellation_rule_add_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_cancellation_rule_add_label_inactive: "非活性化",
      hotel_reservation_rule_setting_cancellation_rule_add_label_yes: "はい。",
      hotel_reservation_rule_setting_cancellation_rule_add_label_no: "いいえ",
      hotel_reservation_rule_setting_cancellation_rule_add_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_cancellation_rule_add_button_add: "追加",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_type: "* タイプを選択してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_penalty_amount: "* パーセントを入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_dayBefore_arrival: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_transaction_code: "* チェックイン~日前を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_before_time: "* チェックイン~時間前を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_detail_title: "キャンセルルール情報",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_rule_name: "規則名",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_description: "説明",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_type: "タイプ",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_percent: "パーセント",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_nights: "宿泊日",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_flat: "固定料金",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_amount: "金額",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_percent: "パーセント",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_nights: "宿泊日",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_transactio_code: "決済コード",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_inactive: "非活性化",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_yes: "はい。",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_no: "いいえ",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_created_at: "生成時間",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_updated_at: "アップデート時間",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_cancel: "キャンセル",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_delete: "削除",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_edit: "修整",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_ok: "確認",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_type: "* タイプを選択してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_penalty_amount: "* パーセントを入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_dayBefore_arrival: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_transaction_code: "* チェックイン~日前を入力してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_before_time: "* チェックイン~時間前を入力してください。",
      hotel_reservation_rule_setting_no_show_rule_list_title: "ノーショー·ルール·リスト",
      hotel_reservation_rule_setting_no_show_rule_list_select_posting_rule: "請求規則(すべて)",
      hotel_reservation_rule_setting_no_show_rule_list_select_all_nights: "全宿泊日",
      hotel_reservation_rule_setting_no_show_rule_list_select_deposit_only: "敷金",
      hotel_reservation_rule_setting_no_show_rule_list_select_first_night: "宿泊初日",
      hotel_reservation_rule_setting_no_show_rule_list_select_percent: "パーセント",
      hotel_reservation_rule_setting_no_show_rule_list_placeholder_search: "検索",
      hotel_reservation_rule_setting_no_show_rule_list_label_rule_name: "規則名",
      hotel_reservation_rule_setting_no_show_rule_list_label_penalty_amount: "ペナルティ金額",
      hotel_reservation_rule_setting_no_show_rule_list_label_source_code: "ソースコード",
      hotel_reservation_rule_setting_no_show_rule_list_label_guarantee_type: "ギャランティー·タイプ",
      hotel_reservation_rule_setting_no_show_rule_list_label_posting_rule: "請求規則",
      hotel_reservation_rule_setting_no_show_rule_list_label_created_at: "生成時間",
      hotel_reservation_rule_setting_no_show_rule_list_button_delete: "削除",
      hotel_reservation_rule_setting_no_show_rule_list_button_add: "追加",
      hotel_reservation_rule_setting_no_show_rule_list_guide_empty: "リストが空です",
      hotel_reservation_rule_setting_no_show_rule_add_title: "ノーショールール追加",
      hotel_reservation_rule_setting_no_show_rule_add_label_rule_name: "規則名",
      hotel_reservation_rule_setting_no_show_rule_add_label_description: "説明",
      hotel_reservation_rule_setting_no_show_rule_add_label_source_code: "ソースコード",
      hotel_reservation_rule_setting_no_show_rule_add_label_guarantee_type: "ギャランティー·タイプ",
      hotel_reservation_rule_setting_no_show_rule_add_label_posting_rule: "請求規則",
      hotel_reservation_rule_setting_no_show_rule_add_select_all_nights: "全宿泊日",
      hotel_reservation_rule_setting_no_show_rule_add_select_deposit_only: "敷金",
      hotel_reservation_rule_setting_no_show_rule_add_select_first_night: "宿泊初日",
      hotel_reservation_rule_setting_no_show_rule_add_select_percent: "パーセント",
      hotel_reservation_rule_setting_no_show_rule_add_select_flat: "固定料金",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_amount: "ペナルティ金額",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_percent: "ペナルティ·パーセント",
      hotel_reservation_rule_setting_no_show_rule_add_label_transaction_code: "決済コード",
      hotel_reservation_rule_setting_no_show_rule_add_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_no_show_rule_add_button_add: "追加",
      hotel_reservation_rule_setting_no_show_rule_add_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_no_show_rule_add_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_no_show_rule_add_validation_posting_rule: "* 請求規則を選択してください。",
      hotel_reservation_rule_setting_no_show_rule_add_validation_penalty_amount: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_no_show_rule_detail_title: "ノーショー規則情報",
      hotel_reservation_rule_setting_no_show_rule_detail_label_rule_name: "規則名",
      hotel_reservation_rule_setting_no_show_rule_detail_label_description: "説明",
      hotel_reservation_rule_setting_no_show_rule_detail_label_source_code: "ソースコード",
      hotel_reservation_rule_setting_no_show_rule_detail_label_guarantee_type: "ギャランティー·タイプ",
      hotel_reservation_rule_setting_no_show_rule_detail_label_posting_rule: "請求規則",
      hotel_reservation_rule_setting_no_show_rule_detail_select_all_nights: "全宿泊日",
      hotel_reservation_rule_setting_no_show_rule_detail_select_deposit_only: "敷金",
      hotel_reservation_rule_setting_no_show_rule_detail_select_first_night: "宿泊初日",
      hotel_reservation_rule_setting_no_show_rule_detail_select_percent: "パーセント",
      hotel_reservation_rule_setting_no_show_rule_detaill_select_flat: "固定料金",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_amount: "金額",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_percent: "パーセント",
      hotel_reservation_rule_setting_no_show_rule_detail_label_transactio_code: "決済コード",
      hotel_reservation_rule_setting_no_show_rule_detail_label_created_at: "生成時間",
      hotel_reservation_rule_setting_no_show_rule_detail_label_updated_at: "アップデート時間",
      hotel_reservation_rule_setting_no_show_rule_detail_button_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_no_show_rule_detail_button_cancel: "キャンセル",
      hotel_reservation_rule_setting_no_show_rule_detail_button_delete: "削除",
      hotel_reservation_rule_setting_no_show_rule_detail_button_edit: "修整",
      hotel_reservation_rule_setting_no_show_rule_detail_button_ok: "確認",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_rule_name: "* ルール名を入力してください。",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_posting_rule: "* 請求規則を選択してください。",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_penalty_amount: "* 決済コードを入力してください。",
      hotel_reservation_rule_setting_rule_schedule_list_title: "ルール·スケジュール·リスト",
      hotel_reservation_rule_setting_rule_schedule_list_label_active: "活性化",
      hotel_reservation_rule_setting_rule_schedule_list_label_reservation_rule: "予約規則",
      hotel_reservation_rule_setting_rule_schedule_list_label_no: "番号",
      hotel_reservation_rule_setting_rule_schedule_list_label_schedule_name: "スケジュール名",
      hotel_reservation_rule_setting_rule_schedule_list_label_rate_code: "料金コード",
      hotel_reservation_rule_setting_rule_schedule_list_label_begin_date: "開始日",
      hotel_reservation_rule_setting_rule_schedule_list_label_end_date: "最終日",
      hotel_reservation_rule_setting_rule_schedule_list_label_week: "曜日",
      hotel_reservation_rule_setting_rule_schedule_list_label_week_all: "冒頭",
      hotel_reservation_rule_setting_rule_schedule_list_label_created_at: "生成時間",
      hotel_reservation_rule_setting_rule_schedule_list_label_sorting: "整列",
      hotel_reservation_rule_setting_rule_schedule_list_label_deposit_rule: "敷金規則",
      hotel_reservation_rule_setting_rule_schedule_list_label_cancellation_rule: "キャンセル規則",
      hotel_reservation_rule_setting_rule_schedule_list_label_no_show_rule: "ノーショー規則",
      hotel_reservation_rule_setting_rule_schedule_list_label_inactive: "非活性化",
      hotel_reservation_rule_setting_rule_schedule_list_select_rule_type: "ルールタイプ（すべて）",
      hotel_reservation_rule_setting_rule_schedule_list_select_reservation_rule: "予約規則",
      hotel_reservation_rule_setting_rule_schedule_list_select_deposit_rule: "敷金規則",
      hotel_reservation_rule_setting_rule_schedule_list_select_cancellation_rule: "キャンセル規則",
      hotel_reservation_rule_setting_rule_schedule_list_select_no_show_rule: "ノーショー規則",
      hotel_reservation_rule_setting_rule_schedule_list_placeholder_search: "検索",
      hotel_reservation_rule_setting_rule_schedule_list_label_rule_type: "ルールタイプ",
      hotel_reservation_rule_setting_rule_schedule_list_label_delete: "削除",
      hotel_reservation_rule_setting_rule_schedule_list_label_add: "追加",
      hotel_reservation_rule_setting_rule_schedule_list_guide_empty: "リストが空です",
      hotel_reservation_rule_setting_rule_schedule_add_title: "ルールスケジュールの追加",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_type: "ルールタイプ",
      hotel_reservation_rule_setting_rule_schedule_add_placeholder_select_box_select: "選択",
      hotel_reservation_rule_setting_rule_schedule_add_select_reservation_rule: "予約規則",
      hotel_reservation_rule_setting_rule_schedule_add_select_deposit_rule: "敷金規則",
      hotel_reservation_rule_setting_rule_schedule_add_select_cancellation_rule: "キャンセル規則",
      hotel_reservation_rule_setting_rule_schedule_add_select_no_show_rule: "ノーショー規則",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_name: "規則名",
      hotel_reservation_rule_setting_rule_schedule_add_label_schedule_name: "スケジュール名",
      hotel_reservation_rule_setting_rule_schedule_add_label_description: "説明",
      hotel_reservation_rule_setting_rule_schedule_add_label_rate_code: "料金コード",
      hotel_reservation_rule_setting_rule_schedule_add_label_begin_date: "開始日",
      hotel_reservation_rule_setting_rule_schedule_add_label_end_date: "最終日",
      hotel_reservation_rule_setting_rule_schedule_add_label_week: "曜日",
      hotel_reservation_rule_setting_rule_schedule_add_label_inactive: "非活性化",
      hotel_reservation_rule_setting_rule_schedule_add_label_yes: "はい。",
      hotel_reservation_rule_setting_rule_schedule_add_label_no: "いいえ",
      hotel_reservation_rule_setting_rule_schedule_add_label_sequence: "順序",
      hotel_reservation_rule_setting_rule_schedule_add_label_type: "タイプ",
      hotel_reservation_rule_setting_rule_schedule_add_label_amount: "金額",
      hotel_reservation_rule_setting_rule_schedule_add_label_percent: "パーセント",
      hotel_reservation_rule_setting_rule_schedule_add_label_nights: "宿泊日",
      hotel_reservation_rule_setting_rule_schedule_add_label_transaction_code: "決済コード",
      hotel_reservation_rule_setting_rule_schedule_add_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_rule_schedule_add_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_rule_schedule_add_label_source_code: "ソースコード",
      hotel_reservation_rule_setting_rule_schedule_add_label_guarantee_type: "ギャランティー·タイプ",
      hotel_reservation_rule_setting_rule_schedule_add_label_posting_rule: "請求規則",
      hotel_reservation_rule_setting_rule_schedule_add_label_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_rule_schedule_add_label_add: "追加",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_type: "* ルールタイプを選択してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_name: "* ルール名を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_schedule_name: "* スケジュール名を入力してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rate_code: "* 料金コードを入力してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_begin_date: "* 開始日を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_end_date: "* 最終日を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_week: "* 曜日を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_reservation_rule_setting_rule_schedule_detail_title: "ルールスケジュール情報",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_type: "ルールタイプ",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_name: "規則名",
      hotel_reservation_rule_setting_rule_schedule_detail_label_schedule_name: "スケジュール名",
      hotel_reservation_rule_setting_rule_schedule_detail_label_description: "説明",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rate_code: "料金コード",
      hotel_reservation_rule_setting_rule_schedule_detail_label_begin_date: "開始日",
      hotel_reservation_rule_setting_rule_schedule_detail_label_end_date: "最終日",
      hotel_reservation_rule_setting_rule_schedule_detail_label_week: "曜日",
      hotel_reservation_rule_setting_rule_schedule_detail_label_inactive: "非活性化",
      hotel_reservation_rule_setting_rule_schedule_detail_label_yes: "はい。",
      hotel_reservation_rule_setting_rule_schedule_detail_label_no: "いいえ",
      hotel_reservation_rule_setting_rule_schedule_detail_label_sequence: "順序",
      hotel_reservation_rule_setting_rule_schedule_detail_label_created_at: "生成時間",
      hotel_reservation_rule_setting_rule_schedule_detail_label_updated_at: "アップデート時間",
      hotel_reservation_rule_setting_rule_schedule_detail_label_type: "タイプ",
      hotel_reservation_rule_setting_rule_schedule_detail_label_amount: "金額",
      hotel_reservation_rule_setting_rule_schedule_detail_label_percent: "パーセント",
      hotel_reservation_rule_setting_rule_schedule_detail_label_nights: "宿泊日",
      hotel_reservation_rule_setting_rule_schedule_detail_label_transaction_code: "決済コード",
      hotel_reservation_rule_setting_rule_schedule_detail_label_day_before_arrival: "チェックイン~日前(日)",
      hotel_reservation_rule_setting_rule_schedule_detail_label_before_time: "チェックイン~時間前(時間:分)",
      hotel_reservation_rule_setting_rule_schedule_detail_label_source_code: "ソースコード",
      hotel_reservation_rule_setting_rule_schedule_detail_label_guarantee_type: "ギャランティー·タイプ",
      hotel_reservation_rule_setting_rule_schedule_detail_label_posting_rule: "請求規則",
      hotel_reservation_rule_setting_rule_schedule_detail_label_go_to_list: "リストへのアクセス",
      hotel_reservation_rule_setting_rule_schedule_detail_label_cancel: "キャンセル",
      hotel_reservation_rule_setting_rule_schedule_detail_label_delete: "削除",
      hotel_reservation_rule_setting_rule_schedule_detail_label_edit: "修整",
      hotel_reservation_rule_setting_rule_schedule_detail_label_ok: "確認",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_type: "* ルールタイプを選択してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_name: "* ルール名を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_schedule_name: "* スケジュール名を入力してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_description: "* 説明を入力してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rate_code: "* 料金コードを入力してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_begin_date: "* 開始日を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_end_date: "* 最終日を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_week: "* 曜日を選択してください。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_inactive: "* 無効化ステータスを選択してください。",
      hotel_inhouse_hotel_info_list_title: "ホテル情報メニュー",
      hotel_inhouse_hotel_info_list_button_add: "追加",
      hotel_inhouse_hotel_info_list_button_delete: "削除",
      hotel_inhouse_hotel_info_list_button_edit: "修整",
      hotel_inhouse_hotel_info_list_guide_add: "ホテル情報を追加してください",
      hotel_inhouse_hotel_info_add_title: "ホテル情報追加",
      hotel_inhouse_hotel_info_add_placeholder_title: "呼称を入力してください",
      hotel_inhouse_hotel_info_add_placeholder_index: "番号を入力してください",
      hotel_inhouse_hotel_info_add_select_choose: "タイプをお選びください",
      hotel_inhouse_hotel_info_add_select_address: "ホテルの住所",
      hotel_inhouse_hotel_info_add_select_wifi: "WiFi",
      hotel_inhouse_hotel_info_add_select_smoking: "禁煙",
      hotel_inhouse_hotel_info_add_select_transportation: "移動手段",
      hotel_inhouse_hotel_info_add_select_parking: "駐車",
      hotel_inhouse_hotel_info_add_select_fitness_center: "フィットネス·センター",
      hotel_inhouse_hotel_info_add_select_business_center: "ビジネス·センター",
      hotel_inhouse_hotel_info_add_select_guest_service: "顧客サービス",
      hotel_inhouse_hotel_info_add_select_room_service: "ルーム·サービス",
      hotel_inhouse_hotel_info_add_select_breakfast: "朝食",
      hotel_inhouse_hotel_info_add_select_dining: "ダイニング",
      hotel_inhouse_hotel_info_add_select_executive_lounge: "エグゼクティブ·ラウンジ",
      hotel_inhouse_hotel_info_add_toolbar_bold: "太く",
      hotel_inhouse_hotel_info_add_toolbar_italic: "イタリック",
      hotel_inhouse_hotel_info_add_toolbar_underline: "下線",
      hotel_inhouse_hotel_info_add_toolbar_remove_font_style: "書体取り",
      hotel_inhouse_hotel_info_add_toolbar_font_family: "フォント集",
      hotel_inhouse_hotel_info_add_toolbar_font_size: "フォントサイズ",
      hotel_inhouse_hotel_info_add_toolbar_recent_color: "テキストカラー",
      hotel_inhouse_hotel_info_add_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_inhouse_hotel_info_add_toolbar_unordered_list: "迷走文目録",
      hotel_inhouse_hotel_info_add_toolbar_ordered_list: "注文リスト",
      hotel_inhouse_hotel_info_add_toolbar_paragraph: "短絡",
      hotel_inhouse_hotel_info_add_toolbar_line_height: "線の高さ",
      hotel_inhouse_hotel_info_add_placeholder_content: "内容を入力してください",
      hotel_inhouse_hotel_info_add_button_cancel: "キャンセル",
      hotel_inhouse_hotel_info_add_button_ok: "確認",
      hotel_inhouse_hotel_info_edit_title: "ホテル情報修正",
      hotel_inhouse_hotel_info_edit_placeholder_title: "呼称",
      hotel_inhouse_hotel_info_edit_select_kr: "韓国語",
      hotel_inhouse_hotel_info_edit_select_en: "英語",
      hotel_inhouse_hotel_info_edit_select_cn: "中国語",
      hotel_inhouse_hotel_info_edit_select_jp: "日本語",
      hotel_inhouse_hotel_info_edit_placeholder_index: "索引",
      hotel_inhouse_hotel_info_edit_toolbar_bold: "太く",
      hotel_inhouse_hotel_info_edit_toolbar_italic: "イタリック",
      hotel_inhouse_hotel_info_edit_toolbar_underline: "下線",
      hotel_inhouse_hotel_info_edit_toolbar_remove_font_style: "書体取り",
      hotel_inhouse_hotel_info_edit_toolbar_font_family: "フォント集",
      hotel_inhouse_hotel_info_edit_toolbar_font_size: "フォントサイズ",
      hotel_inhouse_hotel_info_edit_toolbar_recent_color: "最近の使用色",
      hotel_inhouse_hotel_info_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_inhouse_hotel_info_edit_toolbar_unordered_list: "迷走文目録",
      hotel_inhouse_hotel_info_edit_toolbar_ordered_list: "注文リスト",
      hotel_inhouse_hotel_info_edit_toolbar_paragraph: "短絡",
      hotel_inhouse_hotel_info_edit_toolbar_code_view: "コードを見ること",
      hotel_inhouse_hotel_info_edit_toolbar_line_height: "線の高さ",
      hotel_inhouse_hotel_info_edit_button_append: "追加",
      hotel_inhouse_hotel_info_edit_button_remove: "除去",
      hotel_inhouse_hotel_info_edit_button_cancel: "キャンセル",
      hotel_inhouse_hotel_info_edit_button_ok: "確認",
      hotel_inhouse_room_to_room_list_title: "ルームトゥルームリスト",
      hotel_inhouse_room_to_room_list_button_alive: "使用中",
      hotel_inhouse_room_to_room_list_placeholder_select_box_room_to_room: "ルームトゥルーム",
      hotel_inhouse_room_to_room_list_select_room_no: "客室番号",
      hotel_inhouse_room_to_room_list_select_email: "Eメール",
      hotel_inhouse_room_to_room_list_select_user_name: "ユーザー名",
      hotel_inhouse_room_to_room_list_placeholder_search: "検索",
      hotel_inhouse_room_to_room_list_label_no: "番号",
      hotel_inhouse_room_to_room_list_label_sender_room_no: "発信者の客室番号",
      hotel_inhouse_room_to_room_list_label_sender_name: "発信者名",
      hotel_inhouse_room_to_room_list_label_sender_email: "送信者Eメール",
      hotel_inhouse_room_to_room_list_label_key_type: "キータイプ",
      hotel_inhouse_room_to_room_list_label_expired_at: "満了日",
      hotel_inhouse_room_to_room_list_label_receiver_room_no: "受信者の客室番号",
      hotel_inhouse_room_to_room_list_label_receiver_email: "受信者メール",
      hotel_inhouse_room_to_room_list_label_receiver_status: "受信者現況",
      hotel_inhouse_room_to_room_list_label_date: "日付",
      hotel_inhouse_room_to_room_list_guide_empty: "リストが空です",
      hotel_inhouse_room_to_room_detail_title: "ルームツールームの詳細情報",
      hotel_inhouse_room_to_room_detail_label_sender_room_no: "発信者の客室番号",
      hotel_inhouse_room_to_room_detail_label_sender_name: "発信者名",
      hotel_inhouse_room_to_room_detail_label_sender_email: "送信者Eメール",
      hotel_inhouse_room_to_room_detail_label_key_type: "ルームツールームキータイプ",
      hotel_inhouse_room_to_room_detail_label_expired_at: "ルームトゥルーム満了日",
      hotel_inhouse_room_to_room_detail_label_receiver_room_no: "受信者の客室番号",
      hotel_inhouse_room_to_room_detail_label_receiver_email: "受信者メール",
      hotel_inhouse_room_to_room_detail_label_receiver_status: "受信者現況",
      hotel_inhouse_room_to_room_detail_label_created_at: "生成時間",
      hotel_inhouse_room_to_room_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_concierge_request_list_title: "コンシェルジュリクエストリスト(確認前)",
      hotel_inhouse_concierge_request_list_label_that_date_request: "顧客要請数",
      hotel_inhouse_concierge_request_list_label_that_date_not_confirmed: "お客様のリクエスト(確認前)",
      hotel_inhouse_concierge_request_list_label_taxi_ex: "タクシー·エクスプレス",
      hotel_inhouse_concierge_request_list_label_taxi: "タクシー",
      hotel_inhouse_concierge_request_list_label_parcel_ex: "宅配便エクスプレス",
      hotel_inhouse_concierge_request_list_label_parcel: "宅配サービス",
      hotel_inhouse_concierge_request_list_label_luggage_ex: "手荷物サービス·エクスプレス",
      hotel_inhouse_concierge_request_list_label_luggage: "手荷物サービス",
      hotel_inhouse_concierge_request_list_label_total: "総料金",
      hotel_inhouse_concierge_request_list_select_request_type: "リクエストタイプ（すべて）",
      hotel_inhouse_concierge_request_list_select_request_type_all: "冒頭",
      hotel_inhouse_concierge_request_list_select_taxi: "タクシー·サービス",
      hotel_inhouse_concierge_request_list_select_parcel: "宅配サービス",
      hotel_inhouse_concierge_request_list_select_luggage: "手荷物サービス",
      hotel_inhouse_concierge_request_list_select_express: "タスク（すべて）",
      hotel_inhouse_concierge_request_list_select_express_all: "冒頭",
      hotel_inhouse_concierge_request_list_select_yes: "エクスプレス",
      hotel_inhouse_concierge_request_list_select_no: "一般",
      hotel_inhouse_concierge_request_list_select_request: "リクエスト(すべて)",
      hotel_inhouse_concierge_request_list_select_request_all: "冒頭",
      hotel_inhouse_concierge_request_list_select_room_no: "客室番号",
      hotel_inhouse_concierge_request_list_select_user_name: "ユーザー名",
      hotel_inhouse_concierge_request_list_placeholder_search: "検索",
      hotel_inhouse_concierge_request_list_label_room_no: "客室番号",
      hotel_inhouse_concierge_request_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_concierge_request_list_label_user_name: "ユーザー名",
      hotel_inhouse_concierge_request_list_label_request_date: "要請日",
      hotel_inhouse_concierge_request_list_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_request_list_label_express: "タスク",
      hotel_inhouse_concierge_request_list_label_number_of_item: "項目数",
      hotel_inhouse_concierge_request_list_label_action_status: "現在の動作状態",
      hotel_inhouse_concierge_request_list_label_date: "日付",
      hotel_inhouse_concierge_request_list_guide_empty: "リストが空です",
      hotel_inhouse_concierge_request_detail_title: "要請 詳細",
      hotel_inhouse_concierge_request_detail_label_room_no: "客室番号",
      hotel_inhouse_concierge_request_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_concierge_request_detail_label_user_name: "ユーザー名",
      hotel_inhouse_concierge_request_detail_label_email: "Eメール",
      hotel_inhouse_concierge_request_detail_label_stay: "投宿",
      hotel_inhouse_concierge_request_detail_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_request_detail_label_status: "現況",
      hotel_inhouse_concierge_request_detail_label_number_of_item: "項目数",
      hotel_inhouse_concierge_request_detail_label_request_at: "要請時間",
      hotel_inhouse_concierge_request_detail_label_created_at: "生成時間",
      hotel_inhouse_concierge_request_detail_label_total: "総金額",
      hotel_inhouse_concierge_request_detail_button_not_available: "サービス不可",
      hotel_inhouse_concierge_request_detail_button_myconect: "MyCONECT",
      hotel_inhouse_concierge_request_detail_button_confirm: "確認",
      hotel_inhouse_concierge_request_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_concierge_request_modal_title: "MyCONECT",
      hotel_inhouse_concierge_request_modal_button_one_time: "一度",
      hotel_inhouse_concierge_request_modal_button_one_hour: "一時間後",
      hotel_inhouse_concierge_request_modal_button_two_hour: "二時間後",
      hotel_inhouse_concierge_request_modal_button_three_hour: "三時間後",
      hotel_inhouse_concierge_request_modal_button_unlimited: "不明",
      hotel_inhouse_concierge_request_modal_button_cancel: "キャンセル",
      hotel_inhouse_concierge_process_list_title: "「コンシェルジュ進行中リスト（確認後）」",
      hotel_inhouse_concierge_process_list_label_request: "顧客要請数",
      hotel_inhouse_concierge_process_list_label_unassigned: "日付未指定",
      hotel_inhouse_concierge_process_list_label_taxi_ex: "タクシー·エクスプレス",
      hotel_inhouse_concierge_process_list_label_taxi: "タクシー",
      hotel_inhouse_concierge_process_list_label_parcel_ex: "宅配便エクスプレス",
      hotel_inhouse_concierge_process_list_label_parcel: "宅配サービス",
      hotel_inhouse_concierge_process_list_label_luggage_ex: "手荷物サービス·エクスプレス",
      hotel_inhouse_concierge_process_list_label_luggage: "手荷物サービス",
      hotel_inhouse_concierge_process_list_label_total: "総料金",
      hotel_inhouse_concierge_process_list_select_request_type: "リクエストタイプ（すべて）",
      hotel_inhouse_concierge_process_list_select_taxi: "タクシー",
      hotel_inhouse_concierge_process_list_select_parcel: "宅配サービス",
      hotel_inhouse_concierge_process_list_select_luggage: "手荷物サービス",
      hotel_inhouse_concierge_process_list_select_express: "タスク（すべて）",
      hotel_inhouse_concierge_process_list_select_yes: "エクスプレス",
      hotel_inhouse_concierge_process_list_select_no: "一般",
      hotel_inhouse_concierge_process_list_select_request: "リクエスト(すべて)",
      hotel_inhouse_concierge_process_list_select_room_no: "客室番号",
      hotel_inhouse_concierge_process_list_select_user_name: "ユーザー名",
      hotel_inhouse_concierge_process_list_placeholder_search: "検索",
      hotel_inhouse_concierge_process_list_label_room_no: "客室番号",
      hotel_inhouse_concierge_process_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_concierge_process_list_label_user_name: "ユーザー名",
      hotel_inhouse_concierge_process_list_label_request_date: "要請日",
      hotel_inhouse_concierge_process_list_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_process_list_label_express: "タスク",
      hotel_inhouse_concierge_process_list_label_number_of_item: "項目数",
      hotel_inhouse_concierge_process_list_label_action_status: "現在の動作状態",
      hotel_inhouse_concierge_process_list_label_date: "日付",
      hotel_inhouse_concierge_process_list_guide_empty: "リストが空です",
      hotel_inhouse_concierge_process_detail_title: "リクエスト (リクエスト後) 詳細情報",
      hotel_inhouse_concierge_process_detail_label_room_no: "客室番号",
      hotel_inhouse_concierge_process_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_concierge_process_detail_label_user_name: "ユーザー名",
      hotel_inhouse_concierge_process_detail_label_email: "Eメール",
      hotel_inhouse_concierge_process_detail_label_stay: "投宿",
      hotel_inhouse_concierge_process_detail_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_process_detail_label_status: "現況",
      hotel_inhouse_concierge_process_detail_label_number_of_item: "項目数",
      hotel_inhouse_concierge_process_detail_label_request_at: "要請時間",
      hotel_inhouse_concierge_process_detail_label_created_at: "生成時間",
      hotel_inhouse_concierge_process_detail_button_stand_by: "待機",
      hotel_inhouse_concierge_process_detail_button_vehicle_no: "車両番号",
      hotel_inhouse_concierge_process_detail_button_complete: "完了",
      hotel_inhouse_concierge_process_detail_button_stop: "中断",
      hotel_inhouse_concierge_process_detail_button_pickup: "ピックアップ",
      hotel_inhouse_concierge_process_detail_button_start: "始まり",
      hotel_inhouse_concierge_process_detail_button_deliver: "出前",
      hotel_inhouse_concierge_process_detail_button_change: "変更",
      hotel_inhouse_concierge_process_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_concierge_process_modal_title_vehicle_no: "車両番号",
      hotel_inhouse_concierge_process_modal_placeholder_vehicle_no: "車両番号を入力してください",
      hotel_inhouse_concierge_process_modal_title_luggage: "手荷物の個数",
      hotel_inhouse_concierge_process_modal_placeholder_luggage: "手荷物の数を入力してください。",
      hotel_inhouse_concierge_process_modal_title_parcel: "宅配情報",
      hotel_inhouse_concierge_process_modal_button_cancel: "キャンセル",
      hotel_inhouse_concierge_process_modal_button_ok: "確認",
      hotel_inhouse_concierge_process_modal_title_stop_message: "中断メッセージ",
      hotel_inhouse_concierge_process_modal_button_unknown_situation: "現況把握不可",
      hotel_inhouse_concierge_process_modal_button_no_taxi: "タクシー·ノーショー",
      hotel_inhouse_concierge_process_modal_button_no_guest: "顧客ノーショー",
      hotel_inhouse_concierge_process_modal_button_waiting_30: "待ち時間30分超過",
      hotel_inhouse_concierge_process_modal_button_waiting_60: "待ち時間60分超過",
      hotel_inhouse_concierge_process_modal_button_no_motorcycle: "配達バイクノーショー",
      hotel_inhouse_concierge_process_modal_button_not_luggage: "手荷物の準備ができていない",
      hotel_inhouse_concierge_myconect_list_title: "コンシェルジュスケジュール処理結果リスト",
      hotel_inhouse_concierge_myconect_list_label_user_count: "ユーザーキャンセル数",
      hotel_inhouse_concierge_myconect_list_label_hotel_count: "ホテルのキャンセル数",
      hotel_inhouse_concierge_myconect_list_label_taxi_ex: "タクシー·エクスプレス",
      hotel_inhouse_concierge_myconect_list_label_taxi: "タクシー",
      hotel_inhouse_concierge_myconect_list_label_parcel_ex: "宅配便エクスプレス",
      hotel_inhouse_concierge_myconect_list_label_parcel: "宅配サービス",
      hotel_inhouse_concierge_myconect_list_label_luggage_ex: "手荷物サービス·エクスプレス",
      hotel_inhouse_concierge_myconect_list_label_luggage: "手荷物サービス",
      hotel_inhouse_concierge_myconect_list_label_total: "総金額",
      hotel_inhouse_concierge_myconect_list_label_room_no: "客室番号",
      hotel_inhouse_concierge_myconect_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_concierge_myconect_list_label_user_name: "ユーザー名",
      hotel_inhouse_concierge_myconect_list_label_request_date: "要請日",
      hotel_inhouse_concierge_myconect_list_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_myconect_list_label_express: "タスク",
      hotel_inhouse_concierge_myconect_list_label_action_status: "現在の動作状態",
      hotel_inhouse_concierge_myconect_list_label_myconect_at: "MyCONECT 動作時間",
      hotel_inhouse_concierge_myconect_list_label_completed_at: "完了時間",
      hotel_inhouse_concierge_myconect_list_label_date: "日付",
      hotel_inhouse_concierge_myconect_list_guide_empty: "リストが空です",
      hotel_inhouse_concierge_myconect_detail_title: "スケジュール処理結果 詳細",
      hotel_inhouse_concierge_myconect_detail_label_room_no: "客室番号",
      hotel_inhouse_concierge_myconect_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_concierge_myconect_detail_label_user_name: "ユーザー名",
      hotel_inhouse_concierge_myconect_detail_label_email: "Eメール",
      hotel_inhouse_concierge_myconect_detail_label_stay: "投宿",
      hotel_inhouse_concierge_myconect_detail_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_myconect_detail_label_status: "現況",
      hotel_inhouse_concierge_myconect_detail_label_number_of_item: "項目数",
      hotel_inhouse_concierge_myconect_detail_label_request_at: "要請時間",
      hotel_inhouse_concierge_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_concierge_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_concierge_multi_myconect_list_title: "コンシェルジュスケジュール設定リスト",
      hotel_inhouse_concierge_multi_myconect_list_button_alive: "使用中",
      hotel_inhouse_concierge_multi_myconect_list_label_staff_number: "スタッフ番号",
      hotel_inhouse_concierge_multi_myconect_list_label_request: "要請",
      hotel_inhouse_concierge_multi_myconect_list_label_department: "部署",
      hotel_inhouse_concierge_multi_myconect_list_label_email: "Eメール",
      hotel_inhouse_concierge_multi_myconect_list_label_respond: "MyCONECT応答",
      hotel_inhouse_concierge_multi_myconect_list_label_from: "~から",
      hotel_inhouse_concierge_multi_myconect_list_label_to: "~まで",
      hotel_inhouse_concierge_multi_myconect_list_label_date: "日付",
      hotel_inhouse_concierge_multi_myconect_list_button_add: "追加",
      hotel_inhouse_concierge_multi_myconect_add_title: "コンシェルジュスケジュール設定追加",
      hotel_inhouse_concierge_multi_myconect_add_label_request_type: "要請タイプ",
      hotel_inhouse_concierge_multi_myconect_add_placeholder_select_box_choose: "選択",
      hotel_inhouse_concierge_multi_myconect_add_select_box_luggage: "手荷物サービス",
      hotel_inhouse_concierge_multi_myconect_add_select_box_parcel: "宅配サービス",
      hotel_inhouse_concierge_multi_myconect_add_select_box_taxi: "タクシー",
      hotel_inhouse_concierge_multi_myconect_add_label_date: "日付",
      hotel_inhouse_concierge_multi_myconect_add_label_respond: "MyCONECT応答",
      hotel_inhouse_concierge_multi_myconect_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_concierge_multi_myconect_add_button_add: "追加",
      hotel_inhouse_concierge_multi_myconect_add_validation_type_request: "* リクエストタイプを選択してください。",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_start: "* 日付を選択してください。",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_end: "* 日付を選択してください。",
      hotel_inhouse_concierge_multi_myconect_add_validation_respond: "* MyCONECTの応答を選択してください。",
      hotel_inhouse_concierge_multi_myconect_detail_title: "コンシェルジュスケジュール設定情報",
      hotel_inhouse_concierge_multi_myconect_detail_label_update: "アップデートの日付",
      hotel_inhouse_concierge_multi_myconect_detail_label_date: "日付",
      hotel_inhouse_concierge_multi_myconect_detail_label_respond: "MyCONECT応答",
      hotel_inhouse_concierge_multi_myconect_detail_label_staff_number: "スタッフ番号",
      hotel_inhouse_concierge_multi_myconect_detail_label_request: "要請",
      hotel_inhouse_concierge_multi_myconect_detail_label_department: "部署",
      hotel_inhouse_concierge_multi_myconect_detail_label_email: "Eメール",
      hotel_inhouse_concierge_multi_myconect_detail_label_released_at: "解除時間",
      hotel_inhouse_concierge_multi_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_concierge_multi_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_concierge_multi_myconect_detail_button_cancel: "キャンセル",
      hotel_inhouse_concierge_multi_myconect_detail_button_release: "解除",
      hotel_inhouse_concierge_multi_myconect_detail_button_edit: "修整",
      hotel_inhouse_concierge_multi_myconect_detail_button_ok: "確認",
      hotel_inhouse_housekeeping_request_list_title: "ハウスキーピングリクエストリスト(確認前)",
      hotel_inhouse_housekeeping_request_list_label_request: "顧客要請数",
      hotel_inhouse_housekeeping_request_list_label_not_confirmed: "日付未確認",
      hotel_inhouse_housekeeping_request_list_label_mur: "客室清掃",
      hotel_inhouse_housekeeping_request_list_label_tds: "客室整頓",
      hotel_inhouse_housekeeping_request_list_label_green: "グリーン·キャンペーン",
      hotel_inhouse_housekeeping_request_list_label_ironing_ex: "アイロンエクスプレス",
      hotel_inhouse_housekeeping_request_list_label_ironing: "アイロンサービス",
      hotel_inhouse_housekeeping_request_list_label_laundry_ex: "ランドリー·エクスプレス",
      hotel_inhouse_housekeeping_request_list_label_laundry: "ランドリーサービス",
      hotel_inhouse_housekeeping_request_list_label_amenity: "アメニティ·サービス",
      hotel_inhouse_housekeeping_request_list_label_total: "総料金",
      hotel_inhouse_housekeeping_request_list_select_request_type: "リクエストタイプ（すべて）",
      hotel_inhouse_housekeeping_request_list_select_mur: "客室清掃",
      hotel_inhouse_housekeeping_request_list_select_tds: "客室整頓",
      hotel_inhouse_housekeeping_request_list_select_green: "グリーン·キャンペーン",
      hotel_inhouse_housekeeping_request_list_select_ironing: "アイロンサービス",
      hotel_inhouse_housekeeping_request_list_select_laundry: "ランドリーサービス",
      hotel_inhouse_housekeeping_request_list_select_amenity: "アメニティ·サービス",
      hotel_inhouse_housekeeping_request_list_select_request_express: "タスク（すべて）",
      hotel_inhouse_housekeeping_request_list_select_yes: "エクスプレス",
      hotel_inhouse_housekeeping_request_list_select_no: "一般",
      hotel_inhouse_housekeeping_request_list_select_request: "リクエスト(すべて)",
      hotel_inhouse_housekeeping_request_list_select_room_no: "客室番号",
      hotel_inhouse_housekeeping_request_list_select_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_request_list_placeholder_search: "検索",
      hotel_inhouse_housekeeping_request_list_label_roon_no: "客室番号",
      hotel_inhouse_housekeeping_request_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_housekeeping_request_list_label_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_request_list_label_request_date: "要請日",
      hotel_inhouse_housekeeping_request_list_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_request_list_label_express: "タスク",
      hotel_inhouse_housekeeping_request_list_label_action_status: "現在の動作状態",
      hotel_inhouse_housekeeping_request_list_label_date: "日付",
      hotel_inhouse_housekeeping_request_list_guide_empty: "リストが空です",
      hotel_inhouse_housekeeping_request_detail_title: "要請 詳細",
      hotel_inhouse_housekeeping_request_detail_label_roon_no: "客室番号",
      hotel_inhouse_housekeeping_request_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_housekeeping_request_detail_label_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_request_detail_label_email: "Eメール",
      hotel_inhouse_housekeeping_request_detail_label_stay: "投宿",
      hotel_inhouse_housekeeping_request_detail_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_request_detail_label_status: "現況",
      hotel_inhouse_housekeeping_request_detail_label_request_at: "要請時間",
      hotel_inhouse_housekeeping_request_detail_label_created_at: "生成時間",
      hotel_inhouse_housekeeping_request_detail_label_total: "総料金",
      hotel_inhouse_housekeeping_request_detail_button_not_availble: "サービス不可",
      hotel_inhouse_housekeeping_request_detail_button_myconect: "MyCONECT",
      hotel_inhouse_housekeeping_request_detail_button_auto: "自動",
      hotel_inhouse_housekeeping_request_detail_button_delay: "遅延",
      hotel_inhouse_housekeeping_request_detail_button_confirm: "確認",
      hotel_inhouse_housekeeping_request_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_housekeeping_request_modal_title_delay: "遅延メッセージ",
      hotel_inhouse_housekeeping_request_modal_button_delay_30: "30分遅れ",
      hotel_inhouse_housekeeping_request_modal_button_delay_60: "60分遅れ",
      hotel_inhouse_housekeeping_request_modal_button_as_soon_as: "早いうちに",
      hotel_inhouse_housekeeping_request_modal_title_myconect: "MyCONECT",
      hotel_inhouse_housekeeping_request_modal_button_one_time: "一度",
      hotel_inhouse_housekeeping_request_modal_button_one_hour: "一時間後",
      hotel_inhouse_housekeeping_request_modal_button_two_hour: "二時間後",
      hotel_inhouse_housekeeping_request_modal_button_three_hour: "三時間後",
      hotel_inhouse_housekeeping_request_modal_button_unlimited: "不明",
      hotel_inhouse_housekeeping_request_modal_button_cancel: "キャンセル",
      hotel_inhouse_housekeeping_process_list_title: "ハウスキーピング進行中リスト(確認完了)",
      hotel_inhouse_housekeeping_process_list_label_request: "顧客要請数",
      hotel_inhouse_housekeeping_process_list_label_unassigned: "日付未指定",
      hotel_inhouse_housekeeping_process_list_label_mur: "客室清掃",
      hotel_inhouse_housekeeping_process_list_label_tds: "客室整頓",
      hotel_inhouse_housekeeping_process_list_label_housekeeping_total: "銃",
      hotel_inhouse_housekeeping_process_list_label_ironing_ex: "アイロンエクスプレス",
      hotel_inhouse_housekeeping_process_list_label_ironing: "アイロンサービス",
      hotel_inhouse_housekeeping_process_list_label_laundry_ex: "ランドリー·エクスプレス",
      hotel_inhouse_housekeeping_process_list_label_laundry: "ランドリーサービス",
      hotel_inhouse_housekeeping_process_list_label_guest_service_total: "総料金",
      hotel_inhouse_housekeeping_process_list_label_green: "グリーン·キャンペーン",
      hotel_inhouse_housekeeping_process_list_label_dnd: "妨害禁止",
      hotel_inhouse_housekeeping_process_list_label_amenity_number: "アメニティ項目数",
      hotel_inhouse_housekeeping_process_list_label_bath_towel: "バスタオル",
      hotel_inhouse_housekeeping_process_list_label_face_towel: "フェイスタオル",
      hotel_inhouse_housekeeping_process_list_label_hand_towel: "ハンドタオル",
      hotel_inhouse_housekeeping_process_list_label_bath_gown: "バスローブ",
      hotel_inhouse_housekeeping_process_list_label_razor: "シェーバー",
      hotel_inhouse_housekeeping_process_list_label_shampoo: "シャンプー",
      hotel_inhouse_housekeeping_process_list_label_conditioner: "コンディショナー",
      hotel_inhouse_housekeeping_process_list_label_soap: "せっけん",
      hotel_inhouse_housekeeping_process_list_label_amenity_total: "銃",
      hotel_inhouse_housekeeping_process_list_select_request_type: "リクエストタイプ（すべて）",
      hotel_inhouse_housekeeping_process_list_select_mur: "客室清掃",
      hotel_inhouse_housekeeping_process_list_select_tds: "客室整頓",
      hotel_inhouse_housekeeping_process_list_select_green: "グリーン·キャンペーン",
      hotel_inhouse_housekeeping_process_list_select_dnd: "妨害禁止",
      hotel_inhouse_housekeeping_process_list_select_ironing: "アイロンサービス",
      hotel_inhouse_housekeeping_process_list_select_laundry: "ランドリーサービス",
      hotel_inhouse_housekeeping_process_list_select_amenity: "アメニティ·サービス",
      hotel_inhouse_housekeeping_process_list_select_express: "タスク（すべて）",
      hotel_inhouse_housekeeping_process_list_select_express_all: "冒頭",
      hotel_inhouse_housekeeping_process_list_select_yes: "エクスプレス",
      hotel_inhouse_housekeeping_process_list_select_no: "一般",
      hotel_inhouse_housekeeping_process_list_select_floor: "階 (すべて )",
      hotel_inhouse_housekeeping_process_list_select_floor_1: "一階",
      hotel_inhouse_housekeeping_process_list_select_floor_2: "二階",
      hotel_inhouse_housekeeping_process_list_select_floor_3: "三階",
      hotel_inhouse_housekeeping_process_list_select_floor_4: "四階",
      hotel_inhouse_housekeeping_process_list_select_floor_5: "五階",
      hotel_inhouse_housekeeping_process_list_select_floor_6: "六階",
      hotel_inhouse_housekeeping_process_list_select_floor_7: "七階",
      hotel_inhouse_housekeeping_process_list_select_floor_8: "8階",
      hotel_inhouse_housekeeping_process_list_select_request: "リクエスト(すべて)",
      hotel_inhouse_housekeeping_process_list_select_room_no: "客室番号",
      hotel_inhouse_housekeeping_process_list_placeholder_search: "検索",
      hotel_inhouse_housekeeping_process_list_label_roon_no: "客室番号",
      hotel_inhouse_housekeeping_process_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_housekeeping_process_list_label_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_process_list_label_request_date: "要請日",
      hotel_inhouse_housekeeping_process_list_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_process_list_label_express: "タスク",
      hotel_inhouse_housekeeping_process_list_label_action_status: "現在の動作状態",
      hotel_inhouse_housekeeping_process_list_label_date: "日付",
      hotel_inhouse_housekeeping_process_list_guide_empty: "リストが空です",
      hotel_inhouse_housekeeping_process_detail_title: "要請 詳細",
      hotel_inhouse_housekeeping_process_detail_label_roon_no: "客室番号",
      hotel_inhouse_housekeeping_process_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_housekeeping_process_detail_label_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_process_detail_label_email: "Eメール",
      hotel_inhouse_housekeeping_process_detail_label_stay: "投宿",
      hotel_inhouse_housekeeping_process_detail_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_process_detail_label_status: "現況",
      hotel_inhouse_housekeeping_process_detail_label_request_at: "要請時間",
      hotel_inhouse_housekeeping_process_detail_label_created_at: "生成時間",
      hotel_inhouse_housekeeping_process_detail_button_put_off: "煙",
      hotel_inhouse_housekeeping_process_detail_button_complete: "完了",
      hotel_inhouse_housekeeping_process_detail_button_start: "始まり",
      hotel_inhouse_housekeeping_process_detail_button_stop: "中断",
      hotel_inhouse_housekeeping_process_detail_button_deliver: "出前",
      hotel_inhouse_housekeeping_process_detail_button_pickup: "ピックアップ",
      hotel_inhouse_housekeeping_process_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_housekeeping_process_modal_title_put_off: "延期メッセージ",
      hotel_inhouse_housekeeping_process_modal_button_cancel: "キャンセル",
      hotel_inhouse_housekeeping_process_modal_button_delay_30: "30分遅れ",
      hotel_inhouse_housekeeping_process_modal_button_delay_60: "60分遅れ",
      hotel_inhouse_housekeeping_process_modal_button_as_soon_as: "早いうちに",
      hotel_inhouse_housekeeping_process_modal_button_out_of_order: "客室不良",
      hotel_inhouse_housekeeping_process_modal_title_stop_message: "中断メッセージ",
      hotel_inhouse_housekeeping_process_modal_button_unknown_situation: "現況把握不可",
      hotel_inhouse_housekeeping_process_modal_button_cancel_service: "サービスのキャンセル",
      hotel_inhouse_housekeeping_process_modal_button_not_found_it: "見あたらない",
      hotel_inhouse_housekeeping_process_modal_button_no_guest_room: "顧客不在",
      hotel_inhouse_housekeeping_process_modal_button_change_items: "項目変更",
      hotel_inhouse_housekeeping_myconect_list_title: "ハウスキーピングスケジュール処理結果リスト",
      hotel_inhouse_housekeeping_myconect_list_label_user_count: "ユーザーキャンセルカウント",
      hotel_inhouse_housekeeping_myconect_list_label_hotel_count: "ホテルキャンセルカウント",
      hotel_inhouse_housekeeping_myconect_list_label_mur: "客室清掃",
      hotel_inhouse_housekeeping_myconect_list_label_tds: "客室整頓",
      hotel_inhouse_housekeeping_myconect_list_label_ironing_ex: "アイロンエクスプレス",
      hotel_inhouse_housekeeping_myconect_list_label_ironing: "アイロンサービス",
      hotel_inhouse_housekeeping_myconect_list_label_laundry_ex: "ランドリー·エクスプレス",
      hotel_inhouse_housekeeping_myconect_list_label_laundry: "ランドリーサービス",
      hotel_inhouse_housekeeping_myconect_list_label_total: "総料金",
      hotel_inhouse_housekeeping_myconect_list_label_roon_no: "客室番号",
      hotel_inhouse_housekeeping_myconect_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_housekeeping_myconect_list_label_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_myconect_list_label_request_date: "要請日",
      hotel_inhouse_housekeeping_myconect_list_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_myconect_list_label_express: "タスク",
      hotel_inhouse_housekeeping_myconect_list_label_action_status: "現在の動作状態",
      hotel_inhouse_housekeeping_myconect_list_label_myconect_at: "MyCONECT 動作時間",
      hotel_inhouse_housekeeping_myconect_list_label_completed_at: "完了時間",
      hotel_inhouse_housekeeping_myconect_list_label_date: "日付",
      hotel_inhouse_housekeeping_myconect_list_guide_empty: "リストが空です",
      hotel_inhouse_housekeeping_myconect_detail_title: "スケジュール処理結果 詳細",
      hotel_inhouse_housekeeping_myconect_detail_label_roon_no: "客室番号",
      hotel_inhouse_housekeeping_myconect_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_housekeeping_myconect_detail_label_user_name: "ユーザー名",
      hotel_inhouse_housekeeping_myconect_detail_label_email: "Eメール",
      hotel_inhouse_housekeeping_myconect_detail_label_stay: "投宿",
      hotel_inhouse_housekeeping_myconect_detail_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_myconect_detail_label_status: "現況",
      hotel_inhouse_housekeeping_myconect_detail_label_request_at: "要請時間",
      hotel_inhouse_housekeeping_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_housekeeping_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_housekeeping_multi_myconect_list_title: "ハウスキーピングスケジュールリスト",
      hotel_inhouse_housekeeping_multi_myconect_list_button_alive: "使用中",
      hotel_inhouse_housekeeping_multi_myconect_list_label_staff_number: "スタッフ番号",
      hotel_inhouse_housekeeping_multi_myconect_list_label_request: "要請",
      hotel_inhouse_housekeeping_multi_myconect_list_label_department: "部署",
      hotel_inhouse_housekeeping_multi_myconect_list_label_email: "Eメール",
      hotel_inhouse_housekeeping_multi_myconect_list_label_respond: "MyCONECT応答",
      hotel_inhouse_housekeeping_multi_myconect_list_label_from: "~から",
      hotel_inhouse_housekeeping_multi_myconect_list_label_to: "~まで",
      hotel_inhouse_housekeeping_multi_myconect_list_label_date: "日付",
      hotel_inhouse_housekeeping_multi_myconect_list_button_add: "追加",
      hotel_inhouse_housekeeping_multi_myconect_add_title: "ハウスキーピングスケジュール追加",
      hotel_inhouse_housekeeping_multi_myconect_add_label_request_type: "要請タイプ",
      hotel_inhouse_housekeeping_multi_myconect_add_placeholder_select_box_choose: "選択",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_ironing: "アイロンサービス",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_laundry: "ランドリーサービス",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_amenity: "アメニティ·サービス",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_make_up_room: "客室清掃",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_turn_down_service: "客室整頓",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_green_campaign: "グリーン·キャンペーン",
      hotel_inhouse_housekeeping_multi_myconect_add_label_date: "日付",
      hotel_inhouse_housekeeping_multi_myconect_add_label_respond: "MyCONECT応答",
      hotel_inhouse_housekeeping_multi_myconect_add_select_not_availble: "使用不可",
      hotel_inhouse_housekeeping_multi_myconect_add_select_confirm: "確認",
      hotel_inhouse_housekeeping_multi_myconect_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_housekeeping_multi_myconect_add_button_add: "追加",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_type_request: "* リクエストタイプを選択してください。",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_start: "* 日付を選択してください。",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_end: "* 日付を選択してください。",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_respond: "* MyCONECTの応答を選択してください。",
      hotel_inhouse_housekeeping_multi_myconect_add_button_set: "設定",
      hotel_inhouse_housekeeping_multi_myconect_detail_title: "ハウスキーピングスケジュール情報",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_update: "アップデートの日付",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_date: "日付",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_respond: "MyCONECT応答",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_staff_number: "スタッフ番号",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_request: "要請",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_department: "部署",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_email: "Eメール",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_released_at: "解除時間",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_cancel: "キャンセル",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_release: "解除",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_edit: "修整",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_ok: "確認",
      hotel_inhouse_valet_list_title: "バレットパーキングリクエストリスト",
      hotel_inhouse_valet_list_label_valet_requst: "バレット要請",
      hotel_inhouse_valet_list_label_requesting: "要請中",
      hotel_inhouse_valet_list_label_parking: "駐車",
      hotel_inhouse_valet_list_label_waiting: "待機中",
      hotel_inhouse_valet_list_label_preparing: "準備中",
      hotel_inhouse_valet_list_label_standby: "待機",
      hotel_inhouse_valet_list_label_return: "返還",
      hotel_inhouse_valet_list_label_total: "銃",
      hotel_inhouse_valet_list_placeholder_select_box_request: "リクエスト(すべて)",
      hotel_inhouse_valet_list_select_request_all: "冒頭",
      hotel_inhouse_valet_list_select_vehicle_no: "車両番号",
      hotel_inhouse_valet_list_select_room_no: "客室番号",
      hotel_inhouse_valet_list_select_user_name: "ユーザー名",
      hotel_inhouse_valet_list_placeholder_search: "検索",
      hotel_inhouse_valet_list_label_confirmation_no: "予約番号",
      hotel_inhouse_valet_list_label_room_no: "客室番号",
      hotel_inhouse_valet_list_label_user_name: "ユーザー名",
      hotel_inhouse_valet_list_label_request_date: "要請日",
      hotel_inhouse_valet_list_label_location_no: "位置番号",
      hotel_inhouse_valet_list_label_vehicle_no: "車両番号",
      hotel_inhouse_valet_list_label_action_status: "現在の動作状態",
      hotel_inhouse_valet_list_label_date: "日付",
      hotel_inhouse_valet_detail_title: "バレットパーキング リクエスト 詳細",
      hotel_inhouse_valet_detail_label_confirmation_no: "予約番号",
      hotel_inhouse_valet_detail_label_room_no: "客室番号",
      hotel_inhouse_valet_detail_label_user_name: "ユーザー名",
      hotel_inhouse_valet_detail_label_email: "Eメール",
      hotel_inhouse_valet_detail_label_request_type: "要請タイプ",
      hotel_inhouse_valet_detail_label_location_no: "位置番号",
      hotel_inhouse_valet_detail_label_vehicle_no: "車両番号",
      hotel_inhouse_valet_detail_label_status: "状態",
      hotel_inhouse_valet_detail_label_request_at: "要請時間",
      hotel_inhouse_valet_detail_label_created_at: "生成時間",
      hotel_inhouse_valet_detail_button_valet_no: "バレット受付",
      hotel_inhouse_valet_detail_button_change: "変更",
      hotel_inhouse_valet_detail_button_myconect: "MyCONECT",
      hotel_inhouse_valet_detail_button_stop: "中断",
      hotel_inhouse_valet_detail_button_location: "位置",
      hotel_inhouse_valet_detail_button_standby: "待機",
      hotel_inhouse_valet_detail_button_handover: "インド",
      hotel_inhouse_valet_detail_button_return: "返還",
      hotel_inhouse_valet_detail_button_settlement: "直接決済",
      hotel_inhouse_valet_detail_button_room_posting: "客室請求",
      hotel_inhouse_valet_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_valet_detail_button_go_to_detail: "詳細に行く",
      hotel_inhouse_valet_detail_button_payment: "決済",
      hotel_inhouse_valet_detail_business_list_label_no: "番号",
      hotel_inhouse_valet_detail_business_list_label_type: "タイプ",
      hotel_inhouse_valet_detail_business_list_label_task: "タスク",
      hotel_inhouse_valet_detail_business_list_label_time_from: "使用可能時間 ~ から",
      hotel_inhouse_valet_detail_business_list_label_time_to: "使用可能時間 ~まで",
      hotel_inhouse_valet_detail_business_list_label_charge_amount: "請求金額",
      hotel_inhouse_valet_detail_business_list_label_currency: "通貨",
      hotel_inhouse_valet_detail_placeholder_select_box_choose: "選択",
      hotel_inhouse_valet_detail_modal_valet_title: "バレット受付番号",
      hotel_inhouse_valet_detail_modal_valet_request_received: "リクエストを受け付けました。",
      hotel_inhouse_valet_detail_modal_change_title: "車両番号変更",
      hotel_inhouse_valet_detail_modal_change_placeholder_vehicle_no: "車両番号",
      hotel_inhouse_valet_detail_modal_myconect_title: "位置番号",
      hotel_inhouse_valet_detail_modal_myconect_placeholder_parking_area_no: "駐車区域番号",
      hotel_inhouse_valet_detail_modal_stop_title: "中断メッセージ",
      hotel_inhouse_valet_detail_modal_stop_message_unparked: "駐車されていない状態でキャンセル",
      hotel_inhouse_valet_detail_modal_stop_message_parked: "駐車された状態でキャンセル",
      hotel_inhouse_valet_detail_modal_stop_message_unknown: "現況把握不可",
      hotel_inhouse_valet_detail_modal_valet_location_title: "駐車位置番号",
      hotel_inhouse_valet_detail_modal_valet_location_message: "車のピックアップを開始します。",
      hotel_inhouse_valet_detail_modal_valet_standby_title: "待機",
      hotel_inhouse_valet_detail_modal_valet_standby_message: "車の待機を行います。",
      hotel_inhouse_valet_detail_modal_valet_handover_title: "インド",
      hotel_inhouse_valet_detail_modal_valet_handover_message: "ドライバーへの車の引き渡しを行います。",
      hotel_inhouse_valet_detail_modal_valet_return_title: "返還",
      hotel_inhouse_valet_detail_modal_valet_return_message: "車の返却を開始します。",
      hotel_inhouse_valet_detail_modal_return_placeholder_parking_area_no: "駐車区域番号",
      hotel_inhouse_valet_detail_modal_room_number: "客室番号",
      hotel_inhouse_valet_detail_modal_button_cancel: "キャンセル",
      hotel_inhouse_valet_detail_modal_button_ok: "確認",
      hotel_inhouse_valet_multi_myconect_list_title: "バレットスケジュール設定リスト",
      hotel_inhouse_valet_multi_myconect_list_button_alive: "使用中",
      hotel_inhouse_valet_multi_myconect_list_label_staff_number: "スタッフ番号",
      hotel_inhouse_valet_multi_myconect_list_label_request: "要請",
      hotel_inhouse_valet_multi_myconect_list_label_department: "部署",
      hotel_inhouse_valet_multi_myconect_list_label_email: "Eメール",
      hotel_inhouse_valet_multi_myconect_list_label_respond: "MyCONECT応答",
      hotel_inhouse_valet_multi_myconect_list_label_from: "~から",
      hotel_inhouse_valet_multi_myconect_list_label_to: "~まで",
      hotel_inhouse_valet_multi_myconect_list_label_date: "日付",
      hotel_inhouse_valet_multi_myconect_list_button_add: "追加",
      hotel_inhouse_valet_myconect_list_guide_empty: "リストが空です",
      hotel_inhouse_valet_multi_myconect_add_title: "バレットスケジュール設定追加",
      hotel_inhouse_valet_multi_myconect_add_label_request_type: "要請タイプ",
      hotel_inhouse_valet_multi_myconect_add_label_repond: "応答",
      hotel_inhouse_valet_multi_myconect_add_label_delay_time: "遅延時間",
      hotel_inhouse_valet_multi_myconect_add_placeholder_select_box_choose: "選択",
      hotel_inhouse_valet_multi_myconect_add_select_box_not_available: "サービス不可",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay15: "15分遅れ",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay30: "30分遅れ",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay45: "45分遅れ",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay60: "60分遅れ",
      hotel_inhouse_valet_multi_myconect_add_select_box_asaa: "早いうちに",
      hotel_inhouse_valet_multi_myconect_add_select_box_valet: "バレット",
      hotel_inhouse_valet_multi_myconect_add_select_box_waiting_request: "出車待ちの要請",
      hotel_inhouse_valet_multi_myconect_add_label_date: "日付",
      hotel_inhouse_valet_multi_myconect_add_validation_type_request: "* Please Select the Request Type.",
      hotel_inhouse_valet_multi_myconect_add_validation_repond: "* Please Select the Respond.",
      hotel_inhouse_valet_multi_myconect_add_validation_delay: "* Please Select the Delay Time.",
      hotel_inhouse_valet_multi_myconect_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_valet_multi_myconect_add_button_add: "追加",
      hotel_inhouse_valet_multi_myconect_detail_title: "バレットスケジュール設定情報",
      hotel_inhouse_valet_multi_myconect_detail_label_update: "アップデートの日付",
      hotel_inhouse_valet_multi_myconect_detail_label_date: "日付",
      hotel_inhouse_valet_multi_myconect_detail_label_delay_time: "遅延",
      hotel_inhouse_valet_multi_myconect_detail_placeholder_select_box_choose: "選択",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay15: "15分遅れ",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay30: "30分遅れ",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay45: "45分遅れ",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay60: "60分遅れ",
      hotel_inhouse_valet_multi_myconect_detail_select_box_asaa: "早いうちに",
      hotel_inhouse_valet_multi_myconect_detail_label_respond: "MyCONECT応答",
      hotel_inhouse_valet_multi_myconect_detail_label_staff_number: "スタッフ番号",
      hotel_inhouse_valet_multi_myconect_detail_label_request: "ヨッチャン",
      hotel_inhouse_valet_multi_myconect_detail_label_department: "部署",
      hotel_inhouse_valet_multi_myconect_detail_label_email: "Eメール",
      hotel_inhouse_valet_multi_myconect_detail_label_released_at: "解除時間",
      hotel_inhouse_valet_multi_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_valet_multi_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_valet_multi_myconect_detail_button_cancel: "キャンセル",
      hotel_inhouse_valet_multi_myconect_detail_button_release: "解除",
      hotel_inhouse_valet_multi_myconect_detail_button_edit: "修整",
      hotel_inhouse_valet_multi_myconect_detail_button_ok: "確認",
      hotel_inhouse_in_room_dining_cart_list_title: "インルームダイニングショッピングカート一覧",
      hotel_inhouse_in_room_dining_cart_list_placeholder_select_box_select: "選択",
      hotel_inhouse_in_room_dining_cart_list_select_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_cart_list_placeholder_search: "検索",
      hotel_inhouse_in_room_dining_cart_list_label_no: "番号",
      hotel_inhouse_in_room_dining_cart_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_cart_list_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_cart_list_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_cart_list_label_update_date: "日付更新",
      hotel_inhouse_in_room_dining_cart_list_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_cart_detail_title: "インルームダイニングのカート情報",
      hotel_inhouse_in_room_dining_cart_detail_label_info: "情報",
      hotel_inhouse_in_room_dining_cart_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_cart_detail_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_cart_detail_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_cart_detail_label_updated_at: "日付更新",
      hotel_inhouse_in_room_dining_cart_detail_label_payment: "決済",
      hotel_inhouse_in_room_dining_cart_detail_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_cart_detail_label_discount_rate: "割引率",
      hotel_inhouse_in_room_dining_cart_detail_label_price: "価格",
      hotel_inhouse_in_room_dining_cart_detail_label_order: "呪文",
      hotel_inhouse_in_room_dining_cart_detail_label_no: "番号",
      hotel_inhouse_in_room_dining_cart_detail_label_category: "カテゴリー",
      hotel_inhouse_in_room_dining_cart_detail_label_menu: "メニュー",
      hotel_inhouse_in_room_dining_cart_detail_label_option: "オプション",
      hotel_inhouse_in_room_dining_cart_detail_label_items: "品目",
      hotel_inhouse_in_room_dining_cart_detail_label_quantity: "数量",
      hotel_inhouse_in_room_dining_cart_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_cart_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_category_list_title: "インルームダイニングカテゴリー一覧",
      hotel_inhouse_in_room_dining_category_list_label_all: "メニューを展示するかどうか(すべて)",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_yes: "メニューを展示するかどうか（例）",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_no: "メニューを展示するかどうか（いいえ）",
      hotel_inhouse_in_room_dining_category_list_placeholder_search: "検索",
      hotel_inhouse_in_room_dining_category_list_label_no: "番号",
      hotel_inhouse_in_room_dining_category_list_label_name: "名前",
      hotel_inhouse_in_room_dining_category_list_label_date: "日付",
      hotel_inhouse_in_room_dining_category_list_label_sorting: "整列",
      hotel_inhouse_in_room_dining_category_list_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_category_list_button_delete: "削除",
      hotel_inhouse_in_room_dining_category_list_button_add: "追加",
      hotel_inhouse_in_room_dining_category_add_title: "インルームダイニングカテゴリー",
      hotel_inhouse_in_room_dining_category_add_label_name: "名前",
      hotel_inhouse_in_room_dining_category_add_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_category_add_select_yes: "例",
      hotel_inhouse_in_room_dining_category_add_select_no: "いいえ",
      hotel_inhouse_in_room_dining_category_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_category_add_button_add: "追加",
      hotel_inhouse_in_room_dining_category_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_category_detail_title: "インルームダイニングカテゴリー情報",
      hotel_inhouse_in_room_dining_category_detail_label_name: "名前",
      hotel_inhouse_in_room_dining_category_detail_label_date: "日付",
      hotel_inhouse_in_room_dining_category_detail_label_category_details: "カテゴリー詳細情報",
      hotel_inhouse_in_room_dining_category_detail_label_language: "言語",
      hotel_inhouse_in_room_dining_category_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_category_detail_button_add: "追加",
      hotel_inhouse_in_room_dining_category_detail_button_delete: "削除",
      hotel_inhouse_in_room_dining_category_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_category_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_category_edit_title: "インルームダイニングカテゴリー",
      hotel_inhouse_in_room_dining_category_edit_label_category_details: "カテゴリー詳細情報",
      hotel_inhouse_in_room_dining_category_edit_label_language: "言語",
      hotel_inhouse_in_room_dining_category_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_category_edit_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_category_edit_select_yes: "例",
      hotel_inhouse_in_room_dining_category_edit_select_no: "いいえ",
      hotel_inhouse_in_room_dining_category_edit_label_date: "日付",
      hotel_inhouse_in_room_dining_category_edit_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_category_edit_button_add: "追加",
      hotel_inhouse_in_room_dining_category_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_category_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_category_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_category_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_category_edit_guide_empty: "リストが空です",
      hotel_hotel_inhouse_in_room_dining_category_translation_add_title: "インルームダイニングカテゴリー翻訳",
      hotel_inhouse_in_room_dining_category_translation_add_label_language: "言語",
      hotel_inhouse_in_room_dining_category_translation_add_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_category_translation_add_select_en: "英語",
      hotel_inhouse_in_room_dining_category_translation_add_select_ja: "日本語",
      hotel_inhouse_in_room_dining_category_translation_add_select_zh: "中国語",
      hotel_inhouse_in_room_dining_category_translation_add_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_category_translation_add_label_name: "名前",
      hotel_inhouse_in_room_dining_category_translation_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_category_translation_add_button_add: "追加",
      hotel_inhouse_in_room_dining_category_translation_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_category_translation_edit_title: "インルームダイニングカテゴリー翻訳",
      hotel_inhouse_in_room_dining_category_translation_edit_label_language: "言語",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_category_translation_edit_select_en: "英語",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ja: "日本語",
      hotel_inhouse_in_room_dining_category_translation_edit_select_zh: "中国語",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_category_translation_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_category_translation_edit_label_date: "日付",
      hotel_inhouse_in_room_dining_category_translation_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_category_translation_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_category_translation_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_category_translation_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_list_title: "個室ダイニングメニューの設定リスト",
      hotel_inhouse_in_room_dining_menu_setting_list_label_no: "番号",
      hotel_inhouse_in_room_dining_menu_setting_list_label_type: "種類",
      hotel_inhouse_in_room_dining_menu_setting_list_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_list_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_setting_list_discount_rate: "割引率",
      hotel_inhouse_in_room_dining_menu_setting_list_price: "価格",
      hotel_inhouse_in_room_dining_menu_setting_list_status: "状態",
      hotel_inhouse_in_room_dining_menu_setting_list_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_setting_list_created_at: "日付",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sold_out: "売り切れ",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sale: "販売",
      hotel_inhouse_in_room_dining_menu_setting_list_select_preparing: "準備中",
      hotel_inhouse_in_room_dining_menu_setting_list_select_not_sale: "非売品",
      hotel_inhouse_in_room_dining_menu_setting_list_select_yes: "例",
      hotel_inhouse_in_room_dining_menu_setting_list_select_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_setting_list_sorting: "整列",
      hotel_inhouse_in_room_dining_menu_setting_list_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_setting_list_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_setting_list_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_setting_add_title: "個室ダイニングメニュー設定追加",
      hotel_inhouse_in_room_dining_menu_setting_add_label_category: "カテゴリー",
      hotel_inhouse_in_room_dining_menu_setting_add_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_add_label_about: "説明",
      hotel_inhouse_in_room_dining_menu_setting_add_label_main_image: "メイン·イメージ",
      hotel_inhouse_in_room_dining_menu_setting_add_label_detail_images: "詳細イメージ",
      hotel_inhouse_in_room_dining_menu_setting_add_label_upload: "アップロード",
      hotel_inhouse_in_room_dining_menu_setting_add_label_more: "追加説明",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_start_time: "注文可能時間(時間:分)",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_cutoff_time: "注文終了時間(時間:分)",
      hotel_inhouse_in_room_dining_menu_setting_add_label_day_of_week: "曜日",
      hotel_inhouse_in_room_dining_menu_setting_add_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_type: "割引率",
      hotel_inhouse_in_room_dining_menu_setting_add_select_flat: "固定料金",
      hotel_inhouse_in_room_dining_menu_setting_add_select_percent: "パーセント",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_setting_add_label_status: "状態",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sold_out: "売り切れ",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sale: "販売",
      hotel_inhouse_in_room_dining_menu_setting_add_select_preparing: "準備中",
      hotel_inhouse_in_room_dining_menu_setting_add_select_not_sale: "非売品",
      hotel_inhouse_in_room_dining_menu_setting_add_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_setting_add_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_setting_add_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_setting_add_label_recommended: "推薦の可否",
      hotel_inhouse_in_room_dining_menu_setting_add_label_packaging_availability: "包装可能かどうか",
      hotel_inhouse_in_room_dining_menu_setting_add_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_setting_add_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_menu_category_ids: "* カテゴリを選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_about: "* 説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_main_image: "* メイン画像をアップロードしてください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_detail_images: "* 詳細画像をアップロードしてください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_more: "* 追加の説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_from_at: "* 注文可能時間を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_to_at: "* 注文終了時間を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_day_of_week: "* 曜日を選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_supply_price: "* 供給価格を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_show: "* メニューを展示するかどうかを選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_recommended: "* おすすめするかどうかを選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_packaging_availability: "* 梱包可能かどうかをお選びください。",
      hotel_inhouse_in_room_dining_menu_setting_detail_title: "インルームダイニングメニューの設定情報",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_category: "カテゴリー",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_about: "説明",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_main_image: "メイン·イメージ",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_detail_images: "詳細イメージ",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_more: "追加説明",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_start_time: "注文可能時間(時間:分)",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_cutoff_time: "注文終了時間(時間:分)",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_day_of_week: "曜日",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_type: "割引率",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_flat: "固定料金",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_percent: "パーセント",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_price: "割引価格",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_sorting: "整列",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_status: "状態",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sold_out: "売り切れ",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sale: "販売",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_preparing: "準備中",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_not_sale: "非売品",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_recommended: "推薦の可否",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_packaging_availability: "包装可能かどうか",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_language: "言語",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_date: "日付",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_option: "オプション",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_no: "番号",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_items: "品目",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_required: "要否",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_setting_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_setting_edit_title: "インルームダイニングメニュー設定",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_category: "カテゴリー",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_about: "説明",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_main_image: "メイン·イメージ",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_detail_images: "詳細イメージ",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_upload: "アップロード",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_more: "追加説明",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_from_at: "注文可能時間(時間:分)",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_to_at: "注文終了時間(時間:分)",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_week: "曜日",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_type: "割引率",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_flat: "固定料金",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_percent: "パーセント",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_status: "状態",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sold_out: "売り切れ",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sale: "販売",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_preparing: "準備中",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_not_sale: "非売品",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_recommended: "推薦の可否",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_packaging_availability: "包装可能かどうか",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_language: "言語",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_delete: "キャンセル",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_add: "確認",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_date: "日付",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_option: "オプション",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_no: "番号",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_items: "品目",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_required: "要否",
      hotel_inhouse_in_room_dining_menu_setting_edit_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_price: "価格",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_menu_category_ids: "* カテゴリを選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_about: "* 説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_more: "* 追加の説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_from_at: "* 注文可能時間を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_to_at: "* 注文終了時間を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_day_of_week: "* 曜日を選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_supply_price: "* 供給価格を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_title: "インルームダイニングメニューの翻訳追加",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_en: "英語",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ja: "日本語",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_zh: "中国語",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_about: "説明",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_more: "追加説明",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_language_code: "* 言語コードを選択してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_about: "* 説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_more: "* 追加の説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_title: "インルームダイニングメニューの翻訳情報",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_about: "説明",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_more: "追加説明",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_title: "インルームダイニングメニュー翻訳修正",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_en: "英語",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ja: "日本語",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_zh: "中国語",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_about: "説明",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_more: "追加説明",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_about: "* 説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_more: "* 追加の説明を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_group_add_title: "個室ダイニングメニューオプションを追加",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_max_select: "最大選択数",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_required: "要否",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_required: "* 必須かどうかを選択してください。",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_max_select: "* 最大選択数を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_show: "*メニューを展示するかどうかを選択してください。",
      hotel_inhouse_in_room_dining_menu_option_group_detail_title: "インルームダイニングメニューオプション情報",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_max_select: "最大選択数",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_required: "要否",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_language: "言語",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_date: "日付",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_sorting: "整列",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_option: "オプション",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_no: "番号",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_items: "品目",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_option_group_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_price: "価格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_title: "インルームダイニングメニューオプションの修正",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_max_select: "最大選択数",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_required: "要否",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_language: "言語",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_date: "日付",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_option: "オプション",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_no: "番号",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_items: "品目",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_price: "価格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_required: "* 必須かどうかを選択してください。",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_max_select: "* 最大選択数を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_title: "インルームダイニングメニューオプション翻訳追加",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_en: "英語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ja: "日本語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_zh: "中国語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_language_code: "* 言語コードを選択してください。",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_title: "インルーム ダイニング メニュー オプション 翻訳 修正",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_en: "英語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ja: "日本語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_zh: "中国語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_value_add_title: "個室ダイニングメニューオプション品目の追加",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_type: "割引種類",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_flat: "固定料金",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_percent: "パーセント",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_price: "価格",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_supply_price: "* 供給価格を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_show: "* メニューを展示するかどうかを選択してください。",
      hotel_inhouse_in_room_dining_menu_option_value_detail_title: "インルームダイニングメニューオプション品目情報",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_type: "割引種類",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_price: "価格",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_language: "言語",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_date: "日付",
      hotel_inhouse_in_room_dining_menu_option_value_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_value_edit_title: "インルーム ダイニング メニュー オプション 品目 修正",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_yes: "例",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_no: "いいえ",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_show: "メニューを展示するかどうか",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_language: "言語",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_date: "日付",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_option: "オプション",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_no: "番号",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_items: "草木",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_required: "要否",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_amount: "割引価格",
      hotel_inhouse_in_room_dining_menu_option_value_edit_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_type: "割引種類",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_flat: "固定料金",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_percent: "パーセント",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_price: "価格",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_supply_price: "* 供給価格を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_title: "インルーム ダイニング メニュー オプション 品目 翻訳 追加",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_en: "英語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ja: "日本語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_zh: "中国語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_add: "追加",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_title: "インルーム ダイニング メニュー オプション 品目 翻訳 修正",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_language_code: "言語コード",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ko: "韓国語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_en: "英語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ja: "日本語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_zh: "中国語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ms: "マレーシア語",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_name: "名前",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_delete: "削除",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_ok: "確認",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_validation_name: "* 名前を入力してください。",
      hotel_inhouse_in_room_dining_process_list_title: "インルームダイニングリクエストリスト(確認完了)",
      hotel_inhouse_in_room_dining_process_list_label_number_of_guest_requests: "顧客要請数",
      hotel_inhouse_in_room_dining_process_list_label_unconfirmed_guest_requests: "確認されていないお客様からのリクエスト",
      hotel_inhouse_in_room_dining_process_list_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_process_list_label_all: "リクエスト(すべて)",
      hotel_inhouse_in_room_dining_process_list_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_process_list_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_process_list_placeholder_search: "検索",
      hotel_inhouse_in_room_dining_process_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_process_list_label_request_date: "要請日",
      hotel_inhouse_in_room_dining_process_list_label_request_type: "要請タイプ",
      hotel_inhouse_in_room_dining_process_list_label_task: "タスク",
      hotel_inhouse_in_room_dining_process_list_label_action_status: "現在の動作状態",
      hotel_inhouse_in_room_dining_process_list_label_date: "日付",
      hotel_inhouse_in_room_dining_process_list_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_process_detail_title: "インルームダイニングのリクエスト情報",
      hotel_inhouse_in_room_dining_process_detail_label_order_no: "注文番号",
      hotel_inhouse_in_room_dining_process_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_process_detail_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_process_detail_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_process_detail_label_status: "状態",
      hotel_inhouse_in_room_dining_process_detail_label_requests: "要請",
      hotel_inhouse_in_room_dining_process_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_process_detail_label_request_at: "要請時間",
      hotel_inhouse_in_room_dining_process_detail_button_stop: "キャンセル",
      hotel_inhouse_in_room_dining_process_detail_button_preparing: "準備中",
      hotel_inhouse_in_room_dining_process_detail_button_deliver: "配達完了",
      hotel_inhouse_in_room_dining_process_detail_label_payment: "決済手段",
      hotel_inhouse_in_room_dining_process_detail_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_process_detail_label_discount_rate: "割引率",
      hotel_inhouse_in_room_dining_process_detail_label_price: "価格",
      hotel_inhouse_in_room_dining_process_detail_label_to_go: "包装",
      hotel_inhouse_in_room_dining_process_detail_label_order: "呪文",
      hotel_inhouse_in_room_dining_process_detail_label_no: "番号",
      hotel_inhouse_in_room_dining_process_detail_label_category: "カテゴリー",
      hotel_inhouse_in_room_dining_process_detail_label_menu: "メニュー",
      hotel_inhouse_in_room_dining_process_detail_label_option: "オプション",
      hotel_inhouse_in_room_dining_process_detail_label_items: "品目",
      hotel_inhouse_in_room_dining_process_detail_label_quantity: "数量",
      hotel_inhouse_in_room_dining_process_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_process_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_modal_stop_customer_reason: "顧客事由",
      hotel_inhouse_in_room_dining_modal_stop_product_issue: "製品問題",
      hotel_inhouse_in_room_dining_modal_stop_delivery_problem: "配送(配達)問題",
      hotel_inhouse_in_room_dining_modal_stop_other: "その他",
      hotel_inhouse_in_room_dining_receipt_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_receipt_label_order_created_date: "注文作成日",
      hotel_inhouse_in_room_dining_receipt_label_guest_requested_date: "お客様のリクエスト日",
      hotel_inhouse_in_room_dining_receipt_label_confirmation_no: "予約番号",
      hotel_inhouse_in_room_dining_receipt_label_guest_name: "顧客名",
      hotel_inhouse_in_room_dining_receipt_label_check_in: "チェックイン",
      hotel_inhouse_in_room_dining_receipt_label_check_out: "チェックアウト",
      hotel_inhouse_in_room_dining_receipt_label_order_no: "注文番号",
      hotel_inhouse_in_room_dining_receipt_label_to_go: "包装",
      hotel_inhouse_in_room_dining_receipt_label_description: "メニュー名",
      hotel_inhouse_in_room_dining_receipt_label_unit_price: "単位当たりの価格",
      hotel_inhouse_in_room_dining_receipt_label_quantity: "数量",
      hotel_inhouse_in_room_dining_receipt_label_amount: "価格",
      hotel_inhouse_in_room_dining_receipt_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_receipt_label_subtotal: "合計",
      hotel_inhouse_in_room_dining_receipt_label_discount: "割引",
      hotel_inhouse_in_room_dining_receipt_label_total: "総額",
      hotel_inhouse_in_room_dining_receipt_label_payment: "決済",
      hotel_inhouse_in_room_dining_request_receipt_label_room_posting: "ゲキシル決済",
      hotel_inhouse_in_room_dining_request_receipt_label_self_settlement: "セルフ精算",
      hotel_inhouse_in_room_dining_request_receipt_label_balance: "残額",
      hotel_inhouse_in_room_dining_request_receipt_contents_agreement:
        "本人はこの計算書に対する責任が免除されず、指定された個人、会社、または団体が料金の一部または全部を支払わない場合は、個人的な責任を負うことに同意します。",
      hotel_inhouse_in_room_dining_request_list_title: "個室ダイニングリクエストリスト(確認前)",
      hotel_inhouse_in_room_dining_request_list_label_number_of_guest_requests: "顧客要請数",
      hotel_inhouse_in_room_dining_request_list_label_unconfirmed_guest_requests: "確認されていないお客様からのリクエスト",
      hotel_inhouse_in_room_dining_request_list_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_request_list_label_all: "リクエスト(すべて)",
      hotel_inhouse_in_room_dining_request_list_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_request_list_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_request_list_placeholder_search: "検索",
      hotel_inhouse_in_room_dining_request_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_request_list_label_request_date: "要請日",
      hotel_inhouse_in_room_dining_request_list_label_request_type: "要請タイプ",
      hotel_inhouse_in_room_dining_request_list_label_task: "タスク",
      hotel_inhouse_in_room_dining_request_list_label_action_status: "現在の動作状態",
      hotel_inhouse_in_room_dining_request_list_label_date: "日付",
      hotel_inhouse_in_room_dining_request_list_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_request_detail_title: "インルームダイニングのリクエスト情報",
      hotel_inhouse_in_room_dining_request_detail_label_update_at: "アップデート時間",
      hotel_inhouse_in_room_dining_request_detail_label_order_no: "注文番号",
      hotel_inhouse_in_room_dining_request_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_request_detail_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_request_detail_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_request_detail_label_status: "状態",
      hotel_inhouse_in_room_dining_request_detail_label_requests: "要請",
      hotel_inhouse_in_room_dining_request_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_request_detail_label_request_at: "要請時間",
      hotel_inhouse_in_room_dining_request_detail_button_pdf: "PDF",
      hotel_inhouse_in_room_dining_request_detail_button_not_available: "キャンセル",
      hotel_inhouse_in_room_dining_request_detail_button_confirm: "確認",
      hotel_inhouse_in_room_dining_request_detail_label_payment: "決済手段",
      hotel_inhouse_in_room_dining_request_detail_label_supply_price: "供給価格",
      hotel_inhouse_in_room_dining_request_detail_label_discount_rate: "割引率",
      hotel_inhouse_in_room_dining_request_detail_label_price: "価格",
      hotel_inhouse_in_room_dining_request_detail_label_to_go: "包装",
      hotel_inhouse_in_room_dining_request_detail_label_order: "呪文",
      hotel_inhouse_in_room_dining_request_detail_label_no: "番号",
      hotel_inhouse_in_room_dining_request_detail_label_category: "カテゴリー",
      hotel_inhouse_in_room_dining_request_detail_label_menu: "メニュー",
      hotel_inhouse_in_room_dining_request_detail_label_option: "オプション",
      hotel_inhouse_in_room_dining_request_detail_label_items: "品目",
      hotel_inhouse_in_room_dining_request_detail_label_quantity: "数量",
      hotel_inhouse_in_room_dining_request_detail_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_request_detail_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_request_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_myconect_list_title: "インルームダイニングスケジュール処理結果リスト",
      hotel_inhouse_in_room_dining_myconect_list_label_user_count: "ユーザーキャンセル数",
      hotel_inhouse_in_room_dining_myconect_list_label_hotel_count: "ホテルのキャンセル数",
      hotel_inhouse_in_room_dining_myconect_list_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_myconect_list_label_room_no: "客室番号",
      hotel_inhouse_in_room_dining_myconect_list_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_myconect_list_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_myconect_list_label_request_date: "要請日",
      hotel_inhouse_in_room_dining_myconect_list_label_request_type: "要請タイプ",
      hotel_inhouse_in_room_dining_myconect_list_label_express: "タスク",
      hotel_inhouse_in_room_dining_myconect_list_label_action_status: "現在の動作状態",
      hotel_inhouse_in_room_dining_myconect_list_label_myconect_at: "MyCONECT 動作時間",
      hotel_inhouse_in_room_dining_myconect_list_label_completed_at: "完了時間",
      hotel_inhouse_in_room_dining_myconect_list_label_date: "日付",
      hotel_inhouse_in_room_dining_myconect_list_guide_empty: "リストが空です",
      hotel_inhouse_in_room_dining_myconect_detail_title: "スケジュール処理結果 詳細",
      hotel_inhouse_in_room_dining_myconect_detail_label_roon_no: "客室番号",
      hotel_inhouse_in_room_dining_myconect_detail_label_confirmation_no: "予約確認番号",
      hotel_inhouse_in_room_dining_myconect_detail_label_user_name: "ユーザー名",
      hotel_inhouse_in_room_dining_myconect_detail_label_email: "Eメール",
      hotel_inhouse_in_room_dining_myconect_detail_label_stay: "投宿",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_type: "要請タイプ",
      hotel_inhouse_in_room_dining_myconect_detail_label_status: "現況",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_at: "要請時間",
      hotel_inhouse_in_room_dining_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_multi_myconect_list_title: "インルームダイニングスケジュール一覧",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_alive: "使用中",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_staff_number: "スタッフ番号",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_request: "要請",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_department: "部署",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_email: "Eメール",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_respond: "MyCONECT応答",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_from: "~から",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_to: "~まで",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_date: "日付",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_add: "追加",
      hotel_inhouse_in_room_dining_multi_myconect_add_title: "インルームダイニングスケジュール追加",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_request_type: "要請タイプ",
      hotel_inhouse_in_room_dining_multi_myconect_add_placeholder_select_box_choose: "選択",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_respond: "MyCONECT応答",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_not_availble: "使用不可",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_confirm: "確認",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_date: "日付",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_add: "追加",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_set: "設定",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_type_request: "* リクエストタイプを選択してください。",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_start: "* 日付を選択してください。",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_end: "* 日付を選択してください。",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_respond: "* MyCONECTの応答を選択してください。",
      hotel_inhouse_in_room_dining_multi_myconect_detail_title: "インルームダイニングスケジュール情報",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_update: "アップデートの日付",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_date: "日付",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_respond: "MyCONECT応答",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_staff_number: "スタッフ番号",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_request: "要請",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_department: "部署",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_email: "Eメール",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_released_at: "解除時間",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_created_at: "生成時間",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_release: "解除",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_ok: "確認",
      hotel_inhouse_in_room_dining_setting_detail_title: "インルームダイニング設定",
      hotel_inhouse_in_room_dining_setting_detail_label_update_at: "アップデート時間",
      hotel_inhouse_in_room_dining_setting_detail_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_setting_detail_label_room_posting: "客室請求",
      hotel_inhouse_in_room_dining_setting_detail_button_yes: "例",
      hotel_inhouse_in_room_dining_setting_detail_button_no: "いいえ",
      hotel_inhouse_in_room_dining_setting_detail_label_payment: "決済手段",
      hotel_inhouse_in_room_dining_setting_detail_button_edit: "修整",
      hotel_inhouse_in_room_dining_setting_edit_title: "インルームダイニング設定",
      hotel_inhouse_in_room_dining_setting_edit_label_update_at: "アップデート時間",
      hotel_inhouse_in_room_dining_setting_edit_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_in_room_dining_setting_edit_label_room_posting: "客室請求",
      hotel_inhouse_in_room_dining_setting_edit_button_yes: "例",
      hotel_inhouse_in_room_dining_setting_edit_button_no: "いいえ",
      hotel_inhouse_in_room_dining_setting_edit_label_payment: "決済手段",
      hotel_inhouse_in_room_dining_setting_edit_button_cancel: "キャンセル",
      hotel_inhouse_in_room_dining_setting_edit_button_ok: "確認",
      hotel_inhouse_setting_business_list_title: "業務規則の設定",
      hotel_inhouse_setting_business_list_label_no: "番号",
      hotel_inhouse_setting_business_list_label_type: "タイプ",
      hotel_inhouse_setting_business_list_label_task: "タスク",
      hotel_inhouse_setting_business_list_label_time_from: "使用可能時間 ~ から",
      hotel_inhouse_setting_business_list_label_time_to: "使用可能時間 ~まで",
      hotel_inhouse_setting_business_list_label_charge_amount: "請求金額",
      hotel_inhouse_setting_business_list_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_setting_business_list_label_update_at: "アップデート時間",
      hotel_inhouse_setting_business_list_guide_empty: "リストが空です",
      hotel_inhouse_setting_business_detail_title: "業務規則設定情報",
      hotel_inhouse_setting_business_detail_label_date: "日付更新",
      hotel_inhouse_setting_business_detail_label_type: "タイプ",
      hotel_inhouse_setting_business_detail_label_task: "タスク",
      hotel_inhouse_setting_business_detail_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_setting_business_detail_label_time_from: "使用可能時間 ~ から",
      hotel_inhouse_setting_business_detail_label_time_to: "使用可能時間 ~まで",
      hotel_inhouse_setting_business_detail_label_min_requirement_time: "最小要求時間(分)",
      hotel_inhouse_setting_business_detail_label_waiting_time: "MyCONECT 待ち時間(分)",
      hotel_inhouse_setting_business_detail_label_respond: "MyCONECT応答",
      hotel_inhouse_setting_business_detail_label_amount: "請求金額",
      hotel_inhouse_setting_business_detail_label_transaction_code: "決済コード",
      hotel_inhouse_setting_business_detail_label_description: "内訳",
      hotel_inhouse_setting_business_detail_label_supplement: "料金詳細内訳",
      hotel_inhouse_setting_business_detail_label_myconect_from: "MyCONECT 使い始め時間",
      hotel_inhouse_setting_business_detail_label_myconect_to: "MyCONECT 使用終了時間",
      hotel_inhouse_setting_business_detail_label_additional_charge_count: "追加決済条件回数",
      hotel_inhouse_setting_business_detail_label_additional_charge_repeat_flag: "追加決済を繰り返すかどうか",
      hotel_inhouse_setting_business_detail_button_yes: "例",
      hotel_inhouse_setting_business_detail_button_no: "いいえ",
      hotel_inhouse_setting_business_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_setting_business_detail_button_edit: "修整",
      hotel_inhouse_setting_business_edit_title: "業務規則設定情報",
      hotel_inhouse_setting_business_edit_label_date: "アップデートの日付",
      hotel_inhouse_setting_business_edit_label_type: "タイプ",
      hotel_inhouse_setting_business_edit_label_task: "タスク",
      hotel_inhouse_setting_business_edit_label_time_from: "使用可能時間 ~ から",
      hotel_inhouse_setting_business_edit_label_time_to: "使用可能時間 ~まで",
      hotel_inhouse_setting_business_edit_label_min_requirement_time: "最小要求時間",
      hotel_inhouse_setting_business_edit_label_waiting_time: "MyCONECT 待ち時間",
      hotel_inhouse_setting_business_edit_label_respond: "MyCONECT応答",
      hotel_inhouse_setting_business_edit_select_not_availble: "使用不可",
      hotel_inhouse_setting_business_edit_select_confirm: "確認",
      hotel_inhouse_setting_business_edit_select_delay15: "15分遅れ",
      hotel_inhouse_setting_business_edit_select_delay30: "30分遅れ",
      hotel_inhouse_setting_business_edit_select_delay45: "45分遅れ",
      hotel_inhouse_setting_business_edit_select_delay60: "60分遅れ",
      hotel_inhouse_setting_business_edit_select_asaa: "早いうちに",
      hotel_inhouse_setting_business_edit_label_amount: "請求金額",
      hotel_inhouse_setting_business_edit_label_transaction_code: "決済コード",
      hotel_inhouse_setting_business_edit_label_description: "内訳",
      hotel_inhouse_setting_business_edit_label_supplement: "料金詳細内訳",
      hotel_inhouse_setting_business_edit_label_myconect_from: "MyCONECT 使い始め時間",
      hotel_inhouse_setting_business_edit_label_myconect_to: "MyCONECT 使用終了時間",
      hotel_inhouse_setting_business_edit_validation_available_time_from: "* 使用可能時間 ~ から を入力してください。",
      hotel_inhouse_setting_business_edit_validation_available_time_to: "* 使用可能時間~までを入力してください。",
      hotel_inhouse_setting_business_edit_validation_min_requirement_time: "* 最小要求時間を入力してください。",
      hotel_inhouse_setting_business_edit_validation_myconect_waiting_time: "* MyCONECTの待ち時間を入力してください。",
      hotel_inhouse_setting_business_edit_validation_myconect_respond: "* MyCONECTの応答を選択してください。",
      hotel_inhouse_setting_business_edit_validation_charge_amount: "* 請求金額を入力してください。",
      hotel_inhouse_setting_business_edit_validation_charge_transaction_code: "* 決済コードを入力してください。",
      hotel_inhouse_setting_business_edit_validation_charge_description: "* 内訳を入力してください。",
      hotel_inhouse_setting_business_edit_validation_charge_supplement: "* 料金の詳細内訳を入力してください。",
      hotel_inhouse_setting_business_edit_label_in_room_dining: "イン·ルーム·ダイニング",
      hotel_inhouse_setting_business_edit_button_cancel: "キャンセル",
      hotel_inhouse_setting_business_edit_button_ok: "確認",
      hotel_inhouse_setting_boundary_list_title: "制限設定",
      hotel_inhouse_setting_boundary_list_label_no: "番号",
      hotel_inhouse_setting_boundary_list_label_type: "タイプ",
      hotel_inhouse_setting_boundary_list_label_task: "タスク",
      hotel_inhouse_setting_boundary_list_label_ten_mins: "十分",
      hotel_inhouse_setting_boundary_list_label_hour: "時",
      hotel_inhouse_setting_boundary_list_label_three_hours: "三時間",
      hotel_inhouse_setting_boundary_list_label_update_at: "アップデート時間",
      hotel_inhouse_setting_boundary_list_guide_empty: "リストが空です",
      hotel_inhouse_setting_boundary_detail_title: "業務情報",
      hotel_inhouse_setting_boundary_detail_label_date: "アップデート時間",
      hotel_inhouse_setting_boundary_detail_label_type: "タイプ",
      hotel_inhouse_setting_boundary_detail_label_task: "タスク",
      hotel_inhouse_setting_boundary_detail_label_ten_mins: "十分",
      hotel_inhouse_setting_boundary_detail_label_hour: "時間",
      hotel_inhouse_setting_boundary_detail_label_three_hours: "三時間",
      hotel_inhouse_setting_boundary_detail_button_none: "なし",
      hotel_inhouse_setting_boundary_detail_button_unlimited: "時間制限なし",
      hotel_inhouse_setting_boundary_detail_button_value: "値段",
      hotel_inhouse_setting_boundary_detail_label_option_one: "一番目のオプション",
      hotel_inhouse_setting_boundary_detail_label_option_two: "二番目のオプション",
      hotel_inhouse_setting_boundary_detail_button_go_to_list: "リストへのアクセス",
      hotel_inhouse_setting_boundary_detail_button_edit: "修整",
      hotel_inhouse_setting_boundary_edit_title: "業務情報",
      hotel_inhouse_setting_boundary_edit_label_date: "日付更新",
      hotel_inhouse_setting_boundary_edit_label_type: "タイプ",
      hotel_inhouse_setting_boundary_edit_label_task: "タスク",
      hotel_inhouse_setting_boundary_edit_label_ten_mins: "十分",
      hotel_inhouse_setting_boundary_edit_label_hour: "時間",
      hotel_inhouse_setting_boundary_edit_label_three_hours: "三時間",
      hotel_inhouse_setting_boundary_edit_button_none: "なし",
      hotel_inhouse_setting_boundary_edit_button_unlimited: "時間制限なし",
      hotel_inhouse_setting_boundary_edit_button_value: "値段",
      hotel_inhouse_setting_boundary_edit_label_option_one: "一番目のオプション",
      hotel_inhouse_setting_boundary_edit_label_option_two: "二番目のオプション",
      hotel_inhouse_setting_boundary_edit_validation_boundary_line_hour: "* 時間を入力してください。",
      hotel_inhouse_setting_boundary_edit_button_cancel: "キャンセル",
      hotel_inhouse_setting_boundary_edit_button_ok: "確認",
      hotel_inhouse_setting_amenity_list_title: "アメニティ設定",
      hotel_inhouse_setting_amenity_list_label_no: "番号",
      hotel_inhouse_setting_amenity_list_label_item: "項目",
      hotel_inhouse_setting_amenity_list_label_order_by: "注文順",
      hotel_inhouse_setting_amenity_list_label_update_at: "アップデート時間",
      hotel_inhouse_setting_amenity_list_guide_empty: "リストが空です",
      hotel_inhouse_room_controller_detail_title: "客室制御",
      hotel_inhouse_room_controller_detail_button_choose_file: "ファイル選択",
      hotel_inhouse_room_controller_detail_button_insert: "入力",
      hotel_inhouse_room_controller_detail_button_export: "送り出し",
      hotel_inhouse_help_list_title: "モバイルキーメニューヘルプ",
      hotel_inhouse_help_list_button_add: "追加",
      hotel_inhouse_help_list_button_delete: "削除",
      hotel_inhouse_help_list_button_edit: "修整",
      hotel_inhouse_help_list_guide_add: "ホテルの情報を入力してください",
      hotel_inhouse_help_add_title: "モバイルキーヘルプの追加",
      hotel_inhouse_help_add_placeholder_title: "タイトルを入力してください。",
      hotel_inhouse_help_add_placeholder_index: "番号を入力してください。",
      hotel_inhouse_help_add_select_choose: "タイプをお選びください",
      hotel_inhouse_help_add_select_use: "使用",
      hotel_inhouse_help_add_select_access: "接近",
      hotel_inhouse_help_add_select_range: "範囲",
      hotel_inhouse_help_add_select_not_working: "実行不可",
      hotel_inhouse_help_add_select_in_room_tech: "イン·ルーム·テック",
      hotel_inhouse_help_add_select_trouble: "問題",
      hotel_inhouse_help_add_toolbar_bold: "太く",
      hotel_inhouse_help_add_toolbar_italic: "イタリック体",
      hotel_inhouse_help_add_toolbar_underline: "下線",
      hotel_inhouse_help_add_toolbar_remove_font_style: "書体取り",
      hotel_inhouse_help_add_toolbar_font_family: "フォント集",
      hotel_inhouse_help_add_toolbar_font_size: "フォントサイズ",
      hotel_inhouse_help_add_toolbar_recent_color: "最近の使用色",
      hotel_inhouse_help_add_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_inhouse_help_add_toolbar_unordered_list: "迷走文目録",
      hotel_inhouse_help_add_toolbar_ordered_list: "注文リスト",
      hotel_inhouse_help_add_toolbar_paragraph: "短絡",
      hotel_inhouse_help_add_toolbar_line_height: "線の高さ",
      hotel_inhouse_help_add_placeholder_content: "内容を入力してください",
      hotel_inhouse_help_add_button_cancel: "キャンセル",
      hotel_inhouse_help_add_button_ok: "確認",
      hotel_inhouse_help_edit_title: "ホテル情報修正",
      hotel_inhouse_help_edit_placeholder_title: "タイトル",
      hotel_inhouse_help_edit_select_kr: "韓国語",
      hotel_inhouse_help_edit_select_en: "英語",
      hotel_inhouse_help_edit_select_cn: "中国語",
      hotel_inhouse_help_edit_select_jp: "日本語",
      hotel_inhouse_help_edit_select_ms: "MS",
      hotel_inhouse_help_edit_placeholder_index: "索引",
      hotel_inhouse_help_edit_toolbar_bold: "太く",
      hotel_inhouse_help_edit_toolbar_italic: "イタリック体",
      hotel_inhouse_help_edit_toolbar_underline: "下線",
      hotel_inhouse_help_edit_toolbar_remove_font_style: "書体取り",
      hotel_inhouse_help_edit_toolbar_font_family: "フォント集",
      hotel_inhouse_help_edit_toolbar_font_size: "フォントサイズ",
      hotel_inhouse_help_edit_toolbar_recent_color: "最近の使用色",
      hotel_inhouse_help_edit_toolbar_recent_more_color: "カラー もっと 見る",
      hotel_inhouse_help_edit_toolbar_unordered_list: "迷走文目録",
      hotel_inhouse_help_edit_toolbar_ordered_list: "注文リスト",
      hotel_inhouse_help_edit_toolbar_paragraph: "短絡",
      hotel_inhouse_help_edit_toolbar_code_view: "コードを見ること",
      hotel_inhouse_help_edit_toolbar_line_height: "線の高さ",
      hotel_inhouse_help_edit_button_append: "追加",
      hotel_inhouse_help_edit_button_remove: "除去",
      hotel_inhouse_help_edit_button_cancel: "キャンセル",
      hotel_inhouse_help_edit_button_ok: "確認",
      hotel_key_guest_list_title: "顧客キーリスト",
      hotel_key_guest_list_button_alive: "使用中",
      hotel_key_guest_list_placeholder_select_box_key: "身長",
      hotel_key_guest_list_select_room_no: "客室番号",
      hotel_key_guest_list_placeholder_search: "検索",
      hotel_key_guest_list_label_id: "ID",
      hotel_key_guest_list_label_availble_at: "使用可能時間",
      hotel_key_guest_list_label_expired_at: "満了時間",
      hotel_key_guest_list_label_is_main: "代表",
      hotel_key_guest_list_label_room_no: "客室番号",
      hotel_key_guest_list_label_user: "使用者",
      hotel_key_guest_list_button_cancel: "キャンセル",
      hotel_key_guest_list_guide_empty: "リストが空です",
      hotel_key_guest_detail_title: "鍵情報",
      hotel_key_guest_detail_label_id: "ID",
      hotel_key_guest_detail_label_availble_at: "使用可能時間",
      hotel_key_guest_detail_label_expired_at: "満了時間",
      hotel_key_guest_detail_label_is_main: "代表",
      hotel_key_guest_detail_label_room_no: "客室番号",
      hotel_key_guest_detail_label_confirmation_no: "予約確認番号",
      hotel_key_guest_detail_label_user: "使用者",
      hotel_key_guest_detail_label_user_email: "ユーザーEメール",
      hotel_key_guest_detail_button_go_to_list: "リストへのアクセス",
      hotel_key_guest_detail_button_cancel: "キャンセル",
      hotel_key_staff_list_title: "スタッフ·キー·リスト",
      hotel_key_staff_list_button_alive: "使用中",
      hotel_key_staff_list_placeholder_select_box_key: "身長",
      hotel_key_staff_list_select_task: "タスク",
      hotel_key_staff_list_select_emp_id: "職員ID",
      hotel_key_staff_list_placeholder_search: "検索",
      hotel_key_staff_list_label_type: "タイプ",
      hotel_key_staff_list_label_link_type: "リンクタイプ",
      hotel_key_staff_list_label_link_detail: "リンクの詳細情報",
      hotel_key_staff_list_label_link_code: "リンクコード",
      hotel_key_staff_list_label_availble_at: "使用可能時間",
      hotel_key_staff_list_label_expired_at: "満了時間",
      hotel_key_staff_list_label_staff_number: "スタッフ番号",
      hotel_key_staff_list_button_cancel: "キャンセル",
      hotel_key_staff_list_button_add: "追加",
      hotel_key_staff_list_guide_empty: "リストが空です",
      hotel_key_staff_add_title: "スタッフキー追加",
      hotel_key_staff_add_button_append: "追加",
      hotel_key_staff_add_label_staff_number: "スタッフ番号",
      hotel_key_staff_add_label_type: "タイプ",
      hotel_key_staff_add_label_detail: "詳細",
      hotel_key_staff_add_label_from_date: "開始日",
      hotel_key_staff_add_label_end_date: "終了日",
      hotel_key_staff_add_placeholder_select_box_choose: "選択",
      hotel_key_staff_add_button_remove: "除去",
      hotel_key_staff_add_button_go_to_list: "リストへのアクセス",
      hotel_key_staff_add_button_add: "追加",
      hotel_key_staff_add_validation_number: "* スタッフ番号を入力してください。",
      hotel_key_staff_add_validation_type: "* タイプを選択してください。",
      hotel_key_staff_add_validation_detail: "* 詳細を選択してください。",
      hotel_key_staff_add_validation_from_date: "* 開始日を選択してください。",
      hotel_key_staff_add_validation_end_date: "* 終了日を選択してください。",
      hotel_key_staff_detail_title: "スタッフキー情報",
      hotel_key_staff_detail_label_type: "タイプ",
      hotel_key_staff_detail_label_link_type: "リンクタイプ",
      hotel_key_staff_detail_label_link_detail: "リンクの詳細情報",
      hotel_key_staff_detail_label_link_code: "リンクコード",
      hotel_key_staff_detail_label_availble_at: "使用可能時間",
      hotel_key_staff_detail_label_expired_at: "満了時間",
      hotel_key_staff_detail_label_employee_id: "職員番号",
      hotel_key_staff_detail_label_canceled_at: "キャンセル時間",
      hotel_key_staff_detail_button_go_to_list: "リストへのアクセス",
      hotel_key_staff_detail_button_cancel: "キャンセル",
      hotel_inhouse_share_key_list_title: "共有キーリスト",
      hotel_inhouse_share_key_list_button_alive: "使用中",
      hotel_inhouse_share_key_list_placeholder_select_box_share_key: "共有キー",
      hotel_inhouse_share_key_list_select_room_no: "客室番号",
      hotel_inhouse_share_key_list_select_email: "Eメール",
      hotel_inhouse_share_key_list_select_user_name: "ユーザー名",
      hotel_inhouse_share_key_list_placeholder_search: "検索",
      hotel_inhouse_share_key_list_label_no: "番号",
      hotel_inhouse_share_key_list_label_sender_room_no: "発信者の客室番号",
      hotel_inhouse_share_key_list_label_sender_name: "発信者名",
      hotel_inhouse_share_key_list_label_sender_email: "送信者Eメール",
      hotel_inhouse_share_key_list_label_key_type: "キータイプ",
      hotel_inhouse_share_key_list_label_expired_at: "満了日",
      hotel_inhouse_share_key_list_label_receiver_room_no: "受信者の客室番号",
      hotel_inhouse_share_key_list_label_receiver_email: "受信者メール",
      hotel_inhouse_share_key_list_label_receiver_status: "受信者現況",
      hotel_inhouse_share_key_list_label_date: "日付",
      hotel_inhouse_share_key_list_guide_empty: "リストが空です",
      hotel_inhouse_share_key_detail_title: "共有キーの詳細情報",
      hotel_inhouse_share_key_detail_label_sender_room_no: "発信者の客室番号",
      hotel_inhouse_share_key_detail_label_sender_name: "発信者名",
      hotel_inhouse_share_key_detail_label_sender_email: "送信者Eメール",
      hotel_inhouse_share_key_detail_label_key_type: "共有キータイプ",
      hotel_inhouse_share_key_detail_label_expired_at: "共有キーの有効期限",
      hotel_inhouse_share_key_detail_label_receiver_room_no: "受信者の客室番号",
      hotel_inhouse_share_key_detail_label_receiver_email: "受信者メール",
      hotel_inhouse_share_key_detail_label_receiver_status: "受信者現況",
      hotel_inhouse_share_key_detail_label_created_at: "生成時間",
      hotel_inhouse_share_key_detail_button_go_to_list: "リストへのアクセス",
      help_for_mobile_key_add_validation_title: "* タイトルを入力してください。",
      help_for_mobile_key_add_validation_index: "* 番号を入力してください。",
      help_for_mobile_key_add_validation_type: "* タイプを選択してください。",
      help_for_mobile_key_add_validation_content: "* 内容を入力してください。",
      help_for_mobile_key_edit_validation_title: "* タイトルを入力してください。",
      help_for_mobile_key_edit_validation_index: "* 番号を入力してください。",
      help_for_mobile_key_edit_validation_content: "* 内容を入力してください。",
      help_for_mobile_key_edit_validation_language_code: "* 重複しない言語コードを選択してください。",
      hotel_key_link_list_title: "スタッフキーリンクリスト",
      hotel_key_link_list_placeholder_search: "検索",
      hotel_key_link_list_label_type_code: "タイプコード",
      hotel_key_link_list_label_type_description: "タイプ説明",
      hotel_key_link_list_label_detail_code: "詳細コード",
      hotel_key_link_list_label_detail_description: "詳細説明",
      hotel_key_link_list_label_staff_type: "スタッフ·タイプ",
      hotel_key_link_list_label_code: "コード",
      hotel_key_link_list_label_date: "日付",
      hotel_key_link_list_button_delete: "削除",
      hotel_key_link_list_button_add: "追加",
      hotel_key_link_list_guide_empty: "リストが空です",
      hotel_key_link_add_title: "スタッフキーリンク追加",
      hotel_key_link_add_label_type_code: "タイプコード",
      hotel_key_link_add_placeholder_select_box_choose: "選択",
      hotel_key_link_add_select_floor: "層",
      hotel_key_link_add_select_task: "タスク",
      hotel_key_link_add_select_zone: "区域",
      hotel_key_link_add_label_type_description: "タイプ説明",
      hotel_key_link_add_label_detail_code: "詳細コード",
      hotel_key_link_add_label_detail_description: "詳細説明",
      hotel_key_link_add_label_staff_type: "スタッフ·タイプ",
      hotel_key_link_add_select_concoerge: "コンシェルジュ",
      hotel_key_link_add_select_housekeeping: "ハウスキーピング",
      hotel_key_link_add_select_front_desk: "フロント·デスク",
      hotel_key_link_add_label_code: "コード",
      hotel_key_link_add_button_go_to_list: "リストへのアクセス",
      hotel_key_link_add_button_add: "追加",
      hotel_key_link_add_validation_type_code: "* タイプコードを選択してください。",
      hotel_key_link_add_validation_type_description: "* タイプ説明を入力してください。",
      hotel_key_link_add_validation_detail_code: "* 詳細コードを選択してください。",
      hotel_key_link_add_validation_detail_description: "* 詳細説明を入力してください。",
      hotel_key_link_add_validation_staff_type: "* スタッフタイプを選択してください。",
      hotel_key_link_add_validation_code: "* コードを選択してください。",
      hotel_key_link_detail_title: "スタッフキーリンク情報",
      hotel_key_link_detail_label_type_code: "タイプコード",
      hotel_key_link_detail_label_type_description: "タイプ説明",
      hotel_key_link_detail_label_detail_code: "詳細コード",
      hotel_key_link_detail_label_detail_description: "詳細説明",
      hotel_key_link_detail_label_staff_type: "スタッフ·タイプ",
      hotel_key_link_detail_label_code: "コード",
      hotel_key_link_detail_label_date: "日付",
      hotel_key_link_detail_button_go_to_list: "リストへのアクセス",
      hotel_key_link_detail_button_delete: "削除",
      hotel_key_link_detail_button_edit: "修整",
      hotel_key_link_edit_title: "スタッフキーリンク情報",
      hotel_key_link_edit_label_type_code: "タイプコード",
      hotel_key_link_edit_placeholder_select_box_choose: "選択",
      hotel_key_link_edit_select_floor: "層",
      hotel_key_link_edit_select_task: "タスク",
      hotel_key_link_edit_select_zone: "区域",
      hotel_key_link_edit_select_room: "客室",
      hotel_key_link_edit_label_type_description: "タイプ説明",
      hotel_key_link_edit_label_detail_code: "詳細コード",
      hotel_key_link_edit_label_detail_description: "詳細説明",
      hotel_key_link_edit_label_staff_type: "スタッフ·タイプ",
      hotel_key_link_edit_select_concoerge: "コンシェルジュ",
      hotel_key_link_edit_select_housekeeping: "ハウスキーピング",
      hotel_key_link_edit_select_front_desk: "フロント·デスク",
      hotel_key_link_edit_label_code: "コード",
      hotel_key_link_edit_label_date: "日付",
      hotel_key_link_edit_button_cancel: "キャンセル",
      hotel_key_link_edit_button_delete: "削除",
      hotel_key_link_edit_button_ok: "確認",
      hotel_key_link_edit_validation_type_code: "* タイプコードを選択してください。",
      hotel_key_link_edit_validation_type_description: "* タイプ説明を入力してください。",
      hotel_key_link_edit_validation_detail_code: "* 詳細コードを選択してください。",
      hotel_key_link_edit_validation_detail_description: "* 詳細説明を入力してください。",
      hotel_key_link_edit_validation_staff_type: "* スタッフタイプを選択してください。",
      hotel_key_link_edit_validation_code: "* コードを選択してください。",
      hotel_key_access_list_title: "アクセスリスト",
      hotel_key_access_list_label_title: "呼称",
      hotel_key_access_list_label_type: "タイプ",
      hotel_key_access_list_label_content: "説明",
      hotel_key_access_list_label_position_code: "役職コード",
      hotel_key_access_list_label_position_name: "役職名",
      hotel_key_access_list_label_remark: "備考",
      hotel_key_access_list_label_lcon: "アイコン",
      hotel_key_access_list_label_update_at: "アップデート時間",
      hotel_key_access_list_button_delete: "削除",
      hotel_key_access_list_button_add: "追加",
      hotel_key_access_list_guide_empty: "リストが空です",
      hotel_key_access_add_title: "アクセスの追加",
      hotel_key_access_add_label_title: "呼称",
      hotel_key_access_add_label_type: "タイプ",
      hotel_key_access_add_label_content: "内容",
      hotel_key_access_add_label_position_code: "役職コード",
      hotel_key_access_add_label_position_name: "役職名",
      hotel_key_access_add_label_remark: "備考",
      hotel_key_access_add_placeholder_select_box_choose: "選択",
      hotel_key_access_add_label_icon: "アイコン",
      hotel_key_access_add_button_upload: "アップロード",
      hotel_key_access_add_placeholder_upload: "ex) MyCONECT。png",
      hotel_key_access_add_button_go_to_list: "リストへのアクセス",
      hotel_key_access_add_button_add: "追加",
      hotel_key_access_add_validation_title: "* 呼称を入力してください。",
      hotel_key_access_add_validation_type: "* タイプを入力してください。",
      hotel_key_access_add_validation_content: "* 内容を入力してください。",
      hotel_key_access_add_validation_position_name: "* 役職名を選択してください。",
      hotel_key_access_add_validation_file: "* アイコンをアップロードしてください。",
      hotel_key_access_detail_title: "アクセス情報",
      hotel_key_access_detail_label_title: "呼称",
      hotel_key_access_detail_label_type: "タイプ",
      hotel_key_access_detail_label_content: "内容",
      hotel_key_access_detail_label_position_code: "役職コード",
      hotel_key_access_detail_label_position_name: "役職名",
      hotel_key_access_detail_label_remark: "備考",
      hotel_key_access_detail_label_lcon: "アイコン",
      hotel_key_access_detail_label_update_date: "日付更新",
      hotel_key_access_detail_button_upload: "アップロード",
      hotel_key_access_detail_button_go_to_list: "リストへのアクセス",
      hotel_key_access_detail_button_cancel: "キャンセル",
      hotel_key_access_detail_button_delete: "削除",
      hotel_key_access_detail_button_edit: "修整",
      hotel_key_access_detail_button_ok: "確認",
      hotel_key_access_edit_validation_title: "* 呼称を入力してください。",
      hotel_key_access_edit_validation_type: "* タイプを入力してください。",
      hotel_key_access_edit_validation_content: "* 内容を入力してください。",
      hotel_key_access_edit_validation_position_name: "* 役職名を選択してください。",
      hotel_key_access_edit_validation_file: "* アイコンをアップロードしてください。",
      hotel_key_access_detail_list_title: "アクセス 詳細情報リスト",
      hotel_key_access_detail_list_label_access_title: "接近呼称",
      hotel_key_access_detail_list_label_language_code: "言語コード",
      hotel_key_access_detail_list_label_access_detail_title: "アクセス詳細情報呼称",
      hotel_key_access_detail_list_label_date: "日付",
      hotel_key_access_detail_list_button_delete: "削除",
      hotel_key_access_detail_list_button_add: "追加",
      hotel_key_access_detail_list_guide_empty: "リストが空です",
      hotel_key_access_detail_add_title: "アクセス 詳細情報の追加",
      hotel_key_access_detail_add_label_access_title: "接近呼称",
      hotel_key_access_detail_add_placeholder_select_box_choose: "選択",
      hotel_key_access_detail_add_label_language_code: "言語コード",
      hotel_key_access_detail_add_select_kr: "韓国語",
      hotel_key_access_detail_add_select_en: "英語",
      hotel_key_access_detail_add_select_cn: "中国語",
      hotel_key_access_detail_add_select_jp: "日本語",
      hotel_key_access_detail_add_label_access_detail_title: "アクセス詳細情報呼称",
      hotel_key_access_detail_add_label_content: "内容",
      hotel_key_access_detail_add_button_go_to_list: "リストへのアクセス",
      hotel_key_access_detail_add_button_add: "追加",
      hotel_key_access_detail_add_validation_hotel_access_area_id: "* アクセス呼称を選択してください。",
      hotel_key_access_detail_add_validation_language_code: "* 言語コードを選択してください。",
      hotel_key_access_detail_add_validation_title: "* アクセスの詳細情報の呼称を入力してください。",
      hotel_key_access_detail_add_validation_content: "* 内容を入力してください。",
      hotel_key_access_detail_detail_title: "アクセス詳細情報",
      hotel_key_access_detail_detail_label_date: "アップデート時間",
      hotel_key_access_detail_detail_label_access_title: "接近呼称",
      hotel_key_access_detail_detail_placeholder_select_box_choose: "選択",
      hotel_key_access_detail_detail_label_language_code: "言語コード",
      hotel_key_access_detail_detail_select_kr: "韓国語",
      hotel_key_access_detail_detail_select_en: "英語",
      hotel_key_access_detail_detail_select_cn: "中国語",
      hotel_key_access_detail_detail_select_jp: "日本語",
      hotel_key_access_detail_detail_label_access_detail_title: "アクセス詳細情報呼称",
      hotel_key_access_detail_detail_label_content: "内容",
      hotel_key_access_detail_detail_button_go_to_list: "リストへのアクセス",
      hotel_key_access_detail_detail_button_cancel: "キャンセル",
      hotel_key_access_detail_detail_button_delete: "削除",
      hotel_key_access_detail_detail_button_edit: "修整",
      hotel_key_access_detail_detail_button_ok: "確認",
      hotel_key_access_detail_edit_validation_language_code: "* 言語コードを選択してください。",
      hotel_key_access_detail_edit_validation_title: "* アクセスの詳細情報の呼称を入力してください。",
      hotel_key_access_detail_edit_validation_content: "* 内容を入力してください。",
      hotel_key_reservation_access_add_validation_type: "* タイプを選択してください。",
      hotel_key_reservation_access_add_validation_code: "* コードを選択してください。",
      hotel_key_reservation_access_add_validation_name: "* 名前を入力してください。",
      hotel_key_reservation_access_add_validation_description: "* 内容を入力してください。",
      hotel_key_reservation_access_add_validation_checked_list: "* 予約アクセス権限のリストをチェックしてください。",
      hotel_key_reservation_access_edit_validation_checked_list: "* 予約アクセス権限のリストをチェックしてください。",
      hotel_key_setting_detail_title: "キー設定",
      hotel_key_setting_detail_label_update_date: "アップデートされた日付",
      hotel_key_setting_detail_label_card_key: "カードキー",
      hotel_key_setting_detail_label_card_key_type: "カードキータイプ",
      hotel_key_setting_detail_label_card_key_ip: "カードキーIP",
      hotel_key_setting_detail_label_card_key_port: "カード·キー·ポート",
      hotel_key_setting_detail_label_card_key_path: "カードキーパス",
      hotel_key_setting_detail_label_card_key_id: "カードキーID",
      hotel_key_setting_detail_label_card_key_password: "カードキーパスワード",
      hotel_key_setting_detail_label_special_guest: "顧客特別区域",
      hotel_key_setting_detail_label_special_staff: "スタッフ特別区域",
      hotel_key_setting_detail_label_rssi_guest: "顧客 RSSI",
      hotel_key_setting_detail_label_rrssi_staff: "スタッフ RSSI",
      hotel_key_setting_detail_label_room_show_update_flag: "ルームショーアップデート表示",
      hotel_key_setting_detail_label_room_open_update_flag: "部屋開きアップデート表示",
      hotel_key_setting_detail_label_room_show_key: "フロントデスク ルームショッキー 発行",
      hotel_key_setting_detail_button_yes: "例",
      hotel_key_setting_detail_button_no: "いいえ",
      hotel_key_setting_detail_label_key_expired_time: "鍵満了時間(時間:分)",
      hotel_key_setting_detail_label_accompany_room_to_room_issue: "同伴者ルームツールーム発行",
      hotel_key_setting_detail_label_room_to_room_not_available_time: "ルームツールーム受諾の待機時間(分)",
      hotel_key_setting_detail_label_share_key_issue: "共有キー発行",
      hotel_key_setting_detail_label_accompany_share_key_issue: "同伴者共有キーの発行",
      hotel_key_setting_detail_label_share_key_not_available_time: "共有鍵受諾待ち時間(分)",
      hotel_key_setting_detail_label_share_key_room_key_issued_max_count: "ルームキーの最大発行数",
      hotel_key_setting_detail_label_test_room_number: "テストルーム番号",
      hotel_key_setting_detail_button_cancel: "キャンセル",
      hotel_key_setting_detail_button_edit: "修整",
      hotel_key_setting_edit_title: "キー設定",
      hotel_key_setting_edit_label_update_date: "アップデートされた日付",
      hotel_key_setting_edit_label_card_key: "カードキー",
      hotel_key_setting_edit_label_card_key_type: "カードキータイプ",
      hotel_key_setting_edit_placeholder_select_box_choose: "選択",
      hotel_key_setting_edit_select_messerschmitt: "Messerschmitt",
      hotel_key_setting_edit_select_racos: "RACOS",
      hotel_key_setting_edit_select_racos_cms: "RACOS CMS",
      hotel_key_setting_edit_select_assa_abloy: "ASSA ABLOY",
      hotel_key_setting_edit_select_none: "不使用",
      hotel_key_setting_edit_label_card_key_ip: "カードキーIP",
      hotel_key_setting_edit_placeholder_card_key_ip: "ex) 127.0.0.1",
      hotel_key_setting_edit_label_card_key_port: "カード·キー·ポート",
      hotel_key_setting_edit_placeholder_card_key_port: "ex) 8080",
      hotel_key_setting_edit_label_card_key_path: "カードキーパス",
      hotel_key_setting_edit_label_card_key_id: "カードキーID",
      hotel_key_setting_edit_label_card_key_password: "カードキーパスワード",
      hotel_key_setting_edit_label_special_guest: "顧客特別区域",
      hotel_key_setting_edit_label_special_staff: "スタッフ特別区域",
      hotel_key_setting_edit_label_rssi_guest: "顧客 RSSI",
      hotel_key_setting_edit_label_rrssi_staff: "スタッフ RSSI",
      hotel_key_setting_edit_label_room_show_update_flag: "ルームショーアップデート表示",
      hotel_key_setting_edit_label_room_show_key: "フロントデスク ルームショッキー 発行",
      hotel_key_setting_edit_button_yes: "例",
      hotel_key_setting_edit_button_no: "いいえ",
      hotel_key_setting_edit_label_test_room_number: "テストルーム番号",
      hotel_key_setting_edit_button_cancel: "キャンセル",
      hotel_key_setting_edit_button_ok: "確認",
      hotel_key_setting_edit_validation_card_key_type: "* カードキータイプを選択してください。",
      hotel_key_setting_edit_validation_card_key_IP: "* カードキーIPを入力してください。",
      hotel_key_setting_edit_validation_card_key_port: "* カードキーポートを入力してください。",
      hotel_key_setting_edit_validation_card_key_path: "* カードキーのパスを入力してください。",
      hotel_key_setting_edit_validation_card_key_client_id: "* カードキーIDを入力してください。",
      hotel_key_setting_edit_validation_card_key_client_password: "* カードキーのパスワードを入力してください。",
      hotel_key_setting_edit_validation_special_area_for_guest: "* 顧客特別エリアを入力してください。",
      hotel_key_setting_edit_validation_special_area_for_staff: "* スタッフ特別エリアを入力してください。",
      hotel_key_setting_edit_validation_RSSI_for_guest: "* 顧客RSSIを入力してください。",
      hotel_key_setting_edit_validation_RSSI_for_staff: "* スタッフRSSI を入力してください。",
      hotel_key_setting_edit_validation_room_show_flag: "* ルームショーの更新表示を選択してください。",
      hotel_key_setting_edit_validation_room_show_issued_front_desk: "* フロントデスク ルーム ショーキーの発行を選択してください。",
      hotel_key_setting_edit_validation_key_expired_time: "* キーの有効期限を入力してください。",
      hotel_key_setting_edit_validation_room_to_room_not_available_time: "* ルームツールームの受付待ち時間を入力してください。",
      hotel_key_setting_edit_validation_is_share_key_issue: "* 共有キーの発行を選択してください。",
      hotel_key_setting_edit_validation_share_key_not_available_time: "* 共有キーの受け入れ待ち時間を入力してください。",
      hotel_key_setting_edit_validation_room_key_issued_max_count: "* ルームキーの最大発行数を入力してください。",
      hotel_key_setting_edit_validation_test_room_no: "* テスト客室番号を入力してください。",
      hotel_key_reservation_access_list_title: "アクセス権限リンク",
      hotel_key_reservation_access_list_label_type: "タイプ",
      hotel_key_reservation_access_list_label_name: "名前",
      hotel_key_reservation_access_list_label_code: "コード",
      hotel_key_reservation_access_list_label_access_area: "アクセス領域",
      hotel_key_reservation_access_list_label_date: "日付",
      hotel_key_reservation_access_list_label_description: "内容",
      hotel_key_reservation_access_list_button_cancel: "削除",
      hotel_key_reservation_access_list_button_add: "追加",
      hotel_key_reservation_access_list_guide_empty: "リストが空です",
      hotel_key_reservation_access_add_title: "アクセス権限リンクの追加",
      hotel_key_reservation_access_add_label_type: "タイプ",
      hotel_key_reservation_access_add_placeholder_select_box_choose: "選択",
      hotel_key_reservation_access_add_select_transaction: "決済",
      hotel_key_reservation_access_add_select_package: "パッケージ",
      hotel_key_reservation_access_add_label_code: "コード",
      hotel_key_reservation_access_add_label_name: "名前",
      hotel_key_reservation_access_add_label_description: "内容",
      hotel_key_reservation_access_add_label_list: "予約アクセス権限一覧",
      hotel_key_reservation_access_add_label_list_title: "呼称",
      hotel_key_reservation_access_add_label_list_type: "タイプ",
      hotel_key_reservation_access_add_label_list_content: "内容",
      hotel_key_reservation_access_add_label_list_position_code: "役職コード",
      hotel_key_reservation_access_add_label_list_position_name: "役職名",
      hotel_key_reservation_access_add_label_list_remark: "備考",
      hotel_key_reservation_access_add_button_go_to_list: "リストへのアクセス",
      hotel_key_reservation_access_add_button_add: "追加",
      hotel_key_reservation_access_detail_title: "アクセス権限リンク情報",
      hotel_key_reservation_access_detail_label_type: "タイプ",
      hotel_key_reservation_access_detail_label_code: "コード",
      hotel_key_reservation_access_detail_label_name: "名前",
      hotel_key_reservation_access_detail_label_description: "内容",
      hotel_key_reservation_access_detail_label_list: "予約アクセス権限一覧",
      hotel_key_reservation_access_detail_label_list_title: "呼称",
      hotel_key_reservation_access_detail_label_list_type: "タイプ",
      hotel_key_reservation_access_detail_label_list_content: "内容",
      hotel_key_reservation_access_detail_label_list_position_code: "役職コード",
      hotel_key_reservation_access_detail_label_list_position_name: "役職名",
      hotel_key_reservation_access_detail_label_list_remark: "備考",
      hotel_key_reservation_access_detail_button_go_to_list: "リストへのアクセス",
      hotel_key_reservation_access_detail_button_delete: "削除",
      hotel_key_reservation_access_detail_button_edit: "修整",
      hotel_key_reservation_access_edit_title: "アクセス権限リンク情報",
      hotel_key_reservation_access_edit_label_type: "タイプ",
      hotel_key_reservation_access_edit_placeholder_select_box_choose: "選択",
      hotel_key_reservation_access_edit_select_transaction: "決済",
      hotel_key_reservation_access_edit_select_package: "パッケージ",
      hotel_key_reservation_access_edit_label_code: "コード",
      hotel_key_reservation_access_edit_label_description: "内容",
      hotel_key_reservation_access_edit_label_list: "予約アクセス権限一覧",
      hotel_key_reservation_access_edit_label_list_title: "呼称",
      hotel_key_reservation_access_edit_label_list_type: "タイプ",
      hotel_key_reservation_access_edit_label_list_content: "内容",
      hotel_key_reservation_access_edit_label_list_position_code: "役職コード",
      hotel_key_reservation_access_edit_label_list_position_name: "役職名",
      hotel_key_reservation_access_edit_label_list_remark: "備考",
      hotel_key_reservation_access_edit_button_cancel: "キャンセル",
      hotel_key_reservation_access_edit_button_delete: "削除",
      hotel_key_reservation_access_edit_button_ok: "確認",
      hotel_terms_list_title: "約款目録",
      hotel_terms_list_select_type: "タイプ (すべて )",
      hotel_terms_list_select_hotel_terms: "ホテルの利用規約",
      hotel_terms_list_select_hotel_privacy_policies: "ホテルプライバシーポリシー",
      hotel_terms_list_select_reservation_terms: "予約利用規約",
      hotel_terms_list_select_reservation_privacy_policies: "予約プライバシーポリシー",
      hotel_terms_list_select_language_code: "言語コード(すべて)",
      hotel_terms_list_select_ko: "韓国語",
      hotel_terms_list_select_en: "英語",
      hotel_terms_list_select_cn: "中国語",
      hotel_terms_list_select_jp: "日本語",
      hotel_terms_list_label_id: "アイディー",
      hotel_terms_list_label_language_code: "言語コード",
      hotel_terms_list_label_type: "タイプ",
      hotel_terms_list_label_title: "呼称",
      hotel_terms_list_label_version: "バージョン",
      hotel_terms_list_label_link_text: "リンク·テキスト",
      hotel_terms_list_label_created_at: "生成時間",
      hotel_terms_list_select_posted: "掲示の可否（すべて）",
      hotel_terms_list_select_yes: "例",
      hotel_terms_list_select_no: "いいえ",
      hotel_terms_list_placeholder_search: "検索",
      hotel_terms_list_guide_empty: "リストが空です。",
      hotel_terms_list_button_delete: "削除",
      hotel_terms_list_button_add: "追加",
      hotel_terms_add_title: "約款追加",
      hotel_terms_add_label_language_code: "言語コード",
      hotel_terms_add_placeholder_select_box_select: "選択",
      hotel_terms_add_select_ko: "韓国語",
      hotel_terms_add_select_en: "英語",
      hotel_terms_add_select_cn: "中国語",
      hotel_terms_add_select_jp: "日本語",
      hotel_terms_add_label_type: "タイプ",
      hotel_terms_add_select_hotel_terms: "ホテルの利用規約",
      hotel_terms_add_select_hotel_privacy_policies: "ホテルプライバシーポリシー",
      hotel_terms_add_select_reservation_terms: "予約利用規約",
      hotel_terms_add_select_reservation_privacy_policies: "予約プライバシーポリシー",
      hotel_terms_add_label_title: "呼称",
      hotel_terms_add_label_required: "要否",
      hotel_terms_add_button_yes: "例",
      hotel_terms_add_button_no: "いいえ",
      hotel_terms_add_label_description: "説明",
      hotel_terms_add_label_link_text: "リンク·テキスト",
      hotel_terms_add_label_posted: "掲示の可否",
      hotel_terms_add_label_version: "バージョン",
      hotel_terms_add_label_content_type: "内容タイプ",
      hotel_terms_add_select_file: "ファイル",
      hotel_terms_add_select_text: "テキスト",
      hotel_terms_add_label_content: "内容",
      hotel_terms_add_label_file_url: "ファイルURL",
      hotel_terms_add_button_upload: "アップロード",
      hotel_terms_add_button_go_to_list: "リストへのアクセス",
      hotel_terms_add_button_add: "追加",
      hotel_terms_add_validation_language_code: "* 言語コードを選択してください。",
      hotel_terms_add_validation_type: "* タイプを選択してください。",
      hotel_terms_add_validation_title: "* 呼称を入力してください。",
      hotel_terms_add_validation_required: "* 必須かどうかを選択してください。",
      hotel_terms_add_validation_description: "* 説明を入力してください。",
      hotel_terms_add_validation_link_text: "* リンク テキストを入力してください。",
      hotel_terms_add_validation_posted: "* 掲示するかどうかを選択してください。",
      hotel_terms_add_validation_version: "* バージョンを入力してください。",
      hotel_terms_add_validation_content_type: "* 内容タイプを選択してください。",
      hotel_terms_add_validation_content: "* 内容を入力してください。",
      hotel_terms_add_validation_hotel_terms_file_url: "* ファイルをアップロードしてください。",
      hotel_terms_detail_title: "約款詳細",
      hotel_terms_detail_label_terms_id: "約款ID",
      hotel_terms_detail_label_language_code: "言語コード",
      hotel_terms_detail_select_ko: "韓国語",
      hotel_terms_detail_select_en: "英語",
      hotel_terms_detail_select_cn: "中国語",
      hotel_terms_detail_select_jp: "日本語",
      hotel_terms_detail_label_type: "タイプ",
      hotel_terms_detail_select_hotel_terms: "ホテルの利用規約",
      hotel_terms_detail_select_hotel_privacy_policies: "ホテルプライバシーポリシー",
      hotel_terms_detail_select_reservation_terms: "予約利用規約",
      hotel_terms_detail_select_reservation_privacy_policies: "予約プライバシーポリシー",
      hotel_terms_detail_label_version: "バージョン",
      hotel_terms_detail_label_title: "呼称",
      hotel_terms_detail_label_posted: "掲示の可否",
      hotel_terms_detail_button_yes: "例",
      hotel_terms_detail_button_no: "いいえ",
      hotel_terms_detail_label_required: "要否",
      hotel_terms_detail_label_description: "説明",
      hotel_terms_detail_label_link_text: "リンク·テキスト",
      hotel_terms_detail_label_content_type: "内容タイプ",
      hotel_terms_detail_select_file: "ファイル",
      hotel_terms_detail_select_text: "テキスト",
      hotel_terms_detail_label_file_url: "ファイルURL",
      hotel_terms_detail_button_upload: "アップロード",
      hotel_terms_detail_label_content: "内容",
      hotel_terms_detail_label_created_at: "生成時間",
      hotel_terms_detail_label_updated_at: "アップデート時間",
      hotel_terms_detail_button_go_to_list: "リストへのアクセス",
      hotel_terms_edit_button_cancel: "キャンセル",
      hotel_terms_detail_button_delete: "削除",
      hotel_terms_edit_button_download: "ダウンロード",
      hotel_terms_detail_button_edit: "修整",
      hotel_terms_edit_button_ok: "確認",
      hotel_terms_edit_validation_language_code: "* 言語コードを選択してください。",
      hotel_terms_edit_validation_type: "* タイプを選択してください。",
      hotel_terms_edit_validation_title: "* 呼称を入力してください。",
      hotel_terms_edit_validation_required: "* 必須かどうかを選択してください。",
      hotel_terms_edit_validation_description: "* 説明を入力してください。",
      hotel_terms_edit_validation_link_text: "* リンク テキストを入力してください。",
      hotel_terms_edit_validation_posted: "* 掲示するかどうかを選択してください。",
      hotel_terms_edit_validation_version: "* バージョンを入力してください。",
      hotel_terms_edit_validation_content_type: "* 内容タイプを選択してください。",
      hotel_terms_edit_validation_content: "* 内容を入力してください。",
      hotel_terms_edit_validation_hotel_terms_file_url: "* ファイルをアップロードしてください。",
      hotel_agreement_list_title: "同意履歴リスト",
      hotel_agreement_list_placeholder_select_box_choose: "サンテック",
      hotel_agreement_list_select_email: "Eメール",
      hotel_agreement_list_select_content: "内容",
      hotel_agreement_list_select_confirmation_no: "予約番号",
      hotel_agreement_list_placeholder_search: "検索",
      hotel_agreement_list_label_no: "番号",
      hotel_agreement_list_label_terms_type: "約款タイプ",
      hotel_agreement_list_label_terms_id: "約款ID",
      hotel_agreement_list_label_terms_title: "約款呼称",
      hotel_agreement_list_label_confirmation_no: "予約番号",
      hotel_agreement_list_label_email: "Eメール",
      hotel_agreement_list_label_name: "名前",
      hotel_agreement_list_label_created_at: "生成時間",
      hotel_agreement_detail_title: "同意内訳詳細",
      hotel_agreement_detail_label_agreement: "同意",
      hotel_agreement_detail_label_agreement_at: "同意時間",
      hotel_agreement_detail_label_user: "使用者",
      hotel_agreement_detail_label_email: "Eメール",
      hotel_agreement_detail_label_name: "名前",
      hotel_agreement_detail_label_language_code: "言語コード",
      hotel_agreement_detail_label_reservation: "予約",
      hotel_agreement_detail_label_confirmation_no: "予約番号",
      hotel_agreement_detail_label_terms: "弱冠",
      hotel_agreement_detail_label_terms_id: "約款ID",
      hotel_agreement_detail_label_type: "タイプ",
      hotel_agreement_detail_label_version: "バージョン",
      hotel_agreement_detail_label_title: "呼称",
      hotel_agreement_detail_label_required: "要否",
      hotel_agreement_detail_button_go_to_list: "リストへのアクセス",
      hotel_event_list_title: "イベント",
      hotel_event_list_label_category_all: "カテゴリ (すべて)",
      hotel_event_list_label_search_type_all: "検索タイプ ( ALL )",
      hotel_event_list_label_title: "タイトル",
      hotel_event_list_label_description: "説明",
      hotel_event_list_placeholder_search: "検索...",
      hotel_event_list_label_no: "いいえ。",
      hotel_event_list_label_category: "カテゴリー",
      hotel_event_list_label_date: "日付",
      hotel_event_list_guide_empty: "リストが空です",
      hotel_event_list_button_delete: "消去",
      hotel_event_list_button_add: "追加",
      hotel_event_add_validation_category: "※カテゴリを選択してください。",
      hotel_event_add_validation_templates: "※テンプレートを選択してください。",
      hotel_event_add_validation_users: "※ユーザーを選択してください。",
      hotel_event_add_title: "イベントの追加",
      hotel_input_detail_label_details: "詳細",
      hotel_event_add_label_category: "カテゴリー",
      hotel_event_add_label_templates: "テンプレート",
      hotel_event_add_select_all: "全て",
      hotel_event_add_label_notification_time: "通知時間",
      hotel_event_add_label_title: "タイトル",
      hotel_event_add_label_content: "コンテンツ",
      hotel_event_add_label_description: "説明",
      hotel_event_add_label_template_language: "テンプレート言語",
      hotel_event_add_template_add_empty_guide: "コンテンツを追加してください",
      hotel_event_add_label_user: "ユーザー",
      hotel_event_add_label_search_time: "Search Time",
      hotel_event_add_label_myconect_id: "MyCONECT ID",
      hotel_event_add_label_check_in: "Check In",
      hotel_event_add_placeholder_search: "検索...",
      hotel_event_add_label_no: "いいえ。",
      hotel_event_add_label_first_name: "ファーストネーム",
      hotel_event_add_label_last_name: "苗字",
      hotel_event_add_label_email: "Eメール",
      hotel_event_add_label_tile: "タイトル",
      hotel_event_add_label_check_out: "Check Out",
      hotel_event_add_guide_empty: "リストが空です",
      hotel_event_add_button_go_to_list: "リストに移動",
      hotel_event_add_button_delete: "消去",
      hotel_event_add_button_ok: "わかりました",
      hotel_event_add_user_validation_users: "※ユーザーを選択してください。",
      hotel_event_add_user_title: "イベントの追加",
      hotel_event_add_user_label_user: "ユーザー",
      hotel_event_add_user_label_current_time: "Current Time",
      hotel_event_add_user_label_search_time: "Search Time",
      hotel_event_add_user_label_all: "全て",
      hotel_event_add_user_label_myconect_id: "MyCONECT ID",
      hotel_event_add_user_label_profile_id: "プロフィールID",
      hotel_event_add_user_label_check_in: "Check In",
      hotel_event_add_user_placeholder_search: "検索...",
      hotel_event_add_user_label_floor: "床",
      hotel_event_add_user_label_reservation_status: "予約状況",
      hotel_event_add_user_label_room_no: "Room No.",
      hotel_event_add_user_label_reserved: "予約済み",
      hotel_event_add_user_label_due_in: "Due in",
      hotel_event_add_user_label_due_out: "Due out",
      hotel_event_add_user_label_in_house: "社内",
      hotel_event_add_user_label_checked_out: "Checked out",
      hotel_event_add_user_label_title: "タイトル",
      hotel_event_add_user_label_no: "いいえ。",
      hotel_event_add_user_label_first_name: "ファーストネーム",
      hotel_event_add_user_label_last_name: "苗字",
      hotel_event_add_user_label_email: "Eメール",
      hotel_event_add_user_label_check_out: "Check Out",
      hotel_event_add_user_guide_empty: "リストが空です",
      hotel_event_add_user_button_cancel: "キャンセル",
      hotel_event_add_user_button_ok: "わかりました",
      hotel_event_detail_title: "イベント詳細",
      hotel_event_detail_label_details: "詳細",
      hotel_event_detail_label_category: "カテゴリー",
      hotel_event_detail_label_templates: "テンプレート",
      hotel_event_detail_label_notification_time: "通知時間",
      hotel_event_detail_label_updated_at: "更新日",
      hotel_event_detail_label_title: "タイトル",
      hotel_event_detail_label_content: "コンテンツ",
      hotel_event_detail_label_description: "説明",
      hotel_event_detail_label_templates_language: "テンプレート言語",
      hotel_event_detail_template_add_empty_guide: "コンテンツを追加してください",
      hotel_event_detail_label_all: "全て",
      hotel_event_detail_label_myconect_id: "MyCONECT ID",
      hotel_event_detail_label_profile_id: "プロフィールID",
      hotel_event_detail_placeholder_search: "検索...",
      hotel_event_detail_label_no: "いいえ。",
      hotel_event_detail_label_first_name: "ファーストネーム",
      hotel_event_detail_label_last_name: "苗字",
      hotel_event_detail_label_email: "Eメール",
      hotel_event_detail_label_gender: "タイトル",
      hotel_event_detail_label_send: "送信",
      hotel_event_detail_label_check_up: "調べる",
      hotel_event_detail_label_check_in: "Check In",
      hotel_event_detail_label_check_out: "Check Out",
      hotel_event_detail_label_success: "成功",
      hotel_event_detail_label_failed: "失敗した",
      hotel_event_detail_guide_empty: "リストが空です",
      hotel_event_detail_button_go_to_list: "リストに移動",
      hotel_event_detail_button_delete: "消去",
      hotel_event_detail_button_edit: "編集",
      hotel_event_edit_validation_category: "※カテゴリを選択してください。",
      hotel_event_edit_validation_templates: "※テンプレートを選択してください。",
      hotel_event_edit_validation_users: "※ユーザーを選択してください。",
      hotel_event_edit_title: "イベント編集",
      hotel_event_edit_label_details: "詳細",
      hotel_event_edit_label_category: "カテゴリー",
      hotel_event_edit_label_templates: "テンプレート",
      hotel_event_edit_label_all: "全て",
      hotel_event_edit_label_notification_time: "通知時間",
      hotel_event_edit_label_title: "タイトル",
      hotel_event_edit_label_content: "コンテンツ",
      hotel_event_edit_label_description: "説明",
      hotel_input_edit_label_templates_language: "テンプレート言語",
      hotel_event_edit_template_add_empty_guide: "コンテンツを追加してください",
      hotel_event_edit_label_user: "ユーザー",
      hotel_event_edit_label_myconect_id: "MyCONECT ID",
      hotel_event_edit_label_check_in: "Check In",
      hotel_event_edit_label_check_out: "",
      hotel_event_edit_placeholder_search: "検索...",
      hotel_event_edit_button_delete: "消去",
      hotel_event_edit_label_no: "いいえ。",
      hotel_event_edit_label_first_name: "ファーストネーム",
      hotel_event_edit_label_last_name: "苗字",
      hotel_event_edit_label_email: "Eメール",
      hotel_event_edit_label_gender: "タイトル",
      hotel_event_edit_guide_empty: "リストが空です",
      hotel_event_edit_button_cancel: "キャンセル",
      hotel_event_edit_button_ok: "わかりました",
      hotel_event_category_list_title: "イベントカテゴリー",
      hotel_event_category_list_label_all: "冒頭",
      hotel_event_category_list_label_type: "タイプ",
      hotel_event_category_list_label_show: "展示の可否",
      hotel_event_category_list_placeholder_search: "検索······",
      hotel_event_category_list_label_no: "番号",
      hotel_event_category_list_label_date: "日付",
      hotel_event_category_list_guide_empty: "リストが空です",
      hotel_event_category_list_button_delete: "削除",
      hotel_event_category_list_button_add: "追加",
      hotel_event_category_add_title: "イベントカテゴリー追加",
      hotel_event_category_add_label_type: "タイプ",
      hotel_event_category_add_label_show: "展示の可否",
      hotel_event_category_add_label_yes: "例",
      hotel_event_category_add_label_no: "いいえ",
      hotel_event_category_add_button_go_to_list: "リストへのアクセス",
      hotel_event_category_add_button_add: "追加",
      hotel_event_category_add_validation_type: "* タイプを入力してください。",
      hotel_event_category_add_validation_show: "* 展示するかどうかを選択してください。",
      hotel_event_category_detail_title: "イベントカテゴリー詳細",
      hotel_event_category_detail_label_detail: "詳細",
      hotel_event_category_detail_button_edit: "修整",
      hotel_event_category_detail_label_type: "タイプ",
      hotel_event_category_detail_label_show: "展示の可否",
      hotel_event_category_detail_label_date: "日付",
      hotel_event_category_detail_button_go_to_list: "リストへのアクセス",
      hotel_event_category_detail_button_delete: "削除",
      hotel_event_category_detail_label_yes: "例",
      hotel_event_category_detail_label_no: "いいえ",
      hotel_event_category_detail_label_template: "テンプレート",
      hotel_event_category_detail_button_add: "追加",
      hotel_event_category_detail_label_number: "番号",
      hotel_event_category_detail_label_title: "タイトル",
      hotel_event_category_detail_guide_empty: "リストが空です",
      hotel_event_category_edit_title: "イベントカテゴリー修正",
      hotel_event_category_edit_label_type: "タイプ",
      hotel_event_category_edit_label_show: "展示の可否",
      hotel_event_category_edit_label_yes: "例",
      hotel_event_category_edit_label_no: "いいえ",
      hotel_event_category_edit_label_date: "日付",
      hotel_event_category_edit_button_cancel: "キャンセル",
      hotel_event_category_edit_button_delete: "削除",
      hotel_event_category_edit_button_ok: "確認",
      hotel_event_category_edit_validation_type: "* タイプを入力してください。",
      hotel_event_category_template_add_title: "イベント カテゴリ テンプレートの追加",
      hotel_event_category_template_add_label_title: "タイトル",
      hotel_event_category_template_add_label_content: "内容",
      hotel_event_category_template_add_label_description: "説明",
      hotel_event_category_template_add_label_language: "言語",
      hotel_event_category_template_add_button_add: "追加",
      hotel_event_category_template_add_button_delete: "削除",
      hotel_event_category_template_add_empty_guide: "内容を追加してください。",
      hotel_event_category_template_add_button_go_to_list: "リストへのアクセス",
      hotel_event_category_template_add_button_ok: "確認",
      hotel_event_category_template_add_validation_title: "* タイトルを入力してください。",
      hotel_event_category_template_add_validation_content: "* 内容を入力してください。",
      hotel_event_category_template_add_validation_description: "* 説明を入力してください。",
      hotel_event_category_template_detail_title: "イベント カテゴリ テンプレート 詳細",
      hotel_event_category_template_detail_label_templates: "テンプレート",
      hotel_event_category_template_detail_label_category: "カテゴリー",
      hotel_event_category_template_detail_label_title: "タイトル",
      hotel_event_category_template_detail_label_content: "内容",
      hotel_event_category_template_detail_label_description: "説明",
      hotel_event_category_template_detail_label_date: "日付",
      hotel_event_category_template_detail_label_language: "言語",
      hotel_event_category_template_detail_empty_guide: "内容を追加してください。",
      hotel_event_category_template_detail_button_go_to_list: "リストへのアクセス",
      hotel_event_category_template_detail_button_delete: "削除",
      hotel_event_category_template_detail_button_edit: "修整",
      hotel_event_category_template_edit_title: "イベント カテゴリ テンプレートの修正",
      hotel_event_category_template_edit_label_title: "タイトル",
      hotel_event_category_template_edit_label_content: "内容",
      hotel_event_category_template_edit_label_description: "説明",
      hotel_event_category_template_edit_label_date: "日付",
      hotel_event_category_template_edit_label_language: "言語",
      hotel_event_category_template_edit_button_add: "追加",
      hotel_event_category_template_edit_button_delete: "削除",
      hotel_event_category_template_edit_empty_guide: "内容を追加してください。",
      hotel_event_category_template_edit_button_go_to_list: "リストへのアクセス",
      hotel_event_category_template_edit_button_ok: "確認",
      hotel_event_category_template_edit_validation_title: "* タイトルを入力してください。",
      hotel_event_category_template_edit_validation_content: "* 内容を入力してください。",
      hotel_event_category_template_edit_validation_description: "* 説明を入力してください。",
      hotel_staff_user_list_title: "スタッフ一覧",
      hotel_staff_user_list_select_staff: "スタッフ(全員)",
      hotel_staff_user_list_select_all: "冒頭",
      hotel_staff_user_list_select_department: "部署",
      hotel_staff_user_list_select_team: "チーム",
      hotel_staff_user_list_select_level: "職級",
      hotel_staff_user_list_select_task: "タスク",
      hotel_staff_user_list_select_staff_number: "スタッフ番号",
      hotel_staff_user_list_select_email: "Eメール",
      hotel_staff_user_list_placeholder_search: "検索",
      hotel_staff_user_list_label_department: "部署",
      hotel_staff_user_list_label_team: "チーム",
      hotel_staff_user_list_label_level: "職責",
      hotel_staff_user_list_label_task: "タスク",
      hotel_staff_user_list_label_number: "番号",
      hotel_staff_user_list_label_employee_id: "職員ID",
      hotel_staff_user_list_label_available_at: "使用可能時間",
      hotel_staff_user_list_label_expired_at: "満了時間",
      hotel_staff_user_list_label_login_at: "ログイン時間",
      hotel_staff_user_list_label_date: "日付",
      hotel_staff_user_list_button_delete: "削除",
      hotel_staff_user_list_button_add: "追加",
      hotel_staff_user_add_title: "新規スタッフ",
      hotel_staff_user_add_guide_click_add: "新規スタッフ登録のため、以下の情報を入力し、「追加」をクリックしてください。",
      hotel_staff_user_add_validation_department: "* スタッフの部署を選択してください。",
      hotel_staff_user_add_validation_team: "* スタッフのチームを選択してください。",
      hotel_staff_user_add_validation_level: "* スタッフの役職を選択してください。",
      hotel_staff_user_add_validation_guest_inquiry_level: "* スタッフの顧客照会レベルを選択してください。",
      hotel_staff_user_add_validation_number: "* スタッフ番号を入力してください。",
      hotel_staff_user_add_validation_task: "* スタッフ タスクを入力してください。",
      hotel_staff_user_add_validation_number_only: "* 数字のみ入力してください。",
      hotel_staff_user_add_validation_email: "* スタッフのメールアドレスを入力してください。",
      hotel_staff_user_add_validation_email_format: "* メールの形式に合わせて入力してください。",
      hotel_staff_user_add_validation_password: "* スタッフのパスワードを入力してください。",
      hotel_staff_user_add_validation_character_number_combining: "* 英文と数字を組み合わせて8文字以上入力してください。",
      hotel_staff_user_add_validation_not_mach: "* パスワードが一致しません。",
      hotel_staff_user_add_label_type: "タイプ",
      hotel_staff_user_add_select_full_time: "正社員",
      hotel_staff_user_add_select_temporary: "非正規職",
      hotel_staff_user_add_placeholder_select_box_choose: "選択",
      hotel_staff_user_add_label_department: "部署",
      hotel_staff_user_add_select_manage: "役人",
      hotel_staff_user_add_select_concierge: "コンシェルジュ",
      hotel_staff_user_add_select_housekeeping: "ハウスキーピング",
      hotel_staff_user_add_select_front_desk: "フロント·デスク",
      hotel_staff_user_add_select_valet: "バレット",
      hotel_staff_user_add_label_team: "チーム",
      hotel_staff_user_add_select_none: "なし",
      hotel_staff_user_add_select_guest_service: "顧客サービス",
      hotel_staff_user_add_label_level: "職級",
      hotel_staff_user_add_select_manager: "マネージャー",
      hotel_staff_user_add_select_supervisor: "スーパーバイザー",
      hotel_staff_user_add_select_staff: "スタッフ",
      hotel_staff_user_add_select_duty_manager: "当直支配人",
      hotel_staff_user_add_label_guest_inquiry_level: "顧客照会レベル",
      hotel_staff_user_add_label_number: "番号",
      hotel_staff_user_add_placeholder_staff_number: "スタッフ番号",
      hotel_staff_user_add_label_location: "位置",
      hotel_staff_user_add_placeholder_staff_location: "スタッフ位置",
      hotel_staff_user_add_label_task: "タスク",
      hotel_staff_user_add_placeholder_staff_task: "スタッフ·タスク",
      hotel_staff_user_add_label_email: "Eメール",
      hotel_staff_user_add_placeholder_staff_email: "スタッフメール",
      hotel_staff_user_add_label_password: "パスワード",
      hotel_staff_user_add_placeholder_staff_password: "スタッフパスワード",
      hotel_staff_user_add_label_confirm_password: "確認パスワード",
      hotel_staff_user_add_placeholder_staff_confirm_password: "確認パスワード",
      hotel_staff_user_add_label_available_at: "可能時間",
      hotel_staff_user_add_label_expired_at: "満了時間",
      hotel_staff_user_add_button_add: "追加",
      hotel_staff_user_add_button_go_to_list: "リストへのアクセス",
      hotel_staff_user_detail_title: "スタッフ情報",
      hotel_staff_user_detail_label_update_date: "アップデートされた日付",
      hotel_staff_user_detail_label_department: "部署",
      hotel_staff_user_detail_label_team: "チーム",
      hotel_staff_user_detail_label_level: "職責",
      hotel_staff_user_detail_label_guest_inquiry_level: "顧客照会レベル",
      hotel_staff_user_detail_label_task: "タスク",
      hotel_staff_user_detail_label_staff_number: "スタッフ番号",
      hotel_staff_user_detail_label_email: "Eメール",
      hotel_staff_user_detail_label_location: "位置",
      hotel_staff_user_detail_label_room: "客室",
      hotel_staff_user_detail_label_login_at: "ログイン時間",
      hotel_staff_user_detail_label_date: "日付",
      hotel_staff_user_detail_label_available_at: "アカウント使用開始時間",
      hotel_staff_user_detail_label_expired_at: "アカウントの使用期限切れ時間",
      hotel_staff_user_detail_label_locked_information: "ロック情報",
      hotel_staff_user_detail_label_locked_reason: "ロック事由",
      hotel_staff_user_detail_label_locked_at: "ロック時間",
      hotel_staff_user_detail_label_password: "パスワード",
      hotel_staff_user_detail_label_password_change: "パスワード変更",
      hotel_staff_user_detail_button_go_to_list: "リストへのアクセス",
      hotel_staff_user_detail_button_delete: "削除",
      hotel_staff_user_detail_button_edit: "修整",
      hotel_staff_user_edit_title: "スタッフ情報",
      hotel_staff_user_edit_label_update_date: "アップデートされた日付",
      hotel_staff_user_edit_label_department: "部署",
      hotel_staff_user_edit_placeholder_select_box_choose: "選択",
      hotel_staff_user_edit_validation_department: "* スタッフ部門を選択してください。",
      hotel_staff_user_edit_select_manage: "管理部",
      hotel_staff_user_edit_select_concierge: "コンシェルジュ",
      hotel_staff_user_edit_select_housekeeping: "ハウスキーピング",
      hotel_staff_user_edit_select_front_desk: "フロント·デスク",
      hotel_staff_user_edit_select_valet: "バレット",
      hotel_staff_user_edit_label_team: "チーム",
      hotel_staff_user_edit_select_none: "なし",
      hotel_staff_user_edit_select_guest_service: "顧客サービス",
      hotel_staff_user_edit_label_level: "職級",
      hotel_staff_user_edit_select_manager: "マネージャー",
      hotel_staff_user_edit_select_supervisor: "スーパーバイザー",
      hotel_staff_user_edit_select_staff: "スタッフ",
      hotel_staff_user_edit_select_duty_manager: "当直支配人",
      hotel_staff_user_edit_label_task: "タスク",
      hotel_staff_user_edit_placeholder_task: "タスク",
      hotel_staff_user_edit_label_staff_number: "スタッフ番号",
      hotel_staff_user_edit_placeholder_staff_number: "スタッフ番号",
      hotel_staff_user_edit_label_email: "Eメール",
      hotel_staff_user_edit_placeholder_email: "Eメール",
      hotel_staff_user_edit_label_location: "位置",
      hotel_staff_user_edit_placeholder_location: "位置",
      hotel_staff_user_edit_label_room: "客室",
      hotel_staff_user_edit_label_login_at: "ログイン時間",
      hotel_staff_user_edit_label_date: "日付",
      hotel_staff_user_edit_label_available_at: "アカウント使用開始時間",
      hotel_staff_user_edit_label_expired_at: "アカウントの使用期限切れ時間",
      hotel_staff_user_edit_label_locked_information: "ロック情報",
      hotel_staff_user_edit_label_locked_reason: "ロック事由",
      hotel_staff_user_edit_label_locked_at: "ロック時間",
      hotel_staff_user_edit_button_cancel: "キャンセル",
      hotel_staff_user_edit_button_delete: "削除",
      hotel_staff_user_edit_button_ok: "確認",
      contents_failed_unable_connect_server: "サーバーに接続できません。 \nインターネット接続を確認してください。",
      button_ok: "わかりました",
      button_cancel: "キャンセル",
      button_failed_login_message: "メールアドレスとパスワードが一致しません。",
      button_delete_message: "選択した情報を削除してもよろしいですか?",
      button_no_select_delete_message: "何も選択されていません。 \n削除するリストを選択してください。",
      button_no_select_hotel_copy_message: "何も選択されていません。\nコピーするホテルを選択してください。",
      button_only_one_hotel_copy_message: "ホテルを 1 つだけ選択してください。",
      button_cancel_message: "選択した情報をキャンセルしてもよろしいですか?",
      button_no_select_cancel_message: "何も選択されていません。 \nキャンセルするリストを選択してください。",
      button_admin_cant_delete_message: "ADMIN は削除できません。選択を解除してください。",
      button_manager_cant_delete_message: "マネージャーは削除できません。 \n選択を解除してください。",
      button_cant_release_message: "このメンバーを解放することはできません。 \n選択を解除してください。",
      button_withdraw_message: "退会しますか?",
      button_release_message: "選択した情報を公開してもよろしいですか?",
      button_no_select_release_message: "何も選択されていません。 \nリリースするリストを選択してください。",
      button_delete_qna_message: "QnA を削除してもよろしいですか?",
      button_update_information_message: "一致する情報が存在します。 \nこの情報を更新しますか?",
      button_delete_information_message: "既存の情報を削除して更新します \n新しい情報とともに。",
      button_insert_information_message: "新しい情報を入力します。",
      button_qna_loading_message: "読み込み中…",
      button_cancel_not_save_message: "キャンセルすると内容は保存されません。\nあなたがキャンセルしてもよろしいです？",
      button_cancel_booking_message: "選択した予約をキャンセルしてもよろしいですか?",
      button_delete_owner_message: "選択したメインゲストを削除してもよろしいですか?",
      button_success_message: "変更が加えられました。",
      button_stop_message: "選択したリクエストを停止しますか? \n(状況不明)",
      button_no_select_stop_message: "何も選択されていません。停止するリストを選択してください。",
      button_upload_success_message: "アップロードに成功しました。 \n[[画像]]",
      button_reservation_message: "というリクエストがあります \nまだ完成していません。リクエストを確認しますか?",
      button_amenity_message_title: "商品番号。",
      button_amenity_message: "該当するアメニティの商品番号を以下のように変更しますか？",
      button_transfer_user_id_message: "再同期するゲスト MyCONECT ユーザー ID を入力します。",
      contents_failed_session_password: "新しいパスワードを入力してください\n少なくとも 8 文字。",
      contents_session_out_message: "セッションタイムアウト。 \nログインページにアクセスしてください。",
      contents_failed_session_message: "セッションの取得に失敗しました。",
      contents_failed_password_incorrect_message: "このパスワードは間違っています。",
      contents_failed_get_hotel_detail: "ホテルの詳細を取得できませんでした。",
      contents_failed_get_region_detail: "地域の詳細を取得できませんでした。",
      contents_failed_get_region_list: "リージョンリストの取得に失敗しました",
      contents_failed_get_country_detail: "国の詳細を取得できませんでした。",
      contents_failed_get_country_list: "国リストの取得に失敗しました",
      contents_failed_get_city_detail: "都市の詳細を取得できませんでした。",
      contents_failed_get_city_list: "都市リストの取得に失敗しました",
      contents_failed_edit_hotel: "ホテルの編集に失敗しました。",
      contents_failed_edit_region: "リージョンの編集に失敗しました。",
      contents_failed_edit_country: "国の編集に失敗しました。",
      contents_failed_edit_city: "都市の編集に失敗しました。",
      contents_failed_edit_amenity: "アメニティの編集に失敗しました。",
      contents_failed_add_amenity: "アメニティを追加できません。",
      contents_failed_edit_recommend_app: "おすすめアプリの編集に失敗しました。",
      contents_failed_add_recommend_app: "おすすめアプリを追加できません。",
      contents_failed_edit_id_recognition_information: "ID認識情報の編集に失敗しました。",
      contents_failed_add_id_recognition_information: "ID認識情報を追加できません。",
      contents_failed_get_list: "対応するページリストの取得に失敗しました。",
      contents_failed_logout: "ログアウトに失敗しました。",
      contents_failed_get_informarion: "ユーザー情報の取得に失敗しました。",
      contents_failed_edit_information: "情報の編集に失敗しました。",
      contents_failed_modify: "パスワードの変更に失敗しました。",
      contents_failed_add_user: "ユーザーを追加できません。メールがすでに存在するかどうかを確認してください。",
      contents_failed_transfer: "リンク転送に失敗しました。",
      contents_failed_change_duty: "職務変更に失敗しました。",
      contents_failed_edit_user: "ユーザーの編集に失敗しました。",
      contents_failed_get_withdraw: "撤退に失敗しました。",
      contents_failed_add_version: "バージョンを追加できません。",
      contents_failed_edit_version: "バージョンの編集に失敗しました。",
      contents_failed_delete: "削除に失敗しました。",
      contents_failed_image: "画像情報の取得に失敗しました。",
      contents_failed_get_area_list: "特殊領域リストの取得に失敗しました。",
      contents_failed_get_hotel_detail_list: "ホテルの詳細リストの取得に失敗しました。",
      contents_failed_get_hotel_detail_detail: "ホテルの詳細を取得できませんでした。",
      contents_failed_get_facility_icon_list: "施設アイコンリストの取得に失敗しました。",
      contents_failed_get_hotel_amenity_list: "ホテルのアメニティリストの取得に失敗しました。",
      contents_failed_add_hotel_detail_content: "ホテルの詳細コンテンツを追加できません。",
      contents_failed_add_hotel_detail_amenity: "ホテル詳細のアメニティを追加できません。",
      contents_failed_get_hotel_recommend_application_list: "ホテル推奨アプリケーションリストの取得に失敗しました。",
      contents_failed_add_hotel_detail_recommend_application: "ホテル推奨アプリケーションを追加できません。",
      contents_failed_get_hotel_img_detail: "ホテル画像の詳細を取得できませんでした。",
      contents_failed_get_facility_img_detail: "ホテルの設備画像の詳細を取得できませんでした。",
      contents_failed_add_hotel_img: "ホテルの画像を追加できません。",
      contents_failed_add_facility_img: "ホテル施設画像を追加できません。",
      contents_failed_add_room_img: "部屋の画像を追加できません。",
      contents_failed_get_room_amenity_list: "客室アメニティリストの取得に失敗しました。",
      contents_failed_add_room_detail_amenity: "客室詳細のアメニティを追加できません。",
      contents_failed_get_room_img_detail: "部屋画像の詳細を取得できませんでした。",
      contents_failed_edit_hotel_detail_content: "ホテル詳細コンテンツの編集に失敗しました。",
      contents_failed_edit_room: "ルームの編集に失敗しました。",
      contents_failed_edit_room_detail_content: "ルーム詳細コンテンツの編集に失敗しました。",
      contents_failed_add_package: "パッケージを追加できません。",
      contents_failed_add_package_detail: "パッケージの詳細を追加できません。",
      contents_failed_get_package_detail: "パッケージの取得に失敗しました。",
      contents_failed_get_package_detail_detail: "パッケージの詳細を取得できませんでした。",
      contents_failed_get_access_detail_detail: "アクセス詳細の取得に失敗しました。",
      contents_failed_add_access: "アクセスを追加できません。",
      contents_failed_edit_access: "Accessの編集に失敗しました。",
      contents_failed_add_access_detail: "アクセスの詳細を追加できません。",
      contents_failed_add_beacon: "ビーコンを追加できません。",
      contents_failed_add_region: "地域を追加できません。",
      contents_failed_add_country: "国を追加できません。",
      contents_failed_add_city: "都市を追加できません。",
      contents_failed_edit_beacon: "ビーコンの編集に失敗しました。",
      contents_failed_add_currency: "通貨を追加できません。",
      contents_failed_get_currency_detail: "ホテル通貨の詳細を取得できませんでした。",
      contents_not_supported_exchange_rate: "為替レートはサポートされていません。",
      contents_failed_get_currency_pms: "PMS ホテル通貨の取得に失敗しました。",
      contents_failed_get_currency_update: "ホテル通貨の更新に失敗しました。",
      contents_failed_request_stop: "停止要求に失敗しました。",
      contents_failed_upload_file: "ファイルのアップロードに失敗しました。",
      contents_failed_get_count_canceled: "キャンセルされたカウントボードの取得に失敗しました。",
      contents_failed_get_business_rule_list: "ビジネスルールリストの取得に失敗しました。",
      contents_failed_uptade_myconect: "MyCONECT動作時間設定の更新に失敗しました。",
      contents_failed_get_concierge_myconect: "MyCONECTコンシェルジュリストの取得に失敗しました。",
      contents_request_type_not_exist: "リクエストタイプが存在しません。",
      contents_failed_get_request_detail: "リクエストの詳細を取得できませんでした。",
      contents_failed_request_stand_by: "スタンバイ要求に失敗しました。",
      contents_failed_request_pickup: "ピックアップのリクエストに失敗しました。",
      contents_failed_request_start: "開始のリクエストに失敗しました。",
      contents_failed_request_deliver: "配信リクエストに失敗しました。",
      contents_failed_request_valet_no: "バレット番号のリクエストに失敗しました。",
      contents_failed_get_hotel_setting: "ホテルの設定を取得できませんでした。",
      contents_failed_get_count_request_board: "リクエスト数ボードの取得に失敗しました。",
      contents_failed_get_concierge_process_list: "コンシェルジュプロセスリストの取得に失敗しました。",
      contents_failed_get_valet_parking_list: "バレーパーキングリストの取得に失敗しました。",
      contents_failed_add_parking_area_no: "駐車場番号を追加できません。",
      contents_failed_change_veicle_no: "車両番号の変更に失敗しました。",
      contents_failed_request_location: "車両の準備に失敗しました。",
      contents_failed_request_return: "車両の返却に失敗しました。",
      contents_failed_request_handover: "ゲストへの車両の引き渡しに失敗しました。",
      contents_failed_get_business_rule: "ビジネスルールの取得に失敗しました。",
      contents_failed_get_boundary_line_setting: "境界線設定の取得に失敗しました。",
      contents_failed_request_confirm: "「確認」のリクエストに失敗しました。",
      contents_failed_request_not_available: "「利用不可」のリクエストに失敗しました。",
      contents_failed_request_not_available_myconect: "「MyCONECT では利用できません」のリクエストに失敗しました。",
      contents_failed_get_concierge_request_list: "コンシェルジュリクエストリストの取得に失敗しました。",
      contents_already_exists: "このタイプはすでに存在します。",
      contents_failed_add_help_for_mobile_key: "「モバイルキーのヘルプ」を追加できませんでした。",
      contents_failed_get_help_for_mobile_key: "「モバイルキーのヘルプ」の取得に失敗しました。",
      contents_failed_edit_help_for_mobile_key: "「モバイルキーのヘルプ」の編集に失敗しました。",
      contents_failed_delete_help_for_mobile_key: "「モバイルキーのヘルプ」情報の削除に失敗しました。",
      contents_failed_add_service_description: "「サービスの説明」を追加できませんでした。",
      contents_failed_get_service_description: "「サービスの説明」の取得に失敗しました。",
      contents_failed_edit_service_description: "「サービスの説明」の編集に失敗しました。",
      contents_failed_delete_service_description: "「サービス説明」の削除に失敗しました。",
      contents_failed_add_hotel_information: "ホテル情報の追加に失敗しました。",
      contents_failed_get_hotel_information: "ホテル情報の取得に失敗しました。",
      contents_failed_edit_hotel_information: "ホテル情報の編集に失敗しました。",
      contents_failed_edit_language_code_translation: "言語はすでに選択されています。",
      contents_failed_all_language_code_exist: "All translations have been added.",
      contents_failed_delete_hotel_information: "ホテル情報の削除に失敗しました。",
      contents_failed_get_canceled_user_count: "ユーザー数によるキャンセルの取得に失敗しました。",
      contents_failed_get_canceled_staff_count: "MyCONECT またはスタッフ数によるキャンセルの取得に失敗しました。",
      contents_failed_get_housekeeping_myconect: "ハウスキーピング MyCONECT リストの取得に失敗しました。",
      contents_failed_add_multi_myconect: "選択した日付が間違っています",
      contents_failed_request_complete: "「完了」のリクエストに失敗しました。",
      contents_failed_request_put_off: "「延期」のリクエストに失敗しました。",
      contents_failed_get_amenity_count: "アメニティ数の取得に失敗しました。",
      contents_failed_get_valet_count: "バレットカウントの取得に失敗しました。",
      contents_failed_get_housekeeping_process_list: "ハウスキーピング進行状況リストの取得に失敗しました。",
      contents_failed_get_boundary_line_request_count: "境界線リクエスト数の取得に失敗しました。",
      contents_failed_update_auto: "グリーン キャンペーンの自動モード時間を更新できませんでした",
      contents_failed_get_housekeeping_request_list: "ハウスキーピング リクエスト リストの取得に失敗しました。",
      contents_failed_get_room_controller_detail: "ルームコントロールの詳細を取得できませんでした。",
      contents_failed_insert_files: "ファイルの挿入に失敗しました。",
      contents_failed_get_room_to_room_detail: "部屋間の詳細を取得できませんでした。",
      contents_failed_get_share_key_detail: "共有キーの詳細を取得できませんでした。",
      contents_failed_modify_amenity: "アメニティアイテム番号の変更に失敗しました。",
      contents_failed_get_boundary_line_detail: "境界線の詳細を取得できませんでした。",
      contents_failed_modify_boundary_line_detail: "境界線の詳細を変更できませんでした。",
      contents_failed_modify_boundary_line_unasigned_count: "未割り当ての境界線数の取得に失敗しました。",
      contents_failed_get_business_rule_detail: "ビジネス ルールの詳細を取得できませんでした。",
      contents_failed_modify_business_rule_detail: "ビジネス ルールの詳細を変更できませんでした。",
      contents_failed_modify_waiting_time: "ルーム待機時間の変更に失敗しました。",
      contents_failed_cancel: "キャンセルに失敗しました。",
      contents_failed_add_member_type: "メンバータイプを追加できません。",
      contents_failed_edit_member_type: "メンバータイプの編集に失敗しました。",
      contents_failed_edit_pms: "PMSの編集に失敗しました。",
      contents_failed_sync: "同期に失敗しました。",
      contents_failed_send_reservation_email: "予約メールの送信に失敗しました。",
      contents_failed_get_staff_key_link_list: "スタッフキーリンクリストの取得に失敗しました。",
      contents_failed_get_staff_key_link_type_list: "譜表キーリンクタイプリストの取得に失敗しました。",
      contents_failed_get_staff_key_link_floor_list: "スタッフキーリンクフロアリストの取得に失敗しました。",
      contents_failed_get_staff_key_link_zone_list: "譜表キーリンクゾーンリストの取得に失敗しました。",
      contents_failed_get_staff_key_link_code_list: "スタッフキーリンクコードリストの取得に失敗しました。",
      contents_failed_get_staff_detail: "スタッフの詳細を取得できませんでした。",
      contents_failed_edit_staff_user: "スタッフユーザーの編集に失敗しました。",
      contents_failed_add_title: "タイトルを追加できません。",
      contents_failed_edit_title: "タイトルの編集に失敗しました。",
      contents_failed_get_list_search_page: "検索ページのリストの取得に失敗しました。",
      contents_failed_edit_setting: "設定の編集に失敗しました。",
      contents_failed_add_staff_key_link: "スタッフキーリンクを追加できません。",
      contents_failed_edit_staff_key_link: "五線キーリンクの編集に失敗しました。",
      contents_failed_edit_hotel_img: "ホテル画像の編集に失敗しました。",
      contents_failed_edit_facility_img: "ホテル施設画像の編集に失敗しました。",
      contents_failed_edit_room_img: "ルーム画像の編集に失敗しました。",
      contents_failed_edit_package_detail: "パッケージの詳細の編集に失敗しました。",
      contents_failed_edit_access_detail: "アクセス詳細の編集に失敗しました。",
      contents_failed_add_guest_app_version: "ゲスト アプリのバージョンを追加できませんでした。",
      contents_failed_add_staff_app_version: "スタッフアプリのバージョンを追加できませんでした。",
      contents_failed_get_rule_information: "ルール情報の取得に失敗しました。",
      contents_failed_edit_rule_information: "ルール情報の編集に失敗しました。",
      contents_failed_edit_sequence_information: "シーケンスの編集に失敗しました。",
      contents_failed_get_rule_schedule_information: "ルールスケジュール情報の取得に失敗しました。",
      contents_failed_edit_rule_schedule_information: "ルールスケジュール情報の編集に失敗しました。",
      None: "顔比較情報の取得に失敗しました。",
      contents_modal_stop_message_unknown: "停止メッセージは次のように保存されます。 \n「未知の状況」 \n続行しますか?",
      contents_modal_stop_message_no_taxi: "停止メッセージは次のように保存されます。 \n「タクシーノーショー」 \n続行しますか?",
      contents_modal_stop_message_no_guest: "停止メッセージは次のように保存されます。 \n「ゲストノーショー」 \n続行しますか?",
      contents_modal_stop_message_time_out_30: "停止メッセージは次のように保存されます。 \n「待ち時間が30分を超えました」 \n続行しますか?",
      contents_modal_stop_message_time_out_60: "停止メッセージは次のように保存されます。 \n「待ち時間が60分を超えました」 \n続行しますか?",
      contents_modal_stop_message_no_motorcycle: "停止メッセージは次のように保存されます。 \n「デリバリーバイクノーショー」 \n続行しますか?",
      contents_modal_stop_message_no_luggage: "停止メッセージは次のように保存されます。 \n「荷物の準備ができていません」 \n続行しますか?",
      contents_modal_stop_message_delay_30: "「Put Off」メッセージは次のように保存されます。 \n「30分遅れてください」 \n続行しますか?",
      contents_modal_stop_message_delay_60: "「Put Off」メッセージは次のように保存されます。 \n「60分遅れます」 \n続行しますか?",
      contents_modal_stop_message_as_soon_as: "「Put Off」メッセージは次のように保存されます。 \n「入手でき次第」 \n続行しますか?",
      contents_modal_stop_message_out_of_order: "停止メッセージは次のように保存されます。 \n'故障中' \n続行しますか?",
      contents_modal_stop_message_cancel_service: "停止メッセージは次のように保存されます。 \n「サービスをキャンセルする」 \n続行しますか?",
      contents_modal_stop_message_not_found_it: "停止メッセージは次のように保存されます。 \n'見つからない' \n続行しますか?",
      contents_modal_stop_message_no_guest_room: "停止メッセージは次のように保存されます。 \n「ゲストが部屋にいません」 \n続行しますか?",
      contents_modal_stop_message_change_items: "停止メッセージは次のように保存されます。 \n「アイテムを変更する」 \n続行しますか?",
      contents_modal_stop_message_unparked: "停止メッセージは次のように保存されます。 \n「駐車を解除してキャンセル」 \n続行しますか?",
      contents_modal_stop_message_parked: "停止メッセージは次のように保存されます。 \n「駐車してキャンセル」 \n続行しますか?",
      contents_modal_myconect_release_proceed: "MyCONECT は次のように進行します。 \n「MyCONECT ではリリースが利用できません」 \n続行しますか?",
      contents_modal_pickup_request: "リクエストを受け取りますか?",
      contents_modal_deliver_request: "リクエストを配信しますか?",
      contents_modal_start_request: "リクエストを開始しますか?",
      contents_modal_confirm_request: "リクエストを確認しますか?",
      contents_modal_not_available_request: "リクエストを「利用不可」に設定しますか?",
      contents_modal_complete_request: "リクエストを完了しますか?",
      contents_modal_greencampaign_auto_process: "オートグリーンキャンペーンを進めてみませんか？",
      contents_modal_select_staff_payment: "選択した情報を続行しますか?",
      contents_modal_select_business_rule: "選択した情報を続行しますか?",
      contents_modal_delay_request: "リクエストを遅らせますか?",
      contents_modal_put_off_request: "リクエストを延期しますか?",
      contents_modal_change_request: "変更しますか?",
      contents_modal_save_these_modifications: "これらの変更を保存しますか?",
      contents_modal_select_myconect_not_available: "「MyCONECT では利用不可」の設定時間を選択します",
      contents_modal_select_delay: "「遅延」設定時間を選択します",
      contents_modal_select_status: "ステータスを選択してください。",
      contents_modal_make_sure_reason: "理由を選択してください。",
      contents_modal_myconect_not_available_proceed: "MyCONECT は次のように進行します。 \n「このリクエストは「利用不可」に設定されます \n続行しますか?",
      contents_modal_myconect_time_proceed: "MyCONECT は次のように進行します。 \n送信元: {{fromTime}} / 宛先: {{endTime}} \n続行しますか?",
      contents_modal_greencampaign_time_proceed: "グリーンキャンペーンは以下のような流れで進めていきます。 \n送信元: {{fromTime}} / 宛先: {{endTime}} \n続行しますか?",
      contents_modal_cannot_empty_value: "値を空にすることはできません。 \n値を入力してください。",
      contents_modal_information_already_exists: "情報はすでに存在します。 \nもう一度試してください。",
      contents_modal_placeholder_user_email: "ユーザーのメールアドレスを入力してください。",
      contents_modal_check_reset: "Previously selected users will be reset. Would you like to continue?",
    },
  },
  date_format: {
    ja: {
      date_format_slash_time_date: {
        date_format: "hh:mm a MM/dd/yy",
        language_code: "ja",
      },
      date_format_slash_date: {
        date_format: "MM/dd/yy",
        language_code: "ja",
      },
      date_format_hyphen_date: {
        date_format: "yyyy-MM-dd",
        language_code: "ja",
      },
      date_format_dot_month_year: {
        date_format: "MMM.yyyy",
        language_code: "ja",
      },
      date_format_dot_year_month: {
        date_format: "yyyy.MM",
        language_code: "ja",
      },
      date_format_slash_year_month: {
        date_format: "MM/yy",
        language_code: "ja",
      },
      date_format_ampm_time: {
        date_format: "a hh:mm",
        language_code: "ja",
      },
      date_format_time_ampm: {
        date_format: "hh:mm a",
        language_code: "ja",
      },
      date_format_24_hours_time: {
        date_format: "HH:mm",
        language_code: "ja",
      },
      date_format_hours_ampm: {
        date_format: "ha",
        language_code: "ja",
      },
      date_format_full_year: {
        date_format: "yyyy",
        language_code: "ja",
      },
      date_format_word_month: {
        date_format: "MMM",
        language_code: "ja",
      },
      date_format_full_day: {
        date_format: "dd",
        language_code: "ja",
      },
      date_format_short_day: {
        date_format: "d",
        language_code: "ja",
      },
      date_format_day_of_week: {
        date_format: "E",
        language_code: "ja",
      },
      date_format_ampm: {
        date_format: "a",
        language_code: "ja",
      },
      date_format_day_of_week_full: {
        date_format: "EEEE",
        language_code: "ja",
      },
      date_format_dot_month_day: {
        date_format: "MM.dd",
        language_code: "ja",
      },
      date_format_day_of_week_date: {
        date_format: "E. MMM. dd, yyyy",
        language_code: "ja",
      },
      date_format_slash_date_time: {
        date_format: "MM/dd/yy hh:mm a",
        language_code: "ja",
      },
      date_format_slash_month_day_time: {
        date_format: "MM/dd hh:mm a",
        language_code: "ja",
      },
      date_format_dot_date_time_full: {
        date_format: "yyyy.MM.dd hh:mm a",
        language_code: "ja",
      },
      date_format_dot_year_month_day: {
        date_format: "MM.dd.yy",
        language_code: "ja",
      },
      date_format_slash_full_year_date: {
        date_format: "yyyy/MM/dd",
        language_code: "ja",
      },
      date_format_space_date: {
        date_format: "dd MMM yyyy",
        language_code: "ja",
      },
      date_format_create_at_time_full: {
        date_format: "yyyy.MM.dd\nhh:mm a",
        language_code: "ja",
      },
    },
  },
};
export default JapaneseLanguage;
