import axiosInstance from "../api/axios";

/* ------------------------------------------------- match password ------------------------------------------------- */
export function onMatchPassword(email, password) {
  const data = {
    email: email,
    password: password,
  };

  return axiosInstance.post("/admin/api/auth/", data);
}
/* -------------------------------------------------- edit password ------------------------------------------------- */
export function onEditPassword(oldPassword, newPassword) {
  const data = {
    oldPassword: oldPassword,
    password: newPassword,
  };

  return axiosInstance.put("/admin/api/auth/", data);
}
