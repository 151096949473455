import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import LogDetailData from "../LogDetail/LogDetailData";

//css
import "../../css/common.css";
import "../../css/payment/paymentLogDetail.css";

// utils
import { formatDateTimeDot, prettyPrint } from "../../common/Utils";

class LogDetailView extends LogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      logId: "",
    };
  }

  // 첫 실행시 URL에서 paymentId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const logId = params.get("logId");
    this.setState({
      logId: logId,
    });
    this.requestGetLogDetail(logId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let logDetail = null;

    if (this.props.logDetail != null && this.props.logDetail?.success == true) {
      logDetail = this.props.logDetail.results;
    }

    return (
      <div className="logDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="payment_detail_container">
          <div className="payment_detail_innder">
            <div className="title" data-detect="admin_payment_log_detail_title">
              Payment Detail Information
            </div>
            {/* 결제 */}
            <div className="payment_container">
              <div className="payment_title_container"></div>
              <div className="payment_info_container">
                <div className="payment_info_table">
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_id">ID</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{logDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_hotel_id">Hotel ID</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {logDetail?.hotelId??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_payment_id">Payment ID</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {logDetail?.paymentId??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_transfer_code">Transfer Code</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {logDetail?.transferCode??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_type">Type</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span> {logDetail?.type??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_method">Method</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{logDetail?.method??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_path">Path</span>
                    </div>
                    <div className="payment_info_cell content">
                      {/* <pre dangerouslySetInnerHTML={{ __html: prettyPrint(logDetail.path) || "-" }}></pre> */}
                      <span>{logDetail?.path??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_header">Header</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span className="format">{prettyPrint(logDetail?.header)}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_param">Param</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span className="format">{prettyPrint(logDetail?.param)}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_body">Body</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span className="format">{prettyPrint(logDetail?.body)}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_status">Status</span>
                    </div>{" "}
                    <div className="payment_info_cell content">
                      <span>{logDetail?.status??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_session">Session</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{logDetail?.session??"-"}</span>
                    </div>
                  </div>
                  <div className="payment_info_row">
                    <div className="payment_info_cell index">
                      <span data-detect="admin_payment_log_detail_label_created_at">CreatedAt</span>
                    </div>
                    <div className="payment_info_cell content">
                      <span>{logDetail ? formatDateTimeDot(logDetail.created_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = LogDetailData.mapStateToProps;
let mapDispatchToProps = LogDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogDetailView));
