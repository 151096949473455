const MultiLanguage = {
  version: "0.0.3",
  language: {
    en: {
      hotel_reservation_title_find_hotel: "Find Hotel",
      hotel_reservation_title_hotel_details: "Hotel Details",
      hotel_reservation_title_find_room: "Find Room",
      hotel_reservation_title_guest_details: "Guest Details",
      hotel_reservation_title_credit_card_info: "Credit Card Information",
      hotel_reservation_title_complete: "Your reservation has been successfully completed.",
      hotel_reservation_title_select_hotel: "Select Hotel",
      hotel_reservation_title_select_room: "Select Room<<( Infant/child 37 months to 12 years old )>>",
      hotel_reservation_title_enter_info: "Enter Information",
      hotel_reservation_title_payment_details: "Payment Details",
      hotel_reservation_title_arrival_details: "Arrival Details",
      hotel_reservation_title_room: "Room",
      hotel_reservation_title_date_of_stay: "Date of stay",
      hotel_reservation_title_number_of_people: "Number of people",
      hotel_reservation_select_select: "Select",
      hotel_reservation_select_email: "Email",
      hotel_reservation_select_membership: "Membership",
      hotel_reservation_select_direct_input: "Direct input",
      hotel_reservation_label_title: "Title",
      hotel_reservation_label_first_name: "First Name",
      hotel_reservation_label_last_name: "Last Name",
      hotel_reservation_label_alter_first_name: "First Name (Alternative)",
      hotel_reservation_label_alter_last_name: "Last Name (Alternative)",
      hotel_reservation_label_email: "Email",
      hotel_reservation_label_phone: "Phone",
      hotel_reservation_label_card_type: "Card Type",
      hotel_reservation_label_holder_name: "Card Holder Name",
      hotel_reservation_label_card_number: "Card Number",
      hotel_reservation_label_month: "Month",
      hotel_reservation_label_year: "Year",
      hotel_reservation_label_check_in: "Check-in",
      hotel_reservation_label_check_out: "Check-out",
      hotel_reservation_label_adult: "Adult",
      hotel_reservation_label_children: "Children",
      hotel_reservation_label_size: "Size",
      hotel_reservation_label_bed_type: "Bed type",
      hotel_reservation_label_guests: "Guests",
      hotel_reservation_label_view: "View",
      hotel_reservation_label_room_details: "Room Details",
      hotel_reservation_label_agree_check: "I have confirmed the booking details and agree to the <<Terms & Conditions>> and the <<Privacy Policy>> of the hotel.",
      hotel_reservation_label_ota_no: "OTA No.",
      hotel_reservation_label_confirmation_no: "Confirmation No.",
      hotel_reservation_button_ok: "OK",
      hotel_reservation_button_cancel: "Cancel",
      hotel_reservation_button_select: "SELECT",
      hotel_reservation_button_insert: "INSERT",
      hotel_reservation_button_complete: "COMPLETE RESERVATION",
      hotel_reservation_button_search: "SEARCH",
      hotel_reservation_button_change: "CHANGE",
      hotel_reservation_button_main: "Main",
      hotel_reservation_validation_select_card_type: "Please select the card type.",
      hotel_reservation_validation_enter_card_name: "Please enter the name on card.",
      hotel_reservation_validation_enter_card_number: "Please enter the card number.",
      hotel_reservation_validation_only_enter_card_number: "The card number can only be entered in numbers.",
      hotel_reservation_validation_select_card_expiration: "Please select the card expiration date.",
      hotel_reservation_validation_select_title: "Please select a title.",
      hotel_reservation_validation_enter_first_name: "Please enter your first name.",
      hotel_reservation_validation_enter_last_name: "Please enter your last name.",
      hotel_reservation_validation_email: "Please enter your email.",
      hotel_reservation_validation_invalid_email: "Invalid email address.",
      hotel_reservation_validation_enter_phone: "Please enter your mobile phone number.",
      hotel_reservation_validation_select_hotel: "Please select a hotel.",
      hotel_reservation_validation_select_check_in_date: "Please select a check-in date.",
      hotel_reservation_validation_select_check_out_date: "Please select a check-out date.",
      hotel_reservation_validation_enter_adults: "Please enter the number of adults.",
      hotel_reservation_validation_enter_children: "Please enter the number of children.",
      hotel_reservation_validation_select_room: "Please select a room.",
      hotel_reservation_validation_check_agreement: "Please check the agreement of the terms and conditions.",
      hotel_reservation_failed_get_hotel_list: "Failed to retrieve hotel list.",
      hotel_reservation_failed_get_hotel_info: "Failed to retrieve hotel information.",
      hotel_reservation_failed_get_room_list: "Failed to retrieve room list.",
      hotel_reservation_failed_get_membership_type: "Failed to retrieve membership type.",
      hotel_reservation_failed_get_title: "Failed to retrieve title.",
      hotel_reservation_failed_get_card_type: "Failed to retrieve card type.",
      hotel_reservation_failed_email_missing: "Email address does not exist.",
      hotel_reservation_failed_membership_missing: "Membership No. does not exist.",
      hotel_reservation_failed_get_terms_conditions: "Failed to retrieve terms and conditions.",
      hotel_reservation_failed_get_privacy_policy: "Failed to retrieve privacy policy.",
      hotel_reservation_failed_reservation: "Reservation has failed.",
      hotel_reservation_empty_room: "Cannot find a room.",
    },
    ko: {
      hotel_reservation_title_find_hotel: "호텔 검색",
      hotel_reservation_title_hotel_details: "호텔 세부 정보",
      hotel_reservation_title_find_room: "객실 검색",
      hotel_reservation_title_guest_details: "고객 세부 정보",
      hotel_reservation_title_credit_card_info: "신용 카드 정보",
      hotel_reservation_title_complete: "예약이 성공적으로 완료되었습니다.",
      hotel_reservation_title_select_hotel: "호텔 선택",
      hotel_reservation_title_select_room: "객실 선택 <<( 유아/어린이 37개월 ~ 12세 )>>",
      hotel_reservation_title_enter_info: "정보 입력",
      hotel_reservation_title_payment_details: "결제 세부 정보",
      hotel_reservation_title_arrival_details: "도착 세부 정보",
      hotel_reservation_title_room: "객실",
      hotel_reservation_title_date_of_stay: "숙박 날짜",
      hotel_reservation_title_number_of_people: "인원 수",
      hotel_reservation_select_select: "선택",
      hotel_reservation_select_email: "이메일",
      hotel_reservation_select_membership: "멤버십",
      hotel_reservation_select_direct_input: "직접입력",
      hotel_reservation_label_title: "호칭",
      hotel_reservation_label_first_name: "이름",
      hotel_reservation_label_last_name: "성",
      hotel_reservation_label_alter_first_name: "한글 성",
      hotel_reservation_label_alter_last_name: "한글 이름",
      hotel_reservation_label_email: "이메일",
      hotel_reservation_label_phone: "전화번호",
      hotel_reservation_label_card_type: "카드 타입",
      hotel_reservation_label_holder_name: "카드 명의",
      hotel_reservation_label_card_number: "카드 번호",
      hotel_reservation_label_month: "월",
      hotel_reservation_label_year: "년",
      hotel_reservation_label_check_in: "체크인",
      hotel_reservation_label_check_out: "체크아웃",
      hotel_reservation_label_adult: "성인",
      hotel_reservation_label_children: "유아/어린이",
      hotel_reservation_label_size: "크기",
      hotel_reservation_label_bed_type: "침대 타입",
      hotel_reservation_label_guests: "고객",
      hotel_reservation_label_view: "전망",
      hotel_reservation_label_room_details: "객실 세부 정보",
      hotel_reservation_label_agree_check: "예약 내용을 확인했으며 호텔의 <<이용 약관>> 및 <<개인 정보 보호 정책>>에 동의합니다.",
      hotel_reservation_label_ota_no: "OTA 번호",
      hotel_reservation_label_confirmation_no: "예약 번호",
      hotel_reservation_button_ok: "확인",
      hotel_reservation_button_cancel: "취소",
      hotel_reservation_button_select: "선택",
      hotel_reservation_button_insert: "등록",
      hotel_reservation_button_complete: "예약 완료",
      hotel_reservation_button_search: "검색",
      hotel_reservation_button_change: "변경",
      hotel_reservation_button_main: "메인",
      hotel_reservation_validation_select_card_type: "카드 타입을 선택하십시오.",
      hotel_reservation_validation_enter_card_name: "카드 명의를 입력하십시오.",
      hotel_reservation_validation_enter_card_number: "카드 번호를 입력하십시오.",
      hotel_reservation_validation_only_enter_card_number: "카드 번호는 숫자로만 입력할 수 있습니다.",
      hotel_reservation_validation_select_card_expiration: "카드 유효기간을 선택하십시오.",
      hotel_reservation_validation_select_title: "호칭을 선택하십시오.",
      hotel_reservation_validation_enter_first_name: "이름을 입력하십시오.",
      hotel_reservation_validation_enter_last_name: "성을 입력하십시오.",
      hotel_reservation_validation_email: "이메일을 입력하십시오.",
      hotel_reservation_validation_invalid_email: "잘못된 이메일 주소입니다.",
      hotel_reservation_validation_enter_phone: "휴대폰 번호를 입력하십시오.",
      hotel_reservation_validation_select_hotel: "호텔을 선택하십시오.",
      hotel_reservation_validation_select_check_in_date: "체크인 날짜를 선택하십시오.",
      hotel_reservation_validation_select_check_out_date: "체크아웃 날짜를 선택하십시오.",
      hotel_reservation_validation_enter_adults: "성인 수를 입력하십시오.",
      hotel_reservation_validation_enter_children: "유아/어린이 수를 입력하십시오.",
      hotel_reservation_validation_select_room: "객실을 선택하십시오.",
      hotel_reservation_validation_check_agreement: "약관 동의 여부를 확인해 주세요.",
      hotel_reservation_failed_get_hotel_list: "호텔 목록을 가져오지 못했습니다.",
      hotel_reservation_failed_get_hotel_info: "호텔 정보를 가져오지 못했습니다.",
      hotel_reservation_failed_get_room_list: "객실 목록을 가져오지 못했습니다.",
      hotel_reservation_failed_get_membership_type: "멤버십 타입을 가져오지 못했습니다.",
      hotel_reservation_failed_get_title: "호칭을 가져오지 못했습니다.",
      hotel_reservation_failed_get_card_type: "카드 유형을 가져오지 못했습니다.",
      hotel_reservation_failed_email_missing: "이메일이 존재하지 않습니다.",
      hotel_reservation_failed_membership_missing: "멤버십 번호가 존재하지 않습니다.",
      hotel_reservation_failed_get_terms_conditions: "이용 약관을 가져오지 못했습니다.",
      hotel_reservation_failed_get_privacy_policy: "개인 정보 보호 정책을 가져오지 못했습니다.",
      hotel_reservation_failed_reservation: "예약에 실패했습니다.",
      hotel_reservation_empty_room: "객실을 찾을 수 없습니다.",
    },
  },
  date_format: {
    en: {
      date_format_slash_time_date: {
        date_format: "hh:mm a MM/dd/yy",
        language_code: "en",
      },
      date_format_slash_date: {
        date_format: "MM/dd/yy",
        language_code: "en",
      },
      date_format_hyphen_date: {
        date_format: "yyyy-MM-dd",
        language_code: "en",
      },
      date_format_dot_month_year: {
        date_format: "MMM.yyyy",
        language_code: "en",
      },
      date_format_dot_year_month: {
        date_format: "yyyy.MM",
        language_code: "en",
      },
      date_format_slash_year_month: {
        date_format: "MM/yy",
        language_code: "en",
      },
      date_format_ampm_time: {
        date_format: "a hh:mm",
        language_code: "en",
      },
      date_format_time_ampm: {
        date_format: "hh:mm a",
        language_code: "en",
      },
      date_format_24_hours_time: {
        date_format: "HH:mm",
        language_code: "en",
      },
      date_format_hours_ampm: {
        date_format: "ha",
        language_code: "en",
      },
      date_format_full_year: {
        date_format: "yyyy",
        language_code: "en",
      },
      date_format_word_month: {
        date_format: "MMM",
        language_code: "en",
      },
      date_format_full_day: {
        date_format: "dd",
        language_code: "en",
      },
      date_format_short_day: {
        date_format: "d",
        language_code: "en",
      },
      date_format_day_of_week: {
        date_format: "E",
        language_code: "en",
      },
      date_format_ampm: {
        date_format: "a",
        language_code: "en",
      },
      date_format_day_of_week_full: {
        date_format: "EEEE",
        language_code: "en",
      },
      date_format_dot_month_day: {
        date_format: "MM.dd",
        language_code: "en",
      },
      date_format_day_of_week_date: {
        date_format: "E. MMM. dd, yyyy",
        language_code: "en",
      },
      date_format_slash_date_time: {
        date_format: "MM/dd/yy hh:mm a",
        language_code: "en",
      },
      date_format_slash_month_day_time: {
        date_format: "MM/dd hh:mm a",
        language_code: "en",
      },
      date_format_dot_date_time_full: {
        date_format: "yyyy.MM.dd hh:mm a",
        language_code: "en",
      },
      date_format_dot_year_month_day: {
        date_format: "MM.dd.yy",
        language_code: "en",
      },
      date_format_slash_full_year_date: {
        date_format: "yyyy/MM/dd",
        language_code: "en",
      },
      date_format_space_date: {
        date_format: "dd MMM yyyy",
        language_code: "en",
      },
      date_format_create_at_time_full: {
        date_format: "yyyy.MM.dd\nhh:mm a",
        language_code: "en",
      },
    },
    ko: {
      date_format_slash_time_date: {
        date_format: "yy/MM/dd a hh:mm",
        language_code: "ko",
      },
      date_format_slash_date: {
        date_format: "yy/MM/dd",
        language_code: "ko",
      },
      date_format_hyphen_date: {
        date_format: "yyyy-MM-dd",
        language_code: "ko",
      },
      date_format_dot_month_year: {
        date_format: "yyyy.MMM",
        language_code: "ko",
      },
      date_format_dot_year_month: {
        date_format: "yyyy.MM",
        language_code: "ko",
      },
      date_format_slash_year_month: {
        date_format: "yy/MM",
        language_code: "ko",
      },
      date_format_ampm_time: {
        date_format: "a hh:mm",
        language_code: "ko",
      },
      date_format_time_ampm: {
        date_format: "a hh:mm",
        language_code: "ko",
      },
      date_format_24_hours_time: {
        date_format: "HH:mm",
        language_code: "ko",
      },
      date_format_hours_ampm: {
        date_format: "ha",
        language_code: "ko",
      },
      date_format_full_year: {
        date_format: "yyyy",
        language_code: "ko",
      },
      date_format_word_month: {
        date_format: "MMM",
        language_code: "ko",
      },
      date_format_full_day: {
        date_format: "dd",
        language_code: "ko",
      },
      date_format_short_day: {
        date_format: "d",
        language_code: "ko",
      },
      date_format_day_of_week: {
        date_format: "E",
        language_code: "ko",
      },
      date_format_ampm: {
        date_format: "a",
        language_code: "ko",
      },
      date_format_day_of_week_full: {
        date_format: "EEEE",
        language_code: "ko",
      },
      date_format_dot_month_day: {
        date_format: "MM.dd",
        language_code: "ko",
      },
      date_format_day_of_week_date: {
        date_format: "yyyy'년' MM'월' dd'일' E",
        language_code: "ko",
      },
      date_format_slash_date_time: {
        date_format: "yy/MM/dd a hh:mm",
        language_code: "ko",
      },
      date_format_slash_month_day_time: {
        date_format: "MM/dd a hh:mm",
        language_code: "ko",
      },
      date_format_dot_date_time_full: {
        date_format: "yyyy.MM.dd a hh:mm",
        language_code: "ko",
      },
      date_format_dot_year_month_day: {
        date_format: "yy.MM.dd",
        language_code: "ko",
      },
      date_format_slash_full_year_date: {
        date_format: "yyyy/MM/dd",
        language_code: "ko",
      },
      date_format_space_date: {
        date_format: "yyyy'년' MM'월' dd'일'",
        language_code: "ko",
      },
      date_format_create_at_time_full: {
        date_format: "yyyy.MM.dd\na hh:mm",
        language_code: "ko",
      },
    },
  },
};
export default MultiLanguage;
