import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as appApis from "../lib/app_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const APP_GET_ALL = "app/APP_GET_ALL";
const APP_GET_ONE = "app/APP_GET_ONE";
const DELETE_APP = "app/DELETE_APP";


// 액션 생성자
export const onGetAll = createAction(APP_GET_ALL, appApis.onGetAll);
export const onGetOne = createAction(APP_GET_ONE, appApis.onGetOne);
export const onDelete = createAction(DELETE_APP, appApis.onDelete);


// 초기 상태
const initialState = {
  appList: [],
  appDetail: null,
  successDelete: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: APP_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, appList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, appList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APP_GET_ONE,
      onPending: (state, action) => {
        return { ...state, appDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, appDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_APP,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
