import React, { Component } from 'react';

class InputTextAreaBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
        };
    }

    //test
    onInputValueChange = (e) => {
        this.setState({ inputValue: e.target.value }, this.onCheckValidation);
    };


    // check validation
    onCheckValidation = () => {
        const { inputValue } = this.state;

        if (inputValue == 'errorTest') {
            <div className='myConecttextAreaBox'>
                <div className={`myConect_input_textArea_box ${'inputError'}`}>
                    <input type="textarea" className="errorInput" />
                </div>
            </div>
        } else {
            return null;
        }
    };

    render() {
        const { disabled } = this.props;
        const { inputValue } = this.state;
        const isError = inputValue == 'errorTest';

        return (
            <div className='myConectTextAreaBox'>
                <div className={`myConect_input_textArea_box ${disabled ? 'disabled' : ''} ${isError ? 'inputError' : ''}`} disabled={disabled}>
                    <textarea
                        value={inputValue}
                        onChange={this.onInputValueChange}
                        placeholder='Value'
                        readOnly={disabled}
                    />
                </div>
            </div>
        );
    }
}

export default InputTextAreaBox;
