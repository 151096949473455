import axiosTransferInstance from "../api/transferAxios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllTransferLogList(pageNo, userId, hotelId, search, startDate, endDate) {
  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get(
    "/admin/api/transferLog/?content=" + search + "&userId=" + userId + "&hotelId=" + hotelId + "&offset=" + offset + "&limit=" + 20 + "&fromDate=" + startDate + "&endDate=" + endDate
  );
}

//------------------------------- get One ------------------------------------------------------//
export function onGetTransferLogDetail(transferLogId) {
  return axiosTransferInstance.get("/admin/api/transferLog/" + transferLogId);
}

//------------------------------- get user List ------------------------------------------------------//
export function onGetAllUserTransferLogList(pageNo, email, search, startDate, endDate) {
  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get("/api/userTransferLog/?email=" + email + "&content=" + search + "&offset=" + offset + "&limit=" + 20 + "&fromDate=" + startDate + "&endDate=" + endDate);
}

//------------------------------- get user transfer log One ------------------------------------------------------//
export function onGetUserTransferLogDetail(userTransferLogId) {
  return axiosTransferInstance.get("/api/userTransferLog/" + userTransferLogId);
}

//------------------------------- get transaction List ------------------------------------------------------//
export function onGetAllTransactionLogList(pageNo, movementStatus, search, startDate, endDate) {
  let getListUrl;

  const offset = (pageNo - 1) * 10;

  if (movementStatus == "none") {
    getListUrl = "/admin/api/transactionLog/notComplete?content=" + search + "&offset=" + offset + "&limit=20" + "&fromDate=" + startDate + "&endDate= " + endDate;
  } else {
    getListUrl =
      "/admin/api/transactionLog/searchList?content=" + search + "&completeAction=" + movementStatus + "&offset=" + offset + "&limit=" + 20 + "&fromDate=" + startDate + "&endDate=" + endDate;
  }

  return axiosTransferInstance.get(getListUrl);
}

//------------------------------- get user transfer log One ------------------------------------------------------//
export function onGetTransactionLogDetail(transactionId) {
  return axiosTransferInstance.get("/admin/api/transactionLog/" + transactionId);
}

//------------------------------- get user transaction List ------------------------------------------------------//
export function onGetAllUserTransferLogHistoryList(pageNo, email, search, startDate, endDate) {
  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get("/api/userTransferLog/orderByCode?email=" + email + "&content=" + search + "&offset=" + offset + "&limit=" + 20 + "&fromDate=" + startDate + "&endDate=" + endDate);
}

//------------------------------- get user transfer log One ------------------------------------------------------//
export function onGetUserTransferLogHistoryDetail(userTransferCode) {
  return axiosTransferInstance.get("/api/userTransferLog/code/" + userTransferCode);
}

//------------------------------- get staff transaction List ------------------------------------------------------//
export function onGetAllStaffTransferLogHistoryList(pageNo, userId, hotelId, search, startDate, endDate) {
  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get(
    "/admin/api/transferLog/orderByCode?content=" + search + "&userId=" + userId + "&hotelId=" + hotelId + "&offset=" + offset + "&limit=" + 20 + "&fromDate=" + startDate + "&endDate=" + endDate
  );
}

//------------------------------- get staff transfer log One ------------------------------------------------------//
export function onGetStaffTransferLogHistoryDetail(staffTransferCode) {
  return axiosTransferInstance.get("/admin/api/transferLog/code/" + staffTransferCode);
}

//------------------------------- get user notification all list ------------------------------------------------------//
export function onGetAllUserNotificationLogList(pageNo, email, search, startDate, endDate) {
  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get("/admin/api/notification/user?content=" + search + "&offset=" + offset + "&limit=" + 20 + "&email=" + email + "&fromDate=" + startDate + "&endDate=" + endDate);
}

//------------------------------- get user notification One ------------------------------------------------------//
export function onGetUserNotificationLogDetail(userNotificationLogId) {
  return axiosTransferInstance.get("/admin/api/notification/user/" + userNotificationLogId);
}

//------------------------------- get staff notification all list ------------------------------------------------------//
export function onGetAllStaffNotificationLogList(pageNo, email, search, startDate, endDate) {
  const offset = (pageNo - 1) * 10;

  return axiosTransferInstance.get("/admin/api/notification/staff/?content=" + search + "&offset=" + offset + "&limit=" + 20 + "&email=" + email + "&fromDate=" + startDate + "&endDate=" + endDate);
}

//------------------------------- get user notification One ------------------------------------------------------//
export function onGetStaffNotificationLogDetail(staffNotificationLogId) {
  return axiosTransferInstance.get("/admin/api/notification/staff/" + staffNotificationLogId);
}
