import React from "react";
import CommonComponent from "../../../components/CommonComponent";



// CSS
import "../../../css/common.css";
import "../../../css/emailForm/forgotPassword/forgotPasswordCharacter.css";

class ForgotPasswordCharacterView extends CommonComponent {
  constructor(props) {
    super(props);
  }

  /* ---------------------------------------------------- Lifecycle --------------------------------------------------- */
  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  /* ----------------------------------------------------- Render ----------------------------------------------------- */
  render() {
    return (
      <div className="forgotPasswordCharacter">
        <div className="forgot_password_character_container">
          <div className="forgot_password_character_container_inner">
            <div className="title" data-detect="admin_email_form_forgot_password_detail_title">
              FORGOT PASSWORD CHARACTER
            </div>
            <div className="forgot_password_text_box table">
              <div className="row">
                <div className="forgot_password_text confirmation_number cell">
                  <div className="forgot_password_title row" data-detect="admin_email_form_forgot_password_detail_label_email">
                    E-mail
                  </div>
                  <div className="forgot_password_character row">{`{{ email }}`}</div>
                </div>
                <div className="forgot_password_text confirmation_number cell">
                  <div className="forgot_password_title row" data-detect="admin_email_form_forgot_password_detail_label_password">
                    Password
                  </div>
                  <div className="forgot_password_character row">{`{{ password }}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordCharacterView;
