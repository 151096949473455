import CommonComponent from "../../components/CommonComponent";

// modules
import * as amenityActions from "../../../redux/modules/Amenity";

class AmenityListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      amenityList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.amenityList != this.props.amenityList) {
      if (this.state.requestType == 'GET_AMENITY_LIST') {
        this.onGetAmenityListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_AMENITY_ONE") {
      this.onDeleteAmenityListResults(nextProps);
    }
  }

  // redux에 get amenity list 요청
  requestGetAmenityList = (pageNo, search) => {
    this.setState({
      requestType: "GET_AMENITY_LIST",
    });
    this.props.onGetAll(pageNo, search);
  };
  // redux에 순차적으로 delete amenity 요청
  requestDeleteAmenity = (amenityId) => {
    this.setState({
      requestType: "DELETE_AMENITY_ONE",
    });
    this.props.onDeleteOne(amenityId);
  };

  // get amenity list results
  onGetAmenityListResults = (props) => {
    if (props.amenityList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.amenityList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // onDeleteAmenityListResults 함수 수정
  onDeleteAmenityListResults(props) {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteAmenity();
        } else {
          // 삭제 완료
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetAmenityList(this.state.pageNo, this.state.search);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      amenityList: state.amenity.amenityList,
      failData: state.amenity.failData,
      successDelete: state.amenity.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, search) => dispatch(amenityActions.onGetAllAmenity(pageNo, search)),
      onDeleteOne: (amenityId) => dispatch(amenityActions.onDeleteAmenity(amenityId)),
    };

    return returnProps;
  };
}

export default AmenityListData;
