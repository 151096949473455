// ApplicationVersionListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import ParkingStaffApplicationVersionListData from "../List/parkingStaffApplicationVersionListData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/version/parkingStaffApplicationVersion/parkingStaffApplicationVersionList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class parkingStaffApplicationVersionListView extends ParkingStaffApplicationVersionListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      checkedList: [],
    };
  }

  componentDidMount() {
    this.requestGetActiveList(); //active list 호출
    this.requestGetParkingStaffApplicationVersionList(this.state.pageNo, this.state.search); //Application List 호출
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      search: inputValue,
    });
  };

  onClickSearch = (e) => {
    this.setState({
      isShowAllLoading: true,
    });

    this.requestGetParkingStaffApplicationVersionList(1, this.state.search);
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const inputValue = e.target.value;
    if (window.event.keyCode == 13) {
      this.setState({
        isShowAllLoading: true,
      });

      this.requestGetParkingStaffApplicationVersionList(1, this.state.search);
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.setState({ pageNo: pageNo }, () => {
      this.onPageChange(pageNo); // 페이지 변경 처리
      this.requestGetParkingStaffApplicationVersionList(pageNo, this.state.search); // 호텔 목록 요청
    });
  };

  //insert
  linkParkingStaffApplicationVersionAdd = () => {
    this.props.history.push("/parkingStaffApplicationVersionAddPage");
  };

  //download Applicaiton
  downloadApplication = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true);
    link.click();
  };

  render() {
    let count;
    let parkingStaffActiveList = [];
    let parkingStaffApplicationVersionList = [];

    if (this.props.parkingStaffApplicationVersionList.length !== 0 && this.props.parkingStaffApplicationVersionList?.success == true) {
      count = this.props.parkingStaffApplicationVersionList.results.count;
      parkingStaffApplicationVersionList = this.props.parkingStaffApplicationVersionList.results.rows;
    }
    if (this.props.parkingStaffActiveList.length !== 0 && this.props.parkingStaffActiveList?.success == true) {
      parkingStaffActiveList = this.props.parkingStaffActiveList.results;
    }

    return (
      <div className="parkingStaffApplicationVersionList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="version_list_container">
          <div className="applicationVersion_list_container_inner">
            <div className="title" data-detect="admin_version_parking_staff_app_version_list_title">
              PARKING STAFF APPLICATION VERSION LIST
            </div>
            {/* active_version_list_table */}
            <div className="active_version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(parkingStaffActiveList, this.state.pageNo)}
                        checked={parkingStaffActiveList && this.state.checkedList.length == parkingStaffActiveList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_os">
                      OS
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_version">
                      Version
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_link">
                      Link
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {parkingStaffActiveList?.length > 0 ? (parkingStaffActiveList.map((parkingStaffActiveList, index) => (
                    <div className="row tbodyRow" key={parkingStaffActiveList.id}>
                      <div className="cell">
                        <div className="eachIndexNo blind">
                          <span>{index + 1}</span>
                        </div>
                        <input
                          type="checkbox"
                          name="eachCheck"
                          key={parkingStaffActiveList.id}
                          id={parkingStaffActiveList.id}
                          onClick={() => this.onClickInput(parkingStaffActiveList.id, parkingStaffActiveList)}
                          checked={this.state.checkedList.includes(parkingStaffActiveList.id)}
                          readOnly
                        />
                        <label className="tbodyLabel" htmlFor={parkingStaffActiveList.id}></label>
                      </div>
                      <div className="cell ellipsis">{parkingStaffActiveList.os}</div>
                      <div className="cell">{parkingStaffActiveList.version}</div>
                      <div className="cell ellipsis" title={parkingStaffActiveList.link}>
                        {parkingStaffActiveList.link}
                      </div>
                      <div className="cell"> {formatDateTimeSlash(parkingStaffActiveList.created_at)} </div>
                    </div>
                  ))) : (
                    <div className="no-data-in-list" data-detect="admin_version_staff_app_version_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
            </div>
            <div className="btn_container_left">
              <button
                className="delete_btn"
                id="delete_btn"
                type="button"
                onClick={() => {
                  this.deleteStaffSelectedApplicationVersions();
                }}
              >
                <img src={deleteIcon} alt="delete button" />
                <span data-detect="admin_version_staff_app_version_list_button_delete">Delete</span>
              </button>
              <button
                className="add_btn"
                id="add_btn"
                type="button"
                onClick={() => {
                  this.linkParkingStaffApplicationVersionAdd();
                }}
              >
                <img src={addIcon} alt="add button" />
                <span data-detect="admin_version_staff_app_version_list_button_add">Add</span>
              </button>
            </div>

            {/* inactive_version_list_table */}
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_version_staff_app_version_list_placeholiser_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.search}
                      onChange={this.onChangeSearchText}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="inactive_version_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_version_parking_staff_app_version_list_label_no">
                      No.
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_os">
                      OS
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_version">
                      Version
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_link">
                      Link
                    </div>
                    <div className="cell" data-detect="admin_version_parking_staff_app_version_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {parkingStaffApplicationVersionList?.length > 0 ? (parkingStaffApplicationVersionList.map((parkingStaffAppVersionList, index) => (
                    <div className={`row tbodyRow ${parkingStaffAppVersionList.id}`} key={index}>
                      <div className="cell">
                        <div className="eachIndexNo">
                          <span>{index}</span>
                        </div>
                      </div>
                      <div className="cell ellipsis">{parkingStaffAppVersionList.os}</div>
                      <div className="cell">{parkingStaffAppVersionList.version}</div>
                      <div className="cell ellipsis" title={parkingStaffAppVersionList.link}>
                        {parkingStaffAppVersionList.link}
                      </div>
                      <div className="cell">{formatDateTimeSlash(parkingStaffAppVersionList.created_at)} </div>
                    </div>
                  ))): (
                    <div className="no-data-in-list" data-detect="admin_version_staff_app_version_list_guide_empty">List is empty</div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ParkingStaffApplicationVersionListData.mapStateToProps;
let mapDispatchToProps = ParkingStaffApplicationVersionListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(parkingStaffApplicationVersionListView));
