import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as hotelApis from "../lib/hotel_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const HOTEL_GET_ALL = "hotel/HOTEL_GET_ALL";
const HOTEL_GET_ONE = "hotel/HOTEL_GET_ONE";
const ADD_HOTEL = "hotel/ADD_HOTEL";
const DELETE_HOTEL = "hotel/DELETE_HOTEL";
const EDIT_HOTEL = "hotel/EDIT_HOTEL";
const EDIT_HOTEL_IS_SHOW = "hotel/EDIT_HOTEL_IS_SHOW";
const COPY_HOTEL = "hotel/COPY_HOTEL";
const GET_REGION = "hotel/GET_REGION";
const GET_COUNTRY = "hotel/GET_COUNTRY";
const GET_CITY = "hotel/GET_CITY";
const GET_COUNTRY_NAME = "hotel/GET_COUNTRY_NAME";
const EDIT_COUNTRY_NAME = "hotel/EDIT_COUNTRY_NAME";
const GET_PAYMENT_SETTING = "hotel/GET_PAYMENT_SETTING";
const EDIT_PAYMENT_SETTING = "hotel/EDIT_PAYMENT_SETTING";

// 액션 생성자
export const onGetAll = createAction(HOTEL_GET_ALL, hotelApis.onGetAll);
export const onGetOne = createAction(HOTEL_GET_ONE, hotelApis.onGetOne);
export const onAdd = createAction(ADD_HOTEL, hotelApis.onAdd);
export const onDelete = createAction(DELETE_HOTEL, hotelApis.onDelete);
export const onEdit = createAction(EDIT_HOTEL, hotelApis.onEdit);
export const onEditIsShow = createAction(EDIT_HOTEL_IS_SHOW, hotelApis.onEditIsShow);
export const onCopy = createAction(COPY_HOTEL, hotelApis.onCopy);
export const onGetRegion = createAction(GET_REGION, hotelApis.onGetRegion);
export const onGetCountry = createAction(GET_COUNTRY, hotelApis.onGetCountry);
export const onGetCity = createAction(GET_CITY, hotelApis.onGetCity);
export const onGetCountryName = createAction(GET_COUNTRY_NAME, hotelApis.onGetCountryName);
export const onEditCountryName = createAction(EDIT_COUNTRY_NAME, hotelApis.onEditCountryName);
export const onGetPaymentSetting = createAction(GET_PAYMENT_SETTING, hotelApis.onGetPaymentSetting);
export const onEditPaymentSetting = createAction(EDIT_PAYMENT_SETTING, hotelApis.onEditPaymentSetting);

// 초기 상태
const initialState = {
  hotelList: [],
  hotelDetail: null,
  hotelAdd: null,
  hotelEdit: null,
  hotelIsShowEdit: null,
  hotelCopy: null,
  successDelete: null,
  regionData: [],
  countryData: [],
  cityData: [],
  countryNameData: [],
  countryNameEdit: null,
  paymentSetting: null,
  paymentSettingEdit: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: HOTEL_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, hotelList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: HOTEL_GET_ONE,
      onPending: (state, action) => {
        return { ...state, hotelDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_HOTEL,
      onPending: (state, action) => {
        return { ...state, hotelAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_HOTEL,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_HOTEL,
      onPending: (state, action) => {
        return { ...state, hotelEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_HOTEL_IS_SHOW,
      onPending: (state, action) => {
        return { ...state, hotelIsShowEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelIsShowEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: COPY_HOTEL,
      onPending: (state, action) => {
        return { ...state, hotelCopy: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelCopy: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_REGION,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, regionData: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, regionData: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_COUNTRY,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryData: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, countryData: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_CITY,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, cityData: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, cityData: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_COUNTRY_NAME,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryNameData: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, countryNameData: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_COUNTRY_NAME,
      onPending: (state, action) => {
        return { ...state, countryNameEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, countryNameEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_PAYMENT_SETTING,
      onPending: (state, action) => {
        return { ...state, paymentSetting: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentSetting: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_PAYMENT_SETTING,
      onPending: (state, action) => {
        return { ...state, paymentSettingEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, paymentSettingEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
