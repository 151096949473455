import React from "react";
import { connect } from "react-redux";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ru-RU"; // you can import any other locale
import { Loading } from "../../../components/Paths";
import CreateEmailConfirmFailureEditData from "../Edit/CreateEmailConfirmFailureEditData";

// Import bootstrap(v3 or v4) dependencies
import "bootstrap/js/dropdown";
import "bootstrap/js/modal";
import "bootstrap/js/tooltip";

//css
import "../../../css/common.css";
import "../../../css/emailForm/createEmailConfirmFailure/createEmailConfirmFailureEdit.css";
import "../../../css/summernote/summernote-lite.min.css";

// icon
import lightbulbIcon from "../../../icon/lightbulb.svg";

// jQuery
import $ from "jquery";

// language
import { LANGUAGE_CODE_LIST } from '../../../components/common/Language';

class CreateEmailConfirmFailureEditView extends CreateEmailConfirmFailureEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      about: "",
      languageCode: [], //있는 코드
      createEmailConfirmFailureFormResult: null,
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.requestGetEmailFailureForm();
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // summernote image 업로드할 때
  onUploadImage = (images, insertImage) => {
    for (let i = 0; i < images.length; i++) {
      const reader = new FileReader();

      reader.onloadend = () => {
        insertImage(reader.result);
      };

      reader.readAsDataURL(images[i]);
    }
  };

  makeLanguageOption = (defaultLanguage) => {
    // default 언어 코드와 아직 선택되지 않은 언어 코드들을 옵션 리스트로 반환
    const selectedLanguageCodes = this.state.createEmailConfirmFailureFormResult.emailFormTranslation.map((translation) => translation.languageCode);
    const unusedLanguageCodes = LANGUAGE_CODE_LIST.filter((code) => !selectedLanguageCodes.includes(code) || code == defaultLanguage);

    return unusedLanguageCodes.length > 0 && unusedLanguageCodes.map(code => {
      if (code == 'ko') {
        code = 'kr';
      }
      return (
        <option value={code} data-detect={"admin_email_form_create_confirm_failure_edit_select_" + code}>
          {code}
        </option>
      )
    })
  };

  onCheckValidation = () => {
    //check validation
    const contents = this.state.createEmailConfirmFailureFormResult.emailFormTranslation.map((item) => item.content);
    const isEmpty = contents.some((content) => !content || content == "" || content == "<div></div>");

    if (isEmpty) {
      this.setState({
        errorMessage: "admin_email_form_create_confirm_failure_edit_validation_content",
        isCheckValidation: false,
      });
    } else {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    }
  };

  summernoteForm = (index, emailForm, languageCode) => {
    const clickButton = (context) => {
      const ui = $.summernote.ui;
      const button = ui.button({
        contents: `<img src="${lightbulbIcon}" alt='icon lightbulb' />`,
        tooltip: this.props.MultiLanguage["language"][this.props.multiLanguageCode]["admin_email_form_change_password_edit_toolbar_tip"],
        click: () => {
          window.open("/emailConfirmFailureCharacterPage", "_blank");
        },
      });
      return button.render();
    };
    return (
      <ReactSummernote
        children={<div dangerouslySetInnerHTML={{ __html: emailForm }}></div>}
        options={{
          lang: "en-EN",
          height: 600,
          minHeight: null,
          maxHeight: 1000,
          focus: true,
          lang: this.props.MultiLanguage["language"][this.props.multiLanguageCode]["common_summernote_lang"],
          placeholder: this.props.MultiLanguage["language"][this.props.multiLanguageCode]["admin_email_form_create_confirm_failure_edit_placeholder_content"],
          dialogsInBody: true,
          toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "underline", "clear"]],
            ["fontname", ["fontname"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["insert", ["link", "picture", "video"]],
            ["view", ["fullscreen", "codeview"]],
            ["mybutton", ["clickButton"]],
          ],
          buttons: {
            clickButton: clickButton,
          },
          fontNames: ["Arial", "Arial Black", "Comic Sans MS", "Courier New", "serif", "sans-serif"],
          fontSizes: ["8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "24", "32", "48"],
          lineHeights: ["0.2", "0.3", "0.4", "0.5", "0.6", "0.8", "1.0", "1.2", "1.4", "1.5", "2.0", "3.0"],
          tooltip: false, // 툴팁 비활성화
        }}
        onChange={(content) => this.editSummernote(content, index, languageCode)}
        onImageUpload={this.onUploadImage}
      />
    );
  };

  render() {
    return (
      <div className="createEmailConfirmFailureEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="create_email_confirm_failure_container">
          <div className="create_email_confirm_failure_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_email_form_create_confirm_failure_edit_title"> CREATE E-mail CONFIRM FAILURE</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="create_email_confirm_failure_contents_container">
              <div className="create_email_confirm_failure_contents_container_inner">
                <div className="create_email_confirm_failure_contents default">
                  <div className="create_email_confirm_failure_textarea">
                    {this.state.createEmailConfirmFailureFormResult != null &&
                      this.state.createEmailConfirmFailureFormResult.content != null &&
                      this.summernoteForm(-1, this.state.createEmailConfirmFailureFormResult.content)}

                    {this.state.createEmailConfirmFailureFormResult != null &&
                      this.state.createEmailConfirmFailureFormResult.emailFormTranslation.map((instance, index) => (
                        <div key={index}>
                          <div className="summernote-container">
                            <div className="summernote-top">
                              {
                                <select
                                  id={`selectedLanguage_${index}`}
                                  className="selected_language"
                                  name={`selected_language_${index}`}
                                  style={{ color: "white" }}
                                  onChange={(e) => {
                                    this.onChangeLanguageCode(e, index);
                                  }}
                                  defaultValue={instance.languageCode}
                                >
                                  {this.makeLanguageOption(instance.languageCode)}
                                </select>
                              }
                              <div className="remove_btn container">
                                <input
                                  className="remove_btn"
                                  type="button"
                                  name="remove_btn"
                                  data-detect-value="admin_email_form_create_confirm_failure_edit_button_remove"
                                  onClick={() => {
                                    this.deleteSummernote(index);
                                  }}
                                />
                              </div>
                            </div>
                            {this.summernoteForm(index, instance.content, instance.languageCode)}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="create_email_confirm_failure_append">
              <div className="append_btn_container">
                <input className="append_btn" type="button" data-detect-value="admin_email_form_create_confirm_failure_edit_button_append" onClick={this.addSummernote} />
              </div>
            </div>
            <div className="button_container">
              <div className="save_btn_container">
                <input
                  className="save_btn"
                  type="button"
                  name="save_btn"
                  data-detect-value="admin_email_form_create_confirm_failure_edit_button_save"
                  onClick={this.onSaveBtn}
                  disabled={!this.state.isCheckValidation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CreateEmailConfirmFailureEditData.mapStateToProps;
let mapDispatchToProps = CreateEmailConfirmFailureEditData.mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmailConfirmFailureEditView);
