// ApplicationTranslationAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as applicationTranslationActions from "../../../redux/modules/ApplicationTranslation";



class ApplicationTranslationAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_APPLICATIONTRANSLATION") {
      this.onAddApplicationTranslationResults(props);
    }
  }

  // redux에 add applicationTranslation 요청
  requestAddApplicationTranslation = (appType, osType, appVersion, version, translationFile, translationText) => {
    const formData = new FormData();
    3;
    formData.append("applicationType", appType);
    formData.append("osType", osType);
    formData.append("applicationVersion", appVersion);
    formData.append("version", version);
    formData.append("translationFile", translationFile);
    formData.append("translationText", translationText);

    this.setState({
      requestType: "ADD_APPLICATIONTRANSLATION",
    });
    this.props.onAdd(formData);
  };

  // get add applicationTranslation results
  onAddApplicationTranslationResults = (props) => {
    if (props.applicationTranslationAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.applicationTranslationAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_version"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/applicationTranslationListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationTranslationAdd: state.applicationTranslation.applicationTranslationAdd,
      failData: state.applicationTranslation.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (formData) => dispatch(applicationTranslationActions.onAddapplicationTranslation(formData)),
    };
  };
}

export default ApplicationTranslationAddData;
