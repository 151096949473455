import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as hotelAppDownloadApis from "../lib/hotelAppDownload_apis.js";
import { PURGE } from "redux-persist";

// 액션 타입
const GET_HOTEL_APP_DOWNLOAD_IOS = "hotelAppDownload/GET_HOTEL_APP_DOWNLOAD_IOS";
const GET_HOTEL_APP_DOWNLOAD_AOS = "hotelAppDownload/GET_HOTEL_APP_DOWNLOAD_AOS";

// 액션 생성자
export const onGetIOS = createAction(GET_HOTEL_APP_DOWNLOAD_IOS, hotelAppDownloadApis.onGetIOS);
export const onGetAOS = createAction(GET_HOTEL_APP_DOWNLOAD_AOS, hotelAppDownloadApis.onGetAOS);

// 초기 상태
const initialState = {
  hotelAppDownloadIOSList: null,
  hotelAppDownloadAOSList: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: GET_HOTEL_APP_DOWNLOAD_IOS,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelAppDownloadIOSList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_HOTEL_APP_DOWNLOAD_AOS,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelAppDownloadAOSList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
