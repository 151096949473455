import CommonComponent from "../../components/CommonComponent";

// modules
import * as appActions from "../../../redux/modules/App";

class AppListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      appList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.appList != this.props.appList) {
      if (this.state.requestType == 'GET_APP_LIST') {
        this.onGetAppListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_APP_ONE") {
      this.onDeleteAppListResults(nextProps);
    }
  }

  // redux에 get app list 요청
  requestGetAppList = (pageNo, selectedOption, search) => {
    this.setState({
      requestType: "GET_APP_LIST",
    });
    this.props.onGetAll(pageNo, selectedOption, search);
  };
  // redux에 순차적으로 delete app 요청
  requestDeleteApp = (appId) => {
    this.setState({
      requestType: "DELETE_APP_ONE",
    });
    this.props.onDeleteOne(appId);
  };

  // get app list results
  onGetAppListResults = (props) => {
    if (props.appList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.appList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // delete app results
  onDeleteAppListResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteApp();
        } else {
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    this.props.history.push("/appListPage");
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, appList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  // onClickOK
  onClickOK = () => {
    const { checkedList } = this.state;

    if (checkedList.length > 0) {
      checkedList.forEach((appId) => {
        this.requestDeleteApp(appId);
      });
      // checkedList 비우기 (모든 선택된 앱을 삭제한 후)
      this.setState({ checkedList: [] });
    }
  };

  deleteSelectedApps = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",

        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteApp();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode][
          "button_no_select_delete_message"
        ],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteApp = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      this.requestDeleteApp(checkedList[0]);
    }
  };


  //전체 체크박스 클릭시 checkedList
  allCheck = (appList) => {
    const { checkedList } = this.state;
    const allAppCnt = appList.length;
    const isChecked = allAppCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : appList.map((app) => app.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      appList: state.app.appList,
      successDelete: state.app.successDelete,
      failData: state.app.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, selectedOption, search) =>
        dispatch(appActions.onGetAll(pageNo, selectedOption, search)),
      onDeleteOne: (appId) => dispatch(appActions.onDelete(appId)),
    };
    return returnProps;
  };
}

export default AppListData;
