import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllFacilityImage(pageNo) {
    const offset = (pageNo - 1) * 10;
    return axiosInstance.get("/admin/api/v2/hotelFacilityImage?content=&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetOneFacilityImage(facilityImageId) {
    return axiosInstance.get('/admin/api/v2/hotelFacilityImage/' + facilityImageId);
}

//------------------------------- add FacilityImage ------------------------------------------------------//
export function onAddFacilityImage(formData) {
    return axiosInstance.post('/admin/api/v2/hotelFacilityImage', formData);
}
//------------------------------- delete FacilityImage ------------------------------------------------------//
export function onDeleteFacilityImage(facilityImageId) {
    return axiosInstance.delete('/admin/api/v2/hotelFacilityImage/' + facilityImageId);
}

//------------------------------- edit FacilityImage ------------------------------------------------------//
export async function onEditFacilityImage(formData, facilityImageId) {
    return axiosInstance.put("/admin/api/v2/hotelFacilityImage/" + facilityImageId, formData);
}