// UpdateProfileAddData.js
import CommonComponent from "../../../components/CommonComponent";

// module
import * as emailFormActions from "../../../../redux/modules/EmailForm";

// language
import { LANGUAGE_CODE_LIST } from '../../../components/common/Language';

class UpdateProfileEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      emailFormId: "",
      originalProfileFormTranslations: [],
      langaugeId: [],
      deletableProfileEmailFormTranslations: [],
      editableProfileEmailFormTranslations: [],
      addableProfileEmailFormTranslations: [],
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_PROFILE_FORM") {
      this.onGetCreateEmailFormResults(props);
    }
    if (this.state.requestType == "DELETE_SUMMERNOTE") {
      this.onDeleteSummernoteResults(props);
    }
    if (this.state.requestType == "ADD_SUMMERNOTE") {
      this.onAddSummernoteResults(props);
    }
    if (this.state.requestType == "EDIT_SUMMERNOTE") {
      this.onEditSummernoteResults(props);
    }
  }

  // redux에 get profile form 요청
  requestGetProfileForm = () => {
    this.setState({
      requestType: "GET_PROFILE_FORM",
      isShowAllLoading: true,
    });
    this.props.onGetProfileForm();
  };

  // redux에 순차적으로 delete Summernote 요청
  requestDeleteSummernote = (id) => {
    this.setState({
      requestType: "DELETE_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onDeleteProfileForm(id);
  };

  // redux에 순차적으로 add Summernote 요청
  requestAddSummernote = (emailFormId, content, languageCode) => {
    this.setState({
      requestType: "ADD_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onAddProfileForm(emailFormId, content, languageCode);
  };

  // redux에 순차적으로 edit Summernote 요청
  requestEditSummernote = (id, emailFormId, content, languageCode) => {
    this.setState({
      requestType: "EDIT_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onEditProfileForm(id, emailFormId, content, languageCode);
  };

  // redux에 순차적으로 edit defulat language summernote 요청
  requestEditDefaultLanguage = (id, content) => {
    this.setState({
      requestType: "EDIT_DEFAULT_LANGUAGE",
      isShowAllLoading: true,
    });
    this.props.onEditDefaultLanguage(id, content);
  };

  // get edit updateProfile results
  onGetCreateEmailFormResults = (props) => {
    if (props.profileFormList == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.profileFormList?.success == true) {
      this.onGetSuccess(props);

    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onGetSuccess = (props) => {
    const result = props.profileFormList.results;
    this.setState({
      updateProfileFormResult: result,
      originalProfileFormTranslations: result.emailFormTranslation,
      emailFormId: result.id,
    },
    () => {
      this.onCheckValidation();
    });
  };

  // onDeleteSummernoteResults 함수 수정
  onDeleteSummernoteResults(props) {
    if (props.successProfileFormDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successProfileFormDelete?.success == true) {
      const deletableArray = this.state.deletableProfileEmailFormTranslations.slice(1, this.state.deletableProfileEmailFormTranslations.length);
      this.setState(
        //업데이트
        { deletableProfileEmailFormTranslations: deletableArray },
        () => {
          if (deletableArray.length > 0) {
            this.deleteChangeSummernote(deletableArray[0].id);
          } else {
            // 삭제 완료
            this.onDeleteSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    //add api 호출
    this.addChangeSummernote();
  };

  // onAddSummernoteResults 함수
  onAddSummernoteResults(props) {
    if (props.successProfileFormAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.successProfileFormAdd?.success == true) {
      const addableArray = this.state.addableProfileEmailFormTranslations.slice(1, this.state.addableProfileEmailFormTranslations.length);
      this.setState(
        //업데이트
        { addableProfileEmailFormTranslations: addableArray },
        () => {
          if (addableArray.length > 0) {
            this.addChangeSummernote();
          } else {
            // 추가 완료
            this.onAddSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onAddSuccess = () => {
    //edit api 호출
    this.editChangeSummernote();
  };

  onEditSummernoteResults(props) {
    if (props.successProfileFormEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successProfileFormEdit?.success == true) {
      const editableArray = this.state.editableProfileEmailFormTranslations.slice(1, this.state.editableProfileEmailFormTranslations.length);
      this.setState(
        //업데이트
        { editableProfileEmailFormTranslations: editableArray },
        () => {
          if (editableArray.length > 0) {
            this.editChangeSummernote();
          } else {
            // 수정 완료
            this.onEditSuccess(props);
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onEditSuccess = (props) => {
    this.props.showTopPopupDialog(
      "OK",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_success_message"],
      () => {
        this.props.hideTopPopupDialog();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
        this.requestGetProfileForm();
      }
    );
  };

  onEditDefaultLanguageResults(props) {
    if (props.successDefaultLanguageEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });


    if (props.successDefaultLanguageEdit?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      if (props.successDefaultLanguageEdit.error == 'Validation error') {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_already_exists'], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_failed_send_reservation_email'], () => {
          this.props.hideTopPopupDialog();
        });
      }
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //save 버튼을 눌렀을때 전달
  onSaveBtn = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.onSubmit();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  // Add a new Summernote instance
  addSummernote = () => {
    const selectedLanguageCodes = this.state.updateProfileFormResult.emailFormTranslation.map((translation) => translation.languageCode);
    // 아직 선택되지 않은 언어 코드 필터링
    const unusedLanguageCodes = LANGUAGE_CODE_LIST.filter((code) => !selectedLanguageCodes.includes(code));
    if (unusedLanguageCodes.length > 0) {
      const selectedCode = unusedLanguageCodes[0];
      const newProfileFormTranslation = {
        //update data
        id: -1,
        emailFormId: this.state.emailFormId,
        languageCode: selectedCode,
        content: "",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };
      // 마지막 ID 찾기
      const updateProfileFormResult = { ...this.state.updateProfileFormResult, emailFormTranslation: [...this.state.updateProfileFormResult.emailFormTranslation, newProfileFormTranslation] };

      this.setState({ updateProfileFormResult: updateProfileFormResult }, () => {
        this.onCheckValidation();
      });
    }

    //alert 수정
    else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_all_language_code_exist"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //summernote 삭제
  deleteSummernote = (deletedIndex) => {
    const updateProfileFormResult = { ...this.state.updateProfileFormResult, emailFormTranslation: [...this.state.updateProfileFormResult.emailFormTranslation] };
    updateProfileFormResult.emailFormTranslation.splice(deletedIndex, 1);

    this.setState({ updateProfileFormResult: updateProfileFormResult }, () => {
      this.onCheckValidation();
    });
  };

  //수정된 summernote 값
  editSummernote = (content, index) => {
    const updateProfileFormResult = { ...this.state.updateProfileFormResult, emailFormTranslation: [...this.state.updateProfileFormResult.emailFormTranslation] };
    if (index == -1) {
      // 기본 언어 코드의 content를 수정
      updateProfileFormResult.content = content;
    } else {
      // 그 외 언어 코드의 content를 수정
      updateProfileFormResult.emailFormTranslation[index].content = content;
    }

    this.setState({ updateProfileFormResult: updateProfileFormResult }, () => {
      this.onCheckValidation();
    });
  };

  //저장 버튼 누르면 기존 값과 비교
  onSubmit = () => {
    // 기본언어 코드 수정 요청
    this.requestEditDefaultLanguage(this.state.updateProfileFormResult.id, this.state.updateProfileFormResult.content);

    // 나머지 언어 코드 수정 요청
    let updateEmailFormTranslation = this.state.updateProfileFormResult.emailFormTranslation; //업데이트 된 코드
    let originalProfileFormTranslations = this.state.originalProfileFormTranslations; //기존 코드

    //업데이트된 코드가 가지고있는 언어코드
    const updatedIds = updateEmailFormTranslation.map((item) => item.id);

    // 삭제 해야할 emailFormTranslation 값
    const deletableProfileEmailFormTranslations = originalProfileFormTranslations.filter((translation) => !updatedIds.includes(translation.id));

    // 추가 해야할 emailFormTranslation 값
    const addableProfileEmailFormTranslations = updateEmailFormTranslation.filter((translation) => translation.id == -1);

    // 수정할 emailFormTranslation 값
    const editableProfileEmailFormTranslations = updateEmailFormTranslation.filter((translation) => translation.id != -1);

    this.setState(
      {
        deletableProfileEmailFormTranslations: deletableProfileEmailFormTranslations,
        addableProfileEmailFormTranslations: addableProfileEmailFormTranslations,
        editableProfileEmailFormTranslations: editableProfileEmailFormTranslations,
      },
      () => {
        this.deleteChangeSummernote();
      }
    );
  };

  //삭제
  deleteChangeSummernote = () => {
    const deletableArray = this.state.deletableProfileEmailFormTranslations;
    if (deletableArray.length > 0) {
      this.requestDeleteSummernote(deletableArray[0].id);
    } else {
      //삭제할 값이 없으먼 add함수 호출
      this.addChangeSummernote();
    }
  };

  //add
  addChangeSummernote = () => {
    const addableProfileEmailFormTranslations = this.state.addableProfileEmailFormTranslations;
    if (addableProfileEmailFormTranslations.length > 0) {
      const firstItem = addableProfileEmailFormTranslations[0];
      // requestAddSummernote 함수에 첫 번째 값의 id와 content를 전달
      this.requestAddSummernote(firstItem.emailFormId, firstItem.content, firstItem.languageCode);
    } else {
      this.editChangeSummernote();
    }
  };

  //수정
  editChangeSummernote = () => {
    const editableProfileEmailFormTranslations = this.state.editableProfileEmailFormTranslations;
    if (editableProfileEmailFormTranslations.length > 0) {
      const firstItem = editableProfileEmailFormTranslations[0];
      // requestAddSummernote 함수에 첫 번째 값의 id와 content를 전달
      this.requestEditSummernote(firstItem.id, firstItem.emailFormId, firstItem.content, firstItem.languageCode);
    } else {
      this.onEditSuccess();
    }
  };

  //언어 코드 변경
  onChangeLanguageCode = (e, index) => {
    const selectedCode = e.target.value; // 셀렉트 박스에서 선택된 언어 코드

    this.state.updateProfileFormResult.emailFormTranslation[index].languageCode = selectedCode;

    this.setState({
      updateProfileFormResult: this.state.updateProfileFormResult,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      profileFormList: state.emailForm.profileFormList,
      successProfileFormDelete: state.emailForm.successProfileFormDelete,
      successProfileFormAdd: state.emailForm.successProfileFormAdd,
      successProfileFormEdit: state.emailForm.successProfileFormEdit,
      successDefaultLanguageEdit: state.emailForm.successDefaultLanguageEdit,
      failData: state.emailForm.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetProfileForm: () => dispatch(emailFormActions.onGetProfileForm()),
      onDeleteProfileForm: (id) => dispatch(emailFormActions.onDeleteProfileForm(id)),
      onAddProfileForm: (emailFormId, content, languageCode) => dispatch(emailFormActions.onAddProfileForm(emailFormId, content, languageCode)),
      onEditProfileForm: (id, emailFormId, content, languageCode) => dispatch(emailFormActions.onEditProfileForm(id, emailFormId, content, languageCode)),
      onEditDefaultLanguage: (id, content) => dispatch(emailFormActions.onEditDefaultLanguageForm(id, content)),
    };
  };
}

export default UpdateProfileEditData;
