// ForgotIdAddData.js
import CommonComponent from "../../../components/CommonComponent";

// module
import * as emailFormActions from "../../../../redux/modules/EmailForm";

// language
import { LANGUAGE_CODE_LIST } from '../../../components/common/Language';



class ForgotIdEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      emailFormId: "",
      originalEmailFormTranslation: [],
      langaugeId: [],
      deletableForgotIdFormTranslations: [],
      editableForgotIdFormTranslations: [],
      addableForgotIdFormTranslations: [],
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_FORGOT_ID_FORM") {
      this.onGetForgotIdFormResults(props);
    }
    if (this.state.requestType == "DELETE_FORGOT_ID_SUMMERNOTE") {
      this.onDeleteForgotIdFormResults(props);
    }
    if (this.state.requestType == "ADD_FORGOT_ID_SUMMERNOTE") {
      this.onAddForgotIdFormResults(props);
    }
    if (this.state.requestType == "EDIT_FORGOT_ID_SUMMERNOTE") {
      this.onEditForgotIdFormResults(props);
    }
    if (this.state.requestType == "EDIT_DEFAULT_LANGUAGE") {
      this.onEditDefaultLanguageResults(props);
    }
  }

  // redux에 get email form 요청
  requestGetIdForm = () => {
    this.setState({
      requestType: "GET_FORGOT_ID_FORM",
      isShowAllLoading: true,
    });
    this.props.onGetForgotIdForm();
  };

  // redux에 순차적으로 delete Summernote 요청
  requestDeleteSummernote = (id) => {
    this.setState({
      requestType: "DELETE_FORGOT_ID_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onDeleteForgotIdForm(id);
  };

  // redux에 순차적으로 edit Summernote 요청
  requestAddSummernote = (emailFormId, content, languageCode) => {
    this.setState({
      requestType: "ADD_FORGOT_ID_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onAddForgotIdForm(emailFormId, content, languageCode);
  };

  // redux에 순차적으로 edit Summernote 요청
  requestEditSummernote = (id, emailFormId, content, languageCode) => {
    this.setState({
      requestType: "EDIT_FORGOT_ID_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onEditForgotIdForm(id, emailFormId, content, languageCode);
  };

  // redux에 순차적으로 edit defulat language summernote 요청
  requestEditDefaultLanguage = (id, content) => {
    this.setState({
      requestType: "EDIT_DEFAULT_LANGUAGE",
      isShowAllLoading: true,
    });
    this.props.onEditDefaultLanguage(id, content);
  };

  // get edit forgotId results
  onGetForgotIdFormResults = (props) => {
    if (props.forgotIdFormList == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.forgotIdFormList?.success == true) {
      this.onGetSuccess(props);

    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onGetSuccess = (props) => {
    const result = props.forgotIdFormList.results;
    this.setState({
      forgotIdFormResult: result,
      originalEmailFormTranslation: result.emailFormTranslation,
      emailFormId: props.forgotIdFormList.results.id,
    });
  };

  // onDeleteForgotIdFormResults 함수 수정
  onDeleteForgotIdFormResults(props) {
    if (props.successForgotIdEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successForgotIdEdit?.success == true) {
      const deletableArray = this.state.deletableForgotIdFormTranslations.slice(1, this.state.deletableForgotIdFormTranslations.length);
      this.setState(
        //업데이트
        { deletableForgotIdFormTranslations: deletableArray },
        () => {
          if (deletableArray.length > 0) {
            this.deleteChangeSummernote(deletableArray[0].id);
          } else {
            // 삭제 완료
            this.onDeleteSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    //add api 호출
    this.addChangeSummernote();
  };

  // onAddForgotIdFormResults 함수
  onAddForgotIdFormResults(props) {
    if (props.successForgotIdAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.successForgotIdAdd?.success == true) {
      const addableArray = this.state.addableForgotIdFormTranslations.slice(1, this.state.addableForgotIdFormTranslations.length);
      this.setState(
        //업데이트
        { addableForgotIdFormTranslations: addableArray },
        () => {
          if (addableArray.length > 0) {
            this.addChangeSummernote();
          } else {
            // 추가 완료
            this.onAddSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onAddSuccess = () => {
    //edit api 호출
    this.editChangeSummernote();
  };

  onEditForgotIdFormResults(props) {
    if (props.successForgotIdEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successForgotIdEdit?.success == true) {
      const editableArray = this.state.editableForgotIdFormTranslations.slice(1, this.state.editableForgotIdFormTranslations.length);
      this.setState(
        //업데이트
        { editableForgotIdFormTranslations: editableArray },
        () => {
          if (editableArray.length > 0) {
            this.editChangeSummernote();
          } else {
            // 수정 완료
            this.onEditSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onEditSuccess = () => {
    this.props.showTopPopupDialog(
      "OK",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_success_message"],
      () => {
        this.props.hideTopPopupDialog();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
        this.requestGetIdForm();
      }
    );
  };

  onEditDefaultLanguageResults(props) {
    if (props.successDefaultLanguageEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });


    if (props.successDefaultLanguageEdit?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      if (props.successDefaultLanguageEdit.error == 'Validation error') {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_already_exists'], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_failed_send_reservation_email'], () => {
          this.props.hideTopPopupDialog();
        });
      }
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSaveBtn = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.onSubmit();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  // Add a new Summernote instance
  addSummernote = () => {
    const selectedLanguageCodes = this.state.forgotIdFormResult.emailFormTranslation.map((translation) => translation.languageCode);
    // 아직 선택되지 않은 언어 코드 필터링
    const unusedLanguageCodes = LANGUAGE_CODE_LIST.filter((code) => !selectedLanguageCodes.includes(code));
    if (unusedLanguageCodes.length > 0) {
      const selectedCode = unusedLanguageCodes[0];
      const newEmailFormTranslation = {
        //update data
        id: -1,
        emailFormId: this.state.emailFormId,
        languageCode: selectedCode,
        content: "",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      const forgotIdFormResult = { ...this.state.forgotIdFormResult, emailFormTranslation: [...this.state.forgotIdFormResult.emailFormTranslation, newEmailFormTranslation] };
      this.setState({ forgotIdFormResult: forgotIdFormResult }, () => {
        this.onCheckValidation();
      });
    }

    //alert 수정
    else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_all_language_code_exist"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //summernote 삭제
  deleteSummernote = (deletedIndex) => {
    const forgotIdFormResult = { ...this.state.forgotIdFormResult, emailFormTranslation: [...this.state.forgotIdFormResult.emailFormTranslation] };
    forgotIdFormResult.emailFormTranslation.splice(deletedIndex, 1);

    this.setState({ forgotIdFormResult: forgotIdFormResult }, () => {
      this.onCheckValidation();
    });
  };

  //수정된 summernote 값
  editSummernote = (content, index, languageCode) => {
    const forgotIdFormResult = { ...this.state.forgotIdFormResult, emailFormTranslation: [...this.state.forgotIdFormResult.emailFormTranslation] };
    if (index == -1) {
      // 기본 언어 코드의 content를 수정
      forgotIdFormResult.content = content;
    } else {
      // 그 외 언어 코드의 content를 수정
      forgotIdFormResult.emailFormTranslation[index].content = content;
    }

    this.setState({ forgotIdFormResult: forgotIdFormResult }, () => {
      this.onCheckValidation();
    });
  };

  //저장 버튼 누르면 기존 값과 비교
  onSubmit = () => {
    // 기본언어 코드 수정 요청
    this.requestEditDefaultLanguage(this.state.forgotIdFormResult.id, this.state.forgotIdFormResult.content);

    // 나머지 언어 코드 수정 요청
    let updateEmailFormTranslation = this.state.forgotIdFormResult.emailFormTranslation; //업데이트 된 코드
    let originalEmailFormTranslation = this.state.originalEmailFormTranslation; //기존 코드

    //업데이트된 코드가 가지고있는 언어코드
    const updatedIds = updateEmailFormTranslation.map((item) => item.id);

    // 삭제 해야할 emailFormTranslation 값
    const deletableForgotIdFormTranslations = originalEmailFormTranslation.filter((translation) => !updatedIds.includes(translation.id));

    // 추가 해야할 emailFormTranslation 값
    const addableForgotIdFormTranslations = updateEmailFormTranslation.filter((translation) => translation.id == -1);

    // 수정할 emailFormTranslation 값
    const editableForgotIdFormTranslations = updateEmailFormTranslation.filter((translation) => translation.id != -1);

    this.setState(
      {
        deletableForgotIdFormTranslations: deletableForgotIdFormTranslations,
        addableForgotIdFormTranslations: addableForgotIdFormTranslations,
        editableForgotIdFormTranslations: editableForgotIdFormTranslations,
      },
      () => {
        this.deleteChangeSummernote();
      }
    );
  };

  //삭제
  deleteChangeSummernote = () => {
    // this.setState({ isShowAllLoading: true });

    const deletableArray = this.state.deletableForgotIdFormTranslations;
    if (deletableArray.length > 0) {
      this.requestDeleteSummernote(deletableArray[0].id);
    } else {
      //삭제할 값이 없으먼 add함수 호출
      this.addChangeSummernote();
    }
  };

  //add
  addChangeSummernote = () => {
    const addableForgotIdFormTranslations = this.state.addableForgotIdFormTranslations;
    if (addableForgotIdFormTranslations.length > 0) {
      const firstItem = addableForgotIdFormTranslations[0];
      // requestAddSummernote 함수에 첫 번째 값의 id와 content를 전달
      this.requestAddSummernote(firstItem.emailFormId, firstItem.content, firstItem.languageCode);
    } else {
      this.editChangeSummernote();
    }
  };

  //수정
  editChangeSummernote = () => {
    const editableForgotIdFormTranslations = this.state.editableForgotIdFormTranslations;
    if (editableForgotIdFormTranslations.length > 0) {
      const firstItem = editableForgotIdFormTranslations[0];
      // requestAddSummernote 함수에 첫 번째 값의 id와 content를 전달
      this.requestEditSummernote(firstItem.id, firstItem.emailFormId, firstItem.content, firstItem.languageCode);
    } else {
      this.onEditSuccess();
    }
  };

  //언어 코드 변경
  onChangeLanguageCode = (e, index) => {
    this.state.forgotIdFormResult.emailFormTranslation[index].languageCode = e.target.value;

    this.setState({
      forgotIdFormResult: this.state.forgotIdFormResult,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      forgotIdFormList: state.emailForm.forgotIdFormList,
      successForgotIdEdit: state.emailForm.successForgotIdEdit,
      successForgotIdAdd: state.emailForm.successForgotIdAdd,
      successForgotIdDelete: state.emailForm.successForgotIdDelete,
      successDefaultLanguageEdit: state.emailForm.successDefaultLanguageEdit,
      failData: state.emailForm.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetForgotIdForm: () => dispatch(emailFormActions.onGetForgotIdForm()),
      onDeleteForgotIdForm: (id) => dispatch(emailFormActions.onDeleteForgotIdForm(id)),
      onAddForgotIdForm: (emailFormId, content, languageCode) => dispatch(emailFormActions.onAddForgotIdForm(emailFormId, content, languageCode)),
      onEditForgotIdForm: (id, emailFormId, content, languageCode) => dispatch(emailFormActions.onEditForgotIdForm(id, emailFormId, content, languageCode)),
      onEditDefaultLanguage: (id, content) => dispatch(emailFormActions.onEditDefaultLanguageForm(id, content)),
    };
  };
}

export default ForgotIdEditData;
