import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import PasswordEditData from "../Edit/PasswordEditData";




//css
import "../../css/common.css";
import "../../css/password/passwordEdit.css";

class PasswordEditView extends PasswordEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      oldPassword: "",
      newPassword: "",
      newConPassword: "",
      errorMessage: "",
    };
  }

  componentDidMount() {
    this.setState({ isShowAllLoading: false });
    this.onCheckValidation();
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change old password
  onChangeOldPassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ oldPassword: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change new password
  onChangeNewPassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ newPassword: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change new confirm password
  onChangeNewConPassword = (e) => {
    const inputValue = e.target.value;
    this.setState({ newConPassword: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // check validation
  onCheckValidation = () => {
    const { oldPassword, newPassword, newConPassword } = this.state;
    let errorMessage = "";

    // 정규식 패턴 정의
    const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)[\w!@#$%^&*()+={}]{8,}$/;

    const passwordIsValid = passwordRegExp.test(newPassword);

    if (newPassword !== "" && !passwordIsValid) {
      errorMessage = "영문과 숫자를 조합하여 8자 이상 입력하세요.";
    } else if (newPassword !== "" && newPassword !== newConPassword) {
      errorMessage = "비밀번호가 일치하지 않습니다.";
    }

    this.setState({ errorMessage });

    if (
      oldPassword !== "" &&
      newPassword !== "" &&
      newConPassword !== "" && 
      errorMessage == ""
    ) {
      document.querySelector(".ok_btn").removeAttribute("disabled");
    } else {
      document.querySelector(".ok_btn").setAttribute("disabled", "disabled");
    }
  };

  // edit 버튼을 눌렀을때 전달
  okBtn = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode][
        "button_delete_information_message"
      ],
      () => {
        this.setState({ isShowAllLoading: true });
        this.props.hideTopPopupDialog();
        this.requestMatchPassword();
      },
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  render() {
    return (
      <div className="passwordEdit">
        {/* 로딩 */}
        {this.state.isShowAllLoading && <Loading />}

        <div className="password_container">
          <div className="password_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="hotel_password_title">CHANGE PASSWORD</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="form_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="password_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="hotel_password_placeholder_password"
                      >
                        Password
                      </div>
                      <div className="detail_cell content hotel_no">
                        <input
                          type="password"
                          id="password"
                          value={this.state.oldPassword}
                          onChange={this.onChangeOldPassword}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="hotel_password_placeholder_new_password"
                      >
                        New Password
                      </div>
                      <div className="detail_cell content hotel_name">
                        <input
                          type="password"
                          id="newPassword"
                          value={this.state.newPassword}
                          onChange={this.onChangeNewPassword}
                        />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="hotel_password_placeholder_new_confirm_password"
                      >
                        New Confirm Password
                      </div>
                      <div className="detail_cell content hotel_name">
                        <input
                          type="password"
                          id="newConPassword"
                          value={this.state.newConPassword}
                          onChange={this.onChangeNewConPassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button
                type="button"
                className="ok_btn on"
                onClick={() => this.okBtn()}
                data-detect="hotel_password_button_save"
              ></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = PasswordEditData.mapStateToProps;
let mapDispatchToProps = PasswordEditData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PasswordEditView)
);
