import CommonComponent from "../../components/CommonComponent";

// modules
import * as authActions from "../../../redux/modules/Auth";
import * as statisctisActions from "../../../redux/modules/Statistics";

class ReservationDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
      confirmationNo: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_RESERVATION_ONE") {
      this.onGetReservationDetailResults(props);
    }
    if (this.state.requestType == "GET_PMS_SETTING_ONE") {
      this.onGetPMSSettingOneResults(props);
    }
    if (this.state.requestType == "GET_RESERVATION_INVOICE") {
      this.onGetReservationInvoiceResults(props);
    }
    if (this.state.requestType == "GET_OWNER_AGREEMENT") {
      this.onGetOwnerAgreementResults(props);
    }
  }

  /* -------------------------------------------- request get reservation list -------------------------------------------- */
  requestGetReservationOne = () => {
    const { hotelId, reservationId } = this.state;

    this.setState({
      requestType: "GET_RESERVATION_ONE",
    });

    this.props.onGetReservationOne(hotelId, reservationId);
  };

  /* --------------------------------------------- request get pms detail --------------------------------------------- */
  requestGetPMSSettingOne = () => {
    const { hotelId } = this.state;
    this.setState({
      requestType: "GET_PMS_SETTING_ONE",
    });

    this.props.onGetPMSSettingOne(hotelId);
  };

  /* ----------------------------------------- request get reservation invoice ---------------------------------------- */
  requestGetReservationInvoice = (reservationId) => {
    const { hotelId } = this.state;

    this.setState({
      requestType: "GET_RESERVATION_INVOICE",
    });

    this.props.onGetReservationInvoice(hotelId, reservationId);
  };

  /* ------------------------------------------- request get owner agreement ------------------------------------------ */
  requestGetOwnerAgreement = (confirmationNo) => {
    const { hotelId } = this.state;
    this.setState({
      requestType: "GET_OWNER_AGREEMENT",
    });

    this.props.onGetOwnerAgreement(hotelId, confirmationNo);
  };

  /* ----------------------------------------------------- Results ---------------------------------------------------- */
  // get reservation detail results
  onGetReservationDetailResults = (props) => {
    if (props.reservationDetail?.length === 0 && props.failData == null) {
      return;
    }

    if (props.reservationDetail != null || props.failData != null) {
      this.setState({
        requestType: "",
      });
      if (props.reservationDetail?.success == true) {
        this.setState({
          confirmationNo: props.reservationDetail?.reservation.confirmationNo,
        });
        this.requestGetOwnerAgreement(props.reservationDetail?.reservation.confirmationNo);
      } else if (props.failData?.code != "INVALID_TOKEN" && props.reservationDetail?.success == false) {
        const reason = props.reservationDetail.reason;
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  // get owner agreement results
  onGetOwnerAgreementResults = (props) => {
    if (props.ownerAgreement?.length === 0 && props.failData == null) {
      return;
    }

    if (props.ownerAgreement != null || props.failData != null) {
      this.setState({
        requestType: "",
      });
      if (props.ownerAgreement?.success == true) {
        this.requestGetPMSSettingOne();
      } else if (props.failData?.code != "INVALID_TOKEN" && props.ownerAgreement?.success == false) {
        const reason = props.ownerAgreement.reason;
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  // get PMS detail results
  onGetPMSSettingOneResults = (props) => {
    if (props.pmsSettingDetail?.length === 0 && props.failData == null) {
      return;
    }
    if (props.pmsSettingDetail != null || props.failData != null) {
      this.setState({
        requestType: "",
      });
      if (props.pmsSettingDetail?.success == true) {
        this.requestGetReservationInvoice(props.reservationDetail.reservation?.reservationId);
      } else if (props.failData?.code != "INVALID_TOKEN" && props.pmsSettingDetail?.success == false) {
        const reason = props.pmsSettingDetail.reason;
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  // get reservation invoice results
  onGetReservationInvoiceResults = (props) => {
    if (props.reservationInvoice?.length === 0 && props.failData == null) {
      return;
    }

    if (props.reservationInvoice != null || props.failData != null) {
      this.setState({
        isShowAllLoading: false,
        requestType: "",
      });
      if (props.reservationInvoice?.success == true) {
      } else if (props.failData?.code != "INVALID_TOKEN" && props.reservationInvoice?.success == false) {
        const reason = props.reservationInvoice.reason;
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.onFailed(props);
      }
    }
  };

  // 실패 처리
  onFailed = (props) => {
    if (props.failData != null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      // else
      return;
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      reservationDetail: state.statistics.reservationDetail,
      reservationInvoice: state.statistics.reservationInvoice,
      ownerAgreement: state.statistics.ownerAgreement,
      pmsSettingDetail: state.statistics.pmsSettingDetail,
      failData: state.statistics.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetReservationOne: (hotelId, reservationId) => dispatch(statisctisActions.onGetReservationOne(hotelId, reservationId)),
      onGetReservationInvoice: (hotelId, reservationId) => dispatch(statisctisActions.onGetReservationInvoice(hotelId, reservationId)),
      onGetOwnerAgreement: (hotelId, confirmationNo) => dispatch(statisctisActions.onGetOwnerAgreement(hotelId, confirmationNo)),
      onGetPMSSettingOne: (hotelId) => dispatch(statisctisActions.onGetPMSOne(hotelId)),
    };

    return returnProps;
  };
}

export default ReservationDetailData;
