import React from "react";
import { connect } from "react-redux";
import { Loading } from "../../../components/Paths";
import StaffNotificationLogDetailData from "../Detail/staffNotificationLogDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/staffNotificationLogList/staffNotificationLogDetail.css";

// utils
import { formatDateTimeDot, prettyPrint } from "../../../common/Utils";

class StaffNotificationLogDetailView extends StaffNotificationLogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      staffNotificationLogId: "",
    };
  }

  // 첫 실행시 URL에서 staffNotificationId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const staffNotificationLogId = params.get("staffNotificationLogId");
    this.setState({
      staffNotificationLogId: staffNotificationLogId,
    });
    this.requestGetStaffNotificationLogDetail(staffNotificationLogId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let staffNotificationLogDetail = null;

    if (this.props.staffNotificationLogDetail != null && this.props.staffNotificationLogDetail?.success == true) {
      staffNotificationLogDetail = this.props.staffNotificationLogDetail.results;
    }

    return (
      <div className="staffNotificationLogDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="staffNotification_detail_container">
          <div className="staffNotification_detail_innder">
            <div className="title" data-detect="admin_staff_notification_log_detail_title">
              TAFF NOTIFICATION LOG DETAIL
            </div>

            <div className="staffNotification_container">
              <div className="staffNotification_title_container"></div>
              <div className="staffNotification_info_container">
                <div className="staffNotification_info_table">
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_notification_id">Notification ID</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_hotel_id"> Hotel ID</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span> {staffNotificationLogDetail?.hotelStaff.hotelId??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_staff_id">Staff ID</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span> {staffNotificationLogDetail?.staffId??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_staff_email"> Staff Email</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span> {staffNotificationLogDetail?.hotelStaff.employeeId??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_staff_no"> Staff NO.</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span> {staffNotificationLogDetail?.hotelStaff.id??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_department"> Department</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.department??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_team">Team</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.team??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_level">Level</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.department??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_task">Task</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.task??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_location">Location</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.location??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_zone">Zone</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.zone??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_room">Rooms</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.rooms??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_guest_inquiry_level">Guest Inquiry Level</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.guestInquiryLevel??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_language_code">Language Code </span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.hotelStaff.languageCode??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_os_type">OS Type</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.osType??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_type">Type</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.type??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_title">Title</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.title??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_content">Content</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail?.content??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_data">Data</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span className="format">{prettyPrint(staffNotificationLogDetail?.data)}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_token">Token</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span> {staffNotificationLogDetail?.token??"-"}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_created_at">Created At</span>
                    </div>{" "}
                    <div className="staffNotification_info_cell content">
                      <span>{(staffNotificationLogDetail ? formatDateTimeDot(staffNotificationLogDetail.created_at) : "-")}</span>
                    </div>
                  </div>
                  <div className="staffNotification_info_row">
                    <div className="staffNotification_info_cell index">
                      <span data-detect="admin_staff_notification_log_detail_label_updated_at"> Updated At</span>
                    </div>
                    <div className="staffNotification_info_cell content">
                      <span>{staffNotificationLogDetail ? formatDateTimeDot(staffNotificationLogDetail.updated_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = StaffNotificationLogDetailData.mapStateToProps;
let mapDispatchToProps = StaffNotificationLogDetailData.mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StaffNotificationLogDetailView);
