import React from "react";
import { Link, withRouter } from "react-router-dom";

class MenuLink extends React.Component {
  handleClick = (e) => {
    e.preventDefault();
    const { to, location, history } = this.props;

    if (location.pathname === to) {
      // 현재 pathname과 이동하고자 하는 pathname이 동일한 경우, pathname 뒤에 공백을 추가해 replace했다가 다시 원래 경로로 push함.
      // 컴포넌트를 다시 mount하여 리스트를 업데이트할 수 있도록 함.
      history.replace(`${to} `);
      setTimeout(() => {
        history.push(to);
        }, 0);
    } else {
      history.push(to);
    }
  };

  render() {
    const { to, className, children, "data-detect": dataDetect } = this.props;
    return (
      <Link to={to} className={className} onClick={this.handleClick} data-detect={dataDetect}>
        {children}
      </Link>
    );
  }
}

export default withRouter(MenuLink);
