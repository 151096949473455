// AppListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../components/Paths";
import AppListData from "../List/AppListData";

//css
import "../../css/app/appList.css";
import "../../css/common.css";
import "../../css/common/myConectTable.css";

// icon
import deleteIcon from "../../img/button_delete_white.svg";

//utils
import { formatDateTimeSlash } from "../../common/Utils";

class AppListView extends AppListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      selectedOption: "",
      search: "", //inputValue
      name: "",
      email: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
      tempSelectedOption: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetAppList(this.state.pageNo, this.state.selectedOption, this.state.search);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    const value = e.target.value;
    this.setState({ [stateName]: value });
  }

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        selectedOption: this.state.tempSelectedOption,
      },
      () => {
        this.requestGetAppList(this.state.pageNo, this.state.selectedOption, this.state.search);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          selectedOption: this.state.tempSelectedOption,
        },
        () => {
          this.requestGetAppList(this.state.pageNo, this.state.selectedOption, this.state.search);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // 페이지 number 클릭했을 때 페이지 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true, tempSearch: this.state.search, tempSelectedOption: this.state.selectedOption }, () => {
      this.requestGetAppList(pageNo, this.state.selectedOption, this.state.search); // 앱 목록 요청
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkAppDetail(id);
    }
  }

  //파람값 url로 전달
  linkAppDetail = (appId) => {
    this.props.history.push(`/appDetailPage?appId=${appId}`);
  };

  //insert
  linkAppAdd = () => {
    this.props.history.push("/appAddPage");
  };


  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  render() {
    let count;
    let appList = [];

    if (
      this.props.appList &&
      this.props.appList?.success == true
    ) {
      count = this.props.appList.results.count;
      appList = this.props.appList.results.rows;
    }

    return (
      <div className="appList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="app_list_container">
          <div className="app_list_container_inner">
            <div className="title" data-detect="admin_application_user_list_title">
              APPLICATION USER LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="find">
                  <select style={{ marginRight: "6px" }} id="regionSelect" value={this.state.tempSelectedOption} onChange={(e) => this.onChangeValue(e, "tempSelectedOption")}>
                    <option value="" data-detect="admin_application_user_list_placeholder_select_box_user">
                      USER
                    </option>
                    <option value="email" data-detect="admin_application_user_list_select_email">
                      E-mail
                    </option>
                    <option value="name" data-detect="admin_application_user_list_select_name">
                      Name
                    </option>
                  </select>
                </div>
                <div className="search_container">
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_application_user_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- app list table  --> */}
            <div className="app_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input type="checkbox" id="allCheck" onClick={() => this.allCheck(appList, this.state.pageNo)} checked={appList && this.state.checkedList.length == appList.length} readOnly />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_application_user_list_label_email">
                      E-mail
                    </div>
                    <div className="cell" data-detect="admin_application_user_list_label_backup_email">
                      Backup E-mail
                    </div>
                    <div className="cell" data-detect="admin_application_user_list_label_name">
                      Name
                    </div>
                    <div className="cell" data-detect="admin_application_user_list_label_alternative_name">
                      Alternative Name
                    </div>
                    <div className="cell" data-detect="admin_application_user_list_label_mail_confirmed_at">
                      Mail Confirmed At
                    </div>
                    <div className="cell" data-detect="admin_application_user_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {appList?.length > 0 ? (
                    appList.map((app, index) => (
                      <div className="row tbodyRow" key={app.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={app.id}
                            id={app.id}
                            onClick={() => this.onClickInput(app.id, appList)}
                            checked={this.state.checkedList.includes(app.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={app.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(app.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {app.email}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(app.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {app.backupEmail || "-"}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(app.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {app.gender + app.firstName + app.lastName}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(app.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {app.alternativeFirstName + app.alternativeLastName || "-"}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(app.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(app.mailConfimedAt)}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(app.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(app.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_application_user_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedApps();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_application_user_list_button_delete">Delete</span>
                </button>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AppListData.mapStateToProps;
let mapDispatchToProps = AppListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppListView)
);
