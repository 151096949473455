import React from "react";
import { Provider } from "react-redux";
import { Router, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import createBrowserHistory from "./redux/History";
import { persistor, store } from "./redux/Store";

import Root from "./react/Root";

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={createBrowserHistory}>
        <Switch>
          <Root />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
