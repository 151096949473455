// ApplicationVersionAddData.js

import CommonComponent from "../../../components/CommonComponent";

// module
import * as versionActions from "../../../../redux/modules/Version";


class ParkingStaffApplicationVersionAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_PARKING_STAFF_APPLICATION_VERSION") {
      this.onAddParkingStaffApplicationVersionResults(props);
    }
  }

  // redux에 add parking staffApplicationVersion 요청
  requestAddParkingStaffApplicationVersion = (os, version, link) => {
    this.setState({
      requestType: "ADD_PARKING_STAFF_APPLICATION_VERSION",
    });
    this.props.onAdd(os, version, link);
  };

  // get add parking staffApplicationVersion results
  onAddParkingStaffApplicationVersionResults = (props) => {
    if (props.parkingStaffApplicationVersionAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.parkingStaffApplicationVersionAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_staff_app_version"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/parkingStaffApplicationVersionListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      parkingStaffApplicationVersionAdd: state.version.parkingStaffApplicationVersionAdd,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (os, version, link) => dispatch(versionActions.onAddParkingStaffApplicationVersion(os, version, link)),
    };
  };
}

export default ParkingStaffApplicationVersionAddData;
