import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import MyConectColors from '../../components/colors/MyConectColors';
import "../colors/colors.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';


class Colors extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         startDate: new Date(),
    //         endDate: new Date(),
    //     };
    // }


    render() {

        return (
            <div className='colorsViewPage'>
                <div className='inner'>
                    <Container>
                        <Row>
                            <div className='title'>
                                <div className='myConect_menu_title'>Color Definition </div>
                            </div>
                        </Row>
                        <div className='line'></div>

                        <Row>
                            <div className='title_padding'></div>
                            <div className='sub_title'>
                                <b>Basic Color</b></div>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>BG</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_PRIMARY }}></div></Row>
                                <Row>FFC700</Row>
                                <Row>Primary</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_DARK }}></div></Row>
                                <Row>383838</Row>
                                <Row>Dark</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_LIGHT, border: "1px solid black" }}></div></Row>
                                <Row>FFFFFF</Row>
                                <Row>Light</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_MENU }}></div></Row>
                                <Row>FFF9E5</Row>
                                <Row>Menu</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_GREY }}></div></Row>
                                <Row>Grey</Row>
                                <Row>F2F2F2</Row>
                            </Col>
                        </Row>

                        <Row >
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_MY_PAGE }}></div></Row>
                                <Row>3E4A59</Row>
                                <Row>MyPage</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BG_LIST }}></div></Row>
                                <Row>F8D2D2</Row>
                                <Row>List</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Text</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_DEFAULT }}></div></Row>
                                <Row>3E4A59</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_PRIMARY }}></div></Row>
                                <Row>FFC700</Row>
                                <Row>Primary</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_DARK }}></div></Row>
                                <Row>383838</Row>
                                <Row>Dark</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_LIGHT, border: "1px solid black" }}></div></Row>
                                <Row>FFFFFF</Row>
                                <Row>Light</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_DISABLE }}></div></Row>
                                <Row>E9EDF1</Row>
                                <Row>Disabled</Row>
                            </Col>
                        </Row>
                        <Row >
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_REQUEST }}></div></Row>
                                <Row>0000F5</Row>
                                <Row>Request</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.TEXT_RESPONSE }}></div></Row>
                                <Row>FF4249</Row>
                                <Row>Response</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Line</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.LINE_DEFAULT }}></div></Row>
                                <Row>9A9FA8</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.LINE_LIST }}></div></Row>
                                <Row>E5E5E5</Row>
                                <Row>List</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='title_padding'></div>
                            <div className='sub_title'>
                                <b>Button</b></div>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>BG</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BG_PRIMARY }}></div></Row>
                                <Row>FFC700</Row>
                                <Row>Active</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BG_HOVER }}></div></Row>
                                <Row>FFD84C</Row>
                                <Row>Hover</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BG_FOCUS }}></div></Row>
                                <Row>E5B302</Row>
                                <Row>ACTIVE</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BG_DISABLE }}></div></Row>
                                <Row>E9EDF1</Row>
                                <Row>Disable</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>My Page</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BG_MYPAGE_DEFAULT, border: "1px solid black" }}></div></Row>
                                <Row>FFFFFF</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BG_MYPAGE_SELECT }}></div></Row>
                                <Row>535B68</Row>
                                <Row>Select</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Light</div>
                        </Row>

                        <Row>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_LIGHT_DEFAULT, border: "1px solid black" }}></div></Row>
                                <Row>FFFFFF</Row>
                                <Row>Light Active</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_LIGHT_HOVER }}></div></Row>
                                <Row>FFF9E5</Row>
                                <Row>Light Hover</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_LIGHT_ACTIVE }}></div></Row>
                                <Row>FFF4CC</Row>
                                <Row>Light ACTIVE</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Border</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BORDER_DEFAULT }}></div></Row>
                                <Row>DFE3E9</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BORDER_DISABLE }}></div></Row>
                                <Row>D1D5D8</Row>
                                <Row>Disable</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BORDER_HOVER }}></div></Row>
                                <Row>667486</Row>
                                <Row>Hover</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_BORDER_FOCUS }}></div></Row>
                                <Row>3E4A59</Row>
                                <Row>ACTIVE</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Text</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_DEFAULT }}></div></Row>
                                <Row>7F8FA4</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_DISABLE_GREY }}></div></Row>
                                <Row>9A9FA8</Row>
                                <Row>Disable</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_ERROR }}></div></Row>
                                <Row>FF4249</Row>
                                <Row>Error</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_ACTIVE }}></div></Row>
                                <Row>3E4A59</Row>
                                <Row>ACTIVE</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_PRIMARY }}></div></Row>
                                <Row>FFC700</Row>
                                <Row>Primary</Row>
                            </Col>
                        </Row>

                        <Row >
                            <div className='row_padding'></div>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_DARK }}></div></Row>
                                <Row>383838</Row>
                                <Row>Dark</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.BUTTON_TEXT_LIGHT, border: "1px solid black" }}></div></Row>
                                <Row>FFFFFF</Row>
                                <Row>Light</Row>
                            </Col>
                        </Row>


                        <Row>
                            <div className='title_padding'></div>
                            <div className='sub_title'>
                                <b>Input</b></div>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Border</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_BORDER_DEFAULT }}></div></Row>
                                <Row>DFE3E9</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_BORDER_DISABLE }}></div></Row>
                                <Row>D1D5D8</Row>
                                <Row>Disable</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_BORDER_ERROR }}></div></Row>
                                <Row>FF4249</Row>
                                <Row>Error</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_BORDER_ACTIVE }}></div></Row>
                                <Row>667486</Row>
                                <Row>ACTIVE</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Text</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_TEXT_DEFAULT }}></div></Row>
                                <Row>3E4A59</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_TEXT_DISABLE }}></div></Row>
                                <Row>9A9FA8</Row>
                                <Row>Disable</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.INPUT_TEXT_PLACEHOLDER }}></div></Row>
                                <Row>7F8FA4</Row>
                                <Row>Placeholder</Row>
                            </Col>
                        </Row>

                        <Row>
                            <div className='row_padding'></div>
                            <div className='sub_title'>Icon</div>
                        </Row>

                        <Row >
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.ICON_DEFAULT }}></div></Row>
                                <Row>9A9FA8</Row>
                                <Row>Default</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.ICON_PRIMARY }}></div></Row>
                                <Row>FFC700</Row>
                                <Row>Primary</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.ICON_DARK }}></div></Row>
                                <Row>383838</Row>
                                <Row>Dark</Row>
                            </Col>
                            <Col sm={2}>
                                <Row><div className='color_square' style={{ backgroundColor: MyConectColors.ICON_LIGHT, border: "1px solid black" }}></div></Row>
                                <Row>FFFFFF</Row>
                                <Row>Light</Row>
                            </Col>

                            {/* 
                            <Row>
                                <DatePicker
                                    selectsRange={true}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={(update) => {
                                        this.setState({
                                            startDate: update[0],
                                            endDate: update[1],
                                        })
                                    }}
                                    isClearable={true}
                                />
                            </Row> */}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Colors;
