import CommonComponent from "../../components/CommonComponent";

// module
import * as userInfoActions from "../../../redux/modules/UserInfo";



class UserInfoDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_USER_INFO_ONE") {

      this.onGetUserInfoOneResults(props);
    }
    if (this.state.requestType == "DELETE_USER_INFO_ONE") {
      this.onDeleteUserInfoResults(props);
    }
  }

  // redux에 get userInfo Detail 요청
  requestGetUserInfoOne = (userInfoId) => {
    this.setState({
      requestType: "GET_USER_INFO_ONE",
    });
    this.props.onGetOne(userInfoId);
  };

  // redux에 순차적으로 delete UserInfo 요청
  requestDeleteUserInfo = (userInfoId) => {
    this.setState({
      requestType: "DELETE_USER_INFO_ONE",
    });
    this.props.onDeleteOne(userInfoId);
  };

  // get userInfo One results
  onGetUserInfoOneResults = (props) => {
    if (props.userInfoDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.userInfoDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  // delete userInfo results
  onDeleteUserInfoResults = (props) => {
    if (props.successDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onDeleteSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onDeleteSuccess = () => {
    // 페이지 이동
    this.props.history.push("/userInfoListPage");
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.sessionData,
      userInfoDetail: state.userInfo.userInfoDetail,
      failData: state.userInfo.failData,
      successDelete: state.userInfo.successDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetOne: (userInfoId) =>
        dispatch(userInfoActions.onGetUserInfoOne(userInfoId)),
      onDeleteOne: (userInfoId) =>
        dispatch(userInfoActions.onDeleteUserInfo(userInfoId)),
    };
  };
}

export default UserInfoDetailData;
