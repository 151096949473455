// UserInfoAddData.js

import CommonComponent from "../../components/CommonComponent";

// module
import * as userInfoActions from "../../../redux/modules/UserInfo";


class UserInfoAddData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      requestType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "ADD_USER_INFO") {
      this.onAddUserInfoResults(props);
    }
  }

  // redux에 add userInfo 요청
  requestAddUserInfo = (email, name, password, confirmPassword, department, duty) => {
    this.setState({
      requestType: "ADD_USER_INFO",
    });
    this.props.onAdd(email, name, password, confirmPassword, department, duty);
  };

  // get add userInfo results
  onAddUserInfoResults = (props) => {
    if (props.userInfoAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.userInfoAdd.success === true) {
      // 수정이 완료된 후에 페이지 이동
      this.onAddSuccess();
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_user"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  };

  onAddSuccess = () => {
    // 페이지 이동
    this.props.history.push("/userInfoListPage");
  };

  onFailed = (props) => {
    if (props.failData != null) {
      //response 전에 공백으로인한 오류 발생 예외 처리
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code == "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.sessionData,
      userInfoAdd: state.userInfo.userInfoAdd,
      failData: state.userInfo.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onAdd: (email, name, password, confirmPassword, department, duty) => dispatch(userInfoActions.onAdduserInfo(email, name, password, confirmPassword, department, duty)),
    };
  };
}

export default UserInfoAddData;
