import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAll() {
  return axiosInstance.get("/admin/api/myconectSetting/byOne");
}

//------------------------------- update List ------------------------------------------------------//
export function onUpdate(id, coordinateDistance) {
  const requestData = {
    coordinateDistance: coordinateDistance,
  };

  return axiosInstance.put("/admin/api/myconectSetting/" + id, requestData);
}
