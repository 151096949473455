import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as emailFormApis from "../lib/emailForm_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const GET_PASSWORD_FORM = "emailForm/GET_PASSWORD_FORM";
const DELETE_SUMMERNOTE = "emailForm/DELETE_SUMMERNOTE";
const ADD_SUMMERNOTE = "emailForm/ADD_SUMMERNOTE";
const EDIT_SUMMERNOTE = "emailForm/EDIT_SUMMERNOTE";

const GET_FORGOT_PASSWORD_FORM = "emailForm/GET_FORGOT_PASSWORD_FORM";
const DELETE_FORGOT_PASSWORD_FORM = "emailForm/DELETE_FORGOT_PASSWORD_FORM";
const ADD_FORGOT_PASSWORD_FORM = "emailForm/ADD_FORGOT_PASSWORD_FORM";
const EDIT_FORGOT_PASSWORD_FORM = "emailForm/EDIT_FORGOT_PASSWORD_FORM";

const GET_FORGOT_ID_FORM = "emailForm/GET_FORGOT_ID_FORM";
const DELETE_FORGOT_ID_FORM = "emailForm/DELETE_FORGOT_ID_FORM";
const ADD_FORGOT_ID_FORM = "emailForm/ADD_FORGOT_ID_FORM";
const EDIT_FORGOT_ID_FORM = "emailForm/EDIT_FORGOT_ID_FORM";

const GET_CREATE_EMAIL_FORM = "emailForm/GET_CREATE_EMAIL_FORM";
const DELETE_CREATE_EMAIL_FORM = "emailForm/DELETE_CREATE_EMAIL_FORM";
const ADD_CREATE_EMAIL_FORM = "emailForm/ADD_CREATE_EMAIL_FORM";
const EDIT_CREATE_EMAIL_FORM = "emailForm/EDIT_CREATE_EMAIL_FORM";

const GET_EMAIL_CONFIRM_FAIL_FORM = "emailForm/GET_EMAIL_CONFIRM_FAIL_FORM";
const DELETE_EMAIL_CONFIRM_FAIL_FORM = "emailForm/DELETE_EMAIL_CONFIRM_FAIL_FORM";
const ADD_EMAIL_CONFIRM_FAIL_FORM = "emailForm/ADD_EMAIL_CONFIRM_FAIL_FORM";
const EDIT_EMAIL_CONFIRM_FAIL_FORM = "emailForm/EDIT_EMAIL_CONFIRM_FAIL_FORM";

const GET_EMAIL_CONFIRM_SUCEESS_FORM = "emailForm/GET_EMAIL_CONFIRM_SUCEESS_FORM";
const DELETE_EMAIL_CONFIRM_SUCCESS_FORM = "emailForm/DELETE_EMAIL_CONFIRM_SUCCESS_FORM";
const ADD_EMAIL_CONFIRM_SUCCESS_FORM = "emailForm/ADD_EMAIL_CONFIRM_SUCCESS_FORM";
const EDIT_EMAIL_CONFIRM_SUCCESS_FORM = "emailForm/EDIT_EMAIL_CONFIRM_SUCCESS_FORM";

const GET_PROFILE_FORM = "emailForm/GET_PROFILE_FORM";
const DELETE_PROFILE_FORM = "emailForm/DELETE_PROFILE_FORM";
const ADD_PROFILE_FORM = "emailForm/ADD_PROFILE_FORM";
const EDIT_PROFILE_FORM = "emailForm/EDIT_PROFILE_FORM";

const GET_WITHDRAW_FORM = "emailForm/GET_WITHDRAW_FORM";
const DELETE_WITHDRAW_FORM = "emailForm/DELETE_WITHDRAW_FORM";
const ADD_WITHDRAW_FORM = "emailForm/ADD_WITHDRAW_FORM";
const EDIT_WITHDRAW_FORM = "emailForm/EDIT_WITHDRAW_FORM";

const EDIT_DEFAULT_LANGUAGE_FORM = "emailForm/EDIT_DEFAULT_LANGUAGE_FORM";

// 액션 생성자
export const onGetPasswordForm = createAction(GET_PASSWORD_FORM, emailFormApis.onGetPasswordForm);
export const onDeleteSummernote = createAction(DELETE_SUMMERNOTE, emailFormApis.onDeleteSummernote);
export const onAddSummernote = createAction(ADD_SUMMERNOTE, emailFormApis.onAddSummernote);
export const onEditSummernote = createAction(EDIT_SUMMERNOTE, emailFormApis.onEditSummernote);

export const onGetForgotPasswordForm = createAction(GET_FORGOT_PASSWORD_FORM, emailFormApis.onGetForgotPasswordForm);
export const onDeleteForgotPasswordForm = createAction(DELETE_FORGOT_PASSWORD_FORM, emailFormApis.onDeleteForgotPasswordForm);
export const onAddForgotPasswordForm = createAction(ADD_FORGOT_PASSWORD_FORM, emailFormApis.onAddForgotPasswordForm);
export const onEditForgotPasswordForm = createAction(EDIT_FORGOT_PASSWORD_FORM, emailFormApis.onEditForgotPasswordForm);

export const onGetForgotIdForm = createAction(GET_FORGOT_ID_FORM, emailFormApis.onGetForgotIdForm);
export const onDeleteForgotIdForm = createAction(DELETE_FORGOT_ID_FORM, emailFormApis.onDeleteSummernote);
export const onAddForgotIdForm = createAction(ADD_FORGOT_ID_FORM, emailFormApis.onAddSummernote);
export const onEditForgotIdForm = createAction(EDIT_FORGOT_ID_FORM, emailFormApis.onEditSummernote);

export const onGetCreateEmailForm = createAction(GET_CREATE_EMAIL_FORM, emailFormApis.onGetCreateEmailForm);
export const onDeleteCreateEmailForm = createAction(DELETE_CREATE_EMAIL_FORM, emailFormApis.onDeleteCreateEmailForm);
export const onAddCreateEmailForm = createAction(ADD_CREATE_EMAIL_FORM, emailFormApis.onAddCreateEmailForm);
export const onEditCreateEmailForm = createAction(EDIT_CREATE_EMAIL_FORM, emailFormApis.onEditCreateEmailForm);

export const onGetCreateEmailFailureForm = createAction(GET_EMAIL_CONFIRM_FAIL_FORM, emailFormApis.onGetCreateEmailFailureForm);
export const onDeleteCreateEmailFailureForm = createAction(DELETE_EMAIL_CONFIRM_FAIL_FORM, emailFormApis.onDeleteCreateEmailFailureForm);
export const onAddCreateEmailFailureForm = createAction(ADD_EMAIL_CONFIRM_FAIL_FORM, emailFormApis.onAddCreateEmailFailureForm);
export const onEditCreateEmailFailureForm = createAction(EDIT_EMAIL_CONFIRM_FAIL_FORM, emailFormApis.onEditCreateEmailFailureForm);

export const onGetCreateEmailSuccessForm = createAction(GET_EMAIL_CONFIRM_SUCEESS_FORM, emailFormApis.onGetCreateEmailSuccessForm);
export const onDeleteCreateEmailSuccessForm = createAction(DELETE_EMAIL_CONFIRM_SUCCESS_FORM, emailFormApis.onDeleteCreateEmailSuccessForm);
export const onAddCreateEmailSuccessForm = createAction(ADD_EMAIL_CONFIRM_SUCCESS_FORM, emailFormApis.onAddCreateEmailSuccessForm);
export const onEditCreateEmailSuccessForm = createAction(EDIT_EMAIL_CONFIRM_SUCCESS_FORM, emailFormApis.onEditCreateEmailSuccessForm);

export const onGetProfileForm = createAction(GET_PROFILE_FORM, emailFormApis.onGetProfileForm);
export const onDeleteProfileForm = createAction(DELETE_PROFILE_FORM, emailFormApis.onDeleteProfileForm);
export const onAddProfileForm = createAction(ADD_PROFILE_FORM, emailFormApis.onAddProfileForm);
export const onEditProfileForm = createAction(EDIT_PROFILE_FORM, emailFormApis.onEditProfileForm);

export const onGetWithdrawForm = createAction(GET_WITHDRAW_FORM, emailFormApis.onGetWithdrawForm);
export const onDeleteWithdrawForm = createAction(DELETE_WITHDRAW_FORM, emailFormApis.onDeleteWithdrawForm);
export const onAddWithdrawForm = createAction(ADD_WITHDRAW_FORM, emailFormApis.onAddWithdrawForm);
export const onEditWithdrawForm = createAction(EDIT_WITHDRAW_FORM, emailFormApis.onEditWithdrawForm);

export const onEditDefaultLanguageForm = createAction(EDIT_DEFAULT_LANGUAGE_FORM, emailFormApis.onEditDefaultLanguageForm);

// 초기 상태
const initialState = {
  passwordFormList: null,
  successDelete: null,
  successAdd: null,
  successEdit: null,
  forgotPasswordFormList: null,
  successForgotPasswordDelete: null,
  successForgotPasswordAdd: null,
  successForgotPasswordEdit: null,
  forgotIdFormList: null,
  successForgotIdAdd: null,
  successForgotIdEdit: null,
  successForgotIdEdit: null,
  createEmailFormList: null,
  successCreateEmailFormDelete: null,
  successCreateEmailFormAdd: null,
  successCreateEmailFormEdit: null,
  emailFailureFormList: null,
  failureCreateEmailFormDelete: null,
  failureCreateEmailFormAdd: null,
  failureCreateEmailFormEdit: null,
  emailSuccessFormList: null,
  successCreateEmailFormDelete: null,
  successCreateEmailFormAdd: null,
  successCreateEmailFormEdit: null,
  profileFormList: null,
  successProfileFormDelete: null,
  successProfileFormAdd: null,
  successProfileFormEdit: null,
  withdrawFormList: null,
  successWithdrawFormDelete: null,
  successWithdrawFormAdd: null,
  successWithdrawFormEdit: null,
  successDefaultLanguageEdit: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: GET_PASSWORD_FORM,
      onPending: (state, action) => {
        return { ...state, passwordFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, passwordFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_SUMMERNOTE,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_SUMMERNOTE,
      onPending: (state, action) => {
        return { ...state, successAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_SUMMERNOTE,
      onPending: (state, action) => {
        return { ...state, successEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_FORGOT_PASSWORD_FORM,
      onPending: (state, action) => {
        return { ...state, forgotPasswordFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, forgotPasswordFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_FORGOT_PASSWORD_FORM,
      onPending: (state, action) => {
        return { ...state, successForgotPasswordDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successForgotPasswordDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_FORGOT_PASSWORD_FORM,
      onPending: (state, action) => {
        return { ...state, successForgotPasswordAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successForgotPasswordAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_FORGOT_PASSWORD_FORM,
      onPending: (state, action) => {
        return { ...state, successForgotPasswordEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successForgotPasswordEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_FORGOT_ID_FORM,
      onPending: (state, action) => {
        return { ...state, forgotIdFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, forgotIdFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_FORGOT_ID_FORM,
      onPending: (state, action) => {
        return { ...state, successForgotIdDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successForgotIdDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_FORGOT_ID_FORM,
      onPending: (state, action) => {
        return { ...state, successForgotIdAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successForgotIdAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_FORGOT_ID_FORM,
      onPending: (state, action) => {
        return { ...state, successForgotIdEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successForgotIdEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_CREATE_EMAIL_FORM,
      onPending: (state, action) => {
        return { ...state, createEmailFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, createEmailFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_CREATE_EMAIL_FORM,
      onPending: (state, action) => {
        return { ...state, successCreateEmailFormDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successCreateEmailFormDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_CREATE_EMAIL_FORM,
      onPending: (state, action) => {
        return { ...state, successCreateEmailFormAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successCreateEmailFormAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_CREATE_EMAIL_FORM,
      onPending: (state, action) => {
        return { ...state, successCreateEmailFormEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successCreateEmailFormEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_EMAIL_CONFIRM_FAIL_FORM,
      onPending: (state, action) => {
        return { ...state, emailFailureFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, emailFailureFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_EMAIL_CONFIRM_FAIL_FORM,
      onPending: (state, action) => {
        return { ...state, failureCreateEmailFormDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, failureCreateEmailFormDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_EMAIL_CONFIRM_FAIL_FORM,
      onPending: (state, action) => {
        return { ...state, failureCreateEmailFormAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, failureCreateEmailFormAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_EMAIL_CONFIRM_FAIL_FORM,
      onPending: (state, action) => {
        return { ...state, failureCreateEmailFormEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, failureCreateEmailFormEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_EMAIL_CONFIRM_SUCEESS_FORM,
      onPending: (state, action) => {
        return { ...state, emailSuccessFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, emailSuccessFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_EMAIL_CONFIRM_SUCCESS_FORM,
      onPending: (state, action) => {
        return { ...state, successCreateEmailFormDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successCreateEmailFormDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_EMAIL_CONFIRM_SUCCESS_FORM,
      onPending: (state, action) => {
        return { ...state, successCreateEmailFormAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successCreateEmailFormAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_EMAIL_CONFIRM_SUCCESS_FORM,
      onPending: (state, action) => {
        return { ...state, successCreateEmailFormEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successCreateEmailFormEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: GET_PROFILE_FORM,
      onPending: (state, action) => {
        return { ...state, profileFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, profileFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_PROFILE_FORM,
      onPending: (state, action) => {
        return { ...state, successProfileFormDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successProfileFormDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_PROFILE_FORM,
      onPending: (state, action) => {
        return { ...state, successProfileFormAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successProfileFormAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_PROFILE_FORM,
      onPending: (state, action) => {
        return { ...state, successProfileFormEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successProfileFormEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),

    ...pender({
      type: GET_WITHDRAW_FORM,
      onPending: (state, action) => {
        return { ...state, withdrawFormList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, withdrawFormList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_WITHDRAW_FORM,
      onPending: (state, action) => {
        return { ...state, successWithdrawFormDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successWithdrawFormDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_WITHDRAW_FORM,
      onPending: (state, action) => {
        return { ...state, successWithdrawFormAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successWithdrawFormAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_WITHDRAW_FORM,
      onPending: (state, action) => {
        return { ...state, successWithdrawFormEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successWithdrawFormEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_DEFAULT_LANGUAGE_FORM,
      onPending: (state, action) => {
        return { ...state, successDefaultLanguageEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDefaultLanguageEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
