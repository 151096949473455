import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import AmenityAddData from "../Add/AmenityAddData";



//css
import "../../css/amenity/amenityAdd.css";
import "../../css/common.css";

class AmenityAddView extends AmenityAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      type: "",
      group: "",
      code: "",
      name: "",
      iconFile: null,
      iconText: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change type
  onChangeType = (e) => {
    const inputValue = e.target.value;
    this.setState({ type: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change group
  onChangeGroup = (e) => {
    const inputValue = e.target.value;
    this.setState({ group: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change code
  onChangeCode = (e) => {
    const inputValue = e.target.value;
    this.setState({ code: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change name
  onChangeName = (e) => {
    const inputValue = e.target.value;
    this.setState({ name: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("type", "admin_amenity_add_validation_type") &&
      this.confirmField("group", "admin_amenity_add_validation_group") &&
      this.confirmField("code", "admin_amenity_add_validation_code") &&
      this.confirmField("name", "admin_amenity_add_validation_name") &&
      this.confirmField("iconFile", "admin_amenity_add_validation_icon_image")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //file image 미리보기
  preview = () => {
    if (!this.state.iconFile || !this.state.iconFile instanceof Blob) {
      this.setState({
        isShowAllLoading: false,
      });
      return false;
    }

    // 로딩 시작
    this.setState({
      isShowAllLoading: true,
    });

    const imgEL = document.querySelector(".img__box");
    const reader = new FileReader();
    reader.onload = () => (imgEL.style.backgroundImage = `url(${reader.result})`);

    // 이미지 미리보기가 완료되면 로딩 종료
    reader.onloadend = () => {
      this.setState({
        isShowAllLoading: false,
      });
    };

    reader.readAsDataURL(this.state.iconFile);
  };

  //file 변경
  onFileChange = (e) => {
    const file = e.target.files[0];
    this.setState({ iconFile: file, iconText: file ? file.name : "ex) MyCONECT.png" }, () => {
      this.preview();
      this.onCheckValidation();
    });
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = async () => {
    this.setState({
      isShowAllLoading: true,
    });
    await this.requestAddAmenity(this.state.type, this.state.group, this.state.code, this.state.name, this.state.iconFile, this.state.iconText);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/amenityListPage");
  };

  render() {
    const imgBoxStyle = {
      marginLeft: this.state.iconFile && this.state.iconFile instanceof Blob ? "300px" : "0",
    };

    return (
      <div className="addAmenity">
        {this.state.isShowAllLoading && <Loading />}
        <div className="amenity_add_container">
          <div className="addamenity_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_amenity_add_title">ADD AMENITY</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addamenity_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addamenity_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_amenity_add_label_type">Type</p>
                      </div>
                      <div className="detail_cell content amenity_type">
                        <select id="type" className="type_select" name="type" value={this.state.type} onChange={this.onChangeType}>
                          <option disabled value="" data-detect="admin_amenity_add_placeholder_select_box_choose">
                            Choose
                          </option>
                          <option value="HOTEL" data-detect="admin_amenity_add_select_hotel">
                            HOTEL
                          </option>
                          <option value="ROOM" data-detect="admin_amenity_add_select_room">
                            ROOM
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_amenity_add_label_group">Group</p>
                      </div>
                      <div className="detail_cell content amenity_group">
                        <input type="text" id="group" name="group" autoComplete="off" value={this.state.group} onChange={this.onChangeGroup} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_amenity_add_label_code">Code</p>
                      </div>
                      <div className="detail_cell content amenity_code">
                        <input type="text" id="code" name="code" autoComplete="off" value={this.state.code} onChange={this.onChangeCode} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_amenity_add_label_name">Name</p>
                      </div>
                      <div className="admin_amenity_add_label_name">
                        <input type="name" id="name" name="name" autoComplete="off" value={this.state.name} onChange={this.onChangeName} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_amenity_add_label_icon">
                        Icon
                      </div>
                      <div
                        style={{
                          display: this.state.iconFile && this.state.iconFile instanceof Blob ? "block" : "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <div style={imgBoxStyle}>
                          {this.state.iconFile && this.state.iconFile instanceof Blob && <div className="img__box"></div>}
                          <div className="file_upload_button_container">
                            <form>
                              <label htmlFor="iconFile" className="file_upload_button" data-detect="admin_amenity_add_button_upload">
                                Upload
                              </label>
                              <input
                                type="file"
                                id="iconFile"
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={this.onFileChange}
                                title="Upload"
                                style={{ display: "none" }} // input 요소를 화면에서 감추기
                              />
                            </form>
                            {this.state.iconText ? <p className="file_textName">{this.state.iconText}</p> : <p className="file_textName" data-detect="admin_amenity_add_placeholder_upload"></p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_amenity_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_amenity_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AmenityAddData.mapStateToProps;
let mapDispatchToProps = AmenityAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmenityAddView));
