import CommonComponent from "../../components/CommonComponent";

// module
import * as authActions from "../../../redux/modules/Auth";
import * as paymentActions from "../../../redux/modules/Payment";




class LogDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_LOG_DETAIL") {

      this.onGetPaymentLogResults(props);
    }
  }

  // redux에 get payment Detail 요청
  requestGetLogDetail = (logId) => {
    this.setState({
      requestType: "GET_LOG_DETAIL",
    });
    this.props.onGetLogDetail(logId);
  };

  // get payment One results
  onGetPaymentLogResults = (props) => {
    if (props.logDetail == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.logDetail?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      logDetail: state.payment.logDetail,
      failData: state.payment.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetLogDetail: (logId) => dispatch(paymentActions.onGetLogDetail(logId)),
    };
  };
}

export default LogDetailData;
