// SettingEditView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import SettingEditData from "./SettingEditData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/setting/settingEdit.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class SettingEditView extends SettingEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      coordinateDistance: "",
      errorMessage: "",
      isCheckValidation: true,
    };
  }

  componentDidMount() {
    if (this.props.settingList.length !== 0 && this.props.settingList.success) {
      this.setState({
        coordinateDistance: this.props.settingList.results.coordinateDistance, //default값 설정
        isShowAllLoading: false, // 데이터를 성공적으로 받아왔으므로 로딩 상태를 false로 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change coordinateDistance
  onCoordinateDistanceChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ coordinateDistance: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("coordinateDistance", "admin_setting_edit_validation_coordinate_distance")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };



  render() {
    let settingList = [];
    if (this.props.settingList != null && this.props.settingList?.success == true) {
      settingList = this.props.settingList.results;
    }

    return (
      <div className="settingEdit">
        {this.state.isShowAllLoading && <Loading />}
        <div className="settingdetail_container">
          <div className="settingdetail_container_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_setting_edit_title">MyCONECT SETTING UPDATE</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
              <div className="update_date">
                <p data-detect="admin_setting_edit_label_date">Date Updated</p>
                <span>{settingList && formatDateTimeDot(settingList.updated_at)}</span>
              </div>
            </div>
            <div className="setting_information_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="setting_info">
                    <div className="detail_row title_row">
                      <div className="detail_cell title_index" data-detect="admin_setting_edit_label_setting">
                        Setting
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index" data-detect="admin_setting_edit_label_coordinate_distance">
                        Coordinate Distance
                      </div>
                      <div className="detail_cell content info_coordinateDistance">
                        <input type="text" value={this.state.coordinateDistance} onChange={this.onCoordinateDistanceChange} placeholder="Coordinate Distance" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_container">
              <div className="cancle_btn_container">
                <button className="cancle_btn on" onClick={this.cancelBtn} data-detect="admin_region_edit_button_cancel"></button>
              </div>
              <div className="ok_btn_container">
                <button className="edit_btn" onClick={this.onEditBtn} data-detect="admin_country_edit_button_ok" disabled={!this.state.isCheckValidation}></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = SettingEditData.mapStateToProps;
let mapDispatchToProps = SettingEditData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SettingEditView));
