import CommonComponent from "../../../components/CommonComponent";

// module

class UserTransferLogInfoDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {}

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      userTransferLogHistoryDetail: state.transfer.userTransferLogHistoryDetail,
      failData: state.transfer.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
    };
  };
}

export default UserTransferLogInfoDetailData;
