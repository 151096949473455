// TransferLogListView.js

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTimePicker from "../../../components/DateTimePicker/DataTimePicker";
import { Loading, Pagination } from "../../../components/Paths";
import TransferLogListData from "./TransferListData";

//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/transfer/transferLog/transferLogList.css";

// utils
import { formatDateTimeDot } from "../../../common/Utils";


class TransferLogListView extends TransferLogListData {
  constructor(props) {
    super(props);

    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      userId: "",
      hotelId: "",
      search: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempSearch: "",
      tempUserId: "",
      tempHotelId: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // Date 설정
      const startDate = new Date();
      const thirtyMinutesAgo = startDate.getMinutes() - 30; // startDate를 30분 전으로 설정
      startDate.setMinutes(thirtyMinutesAgo);

      const endDate = new Date();

      this.setState(
        {
          startDate: startDate, // startDate를 어제로 설정
          endDate: endDate,
          tempStartDate: startDate,
          tempEndDate: endDate,
        },
        () => {
          this.requestGetTransferLogList(this.state.pageNo, this.state.userId, this.state.hotelId, this.state.search, this.formatDateISO(startDate), this.formatDateISO(endDate));
        }
      );
    }
  }


  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // input과 select의 value값이 바뀌면 해당되는 이름의 state의 값이 바뀜
  onChangeValue = (e, stateName) => {
    let value = "";

    if (stateName == "tempStartDate" || stateName == "tempEndDate") {
      value = e;
    } else {
      value = e.target.value;
    }

    this.setState({ [stateName]: value });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        userId: this.state.tempUserId,
        hotelId: this.state.tempHotelId,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetTransferLogList(this.state.pageNo, this.state.userId, this.state.hotelId, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          userId: this.state.tempUserId,
          hotelId: this.state.tempHotelId,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetTransferLogList(this.state.pageNo, this.state.userId, this.state.hotelId, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.setState(
      { isShowAllLoading: true, tempSearch: this.state.search, tempUserId: this.state.userId, tempHotelId: this.state.hotelId, tempStartDate: this.state.startDate, tempEndDate: this.state.endDate },
      () => {
        this.onPageChange(pageNo); // 페이지 변경 처리
        this.requestGetTransferLogList(pageNo, this.state.userId, this.state.hotelId, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate)); // 호텔 목록 요청
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linktransferLogDetail(id);
    }
  }

  //파람값 url로 전달
  linktransferLogDetail = (transferLogId) => {
    this.props.history.push(
      `/transferLogDetailPage?transferLogId=${transferLogId}`
    );
  };

  //pageNo 변경시 sessionStorage에 저장
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if(!date || date == "") return;
    return date.toISOString();
  };

  render() {
    let count;
    let transferLogList = [];
    if (
      this.props.transferLogList &&
      this.props.transferLogList?.success == true
    ) {
      transferLogList = this.props.transferLogList.results.rows;
      count = this.props.transferLogList.results.count;
    }
    return (
      <div className="transferLogList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="transfer_list_container">
          <div className="transfer_list_container_inner">
            <div className="title" data-detect="admin_transfer_log_list_title">
              TRANSFER LOG LIST
            </div>
            <div className="period_container">
              <div className="period_start">
                <DataTimePicker value={this.state.tempStartDate} onDateTimeChange={(e) => this.onChangeValue(e, "tempStartDate")} key="datetime1" />
              </div>
              <div className="period_line"></div>
              <div className="period_end">
                <DataTimePicker value={this.state.tempEndDate} onDateTimeChange={(e) => this.onChangeValue(e, "tempEndDate")} key="datetime2" />
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="search search_by_id">
                    <input
                      type="search"
                      id="search_input_user_id"
                      data-detect-placeholder="admin_transfer_log_list_placeholder_user_id"
                      placeholder="User ID"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempUserId")}
                      value={this.state.tempUserId}
                    />
                  </div>
                  <div className="search search_by_id">
                    <input
                      type="search"
                      id="search_input_hotel_id"
                      data-detect-placeholder="admin_transfer_log_list_placeholder_hotel_id"
                      placeholder="Hotel ID"
                      onKeyUp={this.handleKeyUp}
                      onChange={(e) => this.onChangeValue(e, "tempHotelId")}
                      value={this.state.tempHotelId}
                    />
                  </div>
                  <div className="search">
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_transfer_log_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={(e) => this.onChangeValue(e, "tempSearch")}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="transfer_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_transfer_log_list_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_type">
                      Type
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_user">
                      User
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_hotel_id">
                      Hotel ID
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_path">
                      Path
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_status">
                      Status
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_success">
                      Success
                    </div>
                    <div className="cell" data-detect="admin_transfer_log_list_label_created_at">
                      Created At
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {transferLogList.length > 0 ? (
                    transferLogList.map((transferLog, index) => {
                      return (
                        <div className="row tbodyRow" key={transferLog.id}>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.id}
                          </div>
                          <div className={`cell ${transferLog.type}`} onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.type}
                          </div>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.user || "-"}
                          </div>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.hotelId || "-"}
                          </div>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.path || "-"}
                          </div>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.status || "-"}
                          </div>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {transferLog.type == "RESPONSE" && transferLog.body
                              ? (() => {
                                  var includeTrue = transferLog.body.indexOf("true");
                                  var includeFalse = transferLog.body.indexOf("false");
                                  let success;
                                  if (includeTrue == -1 && includeFalse == -1) {
                                    success = "-";
                                  } else if ((includeTrue == -1 ? Number.MAX_SAFE_INTEGER : includeTrue) < (includeFalse == -1 ? Number.MAX_SAFE_INTEGER : includeFalse)) {
                                    success = "true";
                                  } else {
                                    success = "false";
                                  }
                                  return success;
                                })()
                              : "-"}
                          </div>
                          <div className="cell" onMouseUp={() => this.handleMouseUp(transferLog.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                            {formatDateTimeDot(transferLog.created_at)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_transfer_log_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TransferLogListData.mapStateToProps;
let mapDispatchToProps = TransferLogListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransferLogListView)
);
