import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAll(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/version/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetOne(versionId) {
  return axiosInstance.get("/admin/api/version/" + versionId);
}

//------------------------------- add Version ------------------------------------------------------//
export function onAdd(name, ios, iosDebug, andriod, andriodDebug) {
  const data = {
    versionName: name,
    ios: ios,
    iosDebug: iosDebug,
    android: andriod,
    androidDebug: andriodDebug,
  };

  return axiosInstance.post("/admin/api/version/", data);
}
//------------------------------- delete Version ------------------------------------------------------//
export function onDelete(versionId) {
  return axiosInstance.delete("/admin/api/version/" + versionId);
}

//------------------------------- get ApplicationList ------------------------------------------------------//
export function onGetAllApplicationVersion(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/applicationVersion/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get aos List ------------------------------------------------------//
export function onGetaosAll(os) {
  return axiosInstance.get("/admin/api/applicationVersion/byOSForType?os=" + os);
}

//------------------------------- get ios List ------------------------------------------------------//
export function onGetiosAll(os) {
  return axiosInstance.get("/admin/api/applicationVersion/byOSForType?os=" + os);
}

//------------------------------- add ApplicationVersion ------------------------------------------------------//
export function onAddApplicationVersion(os, type, version, link) {
  const data = {
    os: os,
    type: type,
    version: version,
    link: link,
  };

  return axiosInstance.post("/admin/api/applicationVersion/", data);
}

/* ------------------------------------------- delete Application version ------------------------------------------- */
export function onDeleteApplicationVersion(applicationVersionId) {
  return axiosInstance.delete("/admin/api/applicationVersion/" + applicationVersionId);
}

//------------------------------- add Guest ApplicationVersion ------------------------------------------------------//
export function onAddGuestApplicationVersion(os, version, link) {
  const data = {
    os: os,
    version: version,
    link: link,
  };

  return axiosInstance.post("/admin/api/guestApplicationVersion/", data);
}

//------------------------------- get Guest ApplicationList ------------------------------------------------------//
export function onGetAllGuestApplicationVersion(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/guestApplicationVersion/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get active List ------------------------------------------------------//
export function onGetactiveAll(os) {
  return axiosInstance.get("/admin/api/guestApplicationVersion/groupByOS");
}

/* ---------------------------------------- delete Guest Application version ---------------------------------------- */
export function onDeleteGuestApplicationVersion(versionId) {
  return axiosInstance.delete("/admin/api/guestApplicationVersion/" + versionId);
}

//------------------------------- add staff ApplicationVersion ------------------------------------------------------//
export function onAddStaffApplicationVersion(os, version, link) {
  const data = {
    os: os,
    version: version,
    link: link,
  };

  return axiosInstance.post("/admin/api/staffApplicationVersion/", data);
}

//------------------------------- get staff ApplicationList ------------------------------------------------------//
export function onGetAllStaffApplicationVersion(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/staffApplicationVersion/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get staff List ------------------------------------------------------//
export function onGetStaffactiveAll(os) {
  return axiosInstance.get("/admin/api/staffApplicationVersion/groupByOS");
}

/* ---------------------------------------- delete Staff Application version ---------------------------------------- */
export function onDeleteStaffApplicationVersion(versionId) {
  return axiosInstance.delete("/admin/api/staffApplicationVersion/" + versionId);
}

//------------------------------- add parking staff ApplicationVersion ------------------------------------------------------//
export function onAddParkingStaffApplicationVersion(os, version, link) {
  const data = {
    os: os,
    version: version,
    link: link,
  };

  return axiosInstance.post("/admin/api/parkingStaffApplicationVersion/", data);
}

//------------------------------- get parking staff ApplicationList ------------------------------------------------------//
export function onGetAllParkingStaffApplicationVersion(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/parkingStaffApplicationVersion/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get parking staff List ------------------------------------------------------//
export function onGetParkingStaffactiveAll(os) {
  return axiosInstance.get("/admin/api/parkingStaffApplicationVersion/groupByOS");
}

/* ------------------------------------ delete parking staff Application version ------------------------------------ */
export function onDeleteParkingStaffApplicationVersion(versionId) {
  return axiosInstance.delete("/admin/api/parkingStaffApplicationVersion/" + versionId);
}
