import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserInfoEditData from "../Edit/UserInfoEditData";

//css
import "../../css/common.css";
import "../../css/userInfo/userInfoDetail.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class UserInfoEditView extends UserInfoEditData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      userInfoId: "",
      duty: "",
    };
  }

  // 첫 실행시 URL에서 userInfoId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const userInfoId = params.get("userInfoId");
    this.setState({
      userInfoId: userInfoId,
    });

    if (
      this.props.userInfoDetail !== null &&
      this.props.userInfoDetail.success
    ) {
      this.setState({
        duty: this.props.userInfoDetail.results.duty, //default값 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //duty 값 변경
  onChangeDuty = (e) => {
    var inputValue = e.target.value;
    this.setState({
      duty: inputValue,
    });
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/userInfoListPage");
  };

  // edit info
  onSubmit = () => {
    this.requestEditUser(this.state.userInfoId, this.state.duty);
  };



  render() {
    let userInfoDetail = null;
    let getDuty = "";
    let myDuty = "";

    if (
      this.props.userInfoDetail != null &&
      this.props.userInfoDetail?.success == true
    ) {
      userInfoDetail = this.props.userInfoDetail.results;
      getDuty = this.props.userInfoDetail.results.duty;
    }

    if (this.props.data != null && this.props.data?.success == true) {
      myDuty = this.props.data.results.duty;
    }

    let selectOptions;
    if (myDuty == "SUPER_ADMIN") {
      selectOptions = ["SUPER_ADMIN", "ADMIN", "USER"];
    } else if (myDuty == "ADMIN") {
      selectOptions = ["ADMIN", "USER"];
    } else if (myDuty == "USER") {
      selectOptions = ["USER"];
    }

    return (
      <div className="userInfoDetail">
        {/* {this.state.isShowAllLoading && <Loading />} */}
        {userInfoDetail !== null && (
          <div className="userdetail_container">
            <div className="userdetail_container_inner">
              <div
                className="title"
                data-detect="admin_admin_user_detail_title"
              >
                USER INFORMATION
              </div>

              <div className="user_information_container">
                <div className="detail_table">
                  <div className="detail_thead">
                    <div className="detail_cell info_email">
                      <span>{userInfoDetail.email}</span>
                    </div>
                    <div className="detail_cell user_duty">
                      <select
                        id="dutyUser"
                        name="duty_select"
                        onChange={(e) => this.onChangeDuty(e)}
                      >
                        {selectOptions.map((option, index) => (
                          <option
                            key={index}
                            value={option.toUpperCase()}
                            selected={option.toUpperCase() == getDuty}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="detail_tbody">
                    <div className="detail_row info_name">
                      <div
                        className="detail_cell index"
                        data-detect="admin_admin_user_detail_label_name"
                      >
                        Name
                      </div>
                      <div className="detail_cell content">
                        <span>{userInfoDetail.name}</span>
                      </div>
                    </div>
                    <div className="detail_row info_name">
                      <div
                        className="detail_cell index"
                        data-detect="admin_admin_user_detail_label_department"
                      >
                        Department
                      </div>
                      <div className="detail_cell content">
                        <span>{userInfoDetail.department}</span>
                      </div>
                    </div>
                    <div className="detail_row info_name">
                      <div
                        className="detail_cell index"
                        data-detect="admin_admin_user_detail_label_duty"
                      >
                        Duty
                      </div>
                      <div className="detail_cell content">
                        <span>{userInfoDetail.duty}</span>
                      </div>
                    </div>
                    <div className="detail_row info_name">
                      <div
                        className="detail_cell index"
                        data-detect="admin_admin_user_detail_label_date"
                      >
                        Date
                      </div>
                      <div className="detail_cell content">
                        <span>
                          {userInfoDetail &&
                            formatDateTimeDot(userInfoDetail.loginAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="button_form_container">
                <div className="gotolist_btn_container">
                  <button
                    className="gotolist_btn"
                    onClick={this.onBackBtn}
                    data-detect="admin_admin_user_detail_button_go_to_list"
                  ></button>
                </div>

                <div className="edit_btn_container">
                  <button
                    className="edit_btn on"
                    onClick={() => {
                      this.onSubmit();
                    }}
                    data-detect="admin_admin_user_detail_button_ok"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let mapStateToProps = UserInfoEditData.mapStateToProps;
let mapDispatchToProps = UserInfoEditData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserInfoEditView)
);
