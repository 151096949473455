import React, { Component } from 'react';

class SelectCheckBoxButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenSelectCheckBox: false,
            selectedCheckboxStatus: '',
            hasFocus: false,
        };

        this.onChangeToggleStatus = this.onChangeToggleStatus.bind(this);
        this.onChangeCheckboxStatus = this.onChangeCheckboxStatus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);

        this.selectBoxRef = React.createRef();
    }

    onChangeToggleStatus() {
        const { disabled } = this.props;

        if (!disabled) {
            this.setState((prevState) => ({
                isOpenSelectCheckBox: !prevState.isOpenSelectBox,
                hasFocus: true,
            }));
        }
    }

    onChangeCheckboxStatus(checkboxStatus) {
        this.setState({
            isOpenSelectCheckBox: false,
            selectedCheckboxStatus: checkboxStatus,
            hasFocus: false,
        },
            this.onCheckValidation);
    }

    // check validation
    onCheckValidation = () => {
        const { selectedCheckboxStatus } = this.state;

        if (selectedCheckboxStatus == 'error') {
        }
    };

    onBlur() {
        this.setState({
            hasFocus: false,
        });
    }

    onClickOutside(event) {
        if (this.selectBoxRef.current && !this.selectBoxRef.current.contains(event.target)) {
            this.setState({
                isOpenSelectBox: false,
            });
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onClickOutside);
    }

    render() {
        const { disabled } = this.props;
        const { isOpenSelectCheckBox, selectedCheckboxStatus, hasFocus } = this.state;
        const isError = selectedCheckboxStatus == 'error';

        return (
            <div className={`myConectSelectCheckBox ${hasFocus ? 'focused' : ''}`}
                onBlur={this.onBlur}
                tabIndex={0}
                ref={this.selectBoxRef}
            >
                <div
                    className={`myConect_input_select_checkBox ${disabled ? 'disabled' : ''} ${isError ? 'inputError' : ''
                        }`}
                    onClick={this.onChangeToggleStatus}
                    readOnly={disabled}
                >
                    {selectedCheckboxStatus == 'checkedState' ? (
                        <input type="checkbox" id="checkedState" className='selectedCheckBoxStatus' checked={true} readOnly />
                    ) : (
                        <input type="checkbox" id="checkedState" className='selectedCheckBoxStatus' checked={false} readOnly />
                    )}

                    <div className='selectBox_line'></div>
                </div>
                {isOpenSelectCheckBox && (
                    <div className="select_options">
                        <div className={"checkedState" == selectedCheckboxStatus ? 'selected' : ''} onClick={() => this.onChangeCheckboxStatus('checkedState')}>
                            <input
                                type="checkbox"
                                id="checkedState"
                                className='checkedState'
                                checked={true}
                            />
                        </div>
                        <div className={"unCheckedState" == selectedCheckboxStatus ? 'selected' : ''} onClick={() => this.onChangeCheckboxStatus('unCheckedState')}>
                            <input
                                type="checkbox"
                                id="unCheckedState"
                                className='unCheckedState'
                                checked={false}
                            />
                        </div>
                        <div className={"errorTest" == selectedCheckboxStatus ? 'selected' : ''} onClick={() => this.onChangeCheckboxStatus('error')}>
                            <span>error</span>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SelectCheckBoxButton;
