import axiosInstance from "../api/axios";

//------------------------------- get List ------------------------------------------------------//
export function onGetAllAmenity(pageNo, search) {
    const offset = (pageNo - 1) * 10;

    return axiosInstance.get(
        '/admin/api/v2/amenity/?content=' + search + '&offset=' + offset + '&limit=' + 10);
}

//------------------------------- get One ------------------------------------------------------//
export function onGetOneAmenity(amenityId) {

    return axiosInstance.get('/admin/api/v2/amenity/' + amenityId);
}

//------------------------------- add Amenity ------------------------------------------------------//
export function onAddAmenity(formData) {

    return axiosInstance.post('/admin/api/v2/amenity', formData);
}
//------------------------------- delete Amenity ------------------------------------------------------//
export function onDeleteAmenity(amenityId) {

    return axiosInstance.delete('/admin/api/v2/amenity/' + amenityId);
}

//------------------------------- edit Amenity ------------------------------------------------------//
export async function onEditAmenity(formData, amenityId) {

    return axiosInstance.put("/admin/api/v2/amenity/" + amenityId, formData);
}