import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import UserTransferLogDetailData from "../Detail/userTransferLogDetailData";

//css
import "../../../css/common.css";
import "../../../css/transfer/userTransferLog/userTransferLogDetail.css";

// utils
import { formatDateTimeDot,  prettyPrint } from "../../../common/Utils";

class UserTransferLogDetailView extends UserTransferLogDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      userTransferLogId: "",
    };
  }

  // 첫 실행시 URL에서 userTransferLogId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const userTransferLogId = params.get("userTransferLogId");
    this.setState({
      userTransferLogId: userTransferLogId,
    });
    this.requestGetUserTransferLogDetail(userTransferLogId);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  render() {
    let userTransferLogDetail = null;

    if (this.props.userTransferLogDetail != null && this.props.userTransferLogDetail?.success == true) {
      userTransferLogDetail = this.props.userTransferLogDetail.results;
    }

    return (
      <div className="userTransferLogDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="userTransferLog_detail_container">
          <div className="userTransferLog_detail_innder">
            <div className="title" data-detect="admin_user_transfer_log_detail_title">
              User Transfer Detail
            </div>
            <div className="userTransferLog_container">
              <div className="userTransferLog_title_container"></div>
              <div className="userTransferLog_info_container">
                <div className="userTransferLog_info_table">
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_id">Id</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span>{userTransferLogDetail?.id??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_type">Type</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span> {userTransferLogDetail?.type??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_method">Method</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span> {userTransferLogDetail?.method??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_path">Path</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span> {userTransferLogDetail?.path??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_header">Header</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogDetail?.header)}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_param">Param</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogDetail?.param)}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_body">Body</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogDetail?.body)}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_soap">Soap</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogDetail?.soap)}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_status">Status</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span>{userTransferLogDetail?.status??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_session">Session</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span className="format">{prettyPrint(userTransferLogDetail?.session)}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_token">Token</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                    <span className="format">{prettyPrint(userTransferLogDetail?.token)}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_user_email">User Email</span>
                    </div>{" "}
                    <div className="userTransferLog_info_cell content">
                      <span>{userTransferLogDetail?.userEmail??"-"}</span>
                    </div>
                  </div>
                  <div className="userTransferLog_info_row">
                    <div className="userTransferLog_info_cell index">
                      <span data-detect="admin_user_transfer_log_detail_label_created_at">Created At</span>
                    </div>
                    <div className="userTransferLog_info_cell content">
                      <span>{userTransferLogDetail ? formatDateTimeDot(userTransferLogDetail.created_at) : "-"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* button */}
            <div className="button_container">
              <div className="gotolist_btn_container">
                <button className="gotolist_btn" onClick={this.onBackBtn} data-detect="admin_hotel_detail_button_go_to_list">
                  Go to List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = UserTransferLogDetailData.mapStateToProps;
let mapDispatchToProps = UserTransferLogDetailData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTransferLogDetailView));
