import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as applicationTranslationApis from "../lib/applicationTranslsation_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const APPLICATION_TRANSLATION_GET_ALL = "applicaitonTranslation/APPLICATION_TRANSLATION_GET_ALL";
const APPLICATION_TRANSLATION_GET_ONE = "applicaitonTranslation/APPLICATION_TRANSLATION_GET_ONE";
const DELETE_APPLICATION_TRANSLATION = "applicaitonTranslation/DELETE_APPLICATION_TRANSLATION";
const ADD_APPLICATION_TRANSLATION = "applicaitonTranslation/ADD_APPLICATION_TRANSLATION";
const EDIT_APPLICATION_TRANSLATION = "applicaitonTranslation/EDIT_APPLICATION_TRANSLATION";

// 액션 생성자
export const onGetAllApplicationTranslation = createAction(APPLICATION_TRANSLATION_GET_ALL, applicationTranslationApis.onGetAllApplicationTranslation);
export const onGetApplicationTranslationOne = createAction(APPLICATION_TRANSLATION_GET_ONE, applicationTranslationApis.onGetApplicationTranslationOne);
export const onAddapplicationTranslation = createAction(ADD_APPLICATION_TRANSLATION, applicationTranslationApis.onAddapplicationTranslation);
export const onEditApplicationTranslation = createAction(EDIT_APPLICATION_TRANSLATION, applicationTranslationApis.onEditApplicationTranslation);
export const onDeleteApplicationTranslation = createAction(DELETE_APPLICATION_TRANSLATION, applicationTranslationApis.onDeleteApplicationTranslation);

// 초기 상태
const initialState = {
  applicationTranslationList: [],
  applicationTranslationAdd: null,
  applicationTranslationDetail: null,
  applicationTranslationEdit: null,
  successDelete: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: APPLICATION_TRANSLATION_GET_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationTranslationList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, applicationTranslationList: [], failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: APPLICATION_TRANSLATION_GET_ONE,
      onPending: (state, action) => {
        return { ...state, applicationTranslationDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationTranslationDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: ADD_APPLICATION_TRANSLATION,
      onPending: (state, action) => {
        return { ...state, applicationTranslationAdd: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationTranslationAdd: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: DELETE_APPLICATION_TRANSLATION,
      onPending: (state, action) => {
        return { ...state, successDelete: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, successDelete: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_APPLICATION_TRANSLATION,
      onPending: (state, action) => {
        return { ...state, applicationTranslationEdit: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, applicationTranslationEdit: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
