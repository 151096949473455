import axiosInstance from "../api/axios";

//termsGroup
//------------------------------- get TermsGroup List ------------------------------------------------------//
export function onGetAllTermsGroup(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/termsGroup/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get TermsGroup One ------------------------------------------------------//
export function onGetOneTermsGroup(termsGroupId) {
  return axiosInstance.get("/admin/api/termsGroup/" + termsGroupId);
}

//------------------------------- add TermsGroup  ------------------------------------------------------//
export function onAddTermsGroup(title, countryCode) {
  const requestData = {
    countryCode: countryCode,
    title: title,
  };

  return axiosInstance.post("/admin/api/termsGroup/", requestData);
}
//------------------------------- delete TermsGroup location ------------------------------------------------------//
export function onDeleteTermsGroup(termsGroupId) {
  return axiosInstance.delete("/admin/api/termsGroup/" + termsGroupId);
}

//------------------------------- edit termsGroup ------------------------------------------------------//
export function onEditTermsGroup(title, countryCode, termsGroupId) {
  const requestData = {
    title: title,
    countryCode: countryCode,
  };

  return axiosInstance.put("/admin/api/termsGroup/" + termsGroupId, requestData);
}

//agreement history
//------------------------------- get All AgreementHistoryList  ------------------------------------------------------//
export function onGetAllAgreementHistoryList(pageNo, email, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/agreementHistory/?email=" + email + "&content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get AgreementHistory One ------------------------------------------------------//
export function onGetAgreementHistory(agreementHistoryId) {
  return axiosInstance.get("/admin/api/agreementHistory/" + agreementHistoryId);
}

//terms
//------------------------------- get Terms List ------------------------------------------------------//
export function onGetAllTerms(pageNo, search) {
  const offset = (pageNo - 1) * 10;

  return axiosInstance.get("/admin/api/terms/?content=" + search + "&offset=" + offset + "&limit=" + 10);
}

//------------------------------- get Terms Group List ------------------------------------------------------//
export function onGetTermsGroupList() {
  return axiosInstance.get("/admin/api/termsGroup/?content=&offset=0&limit=50");
}

//------------------------------- get terms One ------------------------------------------------------//
export function onGetOneTerms(termsId) {
  return axiosInstance.get("/admin/api/terms/" + termsId);
}

//------------------------------- add Terms  ------------------------------------------------------//
export function onAddTerms(formData) {
  return axiosInstance.post("/admin/api/terms/", formData);
}

//------------------------------- edit termsGroup ------------------------------------------------------//
export function onEditTerms(formData, termsId) {
  return axiosInstance.put("/admin/api/terms/" + termsId, formData);
}

//------------------------------- delete TermsGroup location ------------------------------------------------------//
export function onDeleteTerms(termsId) {
  return axiosInstance.delete("/admin/api/terms/" + termsId);
}
