// AuthDataListView.js

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DataTimePicker from "../../components/DateTimePicker/DataTimePicker";
import { Loading, Pagination } from "../../components/Paths";
import AuthDataListData from "../List/authDataListData";

//css
import "../../css/authData/authDataList.css";
import "../../css/common.css";
import "../../css/common/myConectTable.css";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class AuthDataListView extends AuthDataListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      authenticationStatus: "",
      search: "",
      startDate: "",
      endDate: "",

      // 임시값
      tempSearch: "",
      tempAuthenticationStatus: "",
      tempStartDate: "",
      tempEndDate: "",
    };
  }

  componentDidMount() {
    const startDate = new Date();
    const yesterday = startDate.getDate() - 1;
    startDate.setDate(yesterday);

    const endDate = new Date();

    this.setState(
      {
        startDate: startDate, // startDate를 어제로 설정
        endDate: endDate,
        tempStartDate: startDate,
        tempEndDate: endDate,
      },
      () => {
        // endDate 설정 후 redux 호출
        this.requestGetAuthDataList(this.state.pageNo, this.state.authenticationStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
      }
    );
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //datePicker
  onStartDateChange = (startDate) => {
    this.setState({
      tempStartDate: startDate,
    });
  };

  onEndDateChange = (endDate) => {
    this.setState({
      tempEndDate: endDate,
    });
  };

  // authenticationStatus 변경
  onChangeMovementStatus = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempAuthenticationStatus: inputValue,
    });
  };

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        authenticationStatus: this.state.tempAuthenticationStatus,
        startDate: this.state.tempStartDate,
        endDate: this.state.tempEndDate,
      },
      () => {
        this.requestGetAuthDataList(this.state.pageNo, this.state.authenticationStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          authenticationStatus: this.state.tempAuthenticationStatus,
          startDate: this.state.tempStartDate,
          endDate: this.state.tempEndDate,
        },
        () => {
          this.requestGetAuthDataList(this.state.pageNo, this.state.authenticationStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState(
      { isShowAllLoading: true, tempSearch: this.state.search, tempAuthenticationStatus: this.state.authenticationStatus, tempStartDate: this.state.startDate, tempEndDate: this.state.endDate },
      () => {
        this.requestGetAuthDataList(pageNo, this.state.authenticationStatus, this.state.search, this.formatDateISO(this.state.startDate), this.formatDateISO(this.state.endDate));
      }
    );
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    this.setState({
      pageNo: newPageNo,
      isShowAllLoading: true,
    });
  };

  // YYYY-MM-DDTHH:mm:ss.sssZ로 반환
  formatDateISO = (date) => {
    if (!date || date == "") return;
    return date.toISOString();
  };

  render() {
    let count;
    let authDataList = [];

    if (this.props.authDataList && this.props.authDataList?.success == true) {
      authDataList = this.props.authDataList.results.rows;
      count = this.props.authDataList.results.count;
    }

    return (
      <div className="authDataList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="authDataList_list_container">
          <div className="authDataList_list_container_inner">
            <div className="title" data-detect="admin_auth_data_list_title">
              Data verifying the collection ㆍ Use and provision of personal location information
            </div>
            <div className="period_container">
              <div className="period_start">
                <DataTimePicker value={this.state.tempStartDate} onDateTimeChange={this.onStartDateChange} />
              </div>
              <div className="period_line"></div>
              <div className="period_end">
                <DataTimePicker value={this.state.tempEndDate} onDateTimeChange={this.onEndDateChange} />
              </div>
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select
                      id="complete_action_select"
                      className="search_select"
                      name="complete_action_select"
                      onKeyUp={this.handleKeyUp}
                      onChange={this.onChangeMovementStatus}
                      value={this.state.tempAuthenticationStatus}
                    >
                      <option data-detect="admin_auth_data_list_placeholder_select_box_authentication_data" defaultValue value="">
                        Authentication data
                      </option>
                      <option data-detect="admin_auth_data_list_select_hotel_id" value="hotelId">
                        Hotel ID
                      </option>
                      <option data-detect="admin_auth_data_list_select_email" value="email">
                        Email
                      </option>
                    </select>
                    <div
                      style={{
                        position: "relative",
                        top: "-9999px",
                        left: "-9999px",
                        opacity: 0,
                        transform: "translate(-100%, -100%)",
                        height: 0,
                      }}
                    >
                      <span></span>
                    </div>
                  </div>
                  <div className="search">
                    <input type="search" id="pageNo" value="" style={{ display: "none" }} readOnly />
                    <input
                      type="search"
                      id="search_input"
                      data-detect-placeholder="admin_auth_data_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />
                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="authDataList_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell" data-detect="admin_auth_data_list_label_no">
                      NO.
                    </div>
                    <div className="cell" data-detect="admin_auth_data_list_label_user_email">
                      대상
                    </div>
                    <div className="cell" data-detect="admin_amenity_list_label_type">
                      타입
                    </div>
                    <div className="cell" data-detect="admin_auth_data_list_label_acquisition_path">
                      취득경로
                    </div>
                    <div className="cell" data-detect="admin_auth_data_list_label_service">
                      제공 서비스
                    </div>
                    <div className="cell" data-detect="admin_auth_data_list_label_recipient">
                      제공받는 자
                    </div>
                    <div className="cell" data-detect="admin_auth_data_list_label_request_date">
                      이용일시
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {authDataList.length > 0 ? (
                    authDataList.map((authData, index) => {
                      let service = "";
                      let acquisitionPath = "";
                      let recipient = "";
                      var header = authDataList[index].header;
                      var jsonHeader = JSON.parse(header);
                      var index = index + 1 + (this.state.pageNo - 1) * 10;
                      if (authData.path.includes("/staffBeaconCheckLog/beacon")) {
                        service = "호텔인식";
                        acquisitionPath = "마이커넥트";
                      } else if (authData.path.includes("/info/hotelsByCoordinate")) {
                        service = "발렛파킹 가능 호텔 찾기";
                        acquisitionPath = jsonHeader.os;
                      } else if (authData.path.includes("/beaconCheck")) {
                        service = "호텔인식";
                        acquisitionPath = jsonHeader.os;
                      }

                      return (
                        <div className="row tbodyRow" key={authData.id}>
                          <div className="cell">{index}</div>
                          <div className="cell">{authData.userEmail || "-"}</div>
                          <div className="cell">{authData.logType || "-"}</div>
                          <div className="cell">{acquisitionPath}</div>
                          <div className="cell">{service}</div>
                          <div className="cell">{authData.hotel?.name ? authData.hotel.name + " HOTEL" : "-"}</div>
                          <div className="cell">{authData && formatDateTimeSlash(authData.created_at)}</div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_auth_data_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = AuthDataListData.mapStateToProps;
let mapDispatchToProps = AuthDataListData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthDataListView));
