// CreateEmailConfirmAddData.js
import CommonComponent from "../../../components/CommonComponent";

// module
import * as emailFormActions from "../../../../redux/modules/EmailForm";
import { LANGUAGE_CODE_LIST } from '../../../components/common/Language';



// jQuery
class CreateEmailConfirmEditData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      emailFormId: "",
      originalCreateEmailFormTranslations: [],
      langaugeId: [],
      deletableCreateEmailFormTranslations: [],
      editableCreateEmailFormTranslations: [],
      addableCreateEmailFormTranslations: [],
      isShowAllLoading: true,
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "GET_EMAIL_FORM") {
      this.onGetCreateEmailFormResults(props);
    }
    if (this.state.requestType == "DELETE_SUMMERNOTE") {
      this.onDeleteSummernoteResults(props);
    }
    if (this.state.requestType == "ADD_SUMMERNOTE") {
      this.onAddSummernoteResults(props);
    }
    if (this.state.requestType == "EDIT_SUMMERNOTE") {
      this.onEditSummernoteResults(props);
    }
    if (this.state.requestType == "EDIT_DEFAULT_LANGUAGE") {
      this.onEditDefaultLanguageResults(props);
    }
  }

  // redux에 get email form 요청
  requestGetEmailForm = () => {
    this.setState({
      requestType: "GET_EMAIL_FORM",
      isShowAllLoading: true,
    });
    this.props.onGetCreateEmailForm();
  };

  // redux에 순차적으로 delete Summernote 요청
  requestDeleteSummernote = (id) => {
    this.setState({
      requestType: "DELETE_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onDeleteCreateEmailForm(id);
  };

  // redux에 순차적으로 add Summernote 요청
  requestAddSummernote = (emailFormId, content, languageCode) => {
    this.setState({
      requestType: "ADD_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onAddCreateEmailForm(emailFormId, content, languageCode);
  };

  // redux에 순차적으로 edit Summernote 요청
  requestEditSummernote = (id, emailFormId, content, languageCode) => {
    this.setState({
      requestType: "EDIT_SUMMERNOTE",
      isShowAllLoading: true,
    });
    this.props.onEditCreateEmailForm(id, emailFormId, content, languageCode);
  };

  // redux에 edit defulat language summernote 요청
  requestEditDefaultLanguage = (id, content) => {
    this.setState({
      requestType: "EDIT_DEFAULT_LANGUAGE",
      isShowAllLoading: true,
    });
    this.props.onEditDefaultLanguage(id, content);
  };

  // get edit createEmailConfirm results
  onGetCreateEmailFormResults = (props) => {
    if (props.createEmailFormList == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.createEmailFormList?.success == true) {
      this.onGetSuccess(props);

    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_session_message"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  onGetSuccess = (props) => {
    const result = props.createEmailFormList.results;
    this.setState({
      createEmailConfirmFormResult: result,
      originalCreateEmailFormTranslations: result.emailFormTranslation,
      emailFormId: result.id,
    });
  };

  // onDeleteSummernoteResults 함수 수정
  onDeleteSummernoteResults(props) {
    if (props.successCreateEmailFormDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successCreateEmailFormDelete?.success == true) {
      const deletableArray = this.state.deletableCreateEmailFormTranslations.slice(1, this.state.deletableCreateEmailFormTranslations.length);
      this.setState(
        //업데이트
        { deletableCreateEmailFormTranslations: deletableArray },
        () => {
          if (deletableArray.length > 0) {
            this.deleteChangeSummernote(deletableArray[0].id);
          } else {
            // 삭제 완료
            this.onDeleteSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    //add api 호출
    this.addChangeSummernote();
  };

  // onAddSummernoteResults 함수
  onAddSummernoteResults(props) {
    if (props.successCreateEmailFormAdd == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    if (props.successCreateEmailFormAdd?.success == true) {
      const addableArray = this.state.addableCreateEmailFormTranslations.slice(1, this.state.addableCreateEmailFormTranslations.length);
      this.setState(
        //업데이트
        { addableCreateEmailFormTranslations: addableArray },
        () => {
          if (addableArray.length > 0) {
            this.addChangeSummernote();
          } else {
            // 추가 완료
            this.onAddSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_add_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onAddSuccess = () => {
    //edit api 호출
    this.editChangeSummernote();
  };

  onEditSummernoteResults(props) {
    if (props.successCreateEmailFormEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successCreateEmailFormEdit?.success == true) {
      const editableArray = this.state.editableCreateEmailFormTranslations.slice(1, this.state.editableCreateEmailFormTranslations.length);
      this.setState(
        //업데이트
        { editableCreateEmailFormTranslations: editableArray },
        () => {
          if (editableArray.length > 0) {
            this.editChangeSummernote();
          } else {
            // 수정 완료
            this.onEditSuccess();
          }
        }
      );
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_edit_hotel_information"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onEditSuccess = () => {
    this.props.showTopPopupDialog(
      "OK",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_success_message"],
      () => {
        this.props.hideTopPopupDialog();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
        this.requestGetEmailForm();
      }
    );
  };

  onEditDefaultLanguageResults(props) {
    if (props.successDefaultLanguageEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDefaultLanguageEdit?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      if (props.successDefaultLanguageEdit.error == 'Validation error') {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_already_exists'], () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]['contents_failed_send_reservation_email'], () => {
          this.props.hideTopPopupDialog();
        });
      }
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // save 버튼을 눌렀을때 전달
  onSaveBtn = () => {
    this.props.showTopPopupDialog(
      "N",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_modal_save_these_modifications"],
      () => {
        this.props.hideTopPopupDialog();
        this.onSubmit();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  // Add a new Summernote instance
  addSummernote = () => {
    const selectedLanguageCodes = this.state.createEmailConfirmFormResult.emailFormTranslation.map((translation) => translation.languageCode);
    // 아직 선택되지 않은 언어 코드 필터링
    const unusedLanguageCodes = LANGUAGE_CODE_LIST.filter((code) => !selectedLanguageCodes.includes(code));
    if (unusedLanguageCodes.length > 0) {
      const selectedCode = unusedLanguageCodes[0];
      const newCreateEmailConfirmFormTranslation = {
        //update data
        id: -1,
        emailFormId: this.state.emailFormId,
        languageCode: selectedCode,
        content: "",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      const createEmailConfirmFormResult = {
        ...this.state.createEmailConfirmFormResult,
        emailFormTranslation: [...this.state.createEmailConfirmFormResult.emailFormTranslation, newCreateEmailConfirmFormTranslation],
      };
      this.setState({ createEmailConfirmFormResult: createEmailConfirmFormResult }, () => {
        this.onCheckValidation();
      });
    }

    //alert 수정
    else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_all_language_code_exist"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  //summernote 삭제
  deleteSummernote = (deletedIndex) => {
    const createEmailConfirmFormResult = { ...this.state.createEmailConfirmFormResult, emailFormTranslation: [...this.state.createEmailConfirmFormResult.emailFormTranslation] };
    createEmailConfirmFormResult.emailFormTranslation.splice(deletedIndex, 1);

    this.setState({ createEmailConfirmFormResult: createEmailConfirmFormResult }, () => {
      this.onCheckValidation();
    });
  };

  //수정된 summernote 값
  editSummernote = (content, index, languageCode) => {
    const createEmailConfirmFormResult = { ...this.state.createEmailConfirmFormResult, emailFormTranslation: [...this.state.createEmailConfirmFormResult.emailFormTranslation] };
    if (index == -1) {
      // 기본 언어 코드의 content를 수정
      createEmailConfirmFormResult.content = content;
    } else {
      // 그 외 언어 코드의 content를 수정
      createEmailConfirmFormResult.emailFormTranslation[index].content = content;
    }

    this.setState({ createEmailConfirmFormResult: createEmailConfirmFormResult }, () => {
      this.onCheckValidation();
    });
  };

  //저장 버튼 누르면 기존 값과 비교
  onSubmit = () => {
    // 기본언어 코드 수정 요청
    this.requestEditDefaultLanguage(this.state.createEmailConfirmFormResult.id, this.state.createEmailConfirmFormResult.content);

    // 나머지 언어 코드 수정 요청
    let updateEmailFormTranslation = this.state.createEmailConfirmFormResult.emailFormTranslation; //업데이트 된 코드
    let originalEmailFormTranslation = this.state.originalCreateEmailFormTranslations; //기존 코드

    //업데이트된 코드가 가지고있는 언어코드
    const updatedIds = updateEmailFormTranslation.map((item) => item.id);

    // 삭제 해야할 emailFormTranslation 값
    const deletableCreateEmailFormTranslations = originalEmailFormTranslation.filter((translation) => !updatedIds.includes(translation.id));

    // 추가 해야할 emailFormTranslation 값
    const addableCreateEmailFormTranslations = updateEmailFormTranslation.filter((translation) => translation.id == -1);

    // 수정할 emailFormTranslation 값
    const editableCreateEmailFormTranslations = updateEmailFormTranslation.filter((translation) => translation.id != -1);

    this.setState(
      {
        deletableCreateEmailFormTranslations: deletableCreateEmailFormTranslations,
        addableCreateEmailFormTranslations: addableCreateEmailFormTranslations,
        editableCreateEmailFormTranslations: editableCreateEmailFormTranslations,
      },
      () => {
        this.deleteChangeSummernote();
      }
    );
  };

  //삭제
  deleteChangeSummernote = () => {
    const deletableArray = this.state.deletableCreateEmailFormTranslations;
    if (deletableArray.length > 0) {
      this.requestDeleteSummernote(deletableArray[0].id);
    } else {
      //삭제할 값이 없으먼 add함수 호출
      this.addChangeSummernote();
    }
  };

  //add
  addChangeSummernote = () => {
    const addableCreateEmailFormTranslations = this.state.addableCreateEmailFormTranslations;
    if (addableCreateEmailFormTranslations.length > 0) {
      const firstItem = addableCreateEmailFormTranslations[0];
      // requestAddSummernote 함수에 첫 번째 값의 id와 content를 전달
      this.requestAddSummernote(firstItem.emailFormId, firstItem.content, firstItem.languageCode);
    } else {
      this.editChangeSummernote();
    }
  };

  //수정
  editChangeSummernote = () => {
    const editableCreateEmailFormTranslations = this.state.editableCreateEmailFormTranslations;
    if (editableCreateEmailFormTranslations.length > 0) {
      const firstItem = editableCreateEmailFormTranslations[0];
      // requestAddSummernote 함수에 첫 번째 값의 id와 content를 전달
      this.requestEditSummernote(firstItem.id, firstItem.emailFormId, firstItem.content, firstItem.languageCode);
    } else {
      this.onEditSuccess();
    }
  };

  //언어 코드 변경
  onChangeLanguageCode = (e, index) => {
    const selectedCode = e.target.value; // 셀렉트 박스에서 선택된 언어 코드

    this.state.createEmailConfirmFormResult.emailFormTranslation[index].languageCode = selectedCode;

    this.setState({
      createEmailConfirmFormResult: this.state.createEmailConfirmFormResult,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      createEmailFormList: state.emailForm.createEmailFormList,
      successCreateEmailFormDelete: state.emailForm.successCreateEmailFormDelete,
      successCreateEmailFormAdd: state.emailForm.successCreateEmailFormAdd,
      successCreateEmailFormEdit: state.emailForm.successCreateEmailFormEdit,
      successDefaultLanguageEdit: state.emailForm.successDefaultLanguageEdit,
      failData: state.emailForm.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetCreateEmailForm: () => dispatch(emailFormActions.onGetCreateEmailForm()),
      onDeleteCreateEmailForm: (id) => dispatch(emailFormActions.onDeleteCreateEmailForm(id)),
      onAddCreateEmailForm: (emailFormId, content, languageCode) => dispatch(emailFormActions.onAddCreateEmailForm(emailFormId, content, languageCode)),
      onEditCreateEmailForm: (id, emailFormId, content, languageCode) => dispatch(emailFormActions.onEditCreateEmailForm(id, emailFormId, content, languageCode)),
      onEditDefaultLanguage: (id, content) => dispatch(emailFormActions.onEditDefaultLanguageForm(id, content)),
    };
  };
}

export default CreateEmailConfirmEditData;
