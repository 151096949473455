// CountryListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading, Pagination } from "../../../components/Paths";
import CountryListData from "../List/CountryListData";


//css
import "../../../css/common.css";
import "../../../css/common/myConectTable.css";
import "../../../css/location/country/countryList.css";

// icon
import addIcon from "../../../img/button_add_white.svg";
import deleteIcon from "../../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../../common/Utils";

class CountryListView extends CountryListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      search: "",
      regionId: "",
      regionName: "",
      checkedList: [],

      // 임시값
      tempSearch: "",
      tempRegionId: "",
      tempRegionName: "",
    };
  }

  //초기값 pageNo=1 , content=null;
  componentDidMount() {
    const { history, location } = this.props;

    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      this.requestGetRegionList(this.state.pageNo, this.state.search);
      this.requestGetCountryList(this.state.pageNo, this.state.search, this.state.regionId);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // search value값 변경
  onChangeSearchText = (e) => {
    const inputValue = e.target.value;
    this.setState({
      tempSearch: inputValue,
    });
  };

  //regionId값 변경
  onChangeRegion = (regionId, regionName) => {
    this.setState({
      tempRegionId: regionId,
      tempRegionName: regionName,
    });
  };

  //검색 버튼을 누르면
  onClickSearch = (e) => {
    const { history, location } = this.props;
    this.setState(
      {
        isShowAllLoading: true,
        pageNo: 1,
        search: this.state.tempSearch,
        regionId: this.state.tempRegionId,
      },
      () => {
        this.requestGetCountryList(this.state.pageNo, this.state.search, this.state.regionId);
        history.replace(location.pathname, this.state);
      }
    );
  };

  // search 엔터키
  handleKeyUp = (e) => {
    const { history, location } = this.props;
    if (window.event.keyCode == 13) {
      this.setState(
        {
          isShowAllLoading: true,
          pageNo: 1,
          search: this.state.tempSearch,
          regionId: this.state.tempRegionId,
        },
        () => {
          this.requestGetCountryList(this.state.pageNo, this.state.search, this.state.regionId);
          history.replace(location.pathname, this.state);
        }
      );
    }
  };

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리

    this.setState(
      {
        isShowAllLoading: true,
        tempSearch: this.state.search,
        tempRegionId: this.state.regionId,
        tempRegionName: this.state.regionName,
      },
      () => {
        // 페이지 클릭했을 때 넘어가는 로직
        this.requestGetCountryList(pageNo, this.state.search, this.state.regionId);
      }
    );
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkCountryDetail(id);
    }
  }

  // //파람값 url로 전달
  linkCountryDetail = (countryId) => {
    this.props.history.push(`/countryDetailPage?countryId=${countryId}`);
  };

  //insert
  linkCountryAdd = () => {
    this.props.history.push("/countryAddPage");
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  findSelectedName = (value, list) => {
    let selectedName = "";
    let selectedId = "";
    if (value != "" && list.length > 0) {
      const selectedRow = list.find((row) => row.name == value);
      selectedName = selectedRow.name;
      selectedId = selectedRow.id;
    }
    return { name: selectedName, id: selectedId };
  }

  render() {
    let count;
    let countryList = [];
    let regionList = [];
    if (this.props.countryList.length !== 0 && this.props.countryList?.success == true) {
      count = this.props.countryList.results.count;
      countryList = this.props.countryList.results.rows;
    }
    if (this.props.regionList.length !== 0 && this.props.regionList?.success == true) {
      regionList = this.props.regionList.results.rows;
    }

    return (
      <div className="countryList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="country_list_container">
          <div className="country_list_container_inner">
            <div className="title" data-detect="admin_country_list_title">
              REGION LIST
            </div>
            <div className="btn_container">
              <div className="btn_container_right">
                <div className="search_container">
                  <div className="find">
                    <select
                      style={{ marginRight: "6px" }}
                      id="regionSelect"
                      onChange={(e) => {
                        const value = e.target.value;
                        const { id, name } = this.findSelectedName(value, regionList);
                        this.onChangeRegion(id, name);
                      }}
                      value={this.state.tempRegionName}
                    >
                      <option value="" data-detect="admin_country_list_select_region_all">
                        Region ( ALL )
                      </option>
                      {regionList.length > 0 &&
                        regionList.map((region) => (
                          <option key={region.id} value={region.name}>
                            {region.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="search">
                    <input
                      type="text"
                      id="search"
                      data-detect-placeholder="admin_country_list_placeholder_search"
                      placeholder="Search..."
                      onKeyUp={this.handleKeyUp}
                      value={this.state.tempSearch}
                      onChange={this.onChangeSearchText}
                    />

                    <button type="button" className="search_btn" onClick={this.onClickSearch}></button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- country list table  --> */}
            <div className="country_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() => this.allCheck(countryList, this.state.pageNo)}
                        checked={countryList && this.state.checkedList.length == countryList.length}
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div className="cell" data-detect="admin_country_add_label_region">
                      Region.
                    </div>
                    <div className="cell" data-detect="admin_country_add_label_country">
                      Country No.
                    </div>
                    <div className="cell" data-detect="admin_country_list_label_date">
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {countryList?.length > 0 ? (
                    countryList.map((country, index) => (
                      <div className="row tbodyRow" key={country.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={country.id}
                            id={country.id}
                            onClick={() => this.onClickInput(country.id, countryList)}
                            checked={this.state.checkedList.includes(country.id)}
                            readOnly
                          />
                          <label className="tbodyLabel" htmlFor={country.id}></label>
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(country.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {country.hotelRegion.name}
                        </div>
                        <div className="cell ellipsis" onMouseUp={() => this.handleMouseUp(country.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {country.name}
                        </div>
                        <div className="cell" onMouseUp={() => this.handleMouseUp(country.id)} onMouseMove={this.handleMouseMove} onMouseDown={this.handleMouseDown}>
                          {formatDateTimeSlash(country.created_at)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data-in-list" data-detect="admin_country_list_guide_empty">
                      List is empty
                    </div>
                  )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedCountrys();
                  }}
                >
                  <img src={deleteIcon} alt="delete button" />
                  <span data-detect="admin_country_list_button_delete">Delete</span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkCountryAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_country_list_button_add">Add</span>
                </button>
              </div>

              <Pagination total={count} dataSize={"10"} pageSize={"10"} handlePageChange={this.handlePageChange} pageNo={this.state.pageNo} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CountryListData.mapStateToProps;
let mapDispatchToProps = CountryListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CountryListView)
);
