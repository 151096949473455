import CommonComponent from "../../../components/CommonComponent";

// modules
import * as versionActions from "../../../../redux/modules/Version";


class ApplicationVersionListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      aosList: [],
      iosList: [],
      applicationVersionList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.aosList != this.props.aosList) {
      if (this.state.requestType == 'GET_AOS_LIST') {
        this.onGetAOSListResults(nextProps);
      }
    }
    if (nextProps.iosList != this.props.iosList) {
      if (this.state.requestType == 'GET_IOS_LIST') {
        this.onGetIOSListResults(nextProps);
      }
    }
    if (nextProps.applicationVersionList != this.props.applicationVersionList) {
      if (this.state.requestType == 'GET_APPLICATIONVERSION_LIST') {
        this.onGetApplicationVersionListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_APPLICATIONVERSION_ONE") {
      this.onDeleteApplicationVersionListResults(nextProps);
    }
  }

 

  // redux에 get AOS list 요청
  requestGetAOSVersionLIst = (os) => {
    this.setState({
      requestType: "GET_AOS_LIST",
    });
    this.props.onGetaosAll(os);
  };

  // redux에 get IOS list 요청
  requestGetIOSVersionList = (os) => {
    this.setState({
      requestType: "GET_IOS_LIST",
    });
    this.props.onGetiosAll(os);
  };

  // redux에 get applicationVersion list 요청
  requestGetApplicationVersionList = (pageNo, search) => {
    this.setState({
      requestType: "GET_APPLICATIONVERSION_LIST",
    });
    this.props.onGetAll(pageNo, search);
  };
  // redux에 순차적으로 delete applicationVersion 요청
  requestDeleteApplicationVersion = (applicationVersionId) => {
    this.setState({
      requestType: "DELETE_APPLICATIONVERSION_ONE",
    });
    this.props.onDeleteOne(applicationVersionId);
  };

  // get applicationVersion list results
  onGetApplicationVersionListResults = (props) => {
    if (props.applicationVersionList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationVersionList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // get AOS list results
  onGetAOSListResults = (props) => {
    if (props.aosList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.aosList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // get IOS list results
  onGetIOSListResults = (props) => {
    if (props.iosList?.length == 0 && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.iosList?.success == true) {
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // // onDeleteApplicationVersionListResults 함수 수정
  onDeleteApplicationVersionListResults(props) {
    if (props.applicationVersionDelete == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.applicationVersionDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteApplicationVersion();
        } else {
          // 삭제 완료
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_delete"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetAOSVersionLIst("aos"); //AOS LIST 호출
    this.requestGetIOSVersionList("iOS"); //IOS LIST 호출
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
      isShowAllLoading: true,
    });

  };

  deleteSelectedApplicationVersions = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteApplicationVersion();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteApplicationVersion = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      // this.props.onClearApplicationVersionDeleteState()
      this.requestDeleteApplicationVersion(checkedList[0]);
    }
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/applicationVersionListPage");
  };

  // //전체 체크박스 클릭시 checkedList
  allCheck = (versionList, pageNo) => {
    const { checkedList } = this.state;
    const versionListCnt = versionList.length;
    const isChecked = versionListCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : versionList.map((version) => version.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      applicationVersionList: state.version.applicationVersionList,
      aosList: state.version.aosList,
      iosList: state.version.iosList,
      failData: state.version.failData,
      applicationVersionDelete: state.version.applicationVersionDelete,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetAll: (pageNo, search) => dispatch(versionActions.onGetAllApplicationVersion(pageNo, search)),
      onGetaosAll: (os) => dispatch(versionActions.onGetaosAll(os)),
      onGetiosAll: (os) => dispatch(versionActions.onGetiosAll(os)),
      onDeleteOne: (applicationVersionId) => dispatch(versionActions.onDeleteApplicationVersion(applicationVersionId)),
    };

    return returnProps;
  };
}

export default ApplicationVersionListData;
