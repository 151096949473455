import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as settingApis from "../lib/setting_apis";
import { PURGE } from "redux-persist";


// 액션 타입
const SETTING_GET_ALL = "setting/HOTEL_GET_ALL";
const EDIT_SETTING = "setting/EDIT_SETTING";

// 액션 생성자
export const onGetAll = createAction(SETTING_GET_ALL, settingApis.onGetAll);
export const onUpdate = createAction(EDIT_SETTING, settingApis.onUpdate);

// 초기 상태
const initialState = {
  settingList: null,
  updateList: null,
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: SETTING_GET_ALL,
      onPending: (state, action) => {
        return { ...state, settingList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, settingList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    ...pender({
      type: EDIT_SETTING,
      onPending: (state, action) => {
        return { ...state, updateList: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, updateList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
  },
  },
  initialState
);
