// FacilityImageListView.js

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import serverUrl from "../../../react/common/ServerUrl";
import { Loading, Pagination } from "../../components/Paths";
import FacilityImageListData from "../List/FacilityImageListData";

//css
import "../../css/common.css";
import "../../css/common/myConectTable.css";
import "../../css/facilityImage/facilityImageList.css";

// icon
import addIcon from "../../img/button_add_white.svg";
import deleteIcon from "../../img/button_delete_white.svg";

// utils
import { formatDateTimeSlash } from "../../common/Utils";

class FacilityImageListView extends FacilityImageListData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      pageNo: 1,
      checkedList: [],
    };
  }

  componentDidMount() {
    const { history, location } = this.props;
    if (location.state && history.action === "POP") {
      this.setState({
        ...location.state,
        isShowAllLoading: false,
      });
    } else {
      // 컴포넌트가 처음 마운트될 때 list API 호출
      this.requestGetFacilityImageList(this.state.pageNo);
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // page 이동
  handlePageChange = (pageNo) => {
    this.onPageChange(pageNo); // 페이지 변경 처리
    this.setState({ isShowAllLoading: true }, () => {
      this.requestGetFacilityImageList(pageNo); // 호텔 목록 요청
    });
  };

  // mouse가 움직이 때 isMoved를 true로 설정
  handleMouseMove = () => {
    const { isMoved } = this.state;
    if(isMoved) return;

    this.setState({
      isMoved: true
    })
  }

  // mouse로 클릭하거나 드래그를 시작할 때 isMoved를 false로 초기화
  handleMouseDown = () => {
    this.setState({
      isMoved: false
    })
  }

  // mouseup할 때 사용자가 클릭했는지 드래그를 했는지를 확인
  handleMouseUp = (id) => {
    const { isMoved } = this.state;
    if(isMoved){
      // 먀우스로 드래그 했을 경우
    } else {
      // 마우스가 드래그 하지 않고 클릭했을 경우
      this.linkFacilityImageDetail(id);
    }
  }

  //파람값 url로 전달
  linkFacilityImageDetail = (facilityImageId) => {
    this.props.history.push(
      `/facilityImageDetailPage?facilityImageId=${facilityImageId}`
    );
  };

  //insert
  linkFacilityImageAdd = () => {
    this.props.history.push("/facilityImageAddPage");
  };


  //pageNo 변경시 checkedList값 초기화
  onPageChange = (newPageNo) => {
    const { history, location } = this.props;
    this.setState({
      pageNo: newPageNo,
      checkedList: [],
    }, () => {
      history.replace(location.pathname, this.state);
    });
  };

  render() {
    let count;
    let facilityImageList = [];
    if (
      this.props.facilityImageList &&
      this.props.facilityImageList?.success == true
    ) {
      count = this.props.facilityImageList.results.count;
      facilityImageList = this.props.facilityImageList.results.rows;
    }

    return (
      <div className="facilityImageList">
        {this.state.isShowAllLoading && <Loading />}
        <div className="facilityImage_list_container">
          <div className="facilityImage_list_container_inner">
            <div className="title" data-detect="admin_facility_img_list_title">
              FACILITY IMAGE LIST
            </div>

            {/* <!-- facilityImage list table  --> */}
            <div className="facilityImage_list_table">
              <div className="table">
                <div className="thead">
                  <div className="row">
                    <div id="theadFistCell" className="cell">
                      <input
                        type="checkbox"
                        id="allCheck"
                        onClick={() =>
                          this.allCheck(facilityImageList, this.state.pageNo)
                        }
                        checked={
                          facilityImageList &&
                          this.state.checkedList.length ==
                            facilityImageList.length
                        }
                        readOnly
                      />
                      <label id="theadLabel" htmlFor="allCheck"></label>
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_facility_img_list_label_facility_img"
                    >
                      Facility Image
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_facility_img_list_label_description"
                    >
                      Description
                    </div>
                    <div
                      className="cell"
                      data-detect="admin_facility_img_list_label_date"
                    >
                      Date
                    </div>
                  </div>
                </div>
                <div className="tbody">
                  {facilityImageList?.length > 0 ?(
                    facilityImageList.map((facilityImage, index) => (
                      <div className="row tbodyRow" key={facilityImage.id}>
                        <div className="cell">
                          <div className="eachIndexNo blind">
                            <span>{index + 1}</span>
                          </div>
                          <input
                            type="checkbox"
                            name="eachCheck"
                            key={facilityImage.id}
                            id={facilityImage.id}
                            onClick={() =>
                              this.onClickInput(
                                facilityImage.id,
                                facilityImageList
                              )
                            }
                            checked={this.state.checkedList.includes(
                              facilityImage.id
                            )}
                            readOnly
                          />
                          <label
                            className="tbodyLabel"
                            htmlFor={facilityImage.id}
                          ></label>
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(facilityImage.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          <span className="imageDiv">
                            <img
                              className="image_form"
                              src={
                                serverUrl +
                                "/image/facility/" +
                                facilityImage.fileId
                              }
                              alt="icon"
                            />
                          </span>
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(facilityImage.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {facilityImage.description}
                        </div>
                        <div
                          className="cell ellipsis"
                          onMouseUp={() => this.handleMouseUp(facilityImage.id)}
                          onMouseMove={this.handleMouseMove}
                          onMouseDown={this.handleMouseDown}
                        >
                          {facilityImage &&
                            formatDateTimeSlash(facilityImage.created_at)}
                        </div>
                      </div>
                    ))): (
                      <div className="no-data-in-list" data-detect="admin_facility_img_list_guide_empty">List is empty</div>
                    )}
                </div>
              </div>
              <div className="btn_container_left">
                <button
                  className="delete_btn"
                  id="delete_btn"
                  type="button"
                  onClick={() => {
                    this.deleteSelectedFacilityImages();
                  }}
                >
                  <img src={deleteIcon} alt="delete button"/>
                  <span data-detect="admin_facility_img_list_button_delete">
                    Delete
                  </span>
                </button>
                <button
                  className="add_btn"
                  id="add_btn"
                  type="button"
                  onClick={() => {
                    this.linkFacilityImageAdd();
                  }}
                >
                  <img src={addIcon} alt="add button" />
                  <span data-detect="admin_facility_img_list_button_add">
                    Add
                  </span>
                </button>
              </div>

              <Pagination
                total={count}
                dataSize={"10"}
                pageSize={"10"}
                handlePageChange={this.handlePageChange}
                pageNo={this.state.pageNo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = FacilityImageListData.mapStateToProps;
let mapDispatchToProps = FacilityImageListData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FacilityImageListView)
);
