import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import CityAddData from "../Add/CityAddData";


//css
import "../../../css/common.css";
import "../../../css/location/city/cityAdd.css";

class CityAddView extends CityAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      city: "",
      regionId: "",
      countryId: "",
      selectedCountry: "",
      regionList: [],
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
    if (this.props.regionList.length !== 0 && this.props.regionList.success) {
      this.setState({
        regionList: this.props.regionList.results.rows, //default값 설정
      });
    }
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  // change city
  onChangeCity = (e) => {
    const inputValue = e.target.value;
    this.setState({ city: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change regionId값
  onChangeRegion = (e) => {
    const inputValue = e;
    this.setState({ regionId: inputValue }, () => {
      this.requestGetCountry(this.state.regionId);
      this.onCheckValidation();
    });
  };
  // change countryId값
  onChangeCountry = (e) => {
    const inputValue = e;
    this.setState({ countryId: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (
      this.confirmField("regionId", "admin_city_add_validation_region") &&
      this.confirmField("countryId", "admin_city_add_validation_country") &&
      this.confirmField("city", "admin_city_add_validation_city")
    ) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddCity(this.state.regionId, this.state.countryId, this.state.city);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/cityListPage");
  };

  render() {
    let regionList = [];
    let cityList = [];

    if (this.props.regionList.length !== 0 && this.props.regionList?.success == true) {
      regionList = this.props.regionList.results.rows;
    }
    if (this.props.cityData.length !== 0 && this.props.cityData?.success == true) {
      cityList = this.props.cityData.results.rows;
    }
    return (
      <div className="addCity">
        {this.state.isShowAllLoading && <Loading />}
        <div className="country_add_container">
          <div className="addcountry_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_city_add_title">ADD CITY</p>
              </div>
              <div className="sub_text" style={{ color: "white" }}>
                <p data-detect="admin_city_add_guide_click_add">Enter information to add CITY. And Click the "Add" button.</p>
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addcity_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addcity_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_city_add_label_region">Region</p>
                      </div>
                      <div className="detail_cell content region_name">
                        <div className="detail_selectBox">
                          <select
                            className="region_select"
                            style={{ marginRight: "6px" }}
                            id="regionSelect"
                            onChange={(e) => {
                              let selectedId = ""; // 기본값으로 null 설정
                              if (e.target.value !== "") {
                                const selectedRow = regionList.find((row) => row.name == e.target.value);
                                selectedId = selectedRow.id;
                              }
                              this.onChangeRegion(selectedId);
                            }}
                            defaultValue={""}
                          >
                            <option disabled value="" data-detect="admin_city_add_label_choose">
                              Choose
                            </option>
                            {regionList.length > 0 &&
                              regionList.map((region) => (
                                <option key={region.id} value={region.name}>
                                  {region.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_city_add_label_country">Country</p>
                      </div>
                      <div className="detail_cell content country_name">
                        <div className="detail_selectBox">
                          <select
                            className="country_select"
                            style={{ marginRight: "6px" }}
                            id="countrySelect"
                            onChange={(e) => {
                              this.onChangeCountry(e.target.value);
                            }}
                            value={this.state.countryId}
                          >
                            <option className="all" disabled value="" data-detect="admin_city_add_label_choose">
                              Choose
                            </option>
                            {cityList.length > 0 &&
                              cityList.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="detail_row">
                      <div className="detail_cell index">
                        <p data-detect="admin_hotel_detail_label_city">City</p>
                      </div>
                      <div className="detail_cell content city_name">
                        <input
                          type="text"
                          id="city"
                          name="city"
                          data-detect-placeholder="admin_city_add_placeholder_city_name"
                          placeholder="City Name"
                          autoComplete="off"
                          value={this.state.city}
                          onChange={this.onChangeCity}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_city_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_city_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = CityAddData.mapStateToProps;
let mapDispatchToProps = CityAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CityAddView));
