import React from "react";
import CommonComponent from "../../components/CommonComponent";

// module
import * as bookingEmailActions from "../../../redux/modules/bookingEmail";



class BookingEmailDetailData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
    };
  }

  componentWillReceiveProps(props) {
    if (this.state.requestType == "EDIT_BOOKING_EMAIL") {
      this.onEditBookingEmailOneResults(props);
    }
  }

  // redux에 edit bookingEmail 요청
  requestEditBookingEmail = (bookingEmailFile, bookingEmailText) => {
    const formData = new FormData();
    formData.append("emailFile", bookingEmailFile);
    formData.append("emailText", bookingEmailText);

    this.setState({
      requestType: "EDIT_BOOKING_EMAIL",
    });
    this.props.onEdit(formData);
  };

  onEditBookingEmailOneResults(props) {
    if (props.bookingEmailEdit == null && props.failData == null) {
      return;
    }
    this.setState({ isShowAllLoading: false, requestType: "" });
    
    if (props.bookingEmailEdit?.success == true) {
      // 수정이 완료된 후에 페이지 이동
      this.onEditSuccess(props);
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_image"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  }

  onEditSuccess = (props) => {
    this.setState({
      bookingEmailText: "",
      bookingEmailFile: "",
      logoUrl: "",
    });

    const url = props.bookingEmailEdit?.results.url;
    if (url) {
      const message = (
        <span>
          <strong>업로드 완료</strong> {url}.
        </span>
      );
      this.props.showTopPopupDialog(
        "OK",
        message,
        () => {
          this.props.hideTopPopupDialog();
        }, // OK 버튼 클릭 시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    // this.props.onClearBookingEmailEditState()
    this.props.showTopPopupDialog(
      "N",
      // "Are you sure you want to delete it?",
      this.props.MultiLanguage["language"][this.props.multiLanguageCode][
        "contents_modal_save_these_modifications"
      ],
      () => {
        this.props.hideTopPopupDialog();
        this.editBookingEmail();
      }, //ok 버튼시
      () => {
        this.props.hideTopPopupDialog();
      },
      () => {
        this.props.hideTopPopupDialog();
      }
    );
  };

  editBookingEmail = () => {
    // 현재 선택된 값들을 default로 저장
    this.requestEditBookingEmail(
      this.state.bookingEmailFile,
      this.state.bookingEmailText
    );
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      bookingEmailEdit: state.bookingEmail.bookingEmailEdit,
      failData: state.bookingEmail.failData,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    return {
      //commonComponent props값 추가
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onEdit: (formData) =>
        dispatch(bookingEmailActions.onEditBookingEmail(formData)),
    };
  };
}

export default BookingEmailDetailData;
