import CommonComponent from "../../components/CommonComponent";

// modules
import * as authActions from "../../../redux/modules/Auth";
import * as hotelActions from "../../../redux/modules/Hotel";


class HotelListData extends CommonComponent {
  constructor(props) {
    super(props);
    this.state = {
      responseType: "",
      checkedList: [],
      hotelList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hotelList != this.props.hotelList) {
      if (this.state.requestType == 'GET_HOTEL_LIST') {
        this.onGetHotelListResults(nextProps);
      }
    }
    if (this.state.requestType == "DELETE_HOTEL_ONE") {
      this.onDeleteHotelListResults(nextProps);
    }
  }

  // redux에 get hotel list 요청
  requestGetHotelList = (pageNo, search) => {
    this.setState({
      requestType: "GET_HOTEL_LIST",
    });
    this.props.onGetAll(pageNo, search);
  };
  
  // redux에 순차적으로 delete hotel 요청
  requestDeleteHotel = (hotelId) => {
    this.setState({
      requestType: "DELETE_HOTEL_ONE",
    });
    this.props.onDeleteOne(hotelId);
  };

  // get hotel list results
  onGetHotelListResults = (props) => {
    if (props.hotelList?.length == 0 && props.failData == null) {
      return;
    }

    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.hotelList?.success == true) {
      // 성공
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      // 실패
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      // INVALID_TOKEN
      this.onFailed(props);
    }
  };

  // onDeleteHotelListResults 함수 수정
  onDeleteHotelListResults(props) {
    if (props.successDelete == null && props.failData == null) {
      return;
    }

    this.setState({ isShowAllLoading: false, requestType: "" });

    if (props.successDelete?.success == true) {
      var checkedList = [...this.state.checkedList];
      checkedList = this.state.checkedList.slice(1, checkedList.length);
      this.setState({ checkedList: checkedList }, () => {
        if (checkedList.length > 0) {
          this.deleteHotel();
        } else {
          // 수정이 완료된 후에 페이지 이동
          this.onDeleteSuccess();
        }
      });
    } else if (props.failData?.code != "INVALID_TOKEN" && props.failData?.code != "EMPTY_TOKEN") {
      this.props.showSimpleTopPopupDialog(this.props.MultiLanguage["language"][this.props.multiLanguageCode]["contents_failed_get_list"], () => {
        this.props.hideTopPopupDialog();
      });
    } else {
      this.onFailed(props);
    }
  }

  onDeleteSuccess = () => {
    this.setState({ isShowAllLoading: true });
    this.requestGetHotelList(this.state.pageNo, this.state.search);
  };

  onFailed = (props) => {
    if (props.failData !== null) {
      let code = props.failData.code;
      let reason = props.failData.reason;
      if (code === "") {
        this.props.showSimpleTopPopupDialog(reason, () => {
          this.props.hideTopPopupDialog();
        });
      } else {
        this.props.occuredCommonError(code, reason);
      }
    } else {
      this.props.showSimpleTopPopupDialog("unknown error.", () => {
        this.props.hideTopPopupDialog();
      });
    }
  };

  // 개별 체크박스 클릭 값 저장
  onClickInput = (id, hotelList) => {
    const currentCheckedList = [...this.state.checkedList];

    // 클릭한 ID가 이미 배열에 포함되어 있는지 확인
    const index = currentCheckedList.indexOf(id);

    //포함 여부 체크
    if (index == -1) {
      currentCheckedList.push(id);
    } else {
      currentCheckedList.splice(index, 1);
    }
    this.setState({
      checkedList: currentCheckedList,
    });
  };

  deleteSelectedHotels = () => {
    const { checkedList } = this.state;
    this.setState({ requestType: "" });
    //선택한 체크박스가 있는 경우
    if (checkedList.length > 0) {
      this.props.showTopPopupDialog(
        "N",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
          this.deleteHotel();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        } //cancel 버튼시
      );
      //체크박스를 선택하지 않은 경우
    } else {
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_delete_message"],
        () => {
          this.props.hideTopPopupDialog();
        }, //ok 버튼시
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  deleteHotel = () => {
    const { checkedList } = this.state;
    if (checkedList.length > 0) {
      // this.props.onClearHotelDeleteState()
      this.requestDeleteHotel(checkedList[0]);
    }
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/hotelListPage");
  };

  //전체 체크박스 클릭시 checkedList
  allCheck = (hotelList) => {
    const { checkedList } = this.state;
    const allHotelCnt = hotelList.length;
    const isChecked = allHotelCnt == checkedList.length;
    const updatedCheckedList = isChecked ? [] : hotelList.map((hotel) => hotel.id);
    this.setState({
      checkedList: updatedCheckedList,
    });
  };

  //copy hotel
  copyHotel = () => {
    const { checkedList } = this.state;

    if (checkedList.length == 1) {
      const copyId = checkedList[0];
      this.props.history.push(`/hotelcopyPage?copyId=${copyId}`);
    } else if (checkedList.length > 1) {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_only_one_hotel_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
      //초기화
      this.setState({
        checkedList: [],
      });
    } else {
      this.setState({ requestType: "" });
      this.props.showTopPopupDialog(
        "OK",
        this.props.MultiLanguage["language"][this.props.multiLanguageCode]["button_no_select_hotel_copy_message"],
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        },
        () => {
          this.props.hideTopPopupDialog();
        }
      );
    }
  };

  static mapStateToProps = (state, props) => {
    return {
      ...CommonComponent.mapStateToProps(state, props),
      data: state.auth.data,
      userToken: state.auth.sessionData,
      hotelList: state.hotel.hotelList,
      failData: state.hotel.failData,
      successDelete: state.hotel.successDelete,
      errorCode: state.constant.commonError.code,
    };
  };

  static mapDispatchToProps = (dispatch, props) => {
    var returnProps = {
      ...CommonComponent.mapDispatchToProps(dispatch, props),
      onGetToken: () => dispatch(authActions.onGet()),
      onGetAll: (pageNo, search) => dispatch(hotelActions.onGetAll(pageNo, search)),
      onDeleteOne: (hotelId) => dispatch(hotelActions.onDelete(hotelId)),
    };

    return returnProps;
  };
}

export default HotelListData;
