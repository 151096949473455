import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../../components/Paths";
import TermsGroupAddData from "../Add/TermsGroupAddData";


//css
import "../../../css/common.css";
import "../../../css/termsAndCondition/TermsGroup/TermsGroupAdd.css";

class TermsGroupAddView extends TermsGroupAddData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: false,
      title: "",
      countryCode: "",
      errorMessage: "",
      isCheckValidation: false,
    };
  }

  componentDidMount() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  // change title
  onChangeTitle = (e) => {
    const inputValue = e.target.value;
    this.setState({ title: inputValue }, () => {
      this.onCheckValidation();
    });
  };
  // change countryCode
  onChangeCountryCode = (e) => {
    const inputValue = e.target.value;
    this.setState({ countryCode: inputValue }, () => {
      this.onCheckValidation();
    });
  };

  // 빈칸일 때 상단에 표시될 유효성 검사 메시지를 수정함
  confirmField = (field, messageKey) => {
    const value = this.state[field];
    if (value === "" || value === null) {
      this.setState({
        errorMessage: messageKey,
      });
      return false;
    } else {
      return true;
    }
  };

  // check validation
  onCheckValidation = () => {
    if (this.confirmField("title", "admin_terms_group_add_validation_title") && this.confirmField("countryCode", "admin_terms_group_add_validation_country_code")) {
      this.setState({
        errorMessage: "",
        isCheckValidation: true,
      });
    } else {
      this.setState({
        isCheckValidation: false,
      });
    }
  };

  //add 버튼을 눌렀을때 전달
  onSubmit = () => {
    this.setState({
      isShowAllLoading: true,
    });
    this.requestAddTermsGroup(this.state.title, this.state.countryCode);
  };

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.push("/termsGroupListPage");
  };

  render() {
    return (
      <div className="addTermsGroup">
        {this.state.isShowAllLoading && <Loading />}
        <div className="termsGroup_add_container">
          <div className="addtermsGroup_inner">
            <div className="title_container">
              <div className="main_text">
                <p data-detect="admin_terms_group_add_title">ADD TERMS GROUP</p>
              </div>
              <div className="sub_text">
                <p className="message" data-detect={this.state.errorMessage}></p>
              </div>
            </div>
            <div className="addtermsGroup_info_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="addtermsGroup_info">
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_terms_group_add_label_title">Title</p>
                        </div>
                      </div>
                      <div className="detail_cell content termsGroup_name">
                        <input type="text" id="title" name="title" autoComplete="off" value={this.state.title} onChange={this.onChangeTitle} />
                      </div>
                    </div>
                    <div className="detail_row">
                      <div className="detail_cell index">
                        <div className="form_title">
                          <p data-detect="admin_terms_group_add_label_country_code">Country Code</p>
                        </div>
                      </div>
                      <div className="detail_cell content termsGroup_name">
                        <input type="text" id="countryCode" name="countryCode" placeholder="ex) kr" autoComplete="off" value={this.state.countryCode} onChange={this.onChangeCountryCode} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <button type="button" className="gotolist_btn" data-detect="admin_terms_group_add_button_go_to_list" onClick={this.onBackBtn}>
                Go to list
              </button>
              <button type="button" className="add_btn" data-detect="admin_terms_group_add_button_add" onClick={this.onSubmit} disabled={!this.state.isCheckValidation}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = TermsGroupAddData.mapStateToProps;
let mapDispatchToProps = TermsGroupAddData.mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TermsGroupAddView));
