const ChineseLanguage = {
  version: "2.0.18",
  language: {
    zh: {
      login_button_admin: "管理员登录页面",
      login_button_hotel: "酒店登录页面",
      login_guide_login: "单击您的登录页面",
      admin_login_admin_label_admin: "行政",
      admin_login_admin_placeholder_email: "电子邮件",
      admin_login_admin_placeholder_password: "密码",
      admin_login_admin_button_login: "登录",
      admin_login_admin_guide_login: "以管理员身份登录。",
      hotel_login_hotel_label_hotel: "酒店",
      hotel_login_hotel_placeholder_email: "电子邮件",
      hotel_login_hotel_placeholder_password: "密码",
      hotel_login_hotel_button_login: "登录",
      hotel_login_hotel_guide_login: "以酒店身份登录。",
      common_taxi_call: "出租车",
      common_parcel_service: "包裹服务",
      common_luggage_service: "行李服务",
      common_taxi_call_ex: "出租车快车",
      common_parcel_service_ex: "包裹服务快递",
      common_luggage_service_ex: "行李快递服务",
      common_green_campaign: "绿色运动",
      common_make_up_room: "让出一点空间",
      common_turn_down_service: "夜床服务",
      common_emergency: "紧急服务",
      common_dnd: "免打扰",
      common_in_room_dining: "客房送餐服务",
      common_ironing_service: "熨烫服务",
      common_laundry_service: "洗衣服务",
      common_amenity_service: "便利设施服务",
      common_in_room_dining_ex: "客房送餐服务 Express",
      common_ironing_service_ex: "熨烫服务特快专递",
      common_laundry_service_ex: "洗衣服务快捷",
      common_waiting_request: "备用请求服务",
      common_valet_return_additional_charge: "代客归还附加费",
      common_requested: "已请求",
      common_confirmed: "确认的",
      common_change: "改变了",
      common_cancled: "取消",
      common_standby: "支持",
      common_completed: "完全的",
      common_stopped: "已停止",
      common_picked_up: "已接",
      common_started: "开始",
      common_delivered: "发表",
      common_not_available: "无法使用",
      common_delay: "延迟",
      common_room_posting: "房间发布",
      common_room_posting_refund: "房间寄出退款",
      common_self_settlement: "自行结算",
      common_self_settlement_refund: "自行结算退款",
      common_staff_room_posting: "员工室张贴",
      common_staff_settlement: "员工安置",
      common_put_off: "推迟",
      common_vehicle_change: "车号改变",
      common_valet: "代客泊车",
      common_location: "停车位置",
      common_waiting: "待机请求",
      common_waiting_confirm: "确认的",
      common_date_change: "待机时间改变",
      common_vehicle_standby: "车辆准备就绪",
      common_preparing: "准备中",
      common_return: "回",
      common_handover: "已移交",
      common_deleted: "已删除",
      commom_delay_15: "延误15分钟",
      commom_delay_30: "延误30分钟",
      commom_delay_45: "延误 45 分钟",
      commom_delay_60: "延误60分钟",
      commom_delay_asaa: "尽快可用",
      common_normal: "普通的",
      common_express: "表达",
      common_boundary_line: "边界线",
      common_accepted: "公认",
      common_rejected: "拒绝",
      common_key_expired: "密钥已过期",
      common_disposable_key: "一次性钥匙",
      common_today_only_key: "仅限今日关键",
      common_entire_stay_key: "全程住宿钥匙",
      common_validation_name: "请输入姓名。",
      common_validation_content: "请输入内容。",
      common_validation_select_img: "请选择图像。",
      common_summernote_lang: "en-EN",
      common_clean: "清洁完成",
      common_inspected: "已检查",
      common_dirty: "肮脏的",
      common_out_of_order: "故障",
      common_out_of_service: "中止服务",
      common_pick_up: "捡起",
      common_cleaning: "打扫",
      common_done: "完全的",
      common_not_reserved: "不保留",
      common_departed: "离开",
      common_arrived: "到达的",
      common_week_mon: "周一",
      common_week_tue: "星期二",
      common_week_wed: "星期三",
      common_week_thu: "星期四",
      common_week_fri: "周五",
      common_week_sat: "星期六",
      common_week_sun: "太阳",
      common_percent: "百分比",
      common_nights: "夜晚",
      common_flat: "扁平率",
      common_all_nights: "整晚（整个住宿期间）",
      common_deposit_only: "仅限存款",
      common_first_night: "第一晚",
      common_night: "夜晚",
      common_inactive: "不活跃",
      common_active: "积极的",
      common_success: "成功",
      common_failure: "失败",
      admin_header_link_password: "密码",
      admin_header_link_logout: "登出",
      admin_header_link_hotel: "酒店",
      admin_header_link_setting: "环境",
      admin_header_accordion_location: "地点",
      admin_header_link_region_list: "地区列表",
      admin_header_link_country_list: "国家列表",
      admin_header_link_city_list: "城市列表",
      admin_header_link_application_user: "应用程序用户",
      admin_header_link_payment: "支付",
      admin_header_accordion_transfer: "转移",
      admin_header_link_transfer_log_list: "传输日志列表",
      admin_header_link_user_transfer_log_list: "用户传输日志列表",
      admin_header_link_transaction_log_list: "交易日志列表",
      admin_header_link_user_transfer_log_history: "用户传输日志历史记录",
      admin_header_link_staff_transfer_log_history: "员工调动历史记录",
      admin_header_link_user_notification_log_list: "用户通知日志列表",
      admin_header_link_staff_notification_log_list: "员工通知日志列表",
      admin_header_accordion_version: "版本",
      admin_header_link_version: "版本",
      admin_header_link_application_down_version_list: "应用程序下载屏幕设置",
      admin_header_link_guest_application_down_list: "访客应用程序最低版本设置",
      admin_header_link_staff_application_down_list: "员工应用程序最低版本设置",
      admin_header_link_paking_staff_application_down_list: "停车人员应用程序最低版本设置",
      admin_header_link_application_translation: "申请翻译",
      admin_header_link_booking_email: "通过电子邮件发送图片",
      admin_header_accordion_email_form: "电子邮件表格",
      admin_header_link_change_password: "更改密码",
      admin_header_link_forgot_id: "忘记身份证号码",
      admin_header_link_forgot_password: "忘记密码",
      admin_header_link_create_confirm_email: "确认电子邮件",
      admin_header_link_create_confirm_email_fail: "确认邮件失败",
      admin_header_link_create_confirm_email_success: "确认邮件成功",
      admin_header_link_update_profile: "更新个人信息",
      admin_header_link_withdraw: "删除帐户",
      admin_header_link_app_endpoint: "应用端点",
      admin_header_accordion_terms_and_conditions: "条款和条件",
      admin_header_link_terms: "条款",
      admin_header_link_terms_group: "条款组",
      admin_header_link_agreement_history: "协议历史",
      admin_header_current_version: "当前版本",
      admin_header_translation_version: "翻译版",
      admin_header_link_admin_user: "管理员用户",
      admin_header_accordion_auth_data: "信标/GPS 连接历史记录",
      admin_header_link_auth_data_log_list: "信标/GPS 连接列表",
      admin_header_link_use_key_log: "使用按键日志",
      admin_header_link_amenity: "便利设施",
      admin_header_link_facility_image: "酒店设施形象",
      admin_header_link_recommend_app: "推荐应用程序",
      admin_header_link_id_recognition_information: "ID识别信息",
      admin_header_link_statistics: "Statistics",
      admin_password_title: "更改密码",
      admin_password_guide_password: "请输入新密码 \n至少 8 个字符。",
      admin_password_validation_current_password: "* 请输入您当前的密码。",
      admin_password_validation_character_number_combining: "* 请输入至少 8 个字母和数字组合的字符。",
      admin_password_validation_new_password: "* 请输入新密码。",
      admin_password_validation_new_password_again: "* 请再次输入您的新密码。",
      admin_password_validation_not_match: "* 密码不匹配。",
      admin_password_placeholder_password: "密码",
      admin_password_placeholder_new_password: "新密码",
      admin_password_placeholder_new_confirm_password: "确认新密码",
      admin_password_button_save: "节省",
      admin_hotel_list_title: "酒店列表",
      admin_hotel_list_placeholder_search: "搜索...",
      admin_hotel_list_label_hotel_no: "酒店号",
      admin_hotel_list_label_name: "姓名",
      admin_hotel_list_label_address: "地址",
      admin_hotel_list_label_is_show: "是显示",
      admin_hotel_list_label_date: "日期",
      admin_hotel_list_guide_empty: "列表为空",
      admin_hotel_list_button_delete: "删除",
      admin_hotel_list_button_add: "添加",
      admin_hotel_list_button_copy: "复制",
      admin_hotel_add_title: "添加酒店",
      admin_hotel_add_guide_click_add: "输入信息以添加酒店 \n然后单击<<“添加”>>按钮。",
      admin_hotel_add_validation_name: "* 请输入酒店名称。",
      admin_hotel_add_validation_address: "* 请输入酒店地址。",
      admin_hotel_add_validation_korea_english: "* 韩语和英语或“请使用._ - 输入”。",
      admin_hotel_add_validation_email: "* 请输入用户电子邮件。",
      admin_hotel_add_validation_email_format: "* 请以someone@example.com 格式输入电子邮件",
      admin_hotel_add_validation_password: "* 请输入用户密码。",
      admin_hotel_add_validation_character_number_combining: "* 请输入至少 8 个字母和数字组合的字符。",
      admin_hotel_add_validation_not_match: "* 密码不匹配。",
      admin_hotel_add_validation_image_file: "* 请上传徽标图片。",
      admin_hotel_add_label_name: "姓名",
      admin_hotel_add_placeholder_hotel_name: "酒店名称",
      admin_hotel_add_label_address: "地址",
      admin_hotel_add_placeholder_hotel_address: "酒店地址",
      admin_hotel_add_label_email: "电子邮件",
      admin_hotel_add_placeholder_staff_email: "员工邮箱",
      admin_hotel_add_label_password: "密码",
      admin_hotel_add_placeholder_staff_password: "员工密码",
      admin_hotel_add_label_confirm_password: "确认密码",
      admin_hotel_add_placeholder_staff_confirm_password: "确认员工密码",
      admin_hotel_add_label_logo: "标识",
      admin_hotel_add_button_upload: "上传",
      admin_hotel_add_placeholder_upload: "例）MyCONECT.png",
      admin_hotel_add_button_go_to_list: "前往列表",
      admin_hotel_add_button_add: "添加",
      admin_hotel_detail_title: "酒店信息",
      admin_hotel_detail_label_hotel_no: "酒店号",
      admin_hotel_detail_label_name: "姓名",
      admin_hotel_detail_label_address: "地址",
      admin_hotel_detail_label_region: "地区",
      admin_hotel_detail_label_country: "国家",
      admin_hotel_detail_label_city: "城市",
      admin_hotel_detail_label_country_name: "国家的名字",
      admin_hotel_detail_label_payment_server_url: "支付服务器网址",
      admin_hotel_detail_label_is_direct_payable: "是直接付款",
      admin_hotel_detail_label_is_card_tokenizable: "卡是否可代币化",
      admin_hotel_detail_label_is_show: "是显示",
      admin_hotel_detail_button_yes: "伊瑟",
      admin_hotel_detail_button_no: "不",
      admin_hotel_detail_label_logo: "标识",
      admin_hotel_detail_label_hotel_photo: "酒店照片",
      admin_hotel_detail_label_hotel_map: "地图",
      admin_hotel_detail_button_go_to_list: "前往列表",
      admin_hotel_detail_button_edit: "编辑",
      admin_hotel_edit_title: "酒店信息",
      admin_hotel_edit_label_hotel_no: "酒店号",
      admin_hotel_edit_label_name: "姓名",
      admin_hotel_edit_label_address: "地址",
      admin_hotel_edit_label_region: "地区",
      admin_hotel_edit_label_country: "国家",
      admin_hotel_edit_label_choose: "选择",
      admin_hotel_edit_label_city: "城市",
      admin_hotel_edit_label_country_name: "国家的名字",
      admin_hotel_edit_label_logo: "标识",
      admin_hotel_edit_label_payment_server_url: "支付服务器网址",
      admin_hotel_edit_label_is_direct_payable: "是直接付款",
      admin_hotel_edit_label_is_card_tokenizable: "卡是否可代币化",
      admin_hotel_edit_label_is_show: "是显示",
      admin_hotel_edit_button_yes: "伊瑟",
      admin_hotel_edit_button_no: "不",
      admin_hotel_edit_button_upload: "上传",
      admin_hotel_edit_placeholder_upload: "例）MyCONECT.png",
      admin_hotel_edit_label_hotel_photo: "酒店照片",
      admin_hotel_edit_label_hotel_map: "地图",
      admin_hotel_edit_button_search: "搜索",
      admin_hotel_edit_button_cancel: "取消",
      admin_hotel_edit_button_ok: "好的",
      admin_hotel_edit_validation_region: "* 请选择地区。",
      admin_hotel_edit_validation_country: "* 请选择一个国家。",
      admin_hotel_edit_validation_city: "* 请选择城市。",
      admin_hotel_edit_validation_country_name: "* 请选择国家名称。",
      admin_hotel_edit_validation_is_show: "* 请选择“是显示”。",
      admin_hotel_copy_title: "复制酒店",
      admin_hotel_copy_guide_click_copy: "输入酒店详细信息，然后单击“复制”以复制酒店信息。",
      admin_hotel_copy_button_copy: "复制",
      admin_setting_detail_title: "我的连接设置",
      admin_setting_detail_label_date: "更新日期",
      admin_setting_detail_label_setting: "设置",
      admin_setting_detail_label_coordinate_distance: "坐标距离",
      admin_setting_detail_button_edit: "编辑",
      admin_setting_edit_title: "我的连接设置",
      admin_setting_edit_label_date: "更新日期",
      admin_setting_edit_label_setting: "设置",
      admin_setting_edit_label_coordinate_distance: "坐标距离",
      admin_setting_edit_button_ok: "好的",
      admin_setting_edit_button_cancel: "取消",
      admin_setting_edit_validation_coordinate_distance: "* 请输入坐标距离。",
      admin_region_list_title: "地区列表",
      admin_region_list_placeholder_search: "搜索...",
      admin_region_list_label_region: "地区",
      admin_region_list_label_date: "日期",
      admin_region_list_guide_empty: "列表为空",
      admin_region_list_button_delete: "删除",
      admin_region_list_button_add: "添加",
      admin_region_add_title: "添加区域",
      admin_region_add_guide_click_add: "输入地区。 \n然后单击<<“添加”>>按钮。",
      admin_region_add_validation_name: "* 请输入地区名称。",
      admin_region_add_label_region: "地区",
      admin_region_add_placeholder_region_name: "地区名称",
      admin_region_add_button_go_to_list: "前往列表",
      admin_region_add_button_add: "添加",
      admin_region_detail_title: "地区",
      admin_region_detail_label_region: "地区",
      admin_region_detail_label_created_at: "创建于",
      admin_region_detail_label_updated_at: "更新于",
      admin_region_detail_button_go_to_list: "前往列表",
      admin_region_detail_button_edit: "编辑",
      admin_region_edit_title: "地区",
      admin_region_edit_validation_name: "* 请输入地区名称。",
      admin_region_edit_label_region: "地区",
      admin_region_edit_placeholder_region_name: "地区名称",
      admin_region_edit_label_created_at: "创建于",
      admin_region_edit_label_updated_at: "更新于",
      admin_region_edit_button_cancel: "取消",
      admin_region_edit_button_ok: "好的",
      admin_country_list_title: "国家名单",
      admin_country_list_select_region_all: "地区（全部）",
      admin_country_list_placeholder_search: "搜索...",
      admin_country_list_label_region: "地区",
      admin_country_list_label_country: "国家",
      admin_country_list_label_date: "日期",
      admin_country_list_guide_empty: "列表为空",
      admin_country_list_button_delete: "删除",
      admin_country_list_button_add: "添加",
      admin_country_add_title: "添加国家/地区",
      admin_country_add_guide_click_add: "输入国家/地区并单击<<“添加”>>按钮。",
      admin_country_add_validation_region: "* 请选择地区。",
      admin_country_add_validation_country: "* 请输入国家名称。",
      admin_country_add_label_region: "地区",
      admin_country_add_label_choose: "选择",
      admin_country_add_label_country: "国家",
      admin_country_add_placeholder_country_name: "国家的名字",
      admin_country_add_button_go_to_list: "前往列表",
      admin_country_add_button_add: "添加",
      admin_country_detail_title: "国家",
      admin_country_detail_label_region: "地区",
      admin_country_detail_label_country: "国家",
      admin_country_detail_placeholder_country_name: "国家的名字",
      admin_country_detail_label_created_at: "创建于",
      admin_country_detail_label_updated_at: "更新于",
      admin_country_detail_button_go_to_list: "前往列表",
      admin_country_detail_button_edit: "编辑",
      admin_country_edit_title: "国家",
      admin_country_edit_validation_country: "* 请输入国家名称。",
      admin_country_edit_label_region: "地区",
      admin_country_edit_label_country: "国家",
      admin_country_edit_placeholder_country_name: "国家的名字",
      admin_country_edit_label_created_at: "创建于",
      admin_country_edit_label_updated_at: "更新于",
      admin_country_edit_button_cancel: "取消",
      admin_country_edit_button_ok: "好的",
      admin_city_list_title: "城市列表",
      admin_city_list_select_region_all: "地区（全部）",
      admin_city_list_select_country_all: "国家（全部）",
      admin_city_list_placeholder_search: "搜索...",
      admin_city_list_label_region: "地区",
      admin_city_list_label_country: "国家",
      admin_city_list_label_city: "城市",
      admin_city_list_label_date: "日期",
      admin_city_list_guide_empty: "列表为空",
      admin_city_list_button_delete: "删除",
      admin_city_list_button_add: "添加",
      admin_city_add_title: "添加城市",
      admin_city_add_guide_click_add: "进入城市。 \n然后单击<<“添加”>>按钮。",
      admin_city_add_validation_region: "* 请选择地区。",
      admin_city_add_validation_country: "* 请选择国家。",
      admin_city_add_validation_city: "* 请输入城市名称。",
      admin_city_add_label_region: "地区",
      admin_city_add_label_country: "国家",
      admin_city_add_label_choose: "选择",
      admin_city_add_label_city: "城市",
      admin_city_add_placeholder_city_name: "城市名",
      admin_city_add_button_go_to_list: "前往列表",
      admin_city_add_button_add: "城市",
      admin_city_detail_label_region: "添加",
      admin_city_detail_title: "地区",
      admin_city_detail_label_country: "国家",
      admin_city_detail_label_city: "城市",
      admin_city_detail_placeholder_city_name: "城市名",
      admin_city_detail_label_created_at: "创建于",
      admin_city_detail_label_updated_at: "更新于",
      admin_city_detail_button_go_to_list: "前往列表",
      admin_city_detail_button_edit: "编辑",
      admin_city_edit_title: "城市",
      admin_city_edit_validation_city: "* 请输入城市名称。",
      admin_city_edit_label_region: "地区",
      admin_city_edit_label_country: "国家",
      admin_city_edit_label_city: "城市",
      admin_city_edit_placeholder_city_name: "城市名",
      admin_city_edit_label_created_at: "创建于",
      admin_city_edit_label_updated_at: "更新于",
      admin_city_edit_button_cancel: "取消",
      admin_city_edit_button_ok: "好的",
      admin_application_user_list_title: "应用程序用户列表",
      admin_application_user_list_placeholder_select_box_user: "用户",
      admin_application_user_list_select_email: "电子邮件",
      admin_application_user_list_select_name: "姓名",
      admin_application_user_list_placeholder_search: "搜索...",
      admin_application_user_list_label_email: "电子邮件",
      admin_application_user_list_label_backup_email: "备份电子邮件",
      admin_application_user_list_label_name: "姓名",
      admin_application_user_list_label_alternative_name: "备用名称",
      admin_application_user_list_label_mail_confirmed_at: "电子邮件确认于",
      admin_application_user_list_label_date: "日期",
      admin_application_user_list_button_delete: "删除",
      admin_application_user_list_guide_empty: "列表为空",
      admin_application_user_detail_title: "用户邮箱",
      admin_application_user_detail_label_user_information: "应用程序用户信息",
      admin_application_user_detail_user_info_email: "电子邮件",
      admin_application_user_detail_user_info_gender: "性别",
      admin_application_user_detail_user_info_first_name: "名",
      admin_application_user_detail_user_info_last_name: "姓",
      admin_application_user_detail_user_info_alternative_first_name: "替代名字",
      admin_application_user_detail_user_info_alternative_last_name: "替代姓氏",
      admin_application_user_detail_user_info_member_type: "会员类型",
      admin_application_user_detail_user_info_backup_email: "备份电子邮件",
      admin_application_user_detail_user_info_mail_confirmed_at: "电子邮件确认于",
      admin_application_user_detail_user_info_push_token: "推送令牌",
      admin_application_user_detail_user_info_os_type: "操作系统类型",
      admin_application_user_detail_user_info_language_code: "语言代码",
      admin_application_user_detail_user_info_updated_at: "更新于",
      admin_application_user_detail_user_info_date: "日期",
      admin_application_user_detail_button_go_to_list: "前往列表",
      admin_payment_list_title: "付款清单",
      admin_payment_list_placeholder_hotel_id: "酒店ID",
      admin_payment_list_placeholder_user_name: "用户名",
      admin_payment_list_placeholder_search: "搜索...",
      admin_payment_list_label_no: "不。",
      admin_payment_list_label_reservation_id: "预订ID",
      admin_payment_list_label_user_name: "用户名",
      admin_payment_list_label_long_info: "长信息",
      admin_payment_list_label_amount: "数量",
      admin_payment_list_label_unit: "单元",
      admin_payment_list_label_pg_status_code: "PG状态码",
      admin_payment_list_label_pg_status_message: "PG状态信息",
      admin_payment_list_label_hotel_id: "酒店ID",
      admin_payment_list_label_date: "日期",
      admin_payment_detail_label_order_no: "订单号。",
      admin_payment_detail_label_user: "用户",
      admin_payment_detail_label_user_profile_id: "个人资料 ID",
      admin_payment_detail_label_user_title: "标题",
      admin_payment_detail_label_user_first_name: "名",
      admin_payment_detail_label_user_last_name: "姓",
      admin_payment_detail_label_user_email: "电子邮件",
      admin_payment_detail_label_user_date: "日期",
      admin_payment_detail_label_reservation: "预订",
      admin_payment_detail_label_reservation_confirmation_no: "确认号",
      admin_payment_detail_label_reservation_reservation_id: "预订ID",
      admin_payment_detail_label_reservation_reservation_status: "预订状态",
      admin_payment_detail_label_reservation_check_in_date: "入住日期",
      admin_payment_detail_label_reservation_check_out_date: "离开日期",
      admin_payment_detail_label_hotel: "酒店",
      admin_payment_detail_label_hotel_hotel_id: "酒店ID",
      admin_payment_detail_label_hotel_hotel_name: "酒店名称",
      admin_payment_detail_label_hotel_region: "地区",
      admin_payment_detail_label_hotel_country: "国家",
      admin_payment_detail_label_hotel_city: "城市",
      admin_payment_detail_label_payment: "支付",
      admin_payment_detail_button_go_to_list: "前往列表",
      admin_payment_detail_button_go_to_log: "转到日志列表",
      admin_payment_detail_label_payment_order_no: "订单号。",
      admin_payment_detail_label_payment_reservation_id: "预订ID",
      admin_payment_detail_label_payment_long_info: "长信息",
      admin_payment_detail_label_payment_folio_view_no: "对开视图编号",
      admin_payment_detail_label_payment_amount: "数量",
      admin_payment_detail_label_payment_unit: "单元",
      admin_payment_detail_label_payment_pg_order_no: "PG 订单号",
      admin_payment_detail_label_payment_pg_status: "控球后卫状态",
      admin_payment_detail_label_payment_pg_status_code: "PG状态代码",
      admin_payment_detail_label_payment_pg_status_message: "PG状态信息",
      admin_payment_detail_label_payment_pg_amount: "PG金额",
      admin_payment_detail_label_payment_pg_unit: "PG单位",
      admin_payment_detail_label_payment_pg_billing_no: "PG 帐单号码",
      admin_payment_detail_label_payment_confirmed_at: "确认于",
      admin_payment_detail_label_payment_created_at: "创建于",
      admin_payment_detail_label_payment_updated_at: "更新于",
      admin_payment_log_list_title: "付款日志列表",
      admin_payment_log_list_label_no: "不。",
      admin_payment_log_list_label_type: "类型",
      admin_payment_log_list_label_method: "方法",
      admin_payment_log_list_label_path: "小路",
      admin_payment_log_list_label_status: "地位",
      admin_payment_log_list_label_success: "成功",
      admin_payment_log_list_label_created_at: "创建于",
      admin_payment_log_list_guide_empty: "列表为空",
      admin_payment_log_list_button_go_to_detail: "转到详细信息",
      admin_payment_log_detail_title: "付款日志详细信息",
      admin_payment_log_detail_label_id: "ID",
      admin_payment_log_detail_label_hotel_id: "酒店ID",
      admin_payment_log_detail_label_payment_id: "付款ID",
      admin_payment_log_detail_label_transfer_code: "转移代码",
      admin_payment_log_detail_label_type: "类型",
      admin_payment_log_detail_label_method: "方法",
      admin_payment_log_detail_label_path: "小路",
      admin_payment_log_detail_label_header: "标头",
      admin_payment_log_detail_label_param: "参数",
      admin_payment_log_detail_label_body: "身体",
      admin_payment_log_detail_label_status: "地位",
      admin_payment_log_detail_label_session: "会议",
      admin_payment_log_detail_label_created_at: "创建于",
      admin_payment_log_detail_button_go_to_list: "前往列表",
      admin_transfer_log_list_title: "转会名单",
      admin_transfer_log_list_placeholder_user_id: "用户身份",
      admin_transfer_log_list_placeholder_hotel_id: "酒店ID",
      admin_transfer_log_list_placeholder_search: "搜索...",
      admin_transfer_log_list_label_no: "不。",
      admin_transfer_log_list_label_type: "类型",
      admin_transfer_log_list_label_user: "用户",
      admin_transfer_log_list_label_hotel_id: "酒店ID",
      admin_transfer_log_list_label_path: "小路",
      admin_transfer_log_list_label_status: "地位",
      admin_transfer_log_list_label_success: "成功",
      admin_transfer_log_list_label_created_at: "创建于",
      admin_transfer_log_list_guide_empty: "列表为空",
      admin_transfer_log_detail_title: "转账详情",
      admin_transfer_log_detail_label_transfer_id: "转账ID",
      admin_transfer_log_detail_label_type: "类型",
      admin_transfer_log_detail_label_user: "用户",
      admin_transfer_log_detail_label_hotel_id: "酒店ID",
      admin_transfer_log_detail_label_user_email: "用户邮箱",
      admin_transfer_log_detail_label_session: "会议",
      admin_transfer_log_detail_label_token: "代币",
      admin_transfer_log_detail_label_path: "小路",
      admin_transfer_log_detail_label_status: "地位",
      admin_transfer_log_detail_label_header: "标头",
      admin_transfer_log_detail_label_param: "参数",
      admin_transfer_log_detail_label_body: "身体",
      admin_transfer_log_detail_label_created_at: "创建于",
      admin_transfer_log_detail_label_updated_at: "更新于",
      admin_transfer_log_detail_button_go_to_list: "前往列表",
      admin_user_transfer_log_list_title: "用户转移列表",
      admin_user_transfer_log_list_placeholder_email: "请输入电子邮件",
      admin_user_transfer_log_list_placeholder_search: "搜索...",
      admin_user_transfer_log_list_label_no: "不。",
      admin_user_transfer_log_list_label_type: "类型",
      admin_user_transfer_log_list_label_method: "方法",
      admin_user_transfer_log_list_label_path: "小路",
      admin_user_transfer_log_list_label_status: "地位",
      admin_user_transfer_log_list_label_success: "成功",
      admin_user_transfer_log_list_label_user_email: "用户邮箱",
      admin_user_transfer_log_list_label_created_at: "创建于",
      admin_user_transfer_log_list_guide_empty: "列表为空",
      admin_user_transfer_log_detail_title: "用户转账详情",
      admin_user_transfer_log_detail_label_id: "ID",
      admin_user_transfer_log_detail_label_type: "类型",
      admin_user_transfer_log_detail_label_method: "方法",
      admin_user_transfer_log_detail_label_path: "小路",
      admin_user_transfer_log_detail_label_header: "标头",
      admin_user_transfer_log_detail_label_param: "参数",
      admin_user_transfer_log_detail_label_body: "身体",
      admin_user_transfer_log_detail_label_soap: "肥皂",
      admin_user_transfer_log_detail_label_status: "地位",
      admin_user_transfer_log_detail_label_session: "会议",
      admin_user_transfer_log_detail_label_token: "代币",
      admin_user_transfer_log_detail_label_user_email: "用户邮箱",
      admin_user_transfer_log_detail_label_created_at: "创建于",
      admin_user_transfer_log_detail_button_go_to_list: "前往列表",
      admin_transaction_log_list_title: "交易日志列表",
      admin_transaction_log_list_select_complete_action: "完整动作（全部）",
      admin_transaction_log_list_select_complete_action_commit: "犯罪",
      admin_transaction_log_list_select_complete_action_rollback: "回滚",
      admin_transaction_log_list_select_complete_action_none: "没有任何",
      admin_transaction_log_list_placeholder_search: "搜索...",
      admin_transaction_log_list_label_no: "不。",
      admin_transaction_log_list_label_act_on_function: "按功能行事",
      admin_transaction_log_list_label_complete_action: "完成动作",
      admin_transaction_log_list_label_completed_at: "完成于",
      admin_transaction_log_list_label_date: "日期",
      admin_transaction_log_detail_title: "交易日志详细信息",
      admin_transaction_log_detail_label_transaction_id: "交易ID",
      admin_transaction_log_detail_label_act_on_function: "按功能行事",
      admin_transaction_log_detail_label_complete_action: "完成动作",
      admin_transaction_log_detail_label_rollback_reason: "回滚原因",
      admin_transaction_log_detail_label_completed_at: "完成于",
      admin_transaction_log_detail_label_created_at: "创建于",
      admin_transaction_log_detail_label_updated_at: "更新于",
      admin_transaction_log_detail_button_go_to_list: "前往列表",
      admin_transaction_log_detail_rollback_reason_stack: "堆",
      admin_transaction_log_detail_rollback_reason_error_message: "错误信息",
      admin_user_transfer_log_history_title: "用户传输日志历史记录",
      admin_user_transfer_log_history_placeholder_email: "请输入您的电子邮件。",
      admin_user_transfer_log_history_placeholder_search: "搜索...",
      admin_user_transfer_log_history_label_no: "不。",
      admin_user_transfer_log_history_label_method: "方法",
      admin_user_transfer_log_history_label_path: "小路",
      admin_user_transfer_log_history_label_status: "地位",
      admin_user_transfer_log_history_label_success: "成功",
      admin_user_transfer_log_history_label_user_email: "用户邮箱",
      admin_user_transfer_log_history_label_created_at: "创建于",
      admin_user_transfer_log_history_label_process_time: "进展时间(ms)",
      admin_user_transfer_log_history_detail_title: "用户转移详细历史记录 (↓)",
      admin_user_transfer_log_history_detail_label_no: "不。",
      admin_user_transfer_log_history_detail_label_type: "类型",
      admin_user_transfer_log_history_detail_label_method: "方法",
      admin_user_transfer_log_history_detail_label_path: "小路",
      admin_user_transfer_log_history_detail_label_status: "地位",
      admin_user_transfer_log_history_detail_label_success: "成功",
      admin_user_transfer_log_history_detail_label_user_email: "用户邮箱",
      admin_user_transfer_log_history_detail_label_created_at: "创建于",
      admin_user_transfer_log_history_detail_button_go_to_list: "前往列表",
      admin_staff_transfer_log_history_title: "员工调动日志历史记录",
      admin_staff_transfer_log_history_list_placeholder_user_id: "用户身份",
      admin_staff_transfer_log_history_list_placeholder_hotel_id: "酒店ID",
      admin_staff_transfer_log_history_list_placeholder_search: "搜索...",
      admin_staff_transfer_log_history_list_label_no: "不。",
      admin_staff_transfer_log_history_list_label_method: "방법",
      admin_staff_transfer_log_history_list_label_user: "用户",
      admin_staff_transfer_log_history_list_label_hotel_id: "酒店ID",
      admin_staff_transfer_log_history_list_label_path: "小路",
      admin_staff_transfer_log_history_list_label_status: "地位",
      admin_staff_transfer_log_history_list_label_success: "成功",
      admin_staff_transfer_log_history_list_label_created_at: "创建于",
      admin_staff_transfer_log_history_list_label_process_time: "进展时间(ms)",
      admin_staff_transfer_log_history_list_guide_empty: "列表为空",
      admin_staff_transfer_log_history_detail_title: "人员调动详细历史 (↓)",
      admin_staff_transfer_log_history_detail_label_no: "不。",
      admin_staff_transfer_log_history_detail_label_type: "类型",
      admin_staff_transfer_log_history_detail_label_method: "方法",
      admin_staff_transfer_log_history_detail_label_user: "用户",
      admin_staff_transfer_log_history_detail_label_hotel_id: "酒店ID",
      admin_staff_transfer_log_history_detail_label_path: "小路",
      admin_staff_transfer_log_history_detail_label_status: "地位",
      admin_staff_transfer_log_history_detail_label_success: "成功",
      admin_staff_transfer_log_history_detail_label_created_at: "创建于",
      admin_staff_transfer_log_history_detail_button_go_to_list: "前往列表",
      admin_user_notification_log_list_title: "用户通知日志列表",
      admin_user_notification_log_list_placeholder_email: "电子邮件",
      admin_user_notification_log_list_placeholder_search: "搜索...",
      admin_user_notification_log_list_label_no: "不。",
      admin_user_notification_log_list_label_type: "类型",
      admin_user_notification_log_list_label_title: "标题",
      admin_user_notification_log_list_label_email: "电子邮件",
      admin_user_notification_log_list_label_user: "用户",
      admin_user_notification_log_list_label_success: "成功",
      admin_user_notification_log_list_label_os: "操作系统",
      admin_user_notification_log_list_label_language_code: "语言代码",
      admin_user_notification_log_list_label_created_at: "创建于",
      admin_user_notification_log_detail_title: "用户通知日志详细信息",
      admin_user_notification_log_detail_label_notification_id: "通知ID",
      admin_user_notification_log_detail_label_user_id: "用户身份",
      admin_user_notification_log_detail_label_success: "成功",
      admin_user_notification_log_detail_label_user: "用户",
      admin_user_notification_log_detail_label_user_email: "用户邮箱",
      admin_user_notification_log_detail_label_language_code: "语言代码",
      admin_user_notification_log_detail_label_os_type: "操作系统类型",
      admin_user_notification_log_detail_label_type: "类型",
      admin_user_notification_log_detail_label_title: "标题",
      admin_user_notification_log_detail_label_content: "内容",
      admin_user_notification_log_detail_label_data: "数据",
      admin_user_notification_log_detail_label_token: "代币",
      admin_user_notification_log_detail_label_created_at: "创建于",
      admin_user_notification_log_detail_label_updated_at: "更新于",
      admin_user_notification_log_detail_button_go_to_list: "前往列表",
      admin_staff_notification_log_list_title: "员工通知日志列表",
      admin_staff_notification_log_list_placeholder_email: "电子邮件",
      admin_staff_notification_log_list_placeholder_search: "搜索...",
      admin_staff_notification_log_list_label_no: "不。",
      admin_staff_notification_log_list_label_type: "类型",
      admin_staff_notification_log_list_label_title: "标题",
      admin_staff_notification_log_list_label_email: "电子邮件",
      admin_staff_notification_log_list_label_hotel: "酒店",
      admin_staff_notification_log_list_label_staff: "职员",
      admin_staff_notification_log_list_label_task: "任务",
      admin_staff_notification_log_list_label_department: "部门",
      admin_staff_notification_log_list_label_success: "成功",
      admin_staff_notification_log_list_label_os: "操作系统",
      admin_staff_notification_log_list_label_language_code: "语言代码",
      admin_staff_notification_log_list_label_created_at: "创建于",
      admin_staff_notification_log_detail_title: "工作人员通知日志详细信息",
      admin_staff_notification_log_detail_label_notification_id: "通知ID",
      admin_staff_notification_log_detail_label_hotel_id: "酒店ID",
      admin_staff_notification_log_detail_label_staff_id: "员工证",
      admin_staff_notification_log_detail_label_success: "成功",
      admin_staff_notification_log_detail_label_staff_email: "电子邮件",
      admin_staff_notification_log_detail_label_staff_no: "员工编号",
      admin_staff_notification_log_detail_label_department: "部门",
      admin_staff_notification_log_detail_label_team: "团队",
      admin_staff_notification_log_detail_label_level: "等级",
      admin_staff_notification_log_detail_label_task: "任务",
      admin_staff_notification_log_detail_label_location: "地点",
      admin_staff_notification_log_detail_label_zone: "区",
      admin_staff_notification_log_detail_label_room: "房间",
      admin_staff_notification_log_detail_label_guest_inquiry_level: "宾客询问级别",
      admin_staff_notification_log_detail_label_language_code: "语言代码",
      admin_staff_notification_log_detail_label_os_type: "操作系统类型",
      admin_staff_notification_log_detail_label_type: "类型",
      admin_staff_notification_log_detail_label_title: "标题",
      admin_staff_notification_log_detail_label_content: "内容",
      admin_staff_notification_log_detail_label_data: "数据",
      admin_staff_notification_log_detail_label_token: "代币",
      admin_staff_notification_log_detail_label_created_at: "创建于",
      admin_staff_notification_log_detail_label_updated_at: "更新于",
      admin_staff_notification_log_detail_button_go_to_list: "前往列表",
      admin_version_list_title: "版本列表",
      admin_version_list_placeholiser_search: "搜索...",
      admin_version_list_label_version_name: "版本名称",
      admin_version_list_label_ios: "iOS系统",
      admin_version_list_label_ios_debug: "iOS调试",
      admin_version_list_label_android: "安卓",
      admin_version_list_label_android_debug: "安卓调试",
      admin_version_list_guide_empty: "列表为空",
      admin_version_list_button_delete: "删除",
      admin_version_list_button_add: "添加",
      admin_version_add_title: "添加版本",
      admin_version_add_label_version_name: "版本名称",
      admin_version_add_label_ios: "iOS系统",
      admin_version_add_label_ios_debug: "iOS调试",
      admin_version_add_placeholder_ios_debug: "iOS调试",
      admin_version_add_select_ios_true: "真的",
      admin_version_add_select_ios_false: "错误的",
      admin_version_add_label_android: "安卓",
      admin_version_add_label_android_debug: "安卓调试",
      admin_version_add_placeholder_android_debug: "安卓调试",
      admin_version_add_validation_name: "* 请输入版本名称。",
      admin_version_add_validation_ios: "* 请输入iOS。",
      admin_version_add_validation_ios_debug: "* 请选择iOS调试。",
      admin_version_add_validation_andriod: "* 请输入安卓。",
      admin_version_add_validation_andriod_debug: "* 请选择Android调试。",
      admin_version_add_select_android_true: "真的",
      admin_version_add_select_android_false: "错误的",
      admin_version_add_button_go_to_list: "前往列表",
      admin_version_add_button_add: "添加",
      admin_version_detail_title: "版本信息",
      admin_version_detail_label_version_name: "版本名称",
      admin_version_detail_label_ios: "iOS系统",
      admin_version_detail_label_ios_debug: "iOS调试",
      admin_version_detail_label_android: "安卓",
      admin_version_detail_label_android_debug: "安卓调试",
      admin_version_detail_button_go_to_list: "前往列表",
      admin_version_detail_button_delete: "删除",
      admin_version_detail_button_edit: "编辑",
      admin_version_edit_title: "版本信息",
      admin_version_edit_label_version_name: "版本名称",
      admin_version_edit_label_ios: "iOS系统",
      admin_version_edit_label_ios_debug: "iOS调试",
      admin_version_edit_select_ios_true: "真的",
      admin_version_edit_select_ios_false: "错误的",
      admin_version_edit_label_android: "安卓",
      admin_version_edit_label_android_debug: "安卓调试",
      admin_version_edit_select_android_true: "真的",
      admin_version_edit_select_android_false: "错误的",
      admin_version_edit_button_cancel: "取消",
      admin_version_edit_button_delete: "删除",
      admin_version_edit_button_ok: "好的",
      admin_version_app_down_version_list_title: "应用程序版本列表",
      admin_version_app_down_version_list_label_os: "操作系统",
      admin_version_app_down_version_list_label_type: "类型",
      admin_version_app_down_version_list_label_version: "版本",
      admin_version_app_down_version_list_label_link: "关联",
      admin_version_app_down_version_list_label_created_at: "创建于",
      admin_version_app_down_version_list_button_delete: "删除",
      admin_version_app_down_version_list_button_add: "添加",
      admin_version_app_down_version_list_placeholiser_search: "搜索...",
      admin_version_app_down_version_list_label_no: "不。",
      admin_version_app_down_version_list_guide_empty: "列表为空",
      admin_version_app_down_version_add_title: "添加应用程序下载版本",
      admin_version_app_down_version_add_validation_os: "* 请选择操作系统。",
      admin_version_app_down_version_add_validation_type: "* 请选择类型。",
      admin_version_app_down_version_add_validation_version: "* 请输入版本。",
      admin_version_app_down_version_add_validation_link: "* 请输入链接。",
      admin_version_app_down_version_add_label_os: "操作系统",
      admin_version_app_down_version_add_placeholder_os: "操作系统",
      admin_version_app_down_version_add_select_ios: "iOS系统",
      admin_version_app_down_version_add_select_aos: "奥索斯",
      admin_version_app_down_version_add_label_type: "类型",
      admin_version_app_down_version_add_placeholder_type: "类型",
      admin_version_app_down_version_add_select_guest: "客人",
      admin_version_app_down_version_add_select_staff: "职员",
      admin_version_app_down_version_add_select_valet: "代客泊车",
      admin_version_app_down_version_add_select_parking_staff: "停车人员",
      admin_version_app_down_version_add_label_version: "版本",
      admin_version_app_down_version_add_label_link: "关联",
      admin_version_app_down_version_add_button_go_to_list: "前往列表",
      admin_version_app_down_version_add_button_add: "添加",
      admin_version_guest_app_version_list_title: "访客应用程序版本列表",
      admin_version_guest_app_version_list_placeholiser_search: "搜索...",
      admin_version_guest_app_version_list_label_no: "不。",
      admin_version_guest_app_version_list_label_os: "操作系统",
      admin_version_guest_app_version_list_label_version: "版本",
      admin_version_guest_app_version_list_label_link: "关联",
      admin_version_guest_app_version_list_label_created_at: "创建于",
      admin_version_guest_app_version_list_button_delete: "删除",
      admin_version_guest_app_version_list_button_add: "添加",
      admin_version_guest_app_version_list_guide_empty: "列表为空",
      admin_version_guest_app_version_add_title: "添加访客版本",
      admin_version_guest_app_version_add_validation_os: "* 请选择操作系统。",
      admin_version_guest_app_version_add_validation_version: "* 请输入版本。",
      admin_version_guest_app_version_add_validation_link: "* 请输入链接。",
      admin_version_guest_app_version_add_label_os: "操作系统",
      admin_version_guest_app_version_add_placeholder_os: "操作系统",
      admin_version_guest_app_version_add_select_ios: "iOS系统",
      admin_version_guest_app_version_add_select_aos: "奥索斯",
      admin_version_guest_app_version_add_label_version: "版本",
      admin_version_guest_app_version_add_label_link: "关联",
      admin_version_guest_app_version_add_button_go_to_list: "前往列表",
      admin_version_guest_app_version_add_button_add: "添加",
      admin_version_staff_app_version_list_title: "员工应用程序版本列表",
      admin_version_staff_app_version_add_validation_os: "* 请选择操作系统。",
      admin_version_staff_app_version_add_validation_version: "* 请输入版本。",
      admin_version_staff_app_version_add_validation_link: "* 请输入链接。",
      admin_version_staff_app_version_list_placeholiser_search: "搜索...",
      admin_version_staff_app_version_list_label_no: "不。",
      admin_version_staff_app_version_list_label_os: "操作系统",
      admin_version_staff_app_version_list_label_version: "版本",
      admin_version_staff_app_version_list_label_link: "关联",
      admin_version_staff_app_version_list_label_created_at: "创建于",
      admin_version_staff_app_version_list_button_delete: "删除",
      admin_version_staff_app_version_list_button_add: "添加",
      admin_version_staff_app_version_list_guide_empty: "列表为空",
      admin_version_staff_app_version_add_title: "添加员工版本",
      admin_version_staff_app_version_add_label_os: "操作系统",
      admin_version_staff_app_version_add_placeholder_os: "操作系统",
      admin_version_staff_app_version_add_select_ios: "iOS系统",
      admin_version_staff_app_version_add_select_aos: "奥索斯",
      admin_version_staff_app_version_add_label_version: "版本",
      admin_version_staff_app_version_add_label_link: "关联",
      admin_version_staff_app_version_add_button_go_to_list: "前往列表",
      admin_version_staff_app_version_add_button_add: "添加",
      admin_version_parking_staff_app_version_list_title: "停车人员申请表下载列表",
      admin_version_parking_staff_app_version_add_validation_os: "* 请选择操作系统。",
      admin_version_parking_staff_app_version_add_validation_version: "* 请输入版本。",
      admin_version_parking_staff_app_version_add_validation_link: "* 请输入链接。",
      admin_version_parking_staff_app_version_list_placeholiser_search: "搜索...",
      admin_version_parking_staff_app_version_list_label_no: "不。",
      admin_version_parking_staff_app_version_list_label_os: "操作系统",
      admin_version_parking_staff_app_version_list_label_version: "版本",
      admin_version_parking_staff_app_version_list_label_link: "关联",
      admin_version_parking_staff_app_version_list_label_created_at: "创建于",
      admin_version_parking_staff_app_version_list_button_delete: "删除",
      admin_version_parking_staff_app_version_list_button_add: "添加",
      admin_version_parking_staff_app_version_add_title: "添加停车人员 下载",
      admin_version_parking_staff_app_version_add_label_os: "操作系统",
      admin_version_parking_staff_app_version_add_placeholder_os: "操作系统",
      admin_version_parking_staff_app_version_add_select_ios: "iOS系统",
      admin_version_parking_staff_app_version_add_select_aos: "奥索斯",
      admin_version_parking_staff_app_version_add_label_version: "版本",
      admin_version_parking_staff_app_version_add_label_link: "关联",
      admin_version_parking_staff_app_version_add_button_go_to_list: "前往列表",
      admin_version_parking_staff_app_version_add_button_add: "添加",
      admin_application_translation_list_title: "应用程序翻译列表",
      admin_application_translation_list_select_application_type: "应用类型（全部）",
      admin_application_translation_list_select_application_type_guest: "客人",
      admin_application_translation_list_select_application_type_staff: "职员",
      admin_application_translation_list_select_application_type_parking_staff: "停车人员",
      admin_application_translation_list_select_os_type: "操作系统类型（全部）",
      admin_application_translation_list_select_os_type_aos: "奥索斯",
      admin_application_translation_list_select_os_type_ios: "iOS系统",
      admin_application_translation_list_placehoder_application_version: "应用版本",
      admin_application_translation_list_placehoder_file_name: "文件名",
      admin_application_translation_list_label_application_type: "应用类型",
      admin_application_translation_list_label_os: "操作系统",
      admin_application_translation_list_label_application_version: "应用版本",
      admin_application_translation_list_label_version: "版本",
      admin_application_translation_list_label_file_name: "文件名",
      admin_application_translation_list_label_date: "日期",
      admin_application_translation_list_button_delete: "删除",
      admin_application_translation_list_button_add: "添加",
      admin_application_translation_list_guide_empty: "列表为空",
      admin_application_translation_add_title: "添加应用程序翻译",
      admin_application_translation_add_validation_application_type: "* 请选择申请类型。",
      admin_application_translation_add_validation_os_type: "* 请选择操作系统类型。",
      admin_application_translation_add_validation_application_version: "* 请输入应用程序版本。",
      admin_application_translation_add_validation_version: "* 请输入版本。",
      admin_application_translation_add_validation_translation_file: "* 请输入翻译文件。",
      admin_application_translation_add_label_admin_application_type: "应用类型",
      admin_application_translation_add_placeholder_admin_application_type: "应用类型",
      admin_application_translation_add_select_admin_application_type_guest: "客人",
      admin_application_translation_add_select_admin_application_type_staff: "职员",
      admin_application_translation_add_select_admin_application_type_parking_staff: "停车人员",
      admin_application_translation_add_label_os_type: "操作系统类型",
      admin_application_translation_add_placeholder_os_type: "操作系统类型",
      admin_application_translation_add_select_os_type_aos: "奥索斯",
      admin_application_translation_add_select_os_type_ios: "iOS系统",
      admin_application_translation_add_label_admin_application_version: "应用版本",
      admin_application_translation_add_label_version: "版本",
      admin_application_translation_add_label_translation_file: "翻译文件",
      admin_application_translation_add_button_upload: "上传",
      admin_application_translation_add_placeholder_upload: "例如) Translation.json",
      admin_application_translation_add_button_go_to_list: "前往列表",
      admin_application_translation_add_button_add: "添加",
      admin_application_translation_detail_title: "申请翻译详情",
      admin_application_translation_detail_label_admin_application_type: "应用类型",
      admin_application_translation_detail_placeholder_select_box_choose: "选择",
      admin_application_translation_detail_select_admin_application_type_guest: "客人",
      admin_application_translation_detail_select_admin_application_type_staff: "职员",
      admin_application_translation_detail_label_os_type: "操作系统类型",
      admin_application_translation_detail_select_os_type_aos: "奥索斯",
      admin_application_translation_detail_select_os_type_ios: "iOS系统",
      admin_application_translation_detail_label_admin_application_version: "应用版本",
      admin_application_translation_detail_label_version: "版本",
      admin_application_translation_detail_label_file_name: "文件名",
      admin_application_translation_detail_label_date: "日期",
      admin_application_translation_detail_button_edit: "编辑",
      admin_application_translation_detail_button_go_to_list: "前往列表",
      admin_application_translation_detail_button_download: "下载",
      admin_application_translation_edit_title: "申请翻译详情",
      admin_application_translation_edit_button_download: "下载",
      admin_application_translation_edit_validation_application_type: "* 请选择申请类型。",
      admin_application_translation_edit_validation_os_type: "* 请选择操作系统类型。",
      admin_application_translation_edit_validation_application_version: "* 请输入应用程序版本。",
      admin_application_translation_edit_validation_version: "* 请输入版本。",
      admin_application_translation_edit_validation_translation_file: "* 请输入翻译文件。",
      admin_application_translation_edit_label_admin_application_type: "应用类型",
      admin_application_translation_edit_placeholder_select_box_choose: "选择",
      admin_application_translation_edit_select_admin_application_type_guest: "客人",
      admin_application_translation_edit_select_admin_application_type_staff: "职员",
      admin_application_translation_edit_label_os_type: "操作系统类型",
      admin_application_translation_edit_select_os_type_aos: "奥索斯",
      admin_application_translation_edit_select_os_type_ios: "iOS系统",
      admin_application_translation_edit_label_admin_application_version: "应用版本",
      admin_application_translation_edit_label_version: "版本",
      admin_application_translation_edit_label_file_name: "文件名",
      admin_application_translation_edit_label_date: "日期",
      admin_application_translation_edit_button_upload: "上传",
      admin_application_translation_edit_button_ok: "好的",
      admin_application_translation_edit_button_cancel: "取消",
      admin_booking_email_detail_label_format: "电子邮件图像格式",
      admin_booking_email_detail_label_image: "通过电子邮件发送图片",
      admin_booking_email_detail_button_upload: "上传",
      admin_booking_email_detail_placeholder_upload: "例）MyCONECT.png",
      admin_booking_email_detail_button_edit: "编辑",
      admin_email_form_change_password_edit_title: "更改密码",
      admin_email_form_change_password_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_change_password_edit_select_kr: "韩国",
      admin_email_form_change_password_edit_select_en: "CN",
      admin_email_form_change_password_edit_select_cn: "中国",
      admin_email_form_change_password_edit_select_jp: "J.P",
      admin_email_form_change_password_edit_select_ms: "MS",
      admin_email_form_change_password_edit_button_remove: "消除",
      admin_email_form_change_password_edit_button_append: "附加",
      admin_email_form_change_password_edit_button_save: "节省",
      admin_email_form_change_password_edit_toolbar_bold: "大胆的",
      admin_email_form_change_password_edit_toolbar_italic: "斜体",
      admin_email_form_change_password_edit_toolbar_underline: "强调",
      admin_email_form_change_password_edit_toolbar_strikethrough: "删除线",
      admin_email_form_change_password_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_change_password_edit_toolbar_font_family: "字体系列",
      admin_email_form_change_password_edit_toolbar_font_size: "字体大小",
      admin_email_form_change_password_edit_toolbar_text_color: "文字颜色",
      admin_email_form_change_password_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_change_password_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_change_password_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_change_password_edit_toolbar_table: "桌子",
      admin_email_form_change_password_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_change_password_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_change_password_edit_toolbar_paragraph: "段落",
      admin_email_form_change_password_edit_toolbar_line_height: "行高",
      admin_email_form_change_password_edit_toolbar_picture: "图片",
      admin_email_form_change_password_edit_toolbar_code_view: "代码视图",
      admin_email_form_change_password_edit_toolbar_full_screen: "全屏",
      admin_email_form_change_password_edit_toolbar_help: "帮助",
      admin_email_form_change_password_edit_toolbar_tip: "提示",
      admin_email_form_change_password_edit_placeholder_content: "请输入内容。",
      admin_email_form_forgot_id_edit_title: "忘记ID",
      admin_email_form_forgot_id_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_forgot_id_edit_select_kr: "韩国",
      admin_email_form_forgot_id_edit_select_en: "CN",
      admin_email_form_forgot_id_edit_select_cn: "中国",
      admin_email_form_forgot_id_edit_select_jp: "J.P",
      admin_email_form_forgot_id_edit_select_ms: "MS",
      admin_email_form_forgot_id_edit_button_remove: "消除",
      admin_email_form_forgot_id_edit_button_append: "附加",
      admin_email_form_forgot_id_edit_button_save: "节省",
      admin_email_form_forgot_id_edit_toolbar_bold: "大胆的",
      admin_email_form_forgot_id_edit_toolbar_italic: "斜体",
      admin_email_form_forgot_id_edit_toolbar_underline: "强调",
      admin_email_form_forgot_id_edit_toolbar_strikethrough: "删除线",
      admin_email_form_forgot_id_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_forgot_id_edit_toolbar_font_family: "字体系列",
      admin_email_form_forgot_id_edit_toolbar_font_size: "字体大小",
      admin_email_form_forgot_id_edit_toolbar_text_color: "文字颜色",
      admin_email_form_forgot_id_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_forgot_id_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_forgot_id_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_forgot_id_edit_toolbar_table: "桌子",
      admin_email_form_forgot_id_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_forgot_id_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_forgot_id_edit_toolbar_paragraph: "段落",
      admin_email_form_forgot_id_edit_toolbar_line_height: "行高",
      admin_email_form_forgot_id_edit_toolbar_picture: "图片",
      admin_email_form_forgot_id_edit_toolbar_code_view: "代码视图",
      admin_email_form_forgot_id_edit_toolbar_full_screen: "全屏",
      admin_email_form_forgot_id_edit_toolbar_help: "帮助",
      admin_email_form_forgot_id_edit_toolbar_tip: "提示",
      admin_email_form_forgot_id_edit_placeholder_content: "请输入内容。",
      admin_email_form_forgot_id_detail_title: "忘记 ID 字符",
      admin_email_form_forgot_id_detail_label_user_info: "用户信息",
      admin_email_form_forgot_id_detail_label_example_user_info: "{{用户信息}}",
      admin_email_form_forgot_password_edit_title: "忘记密码",
      admin_email_form_forgot_password_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_forgot_password_edit_select_kr: "韩国",
      admin_email_form_forgot_password_edit_select_en: "CN",
      admin_email_form_forgot_password_edit_select_cn: "中国",
      admin_email_form_forgot_password_edit_select_jp: "J.P",
      admin_email_form_forgot_password_edit_select_ms: "MS",
      admin_email_form_forgot_password_edit_button_remove: "消除",
      admin_email_form_forgot_password_edit_button_append: "附加",
      admin_email_form_forgot_password_edit_button_save: "节省",
      admin_email_form_forgot_password_edit_toolbar_bold: "大胆的",
      admin_email_form_forgot_password_edit_toolbar_italic: "斜体",
      admin_email_form_forgot_password_edit_toolbar_underline: "强调",
      admin_email_form_forgot_password_edit_toolbar_strikethrough: "删除线",
      admin_email_form_forgot_password_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_forgot_password_edit_toolbar_font_family: "字体系列",
      admin_email_form_forgot_password_edit_toolbar_font_size: "字体大小",
      admin_email_form_forgot_password_edit_toolbar_text_color: "文字颜色",
      admin_email_form_forgot_password_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_forgot_password_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_forgot_password_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_forgot_password_edit_toolbar_table: "桌子",
      admin_email_form_forgot_password_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_forgot_password_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_forgot_password_edit_toolbar_paragraph: "段落",
      admin_email_form_forgot_password_edit_toolbar_line_height: "行高",
      admin_email_form_forgot_password_edit_toolbar_picture: "图片",
      admin_email_form_forgot_password_edit_toolbar_code_view: "代码视图",
      admin_email_form_forgot_password_edit_toolbar_full_screen: "全屏",
      admin_email_form_forgot_password_edit_toolbar_help: "帮助",
      admin_email_form_forgot_password_edit_toolbar_tip: "提示",
      admin_email_form_forgot_password_edit_placeholder_content: "请输入内容。",
      admin_email_form_forgot_password_detail_title: "忘记密码字符",
      admin_email_form_forgot_password_detail_label_email: "电子邮件",
      admin_email_form_forgot_password_detail_label_example_email: "{{电子邮件}}",
      admin_email_form_forgot_password_detail_label_password: "密码",
      admin_email_form_forgot_password_detail_label_example_password: "{{密码}}",
      admin_email_form_create_confirm_edit_title: "创建电子邮件确认",
      admin_email_form_create_confirm_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_create_confirm_edit_select_kr: "韩国",
      admin_email_form_create_confirm_edit_select_en: "CN",
      admin_email_form_create_confirm_edit_select_cn: "中国",
      admin_email_form_create_confirm_edit_select_jp: "J.P",
      admin_email_form_create_confirm_edit_select_ms: "MS",
      admin_email_form_create_confirm_edit_button_remove: "消除",
      admin_email_form_create_confirm_edit_button_append: "附加",
      admin_email_form_create_confirm_edit_button_save: "节省",
      admin_email_form_create_confirm_edit_toolbar_bold: "大胆的",
      admin_email_form_create_confirm_edit_toolbar_italic: "斜体",
      admin_email_form_create_confirm_edit_toolbar_underline: "强调",
      admin_email_form_create_confirm_edit_toolbar_strikethrough: "删除线",
      admin_email_form_create_confirm_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_create_confirm_edit_toolbar_font_family: "字体系列",
      admin_email_form_create_confirm_edit_toolbar_font_size: "字体大小",
      admin_email_form_create_confirm_edit_toolbar_text_color: "文字颜色",
      admin_email_form_create_confirm_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_create_confirm_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_create_confirm_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_create_confirm_edit_toolbar_table: "桌子",
      admin_email_form_create_confirm_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_create_confirm_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_create_confirm_edit_toolbar_paragraph: "段落",
      admin_email_form_create_confirm_edit_toolbar_line_height: "行高",
      admin_email_form_create_confirm_edit_toolbar_picture: "图片",
      admin_email_form_create_confirm_edit_toolbar_code_view: "代码视图",
      admin_email_form_create_confirm_edit_toolbar_full_screen: "全屏",
      admin_email_form_create_confirm_edit_toolbar_help: "帮助",
      admin_email_form_create_confirm_edit_toolbar_tip: "提示",
      admin_email_form_create_confirm_edit_placeholder_content: "请输入内容。",
      admin_email_form_create_confirm_detail_title: "创建确认字符",
      admin_email_form_create_confirm_detail_label_url: "网址",
      admin_email_form_create_confirm_detail_example_url: "{{网址}}",
      admin_email_form_create_confirm_failure_edit_title: "确认邮件失败",
      admin_email_form_create_confirm_failure_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_create_confirm_failure_edit_select_kr: "韩国",
      admin_email_form_create_confirm_failure_edit_select_en: "CN",
      admin_email_form_create_confirm_failure_edit_select_cn: "中国",
      admin_email_form_create_confirm_failure_edit_select_jp: "J.P",
      admin_email_form_create_confirm_failure_edit_select_ms: "MS",
      admin_email_form_create_confirm_failure_edit_button_remove: "消除",
      admin_email_form_create_confirm_failure_edit_button_append: "附加",
      admin_email_form_create_confirm_failure_edit_button_save: "节省",
      admin_email_form_create_confirm_failure_edit_toolbar_bold: "大胆的",
      admin_email_form_create_confirm_failure_edit_toolbar_italic: "斜体",
      admin_email_form_create_confirm_failure_edit_toolbar_underline: "强调",
      admin_email_form_create_confirm_failure_edit_toolbar_strikethrough: "删除线",
      admin_email_form_create_confirm_failure_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_create_confirm_failure_edit_toolbar_font_family: "字体系列",
      admin_email_form_create_confirm_failure_edit_toolbar_font_size: "字体大小",
      admin_email_form_create_confirm_failure_edit_toolbar_text_color: "文字颜色",
      admin_email_form_create_confirm_failure_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_create_confirm_failure_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_create_confirm_failure_edit_toolbar_table: "桌子",
      admin_email_form_create_confirm_failure_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_create_confirm_failure_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_create_confirm_failure_edit_toolbar_paragraph: "段落",
      admin_email_form_create_confirm_failure_edit_toolbar_line_height: "行高",
      admin_email_form_create_confirm_failure_edit_toolbar_picture: "图片",
      admin_email_form_create_confirm_failure_edit_toolbar_code_view: "代码视图",
      admin_email_form_create_confirm_failure_edit_toolbar_full_screen: "全屏",
      admin_email_form_create_confirm_failure_edit_toolbar_help: "帮助",
      admin_email_form_create_confirm_failure_edit_toolbar_tip: "提示",
      admin_email_form_create_confirm_failure_edit_placeholder_content: "请输入内容。",
      admin_email_form_create_confirm_failure_detail_title: "创建电子邮件失败内容",
      admin_email_form_create_confirm_failure_detail_label_reason: "原因",
      admin_email_form_create_confirm_failure_detail_label_example_reason: "{{原因}}",
      admin_email_form_create_confirm_success_edit_title: "创建成功确认电子邮件",
      admin_email_form_create_confirm_success_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_create_confirm_success_edit_select_kr: "韩国",
      admin_email_form_create_confirm_success_edit_select_en: "CN",
      admin_email_form_create_confirm_success_edit_select_cn: "中国",
      admin_email_form_create_confirm_success_edit_select_jp: "J.P",
      admin_email_form_create_confirm_success_edit_select_ms: "MS",
      admin_email_form_create_confirm_success_edit_button_remove: "消除",
      admin_email_form_create_confirm_success_edit_button_append: "附加",
      admin_email_form_create_confirm_success_edit_button_save: "节省",
      admin_email_form_create_confirm_success_edit_toolbar_bold: "大胆的",
      admin_email_form_create_confirm_success_edit_toolbar_italic: "斜体",
      admin_email_form_create_confirm_success_edit_toolbar_underline: "强调",
      admin_email_form_create_confirm_success_edit_toolbar_strikethrough: "删除线",
      admin_email_form_create_confirm_success_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_create_confirm_success_edit_toolbar_font_family: "字体系列",
      admin_email_form_create_confirm_success_edit_toolbar_font_size: "字体大小",
      admin_email_form_create_confirm_success_edit_toolbar_text_color: "文字颜色",
      admin_email_form_create_confirm_success_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_create_confirm_success_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_create_confirm_success_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_create_confirm_success_edit_toolbar_table: "桌子",
      admin_email_form_create_confirm_success_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_create_confirm_success_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_create_confirm_success_edit_toolbar_paragraph: "段落",
      admin_email_form_create_confirm_success_edit_toolbar_line_height: "行高",
      admin_email_form_create_confirm_success_edit_toolbar_picture: "图片",
      admin_email_form_create_confirm_success_edit_toolbar_code_view: "代码视图",
      admin_email_form_create_confirm_success_edit_toolbar_full_screen: "全屏",
      admin_email_form_create_confirm_success_edit_toolbar_help: "帮助",
      admin_email_form_create_confirm_success_edit_toolbar_tip: "提示",
      admin_email_form_create_confirm_success_edit_placeholder_content: "请输入内容。",
      admin_email_form_update_profile_edit_title: "更新个人信息",
      admin_email_form_update_profile_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_update_profile_edit_select_kr: "韩国",
      admin_email_form_update_profile_edit_select_en: "CN",
      admin_email_form_update_profile_edit_select_cn: "中国",
      admin_email_form_update_profile_edit_select_jp: "J.P",
      admin_email_form_update_profile_edit_select_ms: "MS",
      admin_email_form_update_profile_edit_button_remove: "消除",
      admin_email_form_update_profile_edit_button_append: "附加",
      admin_email_form_update_profile_edit_button_save: "节省",
      admin_email_form_update_profile_edit_toolbar_bold: "大胆的",
      admin_email_form_update_profile_edit_toolbar_italic: "斜体",
      admin_email_form_update_profile_edit_toolbar_underline: "强调",
      admin_email_form_update_profile_edit_toolbar_strikethrough: "删除线",
      admin_email_form_update_profile_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_update_profile_edit_toolbar_font_family: "字体系列",
      admin_email_form_update_profile_edit_toolbar_font_size: "字体大小",
      admin_email_form_update_profile_edit_toolbar_text_color: "文字颜色",
      admin_email_form_update_profile_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_update_profile_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_update_profile_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_update_profile_edit_toolbar_table: "桌子",
      admin_email_form_update_profile_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_update_profile_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_update_profile_edit_toolbar_paragraph: "段落",
      admin_email_form_update_profile_edit_toolbar_line_height: "行高",
      admin_email_form_update_profile_edit_toolbar_picture: "图片",
      admin_email_form_update_profile_edit_toolbar_code_view: "代码视图",
      admin_email_form_update_profile_edit_toolbar_full_screen: "全屏",
      admin_email_form_update_profile_edit_toolbar_help: "帮助",
      admin_email_form_update_profile_edit_toolbar_tip: "提示",
      admin_email_form_update_profile_edit_placeholder_content: "请输入内容。",
      admin_email_form_withdraw_edit_title: "提取",
      admin_email_form_withdraw_edit_validation_content: "* 请输入您的详细信息。",
      admin_email_form_withdraw_edit_select_kr: "韩国",
      admin_email_form_withdraw_edit_select_en: "CN",
      admin_email_form_withdraw_edit_select_cn: "中国",
      admin_email_form_withdraw_edit_select_jp: "J.P",
      admin_email_form_withdraw_edit_select_ms: "MS",
      admin_email_form_withdraw_edit_button_remove: "消除",
      admin_email_form_withdraw_edit_button_append: "附加",
      admin_email_form_withdraw_edit_button_save: "节省",
      admin_email_form_withdraw_edit_toolbar_bold: "大胆的",
      admin_email_form_withdraw_edit_toolbar_italic: "斜体",
      admin_email_form_withdraw_edit_toolbar_underline: "强调",
      admin_email_form_withdraw_edit_toolbar_strikethrough: "删除线",
      admin_email_form_withdraw_edit_toolbar_remove_font_style: "删除字体样式",
      admin_email_form_withdraw_edit_toolbar_font_family: "字体系列",
      admin_email_form_withdraw_edit_toolbar_font_size: "字体大小",
      admin_email_form_withdraw_edit_toolbar_text_color: "文字颜色",
      admin_email_form_withdraw_edit_toolbar_text_more_color: "更多颜色",
      admin_email_form_withdraw_edit_toolbar_recent_color: "最近的颜色",
      admin_email_form_withdraw_edit_toolbar_recent_more_color: "更多颜色",
      admin_email_form_withdraw_edit_toolbar_table: "桌子",
      admin_email_form_withdraw_edit_toolbar_unordered_list: "无序列表",
      admin_email_form_withdraw_edit_toolbar_ordered_list: "有序列表",
      admin_email_form_withdraw_edit_toolbar_paragraph: "段落",
      admin_email_form_withdraw_edit_toolbar_line_height: "行高",
      admin_email_form_withdraw_edit_toolbar_picture: "图片",
      admin_email_form_withdraw_edit_toolbar_code_view: "代码视图",
      admin_email_form_withdraw_edit_toolbar_full_screen: "全屏",
      admin_email_form_withdraw_edit_toolbar_help: "帮助",
      admin_email_form_withdraw_edit_toolbar_tip: "提示",
      admin_email_form_withdraw_edit_placeholder_content: "请输入内容。",
      admin_endpoint_list_title: "应用端点",
      admin_endpoint_list_placeholiser_search: "搜索...",
      admin_endpoint_list_label_package_name: "包裹名字",
      admin_endpoint_list_label_os: "操作系统",
      admin_endpoint_list_label_version: "版本",
      admin_endpoint_list_label_url: "网址",
      admin_endpoint_list_label_created_at: "创建于",
      admin_endpoint_list_guide_empty: "列表为空",
      admin_endpoint_list_button_delete: "删除",
      admin_endpoint_list_button_add: "添加",
      admin_endpoint_add_title: "添加应用程序端点",
      admin_endpoint_add_validation_package_name: "* 请选择包名。",
      admin_endpoint_add_validation_os: "* 请选择操作系统。",
      admin_endpoint_add_validation_version: "* 请输入版本。",
      admin_endpoint_add_validation_url: "* 请选择一个网址。",
      admin_endpoint_add_label_package_name: "包裹名字",
      admin_endpoint_add_placeholder_choose: "选择",
      admin_endpoint_add_select_os_staff: "职员",
      admin_endpoint_add_select_os_guest: "客人",
      admin_endpoint_add_select_os_staff_parking: "员工停车场",
      admin_endpoint_add_label_os: "操作系统",
      admin_endpoint_add_select_os_ios: "iOS系统",
      admin_endpoint_add_select_os_aos: "奥索斯",
      admin_endpoint_add_label_version: "版本",
      admin_endpoint_add_label_url: "网址",
      admin_endpoint_add_button_go_to_list: "前往列表",
      admin_endpoint_add_button_add: "添加",
      admin_endpoint_detail_title: "应用程序端点信息",
      admin_endpoint_detail_label_package_name: "包裹名字",
      admin_endpoint_detail_placeholder_choose: "选择",
      admin_endpoint_detail_select_os_staff: "职员",
      admin_endpoint_detail_select_os_guest: "客人",
      admin_endpoint_detail_select_os_staff_parking: "员工停车场",
      admin_endpoint_detail_label_os: "操作系统",
      admin_endpoint_detail_select_os_ios: "iOS系统",
      admin_endpoint_detail_select_os_aos: "奥索斯",
      admin_endpoint_detail_label_version: "版本",
      admin_endpoint_detail_label_url: "网址",
      admin_endpoint_detail_label_create_at: "创建于",
      admin_endpoint_detail_button_go_to_list: "前往列表",
      admin_endpoint_detail_button_delete: "删除",
      admin_endpoint_detail_button_edit: "编辑",
      admin_endpoint_edit_title: "应用程序端点信息",
      admin_endpoint_edit_validation_package_name: "* 请选择包名。",
      admin_endpoint_edit_validation_os: "* 请选择操作系统。",
      admin_endpoint_edit_validation_version: "* 请输入版本。",
      admin_endpoint_edit_validation_url: "* 请选择一个网址。",
      admin_endpoint_edit_label_package_name: "包裹名字",
      admin_endpoint_edit_placeholder_choose: "选择",
      admin_endpoint_edit_select_os_staff: "职员",
      admin_endpoint_edit_select_os_guest: "客人",
      admin_endpoint_edit_select_os_staff_parking: "员工停车场",
      admin_endpoint_edit_label_os: "操作系统",
      admin_endpoint_edit_select_os_ios: "iOS系统",
      admin_endpoint_edit_select_os_aos: "奥索斯",
      admin_endpoint_edit_label_version: "版本",
      admin_endpoint_edit_label_url: "网址",
      admin_endpoint_edit_label_create_at: "创建于",
      admin_endpoint_edit_button_cancel: "取消",
      admin_endpoint_edit_button_delete: "删除",
      admin_endpoint_edit_button_ok: "好的",
      admin_terms_list_title: "术语表",
      admin_terms_list_placeholder_search: "搜索...",
      admin_terms_list_label_language_code: "语言代码",
      admin_terms_list_label_file_name: "文件名",
      admin_terms_list_label_required: "必需的",
      admin_terms_list_label_title: "标题",
      admin_terms_list_label_terms_group_id: "术语组 ID",
      admin_terms_list_label_created_at: "创建于",
      admin_terms_list_guide_empty: "列表为空",
      admin_terms_list_button_delete: "删除",
      admin_terms_list_button_add: "添加",
      admin_terms_add_title: "添加条款",
      admin_terms_add_validation_title: "* 请输入标题。",
      admin_terms_add_validation_terms_group_id: "* 请选择术语组 ID。",
      admin_terms_add_validation_language_code: "* 请输入语言代码。",
      admin_terms_add_validation_required: "* 请选择是否需要。",
      admin_terms_add_validation_description: "* 请输入描述。",
      admin_terms_add_validation_link_text: "* 请输入链接文字。",
      admin_terms_add_validation_file: "* 请上传文件。",
      admin_terms_add_label_title: "标题",
      admin_terms_add_label_terms_group_id: "术语组 ID",
      admin_terms_add_label_choose: "选择",
      admin_terms_add_label_language_code: "语言代码",
      admin_terms_add_label_required: "必需的",
      admin_terms_add_button_yes: "伊瑟",
      admin_terms_add_button_no: "不",
      admin_terms_add_label_description: "描述",
      admin_terms_add_label_link_text: "链接文字",
      admin_terms_add_label_file_name: "文件名",
      admin_terms_add_label_file_url: "文件网址",
      admin_terms_add_button_upload: "上传",
      admin_terms_add_button_go_to_list: "前往列表",
      admin_terms_add_button_add: "添加",
      admin_terms_detail_title: "条款信息",
      admin_terms_detail_label_title: "标题",
      admin_terms_detail_label_language_code: "语言代码",
      admin_terms_detail_label_description: "描述",
      admin_terms_detail_label_link_text: "链接文字",
      admin_terms_detail_label_required: "必需的",
      admin_terms_detail_button_yes: "是的",
      admin_terms_detail_button_no: "不",
      admin_terms_detail_label_terms_group_id: "术语组 ID",
      admin_terms_detail_label_choose: "选择",
      admin_terms_detail_label_file_name: "文件名",
      admin_terms_detail_label_file_url: "文件网址",
      admin_terms_detail_button_upload: "上传",
      admin_terms_detail_label_created_at: "创建于",
      admin_terms_detail_label_updated_at: "更新于",
      admin_terms_detail_button_go_to_list: "前往列表",
      admin_terms_detail_button_edit: "编辑",
      admin_terms_edit_title: "条款信息",
      admin_terms_edit_validation_title: "* 请输入标题。",
      admin_terms_edit_validation_terms_group_id: "* 请选择术语组 ID。",
      admin_terms_edit_validation_language_code: "* 请输入语言代码。",
      admin_terms_edit_validation_required: "* 请选择是否需要。",
      admin_terms_edit_validation_description: "* 请输入描述。",
      admin_terms_edit_validation_link_text: "* 请输入链接文字。",
      admin_terms_edit_validation_file: "* 请上传文件。",
      admin_terms_edit_label_title: "标题",
      admin_terms_edit_label_language_code: "语言代码",
      admin_terms_edit_label_description: "描述",
      admin_terms_edit_label_link_text: "链接文字",
      admin_terms_edit_label_required: "必需的",
      admin_terms_edit_button_yes: "是的",
      admin_terms_edit_button_no: "不",
      admin_terms_edit_label_terms_group_id: "术语组 ID",
      admin_terms_edit_label_choose: "选择",
      admin_terms_edit_label_file_name: "文件名",
      admin_terms_edit_label_file_url: "文件网址",
      admin_terms_edit_button_upload: "上传",
      admin_terms_edit_label_created_at: "创建于",
      admin_terms_edit_label_updated_at: "更新于",
      admin_terms_edit_button_cancel: "取消",
      admin_terms_edit_button_ok: "好的",
      admin_terms_group_list_title: "术语组列表",
      admin_terms_group_list_placeholder_search: "搜索...",
      admin_terms_group_list_label_title: "标题",
      admin_terms_group_list_label_country_code: "国家代码",
      admin_terms_group_list_label_created_at: "创建于",
      admin_terms_group_list_guide_empty: "列表为空",
      admin_terms_group_list_button_delete: "删除",
      admin_terms_group_list_button_add: "添加",
      admin_terms_group_add_title: "添加条款组",
      admin_terms_group_add_validation_title: "* 请输入标题。",
      admin_terms_group_add_validation_country_code: "* 请输入国家代码。",
      admin_terms_group_add_label_title: "标题",
      admin_terms_group_add_label_country_code: "国家代码",
      admin_terms_group_add_button_go_to_list: "前往列表",
      admin_terms_group_add_button_add: "添加",
      admin_terms_group_detail_title: "条款组信息",
      admin_terms_group_detail_label_title: "标题",
      admin_terms_group_detail_label_country_code: "国家代码",
      admin_terms_group_detail_label_created_at: "创建于",
      admin_terms_group_detail_label_updated_at: "更新于",
      admin_terms_group_detail_button_go_to_list: "前往列表",
      admin_terms_group_detail_button_edit: "编辑",
      admin_terms_group_edit_title: "条款信息",
      admin_terms_group_edit_validation_title: "* 请输入标题。",
      admin_terms_group_edit_validation_country_code: "* 请输入国家代码。",
      admin_terms_group_edit_label_title: "标题",
      admin_terms_group_edit_label_country_code: "国家代码",
      admin_terms_group_edit_label_created_at: "创建于",
      admin_terms_group_edit_label_updated_at: "更新于",
      admin_terms_group_edit_button_cancel: "取消",
      admin_terms_group_edit_button_ok: "好的",
      admin_agreement_list_title: "协议历史列表",
      admin_agreement_list_placeholder_email: "请输入您的电子邮件。",
      admin_agreement_list_placeholder_search: "搜索...",
      admin_agreement_list_label_no: "不。",
      admin_agreement_list_label_user_id: "用户身份",
      admin_agreement_list_label_terms_id: "条款 ID",
      admin_agreement_list_label_agreed: "同意",
      admin_agreement_list_label_email: "电子邮件",
      admin_agreement_list_label_name: "姓名",
      admin_agreement_list_label_file_name: "文件名",
      admin_agreement_list_label_created_at: "创建于",
      admin_agreement_detail_title: "协议历史信息",
      admin_agreement_detail_label_agreement: "协议",
      admin_agreement_detail_label_agreed: "同意",
      admin_agreement_detail_label_agreement_created_at: "协议确认于",
      admin_agreement_detail_label_user: "用户",
      admin_agreement_detail_label_user_id: "用户身份",
      admin_agreement_detail_label_email: "电子邮件",
      admin_agreement_detail_label_name: "姓名",
      admin_agreement_detail_label_email_confimed_at: "电子邮件确认于",
      admin_agreement_detail_label_user_language_code: "用户语言代码",
      admin_agreement_detail_label_user_created_at: "用户创建于",
      admin_agreement_detail_label_terms: "条款",
      admin_agreement_detail_label_terms_id: "条款 ID",
      admin_agreement_detail_label_terms_language_code: "术语 语言代码",
      admin_agreement_detail_label_file_name: "文件名",
      admin_agreement_detail_label_required: "必需的",
      admin_agreement_detail_label_title: "标题",
      admin_agreement_detail_label_description: "描述",
      admin_agreement_detail_label_link_text: "链接文字",
      admin_agreement_detail_label_terms_group_id: "术语组 ID",
      admin_agreement_detail_label_terms_created_at: "条款创建于",
      admin_agreement_detail_button_go_to_list: "前往列表",
      admin_auth_data_list_title: "数据验证收集，\n使用和提供个人位置信息",
      admin_auth_data_list_placeholder_select_box_authentication_data: "认证数据",
      admin_auth_data_list_select_hotel_id: "酒店ID",
      admin_auth_data_list_select_email: "电子邮件",
      admin_auth_data_list_label_no: "不。",
      admin_auth_data_list_placeholder_search: "搜索...",
      admin_auth_data_list_label_user_email: "电子邮件",
      admin_auth_data_list_label_acquisition_path: "获取途径",
      admin_auth_data_list_label_service: "服务",
      admin_auth_data_list_label_recipient: "接受者",
      admin_auth_data_list_label_request_date: "查询日期",
      admin_auth_data_list_label_end_date: "结束日期",
      admin_auth_data_list_guide_empty: "列表为空",
      admin_use_key_log_list_title: "使用按键日志列表",
      admin_use_key_log_list_placeholder_select_box_use_key_log: "使用按键日志",
      admin_use_key_log_list_select_hotel_id: "酒店ID",
      admin_use_key_log_list_select_email: "电子邮件",
      admin_use_key_log_list_placeholder_search: "搜索...",
      admin_use_key_log_list_label_no: "不。",
      admin_use_key_log_list_label_user_email: "用户邮箱",
      admin_use_key_log_list_label_device: "设备",
      admin_use_key_log_list_label_app_version: "应用版本",
      admin_use_key_log_list_label_hotel_id: "酒店ID",
      admin_use_key_log_list_label_key_id: "钥匙编号",
      admin_use_key_log_list_label_date: "日期",
      admin_use_key_log_list_guide_empty: "列表为空",
      admin_use_key_log_detail_title: "使用关键日志详细信息",
      admin_use_key_log_detail_label_use_key_log_id: "使用按键日志 ID",
      admin_use_key_log_detail_label_user_id: "用户身份",
      admin_use_key_log_detail_label_user_email: "用户邮箱",
      admin_use_key_log_detail_label_hotel_key_id: "酒店钥匙 ID",
      admin_use_key_log_detail_label_hotel_id: "酒店ID",
      admin_use_key_log_detail_label_device: "设备",
      admin_use_key_log_detail_label_app_version: "应用版本",
      admin_use_key_log_detail_label_log: "日志",
      admin_use_key_log_detail_label_created_at: "创建于",
      admin_use_key_log_detail_label_updated_at: "更新于",
      admin_use_key_log_detail_button_go_to_list: "前往列表",
      admin_amenity_list_title: "设施清单",
      admin_amenity_list_placeholder_search: "搜索...",
      admin_amenity_list_label_type: "类型",
      admin_amenity_list_label_group: "团体",
      admin_amenity_list_label_code: "代码",
      admin_amenity_list_label_name: "姓名",
      admin_amenity_list_label_date: "日期",
      admin_amenity_list_button_delete: "删除",
      admin_amenity_list_button_add: "添加",
      admin_amenity_list_guide_empty: "列表为空",
      admin_amenity_add_title: "添加便利设施",
      admin_amenity_add_validation_type: "* 请选择类型。",
      admin_amenity_add_validation_group: "* 请输入群组。",
      admin_amenity_add_validation_code: "* 请输入代码。",
      admin_amenity_add_validation_name: "* 请输入姓名。",
      admin_amenity_add_validation_icon_image: "* 请上传图标图像。",
      admin_amenity_add_label_type: "类型",
      admin_amenity_add_placeholder_select_box_choose: "选择",
      admin_amenity_add_select_hotel: "酒店",
      admin_amenity_add_select_room: "房间",
      admin_amenity_add_label_group: "团体",
      admin_amenity_add_label_code: "代码",
      admin_amenity_add_label_name: "姓名",
      admin_amenity_add_label_icon: "图标",
      admin_amenity_add_button_upload: "上传",
      admin_amenity_add_placeholder_upload: "例）MyCONECT.png",
      admin_amenity_add_button_go_to_list: "前往列表",
      admin_amenity_add_button_add: "添加",
      admin_amenity_detail_title: "设施信息",
      admin_amenity_detail_label_type: "类型",
      admin_amenity_detail_select_hotel: "酒店",
      admin_amenity_detail_select_room: "房间",
      admin_amenity_detail_label_group: "团体",
      admin_amenity_detail_label_code: "代码",
      admin_amenity_detail_label_name: "姓名",
      admin_amenity_detail_label_icon: "图标",
      admin_amenity_detail_button_upload: "上传",
      admin_amenity_detail_placeholder_upload: "例）MyCONECT.png",
      admin_amenity_detail_label_update_date: "更新日期",
      admin_amenity_detail_button_go_to_list: "前往列表",
      admin_amenity_detail_button_cancel: "取消",
      admin_amenity_detail_button_delete: "删除",
      admin_amenity_detail_button_edit: "编辑",
      admin_amenity_detail_button_ok: "好的",
      admin_amenity_edit_title: "设施信息",
      admin_amenity_edit_validation_type: "* 请选择类型。",
      admin_amenity_edit_validation_group: "* 请输入群组。",
      admin_amenity_edit_validation_code: "* 请输入代码。",
      admin_amenity_edit_validation_name: "* 请输入姓名。",
      admin_amenity_edit_validation_icon_image: "* 请上传图标图像。",
      admin_amenity_edit_label_type: "类型",
      admin_amenity_edit_placeholder_select_box_choose: "选择",
      admin_amenity_edit_select_hotel: "酒店",
      admin_amenity_edit_select_room: "房间",
      admin_amenity_edit_label_group: "团体",
      admin_amenity_edit_label_code: "代码",
      admin_amenity_edit_label_name: "姓名",
      admin_amenity_edit_label_icon: "图标",
      admin_amenity_edit_button_upload: "上传",
      admin_amenity_edit_placeholder_upload: "例）MyCONECT.png",
      admin_amenity_edit_label_update_date: "更新日期",
      admin_amenity_edit_button_cancel: "取消",
      admin_amenity_edit_button_delete: "删除",
      admin_amenity_edit_button_ok: "好的",
      admin_facility_img_list_title: "酒店设施图片列表",
      admin_facility_img_list_label_facility_img: "酒店设施形象",
      admin_facility_img_list_label_description: "描述",
      admin_facility_img_list_label_date: "日期",
      admin_facility_img_list_button_delete: "删除",
      admin_facility_img_list_button_add: "添加",
      admin_facility_img_list_guide_empty: "列表为空",
      admin_facility_img_add_title: "添加酒店设施图片",
      admin_facility_img_add_validation_image: "* 请上传图片。",
      admin_facility_img_add_validation_description: "* 请输入描述。",
      admin_facility_img_add_label_img: "图像",
      admin_facility_img_add_button_upload: "上传",
      admin_facility_img_add_label_description: "描述",
      admin_facility_img_add_button_go_to_list: "前往列表",
      admin_facility_img_add_button_add: "添加",
      admin_facility_img_detail_title: "酒店设施图像信息",
      admin_facility_img_detail_label_img: "图像",
      admin_facility_img_detail_button_upload: "上传",
      admin_facility_img_detail_label_description: "描述",
      admin_facility_img_detail_label_updated_at: "更新于",
      admin_facility_img_detail_button_go_to_list: "前往列表",
      admin_facility_img_detail_button_cancel: "取消",
      admin_facility_img_detail_button_delete: "删除",
      admin_facility_img_detail_button_edit: "编辑",
      admin_facility_img_detail_button_ok: "好的",
      admin_facility_img_edit_title: "酒店设施图像信息",
      admin_facility_img_edit_validation_image: "* 请上传图片。",
      admin_facility_img_edit_validation_description: "* 请输入描述。",
      admin_facility_img_edit_label_img: "图像",
      admin_facility_img_edit_button_upload: "上传",
      admin_facility_img_edit_label_description: "描述",
      admin_facility_img_edit_label_updated_at: "更新于",
      admin_facility_img_edit_button_cancel: "取消",
      admin_facility_img_edit_button_delete: "删除",
      admin_facility_img_edit_button_ok: "好的",
      admin_recommend_app_list_title: "推荐应用程序列表",
      admin_recommend_app_list_select_name: "姓名",
      admin_recommend_app_list_placeholder_search: "搜索...",
      admin_recommend_app_list_label_name: "姓名",
      admin_recommend_app_list_label_ios_scheme: "iOS方案",
      admin_recommend_app_list_label_aos_package_id: "AOS 包 ID",
      admin_recommend_app_list_label_date: "日期",
      admin_recommend_app_list_button_delete: "删除",
      admin_recommend_app_list_button_add: "添加",
      admin_recommend_app_list_guide_empty: "列表为空",
      admin_recommend_app_add_title: "添加推荐APP",
      admin_recommend_app_add_validation_name: "* 请输入姓名。",
      admin_recommend_app_add_validation_ios_scheme: "* 请输入iOS方案。",
      admin_recommend_app_add_validation_ios_itunes_url: "* 请输入 iOS iTunes URL。",
      admin_recommend_app_add_validation_aos_package_id: "* 请输入 AOS 包 ID。",
      admin_recommend_app_add_validation_aos_play_store_url: "* 请输入 AOS Play 商店 URL。",
      admin_recommend_app_add_label_name: "姓名",
      admin_recommend_app_add_label_ios_scheme: "iOS方案",
      admin_recommend_app_add_label_ios_itunes_url: "iOS iTunes 网址",
      admin_recommend_app_add_label_aos_package_id: "AOS 包 ID",
      admin_recommend_app_add_label_aos_play_store_url: "AOS Play 商店网址",
      admin_recommend_app_add_button_go_to_list: "前往列表",
      admin_recommend_app_add_button_add: "添加",
      admin_recommend_app_detail_title: "推荐应用信息",
      admin_recommend_app_detail_label_name: "姓名",
      admin_recommend_app_detail_label_ios_scheme: "iOS方案",
      admin_recommend_app_detail_label_ios_itunes_url: "iOS iTunes 网址",
      admin_recommend_app_detail_label_aos_package_id: "AOS 包 ID",
      admin_recommend_app_detail_label_aos_play_store_url: "AOS Play 商店网址",
      admin_recommend_app_detail_label_updated_at: "更新于",
      admin_recommend_app_detail_button_go_to_list: "前往列表",
      admin_recommend_app_detail_button_delete: "删除",
      admin_recommend_app_detail_button_edit: "编辑",
      admin_recommend_app_edit_title: "推荐应用信息",
      admin_recommend_app_edit_validation_name: "* 请输入姓名。",
      admin_recommend_app_edit_validation_ios_scheme: "* 请输入iOS方案。",
      admin_recommend_app_edit_validation_ios_itunes_url: "* 请输入 iOS iTunes URL。",
      admin_recommend_app_edit_validation_aos_package_id: "* 请输入 AOS 包 ID。",
      admin_recommend_app_edit_validation_aos_play_store_url: "* 请输入 AOS Play 商店 URL。",
      admin_recommend_app_edit_label_name: "姓名",
      admin_recommend_app_edit_label_ios_scheme: "iOS方案",
      admin_recommend_app_edit_label_ios_itunes_url: "iOS iTunes 网址",
      admin_recommend_app_edit_label_aos_package_id: "AOS 包 ID",
      admin_recommend_app_edit_label_aos_play_store_url: "AOS Play 商店网址",
      admin_recommend_app_editl_label_updated_at: "更新于",
      admin_recommend_app_edit_button_cancel: "取消",
      admin_recommend_app_edit_button_delete: "删除",
      admin_recommend_app_edit_button_ok: "好的",
      admin_admin_user_list_title: "用户列表",
      admin_admin_user_list_placeholder_select_box_user: "用户",
      admin_admin_user_list_select_name: "姓名",
      admin_admin_user_list_select_email: "电子邮件",
      admin_admin_user_list_select_department: "部门",
      admin_admin_user_list_select_duty: "责任",
      admin_admin_user_list_placeholder_search: "搜索...",
      admin_admin_user_list_label_name: "姓名",
      admin_admin_user_list_label_email: "电子邮件",
      admin_admin_user_list_label_department: "部门",
      admin_admin_user_list_label_duty: "责任",
      admin_admin_user_list_label_date: "日期",
      admin_admin_user_list_guide_empty: "列表为空",
      admin_admin_user_list_button_delete: "删除",
      admin_admin_user_list_button_add: "添加",
      admin_admin_user_add_title: "新管理员",
      admin_admin_user_add_guide_click_add: "输入所需信息并单击<<“添加”>>按钮添加新用户。",
      admin_admin_user_add_validation_email: "* 请输入用户电子邮件。",
      admin_admin_user_add_validation_email_format: "* 请以someone@example.com 格式输入电子邮件",
      admin_admin_user_add_validation_password: "* 请输入用户密码。",
      admin_admin_user_add_validation_character_number_combining: "* 请输入至少 8 个字母和数字组合的字符。",
      admin_admin_user_add_validation_not_match: "* 密码不匹配。",
      admin_admin_user_add_validation_name: "* 请输入姓名。",
      admin_admin_user_add_validation_korea_english: "* 韩语和英语或“请使用._ - 输入”。",
      admin_admin_user_add_validation_department: "* 请输入部门。",
      admin_admin_user_add_validation_select_duty: "* 请选择员工职责。",
      admin_admin_user_add_label_email: "电子邮件",
      admin_admin_user_add_placeholder_email: "电子邮件",
      admin_admin_user_add_label_name: "姓名",
      admin_admin_user_add_placeholder_name: "姓名",
      admin_admin_user_add_label_passoword: "密码",
      admin_admin_user_add_placeholder_password: "密码",
      admin_admin_user_add_label_confirm_passoword: "确认密码",
      admin_admin_user_add_placeholder_confirm_password: "确认密码",
      admin_admin_user_add_label_department: "部门",
      admin_admin_user_add_placeholder_department: "部门",
      admin_admin_user_add_label_duty: "责任",
      admin_admin_user_add_select_choose: "选择",
      admin_admin_user_add_select_super_admin: "超级管理员",
      admin_admin_user_add_select_admin: "行政",
      admin_admin_user_add_select_user: "用户",
      admin_admin_user_add_button_add: "添加",
      admin_admin_user_add_button_go_to_list: "前往列表",
      admin_admin_user_detail_title: "用户信息",
      admin_admin_user_detail_select_user: "用户",
      admin_admin_user_detail_select_admin: "行政",
      admin_admin_user_detail_select_super_admin: "超级管理员",
      admin_admin_user_detail_label_admin: "行政",
      admin_admin_user_detail_label_name: "姓名",
      admin_admin_user_detail_label_department: "部门",
      admin_admin_user_detail_label_duty: "责任",
      admin_admin_user_detail_label_date: "日期",
      admin_admin_user_detail_button_delete: "删除",
      admin_admin_user_detail_button_go_to_list: "前往列表",
      admin_admin_user_detail_button_ok: "好的",
      admin_admin_user_edit_title: "编辑管理员",
      admin_admin_user_edit_guide_edit_or_leave: "信息可以修改，但电子邮件地址除外。",
      admin_admin_user_edit_validation_name: "* 请输入姓名。",
      admin_admin_user_edit_validation_department: "* 请输入部门。",
      admin_admin_user_edit_validation_korea_english: "* 韩语和英语或“请使用._ - 输入”。",
      admin_admin_user_edit_button_withdraw: "提取",
      admin_admin_user_edit_button_save: "节省",
      admin_id_recognition_information_list_title: "ID识别信息列表",
      admin_id_recognition_information_list_select_id_type_all: "ID 类型（全部）",
      admin_id_recognition_information_list_select_id_type_registration: "登记",
      admin_id_recognition_information_list_select_id_type_passport: "护照",
      admin_id_recognition_information_list_select_id_type_driver_license: "驾驶执照",
      admin_id_recognition_information_list_placeholder_search: "国家的名字",
      admin_id_recognition_information_list_label_country_name: "国家的名字",
      admin_id_recognition_information_list_label_id_type: "国家的名字",
      admin_id_recognition_information_list_label_date: "日期",
      admin_id_recognition_information_list_guide_empty: "列表为空",
      admin_id_recognition_information_list_button_delete: "删除",
      admin_id_recognition_information_list_button_add: "添加",
      admin_id_recognition_information_add_title: "添加ID识别信息",
      admin_id_recognition_information_add_guide_click_add: "输入 ID 识别信息并单击<<'添加'>>按钮。",
      admin_id_recognition_information_add_label_country_name: "国家的名字",
      admin_id_recognition_information_add_placeholder_country_name: "国家的名字",
      admin_id_recognition_information_add_label_id_type: "身份证类型",
      admin_id_recognition_information_add_placeholder_select_box_choose: "选择",
      admin_id_recognition_information_add_select_id_type_registration: "登记",
      admin_id_recognition_information_add_select_id_type_passport: "护照",
      admin_id_recognition_information_add_select_id_type_driver_license: "驾驶执照",
      admin_id_recognition_information_add_label_recognized_texts: "已识别的文本",
      admin_id_recognition_information_add_placeholder_recognized_texts: "例如）韩国、护照号码、姓氏、名字、出生日期、...",
      admin_id_recognition_information_add_validation_country_name: "* 请输入国家名称。",
      admin_id_recognition_information_add_validation_id_type: "* 请输入ID类型。",
      admin_id_recognition_information_add_validation_recognized_texts: "* 使用 , 输入识别文本。",
      admin_id_recognition_information_add_button_go_to_list: "前往列表",
      admin_id_recognition_information_add_button_add: "添加",
      admin_id_recognition_information_detail_title: "ID识别信息",
      admin_id_recognition_information_detail_label_country_name: "国家的名字",
      admin_id_recognition_information_detail_label_id_type: "身份证类型",
      admin_id_recognition_information_detail_label_recognized_texts: "已识别的文本",
      admin_id_recognition_information_detail_label_created_at: "创建于",
      admin_id_recognition_information_detail_label_updated_at: "更新于",
      admin_id_recognition_information_detail_button_go_to_list: "前往列表",
      admin_id_recognition_information_detail_button_edit: "编辑",
      admin_id_recognition_information_edit_title: "ID识别信息",
      admin_id_recognition_information_edit_label_country_name: "国家的名字",
      admin_id_recognition_information_edit_placeholder_country_name: "国家的名字",
      admin_id_recognition_information_edit_label_id_type: "身份证类型",
      admin_id_recognition_information_edit_placeholder_select_box_choose: "选择",
      admin_id_recognition_information_edit_select_id_type_registration: "登记",
      admin_id_recognition_information_edit_select_id_type_passport: "护照",
      admin_id_recognition_information_edit_select_id_type_driver_license: "驾驶执照",
      admin_id_recognition_information_edit_label_recognized_texts: "已识别的文本",
      admin_id_recognition_information_edit_placeholder_recognized_texts: "例如）韩国、护照号码、姓氏、名字、出生日期、...",
      admin_id_recognition_information_edit_validation_country_name: "* 请输入国家名称。",
      admin_id_recognition_information_edit_validation_id_type: "* 请输入ID类型。",
      admin_id_recognition_information_edit_validation_recognized_texts: "* 使用 , 输入识别文本。",
      admin_id_recognition_information_edit_label_updated_at: "更新于",
      admin_id_recognition_information_edit_button_cancel: "取消",
      admin_id_recognition_information_edit_button_ok: "好的",
      admin_statistics_list_title: "STATISTICS LIST",
      admin_statistics_list_placeholder_select_box_choose: "选择 (全部)",
      admin_statistics_list_placeholder_search: "搜索...",
      admin_statistics_list_label_date: "日期",
      admin_statistics_list_label_reservation: "预订",
      admin_statistics_list_label_walk_in: "步入",
      admin_statistics_list_label_check_in: "报到",
      admin_statistics_list_label_checked_out: "检查过了",
      admin_statistics_list_button_go_to_statistics: "Go to Statistics",
      hotel_header_link_password: "密码",
      hotel_header_link_logout: "登出",
      hotel_header_accordion_hotel: "酒店",
      hotel_header_link_hotel_information: "信息",
      hotel_header_link_hotel_beacon: "灯塔",
      hotel_header_link_hotel_currency: "货币",
      hotel_header_link_hotel_setting: "设置",
      hotel_header_link_hotel_detail: "酒店详情",
      hotel_header_link_hotel_image: "酒店形象",
      hotel_header_accordion_room: "房间",
      hotel_header_link_room: "房间",
      hotel_header_link_room_detail: "房间详情",
      hotel_header_link_room_image: "房间形象",
      hotel_header_accordion_room_management: "客房管理",
      hotel_header_link_room_list: "房间列表",
      hotel_header_link_room_management_setting: "设置",
      hotel_header_accordion_package: "包裹",
      hotel_header_link_package_package: "包裹",
      hotel_header_link_package_package_detail: "套餐详情",
      hotel_header_accordion_member: "成员",
      hotel_header_link_member_member: "成员",
      hotel_header_link_member_non_member: "Non-Member",
      hotel_header_accordion_reservation: "预订",
      hotel_header_link_reservation_reservation: "预订",
      hotel_header_link_reservation_future_booking: "未来预订",
      hotel_header_link_reservation_waiting_room: "等待房间分配",
      hotel_header_accordion_reservation_policy: "预订政策",
      hotel_header_link_reservation_rule_setting_deposit_rule: "存款规则",
      hotel_header_link_reservation_rule_setting_reservation_rule: "预订规则",
      hotel_header_link_reservation_rule_setting_cancellation_rule: "取消规则",
      hotel_header_link_reservation_rule_setting_no_show_rule: "缺席规则",
      hotel_header_link_reservation_rule_setting_rule_schedule: "规则表",
      hotel_header_accordion_payment: "支付",
      hotel_header_link_payment_history: "历史",
      hotel_header_link_payment_setting: "设置",
      hotel_header_accordion_service: "服务",
      hotel_header_link_service_hotel_info: "酒店信息",
      hotel_header_accordion_concierge: "门房",
      hotel_header_link_service_concierge_request: "请求（确认前）",
      hotel_header_link_service_concierge_process: "请求（确认后）",
      hotel_header_link_service_concierge_schedule_process: "进度安排",
      hotel_header_link_service_concierge_schedule: "时间表设定",
      hotel_header_link_service_concierge_business_rule: "业务规则设定",
      hotel_header_link_service_concierge_boundary_line: "边界线设定",
      hotel_header_accordion_housekeeping: "家政",
      hotel_header_link_service_housekeeping_request: "请求（确认前）",
      hotel_header_link_service_housekeeping_process: "请求（确认后）",
      hotel_header_link_service_housekeeping_schedule_process: "进度安排",
      hotel_header_link_service_housekeeping_schedule: "时间表设定",
      hotel_header_link_service_housekeeping_business_rule: "业务规则设定",
      hotel_header_link_service_housekeeping_boundary_line: "边界线设定",
      hotel_header_link_service_setting_amenity: "设施设置",
      hotel_header_link_service_housekeeping_setting: "设置",
      hotel_header_accordion_in_room_dining: "客房内用餐",
      hotel_header_link_service_in_room_dining_request: "请求（确认前）",
      hotel_header_link_service_in_room_dining_process: "请求（确认后）",
      hotel_header_link_service_in_room_dining_schedule_process: "进度安排",
      hotel_header_link_service_in_room_dining_schedule: "时间表设定",
      hotel_header_link_service_in_room_dining_business_rule: "业务规则设定",
      hotel_header_link_service_in_room_dining_boundary_line: "边界线设定",
      hotel_header_link_service_in_room_dining_cart: "大车",
      hotel_header_link_service_in_room_dining_category: "类别",
      hotel_header_link_service_in_room_dining_menu_setting: "菜单设置",
      hotel_header_link_service_in_room_dining_setting: "设置",
      hotel_header_accordion_valet: "代客泊车",
      hotel_header_link_service_valet_process: "要求",
      hotel_header_link_service_valet_schedule: "时间表设定",
      hotel_header_link_service_valet_business_rule: "业务规则设定",
      hotel_header_link_service_valet_parking_setting: "设置",
      hotel_header_accordion_self: "自助停车",
      hotel_header_link_service_self_parking_setting: "设置",
      hotel_header_link_service_hotel_inhouse_menu_setting: "内部菜单设置",
      hotel_header_link_service_hotel_inhouse_menu_description: "服务说明",
      hotel_header_accordion_key: "钥匙",
      hotel_header_link_key_room_to_room: "房间到房间",
      hotel_header_link_key_guest_key: "宾客钥匙",
      hotel_header_link_key_staff_key: "员工钥匙",
      hotel_header_link_key_share_key: "共享密钥",
      hotel_header_link_key_help_for_mobile_key: "手机钥匙帮助",
      hotel_header_link_key_staff_key_link: "关联",
      hotel_header_link_key_access: "使用权",
      hotel_header_link_key_access_detail: "访问详情",
      hotel_header_link_key_key_setting: "设置",
      hotel_header_link_key_reservation_access: "访问链接",
      hotel_header_accordion_pms: "经前综合症",
      hotel_header_link_pms_title: "标题",
      hotel_header_link_pms_card_type: "卡的种类",
      hotel_header_link_pms_membership_type: "会员类型",
      hotel_header_link_pms_fias_manager: "FIAS经理",
      hotel_header_link_pms_notification_callback: "通知回调",
      hotel_header_link_pms_setting: "设置",
      hotel_header_accordion_room_controller: "房间控制器",
      hotel_header_link_room_controller_info: "信息",
      hotel_header_link_room_controller_setting: "设置",
      hotel_header_accordion_document: "文具编辑",
      hotel_header_accordion_document_email: "电子邮件",
      hotel_header_accordion_event: "事件",
      hotel_header_link_event_event: "事件",
      hotel_header_link_event_event_category: "活动类别",
      hotel_header_link_email_reservation: "预订",
      hotel_header_link_email_reservation_change: "预订变更",
      hotel_header_link_email_reservation_cancel: "取消预订",
      hotel_header_link_email_no_show: "没有出现",
      hotel_header_link_email_reservation_pack_add: "预订套餐添加",
      hotel_header_link_email_reservation_pack_delete: "预订套餐删除",
      hotel_header_link_email_reservation_accom_add: "预约陪同添加",
      hotel_header_link_email_reservation_accom_delete: "预约 陪同 删除",
      hotel_header_link_email_reservation_accom_email: "陪同预订电子邮件",
      hotel_header_link_email_reservation_accom_change: "陪同预订变更",
      hotel_header_link_email_reservation_accom_cancel: "陪同预约 取消",
      hotel_header_link_email_reservation_folio_email: "对开电子邮件",
      hotel_header_link_email_reservation_enroll_profile: "注册个人资料",
      hotel_header_accordion_document_pdf: "PDF",
      hotel_header_link_pdf_reservation: "预约PDF",
      hotel_header_link_pdf_reservation_accom_pdf: "陪同预约 PDF",
      hotel_header_link_pdf_folio_pdf: "对开 PDF",
      hotel_header_accordion_terms_and_conditions: "条款和条件",
      hotel_header_link_terms_and_conditions_terms: "条款",
      hotel_header_link_terms_and_conditions_agreement: "协议",
      hotel_header_current_version: "当前版本",
      hotel_header_translation_version: "翻译版",
      hotel_header_link_staff_user: "员工用户",
      hotel_header_link_app_download: "应用程序下载",
      hotel_password_title: "更改密码",
      hotel_password_guide_password: "请输入新密码 \n至少 8 个字符。",
      hotel_password_validation_current_password: "* 请输入您当前的密码。",
      hotel_password_validation_character_number_combining: "* 请输入至少 8 个字母和数字组合的字符。",
      hotel_password_validation_new_password: "* 请输入新密码。",
      hotel_password_validation_new_password_again: "* 请再次输入您的新密码。",
      hotel_password_validation_not_match: "* 密码不匹配。",
      hotel_password_placeholder_password: "密码",
      hotel_password_placeholder_new_password: "新密码",
      hotel_password_placeholder_new_confirm_password: "确认新密码",
      hotel_password_button_save: "节省",
      hotel_hotel_info_detail_title: "酒店信息",
      hotel_hotel_info_detail_label_hotel_no: "酒店号",
      hotel_hotel_info_detail_label_name: "姓名",
      hotel_hotel_info_detail_label_address: "地址",
      hotel_hotel_info_detail_label_region: "地区",
      hotel_hotel_info_detail_label_country: "国家",
      hotel_hotel_info_detail_label_city: "城市",
      hotel_hotel_info_detail_label_logo: "标识",
      hotel_hotel_info_detail_label_hotel_photo: "酒店照片",
      hotel_hotel_info_detail_label_hotel_map: "地图",
      hotel_hotel_info_detail_button_edit: "编辑",
      hotel_hotel_info_edit_title: "酒店信息",
      hotel_hotel_info_edit_label_hotel_no: "酒店号",
      hotel_hotel_info_edit_label_name: "姓名",
      hotel_hotel_info_edit_label_address: "地址",
      hotel_hotel_info_edit_label_region: "地区",
      hotel_hotel_info_edit_label_country: "国家",
      hotel_hotel_info_edit_label_city: "城市",
      hotel_hotel_info_edit_label_logo: "标识",
      hotel_hotel_info_edit_button_upload: "上传",
      hotel_hotel_info_edit_placeholder_upload: "例）MyCONECT.png",
      hotel_hotel_info_edit_label_hotel_photo: "酒店照片",
      hotel_hotel_info_edit_label_hotel_map: "地图",
      hotel_hotel_info_edit_button_search: "搜索",
      hotel_hotel_info_edit_button_ok: "好的",
      hotel_hotel_info_edit_validation_hotel_name: "* 输入酒店名称。",
      hotel_hotel_info_edit_validation_hotel_main_tel: "* 输入酒店的主要电话号码。",
      hotel_hotel_info_edit_validation_hotel_service_tel: "* 输入酒店服务电话号码。",
      hotel_hotel_info_edit_validation_hotel_address: "* 请输入地址。",
      hotel_hotel_pms_detail_title: "设置",
      hotel_hotel_pms_detail_label_date: "更新日期",
      hotel_hotel_pms_detail_label_type: "类型",
      hotel_hotel_pms_detail_label_cloud_flag: "云旗",
      hotel_hotel_pms_detail_label_server_url: "服务器网址",
      hotel_hotel_pms_detail_label_origin_entity_id: "原始实体 ID",
      hotel_hotel_pms_detail_label_origin_system_type: "起源系统类型",
      hotel_hotel_pms_detail_label_destination_entity_id: "目的地实体ID",
      hotel_hotel_pms_detail_label_destination_system_type: "目标系统类型",
      hotel_hotel_pms_detail_label_user_name: "用户名",
      hotel_hotel_pms_detail_label_user_password: "用户密码",
      hotel_hotel_pms_detail_label_domain: "领域",
      hotel_hotel_pms_detail_label_hotel_code: "酒店代码",
      hotel_hotel_pms_detail_label_chain_code: "链码",
      hotel_hotel_pms_detail_label_reservation_transaction_code_is_credit: "预订交易代码为信用",
      hotel_hotel_pms_detail_label_cancellation_transaction_code_is_credit: "取消交易代码为信用",
      hotel_hotel_pms_detail_label_deposit_transaction_code_is_credit: "存款交易代码为信用",
      hotel_hotel_pms_detail_label_noshow_transaction_code_is_credit: "未出现交易代码即为信用",
      hotel_hotel_pms_detail_label_service_fee_transaction_code_is_credit: "服务费交易代码为信用",
      hotel_hotel_pms_detail_label_default_reservation_transaction_code: "默认预订交易代码",
      hotel_hotel_pms_detail_label_default_cancellation_transaction_code: "默认取消交易代码",
      hotel_hotel_pms_detail_label_default_deposit_transaction_code: "默认存款交易代码",
      hotel_hotel_pms_detail_label_default_noshow_transaction_code: "默认未出现交易代码",
      hotel_hotel_pms_detail_label_default_service_fee_transaction_code: "默认服务费交易代码",
      hotel_hotel_pms_detail_label_cancellation_debit_transaction_code: "取消借记交易代码",
      hotel_hotel_pms_detail_label_noshow_debit_transaction_code: "未出现借记交易代码",
      hotel_hotel_pms_detail_label_market_code: "市场代码",
      hotel_hotel_pms_detail_label_future_booking: "未来预订",
      hotel_hotel_pms_detail_label_walk_in: "步入",
      hotel_hotel_pms_detail_label_web_booking: "网上预订",
      hotel_hotel_pms_detail_label_ota_booking: "OTA Booking",
      hotel_hotel_pms_detail_label_origin_code: "原产地代码",
      hotel_hotel_pms_detail_label_rate_code: "费率代码",
      hotel_hotel_pms_detail_label_yes: "是的",
      hotel_hotel_pms_detail_label_no: "不",
      hotel_hotel_pms_detail_label_earliest_checkin_time: "最早入住时间",
      hotel_hotel_pms_detail_label_dummy_pms_profile_id: "虚拟 PMS 配置文件 ID",
      hotel_hotel_pms_detail_button_go_to_info: "前往信息",
      hotel_hotel_pms_detail_button_edit: "编辑",
      hotel_hotel_pms_edit_opera: "歌剧",
      hotel_hotel_pms_edit_planyo: "普兰尼奥",
      hotel_hotel_pms_edit_title: "设置",
      hotel_hotel_pms_edit_label_date: "更新日期",
      hotel_hotel_pms_edit_label_type: "类型",
      hotel_hotel_pms_edit_label_cloud_flag: "云旗",
      hotel_hotel_pms_edit_label_server_url: "服务器网址",
      hotel_hotel_pms_edit_label_origin_entity_id: "原始实体 ID",
      hotel_hotel_pms_edit_label_origin_system_type: "起源系统类型",
      hotel_hotel_pms_edit_label_destination_entity_id: "目的地实体ID",
      hotel_hotel_pms_edit_label_destination_system_type: "目标系统类型",
      hotel_hotel_pms_edit_label_user_name: "用户名",
      hotel_hotel_pms_edit_label_user_password: "用户密码",
      hotel_hotel_pms_edit_label_domain: "领域",
      hotel_hotel_pms_edit_label_hotel_code: "酒店代码",
      hotel_hotel_pms_edit_label_chain_code: "链码",
      hotel_hotel_pms_edit_label_reservation_transaction_code_is_credit: "预订交易代码为信用",
      hotel_hotel_pms_edit_label_cancellation_transaction_code_is_credit: "取消交易代码为信用",
      hotel_hotel_pms_edit_label_deposit_transaction_code_is_credit: "存款交易代码为信用",
      hotel_hotel_pms_edit_label_noshow_transaction_code_is_credit: "未出现交易代码即为信用",
      hotel_hotel_pms_edit_label_service_fee_transaction_code_is_credit: "服务费交易代码为信用",
      hotel_hotel_pms_edit_label_default_reservation_transaction_code: "默认预订交易代码",
      hotel_hotel_pms_edit_label_default_cancellation_transaction_code: "默认取消交易代码",
      hotel_hotel_pms_edit_label_default_deposit_transaction_code: "默认存款交易代码",
      hotel_hotel_pms_edit_label_default_noshow_transaction_code: "默认未出现交易代码",
      hotel_hotel_pms_edit_label_default_service_fee_transaction_code: "默认服务费交易代码",
      hotel_hotel_pms_edit_label_cancellation_debit_transaction_code: "取消借记交易代码",
      hotel_hotel_pms_edit_label_noshow_debit_transaction_code: "未出现借记交易代码",
      hotel_hotel_pms_edit_label_market_code: "市场代码",
      hotel_hotel_pms_edit_label_future_booking: "未来预订",
      hotel_hotel_pms_edit_label_walk_in: "步入",
      hotel_hotel_pms_edit_label_web_booking: "网上预订",
      hotel_hotel_pms_edit_label_ota_booking: "OTA Booking",
      hotel_hotel_pms_edit_label_origin_code: "原产地代码",
      hotel_hotel_pms_edit_label_rate_code: "费率代码",
      hotel_hotel_pms_edit_label_yes: "是的",
      hotel_hotel_pms_edit_label_no: "不",
      hotel_hotel_pms_edit_button_cancel: "取消",
      hotel_hotel_pms_edit_button_ok: "好的",
      hotel_hotel_pms_edit_validation_server_url: "* 请输入服务器网址。",
      hotel_hotel_pms_edit_validation_origin_entity_id: "* 请输入原始实体ID。",
      hotel_hotel_pms_edit_validation_origin_system_type: "* 请输入原始系统类型。",
      hotel_hotel_pms_edit_validation_destination_entity_id: "* 请输入目的地实体 ID。",
      hotel_hotel_pms_edit_validation_destination_system_type: "* 请输入目标系统类型。",
      hotel_hotel_pms_edit_validation_user_name: "* 请输入您的用户名。",
      hotel_hotel_pms_edit_validation_user_password: "* 请输入您的用户密码。",
      hotel_hotel_pms_edit_validation_domain: "* 请输入域名。",
      hotel_hotel_setting_detail_title: "设置",
      hotel_hotel_setting_detail_label_date: "更新日期",
      hotel_hotel_setting_detail_label_room_control: "房间控制",
      hotel_hotel_setting_detail_label_room_control_ip: "房间控制IP",
      hotel_hotel_setting_detail_label_room_control_port: "房间控制端口",
      hotel_hotel_setting_detail_label_fias: "FIAS",
      hotel_hotel_setting_detail_label_notification_callback: "通知回调",
      hotel_hotel_setting_detail_label_fias_hotel_id: "酒店ID",
      hotel_hotel_setting_detail_label_fias_auth_code: "验证码",
      hotel_hotel_setting_detail_label_payment: "支付",
      hotel_hotel_setting_detail_label_payment_auth_code: "验证码",
      hotel_hotel_setting_detail_label_store_id: "店铺编号",
      hotel_hotel_setting_detail_label_store_key: "存储密钥",
      hotel_hotel_setting_detail_label_store_api_id: "存储 API ID",
      hotel_hotel_setting_detail_label_payment_salt: "盐",
      hotel_hotel_setting_detail_label_payment_token_password: "令牌密码",
      hotel_hotel_setting_detail_label_secure_hash_secret: "Secure Hash Secret",
      hotel_hotel_setting_detail_label_payment_url: "支付网址",
      hotel_hotel_setting_detail_label_refund_url: "退款网址",
      hotel_hotel_setting_detail_label_payment_type: "付款方式",
      hotel_hotel_setting_detail_placeholder_select_box_choose: "选择",
      hotel_hotel_setting_detail_select_nicepay: "尼斯支付",
      hotel_hotel_setting_detail_select_asiapay: "联款通",
      hotel_hotel_setting_detail_label_booking_payment_using_myconect_flag: "预订时使用 MyCONECT 付款",
      hotel_hotel_setting_detail_label_checkin_payment_using_myconect_flag: "办理入住手续时使用 MyCONECT 付款",
      hotel_hotel_setting_detail_label_walkin_payment_using_myconect_flag: "上门使用 MyCONECT 付款",
      hotel_hotel_setting_detail_label_noshow_payment_using_myconect_flag: "缺席时使用 MyCONECT 付款",
      hotel_hotel_setting_detail_label_checkout_payment_using_myconect_flag: "退房时使用 MyCONECT 付款",
      hotel_hotel_setting_detail_label_cancel_booking_payment_using_myconect_flag: "取消预订时使用 MyCONECT 付款",
      hotel_hotel_setting_detail_label_need_card_information_for_booking: "预订时需要提供银行卡信息",
      hotel_hotel_setting_detail_label_need_card_information_for_checkin: "办理入住时需要提供银行卡信息",
      hotel_hotel_setting_detail_label_need_card_information_for_walkin: "入场时需要提供卡信息",
      hotel_hotel_setting_detail_label_is_direct_payable: "使用令牌信息启用直接支付",
      hotel_hotel_setting_detail_label_is_card_tokenizable: "启用卡标记化",
      hotel_hotel_setting_detail_label_min_card_expiry_days_post_checkout: "结账后距离卡到期的最短天数。",
      hotel_hotel_setting_detail_label_time: "时间",
      hotel_hotel_setting_detail_label_early_check_in: "提前入住",
      hotel_hotel_setting_detail_label_assign_room_time: "分配房间时间（小时：分钟）",
      hotel_hotel_setting_detail_label_do_not_disturb_time: "请勿打扰时间（小时：分钟）",
      hotel_hotel_setting_detail_label_time_zone: "时区",
      hotel_hotel_setting_detail_label_tell: "电话。",
      hotel_hotel_setting_detail_label_hotel_main_tel: "酒店主要电话",
      hotel_hotel_setting_detail_label_hotel_service_tel: "酒店服务电话",
      hotel_hotel_setting_detail_label_setting: "设置",
      hotel_hotel_setting_detail_label_dnd: "免打扰",
      hotel_hotel_setting_detail_label_dnd_status: "DND 状态提醒通知期限（小时：分钟）",
      hotel_hotel_setting_detail_label_dnd_relese: "在服务通知之前释放 DND（分钟）",
      hotel_hotel_setting_detail_button_yes: "是的",
      hotel_hotel_setting_detail_button_no: "不",
      hotel_hotel_setting_detail_button_refresh: "刷新",
      hotel_hotel_setting_detail_label_member_number_check: "确认入住客人人数",
      hotel_hotel_setting_detail_label_passport: "护照验证模块",
      hotel_hotel_setting_detail_select_detion: "说明",
      hotel_hotel_setting_detail_select_inzi_soft: "因兹软件",
      hotel_hotel_setting_detail_label_accompany_local_check: "国内陪同人员身份证扫描件",
      hotel_hotel_setting_detail_label_accompany_foreigner_check: "外国人陪同人员身份证件扫描件",
      hotel_hotel_setting_detail_label_room_number: "显示房间号",
      hotel_hotel_setting_detail_label_hotel_logo: "显示酒店标志",
      hotel_hotel_setting_detail_label_member_lock_count: "会员锁数量",
      hotel_hotel_setting_detail_label_payment_lock_count: "支付锁定计数",
      hotel_hotel_setting_detail_label_staff_login_lock_count: "员工登录锁定计数",
      hotel_hotel_setting_detail_label_default_currency: "默认货币",
      hotel_hotel_setting_detail_label_percent_for_hotel_charge: "酒店费用百分比 (%)",
      hotel_hotel_setting_detail_label_language_code: "语言代码",
      hotel_hotel_setting_detail_label_alternative_name_code: "备用名称代码",
      hotel_hotel_setting_detail_label_floor: "地面",
      hotel_hotel_setting_detail_label_out_of_order_check: "故障检查",
      hotel_hotel_setting_detail_label_password_change_day: "密码更改日（天）",
      hotel_hotel_setting_detail_label_password_change_notice: "密码变更通知期限（天）",
      hotel_hotel_setting_detail_label_occupy_minutes: "占用分钟数（分钟）",
      hotel_hotel_setting_detail_label_reservation_charge_waiting_minutes: "预订费等待分钟（分钟）",
      hotel_hotel_setting_detail_label_beacon_check_hours: "信标检查时间（小时）",
      hotel_hotel_setting_detail_label_compare_faces_flag: "比较面孔标志",
      hotel_hotel_setting_detail_label_compare_face_for_local_flag: "Compare Face for Local Flag",
      hotel_hotel_setting_detail_label_singapore_government_eva_flag: "新加坡政府 EVA 旗帜",
      hotel_hotel_setting_detail_label_singapore_government_eva_hotel_code: "新加坡政府 EVA 酒店代码",
      hotel_hotel_setting_detail_label_date_reservation_flag: "日期预订标志",
      hotel_hotel_setting_detail_label_time_reservation_flag: "时间保留标志",
      hotel_hotel_setting_detail_label_transit_hotel_flag: "过境酒店旗帜",
      hotel_hotel_setting_detail_label_request_make_up_room_at_checkout: "结帐时要求腾出空间",
      hotel_hotel_setting_detail_label_show_rate_for_ota_flag: "Show Rate for OTA Flag",
      hotel_hotel_setting_detail_button_edit: "编辑",
      hotel_hotel_setting_detail_button_cancel: "取消",
      hotel_hotel_setting_edit_title: "设置",
      hotel_hotel_setting_edit_label_date: "更新日期",
      hotel_hotel_setting_edit_label_room_control: "房间控制",
      hotel_hotel_setting_edit_label_room_control_ip: "房间控制IP",
      hotel_hotel_setting_edit_placeholder_room_control_ip: "例如）127.0.0.1",
      hotel_hotel_setting_edit_label_room_control_port: "房间控制端口",
      hotel_hotel_setting_edit_placeholder_room_control_port: "例如）80",
      hotel_hotel_setting_edit_label_payment: "支付",
      hotel_hotel_setting_edit_label_store_id: "店铺编号",
      hotel_hotel_setting_edit_placeholder_store_id: "店铺编号",
      hotel_hotel_setting_edit_label_payment_url: "支付网址",
      hotel_hotel_setting_edit_placeholder_payment_url: "支付网址",
      hotel_hotel_setting_edit_label_refund_url: "退款网址",
      hotel_hotel_setting_edit_placeholder_refund_url: "退款网址",
      hotel_hotel_setting_edit_label_time: "时间",
      hotel_hotel_setting_edit_label_assign_room_time: "分配房间时间",
      hotel_hotel_setting_edit_placeholder_assign_room_time: "分配房间时间",
      hotel_hotel_setting_edit_label_do_not_disturb_time: "请勿打扰时间",
      hotel_hotel_setting_edit_placeholder_do_not_disturb_time: "请勿打扰时间",
      hotel_hotel_setting_edit_label_time_zone: "时区",
      hotel_hotel_setting_edit_placeholder_time_zone: "时区",
      hotel_hotel_setting_edit_label_tell: "电话。",
      hotel_hotel_setting_edit_label_hotel_main_tel: "酒店主要电话。",
      hotel_hotel_setting_edit_placeholder_hotel_main_tel: "酒店主要电话。",
      hotel_hotel_setting_edit_label_hotel_service_tel: "酒店服务电话",
      hotel_hotel_setting_edit_placeholder_hotel_service_tel: "酒店服务电话",
      hotel_hotel_setting_edit_label_setting: "设置",
      hotel_hotel_setting_edit_button_yes: "是的",
      hotel_hotel_setting_edit_button_no: "不",
      hotel_hotel_setting_edit_label_member_number_check: "确认入住客人人数",
      hotel_hotel_setting_edit_label_accompany_local_check: "国内陪同人员身份证扫描件",
      hotel_hotel_setting_edit_label_accompany_foreigner_check: "外国人陪同人员身份证件扫描件",
      hotel_hotel_setting_edit_label_room_number: "显示房间号",
      hotel_hotel_setting_edit_label_hotel_logo: "显示酒店标志",
      hotel_hotel_setting_edit_label_member_lock_count: "会员锁数量",
      hotel_hotel_setting_edit_placeholder_member_lock_count: "会员锁数量",
      hotel_hotel_setting_edit_label_payment_lock_count: "支付锁定计数",
      hotel_hotel_setting_edit_placeholder_payment_lock_count: "支付锁定计数",
      hotel_hotel_setting_edit_label_default_currency: "默认货币",
      hotel_hotel_setting_edit_placeholder_default_currency: "默认货币",
      hotel_hotel_setting_edit_label_percent_for_hotel_charge: "酒店收费比例",
      hotel_hotel_setting_edit_placeholder_percent_for_hotel_charge: "酒店收费比例",
      hotel_hotel_setting_edit_label_language_code: "语言代码",
      hotel_hotel_setting_edit_placeholder_language_code: "语言代码",
      hotel_hotel_setting_edit_label_alternative_name_code: "备用名称代码",
      hotel_hotel_setting_edit_placeholder_alternative_name_code: "备用名称代码",
      hotel_hotel_setting_edit_label_floor: "地面",
      hotel_hotel_setting_edit_placeholder_floor: "地面",
      hotel_hotel_setting_edit_button_go_to_info: "前往信息",
      hotel_hotel_setting_edit_button_cancel: "取消",
      hotel_hotel_setting_edit_button_ok: "好的",
      hotel_hotel_setting_edit_button_refresh: "刷新",
      hotel_hotel_setting_edit_validation_assign_room_time: "* 请输入房间分配时间。",
      hotel_hotel_setting_edit_validation_time_zone: "* 请选择时区。",
      hotel_hotel_setting_edit_validation_member_lock_count: "* 请输入会员锁定次数。",
      hotel_hotel_setting_edit_validation_payment_lock_count: "* 请输入付款锁定次数。",
      hotel_hotel_setting_edit_validation_staff_lock_count: "* 请输入登录锁定的员工人数。",
      hotel_hotel_setting_edit_validation_default_currency: "* 请输入您的默认货币。",
      hotel_hotel_setting_edit_validation_language_code: "* 请输入语言代码。",
      hotel_hotel_setting_edit_validation_alternative_name_code: "* 请输入您的韩语姓名代码。",
      hotel_hotel_setting_edit_validation_floors: "* 请输入楼层。",
      hotel_hotel_setting_edit_validation_password_change_day: "* 请输入密码更改日期。",
      hotel_hotel_setting_edit_validation_password_change_notice_period: "* 请输入密码更改通知期限。",
      hotel_hotel_setting_edit_validation_occupy_minutes: "* 请输入占用时间。",
      hotel_hotel_setting_edit_validation_reservation_charge_waiting_minutes: "* 请输入预付款的等待时间。",
      hotel_hotel_setting_edit_validation_beacon_check_hours: "* 请输入信标确认时间。",
      hotel_hotel_setting_edit_validation_gov_sg_eva_hotel_code: "* 请输入新加坡政府EVA酒店代码。",
      hotel_hotel_setting_edit_validation_store_id: "* 请输入您的商店 ID。",
      hotel_hotel_setting_edit_validation_store_key: "* 请输入您的商店密钥。",
      hotel_hotel_setting_edit_validation_payment_url: "* 请输入付款网址。",
      hotel_hotel_setting_edit_validation_refund_url: "* 请输入退款网址。",
      hotel_hotel_setting_edit_validation_payment_type: "* 请选择付款方式。",
      hotel_hotel_setting_edit_validation_payment_salt: "* 请输入盐信息。",
      hotel_hotel_setting_edit_validation_payment_token_password: "* 请输入令牌密码。",
      hotel_hotel_setting_edit_validation_secure_hash_secret: "* Please enter the secure hash secret.",
      hotel_hotel_setting_edit_validation_booking_payment_using_myconect_flag: "* 预订时使用 MyCONECT 付款",
      hotel_hotel_setting_edit_validation_checkin_payment_using_myconect_flag: "* 办理入住手续时使用 MyCONECT 付款",
      hotel_hotel_setting_edit_validation_walkin_payment_using_myconect_flag: "* 上门时使用 MyCONECT 付款",
      hotel_hotel_setting_edit_validation_noshow_payment_using_myconect_flag: "* 缺席时使用 MyCONECT 付款",
      hotel_hotel_setting_edit_validation_checkout_payment_using_myconect_flag: "* 结帐时使用 MyCONECT 付款",
      hotel_hotel_setting_edit_validation_cancel_booking_payment_using_myconect_flag: "* 取消预订时使用 MyCONECT 付款",
      hotel_hotel_setting_edit_validation_need_card_information_for_booking: "* 预订时选择是否需要卡信息。",
      hotel_hotel_setting_edit_validation_need_card_information_for_checkin: "* 选择办理入住时是否需要提供银行卡信息。",
      hotel_hotel_setting_edit_validation_need_card_information_for_walkin: "* 选择步入时是否需要卡信息。",
      hotel_hotel_setting_edit_validation_is_direct_payable: "* 选择是否可以直接付款。",
      hotel_hotel_setting_edit_validation_is_card_tokenizable: "* 请选择卡是否可标记。",
      hotel_hotel_setting_edit_validation_cancel_booking_post_charge_using_flag: "* 取消预订时使用后付款。",
      hotel_hotel_setting_edit_validation_no_show_post_charge_using_flag: "* 选择缺席时是否启用后付款。",
      hotel_hotel_setting_edit_validation_min_card_expiry_days_checkout: "* 输入结帐后卡到期前的最短天数。",
      hotel_hotel_setting_detail_label_cancel_booking_post_charge_using_flag: "取消预订时启用后付款",
      hotel_hotel_setting_detail_label_noshow_post_charge_using_flag: "未出现时启用后付款计费",
      hotel_hotel_setting_edit_validation_dnd_status: "* 请输入确认客人免打扰模式的通知期限。",
      hotel_hotel_setting_edit_validation_dnd_relese: "* 请输入在服务时间之前向客人发送免打扰解除提醒通知的时间。",
      hotel_hotel_setting_edit_validation_room_control_property_id: "* 请输入您的酒店 ID。",
      hotel_hotel_setting_edit_validation_room_control_ip: "* 请输入房间控制IP。",
      hotel_hotel_setting_edit_validation_room_control_port: "* 请输入房间控制端口。",
      hotel_hotel_detail_list_title: "酒店详细清单",
      hotel_hotel_detail_list_label_name: "姓名",
      hotel_hotel_detail_list_label_language_code: "语言代码",
      hotel_hotel_detail_list_label_rating: "评分",
      hotel_hotel_detail_list_label_address: "地址",
      hotel_hotel_detail_list_label_date: "日期",
      hotel_hotel_detail_list_button_delete: "删除",
      hotel_hotel_detail_list_button_add: "添加",
      hotel_hotel_detail_add_title: "添加酒店详细信息",
      hotel_hotel_detail_add_label_name: "姓名",
      hotel_hotel_detail_add_label_language_code: "语言代码",
      hotel_hotel_detail_add_placeholder_select_box_choose: "选择",
      hotel_hotel_detail_add_select_ko: "韩国",
      hotel_hotel_detail_add_select_en: "CN",
      hotel_hotel_detail_add_select_cn: "中国",
      hotel_hotel_detail_add_select_jp: "J.P",
      hotel_hotel_detail_add_label_rating: "评分",
      hotel_hotel_detail_add_label_address: "地址",
      hotel_hotel_detail_add_label_phone: "电话",
      hotel_hotel_detail_add_label_email: "电子邮件",
      hotel_hotel_detail_add_button_go_to_list: "前往列表",
      hotel_hotel_detail_add_button_add: "添加",
      hotel_hotel_detail_add_validation_name: "* 请输入酒店名称。",
      hotel_hotel_detail_add_validation_languageCode: "* 请选择语言代码。",
      hotel_hotel_detail_add_validation_rating: "* 请输入酒店等级。",
      hotel_hotel_detail_add_validation_address: "* 请输入地址。",
      hotel_hotel_detail_add_validation_phone: "* 请输入电话号码。",
      hotel_hotel_detail_add_validation_email: "* 请输入酒店电子邮件地址。",
      hotel_hotel_detail_add_validation_about: "* 请输入房间描述。",
      hotel_hotel_detail_detail_title: "酒店详情",
      hotel_hotel_detail_detail_label_details: "细节",
      hotel_hotel_detail_detail_label_name: "姓名",
      hotel_hotel_detail_detail_label_language_code: "语言代码",
      hotel_hotel_detail_detail_placeholder_select_box_choose: "选择",
      hotel_hotel_detail_detail_select_ko: "韩国",
      hotel_hotel_detail_detail_select_en: "CN",
      hotel_hotel_detail_detail_select_cn: "中国",
      hotel_hotel_detail_detail_select_jp: "J.P",
      hotel_hotel_detail_detail_label_rating: "评分",
      hotel_hotel_detail_detail_label_address: "地址",
      hotel_hotel_detail_detail_label_phone: "电话",
      hotel_hotel_detail_detail_label_email: "电子邮件",
      hotel_hotel_detail_detail_label_about: "关于",
      hotel_hotel_detail_detail_label_date: "日期",
      hotel_hotel_detail_detail_label_facility: "设施",
      hotel_hotel_detail_detail_label_add_facility: "添加设施",
      hotel_hotel_detail_detail_label_edit_facility: "编辑设施",
      hotel_hotel_detail_detail_add_placeholder_name: "请输入姓名。",
      hotel_hotel_detail_detail_label_amenity: "便利设施",
      hotel_hotel_detail_detail_label_add_amenity: "添加便利设施",
      hotel_hotel_detail_detail_type: "类型",
      hotel_hotel_detail_detail_group: "团体",
      hotel_hotel_detail_detail_code: "代码",
      hotel_hotel_detail_detail_name: "姓名",
      hotel_hotel_detail_detail_icon: "图标",
      hotel_hotel_detail_detail_date: "日期",
      hotel_hotel_detail_detail_label_recommend_application: "推荐应用程序",
      hotel_hotel_detail_detail_application: "应用",
      hotel_hotel_detail_detail_label_add_recommend_app: "添加推荐应用程序",
      hotel_hotel_detail_detail_empty_guide: "请添加内容",
      hotel_hotel_detail_detail_button_go_to_list: "前往列表",
      hotel_hotel_detail_detail_button_delete: "删除",
      hotel_hotel_detail_detail_button_add: "添加",
      hotel_hotel_detail_detail_button_cancel: "取消",
      hotel_hotel_detail_detail_button_edit: "编辑",
      hotel_hotel_detail_detail_button_ok: "好的",
      hotel_hotel_detail_edit_validation_name: "* 请输入酒店名称。",
      hotel_hotel_detail_edit_validation_languageCode: "* 请选择语言代码。",
      hotel_hotel_detail_edit_validation_rating: "* 请输入酒店等级。",
      hotel_hotel_detail_edit_validation_address: "* 请输入地址。",
      hotel_hotel_detail_edit_validation_phone: "* 请输入电话号码。",
      hotel_hotel_detail_edit_validation_email: "* 请输入酒店电子邮件地址。",
      hotel_hotel_detail_edit_validation_about: "* 请输入房间描述。",
      hotel_hotel_img_list_title: "酒店图片列表",
      hotel_hotel_img_list_label_category: "类别",
      hotel_hotel_img_list_label_type: "类型",
      hotel_hotel_img_list_label_hotel_img: "酒店图片",
      hotel_hotel_img_list_label_date: "日期",
      hotel_hotel_img_list_button_delete: "删除",
      hotel_hotel_img_list_button_add: "添加",
      hotel_hotel_img_add_title: "添加酒店图片",
      hotel_hotel_img_add_label_category: "类别",
      hotel_hotel_img_add_label_type: "类型",
      hotel_hotel_img_add_placeholder_select_box_choose: "选择",
      hotel_hotel_img_add_select_gallery: "画廊",
      hotel_hotel_img_add_select_hotel_side: "赛德酒店",
      hotel_hotel_img_add_select_hotel_detail: "酒店详情",
      hotel_hotel_img_add_select_hotel_center: "酒店中心",
      hotel_hotel_img_add_select_hotel_front: "酒店正门",
      hotel_hotel_img_add_select_hotel_background: "酒店背景",
      hotel_hotel_img_add_select_logo_dark: "徽标 深色",
      hotel_hotel_img_add_select_logo_light: "标志灯",
      hotel_hotel_img_add_select_logo_chat: "徽标聊天",
      hotel_hotel_img_add_select_card_key: "卡钥匙",
      hotel_hotel_img_add_select_direct: "直接输入",
      hotel_hotel_img_add_label_hotel_img: "酒店形象",
      hotel_hotel_img_add_button_upload: "上传",
      hotel_hotel_img_add_button_go_to_list: "前往列表",
      hotel_hotel_img_add_button_add: "添加",
      hotel_hotel_img_add_validation_category: "* 请选择一个分类。",
      hotel_hotel_img_add_validation_hotel_img: "* 请上传酒店图片。",
      hotel_hotel_img_detail_title: "酒店图像信息",
      hotel_hotel_img_detail_label_category: "类别",
      hotel_hotel_img_detail_label_type: "类型",
      hotel_hotel_img_detail_label_type_gallery: "画廊",
      hotel_hotel_img_detail_placeholder_select_box_choose: "选择",
      hotel_hotel_img_detail_select_direct: "直接输入",
      hotel_hotel_img_detail_select_gallery: "画廊",
      hotel_hotel_img_detail_select_hotel_side: "赛德酒店",
      hotel_hotel_img_detail_select_hotel_detail: "酒店详情",
      hotel_hotel_img_detail_select_hotel_center: "酒店中心",
      hotel_hotel_img_detail_select_hotel_front: "酒店正门",
      hotel_hotel_img_detail_select_hotel_background: "酒店背景",
      hotel_hotel_img_detail_select_logo_dark: "徽标 深色",
      hotel_hotel_img_detail_select_logo_light: "标志灯",
      hotel_hotel_img_detail_select_logo_chat: "徽标聊天",
      hotel_hotel_img_detail_select_card_key: "卡钥匙",
      hotel_hotel_img_detail_label_hotel_img: "酒店形象",
      hotel_hotel_img_detail_button_upload: "上传",
      hotel_hotel_img_detail_label_updated_at: "更新于",
      hotel_hotel_img_detail_button_go_to_list: "前往列表",
      hotel_hotel_img_detail_button_delete: "删除",
      hotel_hotel_img_detail_button_edit: "编辑",
      hotel_hotel_img_detail_button_ok: "好的",
      hotel_hotel_img_detail_button_cancel: "取消",
      hotel_room_list_title: "房间列表",
      hotel_room_list_placeholder_select_box_choose: "选择",
      hotel_room_list_select_content: "内容",
      hotel_room_list_placeholder_search: "搜索...",
      hotel_room_list_label_rate_plan: "费率计划",
      hotel_room_list_label_room_type: "房型",
      hotel_room_list_label_date: "日期",
      hotel_room_list_button_delete: "删除",
      hotel_room_list_button_add: "添加",
      hotel_room_list_guide_empty: "列表为空",
      hotel_room_add_title: "添加房间",
      hotel_room_add_label_rate_plan: "费率计划",
      hotel_room_add_label_room_type: "房型",
      hotel_room_add_button_go_to_list: "前往列表",
      hotel_room_add_button_add: "添加",
      hotel_room_add_validation_rate_plan: "* 请输入您的费率计划。",
      hotel_room_add_validation_room_type: "* 请输入房型。",
      hotel_room_detail_title: "房间信息",
      hotel_room_detail_label_rate_plan: "费率计划",
      hotel_room_detail_label_room_type: "房型",
      hotel_room_detail_label_room_date: "日期",
      hotel_room_detail_label_resource_information: "资源信息",
      hotel_room_detail_label_resource_information_resource_id: "资源ID",
      hotel_room_detail_label_resource_information_name: "姓名",
      hotel_room_detail_label_resource_information_unit_price: "单价",
      hotel_room_detail_label_resource_information_currency: "货币",
      hotel_room_detail_label_resource_information_quantity: "数量",
      hotel_room_detail_label_resource_information_time_unit: "时间单位",
      hotel_room_detail_label_resource_information_min_rental_time: "最短租赁时间",
      hotel_room_detail_label_resource_information_max_rental_time: "最长租借时间",
      hotel_room_detail_label_resource_information_min_time_between_rentals: "最短租赁间隔时间",
      hotel_room_detail_label_resource_information_min_hours_to_rental: "最短租赁时间",
      hotel_room_detail_label_resource_information_start_quarters: "起始宿舍",
      hotel_room_detail_label_resource_information_start_hour: "开始时间",
      hotel_room_detail_label_resource_information_end_hour: "结束时间",
      hotel_room_detail_label_resource_information_unit_names: "单位名称",
      hotel_room_detail_button_go_to_list: "前往列表",
      hotel_room_detail_button_cancel: "取消",
      hotel_room_detail_button_delete: "删除",
      hotel_room_detail_button_edit: "编辑",
      hotel_room_detail_button_ok: "好的",
      hotel_room_edit_validation_rate_plan: "* 请输入您的费率计划。",
      hotel_room_edit_validation_room_type: "* 请输入房型。",
      hotel_room_detail_list_title: "房间详细清单",
      hotel_room_detail_list_placeholder_select_box_choose: "选择",
      hotel_room_detail_list_select_room_type: "房型",
      hotel_room_detail_list_select_rate_plan: "费率计划",
      hotel_room_detail_list_placeholder_search: "搜索...",
      hotel_room_detail_list_label_language_code: "语言代码",
      hotel_room_detail_list_label_name: "姓名",
      hotel_room_detail_list_label_view: "看法",
      hotel_room_detail_list_label_type: "类型",
      hotel_room_detail_list_label_room_type: "房型",
      hotel_room_detail_list_label_rate_plan: "费率计划",
      hotel_room_detail_list_label_date: "日期",
      hotel_room_detail_list_button_delete: "删除",
      hotel_room_detail_list_button_add: "添加",
      hotel_room_detail_list_guide_empty: "列表为空",
      hotel_room_detail_add_title: "添加房间详细信息",
      hotel_room_detail_add_label_language_code: "语言代码",
      hotel_room_detail_add_placeholder_select_box_choose: "选择",
      hotel_room_detail_add_select_ko: "韩国",
      hotel_room_detail_add_select_en: "CN",
      hotel_room_detail_add_select_cn: "中国",
      hotel_room_detail_add_select_jp: "J.P",
      hotel_room_detail_add_label_name: "姓名",
      hotel_room_detail_add_label_view: "看法",
      hotel_room_detail_add_label_type: "类型",
      hotel_room_detail_add_label_bed_type: "床型",
      hotel_room_detail_add_label_max_occupancy: "最大入住人数",
      hotel_room_detail_add_label_standard_occupancy: "标准入住",
      hotel_room_detail_add_label_size: "尺寸（平方米）",
      hotel_room_detail_add_label_checkin_time: "签到时间（小时：分钟）",
      hotel_room_detail_add_label_checkout_time: "结账时间（小时：分钟）",
      hotel_room_detail_add_label_about: "关于",
      hotel_room_detail_add_label_more: "更多的",
      hotel_room_detail_add_label_room_type: "房型",
      hotel_room_detail_add_button_go_to_list: "前往列表",
      hotel_room_detail_add_button_add: "添加",
      hotel_room_detail_add_validation_room_type: "* 请选择房型。",
      hotel_room_detail_add_validation_language_code: "* 请选择语言代码。",
      hotel_room_detail_add_validation_name: "* 请输入房间名称。",
      hotel_room_detail_add_validation_view: "* 请输入房间景观。",
      hotel_room_detail_add_validation_type: "* 请输入房型。",
      hotel_room_detail_add_validation_bed_type: "* 请输入床型。",
      hotel_room_detail_add_validation_max_occupancy: "* 请输入最多入住人数。",
      hotel_room_detail_add_validation_standard_occupancy: "* 请输入最少入住人数。",
      hotel_room_detail_add_validation_checkin_time: "* 请输入入住时间。",
      hotel_room_detail_add_validation_checkout_time: "* 请输入退房时间。",
      hotel_room_detail_add_validation_about: "* 请输入房间描述。",
      hotel_room_detail_add_validation_more: "* 请输入任何附加的房间描述。",
      hotel_room_detail_detail_title: "房间详细信息",
      hotel_room_detail_detail_label_details: "房间详情",
      hotel_room_detail_detail_label_name: "姓名",
      hotel_room_detail_detail_label_language_code: "语言代码",
      hotel_room_detail_detail_placeholder_select_box_choose: "选择",
      hotel_room_detail_detail_select_ko: "韩国",
      hotel_room_detail_detail_select_en: "CN",
      hotel_room_detail_detail_select_cn: "中国",
      hotel_room_detail_detail_select_jp: "J.P",
      hotel_room_detail_detail_label_view: "看法",
      hotel_room_detail_detail_label_type: "类型",
      hotel_room_detail_detail_label_bed_type: "床型",
      hotel_room_detail_detail_label_max_occupancy: "最大入住人数",
      hotel_room_detail_detail_label_standard_occupancy: "标准入住",
      hotel_room_detail_detail_label_size: "尺寸（平方米）",
      hotel_room_detail_detail_label_checkin_time: "签到时间（小时：分钟）",
      hotel_room_detail_detail_label_checkout_time: "结账时间（小时：分钟）",
      hotel_room_detail_detail_label_about: "关于",
      hotel_room_detail_detail_label_more: "更多的",
      hotel_room_detail_detail_label_date: "日期",
      hotel_room_detail_detail_title_amenity: "便利设施",
      hotel_room_detail_detail_type: "类型",
      hotel_room_detail_detail_group: "团体",
      hotel_room_detail_detail_code: "代码",
      hotel_room_detail_detail_name: "姓名",
      hotel_room_detail_detail_icon: "图标",
      hotel_room_detail_detail_date: "日期",
      hotel_room_detail_detail_title_add_amenity: "添加便利设施",
      hotel_room_detail_detail_button_go_to_list: "前往列表",
      hotel_room_detail_detail_button_add: "添加",
      hotel_room_detail_detail_button_delete: "删除",
      hotel_room_detail_detail_button_cancel: "取消",
      hotel_room_detail_detail_button_edit: "编辑",
      hotel_room_detail_detail_button_ok: "好的",
      hotel_room_detail_edit_validation_room_type: "* 请选择房型。",
      hotel_room_detail_edit_validation_language_code: "* 请选择语言代码。",
      hotel_room_detail_edit_validation_name: "* 请输入房间名称。",
      hotel_room_detail_edit_validation_view: "* 请输入房间景观。",
      hotel_room_detail_edit_validation_type: "* 请输入房型。",
      hotel_room_detail_edit_validation_bed_type: "* 请输入床型。",
      hotel_room_detail_edit_validation_max_occupancy: "* 请输入最多入住人数。",
      hotel_room_detail_edit_validation_standard_occupancy: "* 请输入最少入住人数。",
      hotel_room_detail_edit_validation_size: "* 请输入房间的大小。",
      hotel_room_detail_edit_validation_checkin_time: "* 请输入入住时间。",
      hotel_room_detail_edit_validation_checkout_time: "* 请输入退房时间。",
      hotel_room_detail_edit_validation_about: "* 请输入房间描述。",
      hotel_room_detail_edit_validation_more: "* 请输入任何附加的房间描述。",
      hotel_room_img_list_title: "房间图片列表",
      hotel_room_img_list_label_rate_plan: "费率计划",
      hotel_room_img_list_label_room_type: "房型",
      hotel_room_img_list_label_type: "类型",
      hotel_room_img_list_label_room_img: "房间形象",
      hotel_room_img_list_label_date: "日期",
      hotel_room_img_list_button_delete: "删除",
      hotel_room_img_list_button_add: "添加",
      hotel_room_img_add_title: "添加房间图片",
      hotel_room_img_add_label_room_type: "房型",
      hotel_room_img_add_label_type: "类型",
      hotel_room_img_add_placeholder_select_box_choose: "选择",
      hotel_room_img_add_select_gallery: "画廊",
      hotel_room_img_add_select_room_detail: "房间详情",
      hotel_room_img_add_select_room_center: "客房中心",
      hotel_room_img_add_label_hotel_img: "房间形象",
      hotel_room_img_add_button_upload: "上传",
      hotel_room_img_add_button_go_to_list: "前往列表",
      hotel_room_img_add_button_add: "添加",
      hotel_room_img_add_validation_room_type: "* 请选择房型。",
      hotel_room_img_add_validation_image_file: "* 请上传酒店图片。",
      hotel_room_img_detail_title: "房间图片信息",
      hotel_room_img_detail_label_room_type: "房型",
      hotel_room_img_detail_placeholder_select_box_choose: "选择",
      hotel_room_img_detail_label_type: "类型",
      hotel_room_img_detail_select_gallery: "画廊",
      hotel_room_img_detail_select_room_detail: "房间详情",
      hotel_room_img_detail_select_room_center: "客房中心",
      hotel_room_img_detail_label_room_img: "房间形象",
      hotel_room_img_detail_button_upload: "上传",
      hotel_room_img_detail_label_updated_at: "更新于",
      hotel_room_img_detail_button_go_to_list: "前往列表",
      hotel_room_img_detail_button_delete: "删除",
      hotel_room_img_detail_button_edit: "编辑",
      hotel_room_img_detail_button_ok: "好的",
      hotel_room_img_detail_button_cancel: "取消",
      hotel_room_management_room_list_list_title: "房间列表",
      hotel_room_management_room_list_list_select_floor: "地面",
      hotel_room_management_room_list_list_select_cleaning_status: "Cleaning Status",
      hotel_room_management_room_list_list_select_room_type: "房型",
      hotel_room_management_room_list_list_select_rooms_status: "房间状态",
      hotel_room_management_room_list_list_select_fo_status: "FO 状态",
      hotel_room_management_room_list_list_select_reservation_status: "预订状态",
      hotel_room_management_room_list_list_placeholder_room_no: "房间号。",
      hotel_room_management_room_list_list_button_all: "全部",
      hotel_room_management_room_list_list_button_change: "改变",
      hotel_room_management_room_list_list_guide_empty: "列表为空",
      hotel_room_management_room_list_detail_title: "房间详情",
      hotel_room_management_room_list_detail_label_floor: "地面",
      hotel_room_management_room_list_detail_label_room_no: "房间号。",
      hotel_room_management_room_list_detail_label_room_type: "房型",
      hotel_room_management_room_list_detail_label_room_status: "房间状态",
      hotel_room_management_room_list_detail_label_fo_status: "FO 状态",
      hotel_room_management_room_list_detail_label_reservation_status: "预订状态",
      hotel_room_management_room_list_detail_label_created_at: "创建于",
      hotel_room_management_room_list_detail_button_status: "地位",
      hotel_room_management_room_list_detail_button_cleaning: "打扫",
      hotel_room_management_room_list_detail_label_history: "历史",
      hotel_room_management_room_list_detail_placeholder_select_box_choose: "选择",
      hotel_room_management_room_list_detail_select_housekeeping: "家政",
      hotel_room_management_room_list_detail_placeholder_search: "搜索...",
      hotel_room_management_room_list_detail_label_number: "不。",
      hotel_room_management_room_list_detail_label_housekeeping: "家政",
      hotel_room_management_room_list_detail_label_staff: "职员",
      hotel_room_management_room_list_detail_label_date: "日期",
      hotel_room_management_room_list_detail_guide_empty: "列表为空",
      hotel_room_management_room_list_modal_title_status_message: "房间状态",
      hotel_room_management_room_list_modal_status_clean: "干净的",
      hotel_room_management_room_list_modal_status_inspected: "已检查",
      hotel_room_management_room_list_modal_status_dirty: "肮脏的",
      hotel_room_management_room_list_modal_status_pick_up: "捡起",
      hotel_room_management_setting_detail_title: "设置",
      hotel_room_management_setting_detail_label_total_floors: "总楼层数",
      hotel_room_management_setting_detail_label_total_number_of_rooms: "房间总数",
      hotel_room_management_setting_detail_label_floor_detail: "地板细节",
      hotel_room_management_setting_detail_label_number: "不。",
      hotel_room_management_setting_detail_label_number_of_rooms_on_the_floor: "楼层房间数",
      hotel_room_management_setting_detail_label_number_of_rooms: "房间的数量",
      hotel_room_management_setting_detail_guide_empty: "列表为空",
      hotel_room_management_setting_detail_button_edit: "编辑",
      hotel_room_management_setting_edit_validation_total_floors: "* 请输入总楼层数。",
      hotel_room_management_setting_edit_validation_number_of_rooms: "* Please enter the number of rooms.",
      hotel_room_management_setting_edit_label_total_floors: "总楼层数",
      hotel_room_management_setting_edit_label_total_number_of_rooms: "房间总数",
      hotel_room_management_setting_edit_label_floor_detail: "地板细节",
      hotel_room_management_setting_edit_label_number: "不。",
      hotel_room_management_setting_edit_label_number_of_rooms_on_the_floor: "楼层房间数",
      hotel_room_management_setting_edit_label_number_of_rooms: "房间的数量",
      hotel_room_management_setting_edit_guide_empty: "列表为空",
      hotel_room_management_setting_edit_button_cancel: "取消",
      hotel_room_management_setting_edit_button_ok: "好的",
      hotel_package_list_title: "包装清单",
      hotel_package_list_label_type: "类型",
      hotel_package_list_label_code: "代码",
      hotel_package_list_label_name: "姓名",
      hotel_package_list_label_description: "描述",
      hotel_package_list_label_date: "日期",
      hotel_package_list_button_delete: "删除",
      hotel_package_list_button_add: "添加",
      hotel_package_list_guide_empty: "列表为空",
      hotel_package_add_title: "添加套餐",
      hotel_package_add_label_type: "类型",
      hotel_package_add_placeholder_select_box_choose: "选择",
      hotel_package_add_placeholder_select_box_package: "包裹",
      hotel_package_add_placeholder_select_box_transaction: "交易",
      hotel_package_add_label_code: "代码",
      hotel_package_add_label_name: "姓名",
      hotel_package_add_label_description: "描述",
      hotel_package_add_button_go_to_list: "前往列表",
      hotel_package_add_button_add: "添加",
      hotel_package_add_validation_type: "* 请选择套餐类型。",
      hotel_package_add_validation_code: "* 请输入包裹代码。",
      hotel_package_add_validation_name: "* 请输入包名。",
      hotel_package_add_validation_description: "* 请输入包装说明。",
      hotel_package_detail_title: "包裹信息",
      hotel_package_detail_label_type: "类型",
      hotel_package_detail_label_code: "代码",
      hotel_package_detail_label_name: "姓名",
      hotel_package_detail_label_description: "描述",
      hotel_package_detail_label_updated_at: "更新于",
      hotel_package_detail_button_go_to_list: "前往列表",
      hotel_package_detail_button_cancel: "取消",
      hotel_package_detail_button_delete: "删除",
      hotel_package_detail_button_edit: "编辑",
      hotel_package_detail_button_ok: "好的",
      hotel_package_edit_validation_type: "* 请选择套餐类型。",
      hotel_package_edit_validation_code: "* 请输入包裹代码。",
      hotel_package_edit_validation_name: "* 请输入包名。",
      hotel_package_edit_validation_description: "* 请输入包装说明。",
      hotel_package_detail_list_title: "包裹详情清单",
      hotel_package_detail_list_label_code: "代码",
      hotel_package_detail_list_label_name: "姓名",
      hotel_package_detail_list_label_language_code: "语言代码",
      hotel_package_detail_list_label_date: "日期",
      hotel_package_detail_list_button_delete: "删除",
      hotel_package_detail_list_button_add: "添加",
      hotel_package_detail_list_guide_empty: "列表为空",
      hotel_package_detail_add_title: "添加包裹详细信息",
      hotel_package_detail_add_label_name: "姓名",
      hotel_package_detail_add_label_package_code: "封装代码",
      hotel_package_detail_add_placeholder_select_box_choose: "选择",
      hotel_package_detail_add_label_language_code: "语言代码",
      hotel_package_detail_add_select_ko: "韩国",
      hotel_package_detail_add_select_en: "CN",
      hotel_package_detail_add_select_cn: "中国",
      hotel_package_detail_add_select_jp: "J.P",
      hotel_package_detail_add_label_description: "描述",
      hotel_package_detail_add_label_content: "内容",
      hotel_package_detail_add_label_package_img: "包装图片",
      hotel_package_detail_add_button_upload: "上传",
      hotel_package_detail_add_button_go_to_list: "前往列表",
      hotel_package_detail_add_button_add: "添加",
      hotel_package_detail_add_validation_package_code: "* 请选择套餐代码。",
      hotel_package_detail_add_validation_language_code: "* 请选择语言代码。",
      hotel_package_detail_add_validation_description: "* 请输入包装说明。",
      hotel_package_detail_add_validation_image_file: "* 请上传包装图片。",
      hotel_package_detail_add_validation_content: "* 请输入包裹详细信息。",
      hotel_package_detail_detail_title: "套餐详情",
      hotel_package_detail_detail_label_date: "更新日期",
      hotel_package_detail_detail_label_name: "姓名",
      hotel_package_detail_detail_label_package_code: "代码",
      hotel_package_detail_detail_placeholder_select_box_choose: "选择",
      hotel_package_detail_detail_label_language_code: "语言代码",
      hotel_package_detail_detail_select_ko: "韩国",
      hotel_package_detail_detail_select_en: "CN",
      hotel_package_detail_detail_select_cn: "中国",
      hotel_package_detail_detail_select_jp: "J.P",
      hotel_package_detail_detail_label_description: "描述",
      hotel_package_detail_detail_label_content: "内容",
      hotel_package_detail_detail_label_package_img: "包装图片",
      hotel_package_detail_detail_button_upload: "上传",
      hotel_package_detail_detail_button_go_to_list: "前往列表",
      hotel_package_detail_detail_button_cancel: "取消",
      hotel_package_detail_detail_button_delete: "删除",
      hotel_package_detail_detail_button_edit: "编辑",
      hotel_package_detail_detail_button_ok: "好的",
      hotel_package_detail_edit_validation_package_code: "* 请选择套餐代码。",
      hotel_package_detail_edit_validation_language_code: "* 请选择语言代码。",
      hotel_package_detail_edit_validation_description: "* 请输入包装说明。",
      hotel_package_detail_edit_validation_image_file: "* 请上传包装图片。",
      hotel_package_detail_edit_validation_content: "* 请输入包裹详细信息。",
      hotel_hotel_valet_setting_detail_title: "代客泊车设置",
      hotel_hotel_valet_setting_detail_label_date: "更新日期",
      hotel_hotel_valet_setting_detail_label_parking: "停車處",
      hotel_hotel_valet_setting_detail_label_parking_availability: "代客泊车服务",
      hotel_hotel_valet_setting_detail_button_yes: "是的",
      hotel_hotel_valet_setting_detail_button_no: "不",
      hotel_hotel_valet_setting_detail_label_time: "时间",
      hotel_hotel_valet_setting_detail_label_delay_time: "延迟时间",
      hotel_hotel_valet_setting_detail_button_15: "15分钟",
      hotel_hotel_valet_setting_detail_button_30: "30分钟",
      hotel_hotel_valet_setting_detail_button_45: "45分钟",
      hotel_hotel_valet_setting_detail_button_60: "60 分钟",
      hotel_hotel_valet_setting_detail_button_as_soon_as: "尽快可用",
      hotel_hotel_valet_setting_detail_label_calculation_time: "等待请求计算时间（小时：分钟）",
      hotel_hotel_valet_setting_detail_label_vehicle_time: "车辆返回时间（小时：分钟）",
      hotel_hotel_valet_setting_detail_label_request_caution_time: "请求注意分钟（分钟）",
      hotel_hotel_valet_setting_detail_label_delay_available_time: "延迟可用时间（分钟）",
      hotel_hotel_valet_setting_detail_label_requirement_time: "可编辑的最短要求时间（分钟）",
      hotel_hotel_valet_setting_detail_label_log_active_hours: "记录活跃时间（小时）",
      hotel_hotel_valet_setting_detail_label_return_additional_charge: "退货附加费",
      hotel_hotel_valet_setting_detail_label_return_push_message: "返回推送消息标志",
      hotel_hotel_valet_setting_detail_label_return_charge_count: "退货费用计数",
      hotel_hotel_valet_setting_detail_label_return_repeat_message: "返回重复标志",
      hotel_hotel_valet_setting_detail_button_edit: "编辑",
      hotel_hotel_valet_setting_edit_title: "代客泊车设置",
      hotel_hotel_valet_setting_edit_label_date: "更新日期",
      hotel_hotel_valet_setting_edit_label_parking: "停車處",
      hotel_hotel_valet_setting_edit_label_parking_availability: "代客泊车服务",
      hotel_hotel_valet_setting_edit_button_yes: "是的",
      hotel_hotel_valet_setting_edit_button_no: "不",
      hotel_hotel_valet_setting_edit_label_time: "时间",
      hotel_hotel_valet_setting_edit_label_delay_time: "延迟时间",
      hotel_hotel_valet_setting_edit_placeholder_delay_time: "例如）80",
      hotel_hotel_valet_setting_edit_label_calculation_time: "等待请求计算时间",
      hotel_hotel_valet_setting_edit_placeholder_calculation_time: "例如) 24:00",
      hotel_hotel_valet_setting_edit_label_vehicle_time: "车辆还车时间",
      hotel_hotel_valet_setting_edit_placeholder_vehicle_time: "例如）80",
      hotel_hotel_valet_setting_edit_button_ok: "好的",
      hotel_hotel_valet_setting_edit_validation_parking_availability: "* 请选择停车位可用状态。",
      hotel_hotel_valet_setting_edit_validation_waiting_calculation_time: "* 请输入等待请求时间。",
      hotel_hotel_valet_setting_edit_validation_vehicle_time: "* 请输入“还车至停车场”时间。",
      hotel_hotel_valet_setting_edit_validation_request_caution_time: "* 请输入要求的关注时间。",
      hotel_hotel_valet_setting_edit_validation_requirement_time: "* 请输入所需的最短编辑时间。",
      hotel_hotel_valet_setting_edit_validation_charge_log_active_hours: "* 请输入您的活动时间记录。",
      hotel_hotel_valet_setting_edit_validation_return_push_message: "* 请选择回信通知状态。",
      hotel_hotel_self_setting_detail_title: "自助停车设置",
      hotel_hotel_self_setting_detail_label_date: "更新日期",
      hotel_hotel_self_setting_detail_label_parking: "停車處",
      hotel_hotel_self_setting_detail_label_parking_availability: "自助停车服务",
      hotel_hotel_self_setting_detail_button_yes: "是的",
      hotel_hotel_self_setting_detail_button_no: "不",
      hotel_hotel_self_setting_detail_button_edit: "编辑",
      hotel_hotel_self_setting_edit_title: "自助停车设置",
      hotel_hotel_self_setting_edit_label_date: "更新日期",
      hotel_hotel_self_setting_edit_label_parking: "停車處",
      hotel_hotel_self_setting_edit_label_parking_availability: "自助停车服务",
      hotel_hotel_self_setting_edit_button_yes: "是的",
      hotel_hotel_self_setting_edit_button_no: "不",
      hotel_hotel_self_setting_edit_placeholder_vehicle_time: "例如）80",
      hotel_hotel_self_setting_edit_button_ok: "好的",
      hotel_hotel_service_setting_detail_title: "内部菜单设置",
      hotel_hotel_service_setting_detail_label_date: "更新日期",
      hotel_hotel_service_setting_detail_button_yes: "是的",
      hotel_hotel_service_setting_detail_button_no: "不",
      hotel_hotel_service_setting_detail_label_housekeeping: "家政",
      hotel_hotel_service_setting_detail_label_housekeeping_dnd: "免打扰",
      hotel_hotel_service_setting_detail_label_housekeeping_green_campaign: "绿色运动",
      hotel_hotel_service_setting_detail_label_housekeeping_emergency_service: "紧急服务",
      hotel_hotel_service_setting_detail_label_housekeeping_make_up_room: "让出一点空间",
      hotel_hotel_service_setting_detail_label_housekeeping_turndown_service: "夜床服务",
      hotel_hotel_service_setting_detail_label_concierge: "门房",
      hotel_hotel_service_setting_detail_label_concierge_luggage_service: "行李服务",
      hotel_hotel_service_setting_detail_label_concierge_parcel_service: "包裹服务",
      hotel_hotel_service_setting_detail_label_concierge_taxi_call_service: "的士服务",
      hotel_hotel_service_setting_detail_label_guest_service: "客户服务",
      hotel_hotel_service_setting_detail_label_guest_service_amenity_service: "便利设施服务",
      hotel_hotel_service_setting_detail_label_guest_service_laundry_service: "洗衣服务",
      hotel_hotel_service_setting_detail_label_guest_service_in_room_dining: "客房内用餐",
      hotel_hotel_service_setting_detail_label_guest_service_ironing_service: "熨烫服务",
      hotel_hotel_service_setting_detail_label_etc: "ETC。",
      hotel_hotel_service_setting_detail_label_room_to_room: "房间到房间",
      hotel_hotel_service_setting_detail_label_share_key: "共享密钥",
      hotel_hotel_service_setting_detail_label_in_room_control: "室内控制",
      hotel_hotel_service_setting_detail_button_edit: "编辑",
      hotel_hotel_service_setting_detail_button_cancel: "取消",
      hotel_hotel_service_setting_detail_button_ok: "好的",
      hotel_service_description_list_title: "服务说明",
      hotel_service_description_list_button_add: "添加",
      hotel_service_description_list_button_delete: "删除",
      hotel_service_description_list_button_edit: "编辑",
      hotel_service_description_list_guide_add: "请添加服务描述。",
      hotel_service_description_add_title: "添加服务描述",
      hotel_service_description_add_select_choose: "请选择类型",
      hotel_service_description_add_select_in_room_dining: "客房内用餐",
      hotel_service_description_add_select_luggage_service: "行李服务",
      hotel_service_description_add_select_parcel_service: "包裹服务",
      hotel_service_description_add_select_taxi_call: "出租车",
      hotel_service_description_add_select_amenity_service: "便利设施服务",
      hotel_service_description_add_select_ironing_service: "熨烫服务",
      hotel_service_description_add_select_laundry_service: "洗衣服务",
      hotel_service_description_add_select_dnd: "免打扰",
      hotel_service_description_add_select_green_campaign: "绿色运动",
      hotel_service_description_add_select_emergency_service: "紧急服务",
      hotel_service_description_add_toolbar_bold: "大胆的",
      hotel_service_description_add_toolbar_italic: "斜体",
      hotel_service_description_add_toolbar_underline: "强调",
      hotel_service_description_add_toolbar_remove_font_style: "删除字体样式",
      hotel_service_description_add_toolbar_font_family: "字体系列",
      hotel_service_description_add_toolbar_font_size: "字体大小",
      hotel_service_description_add_toolbar_recent_color: "最近的颜色",
      hotel_service_description_add_toolbar_recent_more_color: "更多颜色",
      hotel_service_description_add_toolbar_unordered_list: "无序列表",
      hotel_service_description_add_toolbar_ordered_list: "有序列表",
      hotel_service_description_add_toolbar_paragraph: "段落",
      hotel_service_description_add_toolbar_line_height: "行高",
      hotel_service_description_add_placeholder_content: "请输入内容。",
      hotel_service_description_add_button_cancel: "取消",
      hotel_service_description_add_button_ok: "好的",
      hotel_service_description_add_validation_type: "* 请选择类型。",
      hotel_service_description_add_validation_content: "* 请输入详细信息。",
      hotel_service_description_edit_title: "编辑服务描述",
      hotel_service_description_edit_select_kr: "韩国",
      hotel_service_description_edit_select_en: "CN",
      hotel_service_description_edit_select_cn: "中国",
      hotel_service_description_edit_select_jp: "J.P",
      hotel_service_description_edit_toolbar_bold: "大胆的",
      hotel_service_description_edit_toolbar_italic: "斜体",
      hotel_service_description_edit_toolbar_underline: "强调",
      hotel_service_description_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_service_description_edit_toolbar_font_family: "字体系列",
      hotel_service_description_edit_toolbar_font_size: "字体大小",
      hotel_service_description_edit_toolbar_recent_color: "最近的颜色",
      hotel_service_description_edit_toolbar_recent_more_color: "更多颜色",
      hotel_service_description_edit_toolbar_unordered_list: "无序列表",
      hotel_service_description_edit_toolbar_ordered_list: "有序列表",
      hotel_service_description_edit_toolbar_paragraph: "段落",
      hotel_service_description_edit_toolbar_code_view: "代码视图",
      hotel_service_description_edit_toolbar_line_height: "线高",
      hotel_service_description_edit_button_append: "附加",
      hotel_service_description_edit_button_remove: "消除",
      hotel_service_description_edit_button_cancel: "取消",
      hotel_service_description_edit_button_ok: "好的",
      hotel_service_description_edit_validation_language_code: "* 请选择不重叠的语言代码。",
      hotel_service_description_edit_validation_content: "* 请输入详细信息。",
      hotel_hotel_beacon_list_title: "信标列表",
      hotel_hotel_beacon_list_placeholder_search: "搜索...",
      hotel_hotel_beacon_list_label_uuid: "通用唯一识别码",
      hotel_hotel_beacon_list_label_code: "代码",
      hotel_hotel_beacon_list_label_type: "类型",
      hotel_hotel_beacon_list_label_content: "内容",
      hotel_hotel_beacon_list_label_date: "日期",
      hotel_hotel_beacon_list_guide_empty: "列表为空",
      hotel_hotel_beacon_list_button_delete: "删除",
      hotel_hotel_beacon_list_button_add: "添加",
      hotel_hotel_beacon_add_title: "添加信标",
      hotel_hotel_beacon_add_label_uuid: "通用唯一识别码",
      hotel_hotel_beacon_add_label_code: "代码",
      hotel_hotel_beacon_add_label_type: "类型",
      hotel_hotel_beacon_add_label_content: "内容",
      hotel_hotel_beacon_add_button_go_to_list: "前往列表",
      hotel_hotel_beacon_add_button_add: "添加",
      hotel_hotel_beacon_add_validation_uuid: "* 请输入UUID。",
      hotel_hotel_beacon_add_validation_code: "* 请输入代码。",
      hotel_hotel_beacon_add_validation_type: "* 请输入类型。",
      hotel_hotel_beacon_add_validation_content: "* 请输入内容。",
      hotel_hotel_beacon_detail_title: "信标信息",
      hotel_hotel_beacon_detail_label_uuid: "通用唯一识别码",
      hotel_hotel_beacon_detail_label_code: "代码",
      hotel_hotel_beacon_detail_label_type: "类型",
      hotel_hotel_beacon_detail_label_content: "内容",
      hotel_hotel_beacon_detail_label_date: "日期",
      hotel_hotel_beacon_detail_button_cancel: "取消",
      hotel_hotel_beacon_detail_button_go_to_list: "前往列表",
      hotel_hotel_beacon_detail_button_delete: "删除",
      hotel_hotel_beacon_detail_button_edit: "编辑",
      hotel_hotel_beacon_edit_title: "信标信息",
      hotel_hotel_beacon_edit_label_uuid: "通用唯一识别码",
      hotel_hotel_beacon_edit_label_code: "代码",
      hotel_hotel_beacon_edit_label_type: "类型",
      hotel_hotel_beacon_edit_label_content: "内容",
      hotel_hotel_beacon_edit_label_date: "日期",
      hotel_hotel_beacon_edit_button_cancel: "取消",
      hotel_hotel_beacon_edit_button_delete: "删除",
      hotel_hotel_beacon_edit_button_ok: "好的",
      hotel_hotel_beacon_edit_validation_uuid: "* 请输入UUID。",
      hotel_hotel_beacon_edit_validation_code: "* 请输入代码。",
      hotel_hotel_beacon_edit_validation_type: "* 请输入类型。",
      hotel_hotel_beacon_edit_validation_content: "* 请输入内容。",
      hotel_hotel_currency_list_title: "货币列表",
      hotel_hotel_currency_list_guide_exchange_rate: "汇率(韩元) :",
      hotel_hotel_currency_list_guide_won: "{{data}} 获胜 / {{data}}",
      hotel_hotel_currency_list_placeholder_currency: "货币（全部）",
      hotel_hotel_currency_list_select_display: "货币显示",
      hotel_hotel_currency_list_select_name: "货币名称",
      hotel_hotel_currency_list_placeholder_search: "搜索...",
      hotel_hotel_currency_list_label_display: "货币显示",
      hotel_hotel_currency_list_label_name: "货币名称",
      hotel_hotel_currency_list_label_exchange_rate: "汇率",
      hotel_hotel_currency_list_label_date: "日期",
      hotel_hotel_currency_list_label_syne: "同步",
      hotel_hotel_currency_list_guide_empty: "列表为空",
      hotel_hotel_currency_list_button_syne: "同步",
      hotel_hotel_currency_list_button_delete: "删除",
      hotel_hotel_currency_list_button_add: "添加",
      hotel_hotel_currency_add_title: "添加货币",
      hotel_hotel_currency_add_guide_currency: "汇率(韩元)",
      hotel_hotel_currency_add_guide_won: "{{data}} 获胜 / {{data}}",
      hotel_hotel_currency_add_select_display: "货币显示",
      hotel_hotel_currency_add_select_name: "货币名称",
      hotel_hotel_currency_add_label_exchange_rate: "汇率",
      hotel_hotel_currency_add_button_go_to_list: "前往列表",
      hotel_hotel_currency_add_button_add: "添加",
      hotel_hotel_currency_add_validation_currency_display: "* 请输入货币符号。",
      hotel_hotel_currency_add_validation_currency_name: "* 请输入货币名称。",
      hotel_hotel_currency_add_validation_exchange_rate: "* 请输入汇率。",
      hotel_hotel_membership_list_title: "会员类型列表",
      hotel_hotel_membership_list_placeholder_search: "搜索...",
      hotel_hotel_membership_list_label_membership_type: "会员类型",
      hotel_hotel_membership_list_label_description: "描述",
      hotel_hotel_membership_list_label_date: "日期",
      hotel_hotel_membership_list_guide_empty: "列表为空",
      hotel_hotel_membership_list_button_delete: "删除",
      hotel_hotel_membership_list_button_sync: "同步",
      hotel_hotel_membership_list_button_add: "添加",
      hotel_hotel_membership_add_title: "添加会员类型",
      hotel_hotel_membership_add_label_membership_type: "会员类型",
      hotel_hotel_membership_add_label_description: "描述",
      hotel_hotel_membership_add_button_go_to_list: "前往列表",
      hotel_hotel_membership_add_button_add: "添加",
      hotel_hotel_membership_add_validation_membership_type: "* 请输入您的会员类型。",
      hotel_hotel_membership_add_validation_description: "* 请输入您的详细信息。",
      hotel_hotel_membership_detail_title: "会员类型信息",
      hotel_hotel_membership_detail_label_membership_type: "会员类型",
      hotel_hotel_membership_detail_label_description: "描述",
      hotel_hotel_membership_detail_label_date: "日期",
      hotel_hotel_membership_detail_button_go_to_list: "前往列表",
      hotel_hotel_membership_detail_button_delete: "删除",
      hotel_hotel_membership_detail_button_edit: "编辑",
      hotel_hotel_membership_edit_title: "会员类型信息",
      hotel_hotel_membership_edit_label_membership_type: "会员类型",
      hotel_hotel_membership_edit_label_description: "描述",
      hotel_hotel_membership_edit_label_date: "日期",
      hotel_hotel_membership_edit_button_cancel: "取消",
      hotel_hotel_membership_edit_button_delete: "删除",
      hotel_hotel_membership_edit_button_ok: "好的",
      hotel_hotel_membership_edit_validation_membership_type: "* 请输入您的会员类型。",
      hotel_hotel_membership_edit_validation_description: "* 请输入您的详细信息。",
      hotel_Title_list_title: "标题列表",
      hotel_Title_list_placeholder_search: "搜索...",
      hotel_Title_list_label_title: "标题",
      hotel_Title_list_label_description: "描述",
      hotel_Title_list_label_date: "日期",
      hotel_Title_list_guide_empty: "列表为空",
      hotel_Title_list_button_delete: "删除",
      hotel_Title_list_button_sync: "同步",
      hotel_Title_list_button_add: "添加",
      hotel_Title_add_title: "添加",
      hotel_Title_add_label_title: "标题",
      hotel_Title_add_label_description: "描述",
      hotel_Title_add_button_go_to_list: "前往列表",
      hotel_Title_add_button_add: "添加",
      hotel_Title_detail_title: "标题信息",
      hotel_Title_detail_label_title: "标题",
      hotel_Title_detail_label_description: "描述",
      hotel_Title_detail_label_date: "日期",
      hotel_Title_detail_button_cancel: "取消",
      hotel_Title_detail_button_go_to_list: "前往列表",
      hotel_Title_detail_button_delete: "删除",
      hotel_hotel_card_type_list_title: "卡的种类",
      hotel_hotel_card_type_list_label_ok: "不。",
      hotel_hotel_card_type_list_label_value: "价值",
      hotel_hotel_card_type_list_label_description: "描述",
      hotel_hotel_payment_list_title: "付款清单",
      hotel_hotel_payment_list_placeholder_user_name: "用户名",
      hotel_hotel_payment_list_placeholder_search: "搜索...",
      hotel_hotel_payment_list_label_no: "不。",
      hotel_hotel_payment_list_label_reservation_id: "预订ID",
      hotel_hotel_payment_list_label_user_name: "用户名",
      hotel_hotel_payment_list_label_long_info: "长信息",
      hotel_hotel_payment_list_label_amount: "数量",
      hotel_hotel_payment_list_label_unit: "单元",
      hotel_hotel_payment_list_label_pg_status_code: "PG状态码",
      hotel_hotel_payment_list_label_pg_status_message: "PG状态信息",
      hotel_hotel_payment_list_label_date: "日期",
      hotel_hotel_payment_list_guide_empty: "列表为空",
      hotel_hotel_payment_detail_label_order_no: "订单号。",
      hotel_hotel_payment_detail_label_user: "用户",
      hotel_hotel_payment_detail_label_user_profile_id: "个人资料 ID",
      hotel_hotel_payment_detail_label_user_title: "标题",
      hotel_hotel_payment_detail_label_user_first_name: "名",
      hotel_hotel_payment_detail_label_user_last_name: "姓",
      hotel_hotel_payment_detail_label_user_email: "电子邮件",
      hotel_hotel_payment_detail_label_user_date: "日期",
      hotel_hotel_payment_detail_label_reservation: "预订",
      hotel_hotel_payment_detail_label_reservation_confirmation_no: "确认号",
      hotel_hotel_payment_detail_label_reservation_reservation_id: "预订ID",
      hotel_hotel_payment_detail_label_reservation_reservation_status: "预订状态",
      hotel_hotel_payment_detail_label_reservation_check_in_date: "入住日期",
      hotel_hotel_payment_detail_label_reservation_check_out_date: "离开日期",
      hotel_hotel_payment_detail_label_payment: "支付",
      hotel_hotel_payment_detail_button_go_to_list: "前往列表",
      hotel_hotel_payment_detail_button_go_to_log: "转到日志列表",
      hotel_hotel_payment_detail_label_payment_order_no: "订单号。",
      hotel_hotel_payment_detail_label_payment_reservation_id: "预订ID",
      hotel_hotel_payment_detail_label_payment_long_info: "长信息",
      hotel_hotel_payment_detail_label_payment_folio_view_no: "对开视图编号",
      hotel_hotel_payment_detail_label_payment_amount: "数量",
      hotel_hotel_payment_detail_label_payment_unit: "单元",
      hotel_hotel_payment_detail_label_payment_pg_order_no: "PG 订单号",
      hotel_hotel_payment_detail_label_payment_pg_status: "控球后卫状态",
      hotel_hotel_payment_detail_label_payment_pg_status_code: "PG状态代码",
      hotel_hotel_payment_detail_label_payment_pg_status_message: "PG状态信息",
      hotel_hotel_payment_detail_label_payment_pg_amount: "PG金额",
      hotel_hotel_payment_detail_label_payment_pg_unit: "PG单位",
      hotel_hotel_payment_detail_label_payment_pg_billing_no: "PG 帐单号码",
      hotel_hotel_payment_detail_label_payment_confirmed_at: "确认于",
      hotel_hotel_payment_detail_label_payment_created_at: "创建于",
      hotel_hotel_payment_detail_label_payment_updated_at: "更新于",
      hotel_hotel_payment_detail_label_schedule: "日程",
      hotel_hotel_payment_detail_label_schedule_rule_type: "规则类型",
      hotel_hotel_payment_detail_label_schedule_name: "姓名",
      hotel_hotel_payment_detail_label_schedule_description: "描述",
      hotel_hotel_payment_detail_label_schedule_rate_code: "费率代码",
      hotel_hotel_payment_detail_label_schedule_begin_date: "开始日期",
      hotel_hotel_payment_detail_label_schedule_end_date: "结束日期",
      hotel_hotel_payment_detail_label_schedule_day_of_the_week: "一周中的天",
      hotel_hotel_payment_detail_label_schedule_created_at: "创建时间",
      hotel_hotel_payment_detail_label_schedule_updated_at: "更新时间",
      hotel_hotel_payment_detail_label_reservation_rule: "预订规则",
      hotel_hotel_payment_detail_label_rule_name: "规则名称",
      hotel_hotel_payment_detail_label_description: "描述",
      hotel_hotel_payment_detail_label_reservation_amount: "预约金额",
      hotel_hotel_payment_detail_label_day_before_arrival: "抵达前一天",
      hotel_hotel_payment_detail_label_before_time: "时间之前",
      hotel_hotel_payment_detail_label_deposit_rule: "存款规则",
      hotel_hotel_payment_detail_label_type: "类型",
      hotel_hotel_payment_detail_label_deposit_amount: "存款金额",
      hotel_hotel_payment_detail_label_cancellation_rule: "取消规则",
      hotel_hotel_payment_detail_label_no_show_rule: "缺席规则",
      hotel_hotel_payment_detail_label_source_code: "源代码",
      hotel_hotel_payment_detail_label_penalty_amount: "罚款金额",
      hotel_hotel_payment_detail_label_guarantee_type: "担保类型",
      hotel_hotel_payment_detail_label_posting_rule: "发帖规则",
      hotel_payment_history_log_list_title: "付款日志列表",
      hotel_payment_history_log_list_label_no: "不。",
      hotel_payment_history_log_list_label_type: "类型",
      hotel_payment_history_log_list_label_method: "方法",
      hotel_payment_history_log_list_label_path: "帕蒂",
      hotel_payment_history_log_list_label_status: "雕像",
      hotel_payment_history_log_list_label_success: "成功",
      hotel_payment_history_log_list_label_created_at: "创建于",
      hotel_payment_history_log_list_guide_empty: "列表为空",
      hotel_payment_history_log_list_button_go_to_detail: "转到详细信息",
      hotel_payment_history_log_detail_title: "付款日志详细信息",
      hotel_payment_history_log_detail_label_id: "ID",
      hotel_payment_history_log_detail_label_hotel_id: "酒店ID",
      hotel_payment_history_log_detail_label_payment_id: "付款ID",
      hotel_payment_history_log_detail_label_transfer_code: "转移代码",
      hotel_payment_history_log_detail_label_type: "类型",
      hotel_payment_history_log_detail_label_method: "方法",
      hotel_payment_history_log_detail_label_path: "小路",
      hotel_payment_history_log_detail_label_header: "标头",
      hotel_payment_history_log_detail_label_param: "参数",
      hotel_payment_history_log_detail_label_body: "身体",
      hotel_payment_history_log_detail_label_status: "地位",
      hotel_payment_history_log_detail_label_session: "会议",
      hotel_payment_history_log_detail_label_created_at: "创建于",
      hotel_payment_history_log_detail_button_go_to_list: "前往列表",
      hotel_hotel_reservation_email_edit_title: "预订电子邮件格式",
      hotel_hotel_reservation_email_edit_select_kr: "韩国",
      hotel_hotel_reservation_email_edit_select_en: "CN",
      hotel_hotel_reservation_email_edit_select_cn: "中国",
      hotel_hotel_reservation_email_edit_select_jp: "J.P",
      hotel_hotel_reservation_email_edit_select_ms: "MS",
      hotel_hotel_reservation_email_edit_button_remove: "消除",
      hotel_hotel_reservation_email_edit_button_append: "附加",
      hotel_hotel_reservation_email_edit_button_save: "节省",
      hotel_hotel_reservation_email_edit_toolbar_bold: "大胆的",
      hotel_hotel_reservation_email_edit_toolbar_italic: "斜体",
      hotel_hotel_reservation_email_edit_toolbar_underline: "强调",
      hotel_hotel_reservation_email_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_reservation_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_reservation_email_edit_toolbar_font_family: "字体系列",
      hotel_hotel_reservation_email_edit_toolbar_font_size: "字体大小",
      hotel_hotel_reservation_email_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_reservation_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_reservation_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_reservation_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_reservation_email_edit_toolbar_table: "桌子",
      hotel_hotel_reservation_email_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_reservation_email_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_reservation_email_edit_toolbar_paragraph: "段落",
      hotel_hotel_reservation_email_edit_toolbar_line_height: "线高",
      hotel_hotel_reservation_email_edit_toolbar_picture: "图片",
      hotel_hotel_reservation_email_edit_toolbar_code_view: "代码视图",
      hotel_hotel_reservation_email_edit_toolbar_full_screen: "全屏",
      hotel_hotel_reservation_email_edit_toolbar_help: "帮助",
      hotel_hotel_reservation_email_edit_toolbar_tip: "提示",
      hotel_hotel_reservation_email_edit_placeholder_content: "请输入内容。",
      hotel_hotel_reservation_email_edit_validation_content: "* 请输入您的详细信息。",
      hotel_hotel_reservation_email_edit_validation_language_code: "* 请选择不重叠的语言代码。",
      hotel_hotel_reservation_email_detail_title: "预订邮件内容",
      hotel_hotel_reservation_email_detail_label_confurmation_number: "确认号码",
      hotel_hotel_reservation_email_detail_example_confurmation_number: "{{确认号码}}",
      hotel_hotel_reservation_email_detail_label_reservation_id: "预订ID",
      hotel_hotel_reservation_email_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_reservation_email_detail_label_arrival_date: "到达日期",
      hotel_hotel_reservation_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_reservation_email_detail_label_departure_date: "出发日期",
      hotel_hotel_reservation_email_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_reservation_email_detail_label_room_type: "房型",
      hotel_hotel_reservation_email_detail_example_room_type: "{{房型}}",
      hotel_hotel_reservation_email_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_reservation_email_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_reservation_email_detail_label_room_rate: "房价",
      hotel_hotel_reservation_email_detail_example_room_rate: "{{房价}}",
      hotel_hotel_reservation_email_detail_label_total_rate: "总费率",
      hotel_hotel_reservation_email_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_reservation_email_detail_label_current_balance: "当前余额",
      hotel_hotel_reservation_email_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_reservation_email_detail_label_room_number: "房间号",
      hotel_hotel_reservation_email_detail_example_room_number: "{{房间号}}",
      hotel_hotel_reservation_email_detail_label_adults: "成年人",
      hotel_hotel_reservation_email_detail_example_adults: "{{成年人}}",
      hotel_hotel_reservation_email_detail_label_children: "孩子们",
      hotel_hotel_reservation_email_detail_example_children: "{{孩子们}}",
      hotel_hotel_reservation_email_detail_label_check_out_time: "退房时间",
      hotel_hotel_reservation_email_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_reservation_email_detail_label_name_title: "姓名 职务",
      hotel_hotel_reservation_email_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_reservation_email_detail_label_first_name: "名",
      hotel_hotel_reservation_email_detail_example_first_name: "{{名}}",
      hotel_hotel_reservation_email_detail_label_last_name: "姓",
      hotel_hotel_reservation_email_detail_example_last_name: "{{姓}}",
      hotel_hotel_reservation_email_detail_label_country_code: "国家代码",
      hotel_hotel_reservation_email_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_reservation_email_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_reservation_email_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_reservation_email_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_reservation_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_email_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_reservation_email_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_reservation_email_detail_label_phone: "手机号码",
      hotel_hotel_reservation_email_detail_example_phone: "{{电话}}",
      hotel_hotel_reservation_email_detail_label_email: "电子邮件",
      hotel_hotel_reservation_email_detail_example_email: "{{电子邮件}}",
      hotel_hotel_reservation_email_detail_label_accompany: "陪伴",
      hotel_hotel_reservation_email_detail_example_accompany: "{{陪伴}}",
      hotel_hotel_reservation_pdf_edit_title: "预订 PDF 格式",
      hotel_hotel_reservation_pdf_edit_select_kr: "韩国",
      hotel_hotel_reservation_pdf_edit_select_en: "CN",
      hotel_hotel_reservation_pdf_edit_select_cn: "中国",
      hotel_hotel_reservation_pdf_edit_select_jp: "J.P",
      hotel_hotel_reservation_pdf_edit_select_ms: "MS",
      hotel_hotel_reservation_pdf_edit_button_remove: "消除",
      hotel_hotel_reservation_pdf_edit_button_append: "附加",
      hotel_hotel_reservation_pdf_edit_button_save: "节省",
      hotel_hotel_reservation_pdf_edit_toolbar_bold: "大胆的",
      hotel_hotel_reservation_pdf_edit_toolbar_italic: "斜体",
      hotel_hotel_reservation_pdf_edit_toolbar_underline: "强调",
      hotel_hotel_reservation_pdf_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_reservation_pdf_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_reservation_pdf_edit_toolbar_font_family: "字体系列",
      hotel_hotel_reservation_pdf_edit_toolbar_font_size: "字体大小",
      hotel_hotel_reservation_pdf_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_reservation_pdf_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_reservation_pdf_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_reservation_pdf_edit_toolbar_table: "桌子",
      hotel_hotel_reservation_pdf_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_reservation_pdf_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_reservation_pdf_edit_toolbar_paragraph: "段落",
      hotel_hotel_reservation_pdf_edit_toolbar_line_height: "线高",
      hotel_hotel_reservation_pdf_edit_toolbar_picture: "图片",
      hotel_hotel_reservation_pdf_edit_toolbar_code_view: "代码视图",
      hotel_hotel_reservation_pdf_edit_toolbar_full_screen: "全屏",
      hotel_hotel_reservation_pdf_edit_toolbar_help: "帮助",
      hotel_hotel_reservation_pdf_edit_toolbar_tip: "提示",
      hotel_hotel_reservation_pdf_edit_toolbar_download_pdf: "下载PDF",
      hotel_hotel_reservation_pdf_edit_placeholder_content: "请输入内容。",
      hotel_hotel_reservation_pdf_edit_validation_content: "* 请输入您的详细信息。",
      hotel_hotel_reservation_pdf_edit_validation_language_code: "* 请选择不重叠的语言代码。",
      hotel_hotel_reservation_pdf_detail_title: "预订 PDF 内容",
      hotel_hotel_reservation_pdf_detail_label_confurmation_number: "确认号码",
      hotel_hotel_reservation_pdf_detail_example_confurmation_number: "{{确认号码}}",
      hotel_hotel_reservation_pdf_detail_label_reservation_id: "预订ID",
      hotel_hotel_reservation_pdf_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_reservation_pdf_detail_label_arrival_date: "到达日期",
      hotel_hotel_reservation_pdf_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_reservation_pdf_detail_label_departure_date: "出发日期",
      hotel_hotel_reservation_pdf_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_reservation_pdf_detail_label_room_type: "房型",
      hotel_hotel_reservation_pdf_detail_example_room_type: "{{房型}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_reservation_pdf_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_reservation_pdf_detail_label_room_rate: "房价",
      hotel_hotel_reservation_pdf_detail_example_room_rate: "{{房价}}",
      hotel_hotel_reservation_pdf_detail_label_total_rate: "总费率",
      hotel_hotel_reservation_pdf_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_reservation_pdf_detail_label_current_balance: "当前余额",
      hotel_hotel_reservation_pdf_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_reservation_pdf_detail_label_room_number: "房间号",
      hotel_hotel_reservation_pdf_detail_example_room_number: "{{房间号}}",
      hotel_hotel_reservation_pdf_detail_label_adults: "成年人",
      hotel_hotel_reservation_pdf_detail_example_adults: "{{成年人}}",
      hotel_hotel_reservation_pdf_detail_label_children: "孩子们",
      hotel_hotel_reservation_pdf_detail_example_children: "{{孩子们}}",
      hotel_hotel_reservation_pdf_detail_label_check_out_time: "退房时间",
      hotel_hotel_reservation_pdf_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_reservation_pdf_detail_label_name_title: "姓名 职务",
      hotel_hotel_reservation_pdf_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_reservation_pdf_detail_label_first_name: "名",
      hotel_hotel_reservation_pdf_detail_example_first_name: "{{名}}",
      hotel_hotel_reservation_pdf_detail_label_last_name: "姓",
      hotel_hotel_reservation_pdf_detail_example_last_name: "{{姓}}",
      hotel_hotel_reservation_pdf_detail_label_country_code: "国家代码",
      hotel_hotel_reservation_pdf_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_reservation_pdf_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_reservation_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_pdf_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_reservation_pdf_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_reservation_pdf_detail_label_phone: "手机号码",
      hotel_hotel_reservation_pdf_detail_example_phone: "{{电话}}",
      hotel_hotel_reservation_pdf_detail_label_email: "电子邮件",
      hotel_hotel_reservation_pdf_detail_example_email: "{{电子邮件}}",
      hotel_hotel_reservation_accompany_email_edit_title: "陪同预订电子邮件",
      hotel_hotel_reservation_accompany_email_edit_select_kr: "韩国",
      hotel_hotel_reservation_accompany_email_edit_select_en: "CN",
      hotel_hotel_reservation_accompany_email_edit_select_cn: "中国",
      hotel_hotel_reservation_accompany_email_edit_select_jp: "J.P",
      hotel_hotel_reservation_accompany_email_edit_select_ms: "MS",
      hotel_hotel_reservation_accompany_email_edit_button_remove: "消除",
      hotel_hotel_reservation_accompany_email_edit_button_append: "附加",
      hotel_hotel_reservation_accompany_email_edit_button_save: "节省",
      hotel_hotel_reservation_accompany_email_edit_toolbar_bold: "大胆的",
      hotel_hotel_reservation_accompany_email_edit_toolbar_italic: "斜体",
      hotel_hotel_reservation_accompany_email_edit_toolbar_underline: "强调",
      hotel_hotel_reservation_accompany_email_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_reservation_accompany_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_family: "字体系列",
      hotel_hotel_reservation_accompany_email_edit_toolbar_font_size: "字体大小",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_reservation_accompany_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_reservation_accompany_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_reservation_accompany_email_edit_toolbar_table: "桌子",
      hotel_hotel_reservation_accompany_email_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_reservation_accompany_email_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_reservation_accompany_email_edit_toolbar_paragraph: "段落",
      hotel_hotel_reservation_accompany_email_edit_toolbar_line_height: "行高",
      hotel_hotel_reservation_accompany_email_edit_toolbar_picture: "图片",
      hotel_hotel_reservation_accompany_email_edit_toolbar_code_view: "代码视图",
      hotel_hotel_reservation_accompany_email_edit_toolbar_full_screen: "全屏",
      hotel_hotel_reservation_accompany_email_edit_toolbar_help: "帮助",
      hotel_hotel_reservation_accompany_email_edit_toolbar_tip: "提示",
      hotel_hotel_reservation_accompany_email_edit_placeholder_content: "请输入内容。",
      hotel_hotel_reservation_accompany_email_detail_title: "陪同预订电子邮件内容",
      hotel_hotel_reservation_accompany_email_detail_label_confurmation_number: "确认号码",
      hotel_hotel_reservation_accompany_email_detail_example_confurmation_number: "{{确认号码}}",
      hotel_hotel_reservation_accompany_email_detail_label_reservation_id: "预订ID",
      hotel_hotel_reservation_accompany_email_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_reservation_accompany_email_detail_label_arrival_date: "到达日期",
      hotel_hotel_reservation_accompany_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_reservation_accompany_email_detail_label_departure_date: "出发日期",
      hotel_hotel_reservation_accompany_email_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_type: "房型",
      hotel_hotel_reservation_accompany_email_detail_example_room_type: "{{房型}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_rate: "房价",
      hotel_hotel_reservation_accompany_email_detail_example_room_rate: "{{房价}}",
      hotel_hotel_reservation_accompany_email_detail_label_total_rate: "总费率",
      hotel_hotel_reservation_accompany_email_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_reservation_accompany_email_detail_label_current_balance: "当前余额",
      hotel_hotel_reservation_accompany_email_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_reservation_accompany_email_detail_label_room_number: "房间号",
      hotel_hotel_reservation_accompany_email_detail_example_room_number: "{{房间号}}",
      hotel_hotel_reservation_accompany_email_detail_label_adults: "成年人",
      hotel_hotel_reservation_accompany_email_detail_example_adults: "{{成年人}}",
      hotel_hotel_reservation_accompany_email_detail_label_children: "孩子们",
      hotel_hotel_reservation_accompany_email_detail_example_children: "{{孩子们}}",
      hotel_hotel_reservation_accompany_email_detail_label_check_out_time: "退房时间",
      hotel_hotel_reservation_accompany_email_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_reservation_accompany_email_detail_label_name_title: "姓名 职务",
      hotel_hotel_reservation_accompany_email_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_reservation_accompany_email_detail_label_first_name: "名",
      hotel_hotel_reservation_accompany_email_detail_example_first_name: "{{名}}",
      hotel_hotel_reservation_accompany_email_detail_label_last_name: "姓",
      hotel_hotel_reservation_accompany_email_detail_example_last_name: "{{姓}}",
      hotel_hotel_reservation_accompany_email_detail_label_country_code: "国家代码",
      hotel_hotel_reservation_accompany_email_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_reservation_accompany_email_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_reservation_accompany_email_detail_label_phone: "手机号码",
      hotel_hotel_reservation_accompany_email_detail_example_phone: "{{电话}}",
      hotel_hotel_reservation_accompany_email_detail_label_email: "电子邮件",
      hotel_hotel_reservation_accompany_email_detail_example_email: "{{电子邮件}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_title: "伴随标题",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_first_name: "伴随名字",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_email_detail_label_accompany_last_name: "陪同姓氏",
      hotel_hotel_reservation_accompany_email_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_edit_title: "陪同 修改预订电子邮件",
      hotel_document_accom_reservation_change_edit_select_kr: "韩国",
      hotel_document_accom_reservation_change_edit_select_en: "CN",
      hotel_document_accom_reservation_change_edit_select_cn: "中国",
      hotel_document_accom_reservation_change_edit_select_jp: "J.P",
      hotel_document_accom_reservation_change_edit_select_ms: "MS",
      hotel_document_accom_reservation_change_edit_button_remove: "消除",
      hotel_document_accom_reservation_change_edit_button_append: "附加",
      hotel_document_accom_reservation_change_edit_button_save: "节省",
      hotel_document_accom_reservation_change_edit_toolbar_bold: "大胆的",
      hotel_document_accom_reservation_change_edit_toolbar_italic: "斜体",
      hotel_document_accom_reservation_change_edit_toolbar_underline: "强调",
      hotel_document_accom_reservation_change_edit_toolbar_strikethrough: "删除线",
      hotel_document_accom_reservation_change_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_document_accom_reservation_change_edit_toolbar_font_family: "字体系列",
      hotel_document_accom_reservation_change_edit_toolbar_font_size: "字体大小",
      hotel_document_accom_reservation_change_edit_toolbar_text_color: "文字颜色",
      hotel_document_accom_reservation_change_edit_toolbar_text_more_color: "更多颜色",
      hotel_document_accom_reservation_change_edit_toolbar_recent_color: "最近的颜色",
      hotel_document_accom_reservation_change_edit_toolbar_recent_more_color: "更多颜色",
      hotel_document_accom_reservation_change_edit_toolbar_table: "桌子",
      hotel_document_accom_reservation_change_edit_toolbar_unordered_list: "无序列表",
      hotel_document_accom_reservation_change_edit_toolbar_ordered_list: "有序列表",
      hotel_document_accom_reservation_change_edit_toolbar_paragraph: "段落",
      hotel_document_accom_reservation_change_edit_toolbar_line_height: "行高",
      hotel_document_accom_reservation_change_edit_toolbar_picture: "图片",
      hotel_document_accom_reservation_change_edit_toolbar_code_view: "代码视图",
      hotel_document_accom_reservation_change_edit_toolbar_full_screen: "全屏",
      hotel_document_accom_reservation_change_edit_toolbar_help: "帮助",
      hotel_document_accom_reservation_change_edit_toolbar_tip: "提示",
      hotel_document_accom_reservation_change_edit_toolbar_download_pdf: "下载PDF",
      hotel_document_accom_reservation_change_edit_placeholder_content: "请输入内容。",
      hotel_document_accom_reservation_change_detail_title: "陪同预订变更内容",
      hotel_document_accom_reservation_change_detail_label_confirmation_number: "确认号码",
      hotel_document_accom_reservation_change_detail_example_confirmation_number: "{{确认号码}}",
      hotel_document_accom_reservation_change_detail_label_accom_first_name: "伴随名字",
      hotel_document_accom_reservation_change_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_change_detail_label_accom_last_name: "陪同姓氏",
      hotel_document_accom_reservation_change_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_change_detail_label_first_name: "名",
      hotel_document_accom_reservation_change_detail_example_first_name: "{{名}}",
      hotel_document_accom_reservation_change_detail_label_last_name: "姓",
      hotel_document_accom_reservation_change_detail_example_last_name: "{{姓}}",
      hotel_document_accom_reservation_change_detail_label_room_type: "房型",
      hotel_document_accom_reservation_change_detail_example_room_type: "{{房型}}",
      hotel_document_accom_reservation_change_detail_label_arrival_date: "到达日期",
      hotel_document_accom_reservation_change_detail_example_arrival_date: "{{到达日期}}",
      hotel_document_accom_reservation_change_detail_label_departure_date: "出发日期",
      hotel_document_accom_reservation_change_detail_example_departure_date: "{{出发日期}}",
      hotel_document_accom_reservation_change_detail_label_accom_title: "伴随标题",
      hotel_document_accom_reservation_change_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_change_detail_label_adults: "成年人",
      hotel_document_accom_reservation_change_detail_example_adults: "{{成年人}}",
      hotel_document_accom_reservation_change_detail_label_night: "夜晚",
      hotel_document_accom_reservation_change_detail_example_night: "{{夜晚}}",
      hotel_document_accom_reservation_change_detail_label_room_rate: "房价",
      hotel_document_accom_reservation_change_detail_example_room_rate: "{{房价}}",
      hotel_document_accom_reservation_change_detail_label_room_rate_currency: "房价货币",
      hotel_document_accom_reservation_change_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_document_accom_reservation_change_detail_label_total_rate: "总费率",
      hotel_document_accom_reservation_change_detail_example_total_rate: "{{总费率}}",
      hotel_document_accom_reservation_change_detail_label_cancellation: "取消政策",
      hotel_document_accom_reservation_change_detail_example_cancellationPolicy: "{{取消政策}}",
      hotel_document_accom_reservation_cancel_edit_title: "陪同预订 取消电子邮件",
      hotel_document_accom_reservation_cancel_edit_select_kr: "韩国",
      hotel_document_accom_reservation_cancel_edit_select_en: "CN",
      hotel_document_accom_reservation_cancel_edit_select_cn: "中国",
      hotel_document_accom_reservation_cancel_edit_select_jp: "J.P",
      hotel_document_accom_reservation_cancel_edit_select_ms: "MS",
      hotel_document_accom_reservation_cancel_edit_button_remove: "消除",
      hotel_document_accom_reservation_cancel_edit_button_append: "附加",
      hotel_document_accom_reservation_cancel_edit_button_save: "节省",
      hotel_document_accom_reservation_cancel_edit_toolbar_bold: "大胆的",
      hotel_document_accom_reservation_cancel_edit_toolbar_italic: "斜体",
      hotel_document_accom_reservation_cancel_edit_toolbar_underline: "强调",
      hotel_document_accom_reservation_cancel_edit_toolbar_strikethrough: "删除线",
      hotel_document_accom_reservation_cancel_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_family: "字体系列",
      hotel_document_accom_reservation_cancel_edit_toolbar_font_size: "字体大小",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_color: "文字颜色",
      hotel_document_accom_reservation_cancel_edit_toolbar_text_more_color: "更多颜色",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_color: "最近的颜色",
      hotel_document_accom_reservation_cancel_edit_toolbar_recent_more_color: "更多颜色",
      hotel_document_accom_reservation_cancel_edit_toolbar_table: "桌子",
      hotel_document_accom_reservation_cancel_edit_toolbar_unordered_list: "无序列表",
      hotel_document_accom_reservation_cancel_edit_toolbar_ordered_list: "有序列表",
      hotel_document_accom_reservation_cancel_edit_toolbar_paragraph: "段落",
      hotel_document_accom_reservation_cancel_edit_toolbar_line_height: "行高",
      hotel_document_accom_reservation_cancel_edit_toolbar_picture: "图片",
      hotel_document_accom_reservation_cancel_edit_toolbar_code_view: "代码视图",
      hotel_document_accom_reservation_cancel_edit_toolbar_full_screen: "全屏",
      hotel_document_accom_reservation_cancel_edit_toolbar_help: "帮助",
      hotel_document_accom_reservation_cancel_edit_toolbar_tip: "提示",
      hotel_document_accom_reservation_cancel_edit_toolbar_download_pdf: "下载PDF",
      hotel_document_accom_reservation_cancel_edit_placeholder_content: "请输入内容。",
      hotel_document_accom_reservation_cancel_detail_title: "陪同预订 取消内容",
      hotel_document_accom_reservation_cancel_detail_label_confirmation_number: "确认号码",
      hotel_document_accom_reservation_cancel_detail_example_confirmation_number: "{{确认号码}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_first_name: "伴随名字",
      hotel_document_accom_reservation_cancel_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_last_name: "陪同姓氏",
      hotel_document_accom_reservation_cancel_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_document_accom_reservation_cancel_detail_label_first_name: "名",
      hotel_document_accom_reservation_cancel_detail_example_first_name: "{{名}}",
      hotel_document_accom_reservation_cancel_detail_label_last_name: "姓",
      hotel_document_accom_reservation_cancel_detail_example_last_name: "{{姓}}",
      hotel_document_accom_reservation_cancel_detail_label_room_type: "房型",
      hotel_document_accom_reservation_cancel_detail_example_room_type: "{{房型}}",
      hotel_document_accom_reservation_cancel_detail_label_arrival_date: "到达日期",
      hotel_document_accom_reservation_cancel_detail_example_arrival_date: "{{到达日期}}",
      hotel_document_accom_reservation_cancel_detail_label_departure_date: "出发日期",
      hotel_document_accom_reservation_cancel_detail_example_departure_date: "{{出发日期}}",
      hotel_document_accom_reservation_cancel_detail_label_accom_title: "伴随标题",
      hotel_document_accom_reservation_cancel_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_document_accom_reservation_cancel_detail_label_adults: "成年人",
      hotel_document_accom_reservation_cancel_detail_example_adults: "{{成年人}}",
      hotel_document_accom_reservation_cancel_detail_label_night: "夜晚",
      hotel_document_accom_reservation_cancel_detail_example_night: "{{夜晚}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate: "房价",
      hotel_document_accom_reservation_cancel_detail_example_room_rate: "{{房价}}",
      hotel_document_accom_reservation_cancel_detail_label_room_rate_currency: "房价货币",
      hotel_document_accom_reservation_cancel_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_document_accom_reservation_cancel_detail_label_total_rate: "总费率",
      hotel_document_accom_reservation_cancel_detail_example_total_rate: "{{总费率}}",
      hotel_document_accom_reservation_cancel_detail_label_cancellation: "取消政策",
      hotel_document_accom_reservation_cancel_detail_example_cancellationPolicy: "{{取消政策}}",
      hotel_hotel_reservation_accompany_pdf_edit_title: "陪同预订 PDF 格式",
      hotel_hotel_reservation_accompany_pdf_edit_select_kr: "韩国",
      hotel_hotel_reservation_accompany_pdf_edit_select_en: "CN",
      hotel_hotel_reservation_accompany_pdf_edit_select_cn: "中国",
      hotel_hotel_reservation_accompany_pdf_edit_select_jp: "J.P",
      hotel_hotel_reservation_accompany_pdf_edit_select_ms: "MS",
      hotel_hotel_reservation_accompany_pdf_edit_button_remove: "消除",
      hotel_hotel_reservation_accompany_pdf_edit_button_append: "附加",
      hotel_hotel_reservation_accompany_pdf_edit_button_save: "节省",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_bold: "大胆的",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_italic: "斜体",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_underline: "强调",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_family: "字体系列",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_font_size: "字体大小",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_table: "桌子",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_paragraph: "段落",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_line_height: "行高",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_picture: "图片",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_code_view: "代码视图",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_full_screen: "全屏",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_help: "帮助",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_tip: "提示",
      hotel_hotel_reservation_accompany_pdf_edit_toolbar_download_pdf: "下载PDF",
      hotel_hotel_reservation_accompany_pdf_edit_placeholder_content: "请输入内容。",
      hotel_hotel_reservation_accompany_pdf_detail_title: "陪同预订 PDF 内容",
      hotel_hotel_reservation_accompany_pdf_detail_label_confurmation_number: "确认号码",
      hotel_hotel_reservation_accompany_pdf_detail_example_confurmation_number: "{{确认号码}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_reservation_id: "预订ID",
      hotel_hotel_reservation_accompany_pdf_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_arrival_date: "到达日期",
      hotel_hotel_reservation_accompany_pdf_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_departure_date: "出发日期",
      hotel_hotel_reservation_accompany_pdf_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_type: "房型",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_type: "{{房型}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_rate: "房价",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_rate: "{{房价}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_total_rate: "总费率",
      hotel_hotel_reservation_accompany_pdf_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_current_balance: "当前余额",
      hotel_hotel_reservation_accompany_pdf_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_room_number: "房间号",
      hotel_hotel_reservation_accompany_pdf_detail_example_room_number: "{{房间号}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_adults: "成年人",
      hotel_hotel_reservation_accompany_pdf_detail_example_adults: "{{成年人}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_children: "孩子们",
      hotel_hotel_reservation_accompany_pdf_detail_example_children: "{{孩子们}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_check_out_time: "退房时间",
      hotel_hotel_reservation_accompany_pdf_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_name_title: "姓名 职务",
      hotel_hotel_reservation_accompany_pdf_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_first_name: "名",
      hotel_hotel_reservation_accompany_pdf_detail_example_first_name: "{{名}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_last_name: "姓",
      hotel_hotel_reservation_accompany_pdf_detail_example_last_name: "{{姓}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_country_code: "国家代码",
      hotel_hotel_reservation_accompany_pdf_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_reservation_accompany_pdf_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_phone: "手机号码",
      hotel_hotel_reservation_accompany_pdf_detail_example_phone: "{{电话}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_email: "电子邮件",
      hotel_hotel_reservation_accompany_pdf_detail_example_email: "{{电子邮件}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_title: "伴随标题",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_title: "{{accompanyTitle}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_first_name: "伴随名字",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_first_name: "{{accompanyFirstName}}",
      hotel_hotel_reservation_accompany_pdf_detail_label_accompany_last_name: "陪同姓氏",
      hotel_hotel_reservation_accompany_pdf_detail_example_accompany_last_name: "{{accompanyLastName}}",
      hotel_hotel_folio_email_edit_title: "FOLIO 电子邮件格式",
      hotel_hotel_folio_email_edit_select_kr: "韩国",
      hotel_hotel_folio_email_edit_select_en: "CN",
      hotel_hotel_folio_email_edit_select_cn: "中国",
      hotel_hotel_folio_email_edit_select_jp: "J.P",
      hotel_hotel_folio_email_edit_select_ms: "MS",
      hotel_hotel_folio_email_edit_button_remove: "消除",
      hotel_hotel_folio_email_edit_button_append: "附加",
      hotel_hotel_folio_email_edit_button_save: "节省",
      hotel_hotel_folio_email_edit_toolbar_bold: "大胆的",
      hotel_hotel_folio_email_edit_toolbar_italic: "斜体",
      hotel_hotel_folio_email_edit_toolbar_underline: "强调",
      hotel_hotel_folio_email_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_folio_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_folio_email_edit_toolbar_font_family: "字体系列",
      hotel_hotel_folio_email_edit_toolbar_font_size: "字体大小",
      hotel_hotel_folio_email_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_folio_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_folio_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_folio_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_folio_email_edit_toolbar_table: "桌子",
      hotel_hotel_folio_email_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_folio_email_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_folio_email_edit_toolbar_paragraph: "段落",
      hotel_hotel_folio_email_edit_toolbar_line_height: "行高",
      hotel_hotel_folio_email_edit_toolbar_picture: "图片",
      hotel_hotel_folio_email_edit_toolbar_code_view: "代码视图",
      hotel_hotel_folio_email_edit_toolbar_full_screen: "全屏",
      hotel_hotel_folio_email_edit_toolbar_help: "帮助",
      hotel_hotel_folio_email_edit_toolbar_tip: "提示",
      hotel_hotel_folio_email_edit_placeholder_content: "请输入内容。",
      hotel_hotel_folio_email_detail_title: "对开电子邮件内容",
      hotel_hotel_folio_email_detail_label_confurmation_number: "确认号码",
      hotel_hotel_folio_email_detail_example_confurmation_number: "{{确认号码}}",
      hotel_hotel_folio_email_detail_label_reservation_id: "预订ID",
      hotel_hotel_folio_email_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_folio_email_detail_label_arrival_date: "到达日期",
      hotel_hotel_folio_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_folio_email_detail_label_departure_date: "出发日期",
      hotel_hotel_folio_email_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_folio_email_detail_label_room_type: "房型",
      hotel_hotel_folio_email_detail_example_room_type: "{{房型}}",
      hotel_hotel_folio_email_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_folio_email_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_folio_email_detail_label_room_rate: "房价",
      hotel_hotel_folio_email_detail_example_room_rate: "{{房价}}",
      hotel_hotel_folio_email_detail_label_total_rate: "总费率",
      hotel_hotel_folio_email_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_folio_email_detail_label_current_balance: "当前余额",
      hotel_hotel_folio_email_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_folio_email_detail_label_room_number: "房间号",
      hotel_hotel_folio_email_detail_example_room_number: "{{房间号}}",
      hotel_hotel_folio_email_detail_label_adults: "成年人",
      hotel_hotel_folio_email_detail_example_adults: "{{成年人}}",
      hotel_hotel_folio_email_detail_label_children: "孩子们",
      hotel_hotel_folio_email_detail_example_children: "{{孩子们}}",
      hotel_hotel_folio_email_detail_label_check_out_time: "退房时间",
      hotel_hotel_folio_email_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_folio_email_detail_label_name_title: "姓名 职务",
      hotel_hotel_folio_email_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_folio_email_detail_label_first_name: "名",
      hotel_hotel_folio_email_detail_example_first_name: "{{名}}",
      hotel_hotel_folio_email_detail_label_last_name: "姓",
      hotel_hotel_folio_email_detail_example_last_name: "{{姓}}",
      hotel_hotel_folio_email_detail_label_country_code: "国家代码",
      hotel_hotel_folio_email_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_folio_email_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_folio_email_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_folio_email_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_folio_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_email_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_folio_email_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_folio_email_detail_label_phone: "手机号码",
      hotel_hotel_folio_email_detail_example_phone: "{{电话}}",
      hotel_hotel_folio_email_detail_label_email: "电子邮件",
      hotel_hotel_folio_email_detail_example_email: "{{电子邮件}}",
      hotel_hotel_folio_email_detail_label_text_area: "对开文本区域",
      hotel_hotel_folio_email_detail_example_text_area: "{{folio_text_area}}",
      hotel_hotel_enroll_profile_edit_title: "注册个人资料格式",
      hotel_hotel_enroll_profile_edit_select_kr: "韩国",
      hotel_hotel_enroll_profile_edit_select_en: "CN",
      hotel_hotel_enroll_profile_edit_select_cn: "中国",
      hotel_hotel_enroll_profile_edit_select_jp: "J.P",
      hotel_hotel_enroll_profile_edit_select_ms: "MS",
      hotel_hotel_enroll_profile_edit_button_remove: "消除",
      hotel_hotel_enroll_profile_edit_button_append: "附加",
      hotel_hotel_enroll_profile_edit_button_save: "节省",
      hotel_hotel_enroll_profile_edit_toolbar_bold: "大胆的",
      hotel_hotel_enroll_profile_edit_toolbar_italic: "斜体",
      hotel_hotel_enroll_profile_edit_toolbar_underline: "强调",
      hotel_hotel_enroll_profile_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_enroll_profile_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_enroll_profile_edit_toolbar_font_family: "字体系列",
      hotel_hotel_enroll_profile_edit_toolbar_font_size: "字体大小",
      hotel_hotel_enroll_profile_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_enroll_profile_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_enroll_profile_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_enroll_profile_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_enroll_profile_edit_toolbar_table: "桌子",
      hotel_hotel_enroll_profile_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_enroll_profile_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_enroll_profile_edit_toolbar_paragraph: "段落",
      hotel_hotel_enroll_profile_edit_toolbar_line_height: "行高",
      hotel_hotel_enroll_profile_edit_toolbar_picture: "图片",
      hotel_hotel_enroll_profile_edit_toolbar_code_view: "代码视图",
      hotel_hotel_enroll_profile_edit_toolbar_full_screen: "全屏",
      hotel_hotel_enroll_profile_edit_toolbar_help: "帮助",
      hotel_hotel_enroll_profile_edit_toolbar_tip: "提示",
      hotel_hotel_enroll_profile_edit_placeholder_content: "请输入内容。",
      hotel_hotel_enroll_profile_detail_title: "注册个人资料内容",
      hotel_hotel_enroll_profile_detail_label_email: "电子邮件",
      hotel_hotel_enroll_profile_detail_example_email: "{{电子邮件}}",
      hotel_hotel_enroll_profile_detail_label_hotel_name: "酒店名称",
      hotel_hotel_enroll_profile_detail_example_hotel_name: "{{酒店名称}}",
      hotel_hotel_enroll_profile_detail_label_title: "标题",
      hotel_hotel_enroll_profile_detail_example_title: "{{标题}}",
      hotel_hotel_enroll_profile_detail_label_first_name: "名",
      hotel_hotel_enroll_profile_detail_example_first_name: "{{名}}",
      hotel_hotel_enroll_profile_detail_label_last_name: "姓",
      hotel_hotel_enroll_profile_detail_example_last_name: "{{姓}}",
      hotel_hotel_enroll_profile_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_enroll_profile_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_enroll_profile_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_enroll_profile_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_enroll_profile_detail_label_mobile_number: "手机号码",
      hotel_hotel_enroll_profile_detail_example_mobile_number: "{{手机号码}}",
      hotel_hotel_folio_pdf_edit_title: "对开 PDF 格式",
      hotel_hotel_folio_pdf_edit_select_kr: "韩国",
      hotel_hotel_folio_pdf_edit_select_en: "CN",
      hotel_hotel_folio_pdf_edit_select_cn: "中国",
      hotel_hotel_folio_pdf_edit_select_jp: "J.P",
      hotel_hotel_folio_pdf_edit_select_ms: "MS",
      hotel_hotel_folio_pdf_edit_button_remove: "消除",
      hotel_hotel_folio_pdf_edit_button_append: "附加",
      hotel_hotel_folio_pdf_edit_button_save: "节省",
      hotel_hotel_folio_pdf_edit_toolbar_bold: "大胆的",
      hotel_hotel_folio_pdf_edit_toolbar_italic: "斜体",
      hotel_hotel_folio_pdf_edit_toolbar_underline: "强调",
      hotel_hotel_folio_pdf_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_folio_pdf_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_folio_pdf_edit_toolbar_font_family: "字体系列",
      hotel_hotel_folio_pdf_edit_toolbar_font_size: "字体大小",
      hotel_hotel_folio_pdf_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_folio_pdf_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_folio_pdf_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_folio_pdf_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_folio_pdf_edit_toolbar_table: "桌子",
      hotel_hotel_folio_pdf_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_folio_pdf_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_folio_pdf_edit_toolbar_paragraph: "段落",
      hotel_hotel_folio_pdf_edit_toolbar_line_height: "行高",
      hotel_hotel_folio_pdf_edit_toolbar_picture: "图片",
      hotel_hotel_folio_pdf_edit_toolbar_code_view: "代码视图",
      hotel_hotel_folio_pdf_edit_toolbar_full_screen: "全屏",
      hotel_hotel_folio_pdf_edit_toolbar_help: "帮助",
      hotel_hotel_folio_pdf_edit_toolbar_tip: "提示",
      hotel_hotel_folio_pdf_edit_toolbar_download_pdf: "下载PDF",
      hotel_hotel_folio_pdf_edit_placeholder_content: "请输入内容。",
      hotel_hotel_folio_pdf_detail_title: "对开 PDF 内容",
      hotel_hotel_folio_pdf_detail_label_confurmation_number: "确认号码",
      hotel_hotel_folio_pdf_detail_example_confurmation_number: "{{确认号码}}",
      hotel_hotel_folio_pdf_detail_label_reservation_id: "预订ID",
      hotel_hotel_folio_pdf_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_folio_pdf_detail_label_arrival_date: "到达日期",
      hotel_hotel_folio_pdf_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_folio_pdf_detail_label_departure_date: "出发日期",
      hotel_hotel_folio_pdf_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_folio_pdf_detail_label_room_type: "房型",
      hotel_hotel_folio_pdf_detail_example_room_type: "{{房型}}",
      hotel_hotel_folio_pdf_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_folio_pdf_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_folio_pdf_detail_label_room_rate: "房价",
      hotel_hotel_folio_pdf_detail_example_room_rate: "{{房价}}",
      hotel_hotel_folio_pdf_detail_label_total_rate: "总费率",
      hotel_hotel_folio_pdf_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_folio_pdf_detail_label_current_balance: "当前余额",
      hotel_hotel_folio_pdf_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_folio_pdf_detail_label_room_number: "房间号",
      hotel_hotel_folio_pdf_detail_example_room_number: "{{房间号}}",
      hotel_hotel_folio_pdf_detail_label_adults: "成年人",
      hotel_hotel_folio_pdf_detail_example_adults: "{{成年人}}",
      hotel_hotel_folio_pdf_detail_label_children: "孩子们",
      hotel_hotel_folio_pdf_detail_example_children: "{{孩子们}}",
      hotel_hotel_folio_pdf_detail_label_check_out_time: "退房时间",
      hotel_hotel_folio_pdf_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_folio_pdf_detail_label_name_title: "姓名 职务",
      hotel_hotel_folio_pdf_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_folio_pdf_detail_label_first_name: "名",
      hotel_hotel_folio_pdf_detail_example_first_name: "{{名}}",
      hotel_hotel_folio_pdf_detail_label_last_name: "姓",
      hotel_hotel_folio_pdf_detail_example_last_name: "{{姓}}",
      hotel_hotel_folio_pdf_detail_label_country_code: "国家代码",
      hotel_hotel_folio_pdf_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_folio_pdf_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_folio_pdf_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_folio_pdf_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_folio_pdf_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_folio_pdf_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_folio_pdf_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_folio_pdf_detail_label_phone: "手机号码",
      hotel_hotel_folio_pdf_detail_example_phone: "{{电话}}",
      hotel_hotel_folio_pdf_detail_label_email: "电子邮件",
      hotel_hotel_folio_pdf_detail_example_email: "{{电子邮件}}",
      hotel_hotel_folio_pdf_detail_label_text_area: "对开文本区域",
      hotel_hotel_folio_pdf_detail_example_text_area: "{{folio_text_area}}",
      hotel_future_booking_change_email_edit_title: "未来预订变更电子邮件",
      hotel_future_booking_change_email_edit_select_kr: "韩国",
      hotel_future_booking_change_email_edit_select_en: "CN",
      hotel_future_booking_change_email_edit_select_cn: "中国",
      hotel_future_booking_change_email_edit_select_jp: "J.P",
      hotel_future_booking_change_email_edit_select_ms: "MS",
      hotel_future_booking_change_email_edit_button_remove: "消除",
      hotel_future_booking_change_email_edit_button_append: "附加",
      hotel_future_booking_change_email_edit_button_save: "节省",
      hotel_future_booking_change_email_edit_toolbar_bold: "大胆的",
      hotel_future_booking_change_email_edit_toolbar_italic: "斜体",
      hotel_future_booking_change_email_edit_toolbar_underline: "强调",
      hotel_future_booking_change_email_edit_toolbar_strikethrough: "删除线",
      hotel_future_booking_change_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_future_booking_change_email_edit_toolbar_font_family: "字体系列",
      hotel_future_booking_change_email_edit_toolbar_font_size: "字体大小",
      hotel_future_booking_change_email_edit_toolbar_text_color: "文字颜色",
      hotel_future_booking_change_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_future_booking_change_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_future_booking_change_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_future_booking_change_email_edit_toolbar_table: "桌子",
      hotel_future_booking_change_email_edit_toolbar_unordered_list: "无序列表",
      hotel_future_booking_change_email_edit_toolbar_ordered_list: "有序列表",
      hotel_future_booking_change_email_edit_toolbar_paragraph: "段落",
      hotel_future_booking_change_email_edit_toolbar_line_height: "行高",
      hotel_future_booking_change_email_edit_toolbar_picture: "图片",
      hotel_future_booking_change_email_edit_toolbar_code_view: "代码视图",
      hotel_future_booking_change_email_edit_toolbar_full_screen: "全屏",
      hotel_future_booking_change_email_edit_toolbar_help: "帮助",
      hotel_future_booking_change_email_edit_toolbar_tip: "提示",
      hotel_future_booking_change_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_future_booking_change_email_edit_placeholder_content: "请输入内容。",
      hotel_future_booking_change_email_detail_title: "更改未来的预订内容",
      hotel_future_booking_change_email_detail_label_confirmation_number: "确认号码",
      hotel_future_booking_change_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_future_booking_change_email_detail_label_first_name: "名",
      hotel_future_booking_change_email_detail_example_first_name: "{{名}}",
      hotel_future_booking_change_email_detail_label_last_name: "姓",
      hotel_future_booking_change_email_detail_example_last_name: "{{姓}}",
      hotel_future_booking_change_email_detail_label_room_type: "房型",
      hotel_future_booking_change_email_detail_example_room_type: "{{房型}}",
      hotel_future_booking_change_email_detail_label_arrival_date: "到达日期",
      hotel_future_booking_change_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_future_booking_change_email_detail_label_departure_date: "出发日期",
      hotel_future_booking_change_email_detail_example_departure_date: "{{出发日期}}",
      hotel_future_booking_change_email_detail_label_name_title: "姓名 职务",
      hotel_future_booking_change_email_detail_example_name_title: "{{名称标题}}",
      hotel_future_booking_change_email_detail_label_adults: "成年人",
      hotel_future_booking_change_email_detail_example_adults: "{{成年人}}",
      hotel_future_booking_change_email_detail_label_night: "夜晚",
      hotel_future_booking_change_email_detail_example_night: "{{夜晚}}",
      hotel_future_booking_change_email_detail_label_room_rate: "房价",
      hotel_future_booking_change_email_detail_example_room_rate: "{{房价}}",
      hotel_future_booking_change_email_detail_label_room_rate_currency: "房价货币",
      hotel_future_booking_change_email_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_future_booking_change_email_detail_label_total_rate: "总费率",
      hotel_future_booking_change_email_detail_example_total_rate: "{{总费率}}",
      hotel_future_booking_change_email_detail_label_cancellation: "取消政策",
      hotel_future_booking_change_email_detail_example_cancellationPolicy: "{{取消政策}}",
      hotel_future_booking_cancel_email_edit_title: "未来预订取消电子邮件",
      hotel_future_booking_cancel_email_edit_select_kr: "韩国",
      hotel_future_booking_cancel_email_edit_select_en: "CN",
      hotel_future_booking_cancel_email_edit_select_cn: "中国",
      hotel_future_booking_cancel_email_edit_select_jp: "J.P",
      hotel_future_booking_cancel_email_edit_select_ms: "MS",
      hotel_future_booking_cancel_email_edit_button_remove: "消除",
      hotel_future_booking_cancel_email_edit_button_append: "附加",
      hotel_future_booking_cancel_email_edit_button_save: "节省",
      hotel_future_booking_cancel_email_edit_toolbar_bold: "大胆的",
      hotel_future_booking_cancel_email_edit_toolbar_italic: "斜体",
      hotel_future_booking_cancel_email_edit_toolbar_underline: "强调",
      hotel_future_booking_cancel_email_edit_toolbar_strikethrough: "删除线",
      hotel_future_booking_cancel_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_future_booking_cancel_email_edit_toolbar_font_family: "字体系列",
      hotel_future_booking_cancel_email_edit_toolbar_font_size: "字体大小",
      hotel_future_booking_cancel_email_edit_toolbar_text_color: "文字颜色",
      hotel_future_booking_cancel_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_future_booking_cancel_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_future_booking_cancel_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_future_booking_cancel_email_edit_toolbar_table: "桌子",
      hotel_future_booking_cancel_email_edit_toolbar_unordered_list: "无序列表",
      hotel_future_booking_cancel_email_edit_toolbar_ordered_list: "有序列表",
      hotel_future_booking_cancel_email_edit_toolbar_paragraph: "段落",
      hotel_future_booking_cancel_email_edit_toolbar_line_height: "行高",
      hotel_future_booking_cancel_email_edit_toolbar_picture: "图片",
      hotel_future_booking_cancel_email_edit_toolbar_code_view: "代码视图",
      hotel_future_booking_cancel_email_edit_toolbar_full_screen: "全屏",
      hotel_future_booking_cancel_email_edit_toolbar_help: "帮助",
      hotel_future_booking_cancel_email_edit_toolbar_tip: "提示",
      hotel_future_booking_cancel_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_future_booking_cancel_email_edit_placeholder_content: "请输入内容。",
      hotel_future_booking_cancel_email_detail_title: "取消未来的预订内容",
      hotel_future_booking_cancel_email_detail_label_confirmation_number: "确认号码",
      hotel_future_booking_cancel_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_future_booking_cancel_email_detail_label_first_name: "名",
      hotel_future_booking_cancel_email_detail_example_first_name: "{{名}}",
      hotel_future_booking_cancel_email_detail_label_last_name: "姓",
      hotel_future_booking_cancel_email_detail_example_last_name: "{{姓}}",
      hotel_future_booking_cancel_email_detail_label_room_type: "房型",
      hotel_future_booking_cancel_email_detail_example_room_type: "{{房型}}",
      hotel_future_booking_cancel_email_detail_label_arrival_date: "到达日期",
      hotel_future_booking_cancel_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_future_booking_cancel_email_detail_label_departure_date: "出发日期",
      hotel_future_booking_cancel_email_detail_example_departure_date: "{{出发日期}}",
      hotel_future_booking_cancel_email_detail_label_name_title: "姓名 职务",
      hotel_future_booking_cancel_email_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_no_show_email_edit_title: "未来预订未出现电子邮件",
      hotel_hotel_no_show_email_edit_select_kr: "韩国",
      hotel_hotel_no_show_email_edit_select_en: "CN",
      hotel_hotel_no_show_email_edit_select_cn: "中国",
      hotel_hotel_no_show_email_edit_select_jp: "J.P",
      hotel_hotel_no_show_email_edit_select_ms: "MS",
      hotel_hotel_no_show_email_edit_button_remove: "消除",
      hotel_hotel_no_show_email_edit_button_append: "附加",
      hotel_hotel_no_show_email_edit_button_save: "节省",
      hotel_hotel_no_show_email_edit_toolbar_bold: "大胆的",
      hotel_hotel_no_show_email_edit_toolbar_italic: "斜体",
      hotel_hotel_no_show_email_edit_toolbar_underline: "强调",
      hotel_hotel_no_show_email_edit_toolbar_strikethrough: "删除线",
      hotel_hotel_no_show_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_hotel_no_show_email_edit_toolbar_font_family: "字体系列",
      hotel_hotel_no_show_email_edit_toolbar_font_size: "字体大小",
      hotel_hotel_no_show_email_edit_toolbar_text_color: "文字颜色",
      hotel_hotel_no_show_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_hotel_no_show_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_hotel_no_show_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_hotel_no_show_email_edit_toolbar_table: "桌子",
      hotel_hotel_no_show_email_edit_toolbar_unordered_list: "无序列表",
      hotel_hotel_no_show_email_edit_toolbar_ordered_list: "有序列表",
      hotel_hotel_no_show_email_edit_toolbar_paragraph: "段落",
      hotel_hotel_no_show_email_edit_toolbar_line_height: "行高",
      hotel_hotel_no_show_email_edit_toolbar_picture: "图片",
      hotel_hotel_no_show_email_edit_toolbar_code_view: "代码视图",
      hotel_hotel_no_show_email_edit_toolbar_full_screen: "全屏",
      hotel_hotel_no_show_email_edit_toolbar_help: "帮助",
      hotel_hotel_no_show_email_edit_toolbar_tip: "提示",
      hotel_hotel_no_show_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_hotel_no_show_email_edit_placeholder_content: "请输入内容。",
      hotel_hotel_no_show_email_detail_title: "取消未来的预订内容",
      hotel_hotel_no_show_email_detail_label_confirmation_number: "确认号码",
      hotel_hotel_no_show_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_hotel_no_show_email_detail_label_reservation_id: "预订ID",
      hotel_hotel_no_show_email_detail_example_reservation_id: "{{预订ID}}",
      hotel_hotel_no_show_email_detail_label_first_name: "名",
      hotel_hotel_no_show_email_detail_example_first_name: "{{名}}",
      hotel_hotel_no_show_email_detail_label_last_name: "姓",
      hotel_hotel_no_show_email_detail_example_last_name: "{{姓}}",
      hotel_hotel_no_show_email_detail_label_room_type: "房型",
      hotel_hotel_no_show_email_detail_example_room_type: "{{房型}}",
      hotel_hotel_no_show_email_detail_label_arrival_date: "到达日期",
      hotel_hotel_no_show_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_hotel_no_show_email_detail_label_departure_date: "出发日期",
      hotel_hotel_no_show_email_detail_example_departure_date: "{{出发日期}}",
      hotel_hotel_no_show_email_detail_label_name_title: "姓名 职务",
      hotel_hotel_no_show_email_detail_example_name_title: "{{名称标题}}",
      hotel_hotel_no_show_email_detail_label_room_rate_currency: "房价货币",
      hotel_hotel_no_show_email_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_hotel_no_show_email_detail_label_room_rate: "房价",
      hotel_hotel_no_show_email_detail_example_room_rate: "{{房价}}",
      hotel_hotel_no_show_email_detail_label_total_rate: "总费率",
      hotel_hotel_no_show_email_detail_example_total_rate: "{{总费率}}",
      hotel_hotel_no_show_email_detail_label_current_balance: "当前余额",
      hotel_hotel_no_show_email_detail_example_current_balance: "{{当前余额}}",
      hotel_hotel_no_show_email_detail_label_room_number: "房间号",
      hotel_hotel_no_show_email_detail_example_room_number: "{{房间号}}",
      hotel_hotel_no_show_email_detail_label_adults: "成年人",
      hotel_hotel_no_show_email_detail_example_adults: "{{成年人}}",
      hotel_hotel_no_show_email_detail_label_children: "孩子们",
      hotel_hotel_no_show_email_detail_example_children: "{{孩子们}}",
      hotel_hotel_no_show_email_detail_label_check_out_time: "退房时间",
      hotel_hotel_no_show_email_detail_example_check_out_time: "{{退房时间}}",
      hotel_hotel_no_show_email_detail_label_country_code: "国家代码",
      hotel_hotel_no_show_email_detail_example_country_code: "{{国家代码}}",
      hotel_hotel_no_show_email_detail_label_alternative_language_code: "替代语言代码",
      hotel_hotel_no_show_email_detail_example_alternative_language_code: "{{替代语言代码}}",
      hotel_hotel_no_show_email_detail_label_alternative_first_name: "替代名字",
      hotel_hotel_no_show_email_detail_example_alternative_first_name: "{{alternativeFirstName}}",
      hotel_hotel_no_show_email_detail_label_alternative_last_name: "替代姓氏",
      hotel_hotel_no_show_email_detail_example_alternative_last_name: "{{替代姓氏}}",
      hotel_hotel_no_show_email_detail_label_phone: "电话",
      hotel_hotel_no_show_email_detail_example_phone: "{{电话}}",
      hotel_hotel_no_show_email_detail_label_email: "电子邮件",
      hotel_hotel_no_show_email_detail_example_email: "{{电子邮件}}",
      hotel_hotel_no_show_email_detail_label_accompany: "陪伴",
      hotel_hotel_no_show_email_detail_example_accompany: "{{陪伴}}",
      hotel_future_booking_pack_add_email_edit_title: "未来预订套餐 添加电子邮件",
      hotel_future_booking_pack_add_email_edit_select_kr: "韩国",
      hotel_future_booking_pack_add_email_edit_select_en: "CN",
      hotel_future_booking_pack_add_email_edit_select_cn: "中国",
      hotel_future_booking_pack_add_email_edit_select_jp: "J.P",
      hotel_future_booking_pack_add_email_edit_select_ms: "MS",
      hotel_future_booking_pack_add_email_edit_button_remove: "消除",
      hotel_future_booking_pack_add_email_edit_button_append: "附加",
      hotel_future_booking_pack_add_email_edit_button_save: "节省",
      hotel_future_booking_pack_add_email_edit_toolbar_bold: "大胆的",
      hotel_future_booking_pack_add_email_edit_toolbar_italic: "斜体",
      hotel_future_booking_pack_add_email_edit_toolbar_underline: "强调",
      hotel_future_booking_pack_add_email_edit_toolbar_strikethrough: "删除线",
      hotel_future_booking_pack_add_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_future_booking_pack_add_email_edit_toolbar_font_family: "字体系列",
      hotel_future_booking_pack_add_email_edit_toolbar_font_size: "字体大小",
      hotel_future_booking_pack_add_email_edit_toolbar_text_color: "文字颜色",
      hotel_future_booking_pack_add_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_future_booking_pack_add_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_future_booking_pack_add_email_edit_toolbar_table: "桌子",
      hotel_future_booking_pack_add_email_edit_toolbar_unordered_list: "无序列表",
      hotel_future_booking_pack_add_email_edit_toolbar_ordered_list: "有序列表",
      hotel_future_booking_pack_add_email_edit_toolbar_paragraph: "段落",
      hotel_future_booking_pack_add_email_edit_toolbar_line_height: "行高",
      hotel_future_booking_pack_add_email_edit_toolbar_picture: "图片",
      hotel_future_booking_pack_add_email_edit_toolbar_code_view: "代码视图",
      hotel_future_booking_pack_add_email_edit_toolbar_full_screen: "全屏",
      hotel_future_booking_pack_add_email_edit_toolbar_help: "帮助",
      hotel_future_booking_pack_add_email_edit_toolbar_tip: "提示",
      hotel_future_booking_pack_add_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_future_booking_pack_add_email_edit_placeholder_content: "请输入内容。",
      hotel_future_booking_pack_add_email_detail_title: "添加未来预订套餐内容",
      hotel_future_booking_pack_add_email_detail_label_confirmation_number: "确认号码",
      hotel_future_booking_pack_add_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_future_booking_pack_add_email_detail_label_first_name: "名",
      hotel_future_booking_pack_add_email_detail_example_first_name: "{{名}}",
      hotel_future_booking_pack_add_email_detail_label_last_name: "姓",
      hotel_future_booking_pack_add_email_detail_example_last_name: "{{姓}}",
      hotel_future_booking_pack_add_email_detail_label_room_type: "房型",
      hotel_future_booking_pack_add_email_detail_example_room_type: "{{房型}}",
      hotel_future_booking_pack_add_email_detail_label_arrival_date: "到达日期",
      hotel_future_booking_pack_add_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_future_booking_pack_add_email_detail_label_departure_date: "出发日期",
      hotel_future_booking_pack_add_email_detail_example_departure_date: "{{出发日期}}",
      hotel_future_booking_pack_add_email_detail_label_name_title: "姓名 职务",
      hotel_future_booking_pack_add_email_detail_example_name_title: "{{名称标题}}",
      hotel_future_booking_pack_add_email_detail_label_package_code: "封装代码",
      hotel_future_booking_pack_add_email_detail_example_package_code: "{{包代码}}",
      hotel_future_booking_pack_add_email_detail_label_package_description: "包装说明",
      hotel_future_booking_pack_add_email_detail_example_package_description: "{{包描述}}",
      hotel_future_booking_pack_add_email_detail_label_amount: "数量",
      hotel_future_booking_pack_add_email_detail_example_amount: "{{数量}}",
      hotel_future_booking_pack_add_email_detail_label_package_text_area: "封装文本区",
      hotel_future_booking_pack_add_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_pack_delete_email_edit_title: "未来预订套餐 删除电子邮件",
      hotel_future_booking_pack_delete_email_edit_select_kr: "韩国",
      hotel_future_booking_pack_delete_email_edit_select_en: "CN",
      hotel_future_booking_pack_delete_email_edit_select_cn: "中国",
      hotel_future_booking_pack_delete_email_edit_select_jp: "J.P",
      hotel_future_booking_pack_delete_email_edit_select_ms: "MS",
      hotel_future_booking_pack_delete_email_edit_button_remove: "消除",
      hotel_future_booking_pack_delete_email_edit_button_append: "附加",
      hotel_future_booking_pack_delete_email_edit_button_save: "节省",
      hotel_future_booking_pack_delete_email_edit_toolbar_bold: "大胆的",
      hotel_future_booking_pack_delete_email_edit_toolbar_italic: "斜体",
      hotel_future_booking_pack_delete_email_edit_toolbar_underline: "强调",
      hotel_future_booking_pack_delete_email_edit_toolbar_strikethrough: "删除线",
      hotel_future_booking_pack_delete_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_family: "字体系列",
      hotel_future_booking_pack_delete_email_edit_toolbar_font_size: "字体大小",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_color: "文字颜色",
      hotel_future_booking_pack_delete_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_future_booking_pack_delete_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_future_booking_pack_delete_email_edit_toolbar_table: "桌子",
      hotel_future_booking_pack_delete_email_edit_toolbar_unordered_list: "无序列表",
      hotel_future_booking_pack_delete_email_edit_toolbar_ordered_list: "有序列表",
      hotel_future_booking_pack_delete_email_edit_toolbar_paragraph: "段落",
      hotel_future_booking_pack_delete_email_edit_toolbar_line_height: "行高",
      hotel_future_booking_pack_delete_email_edit_toolbar_picture: "图片",
      hotel_future_booking_pack_delete_email_edit_toolbar_code_view: "代码视图",
      hotel_future_booking_pack_delete_email_edit_toolbar_full_screen: "全屏",
      hotel_future_booking_pack_delete_email_edit_toolbar_help: "帮助",
      hotel_future_booking_pack_delete_email_edit_toolbar_tip: "提示",
      hotel_future_booking_pack_delete_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_future_booking_pack_delete_email_edit_placeholder_content: "请输入内容。",
      hotel_future_booking_pack_delete_email_detail_title: "删除未来预订套餐内容",
      hotel_future_booking_pack_delete_email_detail_label_confirmation_number: "确认号码",
      hotel_future_booking_pack_delete_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_future_booking_pack_delete_email_detail_label_first_name: "名",
      hotel_future_booking_pack_delete_email_detail_example_first_name: "{{名}}",
      hotel_future_booking_pack_delete_email_detail_label_last_name: "姓",
      hotel_future_booking_pack_delete_email_detail_example_last_name: "{{姓}}",
      hotel_future_booking_pack_delete_email_detail_label_room_type: "房型",
      hotel_future_booking_pack_delete_email_detail_example_room_type: "{{房型}}",
      hotel_future_booking_pack_delete_email_detail_label_arrival_date: "到达日期",
      hotel_future_booking_pack_delete_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_future_booking_pack_delete_email_detail_label_departure_date: "出发日期",
      hotel_future_booking_pack_delete_email_detail_example_departure_date: "{{出发日期}}",
      hotel_future_booking_pack_delete_email_detail_label_name_title: "姓名 职务",
      hotel_future_booking_pack_delete_email_detail_example_name_title: "{{名称标题}}",
      hotel_future_booking_pack_delete_email_detail_label_package_code: "封装代码",
      hotel_future_booking_pack_delete_email_detail_example_package_code: "{{包代码}}",
      hotel_future_booking_pack_delete_email_detail_label_package_description: "包装说明",
      hotel_future_booking_pack_delete_email_detail_example_package_description: "{{包描述}}",
      hotel_future_booking_pack_delete_email_detail_label_amount: "数量",
      hotel_future_booking_pack_delete_email_detail_example_amount: "{{数量}}",
      hotel_future_booking_pack_delete_email_detail_label_package_text_area: "封装文本区",
      hotel_future_booking_pack_delete_email_detail_example_package_text_area: "{{package_text_area}}",
      hotel_future_booking_accom_add_email_edit_title: "未来预订 陪伴 添加电子邮件",
      hotel_future_booking_accom_add_email_edit_select_kr: "韩国",
      hotel_future_booking_accom_add_email_edit_select_en: "CN",
      hotel_future_booking_accom_add_email_edit_select_cn: "中国",
      hotel_future_booking_accom_add_email_edit_select_jp: "J.P",
      hotel_future_booking_accom_add_email_edit_select_ms: "MS",
      hotel_future_booking_accom_add_email_edit_button_remove: "消除",
      hotel_future_booking_accom_add_email_edit_button_append: "附加",
      hotel_future_booking_accom_add_email_edit_button_save: "节省",
      hotel_future_booking_accom_add_email_edit_toolbar_bold: "大胆的",
      hotel_future_booking_accom_add_email_edit_toolbar_italic: "斜体",
      hotel_future_booking_accom_add_email_edit_toolbar_underline: "强调",
      hotel_future_booking_accom_add_email_edit_toolbar_strikethrough: "删除线",
      hotel_future_booking_accom_add_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_future_booking_accom_add_email_edit_toolbar_font_family: "字体系列",
      hotel_future_booking_accom_add_email_edit_toolbar_font_size: "字体大小",
      hotel_future_booking_accom_add_email_edit_toolbar_text_color: "文字颜色",
      hotel_future_booking_accom_add_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_future_booking_accom_add_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_future_booking_accom_add_email_edit_toolbar_table: "桌子",
      hotel_future_booking_accom_add_email_edit_toolbar_unordered_list: "无序列表",
      hotel_future_booking_accom_add_email_edit_toolbar_ordered_list: "有序列表",
      hotel_future_booking_accom_add_email_edit_toolbar_paragraph: "段落",
      hotel_future_booking_accom_add_email_edit_toolbar_line_height: "行高",
      hotel_future_booking_accom_add_email_edit_toolbar_picture: "图片",
      hotel_future_booking_accom_add_email_edit_toolbar_code_view: "代码视图",
      hotel_future_booking_accom_add_email_edit_toolbar_full_screen: "全屏",
      hotel_future_booking_accom_add_email_edit_toolbar_help: "帮助",
      hotel_future_booking_accom_add_email_edit_toolbar_tip: "提示",
      hotel_future_booking_accom_add_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_future_booking_accom_add_email_edit_placeholder_content: "请输入内容。",
      hotel_future_booking_accom_add_email_detail_title: "添加未来预订伴随内容",
      hotel_future_booking_accom_add_email_detail_label_confirmation_number: "确认号码",
      hotel_future_booking_accom_add_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_future_booking_accom_add_email_detail_label_accom_first_name: "伴随名字",
      hotel_future_booking_accom_add_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_add_email_detail_label_accom_last_name: "陪同姓氏",
      hotel_future_booking_accom_add_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_add_email_detail_label_first_name: "名",
      hotel_future_booking_accom_add_email_detail_example_first_name: "{{名}}",
      hotel_future_booking_accom_add_email_detail_label_last_name: "姓",
      hotel_future_booking_accom_add_email_detail_example_last_name: "{{姓}}",
      hotel_future_booking_accom_add_email_detail_label_room_type: "房型",
      hotel_future_booking_accom_add_email_detail_example_room_type: "{{房型}}",
      hotel_future_booking_accom_add_email_detail_label_arrival_date: "到达日期",
      hotel_future_booking_accom_add_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_future_booking_accom_add_email_detail_label_departure_date: "出发日期",
      hotel_future_booking_accom_add_email_detail_example_departure_date: "{{出发日期}}",
      hotel_future_booking_accom_add_email_detail_label_accom_title: "伴随标题",
      hotel_future_booking_accom_add_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_add_email_detail_label_adults: "成年人",
      hotel_future_booking_accom_add_email_detail_example_adults: "{{成年人}}",
      hotel_future_booking_accom_add_email_detail_label_night: "夜晚",
      hotel_future_booking_accom_add_email_detail_example_night: "{{夜晚}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate: "房价",
      hotel_future_booking_accom_add_email_detail_example_room_rate: "{{房价}}",
      hotel_future_booking_accom_add_email_detail_label_room_rate_currency: "房价货币",
      hotel_future_booking_accom_add_email_detail_example_room_rate_currency: "{{房间价格货币}}",
      hotel_future_booking_accom_add_email_detail_label_total_rate: "总费率",
      hotel_future_booking_accom_add_email_detail_example_total_rate: "{{总费率}}",
      hotel_future_booking_accom_add_email_detail_label_cancellation: "取消政策",
      hotel_future_booking_accom_add_email_detail_example_cancellationPolicy: "{{取消政策}}",
      hotel_future_booking_accom_delete_email_edit_title: "未来预订伴随删除电子邮件",
      hotel_future_booking_accom_delete_email_edit_select_kr: "韩国",
      hotel_future_booking_accom_delete_email_edit_select_en: "CN",
      hotel_future_booking_accom_delete_email_edit_select_cn: "中国",
      hotel_future_booking_accom_delete_email_edit_select_jp: "J.P",
      hotel_future_booking_accom_delete_email_edit_select_ms: "MS",
      hotel_future_booking_accom_delete_email_edit_button_remove: "消除",
      hotel_future_booking_accom_delete_email_edit_button_append: "附加",
      hotel_future_booking_accom_delete_email_edit_button_save: "节省",
      hotel_future_booking_accom_delete_email_edit_toolbar_bold: "大胆的",
      hotel_future_booking_accom_delete_email_edit_toolbar_italic: "斜体",
      hotel_future_booking_accom_delete_email_edit_toolbar_underline: "强调",
      hotel_future_booking_accom_delete_email_edit_toolbar_strikethrough: "删除线",
      hotel_future_booking_accom_delete_email_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_family: "字体系列",
      hotel_future_booking_accom_delete_email_edit_toolbar_font_size: "字体大小",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_color: "文字颜色",
      hotel_future_booking_accom_delete_email_edit_toolbar_text_more_color: "更多颜色",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_color: "最近的颜色",
      hotel_future_booking_accom_delete_email_edit_toolbar_recent_more_color: "更多颜色",
      hotel_future_booking_accom_delete_email_edit_toolbar_table: "桌子",
      hotel_future_booking_accom_delete_email_edit_toolbar_unordered_list: "无序列表",
      hotel_future_booking_accom_delete_email_edit_toolbar_ordered_list: "有序列表",
      hotel_future_booking_accom_delete_email_edit_toolbar_paragraph: "段落",
      hotel_future_booking_accom_delete_email_edit_toolbar_line_height: "行高",
      hotel_future_booking_accom_delete_email_edit_toolbar_picture: "图片",
      hotel_future_booking_accom_delete_email_edit_toolbar_code_view: "代码视图",
      hotel_future_booking_accom_delete_email_edit_toolbar_full_screen: "全屏",
      hotel_future_booking_accom_delete_email_edit_toolbar_help: "帮助",
      hotel_future_booking_accom_delete_email_edit_toolbar_tip: "提示",
      hotel_future_booking_accom_delete_email_edit_toolbar_download_pdf: "下载PDF",
      hotel_future_booking_accom_delete_email_edit_placeholder_content: "请输入内容。",
      hotel_future_booking_accom_delete_email_detail_title: "删除未来预订的伴随内容",
      hotel_future_booking_accom_delete_email_detail_label_confirmation_number: "确认号码",
      hotel_future_booking_accom_delete_email_detail_example_confirmation_number: "{{确认号码}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_first_name: "伴随名字",
      hotel_future_booking_accom_delete_email_detail_example_accom_first_name: "{{accompanyFirstName}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_last_name: "陪同姓氏",
      hotel_future_booking_accom_delete_email_detail_example_accom_last_name: "{{accompanyLastName}}",
      hotel_future_booking_accom_delete_email_detail_label_first_name: "名",
      hotel_future_booking_accom_delete_email_detail_example_first_name: "{{名}}",
      hotel_future_booking_accom_delete_email_detail_label_last_name: "姓",
      hotel_future_booking_accom_delete_email_detail_example_last_name: "{{姓}}",
      hotel_future_booking_accom_delete_email_detail_label_room_type: "房型",
      hotel_future_booking_accom_delete_email_detail_example_room_type: "{{房型}}",
      hotel_future_booking_accom_delete_email_detail_label_arrival_date: "到达日期",
      hotel_future_booking_accom_delete_email_detail_example_arrival_date: "{{到达日期}}",
      hotel_future_booking_accom_delete_email_detail_label_departure_date: "出发日期",
      hotel_future_booking_accom_delete_email_detail_example_departure_date: "{{出发日期}}",
      hotel_future_booking_accom_delete_email_detail_label_accom_title: "伴随标题",
      hotel_future_booking_accom_delete_email_detail_example_accom_title: "{{accompanyTitle}}",
      hotel_future_booking_accom_delete_email_detail_label_main_guest_name: "主要嘉宾姓名",
      hotel_future_booking_accom_delete_email_detail_example_main_guest_name: "{{mainGuestName}}",
      hotel_member_list_title: "会员名单",
      hotel_member_list_placeholder_search: "搜索...",
      hotel_member_list_label_no: "不。",
      hotel_member_list_label_first_name: "名",
      hotel_member_list_label_last_name: "姓",
      hotel_member_list_label_email: "电子邮件",
      hotel_member_list_label_gender: "性别",
      hotel_member_list_label_member_type: "会员类型",
      hotel_member_list_label_profile_id: "个人资料 ID",
      hotel_member_list_label_fail_count: "失败计数",
      hotel_member_list_label_date: "日期",
      hotel_member_list_guide_empty: "列表为空",
      hotel_member_detail_title: "个人资料 ID",
      hotel_member_detail_label_user: "用户",
      hotel_member_detail_button_user_transfer: "重新同步",
      hotel_member_detail_label_user_first_name: "名",
      hotel_member_detail_label_user_last_name: "姓",
      hotel_member_detail_label_user_email: "电子邮件",
      hotel_member_detail_label_user_gender: "性别",
      hotel_member_detail_label_user_member_type: "会员类型",
      hotel_member_detail_label_user_date: "日期",
      hotel_member_detail_label_fail_log_list: "失败日志列表",
      hotel_member_detail_label_fail_count: "失败计数",
      hotel_member_detail_button_unlock: "开锁",
      hotel_member_detail_label_fail_log_no: "不。",
      hotel_member_detail_label_fail_log_lock: "锁",
      hotel_member_detail_label_fail_log_date: "日期",
      hotel_member_detail_label_pms_member_profile: "PMS会员简介",
      hotel_member_detail_label_pms_member_profile_name_title: "姓名 职务",
      hotel_member_detail_label_pms_member_profile_first_name: "名",
      hotel_member_detail_label_pms_member_profile_last_name: "姓",
      hotel_member_detail_label_pms_member_profile_alternative_language_code: "替代语言代码",
      hotel_member_detail_label_pms_member_profile_alternative_first_name: "替代名字",
      hotel_member_detail_label_pms_member_profile_alternative_last_name: "替代姓氏",
      hotel_member_detail_label_pms_member_profile_email: "电子邮件",
      hotel_member_detail_label_pms_member_profile_email_opera_id: "电子邮件 Opera ID",
      hotel_member_detail_label_pms_member_profile_phone: "手机号码",
      hotel_member_detail_label_pms_member_profile_phone_opera_id: "手机号码 Opera ID",
      hotel_member_detail_button_go_to_list: "前往列表",
      hotel_non_member_list_title: "NON MEMBER LIST",
      hotel_non_member_list_placeholder_search: "搜索...",
      hotel_non_member_list_label_no: "不。",
      hotel_non_member_list_label_first_name: "名",
      hotel_non_member_list_label_last_name: "姓",
      hotel_non_member_list_label_email: "电子邮件",
      hotel_non_member_list_label_gender: "性别",
      hotel_non_member_list_label_fail_count: "会员类型",
      hotel_non_member_list_label_date: "日期",
      hotel_non_member_list_guide_empty: "列表为空",
      hotel_non_member_detail_label_user: "用户",
      hotel_non_member_detail_label_user_first_name: "名",
      hotel_non_member_detail_label_user_last_name: "姓",
      hotel_non_member_detail_label_user_email: "电子邮件",
      hotel_non_member_detail_label_user_gender: "性别",
      hotel_non_member_detail_label_user_date: "日期",
      hotel_non_member_detail_label_fail_log_list: "失败日志列表",
      hotel_non_member_detail_label_fail_count: "失败计数",
      hotel_non_member_detail_button_unlock: "开锁",
      hotel_non_member_detail_label_fail_log_no: "不。",
      hotel_non_member_detail_label_fail_log_lock: "锁",
      hotel_non_member_detail_label_fail_log_date: "日期",
      hotel_non_member_detail_button_go_to_list: "前往列表",
      hotel_reservation_reservation_list_title: "预订清单",
      hotel_reservation_reservation_list_placeholder_select_box_reservation_status: "预订状态",
      hotel_reservation_reservation_list_select_inhouse: "内部",
      hotel_reservation_reservation_list_select_reserved: "预订的",
      hotel_reservation_reservation_list_select_changed: "改变了",
      hotel_reservation_reservation_list_select_canceled: "取消",
      hotel_reservation_reservation_list_select_no_show: "没有出现",
      hotel_reservation_reservation_list_select_checked_out: "检查过了",
      hotel_reservation_reservation_list_placeholder_select_box_reservation: "预订",
      hotel_reservation_reservation_list_select_confirmation_no: "确认号",
      hotel_reservation_reservation_list_select_room_no: "房间号。",
      hotel_reservation_reservation_list_select_name: "姓名",
      hotel_reservation_reservation_list_placeholder_search: "搜索...",
      hotel_reservation_reservation_list_label_no: "不。",
      hotel_reservation_reservation_list_label_confirmation_no: "确认号",
      hotel_reservation_reservation_list_label_owner: "所有者",
      hotel_reservation_reservation_list_label_main_guest: "主要嘉宾",
      hotel_reservation_reservation_list_label_status: "地位",
      hotel_reservation_reservation_list_label_checkin_date: "入住日期",
      hotel_reservation_reservation_list_label_checkout_date: "离开日期",
      hotel_reservation_reservation_list_label_date: "日期",
      hotel_reservation_reservation_list_label_cancel: "取消",
      hotel_reservation_reservation_list_guide_empty: "列表为空",
      hotel_reservation_reservation_list_button_cancel: "取消",
      hotel_reservation_reservation_list_button_sync: "同步",
      hotel_reservation_reservation_detail_label_confirmation_no: "确认号",
      hotel_reservation_reservation_detail_label_owner_main_guest: "主要嘉宾",
      hotel_reservation_reservation_detail_label_owner_delete_btn: "删除",
      hotel_reservation_reservation_detail_label_owner_profile_id: "个人资料 ID",
      hotel_reservation_reservation_detail_label_owner_title: "标题",
      hotel_reservation_reservation_detail_label_owner_first_name: "名",
      hotel_reservation_reservation_detail_label_owner_last_name: "姓",
      hotel_reservation_reservation_detail_label_owner_confirmed_at: "确认于",
      hotel_reservation_reservation_detail_label_owner_confirmed_type: "确认类型",
      hotel_reservation_reservation_detail_label_owner_use_key: "使用钥匙",
      hotel_reservation_reservation_detail_label_owner_waiting_room: "房间等待请求时间",
      hotel_reservation_reservation_detail_label_owner_date: "日期",
      hotel_reservation_reservation_detail_owner_list_guide_empty: "列表为空",
      hotel_reservation_reservation_detail_label_booking_user_owner: "所有者",
      hotel_reservation_reservation_detail_label_booking_user_title: "标题",
      hotel_reservation_reservation_detail_label_booking_user_first_name: "名",
      hotel_reservation_reservation_detail_label_booking_user_last_name: "姓",
      hotel_reservation_reservation_detail_label_booking_user_confirmed_at: "确认于",
      hotel_reservation_reservation_detail_label_booking_user_date: "日期",
      hotel_reservation_reservation_detail_label_accompany: "陪伴",
      hotel_reservation_reservation_detail_label_accompany_no: "不。",
      hotel_reservation_reservation_detail_label_accompany_profile_id: "个人资料 ID",
      hotel_reservation_reservation_detail_label_accompany_title: "标题",
      hotel_reservation_reservation_detail_label_accompany_first_name: "名",
      hotel_reservation_reservation_detail_label_accompany_last_name: "姓",
      hotel_reservation_reservation_detail_label_accompany_use_key: "使用钥匙",
      hotel_reservation_reservation_detail_label_accompany_confirmed_at: "确认于",
      hotel_reservation_reservation_detail_label_accompany_confirmed_type: "确认类型",
      hotel_reservation_reservation_detail_label_accompany_hotel_member_id: "酒店会员ID",
      hotel_reservation_reservation_detail_label_accompany_hotel_date: "日期",
      hotel_reservation_reservation_detail_accompany_list_guide_empty: "列表为空",
      hotel_reservation_reservation_detail_label_agreed: "协议",
      hotel_reservation_reservation_detail_label_agreed_title: "标题",
      hotel_reservation_reservation_detail_label_agreed_agreement: "协议",
      hotel_reservation_reservation_detail_label_agreed_agreed_at: "同意",
      hotel_reservation_reservation_detail_label_agreed_view_details: "查看详情",
      hotel_reservation_reservation_detail_label_agreed_go_to_detail: "转到详细信息",
      hotel_reservation_reservation_detail_label_payment: "支付",
      hotel_reservation_reservation_detail_label_payment_long_info: "长信息",
      hotel_reservation_reservation_detail_label_payment_amount: "数量",
      hotel_reservation_reservation_detail_label_payment_unit: "单元",
      hotel_reservation_reservation_detail_label_payment_pg_status_code: "PG状态码",
      hotel_reservation_reservation_detail_label_payment_pg_status_message: "PG状态信息",
      hotel_reservation_reservation_detail_label_payment_date: "日期",
      hotel_reservation_reservation_detail_label_pms: "经前综合症",
      hotel_reservation_reservation_detail_label_pms_confirmation_no: "确认号",
      hotel_reservation_reservation_detail_label_pms_reservation_id: "预订ID",
      hotel_reservation_reservation_detail_label_pms_profile_id: "个人资料 ID",
      hotel_reservation_reservation_detail_label_pms_reservation_status: "预订状态",
      hotel_reservation_reservation_detail_label_pms_computed_reservation_status: "计算的预订状态",
      hotel_reservation_reservation_detail_label_pms_message_exists: "消息存在",
      hotel_reservation_reservation_detail_label_pms_arrival_date: "到达日期",
      hotel_reservation_reservation_detail_label_pms_departure_date: "出发日期",
      hotel_reservation_reservation_detail_label_pms_room_type: "房型",
      hotel_reservation_reservation_detail_label_pms_room_type_code: "房间类型代码",
      hotel_reservation_reservation_detail_label_pms_room_rate_currency: "房价货币",
      hotel_reservation_reservation_detail_label_pms_room_rate: "房价",
      hotel_reservation_reservation_detail_label_pms_total_rate: "总费率",
      hotel_reservation_reservation_detail_label_pms_current_balance: "当前余额",
      hotel_reservation_reservation_detail_label_pms_room_number: "房间号",
      hotel_reservation_reservation_detail_label_pms_adults: "成年人",
      hotel_reservation_reservation_detail_label_pms_children: "孩子们",
      hotel_reservation_reservation_detail_label_pms_accompany: "陪伴",
      hotel_reservation_reservation_detail_label_pms_accompany_no: "不。",
      hotel_reservation_reservation_detail_label_pms_accompany_profile_id: "个人资料 ID",
      hotel_reservation_reservation_detail_label_pms_accompany_first_name: "名",
      hotel_reservation_reservation_detail_label_pms_accompany_last_name: "姓",
      hotel_reservation_reservation_detail_label_pms_accompany_nationality: "国籍",
      hotel_reservation_reservation_detail_label_pms_accompany_passport_no: "护照号。",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_birth: "出生日期",
      hotel_reservation_reservation_detail_label_pms_accompany_gender: "性别",
      hotel_reservation_reservation_detail_label_pms_accompany_date_of_expiry: "到期日期",
      hotel_reservation_reservation_detail_label_pms_main_guest: "主要嘉宾",
      hotel_reservation_reservation_detail_label_pms_main_guest_profile_id: "个人资料 ID",
      hotel_reservation_reservation_detail_label_pms_main_guest_first_name: "名",
      hotel_reservation_reservation_detail_label_pms_main_guest_last_name: "姓",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_nationality: "国籍",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_passport_no: "护照号。",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_birth: "出生日期",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_gender: "性别",
      hotel_reservation_reservation_detail_label_pms_main_guest_passport_info_date_of_expiry: "到期日期",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_date: "到达日期",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_no: "到达航班号",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_arrival_flight_time: "到达航班时间",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_date: "出发日期",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_no: "出发航班号",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_departure_flight_time: "出发时间",
      hotel_reservation_reservation_detail_label_pms_main_guest_flight_info_wake_up_call: "叫醒服务",
      hotel_reservation_reservation_detail_label_pms_package: "包裹",
      hotel_reservation_reservation_detail_label_pms_package_no: "不。",
      hotel_reservation_reservation_detail_label_pms_package_package_code: "封装代码",
      hotel_reservation_reservation_detail_label_pms_package_description: "描述",
      hotel_reservation_reservation_detail_label_pms_package_amount: "数量",
      hotel_reservation_reservation_detail_label_pms_charges_by_date: "按日期收费",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_no: "不。",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_date: "日期",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_description: "描述",
      hotel_reservation_reservation_detail_label_pms_charges_by_date_charges: "收费",
      hotel_reservation_reservation_detail_label_pms_invoice: "发票",
      hotel_reservation_reservation_detail_label_pms_invoice_no: "不。",
      hotel_reservation_reservation_detail_label_pms_invoice_date: "日期",
      hotel_reservation_reservation_detail_label_pms_invoice_description: "描述",
      hotel_reservation_reservation_detail_label_pms_invoice_currency_code: "货币代码",
      hotel_reservation_reservation_detail_label_pms_invoice_amount: "数量",
      hotel_reservation_reservation_detail_label_pms_invoice_supplement: "额外细节",
      hotel_reservation_reservation_detail_button_go_to_list: "前往列表",
      hotel_reservation_reservation_detail_button_cancel: "取消",
      hotel_reservation_reservation_compare_face_detail_title: "比较脸部细节",
      hotel_reservation_reservation_detail_label_user_name: "用户名",
      hotel_reservation_reservation_detail_label_identity_document_type: "身份证明文件类型",
      hotel_reservation_reservation_detail_label_auth_status: "验证状态",
      hotel_reservation_reservation_detail_label_similarity: "相似",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response: "新加坡政府 EVA 回应",
      hotel_reservation_reservation_detail_label_government_singapore_eva_status: "新加坡政府 EVA 状态",
      hotel_reservation_reservation_detail_label_government_singapore_eva_request_at: "新加坡政府 EVA 请求于",
      hotel_reservation_reservation_detail_label_government_singapore_eva_response_at: "新加坡政府 EVA 回应",
      hotel_reservation_reservation_detail_label_created_at: "创建于",
      hotel_reservation_reservation_compare_face_detail_button_go_to_detail: "转到详细信息",
      hotel_reservation_reservation_exist_label_room_no: "房间号。",
      hotel_reservation_reservation_exist_label_period_of_stay: "居留期限",
      hotel_reservation_reservation_exist_label_user: "用户",
      hotel_reservation_reservation_exist_label_user_confirmation_no: "确认号",
      hotel_reservation_reservation_exist_label_user_first_name: "名",
      hotel_reservation_reservation_exist_label_user_last_name: "姓",
      hotel_reservation_reservation_exist_label_user_email: "电子邮件",
      hotel_reservation_reservation_exist_label_user_gender: "性别",
      hotel_reservation_reservation_exist_label_user_member_type: "会员类型",
      hotel_reservation_reservation_exist_label_user_date: "日期",
      hotel_reservation_reservation_exist_label_exist_request_list: "现有请求列表",
      hotel_reservation_reservation_exist_label_exist_request_list_: "查询日期",
      hotel_reservation_reservation_exist_label_exist_request_list_request_type: "请求类型",
      hotel_reservation_reservation_exist_label_exist_request_list_express: "任务",
      hotel_reservation_reservation_exist_label_exist_request_list_number_of_item: "东西的个数",
      hotel_reservation_reservation_exist_label_exist_request_list_action_status: "行动状态",
      hotel_reservation_reservation_exist_label_exist_request_list_date: "日期",
      hotel_reservation_reservation_exist_label_exist_request_list_hover: "请求无法停止。",
      hotel_reservation_reservation_exist_button_go_to_list: "前往列表",
      hotel_reservation_reservation_exist_button_stop: "停止",
      hotel_reservation_waiting_list_title: "房间等候名单",
      hotel_reservation_waiting_list_placeholder_select_box_waiting_room: "房间等待",
      hotel_reservation_waiting_list_select_profile_id: "个人资料 ID",
      hotel_reservation_waiting_list_select_owner_name: "客人姓名",
      hotel_reservation_waiting_list_placeholder_search: "搜索...",
      hotel_reservation_waiting_list_label_no: "不。",
      hotel_reservation_waiting_list_label_profile_id: "个人资料 ID",
      hotel_reservation_waiting_list_label_title: "标题",
      hotel_reservation_waiting_list_label_main_guest: "主要嘉宾",
      hotel_reservation_waiting_list_label_main_guest_email: "主要访客电子邮件",
      hotel_reservation_waiting_list_label_owner_name: "客人姓名",
      hotel_reservation_waiting_list_label_owner_email: "访客电子邮件",
      hotel_reservation_waiting_list_label_confirmation_no: "确认号",
      hotel_reservation_waiting_list_guide_empty: "列表为空",
      hotel_reservation_waiting_detail_label_confirmation_no: "确认号",
      hotel_reservation_waiting_detail_label_owner: "预留宾客",
      hotel_reservation_waiting_detail_label_owner_profile_id: "个人资料 ID",
      hotel_reservation_waiting_detail_label_owner_title: "标题",
      hotel_reservation_waiting_detail_label_owner_first_name: "名",
      hotel_reservation_waiting_detail_label_owner_last_name: "姓",
      hotel_reservation_waiting_detail_label_owner_confirmed_at: "确认于",
      hotel_reservation_waiting_detail_label_owner_confirmed_type: "确认类型",
      hotel_reservation_waiting_detail_label_owner_waiting_room: "等候室",
      hotel_reservation_waiting_detail_label_owner_date: "日期",
      hotel_reservation_waiting_detail_label_accompany: "陪伴",
      hotel_reservation_waiting_detail_label_accompany_no: "不。",
      hotel_reservation_waiting_detail_label_accompany_profile_id: "个人资料 ID",
      hotel_reservation_waiting_detail_label_accompany_tile: "标题",
      hotel_reservation_waiting_detail_label_accompany_first_name: "名",
      hotel_reservation_waiting_detail_label_accompany_last_name: "姓",
      hotel_reservation_waiting_detail_label_accompany_use_key: "使用钥匙",
      hotel_reservation_waiting_detail_label_accompany_confirmed_at: "确认于",
      hotel_reservation_waiting_detail_label_accompany_confirmed_type: "确认类型",
      hotel_reservation_waiting_detail_label_accompany_hotel_member_id: "酒店会员ID",
      hotel_reservation_waiting_detail_label_accompany_date: "日期",
      hotel_reservation_waiting_detail_label_pms: "经前综合症",
      hotel_reservation_waiting_detail_label_pms_confirmation_no: "确认号",
      hotel_reservation_waiting_detail_label_pms_reservation_id: "预订ID",
      hotel_reservation_waiting_detail_label_pms_profile_id: "个人资料 ID",
      hotel_reservation_waiting_detail_label_pms_reservation_status: "预订状态",
      hotel_reservation_waiting_detail_label_pms_message_exists: "消息存在",
      hotel_reservation_waiting_detail_label_pms_arrival_date: "到达日期",
      hotel_reservation_waiting_detail_label_pms_departure_date: "出发日期",
      hotel_reservation_waiting_detail_label_pms_room_type: "房型",
      hotel_reservation_waiting_detail_label_pms_room_type_code: "房间类型代码",
      hotel_reservation_waiting_detail_label_pms_room_rate_currency: "房价货币",
      hotel_reservation_waiting_detail_label_pms_room_rate: "房价",
      hotel_reservation_waiting_detail_label_pms_total_rate: "总费率",
      hotel_reservation_waiting_detail_label_pms_current_balance: "当前余额",
      hotel_reservation_waiting_detail_label_pms_room_number: "房间号",
      hotel_reservation_waiting_detail_label_pms_adults: "成年人",
      hotel_reservation_waiting_detail_label_pms_children: "孩子们",
      hotel_reservation_waiting_detail_label_pms_accompany: "陪伴",
      hotel_reservation_waiting_detail_label_pms_accompany_no: "不。",
      hotel_reservation_waiting_detail_label_pms_accompany_profile_id: "个人资料 ID",
      hotel_reservation_waiting_detail_label_pms_accompany_first_name: "名",
      hotel_reservation_waiting_detail_label_pms_accompany_last_name: "姓",
      hotel_reservation_waiting_detail_label_pms_package: "包裹",
      hotel_reservation_waiting_detail_label_pms_package_no: "不。",
      hotel_reservation_waiting_detail_label_pms_package_package_code: "封装代码",
      hotel_reservation_waiting_detail_label_pms_package_description: "描述",
      hotel_reservation_waiting_detail_label_pms_package_amount: "数量",
      hotel_reservation_waiting_detail_label_pms_charges_by_date: "按日期收费",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_no: "不。",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_date: "日期",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_description: "描述",
      hotel_reservation_waiting_detail_label_pms_charges_by_date_charges: "收费",
      hotel_reservation_waiting_detail_button_go_to_list: "前往列表",
      hotel_reservation_waiting_detail_button_cancel: "取消",
      hotel_reservation_future_booking_list_title: "未来的预订清单",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation_status: "预订状态",
      hotel_reservation_future_booking_list_select_inhouse: "内部",
      hotel_reservation_future_booking_list_select_reserved: "预订的",
      hotel_reservation_future_booking_list_select_changed: "改变了",
      hotel_reservation_future_booking_list_select_canceled: "取消",
      hotel_reservation_future_booking_list_select_no_show: "没有出现",
      hotel_reservation_future_booking_list_select_checked_out: "检查过了",
      hotel_reservation_future_booking_list_placeholder_select_box_reservation: "未来预订",
      hotel_reservation_future_booking_list_select_confirmation_no: "确认号",
      hotel_reservation_future_booking_list_select_name: "姓名",
      hotel_reservation_future_booking_list_placeholder_search: "搜索...",
      hotel_reservation_future_booking_list_label_no: "不。",
      hotel_reservation_future_booking_list_label_confirmation_no: "确认号",
      hotel_reservation_future_booking_list_label_booking_owner: "预订业主",
      hotel_reservation_future_booking_list_label_status: "地位",
      hotel_reservation_future_booking_list_label_occupy: "抢占先机",
      hotel_reservation_future_booking_list_label_date: "日期",
      hotel_reservation_future_booking_list_label_cancel: "取消",
      hotel_reservation_future_booking_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_list_button_cancel: "取消",
      hotel_reservation_future_booking_list_button_sync: "同步",
      hotel_reservation_future_booking_detail_label_confirmation_no: "确认号",
      hotel_reservation_future_booking_detail_label_owner: "预订业主",
      hotel_reservation_future_booking_detail_label_owner_title: "标题",
      hotel_reservation_future_booking_detail_label_owner_first_name: "名",
      hotel_reservation_future_booking_detail_label_owner_last_name: "姓",
      hotel_reservation_future_booking_detail_label_owner_use_key: "使用钥匙",
      hotel_reservation_future_booking_detail_label_owner_confirmed_at: "确认的",
      hotel_reservation_future_booking_detail_label_owner_date: "日期",
      hotel_reservation_future_booking_detail_label_pms: "经前综合症",
      hotel_reservation_future_booking_detail_label_pms_confirmation_no: "确认号",
      hotel_reservation_future_booking_detail_label_pms_reservation_id: "预订ID",
      hotel_reservation_future_booking_detail_label_pms_profile_id: "个人资料 ID",
      hotel_reservation_future_booking_detail_label_pms_reservation_status: "预订状态",
      hotel_reservation_future_booking_detail_label_pms_message_exists: "消息存在",
      hotel_reservation_future_booking_detail_label_pms_arrival_date: "到达日期",
      hotel_reservation_future_booking_detail_label_pms_departure_date: "出发日期",
      hotel_reservation_future_booking_detail_label_pms_room_type: "房型",
      hotel_reservation_future_booking_detail_label_pms_room_type_code: "房间类型代码",
      hotel_reservation_future_booking_detail_label_pms_room_rate_currency: "房价货币",
      hotel_reservation_future_booking_detail_label_pms_room_rate: "房价",
      hotel_reservation_future_booking_detail_label_pms_total_rate: "总费率",
      hotel_reservation_future_booking_detail_label_pms_current_balance: "当前余额",
      hotel_reservation_future_booking_detail_label_pms_room_number: "房间号",
      hotel_reservation_future_booking_detail_label_pms_adults: "成年人",
      hotel_reservation_future_booking_detail_label_pms_children: "孩子们",
      hotel_reservation_future_booking_detail_label_pms_main_guest: "主要嘉宾",
      hotel_reservation_future_booking_detail_label_pms_main_guest_profile_id: "个人资料 ID",
      hotel_reservation_future_booking_detail_label_pms_main_guest_first_name: "名",
      hotel_reservation_future_booking_detail_label_pms_main_guest_last_name: "姓",
      hotel_reservation_future_booking_detail_label_pms_main_guest_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_nationality: "国籍",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_passport_no: "护照号。",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_birth: "出生日期",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_gender: "性别",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_date_of_expiry: "到期日期",
      hotel_reservation_future_booking_detail_label_pms_main_guest_passport_info_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_date: "到达日期",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_no: "到达航班号",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_arrival_flight_time: "到达航班时间",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_date: "出发航班号",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_no: "出发航班号",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_departure_flight_time: "出发时间",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_wake_up_call: "叫醒服务",
      hotel_reservation_future_booking_detail_label_pms_main_guest_flight_info_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_detail_label_pms_accompany: "陪伴",
      hotel_reservation_future_booking_detail_label_pms_accompany_no: "不。",
      hotel_reservation_future_booking_detail_label_pms_accompany_profile_id: "个人资料 ID",
      hotel_reservation_future_booking_detail_label_pms_accompany_first_name: "名",
      hotel_reservation_future_booking_detail_label_pms_accompany_last_name: "姓",
      hotel_reservation_future_booking_detail_label_pms_accompany_info_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_detail_label_pms_package: "包裹",
      hotel_reservation_future_booking_detail_label_pms_package_no: "不。",
      hotel_reservation_future_booking_detail_label_pms_package_package_code: "封装代码",
      hotel_reservation_future_booking_detail_label_pms_package_description: "描述",
      hotel_reservation_future_booking_detail_label_pms_package_amount: "数量",
      hotel_reservation_future_booking_detail_label_pms_package_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date: "按日期收费",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_no: "不。",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_date: "日期",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_description: "描述",
      hotel_reservation_future_booking_detail_label_pms_charges_by_date_charges: "收费",
      hotel_reservation_future_booking_detail_label_pms_charges_list_guide_empty: "列表为空",
      hotel_reservation_future_booking_detail_button_go_to_list: "前往列表",
      hotel_reservation_future_booking_detail_button_cancel: "取消",
      hotel_reservation_rule_setting_reservation_rule_list_title: "预订规则表",
      hotel_reservation_rule_setting_reservation_rule_list_select_active_type: "主动型（全部）",
      hotel_reservation_rule_setting_reservation_rule_list_select_active: "积极的",
      hotel_reservation_rule_setting_reservation_rule_list_select_inactive: "不活跃",
      hotel_reservation_rule_setting_reservation_rule_list_placeholder_search: "搜索...",
      hotel_reservation_rule_setting_reservation_rule_list_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_reservation_rule_list_label_reservation_amount: "预约金额",
      hotel_reservation_rule_setting_reservation_rule_list_label_type: "类型",
      hotel_reservation_rule_setting_reservation_rule_list_label_day_before_arrival: "抵达前一天",
      hotel_reservation_rule_setting_reservation_rule_list_label_before_time: "时间之前",
      hotel_reservation_rule_setting_reservation_rule_list_label_inactive: "不活跃",
      hotel_reservation_rule_setting_reservation_rule_list_label_created_at: "创建于",
      hotel_reservation_rule_setting_reservation_rule_list_button_delete: "删除",
      hotel_reservation_rule_setting_reservation_rule_list_button_add: "添加",
      hotel_reservation_rule_setting_reservation_rule_list_guide_empty: "列表为空",
      hotel_reservation_rule_setting_reservation_rule_add_title: "添加预订规则",
      hotel_reservation_rule_setting_reservation_rule_add_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_reservation_rule_add_label_description: "描述",
      hotel_reservation_rule_setting_reservation_rule_add_label_type: "类型",
      hotel_reservation_rule_setting_reservation_rule_add_placeholder_select_box_select: "选择",
      hotel_reservation_rule_setting_reservation_rule_add_select_percent: "百分比",
      hotel_reservation_rule_setting_reservation_rule_add_select_nights: "夜晚",
      hotel_reservation_rule_setting_reservation_rule_add_select_flat: "扁平率",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_amount: "数量",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_percent: "百分比",
      hotel_reservation_rule_setting_reservation_rule_add_label_reservation_nights: "夜晚",
      hotel_reservation_rule_setting_reservation_rule_add_label_transaction_code: "交易代码",
      hotel_reservation_rule_setting_reservation_rule_add_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_reservation_rule_add_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_reservation_rule_add_label_inactive: "不活跃",
      hotel_reservation_rule_setting_reservation_rule_add_label_yes: "是的",
      hotel_reservation_rule_setting_reservation_rule_add_label_no: "不",
      hotel_reservation_rule_setting_reservation_rule_add_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_reservation_rule_add_button_add: "添加",
      hotel_reservation_rule_setting_reservation_rule_add_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_type: "* 请选择类型。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_reservation_amount: "* 请输入百分比。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_dayBefore_arrival: "* 请输入付款码。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_transaction_code: "* 请输入入住前的天数。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_reservation_rule_add_validation_before_time: "* 请输入入住前的时间。",
      hotel_reservation_rule_setting_reservation_rule_detail_title: "预订规则详情",
      hotel_reservation_rule_setting_reservation_rule_detail_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_reservation_rule_detail_label_description: "描述",
      hotel_reservation_rule_setting_reservation_rule_detail_label_type: "类型",
      hotel_reservation_rule_setting_reservation_rule_detail_select_percent: "百分比",
      hotel_reservation_rule_setting_reservation_rule_detail_select_nights: "夜晚",
      hotel_reservation_rule_setting_reservation_rule_detail_select_flat: "扁平率",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_amount: "数量",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_percent: "百分比",
      hotel_reservation_rule_setting_reservation_rule_detail_label_reservation_nights: "夜晚",
      hotel_reservation_rule_setting_reservation_rule_detail_label_transactio_code: "交易代码",
      hotel_reservation_rule_setting_reservation_rule_detail_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_reservation_rule_detail_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_reservation_rule_detail_label_inactive: "不活跃",
      hotel_reservation_rule_setting_reservation_rule_detail_label_yes: "是的",
      hotel_reservation_rule_setting_reservation_rule_detail_label_no: "不",
      hotel_reservation_rule_setting_reservation_rule_detail_label_created_at: "创建于",
      hotel_reservation_rule_setting_reservation_rule_detail_label_updated_at: "更新于",
      hotel_reservation_rule_setting_reservation_rule_detail_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_reservation_rule_detail_button_cancel: "取消",
      hotel_reservation_rule_setting_reservation_rule_detail_button_delete: "删除",
      hotel_reservation_rule_setting_reservation_rule_detail_button_edit: "编辑",
      hotel_reservation_rule_setting_reservation_rule_detail_button_ok: "好的",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_type: "* 请选择类型。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_reservation_amount: "* 请输入百分比。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_dayBefore_arrival: "* 请输入付款码。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_transaction_code: "* 请输入入住前的天数。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_reservation_rule_edit_validation_before_time: "* 请输入入住前的时间。",
      hotel_reservation_rule_setting_deposit_rule_list_title: "存款规则列表",
      hotel_reservation_rule_setting_deposit_rule_list_select_active_type: "主动型（全部）",
      hotel_reservation_rule_setting_deposit_rule_list_select_active: "积极的",
      hotel_reservation_rule_setting_deposit_rule_list_select_inactive: "不活跃",
      hotel_reservation_rule_setting_deposit_rule_list_placeholder_search: "搜索...",
      hotel_reservation_rule_setting_deposit_rule_list_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_deposit_rule_list_label_deposit_amount: "存款金额",
      hotel_reservation_rule_setting_deposit_rule_list_label_type: "类型",
      hotel_reservation_rule_setting_deposit_rule_list_label_day_before_arrival: "抵达前一天",
      hotel_reservation_rule_setting_deposit_rule_list_label_before_time: "时间之前",
      hotel_reservation_rule_setting_deposit_rule_list_label_inactive: "不活跃",
      hotel_reservation_rule_setting_deposit_rule_list_label_created_at: "创建于",
      hotel_reservation_rule_setting_deposit_rule_list_button_delete: "删除",
      hotel_reservation_rule_setting_deposit_rule_list_button_add: "添加",
      hotel_reservation_rule_setting_deposit_rule_add_title: "添加存款规则",
      hotel_reservation_rule_setting_deposit_rule_add_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_deposit_rule_add_label_description: "描述",
      hotel_reservation_rule_setting_deposit_rule_add_label_type: "类型",
      hotel_reservation_rule_setting_deposit_rule_add_placeholder_select_box_select: "选择",
      hotel_reservation_rule_setting_deposit_rule_add_select_percent: "百分比",
      hotel_reservation_rule_setting_deposit_rule_add_select_nights: "夜晚",
      hotel_reservation_rule_setting_deposit_rule_add_select_flat: "扁平率",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_amount: "数量",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_percent: "百分比",
      hotel_reservation_rule_setting_deposit_rule_add_label_deposit_nights: "夜晚",
      hotel_reservation_rule_setting_deposit_rule_add_label_transaction_code: "交易代码",
      hotel_reservation_rule_setting_deposit_rule_add_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_deposit_rule_add_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_deposit_rule_add_label_inactive: "不活跃",
      hotel_reservation_rule_setting_deposit_rule_add_label_yes: "是的",
      hotel_reservation_rule_setting_deposit_rule_add_label_no: "不",
      hotel_reservation_rule_setting_deposit_rule_add_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_deposit_rule_add_button_add: "添加",
      hotel_reservation_rule_setting_deposit_rule_add_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_type: "* 请选择类型。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_deposit_amount: "* 请输入百分比。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_transaction_code: "* 请输入付款码。",
      hotel_reservation_rule_setting_deposit_rule_add_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_deposit_rule_detail_title: "存款规则信息",
      hotel_reservation_rule_setting_deposit_rule_detail_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_deposit_rule_detail_label_description: "描述",
      hotel_reservation_rule_setting_deposit_rule_detail_label_type: "类型",
      hotel_reservation_rule_setting_deposit_rule_detail_select_percent: "百分比",
      hotel_reservation_rule_setting_deposit_rule_detail_select_nights: "夜晚",
      hotel_reservation_rule_setting_deposit_rule_detail_select_flat: "扁平率",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_amount: "数量",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_percent: "百分比",
      hotel_reservation_rule_setting_deposit_rule_detail_label_deposit_nights: "夜晚",
      hotel_reservation_rule_setting_deposit_rule_detail_label_transactio_code: "交易代码",
      hotel_reservation_rule_setting_deposit_rule_detail_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_deposit_rule_detail_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_deposit_rule_detail_label_inactive: "不活跃",
      hotel_reservation_rule_setting_deposit_rule_detail_label_yes: "是的",
      hotel_reservation_rule_setting_deposit_rule_detail_label_no: "不",
      hotel_reservation_rule_setting_deposit_rule_detail_label_created_at: "创建于",
      hotel_reservation_rule_setting_deposit_rule_detail_label_updated_at: "更新于",
      hotel_reservation_rule_setting_deposit_rule_detail_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_deposit_rule_detail_button_cancel: "取消",
      hotel_reservation_rule_setting_deposit_rule_detail_button_delete: "删除",
      hotel_reservation_rule_setting_deposit_rule_detail_button_edit: "编辑",
      hotel_reservation_rule_setting_deposit_rule_detail_button_ok: "好的",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_type: "* 请选择类型。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_deposit_amount: "* 请输入百分比。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_transaction_code: "* 请输入付款码。",
      hotel_reservation_rule_setting_deposit_rule_edit_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_cancellation_rule_list_title: "取消规则列表",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active_type: "主动型（全部）",
      hotel_reservation_rule_setting_cancellation_rule_list_select_active: "积极的",
      hotel_reservation_rule_setting_cancellation_rule_list_select_inactive: "不活跃",
      hotel_reservation_rule_setting_cancellation_rule_list_placeholder_search: "搜索...",
      hotel_reservation_rule_setting_cancellation_rule_list_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_cancellation_rule_list_label_penalty_amount: "罚款金额",
      hotel_reservation_rule_setting_cancellation_rule_list_label_type: "类型",
      hotel_reservation_rule_setting_cancellation_rule_list_label_day_before_arrival: "抵达前一天",
      hotel_reservation_rule_setting_cancellation_rule_list_label_before_time: "时间之前",
      hotel_reservation_rule_setting_cancellation_rule_list_label_inactive: "不活跃",
      hotel_reservation_rule_setting_cancellation_rule_list_label_created_at: "创建于",
      hotel_reservation_rule_setting_cancellation_rule_list_button_delete: "删除",
      hotel_reservation_rule_setting_cancellation_rule_list_button_add: "添加",
      hotel_reservation_rule_setting_cancellation_rule_list_guide_empty: "列表为空",
      hotel_reservation_rule_setting_cancellation_rule_add_title: "添加取消规则",
      hotel_reservation_rule_setting_cancellation_rule_add_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_cancellation_rule_add_label_description: "描述",
      hotel_reservation_rule_setting_cancellation_rule_add_label_type: "类型",
      hotel_reservation_rule_setting_cancellation_rule_add_placeholder_select_box_select: "选择",
      hotel_reservation_rule_setting_cancellation_rule_add_select_percent: "百分比",
      hotel_reservation_rule_setting_cancellation_rule_add_select_nights: "夜晚",
      hotel_reservation_rule_setting_cancellation_rule_add_select_flat: "扁平率",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_amount: "数量",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_percent: "百分比",
      hotel_reservation_rule_setting_cancellation_rule_add_label_penalty_nights: "夜晚",
      hotel_reservation_rule_setting_cancellation_rule_add_label_transaction_code: "交易代码",
      hotel_reservation_rule_setting_cancellation_rule_add_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_cancellation_rule_add_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_cancellation_rule_add_label_inactive: "不活跃",
      hotel_reservation_rule_setting_cancellation_rule_add_label_yes: "是的",
      hotel_reservation_rule_setting_cancellation_rule_add_label_no: "不",
      hotel_reservation_rule_setting_cancellation_rule_add_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_cancellation_rule_add_button_add: "添加",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_type: "* 请选择类型。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_penalty_amount: "* 请输入百分比。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_dayBefore_arrival: "* 请输入付款码。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_transaction_code: "* 请输入入住前的天数。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_cancellation_rule_add_validation_before_time: "* 请输入入住前的时间。",
      hotel_reservation_rule_setting_cancellation_rule_detail_title: "取消规则信息",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_description: "描述",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_type: "类型",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_percent: "百分比",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_nights: "夜晚",
      hotel_reservation_rule_setting_cancellation_rule_detail_select_flat: "扁平率",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_amount: "数量",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_percent: "百分比",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_penalty_nights: "夜晚",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_transactio_code: "交易代码",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_inactive: "不活跃",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_yes: "是的",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_no: "不",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_created_at: "创建于",
      hotel_reservation_rule_setting_cancellation_rule_detail_label_updated_at: "更新于",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_cancel: "取消",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_delete: "删除",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_edit: "编辑",
      hotel_reservation_rule_setting_cancellation_rule_detail_button_ok: "好的",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_type: "* 请选择类型。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_penalty_amount: "* 请输入百分比。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_dayBefore_arrival: "* 请输入付款码。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_transaction_code: "* 请输入入住前的天数。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_cancellation_rule_edit_validation_before_time: "* 请输入入住前的时间。",
      hotel_reservation_rule_setting_no_show_rule_list_title: "缺席规则列表",
      hotel_reservation_rule_setting_no_show_rule_list_select_posting_rule: "发帖规则（全部）",
      hotel_reservation_rule_setting_no_show_rule_list_select_all_nights: "所有夜晚",
      hotel_reservation_rule_setting_no_show_rule_list_select_deposit_only: "仅限存款",
      hotel_reservation_rule_setting_no_show_rule_list_select_first_night: "第一晚",
      hotel_reservation_rule_setting_no_show_rule_list_select_percent: "百分比",
      hotel_reservation_rule_setting_no_show_rule_list_placeholder_search: "搜索...",
      hotel_reservation_rule_setting_no_show_rule_list_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_no_show_rule_list_label_penalty_amount: "罚款金额",
      hotel_reservation_rule_setting_no_show_rule_list_label_source_code: "源代码",
      hotel_reservation_rule_setting_no_show_rule_list_label_guarantee_type: "担保类型",
      hotel_reservation_rule_setting_no_show_rule_list_label_posting_rule: "发帖规则",
      hotel_reservation_rule_setting_no_show_rule_list_label_created_at: "创建于",
      hotel_reservation_rule_setting_no_show_rule_list_button_delete: "删除",
      hotel_reservation_rule_setting_no_show_rule_list_button_add: "添加",
      hotel_reservation_rule_setting_no_show_rule_list_guide_empty: "列表为空",
      hotel_reservation_rule_setting_no_show_rule_add_title: "添加缺席规则",
      hotel_reservation_rule_setting_no_show_rule_add_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_no_show_rule_add_label_description: "描述",
      hotel_reservation_rule_setting_no_show_rule_add_label_source_code: "源代码",
      hotel_reservation_rule_setting_no_show_rule_add_label_guarantee_type: "担保类型",
      hotel_reservation_rule_setting_no_show_rule_add_label_posting_rule: "发帖规则",
      hotel_reservation_rule_setting_no_show_rule_add_select_all_nights: "所有夜晚",
      hotel_reservation_rule_setting_no_show_rule_add_select_deposit_only: "仅限存款",
      hotel_reservation_rule_setting_no_show_rule_add_select_first_night: "第一晚",
      hotel_reservation_rule_setting_no_show_rule_add_select_percent: "百分比",
      hotel_reservation_rule_setting_no_show_rule_add_select_flat: "扁平率",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_amount: "罚款金额",
      hotel_reservation_rule_setting_no_show_rule_add_label_penalty_percent: "处罚百分比",
      hotel_reservation_rule_setting_no_show_rule_add_label_transaction_code: "交易代码",
      hotel_reservation_rule_setting_no_show_rule_add_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_no_show_rule_add_button_add: "添加",
      hotel_reservation_rule_setting_no_show_rule_add_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_no_show_rule_add_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_no_show_rule_add_validation_posting_rule: "* 请选择计费规则。",
      hotel_reservation_rule_setting_no_show_rule_add_validation_penalty_amount: "* 请输入付款码。",
      hotel_reservation_rule_setting_no_show_rule_detail_title: "缺席规则信息",
      hotel_reservation_rule_setting_no_show_rule_detail_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_no_show_rule_detail_label_description: "描述",
      hotel_reservation_rule_setting_no_show_rule_detail_label_source_code: "源代码",
      hotel_reservation_rule_setting_no_show_rule_detail_label_guarantee_type: "担保类型",
      hotel_reservation_rule_setting_no_show_rule_detail_label_posting_rule: "发帖规则",
      hotel_reservation_rule_setting_no_show_rule_detail_select_all_nights: "所有夜晚",
      hotel_reservation_rule_setting_no_show_rule_detail_select_deposit_only: "仅限存款",
      hotel_reservation_rule_setting_no_show_rule_detail_select_first_night: "第一晚",
      hotel_reservation_rule_setting_no_show_rule_detail_select_percent: "百分比",
      hotel_reservation_rule_setting_no_show_rule_detaill_select_flat: "扁平率",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_amount: "罚款金额",
      hotel_reservation_rule_setting_no_show_rule_detail_label_penalty_percent: "处罚百分比",
      hotel_reservation_rule_setting_no_show_rule_detail_label_transactio_code: "交易代码",
      hotel_reservation_rule_setting_no_show_rule_detail_label_created_at: "创建于",
      hotel_reservation_rule_setting_no_show_rule_detail_label_updated_at: "更新于",
      hotel_reservation_rule_setting_no_show_rule_detail_button_go_to_list: "前往列表",
      hotel_reservation_rule_setting_no_show_rule_detail_button_cancel: "取消",
      hotel_reservation_rule_setting_no_show_rule_detail_button_delete: "删除",
      hotel_reservation_rule_setting_no_show_rule_detail_button_edit: "编辑",
      hotel_reservation_rule_setting_no_show_rule_detail_button_ok: "好的",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_rule_name: "* 请输入规则名称。",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_posting_rule: "* 请选择计费规则。",
      hotel_reservation_rule_setting_no_show_rule_edit_validation_penalty_amount: "* 请输入付款码。",
      hotel_reservation_rule_setting_rule_schedule_list_title: "规则时间表列表",
      hotel_reservation_rule_setting_rule_schedule_list_label_active: "积极的",
      hotel_reservation_rule_setting_rule_schedule_list_label_reservation_rule: "预订规则",
      hotel_reservation_rule_setting_rule_schedule_list_label_no: "不。",
      hotel_reservation_rule_setting_rule_schedule_list_label_schedule_name: "时间表名称",
      hotel_reservation_rule_setting_rule_schedule_list_label_rate_code: "费率代码",
      hotel_reservation_rule_setting_rule_schedule_list_label_begin_date: "开始日期",
      hotel_reservation_rule_setting_rule_schedule_list_label_end_date: "结束日期",
      hotel_reservation_rule_setting_rule_schedule_list_label_week: "一周中的天",
      hotel_reservation_rule_setting_rule_schedule_list_label_week_all: "全部",
      hotel_reservation_rule_setting_rule_schedule_list_label_created_at: "创建于",
      hotel_reservation_rule_setting_rule_schedule_list_label_sorting: "排序",
      hotel_reservation_rule_setting_rule_schedule_list_label_deposit_rule: "存款规则",
      hotel_reservation_rule_setting_rule_schedule_list_label_cancellation_rule: "取消规则",
      hotel_reservation_rule_setting_rule_schedule_list_label_no_show_rule: "缺席规则",
      hotel_reservation_rule_setting_rule_schedule_list_label_inactive: "不活跃",
      hotel_reservation_rule_setting_rule_schedule_list_select_rule_type: "规则类型（全部）",
      hotel_reservation_rule_setting_rule_schedule_list_select_reservation_rule: "预订规则",
      hotel_reservation_rule_setting_rule_schedule_list_select_deposit_rule: "存款规则",
      hotel_reservation_rule_setting_rule_schedule_list_select_cancellation_rule: "取消规则",
      hotel_reservation_rule_setting_rule_schedule_list_select_no_show_rule: "缺席规则",
      hotel_reservation_rule_setting_rule_schedule_list_placeholder_search: "搜索...",
      hotel_reservation_rule_setting_rule_schedule_list_label_rule_type: "规则类型",
      hotel_reservation_rule_setting_rule_schedule_list_label_delete: "删除",
      hotel_reservation_rule_setting_rule_schedule_list_label_add: "添加",
      hotel_reservation_rule_setting_rule_schedule_list_guide_empty: "列表为空",
      hotel_reservation_rule_setting_rule_schedule_add_title: "添加规则时间表",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_type: "规则类型",
      hotel_reservation_rule_setting_rule_schedule_add_placeholder_select_box_select: "选择",
      hotel_reservation_rule_setting_rule_schedule_add_select_reservation_rule: "预订规则",
      hotel_reservation_rule_setting_rule_schedule_add_select_deposit_rule: "存款规则",
      hotel_reservation_rule_setting_rule_schedule_add_select_cancellation_rule: "取消规则",
      hotel_reservation_rule_setting_rule_schedule_add_select_no_show_rule: "缺席规则",
      hotel_reservation_rule_setting_rule_schedule_add_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_rule_schedule_add_label_schedule_name: "时间表名称",
      hotel_reservation_rule_setting_rule_schedule_add_label_description: "描述",
      hotel_reservation_rule_setting_rule_schedule_add_label_rate_code: "费率代码",
      hotel_reservation_rule_setting_rule_schedule_add_label_begin_date: "开始日期",
      hotel_reservation_rule_setting_rule_schedule_add_label_end_date: "结束日期",
      hotel_reservation_rule_setting_rule_schedule_add_label_week: "一周中的天",
      hotel_reservation_rule_setting_rule_schedule_add_label_inactive: "不活跃",
      hotel_reservation_rule_setting_rule_schedule_add_label_yes: "是的",
      hotel_reservation_rule_setting_rule_schedule_add_label_no: "不",
      hotel_reservation_rule_setting_rule_schedule_add_label_sequence: "顺序",
      hotel_reservation_rule_setting_rule_schedule_add_label_type: "类型",
      hotel_reservation_rule_setting_rule_schedule_add_label_amount: "数量",
      hotel_reservation_rule_setting_rule_schedule_add_label_percent: "百分比",
      hotel_reservation_rule_setting_rule_schedule_add_label_nights: "夜晚",
      hotel_reservation_rule_setting_rule_schedule_add_label_transaction_code: "交易代码",
      hotel_reservation_rule_setting_rule_schedule_add_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_rule_schedule_add_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_rule_schedule_add_label_source_code: "源代码",
      hotel_reservation_rule_setting_rule_schedule_add_label_guarantee_type: "担保类型",
      hotel_reservation_rule_setting_rule_schedule_add_label_posting_rule: "发帖规则",
      hotel_reservation_rule_setting_rule_schedule_add_label_go_to_list: "前往列表",
      hotel_reservation_rule_setting_rule_schedule_add_label_add: "添加",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_type: "* 请选择规则类型。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rule_name: "* 请选择规则名称。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_schedule_name: "* 请输入日程名称。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_rate_code: "* 请输入费率代码。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_begin_date: "* 请选择开始日期。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_end_date: "* 请选择最终日期。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_week: "* 请选择一天。",
      hotel_reservation_rule_setting_rule_schedule_add_validation_inactive: "* 请选择停用状态。",
      hotel_reservation_rule_setting_rule_schedule_detail_title: "规则表信息",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_type: "规则类型",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rule_name: "规则名称",
      hotel_reservation_rule_setting_rule_schedule_detail_label_schedule_name: "时间表名称",
      hotel_reservation_rule_setting_rule_schedule_detail_label_description: "描述",
      hotel_reservation_rule_setting_rule_schedule_detail_label_rate_code: "费率代码",
      hotel_reservation_rule_setting_rule_schedule_detail_label_begin_date: "开始日期",
      hotel_reservation_rule_setting_rule_schedule_detail_label_end_date: "结束日期",
      hotel_reservation_rule_setting_rule_schedule_detail_label_week: "一周中的天",
      hotel_reservation_rule_setting_rule_schedule_detail_label_inactive: "不活跃",
      hotel_reservation_rule_setting_rule_schedule_detail_label_yes: "是的",
      hotel_reservation_rule_setting_rule_schedule_detail_label_no: "不",
      hotel_reservation_rule_setting_rule_schedule_detail_label_sequence: "顺序",
      hotel_reservation_rule_setting_rule_schedule_detail_label_created_at: "创建于",
      hotel_reservation_rule_setting_rule_schedule_detail_label_updated_at: "更新于",
      hotel_reservation_rule_setting_rule_schedule_detail_label_type: "类型",
      hotel_reservation_rule_setting_rule_schedule_detail_label_amount: "数量",
      hotel_reservation_rule_setting_rule_schedule_detail_label_percent: "百分比",
      hotel_reservation_rule_setting_rule_schedule_detail_label_nights: "夜晚",
      hotel_reservation_rule_setting_rule_schedule_detail_label_transaction_code: "交易代码",
      hotel_reservation_rule_setting_rule_schedule_detail_label_day_before_arrival: "抵达前一天（天）",
      hotel_reservation_rule_setting_rule_schedule_detail_label_before_time: "之前时间（小时：分钟）",
      hotel_reservation_rule_setting_rule_schedule_detail_label_source_code: "源代码",
      hotel_reservation_rule_setting_rule_schedule_detail_label_guarantee_type: "担保类型",
      hotel_reservation_rule_setting_rule_schedule_detail_label_posting_rule: "发帖规则",
      hotel_reservation_rule_setting_rule_schedule_detail_label_go_to_list: "前往列表",
      hotel_reservation_rule_setting_rule_schedule_detail_label_cancel: "取消",
      hotel_reservation_rule_setting_rule_schedule_detail_label_delete: "删除",
      hotel_reservation_rule_setting_rule_schedule_detail_label_edit: "编辑",
      hotel_reservation_rule_setting_rule_schedule_detail_label_ok: "好的",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_type: "* 请选择规则类型。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rule_name: "* 请选择规则名称。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_schedule_name: "* 请输入日程名称。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_description: "* 请输入描述。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_rate_code: "* 请输入费率代码。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_begin_date: "* 请选择开始日期。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_end_date: "* 请选择最终日期。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_week: "* 请选择一天。",
      hotel_reservation_rule_setting_rule_schedule_edit_validation_inactive: "* 请选择停用状态。",
      hotel_inhouse_hotel_info_list_title: "酒店信息菜单",
      hotel_inhouse_hotel_info_list_button_add: "添加",
      hotel_inhouse_hotel_info_list_button_delete: "删除",
      hotel_inhouse_hotel_info_list_button_edit: "编辑",
      hotel_inhouse_hotel_info_list_guide_add: "请添加酒店信息。",
      hotel_inhouse_hotel_info_add_title: "添加酒店信息",
      hotel_inhouse_hotel_info_add_placeholder_title: "请输入标题。",
      hotel_inhouse_hotel_info_add_placeholder_index: "请输入索引。",
      hotel_inhouse_hotel_info_add_select_choose: "请选择类型。",
      hotel_inhouse_hotel_info_add_select_address: "酒店地址",
      hotel_inhouse_hotel_info_add_select_wifi: "无线上网",
      hotel_inhouse_hotel_info_add_select_smoking: "禁烟",
      hotel_inhouse_hotel_info_add_select_transportation: "运输",
      hotel_inhouse_hotel_info_add_select_parking: "停車處",
      hotel_inhouse_hotel_info_add_select_fitness_center: "健身中心",
      hotel_inhouse_hotel_info_add_select_business_center: "商业中心",
      hotel_inhouse_hotel_info_add_select_guest_service: "客户服务",
      hotel_inhouse_hotel_info_add_select_room_service: "客房服务",
      hotel_inhouse_hotel_info_add_select_breakfast: "早餐",
      hotel_inhouse_hotel_info_add_select_dining: "用餐",
      hotel_inhouse_hotel_info_add_select_executive_lounge: "行政酒廊",
      hotel_inhouse_hotel_info_add_toolbar_bold: "大胆的",
      hotel_inhouse_hotel_info_add_toolbar_italic: "斜体",
      hotel_inhouse_hotel_info_add_toolbar_underline: "强调",
      hotel_inhouse_hotel_info_add_toolbar_remove_font_style: "删除字体样式",
      hotel_inhouse_hotel_info_add_toolbar_font_family: "字体系列",
      hotel_inhouse_hotel_info_add_toolbar_font_size: "字体大小",
      hotel_inhouse_hotel_info_add_toolbar_recent_color: "最近的颜色",
      hotel_inhouse_hotel_info_add_toolbar_recent_more_color: "更多颜色",
      hotel_inhouse_hotel_info_add_toolbar_unordered_list: "无序列表",
      hotel_inhouse_hotel_info_add_toolbar_ordered_list: "有序列表",
      hotel_inhouse_hotel_info_add_toolbar_paragraph: "段落",
      hotel_inhouse_hotel_info_add_toolbar_line_height: "线高",
      hotel_inhouse_hotel_info_add_placeholder_content: "请输入内容。",
      hotel_inhouse_hotel_info_add_button_cancel: "取消",
      hotel_inhouse_hotel_info_add_button_ok: "好的",
      hotel_inhouse_hotel_info_edit_title: "编辑酒店信息",
      hotel_inhouse_hotel_info_edit_placeholder_title: "标题",
      hotel_inhouse_hotel_info_edit_select_kr: "韩国",
      hotel_inhouse_hotel_info_edit_select_en: "CN",
      hotel_inhouse_hotel_info_edit_select_cn: "中国",
      hotel_inhouse_hotel_info_edit_select_jp: "J.P",
      hotel_inhouse_hotel_info_edit_placeholder_index: "指数",
      hotel_inhouse_hotel_info_edit_toolbar_bold: "大胆的",
      hotel_inhouse_hotel_info_edit_toolbar_italic: "斜体",
      hotel_inhouse_hotel_info_edit_toolbar_underline: "强调",
      hotel_inhouse_hotel_info_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_inhouse_hotel_info_edit_toolbar_font_family: "字体系列",
      hotel_inhouse_hotel_info_edit_toolbar_font_size: "字体大小",
      hotel_inhouse_hotel_info_edit_toolbar_recent_color: "最近的颜色",
      hotel_inhouse_hotel_info_edit_toolbar_recent_more_color: "更多颜色",
      hotel_inhouse_hotel_info_edit_toolbar_unordered_list: "无序列表",
      hotel_inhouse_hotel_info_edit_toolbar_ordered_list: "有序列表",
      hotel_inhouse_hotel_info_edit_toolbar_paragraph: "段落",
      hotel_inhouse_hotel_info_edit_toolbar_code_view: "代码视图",
      hotel_inhouse_hotel_info_edit_toolbar_line_height: "行高",
      hotel_inhouse_hotel_info_edit_button_append: "附加",
      hotel_inhouse_hotel_info_edit_button_remove: "消除",
      hotel_inhouse_hotel_info_edit_button_cancel: "取消",
      hotel_inhouse_hotel_info_edit_button_ok: "好的",
      hotel_inhouse_room_to_room_list_title: "房间到房间列表",
      hotel_inhouse_room_to_room_list_button_alive: "积极的",
      hotel_inhouse_room_to_room_list_placeholder_select_box_room_to_room: "房间到房间",
      hotel_inhouse_room_to_room_list_select_room_no: "房间号。",
      hotel_inhouse_room_to_room_list_select_email: "电子邮件",
      hotel_inhouse_room_to_room_list_select_user_name: "用户名",
      hotel_inhouse_room_to_room_list_placeholder_search: "搜索...",
      hotel_inhouse_room_to_room_list_label_no: "不。",
      hotel_inhouse_room_to_room_list_label_sender_room_no: "发件人房间号",
      hotel_inhouse_room_to_room_list_label_sender_name: "发件人名称",
      hotel_inhouse_room_to_room_list_label_sender_email: "发件人邮箱",
      hotel_inhouse_room_to_room_list_label_key_type: "钥匙类型",
      hotel_inhouse_room_to_room_list_label_expired_at: "过期时间",
      hotel_inhouse_room_to_room_list_label_receiver_room_no: "接收室号",
      hotel_inhouse_room_to_room_list_label_receiver_email: "收件人邮箱",
      hotel_inhouse_room_to_room_list_label_receiver_status: "接收器状态",
      hotel_inhouse_room_to_room_list_label_date: "日期",
      hotel_inhouse_room_to_room_list_guide_empty: "列表为空",
      hotel_inhouse_room_to_room_detail_title: "各个房间的详细信息",
      hotel_inhouse_room_to_room_detail_label_sender_room_no: "发件人房间号",
      hotel_inhouse_room_to_room_detail_label_sender_name: "发件人名称",
      hotel_inhouse_room_to_room_detail_label_sender_email: "发件人邮箱",
      hotel_inhouse_room_to_room_detail_label_key_type: "房间间钥匙类型",
      hotel_inhouse_room_to_room_detail_label_expired_at: "房间到房间到期时间",
      hotel_inhouse_room_to_room_detail_label_receiver_room_no: "接收室号",
      hotel_inhouse_room_to_room_detail_label_receiver_email: "收件人邮箱",
      hotel_inhouse_room_to_room_detail_label_receiver_status: "接收器状态",
      hotel_inhouse_room_to_room_detail_label_created_at: "创建于",
      hotel_inhouse_room_to_room_detail_button_go_to_list: "前往列表",
      hotel_inhouse_concierge_request_list_title: "礼宾服务请求清单（确认前）",
      hotel_inhouse_concierge_request_list_label_that_date_request: "客人请求数量",
      hotel_inhouse_concierge_request_list_label_that_date_not_confirmed: "未经确认的客人请求",
      hotel_inhouse_concierge_request_list_label_taxi_ex: "出租车快车",
      hotel_inhouse_concierge_request_list_label_taxi: "出租车",
      hotel_inhouse_concierge_request_list_label_parcel_ex: "包裹服务快递",
      hotel_inhouse_concierge_request_list_label_parcel: "包裹服务",
      hotel_inhouse_concierge_request_list_label_luggage_ex: "行李快递服务",
      hotel_inhouse_concierge_request_list_label_luggage: "行李服务",
      hotel_inhouse_concierge_request_list_label_total: "全部的",
      hotel_inhouse_concierge_request_list_select_request_type: "请求类型（全部）",
      hotel_inhouse_concierge_request_list_select_request_type_all: "全部",
      hotel_inhouse_concierge_request_list_select_taxi: "出租车",
      hotel_inhouse_concierge_request_list_select_parcel: "包裹服务",
      hotel_inhouse_concierge_request_list_select_luggage: "行李服务",
      hotel_inhouse_concierge_request_list_select_express: "任务（全部）",
      hotel_inhouse_concierge_request_list_select_express_all: "全部",
      hotel_inhouse_concierge_request_list_select_yes: "表达",
      hotel_inhouse_concierge_request_list_select_no: "普通的",
      hotel_inhouse_concierge_request_list_select_request: "请求（全部）",
      hotel_inhouse_concierge_request_list_select_request_all: "全部",
      hotel_inhouse_concierge_request_list_select_room_no: "房间号。",
      hotel_inhouse_concierge_request_list_select_user_name: "用户名",
      hotel_inhouse_concierge_request_list_placeholder_search: "搜索...",
      hotel_inhouse_concierge_request_list_label_room_no: "房间号。",
      hotel_inhouse_concierge_request_list_label_confirmation_no: "确认号",
      hotel_inhouse_concierge_request_list_label_user_name: "用户名",
      hotel_inhouse_concierge_request_list_label_request_date: "查询日期",
      hotel_inhouse_concierge_request_list_label_request_type: "请求类型",
      hotel_inhouse_concierge_request_list_label_express: "任务",
      hotel_inhouse_concierge_request_list_label_number_of_item: "东西的个数",
      hotel_inhouse_concierge_request_list_label_action_status: "行动状态",
      hotel_inhouse_concierge_request_list_label_date: "日期",
      hotel_inhouse_concierge_request_list_guide_empty: "列表为空",
      hotel_inhouse_concierge_request_detail_title: "索取详情",
      hotel_inhouse_concierge_request_detail_label_room_no: "房间号。",
      hotel_inhouse_concierge_request_detail_label_confirmation_no: "确认号",
      hotel_inhouse_concierge_request_detail_label_user_name: "用户名",
      hotel_inhouse_concierge_request_detail_label_email: "电子邮件",
      hotel_inhouse_concierge_request_detail_label_stay: "停留",
      hotel_inhouse_concierge_request_detail_label_request_type: "请求类型",
      hotel_inhouse_concierge_request_detail_label_status: "地位",
      hotel_inhouse_concierge_request_detail_label_number_of_item: "东西的个数",
      hotel_inhouse_concierge_request_detail_label_request_at: "请求于",
      hotel_inhouse_concierge_request_detail_label_created_at: "创建于",
      hotel_inhouse_concierge_request_detail_label_total: "全部的",
      hotel_inhouse_concierge_request_detail_button_not_available: "无法使用",
      hotel_inhouse_concierge_request_detail_button_myconect: "我的连接",
      hotel_inhouse_concierge_request_detail_button_confirm: "确认",
      hotel_inhouse_concierge_request_detail_button_go_to_list: "前往列表",
      hotel_inhouse_concierge_request_modal_title: "我的连接",
      hotel_inhouse_concierge_request_modal_button_one_time: "一度",
      hotel_inhouse_concierge_request_modal_button_one_hour: "1小时后",
      hotel_inhouse_concierge_request_modal_button_two_hour: "2小时后",
      hotel_inhouse_concierge_request_modal_button_three_hour: "3小时后",
      hotel_inhouse_concierge_request_modal_button_unlimited: "未知",
      hotel_inhouse_concierge_request_modal_button_cancel: "取消",
      hotel_inhouse_concierge_process_list_title: "礼宾部进度表\n（确认后）",
      hotel_inhouse_concierge_process_list_label_request: "客人请求数量",
      hotel_inhouse_concierge_process_list_label_unassigned: "未指定日期",
      hotel_inhouse_concierge_process_list_label_taxi_ex: "出租车快车",
      hotel_inhouse_concierge_process_list_label_taxi: "出租车",
      hotel_inhouse_concierge_process_list_label_parcel_ex: "包裹服务快递",
      hotel_inhouse_concierge_process_list_label_parcel: "包裹服务",
      hotel_inhouse_concierge_process_list_label_luggage_ex: "行李快递服务",
      hotel_inhouse_concierge_process_list_label_luggage: "行李服务",
      hotel_inhouse_concierge_process_list_label_total: "全部的",
      hotel_inhouse_concierge_process_list_select_request_type: "请求类型（全部）",
      hotel_inhouse_concierge_process_list_select_taxi: "出租车",
      hotel_inhouse_concierge_process_list_select_parcel: "包裹服务",
      hotel_inhouse_concierge_process_list_select_luggage: "行李服务",
      hotel_inhouse_concierge_process_list_select_express: "任务（全部）",
      hotel_inhouse_concierge_process_list_select_yes: "表达",
      hotel_inhouse_concierge_process_list_select_no: "普通的",
      hotel_inhouse_concierge_process_list_select_request: "请求（全部）",
      hotel_inhouse_concierge_process_list_select_room_no: "房间号。",
      hotel_inhouse_concierge_process_list_select_user_name: "用户名",
      hotel_inhouse_concierge_process_list_placeholder_search: "搜索...",
      hotel_inhouse_concierge_process_list_label_room_no: "房间号。",
      hotel_inhouse_concierge_process_list_label_confirmation_no: "确认号",
      hotel_inhouse_concierge_process_list_label_user_name: "用户名",
      hotel_inhouse_concierge_process_list_label_request_date: "查询日期",
      hotel_inhouse_concierge_process_list_label_request_type: "请求类型",
      hotel_inhouse_concierge_process_list_label_express: "任务",
      hotel_inhouse_concierge_process_list_label_number_of_item: "东西的个数",
      hotel_inhouse_concierge_process_list_label_action_status: "行动状态",
      hotel_inhouse_concierge_process_list_label_date: "日期",
      hotel_inhouse_concierge_process_list_guide_empty: "列表为空",
      hotel_inhouse_concierge_process_detail_title: "请求（确认后）详细信息",
      hotel_inhouse_concierge_process_detail_label_room_no: "房间号。",
      hotel_inhouse_concierge_process_detail_label_confirmation_no: "确认号",
      hotel_inhouse_concierge_process_detail_label_user_name: "用户名",
      hotel_inhouse_concierge_process_detail_label_email: "电子邮件",
      hotel_inhouse_concierge_process_detail_label_stay: "停留",
      hotel_inhouse_concierge_process_detail_label_request_type: "请求类型",
      hotel_inhouse_concierge_process_detail_label_status: "地位",
      hotel_inhouse_concierge_process_detail_label_number_of_item: "东西的个数",
      hotel_inhouse_concierge_process_detail_label_request_at: "请求于",
      hotel_inhouse_concierge_process_detail_label_created_at: "创建于",
      hotel_inhouse_concierge_process_detail_button_stand_by: "支持",
      hotel_inhouse_concierge_process_detail_button_vehicle_no: "车号",
      hotel_inhouse_concierge_process_detail_button_complete: "完全的",
      hotel_inhouse_concierge_process_detail_button_stop: "停止",
      hotel_inhouse_concierge_process_detail_button_pickup: "捡起",
      hotel_inhouse_concierge_process_detail_button_start: "开始",
      hotel_inhouse_concierge_process_detail_button_deliver: "递送",
      hotel_inhouse_concierge_process_detail_button_change: "改变",
      hotel_inhouse_concierge_process_detail_button_go_to_list: "前往列表",
      hotel_inhouse_concierge_process_modal_title_vehicle_no: "车号",
      hotel_inhouse_concierge_process_modal_placeholder_vehicle_no: "输入车号",
      hotel_inhouse_concierge_process_modal_title_luggage: "行李数量",
      hotel_inhouse_concierge_process_modal_placeholder_luggage: "输入行李数量。",
      hotel_inhouse_concierge_process_modal_title_parcel: "包裹信息",
      hotel_inhouse_concierge_process_modal_button_cancel: "取消",
      hotel_inhouse_concierge_process_modal_button_ok: "好的",
      hotel_inhouse_concierge_process_modal_title_stop_message: "停止消息",
      hotel_inhouse_concierge_process_modal_button_unknown_situation: "状态未知",
      hotel_inhouse_concierge_process_modal_button_no_taxi: "出租车没有出现",
      hotel_inhouse_concierge_process_modal_button_no_guest: "客人未出现",
      hotel_inhouse_concierge_process_modal_button_waiting_30: "等待时间超过30分钟",
      hotel_inhouse_concierge_process_modal_button_waiting_60: "等待时间超过60分钟",
      hotel_inhouse_concierge_process_modal_button_no_motorcycle: "送货摩托车没有出现",
      hotel_inhouse_concierge_process_modal_button_not_luggage: "行李还没准备好",
      hotel_inhouse_concierge_myconect_list_title: "礼宾服务进度表",
      hotel_inhouse_concierge_myconect_list_label_user_count: "按用户数取消",
      hotel_inhouse_concierge_myconect_list_label_hotel_count: "被酒店取消",
      hotel_inhouse_concierge_myconect_list_label_taxi_ex: "出租车快车",
      hotel_inhouse_concierge_myconect_list_label_taxi: "出租车",
      hotel_inhouse_concierge_myconect_list_label_parcel_ex: "包裹服务快递",
      hotel_inhouse_concierge_myconect_list_label_parcel: "包裹服务",
      hotel_inhouse_concierge_myconect_list_label_luggage_ex: "行李快递服务",
      hotel_inhouse_concierge_myconect_list_label_luggage: "行李服务",
      hotel_inhouse_concierge_myconect_list_label_total: "全部的",
      hotel_inhouse_concierge_myconect_list_label_room_no: "房间号。",
      hotel_inhouse_concierge_myconect_list_label_confirmation_no: "确认号",
      hotel_inhouse_concierge_myconect_list_label_user_name: "用户名",
      hotel_inhouse_concierge_myconect_list_label_request_date: "查询日期",
      hotel_inhouse_concierge_myconect_list_label_request_type: "请求类型",
      hotel_inhouse_concierge_myconect_list_label_express: "任务",
      hotel_inhouse_concierge_myconect_list_label_action_status: "行动状态",
      hotel_inhouse_concierge_myconect_list_label_myconect_at: "MyCONECT 在",
      hotel_inhouse_concierge_myconect_list_label_completed_at: "完成于",
      hotel_inhouse_concierge_myconect_list_label_date: "日期",
      hotel_inhouse_concierge_myconect_list_guide_empty: "列表为空",
      hotel_inhouse_concierge_myconect_detail_title: "安排进度详情",
      hotel_inhouse_concierge_myconect_detail_label_room_no: "房间号。",
      hotel_inhouse_concierge_myconect_detail_label_confirmation_no: "确认号",
      hotel_inhouse_concierge_myconect_detail_label_user_name: "用户名",
      hotel_inhouse_concierge_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_concierge_myconect_detail_label_stay: "停留",
      hotel_inhouse_concierge_myconect_detail_label_request_type: "请求类型",
      hotel_inhouse_concierge_myconect_detail_label_status: "地位",
      hotel_inhouse_concierge_myconect_detail_label_number_of_item: "东西的个数",
      hotel_inhouse_concierge_myconect_detail_label_request_at: "请求于",
      hotel_inhouse_concierge_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_concierge_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_concierge_multi_myconect_list_title: "礼宾服务时间表设置列表",
      hotel_inhouse_concierge_multi_myconect_list_button_alive: "积极的",
      hotel_inhouse_concierge_multi_myconect_list_label_staff_number: "员工人数",
      hotel_inhouse_concierge_multi_myconect_list_label_request: "要求",
      hotel_inhouse_concierge_multi_myconect_list_label_department: "部门",
      hotel_inhouse_concierge_multi_myconect_list_label_email: "电子邮件",
      hotel_inhouse_concierge_multi_myconect_list_label_respond: "MyCONECT 响应",
      hotel_inhouse_concierge_multi_myconect_list_label_from: "从",
      hotel_inhouse_concierge_multi_myconect_list_label_to: "到",
      hotel_inhouse_concierge_multi_myconect_list_label_date: "日期",
      hotel_inhouse_concierge_multi_myconect_list_button_add: "添加",
      hotel_inhouse_concierge_multi_myconect_add_title: "添加礼宾时间表设置",
      hotel_inhouse_concierge_multi_myconect_add_label_request_type: "请求类型",
      hotel_inhouse_concierge_multi_myconect_add_placeholder_select_box_choose: "选择",
      hotel_inhouse_concierge_multi_myconect_add_select_box_luggage: "行李服务",
      hotel_inhouse_concierge_multi_myconect_add_select_box_parcel: "包裹服务",
      hotel_inhouse_concierge_multi_myconect_add_select_box_taxi: "出租车",
      hotel_inhouse_concierge_multi_myconect_add_label_date: "日期",
      hotel_inhouse_concierge_multi_myconect_add_label_respond: "MyCONECT 响应",
      hotel_inhouse_concierge_multi_myconect_add_button_go_to_list: "前往列表",
      hotel_inhouse_concierge_multi_myconect_add_button_add: "添加",
      hotel_inhouse_concierge_multi_myconect_add_validation_type_request: "* 请选择请求类型。",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_start: "* 请选择日期。",
      hotel_inhouse_concierge_multi_myconect_add_validation_period_end: "* 请选择日期。",
      hotel_inhouse_concierge_multi_myconect_add_validation_respond: "* 请选择 MyCONECT 响应。",
      hotel_inhouse_concierge_multi_myconect_detail_title: "礼宾服务时间表设置信息",
      hotel_inhouse_concierge_multi_myconect_detail_label_update: "更新日期",
      hotel_inhouse_concierge_multi_myconect_detail_label_date: "日期",
      hotel_inhouse_concierge_multi_myconect_detail_label_respond: "MyCONECT 响应",
      hotel_inhouse_concierge_multi_myconect_detail_label_staff_number: "员工人数",
      hotel_inhouse_concierge_multi_myconect_detail_label_request: "要求",
      hotel_inhouse_concierge_multi_myconect_detail_label_department: "部门",
      hotel_inhouse_concierge_multi_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_concierge_multi_myconect_detail_label_released_at: "发布于",
      hotel_inhouse_concierge_multi_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_concierge_multi_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_concierge_multi_myconect_detail_button_cancel: "取消",
      hotel_inhouse_concierge_multi_myconect_detail_button_release: "发布",
      hotel_inhouse_concierge_multi_myconect_detail_button_edit: "编辑",
      hotel_inhouse_concierge_multi_myconect_detail_button_ok: "好的",
      hotel_inhouse_housekeeping_request_list_title: "客房清洁请求清单\n（确认前）",
      hotel_inhouse_housekeeping_request_list_label_request: "客人请求数量",
      hotel_inhouse_housekeeping_request_list_label_not_confirmed: "未经确认的客人请求",
      hotel_inhouse_housekeeping_request_list_label_mur: "让出一点空间",
      hotel_inhouse_housekeeping_request_list_label_tds: "夜床服务",
      hotel_inhouse_housekeeping_request_list_label_green: "绿色运动",
      hotel_inhouse_housekeeping_request_list_label_ironing_ex: "熨烫服务特快专递",
      hotel_inhouse_housekeeping_request_list_label_ironing: "熨烫服务",
      hotel_inhouse_housekeeping_request_list_label_laundry_ex: "洗衣服务快捷",
      hotel_inhouse_housekeeping_request_list_label_laundry: "洗衣服务",
      hotel_inhouse_housekeeping_request_list_label_amenity: "便利设施服务",
      hotel_inhouse_housekeeping_request_list_label_total: "全部的",
      hotel_inhouse_housekeeping_request_list_select_request_type: "请求类型（全部）",
      hotel_inhouse_housekeeping_request_list_select_mur: "让出一点空间",
      hotel_inhouse_housekeeping_request_list_select_tds: "夜床服务",
      hotel_inhouse_housekeeping_request_list_select_green: "绿色运动",
      hotel_inhouse_housekeeping_request_list_select_ironing: "熨烫服务",
      hotel_inhouse_housekeeping_request_list_select_laundry: "洗衣服务",
      hotel_inhouse_housekeeping_request_list_select_amenity: "便利设施服务",
      hotel_inhouse_housekeeping_request_list_select_request_express: "任务（全部）",
      hotel_inhouse_housekeeping_request_list_select_yes: "表达",
      hotel_inhouse_housekeeping_request_list_select_no: "普通的",
      hotel_inhouse_housekeeping_request_list_select_request: "请求（全部）",
      hotel_inhouse_housekeeping_request_list_select_room_no: "房间号。",
      hotel_inhouse_housekeeping_request_list_select_user_name: "用户名",
      hotel_inhouse_housekeeping_request_list_placeholder_search: "搜索...",
      hotel_inhouse_housekeeping_request_list_label_roon_no: "房间号。",
      hotel_inhouse_housekeeping_request_list_label_confirmation_no: "确认号",
      hotel_inhouse_housekeeping_request_list_label_user_name: "用户名",
      hotel_inhouse_housekeeping_request_list_label_request_date: "查询日期",
      hotel_inhouse_housekeeping_request_list_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_request_list_label_express: "任务",
      hotel_inhouse_housekeeping_request_list_label_action_status: "行动状态",
      hotel_inhouse_housekeeping_request_list_label_date: "日期",
      hotel_inhouse_housekeeping_request_list_guide_empty: "列表为空",
      hotel_inhouse_housekeeping_request_detail_title: "索取详情",
      hotel_inhouse_housekeeping_request_detail_label_roon_no: "房间号。",
      hotel_inhouse_housekeeping_request_detail_label_confirmation_no: "确认号",
      hotel_inhouse_housekeeping_request_detail_label_user_name: "用户名",
      hotel_inhouse_housekeeping_request_detail_label_email: "电子邮件",
      hotel_inhouse_housekeeping_request_detail_label_stay: "停留",
      hotel_inhouse_housekeeping_request_detail_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_request_detail_label_status: "地位",
      hotel_inhouse_housekeeping_request_detail_label_request_at: "请求于",
      hotel_inhouse_housekeeping_request_detail_label_created_at: "创建于",
      hotel_inhouse_housekeeping_request_detail_label_total: "全部的",
      hotel_inhouse_housekeeping_request_detail_button_not_availble: "无法使用",
      hotel_inhouse_housekeeping_request_detail_button_myconect: "我的连接",
      hotel_inhouse_housekeeping_request_detail_button_auto: "汽车",
      hotel_inhouse_housekeeping_request_detail_button_delay: "延迟",
      hotel_inhouse_housekeeping_request_detail_button_confirm: "确认",
      hotel_inhouse_housekeeping_request_detail_button_go_to_list: "前往列表",
      hotel_inhouse_housekeeping_request_modal_title_delay: "推迟消息",
      hotel_inhouse_housekeeping_request_modal_button_delay_30: "延迟30分钟",
      hotel_inhouse_housekeeping_request_modal_button_delay_60: "延误60分钟",
      hotel_inhouse_housekeeping_request_modal_button_as_soon_as: "尽快可用",
      hotel_inhouse_housekeeping_request_modal_title_myconect: "我的连接",
      hotel_inhouse_housekeeping_request_modal_button_one_time: "一度",
      hotel_inhouse_housekeeping_request_modal_button_one_hour: "1小时后",
      hotel_inhouse_housekeeping_request_modal_button_two_hour: "2小时后",
      hotel_inhouse_housekeeping_request_modal_button_three_hour: "3小时后",
      hotel_inhouse_housekeeping_request_modal_button_unlimited: "未知",
      hotel_inhouse_housekeeping_request_modal_button_cancel: "取消",
      hotel_inhouse_housekeeping_process_list_title: "客房清洁进度清单（已确认）",
      hotel_inhouse_housekeeping_process_list_label_request: "客人请求数量",
      hotel_inhouse_housekeeping_process_list_label_unassigned: "未指定日期",
      hotel_inhouse_housekeeping_process_list_label_mur: "让出一点空间",
      hotel_inhouse_housekeeping_process_list_label_tds: "夜床服务",
      hotel_inhouse_housekeeping_process_list_label_housekeeping_total: "全部的",
      hotel_inhouse_housekeeping_process_list_label_ironing_ex: "熨烫服务特快专递",
      hotel_inhouse_housekeeping_process_list_label_ironing: "熨烫服务",
      hotel_inhouse_housekeeping_process_list_label_laundry_ex: "洗衣服务快捷",
      hotel_inhouse_housekeeping_process_list_label_laundry: "洗衣服务",
      hotel_inhouse_housekeeping_process_list_label_guest_service_total: "全部的",
      hotel_inhouse_housekeeping_process_list_label_green: "绿色运动",
      hotel_inhouse_housekeeping_process_list_label_dnd: "免打扰",
      hotel_inhouse_housekeeping_process_list_label_amenity_number: "便利设施 物品数量",
      hotel_inhouse_housekeeping_process_list_label_bath_towel: "浴巾",
      hotel_inhouse_housekeeping_process_list_label_face_towel: "洗脸毛巾",
      hotel_inhouse_housekeeping_process_list_label_hand_towel: "手巾",
      hotel_inhouse_housekeeping_process_list_label_bath_gown: "浴衣",
      hotel_inhouse_housekeeping_process_list_label_razor: "剃刀",
      hotel_inhouse_housekeeping_process_list_label_shampoo: "洗发水",
      hotel_inhouse_housekeeping_process_list_label_conditioner: "护发素",
      hotel_inhouse_housekeeping_process_list_label_soap: "肥皂",
      hotel_inhouse_housekeeping_process_list_label_amenity_total: "全部的",
      hotel_inhouse_housekeeping_process_list_select_request_type: "请求类型（全部）",
      hotel_inhouse_housekeeping_process_list_select_mur: "让出一点空间",
      hotel_inhouse_housekeeping_process_list_select_tds: "夜床服务",
      hotel_inhouse_housekeeping_process_list_select_green: "绿色运动",
      hotel_inhouse_housekeeping_process_list_select_dnd: "免打扰",
      hotel_inhouse_housekeeping_process_list_select_ironing: "熨烫服务",
      hotel_inhouse_housekeeping_process_list_select_laundry: "洗衣服务",
      hotel_inhouse_housekeeping_process_list_select_amenity: "便利设施服务",
      hotel_inhouse_housekeeping_process_list_select_express: "任务（全部）",
      hotel_inhouse_housekeeping_process_list_select_express_all: "全部",
      hotel_inhouse_housekeeping_process_list_select_yes: "表达",
      hotel_inhouse_housekeeping_process_list_select_no: "普通的",
      hotel_inhouse_housekeeping_process_list_select_floor: "楼层（全部）",
      hotel_inhouse_housekeeping_process_list_select_floor_1: "1 楼",
      hotel_inhouse_housekeeping_process_list_select_floor_2: "2楼",
      hotel_inhouse_housekeeping_process_list_select_floor_3: "3楼",
      hotel_inhouse_housekeeping_process_list_select_floor_4: "4楼",
      hotel_inhouse_housekeeping_process_list_select_floor_5: "5楼",
      hotel_inhouse_housekeeping_process_list_select_floor_6: "6楼",
      hotel_inhouse_housekeeping_process_list_select_floor_7: "7楼",
      hotel_inhouse_housekeeping_process_list_select_floor_8: "8楼",
      hotel_inhouse_housekeeping_process_list_select_request: "请求（全部）",
      hotel_inhouse_housekeeping_process_list_select_room_no: "房间号。",
      hotel_inhouse_housekeeping_process_list_placeholder_search: "搜索...",
      hotel_inhouse_housekeeping_process_list_label_roon_no: "房间号。",
      hotel_inhouse_housekeeping_process_list_label_confirmation_no: "确认号",
      hotel_inhouse_housekeeping_process_list_label_user_name: "用户名",
      hotel_inhouse_housekeeping_process_list_label_request_date: "查询日期",
      hotel_inhouse_housekeeping_process_list_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_process_list_label_express: "任务",
      hotel_inhouse_housekeeping_process_list_label_action_status: "行动状态",
      hotel_inhouse_housekeeping_process_list_label_date: "日期",
      hotel_inhouse_housekeeping_process_list_guide_empty: "列表为空",
      hotel_inhouse_housekeeping_process_detail_title: "索取详情",
      hotel_inhouse_housekeeping_process_detail_label_roon_no: "房间号。",
      hotel_inhouse_housekeeping_process_detail_label_confirmation_no: "确认号",
      hotel_inhouse_housekeeping_process_detail_label_user_name: "用户名",
      hotel_inhouse_housekeeping_process_detail_label_email: "电子邮件",
      hotel_inhouse_housekeeping_process_detail_label_stay: "停留",
      hotel_inhouse_housekeeping_process_detail_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_process_detail_label_status: "地位",
      hotel_inhouse_housekeeping_process_detail_label_request_at: "请求于",
      hotel_inhouse_housekeeping_process_detail_label_created_at: "创建于",
      hotel_inhouse_housekeeping_process_detail_button_put_off: "推迟",
      hotel_inhouse_housekeeping_process_detail_button_complete: "完全的",
      hotel_inhouse_housekeeping_process_detail_button_start: "开始",
      hotel_inhouse_housekeeping_process_detail_button_stop: "停止",
      hotel_inhouse_housekeeping_process_detail_button_deliver: "递送",
      hotel_inhouse_housekeeping_process_detail_button_pickup: "捡起",
      hotel_inhouse_housekeeping_process_detail_button_go_to_list: "前往列表",
      hotel_inhouse_housekeeping_process_modal_title_put_off: "推迟消息",
      hotel_inhouse_housekeeping_process_modal_button_cancel: "取消",
      hotel_inhouse_housekeeping_process_modal_button_delay_30: "延迟30分钟",
      hotel_inhouse_housekeeping_process_modal_button_delay_60: "延误60分钟",
      hotel_inhouse_housekeeping_process_modal_button_as_soon_as: "尽快可用",
      hotel_inhouse_housekeeping_process_modal_button_out_of_order: "故障",
      hotel_inhouse_housekeeping_process_modal_title_stop_message: "停止消息",
      hotel_inhouse_housekeeping_process_modal_button_unknown_situation: "状态未知",
      hotel_inhouse_housekeeping_process_modal_button_cancel_service: "取消服务",
      hotel_inhouse_housekeeping_process_modal_button_not_found_it: "找不到",
      hotel_inhouse_housekeeping_process_modal_button_no_guest_room: "房间内没有客人",
      hotel_inhouse_housekeeping_process_modal_button_change_items: "更改项目",
      hotel_inhouse_housekeeping_myconect_list_title: "客房清洁进度表",
      hotel_inhouse_housekeeping_myconect_list_label_user_count: "按用户数取消",
      hotel_inhouse_housekeeping_myconect_list_label_hotel_count: "被 Hotel Count 取消",
      hotel_inhouse_housekeeping_myconect_list_label_mur: "让出一点空间",
      hotel_inhouse_housekeeping_myconect_list_label_tds: "夜床服务",
      hotel_inhouse_housekeeping_myconect_list_label_ironing_ex: "熨烫服务特快专递",
      hotel_inhouse_housekeeping_myconect_list_label_ironing: "熨烫服务",
      hotel_inhouse_housekeeping_myconect_list_label_laundry_ex: "洗衣服务快捷",
      hotel_inhouse_housekeeping_myconect_list_label_laundry: "洗衣服务",
      hotel_inhouse_housekeeping_myconect_list_label_total: "全部的",
      hotel_inhouse_housekeeping_myconect_list_label_roon_no: "房间号。",
      hotel_inhouse_housekeeping_myconect_list_label_confirmation_no: "确认号",
      hotel_inhouse_housekeeping_myconect_list_label_user_name: "用户名",
      hotel_inhouse_housekeeping_myconect_list_label_request_date: "查询日期",
      hotel_inhouse_housekeeping_myconect_list_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_myconect_list_label_express: "任务",
      hotel_inhouse_housekeeping_myconect_list_label_action_status: "行动状态",
      hotel_inhouse_housekeeping_myconect_list_label_myconect_at: "MyCONECT 在",
      hotel_inhouse_housekeeping_myconect_list_label_completed_at: "完成于",
      hotel_inhouse_housekeeping_myconect_list_label_date: "日期",
      hotel_inhouse_housekeeping_myconect_list_guide_empty: "列表为空",
      hotel_inhouse_housekeeping_myconect_detail_title: "安排流程细节",
      hotel_inhouse_housekeeping_myconect_detail_label_roon_no: "房间号。",
      hotel_inhouse_housekeeping_myconect_detail_label_confirmation_no: "确认号",
      hotel_inhouse_housekeeping_myconect_detail_label_user_name: "用户名",
      hotel_inhouse_housekeeping_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_housekeeping_myconect_detail_label_stay: "停留",
      hotel_inhouse_housekeeping_myconect_detail_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_myconect_detail_label_status: "地位",
      hotel_inhouse_housekeeping_myconect_detail_label_request_at: "请求于",
      hotel_inhouse_housekeeping_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_housekeeping_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_housekeeping_multi_myconect_list_title: "客房清洁时间表",
      hotel_inhouse_housekeeping_multi_myconect_list_button_alive: "积极的",
      hotel_inhouse_housekeeping_multi_myconect_list_label_staff_number: "员工人数",
      hotel_inhouse_housekeeping_multi_myconect_list_label_request: "要求",
      hotel_inhouse_housekeeping_multi_myconect_list_label_department: "部门",
      hotel_inhouse_housekeeping_multi_myconect_list_label_email: "电子邮件",
      hotel_inhouse_housekeeping_multi_myconect_list_label_respond: "MyCONECT 响应",
      hotel_inhouse_housekeeping_multi_myconect_list_label_from: "从",
      hotel_inhouse_housekeeping_multi_myconect_list_label_to: "到",
      hotel_inhouse_housekeeping_multi_myconect_list_label_date: "日期",
      hotel_inhouse_housekeeping_multi_myconect_list_button_add: "添加",
      hotel_inhouse_housekeeping_multi_myconect_add_title: "添加客房清洁时间表",
      hotel_inhouse_housekeeping_multi_myconect_add_label_request_type: "请求类型",
      hotel_inhouse_housekeeping_multi_myconect_add_placeholder_select_box_choose: "选择",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_ironing: "熨烫服务",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_laundry: "洗衣服务",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_amenity: "便利设施服务",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_make_up_room: "让出一点空间",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_turn_down_service: "夜床服务",
      hotel_inhouse_housekeeping_multi_myconect_add_select_box_green_campaign: "绿色运动",
      hotel_inhouse_housekeeping_multi_myconect_add_label_date: "日期",
      hotel_inhouse_housekeeping_multi_myconect_add_label_respond: "MyCONECT 响应",
      hotel_inhouse_housekeeping_multi_myconect_add_select_not_availble: "无法使用",
      hotel_inhouse_housekeeping_multi_myconect_add_select_confirm: "确认",
      hotel_inhouse_housekeeping_multi_myconect_add_button_go_to_list: "前往列表",
      hotel_inhouse_housekeeping_multi_myconect_add_button_add: "添加",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_type_request: "* 请选择请求类型。",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_start: "* 请选择日期。",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_period_end: "* 请选择日期。",
      hotel_inhouse_housekeeping_multi_myconect_add_validation_respond: "* 请选择 MyCONECT 响应。",
      hotel_inhouse_housekeeping_multi_myconect_add_button_set: "放",
      hotel_inhouse_housekeeping_multi_myconect_detail_title: "客房清洁时间表信息",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_update: "更新日期",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_date: "日期",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_respond: "MyCONECT 响应",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_staff_number: "员工人数",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_request: "要求",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_department: "部门",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_released_at: "发布于",
      hotel_inhouse_housekeeping_multi_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_cancel: "取消",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_release: "发布",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_edit: "编辑",
      hotel_inhouse_housekeeping_multi_myconect_detail_button_ok: "好的",
      hotel_inhouse_valet_list_title: "代客停车申请表",
      hotel_inhouse_valet_list_label_valet_requst: "代客泊车请求",
      hotel_inhouse_valet_list_label_requesting: "要求",
      hotel_inhouse_valet_list_label_parking: "停車處",
      hotel_inhouse_valet_list_label_waiting: "等待",
      hotel_inhouse_valet_list_label_preparing: "准备中",
      hotel_inhouse_valet_list_label_standby: "支持",
      hotel_inhouse_valet_list_label_return: "返回",
      hotel_inhouse_valet_list_label_total: "全部的",
      hotel_inhouse_valet_list_placeholder_select_box_request: "请求（全部）",
      hotel_inhouse_valet_list_select_request_all: "全部",
      hotel_inhouse_valet_list_select_vehicle_no: "车号",
      hotel_inhouse_valet_list_select_room_no: "房间号。",
      hotel_inhouse_valet_list_select_user_name: "用户名",
      hotel_inhouse_valet_list_placeholder_search: "搜索...",
      hotel_inhouse_valet_list_label_confirmation_no: "确认号",
      hotel_inhouse_valet_list_label_room_no: "房间号。",
      hotel_inhouse_valet_list_label_user_name: "用户名",
      hotel_inhouse_valet_list_label_request_date: "查询日期",
      hotel_inhouse_valet_list_label_location_no: "地点编号",
      hotel_inhouse_valet_list_label_vehicle_no: "车号",
      hotel_inhouse_valet_list_label_action_status: "行动状态",
      hotel_inhouse_valet_list_label_date: "日期",
      hotel_inhouse_valet_detail_title: "代客泊车请求详情",
      hotel_inhouse_valet_detail_label_confirmation_no: "确认号",
      hotel_inhouse_valet_detail_label_room_no: "房间号。",
      hotel_inhouse_valet_detail_label_user_name: "用户名",
      hotel_inhouse_valet_detail_label_email: "电子邮件",
      hotel_inhouse_valet_detail_label_request_type: "请求类型",
      hotel_inhouse_valet_detail_label_location_no: "地点编号",
      hotel_inhouse_valet_detail_label_vehicle_no: "车号",
      hotel_inhouse_valet_detail_label_status: "地位",
      hotel_inhouse_valet_detail_label_request_at: "请求于",
      hotel_inhouse_valet_detail_label_created_at: "创建于",
      hotel_inhouse_valet_detail_button_valet_no: "代客泊车号",
      hotel_inhouse_valet_detail_button_change: "改变",
      hotel_inhouse_valet_detail_button_myconect: "我的连接",
      hotel_inhouse_valet_detail_button_stop: "停止",
      hotel_inhouse_valet_detail_button_location: "地点",
      hotel_inhouse_valet_detail_button_standby: "支持",
      hotel_inhouse_valet_detail_button_handover: "交出",
      hotel_inhouse_valet_detail_button_return: "返回",
      hotel_inhouse_valet_detail_button_settlement: "沉降",
      hotel_inhouse_valet_detail_button_room_posting: "房间张贴",
      hotel_inhouse_valet_detail_button_go_to_list: "前往列表",
      hotel_inhouse_valet_detail_button_go_to_detail: "前往详情",
      hotel_inhouse_valet_detail_button_payment: "支付",
      hotel_inhouse_valet_detail_business_list_label_no: "不。",
      hotel_inhouse_valet_detail_business_list_label_type: "类型",
      hotel_inhouse_valet_detail_business_list_label_task: "任务",
      hotel_inhouse_valet_detail_business_list_label_time_from: "可用时间从",
      hotel_inhouse_valet_detail_business_list_label_time_to: "可用时间至",
      hotel_inhouse_valet_detail_business_list_label_charge_amount: "收费金额",
      hotel_inhouse_valet_detail_business_list_label_currency: "货币",
      hotel_inhouse_valet_detail_placeholder_select_box_choose: "选择",
      hotel_inhouse_valet_detail_modal_valet_title: "代客接受号码",
      hotel_inhouse_valet_detail_modal_valet_request_received: "您的请求已收到。",
      hotel_inhouse_valet_detail_modal_change_title: "变更车号",
      hotel_inhouse_valet_detail_modal_change_placeholder_vehicle_no: "车号",
      hotel_inhouse_valet_detail_modal_myconect_title: "地点编号",
      hotel_inhouse_valet_detail_modal_myconect_placeholder_parking_area_no: "停车位号",
      hotel_inhouse_valet_detail_modal_stop_title: "停止消息",
      hotel_inhouse_valet_detail_modal_stop_message_unparked: "取消停车并取消",
      hotel_inhouse_valet_detail_modal_stop_message_parked: "停车并取消",
      hotel_inhouse_valet_detail_modal_stop_message_unknown: "情况不明",
      hotel_inhouse_valet_detail_modal_valet_location_title: "停车地点编号",
      hotel_inhouse_valet_detail_modal_valet_location_message: "开始取车。",
      hotel_inhouse_valet_detail_modal_valet_standby_title: "支持",
      hotel_inhouse_valet_detail_modal_valet_standby_message: "继续让车辆待命。",
      hotel_inhouse_valet_detail_modal_valet_handover_title: "交出",
      hotel_inhouse_valet_detail_modal_valet_handover_message: "继续将车辆交付给司机。",
      hotel_inhouse_valet_detail_modal_valet_return_title: "返回",
      hotel_inhouse_valet_detail_modal_valet_return_message: "开始还车。",
      hotel_inhouse_valet_detail_modal_return_placeholder_parking_area_no: "停车位号",
      hotel_inhouse_valet_detail_modal_room_number: "房间号",
      hotel_inhouse_valet_detail_modal_button_cancel: "取消",
      hotel_inhouse_valet_detail_modal_button_ok: "好的",
      hotel_inhouse_valet_multi_myconect_list_title: "代客泊车时间表设置列表",
      hotel_inhouse_valet_multi_myconect_list_button_alive: "积极的",
      hotel_inhouse_valet_multi_myconect_list_label_staff_number: "员工人数",
      hotel_inhouse_valet_multi_myconect_list_label_request: "要求",
      hotel_inhouse_valet_multi_myconect_list_label_department: "部门",
      hotel_inhouse_valet_multi_myconect_list_label_email: "电子邮件",
      hotel_inhouse_valet_multi_myconect_list_label_respond: "MyCONECT 响应",
      hotel_inhouse_valet_multi_myconect_list_label_from: "从",
      hotel_inhouse_valet_multi_myconect_list_label_to: "到",
      hotel_inhouse_valet_multi_myconect_list_label_date: "日期",
      hotel_inhouse_valet_multi_myconect_list_button_add: "添加",
      hotel_inhouse_valet_myconect_list_guide_empty: "列表为空",
      hotel_inhouse_valet_multi_myconect_add_title: "添加代客时间表设置",
      hotel_inhouse_valet_multi_myconect_add_label_request_type: "请求类型",
      hotel_inhouse_valet_multi_myconect_add_label_repond: "回应",
      hotel_inhouse_valet_multi_myconect_add_label_delay_time: "延迟时间",
      hotel_inhouse_valet_multi_myconect_add_placeholder_select_box_choose: "选择",
      hotel_inhouse_valet_multi_myconect_add_select_box_not_available: "无法使用",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay15: "延误15分钟",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay30: "延误30分钟",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay45: "延误 45 分钟",
      hotel_inhouse_valet_multi_myconect_add_select_box_delay60: "延误60分钟",
      hotel_inhouse_valet_multi_myconect_add_select_box_asaa: "尽快可用",
      hotel_inhouse_valet_multi_myconect_add_select_box_valet: "代客泊车",
      hotel_inhouse_valet_multi_myconect_add_select_box_waiting_request: "待机请求",
      hotel_inhouse_valet_multi_myconect_add_label_date: "日期",
      hotel_inhouse_valet_multi_myconect_add_validation_type_request: "* Please Select the Request Type.",
      hotel_inhouse_valet_multi_myconect_add_validation_repond: "* Please Select the Respond.",
      hotel_inhouse_valet_multi_myconect_add_validation_delay: "* Please Select the Delay Time.",
      hotel_inhouse_valet_multi_myconect_add_button_go_to_list: "前往列表",
      hotel_inhouse_valet_multi_myconect_add_button_add: "添加",
      hotel_inhouse_valet_multi_myconect_detail_title: "代客泊车时间表设置信息",
      hotel_inhouse_valet_multi_myconect_detail_label_update: "更新日期",
      hotel_inhouse_valet_multi_myconect_detail_label_date: "日期",
      hotel_inhouse_valet_multi_myconect_detail_label_delay_time: "延迟时间",
      hotel_inhouse_valet_multi_myconect_detail_placeholder_select_box_choose: "选择",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay15: "延误15分钟",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay30: "延误30分钟",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay45: "延误 45 分钟",
      hotel_inhouse_valet_multi_myconect_detail_select_box_delay60: "延误60分钟",
      hotel_inhouse_valet_multi_myconect_detail_select_box_asaa: "尽快可用",
      hotel_inhouse_valet_multi_myconect_detail_label_respond: "MyCONECT 回复",
      hotel_inhouse_valet_multi_myconect_detail_label_staff_number: "员工人数",
      hotel_inhouse_valet_multi_myconect_detail_label_request: "要求",
      hotel_inhouse_valet_multi_myconect_detail_label_department: "部门",
      hotel_inhouse_valet_multi_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_valet_multi_myconect_detail_label_released_at: "发布于",
      hotel_inhouse_valet_multi_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_valet_multi_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_valet_multi_myconect_detail_button_cancel: "取消",
      hotel_inhouse_valet_multi_myconect_detail_button_release: "发布",
      hotel_inhouse_valet_multi_myconect_detail_button_edit: "编辑",
      hotel_inhouse_valet_multi_myconect_detail_button_ok: "好的",
      hotel_inhouse_in_room_dining_cart_list_title: "客房内餐车清单",
      hotel_inhouse_in_room_dining_cart_list_placeholder_select_box_select: "选择",
      hotel_inhouse_in_room_dining_cart_list_select_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_cart_list_placeholder_search: "搜索...",
      hotel_inhouse_in_room_dining_cart_list_label_no: "不。",
      hotel_inhouse_in_room_dining_cart_list_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_cart_list_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_cart_list_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_cart_list_label_update_date: "更新日期",
      hotel_inhouse_in_room_dining_cart_list_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_cart_detail_title: "客房内餐车详情",
      hotel_inhouse_in_room_dining_cart_detail_label_info: "信息",
      hotel_inhouse_in_room_dining_cart_detail_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_cart_detail_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_cart_detail_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_cart_detail_label_updated_at: "更新日期",
      hotel_inhouse_in_room_dining_cart_detail_label_payment: "支付",
      hotel_inhouse_in_room_dining_cart_detail_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_cart_detail_label_discount_rate: "折扣率",
      hotel_inhouse_in_room_dining_cart_detail_label_price: "价格",
      hotel_inhouse_in_room_dining_cart_detail_label_order: "命令",
      hotel_inhouse_in_room_dining_cart_detail_label_no: "不。",
      hotel_inhouse_in_room_dining_cart_detail_label_category: "类别",
      hotel_inhouse_in_room_dining_cart_detail_label_menu: "菜单",
      hotel_inhouse_in_room_dining_cart_detail_label_option: "选项",
      hotel_inhouse_in_room_dining_cart_detail_label_items: "项目",
      hotel_inhouse_in_room_dining_cart_detail_label_quantity: "数量",
      hotel_inhouse_in_room_dining_cart_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_cart_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_category_list_title: "客房内用餐类别列表",
      hotel_inhouse_in_room_dining_category_list_label_all: "显示菜单（全部）",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_yes: "显示菜单（是）",
      hotel_inhouse_in_room_dining_category_list_label_exhibition_no: "显示菜单（无）",
      hotel_inhouse_in_room_dining_category_list_placeholder_search: "搜索...",
      hotel_inhouse_in_room_dining_category_list_label_no: "不。",
      hotel_inhouse_in_room_dining_category_list_label_name: "姓名",
      hotel_inhouse_in_room_dining_category_list_label_date: "日期",
      hotel_inhouse_in_room_dining_category_list_label_sorting: "排序",
      hotel_inhouse_in_room_dining_category_list_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_category_list_button_delete: "删除",
      hotel_inhouse_in_room_dining_category_list_button_add: "添加",
      hotel_inhouse_in_room_dining_category_add_title: "客房内用餐类别",
      hotel_inhouse_in_room_dining_category_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_category_add_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_category_add_select_yes: "是的",
      hotel_inhouse_in_room_dining_category_add_select_no: "不",
      hotel_inhouse_in_room_dining_category_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_category_add_button_add: "添加",
      hotel_inhouse_in_room_dining_category_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_category_detail_title: "客房内用餐类别详情",
      hotel_inhouse_in_room_dining_category_detail_label_name: "姓名",
      hotel_inhouse_in_room_dining_category_detail_label_date: "日期",
      hotel_inhouse_in_room_dining_category_detail_label_category_details: "类别详情",
      hotel_inhouse_in_room_dining_category_detail_label_language: "语言",
      hotel_inhouse_in_room_dining_category_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_category_detail_button_add: "添加",
      hotel_inhouse_in_room_dining_category_detail_button_delete: "删除",
      hotel_inhouse_in_room_dining_category_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_category_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_category_edit_title: "客房内用餐类别",
      hotel_inhouse_in_room_dining_category_edit_label_category_details: "类别详情",
      hotel_inhouse_in_room_dining_category_edit_label_language: "语言",
      hotel_inhouse_in_room_dining_category_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_category_edit_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_category_edit_select_yes: "是的",
      hotel_inhouse_in_room_dining_category_edit_select_no: "不",
      hotel_inhouse_in_room_dining_category_edit_label_date: "日期",
      hotel_inhouse_in_room_dining_category_edit_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_category_edit_button_add: "添加",
      hotel_inhouse_in_room_dining_category_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_category_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_category_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_category_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_category_edit_guide_empty: "列表为空",
      hotel_hotel_inhouse_in_room_dining_category_translation_add_title: "客房送餐类别翻译",
      hotel_inhouse_in_room_dining_category_translation_add_label_language: "语言",
      hotel_inhouse_in_room_dining_category_translation_add_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_category_translation_add_select_en: "英语",
      hotel_inhouse_in_room_dining_category_translation_add_select_ja: "日本人",
      hotel_inhouse_in_room_dining_category_translation_add_select_zh: "中国人",
      hotel_inhouse_in_room_dining_category_translation_add_select_ms: "马来语",
      hotel_inhouse_in_room_dining_category_translation_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_category_translation_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_category_translation_add_button_add: "添加",
      hotel_inhouse_in_room_dining_category_translation_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_category_translation_edit_title: "客房送餐类别翻译",
      hotel_inhouse_in_room_dining_category_translation_edit_label_language: "语言",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_category_translation_edit_select_en: "英语",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ja: "日本人",
      hotel_inhouse_in_room_dining_category_translation_edit_select_zh: "中国人",
      hotel_inhouse_in_room_dining_category_translation_edit_select_ms: "马来语",
      hotel_inhouse_in_room_dining_category_translation_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_category_translation_edit_label_date: "日期",
      hotel_inhouse_in_room_dining_category_translation_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_category_translation_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_category_translation_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_category_translation_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_setting_list_title: "客房内用餐菜单设置列表",
      hotel_inhouse_in_room_dining_menu_setting_list_label_no: "不。",
      hotel_inhouse_in_room_dining_menu_setting_list_label_type: "类型",
      hotel_inhouse_in_room_dining_menu_setting_list_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_list_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_setting_list_discount_rate: "折扣率",
      hotel_inhouse_in_room_dining_menu_setting_list_price: "价格",
      hotel_inhouse_in_room_dining_menu_setting_list_status: "地位",
      hotel_inhouse_in_room_dining_menu_setting_list_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_setting_list_created_at: "日期",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sold_out: "售罄",
      hotel_inhouse_in_room_dining_menu_setting_list_select_sale: "销售",
      hotel_inhouse_in_room_dining_menu_setting_list_select_preparing: "准备中",
      hotel_inhouse_in_room_dining_menu_setting_list_select_not_sale: "不出售",
      hotel_inhouse_in_room_dining_menu_setting_list_select_yes: "是的",
      hotel_inhouse_in_room_dining_menu_setting_list_select_no: "不",
      hotel_inhouse_in_room_dining_menu_setting_list_sorting: "排序",
      hotel_inhouse_in_room_dining_menu_setting_list_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_setting_list_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_setting_list_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_setting_add_title: "添加客房内用餐菜单设置",
      hotel_inhouse_in_room_dining_menu_setting_add_label_category: "类别",
      hotel_inhouse_in_room_dining_menu_setting_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_add_label_about: "关于",
      hotel_inhouse_in_room_dining_menu_setting_add_label_main_image: "主图",
      hotel_inhouse_in_room_dining_menu_setting_add_label_detail_images: "详细图片",
      hotel_inhouse_in_room_dining_menu_setting_add_label_upload: "上传",
      hotel_inhouse_in_room_dining_menu_setting_add_label_more: "更多的",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_start_time: "订单开始时间（小时：分钟）",
      hotel_inhouse_in_room_dining_menu_setting_add_label_appoint_cutoff_time: "订单结束时间（小时：分钟）",
      hotel_inhouse_in_room_dining_menu_setting_add_label_day_of_week: "一周中的天",
      hotel_inhouse_in_room_dining_menu_setting_add_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_type: "折扣类型",
      hotel_inhouse_in_room_dining_menu_setting_add_select_flat: "扁平率",
      hotel_inhouse_in_room_dining_menu_setting_add_select_percent: "百分比",
      hotel_inhouse_in_room_dining_menu_setting_add_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_setting_add_label_status: "地位",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sold_out: "售罄",
      hotel_inhouse_in_room_dining_menu_setting_add_select_sale: "销售",
      hotel_inhouse_in_room_dining_menu_setting_add_select_preparing: "准备中",
      hotel_inhouse_in_room_dining_menu_setting_add_select_not_sale: "不作为产品销售",
      hotel_inhouse_in_room_dining_menu_setting_add_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_setting_add_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_setting_add_button_no: "不",
      hotel_inhouse_in_room_dining_menu_setting_add_label_recommended: "建议",
      hotel_inhouse_in_room_dining_menu_setting_add_label_packaging_availability: "包装可用性",
      hotel_inhouse_in_room_dining_menu_setting_add_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_setting_add_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_menu_category_ids: "* 请选择类别。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_about: "* 请输入描述。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_main_image: "* 请上传主图。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_detail_images: "* 请上传详细图片。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_more: "* 请输入任何附加说明。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_from_at: "* 请输入订单开始时间。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_to_at: "* 请输入订单结束时间。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_day_of_week: "* 请选择星期几。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_supply_price: "* 请输入供货价格。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_show: "* 请选择是否显示菜单。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_recommended: "* 请选择是否显示推荐。",
      hotel_inhouse_in_room_dining_menu_setting_add_validation_packaging_availability: "* 请选择“打包外带”可用性。",
      hotel_inhouse_in_room_dining_menu_setting_detail_title: "客房内用餐菜单设置详情",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_category: "类别",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_about: "关于",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_main_image: "主图",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_detail_images: "详细图片",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_more: "更多的",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_start_time: "订单开始时间（小时：分钟）",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_appoint_cutoff_time: "订单结束时间（小时：分钟）",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_day_of_week: "一周中的天",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_type: "折扣类型",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_flat: "扁平率",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_percent: "百分比",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_sorting: "排序",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_status: "地位",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sold_out: "售罄",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_sale: "销售",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_preparing: "准备中",
      hotel_inhouse_in_room_dining_menu_setting_detail_select_not_sale: "不作为产品销售",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_no: "不",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_recommended: "受到推崇的",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_packaging_availability: "包装可用性",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_language: "语言",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_date: "日期",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_option: "选项",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_no: "不。",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_items: "项目",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_required: "必需的",
      hotel_inhouse_in_room_dining_menu_setting_detail_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_setting_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_setting_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_setting_edit_title: "客房内用餐菜单设置",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_category: "类别",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_about: "关于",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_main_image: "主图",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_detail_images: "详细图片",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_upload: "上传",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_more: "更多的",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_from_at: "订单开始时间（小时：分钟）",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_to_at: "订单结束时间（小时：分钟）",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_week: "一周中的天",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_type: "折扣类型",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_flat: "扁平率",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_percent: "百分比",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_status: "地位",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sold_out: "售罄",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_sale: "销售",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_preparing: "准备中",
      hotel_inhouse_in_room_dining_menu_setting_edit_select_not_sale: "不作为产品销售",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_no: "不",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_recommended: "受到推崇的",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_packaging_availability: "包装可用性",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_language: "语言",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_date: "日期",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_option: "选项",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_no: "不。",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_items: "项目",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_required: "必需的",
      hotel_inhouse_in_room_dining_menu_setting_edit_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_setting_edit_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_setting_edit_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_menu_category_ids: "* 请选择类别。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_about: "* 请输入关于。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_more: "* 请输入更多。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_from_at: "* 请输入订单开始时间。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_to_at: "* 请输入订单结束时间。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_day_of_week: "* 请选择星期几。",
      hotel_inhouse_in_room_dining_menu_setting_edit_validation_supply_price: "* 请输入供货价格。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_title: "客房用餐菜单语言添加",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_en: "英语",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ja: "日本人",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_zh: "中国人",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_select_ms: "马来语",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_about: "关于",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_label_more: "更多的",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_language_code: "* 请选择语言代码。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_about: "* 请输入关于。",
      hotel_inhouse_in_room_dining_menu_setting_translation_add_validation_more: "* 请输入更多。",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_title: "客房用餐菜单选项详情",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_about: "关于",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_more: "更多的",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_setting_translation_detail_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_title: "客房用餐菜单语言编辑",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_en: "英语",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ja: "日本人",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_zh: "中国人",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_select_ms: "马来语",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_about: "关于",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_more: "更多的",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_about: "* 请输入关于。",
      hotel_inhouse_in_room_dining_menu_setting_translation_edit_validation_more: "* 请输入更多。",
      hotel_inhouse_in_room_dining_menu_option_group_add_title: "客房用餐菜单选项 群组添加",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_max_select: "最大选择数",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_required: "必需的",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_no: "不",
      hotel_inhouse_in_room_dining_menu_option_group_add_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_group_add_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_required: "* 请选择需要的。",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_max_select: "* 请输入最大值选择的数量。",
      hotel_inhouse_in_room_dining_menu_option_group_add_validation_show: "* 请选择是否显示菜单。",
      hotel_inhouse_in_room_dining_menu_option_group_detail_title: "客房内用餐菜单选项详情",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_max_select: "最大选择数",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_required: "必需的",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_no: "不",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_language: "语言",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_date: "日期",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_sorting: "排序",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_option: "选项",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_no: "不。",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_items: "项目",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_option_group_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_option_group_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_option_group_detail_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_title: "编辑客房内用餐菜单选项",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_max_select: "最大选择数",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_required: "必需的",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_no: "不",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_language: "语言",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_date: "日期",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_option: "选项",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_no: "不。",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_items: "项目",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_option_group_edit_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_option_group_edit_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_required: "* 请选择需要的。",
      hotel_inhouse_in_room_dining_menu_option_group_edit_validation_max_select: "* 请输入最大值选择的数量。",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_title: "添加客房用餐菜单选项语言",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_en: "英语",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ja: "日本人",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_zh: "中国人",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_select_ms: "马来语",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_language_code: "* 请选择语言代码。",
      hotel_inhouse_in_room_dining_menu_option_group_translation_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_title: "编辑客房用餐菜单选项语言",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_en: "英语",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ja: "日本人",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_zh: "中国人",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_select_ms: "马来语",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_option_group_translation_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_value_add_title: "添加客房内用餐菜单项目",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_no: "不",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_type: "折扣类型",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_flat: "扁平率",
      hotel_inhouse_in_room_dining_menu_option_value_add_select_percent: "百分比",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_option_value_add_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_option_value_add_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_supply_price: "* 请输入供货价格。",
      hotel_inhouse_in_room_dining_menu_option_value_add_validation_show: "* 请选择是否显示菜单。",
      hotel_inhouse_in_room_dining_menu_option_value_detail_title: "客房内用餐菜单项目详情",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_no: "不",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_type: "折扣类型",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_language: "语言",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_value_detail_label_date: "日期",
      hotel_inhouse_in_room_dining_menu_option_value_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_option_value_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_value_edit_title: "编辑客房内用餐菜单项目",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_yes: "是的",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_no: "不",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_show: "显示菜单",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_language: "语言",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_date: "日期",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_option: "选项",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_no: "不。",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_items: "项目",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_required: "必需的",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_amount: "折扣金额",
      hotel_inhouse_in_room_dining_menu_option_value_edit_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_menu_option_value_edit_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_discount_type: "折扣类型",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_flat: "扁平率",
      hotel_inhouse_in_room_dining_menu_option_value_edit_select_percent: "百分比",
      hotel_inhouse_in_room_dining_menu_option_value_edit_label_price: "价格",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_value_edit_validation_supply_price: "* 请输入供货价格。",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_title: "添加客房用餐菜单项语言",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_en: "英语",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ja: "日本人",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_zh: "中国人",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_select_ms: "马来语",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_button_add: "添加",
      hotel_inhouse_in_room_dining_menu_option_value_translation_add_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_title: "编辑客房用餐菜单选项语言",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_language_code: "语言代码",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ko: "韩国人",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_en: "英语",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ja: "日本人",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_zh: "中国人",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_select_ms: "马来语",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_name: "姓名",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_delete: "删除",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_button_ok: "好的",
      hotel_inhouse_in_room_dining_menu_option_value_translation_edit_validation_name: "* 请输入姓名。",
      hotel_inhouse_in_room_dining_process_list_title: "客房用餐进度清单（确认后）",
      hotel_inhouse_in_room_dining_process_list_label_number_of_guest_requests: "客人请求数量",
      hotel_inhouse_in_room_dining_process_list_label_unconfirmed_guest_requests: "未经确认的客人请求",
      hotel_inhouse_in_room_dining_process_list_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_process_list_label_all: "请求（全部）",
      hotel_inhouse_in_room_dining_process_list_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_process_list_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_process_list_placeholder_search: "搜索...",
      hotel_inhouse_in_room_dining_process_list_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_process_list_label_request_date: "查询日期",
      hotel_inhouse_in_room_dining_process_list_label_request_type: "请求类型",
      hotel_inhouse_in_room_dining_process_list_label_task: "任务",
      hotel_inhouse_in_room_dining_process_list_label_action_status: "行动状态",
      hotel_inhouse_in_room_dining_process_list_label_date: "日期",
      hotel_inhouse_in_room_dining_process_list_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_process_detail_title: "客房用餐请求详情",
      hotel_inhouse_in_room_dining_process_detail_label_order_no: "订单号。",
      hotel_inhouse_in_room_dining_process_detail_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_process_detail_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_process_detail_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_process_detail_label_status: "地位",
      hotel_inhouse_in_room_dining_process_detail_label_requests: "要求",
      hotel_inhouse_in_room_dining_process_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_process_detail_label_request_at: "请求于",
      hotel_inhouse_in_room_dining_process_detail_button_stop: "停止",
      hotel_inhouse_in_room_dining_process_detail_button_preparing: "准备中",
      hotel_inhouse_in_room_dining_process_detail_button_deliver: "递送",
      hotel_inhouse_in_room_dining_process_detail_label_payment: "支付",
      hotel_inhouse_in_room_dining_process_detail_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_process_detail_label_discount_rate: "折扣率",
      hotel_inhouse_in_room_dining_process_detail_label_price: "价格",
      hotel_inhouse_in_room_dining_process_detail_label_to_go: "去",
      hotel_inhouse_in_room_dining_process_detail_label_order: "命令",
      hotel_inhouse_in_room_dining_process_detail_label_no: "不。",
      hotel_inhouse_in_room_dining_process_detail_label_category: "类别",
      hotel_inhouse_in_room_dining_process_detail_label_menu: "菜单",
      hotel_inhouse_in_room_dining_process_detail_label_option: "选项",
      hotel_inhouse_in_room_dining_process_detail_label_items: "项目",
      hotel_inhouse_in_room_dining_process_detail_label_quantity: "数量",
      hotel_inhouse_in_room_dining_process_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_process_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_modal_stop_customer_reason: "客户原因",
      hotel_inhouse_in_room_dining_modal_stop_product_issue: "产品问题",
      hotel_inhouse_in_room_dining_modal_stop_delivery_problem: "发货问题",
      hotel_inhouse_in_room_dining_modal_stop_other: "其他",
      hotel_inhouse_in_room_dining_receipt_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_receipt_label_order_created_date: "订单创建日期",
      hotel_inhouse_in_room_dining_receipt_label_guest_requested_date: "客人要求的日期",
      hotel_inhouse_in_room_dining_receipt_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_receipt_label_guest_name: "客人姓名",
      hotel_inhouse_in_room_dining_receipt_label_check_in: "报到",
      hotel_inhouse_in_room_dining_receipt_label_check_out: "查看",
      hotel_inhouse_in_room_dining_receipt_label_order_no: "订单号。",
      hotel_inhouse_in_room_dining_receipt_label_to_go: "去",
      hotel_inhouse_in_room_dining_receipt_label_description: "描述",
      hotel_inhouse_in_room_dining_receipt_label_unit_price: "单价",
      hotel_inhouse_in_room_dining_receipt_label_quantity: "数量",
      hotel_inhouse_in_room_dining_receipt_label_amount: "数量",
      hotel_inhouse_in_room_dining_receipt_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_receipt_label_subtotal: "小计",
      hotel_inhouse_in_room_dining_receipt_label_discount: "折扣",
      hotel_inhouse_in_room_dining_receipt_label_total: "全部的",
      hotel_inhouse_in_room_dining_receipt_label_payment: "支付",
      hotel_inhouse_in_room_dining_request_receipt_label_room_posting: "房间发布",
      hotel_inhouse_in_room_dining_request_receipt_label_self_settlement: "自行结算",
      hotel_inhouse_in_room_dining_request_receipt_label_balance: "平衡",
      hotel_inhouse_in_room_dining_request_receipt_contents_agreement: "我同意不免除我对该账单的责任，并同意在指定的个人、公司或团体未能支付部分或全部费用的情况下承担个人责任。",
      hotel_inhouse_in_room_dining_request_list_title: "客房内用餐请求清单（确认前）",
      hotel_inhouse_in_room_dining_request_list_label_number_of_guest_requests: "客人请求数量",
      hotel_inhouse_in_room_dining_request_list_label_unconfirmed_guest_requests: "未经确认的客人请求",
      hotel_inhouse_in_room_dining_request_list_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_request_list_label_all: "请求（全部）",
      hotel_inhouse_in_room_dining_request_list_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_request_list_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_request_list_placeholder_search: "搜索...",
      hotel_inhouse_in_room_dining_request_list_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_request_list_label_request_date: "查询日期",
      hotel_inhouse_in_room_dining_request_list_label_request_type: "请求类型",
      hotel_inhouse_in_room_dining_request_list_label_task: "任务",
      hotel_inhouse_in_room_dining_request_list_label_action_status: "行动状态",
      hotel_inhouse_in_room_dining_request_list_label_date: "日期",
      hotel_inhouse_in_room_dining_request_list_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_request_detail_title: "客房用餐请求详情",
      hotel_inhouse_in_room_dining_request_detail_label_update_at: "更新于",
      hotel_inhouse_in_room_dining_request_detail_label_order_no: "订单号。",
      hotel_inhouse_in_room_dining_request_detail_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_request_detail_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_request_detail_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_request_detail_label_status: "地位",
      hotel_inhouse_in_room_dining_request_detail_label_requests: "要求",
      hotel_inhouse_in_room_dining_request_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_request_detail_label_request_at: "请求于",
      hotel_inhouse_in_room_dining_request_detail_button_pdf: "PDF",
      hotel_inhouse_in_room_dining_request_detail_button_not_available: "无法使用",
      hotel_inhouse_in_room_dining_request_detail_button_confirm: "确认",
      hotel_inhouse_in_room_dining_request_detail_label_payment: "支付",
      hotel_inhouse_in_room_dining_request_detail_label_supply_price: "供应价格",
      hotel_inhouse_in_room_dining_request_detail_label_discount_rate: "折扣率",
      hotel_inhouse_in_room_dining_request_detail_label_price: "价格",
      hotel_inhouse_in_room_dining_request_detail_label_to_go: "去",
      hotel_inhouse_in_room_dining_request_detail_label_order: "命令",
      hotel_inhouse_in_room_dining_request_detail_label_no: "不。",
      hotel_inhouse_in_room_dining_request_detail_label_category: "类别",
      hotel_inhouse_in_room_dining_request_detail_label_menu: "菜单",
      hotel_inhouse_in_room_dining_request_detail_label_option: "选项",
      hotel_inhouse_in_room_dining_request_detail_label_items: "项目",
      hotel_inhouse_in_room_dining_request_detail_label_quantity: "数量",
      hotel_inhouse_in_room_dining_request_detail_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_request_detail_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_request_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_myconect_list_title: "客房用餐时间表进度表",
      hotel_inhouse_in_room_dining_myconect_list_label_user_count: "按用户数取消",
      hotel_inhouse_in_room_dining_myconect_list_label_hotel_count: "被酒店取消",
      hotel_inhouse_in_room_dining_myconect_list_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_myconect_list_label_room_no: "房间号。",
      hotel_inhouse_in_room_dining_myconect_list_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_myconect_list_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_myconect_list_label_request_date: "查询日期",
      hotel_inhouse_in_room_dining_myconect_list_label_request_type: "请求类型",
      hotel_inhouse_in_room_dining_myconect_list_label_express: "任务",
      hotel_inhouse_in_room_dining_myconect_list_label_action_status: "行动状态",
      hotel_inhouse_in_room_dining_myconect_list_label_myconect_at: "MyCONECT 在",
      hotel_inhouse_in_room_dining_myconect_list_label_completed_at: "完成于",
      hotel_inhouse_in_room_dining_myconect_list_label_date: "日期",
      hotel_inhouse_in_room_dining_myconect_list_guide_empty: "列表为空",
      hotel_inhouse_in_room_dining_myconect_detail_title: "客房内用餐时间表流程详情",
      hotel_inhouse_in_room_dining_myconect_detail_label_roon_no: "房间号。",
      hotel_inhouse_in_room_dining_myconect_detail_label_confirmation_no: "确认号",
      hotel_inhouse_in_room_dining_myconect_detail_label_user_name: "用户名",
      hotel_inhouse_in_room_dining_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_in_room_dining_myconect_detail_label_stay: "停留",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_type: "请求类型",
      hotel_inhouse_in_room_dining_myconect_detail_label_status: "地位",
      hotel_inhouse_in_room_dining_myconect_detail_label_request_at: "请求于",
      hotel_inhouse_in_room_dining_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_multi_myconect_list_title: "客房内用餐时间表",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_alive: "积极的",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_staff_number: "员工人数",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_request: "要求",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_department: "部门",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_email: "电子邮件",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_respond: "MyCONECT 响应",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_from: "从",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_to: "到",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_date: "日期",
      hotel_inhouse_in_room_dining_multi_myconect_list_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_multi_myconect_list_button_add: "添加",
      hotel_inhouse_in_room_dining_multi_myconect_add_title: "添加客房内用餐时间表",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_request_type: "请求类型",
      hotel_inhouse_in_room_dining_multi_myconect_add_placeholder_select_box_choose: "选择",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_respond: "MyCONECT 响应",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_not_availble: "无法使用",
      hotel_inhouse_in_room_dining_multi_myconect_add_select_confirm: "确认",
      hotel_inhouse_in_room_dining_multi_myconect_add_label_date: "日期",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_add: "添加",
      hotel_inhouse_in_room_dining_multi_myconect_add_button_set: "放",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_type_request: "* 请选择请求类型。",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_start: "* 请选择日期。",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_period_end: "* 请选择日期。",
      hotel_inhouse_in_room_dining_multi_myconect_add_validation_respond: "* 请选择 MyCONECT 回复。",
      hotel_inhouse_in_room_dining_multi_myconect_detail_title: "客房内用餐时间表信息",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_update: "更新日期",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_date: "日期",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_respond: "MyCONECT 响应",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_staff_number: "员工人数",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_request: "要求",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_department: "部门",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_email: "电子邮件",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_released_at: "发布于",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_created_at: "创建于",
      hotel_inhouse_in_room_dining_multi_myconect_detail_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_go_to_list: "前往列表",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_cancel: "取消",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_release: "发布",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_multi_myconect_detail_button_ok: "好的",
      hotel_inhouse_in_room_dining_setting_detail_title: "客房内用餐环境",
      hotel_inhouse_in_room_dining_setting_detail_label_update_at: "更新于",
      hotel_inhouse_in_room_dining_setting_detail_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_setting_detail_label_room_posting: "房间发布",
      hotel_inhouse_in_room_dining_setting_detail_button_yes: "是的",
      hotel_inhouse_in_room_dining_setting_detail_button_no: "不",
      hotel_inhouse_in_room_dining_setting_detail_label_payment: "支付",
      hotel_inhouse_in_room_dining_setting_detail_button_edit: "编辑",
      hotel_inhouse_in_room_dining_setting_edit_title: "客房内用餐环境",
      hotel_inhouse_in_room_dining_setting_edit_label_update_at: "更新于",
      hotel_inhouse_in_room_dining_setting_edit_label_in_room_dining: "客房内用餐",
      hotel_inhouse_in_room_dining_setting_edit_label_room_posting: "房间发布",
      hotel_inhouse_in_room_dining_setting_edit_button_yes: "是的",
      hotel_inhouse_in_room_dining_setting_edit_button_no: "不",
      hotel_inhouse_in_room_dining_setting_edit_label_payment: "支付",
      hotel_inhouse_in_room_dining_setting_edit_button_cancel: "取消",
      hotel_inhouse_in_room_dining_setting_edit_button_ok: "好的",
      hotel_inhouse_setting_business_list_title: "业务规则设置",
      hotel_inhouse_setting_business_list_label_no: "不。",
      hotel_inhouse_setting_business_list_label_type: "类型",
      hotel_inhouse_setting_business_list_label_task: "任务",
      hotel_inhouse_setting_business_list_label_time_from: "可用时间从",
      hotel_inhouse_setting_business_list_label_time_to: "可用时间至",
      hotel_inhouse_setting_business_list_label_charge_amount: "收费金额",
      hotel_inhouse_setting_business_list_label_in_room_dining: "客房内用餐",
      hotel_inhouse_setting_business_list_label_update_at: "更新于",
      hotel_inhouse_setting_business_list_guide_empty: "列表为空",
      hotel_inhouse_setting_business_detail_title: "业务规则设置信息",
      hotel_inhouse_setting_business_detail_label_date: "更新日期",
      hotel_inhouse_setting_business_detail_label_type: "类型",
      hotel_inhouse_setting_business_detail_label_task: "任务",
      hotel_inhouse_setting_business_detail_label_in_room_dining: "客房内用餐",
      hotel_inhouse_setting_business_detail_label_time_from: "可用时间从",
      hotel_inhouse_setting_business_detail_label_time_to: "可用时间至",
      hotel_inhouse_setting_business_detail_label_min_requirement_time: "最短要求时间（分钟）",
      hotel_inhouse_setting_business_detail_label_waiting_time: "MyCONECT 等待时间（分钟）",
      hotel_inhouse_setting_business_detail_label_respond: "MyCONECT 响应",
      hotel_inhouse_setting_business_detail_label_amount: "收费金额",
      hotel_inhouse_setting_business_detail_label_transaction_code: "收费交易代码",
      hotel_inhouse_setting_business_detail_label_description: "收费说明",
      hotel_inhouse_setting_business_detail_label_supplement: "收费详情",
      hotel_inhouse_setting_business_detail_label_myconect_from: "MyCONECT 来自",
      hotel_inhouse_setting_business_detail_label_myconect_to: "我的连接 至",
      hotel_inhouse_setting_business_detail_label_additional_charge_count: "附加收费条件计数",
      hotel_inhouse_setting_business_detail_label_additional_charge_repeat_flag: "附加充电条件重复标志",
      hotel_inhouse_setting_business_detail_button_yes: "是的",
      hotel_inhouse_setting_business_detail_button_no: "不",
      hotel_inhouse_setting_business_detail_button_go_to_list: "前往列表",
      hotel_inhouse_setting_business_detail_button_edit: "编辑",
      hotel_inhouse_setting_business_edit_title: "业务规则设置信息",
      hotel_inhouse_setting_business_edit_label_date: "更新日期",
      hotel_inhouse_setting_business_edit_label_type: "类型",
      hotel_inhouse_setting_business_edit_label_task: "任务",
      hotel_inhouse_setting_business_edit_label_time_from: "可用时间从",
      hotel_inhouse_setting_business_edit_label_time_to: "可用时间至",
      hotel_inhouse_setting_business_edit_label_min_requirement_time: "最短要求时间",
      hotel_inhouse_setting_business_edit_label_waiting_time: "MyCONECT 等待时间",
      hotel_inhouse_setting_business_edit_label_respond: "MyCONECT 响应",
      hotel_inhouse_setting_business_edit_select_not_availble: "无法使用",
      hotel_inhouse_setting_business_edit_select_confirm: "确认",
      hotel_inhouse_setting_business_edit_select_delay15: "延误15分钟",
      hotel_inhouse_setting_business_edit_select_delay30: "延误30分钟",
      hotel_inhouse_setting_business_edit_select_delay45: "延误 45 分钟",
      hotel_inhouse_setting_business_edit_select_delay60: "延误60分钟",
      hotel_inhouse_setting_business_edit_select_asaa: "尽快可用",
      hotel_inhouse_setting_business_edit_label_amount: "收费金额",
      hotel_inhouse_setting_business_edit_label_transaction_code: "收费交易代码",
      hotel_inhouse_setting_business_edit_label_description: "收费说明",
      hotel_inhouse_setting_business_edit_label_supplement: "收费详情",
      hotel_inhouse_setting_business_edit_label_myconect_from: "MyCONECT 来自",
      hotel_inhouse_setting_business_edit_label_myconect_to: "我的连接 至",
      hotel_inhouse_setting_business_edit_validation_available_time_from: "* 请输入可用时间",
      hotel_inhouse_setting_business_edit_validation_available_time_to: "* 请输入可到的时间",
      hotel_inhouse_setting_business_edit_validation_min_requirement_time: "* 请输入最小值要求时间。",
      hotel_inhouse_setting_business_edit_validation_myconect_waiting_time: "* 请输入 MyCONECT 等待时间。",
      hotel_inhouse_setting_business_edit_validation_myconect_respond: "* 请选择 MyCONECT 回复。",
      hotel_inhouse_setting_business_edit_validation_charge_amount: "* 请输入收费金额。",
      hotel_inhouse_setting_business_edit_validation_charge_transaction_code: "* 请输入充值交易码",
      hotel_inhouse_setting_business_edit_validation_charge_description: "* 请输入收费说明",
      hotel_inhouse_setting_business_edit_validation_charge_supplement: "* 请输入收费详情",
      hotel_inhouse_setting_business_edit_label_in_room_dining: "客房内用餐",
      hotel_inhouse_setting_business_edit_button_cancel: "取消",
      hotel_inhouse_setting_business_edit_button_ok: "好的",
      hotel_inhouse_setting_boundary_list_title: "边界线设置",
      hotel_inhouse_setting_boundary_list_label_no: "不。",
      hotel_inhouse_setting_boundary_list_label_type: "类型",
      hotel_inhouse_setting_boundary_list_label_task: "任务",
      hotel_inhouse_setting_boundary_list_label_ten_mins: "十分钟",
      hotel_inhouse_setting_boundary_list_label_hour: "小时",
      hotel_inhouse_setting_boundary_list_label_three_hours: "三个小时",
      hotel_inhouse_setting_boundary_list_label_update_at: "更新于",
      hotel_inhouse_setting_boundary_list_guide_empty: "列表为空",
      hotel_inhouse_setting_boundary_detail_title: "边界线设置信息",
      hotel_inhouse_setting_boundary_detail_label_date: "更新日期",
      hotel_inhouse_setting_boundary_detail_label_type: "类型",
      hotel_inhouse_setting_boundary_detail_label_task: "任务",
      hotel_inhouse_setting_boundary_detail_label_ten_mins: "十分钟",
      hotel_inhouse_setting_boundary_detail_label_hour: "小时",
      hotel_inhouse_setting_boundary_detail_label_three_hours: "三个小时",
      hotel_inhouse_setting_boundary_detail_button_none: "没有任何",
      hotel_inhouse_setting_boundary_detail_button_unlimited: "无时间限制",
      hotel_inhouse_setting_boundary_detail_button_value: "价值",
      hotel_inhouse_setting_boundary_detail_label_option_one: "选项一",
      hotel_inhouse_setting_boundary_detail_label_option_two: "选项二",
      hotel_inhouse_setting_boundary_detail_button_go_to_list: "前往列表",
      hotel_inhouse_setting_boundary_detail_button_edit: "编辑",
      hotel_inhouse_setting_boundary_edit_title: "边界线设置",
      hotel_inhouse_setting_boundary_edit_label_date: "更新日期",
      hotel_inhouse_setting_boundary_edit_label_type: "类型",
      hotel_inhouse_setting_boundary_edit_label_task: "任务",
      hotel_inhouse_setting_boundary_edit_label_ten_mins: "十分钟",
      hotel_inhouse_setting_boundary_edit_label_hour: "小时",
      hotel_inhouse_setting_boundary_edit_label_three_hours: "三个小时",
      hotel_inhouse_setting_boundary_edit_button_none: "没有任何",
      hotel_inhouse_setting_boundary_edit_button_unlimited: "无时间限制",
      hotel_inhouse_setting_boundary_edit_button_value: "价值",
      hotel_inhouse_setting_boundary_edit_label_option_one: "选项一",
      hotel_inhouse_setting_boundary_edit_label_option_two: "选项二",
      hotel_inhouse_setting_boundary_edit_validation_boundary_line_hour: "* 请输入时间。",
      hotel_inhouse_setting_boundary_edit_button_cancel: "取消",
      hotel_inhouse_setting_boundary_edit_button_ok: "好的",
      hotel_inhouse_setting_amenity_list_title: "设施设置",
      hotel_inhouse_setting_amenity_list_label_no: "不。",
      hotel_inhouse_setting_amenity_list_label_item: "物品",
      hotel_inhouse_setting_amenity_list_label_order_by: "订购方式",
      hotel_inhouse_setting_amenity_list_label_update_at: "更新于",
      hotel_inhouse_setting_amenity_list_guide_empty: "列表为空",
      hotel_inhouse_room_controller_detail_title: "房间控制器",
      hotel_inhouse_room_controller_detail_button_choose_file: "选择文件",
      hotel_inhouse_room_controller_detail_button_insert: "输入",
      hotel_inhouse_room_controller_detail_button_export: "出口",
      hotel_inhouse_help_list_title: "移动按键菜单帮助",
      hotel_inhouse_help_list_button_add: "添加",
      hotel_inhouse_help_list_button_delete: "删除",
      hotel_inhouse_help_list_button_edit: "编辑",
      hotel_inhouse_help_list_guide_add: "请添加酒店信息。",
      hotel_inhouse_help_add_title: "添加移动钥匙帮助",
      hotel_inhouse_help_add_placeholder_title: "请输入标题。",
      hotel_inhouse_help_add_placeholder_index: "请输入索引。",
      hotel_inhouse_help_add_select_choose: "请选择类型",
      hotel_inhouse_help_add_select_use: "使用",
      hotel_inhouse_help_add_select_access: "使用权",
      hotel_inhouse_help_add_select_range: "范围",
      hotel_inhouse_help_add_select_not_working: "不工作",
      hotel_inhouse_help_add_select_in_room_tech: "室内技术",
      hotel_inhouse_help_add_select_trouble: "麻烦",
      hotel_inhouse_help_add_toolbar_bold: "大胆的",
      hotel_inhouse_help_add_toolbar_italic: "斜体",
      hotel_inhouse_help_add_toolbar_underline: "强调",
      hotel_inhouse_help_add_toolbar_remove_font_style: "删除字体样式",
      hotel_inhouse_help_add_toolbar_font_family: "字体系列",
      hotel_inhouse_help_add_toolbar_font_size: "字体大小",
      hotel_inhouse_help_add_toolbar_recent_color: "最近的颜色",
      hotel_inhouse_help_add_toolbar_recent_more_color: "更多颜色",
      hotel_inhouse_help_add_toolbar_unordered_list: "无序列表",
      hotel_inhouse_help_add_toolbar_ordered_list: "有序列表",
      hotel_inhouse_help_add_toolbar_paragraph: "段落",
      hotel_inhouse_help_add_toolbar_line_height: "行高",
      hotel_inhouse_help_add_placeholder_content: "请输入内容。",
      hotel_inhouse_help_add_button_cancel: "取消",
      hotel_inhouse_help_add_button_ok: "好的",
      hotel_inhouse_help_edit_title: "编辑酒店信息",
      hotel_inhouse_help_edit_placeholder_title: "标题",
      hotel_inhouse_help_edit_select_kr: "韩国",
      hotel_inhouse_help_edit_select_en: "CN",
      hotel_inhouse_help_edit_select_cn: "中国",
      hotel_inhouse_help_edit_select_jp: "J.P",
      hotel_inhouse_help_edit_select_ms: "MS",
      hotel_inhouse_help_edit_placeholder_index: "指数",
      hotel_inhouse_help_edit_toolbar_bold: "大胆的",
      hotel_inhouse_help_edit_toolbar_italic: "斜体",
      hotel_inhouse_help_edit_toolbar_underline: "强调",
      hotel_inhouse_help_edit_toolbar_remove_font_style: "删除字体样式",
      hotel_inhouse_help_edit_toolbar_font_family: "字体系列",
      hotel_inhouse_help_edit_toolbar_font_size: "字体大小",
      hotel_inhouse_help_edit_toolbar_recent_color: "最近的颜色",
      hotel_inhouse_help_edit_toolbar_recent_more_color: "更多颜色",
      hotel_inhouse_help_edit_toolbar_unordered_list: "无序列表",
      hotel_inhouse_help_edit_toolbar_ordered_list: "有序列表",
      hotel_inhouse_help_edit_toolbar_paragraph: "段落",
      hotel_inhouse_help_edit_toolbar_code_view: "代码视图",
      hotel_inhouse_help_edit_toolbar_line_height: "线高",
      hotel_inhouse_help_edit_button_append: "附加",
      hotel_inhouse_help_edit_button_remove: "消除",
      hotel_inhouse_help_edit_button_cancel: "取消",
      hotel_inhouse_help_edit_button_ok: "好的",
      hotel_key_guest_list_title: "宾客钥匙清单",
      hotel_key_guest_list_button_alive: "积极的",
      hotel_key_guest_list_placeholder_select_box_key: "钥匙",
      hotel_key_guest_list_select_room_no: "房间号。",
      hotel_key_guest_list_placeholder_search: "搜索...",
      hotel_key_guest_list_label_id: "ID",
      hotel_key_guest_list_label_availble_at: "可用于",
      hotel_key_guest_list_label_expired_at: "过期时间",
      hotel_key_guest_list_label_is_main: "主要的",
      hotel_key_guest_list_label_room_no: "房间号。",
      hotel_key_guest_list_label_user: "用户",
      hotel_key_guest_list_button_cancel: "取消",
      hotel_key_guest_list_guide_empty: "列表为空",
      hotel_key_guest_detail_title: "关键信息",
      hotel_key_guest_detail_label_id: "ID",
      hotel_key_guest_detail_label_availble_at: "可用于",
      hotel_key_guest_detail_label_expired_at: "过期时间",
      hotel_key_guest_detail_label_is_main: "主要的",
      hotel_key_guest_detail_label_room_no: "房间号。",
      hotel_key_guest_detail_label_confirmation_no: "确认号",
      hotel_key_guest_detail_label_user: "用户",
      hotel_key_guest_detail_label_user_email: "用户邮箱",
      hotel_key_guest_detail_button_go_to_list: "前往列表",
      hotel_key_guest_detail_button_cancel: "取消",
      hotel_key_staff_list_title: "员工关键清单",
      hotel_key_staff_list_button_alive: "积极的",
      hotel_key_staff_list_placeholder_select_box_key: "钥匙",
      hotel_key_staff_list_select_task: "任务",
      hotel_key_staff_list_select_emp_id: "电磁脉冲。 ID",
      hotel_key_staff_list_placeholder_search: "搜索...",
      hotel_key_staff_list_label_type: "类型",
      hotel_key_staff_list_label_link_type: "链接类型",
      hotel_key_staff_list_label_link_detail: "链接详情",
      hotel_key_staff_list_label_link_code: "链接代码",
      hotel_key_staff_list_label_availble_at: "可用于",
      hotel_key_staff_list_label_expired_at: "过期时间",
      hotel_key_staff_list_label_staff_number: "员工人数",
      hotel_key_staff_list_button_cancel: "取消",
      hotel_key_staff_list_button_add: "添加",
      hotel_key_staff_list_guide_empty: "列表为空",
      hotel_key_staff_add_title: "添加员工钥匙",
      hotel_key_staff_add_button_append: "附加",
      hotel_key_staff_add_label_staff_number: "员工人数",
      hotel_key_staff_add_label_type: "类型",
      hotel_key_staff_add_label_detail: "细节",
      hotel_key_staff_add_label_from_date: "从日期",
      hotel_key_staff_add_label_end_date: "结束日期",
      hotel_key_staff_add_placeholder_select_box_choose: "选择",
      hotel_key_staff_add_button_remove: "消除",
      hotel_key_staff_add_button_go_to_list: "前往列表",
      hotel_key_staff_add_button_add: "添加",
      hotel_key_staff_add_validation_number: "* 请输入员工编号。",
      hotel_key_staff_add_validation_type: "* 请选择类型。",
      hotel_key_staff_add_validation_detail: "* 请选择详细信息。",
      hotel_key_staff_add_validation_from_date: "* 请选择开始日期。",
      hotel_key_staff_add_validation_end_date: "* 请选择结束日期。",
      hotel_key_staff_detail_title: "员工关键信息",
      hotel_key_staff_detail_label_type: "类型",
      hotel_key_staff_detail_label_link_type: "链接类型",
      hotel_key_staff_detail_label_link_detail: "链接详情",
      hotel_key_staff_detail_label_link_code: "链接代码",
      hotel_key_staff_detail_label_availble_at: "可用于",
      hotel_key_staff_detail_label_expired_at: "过期时间",
      hotel_key_staff_detail_label_employee_id: "员工ID",
      hotel_key_staff_detail_label_canceled_at: "取消于",
      hotel_key_staff_detail_button_go_to_list: "前往列表",
      hotel_key_staff_detail_button_cancel: "取消",
      hotel_inhouse_share_key_list_title: "分享密钥列表",
      hotel_inhouse_share_key_list_button_alive: "积极的",
      hotel_inhouse_share_key_list_placeholder_select_box_share_key: "共享密钥",
      hotel_inhouse_share_key_list_select_room_no: "房间号。",
      hotel_inhouse_share_key_list_select_email: "电子邮件",
      hotel_inhouse_share_key_list_select_user_name: "用户名",
      hotel_inhouse_share_key_list_placeholder_search: "搜索...",
      hotel_inhouse_share_key_list_label_no: "不。",
      hotel_inhouse_share_key_list_label_sender_room_no: "发件人房间号",
      hotel_inhouse_share_key_list_label_sender_name: "发件人名称",
      hotel_inhouse_share_key_list_label_sender_email: "发件人邮箱",
      hotel_inhouse_share_key_list_label_key_type: "钥匙类型",
      hotel_inhouse_share_key_list_label_expired_at: "过期时间",
      hotel_inhouse_share_key_list_label_receiver_room_no: "接收室号",
      hotel_inhouse_share_key_list_label_receiver_email: "收件人邮箱",
      hotel_inhouse_share_key_list_label_receiver_status: "接收器状态",
      hotel_inhouse_share_key_list_label_date: "日期",
      hotel_inhouse_share_key_list_guide_empty: "列表为空",
      hotel_inhouse_share_key_detail_title: "分享关键细节",
      hotel_inhouse_share_key_detail_label_sender_room_no: "发件人房间号",
      hotel_inhouse_share_key_detail_label_sender_name: "发件人名称",
      hotel_inhouse_share_key_detail_label_sender_email: "发件人邮箱",
      hotel_inhouse_share_key_detail_label_key_type: "共享密钥类型",
      hotel_inhouse_share_key_detail_label_expired_at: "共享密钥过期",
      hotel_inhouse_share_key_detail_label_receiver_room_no: "接收室号",
      hotel_inhouse_share_key_detail_label_receiver_email: "收件人邮箱",
      hotel_inhouse_share_key_detail_label_receiver_status: "接收器状态",
      hotel_inhouse_share_key_detail_label_created_at: "创建于",
      hotel_inhouse_share_key_detail_button_go_to_list: "前往列表",
      help_for_mobile_key_add_validation_title: "* 请输入主题。",
      help_for_mobile_key_add_validation_index: "* 请输入号码。",
      help_for_mobile_key_add_validation_type: "* 请选择类型。",
      help_for_mobile_key_add_validation_content: "* 请输入您的详细信息。",
      help_for_mobile_key_edit_validation_title: "* 请输入主题。",
      help_for_mobile_key_edit_validation_index: "* 请输入号码。",
      help_for_mobile_key_edit_validation_content: "* 请输入您的详细信息。",
      help_for_mobile_key_edit_validation_language_code: "* 请选择不重叠的语言代码。",
      hotel_key_link_list_title: "员工关键链接列表",
      hotel_key_link_list_placeholder_search: "搜索...",
      hotel_key_link_list_label_type_code: "类型代码",
      hotel_key_link_list_label_type_description: "类型 说明",
      hotel_key_link_list_label_detail_code: "详细代码",
      hotel_key_link_list_label_detail_description: "详细说明",
      hotel_key_link_list_label_staff_type: "人员类型",
      hotel_key_link_list_label_code: "代码",
      hotel_key_link_list_label_date: "日期",
      hotel_key_link_list_button_delete: "删除",
      hotel_key_link_list_button_add: "添加",
      hotel_key_link_list_guide_empty: "列表为空",
      hotel_key_link_add_title: "添加员工关键链接",
      hotel_key_link_add_label_type_code: "类型代码",
      hotel_key_link_add_placeholder_select_box_choose: "选择",
      hotel_key_link_add_select_floor: "地面",
      hotel_key_link_add_select_task: "任务",
      hotel_key_link_add_select_zone: "区",
      hotel_key_link_add_label_type_description: "类型 说明",
      hotel_key_link_add_label_detail_code: "详细代码",
      hotel_key_link_add_label_detail_description: "详细说明",
      hotel_key_link_add_label_staff_type: "人员类型",
      hotel_key_link_add_select_concoerge: "门房",
      hotel_key_link_add_select_housekeeping: "家政",
      hotel_key_link_add_select_front_desk: "前台",
      hotel_key_link_add_label_code: "代码",
      hotel_key_link_add_button_go_to_list: "前往列表",
      hotel_key_link_add_button_add: "添加",
      hotel_key_link_add_validation_type_code: "* 请选择类型代码。",
      hotel_key_link_add_validation_type_description: "* 请输入类型描述。",
      hotel_key_link_add_validation_detail_code: "* 请选择详细代码。",
      hotel_key_link_add_validation_detail_description: "* 请输入详细描述。",
      hotel_key_link_add_validation_staff_type: "* 请选择员工类型。",
      hotel_key_link_add_validation_code: "* 请选择一个代码。",
      hotel_key_link_detail_title: "员工关键链接信息",
      hotel_key_link_detail_label_type_code: "类型代码",
      hotel_key_link_detail_label_type_description: "类型 说明",
      hotel_key_link_detail_label_detail_code: "详细代码",
      hotel_key_link_detail_label_detail_description: "详细说明",
      hotel_key_link_detail_label_staff_type: "人员类型",
      hotel_key_link_detail_label_code: "代码",
      hotel_key_link_detail_label_date: "日期",
      hotel_key_link_detail_button_go_to_list: "前往列表",
      hotel_key_link_detail_button_delete: "删除",
      hotel_key_link_detail_button_edit: "编辑",
      hotel_key_link_edit_title: "员工关键链接信息",
      hotel_key_link_edit_label_type_code: "类型代码",
      hotel_key_link_edit_placeholder_select_box_choose: "选择",
      hotel_key_link_edit_select_floor: "地面",
      hotel_key_link_edit_select_task: "任务",
      hotel_key_link_edit_select_zone: "区",
      hotel_key_link_edit_select_room: "房间",
      hotel_key_link_edit_label_type_description: "类型 说明",
      hotel_key_link_edit_label_detail_code: "详细代码",
      hotel_key_link_edit_label_detail_description: "详细说明",
      hotel_key_link_edit_label_staff_type: "人员类型",
      hotel_key_link_edit_select_concoerge: "门房",
      hotel_key_link_edit_select_housekeeping: "家政",
      hotel_key_link_edit_select_front_desk: "前台",
      hotel_key_link_edit_label_code: "代码",
      hotel_key_link_edit_label_date: "日期",
      hotel_key_link_edit_button_cancel: "取消",
      hotel_key_link_edit_button_delete: "删除",
      hotel_key_link_edit_button_ok: "好的",
      hotel_key_link_edit_validation_type_code: "* 请选择类型代码。",
      hotel_key_link_edit_validation_type_description: "* 请输入类型描述。",
      hotel_key_link_edit_validation_detail_code: "* 请选择详细代码。",
      hotel_key_link_edit_validation_detail_description: "* 请输入详细描述。",
      hotel_key_link_edit_validation_staff_type: "* 请选择员工类型。",
      hotel_key_link_edit_validation_code: "* 请选择一个代码。",
      hotel_key_access_list_title: "访问列表",
      hotel_key_access_list_label_title: "标题",
      hotel_key_access_list_label_type: "类型",
      hotel_key_access_list_label_content: "描述",
      hotel_key_access_list_label_position_code: "职位代码",
      hotel_key_access_list_label_position_name: "职位名称",
      hotel_key_access_list_label_remark: "评论",
      hotel_key_access_list_label_lcon: "图标",
      hotel_key_access_list_label_update_at: "更新于",
      hotel_key_access_list_button_delete: "删除",
      hotel_key_access_list_button_add: "添加",
      hotel_key_access_list_guide_empty: "列表为空",
      hotel_key_access_add_title: "添加访问权限",
      hotel_key_access_add_label_title: "标题",
      hotel_key_access_add_label_type: "类型",
      hotel_key_access_add_label_content: "内容",
      hotel_key_access_add_label_position_code: "职位代码",
      hotel_key_access_add_label_position_name: "职位名称",
      hotel_key_access_add_label_remark: "评论",
      hotel_key_access_add_placeholder_select_box_choose: "选择",
      hotel_key_access_add_label_icon: "图标",
      hotel_key_access_add_button_upload: "上传",
      hotel_key_access_add_placeholder_upload: "例）MyCONECT.png",
      hotel_key_access_add_button_go_to_list: "前往列表",
      hotel_key_access_add_button_add: "添加",
      hotel_key_access_add_validation_title: "* 请输入您的头衔。",
      hotel_key_access_add_validation_type: "* 请输入类型。",
      hotel_key_access_add_validation_content: "* 请输入您的详细信息。",
      hotel_key_access_add_validation_position_name: "* 请选择职位名称。",
      hotel_key_access_add_validation_file: "* 请上传一个图标。",
      hotel_key_access_detail_title: "获取信息",
      hotel_key_access_detail_label_title: "标题",
      hotel_key_access_detail_label_type: "类型",
      hotel_key_access_detail_label_content: "内容",
      hotel_key_access_detail_label_position_code: "职位代码",
      hotel_key_access_detail_label_position_name: "职位名称",
      hotel_key_access_detail_label_remark: "评论",
      hotel_key_access_detail_label_lcon: "图标",
      hotel_key_access_detail_label_update_date: "更新日期",
      hotel_key_access_detail_button_upload: "上传",
      hotel_key_access_detail_button_go_to_list: "前往列表",
      hotel_key_access_detail_button_cancel: "取消",
      hotel_key_access_detail_button_delete: "删除",
      hotel_key_access_detail_button_edit: "编辑",
      hotel_key_access_detail_button_ok: "好的",
      hotel_key_access_edit_validation_title: "* 请输入您的头衔。",
      hotel_key_access_edit_validation_type: "* 请输入类型。",
      hotel_key_access_edit_validation_content: "* 请输入您的详细信息。",
      hotel_key_access_edit_validation_position_name: "* 请选择职位名称。",
      hotel_key_access_edit_validation_file: "* 请上传一个图标。",
      hotel_key_access_detail_list_title: "访问详细列表",
      hotel_key_access_detail_list_label_access_title: "访问标题",
      hotel_key_access_detail_list_label_language_code: "语言代码",
      hotel_key_access_detail_list_label_access_detail_title: "访问详细标题",
      hotel_key_access_detail_list_label_date: "日期",
      hotel_key_access_detail_list_button_delete: "删除",
      hotel_key_access_detail_list_button_add: "添加",
      hotel_key_access_detail_list_guide_empty: "列表为空",
      hotel_key_access_detail_add_title: "添加访问详细信息",
      hotel_key_access_detail_add_label_access_title: "访问标题",
      hotel_key_access_detail_add_placeholder_select_box_choose: "选择",
      hotel_key_access_detail_add_label_language_code: "语言代码",
      hotel_key_access_detail_add_select_kr: "韩国",
      hotel_key_access_detail_add_select_en: "CN",
      hotel_key_access_detail_add_select_cn: "中国",
      hotel_key_access_detail_add_select_jp: "J.P",
      hotel_key_access_detail_add_label_access_detail_title: "访问详细标题",
      hotel_key_access_detail_add_label_content: "内容",
      hotel_key_access_detail_add_button_go_to_list: "前往列表",
      hotel_key_access_detail_add_button_add: "添加",
      hotel_key_access_detail_add_validation_hotel_access_area_id: "* 请选择访问标题。",
      hotel_key_access_detail_add_validation_language_code: "* 请选择语言代码。",
      hotel_key_access_detail_add_validation_title: "* 请输入访问详细信息标题。",
      hotel_key_access_detail_add_validation_content: "* 请输入详细信息。",
      hotel_key_access_detail_detail_title: "访问详情",
      hotel_key_access_detail_detail_label_date: "更新日期",
      hotel_key_access_detail_detail_label_access_title: "访问标题",
      hotel_key_access_detail_detail_placeholder_select_box_choose: "选择",
      hotel_key_access_detail_detail_label_language_code: "语言代码",
      hotel_key_access_detail_detail_select_kr: "韩国",
      hotel_key_access_detail_detail_select_en: "CN",
      hotel_key_access_detail_detail_select_cn: "中国",
      hotel_key_access_detail_detail_select_jp: "J.P",
      hotel_key_access_detail_detail_label_access_detail_title: "访问详细标题",
      hotel_key_access_detail_detail_label_content: "内容",
      hotel_key_access_detail_detail_button_go_to_list: "前往列表",
      hotel_key_access_detail_detail_button_cancel: "取消",
      hotel_key_access_detail_detail_button_delete: "删除",
      hotel_key_access_detail_detail_button_edit: "编辑",
      hotel_key_access_detail_detail_button_ok: "好的",
      hotel_key_access_detail_edit_validation_language_code: "* 请选择语言代码。",
      hotel_key_access_detail_edit_validation_title: "* 请输入访问详细信息标题。",
      hotel_key_access_detail_edit_validation_content: "* 请输入详细信息。",
      hotel_key_reservation_access_add_validation_type: "* 请选择类型。",
      hotel_key_reservation_access_add_validation_code: "* 请选择一个代码。",
      hotel_key_reservation_access_add_validation_name: "* 请输入房间名称。",
      hotel_key_reservation_access_add_validation_description: "* 请输入详细信息。",
      hotel_key_reservation_access_add_validation_checked_list: "* 请检查预约访问权限列表。",
      hotel_key_reservation_access_edit_validation_checked_list: "* 请检查预约访问权限列表。",
      hotel_key_setting_detail_title: "按键设置",
      hotel_key_setting_detail_label_update_date: "更新日期",
      hotel_key_setting_detail_label_card_key: "卡钥匙",
      hotel_key_setting_detail_label_card_key_type: "卡钥匙类型",
      hotel_key_setting_detail_label_card_key_ip: "卡密钥IP",
      hotel_key_setting_detail_label_card_key_port: "卡钥匙端口",
      hotel_key_setting_detail_label_card_key_path: "卡钥匙路径",
      hotel_key_setting_detail_label_card_key_id: "卡钥匙 ID",
      hotel_key_setting_detail_label_card_key_password: "卡钥匙密码",
      hotel_key_setting_detail_label_special_guest: "宾客专区",
      hotel_key_setting_detail_label_special_staff: "员工专区",
      hotel_key_setting_detail_label_rssi_guest: "访客 RSSI",
      hotel_key_setting_detail_label_rrssi_staff: "员工 RSSI",
      hotel_key_setting_detail_label_room_show_update_flag: "房间显示更新标志",
      hotel_key_setting_detail_label_room_open_update_flag: "房间打开更新标志",
      hotel_key_setting_detail_label_room_show_key: "前台发放房间钥匙",
      hotel_key_setting_detail_button_yes: "是的",
      hotel_key_setting_detail_button_no: "不",
      hotel_key_setting_detail_label_key_expired_time: "密钥过期时间（小时：分钟）",
      hotel_key_setting_detail_label_accompany_room_to_room_issue: "陪伴房间到房间问题",
      hotel_key_setting_detail_label_room_to_room_not_available_time: "房间到房间接受等待时间（分钟）",
      hotel_key_setting_detail_label_share_key_issue: "分享关键问题",
      hotel_key_setting_detail_label_accompany_share_key_issue: "伴随分享关键问题",
      hotel_key_setting_detail_label_share_key_not_available_time: "共享密钥接受等待时间（分钟）",
      hotel_key_setting_detail_label_share_key_room_key_issued_max_count: "房间钥匙问题最大计数",
      hotel_key_setting_detail_label_test_room_number: "测试室编号",
      hotel_key_setting_detail_button_cancel: "取消",
      hotel_key_setting_detail_button_edit: "编辑",
      hotel_key_setting_edit_title: "按键设置",
      hotel_key_setting_edit_label_update_date: "更新日期",
      hotel_key_setting_edit_label_card_key: "卡钥匙",
      hotel_key_setting_edit_label_card_key_type: "卡钥匙类型",
      hotel_key_setting_edit_placeholder_select_box_choose: "选择",
      hotel_key_setting_edit_select_messerschmitt: "梅塞施密特",
      hotel_key_setting_edit_select_racos: "拉科斯",
      hotel_key_setting_edit_select_racos_cms: "拉科斯内容管理系统",
      hotel_key_setting_edit_select_assa_abloy: "亚萨合莱",
      hotel_key_setting_edit_select_none: "没有任何",
      hotel_key_setting_edit_label_card_key_ip: "卡密钥IP",
      hotel_key_setting_edit_placeholder_card_key_ip: "例如）127.0.0.1",
      hotel_key_setting_edit_label_card_key_port: "卡钥匙端口",
      hotel_key_setting_edit_placeholder_card_key_port: "例）8080",
      hotel_key_setting_edit_label_card_key_path: "卡钥匙路径",
      hotel_key_setting_edit_label_card_key_id: "卡钥匙 ID",
      hotel_key_setting_edit_label_card_key_password: "卡钥匙密码",
      hotel_key_setting_edit_label_special_guest: "宾客专区",
      hotel_key_setting_edit_label_special_staff: "员工专区",
      hotel_key_setting_edit_label_rssi_guest: "访客 RSSI",
      hotel_key_setting_edit_label_rrssi_staff: "员工 RSSI",
      hotel_key_setting_edit_label_room_show_update_flag: "房间显示更新标志",
      hotel_key_setting_edit_label_room_show_key: "前台客房展示关键问题",
      hotel_key_setting_edit_button_yes: "是的",
      hotel_key_setting_edit_button_no: "不",
      hotel_key_setting_edit_label_test_room_number: "测试室编号",
      hotel_key_setting_edit_button_cancel: "取消",
      hotel_key_setting_edit_button_ok: "好的",
      hotel_key_setting_edit_validation_card_key_type: "* 请选择卡钥匙类型。",
      hotel_key_setting_edit_validation_card_key_IP: "* 请输入卡密IP。",
      hotel_key_setting_edit_validation_card_key_port: "* 请输入卡钥匙端口。",
      hotel_key_setting_edit_validation_card_key_path: "* 请输入卡钥匙路径。",
      hotel_key_setting_edit_validation_card_key_client_id: "* 请输入您的卡钥匙 ID。",
      hotel_key_setting_edit_validation_card_key_client_password: "* 请输入您的卡钥匙密码。",
      hotel_key_setting_edit_validation_special_area_for_guest: "* 请进入宾客专区。",
      hotel_key_setting_edit_validation_special_area_for_staff: "* 请进入员工专区。",
      hotel_key_setting_edit_validation_RSSI_for_guest: "* 请输入客户RSSI。",
      hotel_key_setting_edit_validation_RSSI_for_staff: "* 请输入员工RSSI。",
      hotel_key_setting_edit_validation_room_show_flag: "* 请选择显示房间表演更新。",
      hotel_key_setting_edit_validation_room_show_issued_front_desk: "* 请选择前台房间显示关键问题。",
      hotel_key_setting_edit_validation_key_expired_time: "* 请输入密钥过期时间。",
      hotel_key_setting_edit_validation_room_to_room_not_available_time: "* 请输入逐室验收的等待时间。",
      hotel_key_setting_edit_validation_is_share_key_issue: "* 选择发布共享密钥。",
      hotel_key_setting_edit_validation_share_key_not_available_time: "* 请输入共享密钥接受的等待时间。",
      hotel_key_setting_edit_validation_room_key_issued_max_count: "* 请输入发放的房间钥匙的最大数量。",
      hotel_key_setting_edit_validation_test_room_no: "* 请输入考场号码。",
      hotel_key_reservation_access_list_title: "访问链接",
      hotel_key_reservation_access_list_label_type: "类型",
      hotel_key_reservation_access_list_label_name: "姓名",
      hotel_key_reservation_access_list_label_code: "代码",
      hotel_key_reservation_access_list_label_access_area: "访问区",
      hotel_key_reservation_access_list_label_date: "日期",
      hotel_key_reservation_access_list_label_description: "描述",
      hotel_key_reservation_access_list_button_cancel: "删除",
      hotel_key_reservation_access_list_button_add: "添加",
      hotel_key_reservation_access_list_guide_empty: "列表为空",
      hotel_key_reservation_access_add_title: "添加访问链接",
      hotel_key_reservation_access_add_label_type: "类型",
      hotel_key_reservation_access_add_placeholder_select_box_choose: "选择",
      hotel_key_reservation_access_add_select_transaction: "交易",
      hotel_key_reservation_access_add_select_package: "包裹",
      hotel_key_reservation_access_add_label_code: "代码",
      hotel_key_reservation_access_add_label_name: "姓名",
      hotel_key_reservation_access_add_label_description: "描述",
      hotel_key_reservation_access_add_label_list: "预约访问列表",
      hotel_key_reservation_access_add_label_list_title: "标题",
      hotel_key_reservation_access_add_label_list_type: "类型",
      hotel_key_reservation_access_add_label_list_content: "内容",
      hotel_key_reservation_access_add_label_list_position_code: "职位代码",
      hotel_key_reservation_access_add_label_list_position_name: "职位名称",
      hotel_key_reservation_access_add_label_list_remark: "评论",
      hotel_key_reservation_access_add_button_go_to_list: "前往列表",
      hotel_key_reservation_access_add_button_add: "添加",
      hotel_key_reservation_access_detail_title: "访问链接信息",
      hotel_key_reservation_access_detail_label_type: "类型",
      hotel_key_reservation_access_detail_label_code: "代码",
      hotel_key_reservation_access_detail_label_name: "姓名",
      hotel_key_reservation_access_detail_label_description: "描述",
      hotel_key_reservation_access_detail_label_list: "预约访问列表",
      hotel_key_reservation_access_detail_label_list_title: "标题",
      hotel_key_reservation_access_detail_label_list_type: "类型",
      hotel_key_reservation_access_detail_label_list_content: "内容",
      hotel_key_reservation_access_detail_label_list_position_code: "职位代码",
      hotel_key_reservation_access_detail_label_list_position_name: "职位名称",
      hotel_key_reservation_access_detail_label_list_remark: "评论",
      hotel_key_reservation_access_detail_button_go_to_list: "前往列表",
      hotel_key_reservation_access_detail_button_delete: "删除",
      hotel_key_reservation_access_detail_button_edit: "编辑",
      hotel_key_reservation_access_edit_title: "访问链接信息",
      hotel_key_reservation_access_edit_label_type: "类型",
      hotel_key_reservation_access_edit_placeholder_select_box_choose: "选择",
      hotel_key_reservation_access_edit_select_transaction: "交易",
      hotel_key_reservation_access_edit_select_package: "包裹",
      hotel_key_reservation_access_edit_label_code: "代码",
      hotel_key_reservation_access_edit_label_description: "描述",
      hotel_key_reservation_access_edit_label_list: "预约访问列表",
      hotel_key_reservation_access_edit_label_list_title: "标题",
      hotel_key_reservation_access_edit_label_list_type: "类型",
      hotel_key_reservation_access_edit_label_list_content: "内容",
      hotel_key_reservation_access_edit_label_list_position_code: "职位代码",
      hotel_key_reservation_access_edit_label_list_position_name: "职位名称",
      hotel_key_reservation_access_edit_label_list_remark: "评论",
      hotel_key_reservation_access_edit_button_cancel: "取消",
      hotel_key_reservation_access_edit_button_delete: "删除",
      hotel_key_reservation_access_edit_button_ok: "好的",
      hotel_terms_list_title: "术语表",
      hotel_terms_list_select_type: "类型（全部）",
      hotel_terms_list_select_hotel_terms: "酒店条款",
      hotel_terms_list_select_hotel_privacy_policies: "酒店隐私政策",
      hotel_terms_list_select_reservation_terms: "预订条款",
      hotel_terms_list_select_reservation_privacy_policies: "预订隐私政策",
      hotel_terms_list_select_language_code: "语言代码（全部）",
      hotel_terms_list_select_ko: "KO",
      hotel_terms_list_select_en: "CN",
      hotel_terms_list_select_cn: "中国",
      hotel_terms_list_select_jp: "J.P",
      hotel_terms_list_label_id: "ID",
      hotel_terms_list_label_language_code: "语言代码",
      hotel_terms_list_label_type: "类型",
      hotel_terms_list_label_title: "标题",
      hotel_terms_list_label_version: "版本",
      hotel_terms_list_label_link_text: "链接文字",
      hotel_terms_list_label_created_at: "创建于",
      hotel_terms_list_select_posted: "已发布（全部）",
      hotel_terms_list_select_yes: "是的",
      hotel_terms_list_select_no: "不",
      hotel_terms_list_placeholder_search: "搜索...",
      hotel_terms_list_guide_empty: "列表为空",
      hotel_terms_list_button_delete: "删除",
      hotel_terms_list_button_add: "添加",
      hotel_terms_add_title: "添加条款",
      hotel_terms_add_label_language_code: "语言代码",
      hotel_terms_add_placeholder_select_box_select: "选择",
      hotel_terms_add_select_ko: "KO",
      hotel_terms_add_select_en: "CN",
      hotel_terms_add_select_cn: "中国",
      hotel_terms_add_select_jp: "J.P",
      hotel_terms_add_label_type: "类型",
      hotel_terms_add_select_hotel_terms: "酒店条款",
      hotel_terms_add_select_hotel_privacy_policies: "酒店隐私政策",
      hotel_terms_add_select_reservation_terms: "预订条款",
      hotel_terms_add_select_reservation_privacy_policies: "预订隐私政策",
      hotel_terms_add_label_title: "标题",
      hotel_terms_add_label_required: "必需的",
      hotel_terms_add_button_yes: "是的",
      hotel_terms_add_button_no: "不",
      hotel_terms_add_label_description: "描述",
      hotel_terms_add_label_link_text: "链接文字",
      hotel_terms_add_label_posted: "已发布",
      hotel_terms_add_label_version: "版本",
      hotel_terms_add_label_content_type: "内容类型",
      hotel_terms_add_select_file: "文件",
      hotel_terms_add_select_text: "文本",
      hotel_terms_add_label_content: "内容",
      hotel_terms_add_label_file_url: "文件网址",
      hotel_terms_add_button_upload: "上传",
      hotel_terms_add_button_go_to_list: "前往列表",
      hotel_terms_add_button_add: "添加",
      hotel_terms_add_validation_language_code: "* 请选择语言代码。",
      hotel_terms_add_validation_type: "* 请选择类型。",
      hotel_terms_add_validation_title: "* 请输入您的头衔。",
      hotel_terms_add_validation_required: "* 请选择是否需要。",
      hotel_terms_add_validation_description: "* 请输入描述。",
      hotel_terms_add_validation_link_text: "* 请输入链接文字。",
      hotel_terms_add_validation_posted: "* 请选择是否发帖。",
      hotel_terms_add_validation_version: "* 请输入版本。",
      hotel_terms_add_validation_content_type: "* 请选择内容类型。",
      hotel_terms_add_validation_content: "* 请输入详细信息。",
      hotel_terms_add_validation_hotel_terms_file_url: "* 请上传文件。",
      hotel_terms_detail_title: "条款信息",
      hotel_terms_detail_label_terms_id: "条款 ID",
      hotel_terms_detail_label_language_code: "语言代码",
      hotel_terms_detail_select_ko: "KO",
      hotel_terms_detail_select_en: "CN",
      hotel_terms_detail_select_cn: "中国",
      hotel_terms_detail_select_jp: "J.P",
      hotel_terms_detail_label_type: "类型",
      hotel_terms_detail_select_hotel_terms: "酒店条款",
      hotel_terms_detail_select_hotel_privacy_policies: "酒店隐私政策",
      hotel_terms_detail_select_reservation_terms: "预订条款",
      hotel_terms_detail_select_reservation_privacy_policies: "预订隐私政策",
      hotel_terms_detail_label_version: "版本",
      hotel_terms_detail_label_title: "标题",
      hotel_terms_detail_label_posted: "已发布",
      hotel_terms_detail_button_yes: "是的",
      hotel_terms_detail_button_no: "不",
      hotel_terms_detail_label_required: "必需的",
      hotel_terms_detail_label_description: "描述",
      hotel_terms_detail_label_link_text: "链接文字",
      hotel_terms_detail_label_content_type: "内容类型",
      hotel_terms_detail_select_file: "文件",
      hotel_terms_detail_select_text: "文本",
      hotel_terms_detail_label_file_url: "文件网址",
      hotel_terms_detail_button_upload: "上传",
      hotel_terms_detail_label_content: "内容",
      hotel_terms_detail_label_created_at: "创建于",
      hotel_terms_detail_label_updated_at: "更新于",
      hotel_terms_detail_button_go_to_list: "前往列表",
      hotel_terms_edit_button_cancel: "取消",
      hotel_terms_detail_button_delete: "删除",
      hotel_terms_edit_button_download: "下载",
      hotel_terms_detail_button_edit: "编辑",
      hotel_terms_edit_button_ok: "好的",
      hotel_terms_edit_validation_language_code: "* 请选择语言代码。",
      hotel_terms_edit_validation_type: "* 请选择类型。",
      hotel_terms_edit_validation_title: "* 请输入您的头衔。",
      hotel_terms_edit_validation_required: "* 请选择是否需要。",
      hotel_terms_edit_validation_description: "* 请输入描述。",
      hotel_terms_edit_validation_link_text: "* 请输入链接文字。",
      hotel_terms_edit_validation_posted: "* 请选择是否发帖。",
      hotel_terms_edit_validation_version: "* 请输入版本。",
      hotel_terms_edit_validation_content_type: "* 请选择内容类型。",
      hotel_terms_edit_validation_content: "* 请输入详细信息。",
      hotel_terms_edit_validation_hotel_terms_file_url: "* 请上传文件。",
      hotel_agreement_list_title: "协议历史列表",
      hotel_agreement_list_placeholder_select_box_choose: "选择",
      hotel_agreement_list_select_email: "电子邮件",
      hotel_agreement_list_select_content: "内容",
      hotel_agreement_list_select_confirmation_no: "确认号",
      hotel_agreement_list_placeholder_search: "搜索...",
      hotel_agreement_list_label_no: "不。",
      hotel_agreement_list_label_terms_type: "条款类型",
      hotel_agreement_list_label_terms_id: "条款 ID",
      hotel_agreement_list_label_terms_title: "条款标题",
      hotel_agreement_list_label_confirmation_no: "确认号",
      hotel_agreement_list_label_email: "电子邮件",
      hotel_agreement_list_label_name: "姓名",
      hotel_agreement_list_label_created_at: "创建于",
      hotel_agreement_detail_title: "协议历史信息",
      hotel_agreement_detail_label_agreement: "协议",
      hotel_agreement_detail_label_agreement_at: "协议确认于",
      hotel_agreement_detail_label_user: "用户",
      hotel_agreement_detail_label_email: "电子邮件",
      hotel_agreement_detail_label_name: "姓名",
      hotel_agreement_detail_label_language_code: "语言代码",
      hotel_agreement_detail_label_reservation: "预订",
      hotel_agreement_detail_label_confirmation_no: "确认号",
      hotel_agreement_detail_label_terms: "条款",
      hotel_agreement_detail_label_terms_id: "条款 ID",
      hotel_agreement_detail_label_type: "类型",
      hotel_agreement_detail_label_version: "版本",
      hotel_agreement_detail_label_title: "标题",
      hotel_agreement_detail_label_required: "必需的",
      hotel_agreement_detail_button_go_to_list: "前往列表",
      hotel_event_list_title: "事件",
      hotel_event_list_label_category_all: "类别（全部）",
      hotel_event_list_label_search_type_all: "搜索类型（全部）",
      hotel_event_list_label_title: "标题",
      hotel_event_list_label_description: "描述",
      hotel_event_list_placeholder_search: "搜索...",
      hotel_event_list_label_no: "不。",
      hotel_event_list_label_category: "类别",
      hotel_event_list_label_date: "日期",
      hotel_event_list_guide_empty: "列表为空",
      hotel_event_list_button_delete: "删除",
      hotel_event_list_button_add: "添加",
      hotel_event_add_validation_category: "* 请选择类别。",
      hotel_event_add_validation_templates: "* 请选择模板。",
      hotel_event_add_validation_users: "* 请选择用户。",
      hotel_event_add_title: "事件添加",
      hotel_input_detail_label_details: "细节",
      hotel_event_add_label_category: "类别",
      hotel_event_add_label_templates: "模板",
      hotel_event_add_select_all: "全部",
      hotel_event_add_label_notification_time: "通知时间",
      hotel_event_add_label_title: "标题",
      hotel_event_add_label_content: "内容",
      hotel_event_add_label_description: "描述",
      hotel_event_add_label_template_language: "模板语言",
      hotel_event_add_template_add_empty_guide: "请添加内容",
      hotel_event_add_label_user: "用户",
      hotel_event_add_label_search_time: "Search Time",
      hotel_event_add_label_myconect_id: "我的连接 ID",
      hotel_event_add_label_check_in: "Check In",
      hotel_event_add_placeholder_search: "搜索...",
      hotel_event_add_label_no: "不。",
      hotel_event_add_label_first_name: "名",
      hotel_event_add_label_last_name: "姓",
      hotel_event_add_label_email: "电子邮件",
      hotel_event_add_label_tile: "标题",
      hotel_event_add_label_check_out: "Check Out",
      hotel_event_add_guide_empty: "列表为空",
      hotel_event_add_button_go_to_list: "前往列表",
      hotel_event_add_button_delete: "删除",
      hotel_event_add_button_ok: "好的",
      hotel_event_add_user_validation_users: "* 请选择用户。",
      hotel_event_add_user_title: "事件添加",
      hotel_event_add_user_label_user: "用户",
      hotel_event_add_user_label_current_time: "Current Time",
      hotel_event_add_user_label_search_time: "Search Time",
      hotel_event_add_user_label_all: "全部",
      hotel_event_add_user_label_myconect_id: "我的连接 ID",
      hotel_event_add_user_label_profile_id: "个人资料 ID",
      hotel_event_add_user_label_check_in: "Check In",
      hotel_event_add_user_placeholder_search: "搜索...",
      hotel_event_add_user_label_floor: "地面",
      hotel_event_add_user_label_reservation_status: "预订状态",
      hotel_event_add_user_label_room_no: "房间号。",
      hotel_event_add_user_label_reserved: "预订的",
      hotel_event_add_user_label_due_in: "Due in",
      hotel_event_add_user_label_due_out: "Due out",
      hotel_event_add_user_label_in_house: "内部",
      hotel_event_add_user_label_checked_out: "Checked out",
      hotel_event_add_user_label_title: "标题",
      hotel_event_add_user_label_no: "不。",
      hotel_event_add_user_label_first_name: "名",
      hotel_event_add_user_label_last_name: "姓",
      hotel_event_add_user_label_email: "电子邮件",
      hotel_event_add_user_label_check_out: "Check Out",
      hotel_event_add_user_guide_empty: "列表为空",
      hotel_event_add_user_button_cancel: "取消",
      hotel_event_add_user_button_ok: "好的",
      hotel_event_detail_title: "活动详情",
      hotel_event_detail_label_details: "细节",
      hotel_event_detail_label_category: "类别",
      hotel_event_detail_label_templates: "模板",
      hotel_event_detail_label_notification_time: "通知时间",
      hotel_event_detail_label_updated_at: "更新于",
      hotel_event_detail_label_title: "标题",
      hotel_event_detail_label_content: "内容",
      hotel_event_detail_label_description: "描述",
      hotel_event_detail_label_templates_language: "模板语言",
      hotel_event_detail_template_add_empty_guide: "请添加内容",
      hotel_event_detail_label_all: "全部",
      hotel_event_detail_label_myconect_id: "我的连接 ID",
      hotel_event_detail_label_profile_id: "个人资料 ID",
      hotel_event_detail_placeholder_search: "搜索...",
      hotel_event_detail_label_no: "不。",
      hotel_event_detail_label_first_name: "名",
      hotel_event_detail_label_last_name: "姓",
      hotel_event_detail_label_email: "电子邮件",
      hotel_event_detail_label_gender: "标题",
      hotel_event_detail_label_send: "发送",
      hotel_event_detail_label_check_up: "清理",
      hotel_event_detail_label_check_in: "Check In",
      hotel_event_detail_label_check_out: "Check Out",
      hotel_event_detail_label_success: "成功",
      hotel_event_detail_label_failed: "失败的",
      hotel_event_detail_guide_empty: "列表为空",
      hotel_event_detail_button_go_to_list: "前往列表",
      hotel_event_detail_button_delete: "删除",
      hotel_event_detail_button_edit: "编辑",
      hotel_event_edit_validation_category: "* 请选择类别。",
      hotel_event_edit_validation_templates: "* 请选择模板。",
      hotel_event_edit_validation_users: "* 请选择用户。",
      hotel_event_edit_title: "事件编辑",
      hotel_event_edit_label_details: "细节",
      hotel_event_edit_label_category: "类别",
      hotel_event_edit_label_templates: "模板",
      hotel_event_edit_label_all: "全部",
      hotel_event_edit_label_notification_time: "通知时间",
      hotel_event_edit_label_title: "标题",
      hotel_event_edit_label_content: "内容",
      hotel_event_edit_label_description: "描述",
      hotel_input_edit_label_templates_language: "模板语言",
      hotel_event_edit_template_add_empty_guide: "请添加内容",
      hotel_event_edit_label_user: "用户",
      hotel_event_edit_label_myconect_id: "我的连接 ID",
      hotel_event_edit_label_check_in: "Check In",
      hotel_event_edit_label_check_out: "",
      hotel_event_edit_placeholder_search: "搜索...",
      hotel_event_edit_button_delete: "删除",
      hotel_event_edit_label_no: "不。",
      hotel_event_edit_label_first_name: "名",
      hotel_event_edit_label_last_name: "姓",
      hotel_event_edit_label_email: "电子邮件",
      hotel_event_edit_label_gender: "标题",
      hotel_event_edit_guide_empty: "列表为空",
      hotel_event_edit_button_cancel: "取消",
      hotel_event_edit_button_ok: "好的",
      hotel_event_category_list_title: "活动类别",
      hotel_event_category_list_label_all: "全部",
      hotel_event_category_list_label_type: "类型",
      hotel_event_category_list_label_show: "展示",
      hotel_event_category_list_placeholder_search: "搜索...",
      hotel_event_category_list_label_no: "不。",
      hotel_event_category_list_label_date: "日期",
      hotel_event_category_list_guide_empty: "列表为空",
      hotel_event_category_list_button_delete: "删除",
      hotel_event_category_list_button_add: "添加",
      hotel_event_category_add_title: "活动类别添加",
      hotel_event_category_add_label_type: "类型",
      hotel_event_category_add_label_show: "展示",
      hotel_event_category_add_label_yes: "是的",
      hotel_event_category_add_label_no: "不",
      hotel_event_category_add_button_go_to_list: "前往列表",
      hotel_event_category_add_button_add: "添加",
      hotel_event_category_add_validation_type: "* 请输入类型。",
      hotel_event_category_add_validation_show: "* 请选择演出。",
      hotel_event_category_detail_title: "活动类别详细信息",
      hotel_event_category_detail_label_detail: "细节",
      hotel_event_category_detail_button_edit: "编辑",
      hotel_event_category_detail_label_type: "类型",
      hotel_event_category_detail_label_show: "展示",
      hotel_event_category_detail_label_date: "日期",
      hotel_event_category_detail_button_go_to_list: "前往列表",
      hotel_event_category_detail_button_delete: "删除",
      hotel_event_category_detail_label_yes: "是的",
      hotel_event_category_detail_label_no: "不",
      hotel_event_category_detail_label_template: "模板",
      hotel_event_category_detail_button_add: "添加",
      hotel_event_category_detail_label_number: "不。",
      hotel_event_category_detail_label_title: "标题",
      hotel_event_category_detail_guide_empty: "列表为空",
      hotel_event_category_edit_title: "事件类别编辑",
      hotel_event_category_edit_label_type: "类型",
      hotel_event_category_edit_label_show: "展示",
      hotel_event_category_edit_label_yes: "是的",
      hotel_event_category_edit_label_no: "不",
      hotel_event_category_edit_label_date: "日期",
      hotel_event_category_edit_button_cancel: "取消",
      hotel_event_category_edit_button_delete: "删除",
      hotel_event_category_edit_button_ok: "好的",
      hotel_event_category_edit_validation_type: "* 请输入类型。",
      hotel_event_category_template_add_title: "事件类别模板添加",
      hotel_event_category_template_add_label_title: "标题",
      hotel_event_category_template_add_label_content: "内容",
      hotel_event_category_template_add_label_description: "描述",
      hotel_event_category_template_add_label_language: "语言",
      hotel_event_category_template_add_button_add: "添加",
      hotel_event_category_template_add_button_delete: "删除",
      hotel_event_category_template_add_empty_guide: "请添加内容",
      hotel_event_category_template_add_button_go_to_list: "前往列表",
      hotel_event_category_template_add_button_ok: "好的",
      hotel_event_category_template_add_validation_title: "* 请输入标题。",
      hotel_event_category_template_add_validation_content: "* 请输入内容。",
      hotel_event_category_template_add_validation_description: "* 请输入描述。",
      hotel_event_category_template_detail_title: "事件类别模板详细信息",
      hotel_event_category_template_detail_label_templates: "模板",
      hotel_event_category_template_detail_label_category: "类别",
      hotel_event_category_template_detail_label_title: "标题",
      hotel_event_category_template_detail_label_content: "内容",
      hotel_event_category_template_detail_label_description: "描述",
      hotel_event_category_template_detail_label_date: "日期",
      hotel_event_category_template_detail_label_language: "语言",
      hotel_event_category_template_detail_empty_guide: "请添加内容",
      hotel_event_category_template_detail_button_go_to_list: "前往列表",
      hotel_event_category_template_detail_button_delete: "删除",
      hotel_event_category_template_detail_button_edit: "编辑",
      hotel_event_category_template_edit_title: "事件类别模板编辑",
      hotel_event_category_template_edit_label_title: "标题",
      hotel_event_category_template_edit_label_content: "内容",
      hotel_event_category_template_edit_label_description: "描述",
      hotel_event_category_template_edit_label_date: "日期",
      hotel_event_category_template_edit_label_language: "语言",
      hotel_event_category_template_edit_button_add: "添加",
      hotel_event_category_template_edit_button_delete: "删除",
      hotel_event_category_template_edit_empty_guide: "请添加内容",
      hotel_event_category_template_edit_button_go_to_list: "前往列表",
      hotel_event_category_template_edit_button_ok: "好的",
      hotel_event_category_template_edit_validation_title: "* 请输入标题。",
      hotel_event_category_template_edit_validation_content: "* 请输入内容。",
      hotel_event_category_template_edit_validation_description: "* 请输入描述。",
      hotel_staff_user_list_title: "人员名单",
      hotel_staff_user_list_select_staff: "员工（全部）",
      hotel_staff_user_list_select_all: "全部",
      hotel_staff_user_list_select_department: "部门",
      hotel_staff_user_list_select_team: "团队",
      hotel_staff_user_list_select_level: "等级",
      hotel_staff_user_list_select_task: "任务",
      hotel_staff_user_list_select_staff_number: "员工人数",
      hotel_staff_user_list_select_email: "电子邮件",
      hotel_staff_user_list_placeholder_search: "搜索...",
      hotel_staff_user_list_label_department: "部门",
      hotel_staff_user_list_label_team: "团队",
      hotel_staff_user_list_label_level: "等级",
      hotel_staff_user_list_label_task: "任务",
      hotel_staff_user_list_label_number: "数字",
      hotel_staff_user_list_label_employee_id: "员工ID",
      hotel_staff_user_list_label_available_at: "可用于",
      hotel_staff_user_list_label_expired_at: "过期时间",
      hotel_staff_user_list_label_login_at: "登录于",
      hotel_staff_user_list_label_date: "日期",
      hotel_staff_user_list_button_delete: "删除",
      hotel_staff_user_list_button_add: "添加",
      hotel_staff_user_add_title: "新员工",
      hotel_staff_user_add_guide_click_add: "要添加新员工，请输入所需信息并单击“添加”。",
      hotel_staff_user_add_validation_department: "* 请选择员工部门。",
      hotel_staff_user_add_validation_team: "* 请选择员工团队。",
      hotel_staff_user_add_validation_level: "* 请选择员工职位。",
      hotel_staff_user_add_validation_guest_inquiry_level: "* 请选择员工宾客询问级别。",
      hotel_staff_user_add_validation_number: "* 请输入员工编号。",
      hotel_staff_user_add_validation_task: "* 请输入员工任务。",
      hotel_staff_user_add_validation_number_only: "* 请仅使用数字输入。",
      hotel_staff_user_add_validation_email: "* 请输入员工邮箱。",
      hotel_staff_user_add_validation_email_format: "* 请按照邮箱格式输入。",
      hotel_staff_user_add_validation_password: "* 请输入员工密码。",
      hotel_staff_user_add_validation_character_number_combining: "* 请输入至少 8 个字母和数字组合的字符。",
      hotel_staff_user_add_validation_not_mach: "* 密码不匹配。",
      hotel_staff_user_add_label_type: "类型",
      hotel_staff_user_add_select_full_time: "全职",
      hotel_staff_user_add_select_temporary: "暂时的",
      hotel_staff_user_add_placeholder_select_box_choose: "选择",
      hotel_staff_user_add_label_department: "部门",
      hotel_staff_user_add_select_manage: "管理",
      hotel_staff_user_add_select_concierge: "门房",
      hotel_staff_user_add_select_housekeeping: "家政",
      hotel_staff_user_add_select_front_desk: "前台",
      hotel_staff_user_add_select_valet: "代客泊车",
      hotel_staff_user_add_label_team: "团队",
      hotel_staff_user_add_select_none: "没有任何",
      hotel_staff_user_add_select_guest_service: "客户服务",
      hotel_staff_user_add_label_level: "等级",
      hotel_staff_user_add_select_manager: "经理",
      hotel_staff_user_add_select_supervisor: "导师",
      hotel_staff_user_add_select_staff: "职员",
      hotel_staff_user_add_select_duty_manager: "值班经理",
      hotel_staff_user_add_label_guest_inquiry_level: "宾客询问级别",
      hotel_staff_user_add_label_number: "数字",
      hotel_staff_user_add_placeholder_staff_number: "员工人数",
      hotel_staff_user_add_label_location: "地点",
      hotel_staff_user_add_placeholder_staff_location: "员工位置",
      hotel_staff_user_add_label_task: "任务",
      hotel_staff_user_add_placeholder_staff_task: "员工任务",
      hotel_staff_user_add_label_email: "电子邮件",
      hotel_staff_user_add_placeholder_staff_email: "员工邮箱",
      hotel_staff_user_add_label_password: "密码",
      hotel_staff_user_add_placeholder_staff_password: "员工密码",
      hotel_staff_user_add_label_confirm_password: "确认密码",
      hotel_staff_user_add_placeholder_staff_confirm_password: "确认密码",
      hotel_staff_user_add_label_available_at: "可用于",
      hotel_staff_user_add_label_expired_at: "过期时间",
      hotel_staff_user_add_button_add: "添加",
      hotel_staff_user_add_button_go_to_list: "前往列表",
      hotel_staff_user_detail_title: "员工信息",
      hotel_staff_user_detail_label_update_date: "更新日期",
      hotel_staff_user_detail_label_department: "部门",
      hotel_staff_user_detail_label_team: "团队",
      hotel_staff_user_detail_label_level: "等级",
      hotel_staff_user_detail_label_guest_inquiry_level: "客人询问级别",
      hotel_staff_user_detail_label_task: "任务",
      hotel_staff_user_detail_label_staff_number: "员工人数",
      hotel_staff_user_detail_label_email: "电子邮件",
      hotel_staff_user_detail_label_location: "地点",
      hotel_staff_user_detail_label_room: "客房",
      hotel_staff_user_detail_label_login_at: "登录于",
      hotel_staff_user_detail_label_date: "日期",
      hotel_staff_user_detail_label_available_at: "可用于",
      hotel_staff_user_detail_label_expired_at: "过期时间",
      hotel_staff_user_detail_label_locked_information: "锁定信息",
      hotel_staff_user_detail_label_locked_reason: "锁定原因",
      hotel_staff_user_detail_label_locked_at: "锁定于",
      hotel_staff_user_detail_label_password: "密码",
      hotel_staff_user_detail_label_password_change: "更改密码",
      hotel_staff_user_detail_button_go_to_list: "前往列表",
      hotel_staff_user_detail_button_delete: "删除",
      hotel_staff_user_detail_button_edit: "编辑",
      hotel_staff_user_edit_title: "员工信息",
      hotel_staff_user_edit_label_update_date: "更新日期",
      hotel_staff_user_edit_label_department: "部门",
      hotel_staff_user_edit_placeholder_select_box_choose: "选择",
      hotel_staff_user_edit_validation_department: "* 请选择员工部门。",
      hotel_staff_user_edit_select_manage: "管理",
      hotel_staff_user_edit_select_concierge: "门房",
      hotel_staff_user_edit_select_housekeeping: "家政",
      hotel_staff_user_edit_select_front_desk: "前台",
      hotel_staff_user_edit_select_valet: "代客泊车",
      hotel_staff_user_edit_label_team: "团队",
      hotel_staff_user_edit_select_none: "没有任何",
      hotel_staff_user_edit_select_guest_service: "客户服务",
      hotel_staff_user_edit_label_level: "等级",
      hotel_staff_user_edit_select_manager: "经理",
      hotel_staff_user_edit_select_supervisor: "导师",
      hotel_staff_user_edit_select_staff: "职员",
      hotel_staff_user_edit_select_duty_manager: "值班经理",
      hotel_staff_user_edit_label_task: "任务",
      hotel_staff_user_edit_placeholder_task: "任务",
      hotel_staff_user_edit_label_staff_number: "员工人数",
      hotel_staff_user_edit_placeholder_staff_number: "员工人数",
      hotel_staff_user_edit_label_email: "电子邮件",
      hotel_staff_user_edit_placeholder_email: "电子邮件",
      hotel_staff_user_edit_label_location: "地点",
      hotel_staff_user_edit_placeholder_location: "地点",
      hotel_staff_user_edit_label_room: "客房",
      hotel_staff_user_edit_label_login_at: "登录于",
      hotel_staff_user_edit_label_date: "日期",
      hotel_staff_user_edit_label_available_at: "可用于",
      hotel_staff_user_edit_label_expired_at: "过期时间",
      hotel_staff_user_edit_label_locked_information: "锁定信息",
      hotel_staff_user_edit_label_locked_reason: "锁定原因",
      hotel_staff_user_edit_label_locked_at: "锁定于",
      hotel_staff_user_edit_button_cancel: "取消",
      hotel_staff_user_edit_button_delete: "删除",
      hotel_staff_user_edit_button_ok: "好的",
      contents_failed_unable_connect_server: "无法连接到服务器。 \n请检查您的互联网连接。",
      button_ok: "好的",
      button_cancel: "取消",
      button_failed_login_message: "电子邮件地址和密码不匹配。",
      button_delete_message: "您确定要删除所选信息吗？",
      button_no_select_delete_message: "未选择。 \n请选择要删除的列表。",
      button_no_select_hotel_copy_message: "未选择。\n请选择要复制的酒店。",
      button_only_one_hotel_copy_message: "请仅选择一家酒店。",
      button_cancel_message: "您确定要取消所选信息吗？",
      button_no_select_cancel_message: "未选择。 \n请选择要取消的列表。",
      button_admin_cant_delete_message: "您无法删除 ADMIN。请取消选择。",
      button_manager_cant_delete_message: "您无法删除管理器。 \n请取消选择。",
      button_cant_release_message: "您不能释放该成员。 \n请取消选择。",
      button_withdraw_message: "您想退出吗？",
      button_release_message: "您确定要发布所选信息吗？",
      button_no_select_release_message: "未选择。 \n请选择要发布的列表。",
      button_delete_qna_message: "您确定要删除 QnA 吗？",
      button_update_information_message: "存在匹配信息。 \n您想更新此信息吗？",
      button_delete_information_message: "删除现有信息并更新 \n有新信息。",
      button_insert_information_message: "输入新信息。",
      button_qna_loading_message: "加载中…",
      button_cancel_not_save_message: "如果取消，内容将不会被保存。\n你确定要取消？",
      button_cancel_booking_message: "您确定要取消所选的预订吗？",
      button_delete_owner_message: "您确定要删除所选的主要嘉宾吗？",
      button_success_message: "已进行修改。",
      button_stop_message: "您想停止选定的请求吗？ \n（情况不明）",
      button_no_select_stop_message: "未选择。请选择要停止的列表。",
      button_upload_success_message: "上传成功。 \n[[图像]]",
      button_reservation_message: "有些请求有 \n尚未完成。您想确认这些请求吗？",
      button_amenity_message_title: "编号。",
      button_amenity_message: "您想按如下方式修改相应设施的项目编号吗？",
      button_transfer_user_id_message: "输入来宾 MyCONECT 用户 ID 以重新同步。",
      contents_failed_session_password: "请输入新密码\n至少 8 个字符。",
      contents_session_out_message: "会话超时。 \n请前往登录页面。",
      contents_failed_session_message: "检索会话失败。",
      contents_failed_password_incorrect_message: "当前密码不正确。",
      contents_failed_get_hotel_detail: "无法检索酒店详细信息。",
      contents_failed_get_region_detail: "无法检索区域详细信息。",
      contents_failed_get_region_list: "检索区域列表失败",
      contents_failed_get_country_detail: "无法检索国家/地区详细信息。",
      contents_failed_get_country_list: "检索国家/地区列表失败",
      contents_failed_get_city_detail: "无法检索城市详细信息。",
      contents_failed_get_city_list: "检索城市列表失败",
      contents_failed_edit_hotel: "编辑酒店失败。",
      contents_failed_edit_region: "无法编辑区域。",
      contents_failed_edit_country: "无法编辑国家/地区。",
      contents_failed_edit_city: "编辑城市失败。",
      contents_failed_edit_amenity: "无法编辑便利设施。",
      contents_failed_add_amenity: "无法添加便利设施。",
      contents_failed_edit_recommend_app: "编辑推荐应用失败。",
      contents_failed_add_recommend_app: "无法添加推荐应用。",
      contents_failed_edit_id_recognition_information: "编辑ID识别信息失败。",
      contents_failed_add_id_recognition_information: "无法添加ID识别信息。",
      contents_failed_get_list: "无法获取对应的页面列表。",
      contents_failed_logout: "注销失败。",
      contents_failed_get_informarion: "获取用户信息失败。",
      contents_failed_edit_information: "无法编辑信息。",
      contents_failed_modify: "密码更改失败。",
      contents_failed_add_user: "无法添加用户。请检查该电子邮件是否已存在。",
      contents_failed_transfer: "链接传输失败。",
      contents_failed_change_duty: "职责变更失败。",
      contents_failed_edit_user: "无法编辑用户。",
      contents_failed_get_withdraw: "提现失败。",
      contents_failed_add_version: "无法添加版本。",
      contents_failed_edit_version: "无法编辑版本。",
      contents_failed_delete: "删除失败。",
      contents_failed_image: "获取图像信息失败。",
      contents_failed_get_area_list: "获取特殊区域列表失败。",
      contents_failed_get_hotel_detail_list: "检索酒店详细列表失败。",
      contents_failed_get_hotel_detail_detail: "无法获取酒店详细信息。",
      contents_failed_get_facility_icon_list: "无法检索设施图标列表。",
      contents_failed_get_hotel_amenity_list: "无法检索酒店设施列表。",
      contents_failed_add_hotel_detail_content: "无法添加酒店详细内容。",
      contents_failed_add_hotel_detail_amenity: "无法添加酒店详细设施。",
      contents_failed_get_hotel_recommend_application_list: "检索酒店推荐应用列表失败。",
      contents_failed_add_hotel_detail_recommend_application: "无法添加酒店推荐应用程序。",
      contents_failed_get_hotel_img_detail: "无法获取酒店图片详细信息。",
      contents_failed_get_facility_img_detail: "无法获取酒店设施图像详细信息。",
      contents_failed_add_hotel_img: "无法添加酒店图片。",
      contents_failed_add_facility_img: "无法添加酒店设施图像。",
      contents_failed_add_room_img: "无法添加房间图像。",
      contents_failed_get_room_amenity_list: "无法检索房间设施列表。",
      contents_failed_add_room_detail_amenity: "无法添加房间详细设施。",
      contents_failed_get_room_img_detail: "无法获取房间图像详细信息。",
      contents_failed_edit_hotel_detail_content: "无法编辑酒店详细内容。",
      contents_failed_edit_room: "无法编辑房间。",
      contents_failed_edit_room_detail_content: "无法编辑房间详细内容。",
      contents_failed_add_package: "无法添加包。",
      contents_failed_add_package_detail: "无法添加包裹详细信息。",
      contents_failed_get_package_detail: "检索包失败。",
      contents_failed_get_package_detail_detail: "无法检索包裹详细信息。",
      contents_failed_get_access_detail_detail: "无法检索访问详细信息。",
      contents_failed_add_access: "无法添加访问权限。",
      contents_failed_edit_access: "无法编辑访问权限。",
      contents_failed_add_access_detail: "无法添加访问详细信息。",
      contents_failed_add_beacon: "无法添加信标。",
      contents_failed_add_region: "无法添加区域。",
      contents_failed_add_country: "无法添加国家/地区。",
      contents_failed_add_city: "无法添加城市。",
      contents_failed_edit_beacon: "无法编辑信标。",
      contents_failed_add_currency: "无法添加货币。",
      contents_failed_get_currency_detail: "无法获取酒店货币详细信息。",
      contents_not_supported_exchange_rate: "不支持汇率。",
      contents_failed_get_currency_pms: "获取 PMS 酒店货币失败。",
      contents_failed_get_currency_update: "无法更新酒店货币。",
      contents_failed_request_stop: "请求停止失败。",
      contents_failed_upload_file: "上传文件失败。",
      contents_failed_get_count_canceled: "未能获取取消计数板。",
      contents_failed_get_business_rule_list: "获取业务规则列表失败。",
      contents_failed_uptade_myconect: "无法更新 MyCONECT 操作时间设置。",
      contents_failed_get_concierge_myconect: "无法检索 MyCONECT 礼宾列表。",
      contents_request_type_not_exist: "请求类型不存在。",
      contents_failed_get_request_detail: "无法获取请求详细信息。",
      contents_failed_request_stand_by: "请求待机失败。",
      contents_failed_request_pickup: "请求取件失败。",
      contents_failed_request_start: "请求启动失败。",
      contents_failed_request_deliver: "请求交付失败。",
      contents_failed_request_valet_no: "无法请求代客泊车号码",
      contents_failed_get_hotel_setting: "获取酒店设置失败。",
      contents_failed_get_count_request_board: "无法获取请求计数板。",
      contents_failed_get_concierge_process_list: "无法获取礼宾进程列表。",
      contents_failed_get_valet_parking_list: "无法获取代客停车清单。",
      contents_failed_add_parking_area_no: "无法添加停车位号",
      contents_failed_change_veicle_no: "变更车号失败。",
      contents_failed_request_location: "准备车辆失败。",
      contents_failed_request_return: "还车失败。",
      contents_failed_request_handover: "未能将车辆移交给客人。",
      contents_failed_get_business_rule: "未能检索业务规则。",
      contents_failed_get_boundary_line_setting: "无法检索边界线设置。",
      contents_failed_request_confirm: "无法请求“确认”。",
      contents_failed_request_not_available: "无法请求“不可用”。",
      contents_failed_request_not_available_myconect: "无法请求“MyCONECT 不可用”。",
      contents_failed_get_concierge_request_list: "无法检索礼宾请求列表。",
      contents_already_exists: "这种类型已经存在。",
      contents_failed_add_help_for_mobile_key: "无法添加“手机钥匙帮助”。",
      contents_failed_get_help_for_mobile_key: "无法获取“移动密钥帮助”。",
      contents_failed_edit_help_for_mobile_key: "无法编辑“移动密钥帮助”。",
      contents_failed_delete_help_for_mobile_key: "无法删除“移动密钥帮助”信息。",
      contents_failed_add_service_description: "无法添加“服务描述”。",
      contents_failed_get_service_description: "无法获取“服务描述”。",
      contents_failed_edit_service_description: "无法编辑“服务描述”。",
      contents_failed_delete_service_description: "无法删除“服务描述”。",
      contents_failed_add_hotel_information: "添加酒店信息失败。",
      contents_failed_get_hotel_information: "获取酒店信息失败。",
      contents_failed_edit_hotel_information: "无法编辑酒店信息。",
      contents_failed_edit_language_code_translation: "语言已选择。",
      contents_failed_all_language_code_exist: "All translations have been added.",
      contents_failed_delete_hotel_information: "删除酒店信息失败。",
      contents_failed_get_canceled_user_count: "未能按用户数取消。",
      contents_failed_get_canceled_staff_count: "未能通过 MyCONECT 或员工计数取消。",
      contents_failed_get_housekeeping_myconect: "无法获取 Housekeeping MyCONECT 列表。",
      contents_failed_add_multi_myconect: "您选择的日期不正确",
      contents_failed_request_complete: "无法请求“完成”。",
      contents_failed_request_put_off: "无法请求“推迟”。",
      contents_failed_get_amenity_count: "未能检索便利设施计数。",
      contents_failed_get_valet_count: "无法检索代客计数。",
      contents_failed_get_housekeeping_process_list: "无法检索内务处理进度列表。",
      contents_failed_get_boundary_line_request_count: "无法检索边界线请求计数。",
      contents_failed_update_auto: "无法更新绿色运动自动模式时间",
      contents_failed_get_housekeeping_request_list: "无法检索客房服务请求列表。",
      contents_failed_get_room_controller_detail: "无法检索房间控制详细信息。",
      contents_failed_insert_files: "插入文件失败。",
      contents_failed_get_room_to_room_detail: "无法检索房间到房间的详细信息。",
      contents_failed_get_share_key_detail: "无法检索共享密钥详细信息。",
      contents_failed_modify_amenity: "无法修改设施项目编号",
      contents_failed_get_boundary_line_detail: "无法检索边界线详细信息。",
      contents_failed_modify_boundary_line_detail: "无法修改边界线详细信息。",
      contents_failed_modify_boundary_line_unasigned_count: "无法检索未分配的边界线计数。",
      contents_failed_get_business_rule_detail: "无法检索业务规则详细信息。",
      contents_failed_modify_business_rule_detail: "无法修改业务规则详细信息。",
      contents_failed_modify_waiting_time: "修改房间等待时间失败。",
      contents_failed_cancel: "取消失败。",
      contents_failed_add_member_type: "无法添加会员类型。",
      contents_failed_edit_member_type: "无法编辑会员类型。",
      contents_failed_edit_pms: "编辑 PMS 失败。",
      contents_failed_sync: "同步失败。",
      contents_failed_send_reservation_email: "发送预订邮件失败。",
      contents_failed_get_staff_key_link_list: "无法检索员工关键链接列表。",
      contents_failed_get_staff_key_link_type_list: "无法检索员工关键链接类型列表。",
      contents_failed_get_staff_key_link_floor_list: "无法检索员工关键链接楼层列表。",
      contents_failed_get_staff_key_link_zone_list: "无法检索员工关键链接区域列表。",
      contents_failed_get_staff_key_link_code_list: "无法检索员工关键链接代码列表。",
      contents_failed_get_staff_detail: "无法检索员工详细信息。",
      contents_failed_edit_staff_user: "无法编辑员工用户。",
      contents_failed_add_title: "无法添加标题。",
      contents_failed_edit_title: "无法编辑标题。",
      contents_failed_get_list_search_page: "无法检索搜索页面列表。",
      contents_failed_edit_setting: "无法编辑设置。",
      contents_failed_add_staff_key_link: "无法添加员工关键链接。",
      contents_failed_edit_staff_key_link: "无法编辑员工关键链接。",
      contents_failed_edit_hotel_img: "无法编辑酒店图片。",
      contents_failed_edit_facility_img: "无法编辑酒店设施图像。",
      contents_failed_edit_room_img: "无法编辑房间图像。",
      contents_failed_edit_package_detail: "无法编辑包详细信息。",
      contents_failed_edit_access_detail: "无法编辑访问详细信息。",
      contents_failed_add_guest_app_version: "无法添加访客应用程序版本。",
      contents_failed_add_staff_app_version: "添加员工应用程序版本失败。",
      contents_failed_get_rule_information: "检索规则信息失败。",
      contents_failed_edit_rule_information: "无法编辑规则信息。",
      contents_failed_edit_sequence_information: "无法编辑序列。",
      contents_failed_get_rule_schedule_information: "无法检索规则计划信息。",
      contents_failed_edit_rule_schedule_information: "无法编辑规则计划信息。",
      None: "检索比较人脸信息失败。",
      contents_modal_stop_message_unknown: "停止消息保存如下： \n“未知情况” \n您想继续吗？",
      contents_modal_stop_message_no_taxi: "停止消息保存如下： \n“出租车未出现” \n您想继续吗？",
      contents_modal_stop_message_no_guest: "停止消息保存如下： \n“客人未出现” \n您想继续吗？",
      contents_modal_stop_message_time_out_30: "停止消息保存如下： \n“等待时间超过30分钟” \n您想继续吗？",
      contents_modal_stop_message_time_out_60: "停止消息保存如下： \n“等待时间超过60分钟” \n您想继续吗？",
      contents_modal_stop_message_no_motorcycle: "停止消息保存如下： \n“送货摩托车未出现” \n您想继续吗？",
      contents_modal_stop_message_no_luggage: "停止消息保存如下： \n“行李还没准备好” \n您想继续吗？",
      contents_modal_stop_message_delay_30: "推迟消息保存如下： \n“延迟30分钟” \n您想继续吗？",
      contents_modal_stop_message_delay_60: "推迟消息保存如下： \n“延迟 60 分钟” \n您想继续吗？",
      contents_modal_stop_message_as_soon_as: "推迟消息保存如下： \n“尽快” \n您想继续吗？",
      contents_modal_stop_message_out_of_order: "停止消息保存如下： \n“出了故障” \n您想继续吗？",
      contents_modal_stop_message_cancel_service: "停止消息保存如下： \n‘取消服务’ \n您想继续吗？",
      contents_modal_stop_message_not_found_it: "停止消息保存如下： \n“找不到” \n您想继续吗？",
      contents_modal_stop_message_no_guest_room: "停止消息保存如下： \n“客人不在房间” \n您想继续吗？",
      contents_modal_stop_message_change_items: "停止消息保存如下： \n“更改项目” \n您想继续吗？",
      contents_modal_stop_message_unparked: "停止消息保存如下： \n“取消停车并取消” \n您想继续吗？",
      contents_modal_stop_message_parked: "停止消息保存如下： \n“停车并取消” \n您想继续吗？",
      contents_modal_myconect_release_proceed: "MyCONECT 进展如下： \n“MyCONECT 无法发布该版本” \n您想继续吗？",
      contents_modal_pickup_request: "您想提取请求吗？",
      contents_modal_deliver_request: "您想交付请求吗？",
      contents_modal_start_request: "您想开始请求吗？",
      contents_modal_confirm_request: "您想确认请求吗？",
      contents_modal_not_available_request: "您想将请求设置为“不可用”吗？",
      contents_modal_complete_request: "您想完成请求吗？",
      contents_modal_greencampaign_auto_process: "您想推进汽车绿色运动吗？",
      contents_modal_select_staff_payment: "您想继续处理所选信息吗？",
      contents_modal_select_business_rule: "您想继续处理所选信息吗？",
      contents_modal_delay_request: "您想延迟请求吗？",
      contents_modal_put_off_request: "您想推迟请求吗？",
      contents_modal_change_request: "你想改变它吗？",
      contents_modal_save_these_modifications: "您想保存这些修改吗？",
      contents_modal_select_myconect_not_available: "选择“MyCONECT 不可用”设置时间",
      contents_modal_select_delay: "选择“延迟”设定时间",
      contents_modal_select_status: "请选择状态。",
      contents_modal_make_sure_reason: "请选择原因。",
      contents_modal_myconect_not_available_proceed: "MyCONECT将进展如下： \n'此请求将被设置为'不可用' \n您想继续吗？",
      contents_modal_myconect_time_proceed: "MyCONECT将进展如下： \n发件人：{{fromTime}} / 收件人：{{endTime}} \n您想继续吗？",
      contents_modal_greencampaign_time_proceed: "绿色行动将按如下进展： \n发件人：{{fromTime}} / 收件人：{{endTime}} \n您想继续吗？",
      contents_modal_cannot_empty_value: "值不能为空。 \n请输入值。",
      contents_modal_information_already_exists: "信息已经存在。 \n请再试一次。",
      contents_modal_placeholder_user_email: "请输入用户电子邮件。",
      contents_modal_check_reset: "Previously selected users will be reset. Would you like to continue?",
    },
  },
  date_format: {
    zh: {
      date_format_slash_time_date: {
        date_format: "hh:mm a MM/dd/yy",
        language_code: "zh",
      },
      date_format_slash_date: {
        date_format: "MM/dd/yy",
        language_code: "zh",
      },
      date_format_hyphen_date: {
        date_format: "yyyy-MM-dd",
        language_code: "zh",
      },
      date_format_dot_month_year: {
        date_format: "MMM.yyyy",
        language_code: "zh",
      },
      date_format_dot_year_month: {
        date_format: "yyyy.MM",
        language_code: "zh",
      },
      date_format_slash_year_month: {
        date_format: "MM/yy",
        language_code: "zh",
      },
      date_format_ampm_time: {
        date_format: "a hh:mm",
        language_code: "zh",
      },
      date_format_time_ampm: {
        date_format: "hh:mm a",
        language_code: "zh",
      },
      date_format_24_hours_time: {
        date_format: "HH:mm",
        language_code: "zh",
      },
      date_format_hours_ampm: {
        date_format: "ha",
        language_code: "zh",
      },
      date_format_full_year: {
        date_format: "yyyy",
        language_code: "zh",
      },
      date_format_word_month: {
        date_format: "MMM",
        language_code: "zh",
      },
      date_format_full_day: {
        date_format: "dd",
        language_code: "zh",
      },
      date_format_short_day: {
        date_format: "d",
        language_code: "zh",
      },
      date_format_day_of_week: {
        date_format: "E",
        language_code: "zh",
      },
      date_format_ampm: {
        date_format: "a",
        language_code: "zh",
      },
      date_format_day_of_week_full: {
        date_format: "EEEE",
        language_code: "zh",
      },
      date_format_dot_month_day: {
        date_format: "MM.dd",
        language_code: "zh",
      },
      date_format_day_of_week_date: {
        date_format: "E. MMM. dd, yyyy",
        language_code: "zh",
      },
      date_format_slash_date_time: {
        date_format: "MM/dd/yy hh:mm a",
        language_code: "zh",
      },
      date_format_slash_month_day_time: {
        date_format: "MM/dd hh:mm a",
        language_code: "zh",
      },
      date_format_dot_date_time_full: {
        date_format: "yyyy.MM.dd hh:mm a",
        language_code: "zh",
      },
      date_format_dot_year_month_day: {
        date_format: "MM.dd.yy",
        language_code: "zh",
      },
      date_format_slash_full_year_date: {
        date_format: "yyyy/MM/dd",
        language_code: "zh",
      },
      date_format_space_date: {
        date_format: "dd MMM yyyy",
        language_code: "zh",
      },
      date_format_create_at_time_full: {
        date_format: "yyyy.MM.dd\nhh:mm a",
        language_code: "zh",
      },
    },
  },
};

export default ChineseLanguage;
