import MultiLanguage from "./MultiLanguage";

var languageCode = "";
var lang = "undefined";

if (navigator.language != null) {
  lang = navigator.language; // 언어 값이 있을 경우 lang에 저장
} else {
  lang = "undefined";
}

if (lang.includes("ko")) {
  languageCode = "ko-KR";
} else if (lang.includes("ja")) {
  languageCode = "ja-JP";
} else if (lang.includes("zh")) {
  languageCode = "zh-CN";
} else if (lang.includes("ms")) {
  languageCode = "ms-MY";
} else {
  languageCode = "en-EN";
}

var multiLanguageCode = languageCode.substr(0, 2);

export function updateLanguage() {
  updateMenuLanguage(multiLanguageCode);
}

function updateMenuLanguage(multiLanguageCode) {
  // 각 dataset중 detect인 요소들을 찾아 변경하는 곳.
  const changeNodeList = Array.prototype.slice.call(document.querySelectorAll("[data-detect]"));
  changeNodeList.map((v) => {
      v.innerHTML = updateStringToHtml(MultiLanguage.language[multiLanguageCode][v.dataset.detect]??v.dataset.detect);
  });

  const changePlaceholderList = Array.prototype.slice.call(document.querySelectorAll("[data-detect-placeholder]"));
  changePlaceholderList.map((v) => {
      v.placeholder = updateStringToHtml(MultiLanguage.language[multiLanguageCode][v.dataset.detectPlaceholder]);
  });

  const changeValueList = Array.prototype.slice.call(document.querySelectorAll("[data-detect-value]"));
  changeValueList.map((v) => {
      v.value = updateStringToHtml(MultiLanguage.language[multiLanguageCode][v.dataset.detectValue]);
  });
}

function updateStringToHtml(value) {
  var replaceString = value.replace(/\n/gi, "<br>");
  replaceString = replaceString.replace(/<</gi, "<b>");
  replaceString = replaceString.replace(/>>/gi, "</b>");
  return replaceString;
}

export default (languageCode, multiLanguageCode);