import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Loading } from "../../components/Paths";
import ApplicationTranslationDetailData from "../Detail/ApplicationTranslationDetailData";

//css
import "../../css/applicationTranslation/applicationTranslationDetail.css";
import "../../css/common.css";

// utils
import { formatDateTimeDot } from "../../common/Utils";

class ApplicationTranslationDetailView extends ApplicationTranslationDetailData {
  constructor(props) {
    super(props);
    this.state = {
      isShowAllLoading: true,
      applicationTranslationId: "",
      downloadFile: null,
    };
  }

  // 첫 실행시 URL에서 applicationTranslationId 파라미터 가져오기
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const applicationTranslationId = params.get("applicationTranslationId");
    this.setState({
      applicationTranslationId: applicationTranslationId,
    });
    this.requestGetApplicationTranslationOne(applicationTranslationId);
  }

  componentWillReceiveProps(props) {
    super.componentWillReceiveProps(props);
  }

  componentDidUpdate() {
    this.props.updateMultiLanguage(this.props.MultiLanguage);
  }

  //목록으로 돌아가기
  onBackBtn = () => {
    this.props.history.goBack();
  };

  //파일 다운로드
  onDownloadFile = () => {
    const {
      fileId,
      fileName,
    } = this.props.applicationTranslationDetail.results;

    if (fileId && fileName) {
      const downloadUrl = `/translation/${fileId}`;

      // 새로운 링크 엘리먼트 생성
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = fileName;

      // 다운로드를 시작하고 클릭 이벤트를 트리거
      downloadLink.style.display = "none"; // 숨겨진 링크를 만들어 렌더링하지 않도록 함
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      console.error("fileId 또는 fileName이 없습니다.");
    }
  };

  //수정 페이지로 이동
  linkApplicationTranslationEdit = () => {
    const params = new URLSearchParams(window.location.search);
    const applicationTranslationId = params.get("applicationTranslationId");
    this.props.history.push(
      `/applicationTranslationEditPage?applicationTranslationId=${applicationTranslationId}`
    );
  };



  render() {
    let applicationTranslationDetail = null;
    let downloadFile = null;

    if (
      this.props.applicationTranslationDetail != null &&
      this.props.applicationTranslationDetail?.success == true
    ) {
      applicationTranslationDetail = this.props.applicationTranslationDetail
        .results;
    }

    return (
      <div className="applicationTranslationDetail">
        {this.state.isShowAllLoading && <Loading />}
        <div className="applicaitonTranslationinfo_detail_container">
          <div className="applicaitonTranslationinfo_detail_container_inner">
            <div
              className="title_container"
              data-detect="admin_application_translation_detail_title"
            >
              APPLICATION TRANSLATION DETAIL
            </div>
            <div className="applicaitonTranslationinfo_container">
              <div className="detail_table">
                <div className="detail_tbody">
                  <div className="applicaitonTranslation_info">
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_application_translation_detail_label_admin_application_type"
                      >
                        Applicatin Type
                      </div>
                      <div
                        className="detail_cell content applicationType"
                        style={{ color: "white" }}
                      >
                        <span id="applicaitonTranslation_id">
                          {applicationTranslationDetail?.applicationType??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_application_translation_detail_label_os_type"
                      >
                        OS Type
                      </div>
                      <div
                        className="detail_cell content osType"
                        style={{ color: "white" }}
                      >
                        <span id="applicaitonTranslation_id">
                          {applicationTranslationDetail?.osType??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_application_translation_detail_label_admin_application_version"
                      >
                        Application Version
                      </div>
                      <div
                        className="detail_cell content applicationVersion"
                        style={{ color: "white" }}
                      >
                        <span id="applicaitonTranslation_id">
                          {applicationTranslationDetail?.applicationVersion??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_application_translation_detail_label_version"
                      >
                        Version
                      </div>
                      <div
                        className="detail_cell content version"
                        style={{ color: "white" }}
                      >
                        <span id="applicaitonTranslation_id">
                          {applicationTranslationDetail?.version??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_application_translation_detail_label_file_name"
                      >
                        File Name
                      </div>
                      <div
                        className="detail_cell content fileName"
                        style={{ color: "white" }}
                      >
                        <span id="applicaitonTranslation_id">
                          {applicationTranslationDetail?.fileName??"-"}
                        </span>
                      </div>
                    </div>
                    <div className="detail_row">
                      <div
                        className="detail_cell index"
                        data-detect="admin_application_translation_detail_label_date"
                      >
                        Date
                      </div>
                      <div
                        className="detail_cell content date"
                        style={{ color: "white" }}
                      >
                        <span id="applicaitonTranslation_id">
                          {applicationTranslationDetail ?
                            formatDateTimeDot(
                              applicationTranslationDetail.created_at
                            ) : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="button_form_container">
              <div className="gotolist_btn_container">
                <button
                  className="gotolist_btn"
                  onClick={this.onBackBtn}
                  data-detect="admin_application_translation_add_button_go_to_list"
                >
                  Go to List
                </button>
              </div>
              <div className="gotodownload_btn_container">
                <button
                  className="gotodownload_btn"
                  onClick={this.onDownloadFile}
                  data-detect="admin_application_translation_detail_button_download"
                >
                  Download
                </button>
              </div>
              <div className="gotoedit_btn_container">
                <button
                  className="edit_btn"
                  onClick={this.linkApplicationTranslationEdit}
                  data-detect="admin_application_translation_detail_button_edit"
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let mapStateToProps = ApplicationTranslationDetailData.mapStateToProps;
let mapDispatchToProps = ApplicationTranslationDetailData.mapDispatchToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationTranslationDetailView)
);
