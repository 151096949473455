import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import * as statisticsApis from "../lib/statistics_apis";
import { PURGE } from "redux-persist";

// 액션 타입
const GET_STATISTICS_ALL = "statistics/STATISTICS_GET_ALL";
const GET_RESERVATION_ALL = "statistics/GET_RESERVATION_ALL";
const GET_RESERVATION_ONE = "statistics/GET_RESERVATION_ONE";
const GET_RESERVATION_INVOICE = "statistics/GET_RESERVATION_INVOICE";
const GET_OWNER_AGREEMENT = "statistics/GET_OWNER_AGREEMENT";
const GET_PMS_SETTING_ONE = "statistics/GET_PMS_SETTING_ONE";
const GET_HOTEL_ALL = "statistics/GET_HOTEL_ALL";

// 액션 생성자
export const onGetAll = createAction(GET_STATISTICS_ALL, statisticsApis.onGetAll);
export const onGetAllReservation = createAction(GET_RESERVATION_ALL, statisticsApis.onGetAllReservation);
export const onGetReservationOne = createAction(GET_RESERVATION_ONE, statisticsApis.onGetReservationOne);
export const onGetReservationInvoice = createAction(GET_RESERVATION_INVOICE, statisticsApis.onGetReservationInvoice);
export const onGetOwnerAgreement = createAction(GET_OWNER_AGREEMENT, statisticsApis.onGetOwnerAgreement);
export const onGetPMSOne = createAction(GET_PMS_SETTING_ONE, statisticsApis.onGetPMSOne);
export const onGetAllHotel = createAction(GET_HOTEL_ALL, statisticsApis.onGetAllHotel);

// 초기 상태
const initialState = {
  statisticsList: [],
  statisticsDetail: null,
  reservationList: [],
  reservationDetail: null,
  reservationInvoice: null,
  ownerAgreement: null,
  pmsSettingDetail: null,
  hotelList: [],
  failData: null,
};

export default handleActions(
  {
    ...pender({
      type: GET_STATISTICS_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, statisticsList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, statisticsList: [], failData: action.payload.response?.data };
      },
    }),
    // reservation
    ...pender({
      type: GET_RESERVATION_ALL,
      onSuccess: (state, action) => {
        return { ...state, reservationList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response.data };
      },
    }),
    ...pender({
      type: GET_RESERVATION_ONE,
      onPending: (state, action) => {
        return { ...state, reservationDetail: null, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, reservationDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response.data };
      },
    }),
    ...pender({
      type: GET_RESERVATION_INVOICE,
      onSuccess: (state, action) => {
        return { ...state, reservationInvoice: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response.data };
      },
    }),
    ...pender({
      type: GET_OWNER_AGREEMENT,
      onSuccess: (state, action) => {
        return { ...state, ownerAgreement: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response.data };
      },
    }),
    // pms setting
    ...pender({
      type: GET_PMS_SETTING_ONE,
      onSuccess: (state, action) => {
        return { ...state, pmsSettingDetail: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, failData: action.payload.response.data };
      },
    }),
    // hotel
    ...pender({
      type: GET_HOTEL_ALL,
      onPending: (state, action) => {
        return { ...state, failData: null };
      },
      onSuccess: (state, action) => {
        return { ...state, hotelList: action.payload.data };
      },
      onFailure: (state, action) => {
        return { ...state, hotelList: [], failData: action.payload.response?.data };
      },
    }),
    [PURGE]: (state, action) => {
      return initialState;
    },
  },
  initialState
);
